import {
    LOAD_INTERESTS,
    NO_DATA_INTERESTS,
    GET_INTERESTS_SUCCESS,
    GET_INTERESTS_FAIL,
    ADD_INTEREST_SUCCESS,
    ADD_INTEREST_ERROR,
    UPDATE_INTEREST_SUCCESS,
    UPDATE_INTEREST_ERROR,
    READ_INTEREST_SUCCESS,
    STATUS_INTEREST_SUCCESS,
    ARCHIVE_INTEREST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    interestList: [],
    error: {},
    loading:false,
    noData:false,
    successMsg: null,
    errorMsg: null,
    interest: null,
    response:null,
    archiveMsg:null,
}

const interests = (state = INIT_STATE, action) => {
    
    switch (action.type) {
        case LOAD_INTERESTS:
            return {
                ...state,
                loading: true,
            }
            case NO_DATA_INTERESTS:
                return {
                    ...state,
                    noData: true,
                }
        case GET_INTERESTS_SUCCESS:
            return {
                ...state,
                loading: false,
                interestList: action.payload,
            }

        case GET_INTERESTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case ADD_INTEREST_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_INTEREST_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_INTERESTS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_INTEREST_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_INTERESTS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_INTEREST_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_INTERESTS_SUCCESS' ? null : action.payload,
            }
        case READ_INTEREST_SUCCESS:
            return {
                ...state,
                interest: action.payload,
            }
        case STATUS_INTEREST_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_INTEREST_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default interests