/* HOUSE_AD_CREATIVE */
export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES"
export const NO_DATA_HOUSE_AD_CREATIVES = "NO_DATA_HOUSE_AD_CREATIVES"
export const GET_HOUSE_AD_CREATIVES = "GET_HOUSE_AD_CREATIVES"
export const GET_HOUSE_AD_CREATIVES_SUCCESS = "GET_HOUSE_AD_CREATIVES_SUCCESS"
export const GET_HOUSE_AD_CREATIVES_FAIL = "GET_HOUSE_AD_CREATIVES_FAIL"

export const ADD_HOUSE_AD_CREATIVE = "ADD_HOUSE_AD_CREATIVE"
export const ADD_HOUSE_AD_CREATIVE_SUCCESS = "ADD_HOUSE_AD_CREATIVE_SUCCESS"
export const ADD_HOUSE_AD_CREATIVE_ERROR = "ADD_HOUSE_AD_CREATIVE_ERROR"

export const UPDATE_HOUSE_AD_CREATIVE = "UPDATE_HOUSE_AD_CREATIVE"
export const UPDATE_HOUSE_AD_CREATIVE_SUCCESS = "UPDATE_HOUSE_AD_CREATIVE_SUCCESS"
export const UPDATE_HOUSE_AD_CREATIVE_ERROR = "UPDATE_HOUSE_AD_CREATIVE_ERROR"

export const READ_HOUSE_AD_CREATIVE = "READ_HOUSE_AD_CREATIVE"
export const READ_HOUSE_AD_CREATIVE_SUCCESS = "READ_HOUSE_AD_CREATIVE_SUCCESS"

export const STATUS_HOUSE_AD_CREATIVE = "STATUS_HOUSE_AD_CREATIVE"
export const STATUS_HOUSE_AD_CREATIVE_SUCCESS = "STATUS_HOUSE_AD_CREATIVE_SUCCESS"

export const ARCHIVE_HOUSE_AD_CREATIVE = "ARCHIVE_HOUSE_AD_CREATIVE"
export const ARCHIVE_HOUSE_AD_CREATIVE_SUCCESS = "ARCHIVE_HOUSE_AD_CREATIVE_SUCCESS"