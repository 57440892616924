/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import features from './features.json'
import countries from './countryList.json'
import axios from "axios";

import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from "react-simple-maps";

const WorldMap = (props) => {
  const [countryName,setCountryName] = useState("");

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(successFunction, errorFunction);
  } else {
    console.log("Geolocation is not supported by this browser.");
  }
  
 async function successFunction(position) {
    console.log(position);
    let res = await axios.get(
      `https://api.weatherapi.com/v1/forecast.json?key=6c2177229c93464f9bf74536242703&q=${position.coords.latitude},${position.coords.longitude}&days=1&aqi=no&alerts=no`
  );
  if(res.status === 200){
    console.log(res.data.location.country);
    setCountryName(res.data.location.country)
  }
  }
  
  function errorFunction() {
    console.log("Unable to retrieve your location.");
  }

  return (
    <div>
      <div data-tip="" className="worldMap">
        <ComposableMap>
          <Geographies geography={features}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill="#EAEAEC"
                  stroke="#D6D6DA"
                />
              ))
            }
          </Geographies>
          {countries.countryList.map(({ Country, Latitude, Longitude }) => (
            countryName === Country ?   
              <Marker key={Country} coordinates={[Longitude,Latitude]}>
            <g
              fill="none"
              stroke="#FF5533"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform="translate(-12, -24)"
            >
              <circle cx="12" cy="10" r="3" />
              <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
            </g>
            <text
              textAnchor="middle"
              y={15}
              style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
            >
              {Country}
            </text>
            </Marker> : null
        
      ))}
        </ComposableMap>
      </div>
    </div>
  );
}

export default WorldMap