/* eslint-disable react/prop-types */
import React, { useRef, useEffect, useState } from 'react';
// import { Card, CardBody, Col, Form, Input, Label, Row, Spinner, Button,Container } from "reactstrap"
import Breadcrumb from 'components/Common/Breadcrumb';
import Sidebar from 'components/VerticalLayout/Sidebar';
import Header from 'components/VerticalLayout/Header';

import YouTube from 'react-youtube';

import ReactHlsPlayer from 'react-hls-player';
import {
    Card, CardBody, Col, Form, Input, Label, Row, Spinner, Button, Progress, Nav,
    NavItem,
    NavLink, TabContent,
    TabPane,
    Modal, ModalBody, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Container
} from "reactstrap"
import axios from "axios"
import classnames from "classnames";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const LinkTester = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 1,
        },
    };

    const [inputValue, setInputValue] = useState('');


    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };











    const fileChange = (e) => {
        setInputValue(e.target.value)
    };


 


    return (

        <>
            <div className="page-content">
                <Helmet>
                    <title>Link Tester | Bidsxchange</title>
                </Helmet>
                <Container fluid={true}>
                    <Breadcrumb title="Link Tester" breadcrumbItem="Link Tester" />
                    <Row >
                        <Col md="12">

                            <div style={{ padding: '1% 3%', height: '100vh', overflow: 'auto' }}>
                                <div>
                                    <Label className="text-left" htmlFor="direct-file">Enter Link</Label>
                                    <Input className="w-75" type="text" id="direct-file" accept='video/mp4' value={inputValue} onChange={fileChange} />
                                    <span className='mb-3'>Note: supports mp4 and m3u8 links</span>
                                </div>
                                {inputValue &&

                                    <div className="mb-3">
                                        <p className='mt-2' style={{fontWeight: 'bold'}}>Preview</p>
                                        {!inputValue.includes("m3u8") ?



                                            <video type="video/mp4" muted style={{  width: '640px', height: '360px' }}
                                                src={inputValue} controls autoPlay >
                                            </video>
                                            :
                                            <ReactHlsPlayer
                                                src={inputValue
                                                }
                                                autoPlay={true}
                                                controls={true}
                                                muted={true}
                                                width={640}
                                                height={360}
                                  
                                            />
                                        }
                                    </div>

                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>



        </>
    )
}

LinkTester.propTypes = {}

export default LinkTester

