import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";

import classNames from "classnames";
import axios from "axios";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

const AdminPage = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const userRole = localStorage.getItem('UserRole');
  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));

  const [reportData, setReportData] = useState([
    { title: "Network Settings", iconClass: "mdi mdi-access-point-network", link: "/Network_Settings", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "Access Management", iconClass: "mdi mdi-book-account-outline", link: "/Access_Management", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "Partner Management", iconClass: "mdi mdi-contacts", link: "/Partner_Management", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "Publisher Access Management", iconClass: "mdi mdi-contacts", link: "/Publisher_Access_Management", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "Ads.txt Management", iconClass: "mdi mdi-alpha-a-box-outline", link: "/Ads_Txt_Management", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "Master Log", iconClass: "mdi mdi-account-supervisor-circle-outline", link: "/Master_Log", yesterdayRate: '/Master_Log', isIncreased: 'zero' },
    { title: "Publisher Log", iconClass: "mdi mdi-account-supervisor-circle-outline", link: "/Publisher_Log", yesterdayRate: '/Publisher_Log', isIncreased: 'zero' },
    { title: "Notification", iconClass: "bx bxs-notification", link: "/Notification", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "Publisher Approval", iconClass: "bx bxs-user-rectangle", link: "/Publisher_Approval", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "Support Tickets", iconClass: "mdi mdi-ticket-confirmation-outline", link: "/Support_Tickets", yesterdayRate: '0%', isIncreased: 'zero' },
  ])


  var body = document.body;
  if (window.screen.width <= 998) {
    body.classList.toggle("sidebar-enable");
  } else {
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }



  return (
    <React.Fragment>
      <div className="page-content">
        <Helmet>
          <title>Admin | Bidsxchange</title>
        </Helmet>
        <Container fluid >
          <div className=" d-sm-flex  justify-content-between">
            <Breadcrumbs title="Demand" breadcrumbItem="Admin Settings" />
          </div>
          <Row>
            <Col xl="">
              <Row>
                {reportData.map((report, key) => (
                  <Col md="3" key={"_col_" + key}>
                      <Link to={report.link}>
                        <Card className="mini-stats-wid dashboard-cards text-center">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <div className="  rounded-circle bg-primary align-self-center mini-stat-icon" style={{ width: '5rem', height: '5rem', display: 'inline-block' }}>
                                  <span className="avatar-title rounded-circle bg-primary">
                                    <i
                                      className={
                                        "bx " + report.iconClass
                                      }
                                      style={{ fontSize: '40px' }}
                                    ></i>
                                  </span>
                                </div>

                                <h5 className="mt-3">{report.title}</h5>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Link>
                    {/* {report.title === "Ads.txt Management" &&
                      <Card className="mini-stats-wid dashboard-cards text-center">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div className="  rounded-circle bg-primary align-self-center mini-stat-icon" style={{ width: '5rem', height: '5rem', display: 'inline-block' }}>
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i
                                    className={
                                      "bx " + report.iconClass
                                    }
                                    style={{ fontSize: '40px' }}
                                  ></i>
                                </span>
                              </div>

                              <h5 className="mt-3">{report.title}</h5>
                            </div>
                          </div>
                          <Link to="/Ads_Txt_Queries">Ads.txt Queries</Link> &nbsp;&nbsp;&nbsp;&nbsp;
                          <Link to="/Ads_Txt_Entries">ADS.txt Entries</Link> &nbsp;&nbsp;&nbsp;&nbsp;
                          <Link to="/Publisher_Ads_Txt_Entries">Publisher ADS.txt Entries</Link>
                        </CardBody>
                      </Card>
                    } */}
                  </Col>
                ))}

              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AdminPage.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(AdminPage));