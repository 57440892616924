/* SUPPORT_TICKET */
export const LOAD_SUPPORT_TICKETS = "LOAD_SUPPORT_TICKETS"
export const NO_DATA_SUPPORT_TICKET_PUBLISHERS = "NO_DATA_SUPPORT_TICKET_PUBLISHERS"
export const GET_SUPPORT_TICKETS = "GET_SUPPORT_TICKETS"
export const GET_SUPPORT_TICKETS_SUCCESS = "GET_SUPPORT_TICKETS_SUCCESS"
export const GET_SUPPORT_TICKETS_FAIL = "GET_SUPPORT_TICKETS_FAIL"

export const ADD_SUPPORT_TICKET = "ADD_SUPPORT_TICKET"
export const ADD_SUPPORT_TICKET_SUCCESS = "ADD_SUPPORT_TICKET_SUCCESS"
export const ADD_SUPPORT_TICKET_ERROR = "ADD_SUPPORT_TICKET_ERROR"

export const ADD_CHAT = "ADD_CHAT"
export const ADD_CHAT_SUCCESS = "ADD_CHAT_SUCCESS"
export const ADD_CHAT_ERROR = "ADD_CHAT_ERROR"

