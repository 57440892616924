import {
    LOAD_ACTIVITIES,
    NO_DATA_SCRAPPYS,
    GET_SCRAPPYS_SUCCESS,
    GET_SCRAPPYS_FAIL,
    ADD_SCRAPPY_SUCCESS,
    ADD_SCRAPPY_ERROR,
    UPDATE_SCRAPPY_SUCCESS,
    UPDATE_SCRAPPY_ERROR,
    READ_SCRAPPY_SUCCESS,
    STATUS_SCRAPPY_SUCCESS,
    ARCHIVE_SCRAPPY_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    crtvs: [],
    pagination:{},
    error: {},
    loading:false,
    noData:false,
    successMsg: null,
    errorMsg: null,
    scrappy: null,
    response:null,
    archiveMsg:null,
}

const scrappy = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_ACTIVITIES:
            return {
                ...state,
                loading: true,
            }
            case NO_DATA_SCRAPPYS:
                return {
                    ...state,
                    noData: true,
                }
        case GET_SCRAPPYS_SUCCESS:
            return {
                ...state,
                loading:false,
                crtvs: action.payload.response,
                pagination:action.payload
            }

        case GET_SCRAPPYS_FAIL:
            return {
                ...state,
                loading:false,
                error: action.payload,
            }
        case ADD_SCRAPPY_SUCCESS:
            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_SCRAPPY_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_SCRAPPYS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_SCRAPPY_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_SCRAPPYS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_SCRAPPY_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_SCRAPPYS_SUCCESS' ? null : action.payload,
            }
        case READ_SCRAPPY_SUCCESS:
            return {
                ...state,
                scrappy: action.payload,
            }
        case STATUS_SCRAPPY_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_SCRAPPY_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default scrappy