import React, { useEffect, useState, useRef, useCallback } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from "axios";
import { withRouter, Link } from "react-router-dom";
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  Card,
  CardImg,
  CardTitle,
  CardText,
  CardBody,
  Col,
  Box,
  Input,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Label,
  Media
} from "reactstrap"
const Videosuite_Config = props => {
  
  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      <div className="page-content">
        <Helmet>
          <title>VideoSuite Configuration | Bidsxchange</title>
        </Helmet>
        <Container fluid>
          <div className=" d-sm-flex  justify-content-between">
            <Breadcrumbs title="Media" breadcrumbItem="VideoSuite Configuration" />
          </div>

        </Container>
      </div>
     
    </React.Fragment>
  )
}


Videosuite_Config.propTypes = {}

export default Videosuite_Config