export const GET_PLAYLIST = "GET_PLAYLIST"
export const GET_PLAYLIST_SUCCESS = "GET_PLAYLIST_SUCCESS"
export const GET_PLAYLIST_FAIL = "GET_PLAYLIST_FAIL"

export const GET_PLAYLISTBYID = "GET_PLAYLISTBYID"
export const GET_PLAYLISTBYID_SUCCESS = "GET_PLAYLISTBYID_SUCCESS"
export const GET_PLAYLISTBYID_FAIL = "GET_PLAYLISTBYID_FAIL"

export const CREATE_PLAYLIST = "CREATE_PLAYLIST"
export const CREATE_PLAYLIST_SUCCESS = "CREATE_PLAYLIST_SUCCESS"
export const CREATE_PLAYLIST_FAIL = "CREATE_PLAYLIST_FAIL"

export const UPDATE_PLAYLIST = "UPDATE_PLAYLIST"
export const UPDATE_PLAYLIST_SUCCESS = "UPDATE_PLAYLIST_SUCCESS"
export const UPDATE_PLAYLIST_FAIL = "UPDATE_PLAYLIST_FAIL"

export const STATUS_PLAYLIST = "STATUS_PLAYLIST"
export const STATUS_PLAYLIST_SUCCESS = "STATUS_PLAYLIST_SUCCESS"

export const ARCHIVE_PLAYLIST = "ARCHIVE_PLAYLIST"
export const ARCHIVE_PLAYLIST_SUCCESS = "ARCHIVE_PLAYLIST_SUCCESS"
export const ARCHIVE_PLAYLIST_FAIL = "ARCHIVE_PLAYLIST_FAIL" 