/* SCRAPPY */
export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES"
export const NO_DATA_SCRAPPYS = "NO_DATA_SCRAPPYS"
export const GET_SCRAPPYS = "GET_SCRAPPYS"
export const GET_SCRAPPYS_SUCCESS = "GET_SCRAPPYS_SUCCESS"
export const GET_SCRAPPYS_FAIL = "GET_SCRAPPYS_FAIL"

export const ADD_SCRAPPY = "ADD_SCRAPPY"
export const ADD_SCRAPPY_SUCCESS = "ADD_SCRAPPY_SUCCESS"
export const ADD_SCRAPPY_ERROR = "ADD_SCRAPPY_ERROR"

export const UPDATE_SCRAPPY = "UPDATE_SCRAPPY"
export const UPDATE_SCRAPPY_SUCCESS = "UPDATE_SCRAPPY_SUCCESS"
export const UPDATE_SCRAPPY_ERROR = "UPDATE_SCRAPPY_ERROR"

export const READ_SCRAPPY = "READ_SCRAPPY"
export const READ_SCRAPPY_SUCCESS = "READ_SCRAPPY_SUCCESS"

export const STATUS_SCRAPPY = "STATUS_SCRAPPY"
export const STATUS_SCRAPPY_SUCCESS = "STATUS_SCRAPPY_SUCCESS"

export const ARCHIVE_SCRAPPY = "ARCHIVE_SCRAPPY"
export const ARCHIVE_SCRAPPY_SUCCESS = "ARCHIVE_SCRAPPY_SUCCESS"