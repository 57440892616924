/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Spinner, Collapse, Button, DropdownMenu, DropdownItem, Dropdown } from "reactstrap";
import PropTypes from "prop-types";
import axios from "axios";
import Select from "react-select";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";


import {
  getPartners as onGetPartners,
} from "store/PartnerManagement/actions";

const New_BrandSafety = props => {

  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [isEdit, setIsEdit] = useState(false)
  const dispatch = useDispatch();
  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };



  const [categoryList, setCategoryList] = useState([
    {
      label: 'Arts & Entertainment',
      value: false
    },
    {
      label: 'Health & Fitness',
      value: false
    },
    {
      label: 'Science',
      value: false
    },
    {
      label: 'Automotive',
      value: false
    },
    {
      label: 'Hobbies & Interests',
      value: false
    },
    {
      label: 'Shopping',
      value: false
    },
    {
      label: 'business',
      value: false
    },
    {
      label: 'Home & Garden',
      value: false
    },
    {
      label: 'Society',
      value: false
    },
    {
      label: 'Careers',
      value: false
    },
    {
      label: 'News',
      value: false
    },
    {
      label: 'Sports',
      value: false
    },
    {
      label: 'Dating',
      value: false
    },
    {
      label: 'Finance',
      value: false
    },
    {
      label: 'Style & Fashion',
      value: false
    },
    {
      label: 'Education',
      value: false
    },
    {
      label: 'Pets',
      value: false
    },
    {
      label: 'Technology & Computing',
      value: false
    },
    {
      label: 'Family & Parenting',
      value: false
    },
    {
      label: 'Real Estate',
      value: false
    },
    {
      label: 'Travel',
      value: false
    },
    {
      label: 'Food & Drink',
      value: false
    },
  ]
  );

  const [blockedList, setBlockedList] = useState([
    {
      label: 'Adult',
      value: true
    },
    {
      label: 'Gambling',
      value: true
    },
    {
      label: 'Religion & Spirituality',
      value: true
    },
    {
      label: 'Alcohol',
      value: true
    },
    {
      label: 'Law, Govt & Politics',
      value: true
    },
    {
      label: 'Tobacco',
      value: true
    },
  ])

  const [subCategoryList, setSubCategoryList] = useState([{ categoryName: '' }])


  const [bsDataEdit, setBsDataEdit] = useState(props.toEdit[0])

  const [selectedPartner, setSelectedPartner] = useState(null);

  const [partnerData, setPartnerData] = useState([])
  useEffect(() => {
    dispatch(onGetPartners(null));
  }, []);

  let { partners } = useSelector(state => ({
    partners: state.partners.partners,
  }));

  useEffect(() => {
    if (partners !== null) {
      dispatch(onGetPartners({ filter: 'Active', partnerType: '' }));
    }
  }, []);

  console.log(bsDataEdit)
  useEffect(() => {
    console.log(bsDataEdit)
    bsDataEdit && setCategoryList(bsDataEdit.brandSafety.blockAdvertiserCategory)
    bsDataEdit && setDomainList(bsDataEdit.brandSafety.blockAdvertiserDomain)
    bsDataEdit && setSubCategoryList(bsDataEdit.brandSafety.addSubCategoryToBlock)
  }, [])


  useEffect(() => {
    if (bsDataEdit === undefined && selectedPartner) {
      setBsDataEdit({ partnerID: selectedPartner.value, partnerName: selectedPartner.label })
    }
  }, [selectedPartner])


  const handleSafetyChange = (index) => (e) => {
    let categoryLists = [...categoryList]
    categoryLists[index].value = e.target.checked
    setCategoryList(categoryLists)
  }
  const tagChange = (index) => (e) => {
    let subCategories = [...subCategoryList]
    subCategories[index].categoryName = e.target.value
    setSubCategoryList(subCategories)
  }

  const removeCategory = (index) => (e) => {
    let subCategories = [...subCategoryList]
    subCategories.splice(index, 1)
    setSubCategoryList(subCategories)
  }

  const addNewCategory = () => {
    let subCategories = [...subCategoryList]
    subCategories.push({ categoryName: '' })
    setSubCategoryList(subCategories)
  }

  const [domainList, setDomainList] = useState([{ domainName: '' }])

  const domainChange = (index) => (e) => {
    let domains = [...domainList]
    domains[index].domainName = e.target.value
    setDomainList(domains)
  }

  const removeDomian = (index) => (e) => {
    let domains = [...domainList]
    domains.splice(index, 1)
    setDomainList(domains)
  }

  const addNewDomain = () => {
    let domains = [...domainList]
    console.log(domains)
    if (domains[domainList.length - 1].domainName === '') {
      toastr.warning('Please enter Domain Name')
    } else {
      let domainValidation = isValidDomain(domains[domainList.length - 1].domainName)
      console.log(domainValidation)
      if (domainValidation) {
        domains.push({ domainName: '' })
        setDomainList(domains)
      }
      else {
        toastr.warning('Please enter Valid Domain Name')
      }
    }
  }

  function isValidDomain(str) {
    let regex = new RegExp(/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/);
    if (str == null) {
      return false;
    }
    if (regex.test(str) == true) {
      return true;
    }
    else {
      return false;
    }
  }


  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const body = {
    "url": '',
    "requestBody": {
      partnerID: userInfo.userID,
      userRole: userInfo.userRole,
      partnerName: userInfo.name,
    }
  }

  useEffect(() => {
    if (bsDataEdit) {
      setIsEdit(true)
    }
    readBrandSafety()
  }, []);

  const readBrandSafety = () => {
    // body.url = "http://127.0.0.1:3006/v1.0/BrandSafety/read"
    // axios.post("/v1.0/connectPartner", body,
    //   {
    //     headers: headers
    //   })
    //   .then((res) => {
    //     console.log("result is ==>", res)
    //     if (res.data.response.length > 0) {
    //       // setIsEdit(true)
    //       // setCategoryList(res.data.response[0].brandSafety.blockAdvertiserCategory)
    //       // setSubCategoryList(res.data.response[0].brandSafety.addSubCategoryToBlock)
    //       // setDomainList(res.data.response[0].brandSafety.blockAdvertiserDomain)
    //     } else {
    //       // setIsEdit(false)
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("e", err)
    //   });
    console.log("In ReadBrandSafety func")
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let domains = [...domainList]
    if (domains[domainList.length - 1].domainName === '') {
      toastr.warning('Please enter Domain Name')
    } else {
      let domainValidation = isValidDomain(domains[domainList.length - 1].domainName)
      console.log(domainValidation)
      if (domainValidation) {
        let safetyBody = {
          url: "/v2.0/operations/BrandSafety/create",
          "requestBody": {
            partnerID: bsDataEdit.partnerID,
            userRole: userInfo.userRole,
            partnerName: bsDataEdit.partnerName,
            parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner,
            "brandSafety": {
              "blockAdvertiserCategory": categoryList,
              "addSubCategoryToBlock": subCategoryList,
              "blockAdvertiserDomain": domainList
            },
          }
        }
        if (isEdit) {
          safetyBody.url = "/v2.0/operations/BrandSafety/update"
        }
        axios.post(safetyBody.url, safetyBody.requestBody,
          {
            headers: headers
          })
          .then((res) => {
            console.log("result is ==>", res)
            if (res.data.success) {
              if (isEdit) {
                toastr.success('Brand Safety Updated Successfully!...')
                props.closeCanvas('')
              } else {
                toastr.success('Brand Safety Created Successfully!...')
                props.closeCanvas('')
              }
              readBrandSafety()
            }
          })
          .catch((err) => {
            console.log("e", err)
          });
      }
      else {
        toastr.warning('Please enter Valid Domain Name')
      }
    }
    props.closeCanvas('')
  }

  const createUI = () => {
    return categoryList.map((item, i) =>
      <div className="col-md-4" key={i}>
        <div className="form-check  mt-2 mb-2">
          <input
            className="form-check-input"
            type="checkbox"
            checked={item.value}
            name={item.label}
            id={item.label}
            onChange={handleSafetyChange(i)}
          />
          <label
            className="form-check-label" htmlFor={item.label}
          >
            {item.label}
          </label>
        </div>
      </div>
    )
  }

  const createBlockedCategoryUI = () => {
    return blockedList.map((item, i) =>
      <div className="col-md-4" key={i}>
        <div className="form-check  mt-2 mb-2">
          <input
            className="form-check-input"
            type="checkbox"
            checked={item.value}
            name={item.label}
            id={item.label}
            disabled
          // onChange={changeSelection(index)}
          />
          <label
            className="form-check-label" htmlFor={item.label}
          >
            {item.label}
          </label>
        </div>
      </div>
    )
  }



  function handleSelectPartner(selectPartner) {
    console.log(selectPartner)
    setSelectedPartner(selectPartner);

  }

  useEffect(() => {
    if (partners && partners.length > 0) {
      partners=partners.filter((partner)=> !props.existingPartners.includes(partner.partnerID))
      setPartnerData(objectModal(partners))
      console.log(partners)
    }
  }, [partners]);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.partnerID
      obj.label = item.userName
      dataList.push(obj)
    })
    return dataList
  }



  return (

    <React.Fragment>

      <Container fluid={true}>

        <Row>
          <div className="col-16">
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <Row >
                    <div >
                      {isEdit ? <h4 className="mb-3" style={{ fontSize: '16px' }}>
                        Partner Name : <span className="color-dark-blue">{bsDataEdit && bsDataEdit?.partnerName}</span>
                      </h4 > : <>
                        <Col xs={12}>
                          <div className="mb-3">
                            <Label style={{ fontSize: '16px' }}>Select Partner </Label>
                            <Select
                              value={selectedPartner}
                              onChange={s => {
                                handleSelectPartner(s)
                              }}
                              options={partnerData}
                              classNamePrefix="select2-selection"
                            />
                          </div>

                        </Col>
                      </>
                      }
                      <div className="accordion" id="accordion">
                        <div className="accordion-item b-0" style={{ border: 'none' }}>
                          <h5 className="accordion-header" id="headingOne">
                            Block Advertiser Category
                          </h5>

                          <Collapse isOpen={true} className="accordion-collapse">
                            <div className="accordion-body pb-0" >
                              <div className="row">
                                {createUI()}
                              </div>
                              <div className="row mt-2">
                                <h5 id="headingOne mt-3">
                                  <u> Default Blocked Advertiser Category</u>
                                </h5>
                                {createBlockedCategoryUI()}
                              </div>
                              <div className="row mt-2">
                                <h5 id="headingOne mt-3">
                                  <u> Add Sub-Category to Block</u>
                                </h5>
                                <div >
                                  {
                                    subCategoryList.map((subItem, i) => {
                                      return (
                                        <div className="vast-settings" key={i}>
                                          <div className="accordion-body">
                                            <div className="form-group">
                                              <div className="row ">
                                                <div className="col-6">
                                                  <Input
                                                    name="categoryName"
                                                    type="text"
                                                    value={subItem.categoryName}
                                                    onChange={tagChange(i)}
                                                    placeholder='Enter Sub-Category'
                                                  >
                                                  </Input>
                                                </div>
                                                <div className="col-2">
                                                  <div className='row form-group '>
                                                    <Button type="button" className='btn btn-danger ml-auto ' style={{ marginRight: '1pc', marginTop: '0pc', background: '#dc3545', float: 'right', width: '3pc', }} onClick={removeCategory(i)} disabled={subCategoryList.length === 1}
                                                    >X</Button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })
                                  }
                                  <div className='row'>
                                    <Button
                                      color="primary"
                                      className="font-16 btn-block btn btn-primary"
                                      style={{ margin: '1% 2rem 0% ', width: 'auto' }}
                                      onClick={addNewCategory}
                                    >
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Add Sub-Category
                                    </Button>
                                  </div>
                                  <Row >
                                    <div className="accordion mt-4" id="accordion">
                                      <div className="accordion-item b-0 mt-2" style={{ border: 'none' }}>
                                        <h5 className="accordion-header" id="headingOne">
                                          Block Advertiser Domain
                                        </h5>
                                        <Collapse isOpen={true} className="accordion-collapse">
                                          <div className="accordion-body pb-0">
                                            <div className="row ">
                                              {
                                                domainList && domainList.length > 0 && domainList.map((subItem, i) => {
                                                  return (
                                                    <div className="vast-settings mt-2" key={i}>
                                                      <div >
                                                        <div className="form-group">
                                                          <div className="row ">
                                                            <div className="col-6">
                                                              <Input
                                                                name="domainName"
                                                                type="text"
                                                                value={subItem.domainName}
                                                                onChange={domainChange(i)}
                                                                placeholder='Enter Domain'
                                                              >
                                                              </Input>
                                                            </div>
                                                            <div className="col-2">
                                                              <div className='row form-group '>
                                                                <Button type="button" className='btn btn-danger ml-auto ' style={{ marginRight: '1pc', marginTop: '0pc', background: '#dc3545', float: 'right', width: '3pc', }} onClick={removeDomian(i)} disabled={domainList.length === 1}
                                                                >X</Button>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                })
                                              }
                                              <div className='row'>
                                                <Button
                                                  color="primary"
                                                  className="font-16 btn-block btn btn-primary"
                                                  style={{ margin: '1.5pc 0.8pc 5% ', width: 'auto' }}
                                                  onClick={addNewDomain}
                                                >
                                                  <i className="mdi mdi-plus-circle-outline me-1" />
                                                  Add Domain
                                                </Button>
                                              </div>
                                            </div>
                                          </div>
                                        </Collapse>
                                      </div>
                                    </div>
                                  </Row>
                                  {/* <Row>
                                    <Col className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
                                      <div className=" text-sm-end">
                                        {!isEdit &&
                                          <button
                                            type="submit"
                                            className="btn btn-primary save-user"
                                          >
                                            Submit
                                          </button>
                                        }
                                        {isEdit &&
                                          <button
                                            type="submit"
                                            className="btn btn-primary save-user"
                                          >
                                            Update
                                          </button>
                                        }
                                      </div>
                                    </Col>
                                  </Row> */}
                                </div>
                              </div>
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </Row>

                </Form>
              </CardBody>
            </Card>
          </div>

        </Row>
        <Row>
          <Col className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <div className="text-sm-start">
              {!isEdit &&
                <button
                  type="submit"
                  className="btn btn-primary save-user"
                >
                  Submit
                </button>
              }
              {isEdit &&
                <button
                  type="submit"
                  className="btn btn-primary save-user"
                >
                  Update
                </button>
              }
              &nbsp;&nbsp;
              <button
                type="button"
                className="btn btn-outline-primary  save-user"
                // eslint-disable-next-line react/prop-types
                onClick={props.closeCanvas}
              >
                Back
              </button>

            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default New_BrandSafety

New_BrandSafety.propTypes = {
  history: PropTypes.object,
};