import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { Col, Container, Row, Input, Label, Form, Button } from "reactstrap"

const Ad_TAg_Tester = props => {
    const [adTagContent, setAdTagContent] = useState('')

    const handleChange = (e) => {
        setAdTagContent(e.target.value);
    }

    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
                <div className="page-content">
                    <Helmet>
                        <title>Ad Tag Tester | Bidsxchange</title>
                    </Helmet>
                    <Container fluid={true}>
                        <Breadcrumbs title="Tools" breadcrumbItem="Ad Tag Tester" />
                        <div className="card card-body ">
                            <div className="row">
                                <textarea id="input" name="adTagContent" value={adTagContent} onChange={handleChange} className="form-control" placeholder="Either type or paste your web browser code here" style={{ resize: 'none', display: 'block', height: '271.8px' }}></textarea>
                                <div className=" mt-4" id="output">
                                    <iframe src="about:blank" allow="autoplay;fullscreen;" scrolling="yes" srcDoc={adTagContent} style={{ width: '100%', minHeight: '50vh', overflow: 'auto', border: '1px solid #ced4da', borderRadius: '0.25rem' }}></iframe>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            {/* </div > */}
        </React.Fragment >
    )
}

Ad_TAg_Tester.propTypes = {}

export default Ad_TAg_Tester