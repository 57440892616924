import {
    LOAD_ACTIVITIES,
    NO_DATA,
    GET_IOGROUPS_SUCCESS,
    GET_IOGROUPS_FAIL,
    ADD_IOGROUP_SUCCESS,
    ADD_IOGROUP_ERROR,
    UPDATE_IOGROUP_SUCCESS,
    UPDATE_IOGROUP_ERROR,
    READ_IOGROUP_SUCCESS,
    STATUS_IOGROUP_SUCCESS,
    ARCHIVE_IOGROUP_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    ioGroups: [],
    pagination:{},
    error: {},
    loading: false,
    noData: false,
    successMsg: null,
    errorMsg: null,
    ioGroup: null,
    response: null,
    archiveMsg: null,
}

const ioGroups = (state = INIT_STATE, action) => {
    console.log
    switch (action.type) {
        case LOAD_ACTIVITIES:
            return {
                ...state,
                loading: true,
            }
        case NO_DATA:
            return {
                ...state,
                noData: true,
            }
        case GET_IOGROUPS_SUCCESS:
            return {
                ...state,
                loading: false,
                ioGroups:  action.payload.response.iogroups ? action.payload.response.iogroups : [],
                pagination:action.payload.response
            }

        case GET_IOGROUPS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case ADD_IOGROUP_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_IOGROUP_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_IOGROUPS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_IOGROUP_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_IOGROUPS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_IOGROUP_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_IOGROUPS_SUCCESS' ? null : action.payload,
            }
        case READ_IOGROUP_SUCCESS:
            return {
                ...state,
                ioGroup: action.payload,
            }
        case STATUS_IOGROUP_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_IOGROUP_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default ioGroups