/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types'
import {
    Card,
    CardBody,
    Col,
    Row,
    Button,Tooltip,
    Spinner
} from "reactstrap"

const Ads_TxtViewEntries = props => {

    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const copyCrawled = (data) => (e) => {
         const cb = navigator.clipboard;
      const paragraph = document.getElementById(data);
      cb.writeText(paragraph.innerText)
    }

    const onToggleTooltip = (e) => {
        setIsTooltipOpen(!isTooltipOpen)
    }

  return (
    <Col lg="12">
        <Card>
            <CardBody>
                <Row className="mb-2">
                    <Col sm="3">
                    </Col>
                    <Col sm="9">
                        <div className="text-sm-end">
                            <Button
                                color="primary"
                                className="font-16 btn-block btn btn-primary"
                                id="controlledTooltip"
                                onClick={copyCrawled('adsTxtEntries')}
                                onMouseLeave={() => {
                                    setIsTooltipOpen(false)
                                }}
                            >
                                Copy Ads.txt Entries
                            </Button>
                            <Tooltip
                                isOpen={isTooltipOpen}
                                placement="top"
                                target="controlledTooltip"
                                toggle={onToggleTooltip}
                                trigger="click"
                            >
                                Copied
                            </Tooltip>
                        </div>
                    </Col>
                </Row>
                <div className="mb-3 mt-2" id="adsTxtEntries" style={{ border: '1px solid #e9ecef', height: '62vh', overflow: 'auto', padding: '2%', borderRadius: '5px' }}>
                    {
                        props.selectedAdsTxtEntries.length > 0 && props.selectedAdsTxtEntries.map((subItem, i) => {
                            return (
                                <div className="crawledData row mt-2" key={i}>
                                    <span className="col-11">{i+1}. &nbsp;&nbsp; {subItem.SSPEntry}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </CardBody>
        </Card>
    </Col>
  )
}

Ads_TxtViewEntries.propTypes = {}

export default Ads_TxtViewEntries
