import {
  LOAD_ACTIVITIES,
  NO_DATA_HOUSE_AD_PUBLISHER,
    GET_AD_PUBLISHER,
    GET_AD_PUBLISHER_FAIL,
    GET_AD_PUBLISHER_SUCCESS,
    ADD_AD_PUBLISHER,
    ADD_AD_PUBLISHER_SUCCESS,
    ADD_AD_PUBLISHER_ERROR,
    UPDATE_AD_PUBLISHER,
    UPDATE_AD_PUBLISHER_SUCCESS,
    UPDATE_AD_PUBLISHER_ERROR,
    READ_AD_PUBLISHER,
    READ_AD_PUBLISHER_SUCCESS,
    STATUS_AD_PUBLISHER,
    STATUS_AD_PUBLISHER_SUCCESS,
    ARCHIVE_AD_PUBLISHER ,
    ARCHIVE_AD_PUBLISHER_SUCCESS,
    GET_AD_PUBLISHER_TYPE,
    GET_AD_PUBLISHER_TYPE_FAIL,
    GET_AD_PUBLISHER_TYPE_SUCCESS,
    ADD_AD_PUBLISHER_TYPE,
    ADD_AD_PUBLISHER_TYPE_SUCCESS,
    ADD_AD_PUBLISHER_TYPE_ERROR,

  } from "./actionTypes"

  export const loadActivitiesAdPublisher = Params => ({
    type: LOAD_ACTIVITIES,
    payload:Params
  })

  export const noHouseAdPublisher = Params => ({
    type: NO_DATA_HOUSE_AD_PUBLISHER,
    payload:Params
  })

  export const getAdPublisher = Params => ({
    type: GET_AD_PUBLISHER,
    payload:Params
  })
  
  export const getAdPublisherSuccess = AdPublisher => ({
    type: GET_AD_PUBLISHER_SUCCESS,
    payload: AdPublisher,
  })

  export const getAdPublisherFail = error => ({
    type: GET_AD_PUBLISHER_FAIL,
    payload: error,
  })

  export const addNewAdPublisher = AdPublisher => ({
    type: ADD_AD_PUBLISHER,
    payload: AdPublisher,
  })
  
  export const addAdPublisherSuccess = adPublisher => ({
    type: ADD_AD_PUBLISHER_SUCCESS,
    payload: adPublisher,
  })
  
  export const addAdPublisherFail = adPublisher => ({
    type: ADD_AD_PUBLISHER_ERROR,
    payload: adPublisher,
  })

  export const updateAdPublisher = adPublisher => ({
    type: UPDATE_AD_PUBLISHER,
    payload: adPublisher,
  })
  
  export const updateAdPublisherSuccess = adPublisher => ({
    type: UPDATE_AD_PUBLISHER_SUCCESS,
    payload: adPublisher,
  })
  
  export const updateAdPublisherFail = adPublisher => ({
    type: UPDATE_AD_PUBLISHER_ERROR,
    payload: adPublisher,
  })

  export const readAdPublisher = adPublisher => ({
    type: READ_AD_PUBLISHER,
    payload: adPublisher,
  })

  export const readAdPublisherSuccess = adPublisher => ({
    type: READ_AD_PUBLISHER_SUCCESS,
    payload: adPublisher,
  })

  export const statusAdPublisher = adPublisher => ({
    type: STATUS_AD_PUBLISHER,
    payload: adPublisher,
  })

  export const statusAdPublisherSuccess = adPublisher => ({
    type: STATUS_AD_PUBLISHER_SUCCESS,
    payload: adPublisher,
  })

  export const archiveAdPublisher = adPublisher => ({
    type: ARCHIVE_AD_PUBLISHER,
    payload: adPublisher,
  })

  export const archiveAdPublisherSuccess = adPublisher => ({
    type: ARCHIVE_AD_PUBLISHER_SUCCESS,
    payload: adPublisher,
  })


  export const getAdPublisherType = Params => ({
    type: GET_AD_PUBLISHER_TYPE,
    payload:Params
  })
  
  export const getAdPublisherTypeSuccess = AdPublisher => ({
    type: GET_AD_PUBLISHER_TYPE_SUCCESS,
    payload: AdPublisher,
  })

  export const getAdPublisherTypeFail = error => ({
    type: GET_AD_PUBLISHER_TYPE_FAIL,
    payload: error,
  })

  export const addNewAdPublisherType = AdPublisher => ({
    type: ADD_AD_PUBLISHER_TYPE,
    payload: AdPublisher,
  })
  
  export const addAdPublisherTypeSuccess = adPublisher => ({
    type: ADD_AD_PUBLISHER_TYPE_SUCCESS,
    payload: adPublisher,
  })
  
  export const addAdPublisherTypeFail = adPublisher => ({
    type: ADD_AD_PUBLISHER_TYPE_ERROR,
    payload: adPublisher,
  })