import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from 'prop-types'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Breadcrumbs from "components/Common/Breadcrumb";
import axios from "axios";

import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Label
} from "reactstrap"
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

import moment from "moment";

import {
    getScrappys as onGetScrappy,
    statusScrappy as onStatusScrappy,
    archiveScrappy as onArchiveScrappy,
    statusScrappySuccess,
    archiveScrappySuccess,
    addScrappyFail,
    updateScrappyFail,
} from "store/customTracking/Scrappys/actions";

import LoadingTable from "pages/LoaderPages/LoadingTable";
import ProductDataTableLI from "Product_Helpers/ProductDataTableLI";
import NewScrappy from "./NewScrappy";
import LoadingTableContextual from "pages/LoaderPages/LoadingTableContextual";

var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 2 : 1

var fromDate = moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days')
var toDate = moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')

var selectedItem = []
var selectedForArchive = []
var selectedData = ''
var selectedIndex = []
var loader = false
var filterCount = 0

let pageNumber = 1
let pageSize = 10
let totalRecords = 0
let searchText = ''

const ScrappyTool = props => {
    const dispatch = useDispatch();
    const [isRight, setIsRight] = useState(false);
    const [isRightWidget, setIsRightWidget] = useState(false);
    const [proxy, setAnalyticalReports] = useState([])

    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [isArchived, setIsArchived] = useState(false)
    const [selected, setSelected] = useState([])
    const [adUnitSearch, setAdUnitSearch] = useState('')
    const [statusSearch, setStatusSearch] = useState('Active')
    const [popoverdismiss, setpopoverdismiss] = useState(false);

    const [switchLoader, setSwitchLoader] = useState(true)

    const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));


    const handleSwitch = () => {
        setSwitchLoader(!switchLoader)
    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            setIsRight(false);
            setIsRightWidget(false)
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    const [filterState, setFilterState] = useState(false)

    const statusChange = (e) => {
        if (e.target.value === 'Active') {
            filterCount = filterCount > 0 ? filterCount - 1 : 0
        }
        if (statusSearch === 'Active') {
            filterCount = filterCount + 1
        }
        setStatusSearch(e.target.value)
    }
    const adUnitChange = (e) => {
        if (e.target.value === '') {
            filterCount = filterCount > 0 ? filterCount - 1 : 0
        }
        if (adUnitSearch === '') {
            filterCount = filterCount + 1
        }
        setAdUnitSearch(e.target.value)
    }

    const applyFilter = (e) => {
        totalRecords = 0
        pageNumber = 1
        pageSize = 10
        dispatch(onGetScrappy({ pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch }));
        if (filterCount > 0) {
            setFilterState(true)
        } else {
            setFilterState(false)
        }
    }

    const [isMenu, setisMenu] = useState(false)
    const toggleMenu = () => {
        setisMenu(!isMenu)
    }

    const clearAllFilter = (e) => {
        setStatusSearch('Active')
        setAdUnitSearch('')
        filterCount = 0
        setFilterState(false)
        dispatch(onGetScrappy({ pageSize: 10, pageNumber: 1, searchText: '', filter: 'Active' }));
    }

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    useEffect(() => {
        loader = true
        // dispatch(onGetScrappy(null));
    }, []);

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`,
        UserID: userInfo.userID,
        UserRole: userInfo.userRole,
    }

    const selectRow = {
        mode: "checkbox",
        nonSelectable: [],
        selected: selected,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                setSelected([...selected, row.id])
                setSelectedRowsStatus(true)
                selectedIndex.push(row.scrapyID)
                selectedItem.push(row.scrapyID)
                selectedForArchive.push({ scrapyApplicationType: row.scrapyApplicationType, scrapySite: row.scrapySite })
            } else {
                setSelected(selected.filter(x => x !== row.id))
                let index = selectedIndex.indexOf(row.scrapyID)
                selectedIndex.splice(index, 1)
                let index2 = selectedItem.indexOf(row.scrapyID)
                selectedItem.splice(index2, 1)
                selectedForArchive.splice(index2, 1)
                if (selectedIndex.length === 0) {
                    setSelectedRowsStatus(false)
                }
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            const ids = rows.map(r => r.id);
            if (isSelect) {
                setSelected(ids)
                rows.map((item, index) => {
                    selectedIndex.push(item.scrapyID)
                    selectedItem.push(item.scrapyID)
                    selectedForArchive.push({ scrapyApplicationType: item.scrapyApplicationType, scrapySite: item.scrapySite })
                })
                setSelectedRowsStatus(true)
            } else {
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
                selectedItem = []
                selectedForArchive = []
            }
        }
    };

    const handleClose = () => {
        setSelectedRowsStatus(false)
        selectedItem = []
        selectedForArchive = []
        setSelected([])
        selectedIndex = []
    }

    const { scrappys, paginationData } = useSelector(state => ({
        scrappys: state.scrappy.crtvs,
        paginationData: state.scrappy.pagination
    }));

    totalRecords = paginationData ? paginationData.totalRecords ? paginationData.totalRecords : 0 : 0

    const { loading } = useSelector(state => ({
        loading: state.scrappy.loading,
    }));

    const { noData } = useSelector(state => ({
        noData: state.scrappy.noData,
    }));

    const { adUnits } = useSelector(state => ({
        adUnits: state.adUnit.adUs,
    }));

    const { scrappysResponse } = useSelector(state => ({
        scrappysResponse: state.scrappy.response,
    }));

    const { archiveScrappyResponse } = useSelector(state => ({
        archiveScrappyResponse: state.scrappy.archiveMsg,
    }));

    // const { errorMsg, successMsg } = useSelector(state => ({
    //   errorMsg: state.scrappys.errorMsg,
    //   successMsg: state.scrappys.successMsg,
    // }));

    useEffect(() => {
        console.log(loading)
        if (loading) {
            setSwitchLoader(true)
        }

    }, [loading]);

    console.log(scrappysResponse)
    useEffect(() => {
        if (scrappysResponse !== null) {
            if (scrappysResponse.statusCode === 200) {
                toastr.success('Scrappy Status Changed Successfully')
                dispatch(onGetScrappy({ pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch }));
                dispatch(statusScrappySuccess(null));
                selectedItem = []
                selectedForArchive = []
                setSelected([])
                selectedIndex = []
                setSelectedRowsStatus(false)
            }
        }
    }, [dispatch, scrappysResponse]);

    useEffect(() => {
        if (archiveScrappyResponse !== null) {
            if (archiveScrappyResponse.success) {
                let bodyArchive = {
                    userID: userInfo.userID,
                    userRole: userInfo.userRole,
                    companyID: userInfo.companyID,
                    name: userInfo.name,
                    userName: userInfo.name,
                    scrapyIDList: selectedItem
                }

                axios.post("/api/scrapy/archive", bodyArchive,
                    {
                        headers: headers
                    })
                    .then((res) => {
                        console.log("result is Scrapy ==>", res)
                        if (res.data.statusCode === 200) {
                            toastr.success('Scrappy Archived Successfully')
                            dispatch(onGetScrappy({ pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch }));
                            dispatch(archiveScrappySuccess(null));
                            selectedItem = []
                            selectedForArchive = []
                            setSelected([])
                            selectedIndex = []
                            setSelectedRowsStatus(false)
                        }
                    })
                    .catch((err) => {
                        console.log("e", err)
                    });

            }
        }
    }, [dispatch, archiveScrappyResponse]);

    const toggleRightCanvas = () => {
        selectedData = ''
        dispatch(addScrappyFail(""));
        dispatch(updateScrappyFail(""));
        // dispatch(onGetScrappy({ pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch }));
        setIsRight(!isRight);
    };

    const toggleDrawer = (close, scrappy) => (event) => {
        console.log(event)
        if (event === 'Create') {
            toastr.success('Scrappy Saved Successfully!...')
            dispatch(onGetScrappy({ pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch }));
        }
        if (event === 'Update') {
            toastr.success('Scrappy Updated Successfully!...')
            dispatch(onGetScrappy({ pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch }));
        }
        selectedData = scrappy
        setIsArchived(scrappy.IssScrappyArchived)

        dispatch(addScrappyFail(""));
        dispatch(updateScrappyFail(""));
        setIsRight(close);
    };

    useEffect(() => {
        if (scrappys !== null) {
            dispatch(onGetScrappy({ pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch }));
        }
    }, []);


    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const scrappyListColumns = [
        {
            text: "id",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            dataField: "scrapyID",
            text: "ID",
            sort: true,
        },
        {
            dataField: "scrapyModifyTime",
            text: "Modified Date",
            sort: true,
        },
        {
            dataField: "scrapySite",
            text: "Site Name",
            sort: true,
            style: { wordBreak: 'break-word' }
        },
        {
            dataField: "scrapyApplicationType",
            text: "Application Type",
            sort: false,
        },
        {
            dataField: "scrapyDevice",
            text: "Device Type",
            sort: true,
        },
        {
            dataField: "scrappyFinalUrl",
            text: "URL",
            sort: false,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, scrappy) => (
                <>
                {!scrappy.isScrapyArchived && scrappy.isLive && 
                    <h5 className="font-size-12 mb-1">
                    {scrappy.scrappyFinalUrl} &nbsp;&nbsp;
                   <Link to={{ pathname: scrappy.scrappyFinalUrl }} target="_blank" rel="noopener noreferrer" className="color-dark-blue ">
                     <i className="bx bx-link-external me-1" />
                   </Link>
               </h5>

                }
                {!scrappy.isScrapyArchived && !scrappy.isLive && 
                    <h5 className="font-size-12 mb-1">
                      Processing...
               </h5>
                }
                
                </>
            ),
        },
        {
            dataField: "ExpiryOn",
            text: "Expires In",
            sort: false,
        },
        // {
        //     dataField: "ScrappyStatus",
        //     text: "Status",
        //     sort: true,
        //     formatter: (cellContent, scrappy) => (
        //         <>
        //             {scrappy.ScrappyStatus && !scrappy.issScrappyArchived &&
        //                 <span className="active-state">Active</span>
        //             }
        //             {!scrappy.ScrappyStatus && !scrappy.issScrappyArchived &&
        //                 <span className="inactive-state">Inactive</span>
        //             }
        //             {scrappy.issScrappyArchived &&
        //                 <span className="archived-state">Archived</span>
        //             }
        //         </>

        //     ),
        // },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, scrappy) => (
                <div className="d-flex gap-3" >
                    <Link className="Email-state" to="#" onClick={toggleDrawer(true, scrappy)}>
                        {/* {scrappy.issScrappyArchived &&
                          'View'
                        }
                        {!scrappy.issScrappyArchived &&
                             'Edit'
                        } */}
                        Edit
                    </Link>
                </div>
            ),
        },
    ];

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );

    const copyUrl = (URL) => (e) => {
        setpopoverdismiss(!popoverdismiss)
        navigator.clipboard.writeText(URL)
    }


    const pageOptions = {
        paginationSize: 4,
        pageStartIndex: 1,
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };


    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    useEffect(() => {
        console.log(scrappys)
        if (scrappys !== null && scrappys.length > 0) {
            scrappys.map((item, index) => {
                if (item.scrapyModifyTime) {
                    item.ExpiryOn = getExpiryStatus(item.scrapyModifyTime);
                }
            })
        }

    }, [scrappys]);

    const getExpiryStatus = (createTime) => {
        const createDate = moment(createTime, "YYYY-MM-DD");
        const expiryDate = createDate.add(7, "days");
        const daysLeft = expiryDate.diff(moment(), "days");

        if (daysLeft > 0) {
            return `${daysLeft} days`;
        } else {
            return "Expired";
        }
    };

    const archiveScrappy = async () => {
        console.log(selectedForArchive)
        dispatch(onArchiveScrappy({ scrapyIDList: selectedForArchive }));
    }

    const statusScrappy = async (state) => {
        console.log(state)
        dispatch(onStatusScrappy({ scrapyIDList: selectedItem, scrapyStatus: state }));
    }

    console.log(scrappys)

    // if (scrappys.length > 0) {
    //     if (UserRolePrivileges.s && UserRolePrivileges.s.sScrappys) {
    //         if (!UserRolePrivileges.s.sScrappys.archive && !UserRolePrivileges.s.sScrappys.status) {
    //             scrappys.map((item, index) => {
    //                 selectRow.nonSelectable.push(index + 1)
    //             })
    //         } else {
    //             scrappys.map((item, index) => {
    //                 if (item.IssScrappyArchived) {
    //                     selectRow.nonSelectable.push(index + 1)
    //                 }
    //             })
    //         }
    //     }
    // }

    const paginationChange = async (selectedPages) => {
        console.log(selectedPages)
        pageSize = selectedPages.pageSize
        pageNumber = selectedPages.pageNumber
        dispatch(onGetScrappy({ pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText, filter: statusSearch }));

    }



    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
            <div className="page-content">
                <Helmet>
                    <title>Scrappy Tool | Bidsxchange</title>
                </Helmet>
                <Container fluid={true}>
                    <div className=" d-sm-flex  justify-content-between">
                        <Breadcrumbs title="Custom Tracking" breadcrumbItem="Scrappy Tool" />
                        <div className=" text-sm-end">
                            <div className="btn-group">
                                <Dropdown
                                    direction="left"
                                    isOpen={isMenu}
                                    toggle={toggleMenu}
                                >
                                    <DropdownToggle
                                        tag="button"
                                        className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                                    >
                                        <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                                        {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                                            Filter Applied
                                        </span> : <span className="d-none font-16 d-sm-inline-block">
                                            Filter
                                        </span>
                                        }
                                        {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px',color:'red' }}>{filterCount}</span>
                              } */}
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                                        <div className="dropdown-item-text">
                                            <div className="row">
                                                <h5 className="mb-0">Filters</h5>
                                            </div>
                                        </div>

                                        <DropdownItem divider />
                                        <Card>
                                            <CardBody aria-hidden="true">
                                                <Row >
                                                    <Col sm="12" className="mt-2">
                                                        <Label className="form-label">Status</Label>
                                                        <div className=" text-sm-end">
                                                            <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                                                <option value=''> All</option>
                                                                <option value='Active' > Active</option>
                                                                <option value='Inactive'> InActive</option>
                                                                <option value='Archived'> Archived</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        <DropdownItem divider />
                                        <Row>
                                            <Col sm="6">
                                                <DropdownItem
                                                    className="text-primary text-center"
                                                    onClick={clearAllFilter}
                                                >
                                                    Clear ALL
                                                </DropdownItem>
                                            </Col>
                                            <Col sm="6">
                                                <DropdownItem
                                                    className="text-primary text-center"
                                                    onClick={applyFilter}
                                                >
                                                    Apply Filter
                                                </DropdownItem>
                                            </Col>
                                        </Row>
                                    </DropdownMenu>
                                </Dropdown>
                            </div> &nbsp;&nbsp;
                            <Button
                                color="primary"
                                className="font-16 btn-block btn btn-primary"
                                onClick={toggleRightCanvas}
                            >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                Add Scrappy
                            </Button>
                        </div>
                    </div>
                    {/* {switchLoader &&
                        <LoadingTableContextual loading={loading} targetLoading={handleSwitch} />
                    }
                    {!switchLoader &&
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <ToolkitProvider
                                            keyField={keyField}
                                            data={scrappys}
                                            columns={scrappyListColumns}
                                            // bootstrap4
                                            search
                                        >
                                            {toolkitProps => (
                                                <React.Fragment>
                                                    <Row className="mb-2">
                                                        <Col sm="4">
                                                            <div className="search-box ms-2 mb-2 d-inline-block">
                                                                <div className="position-relative">
                                                                    <SearchBar {...toolkitProps.searchProps} />
                                                                    <i className="bx bx-search-alt search-icon" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col sm="4"></Col>
                                                        <Col sm="4" className="date-range ">
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl="12">
                                                            {selectedRowsStatus && selectedIndex.length > 0 &&
                                                                <div className="row m-0 React-Table-Selection">
                                                                    <div style={{ padding: '0.5% 2%' }}>
                                                                        <button color="primary" className="btn btn-primary " onClick={statusScrappy(true)}>
                                                                            Activate
                                                                        </button>
                                                                        &nbsp;  &nbsp;
                                                                        <button color="primary" className="btn btn-primary " onClick={statusScrappy(false)}>
                                                                            DeActivate
                                                                        </button>
                                                                        &nbsp;  &nbsp;
                                                                        <Button color="primary" className="btn btn-primary " onClick={archiveScrappy} >
                                                                            Archive
                                                                        </Button>
                                                                        <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                                                            className="mdi mdi-close-thick font-size-18"
                                                                        ></i></Button>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col xl="12" className="table-responsive">
                                                            <div className="">
                                                                <BootstrapTable
                                                                    keyField={keyField}
                                                                    responsive
                                                                    bordered={false}
                                                                    striped={false}
                                                                    defaultSorted={defaultSorted}
                                                                    selectRow={selectRow}
                                                                    classes={
                                                                        "table align-middle table-nowrap"
                                                                    }
                                                                    headerWrapperClasses={"thead-light"}
                                                                    {...toolkitProps.baseProps}
                                                                    pagination={paginationFactory(pageOptions)}
                                                                    noDataIndication={<h5 className="text-center">No Data</h5>} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                            )}
                                        </ToolkitProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    } */}

                    <ProductDataTableLI statusAccess={true}
                        archiveAccess={true} loading={loading}
                        data={scrappys} columns={scrappyListColumns} statusChange={statusScrappy} archiveLineItem={archiveScrappy}
                        rowSelection={selectRow} handleClose={handleClose}
                        selectedIndex={selectedIndex} paginationSelection={paginationChange}
                        totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize} />

                </Container>
            </div>
            {/* </div> */}
            <Offcanvas isOpen={isRight} direction='end'  >
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {'Add Scrappy'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <NewScrappy closeCanvas={toggleDrawer(false, '')} selectedScrappy={selectedData} selectedArchived={isArchived} />
                </OffcanvasBody>
            </Offcanvas>
        </React.Fragment >
    )
}

ScrappyTool.propTypes = {}

export default ScrappyTool