import {
  LOAD_ACTIVITIES,
  NO_DATA,
    GET_ADUNITGROUPS,
    GET_ADUNITGROUPS_FAIL,
    GET_ADUNITGROUPS_SUCCESS,
    ADD_ADUNITGROUP,
    ADD_ADUNITGROUP_SUCCESS,
    ADD_ADUNITGROUP_ERROR,
    UPDATE_ADUNITGROUP,
    UPDATE_ADUNITGROUP_SUCCESS,
    UPDATE_ADUNITGROUP_ERROR,
    READ_ADUNITGROUP,
    READ_ADUNITGROUP_SUCCESS,
    STATUS_ADUNITGROUP,
    STATUS_ADUNITGROUP_SUCCESS,
    ARCHIVE_ADUNITGROUP ,
    ARCHIVE_ADUNITGROUP_SUCCESS

  } from "./actionTypes"

  export const loadActivitiesAdUnitGroup = Params => ({
    type: LOAD_ACTIVITIES,
    payload:Params
  })

  export const noDataAdUnitGroup = Params => ({
    type: NO_DATA,
    payload:Params
  })

  export const getAdUnitGroup = Params => ({
    type: GET_ADUNITGROUPS,
    payload:Params
  })
  
  export const getAdUnitGroupsSuccess = AdUnitGroups => ({
    type: GET_ADUNITGROUPS_SUCCESS,
    payload: AdUnitGroups,
  })

  export const getAdUnitGroupsFail = error => ({
    type: GET_ADUNITGROUPS_FAIL,
    payload: error,
  })

  export const addNewAdUnitGroup = AdUnitGroup => ({
    type: ADD_ADUNITGROUP,
    payload: AdUnitGroup,
  })
  
  export const addAdUnitGroupSuccess = adUnitGroup => ({
    type: ADD_ADUNITGROUP_SUCCESS,
    payload: adUnitGroup,
  })
  
  export const addAdUnitGroupFail = adUnitGroup => ({
    type: ADD_ADUNITGROUP_ERROR,
    payload: adUnitGroup,
  })

  export const updateAdUnitGroup = adUnitGroup => ({
    type: UPDATE_ADUNITGROUP,
    payload: adUnitGroup,
  })
  
  export const updateAdUnitGroupSuccess = adUnitGroup => ({
    type: UPDATE_ADUNITGROUP_SUCCESS,
    payload: adUnitGroup,
  })
  
  export const updateAdUnitGroupFail = adUnitGroup => ({
    type: UPDATE_ADUNITGROUP_ERROR,
    payload: adUnitGroup,
  })

  export const readAdUnitGroup = adUnitGroup => ({
    type: READ_ADUNITGROUP,
    payload: adUnitGroup,
  })

  export const readAdUnitGroupSuccess = adUnitGroup => ({
    type: READ_ADUNITGROUP_SUCCESS,
    payload: adUnitGroup,
  })

  export const statusAdUnitGroup = adUnitGroup => ({
    type: STATUS_ADUNITGROUP,
    payload: adUnitGroup,
  })

  export const statusAdUnitGroupSuccess = adUnitGroup => ({
    type: STATUS_ADUNITGROUP_SUCCESS,
    payload: adUnitGroup,
  })

  export const archiveAdUnitGroup = adUnitGroup => ({
    type: ARCHIVE_ADUNITGROUP,
    payload: adUnitGroup,
  })

  export const archiveAdUnitGroupSuccess = adUnitGroup => ({
    type: ARCHIVE_ADUNITGROUP_SUCCESS,
    payload: adUnitGroup,
  })