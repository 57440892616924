/* ADUNIT */
export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES"
export const NO_DATA = "NO_DATA"
export const GET_ADUNITS = "GET_ADUNITS"
export const GET_ADUNITS_SUCCESS = "GET_ADUNITS_SUCCESS"
export const GET_ADUNITS_FAIL = "GET_ADUNITS_FAIL"

export const GET_ADUNITS_TC = "GET_ADUNITS_TC"
export const GET_ADUNITS_TC_SUCCESS = "GET_ADUNITS_TC_SUCCESS"
export const GET_ADUNITS_TC_FAIL = "GET_ADUNITS_TC_FAIL"

export const GET_ADUNITS_HIS = "GET_ADUNITS_HIS"
export const GET_ADUNITS_HIS_SUCCESS = "GET_ADUNITS_HIS_SUCCESS"
export const GET_ADUNITS_HIS_FAIL = "GET_ADUNITS_HIS_FAIL"

export const ADD_ADUNIT = "ADD_ADUNIT"
export const ADD_ADUNIT_SUCCESS = "ADD_ADUNIT_SUCCESS"
export const ADD_ADUNIT_ERROR = "ADD_ADUNIT_ERROR"

export const UPDATE_ADUNIT = "UPDATE_ADUNIT"
export const UPDATE_ADUNIT_SUCCESS = "UPDATE_ADUNIT_SUCCESS"
export const UPDATE_ADUNIT_ERROR = "UPDATE_ADUNIT_ERROR"

export const READ_ADUNIT = "READ_ADUNIT"
export const READ_ADUNIT_SUCCESS = "READ_ADUNIT_SUCCESS"

export const STATUS_ADUNIT = "STATUS_ADUNIT"
export const STATUS_ADUNIT_SUCCESS = "STATUS_ADUNIT_SUCCESS"

export const ARCHIVE_ADUNIT = "ARCHIVE_ADUNIT"
export const ARCHIVE_ADUNIT_SUCCESS = "ARCHIVE_ADUNIT_SUCCESS"