import React from "react"
import { Col } from "reactstrap"
import './cursor.css'

const CarouselPage = () => {
  return (
    <React.Fragment>
      <Col md={6}>
        <div className="auth-full-bg" style={{ background: "#00040E" }}>
          <div className="text-center">
            <div dir="ltr" className="sphere">
              <h3 className="mb-3" style={{ letterSpacing: '6px', color: '#ffffff', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>BIDSXCHANGE</h3>
              <video autoPlay loop muted style={{ width: '100%', display: 'block', height: '100vh', backgroundColor: 'black' }} data-src="https://cdn.dribbble.com/users/2404738/screenshots/15352121/media/26533de814882b5e2428401383c8c792.mp4" data-video-small="https://cdn.dribbble.com/users/2404738/screenshots/15352121/media/55bfa16eb2e75afcd6c3e4f38f233d2b.mp4" data-video-medium="https://cdn.dribbble.com/users/2404738/screenshots/15352121/media/b855328938e2cc2e4b1aca11d707a670.mp4" data-video-large="https://cdn.dribbble.com/users/2404738/screenshots/15352121/media/26533de814882b5e2428401383c8c792.mp4" src="https://cdn.dribbble.com/users/2404738/screenshots/15352121/media/26533de814882b5e2428401383c8c792.mp4"></video>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default CarouselPage
