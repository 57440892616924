/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, useCallback } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Card,
    CardBody,
    Col,
    Row, Button
} from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';
import { withRouter, Link } from "react-router-dom";

import {
    getRoles as onGetRoles,
} from "store/RoleManagement/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import LoadingTable from "pages/LoaderPages/LoadingTable";

import {
    getNetworkBlockings as onGetBlockingData,
    statusNetworkBlocking as onStatusNetworkBlocking,
    archiveNetworkBlocking as onArchiveNetworkBlocking,
    statusNetworkBlockingSuccess,
    archiveNetworkBlockingSuccess,
} from "store/Contextual_Settings/NetBlocking/actions";

import UploadBlockings from "./UploadBlockings";
import LoadingTableContextual from "pages/LoaderPages/LoadingTableContextual";

var selectedItem = []
var selectedData = ''
var selectedIndex = []


function IPV4(props) {
    const dispatch = useDispatch();
    const [statusSearch, setStatusSearch] = useState('Active')

    const [switchLoader, setSwitchLoader] = useState(true)
    const [roles, setRoles] = useState([])
    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
    const [selected, setSelected] = useState([])
    const [isRight, setIsRight] = useState(false);
    const userInfo = JSON.parse(localStorage.getItem('authUser'));

    const handleSwitch = () => {
        setSwitchLoader(!switchLoader)
    }

    console.log(props)

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 5000,
        extendedTimeOut: 1000
    };

    const selectRow = {
        mode: "checkbox",
        nonSelectable: [],
        selected: selected,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                setSelected([...selected, row.id])
                setSelectedRowsStatus(true)
                selectedIndex.push(row.iPV4ControlID)
                selectedItem.push(row.iPV4ControlID)
            } else {
                setSelected(selected.filter(x => x !== row.id))
                let index = selectedIndex.indexOf(row.iPV4ControlID)
                selectedIndex.splice(index, 1)
                let index2 = selectedItem.indexOf(row.iPV4ControlID)
                selectedItem.splice(index2, 1)
                if (selectedIndex.length === 0) {
                    setSelectedRowsStatus(false)
                }
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            const ids = rows.map(r => r.id);
            if (isSelect) {
                setSelected(ids)
                rows.map((item, index) => {
                    selectedIndex.push(item.iPV4ControlID)
                    selectedItem.push(item.iPV4ControlID)
                })
                setSelectedRowsStatus(true)
            } else {
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
                selectedItem = []
            }
        }
    };

    const handleClose = () => {
        setSelectedRowsStatus(false)
        selectedItem = []
        setSelected([])
        selectedIndex = []
    }


    const { networkBlocks } = useSelector(state => ({
        networkBlocks: state.netBlockings.blocks,
    }));

    const { loading } = useSelector(state => ({
        loading: state.netBlockings.loading,
    }));

    //   useEffect(() => {
    //     if (networkBlocks !== null && props.activeId === '2') {
    //       dispatch(onGetBlockingData({isSupplyPartner:userInfo.isSupplyPartner,type:'ipv4'}));
    //     }
    //   }, []);

    useEffect(() => {
        if (props.activeId === '5') {
            dispatch(onGetBlockingData({ isSupplyPartner:userInfo.isSupplyPartner,type: 'ipv4' }));
        }
    }, [props.activeId]);

    useEffect(() => {
        if (loading) {
            setSwitchLoader(true)
        }
    }, [loading]);

    // useEffect(() => {
    //     if (props.networkBlockData.deviceControl && props.networkBlockData.deviceControl.length > 0) {
    //         props.networkBlockData.deviceControl.map((item, index) => {
    //             item.id = index + 1
    //         })
    //         setRoles(props.networkBlockData.deviceControl)
    //     }

    // }, [props.networkBlockData]);

    const { netBlockingResponse } = useSelector(state => ({
        netBlockingResponse: state.netBlockings.response,
    }));

    const { archiveNetworkBlockingsResponse } = useSelector(state => ({
        archiveNetworkBlockingsResponse: state.netBlockings.archiveMsg,
    }));

    useEffect(() => {
        if (netBlockingResponse !== null && props.activeId === '5') {
            if (netBlockingResponse.success) {
                toastr.success('IPV4 Status Changed Successfully')
                dispatch(onGetBlockingData({ isSupplyPartner:userInfo.isSupplyPartner,type: 'ipv4' }));
                // dispatch(statusNetworkBlockingSuccess(null));
                selectedItem = []
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []

            }
        }
    }, [dispatch, netBlockingResponse]);

    useEffect(() => {
        if (archiveNetworkBlockingsResponse !== null && props.activeId === '5') {
            if (archiveNetworkBlockingsResponse.success) {
                toastr.success('IPV4 Archived Successfully')
                dispatch(onGetBlockingData({ isSupplyPartner:userInfo.isSupplyPartner,type: 'ipv4' }));
                // dispatch(archiveNetworkBlockingSuccess(null));
                selectedItem = []
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []

            }
        }
    }, [dispatch, archiveNetworkBlockingsResponse]);

    const toggleRightCanvas = () => {
        selectedData = ''
        setIsRight(!isRight);
    };

    const toggleDrawer = (close, deviceControl) => (event) => {
        console.log(event)
        if (event === 'Create') {
            toastr.success('IPV4 Saved Successfully!...')
        }
        if (event === 'Update') {
            toastr.success('IPV4 Updated Successfully!...')
        }
        dispatch(onGetBlockingData({ isSupplyPartner:userInfo.isSupplyPartner,type: 'ipv4' }));
        selectedData = deviceControl
        setIsRight(close);
    };

    const keyField = "id";

    const roleListColumns = [
        {
            text: "ID",
            headerClasses: "ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            text: "ID",
            headerClasses: "ID",
            dataField: "iPV4ControlID",
            sort: true,
        },
        {
            text: "Group Name",
            dataField: "iPV4ControlGroup",
            sort: true,
        },
        {
            dataField: "iPV4ControlStatus",
            text: "Status",
            sort: false,
            formatter: (cellContent, iPV4Control) => (
                <>
                    {iPV4Control.iPV4ControlStatus &&
                        <span className="active-state">Active</span>
                    }
                    {!iPV4Control.iPV4ControlStatus &&
                        <span className="inactive-state">Inactive</span>
                    }
                </>
            ),
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',

            // eslint-disable-next-line react/display-name
            formatter: (cellContent, deviceControl) => (
                <div className="d-flex gap-3" >
                    <Link className="Email-state" to="#" onClick={toggleDrawer(true, deviceControl)}>
                    Edit
                    </Link>
                </div>
            ),
        },
    ];

    const archiveNetworkBlocking = async () => {
        dispatch(onArchiveNetworkBlocking({ idList: selectedItem, isRead: "notRead", isSupplyPartner:userInfo.isSupplyPartner,type: 'ipv4' }));
    }

    const statusNetworkBlocking = (state) => (event) => {
        dispatch(onStatusNetworkBlocking({ idList: selectedItem, iPV4ControlStatus: state, isRead: "notRead", isSupplyPartner:userInfo.isSupplyPartner,type: 'ipv4' }));
    }


    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );


    const pageOptions = {
        paginationSize: 4,
        pageStartIndex: 1,
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };
    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "asc", // desc or asc
        },
    ];


    return (
        <Row>
            {switchLoader &&
                <LoadingTableContextual loading={loading} targetLoading={handleSwitch} />
            }
            {!switchLoader &&
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <ToolkitProvider
                                    keyField={keyField}
                                    data={networkBlocks}
                                    columns={roleListColumns}
                                    // bootstrap4
                                    search
                                >
                                    {toolkitProps => (
                                        <React.Fragment>
                                            <Row className="mb-2">
                                                <Col sm="4">
                                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                                        <div className="position-relative">
                                                            <SearchBar {...toolkitProps.searchProps} />
                                                            <i className="bx bx-search-alt search-icon" />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm="4"></Col>
                                                <Col sm="4" className="date-range ">
                                                    <div className="text-sm-end">
                                                        <Button
                                                            color="primary"
                                                            className="font-16 btn-block btn btn-primary"
                                                            onClick={toggleRightCanvas}
                                                        >
                                                            <i className="mdi mdi-plus-circle-outline me-1" />
                                                            Add IPV4s
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl="12">
                                                    {selectedRowsStatus && selectedIndex.length > 0 &&
                                                        <div className="row m-0 React-Table-Selection">
                                                            <div style={{ padding: '0.5% 2%' }}>
                                                                <button color="primary" className="btn btn-primary " onClick={statusNetworkBlocking(true)}>
                                                                    Activate
                                                                </button>
                                                                &nbsp;  &nbsp;
                                                                <button color="primary" className="btn btn-primary " onClick={statusNetworkBlocking(false)}>
                                                                    DeActivate
                                                                </button>
                                                                &nbsp;  &nbsp;
                                                                <Button color="primary" className="btn btn-primary " onClick={archiveNetworkBlocking} >
                                                                    Archive
                                                                </Button>
                                                                <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                                                    className="mdi mdi-close-thick font-size-18"
                                                                ></i></Button>
                                                            </div>
                                                        </div>
                                                    }
                                                </Col>
                                                <Col xl="12" className="table-responsive">
                                                    <div >
                                                        <BootstrapTable
                                                            keyField={keyField}
                                                            responsive
                                                            bordered={false}
                                                            striped={false}
                                                            defaultSorted={defaultSorted}
                                                            selectRow={selectRow}
                                                            classes={
                                                                "table align-middle table-nowrap"
                                                            }
                                                            headerWrapperClasses={"thead-light"}
                                                            {...toolkitProps.baseProps}
                                                            pagination={paginationFactory(pageOptions)}
                                                            noDataIndication={<h5 className="text-center">No Data</h5>}
                                                        // ref={node}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    )}
                                </ToolkitProvider>
                            </CardBody>
                        </Card>
                    </Col>
                    <Offcanvas isOpen={isRight} direction='end'  >
                        <OffcanvasHeader toggle={toggleRightCanvas}>
                            {selectedData ? 'Edit IPV4 Upload ' : 'New IPV4 Upload '}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">
                            <UploadBlockings closeCanvas={toggleDrawer(false, '')} selectedData={selectedData} networkBlockType="ipv4" />
                        </OffcanvasBody>
                    </Offcanvas>
                </Row>
            }
        </Row >
    )
}

IPV4.propTypes = {}

export default IPV4
