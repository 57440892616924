import {
  LOAD_ACTIVITIES,
  NO_DATA_LOGS,
    GET_LOGS,
    GET_LOGS_FAIL,
    GET_LOGS_SUCCESS,

    GET_LOGS_MASTER,
    GET_LOGS_SUCCESS_MASTER,
    GET_LOGS_FAIL_MASTER

  } from "./actionTypes"

  export const loadActivitiesLogs = Params => ({
    type: LOAD_ACTIVITIES,
    payload:Params
  })

  export const noLogs = Params => ({
    type: NO_DATA_LOGS,
    payload:Params
  })

  export const getLogs = Params => ({
    type: GET_LOGS,
    payload:Params
  })
  
  export const getLogsSuccess = logs => ({
    type: GET_LOGS_SUCCESS,
    payload: logs,
  })

  export const getLogsFail = error => ({
    type: GET_LOGS_FAIL,
    payload: error,
  })

  export const getLogsMaster = Params => ({
    type: GET_LOGS_MASTER,
    payload:Params
  })
  
  export const getLogsMasterSuccess = logs => ({
    type: GET_LOGS_SUCCESS_MASTER,
    payload: logs,
  })

  export const getLogsMasterFail = error => ({
    type: GET_LOGS_FAIL_MASTER,
    payload: error,
  })
