/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Spinner, InputGroup, Tooltip } from "reactstrap";
import PropTypes from "prop-types";
import axios from "axios";
import { withRouter, Link } from "react-router-dom";

//redux
import { useSelector, useDispatch } from "react-redux";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// action
import { userForgetPassword, userForgetPasswordSuccess, userForgetPasswordError } from "store/actions";

var loading = Boolean(false)

const User_Info = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  // const [partner, setPartner] = useState({
  //   password: ''
  // });
  const [isEdit, setIsEdit] = useState(false);
  const [regionList, setRegionList] = useState();
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedCountry, setSelectedCountry] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [form10F, setForm10F] = useState('')
  const [NPE, setNPE] = useState('')
  const [TRC, setTRC] = useState('')
  const [loader, setLoader] = useState(false);
  const [forcePassword, setForcePassword] = useState(false);

  const [videosList, setVideosList] = useState([
    { id: 1, userName: userInfo.name, userEmail: userInfo.username, confirmEmail: userInfo.username, password: '', forcePassword: true }
  ])
  const addDirectVideoContent = () => {
    let videoContentList = [...videosList]
    videoContentList.push({
      id: videoContentList.length + 1,
      userName: '',
      userEmail: '',
      confirmEmail: '',
      password: generatePassword(),
      forcePassword: true
    })
    setVideosList([...videoContentList])
  }

  function generatePassword() {

    var keyListAlpha = "qwertyuiopasdfghjklzxcvbnm",
      keyListAlphaUpper = "QWERTYUIOPASDFGHJKLZXCVBNM",
      keyListInt = "1234567890",
      keyListSpec = "!@#$%^&*",
      password = '';
    var len = Math.ceil(7 / 2);
    len = len - 1;
    var lenSpec = 7 - 2 * len;

    for (let i = 0; i < len; i++) {
      password += keyListAlpha.charAt(Math.floor(Math.random() * keyListAlpha.length));
      password += keyListAlphaUpper.charAt(Math.floor(Math.random() * keyListAlphaUpper.length));
      password += keyListInt.charAt(Math.floor(Math.random() * keyListInt.length));
    }

    for (let i = 0; i < lenSpec; i++)
      password += keyListSpec.charAt(Math.floor(Math.random() * keyListSpec.length));

    password = password.split('').sort(function () { return 0.5 - Math.random() }).join('');

    return password;
  }


  const handleChangeUser = (index) => e => {
    const { name, value } = e.target;
    let videoContentList = [...videosList]
    videoContentList[index] = { ...videoContentList[index], [name]: value };
    setVideosList([...videoContentList])
  }

  const handleChangeUserForce = (index) => e => {
    const { name, value } = e.target;
    let videoContentList = [...videosList]
    videoContentList[index] = { ...videoContentList[index], [name]: e.target.checked };
    setVideosList([...videoContentList])
  }

  const reGeneratePasswordUser = (index) => e => {
    let videoContentList = [...videosList]
    videoContentList[index] = { ...videoContentList[index], password: generatePassword() };
    setVideosList([...videoContentList])
  }

  const removeDirectContent = (i) => e => {
    let videoContentList = [...videosList]
    videoContentList.splice(i, 1)
    setVideosList([...videoContentList])
  }

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const onToggleTooltip = (e) => {
    setIsTooltipOpen(!isTooltipOpen)
  }

  const copyTag = (tag) => e => {
    navigator.clipboard.writeText(tag)
  }


  const createContentUI = () => {
    return videosList.map((item, i) =>
      <div key={i}>
        <div className="contentSettings">
          {i === 0 ? <h5 className='mt-3 mb-4'>Current User</h5> : <h5 className='mt-3 mb-4'>User {i}</h5>}
          <div className="row mt-1">
            <div className="col-10">
              <div className="mb-3">
                <Label className="form-label">User Name <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Input
                  name="userName"
                  onChange={handleChangeUser(i)}
                  value={item.userName}
                  className={'form-control' + (submitted && !item.userName ? ' is-invalid' : '')}
                  readOnly={i === 0}
                />
                {submitted && !item.userName && (
                  <FormFeedback type="invalid">{'User Name is required'}</FormFeedback>
                )}
              </div>
              <div className="mb-3">
                <Label className="form-label">User Email <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Input
                  name="userEmail"
                  type="email"
                  onChange={handleChangeUser(i)}
                  value={item.userEmail}
                  className={'form-control' + (submitted && !item.userEmail ? ' is-invalid' : '')}
                  readOnly={i === 0}
                />
                {submitted && !user.userEmail && (
                  <FormFeedback type="invalid">{'User Email is required'}</FormFeedback>
                )}
              </div>
              {i !== 0 &&
                <div className="mb-3">
                  <Label className="form-label">Confirm User Email <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                  <Input
                    name="confirmEmail"
                    type="email"
                    onChange={handleChangeUser(i)}
                    value={item.confirmEmail}
                    className={'form-control' + (submitted && !item.confirmEmail ? ' is-invalid' : '')}
                  />
                  {submitted && !item.confirmEmail && (
                    <FormFeedback type="invalid">{'Confirm Email is required'}</FormFeedback>
                  )}
                  {submitted && !(item.confirmEmail === item.userEmail) && (
                    <FormFeedback type="invalid">{'Confirm Email is must match with User Email'}</FormFeedback>
                  )}
                </div>
              }
              {i !== 0 &&
                <div className="mb-3">
                  <Label className="form-label">User Password <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                  <div className="row">
                    <div className="col-9">
                      <InputGroup>
                        <Input
                          name="password"
                          type="text"
                          value={item.password}
                          readOnly
                        />
                        <div className="input-group-text">
                          <span className=" " id="controlledTooltip" onClick={copyTag(item.password)} onMouseLeave={() => {
                            setIsTooltipOpen(false)
                          }} style={{ cursor: 'pointer' }}>
                            <i
                              className="bx bx-copy-alt font-size-18"
                              id="edittooltip"
                            ></i>
                          </span>
                        </div>
                      </InputGroup>
                      <Tooltip
                        isOpen={isTooltipOpen}
                        placement="top"
                        target="controlledTooltip"
                        toggle={onToggleTooltip}
                        trigger="click"
                      >
                        Copied
                      </Tooltip>

                    </div>
                    <div className="col-3">
                      <button
                        type="button"
                        className="btn btn-outline-primary w-100"
                        // eslint-disable-next-line react/prop-types
                        onClick={reGeneratePasswordUser(i)}
                      >
                        Generate Password
                      </button>
                    </div>
                  </div>
                </div>
              }
              {i !== 0 &&
                <div className="mb-3">
                  <div className="form-check mt-2 mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="forcePassword"
                      checked={item.forcePassword}
                      onChange={handleChangeUserForce(i)}
                      id="forceUserToChange"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="forceUserToChange"
                    >
                      Force user(s) to change password on login
                    </label>
                  </div>
                </div>
              }
              <br />
            </div>
            <div className="col-2 p-0">
              {i !== 0 &&
                <button type='button' className='btn btn-danger  ' style={{ position: 'absolute', background: '#dc3545', right: '0' }} onClick={removeDirectContent(i)} disabled={videosList.length === 1}
                >Remove User</button>
              }
            </div>
          </div>
        </div>
      </div>

    )

  }


  return (
    <div>
      <div className="container-fluid">
        {createContentUI()}
        <div className='col-12'>
          <div className='d-flex'>
            {videosList.length <= 5 &&
              <button type='button' className='mt-4 mb-4 btn btn-primary ml-auto' style={{ marginRight: '1rem  2.5rem 1rem auto' }} onClick={addDirectVideoContent} disabled={videosList.length === 5}>Add User &nbsp;&nbsp; <i className="fa fa-plus"></i></button>
            }
          </div>
        </div>
      </div>
    </div>

  )
}

User_Info.propTypes = {}

export default User_Info
