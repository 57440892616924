import React from 'react'
import PropTypes from 'prop-types'
import {
    Col, Container, Row, Input, Label, Form, Button,
    Card,
    CardBody,
    CardTitle,
    CardImg,
    CardText, Spinner
} from "reactstrap"

import OTTApps from "assets/images/bidsxchange/Adformat_Images/ott-1.png"
import videoStories from "assets/images/bidsxchange/Adformat_Images/stories-1.png"
import contextualVideo from "assets/images/bidsxchange/Adformat_Images/context-video-1.png"
import adPushDown from "assets/images/bidsxchange/Adformat_Images/ad-push-down-1.png"
import pagePushDown from "assets/images/bidsxchange/Adformat_Images/pagepush-down.png"
import interstitial from "assets/images/bidsxchange/Adformat_Images/inter.png"
import parallax from "assets/images/bidsxchange/Adformat_Images/parallax.png"
import sliderAds from "assets/images/bidsxchange/Adformat_Images/slider.png"
import towerAds from "assets/images/bidsxchange/Adformat_Images/tower.png"
import stayOn from "assets/images/bidsxchange/Adformat_Images/Stayon-1.png"
import inContent from "assets/images/bidsxchange/Adformat_Images/incontent.png"
import audio from "assets/images/bidsxchange/Adformat_Images/audio-flash-1.png"
import stdNativeUnits from "assets/images/bidsxchange/Adformat_Images/native-1.png"
import underImage from "assets/images/bidsxchange/Adformat_Images/underimage.png"
import inImage from "assets/images/bidsxchange/Adformat_Images/in-image-2.png"

var explore = [
    {
        "adFromat": "OTT apps",
        imgSrc: OTTApps,
    },
    {
        "adFromat": "Video Stories",
        imgSrc: videoStories,
    },
    {
        "adFromat": "Contextual Video",
        imgSrc: contextualVideo,
    },
    {
        "adFromat": "Ad Push Down",
        imgSrc: adPushDown,
    },
    {
        "adFromat": "Page Push Down",
        imgSrc: pagePushDown,
    },
    {
        "adFromat": "Interstitial",
        imgSrc: interstitial,
    },
    {
        "adFromat": "Parallax",
        imgSrc: parallax,
    },
    {
        "adFromat": "Slider Ads",
        imgSrc: sliderAds,
    },
    {
        "adFromat": "Tower Ads",
        imgSrc: towerAds,
    },
    {
        "adFromat": "Stay On",
        imgSrc: stayOn,
    },
    {
        "adFromat": "In-Content",
        imgSrc: inContent,
    },
    {
        "adFromat": "Audio (Beta)",
        imgSrc: audio,
    },
    {
        "adFromat": "Standard Native Units",
        imgSrc: stdNativeUnits,
    },
    {
        "adFromat": "Under Image",
        imgSrc: underImage,
    },
    {
        "adFromat": "In-Image",
        imgSrc: inImage,
    },
]

const Agency_Cards_Loading = props => {

    return (
        <div className="row mt-4">
            {
                explore.map((subItem, i) => {
                    return (
                        <Col mg={4} xl={4} xxl={3} key={i}>
                            <Card style={{ height: '28pc' }}>
                                <CardImg top className="img-fluid" src={subItem.imgSrc} alt={subItem.adFromat} />
                                <div className="card-body">
                                    <h5 className="card-title placeholder-glow">
                                        <span className="placeholder col-6"></span>
                                    </h5>
                                    <p className="card-text placeholder-glow">
                                        <span className="placeholder col-7"></span>
                                        <span className="placeholder col-4"></span>
                                        <span className="placeholder col-4"></span>
                                        <span className="placeholder col-6"></span>
                                        <span className="placeholder col-8"></span>
                                    </p>
                                    <CardText>
                                        <div className="hstack gap-2 justify-content-center" style={{ position: 'absolute', bottom: '5px', left: '0', right: '0' }}>
                                            <a tabIndex="-1" className="btn btn-primary justify-content-center disabled placeholder col-6" href="/ui-placeholders"></a>
                                        </div>
                                    </CardText>
                                </div>

                            </Card>
                        </Col>
                    )
                })
            }
        </div>
    )
}

Agency_Cards_Loading.propTypes = {}

export default Agency_Cards_Loading