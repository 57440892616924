import {
    LOAD_IPMAPS,
    NO_DATA_IPMAPS,
    GET_IPMAPS_SUCCESS,
    GET_IPMAPS_FAIL,
    ADD_IPMAP_SUCCESS,
    ADD_IPMAP_ERROR,
    UPDATE_IPMAP_SUCCESS,
    UPDATE_IPMAP_ERROR,
    READ_IPMAP_SUCCESS,
    STATUS_IPMAP_SUCCESS,
    ARCHIVE_IPMAP_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    iPMappingList: [],
    error: {},
    loading:false,
    noData:false,
    successMsg: null,
    errorMsg: null,
    iPMapping: null,
    response:null,
    archiveMsg:null,
}

const iPMappings = (state = INIT_STATE, action) => {
    
    switch (action.type) {
        case LOAD_IPMAPS:
            return {
                ...state,
                loading: true,
            }
            case NO_DATA_IPMAPS:
                return {
                    ...state,
                    noData: true,
                }
        case GET_IPMAPS_SUCCESS:
            return {
                ...state,
                loading: false,
                iPMappingList: action.payload,
            }

        case GET_IPMAPS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case ADD_IPMAP_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_IPMAP_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_IPMAPS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_IPMAP_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_IPMAPS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_IPMAP_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_IPMAPS_SUCCESS' ? null : action.payload,
            }
        case READ_IPMAP_SUCCESS:
            return {
                ...state,
                iPMapping: action.payload,
            }
        case STATUS_IPMAP_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_IPMAP_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default iPMappings