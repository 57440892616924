import {
    LOAD_SUPPORT_TICKET_ADMINS,
    NO_DATA_SUPPORT_TICKET_ADMINS,
    GET_SUPPORT_TICKET_ADMINS_SUCCESS,
    GET_SUPPORT_TICKET_ADMINS_FAIL,
    ADD_CHAT_ADMIN_SUCCESS,
    ADD_CHAT_ADMIN_ERROR,
    STATUS_CHAT_ADMIN_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
    supportTicketAdminList: [],
    error: {},
    loading:false,
    noData:false,
    successMsg: null,
    errorMsg: null,
    supportTicketAdmin: null,
    response:null,
    archiveMsg:null,
}

const supportTicketAdmins = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_SUPPORT_TICKET_ADMINS:
            return {
                ...state,
                loading: true,
            }
            case NO_DATA_SUPPORT_TICKET_ADMINS:
                return {
                    ...state,
                    noData: true,
                }
        case GET_SUPPORT_TICKET_ADMINS_SUCCESS:
            return {
                ...state,
                loading: false,
                supportTicketAdminList: action.payload,
            }

        case GET_SUPPORT_TICKET_ADMINS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case ADD_CHAT_ADMIN_SUCCESS:
            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_CHAT_ADMIN_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg:  action.payload,
            }
        case STATUS_CHAT_ADMIN_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        default:
            return state
    }
}

export default supportTicketAdmins