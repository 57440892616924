/* LINEITEM */
export const LOAD_ACTIVITIES_LI = "LOAD_ACTIVITIES_LI"
export const NO_DATA = "NO_DATA"
export const GET_LINEITEMS = "GET_LINEITEMS"
export const GET_LINEITEMS_SUCCESS = "GET_LINEITEMS_SUCCESS"
export const GET_LINEITEMS_FAIL = "GET_LINEITEMS_FAIL"

export const ADD_LINEITEM = "ADD_LINEITEM"
export const ADD_LINEITEM_SUCCESS = "ADD_LINEITEM_SUCCESS"
export const ADD_LINEITEM_ERROR = "ADD_LINEITEM_ERROR"

export const UPDATE_LINEITEM = "UPDATE_LINEITEM"
export const UPDATE_LINEITEM_SUCCESS = "UPDATE_LINEITEM_SUCCESS"
export const UPDATE_LINEITEM_ERROR = "UPDATE_LINEITEM_ERROR"

export const READ_LINEITEM = "READ_LINEITEM"
export const READ_LINEITEM_SUCCESS = "READ_LINEITEM_SUCCESS"

export const STATUS_LINEITEM = "STATUS_LINEITEM"
export const STATUS_LINEITEM_SUCCESS = "STATUS_LINEITEM_SUCCESS"

export const ARCHIVE_LINEITEM = "ARCHIVE_LINEITEM"
export const ARCHIVE_LINEITEM_SUCCESS = "ARCHIVE_LINEITEM_SUCCESS"