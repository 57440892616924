import {
   LOAD_CREATIVES,
   NO_DATA,
    GET_CREATIVES,
    GET_CREATIVES_FAIL,
    GET_CREATIVES_SUCCESS,
    ADD_CREATIVE,
    ADD_CREATIVE_SUCCESS,
    ADD_CREATIVE_ERROR,
    UPDATE_CREATIVE,
    UPDATE_CREATIVE_SUCCESS,
    UPDATE_CREATIVE_ERROR,
    READ_CREATIVE,
    READ_CREATIVE_SUCCESS,
    STATUS_CREATIVE,
    STATUS_CREATIVE_SUCCESS,
    ARCHIVE_CREATIVE ,
    ARCHIVE_CREATIVE_SUCCESS,
    ADD_VIDEO_CREATIVE,
    ADD_VIDEO_CREATIVE_SUCCESS,
    ADD_VIDEO_CREATIVE_ERROR,
    UPDATE_VIDEO_CREATIVE,
    UPDATE_VIDEO_CREATIVE_SUCCESS,
    UPDATE_VIDEO_CREATIVE_ERROR,
    READ_VIDEO_CREATIVE,
    READ_VIDEO_CREATIVE_SUCCESS
  } from "./actionTypes"

  export const loadCreative = Params => ({
    type: LOAD_CREATIVES,
    payload:Params
  })

  export const noDataCreative = Params => ({
    type: NO_DATA,
    payload:Params
  })

  export const getCreative = Params => ({
    type: GET_CREATIVES,
    payload:Params
  })
  
  export const getCreativeSuccess = Creative => ({
    type: GET_CREATIVES_SUCCESS,
    payload: Creative,
  })

  export const getCreativeFail = error => ({
    type: GET_CREATIVES_FAIL,
    payload: error,
  })

  export const addNewCreative = Creative => ({
    type: ADD_CREATIVE,
    payload: Creative,
  })
  
  export const addCreativeSuccess = creative => ({
    type: ADD_CREATIVE_SUCCESS,
    payload: creative,
  })
  
  export const addCreativeFail = creative => ({
    type: ADD_CREATIVE_ERROR,
    payload: creative,
  })

  export const updateCreative = creative => ({
    type: UPDATE_CREATIVE,
    payload: creative,
  })
  
  export const updateCreativeSuccess = creative => ({
    type: UPDATE_CREATIVE_SUCCESS,
    payload: creative,
  })
  
  export const updateCreativeFail = creative => ({
    type: UPDATE_CREATIVE_ERROR,
    payload: creative,
  })

  export const readCreative = creative => ({
    type: READ_CREATIVE,
    payload: creative,
  })

  export const readCreativeSuccess = creative => ({
    type: READ_CREATIVE_SUCCESS,
    payload: creative,
  })

  export const statusCreative = creative => ({
    type: STATUS_CREATIVE,
    payload: creative,
  })

  export const statusCreativeSuccess = creative => ({
    type: STATUS_CREATIVE_SUCCESS,
    payload: creative,
  })

  export const archiveCreative = creative => ({
    type: ARCHIVE_CREATIVE,
    payload: creative,
  })

  export const archiveCreativeSuccess = creative => ({
    type: ARCHIVE_CREATIVE_SUCCESS,
    payload: creative,
  })

  export const addNewVideoCreative = Creative => ({
    type: ADD_VIDEO_CREATIVE,
    payload: Creative,
  })
  
  export const addVideoCreativeSuccess = creative => ({
    type: ADD_VIDEO_CREATIVE_SUCCESS,
    payload: creative,
  })
  
  export const addVideoCreativeFail = creative => ({
    type: ADD_VIDEO_CREATIVE_ERROR,
    payload: creative,
  })

  export const updateVideoCreative = creative => ({
    type: UPDATE_VIDEO_CREATIVE,
    payload: creative,
  })
  
  export const updateVideoCreativeSuccess = creative => ({
    type: UPDATE_VIDEO_CREATIVE_SUCCESS,
    payload: creative,
  })
  
  export const updateVideoCreativeFail = creative => ({
    type: UPDATE_VIDEO_CREATIVE_ERROR,
    payload: creative,
  })

  export const readVideoCreative = creative => ({
    type: READ_VIDEO_CREATIVE,
    payload: creative,
  })

  export const readVideoCreativeSuccess = creative => ({
    type: READ_VIDEO_CREATIVE_SUCCESS,
    payload: creative,
  })