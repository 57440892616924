/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  Row, Col, Input, Label, Form, FormFeedback,
  FormGroup,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Container,
  Spinner
} from "reactstrap";
import Select from "react-select";
// Formik Validation
import moment from "moment";
import axios from "axios";

// import "./IOG.scss"

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import classnames from "classnames"
import Flight_Selection from "./Flight_Selection";
import Technology_Selection from "./Technology_Selection";
import Contextual_Targeting from "./Contextual_Targeting";
import Geo_Targeting from "./Geo_Targeting";
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// actions
import {
  addNewLineItem as onADDLineItem,
  updateLineItem as onUpdateLineItem,
  addLineItemSuccess,
  addLineItemFail,
  updateLineItemSuccess,
  updateLineItemFail,
} from "store/Demand/LineItem/actions";

import {
  getAdvertisers as onGetAdvertisers,
} from "store/Demand/Advertiser/actions";

import {
  getIOGroups as onGetIOGroups,
} from "store/Demand/IOGroup/actions";

import {
  getIOs as onGetIOss,
} from "store/Demand/IOs/actions";

import {
  getAdUnit as onGetAdUnits,
} from "store/Supply/AdUnit/actions";
import Toaster from "pages/Toaster/Toaster";

import {
  getCommonDevices as onGetAllContextual,
} from "store/CommonEvents/actions";

import ToasterWarning from "pages/Toaster/ToasterWarning";


var selectedAdUnits = []
var selectedlength = 0
var CountriesSelectedIn = ["DZ", "AO", "BJ", "BW", "BF", "BI", "CM", "CV", "CF", "TD", "KM", "CG", "CD", "CI", "DJ", "EG", "GQ", "ER", "ET", "GA", "GM", "GH", "GN", "GW", "KE", "LS", "LR", "LY", "MG", "MW", "ML", "MR", "MU", "YT", "MA", "MZ", "NA", "NE", "NG", "RW", "RE", "SH", "SN", "SC", "SL", "SO", "ZA", "SD", "SZ", "ST", "TZ", "TG", "TN", "UG", "EH", "ZM", "ZW", "AI", "AG", "AR", "AW", "BS", "BB", "BZ", "BM", "BO", "BR", "VG", "CA", "KY", "CL", "CO", "CR", "CU", "DM", "DO", "EC", "SV", "FK", "GF", "GL", "GD", "GP", "GT", "GY", "HT", "HN", "JM", "MQ", "MX", "MS", "AN", "NI", "PA", "PY", "PE", "PR", "BL", "KN", "LC", "MF", "PM", "VC", "SR", "TT", "TC", "VI", "US", "UY", "VE", "AF", "AM", "AZ", "BH", "BD", "BT", "BN", "KH", "CN", "GE", "HK", "IN", "ID", "IR", "IQ", "IL", "JP", "JO", "KZ", "KW", "KG", "LA", "LB", "MO", "MY", "MV", "MN", "MM", "NP", "NT", "KP", "OM", "PK", "PS", "YD", "PH", "QA", "SA", "SG", "KR", "LK", "SY", "TW", "TJ", "TH", "TL", "TR", "™", "AE", "UZ", "VN", "YE", "AL", "AD", "BY", "BA", "DD", "FO", "GI", "GG", "IS", "IM", "JE", "LI", "MK", "FX", "MD", "MC", "ME", "NO", "RU", "SM", "RS", "CS", "SJ", "CH", "UA", "SU", "VA", "AX", "AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE", "GB", "AS", "AQ", "AU", "BV", "IO", "CX", "CC", "CK", "FJ", "PF", "TF", "GU", "HM", "KI", "MH", "FM", "NR", "NC", "NZ", "NU", "NF", "MP", "PW", "PG", "PN", "WS", "SB", "GS", "TK", "TO", "TV", "UM", "VU", "WF"]
var CountriesSelectedEx = []

const Create_LI = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const body = {
    "url": '',
    "requestBody": {
      userID: userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name: userInfo.name,
    }
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 1000,
    extendedTimeOut: 1000
  };

  var currentTime = new Date().toLocaleString('en-US', {
    timeZone: 'America/New_York',
  })

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  const [submitted, setSubmitted] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [lineItem, setLineItem] = useState({
    lineItemName: '',
    lineItemType: props.selectedLineItemType,
    startDate: moment(currentTime).format('YYYY-MM-DD HH:mm:ss'),
    endDate: moment(currentTime).format('YYYY-MM-DD HH:mm:ss'),
    priority: 'medium',
    requestCapping: {
      per: '',
      capping: "0"
    },
    impressionCapping: {
      per: 'day',
      capping: "0",
    },
    frequencyCapping: {
      per: 'hour',
      capping: "0",
    },
    deliveryOption: "evenly",
    cPMRate: 0,
    devicesIn: [],
    devicesEx: [],
    oSsIn: [],
    oSsEx: [],
    data: {
      isImmediate: false,
      isUnlimited: false
    },
    webBrowsersIn: [],
    webBrowsersEx: [],
    countriesIn: [],
    countriesEx: [],
    statesIn: [],
    statesEx: [],
    citiesIn: [],
    citiesEx: [],
    dMAsIn: [],
    dMAsEx: [],
    zipCodesIn: [],
    zipCodesEx: [],
    dayPartingsIn: [],
    dayPartingsEx: [],
    latLongIn: [],
    latLongEx: [],
    weathersIn: [],
    weathersEx: [],
    pageURLsIn: [],
    pageURLsEx: [],
    languagesIn: [],
    languagesEx: [],
    keywordsIn: [],
    keywordsEx: [],
    interestsIn: [],
    interestsEx: [],
    brandAffinitiesIn: [],
    brandAffinitiesEx: [],
    domainsIn: [],
    domainsEx: [],
    dategoriesIn: [],
    dategoriesEx: [],
    categoriesIn: [],
    categoriesEx: [],
    adUnitID: [],
    ioID: '',
    advertiserID: '',
    ioGroupID: '',
    lineItemPlayerType: 'preRoll'
  });

  const [targetStates, setTargetStates] = useState({
    domains: false,
    languages: false,
    categories: false,
    pageURLs: false,
    interests: false,
    keywords: false,
    brandAffinities: false,
  })

  const [techStates, setTechStates] = useState({
    devices: false,
    OSs: false,
    webBrowsers: false,
  })

  const [geoStates, setGeoStates] = useState({
    countries: false,
    cities: false,
    zipCodes: false,
    latLong: false,
    weathers: false,
  })

  const [cappingStates, setCappingStates] = useState({
    impression: false,
    request: false,
    frequency: false,
    partition: false,
  })

  const [advertiserData, setAdvertiserData] = useState([])
  const [ioGroupData, setIOGroupData] = useState([])
  const [ioSData, setIOSData] = useState([])
  const [adUnitData, setAdUnitData] = useState([])
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
  const [selectedIOGroup, setSelectedIOGroup] = useState(null);
  const [selectedIOs, setSelectedIOs] = useState(null);
  const [selectedAdUnit, setSelectedAdUnit] = useState(null);
  const nextRef = useRef();
  const [nextTab, setNextTab] = useState(false);
  const [creativeType, setCreativeType] = useState('');
  const [selectedAdUnitData, setSelectedAdUnitData] = useState([]);
  const [selectedAdUnitsData, setSelectedAdUnitsData] = useState([]);
  const [hoverStateID, setHoverStateID] = useState('');
  const [childAdUnitData, setChildAdUnitData] = useState([])
  const [contextualData, setContextualData] = useState({})

  const { contextuals } = useSelector(state => ({
    contextuals: state.commonEvents.contextualAllData,
  }));

  useEffect(() => {
    if (contextuals !== null) {
      dispatch(onGetAllContextual());
    }
  }, []);

  useEffect(() => {
    if (contextuals && JSON.stringify(contextuals) !== '{}') {
      setContextualData(contextuals)
    }
  }, [contextuals]);

  const [searchValue, setSearchValue] = useState("");

  const [treeSelect, setTreeSelect] = useState({
    checked: [],
    expanded: [],
  })
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const filterNames = ({ label }) => {
    return label.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
  };

  useEffect(() => {
    dispatch(onGetIOGroups(null));
    dispatch(onGetIOss(null));
    dispatch(onGetAdUnits(null));
  }, [])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLineItem(lineItem => ({ ...lineItem, [name]: value }));
  }

  const [selectedGroup, setselectedGroup] = useState(null);
  const dispatch = useDispatch();

  const { advertisers, ioGroups, ioSs, adUnits } = useSelector(state => ({
    advertisers: state.advertisers.advs,
    ioGroups: state.ioGroups.ioGroups,
    ioSs: state.ioSs.ioSs,
    adUnits: state.adUnits.adUnitList,
  }));

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.lineItems.errorMsg,
    successMsg: state.lineItems.successMsg,
  }));

  useEffect(() => {
    if (advertisers !== null) {
      dispatch(onGetAdvertisers({ filter: 'Active', type: props.selectedLineItemType, userRole: userInfo.userRole, userID: userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner,pageSize: 0, pageNumber: 0, searchText: '' }));
    }
  }, []);

  useEffect(() => {
    if (ioGroups !== null && ioSs !== null && adUnits !== null) {
      dispatch(onGetIOGroups({ advertiserID: '', filter: 'Active', type: props.selectedLineItemType, userRole: userInfo.userRole, userID: userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner,pageSize: 0, pageNumber: 0, searchText: '' }));
      dispatch(onGetIOss({ ioGroupID: '', filter: 'Active', type: props.selectedLineItemType, userRole: userInfo.userRole, userID: userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner ,pageSize: 0, pageNumber: 0, searchText: ''}));
      // dispatch(onGetAdUnits({ adUnitGroupID: '', filter: 'Active', adUnitType: props.selectedLineItemType, adUnitSize: '', userRole: userInfo.userRole, userID: userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner,pageSize: 0, pageNumber: 0, searchText: '' }));
    }
  }, []);

  useEffect(() => {
    if (advertisers && advertisers.length > 0) {
      setAdvertiserData(objectModal(advertisers))
    }
  }, [advertisers]);

  useEffect(() => {
    if (ioGroups && ioGroups.length > 0) {
      setIOGroupData(objectIOGroupModal(ioGroups))
    }
  }, [ioGroups]);

  useEffect(() => {
    if (ioSs && ioSs.length > 0) {
      setIOSData(objectIOsModal(ioSs))
    }
  }, [ioSs]);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.advertiserID
      obj.label = item.advertiserName
      dataList.push(obj)
    })
    return dataList
  }

  const objectIOGroupModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.ioGroupID
      obj.label = item.ioGroupName
      dataList.push(obj)
    })
    return dataList
  }

  const objectIOsModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.ioID
      obj.label = item.ioName
      dataList.push(obj)
    })
    return dataList
  }

  function handleSelectAdvertiser(selectAdvertiser) {
    setLineItem(lineItem => ({ ...lineItem, advertiserID: selectAdvertiser.value }));
    setSelectedAdvertiser(selectAdvertiser);
    dispatch(onGetIOGroups({ advertiserID: selectAdvertiser.value, filter: 'Active', type: props.selectedLineItemType, userRole: userInfo.userRole, userID: userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner ,pageSize: 0, pageNumber: 0, searchText: ''}));
    setSelectedIOGroup(null);
    setSelectedIOs(null);
  }

  function handleSelectIOGroup(selectIOGroup) {
    getParentLineItem(selectIOGroup.value, '')
    setLineItem(lineItem => ({ ...lineItem, ioGroupID: selectIOGroup.value }));
    setSelectedIOGroup(selectIOGroup);
    dispatch(onGetIOss({ ioGroupID: selectIOGroup.value, filter: 'Active', type: props.selectedLineItemType, userRole: userInfo.userRole, userID: userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner,pageSize: 0, pageNumber: 0, searchText: '' }));
    setSelectedIOs(null);
  }

  function handleSelectIOs(selectIOs) {
    getParentLineItem('', selectIOs.value)
    setLineItem(lineItem => ({ ...lineItem, ioID: selectIOs.value }));
    setSelectedIOs(selectIOs);
  }

  function handleSelectAdUnit(selectAdUnit) {
    setLineItem(lineItem => ({ ...lineItem, adUnitID: selectAdUnit.value }));
    setSelectedAdUnit(selectAdUnit);
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    console.log(lineItem)
    // let Technology_Selections = (lineItem.devicesIn.length > 0 || lineItem.devicesEx.length > 0) && (lineItem.webBrowsersIn.length > 0 || lineItem.webBrowsersEx.length > 0) && (lineItem.oSsIn.length > 0 || lineItem.oSsEx.length > 0)
    // if (!Technology_Selections) {
    //   toastr.error('Please fill all Technology Selection Data')
    // } else
    if ((cappingStates.impression && lineItem.impressionCapping.capping < 1) || (lineItem.priority === 'high' && lineItem.impressionCapping.capping < 1)) {
      toastr.error('Please fill Impression Capping Data')
    }
    else if (cappingStates.frequency && lineItem.frequencyCapping.capping < 1) {
      toastr.error('Please fill Frequency Capping Data')
    }
    else {
      let partitionValidation = checkPartitionData()
      let techValidation = checkTechData()
      let contextualValidation = checkTargetingData()
      let geoTargetingValidation = checkGeoTargetingData()
      if (lineItem.lineItemName && contextualValidation && partitionValidation && techValidation && geoTargetingValidation && lineItem.adUnitID.length > 0 && lineItem.ioID && (lineItem.priority === 'high' ? lineItem.impressionCapping.capping > 0 : true)) {
        setCreativeType(props.selectedLineItemType)
        setLoader(true)
        let data = { ...lineItem }
 
        // if (props.selectedLineItem !== '') {
        //   dispatch(onUpdateLineItem({ ...data,countriesIn: CountriesSelectedIn.length > 0 ? CountriesSelectedIn : lineItem.countriesIn,countriesEx: CountriesSelectedEx.length > 0 ? CountriesSelectedEx : lineItem.countriesEx  }));
        // } else {
        //   dispatch(onADDLineItem({ ...data,countriesIn: CountriesSelectedIn.length > 0 ? CountriesSelectedIn : lineItem.countriesIn,countriesEx: CountriesSelectedEx.length > 0 ? CountriesSelectedEx : lineItem.countriesEx }));
        // }
        if (props.selectedLineItem !== '' && props.selectedLineItemCreateStatus !== 'Duplicate') {
          if (data.data.isUnlimited) {
            data.endDate = moment(data.startDate).add(100, 'years').format('YYYY-MM-DD HH:mm:ss')
          }
          console.log(selectedAdUnits)
          if (props.selectedLineItem.adunitArray && props.selectedLineItem.adunitArray.length > 0) {
            let adUnitIds = []
            props.selectedLineItem.adunitArray.map((elem, index) => {
              adUnitIds.push({ ID: elem.ID, isChild: elem.IsChildAdunit })
            })
            dispatch(onUpdateLineItem({ ...data, lineItemName: lineItem.lineItemName.trim(), adUnitIDOld: adUnitIds, adUnitID: selectedAdUnits, userRole: userInfo.userRole, userID: userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner }));
          } else {
            dispatch(onUpdateLineItem({ ...data, lineItemName: lineItem.lineItemName.trim(), adUnitIDOld: [], adUnitID: selectedAdUnits, userRole: userInfo.userRole, userID: userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner }));
          }
        } if (props.selectedLineItem !== '' && props.selectedLineItemCreateStatus === 'Duplicate') {
          delete lineItem.count
          delete lineItem.lineItemCreateTime
          delete lineItem.lineItemID
          delete lineItem.lineItemModifyTime
          delete lineItem.lineItemStatus
          delete lineItem.newStatus
          delete lineItem.isLineItemArchived
          if (data.data.isUnlimited) {
            data.endDate = moment(currentTime).add(100, 'years').format('YYYY-MM-DD HH:mm:ss')
          }
          dispatch(onADDLineItem({ ...data, lineItemName: lineItem.lineItemName.trim(), userRole: userInfo.userRole, userID: userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner }));
        }
        if (props.selectedLineItem === '') {
          if (data.data.isUnlimited) {
            data.endDate = moment(currentTime).add(100, 'years').format('YYYY-MM-DD HH:mm:ss')
          }
          dispatch(onADDLineItem({ ...data, lineItemName: lineItem.lineItemName.trim(), userRole: userInfo.userRole, userID: userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner }));
        }
      }
    }
  }

  const checkPartitionData = () => {

    if (cappingStates.partition && (lineItem.dayPartingsIn.length === 0 && lineItem.dayPartingsEx.length === 0)) {
      toastr.error('Please Add Day Partition')
      return false
    } else {
      return true
    }
  }

  const checkTechData = () => {

    if (techStates.devices && (lineItem.devicesIn.length === 0 && lineItem.devicesEx.length === 0)) {
      toastr.error('Please select Devices')
      return false
    } else if (techStates.OSs && (lineItem.oSsIn.length === 0 && lineItem.oSsEx.length === 0)) {
      toastr.error('Please select Operating Systems')
      return false
    } else if (techStates.webBrowsers && (lineItem.webBrowsersIn.length === 0 && lineItem.webBrowsersEx.length === 0)) {
      toastr.error('Please select Web Browsers')
      return false
    } else {
      return true
    }
  }

  const checkTargetingData = () => {
    if (targetStates.domains && (lineItem.domainsIn.length === 0 && lineItem.domainsEx.length === 0)) {
      toastr.error('Please select Domains')
      return false
    } else if (targetStates.languages && (lineItem.languagesIn.length === 0 && lineItem.languagesEx.length === 0)) {
      toastr.error('Please select Languages')
      return false
    } else if (targetStates.categories && (lineItem.categoriesIn.length === 0 && lineItem.categoriesEx.length === 0)) {
      toastr.error('Please select Categories')
      return false
    } else if (targetStates.pageURLs && (lineItem.pageURLsIn.length === 0 && lineItem.pageURLsEx.length === 0)) {
      toastr.error('Please select Page URLs')
      return false
    } else if (targetStates.interests && (lineItem.interestsIn.length === 0 && lineItem.interestsEx.length === 0)) {
      toastr.error('Please select Interests')
      return false
    } else if (targetStates.keywords && (lineItem.keywordsIn.length === 0 && lineItem.keywordsEx.length === 0)) {
      toastr.error('Please select Keywords')
      return false
    } else if (targetStates.brandAffinities && (lineItem.brandAffinitiesIn.length === 0 && lineItem.brandAffinitiesEx.length === 0)) {
      toastr.error('Please select Brand Affinities')
      return false
    } else {
      return true
    }
  }

  const checkGeoTargetingData = () => {
    console.log(geoStates.countries, lineItem.countriesIn.length, lineItem.countriesEx.length)
    if (geoStates.countries && (lineItem.countriesIn.length === 0 && lineItem.countriesEx.length === 0)) {
      toastr.error('Please select Countries')
      return false
    } else if (geoStates.cities && (lineItem.citiesIn.length === 0 && lineItem.citiesEx.length === 0)) {
      toastr.error('Please select Cities')
      return false
    } else if (geoStates.zipCodes && (lineItem.zipCodesIn.length === 0 && lineItem.zipCodesEx.length === 0)) {
      toastr.error('Please Enter Zipcodes')
      return false
    } else if (geoStates.latLong && (lineItem.latLongIn.length === 0 && lineItem.latLongEx.length === 0)) {
      toastr.error('Please select Lattitude and Langitude')
      return false
    } else if (geoStates.weathers && (lineItem.weathersIn.length === 0 && lineItem.weathersEx.length === 0)) {
      toastr.error('Please select Weathers')
      return false
    } else {
      return true
    }
  }

  const getLineItemData = async (selectedLineItemData) => {
    console.log(selectedLineItemData)
    setLineItem({ ...lineItem, ...selectedLineItemData })
    // if(selectedLineItemData.countriesIn || selectedLineItemData.countriesEx){
    //   CountriesSelectedIn = []
    //   CountriesSelectedEx = []
    // }
    // if(selectedLineItemData.countriesIn && selectedLineItemData.countriesIn.length > 0){
    //   CountriesSelectedIn = selectedLineItemData.countriesIn
    // }
    // if(selectedLineItemData.countriesEx && selectedLineItemData.countriesEx.length > 0 ){
    //   CountriesSelectedEx = selectedLineItemData.countriesEx
    // }
  }

  const getTargetingLineItemData = async (selectedLineItemData) => {
    setTargetStates({ ...targetStates, ...selectedLineItemData })
  }

  const getTechLineItemData = async (selectedLineItemData) => {
    setTechStates({ ...techStates, ...selectedLineItemData })
  }

  const getGeoLineItemData = async (selectedLineItemData) => {
    setGeoStates({ ...geoStates, ...selectedLineItemData })
  }

  const getCappingLineItemData = async (selectedLineItemData) => {
    setCappingStates({ ...cappingStates, ...selectedLineItemData })
  }

  const changePlayerType = e => {
    setLineItem(lineItem => ({ ...lineItem, lineItemPlayerType: e.target.value }))
  }

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.statusCode === 200) {
        dispatch(addLineItemSuccess(""));
        dispatch(addLineItemFail(""));
        dispatch(updateLineItemSuccess(""));
        dispatch(updateLineItemFail(""));
        setLoader(false)
        if (nextTab) {
          props.closeCanvas('')
          nextRef.current.click()
        } else {
          if (props.selectedLineItem !== '' && props.selectedLineItemCreateStatus !== 'Duplicate') {
            props.closeCanvas('Update')
          } else {
            props.closeCanvas('Create')
          }
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if (errorMsg.status && errorMsg.status === 500) {
      } else {
        dispatch(addLineItemSuccess(""));
        dispatch(addLineItemFail(""));
        dispatch(updateLineItemSuccess(""));
        dispatch(updateLineItemFail(""));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addLineItemSuccess(""));
    dispatch(addLineItemFail(""));
    dispatch(updateLineItemSuccess(""));
    dispatch(updateLineItemFail(""));
    props.closeCanvas('')
  };

  useEffect(() => {
    if (props.selectedIO !== '' || props.selectedLineItem === '') {
      getParentLineItem('', '')
    }
    if (props.selectedLineItem !== '' && props.selectedLineItemCreateStatus !== 'Duplicate') {
      setIsEdit(true)
    }
    if (props.selectedLineItem !== '') {
      setLineItem(props.selectedLineItem)
      getParentLineItem('', '')
      setSelectedAdUnitData(props.selectedLineItem.adUnitID);
      setTreeSelect(treeSelect => ({ ...treeSelect, checked: props.selectedLineItem.adUnitID }))
    }
  }, []);

  useEffect(() => {
    selectedAdUnits = []
    selectedlength = 0
    let bodyAdUnit = {
      "url": props.selectedLineItemType === 'video' ? (userInfo.userRole === 'SupplyPartner') ? "http://127.0.0.1:3006/v1.0/Supply/PlayerSettings/getAdUnit" : '/v2.0/demand/LineItems/read/AdunitSPlay' :
       props.selectedLineItemType === 'highImpact' ? ((userInfo.userRole === 'SupplyPartner') ? "/v2.0/supplyPartner/HighImpactSettings/read/AdunitHighImpact/SupplyPartner" : "/v2.0/supply/HighImpactSettings/read/AdunitHighImpact") :
        (userInfo.userRole === 'SupplyPartner') ? "/v2.0/supplyPartner/StandardBannerSettings/read/AdunitStandardBanner/SupplyPartner" : "/v2.0/supply/StandardBannerSettings/read/AdunitStandardBanner",
      "requestBody": {
        userID: userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,pageSize: 0, pageNumber: 0, searchText: ''
      }
    }
    axios.post(bodyAdUnit.url, bodyAdUnit.requestBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log(res)
        if (res.data.statusCode === 200) {
          let trees = []
          if (res.data.response.AdUnits.length > 0) {
            res.data.response.AdUnits.map((item, i) => {
              trees.push(createAdUnitsTree(item.adUnitName, item.adUnitID, item.child ? item.child  : ''))
            })
          }
          console.log(trees)

          console.log(trees, trees.length, adUnitData)
          if (trees.length > 0) {
            if (props.selectedLineItem !== '') {
              var selectedData1 = []
              if (props.selectedLineItem.adunitArray && props.selectedLineItem.adunitArray.length > 0) {
                let adUnitIds = []
                props.selectedLineItem.adunitArray.map((elem, index) => {
                  let obj1 = {}
                  obj1.value = elem.ID
                  obj1.label = elem.Name
                  obj1.isChild = elem.IsChildAdunit
                  selectedData1.push(obj1)
                  selectedAdUnits.push({ ID: elem.ID, isChild: elem.IsChildAdunit })
                  adUnitIds.push({ ID: elem.ID, isChild: elem.IsChildAdunit })
                  sbc(elem.ID, true, elem.ParentAdUnitID ? elem.ParentAdUnitID : '', trees)
                })
                // setSelectedAdUnitsData(Object.values(selectedData1.reduce((acc, cur) => Object.assign(acc, { [cur.value]: cur }), {})))
                console.log(adUnitIds)
                setLineItem(lineItem => ({ ...lineItem, adUnitID: adUnitIds }));
                setLineItem(lineItem => ({ ...lineItem, adUnitIDOld: adUnitIds }));

                // AdUnitSelecteds = props.selectedLineItem.adunitArray.length > 1 ? props.selectedLineItem.adunitArray.length + ' Ad Units are Selected' : '1 Ad Unit is Selected'
                setAdUnitsSelected(props.selectedLineItem.adunitArray.length > 1 ? props.selectedLineItem.adunitArray.length + ' Ad Units are Selected' : '1 Ad Unit is Selected')
              } else {
                setLineItem(lineItem => ({ ...lineItem, adUnitIDOld: [] }));
                setAdUnitData(trees)
              }
            } else {
              setAdUnitData(trees)
            }
          }
        }
      })
      .catch((err) => {
        console.log("e", err)
      });

  }, []);

  const createAdUnitsTree = (groupName, id, options) => {
    return {
      label: groupName,
      value: id,
      checked: false,
      ParentID: '',
      children: options ?  objectModalAdUnits(options, id) : []
    };
  };

  const objectModalAdUnits = (response, parentId) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.childAdUnitID
      obj.label = item.childAdUnitName
      obj.ParentID = parentId
      obj.checked = false
      dataList.push(obj)
    })
    return dataList
  }

  // useEffect(() => {
  //     let  bodyAdUnit = {
  //       "url": "http://127.0.0.1:2002/v1.0/LineItems/read/getAdunit",
  //       "requestBody": {
  //         userID: userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner,
  //         userRole: userInfo.userRole,
  //         companyID: userInfo.companyID,
  //         type:props.selectedLineItemType,
  //         name:userInfo.name,
  //       }
  //     }
  //     axios.post("/v1.0/connectOP", bodyAdUnit,
  //       {
  //         headers: headers
  //       })
  //       .then((res) => {
  //         if (res.data.response) {
  //           setAdUnitData(objectAdUnitModal(res.data.response.data))
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("e", err)
  //       });

  // }, []);

  const objectChildAdUnit = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.AdUnitName
      obj.label = item.AdUnitName
      obj.children = childModal(item.ChildAdunit)
      dataList.push(obj)
    })
    return dataList
  }

  const childModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = JSON.parse(item.ChildAdUnitID)
      obj.label = item.ChildAdUnitName
      dataList.push(obj)
    })
    return dataList
  }

  const getParentLineItem = (IOGroupId, IOId) => {
    var filterBody = {
        "advertiserID": 0,
        "ioGroupID": 0,
        "ioID": 0,
        "lineItemID": 0,
        "creativeID": 0,
        "videoCreativeID": 0,
        userID: userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,
    }
    if (props.selectedLineItem !== '') {
      filterBody.ioID = props.selectedLineItem.ioID
    }
    if (props.selectedIO !== '') {
      filterBody.ioID = props.selectedIO.ioID
    }
    if (IOGroupId) {
      filterBody.ioGroupID = IOGroupId
    }
    if (IOId) {
      filterBody.ioID = IOId
    }
    axios.post(userInfo.userRole === 'SupplyPartner' ?"/v2.0/demandSupplyPartner/getParent/SupplyPartner" : "/v2.0/demand/getParent", filterBody,
      {
        headers: headers
      })
      .then((res) => {
        if (res.data.response) {
          setSelectedAdvertiser({ label: res.data.response.advertiserName, value: res.data.response.advertiserID });
          setSelectedIOGroup({ label: res.data.response.ioGroupName, value: res.data.response.ioGroupID });
          if (!IOGroupId) {
            setSelectedIOs({ label: res.data.response.ioName, value: res.data.response.ioID });
            setLineItem(lineItem => ({ ...lineItem, advertiserID: res.data.response.advertiserID, ioGroupID: res.data.response.ioGroupID, ioID: res.data.response.ioID }));
          } else {
            setLineItem(lineItem => ({ ...lineItem, advertiserID: res.data.response.advertiserID, ioGroupID: res.data.response.ioGroupID }));
          }
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const onFilterChange = (selectedFilter) => {
    setSelectedAdUnitData(selectedFilter);
    setLineItem(lineItem => ({ ...lineItem, adUnitID: selectedFilter }));
  };

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  function allowAlphaNumericSpace(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault(); }
  };

  function allowOnlyNumbers(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code >= 48 && code < 58) && !(code >= 46 && code < 47)) { e.preventDefault(); }
  };

  const [adUnitsSelected, setAdUnitsSelected] = useState('Please Select Ad Units')

  const onChangeAdUnit = (currentNode, selectedNodes) => {

    console.log(currentNode, selectedNodes)
    let index = selectedAdUnits.findIndex(x => x.ID === currentNode.value)
    if (currentNode.checked) {
      if (index === -1) {
        selectedAdUnits.push({
          ID: currentNode.value,
          isChild: currentNode._parent ? true : false
        })
      }
    } else {
      selectedAdUnits.splice(index, 1)
    }
    let array = [...adUnitData]
    selectedlength = selectedAdUnits.length
    console.log(selectedAdUnits, selectedlength)
    let AdUnitSelecteds = selectedlength > 1 ? selectedlength + ' Ad Units are Selected' : selectedlength === 1 ? '1 Ad Unit is Selected' : 'Please Select Ad Units'
    setAdUnitsSelected(AdUnitSelecteds)
    sbc(currentNode.value, currentNode.checked, currentNode.ParentID, array)
  }

  const sbc = (selectedId, checked, parentId, array) => {
    if (array.length > 0) {
      if (parentId) {
        let parentIndex = array.findIndex(x => x.value === parentId)
        let childIndex = array[parentIndex].children.findIndex(x => x.value === selectedId)
        array[parentIndex].children[childIndex].checked = checked
        let selectedElementParent = array[parentIndex]
        array.splice(parentIndex, 1)
        array = [selectedElementParent, ...array]
      } else {
        let index = array.findIndex(x => x.value === selectedId)
        array[index].checked = checked
        let selectedElement = array[index]
        array.splice(index, 1)
        array = [selectedElement, ...array]
      }
      setAdUnitData(array)
      setLineItem(lineItem => ({ ...lineItem, adUnitID: selectedAdUnits }));
    }
  }


  return (
    <React.Fragment>
      {loader &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
        <Form onSubmit={handleSubmit}>
          <div className="formBodyScroll" style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
            <div className="col-12">
              <Row >
                <Col xs={6}>
                  {props.selectedLineItemType === 'video' &&
                    <div className="mb-3 mt-3">
                      <Label className="form-label">Player Type</Label>
                      <Input
                        type="select"
                        className="form-select"
                        name="lineItemPlayerType"
                        onChange={changePlayerType}
                        value={lineItem.lineItemPlayerType}
                        disabled={props.selectedArchived}
                      >

                        <option value=""  >
                          Select...
                        </option>
                        <option value="preRoll">Pre Roll</option>
                        <option value="midRoll">Mid Roll</option>
                        <option value="preRoll&MidRoll">Pre Roll and Mid Roll</option>
                        <option value="vast">Vast</option>
                        <option value="vpaid">VPaid</option>
                      </Input>
                    </div>
                  }

                  <div className="mb-3">
                    <Label>Advertiser  <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                    <Select
                      value={selectedAdvertiser}
                      onChange={s => {
                        handleSelectAdvertiser(s);
                      }}
                      options={advertiserData}
                      classNamePrefix="select2-selection  "
                      className={(submitted && !lineItem.advertiserID ? ' is-invalid' : '')}
                      isDisabled={props.selectedArchived || isEdit}
                    />
                    {submitted && !lineItem.advertiserID ? (
                      <FormFeedback type="invalid">{'Please Select Advertiser'}</FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label>Insertion order Group <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                    <Select
                      value={selectedIOGroup}
                      onChange={(s) => {
                        handleSelectIOGroup(s);
                      }}
                      options={ioGroupData}
                      className={(submitted && !lineItem.ioGroupID ? ' is-invalid' : '')}
                      classNamePrefix="select2-selection"
                      isDisabled={props.selectedArchived || isEdit}
                    />
                    {submitted && !lineItem.ioGroupID ? (
                      <FormFeedback type="invalid">{'Please Select IOGroup'}</FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label>Insertion order <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                    <Select
                      value={selectedIOs}
                      onChange={(s) => {
                        handleSelectIOs(s);
                      }}
                      options={ioSData}
                      classNamePrefix="select2-selection"
                      className={(submitted && !lineItem.ioID ? ' is-invalid' : '')}
                      isDisabled={props.selectedArchived || isEdit}
                    /> {submitted && !lineItem.ioID ? (
                      <FormFeedback type="invalid">{'Please Select Insertion Order'}</FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">Line Item Name <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                    <Input
                      name="lineItemName"
                      type="text"
                      maxLength={110}
                      value={lineItem.lineItemName}
                      onChange={handleChange}
                      onKeyPress={allowAlphaNumericSpace}
                      className={'form-control' + (submitted && !lineItem.lineItemName ? ' is-invalid' : '')}
                      readOnly={props.selectedArchived}
                    />
                    {submitted && !lineItem.lineItemName ? (
                      <FormFeedback type="invalid">{'Line Item Name is required'}</FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3 HIS">
                    <Label>Ad Unit <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>

                    <DropdownTreeSelect className={(submitted && lineItem.adUnitID.length === 0 ? ' is-invalid-msg' : '')} data={adUnitData} texts={{ placeholder: adUnitsSelected }} clearSearchOnChange={true} inlineSearchInput={true} keepOpenOnSelect={true} onChange={onChangeAdUnit} mode={'hierarchical'} />

                    {submitted && lineItem.adUnitID.length === 0 ? (
                      <FormFeedback type="invalid">{'Ad Unit is required'}</FormFeedback>
                    ) : null}
                    {/* <Row className="multi-Adunit">
                        <Col md={6}>
                          <Input
                            placeholder="Search..."
                            type="text"
                            name="search"
                            autoComplete="off"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                          <ul style={{ pointerEvents: (props.selectedArchived) ? 'none' : 'unset' }}>
                            {adUnitData.filter(filterNames).map((adUnit, index) => {
                              return <li key={adUnit.value} onMouseOver={handleEditState(adUnit.value)} onClick={selectAdUnit(adUnit, index)} onMouseLeave={handleEditState('')} className="d-flex" style={{ backgroundColor: (hoverStateID === adUnit.value) ? '#556ee61f' : '', cursor: 'pointer' }}>
                                <Col md={9}>
                                  {adUnit.label}
                                </Col>
                                <Col md={3}>
                                  <div className=" text-sm-end" >
                                    {hoverStateID && hoverStateID === adUnit.value &&
                                      <span style={{ cursor: 'pointer' }}>   <i
                                        className="bx bx-comment-check font-size-24"
                                        id="edittooltip"
                                      ></i></span>
                                    }
                                  </div>
                                </Col>
                              </li>
                            })}
                            {adUnits.length === 0 &&
                              <h5 className="text-center mt-4">No Data</h5>}
                          </ul>
                        </Col>
                        <Col md={6}>
                          <ul style={{ height: '235px', minHeight: '235px', pointerEvents: (props.selectedArchived) ? 'none' : 'unset' ,border:(submitted && lineItem.adUnitID.length === 0 ? '1px solid red' : '')}} className={(submitted && lineItem.adUnitID.length === 0 ? ' is-invalid' : '')} >
                            {selectedAdUnitsData.map((adUnit, index) => {
                              return <li key={adUnit.value} className="d-flex">
                                <Col md={9}>
                                  {adUnit.label}
                                </Col>
                                <Col md={3}>
                                  <div className=" text-sm-end">
                                    <button type="button" className="btn-close text-sm-end" onClick={deSelectAdUnit(adUnit, index)} aria-label="Close"></button>
                                  </div>
                                </Col>
                              </li>
                            })}
                          </ul>
                          {submitted && lineItem.adUnitID.length === 0 ? (
                            <FormFeedback type="invalid">{'AdUnits are required'}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row> */}



                  </div>
                  <div className="mb-3">
                    <Label>CPM Rate</Label>
                    <Input
                      name="cPMRate"
                      type="text"
                      className='form-control' onKeyPress={allowOnlyNumbers}
                      value={lineItem.cPMRate} onChange={handleChange}
                      readOnly={props.selectedArchived}
                    />
                  </div>
                </Col>
              </Row>
            </div>

            <Row >
              <Col lg="12" className="p-0">
                <h4 className="card-title m-2">Settings</h4>
                <div className=" wizard clearfix vertical">
                  <div className="steps clearfix">
                    <ul>
                      <NavItem
                        className={classnames({ current: activeTab === 1 })}
                      >
                        <NavLink
                          className={classnames({ current: activeTab === 1 })}
                          onClick={() => {
                            setactiveTab(1)
                          }}
                        // disabled={!(passedSteps || []).includes(1)}
                        >
                          <span className="number">01</span>Flight Selection
                        </NavLink>
                      </NavItem>

                      <NavItem
                        className={classnames({ current: activeTab === 2 })}
                      >
                        <NavLink
                          className={classnames({ active: activeTab === 2 })}
                          onClick={() => {
                            setactiveTab(2)
                          }}
                        // disabled={!(passedSteps || []).includes(2)}
                        >
                          <span className="number ">02</span> Technology Selection
                        </NavLink>
                      </NavItem>

                      <NavItem
                        className={classnames({ current: activeTab === 3 })}
                      >
                        <NavLink
                          className={classnames({ active: activeTab === 3 })}
                          onClick={() => {
                            setactiveTab(3)
                          }}
                        // disabled={!(passedSteps || []).includes(4)}
                        >
                          <span className="number">03</span> Geo Targeting
                        </NavLink>
                      </NavItem>

                      <NavItem
                        className={classnames({ current: activeTab === 4 })}
                      >
                        <NavLink
                          className={classnames({ active: activeTab === 4 })}
                          onClick={() => {
                            setactiveTab(4)
                          }}
                        // disabled={!(passedSteps || []).includes(3)}
                        >
                          <span className="number">04</span> Contextual Targeting
                        </NavLink>
                      </NavItem>

                    </ul>
                  </div>
                  <div className="content clearfix ">
                    <TabContent activeTab={activeTab} className="body">
                      <TabPane tabId={1}>
                        <Flight_Selection selectedArchived={props.selectedArchived} selectedItems={getLineItemData} submitted={submitted} cappingSelectedItems={getCappingLineItemData} lineItemData={props.selectedLineItem} lineItemType={props.selectedLineItemType} selectedID={props.selectedID} tabId={activeTab} />
                      </TabPane>
                      <TabPane tabId={2}>
                        <div>
                          <Technology_Selection selectedArchived={props.selectedArchived} contextualData={contextualData} selectedItems={getLineItemData} targetedSelectedItems={getTechLineItemData} submitted={submitted} lineItemData={props.selectedLineItem} selectedID={props.selectedID} tabId={activeTab} />
                        </div>
                      </TabPane>
                      <TabPane tabId={3}>
                        <div>
                          <Geo_Targeting selectedArchived={props.selectedArchived} selectedItems={getLineItemData} submitted={submitted} lineItemData={props.selectedLineItem} targetedSelectedItems={getGeoLineItemData} selectedID={props.selectedID} tabId={activeTab} />
                        </div>
                      </TabPane>
                      <TabPane tabId={4}>
                        <div>
                          <Contextual_Targeting selectedArchived={props.selectedArchived} contextualData={contextualData} selectedItems={getLineItemData} targetedSelectedItems={getTargetingLineItemData} submitted={submitted} lineItemData={props.selectedLineItem} selectedID={props.selectedID} tabId={activeTab} />
                        </div>
                      </TabPane>

                    </TabContent>
                  </div>
                  <div className="actions clearfix">
                    <ul>
                      <li
                        className={
                          activeTab === 1 ? "previous disabled" : "previous"
                        }
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            toggleTab(activeTab - 1)
                          }}
                        >
                          Previous
                        </Link>
                      </li>
                      <li
                        className={activeTab === 4 ? "next disabled" : "next"}
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            toggleTab(activeTab + 1)
                          }}
                        >
                          Next
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
                &nbsp;&nbsp;
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>}
                &nbsp;&nbsp;
                {isEdit && !props.selectedArchived &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }

                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                    onClick={() => setNextTab(true)}
                  >
                    Save & Add Creative
                  </button>}
                {/* &nbsp;&nbsp;
                <button
                  type="submit"
                  className="btn btn-primary save-user"
                >
              
                </button> */}

                {nextTab && successMsg && creativeType === 'video' &&
                  <Link ref={nextRef} to={`/Creative?lineItemID=${successMsg.response.lineItemID}&lineItemName=${successMsg.response.lineItemName}&creativeType=${creativeType}&status=CreativeCreateByLineItem`}  > </Link>
                }
                {nextTab && successMsg && creativeType !== 'video' &&
                  <Link ref={nextRef} to={`/Creative_Weightage?lineItemID=${successMsg.response.lineItemID}&lineItemName=${successMsg.response.lineItemName}&creativeType=${creativeType}&status=CreativeCreateByLineItem`}  > </Link>
                }

              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
          <Toaster status="error" msg={errorMsg.message} />
        }
      </div>
      {errorMsg !== null && errorMsg !== '' && errorMsg.status && errorMsg.status === 500 &&
        <div className="p-0 justify-content-center d-flex">
          <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')} />
        </div>
      }
    </React.Fragment>
  );
};

export default Create_LI;