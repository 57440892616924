import {
  LOAD_PAGEURLS,
  NO_DATA_PAGEURLS,
    GET_PAGE_URLS,
    GET_PAGE_URLS_FAIL,
    GET_PAGE_URLS_SUCCESS,
    ADD_PAGE_URL,
    ADD_PAGE_URL_SUCCESS,
    ADD_PAGE_URL_ERROR,
    UPDATE_PAGE_URL,
    UPDATE_PAGE_URL_SUCCESS,
    UPDATE_PAGE_URL_ERROR,
    READ_PAGE_URL,
    READ_PAGE_URL_SUCCESS,
    STATUS_PAGE_URL,
    STATUS_PAGE_URL_SUCCESS,
    ARCHIVE_PAGE_URL ,
    ARCHIVE_PAGE_URL_SUCCESS

  } from "./actionTypes"

  export const loadPageUrls = Params => ({
    type: LOAD_PAGEURLS,
    payload:Params
  })

  export const noPageUrls = Params => ({
    type: NO_DATA_PAGEURLS,
    payload:Params
  })

  export const getPageUrls = () => ({
    type: GET_PAGE_URLS,
  })
  
  export const getPageUrlsSuccess = PageUrls => ({
    type: GET_PAGE_URLS_SUCCESS,
    payload: PageUrls,
  })

  export const getPageUrlsFail = error => ({
    type: GET_PAGE_URLS_FAIL,
    payload: error,
  })

  export const addNewPageUrl = PageUrl => ({
    type: ADD_PAGE_URL,
    payload: PageUrl,
  })
  
  export const addPageUrlSuccess = PageUrl => ({
    type: ADD_PAGE_URL_SUCCESS,
    payload: PageUrl,
  })
  
  export const addPageUrlFail = PageUrl => ({
    type: ADD_PAGE_URL_ERROR,
    payload: PageUrl,
  })

  export const updatePageUrl = PageUrl => ({
    type: UPDATE_PAGE_URL,
    payload: PageUrl,
  })
  
  export const updatePageUrlSuccess = PageUrl => ({
    type: UPDATE_PAGE_URL_SUCCESS,
    payload: PageUrl,
  })
  
  export const updatePageUrlFail = PageUrl => ({
    type: UPDATE_PAGE_URL_ERROR,
    payload: PageUrl,
  })

  export const readPageUrl = PageUrl => ({
    type: READ_PAGE_URL,
    payload: PageUrl,
  })

  export const readPageUrlSuccess = PageUrl => ({
    type: READ_PAGE_URL_SUCCESS,
    payload: PageUrl,
  })

  export const statusPageUrl = PageUrl => ({
    type: STATUS_PAGE_URL,
    payload: PageUrl,
  })

  export const statusPageUrlSuccess = PageUrl => ({
    type: STATUS_PAGE_URL_SUCCESS,
    payload: PageUrl,
  })

  export const archivePageUrl = PageUrl => ({
    type: ARCHIVE_PAGE_URL,
    payload: PageUrl,
  })

  export const archivePageUrlSuccess = PageUrl => ({
    type: ARCHIVE_PAGE_URL_SUCCESS,
    payload: PageUrl,
  })