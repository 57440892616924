/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback, Collapse, } from "reactstrap";
import axios from "axios";

import {
  Button,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";
import Creative_Selection from "./Creative_Selection";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// actions
import {
  addNewVideoCreative as onADDVideoCreative,
  updateVideoCreative as onUpdateVideoCreative,
  readVideoCreative as onReadVideoCreative,
  addVideoCreativeSuccess,
  addVideoCreativeFail,
  updateVideoCreativeSuccess,
  updateVideoCreativeFail,
} from "store/Demand/Creative/actions";

import classnames from "classnames";
import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";

const intialVcPriorities = [
  {
    priority: [{
      vastTag: '',
      vastName:'',
      preRoll:true,
      midRoll:true,
      postRoll:true,
      vastLock:false,
      price: '',
      vastRetry: '3',
      errorLimit: '5',
      vastTimeoutTime: '2000',
      maxRequestsPerMinute: 'Unlimited'
    }]
  }
]

const intialVideoCreative = {
  videoCreativeName: '',
  bannerAdTag: '',
  videoCreativeContent: [{ ...intialVcPriorities, priority: intialVcPriorities.priority }],
  advertiserID: '',
  ioGroupID: '',
  ioID: '',
  lineItemID: '',
  "typeFor":"Priorities",
}

const NewVideoCreative = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  console.log(props)

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 1000,
    extendedTimeOut: 1000
  };

  const [videoCreative, setVideoCreative] = useState({
    ...intialVideoCreative,
    advertiserID: '',
    ioGroupID: '',
    ioID: '',
    lineItemID: '',
    "typeFor":"Priorities",
    userID: userInfo.userID,
    userRole: userInfo.userRole,
    companyID: userInfo.companyID
  });

  const [newVideoGroupCreative, setNewVideoGroupCreative] = useState(true)
  const [submitted, setSubmitted] = useState(false);
  const [videoCreativeContent, setVideoCreativeContent] = useState([{
      priority: [{
        vastTag: '',
        vastName:'',
        vastLock:false,
        price: '',
        vastRetry: '3',
        errorLimit: '5',
        vastTimeoutTime: '2000',
        maxRequestsPerMinute: 'Unlimited'
      }]
  }])
  const [expanded, setExpanded] = useState('panel1');
  const [vastDismiss, setVastDismiss] = useState(false);
  const [groupDismiss, setGroupDismiss] = useState(false);
  const [submitAlert, setSubmitAlert] = useState(false);
  const [itemOpen, setItemOpen] = useState('')
  const [lockLimit, setLockLimit] = useState(false);

  const getCreativeData = async (selectedCreativeData) => {
    console.log(selectedCreativeData)
    setVideoCreative({ ...videoCreative, ...selectedCreativeData })
    console.log(videoCreative)
  }

  const addNewVideoGroup = () => {
    console.log(videoCreativeContent)
    const newVcGroup = {
      priority: [{
        vastTag: '',
        vastName:'',
        vastLock:false,
        price: '',
        vastRetry: '3',
        errorLimit: '5',
        vastTimeoutTime: '2000',
        maxRequestsPerMinute: 'Unlimited'
      }]
    }
    setVideoCreativeContent([...videoCreativeContent, newVcGroup]);
  }
  const addNewVideoVast = (index) => e => {
    console.log(index)
    const newVideoVast = {
      vastTag: '',
      vastName:'',
      vastLock:false,
      price: '',
      vastRetry: '3',
      errorLimit: '5',
      vastTimeoutTime: '2000',
      maxRequestsPerMinute: 'Unlimited'
    }
    const updatedVcPriorities = videoCreativeContent.map((vcGroup, j) => {
      if (index === j) {
        return { ...vcGroup, priority: [...vcGroup.priority, newVideoVast] }
      }
      return vcGroup;
    })
    setVideoCreativeContent(updatedVcPriorities);
    console.log(updatedVcPriorities);
  }

  const vastHandleChange = (vIndex, e, pIndex) => {
    console.log("G");
    const { name, value } = e.target;
    const updatedVastTags = videoCreativeContent.map((vcGroup, j) => {
      if (pIndex === j) {
        return {
          ...vcGroup, priority: vcGroup.priority.map((tag, k) => {
            if (vIndex === k) {
              return { ...tag, [name]: value }
            }
            return tag;
          })
        }
      }
      return vcGroup;
    })
    setVideoCreativeContent(updatedVastTags)
    setVideoCreative(videoCreative => ({ ...videoCreative, videoCreativeContent: updatedVastTags }))
  }

  const vastRollChange = (vIndex, e, pIndex) => {
    console.log(vIndex, e, pIndex)
    const { name, value } = e.target;
  
    const updatedVastTags = videoCreativeContent.map((vcGroup, j) => {
      if (pIndex === j) {
        return {
          ...vcGroup, priority: vcGroup.priority.map((tag, k) => {
            if (vIndex === k) {
              return { ...tag, [name]: e.target.checked }
            }
            return tag;
          })
        }
      }
      return vcGroup;
    })
    if(pIndex === 0){
      let lockCount = 0
      updatedVastTags[0].priority.map((tag, k) => {
        if(tag.vastLock){
          lockCount = lockCount + 1
        }
      })
      if(lockCount >= 3){
        setLockLimit(true)
      }else{
        setLockLimit(false)
      }
    }
    setVideoCreativeContent(updatedVastTags)
    setVideoCreative(videoCreative => ({ ...videoCreative, videoCreativeContent: updatedVastTags }))
  }

  const HandleChangeMaxReq = (vIndex, e, pIndex) => {
    console.log("G");
    const { name, value } = e.target;
    const updatedVastTags = videoCreativeContent.map((vcGroup, j) => {
      if (pIndex === j) {
        return {
          ...vcGroup, priority: vcGroup.priority.map((tag, k) => {
            if (vIndex === k) {
              if(name.includes("unlimited")){
                return { ...tag, maxRequestsPerMinute: 'Unlimited' }
              }
              else{
                return { ...tag, maxRequestsPerMinute: '0' }
              }
            }
            return tag;
          })
        }
      }
      return vcGroup;
    })
    setVideoCreativeContent(updatedVastTags)
    setVideoCreative(videoCreative => ({ ...videoCreative, videoCreativeContent: updatedVastTags }))
  }

  const vastNumChange = (vIndex, e, pIndex) => {
    console.log("Vast Number -----------------")
    const { name, value } = e.target;
    const updatedVastTags = videoCreativeContent.map((vcGroup, j) => {
      if (pIndex === j) {
        return {
          ...vcGroup, priority: vcGroup.priority.map((tag, k) => {
            if (vIndex === k) {
              return { ...tag, [name]: value < 1 ? 1 : value }
            }
            return tag;
          })
        }
      }
      return vcGroup;
    })
    setVideoCreativeContent(updatedVastTags)
    setVideoCreative(videoCreative => ({ ...videoCreative, videoCreativeContent: updatedVastTags }))
  }

  const vastRequestChange = (vIndex, e, pIndex) => {
    console.log("Vast Request Change===================")
    const { name, value } = e.target;

    const updatedVastTags = videoCreativeContent.map((vcGroup, j) => {
      if (pIndex === j) {
        return {
          ...vcGroup, priority: vcGroup.priority.map((tag, k) => {
            let tempVal = value < 1 ? 1 : value
            if (isNaN(tempVal)) {
              tempVal = "Unlimited"
            }
            if (vIndex === k) {
              return { ...tag, [name]: tempVal }
            }
            return tag;
          })
        }
      }
      return vcGroup;
    })
    setVideoCreativeContent(updatedVastTags)
    setVideoCreative(videoCreative => ({ ...videoCreative, videoCreativeContent: updatedVastTags }))
  }
  const handleClearVastData = (gindex, i) => (e) => {
    console.log(gindex, i)
    const vcPriorities = videoCreativeContent.map((item, index) => {
      console.log("Item===================", item.priority)
      if (index === gindex) {
        return {
          ...item,
          priority: item.priority.map((vTag, ind) => {
            if (ind === i) {
              console.log("this is my first vTag Value========", vTag)
              return {
                ...vTag,
                vastTag: ''
              }
            }
          })
        }
      }
      return { ...item }
    })
    console.log()
    setVideoCreativeContent([...vcPriorities])
    return;
  }

  const { errorMsg, successMsg, creativeVideoData } = useSelector(state => ({
    errorMsg: state.creatives.errorMsg,
    successMsg: state.creatives.successMsg,
    creativeVideoData: state.creatives.creative
  }));

  console.log(errorMsg, successMsg, creativeVideoData)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.statusCode === 200) {
        dispatch(addVideoCreativeSuccess(""));
        dispatch(addVideoCreativeFail(null));
        dispatch(updateVideoCreativeSuccess(""));
        dispatch(updateVideoCreativeFail(null));
        if (props.selectedCreativeData !== '' && props.selectedcreativeCreateStatus !== 'Duplicate') {
          props.creativeNewClose('Update')
        } else {
          props.creativeNewClose('Create')
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if(errorMsg.status && errorMsg.status === 500){
      }else{
        dispatch(addVideoCreativeSuccess(""));
        dispatch(addVideoCreativeFail(null));
        dispatch(updateVideoCreativeSuccess(""));
        dispatch(updateVideoCreativeFail(null));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addVideoCreativeSuccess(""));
    dispatch(addVideoCreativeFail(null));
    dispatch(updateVideoCreativeSuccess(""));
    dispatch(updateVideoCreativeFail(null));
        props.creativeNewClose('')
  };

  useEffect(() => {
    console.log(props.selectedCreativeData)
    if (props.selectedCreativeData !== '') {
      dispatch(onReadVideoCreative({ ...props.selectedCreativeData,userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner  }));
      if(props.selectedcreativeCreateStatus !== 'Duplicate'){
        setIsEdit(true)
      }
    }
  }, [])

  useEffect(() => {
    if (creativeVideoData !== null && props.selectedCreativeData !== '') {
      if(creativeVideoData.videoCreativeID === props.selectedCreativeData.creativeID){
        console.log(creativeVideoData)
        if (props.selectedcreativeCreateStatus === 'Duplicate') {
          delete creativeVideoData.videoCreativeCreateTime
          delete creativeVideoData.videoCreativeID
          delete creativeVideoData.videoCreativeModifyTime
          delete creativeVideoData.videoCreativeStatus
          delete creativeVideoData.isVideoCreativeArchived
         }
        setVideoCreative(videoCreative => ({ ...videoCreative, ...creativeVideoData }));
        const newVcGroup = creativeVideoData.videoCreativeContent
        console.log(newVcGroup)
        setVideoCreativeContent(newVcGroup);
        setNewVideoGroupCreative(false);
        console.log(videoCreative)
      }else{
        dispatch(onReadVideoCreative({ ...props.selectedCreativeData }));
      }
    }
  }, [creativeVideoData]);

  const handleChange = (e) => {
    console.log(e.target)
    const { name, value } = e.target;
    setVideoCreative(videoCreative => ({ ...videoCreative, [name]: value }))
  }

  const handleSubmit = async (e) => {
    console.log("submit click", videoCreative)
    e.preventDefault();
    setSubmitted(true);
    console.log("submit click", videoCreative)
    if (videoCreative.videoCreativeName && videoCreative.lineItemID && videoCreative.bannerAdTag && videoCreative.videoCreativeContent.length > 0) {
      console.log(props.creativeId)
      if (videoCreativeContent.findIndex(group => group.priority.findIndex(tag => tag.vastTag === '') > -1) > -1) {
        toastr.warning(' Unable to Save with empty Vast tag — check it out!')
        setSubmitAlert(true);
        return;
      }
      if (props.selectedCreativeData !== '' && props.selectedcreativeCreateStatus !== 'Duplicate') {
        dispatch(onUpdateVideoCreative({ ...videoCreative,videoCreativeName:videoCreative.videoCreativeName.trim(),userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner  }));
      }
      else {
        dispatch(onADDVideoCreative({ ...videoCreative,videoCreativeName:videoCreative.videoCreativeName.trim(),userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner  }));
      }

    }
  }

  const removeVast = (gindex, i) => (e) => {
    console.log(gindex, i)
    const vGroup = videoCreativeContent[gindex].priority[i];
    console.log(vGroup);
    if (vGroup.vastTag !== '') {
      const vcPriorities = videoCreativeContent.map((item, index) => {
        console.log("Item===================", item.priority)
        if (index === gindex) {
          return {
            ...item,
            priority: item.priority.map((vTag, ind) => {
              if (ind === i) {
                toastr.warning(' Unable to delete Vast tag, remove data to delete— check it out!')
                return {
                  ...vTag,
                  displayDismissAlert: true
                }
              }
              return vTag;
            })
          }

        }
        return { ...item }
      })
      console.log("VCPriorities=============", vcPriorities)
      setVideoCreativeContent([...vcPriorities])
      toastr.warning(' Unable to delete Vast tag, remove data to delete— check it out!')
      return setVastDismiss(true);

    }
    const uPriorities = videoCreativeContent.map((item, index) => {
      console.log("Item===================", item.priority)
      if (index === gindex) {
        console.log(item)
        console.log(i);
        return {
          ...item, priority: item.priority.filter((tag, t) => {
            return i !== t
          })
        }
      }
      return item;
    })
    setVideoCreativeContent(uPriorities)
  }
  const removePriorities = (index) => (e) => {
    console.log(index)
    if (videoCreativeContent[index].priority.findIndex(tag => {
      return tag.vastTag !== ''
    }) > -1) {
      toastr.warning(' Unable to delete Group, remove vast tag data to delete— check it out!')
      return setGroupDismiss(true);
    }
    const uPriorities = videoCreativeContent.filter((gp, ind) => {
      return ind !== index
    }).map((gpm, p) => {
      return {
        ...gpm,
      }
    })
    setVideoCreativeContent(uPriorities)
  }

  const openCollapsed = (panel) => (event, newExpanded) => {
    console.log(panel)
    setItemOpen(panel)
    setExpanded(newExpanded ? panel : false);
  };

  useEffect( () => {
    console.log("VideoCreative--------------", videoCreative)
    console.log(videoCreativeContent);

    if (props.selectedLineItem !== '') {
      setVideoCreative({ ...videoCreative, lineItemID: props.selectedLineItem.lineItemID })
    }
    if (props.creativeId) {
      const bodyCreative = {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner
    }
    axios.post("/v2.0/demand/VideoCreatives/read/" + props.creativeId, bodyCreative,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json',
            "Authorization": `Bearer ${userInfo.token}`
          }
        })
        .then((res) => {
          console.log("result is ==>", res)
          if (res.data) {
            console.log("response data in useeffect==================", res.data)
            // setVideoCreative(res.data)
            setVideoCreative({
              ...videoCreative,
              videoCreativeName: res.data.response.videoCreativeName,
              lineItemID: res.data.response.lineItemID,
              bannerAdTag: res.data.response.bannerAdTag,
              videoCreativeContent: res.data.response.videoCreativeContent,
            });

            const newVcGroup = res.data.response.videoCreativeContent
            setVideoCreativeContent(newVcGroup);
            setNewVideoGroupCreative(false);
            console.log(videoCreative)
          }
        })
        .catch((err) => {
          console.log("e", err)
        });
    }
  }, [])

  function allowOnlyNumbers(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code >= 48 && code < 58) && !(code >= 46 && code < 47)) { e.preventDefault(); }
  };

  return (
    <React.Fragment>
      <div className="col-12">

        <Form onSubmit={handleSubmit}>
          <Row form className="formBodyScroll" style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
            <Col xs={12}>
              <h6 className="text-muted">Video Creative Setup</h6>
              <hr></hr>
              <Creative_Selection selectedArchived={props.selectedArchived} creativeType={props.creativeType} selectedItems={getCreativeData} submitted={submitted} selectedCreativeData={props.selectedCreativeData} creativeStatus={isEdit} selectedLineItem={props.selectedLineItem} />
              <h6 className="text-muted">Video Template Setup</h6>
              <hr></hr>
              <div className="form-group">
                <Label >
                  Video Creative Banner Tag
                </Label>
                <Input type="textarea" id="textarea" 
                  rows="3" style={{ width: '100%' }}
                  value={videoCreative.bannerAdTag}
                  name='bannerAdTag'
                  onChange={handleChange}
                  className={'form-control' + (submitted && !videoCreative.bannerAdTag ? ' is-invalid' : '')}
                />
                {submitted && !videoCreative.bannerAdTag &&
                  <div className="invalid-feedback">Video Creative Banner Tag is required</div>
                }
              </div>

              <div className="mt-4 mb-4">
                <h5 >
                  Priorities
                </h5>

                {videoCreativeContent && videoCreativeContent.map((item, index) => {
                  console.log("group............Item", item, "videoCreativeContent==========", videoCreativeContent);
                  return (
                    <div className="videoCreativeContent-settings" key={index}>
                      <div className="accordion-item ml-0 mr-0 mb-4 mt-4">
                        <h2 className="accordion-header row m-0" id="headingOne">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: itemOpen !== ('panel' + (index)) }
                            )}
                            type="button"
                            onClick={openCollapsed('panel' + (index))}
                            style={{ cursor: "pointer" }}
                          >
                               <h5 className="heading5 mt-2">Priority {index + 1}</h5>
                            {
                              videoCreativeContent.length === 1 && <button className='btn  btn-danger disabled-rmg rem-grp-btn ' style={{ position: 'absolute', right: '3pc', margin: '1% 3% 1% auto', width: 'fit-content', background: '#dc3545' }} disabled>Remove Priority</button>
                            }
                            {
                              videoCreativeContent.length !== 1 && <Link to="#" className='btn btn-danger rem-grp-btn' style={{ position: 'absolute', right: '3pc', margin: '1% 3% 1% auto', width: 'fit-content', background: '#dc3545' }} onClick={removePriorities(index)}
                              >Remove Priority</Link>
                            }
                          </button>

                        </h2>
                        {/* isOpen={itemOpen === ('panel'+(index))} */}
                        <Collapse isOpen={true} className="accordion-collapse">
                          {
                            item.priority.map((subItem = {}, i) => {
                              return (
                                <div className="vast-settings" key={i}>
                                  <div className="accordion-body">
                                    <div >{subItem.vast}

                                      <div className="row form-group">
                                        <div className=' d-flex'>
                                          <h5 className="heading5 mt-1">
                                            Tag {i + 1}
                                          </h5>
                                          {index === 0 &&
                                              <div className="mb-1" style={{position:'absolute',right:'6.5pc'}}>
                                                <div className="form-check form-switch form-check-success form-switch-md  mt-1  " >
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    // id="vastLock" added by vasu
                                                    id = {`vastLock+${i+1}`}
                                                    name="vastLock"
                                                    checked={subItem.vastLock}
                                                    onChange={(e) => vastRollChange(i, e, index)}
                                                    disabled={lockLimit && !subItem.vastLock}
                                                  />
                                                  <label className="form-check-label  m-2 mt-1 " htmlFor={`vastLock+${i+1}`}>
                                                    <p className=" mt-1">Lock Vast</p>
                                                  </label>
                                                </div>
                                              </div>
                                            }
                                        </div>
                                        <div className='col-11 mb-3'>
                                          <Label > Vast Tag Name <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                          <Input
                                            type="text" name="vastName" onChange={(e) => vastHandleChange(i, e, index)}
                                            id="standard-full-width"
                                            className={'form-control' + (submitted && !subItem.vastName ? ' is-invalid' : '')}
                                            value={subItem.vastName}
                                          />
                                          {submitted && !subItem.vastName &&
                                            <div className="invalid-feedback">Vast Tag Name is required</div>
                                          }
                                        </div>
                                        <div className='col-1'></div>
                                        <div className='col-11 mb-3'>
                                          <Label >
                                            Vast Tag  <span style={{ color: 'red', fontSize: '15px' }}>*</span>
                                          </Label>
                                          <Input type="textarea" id="textarea"
                                            rows="2" value={subItem.vastTag}
                                            style={{ width: '100%' }}
                                            name="vastTag"
                                            onChange={(e) => vastHandleChange(i, e, index)}
                                            className={'form-control' + (submitted && !subItem.vastTag ? ' is-invalid' : '')}
                                          />
                                          {submitted && !subItem.vastTag &&
                                            <div className="invalid-feedback">Vast Tag is required</div>
                                          }
                                        </div>
                                        <div className='col-1'></div>
                                      </div>
                                      <div className=" row form-group mb-3">
                                        <div className='col-5'>
                                          <Label >  Price <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                          <Input
                                            type="text" onKeyPress={allowOnlyNumbers} name="price" onChange={(e) => vastHandleChange(i, e, index)}
                                            onBlur={(e) => vastNumChange(i, e, index)}
                                            id="standard-full-width"
                                            className={'form-control' + (submitted && !subItem.price ? ' is-invalid' : '')}
                                            value={subItem.price}
                                          />
                                          {submitted && !subItem.price &&
                                            <div className="invalid-feedback">Price is required </div>
                                          }
                                        </div>
                                        <div className='col-1'>
                                        </div>
                                        <div className='col-5'>
                                          <Label >  Vast Retry</Label>
                                          <Input
                                            type="text" onKeyPress={allowOnlyNumbers} name="vastRetry" onChange={(e) => vastHandleChange(i, e, index)}
                                            onBlur={(e) => vastNumChange(i, e, index)}
                                            value={subItem.vastRetry}
                                            className={'form-control' + (submitted && !subItem.vastRetry ? ' is-invalid' : '')}
                                          />
                                          {submitted && !subItem.vastRetry &&
                                            <div className="invalid-feedback">VastRetry is required</div>
                                          }
                                        </div>
                                      </div>
                                      <div className=" row form-group mb-3">
                                        <div className='col-5'>
                                          <Label >  Error Limit</Label>
                                          <Input
                                            type="text" onKeyPress={allowOnlyNumbers} name="errorLimit" onChange={(e) => vastHandleChange(i, e, index)}
                                            onBlur={(e) => vastNumChange(i, e, index)}
                                            value={subItem.errorLimit}
                                            className={'form-control' + (submitted && !subItem.errorLimit ? ' is-invalid' : '')}
                                          />
                                          {submitted && !subItem.errorLimit &&
                                            <div className="invalid-feedback">ErrorLimit is required</div>
                                          }
                                        </div>
                                        <div className='col-1'>

                                        </div>
                                        <div className='col-4'>
                                          <Label > Vast Timeout Time</Label>
                                          <Input
                                           type="text" onKeyPress={allowOnlyNumbers} name="vastTimeoutTime" onChange={(e) => vastHandleChange(i, e, index)}
                                            onBlur={(e) => vastNumChange(i, e, index)}
                                            value={subItem.vastTimeoutTime}
                                            className={'form-control' + (submitted && !subItem.vastTimeoutTime ? ' is-invalid' : '')}
                                          />
                                          {submitted && !subItem.vastTimeoutTime &&
                                            <div className="invalid-feedback">VastTimeoutTime is required</div>
                                          }

                                        </div>
                                        <div className='col-1 mt-5 ml-0'>
                                          <Label > (msec)</Label>
                                        </div>
                                      </div>
                                      <div className=" row form-group mb-3">
                                        <div className='col-5'>
                                          <Label > Max Requests PerMinute</Label>
                                          <div className="row">
                                        <Col md={6}>
                                            <div className="form-check mt-2 mb-2">
                                                <input
                                                    className="form-check-input "
                                                    type="radio"
                                                    name={`${index}${i}unlimited`}
                                                    id={`${index}${i}unlimited`}
                                                    checked={subItem.maxRequestsPerMinute === 'Unlimited'}
                                                    onChange={(e) => HandleChangeMaxReq(i, e, index)}
                                                />
                                                <label className="form-check-label  m-2 mt-1" htmlFor={`${index}${i}unlimited`}>Unlimited </label>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="form-check mt-2 mb-2">
                                                <input
                                                    className="form-check-input "
                                                    type="radio"
                                                    name={`${index}${i}custom`}
                                                    id={`${index}${i}custom`}
                                                    checked={subItem.maxRequestsPerMinute !== 'Unlimited'}
                                                    onChange={(e) => HandleChangeMaxReq(i, e, index)}
                                                />
                                                <label className="form-check-label  m-2 mt-1" htmlFor={`${index}${i}custom`}>Custom </label>
                                            </div>
                                        </Col>
                                        </div>
                                          <Input
                                            type="text" onKeyPress={allowOnlyNumbers} name="maxRequestsPerMinute" onChange={(e) => vastHandleChange(i, e, index)}
                                            onBlur={(e) => vastRequestChange(i, e, index)} readOnly={subItem.maxRequestsPerMinute === 'Unlimited'}
                                            value={subItem.maxRequestsPerMinute}
                                            className={'form-control' + (submitted && !subItem.maxRequestsPerMinute ? ' is-invalid' : '')}
                                          />
                                          {submitted && !subItem.maxRequestsPerMinute &&
                                            <div className="invalid-feedback">MaxRequestsPerMinute is required</div>
                                          }
                                        </div>
                                        <div className='col-4'>

                                        </div>
                                        <div className='col-3'>

                                        </div>
                                      </div>
                                      <div className='col-12 form-group mb-3'>
                                        <div className=" text-sm-end">
                                          <Link to="#" className='btn btn-danger ml-auto clear-vastdata' style={{ marginTop: '0pc', background: '#dc3545', left: '75%', width: '4pc', }} onClick={handleClearVastData(index, i)}
                                          >Clear</Link> &nbsp;&nbsp;
                                          {
                                            videoCreativeContent[index].priority.length === 1 && <button className='btn  btn-danger ml-auto disabled-rmg ' style={{ marginRight: '1pc', marginLeft: 'auto', marginTop: '0pc', background: '#dc3545', float: 'right'}} disabled>Remove Tag</button>
                                          }
                                          {
                                            videoCreativeContent[index].priority.length !== 1 && <Link to="#" className='btn btn-danger ml-auto ' style={{ marginRight: '1pc', marginLeft: 'auto', marginTop: '0pc', background: '#dc3545', float: 'right', }} onClick={removeVast(index, i)}
                                            >Remove Tag</Link>
                                          }
                                        </div>

                                        {/* {
                                        vastDismiss && subItem.displayDismissAlert && <Snackbar open={vastDismiss} autoHideDuration={2000} className="alert-centered" onClose={() => setVastDismiss(false)}>
                                          <Alert onClose={() => setVastDismiss(false)} severity="info" sx={{ width: '100%' }}>
                                            Unable to delete Vast tag, remove data to delete— check it out!
                                          </Alert>
                                        </Snackbar>

                                      } */}

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          }
                          <div className='row'>

                            {
                              videoCreativeContent[index].priority.length >= 8 && <button className='font-16 btn-block btn btn-primary' style={{ margin: '1% 2.5rem 1% auto', width: 'auto' }} disabled>Add New Tag</button>
                            }
                            {
                              videoCreativeContent[index].priority.length < 8 && <Link to="#" className='font-16 btn-block btn btn-primary' style={{ margin: '1% 2.5rem 1% auto', width: 'auto' }} onClick={addNewVideoVast(index)}  >  <i className="mdi mdi-plus-circle-outline me-1" />&nbsp;&nbsp;  Add New Tag </Link>

                            }

                          </div>
                        </Collapse>
                      </div>
                    </div>
                  )
                }
                )}
                <div className='row'>
                  <Button
                    color="primary"
                    className="font-16 btn-block btn btn-primary"
                    style={{ margin: '1% 3.0rem 1% auto', width: 'auto' }}
                    onClick={addNewVideoGroup}
                    disabled={videoCreativeContent && videoCreativeContent.length >= 5}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Add New Priority
                  </Button>
                  {/* <span className='mt-2 mb-2 btn ml-auto' style={{ marginRight: '3.0rem' }} onClick={addNewVideoGroup} >Add New Video Creative Priorities &nbsp;&nbsp; <i className="fa fa-plus"></i></span> */}
                </div>
              </div>
              {/* <div className="form-group row justify-content-center">
                {
                  submitAlert && <Snackbar open={submitAlert} autoHideDuration={3000} className="alert-centered" onClose={() => setSubmitAlert(false)}>
                    <Alert onClose={() => setSubmitAlert(false)} severity="error" sx={{ width: '100%' }}>
                      Unable to Save with empty Vast tag — check it out!
                    </Alert>
                  </Snackbar>
                }
              </div> */}
            </Col>
          </Row>
          <Row style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">
                {isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.creativeTypeClose}
                >
                  Back
                </button>
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
            <Toaster status="error" msg={errorMsg.message}/>
        }
      </div>
        {errorMsg !== null && errorMsg !== '' &&  errorMsg.status && errorMsg.status === 500 && 
            <div className="p-0 justify-content-center d-flex">
            <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')}/>
          </div>
        }
    </React.Fragment>
  )
}

NewVideoCreative.propTypes = {
  history: PropTypes.object,
}

export default NewVideoCreative