import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_AD_PUBLISHER,ADD_AD_PUBLISHER ,  GET_AD_PUBLISHER_TYPE,ADD_AD_PUBLISHER_TYPE ,UPDATE_AD_PUBLISHER,READ_AD_PUBLISHER,STATUS_AD_PUBLISHER,ARCHIVE_AD_PUBLISHER} from "./actionTypes"

import {
  loadActivitiesAdPublisher,
  noHouseAdPublisher,
  getAdPublisherSuccess,
  getAdPublisherFail,
  addAdPublisherSuccess,
  addAdPublisherFail,
  updateAdPublisherSuccess,
  updateAdPublisherFail,
  readAdPublisherSuccess,
  statusAdPublisherSuccess,
  archiveAdPublisherSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getAdPublisher,postAdPublisher,updateAdPublisher,readAdPublisher,statusChangeAdPublisher,archiveAdPublisher } from "../../../helpers/Backend_helper"

function* fetchAdPublisher({ payload: ctg }) {
  try {
    yield put(loadActivitiesAdPublisher(true))
    var getCtgList
      if(ctg){
        getCtgList = yield call(getAdPublisher,ctg)
        getCtgList.response.map((item, index) => {
          item.id = index + 1
      })
      if(getCtgList.response.length === 0){
        yield put(noHouseAdPublisher(true))
      }
        yield put(getAdPublisherSuccess(getCtgList))
      }
      else{
        getCtgList = []
        yield put(getAdPublisherSuccess(getCtgList))
      }

  } catch (error) {
    yield put(getAdPublisherFail(error))
  }
}

function* onReadAdPublisher({ payload: ctg }) {
  try {
    const response = yield call(readAdPublisher, ctg)
    yield put(readAdPublisherSuccess(response))
  } catch (error) {
    yield put(readAdPublisherSuccess(error))
  }
}

function* onAddAdPublisher({ payload: ctg }) {
  try {
    const response = yield call(postAdPublisher, ctg)
    if(response.success){
      yield put(addAdPublisherSuccess(response))
    }else{
      yield put(addAdPublisherFail(response))
    }
  } catch (error) {
    yield put(addAdPublisherFail(error))
  }
}

function* onUpdateAdPublisher({ payload: ctg }) {
  try {
    const response = yield call(updateAdPublisher, ctg)
    if(response.success){
      yield put(updateAdPublisherSuccess(response))
    }else{
      yield put(updateAdPublisherFail(response))
    }
  } catch (error) {
    yield put(updateAdPublisherFail(error))
  }
}

function* onStatusAdPublisher({ payload: ctg }) {
  try {
    const response = yield call(statusChangeAdPublisher, ctg)
    yield put(statusAdPublisherSuccess(response))
  } catch (error) {
    yield put(updateAdPublisherFail(error))
  }
}

function* onArchiveAdPublisher({ payload: ctg }) {
  try {
    const response = yield call(archiveAdPublisher, ctg)
    yield put(archiveAdPublisherSuccess(response))
  } catch (error) {
    yield put(archiveAdPublisherSuccess(error))
  }
}



function* adPublishersSaga() {
  yield takeEvery(GET_AD_PUBLISHER, fetchAdPublisher)
  yield takeEvery(ADD_AD_PUBLISHER, onAddAdPublisher)
  yield takeEvery(UPDATE_AD_PUBLISHER, onUpdateAdPublisher)
  yield takeEvery(READ_AD_PUBLISHER, onReadAdPublisher)
  yield takeEvery(STATUS_AD_PUBLISHER, onStatusAdPublisher)
  yield takeEvery(ARCHIVE_AD_PUBLISHER, onArchiveAdPublisher)
}

export default adPublishersSaga;
