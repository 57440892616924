import {
    LOAD_SUPPORT_TICKET_ADMINS,
    NO_DATA_SUPPORT_TICKET_ADMINS,
    GET_SUPPORT_TICKET_ADMINS,
    GET_SUPPORT_TICKET_ADMINS_FAIL,
    GET_SUPPORT_TICKET_ADMINS_SUCCESS,
    ADD_CHAT_ADMIN,
    ADD_CHAT_ADMIN_SUCCESS,
    ADD_CHAT_ADMIN_ERROR,
    STATUS_CHAT_ADMIN,
    STATUS_CHAT_ADMIN_SUCCESS,

  } from "./actionTypes"

  export const loadSupportTicketAdmins = Params => ({
    type: LOAD_SUPPORT_TICKET_ADMINS,
    payload:Params
  })

  export const noSupportTicketsAdmin = Params => ({
    type: NO_DATA_SUPPORT_TICKET_ADMINS,
    payload:Params
  })

  export const getSupportTicketAdmins = Params => ({
    type: GET_SUPPORT_TICKET_ADMINS,
    payload:Params
  })
  
  export const getSupportTicketAdminsSuccess = SupportTicketAdmins => ({
    type: GET_SUPPORT_TICKET_ADMINS_SUCCESS,
    payload: SupportTicketAdmins,
  })

  export const getSupportTicketAdminsFail = error => ({
    type: GET_SUPPORT_TICKET_ADMINS_FAIL,
    payload: error,
  })

  export const addNewChatAdmin = SupportTicketAdmin => ({
    type: ADD_CHAT_ADMIN,
    payload: SupportTicketAdmin,
  })
  
  export const addChatAdminSuccess = supportTicketAdmin => ({
    type: ADD_CHAT_ADMIN_SUCCESS,
    payload: supportTicketAdmin,
  })
  
  export const addChatAdminFail = supportTicketAdmin => ({
    type: ADD_CHAT_ADMIN_ERROR,
    payload: supportTicketAdmin,
  })

  export const statusSupportTicketAdmin = supportTicketAdmin => ({
    type: STATUS_CHAT_ADMIN,
    payload: supportTicketAdmin,
  })

  export const statusSupportTicketAdminSuccess = supportTicketAdmin => ({
    type: STATUS_CHAT_ADMIN_SUCCESS,
    payload: supportTicketAdmin,
  })

