import {
    LOAD_SUPPORT_TICKETS,
    NO_DATA_SUPPORT_TICKET_PUBLISHERS,
    GET_SUPPORT_TICKETS,
    GET_SUPPORT_TICKETS_FAIL,
    GET_SUPPORT_TICKETS_SUCCESS,
    ADD_SUPPORT_TICKET,
    ADD_SUPPORT_TICKET_SUCCESS,
    ADD_SUPPORT_TICKET_ERROR,
    ADD_CHAT,
    ADD_CHAT_SUCCESS,
    ADD_CHAT_ERROR,
  } from "./actionTypes"

  export const loadSupportTickets = Params => ({
    type: LOAD_SUPPORT_TICKETS,
    payload:Params
  })

  export const noSupportTicketsPublishers = Params => ({
    type: NO_DATA_SUPPORT_TICKET_PUBLISHERS,
    payload:Params
  })

  export const getSupportTickets = Params => ({
    type: GET_SUPPORT_TICKETS,
    payload:Params
  })
  
  export const getSupportTicketsSuccess = SupportTickets => ({
    type: GET_SUPPORT_TICKETS_SUCCESS,
    payload: SupportTickets,
  })

  export const getSupportTicketsFail = error => ({
    type: GET_SUPPORT_TICKETS_FAIL,
    payload: error,
  })

  export const addNewSupportTicket = SupportTicket => ({
    type: ADD_SUPPORT_TICKET,
    payload: SupportTicket,
  })
  
  export const addSupportTicketSuccess = supportTicket => ({
    type: ADD_SUPPORT_TICKET_SUCCESS,
    payload: supportTicket,
  })
  
  export const addSupportTicketFail = supportTicket => ({
    type: ADD_SUPPORT_TICKET_ERROR,
    payload: supportTicket,
  })

  export const addChat = addChat => ({
    type: ADD_CHAT,
    payload: addChat,
  })
  
  export const addChatSuccess = addChat => ({
    type: ADD_CHAT_SUCCESS,
    payload: addChat,
  })
  
  export const addChatFail = addChat => ({
    type: ADD_CHAT_ERROR,
    payload: addChat,
  })



