/* SCHEDULE_REPORT */
export const LOAD_SCHEDULE_REPORTS = "LOAD_SCHEDULE_REPORTS"
export const NO_DATA_SCHEDULE_REPORTS = "NO_DATA_SCHEDULE_REPORTS"
export const GET_SCHEDULE_REPORTS = "GET_SCHEDULE_REPORTS"
export const GET_SCHEDULE_REPORTS_SUCCESS = "GET_SCHEDULE_REPORTS_SUCCESS"
export const GET_SCHEDULE_REPORTS_FAIL = "GET_SCHEDULE_REPORTS_FAIL"

export const ADD_SCHEDULE_REPORT = "ADD_SCHEDULE_REPORT"
export const ADD_SCHEDULE_REPORT_SUCCESS = "ADD_SCHEDULE_REPORT_SUCCESS"
export const ADD_SCHEDULE_REPORT_ERROR = "ADD_SCHEDULE_REPORT_ERROR"

export const UPDATE_SCHEDULE_REPORT = "UPDATE_SCHEDULE_REPORT"
export const UPDATE_SCHEDULE_REPORT_SUCCESS = "UPDATE_SCHEDULE_REPORT_SUCCESS"
export const UPDATE_SCHEDULE_REPORT_ERROR = "UPDATE_SCHEDULE_REPORT_ERROR"

export const READ_SCHEDULE_REPORT = "READ_SCHEDULE_REPORT"
export const READ_SCHEDULE_REPORT_SUCCESS = "READ_SCHEDULE_REPORT_SUCCESS"

export const STATUS_SCHEDULE_REPORTS = "STATUS_SCHEDULE_REPORTS"
export const STATUS_SCHEDULE_REPORT_SUCCESS = "STATUS_SCHEDULE_REPORT_SUCCESS"

export const ARCHIVE_SCHEDULE_REPORT = "ARCHIVE_SCHEDULE_REPORT"
export const ARCHIVE_SCHEDULE_REPORT_SUCCESS = "ARCHIVE_SCHEDULE_REPORT_SUCCESS"