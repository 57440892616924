import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_PROXY_ADUNITS, ADD_PROXY_ADUNIT, UPDATE_PROXY_ADUNIT, READ_PROXY_ADUNIT, STATUS_PROXY_ADUNIT, ARCHIVE_PROXY_ADUNIT, GET_PROXY_ADUNITS_REPORTS, GET_REVENUE_DISPLAY_REPORTS, GET_PUBLISHER_REVENUE_DISPLAY_REPORTS, GET_AGENCY_REVENUE_DISPLAY_REPORTS, GET_PUBLISHER_AGENCY_REVENUE_DISPLAY_REPORTS, GET_ANALYTICS_DISPLAY_REPORTS, GET_PAGE_URL_21_REPORTS } from "./actionTypes"

import {
  getProxyAdUnitsSuccess,
  getProxyAdUnitsFail,
  addProxyAdUnitSuccess,
  addProxyAdUnitFail,
  updateProxyAdUnitSuccess,
  updateProxyAdUnitFail,
  readProxyAdUnitSuccess,
  statusProxyAdUnitSuccess,
  archiveProxyAdUnitSuccess,
  getProxyAdUnitsReportsSuccess,
  getProxyAdUnitsReportsFail,

  getRevenueDisplayReportsSuccess,
  getRevenueDisplayReportsFail,

  getPublisherRevenueDisplayReportsSuccess,
  getPublisherRevenueDisplayReportsFail,

  getAgencyRevenueDisplayReportsSuccess,
  getAgencyRevenueDisplayReportsFail,

  getPAgencyRevenueDisplayReportsSuccess,
  getPAgencyRevenueDisplayReportsFail,

  getAnalyticsDisplayReportsSuccess,
  getAnalyticsDisplayReportsFail,

  getPageUrl21ReportsSuccess,
  getPageUrl21ReportsFail,

  loadReports
} from "./actions"

//Include Both Helper File with needed methods
import { getProxyAdUnits, postProxyAdUnits, updateProxyAdUnits, readProxyAdUnits, statusChangeProxyAdUnits, archiveProxyAdUnits, getProxyAdUnitsReports, getHouseAdUnitsReports, getRevenueDisplayReports, getPublisherRevenueDisplayReports, getAgencyRevenueDisplayReports, getPAgencyRevenueDisplayReports, getAnalyticsDisplayReports, getPageUrl21Reports } from "../../../helpers/Backend_helper"

function* fetchProxyAdUnits({ payload: proxyAdUt }) {
  try {
    yield put(loadReports(true))
    var getProxyAdUList
    if (proxyAdUt) {
      getProxyAdUList = yield call(getProxyAdUnits, proxyAdUt)
      if (getProxyAdUList.statusCode === 200) {
        getProxyAdUList.response.data.map((item, index) => {
          item.id = index + 1
        })
        yield put(getProxyAdUnitsSuccess(getProxyAdUList))
      }
    }
    else {
      getProxyAdUList = []
      yield put(getProxyAdUnitsSuccess(getProxyAdUList))
    }
  } catch (error) {
    yield put(getProxyAdUnitsFail(error))
  }
}

function* fetchProxyAdUnitsReports({ payload: proxyAdUtReport }) {
  try {
    yield put(loadReports(true))
    var getProxyAdUReportList
    if (proxyAdUtReport && proxyAdUtReport.type === 'proxy') {
      delete proxyAdUtReport.type
      getProxyAdUReportList = yield call(getProxyAdUnitsReports, proxyAdUtReport)
      if (getProxyAdUReportList.dataAvailable === false) {
        if (Array.isArray(getProxyAdUReportList.response)) {
          yield put(getProxyAdUnitsReportsFail(null))
        } else {
          yield put(getProxyAdUnitsReportsFail(getProxyAdUReportList.response))
        }
      } else {
        getProxyAdUReportList.response.map((item, index) => {
          item.id = index + 1
          item.requests = parseInt(item.requests)
        })
        yield put(getProxyAdUnitsReportsSuccess(getProxyAdUReportList.response))
      }
    }
    else if (proxyAdUtReport && proxyAdUtReport.type === 'houseAd') {
      delete proxyAdUtReport.type
      getProxyAdUReportList = yield call(getHouseAdUnitsReports, proxyAdUtReport)
      if (getProxyAdUReportList.dataAvailable === false) {
        if (Array.isArray(getProxyAdUReportList.response)) {
          yield put(getProxyAdUnitsReportsFail(null))
        } else {
          yield put(getProxyAdUnitsReportsFail(getProxyAdUReportList.response))
        }
      } else {
        getProxyAdUReportList.response.map((item, index) => {
          item.id = index + 1
          item.requests = parseInt(item.requests)
          item.clicks = parseInt(item.clicks)
        })
        yield put(getProxyAdUnitsReportsSuccess(getProxyAdUReportList.response))
      }
    }
    else {
      getProxyAdUReportList = []
      yield put(getProxyAdUnitsReportsSuccess(getProxyAdUReportList))
    }


  } catch (error) {
    yield put(getProxyAdUnitsReportsFail(error))
  }
}


function* fetchRevenueDisplayReports({ payload: RevenueReport }) {
  try {
    yield put(loadReports(true))
    var getRevenueReportList
    if (RevenueReport) {
      getRevenueReportList = yield call(getRevenueDisplayReports, RevenueReport)
      console.log(getRevenueReportList)
      if (Array.isArray(getRevenueReportList.response)) {
          if(getRevenueReportList.response.length > 0){
            getRevenueReportList.response.map((item, index) => {
              item.id = index + 1
            })
            yield put(getRevenueDisplayReportsSuccess(getRevenueReportList.response))
          }else{
            yield put(getRevenueDisplayReportsSuccess([]))
          }
      }else{
        yield put(getRevenueDisplayReportsSuccess([]))
        yield put(getRevenueDisplayReportsFail(getRevenueReportList.response))
      }
    }
    else {
      getRevenueReportList = []
      yield put(getRevenueDisplayReportsSuccess(getRevenueReportList))
    }
  } catch (error) {
    yield put(getRevenueDisplayReportsFail(error))
  }
}

function* fetchPublisherRevenueDisplayReports({ payload: RevenueReport }) {
  try {
    yield put(loadReports(true))
    var getRevenueReportList
    if (RevenueReport) {
      getRevenueReportList = yield call(getPublisherRevenueDisplayReports, RevenueReport)
      console.log(getRevenueReportList)
      getRevenueReportList.response.map((item, index) => {
        item.id = index + 1
      })
      console.log()
      yield put(getPublisherRevenueDisplayReportsSuccess(getRevenueReportList.response))
    }
    else {
      getRevenueReportList = []
      yield put(getPublisherRevenueDisplayReportsSuccess(getRevenueReportList))
    }
  } catch (error) {
    yield put(getPublisherRevenueDisplayReportsFail(error))
  }
}

function* fetchAgencyRevenueDisplayReports({ payload: RevenueReport }) {
  try {
    yield put(loadReports(true))
    var getRevenueReportList
    if (RevenueReport) {
      getRevenueReportList = yield call(getAgencyRevenueDisplayReports, RevenueReport)
      console.log(getRevenueReportList)
      getRevenueReportList.response.map((item, index) => {
        item.id = index + 1
      })
      console.log()
      yield put(getAgencyRevenueDisplayReportsSuccess(getRevenueReportList.response))
    }
    else {
      getRevenueReportList = []
      yield put(getAgencyRevenueDisplayReportsSuccess(getRevenueReportList))
    }
  } catch (error) {
    yield put(getAgencyRevenueDisplayReportsFail(error))
  }
}

function* fetchPAgencyRevenueDisplayReports({ payload: RevenueReport }) {
  try {
    yield put(loadReports(true))
    var getRevenueReportList
    if (RevenueReport) {
      getRevenueReportList = yield call(getPAgencyRevenueDisplayReports, RevenueReport)
      console.log(getRevenueReportList)
      getRevenueReportList.response.map((item, index) => {
        item.id = index + 1
      })
      console.log()
      yield put(getPAgencyRevenueDisplayReportsSuccess(getRevenueReportList.response))
    }
    else {
      getRevenueReportList = []
      yield put(getPAgencyRevenueDisplayReportsSuccess(getRevenueReportList))
    }
  } catch (error) {
    yield put(getPAgencyRevenueDisplayReportsFail(error))
  }
}

function* fetchAnalyticsDisplayReports({ payload: AnalyticsReport }) {
  try {
    yield put(loadReports(true))
    var getAnalyticsReportList
    if (AnalyticsReport) {
      getAnalyticsReportList = yield call(getAnalyticsDisplayReports, AnalyticsReport)
      console.log(getAnalyticsReportList)
      getAnalyticsReportList.response.map((item, index) => {
        item.id = index + 1
      })
      console.log()
      yield put(getAnalyticsDisplayReportsSuccess(getAnalyticsReportList.response))
    }
    else {
      getAnalyticsReportList = []
      yield put(getAnalyticsDisplayReportsSuccess(getAnalyticsReportList))
    }
  } catch (error) {
    yield put(getAnalyticsDisplayReportsFail(error))
  }
}

function* fetchPageUrl21Reports({ payload: PageUrl21Report }) {
  try {
    yield put(loadReports(true))
    var getPageUrl21ReportList
    if (PageUrl21Report) {
      getPageUrl21ReportList = yield call(getPageUrl21Reports, PageUrl21Report)
      console.log(getPageUrl21ReportList)
      getPageUrl21ReportList.response.map((item, index) => {
        item.id = index + 1
      })
      console.log()
      yield put(getPageUrl21ReportsSuccess(getPageUrl21ReportList.response))
    }
    else {
      getPageUrl21ReportList = []
      yield put(getPageUrl21ReportsSuccess(getPageUrl21ReportList))
    }
  } catch (error) {
    yield put(getPageUrl21ReportsFail(error))
  }
}

function* onReadProxyAdUnit({ payload: proxyAdUt }) {
  try {
    const response = yield call(readProxyAdUnits, proxyAdUt)
    yield put(readProxyAdUnitSuccess(response))
  } catch (error) {
    yield put(readProxyAdUnitSuccess(error))
  }
}

function* onAddProxyAdUnit({ payload: proxyAdUt }) {
  try {
    const response = yield call(postProxyAdUnits, proxyAdUt)
    if (response.statusCode === 200) {
      yield put(addProxyAdUnitSuccess(response))
    } else {
      yield put(addProxyAdUnitFail(response))
    }
  } catch (error) {
    yield put(addProxyAdUnitFail(error))
  }
}

function* onUpdateProxyAdUnit({ payload: proxyAdUt }) {
  try {
    const response = yield call(updateProxyAdUnits, proxyAdUt)
    if (response.statusCode === 200) {
    yield put(updateProxyAdUnitSuccess(response))
    }
  } catch (error) {
    yield put(statusProxyAdUnitSuccess(error))
  }
}

function* onStatusProxyAdUnit({ payload: proxyAdUt }) {
  try {
    const response = yield call(statusChangeProxyAdUnits, proxyAdUt)
    if (response.statusCode === 200) {
    yield put(statusProxyAdUnitSuccess(response))
    }
  } catch (error) {
    yield put(updateProxyAdUnitFail(error))
  }
}

function* onArchiveProxyAdUnit({ payload: proxyAdUt }) {
  try {
    const response = yield call(archiveProxyAdUnits, proxyAdUt)
    if (response.statusCode === 200) {
    yield put(archiveProxyAdUnitSuccess(response))
    }
  } catch (error) {
    yield put(archiveProxyAdUnitSuccess(error))
  }
}

function* proxyAdUnitsSaga() {
  yield takeEvery(GET_PROXY_ADUNITS, fetchProxyAdUnits)
  yield takeEvery(ADD_PROXY_ADUNIT, onAddProxyAdUnit)
  yield takeEvery(UPDATE_PROXY_ADUNIT, onUpdateProxyAdUnit)
  yield takeEvery(READ_PROXY_ADUNIT, onReadProxyAdUnit)
  yield takeEvery(STATUS_PROXY_ADUNIT, onStatusProxyAdUnit)
  yield takeEvery(ARCHIVE_PROXY_ADUNIT, onArchiveProxyAdUnit)
  yield takeEvery(GET_PROXY_ADUNITS_REPORTS, fetchProxyAdUnitsReports)
  yield takeEvery(GET_REVENUE_DISPLAY_REPORTS, fetchRevenueDisplayReports)
  yield takeEvery(GET_PUBLISHER_REVENUE_DISPLAY_REPORTS, fetchPublisherRevenueDisplayReports)
  yield takeEvery(GET_AGENCY_REVENUE_DISPLAY_REPORTS, fetchAgencyRevenueDisplayReports)
  yield takeEvery(GET_PUBLISHER_AGENCY_REVENUE_DISPLAY_REPORTS, fetchPAgencyRevenueDisplayReports)
  yield takeEvery(GET_ANALYTICS_DISPLAY_REPORTS, fetchAnalyticsDisplayReports)
  yield takeEvery(GET_PAGE_URL_21_REPORTS, fetchPageUrl21Reports)
}

export default proxyAdUnitsSaga;
