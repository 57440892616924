import {
    LOAD_ACTIVITIES,
    NO_DATA_HOUSE_CATEGORY,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAIL,
    ADD_CATEGORY_SUCCESS,
    ADD_CATEGORY_ERROR,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_ERROR,
    READ_CATEGORY_SUCCESS,
    STATUS_CATEGORY_SUCCESS,
    ARCHIVE_CATEGORY_SUCCESS,
    GET_CATEGORY_TYPE_SUCCESS,
    GET_CATEGORY_TYPE_FAIL,
    ADD_CATEGORY_TYPE_SUCCESS,
    ADD_CATEGORY_TYPE_ERROR,
} from "./actionTypes"

const INIT_STATE = {
    ctgs: [],
    ctgTypes:[],
    error: {},
    loading:false,
    noData:false,
    successMsg: null,
    errorMsg: null,
    category: null,
    response:null,
    archiveMsg:null,
}

const categories = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_ACTIVITIES:
            return {
                ...state,
                loading: true,
            }
            case NO_DATA_HOUSE_CATEGORY:
                return {
                    ...state,
                    noData: true,
                }
        case GET_CATEGORY_SUCCESS:
            return {
                ...state,
                ctgs: action.payload,
                loading:false,
            }

        case GET_CATEGORY_FAIL:
            return {
                ...state,
                error: action.payload,
                loading:false,
            }
        case ADD_CATEGORY_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_CATEGORY_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_CATEGORY_SUCCESS' ? null : action.payload,
            }
            case GET_CATEGORY_TYPE_SUCCESS:
                return {
                    ...state,
                    ctgTypes: action.payload,
                    loading:false,
                }
    
            case GET_CATEGORY_TYPE_FAIL:
                return {
                    ...state,
                    error: action.payload,
                    loading:false,
                }
            case ADD_CATEGORY_TYPE_SUCCESS:
    
                return {
                    ...state,
                    successMsg: action.payload,
                }
            case ADD_CATEGORY_TYPE_ERROR:
                return {
                    ...state,
                    error: action.payload,
                    errorMsg: action.type === 'GET_CATEGORY_SUCCESS' ? null : action.payload,
                }
        case UPDATE_CATEGORY_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_CATEGORY_SUCCESS' ? null : action.payload,
            }
        case UPDATE_CATEGORY_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_CATEGORY_SUCCESS' ? null : action.payload,
            }
        case READ_CATEGORY_SUCCESS:
            return {
                ...state,
                category: action.payload,
            }
        case STATUS_CATEGORY_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_CATEGORY_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default categories