import axios from "axios";

//apply base url for axios
const API_URL = "";

const axiosApi = axios.create({
  baseURL: API_URL,
});

var APICalls = []

export async function APICallRequest() {
  // console.log(APICalls)
  let apiResponse = []
  if (APICalls.length > 0) {
    apiResponse = APICalls
    // const userInfo = JSON.parse(localStorage.getItem('authUser'));
    // var Headers =  {
    //   "Content-Type": "application/json",
    //   'Accept': "application/json",
    //   "Authorization": `Bearer ${userInfo.token}`
    // }
    //  apiResponse =  axiosApi
    // .post("/v1.0/getWhichAsChanged", APICalls, {headers: Headers})
    // .then(response => response.data);
  } else {
    apiResponse = []
  }
  // APICalls = []
  return apiResponse
}

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);

export async function demandPost(url, data, enityName, config = {}) {
  console.log(url, data, enityName)
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  var Headers = {
    "Content-Type": "application/json",
    'Accept': "application/json",
    "Authorization": `Bearer ${userInfo.token}`,
  }
  let body = {
    ...data, userID: userInfo.userID,
    userRole: userInfo.userRole,
    companyID: userInfo.companyID,
    name: userInfo.name, parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner,
  }
  if(userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency'){
    body = {...body,partnerName:userInfo.name,partnerID:userInfo.userID}
  }
  return axiosApi
    .post(url, body, { headers: Headers })
    .then(response => response.data)
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 503 || error.response.status === 500 || error.response.status === 501 || error.response.status === 504 || error.response.status === 505) {
          sessionStorage.setItem("DemandServiceDown", JSON.stringify(true));
        }
      }
    });
}

export async function supplyPost(url, data, enityName, config = {}) {

  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  console.log(userInfo,url, data, enityName)
  var Headers = {
    "Content-Type": "application/json",
    'Accept': "application/json",
    "Authorization": `Bearer ${userInfo.token}`,
  }
  let body = {
    ...data, userID: userInfo.userID,
    userRole: userInfo.userRole,
    companyID: userInfo.companyID,
    name: userInfo.name,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner,
  }
  return axiosApi
    .post(url, body, { headers: Headers })
    .then(response => response.data)
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 503 || error.response.status === 500 || error.response.status === 501 || error.response.status === 504 || error.response.status === 505) {
          sessionStorage.setItem("SupplyServiceDown", JSON.stringify(true));
        }
      }
    });
}

export async function houseAdsPost(url, data,enityName, config = {}) {
  console.log(url, data,enityName)
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const adminDetails = JSON.parse(localStorage.getItem('AdminDetails'));
  var Headers =  {
    "Content-Type": "application/json",
    'Accept': "application/json",
    "Authorization": `Bearer ${userInfo.token}`,
  }
    let body = { ...data,    userID:userInfo.userID,
            userRole:userInfo.userRole,
            companyID:userInfo.companyID,
            name:userInfo.name ,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner,
    }
    if(userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency'){
      body = {...body,partnerName:userInfo.name,partnerID:userInfo.userID}
      if(adminDetails){
        body = {...body, userlog: {
            userID: adminDetails.userID,
            userRole: adminDetails.userRole,
            companyID: adminDetails.companyID,
            name:adminDetails.name
          }}
    }
    }
  return axiosApi
    .post(userInfo.userRole === 'Partner' ? "/v2.0/BX"+url :userInfo.userRole === 'Agency' ? "/v2.0/BX"+url : "/v2.0/BX"+url, body, {headers: Headers})
    .then(response => response.data)
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 503 || error.response.status === 500 || error.response.status === 501 || error.response.status === 504 || error.response.status === 505) {
          sessionStorage.setItem("BXServiceDown", JSON.stringify(true));
        }
      }
    });
}

export async function scrappyPost(url, data, enityName, config = {}) {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  var Headers = {
    "Content-Type": "application/json",
    'Accept': "application/json",
    "Authorization": `Bearer ${userInfo.token}`,
    UserID: userInfo.userID,
    UserRole: userInfo.userRole,
  }
  let body = {
    ...data, userID: userInfo.userID,
    userRole: userInfo.userRole,
    companyID: userInfo.companyID, name: userInfo.name,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner,
  }
  return axiosApi
    .post(url, body, { headers: Headers })
    .then(response => response);
}

export async function reportPost(url, data, config = {}) {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  var Headers = {
    "Content-Type": "application/json",
    'Accept': "application/json",
    "Authorization": `Bearer ${userInfo.token}`
  }
  let body = {
    ...data, userID: userInfo.userID,
    userRole: userInfo.userRole,
    companyID: userInfo.companyID,
    name: userInfo.name,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner,
  }
  return axiosApi
    .post(url, body, { headers: Headers })
    .then(response => response.data);
}

export async function contextualSettingsPost(url, data, enityName, config = {}) {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  var Headers = {
    "Content-Type": "application/json",
    'Accept': "application/json",
    "Authorization": `Bearer ${userInfo.token}`
  }
  let body = {
    ...data, userID: userInfo.userID,
    userRole: userInfo.userRole,
    companyID: userInfo.companyID, name: userInfo.name,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner,
  }
  return axiosApi
    .post(url, body, { headers: Headers })
    .then(response => response.data);
}

export async function supportTicketPost(url, data, config = {}) {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  var Headers = {
    "Content-Type": "application/json",
    'Accept': "application/json",
    "Authorization": `Bearer ${userInfo.token}`
  }
  let body = {
    ...data, userID: userInfo.userID,
    userRole: userInfo.userRole,
    companyID: userInfo.companyID, name: userInfo.name, partnerName: userInfo.name,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner,
  }
  return axiosApi
    .post(url, body, { headers: Headers })
    .then(response => response.data);
}


export async function partnerManagementPost(url, data, enityName, config = {}) {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  if (enityName) {
    APICalls = []
    APICalls.push({
      "enitity": enityName
    }, {
      data: { ...data }
    })
  }
  console.log(APICalls)
  var Headers = {
    "Content-Type": "application/json",
    'Accept': "application/json",
    "Authorization": `Bearer ${userInfo.token}`
  }
  let body = {
    ...data, partnerCountry: '', partnerType: '', userID: userInfo.userID,
    userRole: userInfo.userRole,
    companyID: userInfo.companyID, name: userInfo.name,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner, userInfo: {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID, name: userInfo.name, parentID: userInfo.parentID, inventoryType: userInfo.inventoryType
    }
  }
  return axiosApi
    .post(url, body, { headers: Headers })
    .then(response => response.data);
}

export async function userManagementPost(url, data, enityName, config = {}) {
  console.log(data)
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  if (enityName) {
    APICalls = []
    APICalls.push({
      "enitity": enityName
    }, {
      data: { ...data }
    })
  }
  console.log(APICalls)
  var Headers = {
    "Content-Type": "application/json",
    'Accept': "application/json",
    "Authorization": `Bearer ${userInfo.token}`
  }
  let body = {
    ...data, userID: userInfo.userID,
    userRole: userInfo.userRole,
    companyID: userInfo.companyID, name: userInfo.name, parentID: userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner, userInfo: {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID, name: userInfo.name, parentID: userInfo.parentID, inventoryType: userInfo.inventoryType
    }
  }
  return axiosApi
    .post(url, body, { headers: Headers })
    .then(response => response.data);
}

export async function partnerManagementCreatePost(url, data, config = {}) {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  var Headers = {
    "Content-Type": "application/json",
    'Accept': "application/json",
    "Authorization": `Bearer ${userInfo.token}`
  }
  let body = {
    ...data, userInfo: {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID, name: userInfo.name, parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner, inventoryType: userInfo.inventoryType
    }
  }
  return axiosApi
    .post(url, body, { headers: Headers })
    .then(response => response.data);
}