/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Input,
    Spinner,
    Label, Progress
} from "reactstrap"

import DateRangePickers from 'helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import {
    getScheduleReports as onGetScheduleReports,
    statusScheduleReport as onStatusScheduleReport,
    archiveScheduleReport as onArchiveScheduleReport,
    statusScheduleReportSuccess,
    archiveScheduleReportSuccess,
    addScheduleReportFail,
    updateScheduleReportFail,
} from "store/PublisherConsole/ScheduleReport/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import Page_Url_Report from "./Page_Url_ReportMaster";


var selectedItem = []
var selectedData = ''
var selectedReportData = ""
var selectedIndex = []
var loader = false

const PageUrlReportMaster = props => {
    const dispatch = useDispatch();
    const [isRight, setIsRight] = useState(false);
    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
    const [statusSearch, setStatusSearch] = useState('Active')
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [isArchived, setIsArchived] = useState(false)
    const [selected, setSelected] = useState([])

    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());

    const changeDatePick = (event) => {
        console.log(event)
        console.log("start: ", event.startDate._d);
        console.log("end: ", event.endDate._d);
        setFromDate(event.startDate._d.toISOString());
        setToDate(event.endDate._d.toISOString());
    }

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    useEffect(() => {
        // loader = true
        dispatch(onGetScheduleReports(null));
    }, []);

    const selectRow = {
        mode: "checkbox",
        nonSelectable: [],
        selected: selected,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                setSelected([...selected, row.id])
                setSelectedRowsStatus(true)
                selectedIndex.push(row.scheduleID)
                selectedItem.push(row.scheduleID)
            } else {
                setSelected(selected.filter(x => x !== row.id))
                let index = selectedIndex.indexOf(row.scheduleID)
                selectedIndex.splice(index, 1)
                let index2 = selectedItem.indexOf(row.scheduleID)
                selectedItem.splice(index2, 1)
                if (selectedIndex.length === 0) {
                    setSelectedRowsStatus(false)
                }
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            const ids = rows.map(r => r.id);
            if (isSelect) {
                setSelected(ids)
                rows.map((item, index) => {
                    selectedIndex.push(item.scheduleID)
                    selectedItem.push(item.scheduleID)
                })
                setSelectedRowsStatus(true)
            } else {
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
                selectedItem = []
            }
        }
    };

    const statusChange = (e) => {
        setStatusSearch(e.target.value)
        dispatch(onGetScheduleReports({ filter: e.target.value }));
    }

    const handleClose = () => {
        setSelectedRowsStatus(false)
        selectedItem = []
        setSelected([])
    }

    const { scheduleReports } = useSelector(state => ({
        scheduleReports: [
            {
                "scheduleName": "Streamlyn daily report",
                "settings": {
                    "metrices": {
                        "PaidImpressions": true,
                        "FillRate": true,
                        "Revenue": true,
                        "TotalCodeServed": true,
                        "RPM": true
                    },
                    "range": "Day",
                    "filters": {
                        "AdUnitSize": [],
                        "DealType": [],
                        "AdUnitName": [],
                        "InventoryType": [],
                        "Device": [],
                        "Domain": []
                    },
                    "dimensions": {
                        "AdUnitSize": false,
                        "DealType": false,
                        "AdUnitName": true,
                        "InventoryType": false,
                        "Device": false,
                        "Domain": true
                    }
                },
                "newStatus": "Active",
                "partnerName": "White Media Solutions",
                "dateRange": "yesterday",
                "nextTime": "2024-02-07 00:05:27",
                "scheduleCreateTime": "10-01-2024",
                "isScheduleArchived": false,
                "frequency": "daily",
                "emails": [
                    "info@whitemediasolutions.com",
                    "shiva.santhosh@streamlyn.com"
                ],
                "scheduleStatus": true,
                "scheduleModifyTime": "29-01-2024",
                "scheduleID": 191,
                "id": 1
            },
            {
                "scheduleName": "Streamlyn Daily Report-1",
                "settings": {
                    "metrices": {
                        "PaidImpressions": true,
                        "FillRate": true,
                        "Revenue": true,
                        "TotalCodeServed": true,
                        "RPM": true
                    },
                    "range": "Day",
                    "filters": {
                        "AdUnitSize": [],
                        "DealType": [],
                        "AdUnitName": [],
                        "InventoryType": [],
                        "Device": [],
                        "Domain": []
                    },
                    "dimensions": {
                        "AdUnitSize": false,
                        "DealType": false,
                        "AdUnitName": true,
                        "InventoryType": false,
                        "Device": false,
                        "Domain": true
                    }
                },
                "newStatus": "Active",
                "partnerName": "White Media Solutions",
                "dateRange": "last7Days",
                "nextTime": "2024-02-07 00:05:31",
                "scheduleCreateTime": "01-02-2024",
                "isScheduleArchived": false,
                "frequency": "daily",
                "emails": [
                    "abhishek.tidke@streamlyn.com",
                    "john.britto@streamlyn.com",
                    "prashanta.ilager@streamlyn.com"
                ],
                "scheduleStatus": false,
                "scheduleModifyTime": "01-02-2024",
                "scheduleID": 201,
                "id": 2
            }
        ],
    }));

    const { loading } = useSelector(state => ({
        loading: state.scheduleReports.loading,
    }));

    const { scheduleReportsResponse } = useSelector(state => ({
        scheduleReportsResponse: state.scheduleReports.response,
    }));

    const { archiveScheduleReportsResponse } = useSelector(state => ({
        archiveScheduleReportsResponse: state.scheduleReports.archiveMsg,
    }));

    // const { errorMsg, successMsg } = useSelector(state => ({
    //   errorMsg: state.scheduleReports.errorMsg,
    //   successMsg: state.scheduleReports.successMsg,
    // }));

    console.log(scheduleReportsResponse, loading)
    // debugger
    useEffect(() => {
        if (scheduleReportsResponse !== null) {
            if (scheduleReportsResponse.success) {
                toastr.success('ScheduleReport Status Changed Successfully')
                // dispatch(onGetScheduleReports({ filter: statusSearch }));
                dispatch(statusScheduleReportSuccess(null));
                selectedItem = []
                setSelected([])
                setSelectedRowsStatus(false)

            }
        }
    }, [dispatch, scheduleReportsResponse]);

    useEffect(() => {
        if (archiveScheduleReportsResponse !== null) {
            if (archiveScheduleReportsResponse.success) {
                toastr.success('ScheduleReport Archived Successfully')
                // dispatch(onGetScheduleReports({ filter: statusSearch }));
                dispatch(archiveScheduleReportSuccess(null));
                selectedItem = []
                setSelected([])
                setSelectedRowsStatus(false)

            }
        }
    }, [dispatch, archiveScheduleReportsResponse]);

    const toggleRightCanvas = () => {
        selectedData = ''
        selectedReportData = ""
        dispatch(addScheduleReportFail(""));
        dispatch(updateScheduleReportFail(""));
        // dispatch(onGetScheduleReports({ filter: statusSearch }));
        setIsRight(!isRight);
    };

    const toggleEnableScroll = () => {
        setIsEnableScroll(!isEnableScroll);
    };

    const toggleDrawer = (close, scheduleReport, action) => (event) => {
        console.log(event)
        if (event === 'Create') {
            toastr.success('Analytics Report Saved Successfully!...')

        }
        if (event === 'Update') {
            toastr.success('Analytics Report Updated Successfully!...')

        }
        selectedData = {
            "requestBody": {
                "dimensions": {
                    "partner_name": true,
                    "publisher_name": true,
                    "ad_unit_name": true,
                    "country": false,
                    "url": false,
                    "device": false
                },
                "startDate": "2024-01-07",
                "endDate": "2024-02-06",
                "filters": {
                    "partner_name": "",
                    "publisher_name": "",
                    "ad_unit_name": "",
                    "country": "",
                    "url": "",
                    "device": ""
                },
                "metrices": {
                    "requests": true,
                    "impressions": false,
                    "refreshRequests": true,
                    "refreshImpressions": true,
                    "click": true,
                    "refreshClick": false
                },
                "range": "monthly",
                "type": "proxy",
                "userID": 242,
                "userRole": "Root",
                "companyID": 84,
                "name": "rootUser"

            }
        }
        selectedReportData = action
        setIsArchived(scheduleReport.isScheduleArchived)
        // dispatch(onGetScheduleReports({ filter: statusSearch }));
        dispatch(addScheduleReportFail(""));
        dispatch(updateScheduleReportFail(""));
        setIsRight(close);
    };

    // useEffect(() => {
    //   if (scheduleReports !== null) {
    //     // loader = true
    //     dispatch(onGetScheduleReports({ filter: statusSearch }));
    //   }
    // }, []);

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const scheduleReportListColumns = [
        {
            text: "ID",
 headerClasses:"ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        //   {
        //     text: "ID",
        //     dataField: "scheduleID",
        //     sort: true,
        //     formatter: (cellContent, scheduleReport) => (
        //       <>
        //         <span>{scheduleReport.scheduleID}</span>
        //       </>
        //     ),
        //   },
        {
            dataField: "scheduleCreateTime",
            text: "Queried Date",
            sort: true,
        },

        {
            text: "Report Name",
            dataField: "scheduleName",
            sort: true,
        },
        {
            text: "Date Range",
            dataField: "dateRange",
            sort: true,
        },
        {
            text: "Range",
            dataField: "frequency",
            sort: true,
        },
        {
            dataField: "ReportStatus",
            text: "Status",
            sort: true,
            formatter: (cellContent, scheduleReport) => (
                <>
                    {scheduleReport.scheduleStatus &&
                        <span className={"active-state"}>Report is Available</span>
                    }
                    {!scheduleReport.scheduleStatus &&
                        <Progress animated color="success" value="75" />
                    }

                </>
            ),
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',
            headerAlign: 'center',

            // eslint-disable-next-line react/display-name
            formatter: (cellContent, scheduleReport) => (
                <span  >
                    <span className="text-success " onClick={toggleDrawer(true, scheduleReport, 'run')}>
                        <i
                            className="mdi mdi-play font-size-18"
                            id="edittooltip"
                        ></i>
                    </span> &nbsp;&nbsp;
                    <span className="text-success " onClick={toggleDrawer(true, scheduleReport, 'edit')}>
                        <i
                            className="mdi mdi-pencil font-size-18"
                            id="edittooltip"
                        ></i>
                    </span>
                </span>
            ),
        },
    ];

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );


    const pageOptions = {
        paginationSize: 4,
        pageStartIndex: 1,
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };




    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const archiveScheduleReport = async () => {
        dispatch(onArchiveScheduleReport({ idList: selectedItem }));
    }

    const statusScheduleReport = (state) => (event) => {
        console.log(state)
        dispatch(onStatusScheduleReport({ idList: selectedItem, scheduleStatus: state }));
    }

    console.log(scheduleReports)

    // if (scheduleReports.length > 0) {
    //   // loader = false
    //   scheduleReports.map((item, index) => {
    //     if (item.isScheduleArchived) {
    //       selectRow.nonSelectable.push(index + 1)
    //     }
    //   })
    // }



    return (
        <React.Fragment>
            {/* <Sidebar />
        <Header />
        <div className="main-content"> */}
            <div className="page-content">
                <Helmet>
                    <title>Report | Bidsxchange</title>
                </Helmet>
                {/* {loading &&
              <Spinner className="ms-2 loader" color="primary" />
            } */}
                <Container fluid style={{ pointerEvents: (loading) ? 'none' : 'unset' }}>
                    {/* Render Breadcrumbs */}
                    <div className="row mb-2 d-sm-flex  justify-content-between">
                        <Breadcrumbs title="Reporting" breadcrumbItem="Page URL Report" />
                        <div className=" text-sm-end">
                            <Button
                                color="primary"
                                className="font-16 btn-block btn btn-primary"
                                onClick={toggleRightCanvas}
                            >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                Query Report
                            </Button>
                        </div>
                    </div>
                    {/* <Card>
                  <CardBody aria-hidden="true">
                    <Row >
                      <Col sm="2">
                        <Label className="form-label">Status</Label>
                        <div className=" text-sm-end">
                          <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                            <option value=''> All</option>
                            <option value='Active' > Active</option>
                            <option value='Inactive'> InActive</option>
                            <option value='Archived'> Archived</option>
                          </Input>
                        </div>
                      </Col>
                      <Col sm="4" className="date-range">
                        <Label className="form-label">Date Range</Label> <br />
                        <DateRangePickers datePick={changeDatePick} />
                      </Col>
                    </Row>
                  </CardBody>
                </Card> */}
                    {loading &&
                        <Spinner className="ms-2 loader" color="primary" />
                    }
                    {!loading &&
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody aria-hidden="true">
                                        <ToolkitProvider
                                            keyField={keyField}
                                            data={scheduleReports}
                                            columns={scheduleReportListColumns}
                                            // bootstrap4
                                            search
                                        >
                                            {toolkitProps => (
                                                <React.Fragment>
                                                    <Row className="mb-2">
                                                        <Col sm="8">
                                                            <div className="search-box ms-2 mb-2 d-inline-block">
                                                                <div className="position-relative">
                                                                    <SearchBar {...toolkitProps.searchProps} />
                                                                    <i className="bx bx-search-alt search-icon" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>

                                                        <Col xl="12" className="table-responsive">
                                                            <div >
                                                                <BootstrapTable
                                                                    keyField={keyField}
                                                                    responsive
                                                                    bordered={false}
                                                                    striped={false}
                                                                    defaultSorted={defaultSorted}
                                                                    selectRow={selectRow}
                                                                    classes={
                                                                        "table align-middle table-nowrap"
                                                                    }
                                                                    headerWrapperClasses={"thead-light"}
                                                                    {...toolkitProps.baseProps}
                                                                    pagination={paginationFactory(pageOptions)}
                                                                    noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                // ref={node}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                            )}
                                        </ToolkitProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </Container>
            </div>
            {/* </div> */}

            <Offcanvas isOpen={isRight} direction='end'  >
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {'Query Report'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0" style={{    background: "linear-gradient(16deg, #183d4a 40%, rgb(26 28 53) 88%)"}}>
                    <Page_Url_Report closeCanvas={toggleDrawer(false, '', '')} selectedScheduleReport={selectedData} selectedReportForRun={selectedReportData} selectedArchived={isArchived} />
                </OffcanvasBody>
            </Offcanvas>
        </React.Fragment>
    )
}

PageUrlReportMaster.propTypes = {}

export default PageUrlReportMaster