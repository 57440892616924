import {
  LOAD_ACTIVITIES,
  NO_DATA,
  GET_NETWORK_SETTINGS,
  GET_NETWORK_SETTINGS_FAIL,
  GET_NETWORK_SETTINGS_SUCCESS,
  ADD_NETWORK_SETTING,
  ADD_NETWORK_SETTING_SUCCESS,
  ADD_NETWORK_SETTING_ERROR,
  UPDATE_NETWORK_SETTING,
  UPDATE_NETWORK_SETTING_SUCCESS,
  UPDATE_NETWORK_SETTING_ERROR, 
  READ_NETWORK_SETTING,
  READ_NETWORK_SETTING_SUCCESS,
  STATUS_NETWORK_SETTING,
  STATUS_NETWORK_SETTING_SUCCESS,
  ARCHIVE_NETWORK_SETTING,
  ARCHIVE_NETWORK_SETTING_SUCCESS

} from "./actionTypes"

export const loadActivitiesNetworkSetting = Params => ({
  type: LOAD_ACTIVITIES,
  payload: Params
})

export const noDataNetworkSetting = Params => ({
  type: NO_DATA,
  payload: Params
})

export const getNetworkSetting = Params => ({
  type: GET_NETWORK_SETTINGS,
  payload: Params
})

export const getNetworkSettingsSuccess = NetworkSettings => ({
  type: GET_NETWORK_SETTINGS_SUCCESS,
  payload: NetworkSettings,
})

export const getNetworkSettingsFail = error => ({
  type: GET_NETWORK_SETTINGS_FAIL,
  payload: error,
})

export const addNewNetworkSetting = NetworkSetting => ({
  type: ADD_NETWORK_SETTING,
  payload: NetworkSetting,
})

export const addNetworkSettingSuccess = NetworkSetting => ({
  type: ADD_NETWORK_SETTING_SUCCESS,
  payload: NetworkSetting,
})

export const addNetworkSettingFail = NetworkSetting => ({
  type: ADD_NETWORK_SETTING_ERROR,
  payload: NetworkSetting,
})

export const updateNetworkSetting = NetworkSetting => ({
  type: UPDATE_NETWORK_SETTING,
  payload: NetworkSetting,
})

export const updateNetworkSettingSuccess = NetworkSetting => ({
  type: UPDATE_NETWORK_SETTING_SUCCESS,
  payload: NetworkSetting,
})

export const updateNetworkSettingFail = NetworkSetting => ({
  type: UPDATE_NETWORK_SETTING_ERROR,
  payload: NetworkSetting,
})

export const readNetworkSetting = NetworkSetting => ({
  type: READ_NETWORK_SETTING,
  payload: NetworkSetting,
})

export const readNetworkSettingSuccess = NetworkSetting => ({
  type: READ_NETWORK_SETTING_SUCCESS,
  payload: NetworkSetting,
})

export const statusNetworkSetting = NetworkSetting => ({
  type: STATUS_NETWORK_SETTING,
  payload: NetworkSetting,
})

export const statusNetworkSettingSuccess = NetworkSetting => ({
  type: STATUS_NETWORK_SETTING_SUCCESS,
  payload: NetworkSetting,
})

export const archiveNetworkSetting = NetworkSetting => ({
  type: ARCHIVE_NETWORK_SETTING,
  payload: NetworkSetting,
})

export const archiveNetworkSettingSuccess = NetworkSetting => ({
  type: ARCHIVE_NETWORK_SETTING_SUCCESS,
  payload: NetworkSetting,
})