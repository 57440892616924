/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback } from "reactstrap";

//redux
import { MultiSelect } from "react-multi-select-component";
import { useSelector, useDispatch } from "react-redux";
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

function Blocking(props) {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [tags, setTags] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  console.log(props)

  const [deviceData, setDeviceData] = useState([])
  const [domainData, setDomainData] = useState([])
  const [appData, setAppData] = useState([])

  const [deviceState, setDeviceState] = useState(true)
  const [domainState, setDomainState] = useState(true)
  const [appState, setAppState] = useState(true)

  const [userAgentData, setUserAgentData] = useState([])
  const [ipv4Data, setIPV4Data] = useState([])
  const [ipv6Data, setIPV6Data] = useState([])

  const [userAgentState, setUserAgentState] = useState(true)
  const [ipv4State, setIPV4State] = useState(true)
  const [ipv6State, setIPV6State] = useState(true)

  const [networkSetting, setNetworkSetting] = useState({
    "domainControlIn": [],
    "domainControlEx": [],
    "appControlIn": [],
    "appControlEx": [],
    "deviceControlIn": [],
    "deviceControlEx": [],
    "ipv4ControlIn": [],
    "ipv4ControlEx": [],
    "ipv6ControlIn": [],
    "ipv6ControlEx": [],
    "userAgentControlIn": [],
    "userAgentControlEx": [],
  })

  const [targetStates, setTargetStates] = useState({
    domains: false,
    apps: false,
    urls: false,
  })

  const [deviceTreeSelect, setDeviceTreeSelect] = useState({
    checked: [],
    expanded: [],
  })
  const [domainTreeSelect, setDomainTreeSelect] = useState({
    checked: [],
    expanded: [],
  })
  const [appTreeSelect, setAppTreeSelect] = useState({
    checked: [],
    expanded: [],
  })

  const [userAgentTreeSelect, setUserAgentTreeSelect] = useState({
    checked: [],
    expanded: [],
  })
  const [ipv4TreeSelect, setIPV4TreeSelect] = useState({
    checked: [],
    expanded: [],
  })
  const [ipv6TreeSelect, setIPV6TreeSelect] = useState({
    checked: [],
    expanded: [],
  })


  const [isDeviceOptionsOpen, setIsDeviceOptionsOpen] = useState(false);
  const [isDomainOptionsOpen, setIsDomainOptionsOpen] = useState(false);
  const [isAppOptionsOpen, setIsAppOptionsOpen] = useState(false);
  const [isUserAgentOptionsOpen, setIsUserAgentOptionsOpen] = useState(false);
  const [isIPV4OptionsOpen, setIsIPV4OptionsOpen] = useState(false);
  const [isIPV6OptionsOpen, setIsIPV6OptionsOpen] = useState(false);

  const devicetoggleOptions = () => {
    setIsDeviceOptionsOpen(!isDeviceOptionsOpen);
  };

  const domainToggleOptions = () => {
    setIsDomainOptionsOpen(!isDomainOptionsOpen);
  };

  const appToggleOptions = () => {
    setIsAppOptionsOpen(!isAppOptionsOpen);
  };

  const userAgenttoggleOptions = () => {
    setIsUserAgentOptionsOpen(!isUserAgentOptionsOpen);
  };

  const ipv4ToggleOptions = () => {
    setIsIPV4OptionsOpen(!isIPV4OptionsOpen);
  };

  const ipv6ToggleOptions = () => {
    setIsIPV6OptionsOpen(!isIPV6OptionsOpen);
  };


  useEffect(() => {
    if (props.tabId !== 3) {
      setIsDeviceOptionsOpen(false);
      setIsDomainOptionsOpen(false);
      setIsAppOptionsOpen(false);
      setIsUserAgentOptionsOpen(false);
      setIsIPV4OptionsOpen(false);
      setIsIPV6OptionsOpen(false);
    }
  }, [props.tabId])


  useEffect(() => {
    if (props.networkSettingData !== '') {
      setNetworkSetting(props.networkSettingData)
      setDeviceTreeSelect(deviceTreeSelect => ({ ...deviceTreeSelect, checked: props.networkSettingData.deviceControlIn.length > 0 ? props.networkSettingData.deviceControlIn : props.networkSettingData.deviceControlEx.length > 0 ? props.networkSettingData.deviceControlEx : [] }))
      setDomainTreeSelect(domainTreeSelect => ({ ...domainTreeSelect, checked: props.networkSettingData.domainControlIn.length > 0 ? props.networkSettingData.domainControlIn : props.networkSettingData.domainControlEx.length > 0 ? props.networkSettingData.domainControlEx : [] }))
      setAppTreeSelect(appTreeSelect => ({ ...appTreeSelect, checked: props.networkSettingData.appControlIn.length > 0 ? props.networkSettingData.appControlIn : props.networkSettingData.appControlEx.length > 0 ? props.networkSettingData.appControlEx : [] }))

      setDeviceState(props.networkSettingData.deviceControlIn.length > 0 ? true : props.networkSettingData.deviceControlEx.length > 0 ? false : true)
      setDomainState(props.networkSettingData.domainControlIn.length > 0 ? true : props.networkSettingData.domainControlEx.length > 0 ? false : true)
      setAppState(props.networkSettingData.appControlIn.length > 0 ? true : props.networkSettingData.appControlEx.length > 0 ? false : true)

      setUserAgentTreeSelect(userAgentTreeSelect => ({ ...userAgentTreeSelect, checked: props.networkSettingData.userAgentControlIn.length > 0 ? props.networkSettingData.userAgentControlIn : props.networkSettingData.userAgentControlEx.length > 0 ? props.networkSettingData.userAgentControlEx : [] }))
      setIPV4TreeSelect(ipv4TreeSelect => ({ ...ipv4TreeSelect, checked: props.networkSettingData.ipv4ControlIn.length > 0 ? props.networkSettingData.ipv4ControlIn : props.networkSettingData.ipv4ControlEx.length > 0 ? props.networkSettingData.ipv4ControlEx : [] }))
      setIPV6TreeSelect(ipv6TreeSelect => ({ ...ipv6TreeSelect, checked: props.networkSettingData.ipv6ControlIn.length > 0 ? props.networkSettingData.ipv6ControlIn : props.networkSettingData.ipv6ControlEx.length > 0 ? props.networkSettingData.ipv6ControlEx : [] }))

      setUserAgentState(props.networkSettingData.userAgentControlIn.length > 0 ? true : props.networkSettingData.userAgentControlEx.length > 0 ? false : true)
      setIPV4State(props.networkSettingData.ipv4ControlIn.length > 0 ? true : props.networkSettingData.ipv4ControlEx.length > 0 ? false : true)
      setIPV6State(props.networkSettingData.ipv6ControlIn.length > 0 ? true : props.networkSettingData.ipv6ControlEx.length > 0 ? false : true)

      setTargetStates(targetStates => ({
        ...targetStates,
        devices: props.networkSettingData.deviceControlIn.length > 0 || props.networkSettingData.deviceControlEx.length > 0 ? true : false,
        domains: props.networkSettingData.domainControlIn.length > 0 || props.networkSettingData.domainControlEx.length > 0 ? true : false,
        apps: props.networkSettingData.appControlIn.length > 0 || props.networkSettingData.appControlEx.length > 0 ? true : false,
        userAgents: props.networkSettingData.userAgentControlIn.length > 0 || props.networkSettingData.userAgentControlEx.length > 0 ? true : false,
        ipv4s: props.networkSettingData.ipv4ControlIn.length > 0 || props.networkSettingData.ipv4ControlEx.length > 0 ? true : false,
        ipv6s: props.networkSettingData.ipv6ControlIn.length > 0 || props.networkSettingData.ipv6ControlEx.length > 0 ? true : false,
      }));

      props.targetedSelectedItems({
        devices: props.networkSettingData.deviceControlIn.length > 0 || props.networkSettingData.deviceControlEx.length > 0 ? true : false,
        domains: props.networkSettingData.domainControlIn.length > 0 || props.networkSettingData.domainControlEx.length > 0 ? true : false,
        apps: props.networkSettingData.appControlIn.length > 0 || props.networkSettingData.appControlEx.length > 0 ? true : false,
        ipv4s: props.networkSettingData.ipv4ControlIn.length > 0 || props.networkSettingData.ipv4ControlEx.length > 0 ? true : false,
        ipv6s: props.networkSettingData.ipv6ControlIn.length > 0 || props.networkSettingData.ipv6ControlEx.length > 0 ? true : false,
      })
    }
  }, []);

  useEffect(() => {
    if (props.networkBlockData.deviceControl && props.networkBlockData.deviceControl.length > 0) {
      setDeviceData(objectDeviceModal(props.networkBlockData.deviceControl))
    }
    if (props.networkBlockData.domainControl && props.networkBlockData.domainControl.length > 0) {
      setDomainData(objectDomainModal(props.networkBlockData.domainControl))
    }

    if (props.networkBlockData.appControl && props.networkBlockData.appControl.length > 0) {
      setAppData(objectAppModal(props.networkBlockData.appControl))
    }

    if (props.networkBlockData.userAgentControl && props.networkBlockData.userAgentControl.length > 0) {
      setUserAgentData(objectUserAgentModal(props.networkBlockData.userAgentControl))
    }

    if (props.networkBlockData.ipv4Control && props.networkBlockData.ipv4Control.length > 0) {
      setIPV4Data(objectIPV4Modal(props.networkBlockData.ipv4Control))
    }

    if (props.networkBlockData.ipv6Control && props.networkBlockData.ipv6Control.length > 0) {
      setIPV6Data(objectIPV6Modal(props.networkBlockData.ipv6Control))
    }

  }, [props.networkBlockData]);

  const objectDeviceModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.deviceControlGroup
      obj.label = item.deviceControlGroup
      obj.children = childModal(item.deviceControlGroup, item.deviceControlList)
      dataList.push(obj)
    })
    return dataList
  }

  const objectDomainModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.domainControlGroup
      obj.label = item.domainControlGroup
      obj.children = childModal(item.domainControlGroup, item.domainControlList)
      dataList.push(obj)
    })
    return dataList
  }

  const objectAppModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.appControlGroup
      obj.label = item.appControlGroup
      obj.children = childModal(item.appControlGroup, item.appControlList)
      dataList.push(obj)
    })
    return dataList
  }

  const objectUserAgentModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.userAgentControlGroup
      obj.label = item.userAgentControlGroup
      obj.children = childModal(item.userAgentControlGroup, item.userAgentControlList)
      dataList.push(obj)
    })
    return dataList
  }

  const objectIPV4Modal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.iPV4ControlGroup
      obj.label = item.iPV4ControlGroup
      obj.children = childModal(item.iPV4ControlGroup, item.iPV4ControlList)
      dataList.push(obj)
    })
    return dataList
  }

  const objectIPV6Modal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.iPV6ControlGroup
      obj.label = item.iPV6ControlGroup
      obj.children = childModal(item.iPV6ControlGroup, item.iPV6ControlList)
      dataList.push(obj)
    })
    return dataList
  }

  const childModal = (groupName, response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = groupName + "--" + item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }

  const deviceStateChange = e => {
    setDeviceState(e.target.checked)
    var devicess = []
    if (networkSetting.deviceControlIn.length > 0) {
      devicess = networkSetting.deviceControlIn
    } else {
      devicess = networkSetting.deviceControlEx
    }
    if (e.target.checked) {
      setNetworkSetting(networkSetting => ({ ...networkSetting, deviceControlIn: devicess, deviceControlEx: [] }));
      props.selectedItems({ deviceControlIn: devicess, deviceControlEx: [] })
    } else {
      setNetworkSetting(networkSetting => ({ ...networkSetting, deviceControlEx: devicess, deviceControlIn: [] }));
      props.selectedItems({ deviceControlEx: devicess, deviceControlIn: [] })
    }
  }

  const domainStateChange = e => {
    setDomainState(e.target.checked)
    var domainss = []
    if (networkSetting.domainControlIn.length > 0) {
      domainss = networkSetting.domainControlIn
    } else {
      domainss = networkSetting.domainControlEx
    }
    if (e.target.checked) {
      setNetworkSetting(networkSetting => ({ ...networkSetting, domainControlIn: domainss, domainControlEx: [] }));
      props.selectedItems({ domainControlIn: domainss, domainControlEx: [] })
    } else {
      setNetworkSetting(networkSetting => ({ ...networkSetting, domainControlEx: domainss, domainControlIn: [] }));
      props.selectedItems({ domainControlEx: domainss, domainControlIn: [] })
    }
  }

  const appStateChange = e => {
    setAppState(e.target.checked)
    var appss = []
    if (networkSetting.appControlIn.length > 0) {
      appss = networkSetting.appControlIn
    } else {
      appss = networkSetting.appControlEx
    }
    if (e.target.checked) {
      setNetworkSetting(networkSetting => ({ ...networkSetting, appControlIn: appss, appControlEx: [] }));
      props.selectedItems({ appControlIn: appss, appControlEx: [] })
    } else {
      setNetworkSetting(networkSetting => ({ ...networkSetting, appControlEx: appss, appControlIn: [] }));
      props.selectedItems({ appControlEx: appss, appControlIn: [] })
    }
  }

  const userAgentStateChange = e => {
    setUserAgentState(e.target.checked)
    var userAgentss = []
    if (networkSetting.userAgentControlIn.length > 0) {
      userAgentss = networkSetting.userAgentControlIn
    } else {
      userAgentss = networkSetting.userAgentControlEx
    }
    if (e.target.checked) {
      setNetworkSetting(networkSetting => ({ ...networkSetting, userAgentControlIn: userAgentss, userAgentControlEx: [] }));
      props.selectedItems({ userAgentControlIn: userAgentss, userAgentControlEx: [] })
    } else {
      setNetworkSetting(networkSetting => ({ ...networkSetting, userAgentControlEx: userAgentss, userAgentControlIn: [] }));
      props.selectedItems({ userAgentControlEx: userAgentss, userAgentControlIn: [] })
    }
  }

  const ipv4StateChange = e => {
    setIPV4State(e.target.checked)
    var ipv4ss = []
    if (networkSetting.ipv4ControlIn.length > 0) {
      ipv4ss = networkSetting.ipv4ControlIn
    } else {
      ipv4ss = networkSetting.ipv4ControlEx
    }
    if (e.target.checked) {
      setNetworkSetting(networkSetting => ({ ...networkSetting, ipv4ControlIn: ipv4ss, ipv4ControlEx: [] }));
      props.selectedItems({ ipv4ControlIn: ipv4ss, ipv4ControlEx: [] })
    } else {
      setNetworkSetting(networkSetting => ({ ...networkSetting, ipv4ControlEx: ipv4ss, ipv4ControlIn: [] }));
      props.selectedItems({ ipv4ControlEx: ipv4ss, ipv4ControlIn: [] })
    }
  }

  const ipv6StateChange = e => {
    setIPV6State(e.target.checked)
    var ipv6ss = []
    if (networkSetting.ipv6ControlIn.length > 0) {
      ipv6ss = networkSetting.ipv6ControlIn
    } else {
      ipv6ss = networkSetting.ipv6ControlEx
    }
    if (e.target.checked) {
      setNetworkSetting(networkSetting => ({ ...networkSetting, ipv6ControlIn: ipv6ss, ipv6ControlEx: [] }));
      props.selectedItems({ ipv6ControlIn: ipv6ss, ipv6ControlEx: [] })
    } else {
      setNetworkSetting(networkSetting => ({ ...networkSetting, ipv6ControlEx: ipv6ss, ipv6ControlIn: [] }));
      props.selectedItems({ ipv6ControlEx: ipv6ss, ipv6ControlIn: [] })
    }
  }

  const handleCheck = e => {
    const { name, value } = e.target;
    setTargetStates(targetStates => ({ ...targetStates, [name]: e.target.checked }));
    props.targetedSelectedItems({ [name]: e.target.checked })
    if (name === 'devices') {
      setDeviceTreeSelect(deviceTreeSelect => ({ ...deviceTreeSelect, checked: [] }));
      setNetworkSetting(networkSetting => ({ ...networkSetting, deviceControlEx: [], deviceControlIn: [] }));
      props.selectedItems({ deviceControlEx: [], deviceControlIn: [] })
    }
    if (name === 'domains') {
      setDomainTreeSelect(domainTreeSelect => ({ ...domainTreeSelect, checked: [] }));
      setNetworkSetting(networkSetting => ({ ...networkSetting, domainControlEx: [], domainControlIn: [] }));
      props.selectedItems({ domainControlEx: [], domainControlIn: [] })
    }
    if (name === 'apps') {
      setAppTreeSelect(appTreeSelect => ({ ...appTreeSelect, checked: [] }));
      setNetworkSetting(networkSetting => ({ ...networkSetting, appControlEx: [], appControlIn: [] }));
      props.selectedItems({ appControlEx: [], appControlIn: [] })
    }
    if (name === 'userAgents') {
      setUserAgentTreeSelect(userAgentTreeSelect => ({ ...userAgentTreeSelect, checked: [] }));
      setNetworkSetting(networkSetting => ({ ...networkSetting, userAgentControlEx: [], userAgentControlIn: [] }));
      props.selectedItems({ userAgentControlEx: [], userAgentControlIn: [] })
    }
    if (name === 'ipv4s') {
      setIPV4TreeSelect(ipv4TreeSelect => ({ ...ipv4TreeSelect, checked: [] }));
      setNetworkSetting(networkSetting => ({ ...networkSetting, ipv4ControlEx: [], ipv4ControlIn: [] }));
      props.selectedItems({ ipv4ControlEx: [], ipv4ControlIn: [] })
    }
    if (name === 'ipv6s') {
      setIPV6TreeSelect(ipv6TreeSelect => ({ ...ipv6TreeSelect, checked: [] }));
      setNetworkSetting(networkSetting => ({ ...networkSetting, ipv6ControlEx: [], ipv6ControlIn: [] }));
      props.selectedItems({ ipv6ControlEx: [], ipv6ControlIn: [] })
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickCityOutside);
    return () => document.removeEventListener("mousedown", handleClickCityOutside);
  });

  const deviceRef = useRef();
  const domainRef = useRef();
  const appRef = useRef();
  const myRef = useRef();
  const cityRef = useRef();
  const keyRef = useRef();

  const handleClickCityOutside = e => {
    if (!deviceRef.current.contains(e.target)) {
      setIsDeviceOptionsOpen(false)
    }
    if (!domainRef.current.contains(e.target)) {
      setIsDomainOptionsOpen(false)
    }
    if (!appRef.current.contains(e.target)) {
      setIsAppOptionsOpen(false)
    }
    if (!myRef.current.contains(e.target)) {
      setIsUserAgentOptionsOpen(false)
    }
    if (!cityRef.current.contains(e.target)) {
      setIsIPV4OptionsOpen(false)
    }
    if (!keyRef.current.contains(e.target)) {
      setIsIPV6OptionsOpen(false)
    }
  };
  
  const [blockingData, setBlockingData] = useState({
    device: false,
    app: false,
    domain: false,
    userAgent: false,
    ipv6: false,
    ipv4: false,
  })

  // useEffect(() => {
  //   if (props.networkSettingData !== '') {
  //     setBlockingData(blockingData => ({
  //       ...blockingData,
  //       userAgent: props.networkSettingData.networkSettingsBlockingData.userAgent ? true : false,
  //       device: props.networkSettingData.networkSettingsBlockingData.device ? true : false,
  //       app: props.networkSettingData.networkSettingsBlockingData.app ? true : false,
  //       domain: props.networkSettingData.networkSettingsBlockingData.domain ? true : false,
  //       ipv6: props.networkSettingData.networkSettingsBlockingData.ipv6 ? true : false,
  //       ipv4: props.networkSettingData.networkSettingsBlockingData.ipv4 ? true : false,
  //     }));

  //     props.targetedSelectedItems({
  //       userAgent: props.networkSettingData.networkSettingsBlockingData.userAgent ? true : false,
  //       device: props.networkSettingData.networkSettingsBlockingData.device ? true : false,
  //       app: props.networkSettingData.networkSettingsBlockingData.app ? true : false,
  //       domain: props.networkSettingData.networkSettingsBlockingData.domain ? true : false,
  //       ipv6: props.networkSettingData.networkSettingsBlockingData.ipv6 ? true : false,
  //       ipv4: props.networkSettingData.networkSettingsBlockingData.ipv4 ? true : false,
  //     })
  //   }
  // }, []);


  // const handleCheck = e => {
  //   const { name, value } = e.target;
  //   setBlockingData(blockingData => ({ ...blockingData, [name]: e.target.checked }));
  //   props.targetedSelectedItems({ [name]: e.target.checked })
  // }


  return (
    <React.Fragment>
      <Row>
        <Col lg="11">


          <div className="mb-3">
            <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="domains" id='domains' checked={targetStates.domains} onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='domains'>Domain</Label>
            </div>
            <div className='row checkbox-tree rmsc'>
              <div className='col-7' ref={domainRef}>
                <button
                  type="button"
                  aria-haspopup="listbox"
                  aria-expanded={isDomainOptionsOpen}
                  className={'selectionButton ' + (isDomainOptionsOpen ? "expanded" : "")}
                  onClick={domainToggleOptions}
                  disabled={props.selectedArchived || !targetStates.domains}
                >
                  {domainTreeSelect.checked.length > 0 ? <span style={{ color: 'black' }}>{domainTreeSelect.checked.length + " Domains are Selected"}</span> : "Please Select Domains"}
                </button>
                <ul className={`options ${isDomainOptionsOpen ? "show" : ""}`} role="listbox" tabIndex={-1}>
                  <CheckboxTree
                    nodes={domainData}
                    checked={domainTreeSelect.checked}
                    expanded={domainTreeSelect.expanded}
                    onCheck={checked => {
                      setDomainTreeSelect(domainTreeSelect => ({ ...domainTreeSelect, checked: checked }));
                      domainState ? setNetworkSetting(networkSetting => ({ ...networkSetting, domainControlIn: checked })) : setNetworkSetting(networkSetting => ({ ...networkSetting, domainControlEx: checked }))
                      domainState ? props.selectedItems({ domainControlIn: checked }) : props.selectedItems({ domainControlEx: checked })
                    }}
                    onExpand={expanded => setDomainTreeSelect(domainTreeSelect => ({ ...domainTreeSelect, expanded: expanded }))}
                    icons={{
                      expandClose: <span ><i className='bx bxs-right-arrow mt-1'  ></i></span>,
                      expandOpen: <span ><i className='bx bxs-down-arrow mt-1'></i></span>,
                    }}
                  />
                </ul>
              </div>
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={domainState}
                  onChange={domainStateChange}
                  disabled={props.selectedArchived || !targetStates.domains}
                />
                <label className="form-check-label">
                  {domainState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="devices" id='devices' checked={targetStates.devices} onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='devices'>Device</Label>
            </div>
            <div className='row checkbox-tree rmsc'>
              <div className='col-7' ref={deviceRef}>
                <button
                  type="button"
                  aria-haspopup="listbox"
                  aria-expanded={isDeviceOptionsOpen}
                  className={'selectionButton ' + (isDeviceOptionsOpen ? "expanded" : "")}
                  onClick={devicetoggleOptions}
                  disabled={props.selectedArchived || !targetStates.devices}
                >
                  {deviceTreeSelect.checked.length > 0 ? <span style={{ color: 'black' }}>{deviceTreeSelect.checked.length + " Devices are Selected"}</span> : "Please Select Devices"}
                </button>
                <ul className={`options ${isDeviceOptionsOpen ? "show" : ""}`} role="listbox" tabIndex={-1}>
                  <CheckboxTree
                    nodes={deviceData}
                    checked={deviceTreeSelect.checked}
                    expanded={deviceTreeSelect.expanded}
                    onCheck={(checked, targetNode) => {
                      setDeviceTreeSelect(deviceTreeSelect => ({ ...deviceTreeSelect, checked: checked }));
                      deviceState ? setNetworkSetting(networkSetting => ({ ...networkSetting, deviceControlIn: checked })) : setNetworkSetting(networkSetting => ({ ...networkSetting, deviceControlEx: checked }))
                      deviceState ? props.selectedItems({ deviceControlIn: checked }) : props.selectedItems({ deviceControlEx: checked })
                    }}
                    onExpand={expanded => setDeviceTreeSelect(deviceTreeSelect => ({ ...deviceTreeSelect, expanded: expanded }))}
                    icons={{
                      expandClose: <span ><i className='bx bxs-right-arrow mt-1'  ></i></span>,
                      expandOpen: <span ><i className='bx bxs-down-arrow mt-1'></i></span>,
                    }}
                  />
                </ul>
              </div>
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={deviceState}
                  onChange={deviceStateChange}
                  disabled={props.selectedArchived || !targetStates.devices}
                />
                <label className="form-check-label">
                  {deviceState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="apps" id='apps' checked={targetStates.apps} onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='apps'>App</Label>
            </div>
            <div className='row checkbox-tree rmsc'>
              <div className='col-7' ref={appRef}>
                <button
                  type="button"
                  aria-haspopup="listbox"
                  aria-expanded={isAppOptionsOpen}
                  className={'selectionButton ' + (isAppOptionsOpen ? "expanded" : "")}
                  onClick={appToggleOptions}
                  disabled={props.selectedArchived || !targetStates.apps}
                >
                  {appTreeSelect.checked.length > 0 ? <span style={{ color: 'black' }}>{appTreeSelect.checked.length + " Apps are Selected"}</span> : "Please Select Apps"}
                </button>
                <ul className={`options ${isAppOptionsOpen ? "show" : ""}`} role="listbox" tabIndex={-1}>
                  <CheckboxTree
                    nodes={appData}
                    checked={appTreeSelect.checked}
                    expanded={appTreeSelect.expanded}
                    onCheck={checked => {
                      setAppTreeSelect(appTreeSelect => ({ ...appTreeSelect, checked: checked }));
                      appState ? setNetworkSetting(networkSetting => ({ ...networkSetting, appControlIn: checked })) : setNetworkSetting(networkSetting => ({ ...networkSetting, appControlEx: checked }))
                      appState ? props.selectedItems({ appControlIn: checked }) : props.selectedItems({ appControlEx: checked })
                    }}
                    onExpand={expanded => setAppTreeSelect(appTreeSelect => ({ ...appTreeSelect, expanded: expanded }))}
                    icons={{
                      expandClose: <span ><i className='bx bxs-right-arrow mt-1'  ></i></span>,
                      expandOpen: <span ><i className='bx bxs-down-arrow mt-1'></i></span>,
                    }}
                  />
                </ul>
              </div>
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={appState}
                  onChange={appStateChange}
                  disabled={props.selectedArchived || !targetStates.apps}
                />
                <label className="form-check-label">
                  {appState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="ipv4s" id='ipv4s' checked={targetStates.ipv4s} onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='ipv4s'>IPV4</Label>
            </div>
            <div className='row checkbox-tree rmsc'>
              <div className='col-7' ref={cityRef}>
                <button
                  type="button"
                  aria-haspopup="listbox"
                  aria-expanded={isIPV4OptionsOpen}
                  className={'selectionButton ' + (isIPV4OptionsOpen ? "expanded" : "")}
                  onClick={ipv4ToggleOptions}
                  disabled={props.selectedArchived || !targetStates.ipv4s}
                >
                  {ipv4TreeSelect.checked.length > 0 ? <span style={{ color: 'black' }}>{ipv4TreeSelect.checked.length + " IPMappings are Selected"}</span> : "Please Select IPMappings"}
                </button>
                <ul className={`options ${isIPV4OptionsOpen ? "show" : ""}`} role="listbox" tabIndex={-1}>
                  <CheckboxTree
                    nodes={ipv4Data}
                    checked={ipv4TreeSelect.checked}
                    expanded={ipv4TreeSelect.expanded}
                    onCheck={checked => {
                      setIPV4TreeSelect(ipv4TreeSelect => ({ ...ipv4TreeSelect, checked: checked }));
                      ipv4State ? setNetworkSetting(networkSetting => ({ ...networkSetting, ipv4ControlIn: checked })) : setNetworkSetting(networkSetting => ({ ...networkSetting, ipv4ControlEx: checked }))
                      ipv4State ? props.selectedItems({ ipv4ControlIn: checked }) : props.selectedItems({ ipv4ControlEx: checked })
                    }}
                    onExpand={expanded => setIPV4TreeSelect(ipv4TreeSelect => ({ ...ipv4TreeSelect, expanded: expanded }))}
                    icons={{
                      expandClose: <span ><i className='bx bxs-right-arrow mt-1'  ></i></span>,
                      expandOpen: <span ><i className='bx bxs-down-arrow mt-1'></i></span>,
                    }}
                  />
                </ul>
              </div>
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={ipv4State}
                  onChange={ipv4StateChange}
                  disabled={props.selectedArchived || !targetStates.ipv4s}
                />
                <label className="form-check-label">
                  {ipv4State ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="userAgents" id='userAgents' checked={targetStates.userAgents} onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='userAgents'>User Agent</Label>
            </div>
            <div className='row checkbox-tree rmsc'>
              <div className='col-7' ref={myRef}>
                <button
                  type="button"
                  aria-haspopup="listbox"
                  aria-expanded={isUserAgentOptionsOpen}
                  className={'selectionButton ' + (isUserAgentOptionsOpen ? "expanded" : "")}
                  onClick={userAgenttoggleOptions}
                  disabled={props.selectedArchived || !targetStates.userAgents}
                >
                  {userAgentTreeSelect.checked.length > 0 ? <span style={{ color: 'black' }}>{userAgentTreeSelect.checked.length + " Page URLs are Selected"}</span> : "Please Select Page URLs"}
                </button>
                <ul className={`options ${isUserAgentOptionsOpen ? "show" : ""}`} role="listbox" tabIndex={-1}>
                  <CheckboxTree
                    nodes={userAgentData}
                    checked={userAgentTreeSelect.checked}
                    expanded={userAgentTreeSelect.expanded}
                    onCheck={(checked, targetNode) => {
                      setUserAgentTreeSelect(userAgentTreeSelect => ({ ...userAgentTreeSelect, checked: checked }));
                      userAgentState ? setNetworkSetting(networkSetting => ({ ...networkSetting, userAgentControlIn: checked })) : setNetworkSetting(networkSetting => ({ ...networkSetting, userAgentControlEx: checked }))
                      userAgentState ? props.selectedItems({ userAgentControlIn: checked }) : props.selectedItems({ userAgentControlEx: checked })

                      // if(targetNode.isParent){
                      //   userAgentState ? setNetworkSetting(networkSetting => ({ ...networkSetting, userAgentControlIn:[...networkSetting.userAgentControlIn,{group_name:targetNode.value,group_values:checked}] })) : setNetworkSetting(networkSetting => ({ ...networkSetting, userAgentControlEx: [...networkSetting.userAgentControlIn,{group_name:targetNode.value,group_values:checked}] }))
                      //   userAgentState ? props.selectedItems({ userAgentControlIn: [...networkSetting.userAgentControlIn,{group_name:targetNode.value,group_values:checked}] }) : props.selectedItems({ userAgentControlEx: [...networkSetting.userAgentControlIn,{group_name:targetNode.value,group_values:checked}] })
                      // }else{
                      //   userAgentState ? setNetworkSetting(networkSetting => ({ ...networkSetting, userAgentControlIn:[...networkSetting.userAgentControlIn,{group_name:targetNode.parent.value,group_values:checked}] })) : setNetworkSetting(networkSetting => ({ ...networkSetting, userAgentControlEx: [...networkSetting.userAgentControlIn,{group_name:targetNode.value,group_values:checked}] }))
                      //   userAgentState ? props.selectedItems({ userAgentControlIn: [...networkSetting.userAgentControlIn,{group_name:targetNode.value,group_values:checked}] }) : props.selectedItems({ userAgentControlEx: [...networkSetting.userAgentControlIn,{group_name:targetNode.value,group_values:checked}] })
                      // }
                    }}
                    onExpand={expanded => setUserAgentTreeSelect(userAgentTreeSelect => ({ ...userAgentTreeSelect, expanded: expanded }))}
                    icons={{
                      expandClose: <span ><i className='bx bxs-right-arrow mt-1'  ></i></span>,
                      expandOpen: <span ><i className='bx bxs-down-arrow mt-1'></i></span>,
                    }}
                  />
                </ul>
              </div>
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={userAgentState}
                  onChange={userAgentStateChange}
                  disabled={props.selectedArchived || !targetStates.userAgents}
                />
                <label className="form-check-label">
                  {userAgentState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="ipv6s" id='ipv6s' checked={targetStates.ipv6s} onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='ipv6s'>IPV6</Label>
            </div>
            <div className='row checkbox-tree rmsc'>
              <div className='col-7' ref={keyRef}>
                <button
                  type="button"
                  aria-haspopup="listbox"
                  aria-expanded={isIPV6OptionsOpen}
                  className={'selectionButton ' + (isIPV6OptionsOpen ? "expanded" : "")}
                  onClick={ipv6ToggleOptions}
                  disabled={props.selectedArchived || !targetStates.ipv6s}
                >
                  {ipv6TreeSelect.checked.length > 0 ? <span style={{ color: 'black' }}>{ipv6TreeSelect.checked.length + " Keywords are Selected"}</span> : "Please Select Keywords"}
                </button>
                <ul className={`options ${isIPV6OptionsOpen ? "show" : ""}`} role="listbox" tabIndex={-1}>
                  <CheckboxTree
                    nodes={ipv6Data}
                    checked={ipv6TreeSelect.checked}
                    expanded={ipv6TreeSelect.expanded}
                    onCheck={checked => {
                      setIPV6TreeSelect(ipv6TreeSelect => ({ ...ipv6TreeSelect, checked: checked }));
                      ipv6State ? setNetworkSetting(networkSetting => ({ ...networkSetting, ipv6ControlIn: checked })) : setNetworkSetting(networkSetting => ({ ...networkSetting, ipv6ControlEx: checked }))
                      ipv6State ? props.selectedItems({ ipv6ControlIn: checked }) : props.selectedItems({ ipv6ControlEx: checked })
                    }}
                    onExpand={expanded => setIPV6TreeSelect(ipv6TreeSelect => ({ ...ipv6TreeSelect, expanded: expanded }))}
                    icons={{
                      expandClose: <span ><i className='bx bxs-right-arrow mt-1'  ></i></span>,
                      expandOpen: <span ><i className='bx bxs-down-arrow mt-1'></i></span>,
                    }}
                  />
                </ul>
              </div>
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={ipv6State}
                  onChange={ipv6StateChange}
                  disabled={props.selectedArchived || !targetStates.ipv6s}
                />
                <label className="form-check-label">
                  {ipv6State ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

Blocking.propTypes = {}

export default Blocking
