import React, {} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import PropTypes from 'prop-types'
import {Col, Container, Row, Input, Label, Form,Button} from "reactstrap"

const Global_Settings = props => {
  return (
    <React.Fragment>
    {/* <Sidebar />
    <Header />
    <div className="main-content"> */}
        <div className="page-content">
            <Helmet>
                <title>Global Settings | Bidsxchange</title>
            </Helmet>
            <Container fluid={true}>
                <Breadcrumbs title="Admin" breadcrumbItem="Global Settings" />
            </Container>
        </div>
    {/* </div> */}
    </React.Fragment >
  )
}

Global_Settings.propTypes = {}

export default Global_Settings