/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from "axios";
import {
    Card,
    CardBody,
    Col,
    Row, Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Container,
} from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withRouter, Link } from "react-router-dom";
import Breadcrumbs from "components/Common/Breadcrumb"

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import LoadingTable from "pages/LoaderPages/LoadingTable";

import NewGeoSettings from "./NewGeoSettings";

var selectedItem = []
var selectedData = ''
var selectedIndex = []

var networkBlocks = [
    {
        id:1,
        geoGroup : "ROW",
        countries : ["AF","AG","AI","AM","AN","AO","AQ","AR","AS","AW","AZ","BB","BF","BI","BJ","BL","BM","BN","BO","BR","BS","BT","BV","BW","BZ","CC","CD","CF","CG","CI","CK","CL","CM","CO","CR","CU","CV","CX","DJ","DM","DO","DZ","EC","EG","EH","ER","ET","FJ","FK","FM","GA","GD","GE","GF","GH","GL","GM","GN","GP","GQ","GS","GT","GU","GW","GY","HM","HN","HT","IL","IO","IQ","IR","JM","JO","KG","KI","KM","KN","KP","KR","KY","KZ","LA","LC","LK","LR","LS","LY","MA","MF","MG","MH","ML","MM","MN","MO","MP","MQ","MR","MS","MU","MV","MW","MX","MZ","NA","NC","NE","NF","NI","NP","NR","NT","NU","PA","PE","PF","PG","PM","PN","PR","PS","PW","PY","RE","RW","SB","SC","SD","SH","SN","SO","SR","ST","SV","SY","SZ","TC","TD","TF","TG","TJ","TK","TL","TM","TN","TO","TR","TT","TV","TZ","UG","UM","UY","UZ","VC","VE","VG","VI","VU","WF","WS","YD","YE","YT","ZM","ZW"]
    },
    {
        id:2,
        geoGroup : "APAC",
        countries : ["BD","CN","HK","ID","IN","JP","KH","MY","PH","PK","SG","SL","TH","TW","VN"]
    },
    {
        id:3,
        geoGroup : "Tier1",
        countries : ["AU","CA","NZ","US"]
    },
    {
        id:4,
        geoGroup : "GCC",
        countries : ["AE","BH","KE","KW","LB","NG","OM","QA","SA","ZA"]
    }
]

function HBGeoSettings(props) {
    const dispatch = useDispatch();
    const [switchLoader, setSwitchLoader] = useState(true)
    const [isRight, setIsRight] = useState(false);
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [countryData, setCountryData] = useState([])
    const [allCountryData, setAllCountryData] = useState([])

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 5000,
        extendedTimeOut: 1000
    };

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            name: userInfo.name,
        }
    }

    useEffect(() => {
        getCountries()
    }, []);

    const getCountries = (event) => {
        body.url = "http://127.0.0.1:2002/v1.0/HB_Buyer_geo/read/geo"
        axios.post("/v1.0/connectOP", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data.data) {
                    let allCountryData = []
                    res.data.data.map((item, index) => {
                        item.id = index + 1
                        allCountryData = [...allCountryData,...item.GeoGroupCountries]
                    })
                    console.log(allCountryData)
                    setCountryData([...res.data.data])
                    setAllCountryData([...allCountryData])
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }

    const toggleRightCanvas = () => {
        selectedData = ''
        setIsRight(!isRight);
    };

    const toggleDrawer = (close, deviceControl) => (event) => {
        console.log(event)
        if (event === 'Create') {
            toastr.success('HB Geo Saved Successfully!...')
        }
        if (event === 'Update') {
            toastr.success('HB Geo Updated Successfully!...')
        }
        getCountries()
        selectedData = deviceControl
        setIsRight(close);
    };

    const keyField = "id";

    const geoListColumns = [
        {
            text: "Sl.No",
            dataField: "id",
            headerClasses:"ID",
            sort: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            text: "GeoGroup Name",
            dataField: "GeoGroupName",
            sort: true,
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',
            headerAlign: 'center',

            // eslint-disable-next-line react/display-name
            formatter: (cellContent, geoGroup) => (
                <span  >
                    <Link className="text-success" to="#" onClick={toggleDrawer(true, geoGroup)}>
                        <i
                            className="mdi mdi-pencil font-size-18"
                            id="edittooltip"
                        ></i>
                    </Link>&nbsp;&nbsp;
                    <Link className="text-danger" to="#" >
                     <i
                         className="mdi mdi-delete font-size-18"
                         id="edittooltip"
                     ></i>
                 </Link>
                </span>
            ),
        },
    ];

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );

    const pageOptions = {
        paginationSize: 4,
        pageStartIndex: 1,
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };
    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "asc", // desc or asc
        },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Helmet>
                    <title>HB Geo Settings | Bidsxchange</title>
                </Helmet>
                <Container fluid={true}>
                    <div className=" d-sm-flex  justify-content-between">
                        <Breadcrumbs title="Demand" breadcrumbItem="HB Geo Settings" breadList="All HB Geos" />
                    </div>
                    <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                                    <ToolkitProvider
                                                        keyField={keyField}
                                                        data={countryData}
                                                        columns={geoListColumns}
                                                        // bootstrap4
                                                        search
                                                    >
                                                        {toolkitProps => (
                                                            <React.Fragment>
                                                                <Row className="mb-2">
                                                                    <Col sm="4">
                                                                        <div className="search-box ms-2 mb-2 d-inline-block">
                                                                            <div className="position-relative">
                                                                                <SearchBar {...toolkitProps.searchProps} />
                                                                                <i className="bx bx-search-alt search-icon" />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm="4"></Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xl="12" className="table-responsive">
                                                                        <div >
                                                                            <BootstrapTable
                                                                                keyField={keyField}
                                                                                responsive
                                                                                bordered={false}
                                                                                striped={false}
                                                                                defaultSorted={defaultSorted}
                                                                                classes={
                                                                                    "table align-middle table-nowrap"
                                                                                }
                                                                                headerWrapperClasses={"thead-light"}
                                                                                {...toolkitProps.baseProps}
                                                                                pagination={paginationFactory(pageOptions)}
                                                                                noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                            // ref={node}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                </Container>
            </div>
            <Offcanvas isOpen={isRight} direction='end'  >
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {selectedData ? 'Edit HB Geo ' : 'New HB Geo'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <NewGeoSettings networkBlockType="geoGroup" closeCanvas={toggleDrawer(false, '')} selectedData={selectedData} allGeoData={allCountryData} />
                </OffcanvasBody>
            </Offcanvas>
        </React.Fragment>

    )
}

HBGeoSettings.propTypes = {

}

export default HBGeoSettings

