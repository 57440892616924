/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from "moment";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app



const Support_Ticket_Chat_List = props => {
    const { chat, chats } = props
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [photoIndex, setPhotoIndex] = useState(0)
    const [openLightBox, setOpenLightBox] = useState(false)

    let images = []
    let videos = []

    chats.map(each => {
        if (each.filepath !== undefined) {
            if (each.filepath.includes("jpg") || each.filepath.includes("png") || each.filepath.includes("JPEG")) {
                images.push(each.filepath)
            }
            else if (each.filepath.includes("mp4")) {
                videos.push(each.filepath)
            }
        }
    }) 

    const handleImageClick = (url) => {
        setOpenLightBox(true)
        setPhotoIndex(images.indexOf(url))
    }


    const renderCurrentUserChat = () => {
        return (
            <div className="d-flex justify-content-end">
                {/* <div className="pt-3">
                    <i className='bx bx-dots-vertical-rounded'></i>
                </div> */}
                {chat.dateNaming === 'Today' ?
                    <div style={{ width: '100%', height: '15px', borderBottom: '1px solid black', textAlign: 'center', margin: '10px' }}>
                        <span style={{ padding: '5px 10px', fontSize: '16px', backgroundColor: 'white' }}>
                            Today
                        </span>
                    </div> : chat.dateNaming === 'Yesterday' ? <div style={{ width: '100%', height: '15px', borderBottom: '1px solid black', textAlign: 'center', margin: '10px' }}>
                        <span style={{ padding: '5px 10px', fontSize: '16px', backgroundColor: 'white' }}>
                            Yesterday
                        </span>
                    </div> : <>
                        <div className="chat-card-right d-flex flex-column justify-content-end" >
                            <div className="text-end">
                                <div className="d-inline">
                                    <h5 className="chatUsername">{chat.messageAuthor}</h5>
                                    {chat.filepath && (chat.filepath.includes("jpg") || chat.filepath.includes("png") || chat.filepath.includes("JPEG")) &&
                                        <img onClick={()=> handleImageClick(chat.filepath)} src={chat.filepath} alt="image" className="chat-img-size" />
                                    }
                                    {chat.filepath && chat.filepath.includes("mp4") &&
                                        <video controls  height="240">
                                            <source src={chat.filepath} type="video/mp4" />
                                        </video>
                                    }


                                    <div className="user-chat-msg mb-2" dangerouslySetInnerHTML={{ __html: chat.message.replace(/\r\n|\r|\n/g, "<br>") }}></div>
                                    <p className="chat-time mb-0"><i className="bx bx-time-five align-middle me-1"></i> {moment(chat.messageTimeStamp).format("LT")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="user-icon d-flex flex-column justify-content-center align-items-center">
                            <h4>{chat.messageAuthor && chat.messageAuthor.slice(0, 2).toUpperCase()}</h4>
                        </div>
                    </>
                }
            </div>
        )
    }
    const renderUsersChat = () => {
        return (
            <div className="d-flex justify-content-start">
                {chat.dateNaming === 'Today' ?
                    <div style={{ width: '100%', height: '15px', borderBottom: '1px solid black', textAlign: 'center', margin: '10px' }}>
                        <span style={{ padding: '5px 10px', fontSize: '16px', backgroundColor: 'white' }}>
                            Today
                        </span>
                    </div> : chat.dateNaming === 'Yesterday' ? <div style={{ width: '100%', height: '15px', borderBottom: '1px solid black', textAlign: 'center', margin: '10px' }}>
                        <span style={{ padding: '5px 10px', fontSize: '16px', backgroundColor: 'white' }}>
                            Yesterday
                        </span>
                    </div> : <>
                        <div className="user-icon d-flex flex-column justify-content-center align-items-center" >
                            <h4>{chat.messageAuthor && chat.messageAuthor.slice(0, 2).toUpperCase()}</h4>
                        </div>
                        <div className="chat-card-left d-flex justify-content-start ">
                            <div>
                                <div className="d-inline">
                                    <h5 className="chatUsername">{chat.messageAuthor}</h5>
                                    {chat.filepath && (chat.filepath.includes("jpg") || chat.filepath.includes("png") || chat.filepath.includes("JPEG")) &&
                                        <img onClick={()=> handleImageClick(chat.filepath)} src={chat.filepath} alt="image" className="chat-img-size" />
                                    }
                                    {chat.filepath && chat.filepath.includes("mp4") &&
                                        <video  controls  height="240">
                                            <source src={chat.filepath} type="video/mp4" />
                                        </video>
                                    }
                                    <div className="user-chat-msg mb-2" dangerouslySetInnerHTML={{ __html: chat.message }}></div>
                                    <p className="chat-time mb-0"><i className="bx bx-time-five align-middle me-1"></i> {moment(chat.messageTimeStamp).format("LT")}</p>

                                </div>

                            </div>
                        </div></>
                }
            </div>
        )
    }
    return (
        <>
            <li>
                {chat.messageAuthor === userInfo.name ? renderCurrentUserChat() : renderUsersChat()}
            </li>
            <div>

                {openLightBox && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => { setOpenLightBox(false) }}
                        onMovePrevRequest={() =>

                            setPhotoIndex((photoIndex + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % images.length)
                        }
                    />
                )}
            </div>
        </>
    )
}

export default Support_Ticket_Chat_List