/* eslint-disable react/prop-types */
import React from 'react'
const PasswordStrengthIndicator = ({ validity: {passwordLength,containNumbers,isUpperCase,isLowerCase,requireSymbols } }) => {
    console.log(passwordLength,containNumbers,isUpperCase,isLowerCase,requireSymbols)
    return (
        <div className="password-meter text-left mb-1">
            <span className="text-dark"> Password must contain:</span>
            <ul className="text-muted">
                <PasswordStrengthIndicatorItem isValid={passwordLength} text="Require Minimum 8 characters" />
                <PasswordStrengthIndicatorItem isValid={containNumbers} text="Require at least one  number" />
                <PasswordStrengthIndicatorItem isValid={isUpperCase} text="Require at least one Uppercase Letter" />
                <PasswordStrengthIndicatorItem isValid={isLowerCase} text="Require at least one Lowercase Letter" />
                <PasswordStrengthIndicatorItem isValid={requireSymbols} text="Require at least one Symbol character" />
            </ul>
        </div>
    )
}

const PasswordStrengthIndicatorItem = ({ isValid, text }) => {
    const highlightClass = isValid ? "fa fa-check text-success" : isValid !== null ? "fa fa-times text-danger mr-2" : ""
    return <li className="list-type"><i className={highlightClass}></i>  &nbsp;  {text}</li>
}

export default PasswordStrengthIndicator
