/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 3 : 2

const DateRangePickerDashboard = props => {
const [fromDate, setFromDate] = useState(props.startDate);
const [toDate, setToDate] = useState(props.endDate);

const [state, setState] = useState({
  start: moment().subtract(estCurrentDate > 2 ? 8 : 7, 'days'),
  end: moment().subtract(estCurrentDate > 2 ? 2 : 1, 'days'),
});

const { start, end } = state;

const handleEvent = (start, end,label) => {
  setState({ start, end });
  let picker = {
    startDate:start,
    endDate:end
  }
  props.datePick(picker)
};

 const label = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');

return (
<DateRangePicker
initialSettings={{
  opens: 'left',
  startDate: start.toDate(),
  endDate: end.toDate(),
 maxDate: moment().subtract(estCurrentDate > 2 ? 2 : 1, 'days'),
  ranges: {
    // Today: [moment(), moment()],
    Yesterday: [
      moment().subtract(estCurrentDate > 2 ? 3 : 2, 'days'),
      moment().subtract(estCurrentDate > 2 ? 3 : 2, 'days'),
      ],
      'Last 7 Days': [moment().subtract(estCurrentDate > 2 ? 8 : 7, 'days'), moment().subtract(estCurrentDate > 2 ? 2 : 1, 'days')],
      'Last 30 Days': [moment().subtract(estCurrentDate > 2 ? 32 : 31, 'days'), moment().subtract(estCurrentDate > 2 ? 2 : 1, 'days')],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
  },
}}
onCallback={handleEvent}
>
<button className='datePicker' type="button">
<i  className="bx bx-calendar font-size-18 " id="edittooltip" style={{margin:'3% 3% 2%'}} ></i>{label}
</button>
</DateRangePicker>
)
}

DateRangePickerDashboard.propTypes = {}

export default DateRangePickerDashboard