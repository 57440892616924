import {
    LOAD_SUPPORT_TICKETS,
    NO_DATA_SUPPORT_TICKET_PUBLISHERS,
    GET_SUPPORT_TICKETS_SUCCESS,
    GET_SUPPORT_TICKETS_FAIL,
    ADD_SUPPORT_TICKET_SUCCESS,
    ADD_SUPPORT_TICKET_ERROR,
    ADD_CHAT_SUCCESS,
    ADD_CHAT_ERROR,
} from "./actionTypes"

const INIT_STATE = {
    supportLists: [],
    pagination:{},
    error: {},
    loading:false,
    noData:false,
    successMsg: null,
    errorMsg: null,
    supportTicket: null,
    response:null,
}

const supportTickets = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_SUPPORT_TICKETS:
            return {
                ...state,
                loading: true,
            }
            case NO_DATA_SUPPORT_TICKET_PUBLISHERS:
                return {
                    ...state,
                    noData: true,
                }
        case GET_SUPPORT_TICKETS_SUCCESS:
            return {
                ...state,
                loading: false,
                supportLists:action.payload.response.supportTicket ? action.payload.response.supportTicket : [],
                pagination:action.payload.response
            }

        case GET_SUPPORT_TICKETS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case ADD_SUPPORT_TICKET_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_SUPPORT_TICKET_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg:  action.payload,
            }
        case ADD_CHAT_SUCCESS:
            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_CHAT_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.payload,
            }

        default:
            return state
    }
}

export default supportTickets