/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from "axios";
import Select from "react-select";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Spinner,
    Label, Form, Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
} from "reactstrap"

import DateRangePickers from 'helpers/DateRangePicker'
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';

import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import moment from "moment";

import {
    getMISAnalyticsReports as onGetAdminAnalyticsReports,
} from "store/Reports/AdminReports/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Team_History_Analytical from "./Team_History_Analytical";
import Breadcrumb from "components/Common/Breadcrumb";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import {
    getCommonDevices as onGetAllContextual,
} from "store/CommonEvents/actions";

var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 2 : 1

var fromDate = moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days')
var toDate = moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')

var globalFilterData = {}

const MIS_Team_Analytical = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const dispatch = useDispatch();
    const [reportOpen, setReportOpen] = useState(false);
    const [btnprimary1, setBtnprimary1] = useState(false)
    const [requestTotal, setRequestTotal] = useState(0)
    const [totalImpressions, setTotalImpressions] = useState(0)
    const [totalClicks, setTotalClicks] = useState(0)
    const [totalRefreshReq, setTotalRefreshReq] = useState(0)
    const [totalRefreshImp, setTotalRefreshImp] = useState(0)
    const [totalRefreshClick, setTotalRefreshClick] = useState(0)
    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const [selectAll, setSelectAll] = useState(false)
    const [selectMetrice, setSelectMetrice] = useState(false)
    const [range, setRange] = useState('daily')
    const [loader, setLoader] = useState(false)
    const [selectAllMTC, setSelectAllMTC] = useState(false)
    const [contextualData, setContextualData] = useState({})
    const [oSData, setOSData] = useState([])

    const { contextuals } = useSelector(state => ({
        contextuals: state.commonEvents.contextualAllData,
    }));

    var currentTimeStamp = Date.now()

    useEffect(() => {
        if (contextuals !== null) {
            dispatch(onGetAllContextual());
        }
    }, []);


    useEffect(() => {
        if (contextuals.OSs && contextuals.OSs.length > 0) {
            setOSData(objectModal(contextuals.OSs))
        }
    }, [contextuals])

    const objectModal = (response) => {
        var dataList = []
        response.map((item, index) => {
            let obj = {}
            obj.value = item
            obj.label = item
            dataList.push(obj)
        })
        return dataList
    }

    const [selectAllDM, setSelectAllDM] = useState({
        // "partner_name": false,
        // "publisher_name": false,
        // "ad_unit_group_name": false,
        "ad_unit_name": false,
        // "ad_unit_type": false,
        // "ad_unit_size": false,
        // "advertiser_name": false,
        // "io_group_name": false,
        "io_name": false,
        "site": false,
        "line_item_name": false,
        // "line_item_type": false,
        // "creative_name": false,
        // "creative_type": false,
        "country": false,
        // "state": false,
        // "city": false,
        "device": false,
        // "os": false,
        // "ad_unit_id": false,
        // "line_item_id": false,
        // "creative_id": false
    });

    const [filters, setFilters] = useState({
        "partner_name": "",
        "publisher_name": "",
        "ad_unit_group_name": "",
        "ad_unit_id": 0,
        "ad_unit_name": "",
        "ad_unit_type": "",
        "ad_unit_size": "",
        "advertiser_name": "",
        "io_group_name": "",
        "io_name": "",
        "site": "",
        "line_item_id": 0,
        "line_item_name": "",
        "line_item_type": "",
        "creative_id": 0,
        "creative_name": "",
        "creative_type": "",
        "country": "",
        "state": "",
        "city": "",
        "device": "",
        "os": ""
    });

    const [selectedMTC, setSelectedMTC] = useState({
        "requests": false,
        "impressions": false,
        // "refreshRequests": false,
        // "refreshImpressions": false,
        // "click": false,
        // "refreshClick": false
    });

    const [Advertiser, setAdvertiser] = useState([]);
    const [IOG, setIOG] = useState([]);
    const [IOs, setIOs] = useState([]);
    const [LineItemId, setLineItemId] = useState([]);
    const [LineItem, setLineItem] = useState([]);
    const [CreativeId, setCreativeId] = useState([]);
    const [Creative, setCreative] = useState([]);
    const [Partner, setPartner] = useState([]);
    const [Publisher, setPublisher] = useState([]);
    const [AdUnitGroup, setAdUnitGroup] = useState([]);
    const [AdUnit, setAdUnit] = useState([]);
    const [AdUnitId, setAdUnitId] = useState([]);

    const [Country, setCountry] = useState([]);
    const [os, setOs] = useState([])
    // const [Site, setSite] = useState([]);


    const [Device, setDevice] = useState([
        { value: 'All', label: 'All' },
        { value: 'Desktop', label: 'Desktop' },
        { value: 'Mobile', label: 'Mobile' },
        { value: 'Tablet', label: 'Tablet' },
    ]);

    const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
    const [selectedIOG, setSelectedIOG] = useState(null);
    const [selectedIOs, setSelectedIOs] = useState(null);
    const [selectedLineItem, setSelectedLineItem] = useState(null);
    const [selectedLineItemId, setSelectedLineItemId] = useState(null);

    const [selectedCreative, setSelectedCreative] = useState(null);
    const [selectedCreativeId, setSelectedCreativeId] = useState(null);
    const [selectedPartner, setSelectedPartner] = useState(null);
    const [selectedPublisher, setSelectedPublisher] = useState(null);
    const [selectedSite, setSelectedSite] = useState(null);
    const [selectedAdUnitGroup, setSelectedAdUnitGroup] = useState(null);
    const [selectedAdUnit, setSelectedAdUnit] = useState(null);
    const [selectedAdUnitId, setSelectedAdUnitId] = useState(null);

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedDevice, setSelectedDevice] = useState(null);

    const [selectedOS, setSelectedOS] = useState([]);

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            name: userInfo.name,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
        }
    }

    // const objectModalData = (response) => {
    //     console.log(response)
    //     if (response.advertiser_name) {
    //         setAdvertiser(objectModal(response.advertiser_name))
    //     }
    //     if (response.io_group_name) {
    //         setIOG(objectModal(response.io_group_name))
    //     }
    //     if (response.io_name) {
    //         setIOs(objectModal(response.io_name))
    //     }
    //     if (response.line_item_id) {
    //         setLineItemId(objectModal(response.line_item_id))
    //     }
    //     if (response.line_item_name) {
    //         setLineItem(objectModal(response.line_item_name))
    //     }
    //     if (response.creative_id) {
    //         setCreativeId(objectModal(response.creative_id))
    //     }
    //     if (response.creative_name) {
    //         setCreative(objectModal(response.creative_name))
    //     }
    //     if (response.partner_name) {
    //         setPartner(objectModal(response.partner_name))
    //     }
    //     if (response.publisher_name) {
    //         setPublisher(objectModal(response.publisher_name))
    //     }
    //     if (response.ad_unit_group_name) {
    //         setAdUnitGroup(objectModal(response.ad_unit_group_name))
    //     }
    //     if (response.ad_unit_id) {
    //         setAdUnitId(objectModal(response.ad_unit_id))
    //     }
    //     if (response.ad_unit_name) {
    //         setAdUnit(objectModal(response.ad_unit_name))
    //     }
    // }

    // useEffect(() => {
    //     globalFilterData = {}
    //     fromDate = moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days')
    //     toDate = moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')
    //     body.url = "http://127.0.0.1:2003/v1.0/Query/Analytic/ReportFilter"
    //     body.requestBody = {
    //         "partner_name": "",
    //         "publisher_name": "",
    //         "ad_unit_group_name": "",
    //         "ad_unit_name": "",
    //         "ad_unit_type": "",
    //         "ad_unit_size": "",
    //         "advertiser_name": "",
    //         "io_group_name": "",
    //         "io_name": "",
    //         "site": "",
    //         "ad_unit_id": 0,
    //         "line_item_id": 0,
    //         "creative_id": 0,
    //         "line_item_name": "",
    //         "line_item_type": "",
    //         "creative_name": "",
    //         "creative_type": "",
    //         "country": "",
    //         "state": "",
    //         "city": "",
    //         "device": "",
    //         "os": ""
    //     }
    //     axios.post("/v1.0/connectReportAPI", body,
    //         {
    //             headers: headers
    //         })
    //         .then((res) => {
    //             console.log("result is ==>", res)
    //             if (res.data) {
    //                 objectModalData(res.data)
    //                 globalFilterData = res.data
    //             }
    //         })
    //         .catch((err) => {
    //             console.log("e", err)
    //         });
    // }, [])


    const handleSelectAdvertiser = advertiser => {
        console.log(advertiser)
        setSelectedIOG(null)
        setSelectedIOs(null)
        setSelectedLineItem(null)
        setSelectedCreative(null)
        setSelectedAdvertiser(advertiser)
        if (advertiser) {
            setFilters(filters => ({ ...filters, advertiser_name: advertiser.label, io_group_name: '', io_name: '', line_item_name: '', creative_name: '' }));
            changeDemandFilter(advertiser, 'IOG',)
        } else {
            callDemandFiltersAll(globalFilterData)
            setFilters(filters => ({ ...filters, advertiser_name: '', io_group_name: '', io_name: '', line_item_name: '', creative_name: '' }));
        }
    }

    const handleSelectIOG = iog => {
        setSelectedIOG(iog)
        setSelectedIOs(null)
        setSelectedLineItem(null)
        setSelectedCreative(null)
        if (iog) {
            setFilters(filters => ({ ...filters, io_group_name: iog.label, io_name: '', line_item_name: '', creative_name: '' }));
            changeDemandFilter(iog, 'IOS',)
        } else {
            if (selectedAdvertiser) {
                changeDemandFilter(selectedAdvertiser, 'IOG',)
            } else {
                callDemandFiltersAll(globalFilterData)
            }
            setFilters(filters => ({ ...filters, io_group_name: '', io_name: '', line_item_name: '', creative_name: '' }));
        }
    }

    const handleSelectIOs = ios => {
        setSelectedIOs(ios)
        setSelectedLineItem(null)
        setSelectedCreative(null)
        if (ios) {
            setFilters(filters => ({ ...filters, io_name: ios.label, line_item_name: '', creative_name: '' }));
            changeDemandFilter(ios, 'LI',)
        } else {
            if (selectedIOG) {
                changeDemandFilter(selectedIOG, 'IOS',)
            }
            else if (selectedAdvertiser) {
                changeDemandFilter(selectedAdvertiser, 'IOG',)
            }
            else {
                callDemandFiltersAll(globalFilterData)
            }
            setFilters(filters => ({ ...filters, io_name: '', line_item_name: '', creative_name: '' }));
        }
    }

    const handleSelectLineItem = lineItem => {
        setSelectedLineItem(lineItem)
        setSelectedCreative(null)
        if (lineItem) {
            setFilters(filters => ({ ...filters, line_item_name: lineItem.label, creative_name: '' }));
            changeDemandFilter(lineItem, 'CR',)
        } else {
            if (selectedIOs) {
                changeDemandFilter(selectedIOs, 'LI',)
            } else if (selectedIOG) {
                changeDemandFilter(selectedIOG, 'IOS',)
            }
            else if (selectedAdvertiser) {
                changeDemandFilter(selectedAdvertiser, 'IOG',)
            }
            else {
                callDemandFiltersAll(globalFilterData)
            }
            setFilters(filters => ({ ...filters, line_item_name: '', creative_name: '' }));
        }
    }

    const handleSelectLineItemId = lineItem => {
        setSelectedLineItemId(lineItem)
        setSelectedCreative(null)
        if (lineItem) {
            setFilters(filters => ({ ...filters, line_item_id: lineItem.label, creative_name: '' }));
            changeDemandFilter(lineItem, 'CR',)
        } else {
            changeDemandFilter(selectedIOs, 'LI',)
            setFilters(filters => ({ ...filters, line_item_id: '', creative_name: '' }));
        }
    }

    const handleSelectCreative = creative => {
        setSelectedCreative(creative)
        if (creative) {
            setFilters(filters => ({ ...filters, creative_name: creative.label }));
        } else {
            setFilters(filters => ({ ...filters, creative_name: '' }));
        }
    }

    const handleSelectCreativeId = creativeId => {
        setSelectedCreativeId(creativeId)
        if (creativeId) {
            setFilters(filters => ({ ...filters, creative_name: creativeId.label }));
        } else {
            setFilters(filters => ({ ...filters, creative_name: '' }));
        }
    }

    const handleSelectPartner = partner => {
        setSelectedPartner(partner)
        setSelectedPublisher(null)
        setSelectedAdUnitGroup(null)
        setSelectedAdUnit(null)
        if (partner) {
            setFilters(filters => ({ ...filters, partner_name: partner.label, publisher_name: '', ad_unit_group_name: '', ad_unit_name: '' }));
            changeSupplyFilter(partner, 'PB',)
        } else {
            callSupplyFiltersAll(globalFilterData)
            setFilters(filters => ({ ...filters, partner_name: '', publisher_name: '', ad_unit_group_name: '', ad_unit_name: '' }));
        }
    }

    const handleSelectPublisher = publisher => {
        setSelectedPublisher(publisher)
        setSelectedAdUnitGroup(null)
        setSelectedAdUnit(null)
        if (publisher) {
            setFilters(filters => ({ ...filters, publisher_name: publisher.label, ad_unit_group_name: '', ad_unit_name: '' }));
            changeSupplyFilter(publisher, 'AUG',)
        } else {
            if (selectedPartner) {
                changeSupplyFilter(selectedPartner, 'PB',)
            } else {
                callSupplyFiltersAll(globalFilterData)
            }
            setFilters(filters => ({ ...filters, publisher_name: '', ad_unit_group_name: '', ad_unit_name: '' }));
        }
    }

    const handleSelectAdUnitGroup = adUnitGroup => {
        setSelectedAdUnitGroup(adUnitGroup)
        setSelectedAdUnit(null)
        if (adUnitGroup) {
            setFilters(filters => ({ ...filters, ad_unit_group_name: adUnitGroup.label, ad_unit_name: '' }));
            changeSupplyFilter(adUnitGroup, 'AU',)
        } else {
            if (selectedPublisher) {
                changeSupplyFilter(selectedPublisher, 'AUG',)
            } else if (selectedPartner) {
                changeSupplyFilter(selectedPartner, 'PB',)
            }
            else {
                callSupplyFiltersAll(globalFilterData)
            }
            setFilters(filters => ({ ...filters, ad_unit_group_name: '', ad_unit_name: '' }));
        }
    }

    const handleSelectAdUnit = adUnitName => {
        setSelectedAdUnit(adUnitName)
        if (adUnitName) {
            setFilters(filters => ({ ...filters, ad_unit_name: adUnitName.label }));
        } else {
            setFilters(filters => ({ ...filters, ad_unit_name: '' }));
        }
    }

    const handleSelectAdUnitId = adUnitName => {
        setSelectedAdUnitId(adUnitName)
        if (adUnitName) {
            setFilters(filters => ({ ...filters, ad_unit_id: adUnitName.label }));
        } else {
            setFilters(filters => ({ ...filters, ad_unit_id: '' }));
        }
    }

    const handleSelectCountry = country => {
        setSelectedCountry(country)
        if (country) {
            setFilters(filters => ({ ...filters, country: country.value }));
        } else {
            setFilters(filters => ({ ...filters, country: '' }));
        }
    }

    const handleSelectOperatingSystem = os => {
        setSelectedOS(os)
        if (os) {
            setFilters(filters => ({ ...filters, os: os.value }));
        } else {
            setFilters(filters => ({ ...filters, os: '' }));
        }
    }





    const handleSelectDevice = device => {
        setSelectedDevice(device)
        if (device) {
            setFilters(filters => ({ ...filters, device: device.value }));
        } else {
            setFilters(filters => ({ ...filters, device: '' }));
        }
    }

    const changeMTCSelect = (e) => {
        setSelectAllMTC(e.target.checked)
        setSelectedMTC({
            "requests": e.target.checked,
            "impressions": e.target.checked,
            // "refreshRequests": e.target.checked,
            // "refreshImpressions": e.target.checked,
            // "click": e.target.checked,
            // "refreshClick": e.target.checked
        })
    }

    const changeSelectionMTC = (e) => {
        const { name, value } = e.target;
        setSelectedMTC(selectedMTC => ({ ...selectedMTC, [name]: e.target.checked }));
        let newMTC = { ...selectedMTC, [name]: e.target.checked }
        let MtcArr = Object.values(newMTC)
        if (!MtcArr.includes(false)) {
            setSelectAllMTC(true)
        } else {
            setSelectAllMTC(false)
        }
    }



    useEffect(() => {
        axios.post("/v2.0/operations/regions/list", body.requestBody,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data) {
                    setCountry([...res.data.Africa, ...res.data.Americas, ...res.data.Asia, ...res.data.Europe, ...res.data.GDPR, ...res.data.Oceania])
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }, []);

    useEffect(() => {
        globalFilterData = {}
        let bodyFilter = {
            "url": 'http://127.0.0.1:2002/v1.0/cache',
            "requestBody": {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                "EntityName": "Advertiser",
                "EntityValue": ""
            }
        }
        axios.post("/v1.0/connectReportAPI", bodyFilter,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data) {
                    callDemandFiltersAll(res.data)
                    callSupplyFiltersAll(res.data)
                    globalFilterData = res.data
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
        fromDate = moment().subtract(6, 'days')
        toDate = moment()
        console.log("start: ", fromDate);
        console.log("end: ", toDate);
    }, []);

    const callDemandFiltersAll = (loadData) => {
        let IOGList = []
        let IOSList = []
        let LIList = []
        let CRList = []
        console.log(loadData)
        if (loadData.demand.length > 0) {
            loadData.demand.map(y => {
                y.value = y.AdvertiserID; y.label = y.AdvertiserName;
                y.IOGroups.length > 0 && y.IOGroups.map(a => {
                    a.value = a.IOGroupID; a.label = a.IOGroupName;
                    IOGList.push(a)
                    a.IOs.length > 0 && a.IOs.map(b => {
                        b.value = b.IOID; b.label = b.IOName;
                        IOSList.push(b)
                        b.LineItems.length > 0 && b.LineItems.map(c => {
                            c.value = c.LineItemID; c.label = c.LineItemName;
                            LIList.push(c)
                            c.Creatives.length > 0 && c.Creatives.map(d => {
                                d.value = d.CreativeID; d.label = d.CreativeName;
                                CRList.push(d)
                            })
                        })
                    })
                })
            })
        }
        setAdvertiser(loadData.demand)
        setIOG(IOGList)
        setIOs(IOSList)
        setLineItem(LIList)
        setCreative(CRList)
    }

    const callSupplyFiltersAll = (loadData) => {
        let PBList = []
        let AUGList = []
        let AUList = []
        if (loadData.supply.length > 0) {
            loadData.supply.map(y => {
                y.value = y.PartnerID; y.label = y.PartnerName;
                y.Publishers.length > 0 && y.Publishers.map(a => {
                    a.value = a.PublisherID; a.label = a.PublisherName;
                    PBList.push(a)
                    a.AdUnitGroups.length > 0 && a.AdUnitGroups.map(b => {
                        b.value = b.AdUnitGroupID; b.label = b.AdUnitGroupName;
                        AUGList.push(b)
                        b.AdUnits.length > 0 && b.AdUnits.map(c => {
                            c.value = c.AdUnitID; c.label = c.AdUnitName;
                            AUList.push(c)
                        })
                    })
                })
            })
        }
        setPartner(loadData.supply)
        setPublisher(PBList)
        setAdUnitGroup(AUGList)
        setAdUnit(AUList)
    }

    const changeDemandFilter = (loadData, entity) => {
        console.log(loadData)
        if (entity === 'IOG') {
            let IOGList = []
            let IOSList = []
            let LIList = []
            let CRList = []
            loadData.IOGroups.length > 0 && loadData.IOGroups.map(a => {
                a.value = a.IOGroupID; a.label = a.IOGroupName;
                IOGList.push(a)
                a.IOs.length > 0 && a.IOs.map(b => {
                    b.value = b.IOID; b.label = b.IOName;
                    IOSList.push(b)
                    b.LineItems.length > 0 && b.LineItems.map(c => {
                        c.value = c.LineItemID; c.label = c.LineItemName;
                        LIList.push(c)
                        c.Creatives.length > 0 && c.Creatives.map(d => {
                            d.value = d.CreativeID; d.label = d.CreativeName;
                            CRList.push(d)
                        })
                    })
                })
            })
            setIOG(IOGList)
            setIOs(IOSList)
            setLineItem(LIList)
            setCreative(CRList)
        }
        if (entity === 'IOS') {
            let IOSList = []
            let LIList = []
            let CRList = []
            loadData.IOs.length > 0 && loadData.IOs.map(b => {
                b.value = b.IOID; b.label = b.IOName;
                IOSList.push(b)
                b.LineItems.length > 0 && b.LineItems.map(c => {
                    c.value = c.LineItemID; c.label = c.LineItemName;
                    LIList.push(c)
                    c.Creatives.length > 0 && c.Creatives.map(d => {
                        d.value = d.CreativeID; d.label = d.CreativeName;
                        CRList.push(d)
                    })
                })
            })
            setIOs(IOSList)
            setLineItem(LIList)
            setCreative(CRList)
        }
        if (entity === 'LI') {
            let LIList = []
            let CRList = []
            loadData.LineItems.length > 0 && loadData.LineItems.map(c => {
                c.value = c.LineItemID; c.label = c.LineItemName;
                LIList.push(c)
                c.Creatives.length > 0 && c.Creatives.map(d => {
                    d.value = d.CreativeID; d.label = d.CreativeName;
                    CRList.push(d)
                })
            })
            setLineItem(LIList)
            setCreative(CRList)
        }
        if (entity === 'CR') {
            let CRList = []
            loadData.Creatives.length > 0 && loadData.Creatives.map(d => {
                d.value = d.CreativeID; d.label = d.CreativeName;
                CRList.push(d)
            })
            setCreative(CRList)
        }
    }

    const changeSupplyFilter = (loadData, entity) => {
        console.log(loadData)
        if (entity === 'PB') {
            let PBList = []
            let AUGList = []
            let AUList = []
            loadData.Publishers.length > 0 && loadData.Publishers.map(a => {
                a.value = a.PublisherID; a.label = a.PublisherName;
                PBList.push(a)
                a.AdUnitGroups.length > 0 && a.AdUnitGroups.map(b => {
                    b.value = b.AdUnitGroupID; b.label = b.AdUnitGroupName;
                    AUGList.push(b)
                    b.AdUnits.length > 0 && b.AdUnits.map(c => {
                        c.value = c.AdUnitID; c.label = c.AdUnitName;
                        AUList.push(c)
                    })
                })
            })
            setPublisher(PBList)
            setAdUnitGroup(AUGList)
            setAdUnit(AUList)
        }
        if (entity === 'AUG') {
            let AUGList = []
            let AUList = []
            loadData.AdUnitGroups.length > 0 && loadData.AdUnitGroups.map(b => {
                b.value = b.AdUnitGroupID; b.label = b.AdUnitGroupName;
                AUGList.push(b)
                b.AdUnits.length > 0 && b.AdUnits.map(c => {
                    c.value = c.AdUnitID; c.label = c.AdUnitName;
                    AUList.push(c)
                })
            })
            setAdUnitGroup(AUGList)
            setAdUnit(AUList)
        }
        if (entity === 'AU') {
            let AUList = []
            loadData.AdUnits.length > 0 && loadData.AdUnits.map(c => {
                c.value = c.AdUnitID; c.label = c.AdUnitName;
                AUList.push(c)
            })
            setAdUnit(AUList)
        }
    }

    // const changeData = (data) => {
    //     const { name, value } = data.target;
    //     body.url = "http://127.0.0.1:4012/v1.0/Ssrv/specificFilter"
    //     body.requestBody = {
    //         ...body.requestBody,
    //         "name": name === 'name' ? value : '',
    //         "size": name === 'size' ? value : '',
    //         "sourceplatformid": name === 'sourceplatformid' ? value : '',
    //         "sourceplatformname": name === 'sourceplatformname' ? value : '',
    //         "passbackplatformname": name === 'passbackplatformname' ? value : '',
    //         "passbackcreativename": name === 'passbackcreativename' ? value : '',
    //     }
    //     axios.post("/v1.0/connectOP", body,
    //         {
    //             headers: headers
    //         })
    //         .then((res) => {
    //             console.log("result is ==>", res)
    //             if (res.data) {
    //                 setFilterData({ ...filterData, ...res.data })
    //                 console.log(filterData)
    //             }
    //         })
    //         .catch((err) => {
    //             console.log("e", err)
    //         });
    // };

    const changeDMSelect = (e) => {
        setSelectAll(e.target.checked)
        setSelectAllDM({
            // "partner_name": e.target.checked,
            // "publisher_name": e.target.checked,
            // "ad_unit_group_name": e.target.checked,
            "ad_unit_name": e.target.checked,
            // "ad_unit_type": e.target.checked,
            // "ad_unit_size": e.target.checked,
            // "advertiser_name": e.target.checked,
            // "io_group_name": e.target.checked,
            "io_name": e.target.checked,
            "site": e.target.checked,
            "line_item_name": e.target.checked,
            // "line_item_type": e.target.checked,
            // "creative_name": e.target.checked,
            // "creative_type": e.target.checked,
            "country": e.target.checked,
            // "state": e.target.checked,
            // "city": e.target.checked,
            "device": e.target.checked,
            // "os": e.target.checked,
            // "ad_unit_id": e.target.checked,
            // "line_item_id": e.target.checked,
            // "creative_id": e.target.checked
        })
    }

    const changeSelection = (e) => {
        setSelectAll(false)
        const { name, value } = e.target;
        setSelectAllDM(selectAllDM => ({ ...selectAllDM, [name]: e.target.checked }));
        let newDim = { ...selectAllDM, [name]: e.target.checked }
        let DimArr = Object.values(newDim)
        if (!DimArr.includes(false)) {
            setSelectAll(true)
        } else {
            setSelectAll(false)
        }
    }

    const changeRange = (e) => {
        setRange(e.target.value)
    }

    const { errorMsg, successMsg } = useSelector(state => ({
        errorMsg: state.adminReports.errorMsg,
        successMsg: state.adminReports.successMsg,
    }));

    console.log(errorMsg, successMsg)

    useEffect(() => {
        if (errorMsg) {
            const timer = setTimeout(() => {
                setReportOpen(false)
                dispatch(onGetAdminAnalyticsReports(null));
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [errorMsg]);

    const { analyticalReports } = useSelector(state => ({
        analyticalReports: state.adminReports.misAnalyticsRpt,
    }));

    const { loading } = useSelector(state => ({
        loading: state.adminReports.loading,
    }));


    console.log(analyticalReports, loading)

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const analyticalReportsColumns = [
        {
            text: "No",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
            footer: ''
        },
    ];

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );


    const pageOptions = {
        paginationSize: 4,
        pageStartIndex: 1,
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };

    const defaultSorted = [
        {
            dataField: "requests", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch(onGetAdminAnalyticsReports(null));
        let DimArr = Object.values(selectAllDM)
        let MtcArr = Object.values(selectedMTC)
        if (!MtcArr.includes(true)) {
            toastr.warning('Please select atleast one Metrices')
        }
        else {
            setLoader(true)

            let query = {
                "dimensions": selectAllDM,
                "startDate": moment(fromDate).format("YYYY-MM-DD"),
                "endDate": moment(toDate).format("YYYY-MM-DD"),
                "filters": filters,
                "metrices": selectedMTC,
                "range": range,
            }
            console.log(query)
            dispatch(onGetAdminAnalyticsReports({ ...query }));
            setReportOpen(true)
        }
    }

    if (analyticalReports.length > 0) {
        let keys = [];
        for (var k in analyticalReports[0]) keys.push(k);
        if (keys.includes("hour")) {
            analyticalReports.map((item, index) => {
                item.hour = parseInt(item.hour)
            })
        }
        console.log(keys)
        if (keys.includes("date")) {
            analyticalReportsColumns.push({
                dataField: "date",
                text: "Date",
                sort: true,
                footer: 'Total Requests'
            })
        }
        if (keys.includes("month")) {
            analyticalReportsColumns.push({
                dataField: "month",
                text: "Month",
                sort: true,
                footer: 'Total Requests'
            })
        }
        if (keys.includes("hour")) {
            analyticalReportsColumns.push({
                dataField: "hour",
                text: "Hour",
                sort: true,
                footer: ''
            })
        }
        if (keys.includes("advertiser_name")) {
            analyticalReportsColumns.push({
                dataField: "advertiser_name",
                text: "Advertiser",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("io_group_name")) {
            analyticalReportsColumns.push({
                dataField: "io_group_name",
                text: "IO Group",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("io_name")) {
            analyticalReportsColumns.push({
                dataField: "io_name",
                text: "Insertion Order",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("line_item_id")) {
            analyticalReportsColumns.push({
                dataField: "line_item_id",
                text: "LineItem Id",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("line_item_name")) {
            analyticalReportsColumns.push({
                dataField: "line_item_name",
                text: "LineItem",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("line_item_type")) {
            analyticalReportsColumns.push({
                dataField: "line_item_type",
                text: "LineItem Type",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("creative_id")) {
            analyticalReportsColumns.push({
                dataField: "creative_id",
                text: "Creative Id",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("creative_name")) {
            analyticalReportsColumns.push({
                dataField: "creative_name",
                text: "Creative",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("creative_type")) {
            analyticalReportsColumns.push({
                dataField: "creative_type",
                text: "Creative Type",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("partner_name")) {
            analyticalReportsColumns.push({
                dataField: "partner_name",
                text: "Partner",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("publisher_name")) {
            analyticalReportsColumns.push({
                dataField: "publisher_name",
                text: "Publisher",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("site")) {
            analyticalReportsColumns.push({
                dataField: "site",
                text: "Site",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("ad_unit_group_name")) {
            analyticalReportsColumns.push({
                dataField: "ad_unit_group_name",
                text: "Ad Unit Group",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("ad_unit_id")) {
            analyticalReportsColumns.push({
                dataField: "ad_unit_id",
                text: "Ad Unit Id",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("ad_unit_name")) {
            analyticalReportsColumns.push({
                dataField: "ad_unit_name",
                text: "Ad Unit",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("ad_unit_type")) {
            analyticalReportsColumns.push({
                dataField: "ad_unit_type",
                text: "Ad Unit Type",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("ad_unit_size")) {
            analyticalReportsColumns.push({
                dataField: "ad_unit_size",
                text: "Ad Unit Size",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("country")) {
            analyticalReportsColumns.push({
                dataField: "country",
                text: "Country",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("state")) {
            analyticalReportsColumns.push({
                dataField: "state",
                text: "State",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("city")) {
            analyticalReportsColumns.push({
                dataField: "city",
                text: "City",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("device")) {
            analyticalReportsColumns.push({
                dataField: "device",
                text: "Device",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("os")) {
            analyticalReportsColumns.push({
                dataField: "os",
                text: "OS",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }

        if (keys.includes("requests")) {
            analyticalReportsColumns.push({
                dataField: "requests",
                text: "Requests",
                sort: true,
                footer: `${requestTotal}`
            })
        }

        if (keys.includes("impressions")) {
            analyticalReportsColumns.push({
                dataField: "impressions",
                text: "Impressions",
                sort: true,
                footer: `${totalImpressions}`
            })
        }

        if (keys.includes("click")) {
            analyticalReportsColumns.push({
                dataField: "click",
                text: "click",
                sort: true,
                footer: `${totalClicks}`
            })
        }

        if (keys.includes("refreshRequests")) {
            analyticalReportsColumns.push({
                dataField: "refreshRequests",
                text: "Refresh Requests",
                sort: true,
                footer: `${totalRefreshReq}`
            })
        }
        if (keys.includes("refreshImpressions")) {
            analyticalReportsColumns.push({
                dataField: "refreshImpressions",
                text: "Refresh Impressions",
                sort: true,
                footer: `${totalRefreshImp}`
            })
        }

        if (keys.includes("refreshClick")) {
            analyticalReportsColumns.push({
                dataField: "refreshClick",
                text: "Refresh Click",
                sort: true,
                footer: `${totalRefreshClick}`
            })
        }

        // loader = false
        console.log(analyticalReportsColumns)
    } else {
        // loader = false
    }

    const [downloadStatus, setDownloadStatus] = useState(false);
    const downloadReport = (e) => {
        setDownloadStatus(true)
    }

    useEffect(() => {
        console.log(analyticalReports)
        if (analyticalReports !== null && analyticalReports.length > 0) {
            setLoader(false)
            let totalRequest = 0
            let totalImpressionsCount = 0
            let totalClicks = 0
            let totalRefreshRequests = 0
            let totalRefreshImpressions = 0
            let totalRefreshClicks = 0
            analyticalReports.map((item, index) => {
                if (item.requests) {
                    item.requests = Number(item.requests)
                    totalRequest = totalRequest + parseInt(item.requests)
                }
                if (item.impressions) {
                    item.impressions = Number(item.impressions)
                    totalImpressionsCount = totalImpressionsCount + parseInt(item.impressions)
                }
                if (item.click) {
                    item.click = Number(item.click)
                    totalClicks += parseInt(item.click)
                }
                if (item.refreshRequests) {
                    item.refreshRequests = Number(item.refreshRequests)
                    totalRefreshRequests += parseInt(item.refreshRequests)
                }
                if (item.refreshImpressions) {
                    item.refreshImpressions = Number(item.refreshImpressions)
                    totalRefreshImpressions += parseInt(item.refreshImpressions)
                }
                if (item.refreshClick) {
                    item.refreshClick = Number(item.refreshClick)
                    totalRefreshClicks += parseInt(item.refreshClick)
                }

            })
            setRequestTotal(totalRequest)
            setTotalImpressions(totalImpressionsCount)
            setTotalClicks(totalClicks)
            setTotalRefreshReq(totalRefreshRequests)
            setTotalRefreshImp(totalRefreshImpressions)
            setTotalRefreshClick(totalRefreshClicks)
            if (downloadStatus) {
                CSVClick()
                setDownloadStatus(false)
            }

        } else {
            setLoader(false)
        }

    }, [analyticalReports]);

    var CSVProp
    const CSVClick = () => {
        CSVProp.onExport();
    }

    const MyExportCSV = (csvprops) => {
        CSVProp = csvprops
        return (<></>);
    };

    const copyTable = () => {
        console.log(CSVProp, JSON.stringify(CSVProp))
        navigator.clipboard.writeText(CSVProp)
        console.log('Element Copied! Paste it in a file')

    }

    const [state, setState] = useState({
        start: moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days'),
        end: moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),
    });

    const { start, end } = state;

    const handleEvent = (startDate, endDate, label) => {
        console.log(startDate, endDate)
        setState({ start: startDate, end: endDate, });
        fromDate = startDate._d.toISOString();
        toDate = endDate._d.toISOString();
    };

    const labelDate = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');

    const [isRight, setIsRight] = useState(false);

    const toggleRightCanvas = () => {
        setIsRight(!isRight);
    };

    const toggleDrawer = (close, scheduleReport, action) => (event) => {
        console.log(event)
        setIsRight(close);
    };

    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
            <div className="page-content">
                <Helmet>
                    <title>MIS Team Analytics Report | Bidsxchange</title>
                </Helmet>
                {!reportOpen &&
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <div className=" d-sm-flex  justify-content-between">
                            <Breadcrumb title="Reporting" breadcrumbItem="MIS Team Analytics Report Query " />
                            <div className=" text-sm-end">
                                <Button
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                    onClick={toggleRightCanvas}
                                >
                                    History Report
                                </Button>
                            </div>
                        </div>
                        <Row>
                            <div className="col-12">
                                <Card>
                                    <CardBody>
                                        <Form onSubmit={handleSubmit}>
                                            <Row style={{ padding: '1% 3%', }}>
                                                <Col xs={4} >
                                                    <div className="mb-3">
                                                        <h5>SELECT DATE</h5> <br />
                                                        <DateRangePicker
                                                            initialSettings={{
                                                                opens: 'left',
                                                                startDate: start.toDate(),
                                                                endDate: end.toDate(),
                                                                minDate: moment().subtract(estCurrentDate > 1 ? 31 : 30, 'days'), maxDate: moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),
                                                                ranges: {
                                                                    // Today: [ moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),
                                                                    // moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),],
                                                                    Yesterday: [
                                                                        moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
                                                                        moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
                                                                    ],
                                                                    'Last 7 Days': [moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days'), moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')],
                                                                    'Last 30 Days': [moment().subtract(estCurrentDate > 1 ? 31 : 30, 'days'), moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')],
                                                                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                                    // 'Last Month': [
                                                                    // moment().subtract(1, 'month').startOf('month'),
                                                                    // moment().subtract(1, 'month').endOf('month'),
                                                                    // ],
                                                                },
                                                            }}
                                                            onCallback={handleEvent}
                                                        >
                                                            <button className='datePicker' type="button">
                                                                <i className="bx bx-calendar font-size-18 " id="edittooltip" style={{ margin: '3% 3% 2%' }} ></i>{labelDate}
                                                            </button>
                                                        </DateRangePicker>
                                                    </div>
                                                    <br />
                                                    <h5>FILTERS</h5>
                                                    {/* <div className="mb-3">
                                                        <Label className="form-label">Advertiser</Label>
                                                        <Select
                                                            value={selectedAdvertiser}
                                                            onChange={s => {
                                                                handleSelectAdvertiser(s)
                                                            }}
                                                            options={Advertiser}
                                                            isClearable={true}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Insertion Order Group</Label>
                                                        <Select
                                                            value={selectedIOG}
                                                            onChange={s => {
                                                                handleSelectIOG(s)
                                                            }}
                                                            options={IOG}
                                                            isClearable={true}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div> */}
                                                    <div className="mb-3">
                                                        <Label>Insertion Order</Label>
                                                        <Select
                                                            value={selectedIOs}
                                                            onChange={s => {
                                                                handleSelectIOs(s)
                                                            }}
                                                            options={IOs}
                                                            isClearable={true}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                    {/* <div className="mb-3">
                                                        <Label className="form-label">Line Item Id</Label>
                                                        <Select
                                                            value={selectedLineItemId}
                                                            onChange={s => {
                                                                handleSelectLineItemId(s)
                                                            }}
                                                            options={LineItemId}
                                                            isClearable={true}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div> */}
                                                    <div className="mb-3">
                                                        <Label className="form-label">Line Item</Label>
                                                        <Select
                                                            value={selectedLineItem}
                                                            onChange={s => {
                                                                handleSelectLineItem(s)
                                                            }}
                                                            options={LineItem}
                                                            isClearable={true}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                    {/* <div className="mb-3">
                                                        <Label className="form-label">Creative Id</Label>
                                                        <Select
                                                            value={selectedCreativeId}
                                                            onChange={s => {
                                                                handleSelectCreativeId(s)
                                                            }}
                                                            options={CreativeId}
                                                            isClearable={true}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div> */}
                                                    {/* <div className="mb-3">
                                                        <Label className="form-label">Creative</Label>
                                                        <Select
                                                            value={selectedCreative}
                                                            onChange={s => {
                                                                handleSelectCreative(s)
                                                            }}
                                                            options={Creative}
                                                            isClearable={true}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div> */}
                                                    {/* <div className="mb-3">
                                                        <Label className="form-label">Supply Partner </Label>
                                                        <Select
                                                            value={selectedPartner}
                                                            onChange={s => {
                                                                handleSelectPartner(s)
                                                            }}
                                                            options={Partner}
                                                            isClearable={true}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Publisher</Label>
                                                        <Select
                                                            value={selectedPublisher}
                                                            onChange={s => {
                                                                handleSelectPublisher(s)
                                                            }}
                                                            options={Publisher}
                                                            isClearable={true}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Ad Unit Group</Label>
                                                        <Select
                                                            value={selectedAdUnitGroup}
                                                            onChange={s => {
                                                                handleSelectAdUnitGroup(s)
                                                            }}
                                                            options={AdUnitGroup}
                                                            isClearable={true}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div> */}
                                                    {/* <div className="mb-3">
                                                        <Label className="form-label">Ad Unit Id</Label>
                                                        <Select
                                                            value={selectedAdUnitId}
                                                            onChange={s => {
                                                                handleSelectAdUnitId(s)
                                                            }}
                                                            options={AdUnitId}
                                                            isClearable={true}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div> */}
                                                    <div className="mb-3">
                                                        <Label className="form-label">Ad Unit</Label>
                                                        <Select
                                                            value={selectedAdUnit}
                                                            onChange={s => {
                                                                handleSelectAdUnit(s)
                                                            }}
                                                            options={AdUnit}
                                                            isClearable={true}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Country </Label>
                                                        <Select
                                                            value={selectedCountry}
                                                            onChange={s => {
                                                                handleSelectCountry(s)
                                                            }}
                                                            options={Country}
                                                            isClearable={true}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                    {/* <div className="mb-3">
                                                            <Label className='form-label ' htmlFor='OSs'>Operating System</Label>
                                                            <MultiSelect
                                                                options={oSData}
                                                                value={selectedOS}
                                                                // onChange={handleSelectOS}
                                                                overrideStrings={{ "selectSomeItems": "Please Select Operating System" }}
                                                                className="select2-selection"
                                                                // disabled={props.selectedArchived || !targetStates.OSs}
                                                            />
                                                            
                                                    </div> */}
                                                    {/* <div className="mb-3">
                                                        <Label className="form-label">Operating System</Label>
                                                        <Select
                                                            value={selectedOS}
                                                            onChange={s => {
                                                                handleSelectOperatingSystem(s)
                                                            }}
                                                            options={oSData}
                                                            isClearable={true}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div> */}
                                                    <div className="mb-3">
                                                        <Label className="form-label">Device</Label>
                                                        <Select
                                                            value={selectedDevice}
                                                            onChange={s => {
                                                                handleSelectDevice(s)
                                                            }}
                                                            options={Device}
                                                            isClearable={true}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>

                                                </Col>
                                                <Col xs={4} style={{ padding: '0% 3%', }}>

                                                    <div className="mb-3">
                                                        <h5> SELECT RANGE</h5><br />
                                                        <Input
                                                            name="range"
                                                            type="select"
                                                            className="form-select"
                                                            onChange={changeRange}
                                                            value={range}
                                                        >
                                                            <option value=''>Select</option>
                                                            {/* <option value='alltime'>All Time</option> */}
                                                            <option value='monthly'>Monthly</option>
                                                            <option value='daily'>Daily</option>
                                                            <option value='hourly'>Hourly</option>
                                                        </Input>
                                                    </div>
                                                    <br />
                                                    <h5 className="mb-3 ">DIMENSIONS</h5>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAll}
                                                            onChange={changeDMSelect}
                                                            id="selectall"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="selectall"
                                                        >
                                                            Select All
                                                        </label>
                                                    </div>
                                                    {/* <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.advertiser_name}
                                                            name="advertiser_name"
                                                            onChange={changeSelection}
                                                            id="advertiser_name"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="advertiser_name"
                                                        >
                                                            Advertiser
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.io_group_name}
                                                            name="io_group_name"
                                                            onChange={changeSelection}
                                                            id="io_group_name"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="io_group_name"
                                                        >
                                                            Insertion Order Group
                                                        </label>
                                                    </div>
                                                          <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.line_item_id}
                                                            name="line_item_id"
                                                            onChange={changeSelection}
                                                            id="line_item_id"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="line_item_id"
                                                        >
                                                            Line Item Id
                                                        </label>
                                                    </div> */}
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.io_name}
                                                            name="io_name"
                                                            onChange={changeSelection}
                                                            id="io_name"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="io_name"
                                                        >
                                                            Insertion Order
                                                        </label>
                                                    </div>

                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.line_item_name}
                                                            name="line_item_name"
                                                            onChange={changeSelection}
                                                            id="line_item_name"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="line_item_name"
                                                        >
                                                            Line Item
                                                        </label>
                                                    </div>
                                                    {/* <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.line_item_type}
                                                            name="line_item_type"
                                                            onChange={changeSelection}
                                                            id="line_item_type"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="line_item_type"
                                                        >
                                                            Line Item Type
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.creative_id}
                                                            name="creative_id"
                                                            onChange={changeSelection}
                                                            id="creative_id"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="creative_id"
                                                        >
                                                            Creative Id
                                                        </label>
                                                    </div> */}
                                                    {/* <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.creative_name}
                                                            name="creative_name"
                                                            onChange={changeSelection}
                                                            id="creative_name"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="creative_name"
                                                        >
                                                            Creative
                                                        </label>
                                                    </div> */}
                                                    {/* <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.creative_type}
                                                            name="creative_type"
                                                            onChange={changeSelection}
                                                            id="creative_type"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="creative_type"
                                                        >
                                                            Creative Type
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.partner_name}
                                                            name="partner_name"
                                                            onChange={changeSelection}
                                                            id="partner_name"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="partner_name"
                                                        >
                                                            Supply Partner
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.publisher_name}
                                                            name="publisher_name"
                                                            onChange={changeSelection}
                                                            id="publisher_name"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="publisher_name"
                                                        >
                                                            Publisher
                                                        </label>
                                                    </div>
                                           
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.ad_unit_group_name}
                                                            name="ad_unit_group_name"
                                                            onChange={changeSelection}
                                                            id="ad_unit_group_name"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="ad_unit_group_name"
                                                        >
                                                            Ad Unit Group
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.ad_unit_id}
                                                            name="ad_unit_id"
                                                            onChange={changeSelection}
                                                            id="ad_unit_id"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="ad_unit_id"
                                                        >
                                                            Ad Unit Id
                                                        </label>
                                                    </div> */}
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.site}
                                                            name="site"
                                                            onChange={changeSelection}
                                                            id="site"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="site"
                                                        >
                                                            Publisher Site
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.ad_unit_name}
                                                            name="ad_unit_name"
                                                            onChange={changeSelection}
                                                            id="ad_unit_name"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="ad_unit_name"
                                                        >
                                                            Ad Unit
                                                        </label>
                                                    </div>
                                                    {/* <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.ad_unit_type}
                                                            name="ad_unit_type"
                                                            onChange={changeSelection}
                                                            id="ad_unit_type"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="ad_unit_type"
                                                        >
                                                            Ad Unit Type
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.ad_unit_size}
                                                            name="ad_unit_size"
                                                            onChange={changeSelection}
                                                            id="ad_unit_size"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="ad_unit_size"
                                                        >
                                                            Ad Unit Size
                                                        </label>
                                                    </div> */}
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.country}
                                                            name="country"
                                                            onChange={changeSelection}
                                                            id="country"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="country"
                                                        >
                                                            Country
                                                        </label>
                                                    </div>
                                                    {/* <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.state}
                                                            name="state"
                                                            onChange={changeSelection}
                                                            id="state"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="state"
                                                        >
                                                            State
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.city}
                                                            name="city"
                                                            onChange={changeSelection}
                                                            id="city"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="city"
                                                        >
                                                            City
                                                        </label>
                                                    </div> */}
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.device}
                                                            name="device"
                                                            onChange={changeSelection}
                                                            id="device"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="device"
                                                        >
                                                            Device
                                                        </label>
                                                    </div>
                                                    {/* <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.os}
                                                            name="os"
                                                            onChange={changeSelection}
                                                            id="os"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="os"
                                                        >
                                                            OS
                                                        </label>
                                                    </div> */}



                                                </Col>
                                                <Col xs={4} style={{ padding: '0 3%', }}>
                                                    <div className="mb-3">
                                                        <h5> SELECT Time Zone</h5><br />
                                                        <Input
                                                            name="timeZone"
                                                            type="select"
                                                            className="form-select"
                                                            defaultValue={'EST'}
                                                        >
                                                            <option value='' disabled>Select</option>
                                                            <option value='EST'>EST</option>
                                                        </Input>
                                                    </div>
                                                    <br />
                                                    <h5 className="mb-3">METRICES</h5>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllMTC}
                                                            onChange={changeMTCSelect}
                                                            id="selectallmetrices"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="selectallmetrices"
                                                        >
                                                            Select All
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectedMTC.impressions}
                                                            name="impressions"
                                                            onChange={changeSelectionMTC}
                                                            id="impressions"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="impressions"
                                                        >
                                                            Impressions
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectedMTC.requests}
                                                            name="requests"
                                                            onChange={changeSelectionMTC}
                                                            id="totalcodeserved"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="totalcodeserved"
                                                        >
                                                            Requests
                                                        </label>
                                                    </div>
                                                    {/* <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectedMTC.refreshRequests}
                                                            name="refreshRequests"
                                                            onChange={changeSelectionMTC}
                                                            id="refreshRequests"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="refreshRequests"
                                                        >
                                                            Refresh Requests
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectedMTC.refreshImpressions}
                                                            name="refreshImpressions"
                                                            onChange={changeSelectionMTC}
                                                            id="refreshImpressions"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="refreshImpressions"
                                                        >
                                                            Refresh Impressions
                                                        </label>
                                                    </div> */}
                                                    {/* <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectedMTC.click}
                                                            name="click"
                                                            onChange={changeSelectionMTC}
                                                            id="click"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="click"
                                                        >
                                                            Clicks
                                                        </label>
                                                    </div> */}
                                                    {/* <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectedMTC.refreshClick}
                                                            name="refreshClick"
                                                            onChange={changeSelectionMTC}
                                                            id="refreshClick"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="refreshClick"
                                                        >
                                                            Refresh Click
                                                        </label>
                                                    </div> */}
                                                    {/* <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectedMTC.impressions}
                                                                name="impressions"
                                                                onChange={changeSelectionMTC}
                                                                id="impressions"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="impressions"
                                                            >
                                                                Impressions
                                                            </label>
                                                        </div> */}

                                                    {/* <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectedMTC.requests}
                                                                name="requests"
                                                                onChange={changeSelectionMTC}
                                                                id="revenue"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="revenue"
                                                            >
                                                                Revenue
                                                            </label>
                                                        </div> */}
                                                    {/* <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectedMTC.impressions}
                                                                name="impressions"
                                                                onChange={changeSelectionMTC}
                                                                id="ecpm"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="ecpm"
                                                            >
                                                                eCPM
                                                            </label>
                                                        </div> */}

                                                    {/* <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectedMTC.requests}
                                                                name="requests"
                                                                onChange={changeSelectionMTC}
                                                                id="unfilledimpressions"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="unfilledimpressions"
                                                            >
                                                                Unfilled Impressions
                                                            </label>
                                                        </div> */}
                                                    {/* <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectedMTC.impressions}
                                                                name="impressions"
                                                                onChange={changeSelectionMTC}
                                                                id="refreshedimpressions"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="refreshedimpressions"
                                                            >
                                                                Refreshed Impressions
                                                            </label>
                                                        </div> */}


                                                    {/* <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectedMTC.requests}
                                                                name="requests"
                                                                onChange={changeSelectionMTC}
                                                                id="ctr%"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="ctr%"
                                                            >
                                                                CTR%
                                                            </label>
                                                        </div> */}
                                                    {/* <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectedMTC.impressions}
                                                                name="impressions"
                                                                onChange={changeSelectionMTC}
                                                                id="viewableImpressions"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="viewableImpressions"
                                                            >
                                                                Viewable Impressions
                                                            </label>
                                                        </div> */}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className=" text-sm-end">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary save-user"
                                                        >
                                                            Run Report
                                                        </button>
                                                        &nbsp;&nbsp;
                                                        <button
                                                            type="submit"
                                                            onClick={downloadReport}
                                                            className="btn btn-primary save-user"
                                                        >
                                                            Run & Download Report
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </div>
                        </Row>
                    </Container>
                }
                {reportOpen &&
                    <Container fluid={true}>
                        <div className=" d-sm-flex  justify-content-between">
                            <Breadcrumb title="Reporting" breadcrumbItem="MIS Team Analytics Report " />
                            <div className=" text-sm-end">
                                {analyticalReports.length > 0 && !loading &&
                                    <div className="btn-group">
                                        <Dropdown
                                            isOpen={btnprimary1}
                                            toggle={() => setBtnprimary1(!btnprimary1)}
                                        >
                                            <DropdownToggle tag="button" className="btn btn-primary">
                                                Export
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {/* <DropdownItem onClick={copyTable}>Copy</DropdownItem> */}
                                                <DropdownItem onClick={CSVClick}>CSV</DropdownItem>
                                                {/* <DropdownItem onClick={exportPDF}>PDF</DropdownItem> */}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                }
                                &nbsp;&nbsp;&nbsp;
                                {!loading &&
                                    <Button
                                        color="primary"
                                        className="font-16 btn-block btn btn-primary"
                                        onClick={(e) => setReportOpen(false)}
                                    >
                                        <i className="mdi mdi-plus-circle-outline me-1" />
                                        Modify Report
                                    </Button>
                                }
                            </div>
                        </div>
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody aria-hidden="true">
                                        {loading &&
                                            <>
                                                <Spinner className="ms-2 loader" color="primary" />
                                                <br />
                                                <h5 className="text-center mt-5" style={{ padding: '15%', fontSize: '25px' }}></h5>
                                                <br />
                                            </>
                                        }
                                        {analyticalReports.length === 0 && !loading &&
                                            <div className="text-center" style={{ padding: '15%' }}>
                                                <h5 className="text-center" style={{ fontSize: '25px' }}>{errorMsg ? errorMsg : "No Data"}</h5>
                                                <div className="loading-bar mt-4">
                                                    <div className="loading-bar--progress">
                                                        <span className="first"></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                        <span className="last"></span>
                                                    </div>
                                                </div>
                                                <h5 className="text-center mt-3">Redirecting to Report Query...</h5>
                                            </div>
                                        }
                                        {/* <div className="text-center" style={{ padding: '15%' }}>
                                            <h4 className="text-center mt-3">Hey! {userInfo.name} </h4>
                                            <h6 className="text-center ">Read below ...</h6>
                                            <img src={animatedEmail} alt="Email Sent" style={{ width: '10%' }} />
                                            <h5 className="text-center" >{errorMsg ? errorMsg : "No Data"}</h5>
                                            <div className="loading-bar mt-4">
                                                <div className="loading-bar--progress">
                                                    <span className="first"></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span className="last"></span>
                                                </div>
                                            </div>
                                            <h5 className="text-center mt-3">Redirecting to Report Query...</h5>

                                        </div> */}

                                        {analyticalReports.length > 0 && !loading &&
                                            <ToolkitProvider
                                                keyField={keyField}
                                                data={analyticalReports}
                                                columns={analyticalReportsColumns}
                                                // bootstrap4
                                                search
                                                exportCSV={{
                                                    fileName: 'Analytics-Report-' + currentTimeStamp + '.csv',
                                                }}
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col sm="8">
                                                                <div className="search-box ms-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <SearchBar {...toolkitProps.searchProps} />
                                                                        <i className="bx bx-search-alt search-icon" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col sm="1">
                                                                <MyExportCSV {...toolkitProps.csvProps} />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12" className="table-responsive">
                                                                <div >
                                                                    <BootstrapTable
                                                                        keyField={keyField}
                                                                        responsive
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        classes={
                                                                            "table align-middle table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        pagination={paginationFactory(pageOptions)}
                                                                        noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }

            </div>
            {/* </div> */}
            <Offcanvas isOpen={isRight} direction='end'  >
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {'MIS Team Report History'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <Team_History_Analytical closeCanvas={toggleDrawer(false, '', '')} selectedTeam="MIS" />
                </OffcanvasBody>
            </Offcanvas>
        </React.Fragment>
    )
}

MIS_Team_Analytical.propTypes = {}

export default MIS_Team_Analytical