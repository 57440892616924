import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_BRAND_AFFINITYS,ADD_BRAND_AFFINITY , UPDATE_BRAND_AFFINITY,READ_BRAND_AFFINITY,STATUS_BRAND_AFFINITY,ARCHIVE_BRAND_AFFINITY} from "./actionTypes"

import {
  loadBay,
  noBays,
  getBrandAffinitysSuccess,
  getBrandAffinitysFail,
  addBrandAffinitySuccess,
  addBrandAffinityFail,
  updateBrandAffinitySuccess,
  updateBrandAffinityFail,
  readBrandAffinitySuccess,
  statusBrandAffinitySuccess,
  archiveBrandAffinitySuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getBrandAffinity,createBrandAffinity,updateBrandAffinity,readBrandAffinity,statusChangeBrandAffinity,archiveBrandAffinity } from "../../../helpers/Backend_helper"

function* fetchBrandAffinitys() {
  try {
    yield put(loadBay(true))
    const response = yield call(getBrandAffinity)
    console.log(response)
    response.response.map((item, index) => {
      item.id = index + 1
  })
  console.log(response)
  if(response.response.length === 0){
    yield put(noBays(true))
  }
    yield put(getBrandAffinitysSuccess(response.response))
  } catch (error) {
    yield put(getBrandAffinitysFail(error))
  }
}

function* onReadBrandAffinity({ payload: brandAffinity }) {
  try {
    const response = yield call(readBrandAffinity, brandAffinity)
    yield put(readBrandAffinitySuccess(response))
  } catch (error) {
    yield put(readBrandAffinitySuccess(error))
  }
}

function* onAddBrandAffinity({ payload: brandAffinity }) {
  try {
    const response = yield call(createBrandAffinity, brandAffinity)
    if(response.success){
      yield put(addBrandAffinitySuccess(response))
    }else{
      yield put(addBrandAffinityFail(response))
    }
  } catch (error) {
    yield put(addBrandAffinityFail(error))
  }
}

function* onUpdateBrandAffinity({ payload: brandAffinity }) {
  try {
    const response = yield call(updateBrandAffinity, brandAffinity)
    if(response.success){
      yield put(updateBrandAffinitySuccess(response))
    }else{
      yield put(updateBrandAffinityFail(response))
    }
  } catch (error) {
    yield put(updateBrandAffinityFail(error))
  }
}

function* onStatusBrandAffinity({ payload: brandAffinity }) {
  try {
    const response = yield call(statusChangeBrandAffinity, brandAffinity)
    if(response.success){
      yield put(statusBrandAffinitySuccess(response))
    }else{
      yield put(statusBrandAffinitySuccess(response.message))
    }
  } catch (error) {
    yield put(updateBrandAffinityFail(error))
  }
}

function* onArchiveBrandAffinity({ payload: brandAffinity }) {
  try {
    const response = yield call(archiveBrandAffinity, brandAffinity)
    yield put(archiveBrandAffinitySuccess(response))
  } catch (error) {
    yield put(archiveBrandAffinitySuccess(error))
  }
}

function* brandAffinitySaga() {
  yield takeEvery(GET_BRAND_AFFINITYS, fetchBrandAffinitys)
  yield takeEvery(ADD_BRAND_AFFINITY, onAddBrandAffinity)
  yield takeEvery(UPDATE_BRAND_AFFINITY, onUpdateBrandAffinity)
  yield takeEvery(READ_BRAND_AFFINITY, onReadBrandAffinity)
  yield takeEvery(STATUS_BRAND_AFFINITY, onStatusBrandAffinity)
  yield takeEvery(ARCHIVE_BRAND_AFFINITY, onArchiveBrandAffinity)
}

export default brandAffinitySaga;
