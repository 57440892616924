/* ROLE */
export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES"
export const NO_DATA_ROLES = "NO_DATA_ROLES"
export const GET_ROLES = "GET_ROLES"
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS"
export const GET_ROLES_FAIL = "GET_ROLES_FAIL"

export const ADD_ROLE = "ADD_ROLE"
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS"
export const ADD_ROLE_ERROR = "ADD_ROLE_ERROR"

export const UPDATE_ROLE = "UPDATE_ROLE"
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS"
export const UPDATE_ROLE_ERROR = "UPDATE_ROLE_ERROR"

export const READ_ROLE = "READ_ROLE"
export const READ_ROLE_SUCCESS = "READ_ROLE_SUCCESS"

export const STATUS_ROLE = "STATUS_ROLE"
export const STATUS_ROLE_SUCCESS = "STATUS_ROLE_SUCCESS"

export const ARCHIVE_ROLE = "ARCHIVE_ROLE"
export const ARCHIVE_ROLE_SUCCESS = "ARCHIVE_ROLE_SUCCESS"
