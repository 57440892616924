import {
  LOAD_ACTIVITIES,
  NO_DATA,
  GET_STBR_SETTINGS,
  GET_STBR_SETTINGS_FAIL,
  GET_STBR_SETTINGS_SUCCESS,
  ADD_STBR_SETTING,
  ADD_STBR_SETTING_SUCCESS,
  ADD_STBR_SETTING_ERROR,
  UPDATE_STBR_SETTING,
  UPDATE_STBR_SETTING_SUCCESS,
  UPDATE_STBR_SETTING_ERROR, 
  READ_STBR_SETTING,
  READ_STBR_SETTING_SUCCESS,
  STATUS_STBR_SETTING,
  STATUS_STBR_SETTING_SUCCESS,
  ARCHIVE_STBR_SETTING,
  ARCHIVE_STBR_SETTING_SUCCESS

} from "./actionTypes"

export const loadActivitiesStbrSetting = Params => ({
  type: LOAD_ACTIVITIES,
  payload: Params
})

export const noDataStbrSetting = Params => ({
  type: NO_DATA,
  payload: Params
})

export const getStbrSetting = Params => ({
  type: GET_STBR_SETTINGS,
  payload: Params
})

export const getStbrSettingsSuccess = StbrSettings => ({
  type: GET_STBR_SETTINGS_SUCCESS,
  payload: StbrSettings,
})

export const getStbrSettingsFail = error => ({
  type: GET_STBR_SETTINGS_FAIL,
  payload: error,
})

export const addNewStbrSetting = StbrSetting => ({
  type: ADD_STBR_SETTING,
  payload: StbrSetting,
})

export const addStbrSettingSuccess = StbrSetting => ({
  type: ADD_STBR_SETTING_SUCCESS,
  payload: StbrSetting,
})

export const addStbrSettingFail = StbrSetting => ({
  type: ADD_STBR_SETTING_ERROR,
  payload: StbrSetting,
})

export const updateStbrSetting = StbrSetting => ({
  type: UPDATE_STBR_SETTING,
  payload: StbrSetting,
})

export const updateStbrSettingSuccess = StbrSetting => ({
  type: UPDATE_STBR_SETTING_SUCCESS,
  payload: StbrSetting,
})

export const updateStbrSettingFail = StbrSetting => ({
  type: UPDATE_STBR_SETTING_ERROR,
  payload: StbrSetting,
})

export const readStbrSetting = StbrSetting => ({
  type: READ_STBR_SETTING,
  payload: StbrSetting,
})

export const readStbrSettingSuccess = StbrSetting => ({
  type: READ_STBR_SETTING_SUCCESS,
  payload: StbrSetting,
})

export const statusStbrSetting = StbrSetting => ({
  type: STATUS_STBR_SETTING,
  payload: StbrSetting,
})

export const statusStbrSettingSuccess = StbrSetting => ({
  type: STATUS_STBR_SETTING_SUCCESS,
  payload: StbrSetting,
})

export const archiveStbrSetting = StbrSetting => ({
  type: ARCHIVE_STBR_SETTING,
  payload: StbrSetting,
})

export const archiveStbrSettingSuccess = StbrSetting => ({
  type: ARCHIVE_STBR_SETTING_SUCCESS,
  payload: StbrSetting,
})