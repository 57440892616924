/* eslint-disable react/prop-types */
import { set } from "lodash";
import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import {
    CardBody, Col, Form, Card, Label, Input, Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Row,
    UncontrolledTooltip,

} from "reactstrap";

//react-scroll
import { Element, animateScroll, scroller } from 'react-scroll';


//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import './Support_Tickets.css'
import Support_Ticket_Chat_List from "./Support_Ticket_Chat_List";
import axios from "axios";
import $ from "jquery";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import moment from "moment";

//redux

import { useSelector, useDispatch } from "react-redux";
import { statusSupportTicketAdmin as changeStatusSupportTicketAdmin } from 'store/Support_Ticket/Admin_Ticket/Admin_Support/actions'

var file

const Support_Ticket_Chat = (props) => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [userMsg, setUsermsg] = useState("")
    const user = userInfo.name
    const { notificationData, toggleSpecificNotification } = props
    const toscroll = useRef(null)
    const dispatch = useDispatch()
    const [chats, setChats] = useState([])
    const [userFilename, setUserFilename] = useState('')
    console.log(notificationData)
    const [inputKey, setInputKey] = useState(Math.random().toString(36))
    const [btnprimary1, setBtnprimary1] = useState(false)

    const [errorMessage, setErrorMessage] = useState("");

    const [chatLoader, setChatLoader] = useState(false)



    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const handleUserMsg = e => {
        e.preventDefault()
        setUsermsg(e.target.value)
    }

    const { supportTicketAdminList } = useSelector(state => ({
        supportTicketAdminList: state.supportTicketAdmins.supportTicketAdminList
    }))



    const scrollToBottom = () => {
        setTimeout(() => {
            animateScroll.scrollToBottom({
                containerId: 'chat-container',
                duration: 0,
            });
        }, 500);
    };

    useEffect(() => {
        scrollToBottom();
    }, [chats]);




    // useLayoutEffect(() => {
    //     if (!toscroll || !toscroll.current) {
    //         return;
    //     }

    //     window.setTimeout(() => {
    //         const scrollY = window.scrollY;
    //         requestAnimationFrame(() => {
    //             toscroll.current.scroll({ top: toscroll.current.scrollHeight, behavior: 'instant' });
    //             window.scrollTo(0, scrollY + toscroll.current.scrollHeight);
    //         });
    //         toscroll.current.addEventListener('DOMNodeInserted', event => {
    //             const { currentTarget: target } = event;
    //             target.scroll({ top: target.scrollHeight, behavior: 'instant' });
    //         });
    //     }, 500);
    // }, [toscroll]);


    const onFileChange = e => {
        if (e.target.files[0] != undefined) {
            setUserFilename(e.target.files[0].name)
            file = e.target.files[0]
            if (file.size > 10485760) {
                toastr.error("File size must be less than 10 MB");
                console.log("large file")
                setUserFilename("")
                setInputKey(Math.random().toString(36))
                file = ''
            }
        } else {
            setUserFilename("")
        }
    }

    const handleStatus = () => {
        let status = {
            "supportTicketID": [notificationData.supportTicketID],
            "supportTicketStatus": "closed"
        }
        dispatch(changeStatusSupportTicketAdmin(status))
        toggleSpecificNotification()
        console.log("close status is called")
    }

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    useEffect(() => {
        getChats()
    }, [])

    useEffect(() => {
        const intervalCall = setInterval(() => {
            getChats()
        }, 10000);
        return () => {
            // clean up
            clearInterval(intervalCall);
        };
    }, []);

    const getChats = (e) => {
        let bodyChat = {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                name: userInfo.name
        }
        axios.post('/v2.0/support/SupportTickets/read/' + notificationData.supportTicketID, bodyChat,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data.success) {
                    let todayLine = {
                        "dateNaming": 'Today'
                    }
                    let yesterdayLine = {
                        "dateNaming": 'Yesterday'
                    }
                    let todayLinePresent = 'yes'
                    let yesterdayLinePresent = 'yes'
                    res.data[0].chat.map((item, index) => {
                        console.log(moment(item.messageTimeStamp).calendar())
                        if (moment(item.messageTimeStamp).calendar().includes('Today')) {
                            if (todayLinePresent === 'yes') {
                                res.data[0].chat.splice(index, 0, todayLine);
                                todayLinePresent = 'no'
                            }
                        }
                        if (moment(item.messageTimeStamp).calendar().includes('Yesterday')) {
                            if (yesterdayLinePresent === 'yes') {
                                res.data[0].chat.splice(index, 0, yesterdayLine);
                                yesterdayLinePresent = 'no'
                            }
                        }
                    })
                    setChats(res.data[0].chat)
                }
            })
            .catch((err) => {
                console.log("e", err)
                setInputKey(Math.random().toString(36))
                file = ''
            });

    }

    function _handleSubmit(e) {
        e.preventDefault();
        setChatLoader(true)

        let fileData = new FormData();
        fileData.append('file', file);
        fileData.append("supportTicketID", notificationData.supportTicketID);
        fileData.append("message", userMsg)
        fileData.append('userID', userInfo.userID)
        fileData.append('userRole', userInfo.userRole)
        fileData.append('companyID', userInfo.companyID)
        fileData.append('name', userInfo.name)

        $.ajax({
            url: "https://support-ticket.bidsxchange.com/create/chat",
            type: 'POST',
            data: fileData,
            processData: false,
            contentType: false,
            mode: 'cors',
            cache: false,
            credentials: 'include',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            enctype: "multipart/form-data",
            success: function (data) {
                // Success..
                setChatLoader(false)

                e.target.reset()
                setUsermsg('')
                getChats()
                setUserFilename('')
                console.log('success....', data);
                setInputKey(Math.random().toString(36))
                file = ''
            },
            // Fail..
            error: function (xhr, status, err) {
                console.log(xhr, status);
                console.log(err);
                setInputKey(Math.random().toString(36))
                file = ''
            }
        });
    }

    const handleClear = () => {
        setUserFilename('')
        setInputKey(Math.random().toString(36))
    }

    const handleEnterKey = (e) => {
        console.log(e.key)
        const buttonSend = document.getElementById("sendBtn");
        if (e.key === "Enter") {
            if (e.shiftKey) {
                console.log("next-line")
            } else {
                e.preventDefault()
                buttonSend.click();
            }
        }
    }

    const handleAttachmentEnter = (e) => {
        const buttonSend = document.getElementById("sendBtn");
        if(e.key === "Enter"){
            e.preventDefault()
            buttonSend.click()
        }
    }



    return (
        <Col md="12">
            <Col sm="12">
                <Card className="p-1 mb-0" >
                    <CardBody className="d-flex justify-content-end">
                        <div className="d-flex justify-content-end fixed">
                            <button
                                // outline
                                className="m-1 active-state"
                                style={{ borderColor: "#20C9AC" }}

                            >
                                {`Status: ${notificationData.supportTicketStatus}`}
                            </button>
                            <Button
                                color="secondary"
                                className="m-1"
                                type="button"
                                onClick={handleStatus}
                                disabled = {notificationData.supportTicketStatus === 'closed'}
                            >
                                Close Ticket
                            </Button>
                        </div>
                    </CardBody>
                </Card>
                <Card className="mb-0 mt-0">
                    <CardBody className="mt-0 chatbox-outer">
                        <div className="chatbox d-flex flex-column">
                            <Element id="chat-container" style={{ overflowY: 'scroll' }} offset={10} >
                                <ul className="ul-chat-list" >
                                    {
                                        chats.length !== 0 &&
                                        chats.map((each, index) => <Support_Ticket_Chat_List chat={each} key={index} chats={chats} />)
                                    }
                                </ul>
                            </Element>

                        </div>
                        <div className="p-3 chat-input-section">
                            <Form onSubmit={_handleSubmit}>
                                <Row>
                                    <Col>
                                        <div className="position-relative">
                                            <textarea
                                                type="text"
                                                rows={1}
                                                value={userMsg}
                                                // onKeyPress={onKeyPress}
                                                onChange={handleUserMsg}
                                                className="form-control chat-input"
                                                style={{overflow: "hidden", resize: "none"}}
                                                onKeyDown={handleEnterKey}
                                                placeholder="Enter Message..."
                                            />
                                            <div className="chat-input-links">
                                                <ul className="list-inline mb-0 ">
                                                    <li className="list-inline-item d-flex justify-content-center align-items-center">
                                                        <div className="support-ticket-file btn btn-sm  d-flex justify-content-center align-items-center">
                                                            <i className='bx bx-paperclip  bx-sm rotate-90'></i>
                                                            {userFilename ? `${userFilename}` : "Attach File"}
                                                            <input className="support-ticket-input" type="file" key={inputKey || ''}  onKeyDown={handleAttachmentEnter} onChange={onFileChange} name="file" accept=".png,.jpeg,.jpg,video/*" />
                                                        </div>
                                                        {userFilename && <i className='bx bx-x ml-3' style={{ cursor: "pointer", fontSize: "1.2rem" }} onClick={() => handleClear()}></i>}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="col-auto">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            //   onClick={() =>
                                            //     addMessage(currentRoomId, currentUser.name)
                                            //   }
                                            className="btn btn-primary btn-rounded chat-send w-md "
                                            disabled={notificationData.supportTicketStatus === "closed"}
                                            id="sendBtn"
                                        >
                                            <i className={chatLoader ? "bx bx-loader-circle bx-spin font-size-16 align-middle me-2 ml-2 mr-2" : ''} ></i>

                                            <span className="d-none d-sm-inline-block me-2">
                                                Send
                                            </span>
                                            <i className="mdi mdi-send" />
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Col>
    )
}

Support_Ticket_Chat.propTypes = {}

export default Support_Ticket_Chat