// VIDEO CONTENT
export const LOAD_VIDEO_CONTENTS = "LOAD_VIDEO_CONTENTS"
export const GET_VIDEO_CONTENTS = "GET_VIDEO_CONTENTS"
export const GET_VIDEO_CONTENTS_SUCCESS = "GET_VIDEO_CONTENTS_SUCCESS"
export const GET_VIDEO_CONTENTS_FAIL = "GET_VIDEO_CONTENTS_FAIL"

export const ADD_VIDEO_CONTENT = "ADD_VIDEO_CONTENT"
export const ADD_VIDEO_CONTENT_SUCCESS = "ADD_VIDEO_CONTENT_SUCCESS"
export const ADD_VIDEO_CONTENT_ERROR = "ADD_VIDEO_CONTENT_ERROR"


export const DELETE_VIDEO_CONTENT = "DELETE_VIDEO_CONTENT"
export const DELETE_VIDEO_CONTENT_SUCCESS = "DELETE_VIDEO_CONTENT_SUCCESS"
export const DELETE_VIDEO_CONTENT_ERROR = "DELETE_VIDEO_CONTENT_ERROR"

 