import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_PLAYERSETTINGS, ADD_PLAYERSETTING, UPDATE_PLAYERSETTING, READ_PLAYERSETTING, STATUS_PLAYERSETTING, ARCHIVE_PLAYERSETTING } from "./actionTypes"

import {
  loadActivitiesPlayerSetting,
  noDataPlayerSetting,
  getPlayerSettingSuccess,
  getPlayerSettingFail,
  addPlayerSettingSuccess,
  addPlayerSettingFail,
  updatePlayerSettingSuccess,
  updatePlayerSettingFail,
  readPlayerSettingSuccess,
  statusPlayerSettingSuccess,
  archivePlayerSettingSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getPlayerSetting, getPlayerSettingsByAdUnit, createPlayerSetting, updatePlayerSetting, readPlayerSetting, statusChangePlayerSetting, archivePlayerSetting } from "../../../helpers/Backend_helper"

function* fetchPlayerSettings({ payload: playerSetting }) {
  try {
    yield put(loadActivitiesPlayerSetting(true))
    var getPlayerSettingsList
    console.log(playerSetting)
    // if(playerSetting && playerSetting.adUnitID !== ''){
    //   getPlayerSettingsList = yield call(getPlayerSettingsByAdUnit,playerSetting)
    //   getPlayerSettingsList.response.map((item, index) => {
    //     item.id = index + 1
    // })
    //   yield put(getPlayerSettingSuccess(getPlayerSettingsList.response))
    // }else 
    if (playerSetting) {
      getPlayerSettingsList = yield call(getPlayerSetting, playerSetting)
      if (getPlayerSettingsList.statusCode === 200) {
        sessionStorage.setItem("SupplyServiceDown", JSON.stringify(false));
        getPlayerSettingsList.response.playerSettings.map((item, index) => {
          item.id = index + 1
        })
        if (getPlayerSettingsList.response.playerSettings.length === 0) {
          yield put(noDataPlayerSetting(true))
        }
        yield put(getPlayerSettingSuccess(getPlayerSettingsList))
      }
      } else {
        getPlayerSettingsList = []
        yield put(getPlayerSettingSuccess(getPlayerSettingsList))
      }
    } catch (error) {
      yield put(getPlayerSettingFail(error))
    }
  }

function* onReadPlayerSetting({ payload: playerSetting }) {
    try {
      const response = yield call(readPlayerSetting, playerSetting)
      yield put(readPlayerSettingSuccess(response))
    } catch (error) {
      yield put(readPlayerSettingSuccess(error))
    }
  }

  function* onAddPlayerSetting({ payload: playerSetting }) {
    try {
      const response = yield call(createPlayerSetting, playerSetting)
      if (response.statusCode === 200) {
        yield put(addPlayerSettingSuccess(response))
      } else {
        yield put(addPlayerSettingFail(response))
      }
    } catch (error) {
      yield put(addPlayerSettingFail(error))
    }
  }

  function* onUpdatePlayerSetting({ payload: playerSetting }) {
    try {
      const response = yield call(updatePlayerSetting, playerSetting)
      if (response.statusCode === 200) {
      yield put(updatePlayerSettingSuccess(response))
      }
    } catch (error) {
      yield put(statusPlayerSettingSuccess(error))
    }
  }

  function* onStatusPlayerSetting({ payload: playerSetting }) {
    try {
      const response = yield call(statusChangePlayerSetting, playerSetting)
      if (response.statusCode === 200) {
      yield put(statusPlayerSettingSuccess(response))
      }
    } catch (error) {
      yield put(updatePlayerSettingFail(error))
    }
  }

  function* onArchivePlayerSetting({ payload: playerSetting }) {
    try {
      const response = yield call(archivePlayerSetting, playerSetting)
      if (response.statusCode === 200) {
      yield put(archivePlayerSettingSuccess(response))
      }
    } catch (error) {
      yield put(archivePlayerSettingSuccess(error))
    }
  }

  function* playerSettingSaga() {
    yield takeEvery(GET_PLAYERSETTINGS, fetchPlayerSettings)
    yield takeEvery(ADD_PLAYERSETTING, onAddPlayerSetting)
    yield takeEvery(UPDATE_PLAYERSETTING, onUpdatePlayerSetting)
    yield takeEvery(READ_PLAYERSETTING, onReadPlayerSetting)
    yield takeEvery(STATUS_PLAYERSETTING, onStatusPlayerSetting)
    yield takeEvery(ARCHIVE_PLAYERSETTING, onArchivePlayerSetting)
  }

  export default playerSettingSaga;
