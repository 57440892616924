import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Input,
  Spinner,
  Label
} from "reactstrap"
import queryString from 'query-string';

import DateRangePickers from '../../../helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";
import LoadingTable from "pages/LoaderPages/LoadingTable";
import BrandSafetyList from "./BrandSafetyList";
import New_BrandSafety from "./New_BrandSafety";
import axios from "axios";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var filterCount = 0

const Admin_Brand_Safety = () => {
  const dispatch = useDispatch();
  const [isRight, setIsRight] = useState(false);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const parsed = queryString.parse(window.location.search);
  const [statusSearch, setStatusSearch] = useState('Active')
  const [isArchived, setIsArchived] = useState(false)
  const [selected, setSelected] = useState([])
  const [createState, setCreateState] = useState(true)
  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));

  const userInfo = JSON.parse(localStorage.getItem('authUser'));


  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const [switchLoader, setSwitchLoader] = useState(true);
  const [loader, setLoader] = useState(true)

  const [bsData, setBsData] = useState([])

  const [existingPartners, setExistingPartners] = useState([])


  const getBrandSafetyData = async () => {

    console.log("Calling Again")
    const userInfo2 = await JSON.parse(localStorage.getItem('authUser'));
    const body = {
      "userID": userInfo2.userID,
      "userRole": userInfo2.userRole,
      "companyID": userInfo2.companyID,
      "name": userInfo2.user, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
    }

    const headers = {
      "Content-Type": "application/json",
      "Accept": 'application/json',
      "Authorization": `Bearer ${userInfo2.token}`
    }
    setLoader(true)
    await axios
      .post("/v2.0/operations/BrandSafety/admin/read",body, { headers: headers }).then((res) => {
        console.log(res.data.response);
        setBsData(res.data.response ? res.data.response : [])
        setLoader(false)
      }).catch((err) => {
        console.log(err)
        setLoader(false)
      })

  }
  useEffect(() => {
    setExistingPartners(bsData?.map((item) => item.partnerID))
  }, [bsData])
  console.log(existingPartners)

  useEffect(() => {

    console.log('BS API')
    getBrandSafetyData();

  }, [])

  // useEffect(()=>{
  //   getBrandSafetyData();
  // },[isRight])

  console.log(bsData)

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }


  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {

      setIsRight(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }


  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 5000,
    extendedTimeOut: 1000
  };



  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      console.log(row, isSelect, rowIndex)
      if (isSelect) {
        setSelected([...selected, row.id])
        setSelectedRowsStatus(true)
        selectedIndex.push(row.publisherID)
        selectedItem.push(row.publisherID)
        console.log(selectedIndex)
      } else {
        setSelected(selected.filter(x => x !== row.id))
        let index = selectedIndex.indexOf(row.publisherID)
        selectedIndex.splice(index, 1)
        let index2 = selectedItem.indexOf(row.publisherID)
        selectedItem.splice(index2, 1)
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
        console.log(selectedIndex)
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      const ids = rows.map(r => r.id);
      if (isSelect) {
        setSelected(ids)
        rows.map((item, index) => {
          selectedIndex.push(item.publisherID)
          selectedItem.push(item.publisherID)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
      }

    }
  };

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
    selectedIndex = []
  }


  const { loading } = useSelector(state => ({
    loading: state.publishers.loading,
  }));





  useEffect(() => {
    if (loading) {
      setSwitchLoader(true)
    }
  }, [loading]);


  const toggleRightCanvas = () => {
    selectedData = ''
    setIsRight(!isRight);
    setCreateState(!createState)
  };

  const toggleEnableScroll = () => {
    setIsEnableScroll(!isEnableScroll);
  };


  const toggleDrawer = (close, bsData) => (event) => {

    selectedData = bsData;

    setIsRight(close);
    setCreateState(!createState)
  };





  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const brandSafetyListColumns = [
    {
      text: "ID",
      headerClasses: "ID",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },

    {
      text: "ID",
      headerClasses: "ID",
      dataField: "partnerID",
      sort: true,
    },
    {
      text: "Partner Name",
      dataField: "partnerName",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, bsData) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to={`/AdUnitGroup?publisherId=${bsData.partnerID}&publisherName=${bsData.partnerName}&status=AdUnitGroupCreateByParent`} className="color-dark-blue ">
              {bsData.partnerName}
            </Link>
          </h5>
        </>
      ),
    },
    {
      dataField: "brandSafetyCreateTime",
      text: "Creation Date",
      sort: true,
    },
    {
      dataField: "brandSafetyModifyTime",
      text: "Modified Date",
      sort: true,
    },


    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, bsData) => (
        <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
          <span className="text-success " onClick={toggleDrawer(true, bsData.partnerID)}>

            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
            ></i>

          </span>
        </div>
      ),
    },
  ];

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];




  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      <div className="page-content">
        <Helmet>
          <title>Brand Safety | Bidsxchange</title>
        </Helmet>
        <Container fluid>
          {/* Render Breadcrumbs */}

          <div className=" d-sm-flex  justify-content-between">

            <SupplyBreadCrumb title="Brand Safety " breadcrumbItem="Brand Safety " />


            <div className="text-sm-end">
              <div className="btn-group">
                {/* <Dropdown
                    direction="left"
                    isOpen={isMenu}
                    toggle={toggleMenu}
                  >
                    <DropdownToggle
                      tag="button"
                      className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                    >
                      <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                      {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                        Filter Applied
                      </span> : <span className="d-none font-16 d-sm-inline-block">
                        Filter
                      </span>
                      }
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                      <div className="dropdown-item-text">
                        <div className="row">
                          <h5 className="mb-0">Filters</h5>
                        </div>
                      </div>

                      <DropdownItem divider />
                      <Card>
                        <CardBody aria-hidden="true">
                          <Row >
                            <Col sm="12" className="mt-2">
                              <Label className="form-label">Status</Label>
                              <div className=" text-sm-end">
                                <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                  <option value=''> All</option>
                                  <option value='Active' > Active</option>
                                  <option value='Inactive'> InActive</option>
                                  <option value='Archived'> Archived</option>
                                </Input>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      <DropdownItem divider />
                      <Row>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={clearAllFilter}
                          >
                            Clear ALL
                          </DropdownItem>
                        </Col>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={applyFilter}
                          >
                            Apply Filter
                          </DropdownItem>
                        </Col>
                      </Row>

                    </DropdownMenu>
                  </Dropdown> */}
              </div> &nbsp;&nbsp;
              <Button
                color="primary"
                className="font-16 btn-block btn btn-primary"
                onClick={toggleRightCanvas}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                New Brand Safety
              </Button>
            </div>

          </div>
          {loader &&
            <LoadingTable loading={loader} targetLoading={handleSwitch} />
          }


          {!loader &&
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField={keyField}
                      data={bsData}
                      columns={brandSafetyListColumns}
                      // bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box ms-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="4"></Col>
                          </Row>
                          <Row>
                            {/* <Col xl="12">
                              {selectedRowsStatus && selectedIndex.length > 0 &&
                                <div className="row m-0 React-Table-Selection">
                                  <div style={{ padding: '0.5% 2%' }}>

                                    &nbsp;  &nbsp;

                                    <Button color="primary" className="btn btn-primary " onClick={archivePublisher} >
                                      Archive
                                    </Button>

                                    <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                      className="mdi mdi-close-thick font-size-18"
                                    ></i></Button>
                                  </div>
                                </div>
                              }
                            </Col> */}
                            <Col xl="12" className="table-responsive">
                              <div >
                                <BootstrapTable
                                  keyField={keyField}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                 
                                  classes={
                                    "table align-middle table-nowrap"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  pagination={paginationFactory(pageOptions)}
                                  noDataIndication={<h5 className="text-center">No Data</h5>}
                                // ref={node}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </Container>
      </div>
      {/* </div> */}

      <Offcanvas isOpen={isRight} direction='end' >
        <OffcanvasHeader toggle={toggleRightCanvas} >
          {selectedData ? 'Edit Brand Safety' : 'Add Brand Safety'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <New_BrandSafety existingPartners={existingPartners} closeCanvas={toggleDrawer(false, '')} toEdit={bsData.length ? bsData.filter((item, i) => item.partnerID === selectedData) : []} />
        </OffcanvasBody>
      </Offcanvas>


    </React.Fragment>
  )
}

export default Admin_Brand_Safety