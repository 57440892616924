import {
  LOAD_ACTIVITIES,
  NO_DATA_HOUSE_ADUNITS,
    GET_HOUSE_ADUNITS,
    GET_HOUSE_ADUNITS_FAIL,
    GET_HOUSE_ADUNITS_SUCCESS,
    ADD_HOUSE_ADUNIT,
    ADD_HOUSE_ADUNIT_SUCCESS,
    ADD_HOUSE_ADUNIT_ERROR,
    UPDATE_HOUSE_ADUNIT,
    UPDATE_HOUSE_ADUNIT_SUCCESS,
    UPDATE_HOUSE_ADUNIT_ERROR,
    READ_HOUSE_ADUNIT,
    READ_HOUSE_ADUNIT_SUCCESS,
    STATUS_HOUSE_ADUNIT,
    STATUS_HOUSE_ADUNIT_SUCCESS,
    ARCHIVE_HOUSE_ADUNIT ,
    ARCHIVE_HOUSE_ADUNIT_SUCCESS

  } from "./actionTypes"

  export const loadActivitiesAds = Params => ({
    type: LOAD_ACTIVITIES,
    payload:Params
  })
  
  export const noAds = Params => ({
    type: NO_DATA_HOUSE_ADUNITS,
    payload:Params
  })
  
  export const getHouseAdUnits = Params => ({
    type: GET_HOUSE_ADUNITS,
    payload:Params
  })
  
  export const getHouseAdUnitsSuccess = HouseAdUnits => ({
    type: GET_HOUSE_ADUNITS_SUCCESS,
    payload: HouseAdUnits,
  })

  export const getHouseAdUnitsFail = error => ({
    type: GET_HOUSE_ADUNITS_FAIL,
    payload: error,
  })

  export const addNewHouseAdUnit = HouseAdUnit => ({
    type: ADD_HOUSE_ADUNIT,
    payload: HouseAdUnit,
  })
  
  export const addHouseAdUnitSuccess = adUnit => ({
    type: ADD_HOUSE_ADUNIT_SUCCESS,
    payload: adUnit,
  })
  
  export const addHouseAdUnitFail = adUnit => ({
    type: ADD_HOUSE_ADUNIT_ERROR,
    payload: adUnit,
  })

  export const updateHouseAdUnit = adUnit => ({
    type: UPDATE_HOUSE_ADUNIT,
    payload: adUnit,
  })
  
  export const updateHouseAdUnitSuccess = adUnit => ({
    type: UPDATE_HOUSE_ADUNIT_SUCCESS,
    payload: adUnit,
  })
  
  export const updateHouseAdUnitFail = adUnit => ({
    type: UPDATE_HOUSE_ADUNIT_ERROR,
    payload: adUnit,
  })

  export const readHouseAdUnit = adUnit => ({
    type: READ_HOUSE_ADUNIT,
    payload: adUnit,
  })

  export const readHouseAdUnitSuccess = adUnit => ({
    type: READ_HOUSE_ADUNIT_SUCCESS,
    payload: adUnit,
  })

  export const statusHouseAdUnit = adUnit => ({
    type: STATUS_HOUSE_ADUNIT,
    payload: adUnit,
  })

  export const statusHouseAdUnitSuccess = adUnit => ({
    type: STATUS_HOUSE_ADUNIT_SUCCESS,
    payload: adUnit,
  })

  export const archiveHouseAdUnit = adUnit => ({
    type: ARCHIVE_HOUSE_ADUNIT,
    payload: adUnit,
  })

  export const archiveHouseAdUnitSuccess = adUnit => ({
    type: ARCHIVE_HOUSE_ADUNIT_SUCCESS,
    payload: adUnit,
  })