
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types'
import {
    Col,
    Card,
    CardBody,
} from "reactstrap";

const LoadingVCSCard = props => {

    const [reportData, setReportData] = useState([
        { title: "Total Code Served", iconClass: "bx-bar-chart", description: "0", yesterdayRate: '0%', isIncreased: 'zero' },
        { title: "Paid Impressions", iconClass: "bx-bar-chart", description: "0", yesterdayRate: '0%', isIncreased: 'zero' },
        { title: "Revenue", iconClass: "bx-dollar-circle", description: "$0", yesterdayRate: '0%', isIncreased: 'zero' },
        { title: "Estimated eCPM", iconClass: "bx-dollar-circle", description: "$0", yesterdayRate: '0%', isIncreased: 'zero' },
        { title: "Estimated Fill Rate", iconClass: "bx-show-alt", description: "0%", yesterdayRate: '0%', isIncreased: 'zero' },
        { title: "Revenue", iconClass: "bx-dollar-circle", description: "$0", yesterdayRate: '0%', isIncreased: 'zero' },
        { title: "Estimated eCPM", iconClass: "bx-dollar-circle", description: "$0", yesterdayRate: '0%', isIncreased: 'zero' },
        { title: "Estimated Fill Rate", iconClass: "bx-show-alt", description: "0%", yesterdayRate: '0%', isIncreased: 'zero' },
    ])

    const [timer, setTimer] = useState({ count: 0 })
    const increment = useRef(null)

    useEffect(() => {
        handleStart()
    }, []);

    useEffect(() => {
        if (props.loading) {
        } else {
            if (timer.count >= 1) {
                clearInterval(increment.current)
                setTimer({ count: 0 })
                props.targetLoading()
            }
        }

    }, [timer.count, props.loading]);

    const handleStart = () => {
        increment.current = setInterval(() => {
            setTimer(prevState => ({
                count: prevState.count + 1
            }))
            console.log()
        }, 100)
    }

    return (
        <React.Fragment>
            {reportData.map((report, key) => (
                <Col sm={4} xxl={3} key={"_col_" + key}>
                    <Card className="mini-stats-wid dashboard-cards placeholder-glow text-center">
                        <CardBody>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <div className='row' >
                                    <span className="placeholder " style={{ height:'10pc',borderRadius:'10px' }}></span>
                                    </div>
                                    <br></br>
                                    <h4 className='justify-content-center' style={{ marginBottom: '3%' }}><span className="placeholder col-9 "></span></h4>
                                    <h5 className='justify-content-center' style={{ marginBottom: '3%' }}><span className="placeholder col-5 "></span></h5>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            ))}
        </React.Fragment>
    )
}

LoadingVCSCard.propTypes = {}

export default LoadingVCSCard
