/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Input,
  Spinner,
  Label, Progress
} from "reactstrap"

import DateRangePickers from 'helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';
import moment from "moment";


import {
  getTeamHistoryReports as onGetAdminAnalyticsReports,
} from "store/Reports/AdminReports/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"


var selectedItem = []
var selectedData = ''
var selectedReportData = ""
var selectedIndex = []
var loader = false

const Team_History_Analytical = props => {
  const dispatch = useDispatch();
  const [isRight, setIsRight] = useState(false);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const [statusSearch, setStatusSearch] = useState('Active')
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [isArchived, setIsArchived] = useState(false)
  const [selected, setSelected] = useState([])

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const changeDatePick = (event) => {
    console.log(event)
    console.log("start: ", event.startDate._d);
    console.log("end: ", event.endDate._d);
    setFromDate(event.startDate._d.toISOString());
    setToDate(event.endDate._d.toISOString());
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };

  useEffect(() => {
    let query = {
      "reportType": props.selectedTeam,
      isSupplyPartner:userInfo.isSupplyPartner
    }
    dispatch(onGetAdminAnalyticsReports({ ...query }));
  }, []);

  const { scheduleReports } = useSelector(state => ({
    scheduleReports: state.adminReports.teamHistoryRpt,
  }));

  const { loading } = useSelector(state => ({
    loading: state.adminReports.loading,
  }));


  const toggleRightCanvas = () => {
    selectedData = ''
    selectedReportData = ""
    // dispatch(onGetScheduleReports({ filter: statusSearch }));
    setIsRight(!isRight);
  };

  const toggleEnableScroll = () => {
    setIsEnableScroll(!isEnableScroll);
  };

  const toggleDrawer = (close, scheduleReport, action) => (event) => {
    console.log(event)
    if (event === 'Create') {
      toastr.success('Analytics Report Saved Successfully!...')

    }
    if (event === 'Update') {
      toastr.success('Analytics Report Updated Successfully!...')

    }
    selectedData = {
      "requestBody": {
        "dimensions": {
          "partner_name": false,
          "publisher_name": false,
          "ad_unit_group_name": false,
          "ad_unit_name": false,
          "ad_unit_type": false,
          "ad_unit_size": false,
          "advertiser_name": true,
          "io_group_name": true,
          "io_name": true,
          "site": false,
          "line_item_name": false,
          "line_item_type": false,
          "creative_name": false,
          "creative_type": false,
          "country": false,
          "state": false,
          "city": false,
          "device": false,
          "os": false,
          "ad_unit_id": false,
          "line_item_id": true,
          "creative_id": false
        },
        "startDate": "2024-01-31",
        "endDate": "2024-02-06",
        "filters": {
          "partner_name": "",
          "publisher_name": "",
          "ad_unit_group_name": "",
          "ad_unit_id": 0,
          "ad_unit_name": "",
          "ad_unit_type": "",
          "ad_unit_size": "",
          "advertiser_name": "",
          "io_group_name": "",
          "io_name": "",
          "site": "",
          "line_item_id": 0,
          "line_item_name": "",
          "line_item_type": "",
          "creative_id": 0,
          "creative_name": "",
          "creative_type": "",
          "country": "",
          "state": "",
          "city": "",
          "device": "",
          "os": ""
        },
        "metrices": {
          "requests": true,
          "impressions": true,
          "refreshRequests": true,
          "refreshImpressions": true,
          "click": false,
          "refreshClick": false
        },
        "range": "daily",
        "userID": 242,
        "userRole": "Root",
        "companyID": 84,
        "name": "rootUser"
      }
    }
    selectedReportData = action
    setIsArchived(scheduleReport.isScheduleArchived)
    // dispatch(onGetScheduleReports({ filter: statusSearch }));
    setIsRight(close);
  };

  // useEffect(() => {
  //   if (scheduleReports !== null) {
  //     // loader = true
  //     dispatch(onGetScheduleReports({ filter: statusSearch }));
  //   }
  // }, []);

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const scheduleReportListColumns = [
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    //   {
    //     text: "ID",
    //     dataField: "scheduleID",
    //     sort: true,
    //     formatter: (cellContent, scheduleReport) => (
    //       <>
    //         <span>{scheduleReport.scheduleID}</span>
    //       </>
    //     ),
    //   },
    {
      dataField: "QueryDate",
      text: "Queried Date",
      sort: true,
    },
    {
      dataField: "QueryTime",
      text: "Queried Time",
      sort: true,
      formatter: (cellContent, report) => (
        <>
          <span>{moment(parseInt(report.QueryTime*1000)).format('h:mm A')}</span>
        </>
      ),
    },

    {
      text: "Report ID",
      dataField: "ReportID",
      sort: true,
    },
    {
      text: "Date Range",
      dataField: "DateRange",
      sort: true,
    },
    {
      text: "Range",
      dataField: "Range",
      sort: true,
    },
    {
      dataField: "ReportStatus",
      text: "Status",
      sort: true,
      formatter: (cellContent, scheduleReport) => (
        <>
          {scheduleReport.ReportStatus === 'processed_UI' &&
            <span className={"active-state"}>Report is Available in UI</span>
          }
          {scheduleReport.ReportStatus === 'processed_EMAIL' &&
            <span className={"Email-state"}>Report Sent by Email</span>
          }
          {scheduleReport.ReportStatus === 'no_data' &&
            <span className={"medium-state"}>No Data is Available</span>
          }
          {scheduleReport.ReportStatus === 'in_progress' &&
            <span className={"inactive-state"}>Report is in Progress</span>
            // <Progress animated color="success" value="75" />
          }
          {scheduleReport.ReportStatus === 'failed' &&
            <span className={"inactive-state"}>Query Failed</span>
            // <Progress animated color="success" value="75" />
          }
        </>
      ),
    },
    // {
    //   dataField: "",
    //   isDummyField: true,
    //   editable: false,
    //   text: "Action",
    //   align: 'center',
    //   headerAlign: 'center',

    //   // eslint-disable-next-line react/display-name
    //   formatter: (cellContent, scheduleReport) => (
    //     <span  >
    //       <span className="text-success " onClick={toggleDrawer(true, scheduleReport, 'run')}>
    //         <i
    //           className="mdi mdi-play font-size-18"
    //           id="edittooltip"
    //         ></i>
    //       </span> &nbsp;&nbsp;
    //       <span className="text-success " onClick={toggleDrawer(true, scheduleReport, 'edit')}>
    //         <i
    //           className="mdi mdi-pencil font-size-18"
    //           id="edittooltip"
    //         ></i>
    //       </span>
    //     </span>
    //   ),
    // },
  ];

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];


  console.log(scheduleReports)

  return (
    <React.Fragment>
      <Container fluid style={{ pointerEvents: (loading) ? 'none' : 'unset' }}>

        {loading &&
          <Spinner className="ms-2 loader" color="primary" />
        }
        {!loading &&
          <Row>
            <Col lg="12">
              <Card>
                <CardBody aria-hidden="true">
                  <ToolkitProvider
                    keyField={keyField}
                    data={scheduleReports}
                    columns={scheduleReportListColumns}
                    // bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="8">
                            <div className="search-box ms-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>

                          <Col xl="12" className="table-responsive">
                            <div >
                              <BootstrapTable
                                keyField={keyField}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap"
                                }
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                pagination={paginationFactory(pageOptions)}
                                noDataIndication={<h5 className="text-center">No Data</h5>}
                              // ref={node}
                              />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
      </Container>
    </React.Fragment>
  )
}

Team_History_Analytical.propTypes = {}

export default Team_History_Analytical