import {
    LOAD_ADVERTISERS,
    NO_DATA,
    GET_ADVERTISERS,
    GET_ADVERTISERS_FAIL,
    GET_ADVERTISERS_SUCCESS,
    ADD_ADVERTISER,
    ADD_ADVERTISER_SUCCESS,
    ADD_ADVERTISER_ERROR,
    UPDATE_ADVERTISER,
    UPDATE_ADVERTISER_SUCCESS,
    UPDATE_ADVERTISER_ERROR,
    READ_ADVERTISER,
    READ_ADVERTISER_SUCCESS,
    STATUS_ADVERTISER,
    STATUS_ADVERTISER_SUCCESS,
    ARCHIVE_ADVERTISER ,
    ARCHIVE_ADVERTISER_SUCCESS,

  } from "./actionTypes"

  export const loadAdvertisers = Params => ({
    type: LOAD_ADVERTISERS,
    payload:Params
  })

  export const noDataAdvertisers = Params => ({
    type: NO_DATA,
    payload:Params
  })

  export const getAdvertisers = Params => ({
    type: GET_ADVERTISERS,
    payload:Params
  })
  
  export const getAdvertisersSuccess = Advertisers => ({
    type: GET_ADVERTISERS_SUCCESS,
    payload: Advertisers,
  })

  export const getAdvertisersFail = error => ({
    type: GET_ADVERTISERS_FAIL,
    payload: error,
  })

  export const addNewAdvertiser = Advertiser => ({
    type: ADD_ADVERTISER,
    payload: Advertiser,
  })
  
  export const addAdvertiserSuccess = advertiser => ({
    type: ADD_ADVERTISER_SUCCESS,
    payload: advertiser,
  })
  
  export const addAdvertiserFail = advertiser => ({
    type: ADD_ADVERTISER_ERROR,
    payload: advertiser,
  })

  export const updateAdvertiser = advertiser => ({
    type: UPDATE_ADVERTISER,
    payload: advertiser,
  })
  
  export const updateAdvertiserSuccess = advertiser => ({
    type: UPDATE_ADVERTISER_SUCCESS,
    payload: advertiser,
  })
  
  export const updateAdvertiserFail = advertiser => ({
    type: UPDATE_ADVERTISER_ERROR,
    payload: advertiser,
  })

  export const readAdvertiser = advertiser => ({
    type: READ_ADVERTISER,
    payload: advertiser,
  })

  export const readAdvertiserSuccess = advertiser => ({
    type: READ_ADVERTISER_SUCCESS,
    payload: advertiser,
  })

  export const statusAdvertiser = advertiser => ({
    type: STATUS_ADVERTISER,
    payload: advertiser,
  })

  export const statusAdvertiserSuccess = advertiser => ({
    type: STATUS_ADVERTISER_SUCCESS,
    payload: advertiser,
  })

  export const archiveAdvertiser = advertiser => ({
    type: ARCHIVE_ADVERTISER,
    payload: advertiser,
  })

  export const archiveAdvertiserSuccess = advertiser => ({
    type: ARCHIVE_ADVERTISER_SUCCESS,
    payload: advertiser,
  })