import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_HEADERBIDS, ADD_HEADERBID, UPDATE_HEADERBID,UPDATE_ADUNITBID, READ_HEADERBID, STATUS_HEADERBID, ARCHIVE_HEADERBID } from "./actionTypes"

import {
  loadActivitiesHeaderBids,
  noDataHeaderBids,
  getHeaderBidsSuccess,
  getHeaderBidsFail,
  addHeaderBidSuccess,
  addHeaderBidFail,
  updateHeaderBidSuccess,
  updateHeaderBidFail,
  updateAdUnitBidSuccess,
  updateAdUnitBidFail,
  readHeaderBidSuccess,
  statusHeaderBidSuccess,
  archiveHeaderBidSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getHeaderBid,  createHeaderBid, updateHeaderBid,updateAdUnitBid, readHeaderBid, statusChangeHeaderBid, archiveHeaderBid } from "../../../helpers/Backend_helper"

function* fetchHeaderBids({ payload: adv }) {
  try {
    yield put(loadActivitiesHeaderBids(true))
    var getHeaderBiddingList
    if (adv) {
      getHeaderBiddingList = yield call(getHeaderBid, adv)
      if(adv.structure === 'old'){
        getHeaderBiddingList.response.map((item, index) => {
          item.id = index + 1
        })
        if (getHeaderBiddingList.response.length === 0) {
          yield put(noDataHeaderBids(true))
        }
        yield put(getHeaderBidsSuccess(getHeaderBiddingList))
      }else{
        getHeaderBiddingList.response.hbbuyers.map((item, index) => {
          item.id = index + 1
        })
        if (getHeaderBiddingList.response.hbbuyers.length === 0) {
          yield put(noDataHeaderBids(true))
        }
        yield put(getHeaderBidsSuccess(getHeaderBiddingList))
      }
  
    } else {
      getHeaderBiddingList = []
      yield put(getHeaderBidsSuccess(getHeaderBiddingList))
    }
  } catch (error) {
    console.log(JSON.stringify(error.message))

    yield put(getHeaderBidsFail(error))
  }
}

function* onReadHeaderBid({ payload: adv }) {
  try {
    const response = yield call(readHeaderBid, adv)
    yield put(readHeaderBidSuccess(response))
  } catch (error) {
    yield put(readHeaderBidSuccess(error))
  }
}

function* onAddHeaderBid({ payload: adv }) {
  try {
    const response = yield call(createHeaderBid, adv)
    if(adv.structure === 'old'){
      if (response.success) {
        yield put(addHeaderBidSuccess(response))
      } else {
        yield put(addHeaderBidFail(response))
      }
    }
    else{
      if (response.statusCode === 200 || response.status) {
        yield put(addHeaderBidSuccess(response))
      } else {
        yield put(addHeaderBidFail(response))
      }
    }

  } catch (error) {
    yield put(addHeaderBidFail(error))
  }
}

function* onUpdateHeaderBid({ payload: adv }) {
  try {
    const response = yield call(updateHeaderBid, adv)
    console.log(response,adv)
    if(adv.structure === 'old'){
      if (response.success) {
        yield put(updateHeaderBidSuccess(response))
      } else {
        yield put(updateHeaderBidFail(response))
      }
    }else{
      if (response.statusCode === 200 || response.status) {
        yield put(updateHeaderBidSuccess(response))
      } else {
        yield put(updateHeaderBidFail(response))
      }
    }

  } catch (error) {
    yield put(updateHeaderBidFail(error))
  }
}

function* onUpdateAdUnitBid({ payload: adv }) {
  try {
    const response = yield call(updateAdUnitBid, adv)
    if(adv.structure === 'old'){
      if (response.success) {
        yield put(updateAdUnitBidSuccess(response))
      } else {
        yield put(updateAdUnitBidFail(response))
      }
    }else{
      if (response.statusCode === 200 || response.status) {
        yield put(updateAdUnitBidSuccess(response))
      } else {
        yield put(updateAdUnitBidFail(response))
      }
    }

  } catch (error) {
    yield put(updateAdUnitBidFail(error))
  }
}

function* onStatusHeaderBid({ payload: adv }) {
  try {
    const response = yield call(statusChangeHeaderBid, adv)
    if(adv.structure === 'old'){
      if (response.success) {
        yield put(statusHeaderBidSuccess(response))
        }
    }else{
      if (response.statusCode === 200 || response.status) {
        yield put(statusHeaderBidSuccess(response))
        }
    }
  } catch (error) {
    yield put(statusHeaderBidSuccess(error))
  }
}

function* onArchiveHeaderBid({ payload: adv }) {
  try {
    const response = yield call(archiveHeaderBid, adv)
    if(adv.structure === 'old'){
      if (response.success) {
        yield put(archiveHeaderBidSuccess(response))
        }
    }else{
      if (response.statusCode === 200 || response.status) {
        yield put(archiveHeaderBidSuccess(response))
        }
    }
  } catch (error) {
    yield put(archiveHeaderBidSuccess(error))
  }
}

function* headerBidSaga() {
  yield takeEvery(GET_HEADERBIDS, fetchHeaderBids)
  yield takeEvery(ADD_HEADERBID, onAddHeaderBid)
  yield takeEvery(UPDATE_HEADERBID, onUpdateHeaderBid)
  yield takeEvery(UPDATE_ADUNITBID, onUpdateAdUnitBid)
  yield takeEvery(READ_HEADERBID, onReadHeaderBid)
  yield takeEvery(STATUS_HEADERBID, onStatusHeaderBid)
  yield takeEvery(ARCHIVE_HEADERBID, onArchiveHeaderBid)
}

export default headerBidSaga;
