import {
    LOAD_CONTEXTUAL_DATAS,
    NO_DATA,
    GET_CONTEXTUAL_DATAS_SUCCESS,
    GET_CONTEXTUAL_DATAS_FAIL,
    ADD_CONTEXTUAL_DATA_SUCCESS,
    ADD_CONTEXTUAL_DATA_ERROR,
    UPDATE_CONTEXTUAL_DATA_SUCCESS,
    UPDATE_CONTEXTUAL_DATA_ERROR,
    READ_CONTEXTUAL_DATA_SUCCESS,
    STATUS_CONTEXTUAL_DATA_SUCCESS,
    ARCHIVE_CONTEXTUAL_DATA_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
    blocks: [],
    error: {},
    loading:false,
    noData:false,
    successMsg: null,
    errorMsg: null,
    netBlocking: null,
    response:null,
    archiveMsg:null,
    apiCallMsg:null
}

const contextualData = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_CONTEXTUAL_DATAS:
            return {
                ...state,
                loading: true,
            }
        case NO_DATA:
                return {
                    ...state,
                    noData: true,
                }
        case GET_CONTEXTUAL_DATAS_SUCCESS:
            return {
                ...state,
                loading: false,
                blocks: action.payload,
            }

        case GET_CONTEXTUAL_DATAS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case ADD_CONTEXTUAL_DATA_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_CONTEXTUAL_DATA_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_CONTEXTUAL_DATAS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_CONTEXTUAL_DATA_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_CONTEXTUAL_DATAS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_CONTEXTUAL_DATA_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_CONTEXTUAL_DATAS_SUCCESS' ? null : action.payload,
            }
        case READ_CONTEXTUAL_DATA_SUCCESS:
            return {
                ...state,
                netBlocking: action.payload,
            }
        case STATUS_CONTEXTUAL_DATA_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_CONTEXTUAL_DATA_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default contextualData