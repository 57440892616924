/* NETWORK_SETTING */
export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES"
export const NO_DATA = "NO_DATA"
export const GET_NETWORK_SETTINGS = "GET_NETWORK_SETTINGS"
export const GET_NETWORK_SETTINGS_SUCCESS = "GET_NETWORK_SETTINGS_SUCCESS"
export const GET_NETWORK_SETTINGS_FAIL = "GET_NETWORK_SETTINGS_FAIL"

export const ADD_NETWORK_SETTING = "ADD_NETWORK_SETTING"
export const ADD_NETWORK_SETTING_SUCCESS = "ADD_NETWORK_SETTING_SUCCESS"
export const ADD_NETWORK_SETTING_ERROR = "ADD_NETWORK_SETTING_ERROR"

export const UPDATE_NETWORK_SETTING = "UPDATE_NETWORK_SETTING"
export const UPDATE_NETWORK_SETTING_SUCCESS = "UPDATE_NETWORK_SETTING_SUCCESS"
export const UPDATE_NETWORK_SETTING_ERROR = "UPDATE_NETWORK_SETTING_ERROR"

export const READ_NETWORK_SETTING = "READ_NETWORK_SETTING"
export const READ_NETWORK_SETTING_SUCCESS = "READ_NETWORK_SETTING_SUCCESS"

export const STATUS_NETWORK_SETTING = "STATUS_NETWORK_SETTING"
export const STATUS_NETWORK_SETTING_SUCCESS = "STATUS_NETWORK_SETTING_SUCCESS"

export const ARCHIVE_NETWORK_SETTING = "ARCHIVE_NETWORK_SETTING"
export const ARCHIVE_NETWORK_SETTING_SUCCESS = "ARCHIVE_NETWORK_SETTING_SUCCESS"