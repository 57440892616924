/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import {
  Row, Col, Input, Label, Form, FormFeedback,
  FormGroup,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Container, InputGroup
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import { Tooltip as ReactTooltip } from "react-tooltip";

const PlayerControls = (props) => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [controls, setControls] = useState({
    closeBtn_position: "outer-right",
    closeBtn_bottomMargin: 0,
    closeBtn_rightMargin: 0,
    closeBtn_topMargin: 0,
    closeBtn_leftMargin: 0,
    closeBtn_height: 12,
    closeBtn_width: 12,
    closeBtn_Text: '',
    closeBtn_InfoURL: 'https://bidsxchange.com/',
    showPlaylist: false,
    previousNextButton: true,
    playPauseButton: true,
    playOnViewPercentage: 50,
    fullscreenToggle: false,
    duration: true,
    soundButton: true,
    playbackRates: false,
    autoplay: false,
    autoContinue: true,
    loop: true,
    brandIdentity: false,
    playOnView: true,
    autoSkip: true,
    loadAdOnView: true,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    muted: true,
    isResponsive: true,
    playerSize: 'desktop',
    playerHeight: 360,
    playerWidth: 640,
    floatingCloseButton: true,
    floatOnStart: true,
    floatingOnlyOnAd: false,
    floatingMarginLeft: 5,
    floatingMarginRight: 5,
    floatingMarginTop: 5,
    floatingMarginBottom: 5,
    floatingPlacement: "bottom-right",
    floatingZindex: "99999",
    floatingSize: '',
    floatingSizeHeight: '120',
    floatingSizeWidth: '320',
    floatingSizeVH: '',
    floatingSizeVW: '',
    floatOnView: 50,
    playerState: 'inRead',
    stickyPosition: "bottom",
    stickyPlacement: 'right',
    contentIdentifier: 'tag',
    contentIdentifierName: '',
    customCss: '',
    backgroundColor: '',
    closeBtn: true,
    logo: true,
    logoPosition: 'top-right',
    logoHeight: 21,
    logoWidth: 21,
    logoURL: "https://admin.bidsxchange.com/images/shards-dashboard.comm",
    logoLpu: '',
    logoClickThroughURL: "https://streamlyn.com/products-seller",
    logoToolTip: '',
    logoTextFontSize: 14,
    logoText: "Ads by Bidsxchange",

    playerLoadOnPageView: true,
    checkTabInView: true,
    playAdInView: true,
    skipButton: false,
    skipTimer: 25,
    skipText: 'skip',
    timeToSkip: 25,
    // showAutoSkipButtons: '',
    autoSkipText: 'stay',
    dfp1x1: false,
    contentTagIdentifierNo: 1
  });

  useEffect( () => {
    console.log(props.selectedPlayer)
    if (props.selectedPlayer !== '') {
      setControls({ ...controls, ...props.selectedPlayer.playerSettingsAesthetics })
    }
  }, [])

  //old one
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setControls((controls) => ({ ...controls, [name]: value }));
  //   props.selectedItems({ ...controls, [name]: value })

  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValues = { ...controls, [name]: value };
    if (value === 'desktop') {
      updatedValues = { ...updatedValues, playerWidth: 640, playerHeight: 360 };
    } else if (value === 'mobile') {
      updatedValues = { ...updatedValues, playerWidth: 400, playerHeight: 225 };
    }
    setControls(updatedValues);
    props.selectedItems(updatedValues);
  };

  const handleChangeNumbers = (e) => {
    const { name, value } = e.target;
    let updatedValues = { ...controls, [name]: Number(value) };
    setControls(updatedValues);
    props.selectedItems(updatedValues);
  };


  //old one
  // const handleCheck = (event) => {
  //   console.log(event.target.name)
  //   setControls({
  //     ...controls,
  //     [event.target.name]: event.target.checked,
  //   });
  //   props.selectedItems({ ...controls, [event.target.name]: event.target.checked })
  // };

  const handleCheck = (event) => {
    const { name, checked } = event.target;

    const updatedControls = {
      ...controls,
      [name]: checked,
    };

    if (name === "autoplay" && checked) {
      updatedControls.playOnView = false;
    } else if (name === "playOnView" && checked) {
      updatedControls.autoplay = false;
    } else if (name === 'autoSkip' && checked) {
      updatedControls.skipButton = false;
    } else if (name === 'skipButton' && checked) {
      updatedControls.autoSkip = false;
    }

    setControls(updatedControls);
    props.selectedItems(updatedControls);
  };

  function allowOnlyNumbers(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code >= 48 && code < 58) && !(code >= 46 && code < 47)) { e.preventDefault(); }
  };


  return (
    <div>
      {/* <PlayerSettings /> */}
      <div className="player-content">
        <div className="container-fluid">
          <h5 className="">Player Interfaces</h5>

          <div className="row mb-4">
            <div className="col-md-5">
              <div className="form-group mt-3">
                <input type="checkbox" id="nextBtn" className="form-check-input mt-1 p-0 " checked={controls.showPlaylist} onChange={handleCheck} name="showPlaylist" />
                <label htmlFor="nextBtn" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >Show Playlist</label>
              </div>
              <div className="form-group mt-3">

                <input type="checkbox" id="prevBtn" className="form-check-input mt-1 p-0" checked={controls.previousNextButton} onChange={handleCheck} name="previousNextButton" />
                <label htmlFor="prevBtn" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >Previous/Next Button</label>
              </div>
              <div className="form-group mt-3">
                <input type="checkbox" id="playpauseBtn" className="form-check-input  mt-1 p-0" checked={controls.playPauseButton} onChange={handleCheck} name="playPauseButton" />
                <label htmlFor="playpauseBtn" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >Play/Pause Button</label>
              </div>

              <div className="form-group mt-3">
                <input type="checkbox" id="fullscreen" className="form-check-input  mt-1 p-0" checked={controls.fullscreenToggle} onChange={handleCheck} name="fullscreenToggle" />
                <label htmlFor="fullscreen" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >Fullscreen Button</label>
              </div>
              <div className="form-group mt-3">
                <input type="checkbox" id="duration" className="form-check-input  mt-1 p-0" checked={controls.duration} onChange={handleCheck} name="duration" />
                <label htmlFor="duration" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >Duration</label>
              </div>
              <div className="form-group mt-3">
                <input id="soundBtn" type="checkbox" className="form-check-input  mt-1 p-0" checked={controls.soundButton} onChange={handleCheck} name="soundButton" />
                <label htmlFor="soundBtn" className="col-9 mt-0 mb-0 m-3" style={{ cursor: "pointer" }} >Sound Button</label>
              </div>
              <div className="form-group mt-3">
                <input type="checkbox" id="playback" className='form-check-input mt-1 p-0' checked={controls.playbackRates} onChange={handleCheck} name="playbackRates" />
                <label htmlFor="playback" className='col-9 mt-0 mb-0 m-3' style={{ cursor: "pointer" }} >Playback Speed</label>
              </div>
              <div className="form-group mt-3">
                <input type="checkbox" id="playerLoadOnPageView" className="form-check-input mt-1 p-0" checked={controls.playerLoadOnPageView} onChange={handleCheck} name="playerLoadOnPageView" />
                <label className="col-9 mt-0 mb-0 m-3" htmlFor='playerLoadOnPageView' style={{ cursor: "pointer" }} >Player Load On Page Load</label>
              </div>



            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5 ">
              <div className="form-group mt-3">
                <input type="checkbox" id="checkTabInView" className="form-check-input mt-1 p-0" checked={controls.checkTabInView} onChange={handleCheck} name="checkTabInView" />
                <label className="col-9 mt-0 mb-0 m-3" htmlFor='checkTabInView' style={{ cursor: "pointer" }} >Check Tab In View </label>
                {/* <i className='bx bxs-info-circle font-size-12' id="checktabi" ></i> */}
                {/* <ReactTooltip
                  anchorId="checktabi"
                  place="top"
                  variant="info"

                  content="The player will automatically check the browser tab viewbility and will
                start ad only when it is in view"
                /> */}
              </div>
              <div className="form-group mt-3">
                <input id="autoplay" type="checkbox" className='form-check-input mt-1 p-0' checked={controls.autoplay} onChange={handleCheck} name="autoplay" />
                <label htmlFor="autoplay" className='col-9 mt-0 mb-0 m-3' style={{ cursor: "pointer" }} >Autoplay <i className='bx bxs-info-circle font-size-12' id="autoplayi" ></i></label>
                <ReactTooltip
                  anchorId="autoplayi"
                  place="top"
                  variant="info"

                  content="By enabling autoplay, paly on view will be disabled"
                />
              </div>
              <div className="form-group mt-3">
                <input id="autocontinuei" type="checkbox" className='form-check-input mt-1 p-0' checked={controls.autoContinue} onChange={handleCheck} name="autoContinue" />
                <label htmlFor="autocontinuei" className='col-9 mt-0 mb-0 m-3' style={{ cursor: "pointer" }} >Auto Continue</label>
              </div>
              <div className="form-group mt-3">
                <input id="loop" type="checkbox" className='form-check-input mt-1 p-0' checked={controls.loop} onChange={handleCheck} name="loop" />
                <label htmlFor="loop" className='col-9 mt-0 mb-0 m-3' style={{ cursor: "pointer" }} >Loop</label>
              </div>
              <div className="form-group mt-3">
                <input id="brandi" type="checkbox" className='form-check-input mt-1 p-0' checked={controls.brandIdentity} onChange={handleCheck} name="brandIdentity" />
                <label htmlFor="brandi" className='col-9 mt-0 mb-0 m-3' style={{ cursor: "pointer" }} >Brand Identity</label>
              </div>
              <div className="form-group mt-3">
                <input id="playonview" type="checkbox" className='form-check-input mt-1 p-0' checked={controls.playOnView} onChange={handleCheck} name="playOnView" />
                <label htmlFor="playonview" className='col-9 mt-0 mb-0 m-3' style={{ cursor: "pointer" }} >Play On View <i className='bx bxs-info-circle font-size-12' id="playonviewi" ></i></label>

                <ReactTooltip
                  anchorId="playonviewi"
                  place="top"
                  variant="info"

                  content="By enabling play on view, auto play will be disabled"
                />
              </div>
              <div className="form-group mt-3">
                <input id="loadadonview" type="checkbox" className='form-check-input mt-1 p-0' checked={controls.loadAdOnView} onChange={handleCheck} name="loadAdOnView" />
                <label htmlFor="loadadonview" className='col-9 mt-0 mb-0 m-3 ' style={{ cursor: "pointer" }} >Load Ad On View</label>
              </div>
              <div className="form-group mt-3">
                <input id="muted" type="checkbox" className='form-check-input mt-1 p-0' checked={controls.muted} onChange={handleCheck} name="muted" />
                <label htmlFor="muted" className='col-9 mt-0 mb-0 m-3' style={{ cursor: "pointer" }} >Muted</label>
              </div>

            </div>

          </div>

          <h5 className="mt-3 mb-3">Player Aesthetics</h5>
          <div className="">
            <div className="form-check form-switch form-check-success form-switch-md col-12 col-md-6" >
              <input
                type="checkbox"
                className="form-check-input"
                id="dfp1x1"
                checked={controls.dfp1x1} onChange={handleCheck} name="dfp1x1"
              />
              <label className="form-check-label " htmlFor="dfp1x1">
                <h5 className="heading5 mt-1">DFP 1x1</h5>
              </label>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-5">

              <div className="form-group mt-3">
                <Label className="form-label">Margin Left</Label>
                <Input
                  type="text"
                  onKeyPress={allowOnlyNumbers}
                  value={controls.marginLeft}
                  onChange={handleChangeNumbers}
                  name="marginLeft"
                />
              </div>
              <div className="form-group mt-3">
                <Label className="form-label">Margin Right</Label>
                <Input
                  type="text"
                  onKeyPress={allowOnlyNumbers}
                  value={controls.marginRight}
                  onChange={handleChangeNumbers}
                  name="marginRight"
                />
              </div>
              <div className="form-group mt-3">
                <Label className="form-label">Play OnView Percentage</Label>
                <Input
                  type="select"
                  value={controls.playOnViewPercentage}
                  onChange={handleChange}
                  name="playOnViewPercentage"
                  className="col-5"
                  disabled={!controls.playOnView}
                >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="75">75</option>
                  <option value="100">100</option>
                </Input>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="form-group mt-3">
                <Label className="form-label">Margin Top</Label>
                <Input
                  type="text"
                  onKeyPress={allowOnlyNumbers}
                  value={controls.marginTop}
                  onChange={handleChangeNumbers}
                  name="marginTop"
                />
              </div>
              <div className="form-group mt-3">
                <Label className="form-label">Margin Bottom</Label>
                <Input
                  type="text"
                  onKeyPress={allowOnlyNumbers}
                  value={controls.marginBottom}
                  onChange={handleChangeNumbers}
                  name="marginBottom"
                />
              </div>
            </div>
          </div>

          <div className="mb-3">
            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-12 col-md-6" >
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemdskip"
                checked={controls.skipButton} onChange={handleCheck} name="skipButton"
              />
              <label className="form-check-label " htmlFor="customSwitchsizemdskip">
                <h5 className="heading5 mt-1">Skip Button</h5>
              </label>
            </div>
          </div>
          <div className="form-group row mt-1  mb-3 ml-3">
            <div className="row mt-2 ml-4 mb-3">
              <div className="col-lg-5">
                <div className="form-group ">
                  <Label className="form-label">
                    Skip Timer
                  </Label>
                  <InputGroup>
                    <Input
                      type="text"
                      label="skipTimer"
                      value={controls.skipTimer}
                      onKeyPress={allowOnlyNumbers}
                      onChange={handleChangeNumbers}
                      name="skipTimer"
                      readOnly={!controls.skipButton}
                    />
                    <div className="input-group-text">Seconds</div>
                  </InputGroup>

                </div>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-5">
                <div className="form-group ">
                  <Label className="form-label">
                    Skip Text
                  </Label>
                  <Input
                    type="text"
                    label="skipText"
                    value={controls.skipText}
                    onChange={handleChange}
                    name="skipText"
                    readOnly={!controls.skipButton}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mb-3">
            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-12 col-md-6" >
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemdskips"
                checked={controls.autoSkip} onChange={handleCheck} name="autoSkip"
              />
              <label className="form-check-label " htmlFor="customSwitchsizemdskips">
                <h5 className="heading5 mt-1">Enable Auto Skip</h5>
              </label>
            </div>
          </div>
          <div className="form-group row mt-1  mb-3 ml-3">
            <div className="row mt-2 ml-4 mb-3">
              <div className="col-lg-5">
                <div className="form-group ">
                  <Label className="form-label">
                    Time To Skip
                  </Label>
                  <InputGroup>
                    <Input
                      type="text"
                      label="timeToSkip"
                      value={controls.timeToSkip}
                      onChange={handleChangeNumbers}
                      name="timeToSkip"
                      readOnly={!controls.autoSkip}
                      onKeyPress={allowOnlyNumbers}
                    />
                    <div className="input-group-text">Seconds</div>
                  </InputGroup>

                </div>
              </div>
              <div className="col-lg-1"></div>
              {/* <div className="col-lg-5">
                <div className="form-group ">
                  <Label className="form-label">
                    Show Auto Skip Buttons
                  </Label>
                  <InputGroup>
                    <Input
                      type="text"
                      label="showAutoSkipButtons"
                      value={controls.showAutoSkipButtons}
                      onChange={handleChange}
                      name="showAutoSkipButtons"
                      readOnly={!controls.autoSkip}
                    />
                    <div className="input-group-text">Seconds</div>
                  </InputGroup>
                </div>
              </div> */}

              {/* <div className="row mt-2 ml-4 mb-3"> */}
              <div className="col-lg-5">
                <div className="form-group ">
                  <Label className="form-label">
                    Skip Text
                  </Label>
                  <Input
                    type="text"
                    label="autoSkipText"
                    value={controls.autoSkipText}
                    onChange={handleChange}
                    name="autoSkipText"
                    readOnly={!controls.autoSkip}
                  />
                </div>
              </div>
              {/* </div> */}
            </div>

          </div>


          <h5 className="mt-3">Player Scale</h5>
          <div className="form-group row  mt-3 ml-3">
            <div className="mb-1">
              <div className="form-check form-switch form-check-success form-switch-md mt-2  col-12 col-md-6" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="inResposiveBtn"
                  checked={controls.isResponsive} onChange={handleCheck} name="isResponsive"
                />
                <label className="form-check-label " htmlFor="inResposiveBtn">
                  <h5 className="heading5 mt-1">Responsive</h5>
                </label>
              </div>
            </div>

            <h5 className="mt-3">Player Size</h5>
            <div className='col-lg-5 mb-3'>
              <Label className="form-label">
                Select Player Size
              </Label>
              <Input
                type="select"
                className="form-select"
                value={controls.playerSize}
                onChange={handleChange}
                name="playerSize"
              >
                <option value="desktop">Desktop-Default (640x360)</option>
                <option value="mobile">Mobile-Default (400x225)</option>
                <option value="custom">Custom</option>
              </Input>
            </div>
            {controls.playerSize === 'custom' &&
              <div className="row mt-2 ml-4">
                <div className="col-lg-5">
                  <div className="form-group ">
                    <Label className="form-label">
                      Height
                    </Label>
                    <InputGroup>
                      <Input
                        type="text"
                        label="Height"
                        value={controls.playerHeight}
                        onChange={handleChangeNumbers}
                        name="playerHeight"
                        onKeyPress={allowOnlyNumbers}
                      />
                      <div className="input-group-text">px</div>

                    </InputGroup>
                  </div>
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-5">
                  <div className="form-group ">
                    <Label className="form-label">
                      Width
                    </Label>
                    <InputGroup>
                      <Input
                        type="text"
                        label="Width"
                        value={controls.playerWidth}
                        onChange={handleChangeNumbers}
                        name="playerWidth"
                        onKeyPress={allowOnlyNumbers}
                      />
                      <div className="input-group-text">px</div>

                    </InputGroup>
                  </div>
                </div>
              </div>
            }
          </div>



          <h5 className="mt-3">Player State</h5>
          <div className="row mb-3">
            <div className='col-lg-5 mb-3'>
              <div className="form-group mt-2">
                <Label className="form-label">
                  Player State
                </Label>
                <Input
                  type="select"
                  className="form-select"
                  value={controls.playerState}
                  onChange={handleChange}
                  name="playerState"
                >
                  <option value="inRead"  >
                    In Read
                  </option>
                  <option value="floating"  >
                    Floating
                  </option>
                  {/* <option value="sticky"  >
                    Sticky
                  </option> */}
                </Input>
              </div>
              {/* <div className="form-group mt-3">
                <Label className="form-label">
                  Background Color
                </Label>
                <Input
                  type="text"
                  value={controls.backgroundColor}
                  onChange={handleChange}
                  name="backgroundColor"
                />
              </div> */}

            </div>
          </div>
          {
            controls.playerState === "floating" &&
            <>
              <div className="mb-3 mt-3 ">
                <h4>Floating</h4>

                {/* <div className="form-group mt-3">
                  <input type="checkbox" id="floatingOnBtn" className="form-check-input mt-1 p-0" checked={controls.floatOnStart} onChange={handleCheck} name="floatOnStart" />
                  <label className=" mt-0 mb-0 m-3" style={{ cursor: "pointer" }} htmlFor="floatingOnBtn">Float On Start</label>
                </div>  */}
                <div className="form-group mt-3">
                  <input type="checkbox" id="floatingCloseBtn" className="form-check-input mt-1 p-0" checked={controls.floatingCloseButton} onChange={handleCheck} name="floatingCloseButton" />
                  <label className=" mt-0 mb-0 m-3" style={{ cursor: "pointer" }} htmlFor="floatingCloseBtn">Floating Close Button</label>
                </div>
                <div className="form-group mt-3">
                  <input type="checkbox" id="floatingOnlyOnAd" className="form-check-input mt-1 p-0" checked={controls.floatingOnlyOnAd} onChange={handleCheck} name="floatingOnlyOnAd" />
                  <label className=" mt-0 mb-0 m-3" style={{ cursor: "pointer" }} htmlFor="floatingOnlyOnAd">Float Only On Ad</label>
                </div>
              </div>

              <h5>Floating Position</h5>
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group mt-3">
                    <Label className="form-label">Margin Left</Label>
                    <InputGroup>
                      <Input
                        type="text"
                        value={controls.floatingMarginLeft}
                        onChange={handleChangeNumbers}
                        name="floatingMarginLeft"
                        onKeyPress={allowOnlyNumbers}
                      />
                      <div className="input-group-text">px</div>

                    </InputGroup>
                  </div>
                  <div className="form-group mt-3">
                    <Label className="form-label">Margin Right</Label>
                    <InputGroup>
                      <Input
                        type="text"
                        value={controls.floatingMarginRight}
                        onChange={handleChangeNumbers}
                        name="floatingMarginRight"
                        onKeyPress={allowOnlyNumbers}
                      />
                      <div className="input-group-text">px</div>

                    </InputGroup>
                  </div>
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-5">
                  <div className="form-group mt-3">
                    <Label className="form-label">Margin Top</Label>
                    <InputGroup>
                      <Input
                        type="text"
                        value={controls.floatingMarginTop}
                        onChange={handleChangeNumbers}
                        name="floatingMarginTop"
                        onKeyPress={allowOnlyNumbers}
                      />
                      <div className="input-group-text">px</div>

                    </InputGroup>
                  </div>
                  <div className="form-group mt-3">
                    <Label className="form-label">Margin Bottom</Label>
                    <InputGroup>
                      <Input
                        type="text"
                        value={controls.floatingMarginBottom}
                        onChange={handleChangeNumbers}
                        name="floatingMarginBottom"
                        onKeyPress={allowOnlyNumbers}
                      />
                      <div className="input-group-text">px</div>

                    </InputGroup>
                  </div>
                </div>
              </div>

              <div className="row mt-3 ml-4 mb-3 ">
                <div className="col-md-5">
                  <div className="form-group">
                    <Label className="form-label">Floating Placement</Label>
                    <Input
                      type="select"
                      className="form-select"
                      value={controls.floatingPlacement}
                      onChange={handleChange}
                      name="floatingPlacement"
                    >
                      <option value="top-left">top-left</option>
                      <option value="top-right">top-right</option>
                      <option value="bottom-left">bottom-left</option>
                      <option value="bottom-right">bottom-right</option>
                    </Input>
                  </div>
                  <div className="form-group mt-3">
                    <Label className="form-label">Floating Size</Label>
                    <Input
                      type="select"
                      className="form-select"
                      value={controls.floatingSize}
                      onChange={handleChange}
                      name="floatingSize"
                    >
                      <option value="" disabled >
                        Select Floating Size Type
                      </option>
                      <option value="Pixels">Height And Width</option>
                      <option value="vhandvw">Viewport Percentage</option>
                    </Input>
                  </div>

                </div>
                <div className="col-md-1"></div>
                <div className="col-md-5">
                  <div className="form-group">
                    <Label className="form-label">Z-index</Label>
                    <Input
                      type="text"
                      value={controls.floatingZindex}
                      onChange={handleChangeNumbers}
                      name="floatingZindex"
                      onKeyPress={allowOnlyNumbers}
                    />
                  </div>
                  {
                    controls.floatingSize === 'Pixels' &&
                    <div>
                      <div className="form-group mt-3">
                        <Label className="form-label">Height</Label>
                        <InputGroup>
                          <Input
                            type="text"
                            value={controls.floatingSizeHeight}
                            onChange={handleChangeNumbers}
                            name="floatingSizeHeight"
                            onKeyPress={allowOnlyNumbers}
                          />
                          <div className="input-group-text">px</div>

                        </InputGroup>

                      </div>
                      <div className="form-group mt-3">
                        <Label className="form-label">Width</Label>
                        <InputGroup>
                          <Input
                            type="text"
                            value={controls.floatingSizeWidth}
                            onChange={handleChangeNumbers}
                            name="floatingSizeWidth"
                            onKeyPress={allowOnlyNumbers}
                          />
                          <div className="input-group-text">px</div>
                        </InputGroup>
                      </div>
                    </div>
                  }
                  {
                    controls.floatingSize === 'vhandvw' &&
                    <div>
                      <div className="form-group mt-3">
                        <Label className="form-label">Height %</Label>
                        <Input
                          type="text"
                          value={controls.floatingSizeVH}
                          onChange={handleChangeNumbers}
                          name="floatingSizeVH"
                          onKeyPress={allowOnlyNumbers}
                        />
                      </div>
                      <div className="form-group mt-3">
                        <Label className="form-label">Width %</Label>
                        <Input
                          type="text"
                          value={controls.floatingSizeVW}
                          onChange={handleChangeNumbers}
                          name="floatingSizeVW"
                          onKeyPress={allowOnlyNumbers}
                        />
                      </div>
                    </div>
                  }
                </div>
              </div>
            </>
          }
          {
            controls.playerState === 'sticky' &&
            <>
              <div className="row ml-3 mt-3">
                <div className='col-lg-5'>
                  <Label className="form-label">
                    Sticky Position
                  </Label>
                  <Input
                    type="select"
                    className="form-select"
                    value={controls.stickyPosition}
                    onChange={handleChange}
                    name="stickyPosition"
                  >
                    <option value="bottom">Bottom</option>
                    <option value="top">Top</option>
                    <option value="bottom-right">Bottom Right</option>
                    <option value="bottom-left">Bottom Left</option>
                    <option value="top-right">Top Right</option>
                    <option value="top-left">Top Left</option>
                  </Input>
                </div>
                <div className='col-lg-1'></div>
                <div className='col-lg-5'>
                  <Label className="form-label">
                    Sticky Placement
                  </Label>
                  <Input
                    type="select"
                    className="form-select"
                    value={controls.stickyPlacement}
                    onChange={handleChange}
                    name="stickyPlacement"
                  >
                    <option value="right">Right</option>
                    <option value="left">Left</option>
                  </Input>
                </div>
              </div>
            </>
          }
          <h5 className="mt-3">Content Identifier</h5>
          <div className="row mt-3 mb-3">
            <div className='col-lg-5 mb-3'>
              <Label className="form-label">
                Selector
              </Label>
              <Input
                type="select"
                className="form-select"
                value={controls.contentIdentifier}
                onChange={handleChange}
                name="contentIdentifier"
              >
                <option value="tag">Tag</option>
                <option value="class">class</option>
                <option value="id">id</option>
              </Input>
            </div>
            <div className='col-lg-6'></div>

            <div className='col-lg-5 mb-3'>
              <Label className="form-label">
                {controls.contentIdentifier === 'tag' ? 'Tag Name' : controls.contentIdentifier === 'class' ? 'Class Name' : 'ID Name'}
              </Label>
              <Input
                type="text"
                value={controls.contentIdentifierName}
                onChange={handleChange}
                name="contentIdentifierName"
              />
            </div>
            <div className='col-lg-1'></div>
            <div className='col-lg-5'>
              {controls.contentIdentifier === 'tag' &&
                <div>
                  <Label className="form-label">
                    Tag Identifier No
                  </Label>
                  <Input
                    type="text"
                    value={controls.contentTagIdentifierNo}
                    onChange={handleChange}
                    name="contentTagIdentifierNo"
                  />
                </div>
              }
            </div>
            <div className='col-lg-5 mb-3'>
              <Label className="form-label">
                Custom CSS
              </Label>
              <Input
                type="textarea"
                rows="5"
                value={controls.customCss}
                onChange={handleChange}
                name="customCss"
              />
            </div>
            {/* <div className='col-lg-5 mb-3'>
              <Label className="form-label">
                Insert
              </Label>
              <Input
                type="select"
                className="form-select"
                value={controls.contentIdentifier}
                onChange={handleChange}
                name="contentIdentifier"
              >
                <option value="tag">Paragraph</option>
                <option value="class">class</option>
                <option value="id">id</option>
                <option value="Before_Post">Before Post </option>
                <option value="Before_content">Before content</option>
                <option value="Before_paragraph">Before paragraph</option>
                <option value="After paragraph">After paragraph </option>
                <option value="Before image">Before image</option>
                <option value="After image">After image	</option>
                <option value="After content">After content	</option>
                <option value="After post">After post </option>
                <option value="Before excerpt">Before excerpt</option>
                <option value="After excerpt">After excerpt</option>
                <option value="Between posts">Between posts</option>
                <option value="Before comments">Before comments</option>
                <option value="Between comments">Between comments</option>
                <option value="After comments">After comments</option>
                <option value="Footer">Footer</option>
                <option value="Before HTML element">Before HTML element</option>
                <option value="Inside HTML element">Inside HTML element</option>
                <option value="After HTML element">After HTML element</option>
              </Input>
            </div>
            <div className='col-lg-4'></div>
            <div className='col-lg-5 mb-3'>
              <Label className="form-label">
                Selector
              </Label>
              <Input
                type="textarea"
                rows="5"
                value={controls.customCss}
                onChange={handleChange}
                name="customCss"
              />
            </div> */}
          </div>

          <div className="mb-3">
            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-12 col-md-6" >
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemdclose"
                checked={controls.closeBtn} onChange={handleCheck} name="closeBtn"
              />
              <label className="form-check-label " htmlFor="customSwitchsizemdclose">
                <h5 className="heading5 mt-1">Close Button</h5>
              </label>
            </div>
          </div>
          <div className="row mt-2 ml-4">
            <div className="col-md-5">
              <div className="form-group">
                <Label className="form-label">Position</Label>
                <Input
                  type="select"
                  className="form-select"
                  value={controls.closeBtn_position}
                  onChange={handleChange}
                  name="closeBtn_position"
                  disabled={!controls.closeBtn}
                >
                  <option value="inner-left">Inner-left</option>
                  <option value="inner-right">Inner-right</option>
                  <option value="outer-left">Outer-left</option>
                  <option value="outer-right">Outer-right</option>
                </Input>
              </div>
            </div>
          </div>
          <div className="row mt-2 ml-4">
            <div className="col-md-5">
              <div className="form-group">
                <Label className="form-label">Height</Label>
                <InputGroup>
                  <Input
                    type="text"
                    value={controls.closeBtn_height}
                    onChange={handleChangeNumbers}
                    name="closeBtn_height"
                    readOnly={!controls.closeBtn}
                    onKeyPress={allowOnlyNumbers}
                  />
                  <div className="input-group-text">px</div>

                </InputGroup>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="form-group">
                <Label className="form-label">Width</Label>
                <InputGroup>
                  <Input
                    type="text"
                    value={controls.closeBtn_width}
                    onChange={handleChangeNumbers}
                    name="closeBtn_width"
                    readOnly={!controls.closeBtn}
                    onKeyPress={allowOnlyNumbers}
                  />
                  <div className="input-group-text">px</div>

                </InputGroup>
              </div>
            </div>
          </div>
          <div className="row mt-2 ml-4">
            <div className="col-md-5">
              <div className="form-group">
                <Label className="form-label">Left Margin</Label>
                <InputGroup>
                  <Input
                    type="text"
                    value={controls.closeBtn_leftMargin}
                    onChange={handleChangeNumbers}
                    name="closeBtn_leftMargin"
                    readOnly={!controls.closeBtn}
                    onKeyPress={allowOnlyNumbers}
                  />
                  <div className="input-group-text">px</div>
                </InputGroup>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="form-group">
                <Label className="form-label">Right Margin</Label>
                <InputGroup>
                  <Input
                    type="text"
                    value={controls.closeBtn_rightMargin}
                    onChange={handleChangeNumbers}
                    name="closeBtn_rightMargin"
                    readOnly={!controls.closeBtn}
                    onKeyPress={allowOnlyNumbers}
                  />
                  <div className="input-group-text">px</div>

                </InputGroup>
              </div>
            </div>
          </div>
          <div className="row mt-2 ml-4 mb-4">
            <div className="col-md-5">
              <div className="form-group">
                <Label className="form-label">Top Margin</Label>
                <InputGroup>
                  <Input
                    type="text"
                    value={controls.closeBtn_topMargin}
                    onChange={handleChangeNumbers}
                    name="closeBtn_topMargin"
                    readOnly={!controls.closeBtn}
                    onKeyPress={allowOnlyNumbers}
                  />
                  <div className="input-group-text">px</div>
                </InputGroup>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="form-group">
                <Label className="form-label">Bottom Margin</Label>
                <InputGroup>
                  <Input
                    type="text"
                    value={controls.closeBtn_bottomMargin}
                    onChange={handleChangeNumbers}
                    name="closeBtn_bottomMargin"
                    readOnly={!controls.closeBtn}
                    onKeyPress={allowOnlyNumbers}
                  />
                  <div className="input-group-text">px</div>
                </InputGroup>
              </div>
            </div>
          </div>
          <div className="row mt-2 ml-4 mb-4">
            {/* <div className="col-md-5">
              <div className="form-group">
                <Label className="form-label">Close Button Text</Label>
                <Input
                  type="text"
                  value={controls.closeBtn_Text}
                  onChange={handleChange}
                  name="closeBtn_Text"
                  readOnly={!controls.closeBtn}
                />
              </div>
            </div>
            <div className="col-md-1"></div> */}
            <div className="col-md-5">
              <div className="form-group">
                <Label className="form-label">Close Button Info URL</Label>
                <Input
                  type="text"
                  value={controls.closeBtn_InfoURL}
                  onChange={handleChange}
                  name="closeBtn_InfoURL"
                  readOnly={!controls.closeBtn}
                />
              </div>
            </div>
          </div>


          <div className="mb-3">
            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-12 col-md-6" >
              <input
                type="checkbox"
                className="form-check-input"
                id="logo"
                checked={controls.logo} onChange={handleCheck} name="logo"
              />
              <label className="form-check-label " htmlFor="logo">
                <h5 className="heading5 mt-1">Logo</h5>
              </label>
            </div>
          </div>
          <div className="row mt-2 ml-4">
            <div className="col-md-5">
              <div className="form-group">
                <Label className="form-label">Position</Label>
                <Input
                  type="select"
                  className="form-select"
                  value={controls.logoPosition}
                  onChange={handleChange}
                  name="logoPosition"
                  disabled={!controls.logo}
                >
                  <option value="top-left">Top Left</option>
                  <option value="top-right">Top Right</option>
                </Input>
              </div>
            </div>
          </div>
          <div className="row mt-2 ml-4">
            <div className="col-md-5">
              <div className="form-group">
                <Label className="form-label">Height</Label>
                <InputGroup>
                  <Input
                    type="text"
                    value={controls.logoHeight}
                    onChange={handleChangeNumbers}
                    name="logoHeight"
                    readOnly={!controls.logo}
                    onKeyPress={allowOnlyNumbers}
                  />
                  <div className="input-group-text">px</div>

                </InputGroup>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="form-group">
                <Label className="form-label">Width</Label>
                <InputGroup>
                  <Input
                    type="text"
                    value={controls.logoWidth}
                    onChange={handleChangeNumbers}
                    name="logoWidth"
                    readOnly={!controls.logo}
                    onKeyPress={allowOnlyNumbers}
                  />
                  <div className="input-group-text">px</div>
                </InputGroup>
              </div>
            </div>
          </div>
          <div className="row mt-2 ml-4">
            <div className="col-md-5">
              <div className="form-group">
                <Label className="form-label">Link</Label>
                <Input
                  type="text"
                  value={controls.logoURL}
                  onChange={handleChange}
                  name="logoURL"
                  readOnly={!controls.logo}
                />
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="form-group">
                <Label className="form-label">Click through URL</Label>
                <Input
                  type="text"
                  name="logoClickThroughURL"
                  value={controls.logoClickThroughURL}
                  onChange={handleChange}
                  readOnly={!controls.logo}
                />
              </div>
            </div>
          </div>
          <div className="row mt-2 ml-4">
            <div className="col-md-5">
              <div className="form-group">
                <Label className="form-label">Text</Label>
                <Input
                  type="text"
                  name="logoText"
                  value={controls.logoText}
                  onChange={handleChange}
                  readOnly={!controls.logo}
                />
              </div>
            </div>
            <div className="col-md-1"></div>
            {/* <div className="col-md-5">
              <div className="form-group">
                <Label className="form-label">Logo Lpu</Label>
                <Input
                  type="text"
                  name="logoLpu"
                  value={controls.logoLpu}
                  onChange={handleChange}
                  readOnly={!controls.logo}
                />
              </div>
            </div> */}
            <div className="col-md-5">
              <div className="form-group">
                <Label className="form-label">Logo Text FontSize</Label>
                <Input
                  type="text"
                  name="logoTextFontSize"
                  value={controls.logoTextFontSize}
                  onChange={handleChange}
                  readOnly={!controls.logo}
                />
              </div>
            </div>

          </div>
          <div className="row mt-2 ml-4">
            <div className="col-md-5">
              <div className="form-group">
                <Label className="form-label">Logo Tool Tip</Label>
                <Input
                  type="text"
                  name="logoToolTip"
                  value={controls.logoToolTip}
                  onChange={handleChange}
                  readOnly={!controls.logo}
                />
              </div>
            </div>
            {/* <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="form-group">
                <Label className="form-label">Logo Text FontSize</Label>
                <Input
                  type="text"
                  name="logoTextFontSize"
                  value={controls.logoTextFontSize}
                  onChange={handleChange}
                  readOnly={!controls.logo}
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div >
  )
}

export default PlayerControls