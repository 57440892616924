import {
  LOAD_ACTIVITIES,
  NO_DATA,
    GET_PUBLISHERS,
    GET_PUBLISHERS_FAIL,
    GET_PUBLISHERS_SUCCESS,
    ADD_PUBLISHER,
    ADD_PUBLISHER_SUCCESS,
    ADD_PUBLISHER_ERROR,
    UPDATE_PUBLISHER,
    UPDATE_PUBLISHER_SUCCESS,
    UPDATE_PUBLISHER_ERROR,
    READ_PUBLISHER,
    READ_PUBLISHER_SUCCESS,
    STATUS_PUBLISHER,
    STATUS_PUBLISHER_SUCCESS,
    ARCHIVE_PUBLISHER ,
    ARCHIVE_PUBLISHER_SUCCESS

  } from "./actionTypes"

  export const loadActivitiesPublisher = Params => ({
    type: LOAD_ACTIVITIES,
    payload:Params
  })

  export const noDataPublisher = Params => ({
    type: NO_DATA,
    payload:Params
  })

  export const getPublisher = Params => ({
    type: GET_PUBLISHERS,
    payload:Params
  })
  
  export const getPublisherSuccess = Publisher => ({
    type: GET_PUBLISHERS_SUCCESS,
    payload: Publisher,
  })

  export const getPublisherFail = error => ({
    type: GET_PUBLISHERS_FAIL,
    payload: error,
  })

  export const addNewPublisher = Publisher => ({
    type: ADD_PUBLISHER,
    payload: Publisher,
  })
  
  export const addPublisherSuccess = publisher => ({
    type: ADD_PUBLISHER_SUCCESS,
    payload: publisher,
  })
  
  export const addPublisherFail = publisher => ({
    type: ADD_PUBLISHER_ERROR,
    payload: publisher,
  })

  export const updatePublisher = publisher => ({
    type: UPDATE_PUBLISHER,
    payload: publisher,
  })
  
  export const updatePublisherSuccess = publisher => ({
    type: UPDATE_PUBLISHER_SUCCESS,
    payload: publisher,
  })
  
  export const updatePublisherFail = publisher => ({
    type: UPDATE_PUBLISHER_ERROR,
    payload: publisher,
  })

  export const readPublisher = publisher => ({
    type: READ_PUBLISHER,
    payload: publisher,
  })

  export const readPublisherSuccess = publisher => ({
    type: READ_PUBLISHER_SUCCESS,
    payload: publisher,
  })

  export const statusPublisher = publisher => ({
    type: STATUS_PUBLISHER,
    payload: publisher,
  })

  export const statusPublisherSuccess = publisher => ({
    type: STATUS_PUBLISHER_SUCCESS,
    payload: publisher,
  })

  export const archivePublisher = publisher => ({
    type: ARCHIVE_PUBLISHER,
    payload: publisher,
  })

  export const archivePublisherSuccess = publisher => ({
    type: ARCHIVE_PUBLISHER_SUCCESS,
    payload: publisher,
  })