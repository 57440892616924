/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback, Spinner, Collapse, Button } from "reactstrap";
import CreatableSelect from 'react-select/creatable';
import Select from "react-select";
import axios from "axios";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

import { MultiSelect } from "react-multi-select-component";

import {
  getPartners as onGetPartners,
} from "store/PartnerManagement/actions";

import {
  getAdPublisher as onGetPublishers,
} from "store/customTracking/HouseAdPub/actions";

// actions
import {
  getCategory as onGetCategories,
} from "store/customTracking/Categories/actions";

import {
  getHouseAdCreatives as onGetCreative,
} from "store/customTracking/Creatives/actions";

import {
  addNewHouseAdUnit as onADDAdUnit,
  updateHouseAdUnit as onUpdateAdUnit,
  readHouseAdUnit as onReadAdUnit,
  addHouseAdUnitSuccess,
  addHouseAdUnitFail,
  updateHouseAdUnitSuccess,
  updateHouseAdUnitFail,
} from "store/customTracking/AdUnits/actions";


import Toaster from "pages/Toaster/Toaster";
import classnames from "classnames";

var total = 0
var totalCreative = 0
var totalPercentage = 0
var totalPercentageCreative = 0

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ToasterWarning from "pages/Toaster/ToasterWarning";


const New_Supply_Tag = (props) => {

  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [isEdit, setIsEdit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [categoryGroup, setCategoryGroup] = useState([
    {
      categoryID: '',
      categoryName: '',
      categoryWeightage: 1,
      categoryPercentage: '',
      categoryLists: [],
      mainCreativeLists: [],
      creativePresent: false,
      creativeData: [{
        creativeID: '',
        creativeName: '',
        creativeWeightage: 1,
        creativePercentage: 100,
        creativeLists: []
      }]
    }])

  const [adUnit, setAdUnit] = useState({
    houseAdsAdUnitName: "",
    houseAdsAdUnitPublisher: "",
    houseAdsAdUnitSize: "",
    data: { list: categoryGroup }
  });

  const [selectedSize, setSelectedSize] = useState(null);
  const [sizeData, setSizeData] = useState([
    { value: "0x0", label: "0x0" },
    { value: "1x1", label: "1x1" },
    { value: "300x250", label: "300x250" },
    { value: "300x600", label: "300x600" },
    { value: "320x442", label: "320x442" },
    { value: '360x480', label: '360x480'},
    { value: "468x100", label: "468x100" },
    {value:'640x480',label:'640x480'},
    { value: "728x90", label: "728x90" },
    { value: "800x600", label: "800x600" },
    { value: "120x600", label: "120x600" },
    { value: "160x600", label: "160x600" },
    { value: "970x250", label: "970x250" },
    {value:'1280x250',label:'1280x250'},
    { value: "970x90", label: "970x90" },
    { value: "320x50", label: "320x50" },
    { value: "320x100", label: "320x100" },
    { value: "320x480", label: "320x480" },
    { value: "300x50", label: "300x50" },
    { value: "300x100", label: "300x100" },
    { value: "468x60", label: "468x60" },
    { value: "468x90", label: "468x90" },
    { value: "336x280", label: "336x280" },
    { value: "800x485", label: "800x485" },])

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 1000,
    extendedTimeOut: 1000
  };

  const [publisherData, setPublisherData] = useState([])

  const [selectedPublisher, setSelectedPublisher] = useState(null);

  const [categorySelectedlist, setCategorySelectedlist] = useState([])
  const [categorySelection, setCategorySelection] = useState(true);

  const [loader, setLoader] = useState(false);
  const [itemOpen, setItemOpen] = useState('')

  console.log(props)

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const { creatives } = useSelector(state => ({
    creatives: state.creative.crtvs,
  }));

  const { categories } = useSelector(state => ({
    categories: state.categories.ctgs,
  }));

  console.log(categories, creatives)

  const { publishers } = useSelector(state => ({
    publishers: state.adPublishers.adPubs,
  }));

  useEffect(() => {
    dispatch(onGetPublishers(null));
  }, [])

  useEffect(() => {
    if (props.selectedAdUnit !== '') {
      setIsEdit(true)
      setAdUnit(props.selectedAdUnit)

      console.log(props.selectedAdUnit.data.list)
      setSelectedSize({ value: props.selectedAdUnit.houseAdsAdUnitSize, label: props.selectedAdUnit.houseAdsAdUnitSize });

      setSelectedPublisher({ value: props.selectedAdUnit.houseAdsAdUnitPublisher, label: props.selectedAdUnit.houseAdsAdUnitPublisher });
      // setCategoryGroup([...props.selectedAdUnit.data.list]);
      changePercentage(props.selectedAdUnit.data.list)
      console.log(props.selectedAdUnit)
      console.log(categoryGroup)
    } else {
      if (categoryGroup.length > 0) {
        changePercentage(categoryGroup)
      }
    }
    if (props.selectedPublisher && props.selectedPublisher !== '') {
      setAdUnit(adUnit => ({ ...adUnit, houseAdsAdUnitPublisher: props.selectedPublisher.publisherName }));
      setSelectedPublisher({ value: props.selectedPublisher.publisherName, label: props.selectedPublisher.publisherName });
    }

  }, []);

  const getCreativesList = (categoryID, index) => {
    var filterBody = {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,
    }
    axios.post("/v2.0/BxApps/HouseAdsCreatives/readOnCategory/"+ categoryID, filterBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("cdsresult is ==>", res)
        if (res.data) {
          res.data.response.map((item, index) => {
            item.id = index + 1
          })
          props.selectedAdUnit.data.list[index].mainCreativeLists = res.data.response
          if (categoryID === 328) {
            res.data.response.unshift({
              houseAdsCreativeID: 10001,
              houseAdsCreativeName: 'All'
            })
          }
          if (props.selectedAdUnit.data.list[index].creativeData.includes('ALL')) {
            props.selectedAdUnit.data.list[index].creativePresent = false
            props.selectedAdUnit.data.list[index].creativeData = [{
              creativeID: 10001,
              creativeName: 'All',
              creativeWeightage: 1,
              creativePercentage: 100,
              creativeLists: objectCreativesModal(res.data.response)
            }]
            setCategoryGroup(props.selectedAdUnit.data.list)
          } else {
            console.log(props.selectedAdUnit.data.list[index].creativeData)
            props.selectedAdUnit.data.list[index].creativePresent = true
            props.selectedAdUnit.data.list[index].creativeData.map((ele, index) => {
              ele.creativeLists = objectCreativesModal(res.data.response)
              ele.creativePercentage = ''
            })
            setCategoryGroup(props.selectedAdUnit.data.list)
            changePercentageCreative(props.selectedAdUnit.data.list, index)
          }
          console.log(props.selectedAdUnit.data.list)
          if (props.selectedAdUnit.data.list.length !== categories.length) {
            setCategorySelection(false)
          } else {
            setCategorySelection(true)
          }
          return res.data.response
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }



  useEffect(() => {
    if (categories !== null) {
      dispatch(onGetCategories({ filter: 'Active' }));
    }
  }, []);

  // creativeLists

  useEffect(() => {
    if (categories !== null) {
      console.log(categories)
      setCategorySelectedlist(categories)
      let newArr = [...categoryGroup];
      newArr[0].categoryLists = objectCategoriesModal(categories);
      if (props.selectedAdUnit === '') {
        setCategoryGroup(newArr);
      }
      if (props.selectedAdUnit !== '') {
        if (props.selectedAdUnit.data.list.length > 0) {
          props.selectedAdUnit.data.list.map(async (item, index) => {

            item.categoryLists = objectCategoriesModal(categories)
            item.mainCreativeLists = []
            getCreativesList(item.categoryID, index)
            console.log(categories)
          })
        }
        setCategoryGroup(props.selectedAdUnit.data.list)
      }
    }
  }, [categories]);

  useEffect(() => {
    if (creatives !== null) {
      dispatch(onGetCreative({ filter: '', adunit: "" }));
    }
  }, []);


  useEffect(() => {
    if (publishers !== null) {
      dispatch(onGetPublishers({ filter: '' }));
    }
  }, []);

  useEffect(() => {
    if (publishers.length > 0) {
      setPublisherData(objectPublisherModal(publishers))
      console.log(publishers)
    }
  }, [publishers]);

  const objectPublisherModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.houseAdsPublisherName
      obj.label = item.houseAdsPublisherName
      dataList.push(obj)
    })
    return dataList
  }

  const objectCreativesModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.houseAdsCreativeID
      obj.label = item.houseAdsCreativeName
      dataList.push(obj)
    })
    return dataList
  }

  const objectCategoriesModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.houseAdsCategoryID
      obj.label = item.houseAdsCategoryName
      dataList.push(obj)
    })
    return dataList
  }

  function handleSelectPublisher(selectPublisher) {
    setAdUnit(adUnit => ({ ...adUnit, houseAdsAdUnitPublisher: selectPublisher.label }));
    setSelectedPublisher(selectPublisher);
  }

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdUnit(adUnit => ({ ...adUnit, [name]: value }));
  }

  const addNewCategoryGroup = () => {
    let newArr = [...categoryGroup];
    let sortCategory = []
    let unSorted = []
    categories.forEach(i => {
      let a = newArr.find(c => c.categoryID === i.houseAdsCategoryID);
      if (a === undefined) {
        unSorted.push(i)
      } else {
        sortCategory.push(i);
      }
    });
    console.log(unSorted)
    const newCategoryGroup = {
      categoryID: '',
      categoryName: '',
      categoryWeightage: 1,
      categoryPercentage: '',
      categoryLists: objectCategoriesModal(unSorted),
      mainCreativeLists: [],
      creativePresent: false,
      creativeData: [{
        creativeID: '',
        creativeName: '',
        creativeWeightage: 1,
        creativePercentage: '',
        creativeLists: []
      }]
    }
    setCategoryGroup([...categoryGroup, newCategoryGroup])
    changePercentage([...categoryGroup, newCategoryGroup])
    // changePercentageCreative([...categoryGroup, newCategoryGroup],0)
    setAdUnit(adUnit => ({ ...adUnit, data: { list: [...categoryGroup, newCategoryGroup] } }));
    setCategorySelection(true)
  }

  const addNewCreativeData = (index) => e => {
    let newArrGroup = [...categoryGroup];
    let sortCreative = []
    let unSorted = []
    let mainCreatives = newArrGroup[index].mainCreativeLists
    mainCreatives.forEach(i => {
      let a = newArrGroup[index].creativeData.find(c => c.creativeID === i.houseAdsCreativeID);
      if (a === undefined) {
        unSorted.push(i)
      } else {
        sortCreative.push(i);
      }
    });
    console.log(unSorted)
    const newCreativeData = {
      creativeID: '',
      creativeName: '',
      creativeWeightage: 1,
      creativePercentage: '',
      creativeLists: objectCreativesModal(unSorted)
    }
    let mainArr = [...categoryGroup]
    let newArr = [...categoryGroup[index].creativeData];
    newArr.push(newCreativeData);
    mainArr[index].creativePresent = false
    mainArr[index].creativeData = [...newArr]
    setCategoryGroup(mainArr);
    changePercentageCreative(mainArr, index)
    setAdUnit(adUnit => ({ ...adUnit, data: { list: mainArr } }));
  }

  const removeCreative = (gindex, i) => (e) => {
    let mainArr = [...categoryGroup]
    let newArr = [...categoryGroup[gindex].creativeData];
    newArr.splice(i, 1);
    mainArr[gindex].creativeData = [...newArr]
    setCategoryGroup(mainArr);
    changePercentageCreative(mainArr, gindex)
    setAdUnit(adUnit => ({ ...adUnit, data: { list: mainArr } }));
  }

  const removeGroups = (index) => (e) => {
    console.log(index)
    // if (categoryGroup[index].creativeData.findIndex(tag => {
    //   return tag.creativeID !== ''
    // }) > -1) {
    //   toastr.warning(' Unable to delete Category, remove Creative data to delete— check it out!')
    // }
    // const uGroups = categoryGroup.filter((gp, ind) => {
    //   return ind !== index
    // }).map((gpm, p) => {
    //   return {
    //     ...gpm,
    //     categoryID: 'group' + (p + 1),
    //   }
    // })

    let newArr = [...categoryGroup];
    newArr.splice(index, 1);
    setCategoryGroup(newArr);
    changePercentage(newArr)
    setAdUnit(adUnit => ({ ...adUnit, data: { list: newArr } }));
  }

  const openCollapsed = (panel) => (event, newExpanded) => {
    console.log(panel)
    setItemOpen(panel)
  };

  function categoryChange(index, selected) {
    getCreatives(parseInt(selected.value), index)
  }

  const getCreatives = (categoryID, index) => {
    var filterBody = {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,
    }
    axios.post("/v2.0/BxApps/HouseAdsCreatives/readOnCategory/" + categoryID, filterBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("cdsresult is ==>", res)
        if (res.data) {
          if (categoryID === 328) {
            res.data.response.unshift({
              houseAdsCreativeID: 10001,
              houseAdsCreativeName: 'All'
            })
          }
          res.data.response.map((item, index) => {
            item.id = index + 1
          })
          let newArr = [...categoryGroup];
          console.log(newArr)
          newArr[index].categoryID = parseInt(categoryID);
          newArr[index].categoryName = (newArr[index].categoryLists.filter((option) => option.value === parseInt(categoryID))[0].label)
          newArr[index].mainCreativeLists = res.data.response
          let newCreative = [{
            creativeID: '',
            creativeName: '',
            creativeWeightage: 1,
            creativePercentage: 100,
            creativeLists: objectCreativesModal(res.data.response)
          }]
          newArr[index].creativeData = newCreative
          setCategoryGroup(newArr)
          setAdUnit(adUnit => ({ ...adUnit, data: { list: newArr } }));
          console.log(categoryGroup)
          setCategorySelection(false)
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  function creativeChange(CtgIndex, index, selected) {
    console.log(categoryGroup, selected)
    let mainArr = [...categoryGroup]
    let newArr = [...categoryGroup[CtgIndex].creativeData];
    console.log(selected, mainArr)
    if (selected.value === '10001') {
      mainArr[CtgIndex].creativeData = [{
        creativeID: 10001,
        creativeName: 'All',
        creativeWeightage: 1,
        creativePercentage: 100,
        creativeLists: mainArr[CtgIndex].creativeData[0].creativeLists
      }]
    } else {
      newArr[index].creativeID = parseInt(selected.value)
      newArr[index].creativeName = (mainArr[CtgIndex].creativeData[0].creativeLists.filter((option) => option.value === selected.value)[0].label),
        mainArr[CtgIndex].creativeData = [...newArr]
      mainArr[CtgIndex].creativePresent = true
    }
    console.log(mainArr)
    setCategoryGroup(mainArr);
    setAdUnit(adUnit => ({ ...adUnit, data: { list: mainArr } }));
  }

  const contentChange = index => e => {
    console.log(index)
    console.log(e.target)
    let newArr = [...categoryGroup];
    console.log(newArr)
    console.log(newArr[index].categoryWeightage)
    newArr[index].categoryWeightage = parseInt(e.target.value);
    console.log(total)
    setCategoryGroup(newArr);
    changePercentage(newArr)
    setAdUnit(adUnit => ({ ...adUnit, data: { list: newArr } }));
  }

  const blurChange = index => e => {
    console.log("input blurred")
    let newArr = [...categoryGroup];
    if (parseInt(e.target.value) < 1) {
      newArr[index].categoryWeightage = 1;
    } else {
      newArr[index].categoryWeightage = parseInt(e.target.value);
    }
    console.log(newArr)
    setCategoryGroup(newArr);
    changePercentage(newArr)
    setAdUnit(adUnit => ({ ...adUnit, data: { list: newArr } }));
  }

  const changePercentage = (data) => {
    console.log(data)
    total = 0
    data.map((item, index) => {
      if (Number.isNaN(parseInt(item.categoryWeightage)) || parseInt(item.categoryWeightage) < 1) {
        total = total + 1
      } else {
        total = total + parseInt(item.categoryWeightage)
      }
    })
    totalPercentageChange(data)
  }

  const creativeMaxChange = (data) => {
    console.log(data)
    var maxId = data.reduce(function (maxId, item) {
      return Math.max(maxId, item.categoryPercentage)
    }, Number.MIN_VALUE);
    console.log("Max:", maxId)
    console.log("Max:", data)
    console.log("TotalPercentage:", totalPercentage)
    data.map((item, index) => {
      let newArr = [...data];
      if (totalPercentage >= 100) {
        if (item.categoryPercentage === maxId) {
          console.log(item.categoryPercentage)
          newArr[index].categoryPercentage = maxId - (totalPercentage - 100);
          console.log(newArr)

          setCategoryGroup(newArr);
          setAdUnit(adUnit => ({ ...adUnit, data: { list: newArr } }));
        }
      }
      else if (item.categoryPercentage >= 99) {
        newArr[index].categoryPercentage = item.categoryPercentage - 2;
      }
      else if (item.categoryPercentage === 0) {
        newArr[index].categoryPercentage = 1;
        totalPercentage = totalPercentage + 1
      }
    })
  }

  const creativeMinChange = (data) => {
    console.log(data)

    let newArr = [...data];
    console.log(totalPercentage)
    if (totalPercentage === 99) {
      newArr[0].categoryPercentage = newArr[0].categoryPercentage + 1
      console.log(newArr)

      setCategoryGroup(newArr);
      setAdUnit(adUnit => ({ ...adUnit, data: { list: newArr } }));
    }
    creativeMaxChange(newArr)
  }

  const totalPercentageChange = (data) => {
    totalPercentage = 0
    data.map((item, index) => {
      if (item.categoryWeightage < 1) {
        item.categoryPercentage = Math.floor(((1 / total) * 100), 6);
      } else {
        item.categoryPercentage = Math.floor(((item.categoryWeightage / total) * 100), 6);
      }
      totalPercentage = totalPercentage + item.categoryPercentage
    })
    console.log(data)
    let newArr = [...data];
    setCategoryGroup(newArr)
    setAdUnit(adUnit => ({ ...adUnit, data: { list: newArr } }));
    creativeMinChange(newArr)
  }



  const contentChangeCreative = (CtgIndex, index) => e => {
    let mainArr = [...categoryGroup]
    let newArr = [...categoryGroup[CtgIndex].creativeData];
    newArr[index].creativeWeightage = parseInt(e.target.value);
    mainArr[CtgIndex].creativeData = [...newArr]
    setCategoryGroup(mainArr);
    changePercentageCreative(mainArr, CtgIndex)
    setAdUnit(adUnit => ({ ...adUnit, data: { list: mainArr } }));
  }

  const blurChangeCreative = (CtgIndex, index) => e => {
    console.log("input blurred")
    let mainArr = [...categoryGroup]
    let newArr = [...categoryGroup[CtgIndex].creativeData];
    if (parseInt(e.target.value) < 1) {
      newArr[index].creativeWeightage = 1;
    } else {
      newArr[index].creativeWeightage = parseInt(e.target.value);
    }
    mainArr[CtgIndex].creativeData = [...newArr]
    setCategoryGroup(mainArr);
    changePercentageCreative(mainArr, CtgIndex)
    setAdUnit(adUnit => ({ ...adUnit, data: { list: mainArr } }));
  }

  const changePercentageCreative = (data, index) => {
    console.log(data)
    totalCreative = 0
    data[index].creativeData.map((item, index) => {
      if (Number.isNaN(parseInt(item.creativeWeightage)) || parseInt(item.creativeWeightage) < 1) {
        totalCreative = totalCreative + 1
      } else {
        totalCreative = totalCreative + parseInt(item.creativeWeightage)
      }
    })
    totalPercentageChangeCreative(data, index)
  }

  const creativeMaxChangeCreative = (data, index) => {
    console.log(data)
    var maxId = data[index].creativeData.reduce(function (maxId, item) {
      return Math.max(maxId, item.creativePercentage)
    }, Number.MIN_VALUE);
    console.log("Max:", maxId)
    console.log("Max:", data)
    console.log("TotalPercentage:", totalPercentageCreative)
    data[index].creativeData.map((item, i) => {
      let mainArr = [...categoryGroup]
      let newArr = [...categoryGroup[index].creativeData];
      if (totalPercentageCreative >= 100) {
        if (item.creativePercentage === maxId) {
          console.log(item.creativePercentage)
          newArr[i].creativePercentage = maxId - (totalPercentageCreative - 100);
          console.log(newArr)
          mainArr[index].creativeData = [...newArr]
          setCategoryGroup(mainArr);
          setAdUnit(adUnit => ({ ...adUnit, data: { list: mainArr } }));
        }
      }
      else if (item.creativePercentage >= 99) {
        newArr[i].creativePercentage = item.creativePercentage - 2;
      }
      else if (item.creativePercentage === 0) {
        newArr[i].creativePercentage = 1;
        totalPercentageCreative = totalPercentageCreative + 1
      }
    })
  }

  const creativeMinChangeCreative = (data, index) => {
    console.log(data)
    let mainArr = [...categoryGroup]
    let newArr = [...data[index].creativeData];
    console.log(totalPercentageCreative)
    if (totalPercentageCreative === 99) {
      newArr[0].creativePercentage = newArr[0].creativePercentage + 1
      console.log(newArr)
      mainArr[index].creativeData = [...newArr]
      setCategoryGroup(mainArr);
      setAdUnit(adUnit => ({ ...adUnit, data: { list: mainArr } }));
    }
    creativeMaxChangeCreative(mainArr, index)
  }

  const totalPercentageChangeCreative = (data, index) => {
    totalPercentageCreative = 0
    data[index].creativeData.map((item, index) => {
      if (item.creativeWeightage < 1) {
        item.creativePercentage = Math.floor(((1 / totalCreative) * 100), 6);
      } else {
        item.creativePercentage = Math.floor(((item.creativeWeightage / totalCreative) * 100), 6);
      }
      totalPercentageCreative = totalPercentageCreative + item.creativePercentage
    })
    console.log(data)
    let newArr = [...data];
    setCategoryGroup(newArr)
    setAdUnit(adUnit => ({ ...adUnit, data: { list: newArr } }));
    creativeMinChangeCreative(newArr, index)
  }

  const handleSubmit = async (e) => {
    console.log(adUnit)
    e.preventDefault();
    setSubmitted(true);
    let newArrGroup = [...categoryGroup];
    let a = newArrGroup.find(c => c.categoryID === '')
    console.log(a)

    if (a && (a.categoryID === '' || a.categoryID === 'NAN')) {
      toastr.error('Please Select Category — check it out!')
    } else {
      if (newArrGroup[0].creativeData[0].creativeID === '' || newArrGroup[0].creativeData[0].creativeID === 'NAN') {
        toastr.error('Please Select Creative — check it out!')
      } else {
        if (adUnit.houseAdsAdUnitName && adUnit.houseAdsAdUnitPublisher && adUnit.houseAdsAdUnitSize) {
          setLoader(true)
          let finalData = adUnit
          if (finalData.data.list.length > 0) {
            finalData.data.list.map((item, index) => {
              delete item.categoryLists;
              delete item.mainCreativeLists;
              delete item.creativePresent;
              item.creativeData.map((elem, i) => {
                console.log(elem.creativeID)
                if (elem.creativeID === 10001) {
                  delete elem.creativeLists
                  delete elem.creativeID
                  delete elem.creativePercentage
                  delete elem.creativeWeightage
                  item.creativeData = ['ALL']
                } else {
                  delete elem.creativeLists
                }
              })
              console.log(item.creativeData.length)
            })
          }
          console.log(finalData)
          debugger
          if (props.selectedAdUnit !== '') {
            dispatch(onUpdateAdUnit({ ...finalData, houseAdsAdUnitName: finalData.houseAdsAdUnitName.trim() }));
          } else {
            dispatch(onADDAdUnit({ ...finalData, houseAdsAdUnitName: finalData.houseAdsAdUnitName.trim() }));
          }
        }
      }
    }
  }

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.adUnit.errorMsg,
    successMsg: state.adUnit.successMsg,
  }));

  console.log(errorMsg, successMsg)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.success) {
        dispatch(addHouseAdUnitSuccess(""));
        dispatch(addHouseAdUnitFail(""));
        dispatch(updateHouseAdUnitSuccess(""));
        dispatch(updateHouseAdUnitFail(""));
        setLoader(false)
        if (props.selectedAdUnit !== '') {
          props.closeCanvas('Update')
        } else {
          props.closeCanvas('Create')
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if (errorMsg.status && errorMsg.status === 500) {
      } else {
        dispatch(addHouseAdUnitSuccess(""));
        dispatch(addHouseAdUnitFail(""));
        dispatch(updateHouseAdUnitSuccess(""));
        dispatch(updateHouseAdUnitFail(""));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addHouseAdUnitSuccess(""));
    dispatch(addHouseAdUnitFail(""));
    dispatch(updateHouseAdUnitSuccess(""));
    dispatch(updateHouseAdUnitFail(""));
    props.closeCanvas('')
  };

  function allowAlphaNumericSpace(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault(); }
  };

  function handleSelectSize(selectSize) {
    setAdUnit(adUnit => ({ ...adUnit, houseAdsAdUnitSize: selectSize.value }));
    setSelectedSize(selectSize);
  }


  return (
    <React.Fragment>
      {loader &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
        <Form onSubmit={handleSubmit}>
          <Row style={{ padding: '1% 3%', height: '78vh', overflow: 'auto', width: '100%' }}>
            <Col xs={12}>
              <div className="mb-3">
                <Label>Publisher  <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Select
                  value={selectedPublisher}
                  onChange={s => {
                    handleSelectPublisher(s)
                  }}
                  options={publisherData}
                  classNamePrefix="select2-selection"
                  className={(submitted && !adUnit.houseAdsAdUnitPublisher ? ' is-invalid' : '')}
                  isDisabled={props.selectedArchived}
                />
                {submitted && !adUnit.houseAdsAdUnitPublisher ? (
                  <FormFeedback type="invalid">{'Publisher is required'}</FormFeedback>
                ) : null}

              </div>
              <div className="mb-3">
                <Label className="form-label">Ad Size  <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                {/* <Input type="select" name="houseAdsAdUnitSize" onChange={handleChange} value={adUnit.houseAdsAdUnitSize}
                  className={'form-control form-select' + (submitted && !adUnit.houseAdsAdUnitSize ? ' is-invalid' : '')} readOnly={props.selectedArchived}>
                  <option value=''> Select</option>
                  <option value="0x0">0x0 </option>
                  <option value="1x1">1x1 </option>
                  <option value="300x250">300x250 </option>
                  <option value="300x600">300x600 </option>
                  <option value="320x442"> 320x442 </option>
                  <option value="468x100">468x100 </option>
                  <option value="728x90">728x90 </option>
                  <option value="800x600">800x600 </option>
                  <option value="120x600">120x600 </option>
                  <option value="160x600">160x600 </option>
                  <option value="970x250">970x250 </option>
                  <option value="970x90">970x90</option>
                  <option value="320x50">320x50</option>
                  <option value="320x100">320x100 </option>
                  <option value="320x480">320x480 </option>
                  <option value="300x50">300x50 </option>
                  <option value="300x100">300x100 </option>
                  <option value="468x60">468x60 </option>
                  <option value="468x90">468x90</option>
                  <option value="336x280">336x280 </option>
                  <option value="800x485"> 800x485 </option>

                </Input> */}
                <Select
                  value={selectedSize}
                  onChange={s => {
                    handleSelectSize(s)
                  }}
                  options={sizeData}
                  classNamePrefix="select2-selection"
                  className={(submitted && !adUnit.houseAdsAdUnitSize ? ' is-invalid' : '')}
                  isDisabled={props.selectedArchived}
                />
                {submitted && !adUnit.houseAdsAdUnitSize ? (
                  <FormFeedback type="invalid">{'Ad Size is required'}</FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Ad Unit Name  <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Input
                  name="houseAdsAdUnitName"
                  type="text"
                  value={adUnit.houseAdsAdUnitName}
                  maxLength={110}
                  onKeyPress={allowAlphaNumericSpace}
                  onChange={handleChange}
                  className={'form-control' + (submitted && !adUnit.houseAdsAdUnitName ? ' is-invalid' : '')}
                  readOnly={props.selectedArchived}
                />
                {submitted && !adUnit.houseAdsAdUnitName ? (
                  <FormFeedback type="invalid">{'Ad Unit Name is required'}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col xs={12}>
              <div className="">
                <Label >
                  Category Management
                </Label>

                {categoryGroup && categoryGroup.map((item, index) => {
                  console.log("group............Item", item, "categoryGroup==========", categoryGroup);
                  return (
                    <div className="categoryGroup-settings" key={index}>
                      <div className="accordion-item ml-0 mr-0 mb-4 mt-4">
                        <h2 className="accordion-header row m-0" id="headingOne">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: itemOpen !== ('panel' + (index)) }
                            )}
                            type="button"
                            onClick={openCollapsed('panel' + (index))}
                            style={{ cursor: "pointer" }}
                          >
                            <span className='mt-2 '>        <div className="row mb-3">
                              <div className="col-7">
                                <Label className="form-label"> Select Category</Label>
                                {/* <Input
                                  name="houseAdsCategoryID"
                                  type="select"
                                  className="form-select"
                                  value={item.categoryID}
                                  onChange={categoryChange(index)}
                                >
                                  <option value='' disabled>Select</option>
                                  {item.categoryLists && item.categoryLists.map((elem) => (
                                    <option key={elem.houseAdsCategoryID} value={elem.houseAdsCategoryID}>{elem.houseAdsCategoryName}</option>
                                  ))}
                                </Input> */}
                                <Select
                                  value={{ value: item.categoryID, label: item.categoryName }}
                                  onChange={s => {
                                    categoryChange(index, s)
                                  }}
                                  options={item.categoryLists}
                                  classNamePrefix="select2-selection"
                                  isDisabled={props.selectedArchived}
                                />
                              </div>
                              <div className="col-4">
                                <Label >  Weightage</Label>
                                <Input
                                  type="number" name="categoryWeightage" value={item.categoryWeightage} onBlur={blurChange(index)} maxLength='4' placeholder='1' onChange={contentChange(index)} onWheel={(e) => e.currentTarget.blur()}
                                />
                              </div>
                              <div className="col-1 text-center">
                                <Label className="mb-3">  Parcentage</Label>
                                <span className='ml-4 mr-4 mt-3'>
                                  {item.categoryPercentage}%
                                </span>
                              </div>
                            </div></span>

                          </button>
                          {
                            categoryGroup.length === 1 && <button className='btn  btn-danger disabled-rmg rem-grp-btn ' style={{ margin: '1% 3% 1% auto', width: 'fit-content', background: '#dc3545' }} disabled>Remove Category</button>
                          }
                          {
                            categoryGroup.length !== 1 && <Link to="#" className='btn btn-danger rem-grp-btn' style={{ margin: '1% 3% 1% auto', width: 'fit-content', background: '#dc3545' }} onClick={removeGroups(index)}
                            >Remove Category</Link>
                          }
                        </h2>
                        {
                          item.creativeData.map((subItem = {}, i) => {
                            return (
                              <div className="vast-settings" key={i}>
                                <div className="accordion-body">
                                  <div >{subItem.vast}

                                    <div className="form-group">
                                      <div className="row mb-3">
                                        <div className="col-5">
                                          <Label className="form-label"> Select Creative</Label>
                                          <Select
                                            value={{ value: subItem.creativeID, label: subItem.creativeName }}
                                            onChange={s => {
                                              creativeChange(index, i, s)
                                            }}
                                            options={subItem.creativeLists}
                                            classNamePrefix="select2-selection"
                                            isDisabled={props.selectedArchived}
                                          />
                                        </div>
                                        <div className="col-3" style={{ display: subItem.creativeID === 10001 ? 'none' : 'block' }}>
                                          <Label>Weightage</Label>
                                          <Input
                                            type="number" name="creativeWeightage" value={subItem.creativeWeightage} onBlur={blurChangeCreative(index, i)} maxLength='4' placeholder='1' onChange={contentChangeCreative(index, i)} onWheel={(e) => e.currentTarget.blur()}
                                          />
                                        </div>
                                        <div className="col-2 text-center" style={{ display: subItem.creativeID === 10001 ? 'none' : 'block' }}>
                                          <Label className="mb-3 no-wrap"> Parcentage</Label><br />
                                          <span className='ml-4 mr-4 '>
                                            {subItem.creativePercentage}%
                                          </span>
                                        </div>
                                        <div className="col-2" style={{ display: subItem.creativeID === 10001 ? 'none' : 'block' }}>
                                          <div className='row form-group mt-4'>
                                            {/* <Link to="#" className='btn btn-danger ml-auto clear-vastdata' style={{ marginTop: '0pc', background: '#dc3545', left: '75%', width: '4pc', }} onClick={handleClearVastData(index, i)}
                                              >Clear</Link> */}
                                            {
                                              categoryGroup[index].creativeData.length === 1 && <button className='btn mt-1 btn-danger ml-auto disabled-rmg ' style={{ marginRight: '1pc', marginLeft: 'auto', marginTop: '0pc', background: '#dc3545', float: 'right', width: '3pc', }} disabled>X</button>
                                            }
                                            {
                                              categoryGroup[index].creativeData.length !== 1 && <Link to="#" className='btn mt-1 btn-danger ml-auto ' style={{ marginRight: '1pc', marginLeft: 'auto', marginTop: '0pc', background: '#dc3545', float: 'right', width: '3pc', }} onClick={removeCreative(index, i)}
                                              >X</Link>
                                            }

                                            {/* {
                                              vastDismiss && subItem.displayDismissAlert && <Snackbar open={vastDismiss} autoHideDuration={2000} className="alert-centered" onClose={() => setVastDismiss(false)}>
                                                <Alert onClose={() => setVastDismiss(false)} severity="info" sx={{ width: '100%' }}>
                                                  Unable to delete Vast tag, remove data to delete— check it out!
                                                </Alert>
                                              </Snackbar>

                                              } */}

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                        <div className='row'>

                          {/* {
                              categoryGroup[index].creativeData.length >= 5 && <button className='font-16 btn-block btn btn-primary' style={{ margin: '1% 2.5rem 1% auto', width: 'auto' }} disabled>Add Creative</button>
                            } */}
                          {/* <Link to="#" className='font-16 btn-block btn btn-primary' style={{ margin: '1% 2.5rem 1% auto', width: 'auto' }} onClick={addNewCreativeData(index)} readOnly={item.mainCreativeLists.length === item.creativeData.length} >  <i className="mdi mdi-plus-circle-outline me-1" />&nbsp;&nbsp;  Add Creative </Link> */}
                          <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                            style={{ margin: '1% 2.5rem 1% auto', width: 'auto' }}
                            onClick={addNewCreativeData(index)}
                            disabled={item.mainCreativeLists && (item.mainCreativeLists.length === item.creativeData.length || !item.creativePresent)}
                          >
                            <i className="mdi mdi-plus-circle-outline me-1" />
                            Add Creative
                          </Button>

                        </div>
                      </div>
                    </div>
                  )
                }
                )}
                <div className='row'>
                  <Button
                    color="primary"
                    className="font-16 btn-block btn btn-primary"
                    style={{ margin: '1% 3.0rem 1% auto', width: 'auto' }}
                    onClick={addNewCategoryGroup}
                    disabled={(categories.length === categoryGroup.length) || categorySelection}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Add Category
                  </Button>
                  {/* <span className='mt-2 mb-2 btn ml-auto' style={{ marginRight: '3.0rem' }} onClick={addNewCategoryGroup} >Add New Video Creative Groups &nbsp;&nbsp; <i className="fa fa-plus"></i></span> */}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
            <Col>
              <div className="text-start">
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
                &nbsp;&nbsp;
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }
                &nbsp;&nbsp;

                {isEdit && !props.selectedArchived &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
          <Toaster status="error" msg={errorMsg.message} />
        }
      </div>
      {errorMsg !== null && errorMsg !== '' && errorMsg.status && errorMsg.status === 500 &&
        <div className="p-0 justify-content-center d-flex">
          <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')} />
        </div>
      }
    </React.Fragment>
  )
}

export default New_Supply_Tag