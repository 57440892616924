import React, { useState, useEffect } from "react";

const Title = () => {
    // const [originalTitle, setOriginalTitle] = useState(document.title);

    // useEffect(() => {
    //     const handleTabVisibilityChange = () => {
    //         if (document.hidden) {
    //             document.title = `Come back :(`;
    //         } else {
    //             document.title = originalTitle;
    //         }
    //     };

    //     document.addEventListener("visibilitychange", handleTabVisibilityChange);

    //     return () => {
    //         document.removeEventListener("visibilitychange", handleTabVisibilityChange);
    //     };
    // }, [originalTitle]);

    return <h1></h1>;
};

export default Title;
