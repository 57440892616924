/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 2 : 1

const DateRangePickers = (props) => {
  const [fromDate, setFromDate] = useState(props.startDate);
  const [toDate, setToDate] = useState(props.endDate);
  
  const handleEvent = (event, picker) => {

    if(picker.startDate._isValid && picker.endDate._isValid){
      setFromDate(picker.startDate._d.toISOString());
      setToDate(picker.endDate._d.toISOString());
      props.datePick(picker)
    }else{
      setFromDate(picker.startDate._i.toString());
      setToDate(picker.endDate._i.toString());
      props.datePick(picker)
    }
  };
  
  return (
  <DateRangePicker
  initialSettings={{
    opens: 'left',
    startDate:props.startDate || moment().subtract(2, 'days'),
    endDate:props.endDate || moment().subtract(2, 'days'),
    maxDate: moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),
    ranges: {
      // Today: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      Yesterday: [
        moment().subtract(2, 'days'),
        moment().subtract(2, 'days'),
      ],
      'Last 7 Days': [moment().subtract(6, 'days'), moment().subtract(1, 'days')],
      'Last 30 Days': [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ],
    },
  }}
  onEvent={handleEvent}
  >
  <button className='datePicker' type="button">
  <i  className="bx bx-calendar font-size-18 " id="edittooltip" style={{margin:'3% 3% 2%'}} ></i>{moment(fromDate).format("LL")} - {moment(toDate).format("LL")}
  </button>
  </DateRangePicker>
  )
  }

export default DateRangePickers