import {
    LOAD_NET_BLOCKINGS,
    NO_DATA,
    GET_NET_BLOCKINGS,
    GET_NET_BLOCKINGS_FAIL,
    GET_NET_BLOCKINGS_SUCCESS,
    ADD_NET_BLOCKING,
    ADD_NET_BLOCKING_SUCCESS,
    ADD_NET_BLOCKING_ERROR,
    UPDATE_NET_BLOCKING,
    UPDATE_NET_BLOCKING_SUCCESS,
    UPDATE_NET_BLOCKING_ERROR,
    READ_NET_BLOCKING,
    READ_NET_BLOCKING_SUCCESS,
    STATUS_NET_BLOCKING,
    STATUS_NET_BLOCKING_SUCCESS,
    ARCHIVE_NET_BLOCKING ,
    ARCHIVE_NET_BLOCKING_SUCCESS,

  } from "./actionTypes"

  export const loadNetworkBlockings = Params => ({
    type: LOAD_NET_BLOCKINGS,
    payload:Params
  })

  export const noDataNetworkBlockings = Params => ({
    type: NO_DATA,
    payload:Params
  })

  export const getNetworkBlockings = Params => ({
    type: GET_NET_BLOCKINGS,
    payload:Params
  })
  
  export const getNetworkBlockingsSuccess = NetworkBlockings => ({
    type: GET_NET_BLOCKINGS_SUCCESS,
    payload: NetworkBlockings,
  })

  export const getNetworkBlockingsFail = error => ({
    type: GET_NET_BLOCKINGS_FAIL,
    payload: error,
  })

  export const addNewNetworkBlocking = NetworkBlocking => ({
    type: ADD_NET_BLOCKING,
    payload: NetworkBlocking,
  })
  
  export const addNetworkBlockingSuccess = netBlocking => ({
    type: ADD_NET_BLOCKING_SUCCESS,
    payload: netBlocking,
  })
  
  export const addNetworkBlockingFail = netBlocking => ({
    type: ADD_NET_BLOCKING_ERROR,
    payload: netBlocking,
  })

  export const updateNetworkBlocking = netBlocking => ({
    type: UPDATE_NET_BLOCKING,
    payload: netBlocking,
  })
  
  export const updateNetworkBlockingSuccess = netBlocking => ({
    type: UPDATE_NET_BLOCKING_SUCCESS,
    payload: netBlocking,
  })
  
  export const updateNetworkBlockingFail = netBlocking => ({
    type: UPDATE_NET_BLOCKING_ERROR,
    payload: netBlocking,
  })

  export const readNetworkBlocking = netBlocking => ({
    type: READ_NET_BLOCKING,
    payload: netBlocking,
  })

  export const readNetworkBlockingSuccess = netBlocking => ({
    type: READ_NET_BLOCKING_SUCCESS,
    payload: netBlocking,
  })

  export const statusNetworkBlocking = netBlocking => ({
    type: STATUS_NET_BLOCKING,
    payload: netBlocking,
  })

  export const statusNetworkBlockingSuccess = netBlocking => ({
    type: STATUS_NET_BLOCKING_SUCCESS,
    payload: netBlocking,
  })

  export const archiveNetworkBlocking = netBlocking => ({
    type: ARCHIVE_NET_BLOCKING,
    payload: netBlocking,
  })

  export const archiveNetworkBlockingSuccess = netBlocking => ({
    type: ARCHIVE_NET_BLOCKING_SUCCESS,
    payload: netBlocking,
  })