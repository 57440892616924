/* PLAYERSETTING */
export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES"
export const NO_DATA = "NO_DATA"
export const GET_PLAYERSETTINGS = "GET_PLAYERSETTINGS"
export const GET_PLAYERSETTINGS_SUCCESS = "GET_PLAYERSETTINGS_SUCCESS"
export const GET_PLAYERSETTINGS_FAIL = "GET_PLAYERSETTINGS_FAIL"

export const ADD_PLAYERSETTING = "ADD_PLAYERSETTING"
export const ADD_PLAYERSETTING_SUCCESS = "ADD_PLAYERSETTING_SUCCESS"
export const ADD_PLAYERSETTING_ERROR = "ADD_PLAYERSETTING_ERROR"

export const UPDATE_PLAYERSETTING = "UPDATE_PLAYERSETTING"
export const UPDATE_PLAYERSETTING_SUCCESS = "UPDATE_PLAYERSETTING_SUCCESS"
export const UPDATE_PLAYERSETTING_ERROR = "UPDATE_PLAYERSETTING_ERROR"

export const READ_PLAYERSETTING = "READ_PLAYERSETTING"
export const READ_PLAYERSETTING_SUCCESS = "READ_PLAYERSETTING_SUCCESS"

export const STATUS_PLAYERSETTING = "STATUS_PLAYERSETTING"
export const STATUS_PLAYERSETTING_SUCCESS = "STATUS_PLAYERSETTING_SUCCESS"

export const ARCHIVE_PLAYERSETTING = "ARCHIVE_PLAYERSETTING"
export const ARCHIVE_PLAYERSETTING_SUCCESS = "ARCHIVE_PLAYERSETTING_SUCCESS"