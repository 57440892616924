/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form,Spinner } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import Creative_Selection from "./Creative_Selection";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// actions
import {
  addNewCreative as onADDCreative,
  updateCreative as onUpdateCreative,
  readCreative as onReadCreative,
  addCreativeSuccess,
  addCreativeFail,
  updateCreativeSuccess,
  updateCreativeFail,
} from "store/Demand/Creative/actions";
import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";

const NewThirdParty = props => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  console.log(props)

  const [finalData, setFinalData] = useState({
    creativeType:props.creativeType === 'stdb' ? "stdbST" : "highImpact",
    CreativeTemplateFor: props.creativeType === 'stdb' ? "STDB" : "highImpact",
    typeFor:props.creativeType,
    creativeWeight: 1,
    url: "",
    HbDemanOption  : 'all',
    googleDemandTag: '',
    SSPTag: '',
    HbTag:'',
    creativeTemplateName: 'thirdParty',
    googleDemandOption:''
  })

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 1000,
    extendedTimeOut: 1000
  };

  const [submitted, setSubmitted] = useState(false);
  const [googleState, setGoogleState] = useState(true);

  const { errorMsg, successMsg ,creativeData} = useSelector(state => ({
    errorMsg: state.creatives.errorMsg,
    successMsg: state.creatives.successMsg,
    creativeData: state.creatives.creative
  }));

  console.log(errorMsg, successMsg,creativeData)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.statusCode === 200) {
        dispatch(addCreativeSuccess(""));
        dispatch(addCreativeFail(null));
        dispatch(updateCreativeSuccess(""));
        dispatch(updateCreativeFail(null));
        setLoader(false)
        if (props.selectedCreativeData !== '' && props.selectedcreativeCreateStatus !== 'Duplicate') {
          props.creativeNewClose('Update')
        } else {
          props.creativeNewClose('Create')
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if(errorMsg.status && errorMsg.status === 500){
      }else{
        dispatch(addCreativeSuccess(""));
        dispatch(addCreativeFail(null));
        dispatch(updateCreativeSuccess(""));
        dispatch(updateCreativeFail(null));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addCreativeSuccess(""));
    dispatch(addCreativeFail(null));
    dispatch(updateCreativeSuccess(""));
    dispatch(updateCreativeFail(null));
        props.creativeNewClose('')
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFinalData(creative => ({ ...creative, [name]: value }));
  }

  const blurChange = (e) => {
    console.log("input blurred")
    const { name, value } = e.target;
    if (e.target.value < 1) {
      setFinalData(finalData => ({ ...finalData, [name]: 1 }));
    } else {
      setFinalData(finalData => ({ ...finalData, [name]: value }));
    }
  }

  const getCreativeData = async (selectedCreativeData) => {
    console.log(selectedCreativeData)
    setFinalData({ ...finalData, ...selectedCreativeData,creativeType:props.creativeType === 'stdb' ? "stdbST" : "highImpact",CreativeTemplateFor:props.creativeType === 'stdb' ? "STDB" : "highImpact" })
    console.log(finalData)
  }

  const changeTarget3 = (e) => {
    setGoogleState(e.target.checked)
    if (e.target.checked) {
      setFinalData({ ...finalData, HbDemanOption  : 'all' })
    } else {
      setFinalData({ ...finalData, HbDemanOption  : 'firstlook' })
    }
  }

  useEffect(() => {
    console.log(props.selectedCreativeData)
    if (props.selectedCreativeData !== '') {
      dispatch(onReadCreative({ ...props.selectedCreativeData,userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID }));
      if(props.selectedcreativeCreateStatus !== 'Duplicate'){
        setIsEdit(true)
      }
  }
    if(props.selectedLineItem !== ''){
      setFinalData({ ...finalData, lineItemID: props.selectedLineItem.lineItemID })
     }
  }, [])

  useEffect(() => {
    if (creativeData !== null && props.selectedCreativeData !== '') {
      console.log(creativeData)
      if (props.selectedcreativeCreateStatus === 'Duplicate') {
        delete creativeData.creativeCreateTime
        delete creativeData.creativeID
        delete creativeData.creativeModifyTime
        delete creativeData.creativeStatus
        delete creativeData.isCreativeArchived
       }
      setFinalData(finalData => ({ ...finalData,SSPTag: creativeData.tag1 ? creativeData.tag1 : creativeData.SSPTag, ...creativeData}));
      setGoogleState(creativeData.HbDemanOption   === 'all' ? true : false)
    }
  }, [creativeData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let body = {...finalData,creativeType:props.creativeType === 'stdb' ? "stdbST" : "highImpact",CreativeTemplateFor:props.creativeType === 'stdb' ? "STDB" : "highImpact"}
    console.log(body)

    setSubmitted(true);
    if (finalData.creativeName && finalData.lineItemID  && finalData.creativeWeight && finalData.SSPTag) {
      setLoader(true)
      if (props.selectedCreativeData !== '' && props.selectedcreativeCreateStatus !== 'Duplicate'){
        dispatch(onUpdateCreative({ ...body,creativeName:finalData.creativeName.trim(),userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID  }));
      }else{
        dispatch(onADDCreative({ ...body,creativeName:finalData.creativeName.trim(),userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID  }));
      }
    }
  }

  function allowOnlyNumbers(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if ( !(code >= 48 && code < 58) && !(code >= 46 && code < 47)) { e.preventDefault();}
  };

  return (
    <React.Fragment>
     {loader &&
              <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-12" style={{pointerEvents:(loader) ? 'none' : 'unset'}}>
      <Form onSubmit={handleSubmit}>
          <Row form className="formBodyScroll" style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
            <Col xs={12}>
              <h6 className="text-muted">Third Party Creative Setup</h6>
              <hr></hr>
              <Creative_Selection selectedArchived={props.selectedArchived} creativeType={props.creativeType} selectedItems={getCreativeData} submitted={submitted} selectedCreativeData={props.selectedCreativeData} creativeStatus={isEdit} selectedLineItem={props.selectedLineItem}/>
              <h6 className="text-muted">JS Tag Setup</h6>
              <hr></hr>

       
              {/* <div className="mb-3">
                <Label >
                3rd party pixel
                </Label>
                <Input
                  type="textarea"
                  id="textarea"
                  rows="3"
                  onChange={handleChange} name="third_party_tag" value={finalData.third_party_tag}   readOnly={props.selectedArchived}
                   placeholder=' 3rd party pixel' className={'form-control'}
                />
              </div> */}
              {/* <Label >
                Header Bidding Demand Options
              </Label>
                <div className="form-check form-switch form-check-success form-switch-md  mt-1  " >
                                                  <input
                                                           type="checkbox"
                                                           className="form-check-input"
                                                           id="customSwitchsizemd"
                                                           checked={googleState}
                                                           onChange={changeTarget3}
                                                           disabled={props.selectedArchived}
                                                  />
                                                  <label className="form-check-label " htmlFor="customSwitchsizemd">
                                                  {googleState ? 'All' : "First Look"}
                                                  </label>
                                                </div> */}
        
              <div className="mb-3">
                <Label >
                Header Bidding Demand Tag
                </Label>
                <Input
                  type="textarea"
                  id="textarea"
                  rows="3"
                  onChange={handleChange} name="HbTag" value={finalData.HbTag}   readOnly={props.selectedArchived}
                   placeholder='Header Bidding Demand Tag' className={'form-control'}
                />
              </div>
              <div className="mb-3">
                <Label >
                  SSP Tag
                </Label>
                <Input
                  type="textarea"
                  id="textarea"
                  rows="3"
                  onChange={handleChange} name="SSPTag" value={finalData.SSPTag}   readOnly={props.selectedArchived}
                   placeholder='SSP Tag' className={'form-control' + (submitted && !finalData.SSPTag ? ' is-invalid' : '')}
                />
                   {submitted && !finalData.SSPTag &&
                                    <div className="invalid-feedback">SSP Tag is required</div>
                                }
              </div>
              <div className="mb-3">
                <Label className="form-label">Creative Weightage</Label>
                <Input
                 type="text" name="creativeWeight" value={finalData.creativeWeight}  onKeyPress={allowOnlyNumbers} onBlur={blurChange}   readOnly={props.selectedArchived}
                  onChange={handleChange} className={'form-control' + (submitted && !finalData.creativeWeight ? ' is-invalid' : '')}
             />
             {submitted && !finalData.creativeWeight &&
                 <div className="invalid-feedback">Creative Weightage is required</div>
             }
              </div>
            </Col>
          </Row>
          <Row className="drawer-footer"  style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">
                {isEdit && !props.selectedArchived &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.creativeTypeClose}
                >
                  Back
                </button>
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
            <Toaster status="error" msg={errorMsg.message}/>
        }
      </div>
        {errorMsg !== null && errorMsg !== '' &&  errorMsg.status && errorMsg.status === 500 && 
            <div className="p-0 justify-content-center d-flex">
            <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')}/>
          </div>
        }
    </React.Fragment>
  )
}

NewThirdParty.propTypes = {
  history: PropTypes.object,
}

export default NewThirdParty