/* IPMAP */
export const LOAD_IPMAPS = "LOAD_IPMAPS"
export const NO_DATA_IPMAPS = "NO_DATA_IPMAPS"
export const GET_IPMAPS = "GET_IPMAPS"
export const GET_IPMAPS_SUCCESS = "GET_IPMAPS_SUCCESS"
export const GET_IPMAPS_FAIL = "GET_IPMAPS_FAIL"

export const ADD_IPMAP = "ADD_IPMAP"
export const ADD_IPMAP_SUCCESS = "ADD_IPMAP_SUCCESS"
export const ADD_IPMAP_ERROR = "ADD_IPMAP_ERROR"

export const UPDATE_IPMAP = "UPDATE_IPMAP"
export const UPDATE_IPMAP_SUCCESS = "UPDATE_IPMAP_SUCCESS"
export const UPDATE_IPMAP_ERROR = "UPDATE_IPMAP_ERROR"

export const READ_IPMAP = "READ_IPMAP"
export const READ_IPMAP_SUCCESS = "READ_IPMAP_SUCCESS"

export const STATUS_IPMAP = "STATUS_IPMAP"
export const STATUS_IPMAP_SUCCESS = "STATUS_IPMAP_SUCCESS"

export const ARCHIVE_IPMAP = "ARCHIVE_IPMAP"
export const ARCHIVE_IPMAP_SUCCESS = "ARCHIVE_IPMAP_SUCCESS"