import React, { useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  BreadcrumbItem
} from "reactstrap";

import classnames from "classnames";
import Partner_Ads_Txt_Entries from "./Partner_Ads_Txt_Entries";
import Ads_Txt_Entries from "./Ads_Txt_Entries";
import Ads_Txt_Management from "./Ads_Txt_Management";

const Ads_TXT_Manage = (props) => {

  const [customActiveTab, setcustomActiveTab] = useState("1");

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  var bodyDoc = document.body;
  if (window.screen.width <= 998) {
    bodyDoc.classList.add("sidebar-enable");
  } else {
    bodyDoc.classList.add("vertical-collpsed");
    bodyDoc.classList.add("sidebar-enable");
  }

  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />

      <div className="main-content access-management"> */}
         {/* <AdminSidebar />
      <div className="main-content access-management"> */}
   
        <div className="page-content">
          <Helmet>
            <title>Ads.Txt Management | Bidsxchange</title>
          </Helmet>
          <Container fluid={true}>
          <h4 className="mb-2 font-size-18 text-uppercase">  <Link to="/Admin"><i className="mdi mdi-arrow-left-circle"></i></Link> &nbsp;&nbsp;&nbsp; Ads.Txt Management</h4>
            <ol className="breadcrumb m-0 mb-2 p-0">
            <div className='d-flex'>
                <BreadcrumbItem>
                  <Link to="/Dashboard" className="color-dark-blue signUp">Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to="/Admin" className="color-dark-blue signUp">Admin Settings</Link>
                </BreadcrumbItem>
              </div>
            </ol>
                <Nav tabs className="nav-tabs-custom nav-justified" style={{ width: '45pc' }}>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleCustom("1");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-home"></i>
                      </span>
                      <span className="d-none d-sm-block" style={{ fontSize: '16px' }}>Ads.txt Queries</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleCustom("2");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-user"></i>
                      </span>
                      <span className="d-none d-sm-block" style={{ fontSize: '16px' }}>ADS.txt Entries</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "3",
                      })}
                      onClick={() => {
                        toggleCustom("3");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-user"></i>
                      </span>
                      <span className="d-none d-sm-block" style={{ fontSize: '16px' }}>Publisher ADS.txt Entries</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent
                  activeTab={customActiveTab}
                  className=" text-muted"
                >
                  <TabPane tabId="1">
                        <Ads_Txt_Management  activeId={customActiveTab}  />
                  </TabPane>
                  <TabPane tabId="2">
                        <Ads_Txt_Entries activeId={customActiveTab} />
                  </TabPane>
                  <TabPane tabId="3">
                        <Partner_Ads_Txt_Entries activeId={customActiveTab} />
                  </TabPane>
                </TabContent>
          </Container>
        </div>
        {/* </div> */}
      {/* </div> */}
    </React.Fragment >
  )
}

export default Ads_TXT_Manage