import {
    LOAD_WBCTG,
    NO_DATA_WBCTG,
    GET_WEBSITE_CATEGORIZATIONS,
    GET_WEBSITE_CATEGORIZATIONS_FAIL,
    GET_WEBSITE_CATEGORIZATIONS_SUCCESS,
    ADD_WEBSITE_CATEGORIZATION,
    ADD_WEBSITE_CATEGORIZATION_SUCCESS,
    ADD_WEBSITE_CATEGORIZATION_ERROR,
    UPDATE_WEBSITE_CATEGORIZATION,
    UPDATE_WEBSITE_CATEGORIZATION_SUCCESS,
    UPDATE_WEBSITE_CATEGORIZATION_ERROR,
    READ_WEBSITE_CATEGORIZATION,
    READ_WEBSITE_CATEGORIZATION_SUCCESS,
    STATUS_WEBSITE_CATEGORIZATION,
    STATUS_WEBSITE_CATEGORIZATION_SUCCESS,
    ARCHIVE_WEBSITE_CATEGORIZATION ,
    ARCHIVE_WEBSITE_CATEGORIZATION_SUCCESS,

    GET_LANGUAGES,
    GET_LANGUAGE_SUCCESS,
    GET_LANGUAGE_FAIL,

  } from "./actionTypes"

  export const loadWcgs = Params => ({
    type: LOAD_WBCTG,
    payload:Params
  })


  export const getWebsiteCategorizations = () => ({
    type: GET_WEBSITE_CATEGORIZATIONS,
  })

  export const noWcgs = Params => ({
    type: NO_DATA_WBCTG,
    payload:Params
  })
  
  export const getWebsiteCategorizationsSuccess = WebsiteCategorizations => ({
    type: GET_WEBSITE_CATEGORIZATIONS_SUCCESS,
    payload: WebsiteCategorizations,
  })

  export const getWebsiteCategorizationsFail = error => ({
    type: GET_WEBSITE_CATEGORIZATIONS_FAIL,
    payload: error,
  })

  export const addNewWebsiteCategorization = WebsiteCategorization => ({
    type: ADD_WEBSITE_CATEGORIZATION,
    payload: WebsiteCategorization,
  })
  
  export const addWebsiteCategorizationSuccess = WebsiteCategorization => ({
    type: ADD_WEBSITE_CATEGORIZATION_SUCCESS,
    payload: WebsiteCategorization,
  })
  
  export const addWebsiteCategorizationFail = WebsiteCategorization => ({
    type: ADD_WEBSITE_CATEGORIZATION_ERROR,
    payload: WebsiteCategorization,
  })

  export const updateWebsiteCategorization = WebsiteCategorization => ({
    type: UPDATE_WEBSITE_CATEGORIZATION,
    payload: WebsiteCategorization,
  })
  
  export const updateWebsiteCategorizationSuccess = WebsiteCategorization => ({
    type: UPDATE_WEBSITE_CATEGORIZATION_SUCCESS,
    payload: WebsiteCategorization,
  })
  
  export const updateWebsiteCategorizationFail = WebsiteCategorization => ({
    type: UPDATE_WEBSITE_CATEGORIZATION_ERROR,
    payload: WebsiteCategorization,
  })

  export const readWebsiteCategorization = WebsiteCategorization => ({
    type: READ_WEBSITE_CATEGORIZATION,
    payload: WebsiteCategorization,
  })

  export const readWebsiteCategorizationSuccess = WebsiteCategorization => ({
    type: READ_WEBSITE_CATEGORIZATION_SUCCESS,
    payload: WebsiteCategorization,
  })

  export const statusWebsiteCategorization = WebsiteCategorization => ({
    type: STATUS_WEBSITE_CATEGORIZATION,
    payload: WebsiteCategorization,
  })

  export const statusWebsiteCategorizationSuccess = WebsiteCategorization => ({
    type: STATUS_WEBSITE_CATEGORIZATION_SUCCESS,
    payload: WebsiteCategorization,
  })

  export const archiveWebsiteCategorization = WebsiteCategorization => ({
    type: ARCHIVE_WEBSITE_CATEGORIZATION,
    payload: WebsiteCategorization,
  })

  export const archiveWebsiteCategorizationSuccess = WebsiteCategorization => ({
    type: ARCHIVE_WEBSITE_CATEGORIZATION_SUCCESS,
    payload: WebsiteCategorization,
  })

  export const getLanguages = () => ({
    type: GET_LANGUAGES,
  })
  
  export const getLanguageSuccess = Languages => ({
    type: GET_LANGUAGE_SUCCESS,
    payload: Languages,
  })

  export const getLanguageFail = error => ({
    type: GET_LANGUAGE_FAIL,
    payload: error,
  })