/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  Row, Col, Input, Label, Form, FormFeedback,
  FormGroup,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Container,
} from "reactstrap";
import Select from "react-select";
// Formik Validation
import moment from "moment";
import axios from "axios";
import queryString from 'query-string';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { Link } from "react-router-dom";
import classnames from "classnames"

// import images
import logodark from "assets/images/favicons.png";

//redux
import { useSelector, useDispatch } from "react-redux";

// actions
import {
  addNewPlayerSetting as onADDPlayerSetting,
  updatePlayerSetting as onUpdatePlayerSetting,
  readPlayerSetting as onReadPlayerSetting,
  addPlayerSettingSuccess,
  addPlayerSettingFail,
  updatePlayerSettingSuccess,
  updatePlayerSettingFail,
} from "store/Supply/PlayerSetting/actions";

import {
  getPartners as onGetPartners,
} from "store/PartnerManagement/actions";

import {
  getPublisher as onGetPublishers,
} from "store/Supply/Publisher/actions";

import {
  getAdUnitGroup as onGetAdUnitGroups,
} from "store/Supply/AdUnitGroup/actions";

import {
  getAdUnit as onGetAdUnits,
} from "store/Supply/AdUnit/actions";

import PlayerContent from './PlayerContent/PlayerContent';
import PlayerControls from './PlayerControls/PlayerControls'
import PlayerGesture from './PlayerGesture/PlayerGesture';
import PlayerAdConfig from './PlayerAdConfig/PlayerAdConfig';
import Toaster from "pages/Toaster/Toaster";
import VideoPlayerControls from "./PlayerControls/VideoPlayerControls";


const Video_Player_Settings = props => {
  const dispatch = useDispatch();
  const [nextTab, setNextTab] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [playerSetting, setPlayerSetting] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [partnerData, setPartnerData] = useState([])
  const [publisherData, setPublisherData] = useState([])
  const [adUnitGroupData, setAdUnitGroupData] = useState([])
  const [adUnitData, setAdUnitData] = useState([])
  const [selectedPublisher, setSelectedPublisher] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedAdUnitGroup, setSelectedAdUnitGroup] = useState(null);
  const [selectedAdUnit, setSelectedAdUnit] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const [activeTab, setactiveTab] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  console.log(props)

  useEffect(() => {
    dispatch(onGetPublishers(null));
    dispatch(onGetAdUnitGroups(null));
    dispatch(onGetAdUnits(null));
  }, [])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 2) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  useEffect(() => {
    if (props.selectedPlayerSetting !== '') {
      setIsEdit(true)
      setPlayerSetting(props.selectedPlayerSetting)
      getParentPlayerSetting()
    }
  }, []);

  const getParentPlayerSetting = () => {
    var filterBody = {
        "partnerID": 0,
        "publisherID": 0,
        "adUnitGroupID": 0,
        "adUnitID": 0,
        "playerSettingID": 0,
        "playerSettingID": 0,
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner
    }
    if (props.selectedPlayerSetting !== '') {
      filterBody.adUnitID = props.selectedPlayerSetting.adUnitID
    }
    axios.post(userInfo.isSupplyPartner ?"/v2.0/supplyPartner/getParent/SupplyPartner" :  "/v2.0/supply/getParent", filterBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("cdsresult is ==>", res)
        if (res.data) {
          setSelectedPublisher({ value: res.data.publisherID, label: res.data.publisherName });
          setSelectedPartner({ value: res.data.partnerID, label: res.data.username });
          setSelectedAdUnitGroup({ value: res.data.adUnitGroupID, label: res.data.adUnitGroupName });
          setSelectedAdUnit({ value: res.data.adUnitID, label: res.data.adUnitName });
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      partnerID: (selectedPartner ? selectedPartner.value : '') || '',
      publisherID: (selectedPublisher ? selectedPublisher.value : '') || '',
      adUnitGroupID: (selectedAdUnitGroup ? selectedAdUnitGroup.value : '') || '',
      adUnitID: (selectedAdUnit ? selectedAdUnit.value : '') || '',
      playerSettingName: (playerSetting && playerSetting.playerSettingName) || '',
      playerSettingData: {},
    },
    validationSchema: Yup.object({
      playerSettingName: Yup.string().required("PlayerSetting Name is required"),
      partnerID: Yup.string().required("Partner is required"),
      publisherID: Yup.string().required("Publisher is required"),
    }),
    onSubmit: (values) => {
      if (props.selectedPlayerSetting !== '') {
        dispatch(onUpdatePlayerSetting({ ...values, playerSettingID: props.selectedPlayerSetting.playerSettingID }));
      } else {
        dispatch(onADDPlayerSetting(values));
      }
    }
  });

  const parsed = queryString.parse(window.location.search);

  const [finalData, setFinalData] = useState({
    playerSettingName: '',
    desktopPlayerContents: [],
    mobilePlayerContents: [],
    desktopPlayerControls: {
      closeButton:true,
      closeButtonPosition: "outer-right",
      brandIdentity: true,
      brandIdentityPosition:'center',
      logo:true,
      logoPosition: 'left',
      logoURL: "https://nino.bidsxchange.com/static/media/favicons.ca93cc9b.png",
      logoClickThroughURL: "https://streamlyn.com/products-seller",
      vastTag:''
    },
    mobilePlayerControls: {},
    desktopPlayerGestures: {},
    mobilePlayerGestures: {},
    desktopAdConfig: {},
    mobileAdConfig: {},
    adUnitID: '',
    partnerID: '',
    publisherID: '',
    adUnitGroupID: ''
  })
  const [newSettings, setNewSettings] = useState(true)
  const linkRef = useRef();

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const body = {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
  }

  const getContentData = async (selectedData) => {
    console.log(selectedData)
    setFinalData({ ...finalData, desktopPlayerContents: selectedData, mobilePlayerContents: selectedData })
    console.log(finalData)
  }

  const getControlslData = async (selectedData) => {
    console.log(selectedData)
    setFinalData({ ...finalData, desktopPlayerControls: ({ ...finalData.desktopPlayerControls, ...selectedData }), mobilePlayerControls: ({ ...finalData.mobilePlayerControls, ...selectedData }) })
    console.log(finalData)
  }

  const getGestureData = async (selectedData) => {
    console.log(selectedData)
    setFinalData({ ...finalData, desktopPlayerGestures: ({ ...finalData.desktopPlayerGestures, ...selectedData }), mobilePlayerGestures: ({ ...finalData.mobilePlayerGestures, ...selectedData }) })
    console.log(finalData)
  }

  const getAdConfigData = async (selectedData) => {
    console.log(selectedData)
    setFinalData({ ...finalData, desktopAdConfig: ({ ...finalData.desktopAdConfig, ...selectedData }), mobileAdConfig: ({ ...finalData.mobileAdConfig, ...selectedData }) })
    console.log(finalData)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFinalData(finalData => ({ ...finalData, [name]: value }));
  }

  useEffect(async () => {
    if (parsed.settingsId) {

      await axios.post("/v2.0/supply/PlayerSettings/read/" + props.settingsId, body,
        {
          headers: headers
        })
        .then((res) => {
          console.log("result is ==>", res)
          if (res.data) {
            console.log(res.data)
            settingsDatas = { ...finalData, ...res.data.response }
            setFinalData({ ...finalData, ...res.data.response })
            setNewSettings(false)
          }
        })
        .catch((err) => {
          console.log("e", err)
        });
    }

    if (parsed.adUnitID) {
      setFinalData({ ...finalData, adUnitID: parsed.adUnitID });
      // setParentAdUnitPresent(true)
    }

  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    console.log("submit click", finalData)
    if (finalData.playerSettingName && finalData.adUnitID) {
      if (props.selectedPlayerSetting !== '') {
        dispatch(onUpdatePlayerSetting({ ...finalData }))
      } else {
        dispatch(onADDPlayerSetting({ ...finalData }))
      }
    }
  }

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.playerSettings.errorMsg,
    successMsg: state.playerSettings.successMsg,
  }));

  console.log(errorMsg, successMsg)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.success) {
        dispatch(addPlayerSettingSuccess(""));
        dispatch(addPlayerSettingFail(""));
        dispatch(updatePlayerSettingSuccess(""));
        dispatch(updatePlayerSettingFail(""));
        if (props.selectedPlayerSetting !== '') {
          props.closeCanvas('Update')
        } else {
          props.closeCanvas('Create')
        }
      }
    }
  }, [successMsg]);

  const { partners, publishers, adUnitGroups, adUnits } = useSelector(state => ({
    partners: state.partners.partners,
    publishers: state.publishers.publisherList,
    adUnitGroups: state.adUnitGroups.adUnitGroupsList,
    adUnits: state.adUnits.adUnitList,
  }));

  console.log(partners, publishers, adUnitGroups, adUnits)

  // useEffect(() => {
  //   if (partners !== null ) {
  //     dispatch(onGetPartners());
  //   }
  // }, []);

  //  useEffect(() => {
  //   if (publishers !== null && adUnitGroups !== null && adUnits !== null ) {
  //     dispatch(onGetPublishers({partnerID:'',filter:'NonArchived'}));
  //     dispatch(onGetAdUnitGroups({publisherID:'',filter:'NonArchived',adUnitGroupType:''}));
  //     dispatch(onGetAdUnits({ adUnitGroupID: '', filter: 'NonArchived', adUnitType:'video', adUnitSize: '' }));
  //   }
  // }, []);

  useEffect(() => {
    if (partners && partners.length > 0) {
      setPartnerData(objectModal(partners))
      console.log(partners)
    }
  }, [partners]);

  useEffect(() => {
    if (publishers && publishers.length > 0) {
      setPublisherData(objectPublisherModal(publishers))
      console.log(publishers)
    }
  }, [publishers]);

  useEffect(() => {
    if (adUnitGroups && adUnitGroups.length > 0) {
      setAdUnitGroupData(objectAdUnitGroupModal(adUnitGroups))
      console.log(adUnitGroups)
    }
  }, [adUnitGroups]);

  useEffect(() => {
    if (adUnits && adUnits.length > 0) {
      setAdUnitData(objectAdUnitModal(adUnits))
      console.log(adUnits)
    }
  }, [adUnits]);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.partnerID
      obj.label = item.username
      dataList.push(obj)
    })
    return dataList
  }

  const objectPublisherModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.publisherID
      obj.label = item.publisherName
      dataList.push(obj)
    })
    return dataList
  }

  const objectAdUnitGroupModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.adUnitGroupID
      obj.label = item.adUnitGroupName
      dataList.push(obj)
    })
    return dataList
  }

  const objectAdUnitModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.adUnitID
      obj.label = item.adUnitName
      dataList.push(obj)
    })
    return dataList
  }

  function handleSelectPartner(selectPartner) {
    setFinalData(finalData => ({ ...finalData, partnerID: selectPartner.value }));
    setSelectedPartner(selectPartner);
    dispatch(onGetPublishers({ partnerID: selectPartner.value, filter: 'NonArchived' }));
    setSelectedPublisher(null)
  }

  function handleSelectPublisher(selectPublisher) {
    setFinalData(finalData => ({ ...finalData, publisherID: selectPublisher.value }));
    setSelectedPublisher(selectPublisher);
    dispatch(onGetAdUnitGroups({ publisherID: selectPublisher.value, filter: 'NonArchived', adUnitGroupType: '' }));
    setSelectedAdUnitGroup(null)
  }

  function handleSelectAdUnitGroup(selectAdUnitGroup) {
    setFinalData(finalData => ({ ...finalData, adUnitGroupID: selectAdUnitGroup.value }));
    setSelectedAdUnitGroup(selectAdUnitGroup);
    dispatch(onGetAdUnits({ adUnitGroupID: selectAdUnitGroup.value, filter: 'NonArchived', adUnitType: 'video', adUnitSize: '' }));
    setSelectedAdUnit(null)
  }

  function handleSelectAdUnit(selectAdUnit) {
    setFinalData(finalData => ({ ...finalData, adUnitID: selectAdUnit.value }));
    setSelectedAdUnit(selectAdUnit);
  }

  return (
    <React.Fragment>
      <div className="col-12">
        <Form onSubmit={handleSubmit}>
          <div className="row" >
            <div className="col-12 formBodyScroll" style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
              <Row >
                <Col lg="12" className="p-0">
                <h4 className="heading3">Current Settings</h4>
                  {/* <Card>
                    <CardBody className="p-0"> */}
                    {/* <PlayerControls selectedItems={getControlslData} settingsId={parsed.settingsId} settingsData={finalData} /> */}
                    <VideoPlayerControls selectedItems={getControlslData} settingsId={parsed.settingsId} settingsData={finalData} />
                      {/* <h4 className="card-title mb-4">Settings</h4>
                      <div className=" wizard clearfix vertical">
                        <div className="steps clearfix">
                          <ul>
                            <NavItem
                              className={classnames({ current: activeTab === 1 })}
                            >
                              <NavLink
                                className={classnames({ current: activeTab === 1 })}
                                onClick={() => {
                                  setactiveTab(1)
                                }}
                              // disabled={!(passedSteps || []).includes(1)}
                              >
                                <span className="number">1.</span>Player Controls
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({ current: activeTab === 2 })}
                            >
                              <NavLink
                                className={classnames({ active: activeTab === 2 })}
                                onClick={() => {
                                  setactiveTab(2)
                                }}
                              // disabled={!(passedSteps || []).includes(3)}
                              >
                                <span className="number">02</span> Player Gesture
                              </NavLink>
                            </NavItem>

                          </ul>
                        </div>
                        <div className="content clearfix ">
                          <TabContent activeTab={activeTab} className="body">
                            <TabPane tabId={1}>
                              <PlayerControls selectedItems={getControlslData} settingsId={parsed.settingsId} settingsData={finalData} />
                            </TabPane>
                            <TabPane tabId={2}>
                              <div>
                                <PlayerGesture selectedItems={getGestureData} settingsId={parsed.settingsId} settingsData={finalData} />
                              </div>
                            </TabPane>
                          </TabContent>
                        </div>
                        <div className="actions clearfix">
                          <ul>
                            <li
                              className={
                                activeTab === 1 ? "previous disabled" : "previous"
                              }
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTab(activeTab - 1)
                                }}
                              >
                                Previous
                              </Link>
                            </li>
                            <li
                              className={activeTab === 2 ? "next disabled" : "next"}
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTab(activeTab + 1)
                                }}
                              >
                                Next
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                    {/* </CardBody>
                  </Card> */}
                </Col>
              </Row>
            </div>
            <div className="col-12">
            <h4 className="heading3 text-center mt-4">Video Preview</h4>
              <div id="page-content">
                <div id="video-container">
                  <div className="player-closeButton" style={{position:'absolute',display:finalData.desktopPlayerControls.closeButton ? 'block' : 'none',
                  right:  ( finalData.desktopPlayerControls.closeButtonPosition === 'outer-right' || finalData.desktopPlayerControls.closeButtonPosition === 'inner-right') ? 0 : 'unset' ,
                  top: ( finalData.desktopPlayerControls.closeButtonPosition === 'inner-left' || finalData.desktopPlayerControls.closeButtonPosition === 'inner-right') ? '1.2pc' : 'unset'}}>
                     <i className="far fa-window-close " style={{fontSize:'1.2vw'}} />
                  </div>
                  <div className="player-identity text-center" style={{display:finalData.desktopPlayerControls.brandIdentity ? 'block' : 'none'}}>
                          <span className="brand-identity" style={{
                        borderLeft: finalData.desktopPlayerControls.brandIdentityPosition === 'center' ? '50px solid transparent' : finalData.desktopPlayerControls.brandIdentityPosition === 'left' ? 0 : '50px solid transparent',
                        borderRight: finalData.desktopPlayerControls.brandIdentityPosition === 'center' ? '50px solid transparent' : finalData.desktopPlayerControls.brandIdentityPosition === 'right' ? 0 : '50px solid transparent',
                        display:finalData.desktopPlayerControls.brandIdentityPosition === 'center' ? 'inline-Block' : finalData.desktopPlayerControls.brandIdentityPosition === 'left' ? 'flex' : 'flex',
                        marginLeft:finalData.desktopPlayerControls.brandIdentityPosition === 'right' ? 'auto' : 'unset' }}>-ADS BY BIDSXCHANGE-</span>
                  </div>
                  <div className="player-logo" style={{position:'absolute',display:finalData.desktopPlayerControls.logo ? 'block' : 'none',right:finalData.desktopPlayerControls.logoPosition === 'right' ? 0 : 'unset' ,
                  margin:'1vw',zIndex:'200'}}>
                    <Link to={{ pathname: finalData.desktopPlayerControls.logoClickThroughURL }} target='_blank'   rel='noopener noreferrer' >
                    <img
                        src={finalData.desktopPlayerControls.logoURL}
                        alt=""
                        height="30"
                        className="auth-logo-dark"
                      /></Link>
             
                  </div>
                  <video id="video-player" controls>
                    <source src="https://storage.bidsxchange.com/exclusive/arts/video/en/en-different-forms-of-drawing.mp4"
                      type="video/mp4" />
                  </video>
                  <div id="ad-container"></div>
                </div>
              </div>
            </div>
          </div>

          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
                &nbsp;&nbsp;
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>}
                &nbsp;&nbsp;
                {isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }

              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' &&
          <Toaster status="error" msg={errorMsg} />
        }
      </div>
    </React.Fragment>
  )
}

Video_Player_Settings.propTypes = {}

export default Video_Player_Settings