/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, Spinner,Button } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import Creative_Selection from "./Creative_Selection";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

// actions
import {
    addNewVideoCreative as onADDVideoCreative,
    updateVideoCreative as onUpdateVideoCreative,
    readVideoCreative as onReadVideoCreative,
    addVideoCreativeSuccess,
    addVideoCreativeFail,
    updateVideoCreativeSuccess,
    updateVideoCreativeFail,
} from "store/Demand/Creative/actions";
import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";

import { getPlaylist as onGetPlaylist } from 'store/Media/Playlist/actions'
import moment from "moment";

var videoPlaylist = []
// var categoriesList = []
// var languageList = []
var selectedPlaylists = []

var videoContentList = []

var addedContents = []

const NewAssetVideoCreative = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const dispatch = useDispatch();
    const [isEdit, setIsEdit] = useState(false);
    const [loader, setLoader] = useState(false);
    console.log(props)

    const [finalData, setFinalData] = useState({
        videoCreativeName: '',
        "bannerAdTag": "",
        videoCreativeContent: {
            type: 'directUrl',
            list: []
        },
        advertiserID: '',
        ioGroupID: '',
        ioID: '',
        lineItemID: '',
        "typeFor": "VideoAsset",
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID
    })

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 1000,
        extendedTimeOut: 1000
    };


    const [submitted, setSubmitted] = useState(false);
    const [googleState, setGoogleState] = useState(true);

    const { errorMsg, successMsg, creativeData } = useSelector(state => ({
        errorMsg: state.creatives.errorMsg,
        successMsg: state.creatives.successMsg,
        creativeData: state.creatives.creative
    }));

    console.log(errorMsg, successMsg, creativeData)

    useEffect(() => {
        if (successMsg !== null) {
            if (successMsg.statusCode === 200) {
                dispatch(addVideoCreativeSuccess(""));
                dispatch(addVideoCreativeFail(null));
                dispatch(updateVideoCreativeSuccess(""));
                dispatch(updateVideoCreativeFail(null));
                setLoader(false)
                if (props.selectedCreativeData !== '' && props.selectedcreativeCreateStatus !== 'Duplicate') {
                    props.creativeNewClose('Update')
                } else {
                    props.creativeNewClose('Create')
                }
            }
        }
    }, [successMsg]);

    useEffect(() => {
        console.log(errorMsg)
        if (errorMsg !== null && errorMsg !== '') {
            setLoader(false)
            if (errorMsg.status && errorMsg.status === 500) {
            } else {
                dispatch(addVideoCreativeSuccess(""));
                dispatch(addVideoCreativeFail(null));
                dispatch(updateVideoCreativeSuccess(""));
                dispatch(updateVideoCreativeFail(null));
            }
        }
    }, [errorMsg]);

    const toggleToast = (n) => (e) => {
        dispatch(addVideoCreativeSuccess(""));
        dispatch(addVideoCreativeFail(null));
        dispatch(updateVideoCreativeSuccess(""));
        dispatch(updateVideoCreativeFail(null));
        props.creativeNewClose('')
    };

    //   const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFinalData(creative => ({ ...creative, [name]: value }));
    //   }

    const getCreativeData = async (selectedCreativeData) => {
        console.log(selectedCreativeData)
        setFinalData({ ...finalData, ...selectedCreativeData })
        console.log(finalData)
    }

    useEffect(() => {
        console.log(props.selectedCreativeData)
        if (props.selectedCreativeData !== '') {
            dispatch(onReadVideoCreative({ ...props.selectedCreativeData,userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner }));
            if(props.selectedcreativeCreateStatus !== 'Duplicate'){
                setIsEdit(true)
              }
        }
        if (props.selectedLineItem !== '') {
            setFinalData({ ...finalData, lineItemID: props.selectedLineItem.lineItemID })
        }
    }, [])

    useEffect(() => {
        if (creativeData !== null && props.selectedCreativeData !== '') {
            console.log(creativeData)
            if(JSON.stringify(creativeData) !== "{}"){
                if (props.selectedcreativeCreateStatus === 'Duplicate') {
                    delete creativeData.videoCreativeCreateTime
                    delete creativeData.videoCreativeID
                    delete creativeData.videoCreativeModifyTime
                    delete creativeData.videoCreativeStatus
                    delete creativeData.isVideoCreativeArchived
                   }
                setFinalData(finalData => ({ ...finalData, ...creativeData }));
                if (creativeData.videoCreativeContent.type === 'directUrl') {
                    setUrlsState(false)
                } else {
                    setUrlsState(true)
                }
    
                if (creativeData.videoCreativeContent.list.length > 0 && creativeData.videoCreativeContent.type === 'directUrl') {
                    creativeData.videoCreativeContent.list.map((item, index) => {
                        item.id = index + 1
                        item.selectManually = false
                        item.selectUpload = true
                    })
                }
                videoContentList = creativeData.videoCreativeContent.list
                console.log(videoContentList)
                setVideosList([...videoContentList])
            }

        }
    }, [creativeData]);

    useEffect(() => {
        if (videoContentList) {
            if (videoContentList.length < 1) {
                addDirectVideoContent()
            }
        }
    }, [])


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(finalData)
        setSubmitted(true);
        if(finalData.videoCreativeContent.list.length === 0){
            toastr.error("Please Add atleast one Video Content")
        }else{
            if(finalData.videoCreativeContent.type === 'directUrl' && finalData.videoCreativeContent.list.length > 0 ){
                finalData.videoCreativeContent.list.map((item, index) => {
                 delete item.id
                 delete item.selectManually
                 delete item.selectUpload
                })
              }
            if (finalData.videoCreativeName && finalData.lineItemID) {
                setLoader(true)
                if (props.selectedCreativeData !== '' && props.selectedcreativeCreateStatus !== 'Duplicate') {
                    dispatch(onUpdateVideoCreative({ ...finalData, videoCreativeName: finalData.videoCreativeName.trim(),userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner }));
                } else {
                    dispatch(onADDVideoCreative({ ...finalData, videoCreativeName: finalData.videoCreativeName.trim(),userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner }));
                }
            }
        }
    }

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
        }
    }

    const [contentList, setContentList] = useState([...videoPlaylist])
    const [finalContentList, setFinalContentList] = useState([...videoPlaylist])
    const [playlistData, setPlaylistsData] = useState([])

    const [videosList, setVideosList] = useState([])
    const [urlsState, setUrlsState] = useState(false)

    const [videoLoaded, setVideoLoaded] = useState(false);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    const [addPlaylistCanvas, setAddPlaylistCanvas] = useState(false)

    const handleVideoLoadedData = () => {
        setVideoLoaded(true);
    }

    const urlStateChange = e => {
        setUrlsState(e.target.checked)
        if (e.target.checked) {
            setFinalData(finalData => ({ ...finalData, videoCreativeContent: { ...finalData.videoCreativeContent, type: 'videoContentSuite' } }));
        } else {
            setFinalData(finalData => ({ ...finalData, videoCreativeContent: { ...finalData.videoCreativeContent, type: 'directUrl' } }));
        }
    }



    const handleVideoTimeUpdate = (index) => {
        console.log("called handle video time update")
        const video = videoRef.current;
        const canvas = canvasRef.current;
        console.log(canvas)
        if (canvas !== null) {
            canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
            const thumbnailUrl = canvas.toDataURL();
            canvas.toBlob((blob) => {
            }, 'image/png', 0.8);
            if (thumbnailUrl.includes("data:image/png;base64,")) {
                var captureUrl = thumbnailUrl.replace("data:image/png;base64,", "");
            }
            videoContentList[index] = { ...videoContentList[index], url: captureUrl }
            setVideosList([...videoContentList])
        }

    }

    const uploadSelectedFrame = async (blob, index) => {
        const rawFileName = videoContentList[index].videoURL.match(/(?<=\/)(\w|-)+(?:\.\w+)?(?=$|\?)/)[0];
        const alteredfileName = rawFileName.replace(".mp4", '')
        const fileName = `${alteredfileName}${index}`
        console.log(fileName)
        const toBase64 = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);

        });
        const newBaseContent = {
            data: await toBase64(blob),
            fileName: fileName,
            type: 'image/png',
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID
        }

        axios.post("/upload/uploadFile", newBaseContent, {
            headers: headers
        })
            .then((res) => {
                console.log("Thumbnail Upload ==>", res)

                if (res.data.success) {
                    // setLoader(false)
                    videoContentList[index] = { ...videoContentList[index], url: `/home/ubuntu/videoThumbnailData/${fileName}` }
                    setVideosList([...videoContentList])
                    // toastr.success("Frame uploaded successfully")
                } else if (!res.data.success) {
                    setLoader(false)
                    console.log(err)
                }

            })
            .catch((err) => {
                console.log("e", err)
            });
    }

    const [customActiveTab, setcustomActiveTab] = useState("1");

    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const addNewVideoContent = () => {
        videoPlaylist.push({
            id: videoPlaylist.length + 1,
            playListName: '',
            playListVideoData: [],
        })
        setContentList([...videoPlaylist])
        console.log(videoPlaylist)
    }

    const addDirectVideoContent = () => {
        if (videosList.length === 5) {
        } else {
            videoContentList.push({
                id: videoContentList.length + 1,
                videoName: '',
                videoURL: '',
                clickThroughURL: '',
                videoThumbnailURL: '',
                url: '',
                selectManually: false,
                selectUpload: true
            })
            setVideosList([...videoContentList])
            console.log(videosList)
        }
    }

    const handleChange = (index) => e => {
        const { name, value } = e.target;
        console.log(name, value)
        console.log(playlistData)
        // if (name === 'thumbnail') {
        //     videoPlaylist[index] = { ...videoPlaylist[index], thumbnailImg: value };
        //     setContentList([...videoPlaylist])
        // }
        // if (name === 'videoUrl') {
        //     getDuration(value, index)
        // }
        if (name === 'playListName') {
            let i = playlistData.findIndex(x => x.playListName === value)
            console.log(playlistData[i].playListVideoData)
            videoPlaylist[index] = { ...videoPlaylist[index], playListVideoData: playlistData[i].playListVideoData };
            videoPlaylist[index] = { ...videoPlaylist[index], [name]: value };
            setContentList([...videoPlaylist])
            selectedPlaylists.push(playlistData[i].playListID)
        }
        videoPlaylist.map((item, index) => {
            let obj = {}
            obj.playListName = item.playListName
            obj.Language = item.language
            obj.Category = item.category
            obj.Name = item.videoName
            obj.URL = item.videoUrl
            obj.Thumbnail = item.thumbnailImg
            setFinalContentList(obj)
        })
        setFinalData(finalData => ({ ...finalData, videoCreativeContent: { ...finalData.videoCreativeContent, list: selectedPlaylists } }));
        console.log(finalContentList)
    }

    const { playlists } = useSelector(state => ({
        playlists: state.playlistsReducer.playlist
    }));

    useEffect(() => {
        if (playlists !== null) {
            dispatch(onGetPlaylist({ filter: '' }));
        }
    }, [addPlaylistCanvas]);

    console.log(playlists)

    useEffect(() => {
        if (playlists !== null && playlists.length > 0) {
            setPlaylistsData(playlists)
            if (creativeData !== null && props.selectedCreativeData !== '') {
                if(JSON.stringify(creativeData) !== "{}"){
                    if(creativeData.videoCreativeContent.list.length > 0 && creativeData.videoCreativeContent.type === 'videoContentSuite' ) {
                        let lists = []
                        creativeData.videoCreativeContent.list.map((item, index) => {
                            let i = playlists.findIndex(x => x.playListID === item)
                            lists.push(playlists[i])
                            setContentList([...lists])
                            selectedPlaylists.push(item)
                        })
                    }
                }
            }
        }
    }, [playlists]);

    const removeContent = (i) => e => {
        console.log(i)
        videoPlaylist.splice(i, 1)
        console.log(videoPlaylist)
        setContentList([...videoPlaylist])
        setFinalData(finalData => ({ ...finalData, videoCreativeContent: { ...finalData.videoCreativeContent, list: videoPlaylist } }));
    }

    const videoListCoulumns = [
        {
            text: "ID",
 headerClasses:"ID",
            dataField: "id",
            hidden: true,
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Video Thumbnail",
            align: 'center',
            formatter: (cellContent, eachRow) => (
                <img src={eachRow.videoContentThumbnailURL} alt="thumbnail" style={{ height: "3.5rem", width: "6 rem" }} />
            )
        },
        {
            text: 'Video Name',
            dataField: 'videoContentName'
        },
        {
            text: 'Category',
            dataField: 'videoContentCategory'
        },
        {
            text: 'Language',
            dataField: 'videoContentLanguage'
        },
        {
            text: 'Duration',
            dataField: 'videoContentDuration',
            formatter: (cellContent, eachRow) => (
                <span className="mb-2">{moment.unix(eachRow.videoContentDuration).utc().format(' m [min] s [secs]')} </span>
            )
        }
    ]

    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "asc", // desc or asc
        },
    ];

    const keyField = "id";

    console.log(contentList)
    console.log(videosList)


    const createUI = () => {
        return contentList.map((item, i) =>
            <div key={i}>
                <div className="contentSettings">
                    <h5 className='mt-3 mb-4'>Content {i + 1}</h5>
                    <div className="row mt-1">
                        <div className="col-10">
                            <div className="form-group">
                                <Label className="form-label">Select Playlists</Label>
                                <Input
                                    type="select"
                                    className="form-select"
                                    value={item.playListName}
                                    onChange={handleChange(i)}
                                    name="playListName"
                                >

                                    <option value="" disabled >
                                        Select Playlists
                                    </option>
                                    {playlistData.length > 0 && playlistData.map((item) => (
                                        <option
                                            key={item.playListName}
                                            value={item.playListName}
                                        >
                                            {item.playListName}
                                        </option>
                                    ))}
                                </Input>
                            </div>
                            <br />

                            {item.playListName !== '' &&
                                <Row>
                                    <Col lg="12">

                                        <React.Fragment>
                                            <Row>
                                                <Col xl="12">
                                                    <div className="table-responsive">
                                                        <BootstrapTable
                                                            keyField={keyField}
                                                            data={item.playListVideoData}
                                                            columns={videoListCoulumns}
                                                            noDataIndication={<h5 className="text-center">No Video Data</h5>}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    </Col>
                                </Row>
                            }
                        </div>
                        <div className="col-2 p-0">
                            <button type='button' className='btn btn-danger  ' style={{ position: 'absolute', background: '#dc3545', right: '0' }} onClick={removeContent(i)} disabled={videoPlaylist.length === 1}
                            >Remove Content</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    console.log(videosList)

    const handleDirectChange = (index, videoData) => e => {
        const { name, value } = e.target;
        console.log(name, value)
        if (name === 'videoContentName') {
            videoContentList[index] = { ...videoContentList[index], videoName: value };
            console.log(videoContentList)
            setVideosList([...videoContentList])
        }
        else if (name === 'videoURL') {
            videoContentList[index] = { ...videoContentList[index], videoURL: value };
            console.log(videoContentList)
            setVideosList([...videoContentList])
        }
        else if (name === 'clickThroughURL') {
            videoContentList[index] = { ...videoContentList[index], clickThroughURL: value };
            console.log(videoContentList)
            setVideosList([...videoContentList])
        }
        else if (name === 'videoThumbnailURL') {
            videoContentList[index] = { ...videoContentList[index], videoThumbnailURL: value };
            console.log(videoContentList)
            setVideosList([...videoContentList])
        }
        else if (name === `selectUpload-${index}`) {
            videoContentList[index] = { ...videoContentList[index], selectUpload: e.target.checked, selectManually: !e.target.checked };
            console.log(videoContentList)
            setVideosList([...videoContentList])
        }
        else if (name === `selectManually-${index}`) {
            videoContentList[index] = { ...videoContentList[index], selectManually: e.target.checked, selectUpload: !e.target.checked };
            console.log(videoContentList)
            setVideosList([...videoContentList])
        }

        console.log(videoData)
        console.log(videosList)
        setFinalData(finalData => ({ ...finalData, videoCreativeContent: { ...finalData.videoCreativeContent, list: videoContentList } }));
        //   props.selectedItems({ list: videoContentList })
        // if (name === 'videoUrl') {
        //     getDuration(value, index)
        // }
    }

    const fileChangeThumbnail = (index) => (file) => {
        console.log("called file upload in player settings", file, index)
        encodeFileBase64(file.target.files[0], index);
    }

    const encodeFileBase64 = (file, index) => {
        var reader = new FileReader();
        console.log(file, index)
        if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
                var base64 = reader.result
                if (base64.includes("data:image/png;base64,")) {
                    base64 = reader.result.replace("data:image/png;base64,", "");
                }
                else if (base64.includes("data:image/jpeg;base64,")) {
                    base64 = reader.result.replace("data:image/jpeg;base64,", "");
                } else if (base64.includes("data:image/gif;base64,")) {
                    base64 = reader.result.replace("data:image/gif;base64,", "");
                }
                console.log(base64)
                if (base64) {
                    videoContentList[index] = { ...videoContentList[index], url: base64 };
                    setVideosList([...videoContentList])
                    setFinalData(finalData => ({ ...finalData, videoCreativeContent: { ...finalData.videoCreativeContent, list: videoContentList } }));
                    //   props.selectedItems({ list: videoContentList })
                    console.log(videoContentList)
                }
            }
            reader.onerror = (error) => {
                console.log("error: ", error)
            };
        }
    }



    const removeDirectContent = (i) => e => {
        videoContentList.splice(i, 1)
        setVideosList([...videoContentList])
        setFinalData(finalData => ({ ...finalData, videoCreativeContent: { ...finalData.videoCreativeContent, list: videoContentList } }));
        //   props.selectedItems({ list: videoContentList })
    }

    const createContentUI = () => {
        return videosList.map((item, i) =>
            <div key={i}>
                <div className="contentSettings">
                    <h5 className='mt-3 mb-4'>Content {i + 1}</h5>
                    <div className="row mt-1">
                        <div className="col-10">
                            <div className="mb-2">
                                <Label className="form-label">Video Name</Label>
                                <Input
                                    name="videoContentName"
                                    type="text"
                                    onChange={handleDirectChange(i)}
                                    value={item.videoName}
                                    className={'form-control'}
                                />
                            </div>
                            <br />
                            <div className="mb-2">
                                <Label className="form-label">Video URL</Label>
                                <Input
                                    name="videoURL"
                                    type="text"
                                    onChange={handleDirectChange(i)}
                                    value={item.videoURL}
                                    className={'form-control'}
                                />
                            </div>
                            <div className="mb-2">
                                <Label className="form-label">Video Thumbnail URL</Label>
                                <Input
                                    name="videoThumbnailURL"
                                    type="text"
                                    onChange={handleDirectChange(i)}
                                    value={item.videoThumbnailURL}
                                    className={'form-control'}
                                />
                            </div>
                            <br />
                            {item.videoThumbnailURL === '' &&
                                <div className="mb-2">
                                    <Label className="form-label">Select Thumbnail</Label>
                                    <div className='row'>
                                        <Col md={3}>
                                            <div className="d-flex justify-content-start align-items-center form-check form-switch form-check-success form-switch-md" style={{ paddingLeft: "1rem" }}>
                                                <input
                                                    className="form-check-input m-1"
                                                    type="checkbox"
                                                    name={`selectUpload-${i}`}
                                                    id={`fileUpload${i}`}
                                                    checked={item.selectUpload}
                                                    onChange={handleDirectChange(i)}
                                                />
                                                <label className="form-check-label  m-2 mt-1 m-1" htmlFor={`fileUpload${i}`}> File Upload</label>

                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div className='d-flex justify-content-start align-items-center form-check form-switch form-check-success form-switch-md' style={{ paddingLeft: "0rem" }}>
                                                <input
                                                    className="form-check-input m-1"
                                                    type="checkbox"
                                                    name={`selectManually-${i}`}
                                                    id={`fileManually${i}`}
                                                    checked={item.selectManually}
                                                    onChange={handleDirectChange(i)}
                                                />
                                                <label className="form-check-label  m-2 mt-1 m-1" htmlFor={`fileManually${i}`}> Select Manually  </label>
                                            </div>
                                        </Col>

                                    </div>
                                    {item.selectManually &&
                                        <Col sm="12">
                                            <p style={{ fontWeight: "500" }}>Choose Your Thumbnail: </p>
                                            <div className='d-flex justify-content-around align-items-start'>
                                                <div className='d-flex flex-column justify-content-start justify-conent-center'>


                                                    <video className='m-2' ref={videoRef} src={item.videoURL} controls width={400} height={270} crossOrigin="anonymous" onLoadedData={handleVideoLoadedData}>
                                                        {/* <source  type="video/mp4" /> */}
                                                    </video>
                                                    <Button className=' text-right m-3' onClick={() => handleVideoTimeUpdate(i)} disabled={!videoLoaded}>capture</Button>
                                                </div>
                                                <canvas ref={canvasRef} width={400} height={270} className="d-none" />
                                                {item.url ? <img className='single-frame m-1 ' src={item.url} crossOrigin="anonymous" alt="Video Thumbnail" /> : <div className='single-frame d-flex justify-content-center align-items-center' style={{ backgroundColor: "gray" }}> <h6>Capture dynamically which frame you want</h6></div>}
                                                {/* <Button className='m-3' onClick={uploadSelectedFrame} >Save Frame</Button> */}
                                                {/* {thumbnailUrl && <img className='single-frame' src={thumbnailUrl} crossOrigin="anonymous" alt="Video Thumbnail" />} */}

                                            </div>
                                        </Col>
                                    }
                                    {item.selectUpload &&
                                        <Col sm="12">
                                            <div className="mb-3">
                                                <Label className="form-label">Browse Thumbnail Image File</Label>
                                                <div>
                                                    <Input type="file" accept="image/x-png,image/jpg" onChange={fileChangeThumbnail(i)} />
                                                    <span className="notes">Note: Allowed file types: jpg, png. Max-size 5Mb</span>
                                                </div>
                                                {/* {submitted && !videoContent.videoContentThumbnailPath ? (
                 <FormFeedback type="invalid">{'VideoContent Name is required'}</FormFeedback>
             ) : null} */}
                                            </div>

                                        </Col>
                                    }

                                </div>
                            }

                            <br />
                            <div className="mb-2">
                                <Label className="form-label">Click Through URL</Label>
                                <Input
                                    name="clickThroughURL"
                                    type="text"
                                    onChange={handleDirectChange(i)}
                                    value={item.clickThroughURL}
                                    className={'form-control'}
                                />
                            </div>
                            <br />
                        </div>
                        <div className="col-2 p-0">
                            <button type='button' className='btn btn-danger  ' style={{ position: 'absolute', background: '#dc3545', right: '0' }} onClick={removeDirectContent(i)} disabled={videoContentList.length === 1}
                            >Remove Content</button>
                        </div>
                    </div>
                </div>
            </div>

        )

    }

    return (
        <React.Fragment>
            {loader &&
                <Spinner className="ms-2 loader" color="primary" />
            }
            <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
                <Form onSubmit={handleSubmit}>
                    <Row className="formBodyScroll" style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
                        <Col xs={6}>
                            <h6 className="text-muted">Asset Video Creative Setup</h6>
                            <hr></hr>
                            <Creative_Selection selectedArchived={props.selectedArchived} creativeType={props.creativeType} selectedItems={getCreativeData} submitted={submitted} selectedCreativeData={props.selectedCreativeData} creativeStatus={isEdit} selectedLineItem={props.selectedLineItem} />
                        </Col>
                        <h6 className="text-muted">Video Setup</h6>
                        <hr></hr>
                        <div className="mb-3">
                            <Col sm="12">
                                <div className="player-content player-content2">
                                    <div className="container-fluid d-flex">
                                        <label className="form-check-label  m-2 mt-1 mb-3 " style={{ marginTop: '0.8pc' }}>
                                            <span color='text-success' style={{ color: urlsState ? '#a3a3a3' : '#000000', marginTop: '' }}>Direct URL</span>&nbsp;&nbsp;
                                        </label>
                                        <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >

                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="customSwitchsizemd"
                                                checked={urlsState}
                                                onChange={urlStateChange}
                                                disabled={props.selectedArchived}
                                            />
                                            <label className="form-check-label  m-2 mt-1">
                                                <span color='text-success' style={{ color: urlsState ? '#000000' : '#a3a3a3' }}>Video Content Suite</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    {!urlsState &&
                                        <div className="player-content">
                                            <div className="container-fluid">
                                                {createContentUI()}
                                                <div className='col-12'>
                                                    <div className='d-flex'>
                                                        <button type='button' className='mt-4 mb-4 btn btn-primary ml-auto' style={{ marginRight: '1rem  2.5rem 1rem auto' }} onClick={addDirectVideoContent} >Add New Content &nbsp;&nbsp; <i className="fa fa-plus"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {urlsState &&
                                        <div className="player-content">
                                            <div className="container-fluid">
                                                {createUI()}
                                                <div className='col-12'>
                                                    <div className='d-flex'>
                                                        <button type='button' className='mt-4 mb-4 btn btn-primary ml-auto' style={{ marginRight: '1rem  2.5rem 1rem auto' }} onClick={addNewVideoContent} >Add New Content &nbsp;&nbsp; <i className="fa fa-plus"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Col>
                        </div>

                    </Row>
                    <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
                        <Col>
                            <div className="text-start">
                                {isEdit && !props.selectedArchived &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Update
                                    </button>
                                }
                                {!isEdit &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Save
                                    </button>
                                }
                                &nbsp;&nbsp;
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    // eslint-disable-next-line react/prop-types
                                    onClick={props.creativeTypeClose}
                                >
                                    Back
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
                {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
                    <Toaster status="error" msg={errorMsg.message} />
                }
            </div>
            {errorMsg !== null && errorMsg !== '' && errorMsg.status && errorMsg.status === 500 &&
                <div className="p-0 justify-content-center d-flex">
                    <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')} />
                </div>
            }
        </React.Fragment>
    )
}

NewAssetVideoCreative.propTypes = {
    history: PropTypes.object,
}

export default NewAssetVideoCreative