import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import moment from "moment";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Label,
    Modal,
    Spinner
} from "reactstrap"

import DateRangePickers from 'helpers/DateRangePicker'
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';
import excelFile from 'assets/files/AdsTxtFile.xlsx'
import PDFFile from 'assets/files/AdsTxtEntries.pdf'
import axios from "axios";

// import images
import logodark from "assets/images/bidsxchange/logo-hd.png";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import LoadingTable from "pages/LoaderPages/LoadingTable";
import InvoiceTableHelper from "Product_Helpers/InvoiceTableHelper";
var filterCount = 0

let pageNumber = 1
let pageSize = 10
let totalRecords = 0
let searchText = ''

const Publisher_Payments = props => {
    var currentTime = new Date().toLocaleString('en-US', {
        timeZone: 'America/New_York',
    })
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const dispatch = useDispatch();
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [invoiceData, setInvoiceData] = useState([])
    const [btnprimary1, setBtnprimary1] = useState(false)
    const [jsTagModalState, setJsTagModalState] = useState(false);
    const [totalInvoice, setTotalInvoice] = useState(0)
    const [invoiceChecked, setInvoiceChecked] = useState(false);
    const [invoicePaid, setInvoicePaid] = useState(false);
    const [invoiceCheckedDate, setInvoiceCheckedDate] = useState(moment(currentTime).format('YYYY-MM-DD HH:mm:ss'))
    const [months, setMonths] = useState([])
    const [partners, setPartners] = useState([])
    const [invoices, setInvoices] = useState([])
    const [financeNotes, setFinanceNotes] = useState('')

    const reportTemplateRef = useRef(null);
    const [switchLoader, setSwitchLoader] = useState(true)
    const [loading, setLoading] = useState(false)
    const [filterState, setFilterState] = useState(false)

    const handleSwitch = () => {
        setSwitchLoader(!switchLoader)
    }

    const [monthSearch, setMonthSearch] = useState('')
    const [partnerSearch, setPartnerSearch] = useState('')
    const [inventoryTypeSearch, setInventoryTypeSearch] = useState('')
    const [partnerData, setPartnerData] = useState({
        partnerName: '',
        date: '',
        month: '',
        billID: ''
    })
    const [accountType, setAccountType] = useState('streamlyn_India')
    const [notesData, setNotesData] = useState({
        notes: 'Thanks for your business',
        terms: 'Publisher must raise Invoice from their own portal and not forward this invoice to us. Payments will be made with agreed Net term from date of receiving the invoice.'
    })


    const changeDatePick = (event) => {
        console.log(event)
        console.log("start: ", event.startDate._d);
        console.log("end: ", event.endDate._d);
        setFromDate(event.startDate._d.toISOString());
        setToDate(event.endDate._d.toISOString());
    }


    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            parentID: userInfo.parentID, isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner: userInfo.isForSupplyPartner
        }
    }

    useEffect(() => {
        querySearch({ pageSize: pageSize, pageNumber: pageNumber, searchText: searchText })

        let bodyMonth = {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,name: userInfo.name,
            partner: userInfo.name, parentID: userInfo.parentID, isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner: userInfo.isForSupplyPartner
        }
        axios.post(userInfo.isSupplyPartner ? '/v2.0/invoiceSupplyPartner/History/Month/SupplyPartner' : "/v2.0/invoice/History/Month/forPartner", bodyMonth,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is Invoices ==>", res)
                if (res.data.statusCode === 200) {
                    setMonths(res.data.response)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });

        // let bodyPartner = {
        //     "url": "/v2.0/invoice/History/Partner",
        //     "requestBody": {
        //         userID: userInfo.userID,
        //         userRole: userInfo.userRole,
        //         companyID: userInfo.companyID,
        //     }
        // }
        // axios.post("/v1.0/connectPartner", bodyPartner,
        //     {
        //         headers: headers
        //     })
        //     .then((res) => {
        //         console.log("result is Invoices ==>", res)
        //         if (res.data.success) {
        //             setPartners(res.data.response)
        //         }
        //     })
        //     .catch((err) => {
        //         console.log("e", err)
        //     });
    }, []);

    const querySearch = (data) => {
        setLoading(true)
        if (filterCount > 0) {
            setFilterState(true)
        } else {
            setFilterState(false)
        }
        body.url = ""
        body.requestBody = {
            ...body.requestBody,
            month: filterCount > 0 ? monthSearch : '',
            partner: userInfo.name,
            pageSize:data ? data.pageSize : 10, pageNumber: data ? data.pageNumber : 1, searchText: data ? data.searchText : '',
            userRole: userInfo.userRole, userID: userInfo.userID, parentID: userInfo.parentID, isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner: userInfo.isForSupplyPartner
        }
        axios.post(userInfo.isSupplyPartner ? `/v2.0/invoiceSupplyPartner/History/listApproved/SupplyPartner?page=${data.pageNumber ? data.pageNumber : 1}&size=${data.pageSize ? data.pageSize : 10}` : `/v2.0/invoice/History/listApproved/forPartner?page=${data ? data.pageNumber : 1}&size=${data ? data.pageSize : 10}`, body.requestBody,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is Invoices ==>", res)
                setLoading(false)
                if (res.data.statusCode === 200) {
                    let invoiceList = []
                    setLoading(false)
                    res.data.response.map((item, index) => {
                        item.id = index + 1
                        // item.Partners.map((ele, index) => {
                        //     let obj = Object()
                        //     obj.month = item.month
                        //     obj.date = item.date
                        //     obj.Partner = ele.Partner
                        //     obj.paymentStatus = ele.paymentStatus
                        //     obj.paymentDate = ele.paymentDate || ''
                        //     obj.financialNotes = ele.financialNotes || ''
                        //     obj.IsApproved = ele.IsApproved
                        //     obj.invoices = ele.invoices
                        //     obj.allData = ele
                        //     obj.billID = ele.iDInvoice
                        //     if (ele.invoices && ele.invoices.length > 0) {
                        //         let invoiceID = ''
                        //         ele.invoices.map((item, index) => {
                        //             invoiceID = ele.invoices.length > 1 ? (invoiceID + ((item.inventoryType).slice(0, 1)).toUpperCase() + item.invoiceID + '/') : ((item.inventoryType).slice(0, 1)).toUpperCase() + item.invoiceID.toString()
                        //         })
                        //         obj.invoiceID = '#INV-' + (invoiceID.slice(-1) === '/' ? invoiceID.slice(0, -1) + '' : invoiceID)
                        //     }
                        //     invoiceList.push(obj)
                        // })
                    })
                    totalRecords = res.data.totalRecords
                    setInvoiceData(res.data.response)
                } else {
                    setLoading(false)
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log("e", err)
            });
    }

    useEffect(() => {
        let partnerBody = {
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            name: userInfo.name,
            userID: userInfo.userID,
            partnerName: userInfo.name
        }
        axios.post("/v1.0/partner/" + userInfo.userID, partnerBody,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is Partner ==>", res)
                if (res.data) {
                    setAccountType(res.data.partnerAccountType)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });

    }, []);

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const invoiceDataListColumns = [
        {
            text: "ID",
            headerClasses: "ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            dataField: 'iDInvoice',
            text: 'ID',
            sort: true
        },
        {
            dataField: "invoiceDate",
            text: "Date",
            sort: true,
        },

        {
            dataField: "partner",
            text: "Partner",
            sort: false,
        },
        {
            dataField: "paymentStatus",
            text: "Payment Status",
            sort: true,
            formatter: (cellContent, invoiceData) => (
                <>
                    {invoiceData.paymentStatus &&
                        <span className="active-state">Paid</span>
                    }
                    {!invoiceData.paymentStatus &&
                        <span className="inactive-state">Payment Pending</span>
                    }
                </>
            ),
        },
        {
            dataField: "paymentDate",
            text: "Paid on",
            sort: true,
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',

            // eslint-disable-next-line react/display-name
            formatter: (cellContent, invoiceData) => (
                <div className="d-flex gap-3" style={{ marginLeft: '0' }}>
                    <Link className="bordered" to="#" onClick={viewInvoice(invoiceData)} style={{ border: '1px solid', padding: '2px 5px', borderRadius: '5px', color: 'black' }}>
                        {/* <i
                      className="mdi mdi-dots-vertical font-size-18"
                      id="edittooltip"
                    ></i> */}
                        View
                    </Link>
                </div>
            ),
        },
    ];

    const invoiceListColumns = [
        {
            text: "ID",
            headerClasses: "ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },

        {
            dataField: "inventoryType",
            text: "Description",
            sort: false,
            footer: 'Total Amount',
            style: { wordBreak: 'break-word' },
            // headerFormatter: priceFormatter,
            editable: (content, row, rowIndex, columnIndex) => row.rowAdded !== 'old'
        },
        {
            dataField: "publisherRevenue",
            text: "Amount (USD)",
            sort: false,
            footer: `${totalInvoice}`
        },

    ];

    useEffect(() => {
        if (invoices.length > 0) {
            let invoiceTotal = 0
            invoices.map((item, index) => {
                if (item.method === '+') {
                    invoiceTotal = invoiceTotal + Number(parseFloat(item.publisherRevenue).toFixed(2))
                } else {
                    invoiceTotal = invoiceTotal - Number(parseFloat(item.publisherRevenue).toFixed(2))
                }
            })
            setTotalInvoice(Number(parseFloat(invoiceTotal).toFixed(2)))
        }
    }, [invoices]);

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );


    const pageOptions = {
        paginationSize: 4,
        pageStartIndex: 1,
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };

    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const closeInvoice = (e) => {
        setInvoiceCheckedDate(moment(currentTime).format('YYYY-MM-DD HH:mm:ss'))
        setInvoicePaid(false)
        setJsTagModalState(false)
    }

    const [selectedInvoiceID, setSelectedInvoiceID] = useState('')
    const viewInvoice = (data) => (e) => {
        setFinanceNotes('')
        console.log(data)
        if (data.paymentStatus) {
            setInvoiceCheckedDate(data.paymentDate)
            setInvoicePaid(true)
            setFinanceNotes(data.financialNotes)
        }

        data.invoices.map((ele, index) => {
            ele.method = '+'
            ele.rowAdded = 'old'
        })
        if (data.invoices && data.invoices.length > 0) {
            let invoiceID = ''
            data.invoices.map((ele, index) => {
                invoiceID = data.invoices.length > 1 ? (invoiceID + ((ele.inventoryType).slice(0, 1)).toUpperCase() + ele.invoiceID + '/') : ((ele.inventoryType).slice(0, 1)).toUpperCase() + ele.invoiceID.toString()
            })
            setSelectedInvoiceID(invoiceID.slice(-1) === '/' ? invoiceID.slice(0, -1) + '' : invoiceID)
        }
        let arr = [...data.invoices]
        if (data.extraCharge) {
            if (data.extraCharge.onTotal && data.extraCharge.onTotal.length > 0) {
                data.extraCharge.onTotal.map((ele, index) => {
                    ele.method = ele.extraMethod
                    ele.rowAdded = 'old'
                    ele.inventoryType = ele.Extra
                    ele.publisherRevenue = ele.amount ? Number(parseFloat(ele.amount).toFixed(2)) : 0
                })
                arr = [...arr, ...data.extraCharge.onTotal]
            }
        }
        setNotesData(notesData => ({ ...notesData, notes: data.invoiceNote ? data.invoiceNote : notesData.notes, terms: data.termsAndCondition ? data.termsAndCondition : notesData.terms }));
        arr.map((ele, index) => {
            ele.id = index + 1
            ele.publisherRevenue = Number(parseFloat(ele.publisherRevenue).toFixed(2))
            ele.inventoryType = ele.inventoryType === 'video' ? 'Video Advertising Revenue' : ele.inventoryType === 'highImpact' ? 'High Impact Advertising Revenue' : ele.inventoryType === 'standardBanner' ? 'Standard Banner Advertising Revenue' : ele.inventoryType
        })
        console.log(arr)
        setPartnerData({
            partnerName: data.partner,
            date: data.invoices && data.invoices.length > 0 ? data.invoices[0].date : data.invoiceDate,
            month: data.invoiceDate,
            billID: data.iDInvoice
        })
        setInvoices(arr)
        setJsTagModalState(true)
    }

    const invoicePayment = (e) => {
        let bodyPayment = {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            date: partnerData.date,
            Partner: partnerData.partnerName,
            paymentStatus: true,
            paymentDate: invoiceCheckedDate
        }
        console.log(bodyPayment)
        axios.post("/v2.0/invoice/Payment/updatePayment", bodyPayment,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is Invoices Approve ==>", res)
                if (res.data.success) {
                    // setPartners(res.data.response)
                    querySearch({ pageSize: pageSize, pageNumber: pageNumber, searchText: searchText })
                    setJsTagModalState(false)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }

    useEffect(() => {
        console.log(loading)
        if (loading) {
            setSwitchLoader(true)
        }
    }, [loading]);

    const [isMenu, setisMenu] = useState(false)
    const toggleMenu = () => {
        setisMenu(!isMenu)
    }

    const clearAllFilter = (e) => {
        setMonthSearch('')
        filterCount = 0
        setFilterState(false)
        setInvoiceData([])
        querySearch({ pageSize: pageSize, pageNumber: pageNumber, searchText: searchText })
    }

    const paginationChange = async (selectedPages) => {
        console.log(selectedPages)
        pageSize = selectedPages.pageSize
        pageNumber = selectedPages.pageNumber
        querySearch({
            pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText
        });
    }
    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
            <div className="page-content">
                <Helmet>
                    <title>Payments | Bidsxchange</title>
                </Helmet>
                <Container fluid style={{ pointerEvents: (loading) ? 'none' : 'unset' }}>
                    <div className=" d-sm-flex  justify-content-between">
                        <Breadcrumbs title="Billing" breadcrumbItem="Payments" />
                        <div className="text-sm-end">
                            <div className="btn-group">
                                <Dropdown
                                    direction="left"
                                    isOpen={isMenu}
                                    toggle={toggleMenu}
                                >
                                    <DropdownToggle
                                        tag="button"
                                        className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                                    >
                                        <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                                        {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                                            Filter Applied
                                        </span> : <span className="d-none font-16 d-sm-inline-block">
                                            Filter
                                        </span>
                                        }
                                        {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px',color:'red' }}>{filterCount}</span>
                              } */}
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                                        <div className="dropdown-item-text">
                                            <div className="row">
                                                <h5 className="mb-0">Filters</h5>
                                            </div>
                                        </div>

                                        <DropdownItem divider />
                                        <Card>
                                            <CardBody aria-hidden="true">
                                                <Row >
                                                    <Col sm="12" className="mt-2">
                                                        <Label className="form-label">Month</Label>
                                                        <Input type="select" className="form-select" value={monthSearch} onChange={(e) => {
                                                            if (e.target.value === '') {
                                                                filterCount = filterCount > 0 ? filterCount - 1 : 0
                                                            }
                                                            if (monthSearch === '') {
                                                                filterCount = filterCount + 1
                                                            }
                                                            setMonthSearch(e.target.value);
                                                        }
                                                        }>
                                                            <option value=''> All </option>
                                                            {months.map((item) => (
                                                                <option key={item} value={item}>{item}</option>
                                                            ))}
                                                        </Input>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                        <DropdownItem divider />
                                        <Row>
                                            <Col sm="6">
                                                <DropdownItem
                                                    className="text-primary text-center"
                                                    onClick={clearAllFilter}
                                                >
                                                    Clear ALL
                                                </DropdownItem>
                                            </Col>
                                            <Col sm="6">
                                                <DropdownItem
                                                    className="text-primary text-center"
                                                    onClick={querySearch}
                                                >
                                                    Apply Filter
                                                </DropdownItem>
                                            </Col>
                                        </Row>

                                    </DropdownMenu>
                                </Dropdown>
                            </div>

                        </div>
                    </div>
                    {switchLoader &&
                        <LoadingTable loading={loading} targetLoading={handleSwitch} />
                    }
                    {!switchLoader &&
                        <InvoiceTableHelper data={invoiceData} Module='history' columns={invoiceDataListColumns} paginationSelection={paginationChange} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize} />
                    }
                </Container>
            </div>
            {/* </div> */}
            <Modal
                size="lg"
                isOpen={jsTagModalState}

            >
                <div className="modal-content" id="Invoice">
                    <div className="modal-header"  >
                        <h3 className="modal-title mt-0" id="myModalLabel">
                            Invoice
                            <p style={{ fontSize: '14px', color: 'grey', margin: '0' }}>
                                {partnerData.billID}
                            </p>
                        </h3>
                        <button
                            type="button"
                            onClick={closeInvoice}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ maxHeight: 'fit-content', overflow: 'auto' }}  >
                        <div className="row mb-4">
                            <h4>
                                <img
                                    src={logodark}
                                    alt=""
                                    height="50"
                                    className="auth-logo-dark"
                                />
                            </h4>
                            <span> Bill To</span>
                            {(accountType === 'streamlyn_Singapore') ?
                                <h5>Streamlyn Pte Ltd.</h5>
                                :
                                <h5>Streamlyn Semisoft Private Limited.</h5>
                            }

                            {(accountType === 'streamlyn_Singapore') ?
                                <span style={{ width: '13pc' }}>
                                    16 Raffles Quay  #33-03, Hong Leong Building, Singapore, 048581
                                    {/* #15-01, 15th Floor, 491B River Valley Road, Valley Point, Singapore, 248373 */}
                                </span>
                                :
                                <span style={{ width: '15pc' }}>
                                    No.34, AVS Compound, 80FT Road, 4th Block, Koramangala, Bangalore, India, 560034
                                </span>
                            }
                        </div>

                        <br />
                        <div className="row mt-2">
                            <div className="col-md-8">
                                Bill From
                                <h6><b>{partnerData.partnerName}</b></h6>
                            </div>
                            <div className="col-md-4">
                                <div className="text-sm-end mt-2">
                                    <span>Invoice Date : &nbsp;&nbsp;&nbsp; <b>{partnerData.month}</b></span> <br />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <BootstrapTable
                                keyField="id"
                                data={invoices}
                                columns={invoiceListColumns}
                            />
                        </div>
                        {/* <div className="row mt-4">
                            <h5>Notes </h5>
                            <span>{notesData.notes}</span>
                        </div>
                        <div className="row mt-4">
                            <h5>Terms & Conditions </h5>
                            <span>{notesData.terms}</span>
                        </div> */}
                        {invoicePaid &&
                            <label className="form-check-label  m-2 mt-1 mt-4">Paid On </label>
                        }
                        {invoicePaid &&
                            <h6 className="mt-2">{invoiceCheckedDate}</h6>
                        }
                        {/* {financeNotes !== '' &&
                            <label className="form-check-label  m-2 mt-1 mt-4">Financial Notes </label>
                        }
                        {financeNotes !== '' &&
                            <h6 className="mt-2">{financeNotes}</h6>
                        } */}
                    </div>
                </div>

                <div className="modal-footer">
                    {/* <button className="btn btn-primary " onClick={handleGeneratePdf}>
                        Download Invoice
                    </button> &nbsp;&nbsp; */}
                    <button
                        type="button"
                        onClick={closeInvoice}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                    >
                        Close
                    </button>

                </div>
            </Modal>
        </React.Fragment >
    )
}

Publisher_Payments.propTypes = {}

export default Publisher_Payments