import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ROLES,ADD_ROLE , UPDATE_ROLE,READ_ROLE,STATUS_ROLE,ARCHIVE_ROLE} from "./actionTypes"

import {
  loadActivitiesRoles,
  noRoles,
  getRolesSuccess,
  getRolesFail,
  addRoleSuccess,
  addRoleFail,
  updateRoleSuccess,
  updateRoleFail,
  readRoleSuccess,
  statusRoleSuccess,
  archiveRoleSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getRoles,createRole,updateRole,readRole,statusChangeRole,archiveRole } from "../../helpers/Backend_helper"

function* fetchRoles({ payload: roles }) {
  try {
    yield put(loadActivitiesRoles(true))
    const response = yield call(getRoles,roles)
    console.log(response)
    response.response.map((item, index) => {
      item.id = index + 1
  })
  console.log(response)
  if(response.response.length === 0){
    yield put(noRoles(true))
  }
    yield put(getRolesSuccess(response.response))
  } catch (error) {
    yield put(getRolesFail(error))
  }
}

function* onReadRole({ payload: role }) {
  try {
    const response = yield call(readRole, role)
    yield put(readRoleSuccess(response))
  } catch (error) {
    yield put(readRoleSuccess(error))
  }
}

function* onAddRole({ payload: role }) {
  try {
    delete role.confirmEmail
    const response = yield call(createRole, role)
    if(response.success){
      yield put(addRoleSuccess(response))
    }else{
      yield put(addRoleFail(response))
    }
  } catch (error) {
    yield put(addRoleFail(error))
  }
}

function* onUpdateRole({ payload: role }) {
  try {
    const response = yield call(updateRole, role)
    if(response.success){
      yield put(updateRoleSuccess(response))
    }else{
      yield put(updateRoleFail(response))
    }
  } catch (error) {
    yield put(updateRoleFail(error))
  }
}

function* onStatusRole({ payload: role }) {
  try {
    const response = yield call(statusChangeRole, role)
    if(response.success){
      yield put(statusRoleSuccess(response))
    }else{
      yield put(statusRoleSuccess(response.message))
    }
  } catch (error) {
    yield put(updateRoleFail(error))
  }
}

function* onArchiveRole({ payload: role }) {
  try {
    const response = yield call(archiveRole, role)
    yield put(archiveRoleSuccess(response))
  } catch (error) {
    yield put(archiveRoleSuccess(error))
  }
}

function* rolesSaga() {
  yield takeEvery(GET_ROLES, fetchRoles)
  yield takeEvery(ADD_ROLE, onAddRole)
  yield takeEvery(UPDATE_ROLE, onUpdateRole)
  yield takeEvery(READ_ROLE, onReadRole)
  yield takeEvery(STATUS_ROLE, onStatusRole)
  yield takeEvery(ARCHIVE_ROLE, onArchiveRole)
}

export default rolesSaga;
