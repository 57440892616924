import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from 'react';
import { Switch, useHistory, BrowserRouter as Router, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { authProtectedRoutes, publicRoutes } from "./routes";
import Authmiddleware from "./routes/route";
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import "./assets/scss/theme.scss";
import packageJson from "../package.json";
import { getBuildDate } from "./utils/utils.js";
import withClearCache from "./ClearCache";
import axios from "axios";
const ClearCacheComponent = withClearCache(MainApp);

function App(props) {
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));
  const path = window.location
  console.log(path.hostname, userInfo)
  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = VerticalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }
  const Layout = getLayout();

  return (
    <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
            {userInfo ? <Redirect to="/Dashboard" /> : <Redirect to="/login" />}
          </Switch>
        </Router>
    </React.Fragment>
  );
}

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

function MainApp(props) {
  return (
    <div>
      <ClearCacheComponent />
      {React.createElement(App(props))}
    </div>
  );
}

export default connect(mapStateToProps, null)(App);
