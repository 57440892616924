/* PROXY_ADUNIT */
export const GET_PROXY_ADUNITS = "GET_PROXY_ADUNITS"
export const GET_PROXY_ADUNITS_SUCCESS = "GET_PROXY_ADUNITS_SUCCESS"
export const GET_PROXY_ADUNITS_FAIL = "GET_PROXY_ADUNITS_FAIL"

export const GET_PROXY_ADUNITS_LOADING = "GET_PROXY_ADUNITS_LOADING"

export const ADD_PROXY_ADUNIT = "ADD_PROXY_ADUNIT"
export const ADD_PROXY_ADUNIT_SUCCESS = "ADD_PROXY_ADUNIT_SUCCESS"
export const ADD_PROXY_ADUNIT_ERROR = "ADD_PROXY_ADUNIT_ERROR"

export const UPDATE_PROXY_ADUNIT = "UPDATE_PROXY_ADUNIT"
export const UPDATE_PROXY_ADUNIT_SUCCESS = "UPDATE_PROXY_ADUNIT_SUCCESS"
export const UPDATE_PROXY_ADUNIT_ERROR = "UPDATE_PROXY_ADUNIT_ERROR"

export const READ_PROXY_ADUNIT = "READ_PROXY_ADUNIT"
export const READ_PROXY_ADUNIT_SUCCESS = "READ_PROXY_ADUNIT_SUCCESS"

export const STATUS_PROXY_ADUNIT = "STATUS_PROXY_ADUNIT"
export const STATUS_PROXY_ADUNIT_SUCCESS = "STATUS_PROXY_ADUNIT_SUCCESS"

export const ARCHIVE_PROXY_ADUNIT = "ARCHIVE_PROXY_ADUNIT"
export const ARCHIVE_PROXY_ADUNIT_SUCCESS = "ARCHIVE_PROXY_ADUNIT_SUCCESS"

export const GET_PROXY_ADUNITS_REPORTS = "GET_PROXY_ADUNITS_REPORTS"
export const GET_PROXY_ADUNITS_REPORTS_SUCCESS = "GET_PROXY_ADUNITS_REPORTS_SUCCESS"
export const GET_PROXY_ADUNITS_REPORTS_FAIL = "GET_PROXY_ADUNITS_REPORTS_FAIL"

export const GET_REVENUE_DISPLAY_REPORTS = "GET_REVENUE_DISPLAY_REPORTS"
export const GET_REVENUE_DISPLAY_REPORTS_SUCCESS = "GET_REVENUE_DISPLAY_REPORTS_SUCCESS"
export const GET_REVENUE_DISPLAY_REPORTS_FAIL = "GET_REVENUE_DISPLAY_REPORTS_FAIL"

export const GET_PUBLISHER_REVENUE_DISPLAY_REPORTS = "GET_PUBLISHER_REVENUE_DISPLAY_REPORTS"
export const GET_PUBLISHER_REVENUE_DISPLAY_REPORTS_SUCCESS = "GET_PUBLISHER_REVENUE_DISPLAY_REPORTS_SUCCESS"
export const GET_PUBLISHER_REVENUE_DISPLAY_REPORTS_FAIL = "GET_PUBLISHER_REVENUE_DISPLAY_REPORTS_FAIL"

export const GET_ANALYTICS_DISPLAY_REPORTS = "GET_ANALYTICS_DISPLAY_REPORTS"
export const GET_ANALYTICS_DISPLAY_REPORTS_SUCCESS = "GET_ANALYTICS_DISPLAY_REPORTS_SUCCESS"
export const GET_ANALYTICS_DISPLAY_REPORTS_FAIL = "GET_ANALYTICS_DISPLAY_REPORTS_FAIL"

export const GET_PAGE_URL_21_REPORTS = "GET_PAGE_URL_21_REPORTS"
export const GET_PAGE_URL_21_REPORTS_SUCCESS = "GET_PAGE_URL_21_REPORTS_SUCCESS"
export const GET_PAGE_URL_21_REPORTS_FAIL = "GET_PAGE_URL_21_REPORTS_FAIL"

export const GET_AGENCY_REVENUE_DISPLAY_REPORTS = "GET_AGENCY_REVENUE_DISPLAY_REPORTS"
export const GET_AGENCY_REVENUE_DISPLAY_REPORTS_SUCCESS = "GET_AGENCY_REVENUE_DISPLAY_REPORTS_SUCCESS"
export const GET_AGENCY_REVENUE_DISPLAY_REPORTS_FAIL = "GET_AGENCY_REVENUE_DISPLAY_REPORTS_FAIL"

export const GET_PUBLISHER_AGENCY_REVENUE_DISPLAY_REPORTS = "GET_PUBLISHER_AGENCY_REVENUE_DISPLAY_REPORTS"
export const GET_PUBLISHER_AGENCY_REVENUE_DISPLAY_REPORTS_SUCCESS = "GET_PUBLISHER_AGENCY_REVENUE_DISPLAY_REPORTS_SUCCESS"
export const GET_PUBLISHER_AGENCY_REVENUE_DISPLAY_REPORTS_FAIL = "GET_PUBLISHER_AGENCY_REVENUE_DISPLAY_REPORTS_FAIL"

export const LOAD_REPORTS = "LOAD_REPORTS"