import {
  LOAD_ACTIVITIES,
  NO_DATA,
    GET_HIGH_IMPACT_SETTINGS,
    GET_HIGH_IMPACT_SETTINGS_FAIL,
    GET_HIGH_IMPACT_SETTINGS_SUCCESS,
    ADD_HIGH_IMPACT_SETTING,
    ADD_HIGH_IMPACT_SETTING_SUCCESS,
    ADD_HIGH_IMPACT_SETTING_ERROR,
    UPDATE_HIGH_IMPACT_SETTING,
    UPDATE_HIGH_IMPACT_SETTING_SUCCESS,
    UPDATE_HIGH_IMPACT_SETTING_ERROR,
    READ_HIGH_IMPACT_SETTING,
    READ_HIGH_IMPACT_SETTING_SUCCESS,
    STATUS_HIGH_IMPACT_SETTING,
    STATUS_HIGH_IMPACT_SETTING_SUCCESS,
    ARCHIVE_HIGH_IMPACT_SETTING ,
    ARCHIVE_HIGH_IMPACT_SETTING_SUCCESS

  } from "./actionTypes"

  export const loadActivitiesHighImpactSetting = Params => ({
    type: LOAD_ACTIVITIES,
    payload:Params
  })

  export const noDataHighImpactSetting = Params => ({
    type: NO_DATA,
    payload:Params
  })

  export const getHighImpactSetting = Params => ({
    type: GET_HIGH_IMPACT_SETTINGS,
    payload:Params
  })
  
  export const getHighImpactSettingsSuccess = HighImpactSettings => ({
    type: GET_HIGH_IMPACT_SETTINGS_SUCCESS,
    payload: HighImpactSettings,
  })

  export const getHighImpactSettingsFail = error => ({
    type: GET_HIGH_IMPACT_SETTINGS_FAIL,
    payload: error,
  })

  export const addNewHighImpactSetting = HighImpactSetting => ({
    type: ADD_HIGH_IMPACT_SETTING,
    payload: HighImpactSetting,
  })
  
  export const addHighImpactSettingSuccess = highImpactSetting => ({
    type: ADD_HIGH_IMPACT_SETTING_SUCCESS,
    payload: highImpactSetting,
  })
  
  export const addHighImpactSettingFail = highImpactSetting => ({
    type: ADD_HIGH_IMPACT_SETTING_ERROR,
    payload: highImpactSetting,
  })

  export const updateHighImpactSetting = highImpactSetting => ({
    type: UPDATE_HIGH_IMPACT_SETTING,
    payload: highImpactSetting,
  })
  
  export const updateHighImpactSettingSuccess = highImpactSetting => ({
    type: UPDATE_HIGH_IMPACT_SETTING_SUCCESS,
    payload: highImpactSetting,
  })
  
  export const updateHighImpactSettingFail = highImpactSetting => ({
    type: UPDATE_HIGH_IMPACT_SETTING_ERROR,
    payload: highImpactSetting,
  })

  export const readHighImpactSetting = highImpactSetting => ({
    type: READ_HIGH_IMPACT_SETTING,
    payload: highImpactSetting,
  })

  export const readHighImpactSettingSuccess = highImpactSetting => ({
    type: READ_HIGH_IMPACT_SETTING_SUCCESS,
    payload: highImpactSetting,
  })

  export const statusHighImpactSetting = highImpactSetting => ({
    type: STATUS_HIGH_IMPACT_SETTING,
    payload: highImpactSetting,
  })

  export const statusHighImpactSettingSuccess = highImpactSetting => ({
    type: STATUS_HIGH_IMPACT_SETTING_SUCCESS,
    payload: highImpactSetting,
  })

  export const archiveHighImpactSetting = highImpactSetting => ({
    type: ARCHIVE_HIGH_IMPACT_SETTING,
    payload: highImpactSetting,
  })

  export const archiveHighImpactSettingSuccess = highImpactSetting => ({
    type: ARCHIVE_HIGH_IMPACT_SETTING_SUCCESS,
    payload: highImpactSetting,
  })