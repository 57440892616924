import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types'
import { Helmet, HelmetProvider } from 'react-helmet-async';
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { Col, Container, Row, Input, Label, Form, Button } from "reactstrap"

const AdCodeTester = props => {

    return (
        <React.Fragment>
            {/* <div className="main-content"> */}
                <div className="page-content">
                    <Helmet>
                        <title>Vast Tag Tester | Bidsxchange</title>
                    </Helmet>
                    <Container fluid={true}>
                        <div className="card card-body " >
                            <iframe src="about:blank" allow="autoplay;fullscreen;" scrolling="yes" srcDoc="<html>
                                <head>
                                <style>
                                #page-content {
                                    position: relative;
                                    max-width: 640px;
                                    margin: 10px auto;
                                  }
                                  
                                  #video-container {
                                    position: relative;
                                    padding-bottom: 56.25%;
                                  }
                                  
                                  #video-element {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                  }
                                
                                  #ad-container {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                  }


                                </style>
                                </head>
                                <body>
                                <div id=&quot;page-content&quot;>
                                <div id=&quot;video-container&quot;>
                                  <video id=&quot;video-element&quot;>
                                    <source src=&quot;https://storage.googleapis.com/interactive-media-ads/media/android.mp4&quot;>
                                    <source src=&quot;https://storage.googleapis.com/interactive-media-ads/media/android.webm&quot;>
                                  </video>
                                  <div id=&quot;ad-container&quot;></div>
                                </div>
                                <button id=&quot;play-button&quot;>Play</button>
                              </div>
                              <script src=&quot;https://imasdk.googleapis.com/js/sdkloader/ima3.js&quot;></script>
                                <script type=&quot;text/javascript&quot;>
                                var videoElement;
                                var adsLoaded = false;
                                var adContainer;
                                var adDisplayContainer;
                                var adsLoader;
                                var adsManager;
                                
                                window.addEventListener('load', function(event) {
                                  videoElement = document.getElementById('video-element');
                                  initializeIMA();
                                  videoElement.addEventListener('play', function(event) {
                                    loadAds(event);
                                  });
                                  var playButton = document.getElementById('play-button');
                                  playButton.addEventListener('click', function(event) {
                                    videoElement.play();
                                  });
                                });
                                
                                window.addEventListener('resize', function(event) {
                                    console.log(&quot;window resized&quot;);
                                    if(adsManager) {
                                      var width = videoElement.clientWidth;
                                      var height = videoElement.clientHeight;
                                      adsManager.resize(width, height, google.ima.ViewMode.NORMAL);
                                    }
                                  });
                                
                                function loadAds(event) {
                                    if(adsLoaded) {
                                      return;
                                    }
                                    adsLoaded = true;
                                    event.preventDefault();
                                  
                                    console.log(&quot;loading ads&quot;);
                                    videoElement.load();
                                    adDisplayContainer.initialize();
                                  
                                    var width = videoElement.clientWidth;
                                    var height = videoElement.clientHeight;
                                    try {
                                      adsManager.init(width, height, google.ima.ViewMode.NORMAL);
                                      adsManager.start();
                                    } catch (adError) {
                                      console.log(&quot;AdsManager could not be started&quot;);
                                      videoElement.play();
                                    }
                                  }
                                
                                function initializeIMA() {
                                    console.log(&quot;initializing IMA&quot;);
                                    adContainer = document.getElementById('ad-container');
                                    adContainer.addEventListener('click', adContainerClick);
                                    adDisplayContainer = new google.ima.AdDisplayContainer(adContainer, videoElement);
                                    adsLoader = new google.ima.AdsLoader(adDisplayContainer);
                                
                                    adsLoader.addEventListener(
                                        google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
                                        onAdsManagerLoaded,
                                        false);
                                    adsLoader.addEventListener(
                                        google.ima.AdErrorEvent.Type.AD_ERROR,
                                        onAdError,
                                        false);
                                  
                                    videoElement.addEventListener('ended', function() {
                                      adsLoader.contentComplete();
                                    });
                                  
                                    var adsRequest = new google.ima.AdsRequest();
                                    adsRequest.adTagUrl = 'https://pubads.g.doubleclick.net/gampad/ads?' +
                                        'iu=/21775744923/external/single_ad_samples&sz=640x480&' +
                                        'cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&' +
                                        'gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=';
                                    adsRequest.linearAdSlotWidth = videoElement.clientWidth;
                                    adsRequest.linearAdSlotHeight = videoElement.clientHeight;
                                    adsRequest.nonLinearAdSlotWidth = videoElement.clientWidth;
                                    adsRequest.nonLinearAdSlotHeight = videoElement.clientHeight / 3;
                                    console.log(&quot;initializing IMA23&quot;);
                                    adsLoader.requestAds(adsRequest);
                                  }
                                
                                  function onAdsManagerLoaded(adsManagerLoadedEvent) {
                                    adsManager = adsManagerLoadedEvent.getAdsManager(
                                        videoElement);
                                  
                                    adsManager.addEventListener(
                                        google.ima.AdErrorEvent.Type.AD_ERROR,
                                        onAdError);
                                    adsManager.addEventListener(
                                        google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
                                        onContentPauseRequested);
                                    adsManager.addEventListener(
                                        google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
                                        onContentResumeRequested);
                                    adsManager.addEventListener(
                                        google.ima.AdEvent.Type.LOADED,
                                        onAdLoaded);
                                  }
                                
                                  function onAdLoaded(adEvent) {
                                    var ad = adEvent.getAd();
                                    if (!ad.isLinear()) {
                                      videoElement.play();
                                    }
                                  }
                                
                                  function onContentPauseRequested() {
                                    videoElement.pause();
                                  }
                                  
                                  function onContentResumeRequested() {
                                    videoElement.play();
                                  }
                                  
                                  function onAdError(adErrorEvent) {
                                    console.log(adErrorEvent.getError());
                                    if(adsManager) {
                                      adsManager.destroy();
                                    }
                                  }
                                
                                  function adContainerClick(event) {
                                    console.log(&quot;ad container clicked&quot;);
                                    if(videoElement.paused) {
                                      videoElement.play();
                                    } else {
                                      videoElement.pause();
                                    }
                                  }
                                </script>
                                </body>
                                </html>
                                " style={{ width: '100%', minHeight: '75vh', overflow: 'auto', border: '1px solid #ced4da', borderRadius: '0.25rem', padding: '2%' }}></iframe>
                        </div>

                    </Container>
                </div>
            {/* </div > */}
        </React.Fragment >
    )
}

AdCodeTester.propTypes = {}

export default AdCodeTester