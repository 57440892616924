import {
  LOAD_ACTIVITIES,
  NO_DATA,
    GET_ADUNITS,
    GET_ADUNITS_FAIL,
    GET_ADUNITS_SUCCESS,
    GET_ADUNITS_TC,
    GET_ADUNITS_TC_FAIL,
    GET_ADUNITS_TC_SUCCESS,
    GET_ADUNITS_HIS,
    GET_ADUNITS_HIS_FAIL,
    GET_ADUNITS_HIS_SUCCESS,
    ADD_ADUNIT,
    ADD_ADUNIT_SUCCESS,
    ADD_ADUNIT_ERROR,
    UPDATE_ADUNIT,
    UPDATE_ADUNIT_SUCCESS,
    UPDATE_ADUNIT_ERROR,
    READ_ADUNIT,
    READ_ADUNIT_SUCCESS,
    STATUS_ADUNIT,
    STATUS_ADUNIT_SUCCESS,
    ARCHIVE_ADUNIT ,
    ARCHIVE_ADUNIT_SUCCESS

  } from "./actionTypes"

  export const loadActivitiesAdUnit = Params => ({
    type: LOAD_ACTIVITIES,
    payload:Params
  })

  export const noDataAdUnit = Params => ({
    type: NO_DATA,
    payload:Params
  })

  export const getAdUnit = Params => ({
    type: GET_ADUNITS,
    payload:Params
  })
  
  export const getAdUnitSuccess = AdUnit => ({
    type: GET_ADUNITS_SUCCESS,
    payload: AdUnit,
  })

  export const getAdUnitFail = error => ({
    type: GET_ADUNITS_FAIL,
    payload: error,
  })

  export const getAdUnitTc = Params => ({
    type: GET_ADUNITS_TC,
    payload:Params
  })
  
  export const getAdUnitTcSuccess = AdUnit => ({
    type: GET_ADUNITS_TC_SUCCESS,
    payload: AdUnit,
  })

  export const getAdUnitTcFail = error => ({
    type: GET_ADUNITS_TC_FAIL,
    payload: error,
  })

  export const getAdUnitHIS = Params => ({
    type: GET_ADUNITS_HIS,
    payload:Params
  })
  
  export const getAdUnitHISSuccess = AdUnit => ({
    type: GET_ADUNITS_HIS_SUCCESS,
    payload: AdUnit,
  })

  export const getAdUnitHISFail = error => ({
    type: GET_ADUNITS_HIS_FAIL,
    payload: error,
  })

  export const addNewAdUnit = AdUnit => ({
    type: ADD_ADUNIT,
    payload: AdUnit,
  })
  
  export const addAdUnitSuccess = adUnit => ({
    type: ADD_ADUNIT_SUCCESS,
    payload: adUnit,
  })
  
  export const addAdUnitFail = adUnit => ({
    type: ADD_ADUNIT_ERROR,
    payload: adUnit,
  })

  export const updateAdUnit = adUnit => ({
    type: UPDATE_ADUNIT,
    payload: adUnit,
  })
  
  export const updateAdUnitSuccess = adUnit => ({
    type: UPDATE_ADUNIT_SUCCESS,
    payload: adUnit,
  })
  
  export const updateAdUnitFail = adUnit => ({
    type: UPDATE_ADUNIT_ERROR,
    payload: adUnit,
  })

  export const readAdUnit = adUnit => ({
    type: READ_ADUNIT,
    payload: adUnit,
  })

  export const readAdUnitSuccess = adUnit => ({
    type: READ_ADUNIT_SUCCESS,
    payload: adUnit,
  })

  export const statusAdUnit = adUnit => ({
    type: STATUS_ADUNIT,
    payload: adUnit,
  })

  export const statusAdUnitSuccess = adUnit => ({
    type: STATUS_ADUNIT_SUCCESS,
    payload: adUnit,
  })

  export const archiveAdUnit = adUnit => ({
    type: ARCHIVE_ADUNIT,
    payload: adUnit,
  })

  export const archiveAdUnitSuccess = adUnit => ({
    type: ARCHIVE_ADUNIT_SUCCESS,
    payload: adUnit,
  })