import {
  LOAD_KEYWORDS,
  NO_DATA_KEYWORDS,
    GET_KEYWORDS,
    GET_KEYWORDS_FAIL,
    GET_KEYWORDS_SUCCESS,
    ADD_KEYWORD,
    ADD_KEYWORD_SUCCESS,
    ADD_KEYWORD_ERROR,
    UPDATE_KEYWORD,
    UPDATE_KEYWORD_SUCCESS,
    UPDATE_KEYWORD_ERROR,
    READ_KEYWORD,
    READ_KEYWORD_SUCCESS,
    STATUS_KEYWORD,
    STATUS_KEYWORD_SUCCESS,
    ARCHIVE_KEYWORD ,
    ARCHIVE_KEYWORD_SUCCESS

  } from "./actionTypes"

  export const loadKeywords = Params => ({
    type: LOAD_KEYWORDS,
    payload:Params
  })

  export const noKeywords = Params => ({
    type: NO_DATA_KEYWORDS,
    payload:Params
  })

  export const getKeywords = () => ({
    type: GET_KEYWORDS,
  })
  
  export const getKeywordsSuccess = Keywords => ({
    type: GET_KEYWORDS_SUCCESS,
    payload: Keywords,
  })

  export const getKeywordsFail = error => ({
    type: GET_KEYWORDS_FAIL,
    payload: error,
  })

  export const addNewKeyword = Keyword => ({
    type: ADD_KEYWORD,
    payload: Keyword,
  })
  
  export const addKeywordSuccess = Keyword => ({
    type: ADD_KEYWORD_SUCCESS,
    payload: Keyword,
  })
  
  export const addKeywordFail = Keyword => ({
    type: ADD_KEYWORD_ERROR,
    payload: Keyword,
  })

  export const updateKeyword = Keyword => ({
    type: UPDATE_KEYWORD,
    payload: Keyword,
  })
  
  export const updateKeywordSuccess = Keyword => ({
    type: UPDATE_KEYWORD_SUCCESS,
    payload: Keyword,
  })
  
  export const updateKeywordFail = Keyword => ({
    type: UPDATE_KEYWORD_ERROR,
    payload: Keyword,
  })

  export const readKeyword = Keyword => ({
    type: READ_KEYWORD,
    payload: Keyword,
  })

  export const readKeywordSuccess = Keyword => ({
    type: READ_KEYWORD_SUCCESS,
    payload: Keyword,
  })

  export const statusKeyword = Keyword => ({
    type: STATUS_KEYWORD,
    payload: Keyword,
  })

  export const statusKeywordSuccess = Keyword => ({
    type: STATUS_KEYWORD_SUCCESS,
    payload: Keyword,
  })

  export const archiveKeyword = Keyword => ({
    type: ARCHIVE_KEYWORD,
    payload: Keyword,
  })

  export const archiveKeywordSuccess = Keyword => ({
    type: ARCHIVE_KEYWORD_SUCCESS,
    payload: Keyword,
  })