import {
    LOAD_VIDEO_CONTENTS,
    GET_VIDEO_CONTENTS,
    GET_VIDEO_CONTENTS_SUCCESS,
    GET_VIDEO_CONTENTS_FAIL,
    ADD_VIDEO_CONTENT,
    ADD_VIDEO_CONTENT_SUCCESS,
    ADD_VIDEO_CONTENT_ERROR,
    DELETE_VIDEO_CONTENT,
    DELETE_VIDEO_CONTENT_SUCCESS,
    DELETE_VIDEO_CONTENT_ERROR
} from './actionTypes';

export const loadVideoContents = Params => ({
    type: LOAD_VIDEO_CONTENTS,
    payload: Params
})

export const getVideoContents = Params => ({
    type: GET_VIDEO_CONTENTS,
    payload: Params
})

export const getVideoContentsSuccess = VideoContents => ({
    type: GET_VIDEO_CONTENTS_SUCCESS,
    payload: VideoContents, 
})

export const getVideoContentsFail = error => ({
    type: GET_VIDEO_CONTENTS_FAIL,
    payload: error,
})

export const addNewVideoContent = VideoContents => ({
    type: ADD_VIDEO_CONTENT,
    payload: VideoContents,
})

export const addVideoContentSuccess = VideoContents => ({
    type: ADD_VIDEO_CONTENT_SUCCESS,
    payload: VideoContents,
})

export const addVideoContentFail = error => ({
    type: ADD_VIDEO_CONTENT_ERROR,
    payload: error,
})

export const deleteVideoContent = VideoContents => ({
    type: DELETE_VIDEO_CONTENT,
    payload: VideoContents,
})

export const deleteVideoContentSuccess = VideoContents => ({
    type: DELETE_VIDEO_CONTENT_SUCCESS,
    payload: VideoContents,
})

export const deleteVideoContentFail = error => ({
    type: DELETE_VIDEO_CONTENT_ERROR,
    payload: error,
})
