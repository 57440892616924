/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback, Spinner, Collapse } from "reactstrap";
import Select from "react-select";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

import Toaster from "pages/Toaster/Toaster";

import {
    getRoles as onGetRoles,
    addNewRole as onADDRole,
    updateRole as onUpdateRole,
    addRoleSuccess,
    addRoleFail,
    updateRoleSuccess,
    updateRoleFail,
} from "store/RoleManagement/actions";
import { event } from "jquery";
import ToasterWarning from "pages/Toaster/ToasterWarning";

const New_Role_Test = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const dispatch = useDispatch();
    const [isEdit, setIsEdit] = useState(false);
    const nextRef = useRef();
    const [nextTab, setNextTab] = useState(false);
    const [loader, setLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [userRole, setUserRole] = useState('')
    console.log(props)
    const [userRolesData, setUserRolesData] = useState([])
    const [selectedUserRoles, setSelectedUserRoles] = useState(null);
    const [templatesData, setTemplatesData] = useState([]);
    const [templateSelect, setTemplateSelect] = useState([]);
    const [stdbTemplateSelect, setSTDBTemplateSelect] = useState([]);
    const [stdbTemplatesData, setSTDBTemplatesData] = useState([]);

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const [finalData, setFinalData] = useState(
        {
            "roleName": "",
            "roleDescription": "",
            "rolePrivileges": {
                "Dashboard": {
                    "Revenue": true,
                    "Analytics": true,
                    isRevenueAccess: false,
                    isAnalyticsAccess: false,
                    isAgencyAccess: false
                },
                "SwitchAccount": {
                    isPartnerAccountAccess: false,
                    isAgencyAccountAccess: false,
                    isUserAccountAccess: false
                },
                "Demand": {
                    "TC": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    "IOs": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    "IOGroups": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    "Creatives": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    "HeaderBidding": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    "LineItems": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    "Advertisers": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    isAdveriserAccess: false,
                    isIOGroupAccess: false,
                    isIOsAccess: false,
                    isLineItemAccess: false,
                    isTCAccess: false,
                    isCreativeAccess: false,
                    isHeaderBiddingAccess: false,
                },
                "adminAccess": {
                    "RoleManagement": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    "UserManagement": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    "Notification": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    isRoleManagementAccess: false,
                    isUserManagementAccess: false,
                    isNotificationAccess: false,
                    isPublisherApprovalAccess: false,
                    isSupportTicketAccess: false,
                },
                "Supply": {
                    "AdUnits": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    "partners": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    "Publishers": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    "AdUnitGroups": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    "ChildAdUnits": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    "HighImpactSettings": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    "STDBSettings": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    "PlayerSettings": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    isPartnerAccess: false,
                    isPublisherAccess: false,
                    isAdUnitGroupAccess: false,
                    isAdUnitAccess: false,
                    isChildAdUnitAccess: false,
                    isHighImpactSettingAccess: false,
                    isSTDBSettingAccess: false,
                    isPlayerSettingAccess: false
                },
                "HouseAds": {
                    "Fsrv": {
                        "read": true,
                        "report": true
                    },
                    "NativeAds": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    "HouseAdsAdUnits": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    "HouseAdsCreatives": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    "HouseAdsCategories": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    "HouseAdsPublishers": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    isHouseAdsPublisherAccess: false,
                    isHouseAdsAdUnitAccess: false,
                    isHouseAdCreativeAccess: false,
                    isNativeAdAccess: false,
                    isHouseAdsCategoryAccess: false,
                    isFsrvAccess: false,
                },
                "ProxyAds": {
                    "Ssrv": {
                        "read": true,
                        "report": true
                    },
                    "ProxyAdUnits": {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    },
                    isProxyAdUnitAccess: true,
                    isSsrvAccess: true,
                },
                "PartnerManagement": {
                    PartnerAccess: {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    }
                },
                "Report": {
                    "Query": {
                        "Pageurl": true,
                        "Revenue": true,
                        "Analytic": true
                    },
                    isQueryAccess: true,
                    isPageUrlReportAccess: false,
                    isRevenueReportAccess: false,
                    isAnalyticReportAccess: false,
                    isYMAnalyticReportAccess: false,
                    isASAnalyticReportAccess: false,
                    isMISAnalyticReportAccess: false,
                    isTeamPageUrlReportAccess: false,
                    isAgencyReportAccess: false,
                },
                "ReportUploading": {
                    "PublihserReport": {
                        "Revenue": true
                    },
                    isPublihserReportAccess: true,
                    isAgencyReportUploadAccess: false,
                },
                "Billing": {
                    "Invoices": {
                        "Approve": true,
                        "History": true,
                        "Payment": true
                    },
                    isInvoiceApproveAccess: false,
                    isInvoiceHistoryAccess: false,
                    isInvoicePaymentAccess: false
                },

                ContextualSettings: {
                    isBrandAffinityAccess: false,
                    isInterestAccess: false,
                    isKeywordAccess: false,
                    isPageUrlAccess: false,
                    isWebCategorizationAccess: false
                },
                Tools: {
                    isVideoCompresser: false,
                    isVideoContentLibrarayAccess: false,
                    isScreenShotToolAccess: false,
                    isAdTagTesterAccess: false,
                    isVastTagTesterAccess: false,
                    isAdsTxtValidatorAccess: false,
                    isLinkTester: false,
                    isPlayerDashBoardAccess: false,
                    isTextSummerizationAccess: false,
                    isTextTranslatorAccess: false,
                    isTextTransisterAccess: false,
                    isAiVideoGeneratorAccess: false
                },
                VCs: {
                    isVideoContentLibrarayAccess: false,
                    isVideoPlayListAccess: false,
                    isVideoImportAccess: false,
                    isContentMarketplaceAccess: false,
                },
                "admin": {
                    "ads.txtEntries": {
                        "read": true,
                        "create": true,
                        "update": true
                    },
                    "ads.txtMonitor": {
                        "read": true
                    },
                    isAccessManagementAccess: false,
                    isAdsTxtEntriesAccess: false,
                    isAdsTxtMonitorAccess: false,
                    isAdsTxtManagementAccess: false,
                    isMasterLogAccess: false,
                    isPublisherLogAccess: false,
                    isPartnerAccessManagementAccess: false,
                    isSupplyPartnerManagementAccess: false,
                    isBrandSafetyAccess: false
                },
                "TagController": {
                    TCs: {
                        "read": true,
                        "create": false,
                        "status": false,
                        "update": false,
                        "archive": false
                    }
                },
                isDashboardAccess: false,
                isDemandAccess: false,
                isSupplyAccess: false,
                isHouseAdAccess: false,
                isProxyAdAccess: false,
                isReportUploadingAccess: false,
                isReportAccess: false,
                isBillingAccess: false,
                isPartnerManagementAccess: false,
                isSwitchManagementAccess: false,
                isContextualSettingsAccess: false,
                isHBSettingAccess: false,
                isNetworkBlockingAccess: false,
                isNetworkSettingAccess: false,
                isToolsAccess: false,
                isVCsAccess: false,
                isAdminAccess: false,
                isTagControllerAccess: false,
            }
        }
    )

    const selectDashboard = (event) => {
        setFinalData(finalData => ({
            ...finalData, rolePrivileges: {
                ...finalData.rolePrivileges, isDashboardAccess: event.target.checked
            }
        }))
        event.target.checked ? '' :
            setFinalData(finalData => ({
                ...finalData, rolePrivileges: {
                    ...finalData.rolePrivileges, Dashboard: {
                        ...finalData.rolePrivileges.Dashboard,
                        "Revenue": false,
                        "Analytics": false,
                        isRevenueAccess: false,
                        isAnalyticsAccess: false,
                        isAgencyAccess: false
                    }
                }
            }))
    }

    const selectSwitchManagement = (event) => {
        setFinalData(finalData => ({
            ...finalData, rolePrivileges: {
                ...finalData.rolePrivileges, isSwitchManagementAccess: event.target.checked
            }
        }))
        event.target.checked ? '' :
            setFinalData(finalData => ({
                ...finalData, rolePrivileges: {
                    ...finalData.rolePrivileges, SwitchAccount: {
                        ...finalData.rolePrivileges.SwitchAccount
                    }
                }
            }))
    }

    const selectDemand = (event) => {
        setFinalData(finalData => ({
            ...finalData, rolePrivileges: {
                ...finalData.rolePrivileges, isDemandAccess: event.target.checked
            }
        }))
        event.target.checked ? '' :
            setFinalData(finalData => ({
                ...finalData, rolePrivileges: {
                    ...finalData.rolePrivileges, Demand: {
                        ...finalData.rolePrivileges.Demand,
                        "TC": {
                            "read": true,
                            "create": false,
                            "status": false,
                            "update": true,
                            "archive": false
                        },
                        "IOs": {
                            "read": true,
                            "create": false,
                            "status": false,
                            "update": true,
                            "archive": false
                        },
                        "IOGroups": {
                            "read": true,
                            "create": false,
                            "status": false,
                            "update": true,
                            "archive": false
                        },
                        "Creatives": {
                            "read": true,
                            "create": false,
                            "status": false,
                            "update": true,
                            "archive": false
                        },
                        "HeaderBidding": {
                            "read": true,
                            "create": false,
                            "status": false,
                            "update": true,
                            "archive": false
                        },
                        "LineItems": {
                            "read": true,
                            "create": false,
                            "status": false,
                            "update": true,
                            "archive": false
                        },
                        "Advertisers": {
                            "read": true,
                            "create": false,
                            "status": false,
                            "update": true,
                            "archive": false
                        },
                        isAdveriserAccess: event.target.checked,
                        isIOGroupAccess: event.target.checked,
                        isIOsAccess: event.target.checked,
                        isLineItemAccess: event.target.checked,
                        isTCAccess: event.target.checked,
                        isCreativeAccess: event.target.checked,
                        isHeaderBiddingAccess: event.target.checked,
                    }
                }
            }))
    }

    const selectSupply = (event) => {
        setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isSupplyAccess: event.target.checked } }))
        if (event.target.checked === false) {
            setFinalData(finalData => ({
                ...finalData, rolePrivileges: {
                    ...finalData.rolePrivileges, Supply: {
                        ...finalData.rolePrivileges.Supply,
                        "AdUnits": {
                            "read": true,
                            "create": false,
                            "status": false,
                            "update": true,
                            "archive": false
                        },
                        "Publishers": {
                            "read": true,
                            "create": false,
                            "status": false,
                            "update": true,
                            "archive": false
                        },
                        "AdUnitGroups": {
                            "read": true,
                            "create": false,
                            "status": false,
                            "update": true,
                            "archive": false
                        },
                        "ChildAdUnits": {
                            "read": true,
                            "create": false,
                            "status": false,
                            "update": true,
                            "archive": false
                        },
                        "HighImpactSettings": {
                            "read": true,
                            "create": false,
                            "status": false,
                            "update": true,
                            "archive": false
                        },
                        "STDBSettings": {
                            "read": true,
                            "create": false,
                            "status": false,
                            "update": true,
                            "archive": false
                        },
                        "PlayerSettings": {
                            "read": true,
                            "create": false,
                            "status": false,
                            "update": true,
                            "archive": false
                        },
                        isPartnerAccess: false,
                        isPublisherAccess: false,
                        isAdUnitGroupAccess: false,
                        isAdUnitAccess: false,
                        isChildAdUnitAccess: false,
                        isHighImpactSettingAccess: false,
                        isSTDBSettingAccess: false,
                        isPlayerSettingAccess: false,
                        isTemplatesCustomAccess: false,
                        templatesSelected: [],
                        isSTDBTemplatesCustomAccess: false,
                        stdbTemplatesSelected: []
                    }
                }
            }))
            setTemplateSelect([])
            setSTDBTemplateSelect([]);
        }
    }

    const selectHouseAd = (event) => {
        setFinalData(finalData => ({
            ...finalData, rolePrivileges: {
                ...finalData.rolePrivileges, isHouseAdAccess: event.target.checked
            }
        }))
        event.target.checked ? '' :
            setFinalData(finalData => ({
                ...finalData, rolePrivileges: {
                    ...finalData.rolePrivileges, HouseAds: {
                        ...finalData.rolePrivileges.HouseAds,
                        isHouseAdsPublisherAccess: event,
                        isHouseAdsAdUnitAccess: event,
                        isHouseAdCreativeAccess: event,
                        isNativeAdAccess: event,
                        isHouseAdsCategoryAccess: event,
                        isFsrvAccess: event,
                    }
                }
            }))
    }

    const selectProxy = (event) => {
        setFinalData(finalData => ({
            ...finalData, rolePrivileges: {
                ...finalData.rolePrivileges, isProxyAdAccess: event.target.checked
            }
        }))
        event.target.checked ? '' :
            setFinalData(finalData => ({
                ...finalData, rolePrivileges: {
                    ...finalData.rolePrivileges, ProxyAds: {
                        ...finalData.rolePrivileges.ProxyAds,
                        isProxyAdUnitAccess: event,
                        isSsrvAccess: event,
                    }
                }
            }))
    }

    const selectReporting = (event) => {
        setFinalData(finalData => ({
            ...finalData, rolePrivileges: {
                ...finalData.rolePrivileges, isReportAccess: event.target.checked
            }
        }))
        event.target.checked ? '' :
            setFinalData(finalData => ({
                ...finalData, rolePrivileges: {
                    ...finalData.rolePrivileges, Report: {
                        ...finalData.rolePrivileges.Report,
                        isAnalyticReportAccess: event,
                        isPageUrlReportAccess: event,
                        isRevenueReportAccess: event,
                        isYMAnalyticReportAccess: event,
                        isASAnalyticReportAccess: event,
                        isMISAnalyticReportAccess: event,
                        isTeamPageUrlReportAccess: event,
                        isAgencyReportAccess: event,
                    }
                }
            }))
    }

    const selectReportUpload = (event) => {
        setFinalData(finalData => ({
            ...finalData, rolePrivileges: {
                ...finalData.rolePrivileges, isReportUploadingAccess: event.target.checked
            }
        }))
        event.target.checked ? '' :
            setFinalData(finalData => ({
                ...finalData, rolePrivileges: {
                    ...finalData.rolePrivileges, ReportUploading: {
                        ...finalData.rolePrivileges.ReportUploading,
                        isPublihserReportAccess: event,
                        isAgencyReportUploadAccess: event
                    }
                }
            }))
    }

    const selectBilling = (event) => {
        setFinalData(finalData => ({
            ...finalData, rolePrivileges: {
                ...finalData.rolePrivileges, isBillingAccess: event.target.checked
            }
        }))
        event.target.checked ? '' :
            setFinalData(finalData => ({
                ...finalData, rolePrivileges: {
                    ...finalData.rolePrivileges, Billing: {
                        ...finalData.rolePrivileges.Billing,
                        isInvoiceApproveAccess: false,
                        isInvoiceHistoryAccess: false,
                        isInvoicePaymentAccess: false,
                        "Invoices": {
                            "Approve": false,
                            "History": false,
                            "Payment": false
                        }
                    }
                }
            }))
    }

    const selectContextualSetting = (event) => {
        setFinalData(finalData => ({
            ...finalData, rolePrivileges: {
                ...finalData.rolePrivileges, isContextualSettingsAccess: event.target.checked
            }
        }))
        event.target.checked ? '' :
            setFinalData(finalData => ({
                ...finalData, rolePrivileges: {
                    ...finalData.rolePrivileges, ContextualSettings: {
                        ...finalData.rolePrivileges.ContextualSettings,
                        isBrandAffinityAccess: false,
                        isInterestAccess: false,
                        isKeywordAccess: false,
                        isPageUrlAccess: false,
                        isWebCategorizationAccess: false
                    }
                }
            }))
    }

    const selectTools = (event) => {
        setFinalData(finalData => ({
            ...finalData, rolePrivileges: {
                ...finalData.rolePrivileges, isToolsAccess: event.target.checked
            }
        }))
        event.target.checked ? '' :
            setFinalData(finalData => ({
                ...finalData, rolePrivileges: {
                    ...finalData.rolePrivileges, Tools: {
                        ...finalData.rolePrivileges.Tools,
                        isVideoCompresser: false,
                        isVideoContentLibrarayAccess: false,
                        isScreenShotToolAccess: false,
                        isAdTagTesterAccess: false,
                        isVastTagTesterAccess: false,
                        isAdsTxtValidatorAccess: false,
                        isLinkTester: false,
                        isPlayerDashBoardAccess: false,
                        isTextSummerizationAccess: false,
                        isTextTranslatorAccess: false,
                        isTextTransisterAccess: false,
                        isAiVideoGeneratorAccess: false
                    }
                }
            }))
    }

    const selectVCS = (event) => {
        setFinalData(finalData => ({
            ...finalData, rolePrivileges: {
                ...finalData.rolePrivileges, isVCsAccess: event.target.checked
            }
        }))
        event.target.checked ? '' :
            setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, VCs: { ...finalData.rolePrivileges.VCs, isVideoContentLibrarayAccess: false, isVideoPlayListAccess: false, isVideoImportAccess: false, isContentMarketplaceAccess: false } } }))
    }

    const selectAdmin = (event) => {
        setFinalData(finalData => ({
            ...finalData, rolePrivileges: {
                ...finalData.rolePrivileges, isAdminAccess: event.target.checked
            }
        }))
        event.target.checked ? '' :
            setFinalData(finalData => ({
                ...finalData, rolePrivileges: {
                    ...finalData.rolePrivileges,
                    adminAccess: {
                        ...finalData.rolePrivileges.adminAccess,
                        isRoleManagementAccess: false,
                        isUserManagementAccess: false,
                        isNotificationAccess: false,
                        isPublisherApprovalAccess: false,
                        isSupportTicketAccess: false,
                        "RoleManagement": {
                            "read": true,
                            "create": false,
                            "status": false,
                            "update": false,
                            "archive": false
                        },
                        "UserManagement": {
                            "read": true,
                            "create": false,
                            "status": false,
                            "update": false,
                            "archive": false
                        },
                        "Notification": {
                            "read": true,
                            "create": false,
                            "status": false,
                            "update": false,
                            "archive": false
                        },
                    }
                }
            }))
    }

    const changeData = (parent, child, action) => (e) => {
        const { name, value } = e.target;
        console.log(e.target.checked)
        setFinalData(finalData => ({
            ...finalData, rolePrivileges: {
                ...finalData.rolePrivileges,
                [parent]: {
                    ...finalData.rolePrivileges[parent],
                    [child]: { ...finalData.rolePrivileges[parent][child], [name]: e.target.checked }
                }
            }
        }));
    }


    const changeChildAccess = (parent, child, action) => (e) => {
        const { name, value } = e.target;
        console.log(e.target.checked)
        setFinalData(finalData => ({
            ...finalData, rolePrivileges: {
                ...finalData.rolePrivileges,
                [parent]: {
                    ...finalData.rolePrivileges[parent],
                    [child]: { ...finalData.rolePrivileges[parent][child], read: action, create: action, status: action, update: action, archive: action, }
                }
            }
        }));
    }

    const createUI = (parent, child) => {
        console.log(parent, child, finalData.rolePrivileges[`${parent}`][`${child}`])
        return (
            <div className="row">
                <Col md={2} className="mt-2 mb-2">
                    Select :  &nbsp; <Link to="#" className="text-blue link" onClick={changeChildAccess(`${parent}`, `${child}`, true)} style={{ cursor: 'pointer' }}>All</Link> &nbsp;&nbsp;
                    <Link to="#" className="text-blue link" onClick={changeChildAccess(`${parent}`, `${child}`, false)} style={{ cursor: 'pointer' }}>None</Link>
                </Col>
                <Col md={2}>
                    <div className="form-check mt-2 mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="read"
                            checked={finalData.rolePrivileges[`${parent}`][`${child}`]['read']}
                            onChange={changeData(`${parent}`, `${child}`, `read`)}
                            id={`${parent}${child}Read`}
                            disabled
                        />
                        <label
                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                            htmlFor={`${parent}${child}Read`}
                        >
                            View
                        </label>
                    </div>
                </Col>
                <Col md={2}>
                    <div className="form-check mt-2 mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="create"
                            checked={finalData.rolePrivileges[`${parent}`][`${child}`]['create']}
                            onChange={changeData(`${parent}`, `${child}`, `create`)}
                            id={`${parent}${child}Create`}
                        />
                        <label
                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                            htmlFor={`${parent}${child}Create`}
                        >
                            Create / Update
                        </label>
                    </div>
                </Col>
                <Col md={2}>
                    <div className="form-check mt-2 mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="status"
                            checked={finalData.rolePrivileges[`${parent}`][`${child}`]['status']}
                            onChange={changeData(`${parent}`, `${child}`, `status`)}
                            id={`${parent}${child}ActiveDeactivate`}
                        />
                        <label
                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                            htmlFor={`${parent}${child}ActiveDeactivate`}
                        >
                            Active / DeActivate
                        </label>
                    </div>
                </Col>
                <Col md={2}>
                    <div className="form-check mt-2 mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="archive"
                            checked={finalData.rolePrivileges[`${parent}`][`${child}`]['archive']}
                            onChange={changeData(`${parent}`, `${child}`, `archive`)}
                            id={`${parent}${child}Archive`}
                        />
                        <label
                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                            htmlFor={`${parent}${child}Archive`}
                        >
                            Archive
                        </label>
                    </div>
                </Col>
            </div>
        )

    }

    const { roles } = useSelector(state => ({
        roles: state.roles.roles,
    }));

    useEffect(() => {
        if (roles !== null) {
            dispatch(onGetRoles({ userRole: userInfo.userRole, filter: '' ,isSupplyPartner:userInfo.isSupplyPartner}));
        }
    }, []);

    useEffect(() => {
        if (roles && roles.length > 0) {
            setUserRolesData(objectModal(roles))
            console.log(roles)
        }
    }, [roles]);

    const objectModal = (response) => {
        var dataList = []
        response.map((item, index) => {
            let obj = {}
            obj.value = item.roleName
            obj.label = item.roleName
            dataList.push(obj)
        })
        return dataList
    }

    const { errorMsg, successMsg } = useSelector(state => ({
        errorMsg: state.roles.errorMsg,
        successMsg: state.roles.successMsg,
    }));

    console.log(errorMsg, successMsg)

    useEffect(() => {
        if (successMsg !== null) {
            if (successMsg.success) {
                dispatch(addRoleSuccess(""));
                dispatch(addRoleFail(null));
                dispatch(updateRoleSuccess(""));
                dispatch(updateRoleFail(null));
                setLoader(false)
                if (props.selectedRole !== '') {
                    props.closeCanvas('Update')
                } else {
                    props.closeCanvas('Create')
                }
            }
        }
    }, [successMsg]);

    useEffect(() => {
        if (errorMsg !== null && errorMsg !== '') {
            setLoader(false)
            if (errorMsg.status && errorMsg.status === 500) {
            } else {
                dispatch(addRoleSuccess(""));
                dispatch(addRoleFail(null));
                dispatch(updateRoleSuccess(""));
                dispatch(updateRoleFail(null));
            }
        }
    }, [errorMsg]);

    const toggleToast = (n) => (e) => {
        dispatch(addRoleSuccess(""));
        dispatch(addRoleFail(null));
        dispatch(updateRoleSuccess(""));
        dispatch(updateRoleFail(null));
        props.closeCanvas('')
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFinalData(finalData => ({ ...finalData, [name]: value }));
    }


    function handleSelectUserRoles(selectUserRoles) {
        setSelectedUserRoles(selectUserRoles);
        var filterBody = {
            userID: userInfo.userID,
            parentID: userInfo.parentID,
            userRole: selectUserRoles.value
        }
        axios.post(userInfo.isSupplyPartner ? '/v1.0/AMRole/SupplyPartner/copyRolePrivileges' : "/v1.0/AMRole/copyRolePrivileges", filterBody,
            {
                headers: headers
            })
            .then((res) => {
                console.log("cdsresult is ==>", res)
                if (res.data) {
                    setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, ...res.data } }));
                }

            })
            .catch((err) => {
                console.log("e", err)
            });
    }

    useEffect(() => {
        if (props.selectedRole !== '') {
            setIsEdit(true)
            console.log(finalData, props.selectedRole)
            let roleData = { ...finalData.rolePrivileges, ...props.selectedRole.rolePrivileges }
            console.log(roleData)
            setFinalData(finalData => ({ ...finalData, ...props.selectedRole }));
            setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...roleData } }));
            console.log(finalData)
            console.log(finalData.rolePrivileges['Demand']['Advertisers'])
            console.log(finalData.rolePrivileges['adminAccess']['RoleManagement'])
            if (props.selectedRole.rolePrivileges.Supply && !props.selectedRole.rolePrivileges.Supply.HighImpactSettings) {
                setFinalData(finalData => ({
                    ...finalData, rolePrivileges: {
                        ...finalData.rolePrivileges, Supply: {
                            ...finalData.rolePrivileges.Supply,
                            "HighImpactSettings": {
                                "read": true,
                                "create": false,
                                "status": false,
                                "update": false,
                                "archive": false
                            }, isHighImpactSettingAccess: false,
                        }
                    }
                }))
            }
            if (props.selectedRole.rolePrivileges.Supply && !props.selectedRole.rolePrivileges.Supply.PlayerSettings) {
                setFinalData(finalData => ({
                    ...finalData, rolePrivileges: {
                        ...finalData.rolePrivileges, Supply: {
                            ...finalData.rolePrivileges.Supply,
                            "PlayerSettings": {
                                "read": true,
                                "create": false,
                                "status": false,
                                "update": false,
                                "archive": false
                            },
                            isPlayerSettingAccess: false
                        }
                    }
                }))
            }
            if (props.selectedRole.rolePrivileges.Supply && !props.selectedRole.rolePrivileges.Supply.STDBSettings) {
                setFinalData(finalData => ({
                    ...finalData, rolePrivileges: {
                        ...finalData.rolePrivileges, Supply: {
                            ...finalData.rolePrivileges.Supply,
                            "STDBSettings": {
                                "read": true,
                                "create": false,
                                "status": false,
                                "update": false,
                                "archive": false
                            }, isSTDBSettingAccess: false,
                        }
                    }
                }))
            }
            if (props.selectedRole.rolePrivileges.Demand && !props.selectedRole.rolePrivileges.Demand.HeaderBidding) {
                setFinalData(finalData => ({
                    ...finalData, rolePrivileges: {
                        ...finalData.rolePrivileges, Demand: {
                            ...finalData.rolePrivileges.Demand,
                            "HeaderBidding": {
                                "read": true,
                                "create": false,
                                "status": false,
                                "update": false,
                                "archive": false
                            }, isHeaderBiddingAccess: false,
                        }
                    }
                }))
            }
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (finalData.roleName) {
            setLoader(true)
            if (props.selectedRole !== '') {
                dispatch(onUpdateRole({
                    ...finalData, roleName: finalData.roleName.trim(),
                    userRole: userInfo.userRole,isSupplyPartner:userInfo.isSupplyPartner
                }));
            } else {
                dispatch(onADDRole({
                    ...finalData, roleName: finalData.roleName.trim(), userID: userInfo.userID,
                    userRole: userInfo.userRole,isSupplyPartner:userInfo.isSupplyPartner
                }));
            }
        }
    }

    const customStyles = {
        control: (styles, { isDisabled }) => {
            return {
                ...styles,
                cursor: isDisabled ? 'not-allowed' : 'default',
                color: isDisabled ? '#aaa' : 'white'
            }
        },
        option: (styles, { isDisabled }) => {
            return {
                ...styles,
                color: isDisabled ? '#000' : '#000',
                cursor: isDisabled ? 'not-allowed' : 'default',
            };
        },
    };

    return (
        <React.Fragment>
            <div className="col-12">
                {loader &&
                    <Spinner className="ms-2 loader" color="primary" />
                }
                <Form onSubmit={handleSubmit}>
                    <div className="formBodyScroll" style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
                        <Col xs={12}>
                            <div className="mb-3 ">
                                <Label className="form-label"> Name</Label>
                                <Input
                                    name="roleName"
                                    type="text"
                                    value={finalData.roleName}
                                    onChange={handleChange}
                                    className={'form-control' + (submitted && !finalData.roleName ? ' is-invalid' : '')}
                                />
                                {submitted && !finalData.roleName && (
                                    <FormFeedback type="invalid">{'Name is required'}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3 ">
                                <Label className="form-label"> Copy Permission from role</Label>
                                <Select
                                    value={selectedUserRoles}
                                    onChange={s => {
                                        handleSelectUserRoles(s)
                                    }}
                                    options={userRolesData}
                                    classNamePrefix="select2-selection"
                                    isDisabled={props.selectedArchived}
                                />
                            </div>
                            <div className="mb-3 ">
                                <Label className="form-label"> Description</Label>
                                <Input
                                    type="text"
                                    name="roleDescription"
                                    value={finalData.roleDescription}
                                    onChange={handleChange}
                                />
                            </div>
                        </Col>
                        <hr />
                        <h5>Access</h5>
                        <div>
                            <Row className="m-4">
                                <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                                    <h2 className="accordion-header m-0" id="headingOne">
                                        <label className=" d-sm-flex  justify-content-between form-check-label  m-0 settingsButton" htmlFor="isDashboardAccess">
                                            <h6 className="heading6 m-3">Dashboards</h6>
                                            <div className="text-sm-end">
                                                <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={finalData.rolePrivileges.isDashboardAccess}
                                                        onChange={selectDashboard}
                                                        id="isDashboardAccess"
                                                    />
                                                </div>
                                            </div>
                                        </label>
                                    </h2>
                                    <Collapse isOpen={finalData.rolePrivileges.isDashboardAccess} className="accordion-collapse">
                                        <div className="accordion-body">
                                            <div className="row">
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Dashboard.isRevenueAccess}
                                                            onChange={(e) => setFinalData(finalData => ({
                                                                ...finalData, rolePrivileges: {
                                                                    ...finalData.rolePrivileges, Dashboard: {
                                                                        ...finalData.rolePrivileges.Dashboard,
                                                                        isRevenueAccess: e.target.checked
                                                                    }
                                                                }
                                                            }))}
                                                            id="marketplaceDashboard"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="marketplaceDashboard"
                                                        >
                                                            Marketplace Dashboard
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Dashboard.isAnalyticsAccess}
                                                            onChange={(e) => setFinalData(finalData => ({
                                                                ...finalData, rolePrivileges: {
                                                                    ...finalData.rolePrivileges, Dashboard: {
                                                                        ...finalData.rolePrivileges.Dashboard,
                                                                        isAnalyticsAccess: e.target.checked
                                                                    }
                                                                }
                                                            }))}
                                                            id="analytics"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="analytics"
                                                        >
                                                            Server Dashboard
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Dashboard.isAgencyAccess}
                                                            onChange={(e) => setFinalData(finalData => ({
                                                                ...finalData, rolePrivileges: {
                                                                    ...finalData.rolePrivileges, Dashboard: {
                                                                        ...finalData.rolePrivileges.Dashboard,
                                                                        isAgencyAccess: e.target.checked
                                                                    }
                                                                }
                                                            }))}
                                                            id="isAgencyAccess"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="isAgencyAccess"
                                                        >
                                                            Agency Dashboard
                                                        </label>
                                                    </div>
                                                </Col>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </Row>
                            <Row className="m-4">
                                <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                                    <h2 className="accordion-header m-0" id="headingOne">
                                        <label className=" d-sm-flex  justify-content-between form-check-label  m-0 settingsButton" htmlFor="swithUserManagement">
                                            <h6 className="heading6 m-3">Switch User Management</h6>
                                            <div className="text-sm-end">
                                                <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={finalData.rolePrivileges.isSwitchManagementAccess}
                                                        onChange={selectSwitchManagement}
                                                        id="swithUserManagement"
                                                    />
                                                </div>
                                            </div>
                                        </label>
                                    </h2>
                                    <Collapse isOpen={finalData.rolePrivileges.isSwitchManagementAccess} className="accordion-collapse">
                                        <div className="accordion-body">
                                            <div className="row">
                                                <Col md={3}>
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.SwitchAccount.isPartnerAccountAccess}
                                                            onChange={(e) => {
                                                                setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, SwitchAccount: { ...finalData.rolePrivileges.SwitchAccount, isPartnerAccountAccess: e.target.checked } } }));
                                                            }
                                                            }
                                                            id="isPartnerAccountAccess"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="isPartnerAccountAccess"
                                                        >
                                                            Partner Accounts
                                                        </label>
                                                    </div>
                                                </Col>

                                                <Col md={3}>
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.SwitchAccount.isAgencyAccountAccess}
                                                            onChange={(e) => {
                                                                setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, SwitchAccount: { ...finalData.rolePrivileges.SwitchAccount, isAgencyAccountAccess: e.target.checked } } }));
                                                            }
                                                            }
                                                            id="isAgencyAccountAccess"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="isAgencyAccountAccess"
                                                        >
                                                            Agency Accounts
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.SwitchAccount.isUserAccountAccess}
                                                            onChange={(e) => {
                                                                setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, SwitchAccount: { ...finalData.rolePrivileges.SwitchAccount, isUserAccountAccess: e.target.checked } } }));
                                                            }
                                                            }
                                                            id="isUserAccountAccess"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="isUserAccountAccess"
                                                        >
                                                            User Accounts
                                                        </label>
                                                    </div>

                                                </Col>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </Row>
                            <Row className="m-4">
                                <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                                    <h2 className="accordion-header m-0" id="headingOne">
                                        <label className=" d-sm-flex  justify-content-between form-check-label  m-0 settingsButton" htmlFor="isDemandAccess">
                                            <h6 className="heading6 m-3">Demand</h6>
                                            <div className="text-sm-end">
                                                <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={finalData.rolePrivileges.isDemandAccess}
                                                        onChange={selectDemand}
                                                        id="isDemandAccess"
                                                    />
                                                </div>
                                            </div>
                                        </label>
                                    </h2>
                                    <Collapse isOpen={finalData.rolePrivileges.isDemandAccess} className="accordion-collapse">
                                        <div className="accordion-body">
                                            <div>
                                                {/* <Col md={12} className="m-1">
                                                    Select :  &nbsp; <Link to="#" className="text-blue link" onClick={selectDemand(true)} style={{ cursor: 'pointer' }}>All</Link> &nbsp;&nbsp;
                                                    <Link to="#" className="text-blue link" onClick={selectDemand(false)} style={{ cursor: 'pointer' }}>None</Link>
                                                </Col> */}
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={2}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.Demand.isAdveriserAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({
                                                                        ...finalData, rolePrivileges: {
                                                                            ...finalData.rolePrivileges, Demand: {
                                                                                ...finalData.rolePrivileges.Demand,
                                                                                isAdveriserAccess: e.target.checked
                                                                            }
                                                                        }
                                                                    }))}
                                                                    id="advertiser"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="advertiser"
                                                                >
                                                                    Advertiser
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        {finalData.rolePrivileges.Demand.isAdveriserAccess &&
                                                            <Col md={10} >
                                                                {createUI('Demand', 'Advertisers')}
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={2}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.Demand.isIOGroupAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({
                                                                        ...finalData, rolePrivileges: {
                                                                            ...finalData.rolePrivileges, Demand: {
                                                                                ...finalData.rolePrivileges.Demand,
                                                                                isIOGroupAccess: e.target.checked
                                                                            }
                                                                        }
                                                                    }))}
                                                                    id="IoGroup"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="IoGroup"
                                                                >
                                                                    IO Group
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        {finalData.rolePrivileges.Demand.isIOGroupAccess &&
                                                            <Col md={10} >
                                                                {createUI('Demand', 'IOGroups')}
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={2}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.Demand.isIOsAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({
                                                                        ...finalData, rolePrivileges: {
                                                                            ...finalData.rolePrivileges, Demand: {
                                                                                ...finalData.rolePrivileges.Demand,
                                                                                isIOsAccess: e.target.checked
                                                                            }
                                                                        }
                                                                    }))}
                                                                    id="insertionOrder"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="insertionOrder"
                                                                >
                                                                    Insertion Order
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        {finalData.rolePrivileges.Demand.isIOsAccess &&
                                                            <Col md={10} >
                                                                {createUI('Demand', 'IOs')}
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={2}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.Demand.isLineItemAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({
                                                                        ...finalData, rolePrivileges: {
                                                                            ...finalData.rolePrivileges, Demand: {
                                                                                ...finalData.rolePrivileges.Demand,
                                                                                isLineItemAccess: e.target.checked
                                                                            }
                                                                        }
                                                                    }))}
                                                                    id="lineItem"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="lineItem"
                                                                >
                                                                    Line Item
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        {finalData.rolePrivileges.Demand.isLineItemAccess &&
                                                            <Col md={10} >
                                                                {createUI('Demand', 'LineItems')}
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={2}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.Demand.isCreativeAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({
                                                                        ...finalData, rolePrivileges: {
                                                                            ...finalData.rolePrivileges, Demand: {
                                                                                ...finalData.rolePrivileges.Demand,
                                                                                isCreativeAccess: e.target.checked
                                                                            }
                                                                        }
                                                                    }))}
                                                                    id="creative"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="creative"
                                                                >
                                                                    Creative
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        {finalData.rolePrivileges.Demand.isCreativeAccess &&
                                                            <Col md={10} >
                                                                {createUI('Demand', 'Creatives')}
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>

                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={2}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.Demand.isHeaderBiddingAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({
                                                                        ...finalData, rolePrivileges: {
                                                                            ...finalData.rolePrivileges, Demand: {
                                                                                ...finalData.rolePrivileges.Demand,
                                                                                isHeaderBiddingAccess: e.target.checked
                                                                            }
                                                                        }
                                                                    }))}
                                                                    id="HeaderBidding"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="HeaderBidding"
                                                                >
                                                                    Header Bidding
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        {finalData.rolePrivileges.Demand.isHeaderBiddingAccess &&
                                                            <Col md={10} >
                                                                {createUI('Demand', 'HeaderBidding')}
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </Row>
                            <Row className="m-4">
                                <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                                    <h2 className="accordion-header m-0" id="headingOne">
                                        <label className=" d-sm-flex  justify-content-between form-check-label  m-0 settingsButton" htmlFor="isSupplyAccess">
                                            <h6 className="heading6 m-3">Supply</h6>
                                            <div className="text-sm-end">
                                                <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={finalData.rolePrivileges.isSupplyAccess}
                                                        onChange={selectSupply}
                                                        id="isSupplyAccess"
                                                    />
                                                </div>
                                            </div>
                                        </label>
                                    </h2>
                                    <Collapse isOpen={finalData.rolePrivileges.isSupplyAccess} className="accordion-collapse">
                                        <div className="accordion-body">
                                            <div>
                                                {/* <Col md={12} className="m-1">
                                                    Select :  &nbsp; <Link to="#" className="text-blue link" onClick={selectSupply(true)} style={{ cursor: 'pointer' }}>All</Link> &nbsp;&nbsp;
                                                    <Link to="#" className="text-blue link" onClick={selectSupply(false)} style={{ cursor: 'pointer' }}>None</Link>
                                                </Col> */}
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={2}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.Supply.isPublisherAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({
                                                                        ...finalData, rolePrivileges: {
                                                                            ...finalData.rolePrivileges, Supply: {
                                                                                ...finalData.rolePrivileges.Supply,
                                                                                isPublisherAccess: e.target.checked
                                                                            }
                                                                        }
                                                                    }))}
                                                                    id="supplyPublisher"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="supplyPublisher"
                                                                >
                                                                    Publisher
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        {finalData.rolePrivileges.Supply.isPublisherAccess &&
                                                            <Col md={10} >
                                                                {createUI('Supply', 'Publishers')}
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={2}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.Supply.isAdUnitGroupAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({
                                                                        ...finalData, rolePrivileges: {
                                                                            ...finalData.rolePrivileges, Supply: {
                                                                                ...finalData.rolePrivileges.Supply,
                                                                                isAdUnitGroupAccess: e.target.checked
                                                                            }
                                                                        }
                                                                    }))}
                                                                    id="supplyAdunitGroup"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="supplyAdunitGroup"
                                                                >
                                                                    Ad Unit Group
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        {finalData.rolePrivileges.Supply.isAdUnitGroupAccess &&
                                                            <Col md={10} >
                                                                {createUI('Supply', 'AdUnitGroups')}
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={2}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.Supply.isAdUnitAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({
                                                                        ...finalData, rolePrivileges: {
                                                                            ...finalData.rolePrivileges, Supply: {
                                                                                ...finalData.rolePrivileges.Supply,
                                                                                isAdUnitAccess: e.target.checked
                                                                            }
                                                                        }
                                                                    }))}
                                                                    id="supplyAdUnit"

                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="supplyAdUnit"
                                                                >
                                                                    Ad Unit
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        {finalData.rolePrivileges.Supply.isAdUnitAccess &&
                                                            <Col md={10} >
                                                                {createUI('Supply', 'AdUnits')}
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>

                                                <div>
                                                    <Col md={12} className="m-1">
                                                        <Row>
                                                            <Col md={2}>
                                                                <div className="form-check mt-2 mb-2">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        checked={finalData.rolePrivileges.Supply.isChildAdUnitAccess}
                                                                        onChange={(e) => setFinalData(finalData => ({
                                                                            ...finalData, rolePrivileges: {
                                                                                ...finalData.rolePrivileges, Supply: {
                                                                                    ...finalData.rolePrivileges.Supply,
                                                                                    isChildAdUnitAccess: e.target.checked
                                                                                }
                                                                            }
                                                                        }))}
                                                                        id="supplyChildUnit"
                                                                    // disabled={props.selectedPartner.partnerInvertoryType === 'video'}
                                                                    />
                                                                    <label
                                                                        className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                        htmlFor="supplyChildUnit"
                                                                    >
                                                                        Child AdUnit
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            {finalData.rolePrivileges.Supply.isChildAdUnitAccess &&
                                                                <Col md={10} >
                                                                    {createUI('Supply', 'ChildAdUnits')}
                                                                </Col>
                                                            }
                                                        </Row>
                                                    </Col>

                                                    <Col md={12} className="m-1">
                                                        <Row>
                                                            <Col md={2}>
                                                                <div className="form-check mt-2 mb-2">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        checked={finalData.rolePrivileges.Supply.isHighImpactSettingAccess}
                                                                        onChange={(e) => setFinalData(finalData => ({
                                                                            ...finalData, rolePrivileges: {
                                                                                ...finalData.rolePrivileges, Supply: {
                                                                                    ...finalData.rolePrivileges.Supply,
                                                                                    isHighImpactSettingAccess: e.target.checked
                                                                                }
                                                                            }
                                                                        }))}
                                                                        id="isHighImpactSettingAccess"
                                                                    />
                                                                    <label
                                                                        className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                        htmlFor="isHighImpactSettingAccess"
                                                                    >
                                                                        High Impact Settings
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            {finalData.rolePrivileges.Supply.isHighImpactSettingAccess &&
                                                                <Col md={10} >
                                                                    {createUI('Supply', 'HighImpactSettings')}
                                                                </Col>
                                                            }
                                                        </Row>
                                                    </Col>

                                                    <Col md={12} className="m-1">
                                                        <Row>
                                                            <Col md={2}>
                                                                <div className="form-check mt-2 mb-2">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        checked={finalData.rolePrivileges.Supply.isSTDBSettingAccess}
                                                                        onChange={(e) => setFinalData(finalData => ({
                                                                            ...finalData, rolePrivileges: {
                                                                                ...finalData.rolePrivileges, Supply: {
                                                                                    ...finalData.rolePrivileges.Supply,
                                                                                    isSTDBSettingAccess: e.target.checked
                                                                                }
                                                                            }
                                                                        }))}
                                                                        id="isSTDBSettingAccess"
                                                                    />
                                                                    <label
                                                                        className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                        htmlFor="isSTDBSettingAccess"
                                                                    >
                                                                        Standard Banner Settings
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            {finalData.rolePrivileges.Supply.isSTDBSettingAccess &&
                                                                <Col md={10} >
                                                                    {createUI('Supply', 'STDBSettings')}
                                                                </Col>
                                                            }
                                                        </Row>
                                                    </Col>
                                                </div>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={2}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.Supply.isPlayerSettingAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({
                                                                        ...finalData, rolePrivileges: {
                                                                            ...finalData.rolePrivileges, Supply: {
                                                                                ...finalData.rolePrivileges.Supply,
                                                                                isPlayerSettingAccess: e.target.checked
                                                                            }
                                                                        }
                                                                    }))}
                                                                    id="isPlayerSettingAccess"
                                                                // disabled={props.selectedPartner.partnerInvertoryType === 'display'}
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="isPlayerSettingAccess"
                                                                >
                                                                    Player Settings
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        {finalData.rolePrivileges.Supply.isPlayerSettingAccess &&
                                                            <Col md={10} >
                                                                {createUI('Supply', 'PlayerSettings')}
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </Row>
                            <Row className="m-4">
                                <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                                    <h2 className="accordion-header m-0" id="headingOne">
                                        <label className=" d-sm-flex  justify-content-between form-check-label  m-0 settingsButton" htmlFor="houseAds">
                                            <h6 className="heading6 m-3">House Ads</h6>
                                            <div className="text-sm-end">
                                                <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={finalData.rolePrivileges.isHouseAdAccess}
                                                        onChange={selectHouseAd}
                                                        id="houseAds"
                                                    />
                                                </div>
                                            </div>
                                        </label>
                                    </h2>
                                    <Collapse isOpen={finalData.rolePrivileges.isHouseAdAccess} className="accordion-collapse">
                                        <div className="accordion-body">
                                            <div>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={2}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.HouseAds.isHouseAdsPublisherAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({
                                                                        ...finalData, rolePrivileges: {
                                                                            ...finalData.rolePrivileges, HouseAds: {
                                                                                ...finalData.rolePrivileges.HouseAds,
                                                                                isHouseAdsPublisherAccess: e.target.checked
                                                                            }
                                                                        }
                                                                    }))}
                                                                    id="housePublisher"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="housePublisher"
                                                                >
                                                                    Publisher
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        {finalData.rolePrivileges.HouseAds.isHouseAdsPublisherAccess &&
                                                            <Col md={10} >
                                                                {createUI('HouseAds', 'HouseAdsPublishers')}
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={2}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.HouseAds.isHouseAdsAdUnitAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({
                                                                        ...finalData, rolePrivileges: {
                                                                            ...finalData.rolePrivileges, HouseAds: {
                                                                                ...finalData.rolePrivileges.HouseAds,
                                                                                isHouseAdsAdUnitAccess: e.target.checked
                                                                            }
                                                                        }
                                                                    }))}
                                                                    id="houseAdUnit"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="houseAdUnit"
                                                                >
                                                                    Ad Unit
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        {finalData.rolePrivileges.HouseAds.isHouseAdsAdUnitAccess &&
                                                            <Col md={10} >
                                                                {createUI('HouseAds', 'HouseAdsAdUnits')}
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={2}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.HouseAds.isHouseAdCreativeAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({
                                                                        ...finalData, rolePrivileges: {
                                                                            ...finalData.rolePrivileges, HouseAds: {
                                                                                ...finalData.rolePrivileges.HouseAds,
                                                                                isHouseAdCreativeAccess: e.target.checked
                                                                            }
                                                                        }
                                                                    }))}
                                                                    id="houseCreatives"

                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="houseCreatives"
                                                                >
                                                                    Creatives
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        {finalData.rolePrivileges.HouseAds.isHouseAdCreativeAccess &&
                                                            <Col md={10} >
                                                                {createUI('HouseAds', 'HouseAdsCreatives')}
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={2}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.HouseAds.isNativeAdAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({
                                                                        ...finalData, rolePrivileges: {
                                                                            ...finalData.rolePrivileges, HouseAds: {
                                                                                ...finalData.rolePrivileges.HouseAds,
                                                                                isNativeAdAccess: e.target.checked
                                                                            }
                                                                        }
                                                                    }))}
                                                                    id="houseNativeAd"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="houseNativeAd"
                                                                >
                                                                    Native Ad
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        {finalData.rolePrivileges.HouseAds.isNativeAdAccess &&
                                                            <Col md={10} >
                                                                {createUI('HouseAds', 'NativeAds')}
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={2}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.HouseAds.isHouseAdsCategoryAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({
                                                                        ...finalData, rolePrivileges: {
                                                                            ...finalData.rolePrivileges, HouseAds: {
                                                                                ...finalData.rolePrivileges.HouseAds,
                                                                                isHouseAdsCategoryAccess: e.target.checked
                                                                            }
                                                                        }
                                                                    }))}
                                                                    id="houseCategories"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="houseCategories"
                                                                >
                                                                    Categories
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        {finalData.rolePrivileges.HouseAds.isHouseAdsCategoryAccess &&
                                                            <Col md={10} >
                                                                {createUI('HouseAds', 'HouseAdsCategories')}
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={2}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.HouseAds.isFsrvAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({
                                                                        ...finalData, rolePrivileges: {
                                                                            ...finalData.rolePrivileges, HouseAds: {
                                                                                ...finalData.rolePrivileges.HouseAds,
                                                                                isFsrvAccess: e.target.checked
                                                                            }
                                                                        }
                                                                    }))}
                                                                    id="houseReport"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="houseReport"
                                                                >
                                                                    Report
                                                                </label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </Row>
                            <Row className="m-4">
                                <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                                    <h2 className="accordion-header m-0" id="headingOne">
                                        <label className=" d-sm-flex  justify-content-between form-check-label  m-0 settingsButton" htmlFor="proxy">
                                            <h6 className="heading6 m-3">Proxy</h6>
                                            <div className="text-sm-end">
                                                <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={finalData.rolePrivileges.isProxyAdAccess}
                                                        onChange={selectProxy}
                                                        id="proxy"
                                                    />
                                                </div>
                                            </div>
                                        </label>
                                    </h2>
                                    <Collapse isOpen={finalData.rolePrivileges.isProxyAdAccess} className="accordion-collapse">
                                        <div className="accordion-body">
                                            <div>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={2}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.ProxyAds.isProxyAdUnitAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({
                                                                        ...finalData, rolePrivileges: {
                                                                            ...finalData.rolePrivileges, ProxyAds: {
                                                                                ...finalData.rolePrivileges.ProxyAds,
                                                                                isProxyAdUnitAccess: e.target.checked
                                                                            }
                                                                        }
                                                                    }))}
                                                                    id="proxyAdUnit"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="proxyAdUnit"
                                                                >

                                                                    Ad Unit
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        {finalData.rolePrivileges.ProxyAds.isProxyAdUnitAccess &&
                                                            <Col md={10} >
                                                                {createUI('ProxyAds', 'ProxyAdUnits')}
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={2}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.ProxyAds.isSsrvAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({
                                                                        ...finalData, rolePrivileges: {
                                                                            ...finalData.rolePrivileges, ProxyAds: {
                                                                                ...finalData.rolePrivileges.ProxyAds,
                                                                                isSsrvAccess: e.target.checked
                                                                            }
                                                                        }
                                                                    }))}
                                                                    id="proxyReport"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="proxyReport"
                                                                >
                                                                    Report
                                                                </label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </Row>
                            <Row className="m-4">
                                <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                                    <h2 className="accordion-header m-0" id="headingOne">
                                        <label className=" d-sm-flex  justify-content-between form-check-label  m-0 settingsButton" htmlFor="reporting">
                                            <h6 className="heading6 m-3">Reporting</h6>
                                            <div className="text-sm-end">
                                                <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={finalData.rolePrivileges.isReportAccess}
                                                        onChange={selectReporting}
                                                        id="reporting"
                                                    />
                                                </div>
                                            </div>
                                        </label>
                                    </h2>
                                    <Collapse isOpen={finalData.rolePrivileges.isReportAccess} className="accordion-collapse">
                                        <div className="accordion-body">
                                            <div className="row">
                                                <Col md={3}>
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Report.isAnalyticReportAccess}
                                                            onChange={(e) => {
                                                                setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, isAnalyticReportAccess: e.target.checked } } }));
                                                                setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, Query: { ...finalData.rolePrivileges.Report.Query, Analytic: e.target.checked } } } }));
                                                            }
                                                            }
                                                            id="reportingAnalyticsReport"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="reportingAnalyticsReport"
                                                        >
                                                            Analytics Report
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Report.isPageUrlReportAccess}
                                                            onChange={(e) => {
                                                                setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, isPageUrlReportAccess: e.target.checked } } }))
                                                                setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, Query: { ...finalData.rolePrivileges.Report.Query, Pageurl: e.target.checked } } } }));
                                                            }}
                                                            id="PageUrlReport"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="PageUrlReport"
                                                        >
                                                            Page URL  Report
                                                        </label>
                                                    </div>

                                                </Col>
                                                <Col md={3}>
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Report.isRevenueReportAccess}
                                                            onChange={(e) => {
                                                                setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, isRevenueReportAccess: e.target.checked } } }))
                                                                setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, Query: { ...finalData.rolePrivileges.Report.Query, Revenue: e.target.checked } } } }));
                                                            }}
                                                            id="revenueReport"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="revenueReport"
                                                        >
                                                            Revenue Report
                                                        </label>
                                                    </div>

                                                </Col>
                                                <Col md={3}>
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Report.isYMAnalyticReportAccess}
                                                            onChange={(e) => {
                                                                setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, isYMAnalyticReportAccess: e.target.checked } } }));
                                                            }
                                                            }
                                                            id="ymreportingAnalyticsReport"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="ymreportingAnalyticsReport"
                                                        >
                                                            YM Analytics Report
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Report.isASAnalyticReportAccess}
                                                            onChange={(e) => {
                                                                setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, isASAnalyticReportAccess: e.target.checked } } }));
                                                            }
                                                            }
                                                            id="asreportingAnalyticsReport"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="asreportingAnalyticsReport"
                                                        >
                                                            AS Analytics Report
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Report.isMISAnalyticReportAccess}
                                                            onChange={(e) => {
                                                                setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, isMISAnalyticReportAccess: e.target.checked } } }));
                                                            }
                                                            }
                                                            id="misreportingAnalyticsReport"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="misreportingAnalyticsReport"
                                                        >
                                                            MIS Analytics Report
                                                        </label>
                                                    </div>

                                                </Col>
                                                <Col md={3}>
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Report.isTeamPageUrlReportAccess}
                                                            onChange={(e) => {
                                                                setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, isTeamPageUrlReportAccess: e.target.checked } } }))
                                                            }}
                                                            id="isTeamPageUrlReportAccess"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="isTeamPageUrlReportAccess"
                                                        >
                                                            Team Page URL Report
                                                        </label>
                                                    </div>

                                                </Col>
                                                <Col md={3}>
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Report.isAgencyReportAccess}
                                                            onChange={(e) => {
                                                                setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Report: { ...finalData.rolePrivileges.Report, isAgencyReportAccess: e.target.checked } } }))
                                                            }}
                                                            id="isAgencyReportAccess"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="isAgencyReportAccess"
                                                        >
                                                            Agency Report
                                                        </label>
                                                    </div>

                                                </Col>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </Row>
                            <Row className="m-4">
                                <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                                    <h2 className="accordion-header m-0" id="headingOne">
                                        <label className=" d-sm-flex  justify-content-between form-check-label  m-0 settingsButton" htmlFor="reportUpload">
                                            <h6 className="heading6 m-3">Report Upload</h6>
                                            <div className="text-sm-end">
                                                <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={finalData.rolePrivileges.isReportUploadingAccess}
                                                        onChange={selectReportUpload}
                                                        id="reportUpload"
                                                    />
                                                </div>
                                            </div>
                                        </label>
                                    </h2>
                                    <Collapse isOpen={finalData.rolePrivileges.isReportUploadingAccess} className="accordion-collapse">
                                        <div className="accordion-body">
                                            <div className="row">
                                                <Col md={6}>

                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.ReportUploading.isPublihserReportAccess}
                                                            onChange={(e) => {
                                                                setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, ReportUploading: { ...finalData.rolePrivileges.ReportUploading, isPublihserReportAccess: e.target.checked } } }));
                                                            }
                                                            }
                                                            id="isPublihserReportAccess"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="isPublihserReportAccess"
                                                        >
                                                            Publisher Report Upload
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.ReportUploading.isAgencyReportUploadAccess}
                                                            onChange={(e) => {
                                                                setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, ReportUploading: { ...finalData.rolePrivileges.ReportUploading, isAgencyReportUploadAccess: e.target.checked } } }));
                                                            }
                                                            }
                                                            id="isAgencyReportUploadAccess"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="isAgencyReportUploadAccess"
                                                        >
                                                            Agency Report Upload
                                                        </label>
                                                    </div>

                                                </Col>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </Row>
                            <Row className="m-4">
                                <Col md={2} className='p-0'>
                                    <div className="form-check mt-2 mb-2">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={finalData.rolePrivileges.isPartnerManagementAccess}
                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isPartnerManagementAccess: e.target.checked } }))}
                                            id="partnerMangement"
                                        />
                                        <label
                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                            htmlFor="partnerMangement"
                                        >
                                            Partner Management
                                        </label>
                                    </div>
                                </Col>
                                {finalData.rolePrivileges.isPartnerManagementAccess &&
                                    <Col md={10} >
                                        {createUI('PartnerManagement', 'PartnerAccess')}
                                    </Col>
                                }
                            </Row>
                            <Row className="m-4">
                                <Col md={2} className='p-0'>
                                    <div className="form-check mt-2 mb-2">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={finalData.rolePrivileges.isTagControllerAccess}
                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isTagControllerAccess: e.target.checked } }))}
                                            id="tagController"
                                        />
                                        <label
                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                            htmlFor="tagController"
                                        >
                                            Tag Controller
                                        </label>
                                    </div>
                                </Col>
                                {finalData.rolePrivileges.isTagControllerAccess &&
                                    <Col md={10} >
                                        {createUI('TagController', 'TCs')}
                                    </Col>
                                }
                            </Row>
                            <Row className="m-4">
                                <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                                    <h2 className="accordion-header m-0" id="headingOne">
                                        <label className=" d-sm-flex  justify-content-between form-check-label  m-0 settingsButton" htmlFor="billing">
                                            <h6 className="heading6 m-3">Billing</h6>
                                            <div className="text-sm-end">
                                                <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={finalData.rolePrivileges.isBillingAccess}
                                                        onChange={selectBilling}
                                                        id="billing"
                                                    />
                                                </div>
                                            </div>
                                        </label>
                                    </h2>
                                    <Collapse isOpen={finalData.rolePrivileges.isBillingAccess} className="accordion-collapse">
                                        <div className="accordion-body">
                                            <div className="row">
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Billing.isInvoiceApproveAccess}
                                                            onChange={(e) => {
                                                                setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Billing: { ...finalData.rolePrivileges.Billing, isInvoiceApproveAccess: e.target.checked } } }))
                                                                setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Billing: { ...finalData.rolePrivileges.Billing, Invoices: { ...finalData.rolePrivileges.Billing.Invoices, Approve: e.target.checked } } } }));
                                                            }}
                                                            id="billingInvoiceApproval"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="billingInvoiceApproval"
                                                        >
                                                            Invoices Approval
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Billing.isInvoiceHistoryAccess}
                                                            onChange={(e) => {
                                                                setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Billing: { ...finalData.rolePrivileges.Billing, isInvoiceHistoryAccess: e.target.checked } } }))
                                                                setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Billing: { ...finalData.rolePrivileges.Billing, Invoices: { ...finalData.rolePrivileges.Billing.Invoices, History: e.target.checked } } } }));
                                                            }}
                                                            id="billingInvoiceHistory"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="billingInvoiceHistory"
                                                        >
                                                            Invoice History
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Billing.isInvoicePaymentAccess}
                                                            onChange={(e) => {
                                                                setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Billing: { ...finalData.rolePrivileges.Billing, isInvoicePaymentAccess: e.target.checked } } }))
                                                                setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Billing: { ...finalData.rolePrivileges.Billing, Invoices: { ...finalData.rolePrivileges.Billing.Invoices, Payment: e.target.checked } } } }));
                                                            }}
                                                            id="billingPayments"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="billingPayments"
                                                        >
                                                            Payments
                                                        </label>
                                                    </div>
                                                </Col>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </Row>
                            <Row className="m-4">
                                <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                                    <h2 className="accordion-header m-0" id="headingOne">
                                        <label className=" d-sm-flex  justify-content-between form-check-label  m-0 settingsButton" htmlFor="contextualSettings">
                                            <h6 className="heading6 m-3">Contextual Settings</h6>
                                            <div className="text-sm-end">
                                                <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={finalData.rolePrivileges.isContextualSettingsAccess}
                                                        onChange={selectContextualSetting}
                                                        id="contextualSettings"
                                                    />
                                                </div>
                                            </div>
                                        </label>
                                    </h2>
                                    <Collapse isOpen={finalData.rolePrivileges.isContextualSettingsAccess} className="accordion-collapse">
                                        <div className="accordion-body">
                                            <div className="row">
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.ContextualSettings.isBrandAffinityAccess}
                                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, ContextualSettings: { ...finalData.rolePrivileges.ContextualSettings, isBrandAffinityAccess: e.target.checked } } }))}
                                                            id="brandAffinity"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="brandAffinity"
                                                        >
                                                            Brand Affinity
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.ContextualSettings.isInterestAccess}
                                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, ContextualSettings: { ...finalData.rolePrivileges.ContextualSettings, isInterestAccess: e.target.checked } } }))}
                                                            id="contextualInterests"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="contextualInterests"
                                                        >
                                                            Interests
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.ContextualSettings.isKeywordAccess}
                                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, ContextualSettings: { ...finalData.rolePrivileges.ContextualSettings, isKeywordAccess: e.target.checked } } }))}
                                                            id="contextualKeywords"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="contextualKeywords"
                                                        >
                                                            Keywords
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.ContextualSettings.isPageUrlAccess}
                                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, ContextualSettings: { ...finalData.rolePrivileges.ContextualSettings, isPageUrlAccess: e.target.checked } } }))}
                                                            id="contexualPageUrl"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="contexualPageUrl"
                                                        >
                                                            Page URL
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.ContextualSettings.isWebCategorizationAccess}
                                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, ContextualSettings: { ...finalData.rolePrivileges.ContextualSettings, isWebCategorizationAccess: e.target.checked } } }))}
                                                            id="webCategorization"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="webCategorization"
                                                        >
                                                            Web Categorization
                                                        </label>
                                                    </div>
                                                </Col>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </Row>
                            <Row className="m-4">
                                <Col md={2} className='p-0'>
                                    <div className="form-check mt-2 mb-2">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={finalData.rolePrivileges.isNetworkSettingAccess}
                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isNetworkSettingAccess: e.target.checked } }))}
                                            id="isNetworkSettingAccess"
                                        />
                                        <label
                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                            htmlFor="isNetworkSettingAccess"
                                        >
                                            Network Settings
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="m-4">
                                <Col md={2} className='p-0'>
                                    <div className="form-check mt-2 mb-2">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={finalData.rolePrivileges.isNetworkBlockingAccess}
                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isNetworkBlockingAccess: e.target.checked } }))}
                                            id="isNetworkBlockingAccess"
                                        />
                                        <label
                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                            htmlFor="isNetworkBlockingAccess"
                                        >
                                            Network Blocking
                                        </label>
                                    </div>
                                </Col>

                            </Row>
                            <Row className="m-4">
                                <Col md={2} className='p-0'>
                                    <div className="form-check mt-2 mb-2">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={finalData.rolePrivileges.isHBSettingAccess}
                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, isHBSettingAccess: e.target.checked } }))}
                                            id="isHBSettingAccess"
                                        />
                                        <label
                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                            htmlFor="isHBSettingAccess"
                                        >
                                            HB Settings
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="m-4">
                                <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                                    <h2 className="accordion-header m-0" id="headingOne">
                                        <label className=" d-sm-flex  justify-content-between form-check-label  m-0 settingsButton" htmlFor="tools">
                                            <h6 className="heading6 m-3">Tools</h6>
                                            <div className="text-sm-end">
                                                <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={finalData.rolePrivileges.isToolsAccess}
                                                        onChange={selectTools}
                                                        id="tools"
                                                    />
                                                </div>
                                            </div>
                                        </label>
                                    </h2>
                                    <Collapse isOpen={finalData.rolePrivileges.isToolsAccess} className="accordion-collapse">
                                        <div className="accordion-body">
                                            <div className="row">
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Tools.isScreenShotToolAccess}
                                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Tools: { ...finalData.rolePrivileges.Tools, isScreenShotToolAccess: e.target.checked } } }))}
                                                            id="screenshotTool"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="screenshotTool"
                                                        >
                                                            Screenshot Tool
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Tools.isAdTagTesterAccess}
                                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Tools: { ...finalData.rolePrivileges.Tools, isAdTagTesterAccess: e.target.checked } } }))}
                                                            id="adTagTester"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="adTagTester"
                                                        >
                                                            Ad Tag Tester
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Tools.isVastTagTesterAccess}
                                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Tools: { ...finalData.rolePrivileges.Tools, isVastTagTesterAccess: e.target.checked } } }))}
                                                            id="vastTagTester"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="vastTagTester"
                                                        >
                                                            Vast Tag Tester
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Tools.isAdsTxtValidatorAccess}
                                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Tools: { ...finalData.rolePrivileges.Tools, isAdsTxtValidatorAccess: e.target.checked } } }))}
                                                            id="adsTagValidator"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="adsTagValidator"
                                                        >
                                                            Ads.txt Validator
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Tools.isVideoCompresser}
                                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Tools: { ...finalData.rolePrivileges.Tools, isVideoCompresser: e.target.checked } } }))}
                                                            id="videoCompresser"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="videoCompresser"
                                                        >
                                                            Video Compresser
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Tools.isLinkTester}
                                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Tools: { ...finalData.rolePrivileges.Tools, isLinkTester: e.target.checked } } }))}
                                                            id="isLinkTester"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="isLinkTester"
                                                        >
                                                            Link Tester
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Tools.isPlayerDashBoardAccess}
                                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Tools: { ...finalData.rolePrivileges.Tools, isPlayerDashBoardAccess: e.target.checked } } }))}
                                                            id="isPlayerDashBoardAccess"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="isPlayerDashBoardAccess"
                                                        >
                                                            Player DashBoard
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Tools.isTextSummerizationAccess}
                                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Tools: { ...finalData.rolePrivileges.Tools, isTextSummerizationAccess: e.target.checked } } }))}
                                                            id="isTextSummerizationAccess"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="isTextSummerizationAccess"
                                                        >
                                                            Text Summerization
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Tools.isTextTranslatorAccess}
                                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Tools: { ...finalData.rolePrivileges.Tools, isTextTranslatorAccess: e.target.checked } } }))}
                                                            id="isTextTranslatorAccess"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="isTextTranslatorAccess"
                                                        >
                                                            Text Translator
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Tools.isTextTransisterAccess}
                                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Tools: { ...finalData.rolePrivileges.Tools, isTextTransisterAccess: e.target.checked } } }))}
                                                            id="isTextTransisterAccess"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="isTextTransisterAccess"
                                                        >
                                                            Text Transister
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.Tools.isAiVideoGeneratorAccess}
                                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, Tools: { ...finalData.rolePrivileges.Tools, isAiVideoGeneratorAccess: e.target.checked } } }))}
                                                            id="isAiVideoGeneratorAccess"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="isAiVideoGeneratorAccess"
                                                        >
                                                            AI Video Generator
                                                        </label>
                                                    </div>
                                                </Col>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </Row>
                            <Row className="m-4">
                                <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                                    <h2 className="accordion-header m-0" id="headingOne">
                                        <label className=" d-sm-flex  justify-content-between form-check-label  m-0 settingsButton" htmlFor="isVCsAccess">
                                            <h6 className="heading6 m-3">Video Content Suite</h6>
                                            <div className="text-sm-end">
                                                <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={finalData.rolePrivileges.isVCsAccess}
                                                        onChange={selectVCS}
                                                        id="isVCsAccess"
                                                    />
                                                </div>
                                            </div>
                                        </label>
                                    </h2>
                                    <Collapse isOpen={finalData.rolePrivileges.isVCsAccess} className="accordion-collapse">
                                        <div className="accordion-body">
                                            <div className="row">
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.VCs.isVideoContentLibrarayAccess}
                                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, VCs: { ...finalData.rolePrivileges.VCs, isVideoContentLibrarayAccess: e.target.checked } } }))}
                                                            id="videoContentLibrarys"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="videoContentLibrarys"
                                                        >
                                                            Video Content Library
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.VCs.isVideoPlayListAccess}
                                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, VCs: { ...finalData.rolePrivileges.VCs, isVideoPlayListAccess: e.target.checked } } }))}
                                                            id="playList"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="playList"
                                                        >
                                                            Video Play List
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.VCs.isVideoImportAccess}
                                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, VCs: { ...finalData.rolePrivileges.VCs, isVideoImportAccess: e.target.checked } } }))}
                                                            id="isVideoImportAccess"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="isVideoImportAccess"
                                                        >
                                                            Video Import
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col md={3} >
                                                    <div className="form-check mt-2 mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={finalData.rolePrivileges.VCs.isContentMarketplaceAccess
                                                            }
                                                            onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, VCs: { ...finalData.rolePrivileges.VCs, isContentMarketplaceAccess: e.target.checked } } }))}
                                                            id="isContentMarketplaceAccess"
                                                        />
                                                        <label
                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                            htmlFor="isContentMarketplaceAccess"
                                                        >
                                                            Content Marketplace
                                                        </label>
                                                    </div>
                                                </Col>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </Row>

                            <Row className="m-4">
                                <div className="accordion-item p-0 ml-0 mr-0 mb-2">
                                    <h2 className="accordion-header m-0" id="headingOne">
                                        <label className=" d-sm-flex  justify-content-between form-check-label  m-0 settingsButton" htmlFor="admin">
                                            <h6 className="heading6 m-3">Admin</h6>
                                            <div className="text-sm-end">
                                                <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={finalData.rolePrivileges.isAdminAccess}
                                                        onChange={selectAdmin}
                                                        id="admin"
                                                    />
                                                </div>
                                            </div>
                                        </label>
                                    </h2>
                                    <Collapse isOpen={finalData.rolePrivileges.isAdminAccess} className="accordion-collapse">
                                        <div className="accordion-body">
                                            <div>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={12}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.admin.isAccessManagementAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, admin: { ...finalData.rolePrivileges.admin, isAccessManagementAccess: e.target.checked } } }))}
                                                                    id="accessManagement"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="accessManagement"
                                                                >
                                                                    Access Management
                                                                </label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {finalData.rolePrivileges.admin.isAccessManagementAccess &&
                                                        <>
                                                            <Row className="m-4">
                                                                <Col md={2} className='p-0'>
                                                                    <div className="form-check mt-2 mb-2">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={finalData.rolePrivileges.adminAccess.isUserManagementAccess}
                                                                            onChange={(e) => setFinalData(finalData => ({
                                                                                ...finalData, rolePrivileges: {
                                                                                    ...finalData.rolePrivileges, adminAccess: {
                                                                                        ...finalData.rolePrivileges.adminAccess,
                                                                                        isUserManagementAccess: e.target.checked
                                                                                    }
                                                                                }
                                                                            }))}
                                                                            id="userManagement"
                                                                        />
                                                                        <label
                                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                            htmlFor="userManagement"
                                                                        >
                                                                            User Management
                                                                        </label>
                                                                    </div>
                                                                </Col>
                                                                {finalData.rolePrivileges.adminAccess.isUserManagementAccess &&
                                                                    <Col md={10} >
                                                                        {createUI('adminAccess', 'UserManagement')}
                                                                    </Col>
                                                                }
                                                            </Row>
                                                            <Row className="m-4">
                                                                <Col md={2} className='p-0'>
                                                                    <div className="form-check mt-2 mb-2">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={finalData.rolePrivileges.adminAccess.isRoleManagementAccess}
                                                                            onChange={(e) => setFinalData(finalData => ({
                                                                                ...finalData, rolePrivileges: {
                                                                                    ...finalData.rolePrivileges, adminAccess: {
                                                                                        ...finalData.rolePrivileges.adminAccess,
                                                                                        isRoleManagementAccess: e.target.checked
                                                                                    }
                                                                                }
                                                                            }))}
                                                                            id="roleManagement"
                                                                        />
                                                                        <label
                                                                            className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                            htmlFor="roleManagement"
                                                                        >
                                                                            Role Management
                                                                        </label>
                                                                    </div>
                                                                </Col>
                                                                {finalData.rolePrivileges.adminAccess.isRoleManagementAccess &&
                                                                    <Col md={10} >
                                                                        {createUI('adminAccess', 'RoleManagement')}
                                                                    </Col>
                                                                }
                                                            </Row>
                                                        </>
                                                    }
                                                </Col>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={12}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.admin.isAdsTxtManagementAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, admin: { ...finalData.rolePrivileges.admin, isAdsTxtManagementAccess: e.target.checked } } }))}
                                                                    id="adsTxtManagement"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="adsTxtManagement"
                                                                >
                                                                    Ads.Txt Management
                                                                </label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={12}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.admin.isPartnerAccessManagementAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, admin: { ...finalData.rolePrivileges.admin, isPartnerAccessManagementAccess: e.target.checked } } }))}
                                                                    id="isPartnerAccessManagementAccess"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="isPartnerAccessManagementAccess"
                                                                >
                                                                    Partner Access Management
                                                                </label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={12}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.admin.isSupplyPartnerManagementAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, admin: { ...finalData.rolePrivileges.admin, isSupplyPartnerManagementAccess: e.target.checked } } }))}
                                                                    id="isSupplyPartnerManagementAccess"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="isSupplyPartnerManagementAccess"
                                                                >
                                                                    Supply Partner Management
                                                                </label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={12}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.admin.isBrandSafetyAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, admin: { ...finalData.rolePrivileges.admin, isBrandSafetyAccess: e.target.checked } } }))}
                                                                    id="isBrandSafetyAccess"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="isBrandSafetyAccess"
                                                                >
                                                                    Brand Safety
                                                                </label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={12}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.admin.isMasterLogAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, admin: { ...finalData.rolePrivileges.admin, isMasterLogAccess: e.target.checked } } }))}
                                                                    id="masterLog"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="masterLog"
                                                                >
                                                                    Master Log
                                                                </label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={12}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.admin.isPublisherLogAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, admin: { ...finalData.rolePrivileges.admin, isPublisherLogAccess: e.target.checked } } }))}
                                                                    id="publisherLog"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="publisherLog"
                                                                >
                                                                    Publisher Log
                                                                </label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={2}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.adminAccess.isNotificationAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, adminAccess: { ...finalData.rolePrivileges.adminAccess, isNotificationAccess: e.target.checked } } }))}
                                                                    id="publisherNotification"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="publisherNotification"
                                                                >
                                                                    Notification
                                                                </label>
                                                            </div>
                                                        </Col>
                                                        {finalData.rolePrivileges.adminAccess.isNotificationAccess &&
                                                            <Col md={10} >
                                                                {createUI('adminAccess', 'Notification')}
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={12}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.adminAccess.isPublisherApprovalAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, adminAccess: { ...finalData.rolePrivileges.adminAccess, isPublisherApprovalAccess: e.target.checked } } }))}
                                                                    id="publisherApproval"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="publisherApproval"
                                                                >
                                                                    Publisher Approval
                                                                </label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={12} className="m-1">
                                                    <Row>
                                                        <Col md={12}>
                                                            <div className="form-check mt-2 mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={finalData.rolePrivileges.adminAccess.isSupportTicketAccess}
                                                                    onChange={(e) => setFinalData(finalData => ({ ...finalData, rolePrivileges: { ...finalData.rolePrivileges, adminAccess: { ...finalData.rolePrivileges.adminAccess, isSupportTicketAccess: e.target.checked } } }))}
                                                                    id="supportTicket"
                                                                />
                                                                <label
                                                                    className="form-check-label  m-2 mt-1 m-2 mt-1"
                                                                    htmlFor="supportTicket"
                                                                >
                                                                    Suport Ticket
                                                                </label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </Row>
                        </div>
                    </div>
                    <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
                        <Col>
                            <div className="text-start">
                                &nbsp;&nbsp;
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    // eslint-disable-next-line react/prop-types
                                    onClick={props.closeCanvas}
                                >
                                    Back
                                </button>
                                &nbsp;&nbsp;

                                {!isEdit &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Save
                                    </button>
                                }
                                &nbsp;&nbsp;
                                {isEdit &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Update
                                    </button>
                                }

                            </div>
                        </Col>
                    </Row>
                </Form>
                {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
                    <Toaster status="error" msg={errorMsg.message} />
                }
            </div>
            {errorMsg !== null && errorMsg !== '' && errorMsg.status && errorMsg.status === 500 &&
                <div className="p-0 justify-content-center d-flex">
                    <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')} />
                </div>
            }
        </React.Fragment>
    );
};

export default New_Role_Test

New_Role_Test.propTypes = {
    history: PropTypes.object,
};