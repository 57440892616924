/* IOGROUP */
export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES"
export const NO_DATA = "NO_DATA"
export const GET_IOGROUPS = "GET_IOGROUPS"
export const GET_IOGROUPS_SUCCESS = "GET_IOGROUPS_SUCCESS"
export const GET_IOGROUPS_FAIL = "GET_IOGROUPS_FAIL"

export const ADD_IOGROUP = "ADD_IOGROUP"
export const ADD_IOGROUP_SUCCESS = "ADD_IOGROUP_SUCCESS"
export const ADD_IOGROUP_ERROR = "ADD_IOGROUP_ERROR"

export const UPDATE_IOGROUP = "UPDATE_IOGROUP"
export const UPDATE_IOGROUP_SUCCESS = "UPDATE_IOGROUP_SUCCESS"
export const UPDATE_IOGROUP_ERROR = "UPDATE_IOGROUP_ERROR"

export const READ_IOGROUP = "READ_IOGROUP"
export const READ_IOGROUP_SUCCESS = "READ_IOGROUP_SUCCESS"

export const STATUS_IOGROUP = "STATUS_IOGROUP"
export const STATUS_IOGROUP_SUCCESS = "STATUS_IOGROUP_SUCCESS"

export const ARCHIVE_IOGROUP = "ARCHIVE_IOGROUP"
export const ARCHIVE_IOGROUP_SUCCESS = "ARCHIVE_IOGROUP_SUCCESS"