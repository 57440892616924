import {
    LOAD_BAY,
    NO_DATA_BAY,
    GET_BRAND_AFFINITYS_SUCCESS,
    GET_BRAND_AFFINITYS_FAIL,
    ADD_BRAND_AFFINITY_SUCCESS,
    ADD_BRAND_AFFINITY_ERROR,
    UPDATE_BRAND_AFFINITY_SUCCESS,
    UPDATE_BRAND_AFFINITY_ERROR,
    READ_BRAND_AFFINITY_SUCCESS,
    STATUS_BRAND_AFFINITY_SUCCESS,
    ARCHIVE_BRAND_AFFINITY_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    affintyList: [],
    error: {},
    loading:false,
    noData:false,
    successMsg: null,
    errorMsg: null,
    brandAffinity: null,
    response:null,
    archiveMsg:null,
}

const brandAffinitys = (state = INIT_STATE, action) => {
    
    switch (action.type) {
        case LOAD_BAY:
            return {
                ...state,
                loading: true,
            }
            case NO_DATA_BAY:
                return {
                    ...state,
                    noData: true,
                }
        case GET_BRAND_AFFINITYS_SUCCESS:
            return {
                ...state,
                loading: false,
                affintyList: action.payload,
            }

        case GET_BRAND_AFFINITYS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case ADD_BRAND_AFFINITY_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_BRAND_AFFINITY_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_BRAND_AFFINITYS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_BRAND_AFFINITY_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_BRAND_AFFINITYS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_BRAND_AFFINITY_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_BRAND_AFFINITYS_SUCCESS' ? null : action.payload,
            }
        case READ_BRAND_AFFINITY_SUCCESS:
            return {
                ...state,
                brandAffinity: action.payload,
            }
        case STATUS_BRAND_AFFINITY_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_BRAND_AFFINITY_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default brandAffinitys