import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Input,
  Label,
  Spinner,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
} from "reactstrap"

import DateRangePickers from '../../../helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';
import queryString from 'query-string';

import {
  getPlayerSetting as onGetPlayerSettings,
  statusPlayerSetting as onStatusPlayerSetting,
  archivePlayerSetting as onArchivePlayerSetting,
  statusPlayerSettingSuccess,
  archivePlayerSettingSuccess,
  addPlayerSettingFail,
  updatePlayerSettingFail,
} from "store/Supply/PlayerSetting/actions";


//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Create_Player_Settings from "./Create_Player_Settings/Create_Player_Settings";
import Video_Player_Settings from "./Create_Player_Settings/VideoPlayerSettings";
import LoadingTable from "pages/LoaderPages/LoadingTable";
import DetachPlayerSetting from "./DetachPlayerSetting";
import LineItemDataTable from "Product_Helpers/ProductDataTable"
import ProductDataTableLI from "Product_Helpers/ProductDataTableLI";

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 2 : 1
var fromDate = ''
var toDate = ''

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var filterCount = 0

let pageNumber = 1
let pageSize = 10
let totalRecords = 0
let searchText = ''

const PlayerSetting = () => {
  const dispatch = useDispatch();
  const [isRight, setIsRight] = useState(false);
  const [isRights, setIsRights] = useState(false);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const parsed = queryString.parse(window.location.search);
  const [isArchived, setIsArchived] = useState(false)
  const [selected, setSelected] = useState([])
  const [btnprimary1, setBtnprimary1] = useState(false)
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [statusSearch, setStatusSearch] = useState('Active')
  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }
  const [state, setState] = useState({
    start: moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
    end: moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
  });
  const { start, end } = state;
  const handleEvent = (startDate, endDate, label) => {
    setState({ start: startDate, end: endDate, });
    fromDate = startDate._d.toISOString();
    toDate = endDate._d.toISOString();
    filterCount = filterCount + 1
  }
  const labelDate = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');

  const [switchLoader, setSwitchLoader] = useState(true)

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }
  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setIsRight(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const [filterState, setFilterState] = useState(false)

  useEffect(() => {
    if (parsed.status === 'PlayerSettingCreateByAdUnit') {
      setIsRight(true);
    }
  }, []);

  const statusChange = (e) => {
    if (e.target.value === 'Active') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (statusSearch === 'Active') {
      filterCount = filterCount + 1
    }
    setStatusSearch(e.target.value)
  }

  const applyFilter = (e) => {
    setisMenu(false)
    dispatch(onGetPlayerSettings({ userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,AdUnitID:'' }));
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  const clearAllFilter = (e) => {
    setisMenu(false)
    setStatusSearch('Active')
    filterCount = 0
    setFilterState(false)
    fromDate = ''
    toDate = ''
    setState({
      start: moment().subtract(0, 'days'),
      end: moment().subtract(0, 'days'),
    })
    dispatch(onGetPlayerSettings({ userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: 'Active',AdUnitID:'' }));
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 5000,
    extendedTimeOut: 1000
  };


  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      console.log(row, isSelect, rowIndex)
      if (isSelect) {
        setSelected([...selected, row.id])
        setSelectedRowsStatus(true)
        selectedIndex.push(row.playerSettingID)
        selectedItem.push(row.playerSettingID)
        console.log(selectedIndex)
      } else {
        setSelected(selected.filter(x => x !== row.id))
        let index = selectedIndex.indexOf(row.playerSettingID)
        selectedIndex.splice(index, 1)
        let index2 = selectedItem.indexOf(row.playerSettingID)
        selectedItem.splice(index2, 1)
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
        console.log(selectedIndex)
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      const ids = rows.map(r => r.id);
      if (isSelect) {
        setSelected(ids)
        rows.map((item, index) => {
          selectedIndex.push(item.playerSettingID)
          selectedItem.push(item.playerSettingID)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
      }

    }
  };

  useEffect(() => {
    dispatch(onGetPlayerSettings(null));
    totalRecords = 0
  }, []);

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
    selectedIndex = []
  }

  const { playerSettings,paginationData } = useSelector(state => ({
    playerSettings: state.playerSettings.playerSettingList,
    paginationData: state.playerSettings.pagination
  }));

  totalRecords = paginationData ? paginationData.totalRecords ? paginationData.totalRecords : 0 : 0

  const { loading } = useSelector(state => ({
    loading: state.playerSettings.loading,
  }));

  const { noData } = useSelector(state => ({
    noData: state.advertisers.noData,
  }));

  const { playerSettingsResponse } = useSelector(state => ({
    playerSettingsResponse: state.playerSettings.response,
  }));

  const { archivePlayerSettingsResponse } = useSelector(state => ({
    archivePlayerSettingsResponse: state.playerSettings.archiveMsg,
  }));

  // const { errorMsg, successMsg } = useSelector(state => ({
  //   errorMsg: state.playerSettings.errorMsg,
  //   successMsg: state.playerSettings.successMsg,
  // }));

  useEffect(() => {
    if (loading) {
      setSwitchLoader(true)
    }
  }, [loading]);

  console.log(playerSettingsResponse)
  useEffect(() => {
    if (playerSettingsResponse !== null) {
      if (playerSettingsResponse.statusCode === 200) {
        toastr.success('PlayerSetting Status Changed Successfully')
        if (parsed.adUnitId) {
          dispatch(onGetPlayerSettings({ userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,AdUnitID:parsed.adUnitId }));
        } else {
          dispatch(onGetPlayerSettings({ userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,AdUnitID:'' }));
        }
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        dispatch(statusPlayerSettingSuccess(null));
      }
    }
  }, [dispatch, playerSettingsResponse]);

  useEffect(() => {
    if (archivePlayerSettingsResponse !== null) {
      if (archivePlayerSettingsResponse.statusCode === 200) {
        toastr.success('PlayerSetting Archived Successfully')
        if (parsed.adUnitId) {
          dispatch(onGetPlayerSettings({ userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,AdUnitID:parsed.adUnitId }));
        } else {
          dispatch(onGetPlayerSettings({ userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,AdUnitID:'' }));
        }
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        dispatch(archivePlayerSettingSuccess(null));
      }
    }
  }, [dispatch, archivePlayerSettingsResponse]);

  const toggleRightCanvas = () => {
    selectedData = ''
    if (parsed.adUnitId) {
      dispatch(onGetPlayerSettings({ userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,AdUnitID:parsed.adUnitId }));
    } else {
      dispatch(onGetPlayerSettings({ userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,AdUnitID:'' }));
    }
    dispatch(addPlayerSettingFail(""));
    dispatch(updatePlayerSettingFail(""));
    setIsRight(!isRight);
  };

  const toggleRightCanvass = () => {
    selectedData = ''
    if (parsed.adUnitId) {
      dispatch(onGetPlayerSettings({ userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,AdUnitID:parsed.adUnitId }));
    } else {
      dispatch(onGetPlayerSettings({ userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,AdUnitID:'' }));
    }
    dispatch(addPlayerSettingFail(""));
    dispatch(updatePlayerSettingFail(""));
    setIsRights(!isRights);
  };

  const toggleEnableScroll = () => {
    setIsEnableScroll(!isEnableScroll);
  };

  const toggleDrawer = (close, playerSetting) => (event) => {
    console.log(event)
    if (event === 'Create') {
      toastr.success('PlayerSetting Saved Successfully!...')
    }
    if (event === 'Update') {
      toastr.success('PlayerSetting Updated Successfully!...')
    }
    selectedData = playerSetting
    if (parsed.adUnitId) {
      dispatch(onGetPlayerSettings({ userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,AdUnitID:parsed.adUnitId }));
    } else {
      dispatch(onGetPlayerSettings({ userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,AdUnitID:'' }));
    }
    dispatch(addPlayerSettingFail(""));
    dispatch(updatePlayerSettingFail(""));
    setIsRight(close);
  };

  const toggleDrawers = (close, highImpact) => (event) => {
    console.log(event)
    if (parsed.adUnitId) {
      dispatch(onGetPlayerSettings({ userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,AdUnitID:parsed.adUnitId }));
    } else {
      dispatch(onGetPlayerSettings({ userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,AdUnitID:'' }));
    }
    dispatch(addPlayerSettingFail(""));
    dispatch(updatePlayerSettingFail(""));
    setIsRights(close);
  };

  useEffect(() => {
    if (parsed.adUnitId) {
      dispatch(onGetPlayerSettings({ userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,AdUnitID:parsed.adUnitId }));
    }
  }, []);

  useEffect(() => {
    if (playerSettings !== null && !parsed.adUnitId) {
      dispatch(onGetPlayerSettings({ userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch,AdUnitID:'' }));
    }
  }, []);



  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const playerSettingListColumns = [
    {
      text: "ID",
      headerClasses: "ID",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "ID",
      headerClasses: "ID",
      dataField: "playerSettingID",
      sort: true,
    },
    {
      dataField: "playerSettingCreateTime",
      text: "Creation Date",
      sort: true,
    },
    {
      text: "Player Setting Name",
      dataField: "playerSettingName",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, playerSetting) => (
        <>
          <h5 className="font-size-12 mb-1">
            <Link to="#" className="text-dark">
              {playerSetting.playerSettingName}
            </Link>
          </h5>
        </>
      ),
    },

    {
      dataField: "playerSettingStatus",
      text: "Status",
      sort: true,
      formatter: (cellContent, playerSetting) => (
        <>
          <span className={playerSetting.newStatus === 'Active' ? "active-state" : playerSetting.newStatus === 'Inactive' ? "inactive-state" : "archived-state"}>{playerSetting.newStatus}</span>
        </>
      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',

      // eslint-disable-next-line react/display-name
      formatter: (cellContent, playerSetting) => (
        <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
          <span className="text-success " onClick={toggleDrawer(true, playerSetting)}>
            {playerSetting.newStatus === 'Archived' &&
              <i
                className="mdi mdi-eye font-size-18"
                id="edittooltip"
              ></i>
            }
            {playerSetting.newStatus !== 'Archived' &&
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
              ></i>
            }
          </span>
        </div>
      ),
    },
  ];

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const archivePlayerSetting = async () => {
    dispatch(onArchivePlayerSetting({ playerSettingIDList: selectedItem,userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID }));
  }

  const statusPlayerSetting = (state) => (event) => {
    console.log(state)
    dispatch(onStatusPlayerSetting({ playerSettingIDList: selectedItem, playerSettingStatus: state,userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID }));
  }

  const paginationChange = async (selectedPages) => {
    console.log(selectedPages)
    pageSize = selectedPages.pageSize
    pageNumber = selectedPages.pageNumber
    if (parsed.adUnitId) {
      dispatch(onGetPlayerSettings({ userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText ,filter: statusSearch,AdUnitID:parsed.adUnitId }));
    } else {
      dispatch(onGetPlayerSettings({ userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '', pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText ,filter: statusSearch,AdUnitID:'' }));
    }
  }

  console.log(playerSettings)

  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      <div className="page-content">
        <Helmet>
          <title>Player Setting | Bidsxchange</title>
        </Helmet>
        <Container fluid>
          {/* Render Breadcrumbs */}

          <div className=" d-sm-flex  justify-content-between">
            <Breadcrumbs title="Supply" breadcrumbItem="Player Setting" />
            {/* <div className="text-sm-end">
              <Dropdown
                isOpen={btnprimary1}
                toggle={() => setBtnprimary1(!btnprimary1)}
              >
                <DropdownToggle tag="button" className="btn btn-primary">  <i className="mdi mdi-plus-circle-outline me-1" /> New PlayerSetting &nbsp; <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={toggleRightCanvas}>InStream</DropdownItem>
                  <DropdownItem onClick={toggleRightCanvas}>OutStream</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div> */}

            <div className="text-sm-end">
              <div className="btn-group">
                <Dropdown
                  direction="left"
                  isOpen={isMenu}
                  toggle={() => { }}
                >
                  <DropdownToggle
                    tag="button"
                    onClick={toggleMenu}
                    className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                  >
                    <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                    {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                      Filter Applied
                    </span> : <span className="d-none font-16 d-sm-inline-block">
                      Filter
                    </span>
                    }
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                    <div className="dropdown-item-text">
                      <div className="row">
                        <h5 className="mb-0">Filters</h5>
                      </div>
                    </div>

                    <DropdownItem divider />
                    <Card>
                      <CardBody aria-hidden="true">
                        <Row >
                          <Col sm="12" className="mt-2">
                            <Label className="form-label">Status</Label>
                            <div className=" text-sm-end">
                              <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                <option value=''> All</option>
                                <option value='Active' > Active</option>
                                <option value='Inactive'> InActive</option>
                                <option value='Archived'> Archived</option>
                              </Input>
                            </div>
                          </Col>
                          <Col sm="12" className="date-range mt-2">
                              <Label className="form-label">Filter by Modified Dates</Label>
                              <div className="">
                                <DateRangePicker
                                  initialSettings={{
                                    opens: 'left',
                                    startDate: start.toDate(),
                                    endDate: end.toDate(),
                                  }}
                                  onCallback={handleEvent}
                                >
                                  <button className='datePicker w-100' type="button">
                                    <i className="bx bx-calendar font-size-18 " id="edittooltip" style={{ margin: '3% 3% 2%' }} ></i>{labelDate}
                                  </button>
                                </DateRangePicker>
                              </div>
                            </Col>
                        </Row>
                      </CardBody>
                    </Card>

                    <DropdownItem divider />
                    <Row>
                      <Col sm="6">
                        <DropdownItem
                          className="text-primary text-center"
                          onClick={clearAllFilter}
                        >
                          Clear ALL
                        </DropdownItem>
                      </Col>
                      <Col sm="6">
                        <DropdownItem
                          className="text-primary text-center"
                          onClick={applyFilter}
                        >
                          Apply Filter
                        </DropdownItem>
                      </Col>
                    </Row>

                  </DropdownMenu>
                </Dropdown>
              </div> &nbsp;&nbsp;
              <Button
                color="primary"
                className="font-16 btn-block btn btn-primary"
                onClick={toggleRightCanvas}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Add Player Setting
              </Button>
              {/* &nbsp;&nbsp;
              <Button
                color="primary"
                id="detach-his"
                className="btn btn-danger"
                onClick={toggleRightCanvass}
              >
                <i className="bx bx-unlink me-1" />
                Detach Player Setting
              </Button> */}
            </div>
          </div>
          <ProductDataTableLI statusAccess={(UserRolePrivileges.Supply && UserRolePrivileges.Supply.PlayerSettings && UserRolePrivileges.Supply.PlayerSettings.status) ? true : false}
            archiveAccess={(UserRolePrivileges.Supply && UserRolePrivileges.Supply.PlayerSettings && UserRolePrivileges.Supply.PlayerSettings.archive) ? true : false} loading={loading} data={playerSettings} columns={playerSettingListColumns} statusChange={statusPlayerSetting} archiveLineItem={archivePlayerSetting} rowSelection={selectRow} handleClose={handleClose} selectedIndex={selectedIndex} paginationSelection={paginationChange} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize}  />
          
        </Container>
      </div>
      {/* </div> */}

      <Offcanvas isOpen={isRight} direction='end' className="CanvasLarge">
        <OffcanvasHeader toggle={toggleRightCanvas}>
          {selectedData ? 'Edit Player Setting' : 'New Player Setting'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Create_Player_Settings closeCanvas={toggleDrawer(false, '')} selectedPlayerSetting={selectedData} />
          {/* <Video_Player_Settings closeCanvas={toggleDrawer(false, '')} selectedPlayerSetting={selectedData}  /> */}
        </OffcanvasBody>
      </Offcanvas>

      <Offcanvas isOpen={isRights} direction='end' >
        <OffcanvasHeader toggle={toggleRightCanvass}>
          {'Detach Player Settings'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <DetachPlayerSetting closeCanvas={toggleDrawers(false, '')} />
        </OffcanvasBody>
      </Offcanvas>


    </React.Fragment>
  )
}

export default PlayerSetting