import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_LOGS,GET_LOGS_MASTER} from "./actionTypes"

import {
  loadActivitiesLogs,
  noLogs,
  getLogsSuccess,
  getLogsFail,
  getLogsMasterSuccess,
  getLogsMasterFail
} from "./actions"

//Include Both Helper File with needed methods
import { getLogs,getLogsMaster} from "../../helpers/Backend_helper"

function* fetchUserLogs({ payload: logs }) {
  try {
    yield put(loadActivitiesLogs(true))
    const response = yield call(getLogs,logs)
    console.log(response)
    if(response.list.length === 0){
      yield put(noLogs(true))
    }
    yield put(getLogsSuccess(response.list))
  } catch (error) {
    yield put(getLogsFail(error))
  }
}

function* fetchUserLogsMaster({ payload: logs }) {
  try {
    yield put(loadActivitiesLogs(true))
    const response = yield call(getLogsMaster,logs)
    if(response.list.length === 0){
      yield put(noLogs(true))
    }
    yield put(getLogsMasterSuccess(response.list))
  } catch (error) {
    yield put(getLogsMasterFail(error))
  }
}


function* userLogsSaga() {
  yield takeEvery(GET_LOGS, fetchUserLogs)
  yield takeEvery(GET_LOGS_MASTER,fetchUserLogsMaster)
}

export default userLogsSaga;
