/* eslint-disable react/prop-types */
import React, { useEffect,useState} from "react";
import { Button } from "reactstrap";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

 const Toaster = (props) => {
  console.log(props.msg)
        console.log(props)
        toastr.options = {
          positionClass: "toast-top-center",
            closeButton: true,
            preventDuplicates: true,
            newestOnTop: true,
            showEasing: 'swing',
            hideEasing: 'linear',
            showMethod: 'fadeIn',
            hideMethod: 'fadeOut',
            showDuration: 300,
            hideDuration: 1000,
            timeOut: 5000,
            extendedTimeOut: 1000,
          ...props
           };
    
          useEffect(() => {
            if(props.status === 'error'){
                toastr.error(props.msg)
            }
            else if(props.status === 'info'){
                toastr.info(props.msg)
            }
            else if(props.status === 'warning'){
                toastr.warning(props.msg)
            }if(props.status === 'error' && !props.msg ){
              toastr.error("Internal Server Error")
            }else{
                toastr.success(props.msg)
            }
        }, []);

        const toggleToast = () => {
          setToast(false);
        };
    
      return (
          <div></div>
      )
}

export default Toaster