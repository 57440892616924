/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react"
import { Card, CardBody, Col, Form, Input, Label, Row, Spinner, FormFeedback } from "reactstrap"
import CreatableSelect from 'react-select/creatable';
import axios from "axios";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
  getCommonDevices as onGetAllContextual,
} from "store/CommonEvents/actions";

var videoContentFileName = ''

const Video_General_Info = props => {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [loader, setLoader] = useState(false)
  const [category, setCategory] = useState('');
  const [language, setLanguage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [languageData, setLanguageData] = useState([])
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [categoryData, setCategoryData] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null);

  console.log(props.videoFileName)

  const [videoContent, setVideoContent] = useState({
    videoContentName: `${props.videoFileName}`,
    videoContentCategory: "",
    videoContentLanguage: "",
    videoContentClickThroughURL: ''
  });

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  // props.videoFileName &&  setVideoContent(videoContent => ({ ...videoContent, videoContentName: props.videoFileName }));
  useEffect(() => {
    if (props.videoFileName) {
      setVideoContent(videoContent => ({ ...videoContent, videoContentName: props.videoFileName }));
      props.saveData({ videoContentName: props.videoFileName })

    }
  }, [props.videoFileName]);


  const { contextuals } = useSelector(state => ({
    contextuals: state.commonEvents.contextualAllData,
  }));

  useEffect(() => {
    if (contextuals !== null) {
      dispatch(onGetAllContextual());
    }
  }, []);


  const objectModal = (response = []) => {
    return response.map((item, index) => {
      return {
        value: item,
        label: item
      }
    })
  }

  const handleCategoryChange = (e, actionMeta) => {
    console.log(e)
    if (actionMeta.action === 'create-option' && category !== '') {
      createData(category, 'Category')
    }
    setSelectedCategory(e)
    setVideoContent(videoContent => ({ ...videoContent, videoContentCategory: e.value }));
    props.saveData({ videoContentCategory: e.value })
  }

  const handleInputChangeCategory = (inputValue, actionMeta) => {
    console.log(inputValue);
    setCategory(inputValue)
  };

  const handleLanguageChange = (e, actionMeta) => {
    let event = JSON.stringify(e)
    if (actionMeta.action === 'create-option' && language !== '') {
      createData(language, 'Language')
    }
    setSelectedLanguage(e)
    setVideoContent(videoContent => ({ ...videoContent, videoContentLanguage: e.value }));
    props.saveData({ videoContentLanguage: e.value })
    console.log(videoContent)
  }
  const handleInputChange = (inputValue, actionMeta) => {
    console.log(inputValue);
    setLanguage(inputValue)
  };

  const handleChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target;
    setVideoContent(videoContent => ({ ...videoContent, [name]: value }));
    props.saveData({ [name]: value })
  }

  const handleChangeUrl = (e) => {
    e.preventDefault()
    const { name, value } = e.target;
    setVideoContent(videoContent => ({ ...videoContent, [name]: value }));
    props.saveData({ [name]: value })
  }

  useEffect(() => {
    if (contextuals && Object.keys(contextuals).length !== 0) {
      if (contextuals.language && contextuals.language.length > 0) {
        setLanguageData(objectModal(contextuals.language))
      }
      if (contextuals.category && contextuals.category.length > 0) {
        setCategoryData(objectModal(contextuals.category))
      }
    }
  }, [contextuals]);



  // useEffect(() => {
  //   if (videoContent.videoContentCategory && videoContent.videoContentLanguage) {
  //     props.changeActiveState(3)
  //   }
  // }, [videoContent.videoContentCategory, videoContent.videoContentLanguage])

  const createData = (data, type) => {
    console.log(data, type)
    console.log('create Data')
    let newData = []
    newData.push(data)
    const body = {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
    }

    axios.post("/v1.0/connectReportAPI", body, {
      headers: headers
    })
      .then((res) => {
        console.log("Data Created", res)
        if (type === 'Language') {
          setLanguageData([])
          dispatch(onGetLanguages());
          setLanguageData(objectModal(videoContentLanguage))
        }
        else {
          setCategoryData([])
          dispatch(onGetCategories());
          setCategoryData(objectModal(videoContentCategory))
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  };

  console.log(categoryData)
  console.log(languageData)


  return (
    <Col md="12">
      {loader &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      <div >
        <Col xs={6}>
          <div className="mb-3">
            <Label className="form-label">Title</Label>
            <Input
              name="videoContentName"
              type="text"
              onChange={handleChange}
              value={videoContent.videoContentName}
              placeholder="Name..."
              className={'form-control' + (submitted && !videoContent.videoContentName ? ' is-invalid' : '')}
            />
            {submitted && !videoContent.videoContentName ? (
              <FormFeedback type="invalid">{'VideoContent Name is required'}</FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label>Language</Label>
            <CreatableSelect
              onChange={(event, actionMeta) => {
                handleLanguageChange(event, actionMeta)
              }}
              onInputChange={handleInputChange}
              options={languageData}
              value={selectedLanguage}
            />
            {submitted && !videoContent.videoContentLanguage &&
              <div className="invalid-feedback">Language is required</div>}
          </div>

          <div className="mb-3">
            <Label>Category</Label>
            <div className='row'>
              <CreatableSelect
                onChange={(event, actionMeta) => {
                  handleCategoryChange(event, actionMeta)
                }}
                onInputChange={handleInputChangeCategory}
                options={categoryData}
                value={selectedCategory}
              />
              {submitted && !videoContent.videoContentCategory ? (
                <FormFeedback type="invalid">{'Category is required'}</FormFeedback>
              ) : null}
            </div>
          </div>

          <div className="mb-3">
            <Label className="form-label">Click Though URL</Label>
            <Input
              name="videoContentClickThroughURL"
              type="text"
              onChange={handleChangeUrl}
              value={videoContent.videoContentClickThroughURL}
              placeholder="click throgh url..."
              className={'form-control' + (submitted && !videoContent.videoContentClickThroughURL ? ' is-invalid' : '')}
            />
            {submitted && !videoContent.videoContentClickThroughURL ? (
              <FormFeedback type="invalid">{'videoContentClickThroughURL is required'}</FormFeedback>
            ) : null}
          </div>
      
        </Col>
      </div>
    </Col>
  )
}

Video_General_Info.propTypes = {}

export default Video_General_Info