/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
    Card, CardBody, Col, Form, Row, Spinner, TabContent,
    TabPane, NavItem,
    NavLink, Button
} from "reactstrap"
import Video_Upload from "./Video_Upload";
import Link_Upload from "./Link_Upload";
import Create_Content from "./Create_Content";

const Create_Content_Selection = props => {

    const [creativeType, setCreativeType] = useState({
        creativeTypeName: 'selection',
    });

    const creativeChange = (type) => (event) => {
        // console.log(type)
        // console.log(event)
        if (event === 'close') {
            props.closeCanvas()
        }
        if (type === 'closeNew') {
            props.closeCanvas('Create')
        }
        setCreativeType({
            creativeTypeName: type
        });
    };

    const toggleDrawer1 = (data) => (event) => {
        props. closeCanvas(event)
      };

  return (
                <Col lg="12" className="p-0">
                    <div className="content">
                        {creativeType.creativeTypeName === 'selection' &&
                            <div className="container-fluid">

                                <div className="row mt-4 creative-selections" >
                                    <div className="col-md-4 mt-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title">Video file Upload </h5>
                                                <p className="card-text mb-0">Upload local File of Video.</p>
                                                <hr></hr>
                                                <b>Types :</b> MP4,MKV,WEBM
                                                <button className="btn btn-primary" onClick={creativeChange('video')}>Upload Video</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-4 mt-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title">Link Upload</h5>
                                                <p className="card-text mb-0">Upload only Youtube video links.</p>
                                                <hr></hr>
                                                <b>Types :</b> Youtube Video Link and .Mp4 Link<br></br>
                                                <button className="btn btn-primary" onClick={creativeChange('link')}>Upload Link</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {creativeType.creativeTypeName === 'video' &&
                            <Create_Content closeCanvas={toggleDrawer1(false, '')} creativeTypeClose={creativeChange('selection')} uploadType={creativeType.creativeTypeName} creativeNewClose={creativeChange('closeNew')} />
                        }
                        {creativeType.creativeTypeName === 'link' &&
                            <Create_Content closeCanvas={toggleDrawer1(false, '')} creativeTypeClose={creativeChange('selection')} uploadType={creativeType.creativeTypeName} creativeNewClose={creativeChange('closeNew')} />
                        }
                    </div>
                </Col>
  )
}

Create_Content_Selection.propTypes = {}

export default Create_Content_Selection