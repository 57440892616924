/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback, Collapse, } from "reactstrap";
import axios from "axios";

import {
  Button,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";
import Creative_Selection from "./Creative_Selection";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// actions
import {
  addNewVideoCreative as onADDVideoCreative,
  updateVideoCreative as onUpdateVideoCreative,
  readVideoCreative as onReadVideoCreative,
  addVideoCreativeSuccess,
  addVideoCreativeFail,
  updateVideoCreativeSuccess,
  updateVideoCreativeFail,
} from "store/Demand/Creative/actions";

import classnames from "classnames";
import Toaster from "pages/Toaster/Toaster";

const intialVcPriorities = [
  {
    priority: [{
      vastTag: '',
      vastName:'',
      price: '',
      vastRetry: '2',
      errorLimit: '8',
      vastTimeoutTime: '2000',
      maxRequestsPerMinute: 'Unlimited'
    }]
  }
]

const intialVideoCreative = {
  videoCreativeName: '',
  bannerAdTag: '',
  videoCreativeContent: [{ ...intialVcPriorities, priority: intialVcPriorities.priority }],
  advertiserID: '',
  ioGroupID: '',
  ioID: '',
  lineItemID: '',
  "typeFor":"Tags",
}

const VideoThirdPartyCreative = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [timeOutEdit,seTtimeOutEdit] = useState(false);
  console.log(props)

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 1000,
    extendedTimeOut: 1000
  };

  const [videoCreative, setVideoCreative] = useState({
    ...intialVideoCreative,
    advertiserID: '',
    ioGroupID: '',
    ioID: '',
    lineItemID: '',
    "typeFor":"Tags",
    userID: userInfo.userID,
    userRole: userInfo.userRole,
    companyID: userInfo.companyID
  });

  const [submitted, setSubmitted] = useState(false);
  const [videoCreativeContent, setVideoCreativeContent] = useState([{
      priority: [{
        vastTag: '',
        vastName:'',
        price: '',
        vastRetry: '2',
        errorLimit: '8',
        vastTimeoutTime: '2000',
        maxRequestsPerMinute: 'Unlimited'
      }]
  }])

  const getCreativeData = async (selectedCreativeData) => {
    console.log(selectedCreativeData)
    setVideoCreative({ ...videoCreative, ...selectedCreativeData })
    console.log(videoCreative)
  }


  const vastHandleChange = (vIndex, e, pIndex) => {
    console.log("G");
    const { name, value } = e.target;
    const updatedVastTags = videoCreativeContent.map((vcGroup, j) => {
      if (pIndex === j) {
        return {
          ...vcGroup, priority: vcGroup.priority.map((tag, k) => {
            if (vIndex === k) {
              return { ...tag, [name]: value }
            }
            return tag;
          })
        }
      }
      return vcGroup;
    })
    setVideoCreativeContent(updatedVastTags)
    setVideoCreative(videoCreative => ({ ...videoCreative, videoCreativeContent: updatedVastTags }))
  }

  const HandleChangeMaxReq = (vIndex, e, pIndex) => {
    console.log("G");
    const { name, value } = e.target;
    const updatedVastTags = videoCreativeContent.map((vcGroup, j) => {
      if (pIndex === j) {
        return {
          ...vcGroup, priority: vcGroup.priority.map((tag, k) => {
            if (vIndex === k) {
              if(name.includes("unlimited")){
                return { ...tag, maxRequestsPerMinute: 'Unlimited' }
              }
              else{
                return { ...tag, maxRequestsPerMinute: '0' }
              }
            }
            return tag;
          })
        }
      }
      return vcGroup;
    })
    setVideoCreativeContent(updatedVastTags)
    setVideoCreative(videoCreative => ({ ...videoCreative, videoCreativeContent: updatedVastTags }))
  }

  const vastNumChange = (vIndex, e, pIndex) => {
    console.log("Vast Number -----------------")
    const { name, value } = e.target;
    const updatedVastTags = videoCreativeContent.map((vcGroup, j) => {
      if (pIndex === j) {
        return {
          ...vcGroup, priority: vcGroup.priority.map((tag, k) => {
            if (vIndex === k) {
              return { ...tag, [name]: value < 1 ? 1 : value }
            }
            return tag;
          })
        }
      }
      return vcGroup;
    })
    setVideoCreativeContent(updatedVastTags)
    setVideoCreative(videoCreative => ({ ...videoCreative, videoCreativeContent: updatedVastTags }))
  }

  const vastRequestChange = (vIndex, e, pIndex) => {
    console.log("Vast Request Change===================")
    const { name, value } = e.target;

    const updatedVastTags = videoCreativeContent.map((vcGroup, j) => {
      if (pIndex === j) {
        return {
          ...vcGroup, priority: vcGroup.priority.map((tag, k) => {
            let tempVal = value < 1 ? 1 : value
            if (isNaN(tempVal)) {
              tempVal = "Unlimited"
            }
            if (vIndex === k) {
              return { ...tag, [name]: tempVal }
            }
            return tag;
          })
        }
      }
      return vcGroup;
    })
    setVideoCreativeContent(updatedVastTags)
    setVideoCreative(videoCreative => ({ ...videoCreative, videoCreativeContent: updatedVastTags }))
  }


  const { errorMsg, successMsg, creativeVideoData } = useSelector(state => ({
    errorMsg: state.creatives.errorMsg,
    successMsg: state.creatives.successMsg,
    creativeVideoData: state.creatives.creative
  }));

  console.log(errorMsg, successMsg, creativeVideoData)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.statusCode === 200) {
        dispatch(addVideoCreativeSuccess(""));
        dispatch(addVideoCreativeFail(null));
        dispatch(updateVideoCreativeSuccess(""));
        dispatch(updateVideoCreativeFail(null));
        if (props.selectedCreativeData !== '' && props.selectedcreativeCreateStatus !== 'Duplicate') {
          props.creativeNewClose('Update')
        } else {
          props.creativeNewClose('Create')
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    console.log(errorMsg)
    if (errorMsg !== null) {
      toastr.error(errorMsg)
      dispatch(addVideoCreativeSuccess(""));
      dispatch(addVideoCreativeFail(null));
      dispatch(updateVideoCreativeSuccess(""));
      dispatch(updateVideoCreativeFail(null));
    }
  }, [errorMsg]);

  useEffect(() => {
    console.log(props.selectedCreativeData)
    if (props.selectedCreativeData !== '') {
      dispatch(onReadVideoCreative({ ...props.selectedCreativeData,userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID  }));
      if(props.selectedcreativeCreateStatus !== 'Duplicate'){
        setIsEdit(true)
      }
    }
  }, [])

  useEffect(() => {
    if (creativeVideoData !== null && props.selectedCreativeData !== '') {
      console.log(creativeVideoData)
      if (props.selectedcreativeCreateStatus === 'Duplicate') {
        delete creativeVideoData.videoCreativeCreateTime
        delete creativeVideoData.videoCreativeID
        delete creativeVideoData.videoCreativeModifyTime
        delete creativeVideoData.videoCreativeStatus
        delete creativeVideoData.isVideoCreativeArchived
       }
      //   setVideoCreative({
      //     ...videoCreative,
      //     videoCreativeName: creativeVideoData.videoCreativeName,
      //     lineItemID: creativeVideoData.lineItemID,
      //     bannerAdTag: creativeVideoData.bannerAdTag,
      //     videoCreativeContent: creativeVideoData.videoCreativeContent,
      // });
      let  priorityList = []
      priorityList.push(creativeVideoData.videoCreativeContent)
      const newVcGroup = [{
         priority: priorityList
        }
      ]
      setVideoCreative(videoCreative => ({ ...videoCreative, videoCreativeContent: newVcGroup,bannerAdTag: creativeVideoData.bannerAdTag,lineItemID: creativeVideoData.lineItemID}));
      setVideoCreativeContent(newVcGroup);
      console.log(videoCreative)
    }
  }, [creativeVideoData]);

  const handleChange = (e) => {
    console.log(e.target)
    const { name, value } = e.target;
    setVideoCreative(videoCreative => ({ ...videoCreative, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    console.log("submit click", videoCreative)
    if (videoCreative.videoCreativeName && videoCreative.lineItemID && videoCreative.bannerAdTag && videoCreative.videoCreativeContent.length > 0) {
      console.log(props.creativeId)
      if (videoCreativeContent.findIndex(group => group.priority.findIndex(tag => tag.vastTag === '') > -1) > -1) {
        toastr.warning(' Unable to Save with empty Vast tag — check it out!')
        return;
      }
      if (props.selectedCreativeData !== '' && props.selectedcreativeCreateStatus !== 'Duplicate') {
        dispatch(onUpdateVideoCreative({ ...videoCreative,videoCreativeContent:videoCreative.videoCreativeContent[0].priority[0],videoCreativeName:videoCreative.videoCreativeName.trim(),videoCreativeID:props.selectedCreativeData.creativeID,userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID  }));
      }
      else {
        dispatch(onADDVideoCreative({ ...videoCreative,videoCreativeContent:videoCreative.videoCreativeContent[0].priority[0],videoCreativeName:videoCreative.videoCreativeName.trim(),userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID  }));
      }
    }
  }

  useEffect(async () => {
    console.log("VideoCreative--------------", videoCreative)
    console.log(videoCreativeContent);

    if (props.selectedLineItem !== '') {
      setVideoCreative({ ...videoCreative, lineItemID: props.selectedLineItem.lineItemID })
    }
    if (props.creativeId) {
      const bodyCreative = {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
    }
    axios.post("/v2.0/demand/VideoCreative/read/" + props.creativeId, bodyCreative,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json',
            "Authorization": `Bearer ${userInfo.token}`
          }
        })
        .then((res) => {
          console.log("result is ==>", res)
          if (res.data) {
            console.log("response data in useeffect==================", res.data)
            // setVideoCreative(res.data)
            setVideoCreative({
              ...videoCreative,
              videoCreativeName: res.data.response.videoCreativeName,
              lineItemID: res.data.response.lineItemID,
              bannerAdTag: res.data.response.bannerAdTag,
              videoCreativeContent: res.data.response.videoCreativeContent,
            });

            const newVcGroup = res.data.response.videoCreativeContent
            setVideoCreativeContent(newVcGroup);
            console.log(videoCreative)
          }
        })
        .catch((err) => {
          console.log("e", err)
        });
    }
  }, [])

  function allowOnlyNumbers(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code >= 48 && code < 58) && !(code >= 46 && code < 47)) { e.preventDefault(); }
  };

  return (
    <React.Fragment>
      <div className="col-12">

        <Form onSubmit={handleSubmit}>
          <Row form className="formBodyScroll" style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
            <Col xs={12}>
              <h6 className="text-muted">Video Third Party Creative Setup</h6>
              <hr></hr>
              <Creative_Selection selectedArchived={props.selectedArchived} creativeType={props.creativeType} selectedItems={getCreativeData} submitted={submitted} selectedCreativeData={props.selectedCreativeData} creativeStatus={isEdit} selectedLineItem={props.selectedLineItem} />
              <h6 className="text-muted">Video Template Setup</h6>
              <hr></hr>
              <div className="form-group">
                <Label >
                  Video Creative Banner Tag
                </Label>
                <Input type="textarea" id="textarea" 
                  rows="3" style={{ width: '100%' }}
                  value={videoCreative.bannerAdTag}
                  name='bannerAdTag'
                  onChange={handleChange}
                  className={'form-control' + (submitted && !videoCreative.bannerAdTag ? ' is-invalid' : '')}
                />
                {submitted && !videoCreative.bannerAdTag &&
                  <div className="invalid-feedback">Video Creative Banner Tag is required</div>
                }
              </div>

              <div className="mt-4 mb-4">
                <h5 >
                  Tag
                </h5>

                {videoCreativeContent && videoCreativeContent.map((item, index) => {
                  console.log("group............Item", item, "videoCreativeContent==========", videoCreativeContent);
                  return (
                    <div className="videoCreativeContent-settings" key={index}>
                      {
                            item.priority.map((subItem = {}, i) => {
                              return (
                                <div className="vast-settings" key={i}>
                                  <div className="accordion-body">
                                    <div >{subItem.vast}

                                      <div className="row form-group">
                                        <div className='col-11 mb-3'>
                                          <Label > Ad Source Name</Label>
                                          <Input
                                            type="text" name="vastName" onChange={(e) => vastHandleChange(i, e, index)}
                                            id="standard-full-width"
                                            className={'form-control' + (submitted && !subItem.vastName ? ' is-invalid' : '')}
                                            value={subItem.vastName}
                                          />
                                          {submitted && !subItem.vastName &&
                                            <div className="invalid-feedback">Ad Source Name is required</div>
                                          }
                                        </div>
                                        <div className='col-1'></div>
                                        <div className='col-11 mb-3'>
                                          <Label >
                                            Ad Source 
                                          </Label>
                                          <Input type="textarea" id="textarea"
                                            rows="2" value={subItem.vastTag}
                                            style={{ width: '100%' }}
                                            name="vastTag"
                                            onChange={(e) => vastHandleChange(i, e, index)}
                                            className={'form-control' + (submitted && !subItem.vastTag ? ' is-invalid' : '')}
                                          />
                                          {submitted && !subItem.vastTag &&
                                            <div className="invalid-feedback">Ad Source is required</div>
                                          }
                                        </div>
                                        <div className='col-1'></div>
                                      </div>
                                      <div className=" row form-group mb-3">
                                        <div className='col-5'>
                                          <Label >  Price</Label>
                                          <Input
                                            type="text" onKeyPress={allowOnlyNumbers} name="price" onChange={(e) => vastHandleChange(i, e, index)}
                                            onBlur={(e) => vastNumChange(i, e, index)}
                                            id="standard-full-width"
                                            className={'form-control' + (submitted && !subItem.price ? ' is-invalid' : '')}
                                            value={subItem.price}
                                          />
                                          {submitted && !subItem.price &&
                                            <div className="invalid-feedback">Price is required</div>
                                          }
                                        </div>
                                        <div className='col-1'>
                                        </div>
                                        <div className='col-5'>
                                          <Label >  Vast Retry</Label>
                                          <Input
                                            type="text" onKeyPress={allowOnlyNumbers} name="vastRetry" onChange={(e) => vastHandleChange(i, e, index)}
                                            onBlur={(e) => vastNumChange(i, e, index)}
                                            value={subItem.vastRetry}
                                            className={'form-control' + (submitted && !subItem.vastRetry ? ' is-invalid' : '')}
                                          />
                                          {submitted && !subItem.vastRetry &&
                                            <div className="invalid-feedback">VastRetry is required</div>
                                          }
                                        </div>
                                      </div>
                                      <div className=" row form-group mb-3">
                                        <div className='col-5'>
                                          <Label >  Error Limit</Label>
                                          <Input
                                            type="text" onKeyPress={allowOnlyNumbers} name="errorLimit" onChange={(e) => vastHandleChange(i, e, index)}
                                            onBlur={(e) => vastNumChange(i, e, index)}
                                            value={subItem.errorLimit}
                                            className={'form-control' + (submitted && !subItem.errorLimit ? ' is-invalid' : '')}
                                          />
                                          {submitted && !subItem.errorLimit &&
                                            <div className="invalid-feedback">ErrorLimit is required</div>
                                          }
                                        </div>
                                        <div className='col-1'>

                                        </div>
                                        <div className='col-4'>
                                          {/* <Label > Vast Timeout Time</Label> */}
                                          <div className="form-check mt-2 mb-2">
                                                <input
                                                    className="form-check-input "
                                                    type="checkbox"
                                                    name="vastTimeoutTime"
                                                    id="vastTimeoutTime"
                                                    checked={timeOutEdit}
                                                    onChange={(e) => seTtimeOutEdit(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor='vastTimeoutTime'>Vast Timeout Time </label>
                                            </div>
                                          <Input
                                           type="text" onKeyPress={allowOnlyNumbers} name="vastTimeoutTime" onChange={(e) => vastHandleChange(i, e, index)}
                                            onBlur={(e) => vastNumChange(i, e, index)} readOnly={!timeOutEdit}
                                            value={subItem.vastTimeoutTime}
                                            className={'form-control' + (submitted && !subItem.vastTimeoutTime ? ' is-invalid' : '')}
                                          />
                                          {submitted && !subItem.vastTimeoutTime &&
                                            <div className="invalid-feedback">VastTimeoutTime is required</div>
                                          }

                                        </div>
                                        <div className='col-1 mt-5 ml-0'>
                                          <Label > (msec)</Label>
                                        </div>
                                      </div>
                                      <div className=" row form-group mb-3">
                                        <div className='col-5'>
                                          <Label > Max Requests PerMinute</Label>
                                          <div className="row">
                                        <Col md={6}>
                                            <div className="form-check mt-2 mb-2">
                                                <input
                                                    className="form-check-input "
                                                    type="radio"
                                                    name={`${index}${i}unlimited`}
                                                    id={`${index}${i}unlimited`}
                                                    checked={subItem.maxRequestsPerMinute === 'Unlimited'}
                                                    onChange={(e) => HandleChangeMaxReq(i, e, index)}
                                                />
                                                <label className="form-check-label" htmlFor={`${index}${i}unlimited`}>Unlimited </label>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="form-check mt-2 mb-2">
                                                <input
                                                    className="form-check-input "
                                                    type="radio"
                                                    name={`${index}${i}custom`}
                                                    id={`${index}${i}custom`}
                                                    checked={subItem.maxRequestsPerMinute !== 'Unlimited'}
                                                    onChange={(e) => HandleChangeMaxReq(i, e, index)}
                                                />
                                                <label className="form-check-label" htmlFor={`${index}${i}custom`}>Custom </label>
                                            </div>
                                        </Col>
                                        </div>
                                          <Input
                                            type="text" onKeyPress={allowOnlyNumbers} name="maxRequestsPerMinute" onChange={(e) => vastHandleChange(i, e, index)}
                                            onBlur={(e) => vastRequestChange(i, e, index)} readOnly={subItem.maxRequestsPerMinute === 'Unlimited'}
                                            value={subItem.maxRequestsPerMinute}
                                            className={'form-control' + (submitted && !subItem.maxRequestsPerMinute ? ' is-invalid' : '')}
                                          />
                                          {submitted && !subItem.maxRequestsPerMinute &&
                                            <div className="invalid-feedback">MaxRequestsPerMinute is required</div>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                }
                    </div>
                  )
                }
                )}
              </div>
            </Col>
          </Row>
          <Row style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">
                {isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.creativeTypeClose}
                >
                  Back
                </button>
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' &&
          <Toaster status="error" msg={errorMsg} />
        }
      </div>
    </React.Fragment>
  )
}

VideoThirdPartyCreative.propTypes = {
  history: PropTypes.object,
}

export default VideoThirdPartyCreative