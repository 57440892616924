import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Col, Container, Row, } from "reactstrap";

// availity-reactstrap-validation
import { Link } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

// import images
import sessionImg from "assets/images/bidsxchange/session-timeout_1.svg"

// actions
import { apiError } from "../../store/actions";

const SessionOut = (props) => {
  const dispatch = useDispatch();
  const linkRef = useRef();

  useEffect(() => {
    localStorage.removeItem('authUser');
    localStorage.removeItem('UserRole');
    localStorage.removeItem('ParentRole');
    localStorage.removeItem('Terms');
    localStorage.removeItem('NotificationStatus');
    localStorage.removeItem('UserRolePrivileges');
    localStorage.removeItem('previousNavigation');
    localStorage.removeItem('UserSwitching');
    localStorage.removeItem('AdminDetails');
    localStorage.removeItem('activeTab');
    localStorage.removeItem('InventoryType');
    localStorage.removeItem('userSessionState');
    localStorage.removeItem('darkMode');
    dispatch(apiError(null));
  }, []);

  return (
    <React.Fragment>
      <div>
        <Helmet>
          <title>Session Out | Bidsxchange</title>
        </Helmet>
        <Container fluid className="p-0">
          <Row className="g-0" style={{ background: '#222744' }}>
            <Col xl={12}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-50" style={{ margin: "0 auto" }}>
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                    </div>
                    <div className="my-auto">
                      <h1 className=" text-center" style={{ color: '#f1f1f1', fontWeight: '700' }}>Session Timeout</h1>
                      <div className="mt-4">
                        <div className="user-thumb text-center mb-4">
                          <img src={sessionImg} className="sessionImg" alt='Session Out' style={{ width: '20vw' }} />
                        </div>
                        <div className="text-center">
                          <p className='mt-2 mb-2' style={{ color: '#f1f1f1' }}>Due to browser inactivity for the past 20 minutes your session has timed out and you must login again to access your data.
                            We apologize for the inconvience, but session timeouts were added for security reasons to ensure that your data is protected.</p>

                          <p className='mt-4 mb-4' style={{ color: '#90929df0' }}>Please click the login button below to return to the <Link
                            to="login" className=" " type="submit" style={{ color: '#f1f1f1', fontWeight: '500' }} >Bidsxchange</Link> login page.</p>

                          <Link
                            to="login" className="btn btn-primary w-md" type="submit" > LOGIN PAGE </Link>
                        </div>
                        <Link ref={linkRef} to="/login"></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

SessionOut.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
};

export default SessionOut;
