/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, Dropdown, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, } from "reactstrap"
import axios from "axios";

var itemInfo = {}

const SupplyBreadCrumb = props => {

  const userInfo = JSON.parse(localStorage.getItem('authUser'));

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  useEffect(() => {
    if (props.publisherId || props.adUnitGroupId || props.adUnitId || props.childAdUnitID) {
      var filterBody = {
          "partnerID": 0,
          "publisherID": 0,
          "adUnitGroupID": 0,
          "adUnitID": 0,
          "childAdUnitID": 0,
          "playerSettingID": 0,
          userID: userInfo.userID,
          userRole: userInfo.userRole,
          companyID: userInfo.companyID,
          name:userInfo.name,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner
      }
      if (props.publisherId) {
        filterBody.publisherID = JSON.parse(props.publisherId)
      } else if (props.adUnitGroupId) {
        filterBody.adUnitGroupID = JSON.parse(props.adUnitGroupId)
      }
      else if (props.adUnitId) {
        filterBody.adUnitID = JSON.parse(props.adUnitId)
      }
      else if (props.childAdUnitID) {
        filterBody.childAdUnitID = JSON.parse(props.childAdUnitID)
      } else {
        filterBody.partnerID = 0
      }
      axios.post(userInfo.isSupplyPartner ?"/v2.0/supplyPartner/getParent/SupplyPartner" : "/v2.0/supply/getParent", filterBody,
        {
          headers: headers
        })
        .then((res) => {
          console.log("cdsresult is ==>", res)
          if (res.data) {
            itemInfo = {...itemInfo,...res.data.response}
            console.log(itemInfo)
          }
        })
        .catch((err) => {
          console.log("e", err)
          // setInValid(true)
        });
    }
  }, [])

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box  align-items-center ">
          <h4 className="mb-2 font-size-18">{props.breadcrumbItem}</h4>
          <ol className="breadcrumb m-0 collapsed">
            <div style={{ display: 'contents', fontSize: '12px' }}>
              <li>
                <Link to="/Dashboard" className="color-dark-blue signUp"> <i
                  className="mdi mdi-home "
                ></i> Home</Link>
              </li>
              <li style={{ display: 'none' }}></li>
            </div>
            {!props.breadList && !props.partnerName && !props.publisherId && !props.adUnitGroupId && !props.adUnitId &&
              <div style={{ display: 'contents', fontSize: '12px' }}>
                <li>
                  <span>{props.breadcrumbItem}</span>
                </li>
              </div>
            }
            {props.breadList &&
              <li>
                <span> {props.breadList}</span>
              </li>
            }
            {props.partnerName &&
              <div style={{ display: 'contents', fontSize: '12px' }}>
                <li>
                  <Link to="/Supply_Partner">All Supply Partners</Link>
                </li>
                <li>
                  <span> {props.partnerName}</span>
                </li>
              </div>
            }
            {props.publisherId &&
              <div style={{ display: 'contents', fontSize: '12px' }}>
                {(userInfo.userRole === 'Partner'|| userInfo.userRole === 'Agency') ? '' : <li>
                  <Link to="/Supply_Partner">All Supply Partners</Link>
                </li>}
                <li style={{ display: "flex" }}>
                  <UncontrolledDropdown
                    className="dropdown  active" style={{ fontSize: '1.5rem', marginTop: '-5px' }}
                  >
                    <DropdownToggle
                      tag="span" className="dropdown-toggle"
                    >
                      <i className="bx bx-dots-horizontal-rounded" />
                    </DropdownToggle>
                    <DropdownMenu data-popper-placement="left-start">
                      <DropdownItem > Partner  : &nbsp;&nbsp;<Link to={`/Publisher?partnerId=${itemInfo.partnerID}&partnerName=${itemInfo.username}`} className="color-dark-blue signUp">
                        {itemInfo.username}
                      </Link></DropdownItem>
                      <DropdownItem >   <Link to="/Publisher">All Publishers</Link></DropdownItem>

                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
                <li>
                  <span> {itemInfo.publisherName}</span>
                </li>
              </div>
            }
            {props.adUnitGroupId &&
              <div style={{ display: 'contents', fontSize: '12px' }}>
                      {(userInfo.userRole === 'Partner'|| userInfo.userRole === 'Agency') ? '' : <li>
                  <Link to="/Supply_Partner">All Supply Partners</Link>
                </li>}
                <li style={{ display: "flex" }}>
                  <UncontrolledDropdown
                    className="dropdown  active" style={{ fontSize: '1.5rem', marginTop: '-5px' }}
                  >
                    <DropdownToggle
                      tag="span" className="dropdown-toggle"
                    >
                      <i className="bx bx-dots-horizontal-rounded" />
                    </DropdownToggle>
                    <DropdownMenu data-popper-placement="left-start">
                      <DropdownItem >Partner  : &nbsp;&nbsp;<Link to={`/Publisher?partnerId=${itemInfo.partnerID}&partnerName=${itemInfo.username}`} className="color-dark-blue signUp">
                        {itemInfo.username}
                      </Link></DropdownItem>
                      <DropdownItem >         <Link to="/Publisher">All Publishers</Link></DropdownItem>
                      <DropdownItem >Publisher  : &nbsp;&nbsp;<Link to={`/AdUnitGroup?publisherId=${itemInfo.publisherID}&publisherName=${itemInfo.publisherName}`} className="color-dark-blue signUp">
                        {itemInfo.publisherName}
                      </Link></DropdownItem>
                      <DropdownItem >   <Link to="/AdUnitGroup">All Ad Unit Groups</Link></DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
                <li>
                  <span> {itemInfo.adUnitGroupName}</span>
                </li>
              </div>
            }
            {(props.adUnitId) &&
              <div style={{ display: 'contents', fontSize: '12px' }}>
                      {(userInfo.userRole === 'Partner'|| userInfo.userRole === 'Agency') ? '' : <li>
                  <Link to="/Supply_Partner">All Supply Partners</Link>
                </li>}
                <li style={{ display: "flex" }}>
                  <UncontrolledDropdown
                    className="dropdown  active" style={{ fontSize: '1.5rem', marginTop: '-5px' }}
                  >
                    <DropdownToggle
                      tag="span" className="dropdown-toggle"
                    >
                      <i className="bx bx-dots-horizontal-rounded" />
                    </DropdownToggle>
                    <DropdownMenu data-popper-placement="left-start">
                      <DropdownItem > Partner  : &nbsp;&nbsp;   <Link to={`/Publisher?partnerId=${itemInfo.partnerID}&partnerName=${itemInfo.username}`} className="color-dark-blue signUp">
                        {itemInfo.username}
                      </Link></DropdownItem>
                      <DropdownItem >              <Link to="/Publisher">All Publishers</Link></DropdownItem>
                      <DropdownItem > Publisher  : &nbsp;&nbsp;    <Link to={`/AdUnitGroup?publisherId=${itemInfo.publisherID}&publisherName=${itemInfo.publisherName}`} className="color-dark-blue signUp">
                        {itemInfo.publisherName}
                      </Link></DropdownItem>
                      <DropdownItem >    <Link to="/AdUnitGroup">All Ad Unit Groups</Link></DropdownItem>
                      <DropdownItem >Ad Unit Group  : &nbsp;&nbsp; <Link to={`/AdUnit?adUnitGroupId=${itemInfo.adUnitGroupID}&adUnitGroupName=${itemInfo.adUnitGroupName}`} className="color-dark-blue signUp">
                        {itemInfo.adUnitGroupName}
                      </Link></DropdownItem>
                      <DropdownItem >    <Link to="/AdUnit">All Ad Units</Link></DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
                <li>
                  <span>{itemInfo.adUnitName}</span>
                </li>
              </div>
            }
          </ol>
        </div>
      </Col>
    </Row>
  )
}

SupplyBreadCrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default SupplyBreadCrumb