import {
    LOAD_ACTIVITIES,
    NO_DATA_ROLES,
    GET_ROLES_SUCCESS,
    GET_ROLES_FAIL,
    ADD_ROLE_SUCCESS,
    ADD_ROLE_ERROR,
    UPDATE_ROLE_SUCCESS,
    UPDATE_ROLE_ERROR,
    READ_ROLE_SUCCESS,
    STATUS_ROLE_SUCCESS,
    ARCHIVE_ROLE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
    roles:[],
    error: {},
    loading:false,
    noData:false,
    successMsg: null,
    errorMsg: null,
    role: null,
    response:null,
    archiveMsg:null,
}

const roles = (state = INIT_STATE, action) => {
    
    switch (action.type) {
        case LOAD_ACTIVITIES:
            return {
                ...state,
                loading: true,
            }
            case NO_DATA_ROLES:
                return {
                    ...state,
                    noData: true,
                }
        case GET_ROLES_SUCCESS:
            return {
                ...state,
                loading:false,
                roles: action.payload,
            }

        case GET_ROLES_FAIL:
            return {
                ...state,
                loading:false,
                error: action.payload,
            }

        case ADD_ROLE_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_ROLE_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_ROLES_SUCCESS' ? null : action.payload,
            }
        case UPDATE_ROLE_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_ROLES_SUCCESS' ? null : action.payload,
            }
        case UPDATE_ROLE_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_ROLES_SUCCESS' ? null : action.payload,
            }
        case READ_ROLE_SUCCESS:
            return {
                ...state,
                role: action.payload,
            }
        case STATUS_ROLE_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_ROLE_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default roles