import {
    LOAD_ACTIVITIES,
    NO_DATA_BILLING,
    GET_BILL_HISTORY_SUCCESS,
    GET_BILL_HISTORY_FAIL,

    GET_BILL_HISTORY_SUCCESS_LIST,
    GET_BILL_HISTORY_FAIL_LIST
} from "./actionTypes"

const INIT_STATE = {
    bills: [],
    pagination:{},
    billList:[],
    paginationList:{},
    loading:false,
    noData:false,
    users:[],
    error: {},
    successMsg: null,
    errorMsg: null,
    bill: null,
    response:null,
    archiveMsg:null,
}

const billsHistory = (state = INIT_STATE, action) => {
    
    switch (action.type) {
        case LOAD_ACTIVITIES:
            return {
                ...state,
                loading: true,
            }
        case NO_DATA_BILLING:
                return {
                    ...state,
                    noData: true,
                }
        case GET_BILL_HISTORY_SUCCESS:
            return {
                ...state,
                loading:false,
                bills: action.payload.response ? action.payload.response : [],
                pagination:action.payload
            }

        case GET_BILL_HISTORY_FAIL:
            return {
                ...state,
                loading:false,
                error: action.payload,
            }
            case GET_BILL_HISTORY_SUCCESS_LIST:
                return {
                    ...state,
                    loading:false,
                    billList: action.payload.response ? action.payload.response : [],
                    paginationList:action.payload
                }
    
            case GET_BILL_HISTORY_FAIL_LIST:
                return {
                    ...state,
                    loading:false,
                    error: action.payload,
                }
        default:
            return state
    }
}

export default billsHistory