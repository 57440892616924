/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback, Spinner } from "reactstrap";
import CreatableSelect from 'react-select/creatable';
import Select from "react-select";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

import {
    getCategory as onGetCategories,
} from "store/customTracking/Categories/actions";

// actions
import {
    addNewHouseAdCreative as onADDCreative,
    updateHouseAdCreative as onUpdateCreative,
    readHouseAdCreative as onReadCreative,
    addHouseAdCreativeSuccess,
    addHouseAdCreativeFail,
    updateHouseAdCreativeSuccess,
    updateHouseAdCreativeFail,
} from "store/customTracking/Creatives/actions";

import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";

const NewCreatives = props => {
    const dispatch = useDispatch();
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [submitted, setSubmitted] = useState(false);
    const [loader, setLoader] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [imageData, setImageData] = useState('');
    const [categoryData, setCategoryData] = useState([])

    console.log(props)

    const [finalData, setFinalData] = useState({
        houseAdsCreativeName: '',
        houseAdsCreativeType: "",
        houseAdsCreativeLandingPageUrl: '',
        houseAdsCreativeSize: '',
        houseAdsCategoryID: '',
        houseAdsCreative: '',
    })

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSize, setSelectedSize] = useState(null);
    const [sizeData, setSizeData] = useState([
      { value: "0x0", label: "0x0" },
      { value: "1x1", label: "1x1" },
      { value: "300x250", label: "300x250" },
      { value: "300x600", label: "300x600" },
      { value: "320x442", label: "320x442" },
      { value: '360x480', label: '360x480'},
      { value: "468x100", label: "468x100" },
      {value:'640x480',label:'640x480'},
      { value: "728x90", label: "728x90" },
      { value: "800x600", label: "800x600" },
      { value: "120x600", label: "120x600" },
      { value: "160x600", label: "160x600" },
      { value: "970x250", label: "970x250" },
      {value:'1280x250',label:'1280x250'},
      { value: "970x90", label: "970x90" },
      { value: "320x50", label: "320x50" },
      { value: "320x100", label: "320x100" },
      { value: "320x480", label: "320x480" },
      { value: "300x50", label: "300x50" },
      { value: "300x100", label: "300x100" },
      { value: "468x60", label: "468x60" },
      { value: "468x90", label: "468x90" },
      { value: "336x280", label: "336x280" },
      { value: "800x485", label: "800x485" },])

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const { categories } = useSelector(state => ({
        categories: state.categories.ctgs,
    }));

    console.log(categories)

    useEffect(() => {
        if (props.selectedCreative !== '') {
            setIsEdit(true)
            setFinalData(props.selectedCreative);
            setSelectedSize({ value: props.selectedCreative.houseAdsCreativeSize, label: props.selectedCreative.houseAdsCreativeSize });
            if (props.selectedCreative.houseAdsCreativeType === 'image') {
                setImageData(props.selectedCreative.houseAdsCreative)
            }
        }
    }, [])


    useEffect(() => {
        if (categories !== null) {
            dispatch(onGetCategories({ filter: 'Active' }));
        }
    }, []);

    const objectModal = (response) => {
        var dataList = []
        response.map((item, index) => {
          let obj = {}
          obj.value = item.houseAdsCategoryID
          obj.label = item.houseAdsCategoryName
          dataList.push(obj)
        })
        if (props.selectedCreative !== '') {
            response.map((item, index) => {
                if(props.selectedCreative.houseAdsCategoryID === item.houseAdsCategoryID){
                    setSelectedCategory({ value: item.houseAdsCategoryID, label: item.houseAdsCategoryName });
                }
              })
        }
        return dataList
      }
    
      useEffect(() => {
        if (categories && categories.length > 0) {
          setCategoryData(objectModal(categories))
          console.log(categories)
        }
      }, [categories]);

    const fileChange = (file) => {
        console.log(file.target.files[0])
        console.log(file.target.files[0].name)
        // fileName = file.target.files[0].name
        encodeFileBase64(file.target.files[0])
        setImageData(URL.createObjectURL(file.target.files[0]))
    };

    const encodeFileBase64 = (file) => {
        var reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
                var base64 = reader.result
                if (base64.includes("data:image/png;base64,")) {
                    base64 = reader.result.replace("data:image/png;base64,", "");
                }
                else if (base64.includes("data:image/jpeg;base64,")) {
                    base64 = reader.result.replace("data:image/jpeg;base64,", "");
                } else if (base64.includes("data:image/gif;base64,")) {
                    base64 = reader.result.replace("data:image/gif;base64,", "");
                }
                else if (base64.includes("data:application/x-zip-compressed;base64,")) {
                    base64 = reader.result.replace("data:application/x-zip-compressed;base64,", "");
                }
                console.log(base64)
                if (base64) {
                    setFinalData({ ...finalData, houseAdsCreative: base64, })
                }
            }
            reader.onerror = (error) => {
                console.log("error: ", error)
            };
        }
    }

    const changeSelect = (e) => {
        console.log(e)
        const { name, value } = e.target;
        setFinalData(finalData => ({ ...finalData, [name]: value }));
    }

    const handleChange = (e) => {
        console.log(e.target)
        const { name, value } = e.target;
        console.log(e.target.type)
        if (e.target.type === 'number') {
            if (value) {
                setFinalData(finalData => ({ ...finalData, [name]: parseInt(value) }));
            }
            else {
                setFinalData(finalData => ({ ...finalData, [name]: value }));
            }
        } else {
            setFinalData(finalData => ({ ...finalData, [name]: value }));
        }

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(finalData)
        setSubmitted(true);
        if (finalData.houseAdsCreativeName && finalData.houseAdsCreativeType && finalData.houseAdsCreative && finalData.houseAdsCreativeSize && finalData.houseAdsCategoryID) {
            setLoader(true)
            if (props.selectedCreative !== '') {
                dispatch(onUpdateCreative({ ...finalData, houseAdsCreativeName: finalData.houseAdsCreativeName.trim() }));
            } else {
                dispatch(onADDCreative({ ...finalData, houseAdsCreativeName: finalData.houseAdsCreativeName.trim() }));
            }
        }
    }

    const { errorMsg, successMsg } = useSelector(state => ({
        errorMsg: state.creative.errorMsg,
        successMsg: state.creative.successMsg,
    }));

    console.log(errorMsg, successMsg)

    useEffect(() => {
        if (successMsg !== null) {
            if (successMsg.success) {
                dispatch(addHouseAdCreativeSuccess(""));
                dispatch(addHouseAdCreativeFail(""));
                dispatch(updateHouseAdCreativeSuccess(""));
                dispatch(updateHouseAdCreativeFail(""));
                setLoader(false)
                if (props.selectedCreative !== '') {
                    props.closeCanvas('Update')
                } else {
                    props.closeCanvas('Create')
                }
            }
        }
    }, [successMsg]);

    useEffect(() => {
        if (errorMsg !== null && errorMsg !== '') {
            setLoader(false)
            if (errorMsg.status && errorMsg.status === 500) {
            } else {
                dispatch(addHouseAdCreativeSuccess(""));
                dispatch(addHouseAdCreativeFail(""));
                dispatch(updateHouseAdCreativeSuccess(""));
                dispatch(updateHouseAdCreativeFail(""));
            }
        }
    }, [errorMsg]);

    const toggleToast = (n) => (e) => {
        dispatch(addHouseAdCreativeSuccess(""));
        dispatch(addHouseAdCreativeFail(""));
        dispatch(updateHouseAdCreativeSuccess(""));
        dispatch(updateHouseAdCreativeFail(""));
        props.closeCanvas('')
    };

    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };

    function allowAlphaNumericSpace(e) {
        var code = ('charCode' in e) ? e.charCode : e.keyCode;
        if (!(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault(); }
    };

    function handleSelectSize(selectSize) {
        setFinalData(finalData => ({ ...finalData, houseAdsCreativeSize: selectSize.value }));
        setSelectedSize(selectSize);
      }

    function handleSelectCategory(selectCategory) {
        setFinalData(finalData => ({ ...finalData, houseAdsCategoryID: selectCategory.value }));
        setSelectedCategory(selectCategory);
      }

    return (
        <React.Fragment>
            {loader &&
                <Spinner className="ms-2 loader" color="primary" />
            }
            <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
                <Form onSubmit={handleSubmit}>
                    <Row className="formBodyScroll" style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
                        <Col xs={12}>
                            <div className="mb-3">
                                <Label className="form-label">Creative Name  <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                <Input
                                    name="houseAdsCreativeName"
                                    type="text"
                                    onChange={handleChange}
                                    maxLength={65}
                                    value={finalData.houseAdsCreativeName}
                                    onKeyPress={allowAlphaNumericSpace}
                                    className={'form-control' + (submitted && !finalData.houseAdsCreativeName ? ' is-invalid' : '')}
                                    readOnly={props.selectedArchived}
                                />
                                {submitted && !finalData.houseAdsCreativeName ? (
                                    <FormFeedback type="invalid">{'Creative Name is required'}</FormFeedback>
                                ) : null}
                            </div>

                            <div className="mb-3">
                                <Label className="form-label"> Creative Type  <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                <Input
                                    name="houseAdsCreativeType"
                                    type="select"
                                    value={finalData.houseAdsCreativeType}
                                    className={'form-control form-select' + (submitted && !finalData.houseAdsCreativeType ? ' is-invalid' : '')}
                                    onChange={changeSelect}
                                >
                                    <option value=''>Select</option>
                                    <option value='js'>JS</option>
                                    <option value='image'>Image</option>
                                    <option value='html'>HTML</option>
                                </Input>
                                {submitted && !finalData.houseAdsCreativeType ? (
                                    <FormFeedback type="invalid">{'Creative Type is required'}</FormFeedback>
                                ) : null}
                            </div>
                            {finalData.houseAdsCreativeType === 'js' &&
                                <div className="mb-3">
                                    <Label >
                                        Input Creative Passback Tag  <span style={{ color: 'red', fontSize: '15px' }}>*</span>
                                    </Label>
                                    <Input
                                        type="textarea"
                                        id="textarea"
                                        rows="5" name="houseAdsCreative"
                                        placeholder="Creative Passback Tag"
                                        value={finalData.houseAdsCreative}
                                        className={'form-control' + (submitted && !finalData.houseAdsCreative ? ' is-invalid' : '')}
                                        readOnly={props.selectedArchived}
                                        onChange={handleChange}
                                    />
                                    {submitted && !finalData.houseAdsCreative ? (
                                        <FormFeedback type="invalid">{'Creative Passback Tag is required'}</FormFeedback>
                                    ) : null}
                                </div>
                            }
                            {finalData.houseAdsCreativeType === 'image' &&
                                <div className="mb-3">
                                    <Label className="form-label">Browse Image File</Label>
                                    <div>
                                        <Input type="file" accept="image/x-png,image/gif,image/jpeg" onChange={fileChange} />
                                        <span className="notes">Note: Allowed file types: gif, jpg, png, jpeg. Maximum allowed file size: 15 MB.</span>
                                    </div>
                                    {imageData !== '' &&
                                        <div className="mb-3">
                                            <img className='single-frame m-1 ' src={imageData} crossOrigin="anonymous" alt="Image" />
                                        </div>
                                    }

                                </div>

                            }
                            {finalData.houseAdsCreativeType === 'html' &&
                                <div className="mb-3">
                                    <Label className="form-label">Browse Zip File</Label>
                                    <div>
                                        <Input type="file" onChange={fileChange} />
                                        <span className="notes">Note: Allowed file types: zip. Maximum allowed file size: 15 MB.</span>
                                    </div>
                                </div>
                            }
                            {submitted && !finalData.houseAdsCreativeType && !finalData.houseAdsCreative ? (
                                <FormFeedback type="invalid">{'Creative Content is required'}</FormFeedback>
                            ) : null}
                            <div className="mb-3">
                                <Label className="form-label">Ad Size  <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                <Select
                                    value={selectedSize}
                                    onChange={s => {
                                        handleSelectSize(s)
                                    }}
                                    options={sizeData}
                                    classNamePrefix="select2-selection"
                                    className={(submitted && !finalData.houseAdsCreativeSize ? ' is-invalid' : '')}
                                    isDisabled={props.selectedArchived}
                                />
                                {submitted && !finalData.houseAdsCreativeSize ? (
                                    <FormFeedback type="invalid">{'Ad Size is required'}</FormFeedback>
                                ) : null}

                            </div>
                            <div className="mb-3">
                                <Label className="form-label"> Select Category  <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                {/* <Input
                                    name="houseAdsCategoryID"
                                    type="select"
                                    value={finalData.houseAdsCategoryID}
                                    className={'form-control form-select' + (submitted && !finalData.houseAdsCategoryID ? ' is-invalid' : '')}
                                    onChange={changeSelect}
                                >
                                    <option value=''>Select</option>
                                    {categories.map((item) => (
                                        <option key={item.id} value={item.houseAdsCategoryID}>{item.houseAdsCategoryName}</option>
                                    ))}
                                </Input> */}
                                <Select
                                  value={selectedCategory}
                                  onChange={s => {
                                    handleSelectCategory(s)
                                  }}
                                  options={categoryData}
                                  classNamePrefix="select2-selection"
                                  className={(submitted && !finalData.houseAdsCreativeSize ? ' is-invalid' : '')}
                                  isDisabled={props.selectedArchived}
                                />
                                {submitted && !finalData.houseAdsCategoryID ? (
                                    <FormFeedback type="invalid">{'Category is required'}</FormFeedback>
                                ) : null}
                            </div>
                            {(finalData.houseAdsCreativeType === 'image' || finalData.houseAdsCreativeType === 'html') &&
                                <div className="mb-3">
                                    <Label className="form-label">Landing Page URL</Label>
                                    <Input
                                        name="houseAdsCreativeLandingPageUrl"
                                        type="text"
                                        onChange={handleChange}
                                        value={finalData.houseAdsCreativeLandingPageUrl}
                                    />
                                </div>
                            }

                        </Col>
                    </Row>
                    <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
                        <Col>
                            <div className="text-start">
                                &nbsp;&nbsp;
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    // eslint-disable-next-line react/prop-types
                                    onClick={props.closeCanvas}
                                >
                                    Back
                                </button>
                                &nbsp;&nbsp;
                                {!isEdit &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Save
                                    </button>
                                }
                                &nbsp;&nbsp;

                                {isEdit && !props.selectedArchived &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Update
                                    </button>
                                }
                            </div>
                        </Col>
                    </Row>
                </Form>
                {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
                    <Toaster status="error" msg={errorMsg.message} />
                }
            </div>
            {errorMsg !== null && errorMsg !== '' && errorMsg.status && errorMsg.status === 500 &&
                <div className="p-0 justify-content-center d-flex">
                    <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')} />
                </div>
            }
        </React.Fragment>
    )
}


NewCreatives.propTypes = {}

export default NewCreatives