import React, { useEffect, useState, useRef } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardImg,
  CardTitle,
  CardText,
  CardBody,
  Col,
  Box,
  Input,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Label
} from "reactstrap"
import { Dropdown, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import {
  getCommonCategories as onGetCategories,
} from "store/CommonEvents/actions";

import {
  getLanguages as onGetLanguages,
} from "store/Contextual_Settings/WebsiteCategorization/actions";

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import {
  getVideoContents as onGetVideoContent,
} from "store/Media/VideoContent/actions";


//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import SCSS
// import './VideoContent.scss'

import Create_VideoContent from "../../MediaBidsxchange/Video_Content_Library/Create_VideoContent_old";

const PagesStarter = () => {
  const dispatch = useDispatch();
  const [isRight, setIsRight] = useState(false);
  const [languageData, setLanguageData] = useState([])
  const [categoryData, setCategoryData] = useState([])

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 5000,
    extendedTimeOut: 1000
  };
  const { SearchBar } = Search;
  const sizePerPage = 10;
  const pageOptions = {
    sizePerPage: 10,
    custom: true,
  };
  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedlanguage] = useState();
  const [selectedCateory, setSelectedCategory] = useState();
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false)

  const [languageSearch, setLanguageSearch] = useState('')
  const [categorySearch, setCategorySearch] = useState('')

  const languageChange = (e) => {
    setLanguageSearch(e.target.value)
    dispatch(onGetVideoContent({ videoContentLanguage: e.target.value, videoContentCategory: categorySearch }));
  }

  const categoryChange = (e) => {
    setCategorySearch(e.target.value)
    dispatch(onGetVideoContent({ videoContentLanguage: languageSearch, videoContentCategory: e.target.value }));
  }

  const { videoContents } = useSelector(state => ({
    videoContents: state.videoContents.vcons
  }));

  const [filteredVcons, setFilteredVCons] = useState();

  const { videoContentCategoryList } = useSelector(state => ({
    videoContentCategoryList: state.commonEvents.categoryList,
  }));

  const { videoContentLanguageList } = useSelector(state => ({
    videoContentLanguageList: state.websiteCategorizations.languageList,
  }));

  console.log(videoContents, videoContentCategoryList, videoContentLanguageList)

  useEffect(() => {
    if (videoContents !== null) {
      dispatch(onGetVideoContent({ videoContentLanguage: languageSearch, videoContentCategory: categorySearch }));
    }
  }, []);

  // const { errorMsg, successMsg } = useSelector(state => ({
  //   errorMsg: state.videoContents.errorMsg,
  //   successMsg: state.videoContents.successMsg,
  // }));



  const toggle = () => setOpen(!open);
  const toggleRightCanvas = (value) => {
    // selectedData = ''
    dispatch(onGetVideoContent({ videoContentLanguage: languageSearch, videoContentCategory: categorySearch }));
    setIsRight(!isRight);
  };

  const copyThumbnail = (value) => {
    console.log("this is video content alert============", value)
    window.navigator.clipboard.writeText(value);
  }

  const toggleEnableScroll = () => {
    setIsEnableScroll(!isEnableScroll);
  };

  const toggleDrawer = (close, videoContent) => (event) => {
    console.log(event)
    if (event === 'Create') {
      toastr.success('VideoContent Saved Successfully!...')
    }
    // selectedData = videoContent
    dispatch(onGetVideoContent({ videoContentLanguage: languageSearch, videoContentCategory: categorySearch }));
    setIsRight(close);
  };

  useEffect(() => {
    if (videoContentLanguageList !== null) {
      dispatch(onGetLanguages());
    }
  }, []);

  useEffect(() => {
    if (videoContentCategoryList !== null) {
      dispatch(onGetCategories());
    }
  }, []);

  useEffect(() => {
    if (videoContentLanguageList && videoContentLanguageList.length > 0) {
      setLanguageData(objectModal(videoContentLanguageList))
    }
  }, [videoContentLanguageList]);

  useEffect(() => {
    if (videoContentCategoryList && videoContentCategoryList.length > 0) {
      setCategoryData(objectModal(videoContentCategoryList))
    }
  }, [videoContentCategoryList]);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }



  const languageToggle = (event) => {
    setIsLanguageOpen(!isLanguageOpen)
    const language = event.target.innerText
    if (!!language && language !== 'Select Language') {
      setSelectedlanguage(language)
    }
  }

  const categoryToggle = e => {
    setIsCategoryOpen(!isCategoryOpen);
    const category = e.target.innerText
    if (!!category && category !== 'Select Category') {
      setSelectedCategory(category)
    }
  }

  // const applyFilters = () => {
  //   const fVcons = videoContents.filter(v => {
  //       if (!selectedLanguage && !selectedCateory) {
  //         return true
  //       }
  //       return selectedLanguage?.toLowerCase() === videoContentLanguageList?.toLowerCase() || selectedCateory?.toLowerCase() === videoContentCategoryList?.toLowerCase()
  //     })
  //     setFilteredVCons(fVcons)
  // }




  console.log(videoContents)
  // console.log(filteredVcons)

  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
        <div className="page-content">
          <Helmet>
            <title>VideoContent| Bidsxchange</title>
          </Helmet>
          <Container fluid>
            <Breadcrumbs title="Tools" breadcrumbItem="VideoContent" />
            <Row>
              <div className="text-sm-end mt-2">
                <Button
                  color="primary"
                  className="font-10 btn-block btn btn-primary"
                  onClick={toggleRightCanvas}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Add VideoContent
                </Button>
              </div>
            </Row>
            <Card>
              <CardBody aria-hidden="true">
                <Row >
                  <Col sm="2">
                  <Label className="form-label">Language</Label> <br />
                    <div className=" text-sm-end">
                      <Input type="select" className="form-select" value={languageSearch} onChange={languageChange}>
                        <option value=''> All Languages</option>
                        {languageData.map((item) => (
                          <option
                            key={item.value}
                            value={item.value}
                          >
                            {item.label}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </Col>
                  <Col sm="2">
                  <Label className="form-label">Category</Label> <br />
                    <div className=" text-sm-end">
                      <Input type="select" className="form-select" value={categorySearch} onChange={categoryChange}>
                        <option value=''> All Categories</option>
                        {categoryData.map((item) => (
                          <option
                            key={item.value}
                            value={item.value}
                          >
                            {item.label}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="mt-2 p-3">
              <Row className="mb-2">
                <Col sm="4">
                  <div className="search-box ms-2 mb-2 d-inline-block">
                    <div className="position-relative">
                      <SearchBar />
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                </Col>
                <Col sm="4">
                </Col>

              </Row>
              <Row>
                {videoContents !== null &&
                  videoContents.map((item, index) => {
                    return (
                      <Col xl={4} key={index}>
                        <Card>
                          <video type="video/mp4"
                            src={item.videoContentURL} controls >
                          </video>
                          <CardBody>
                            <CardTitle tag="h5">{item.videoContentName}</CardTitle>

                            <h6 style={{ fontWeight: 600 }}>Category :  <span style={{ fontWeight: 400 }}>{item.videoContentCategory} </span> &nbsp;&nbsp; |  &nbsp;&nbsp; Language :  <span style={{ fontWeight: 400 }}>{item.videoContentLanguage}</span> </h6>

                            <Row className=" row mt-3">
                              <Col md={6}>
                                <Button
                                  color="primary"
                                  className="font-18 w-100 btn btn-primary"
                                  onClick={() => copyThumbnail(item.videoContentThumbnailURL)}
                                >
                                  Thumbnail &nbsp;
                                  <i className="mdi mdi-plus-circle-outline me-1" />
                                </Button>
                              </Col >
                              <Col md={6}>
                                <Button
                                  color="primary"
                                  className="font-18 w-100 btn btn-primary"
                                  onClick={() => copyThumbnail(item.videoContentURL)}
                                >
                                  Video &nbsp;
                                  <i className="mdi mdi-plus-circle-outline me-1" />
                                </Button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    )
                  })
                }
              </Row>
            </Card>
          </Container>
        </div>
      {/* </div> */}
      <Offcanvas isOpen={isRight} direction='end'  >
        <OffcanvasHeader toggle={toggleRightCanvas}>
          Create VideoContent
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Create_VideoContent closeCanvas={toggleDrawer(false, '')} />
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  )
}

export default withRouter(PagesStarter)





