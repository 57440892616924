import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ADVERTISERS, ADD_ADVERTISER, UPDATE_ADVERTISER, READ_ADVERTISER, STATUS_ADVERTISER, ARCHIVE_ADVERTISER } from "./actionTypes"

import {
  loadAdvertisers,
  noDataAdvertisers,
  getAdvertisersSuccess,
  getAdvertisersFail,
  addAdvertiserSuccess,
  addAdvertiserFail,
  updateAdvertiserSuccess,
  updateAdvertiserFail,
  readAdvertiserSuccess,
  statusAdvertiserSuccess,
  archiveAdvertiserSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getAdvertisers, postAdvertiser, getAdvertisersType, updateAdvertiser, readAdvertiser, statusChangeAdvertiser, archiveAdvertiser } from "../../../helpers/Backend_helper"

function* fetchAdvertisers({ payload: adv }) {
  try {
    yield put(loadAdvertisers(true))
    var getAdvList
    if (adv && adv.type) {
      getAdvList = yield call(getAdvertisersType, adv)
      if (getAdvList.statusCode === 200) {
        sessionStorage.setItem("DemandServiceDown", JSON.stringify(false));
        getAdvList.response.advertisers.map((item, index) => {
          item.id = index + 1
        })
        if (getAdvList.response.advertisers.length === 0) {
          yield put(noDataAdvertisers(true))
        }
        yield put(getAdvertisersSuccess(getAdvList))
      }
    } else {
      if (adv) {
        console.log(adv)
        getAdvList = yield call(getAdvertisers, adv)
        if (getAdvList.statusCode === 200) {
          sessionStorage.setItem("DemandServiceDown", JSON.stringify(false));
          getAdvList.response.advertisers.map((item, index) => {
            item.id = index + 1
          })
          if (getAdvList.response.advertisers.length === 0) {
            yield put(noDataAdvertisers(true))
          }
          yield put(getAdvertisersSuccess(getAdvList))
        }
      }
      else {
        getAdvList = []
        yield put(getAdvertisersSuccess(getAdvList))
      }
    }

  } catch (error) {
    console.log(error)
    // if(JSON.stringify(error.message) === "Request failed with status code 503"){
    //   debugger
    //   sessionStorage.setItem("DemandServiceDown", true);
    // }
    yield put(getAdvertisersFail(error))
  }
}

function* onReadAdvertiser({ payload: adv }) {
  try {
    const response = yield call(readAdvertiser, adv)
    yield put(readAdvertiserSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(readAdvertiserSuccess(error))
  }
}

function* onAddAdvertiser({ payload: adv }) {
  try {
    const response = yield call(postAdvertiser, adv)
    if (response.statusCode === 200) {
      yield put(addAdvertiserSuccess(response))
    } else {
      yield put(addAdvertiserFail(response))
    }
  } catch (error) {
    console.log(error)
    yield put(addAdvertiserFail(error))
  }
}

function* onUpdateAdvertiser({ payload: adv }) {
  try {
    const response = yield call(updateAdvertiser, adv)
    if (response.statusCode === 200) {
      yield put(updateAdvertiserSuccess(response))
    } else {
      yield put(updateAdvertiserFail(response))
    }
  } catch (error) {
    console.log(error)
    yield put(updateAdvertiserFail(error))
  }
}

function* onStatusAdvertiser({ payload: adv }) {
  try {
    const response = yield call(statusChangeAdvertiser, adv)
    if (response.statusCode === 200) {
      yield put(statusAdvertiserSuccess(response))
    }
  } catch (error) {
    console.log(error)
    yield put(statusAdvertiserSuccess(error))
  }
}

function* onArchiveAdvertiser({ payload: adv }) {
  try {
    const response = yield call(archiveAdvertiser, adv)
    if (response.statusCode === 200) {
      yield put(archiveAdvertiserSuccess(response))
    }
  } catch (error) {
    console.log(error)
    yield put(archiveAdvertiserSuccess(error))
  }
}

function* advertisersSaga() {
  yield takeEvery(GET_ADVERTISERS, fetchAdvertisers)
  yield takeEvery(ADD_ADVERTISER, onAddAdvertiser)
  yield takeEvery(UPDATE_ADVERTISER, onUpdateAdvertiser)
  yield takeEvery(READ_ADVERTISER, onReadAdvertiser)
  yield takeEvery(STATUS_ADVERTISER, onStatusAdvertiser)
  yield takeEvery(ARCHIVE_ADVERTISER, onArchiveAdvertiser)
}

export default advertisersSaga;
