/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";


var list = []
var urlsList = []

const NetworkUrls = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [tags, setTags] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [url, setUrl] = useState({
    urlGroup: '',
    urlList: '',
  });
  console.log(props)

  const [controls, setControls] = useState(true)

  const handleCheck = (event) => {
    setControls(!controls);
  };

    useEffect(() => {
      if(props.selectedUrl  !== '' ){
        list = []
        urlsList = []
        console.log(props.selectedUrl)
        setIsEdit(true)
        setUrl(props.selectedUrl)
        if(props.selectedUrl.urlList && props.selectedUrl.urlList.length > 0 ){
          setControls(false);
        }
        urlsList = props.selectedUrl.urlList.map((item, index) =>
        Object.assign({}, { text: item, id: item })
    )
    console.log(urlsList)
    props.selectedUrl.urlList.map((item, index) => {
        list.push(item)
        return list
    })
    setTags(urlsList)
      } else {
        list = []
        urlsList = []
      }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUrl(url => ({ ...url, [name]: value }));
    props.selectedItems({ [name]: value })
  }

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
    list.splice(i, 1)
    setUrl(url => ({ ...url, urlList: list }));
    props.selectedItems({ urlList: list })
  };

  const handleAddition = tag => {
    list.push(tag.id)
    setTags([...tags, tag]);
    setUrl(url => ({ ...url, urlList: list }));
    props.selectedItems({ urlList: list })
  };

  return (
    <React.Fragment>
      <div className="col-12">
          <Row form style={{ padding: '3%' }}>
            <Col xs={12}>
                  <div className="mb-3">
                    <Label className="form-label">URL Group Name</Label>
                    <Input
                      type="text" name="urlGroup" value={url.urlGroup} onChange={handleChange} className={'form-control'}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">URL List</Label>

                    {/* <ReactTags
                      tags={tags}
                      handleDelete={handleDelete}
                      handleAddition={handleAddition}
                      inputFieldPosition="inline"
                      style={{ width: '100%' }}
                      autocomplete
                      placeholder="Press enter to add new URL"
                    /> */}
                  </div>
            </Col>
          </Row>
      </div>
    </React.Fragment>
  );
};


NetworkUrls.propTypes = {}

export default NetworkUrls