/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react"
import {
  Card, CardBody, Col, Form, Input, Label, Row, Spinner, Button, Progress, Nav,
  NavItem,
  NavLink, TabContent,
  TabPane,
  Modal, ModalBody, Dropdown, DropdownMenu, DropdownItem, DropdownToggle
} from "reactstrap"
import axios from "axios"
import classnames from "classnames";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';





// var path = require('path');

var videoContentFileName = ""


const Link_Upload = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));

  const [userFile, setUserFile] = useState(Math.random().toString(36))
  const [CdnLink, setCdnLink] = useState('')
  const [loader, setLoader] = useState(false)
  const [loaderYoutube, setLoaderYoutube] = useState(false)

  const [spinner, setSpinner] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0);


  const [inputValue, setInputValue] = useState('');
  const [videoId, setVideoId] = useState('');

  const [showAlert, setShowAlert] = useState(false);

  const [btnprimary1, setBtnprimary1] = useState(false)
  const [resolution, setResolution] = useState('640:360')

  const [btnprimary2, setBtnprimary2] = useState(false)
  const [quality, setQuality] = useState('ultra')

  const [startTimeSec, setStartTimeSec] = useState('');
  const [startTimeMin, setStartTimeMin] = useState('');

  const [durationSec, setDurationSec] = useState('');
  const [durationMin, setDurationMin] = useState('');


  const [trimEnabled, setTrimEnabled] = useState(false);

  const [currentState, setCurrentState] = useState(true)

  const handleStartTimeChangeSec = (event) => {
    setStartTimeSec(event.target.value);
  };
  const handleStartTimeChangeMin = (event) => {
    setStartTimeMin(event.target.value);
  };

  const handleDurationChangeSec = (event) => {
    setDurationSec(event.target.value);
  };

  const handleDurationChangeMin = (event) => {
    setDurationMin(event.target.value);
  };

  const handleTrimChange = e => {
    setTrimEnabled(!trimEnabled);
    if (!e.target.checked) {
      setStartTimeMin('')
      setStartTimeSec('')
      setDurationMin('')
      setDurationSec('')
    }
  };



  console.log(props.type)

  const [videoContent, setVideoContent] = useState({
    videoContentPath: [],
    videoContentOtherDuration: '',
    videoContentCompressedFilePath: ''

  });


  const [customActiveTab, setcustomActiveTab] = useState("1");


  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };


  const fileChange = (file) => {

    setLoader(true);
    console.log(file.target.files[0])
    props.onVideoUpload(URL.createObjectURL(file.target.files[0]));
    props.onVideoName(file.target.files[0].name)

    const fileName = file.target.files[0].name.replace(" ", "_");
    const fileType = file.target.files[0].type;
    console.log("file=====================", file);
    videoContentFileName = fileName;
    console.log(videoContentFileName);
    const formData = new FormData();
    let newFile = file.target.files[0];
    formData.append("data", newFile);

    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          var media = new Audio(reader.result);
          media.onloadedmetadata = function () {
            media.duration; // this would give duration of the video/audio file
            setVideoContent((videoContent) => ({
              ...videoContent,
              videoContentOtherDuration: media.duration,
            }));
            props.saveData({ videoContentOtherDuration: media.duration });
          };
          resolve(reader.result);
        };
        reader.onerror = (error) => reject(error);
        console.log(reader.result);
      });

    var newBaseData = toBase64(newFile);
    const newBaseContent = {};
    (async () => {
      newBaseContent.data = await newBaseData;
      newBaseContent.fileName = fileName;
      newBaseContent.type = fileType;
      newBaseContent.userID = userInfo.userID;
      newBaseContent.userRole = userInfo.userRole;
      newBaseContent.companyID = userInfo.companyID;
      console.log(newBaseContent);
      const body = {
        url: "http://10.0.3.69:3022/upload/uploadFile",
        requestBody: newBaseContent,
      };
      console.log(body)
      axios
        .post("/v1.0/connectOP", body, {
          headers: headers,
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgress(progress);
          },
        })
        .then((res) => {
          console.log("Video Upload ==>", res);
          setLoader(false);
          setVideoContent({
            videoContentPath: `/home/ubuntu/videoContentData/${videoContentFileName}`,
          });
          props.saveData({
            videoContentPath: `/home/ubuntu/videoContentData/${videoContentFileName}`,
          });

          axios.post("/convertTohls", {
            videoFilePath: `/home/ubuntu/videoContentData/${videoContentFileName}`,
            videoFileName: videoContentFileName
          })
            .then((response) => {
              console.log(response)
              const convertedFiles = response.data.videoOutputFiles;
              const compressedFilePath = response.data.compressedVideoFilePath;
              console.log(convertedFiles)
              console.log(compressedFilePath)
              setVideoContent({
                videoContentOtherTsPath: convertedFiles,
                videoContentCompressedFilePath: compressedFilePath
              });

              props.saveData({
                videoContentOtherTsPath: convertedFiles,
                videoContentCompressedFilePath: compressedFilePath
              });


              props.changeActiveState(2);

            })
            .catch((zipError) => {
              console.log(zipError)
            })


        })
        .catch((err) => {
          setLoader(false);
          console.log("e", err.code);
          toastr.error(err);
          setUserFile(Math.random().toString(36))

        });
    })();
  };





  const videoDurationValidator = async () => {
    // setSpinner(true);
    let fileName;
    try {
      const response = await axios.post("/getbasicinfo", {
        url: inputValue
      })
      console.log(response)
      if (parseInt(response.data.data.vInfo.duration) <= 1200) {
        console.log("video is less than 20 mins")
        handleYoutubeAndDirectLinkDownloadNew()
      } else {
        console.log("vieo is greater than 20 mins")
        setShowAlert(true)
      }
    } catch (error) {
      setSpinner(false);
      console.error(error);
    }
  }




  const handleYoutubeAndDirectLinkDownloadNew1 = async () => {
    setShowAlert(false)
    setSpinner(true)
    var videoBlob;
    var videoName;
    var videoType;
    var base64file;
    var videoContentFileName = ""
    var duration = ""

    try {
      if (inputValue.includes('youtube')) {


        const response = await axios.post("/downloadvideo", {
          url: inputValue,
        });

        console.log('youtube video', response)


        const cHeader = response.headers['content-disposition']
        const fileMatch = cHeader.match(/vInfo="(.+)"/)
        if (fileMatch && fileMatch.length > 1) {
          const videoInfo = fileMatch[1]
          const videoData = JSON.parse(videoInfo)
          videoName = videoData[0].title.replace(/\s+/g, "")
          videoType = 'video/mp4'
          videoContentFileName = `${videoName}.mp4`
        }

        setSpinner(false)

      } else if (inputValue.includes(".mp4")) {
        console.log('direct link')
        const response = await axios.get(inputValue, { responseType: 'blob' });
        videoBlob = response.data;
        videoName = inputValue.match(/(?<=\/)(\w|-)+(?:\.\w+)?(?=$|\?)/)[0];
        videoType = videoBlob.type;
        videoContentFileName = `${videoName}.mp4`
      } else {
        toastr.error("Invalid Link");
      }

      const videoFile = new File([videoBlob], `video_${videoName}`, { type: 'video/mp4' })
      const videoUrl = URL.createObjectURL(videoFile)
      // console.log(videoUrl)

      setLoaderYoutube(true);
      props.onVideoUpload(videoUrl);
      props.onVideoName(videoName)

      console.log(videoBlob)

      const formData = new FormData()
      let newFile = videoFile
      formData.append("data", videoFile)


      const toBase64 = (videoFile) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(videoFile);
          reader.onload = () => {
            var media = new Audio(reader.result);
            media.onloadedmetadata = function () {
              media.duration; // this would give duration of the video/audio file
              setVideoContent((videoContent) => ({
                ...videoContent,
                videoContentOtherDuration: media.duration,
              }));
              props.saveData({ videoContentOtherDuration: media.duration });
            };
            resolve(reader.result);
          };
          reader.onerror = (error) => reject(error);
          console.log(reader.result);
        });


      var newBaseData = toBase64(newFile);
      const newBaseContent = {};

      (async () => {
        newBaseContent.data = await newBaseData;
        console.log(newBaseContent.data)
        newBaseContent.fileName = `${videoName}.mp4`;
        newBaseContent.type = videoType;
        newBaseContent.userID = userInfo.userID;
        newBaseContent.userRole = userInfo.userRole;
        newBaseContent.companyID = userInfo.companyID;
        console.log(newBaseContent);
        const body = {
          url: "http://10.0.3.69:3022/upload/uploadFile",
          requestBody: newBaseContent,
        };
        axios.post("/v1.0/connectOP", body, {
          headers: headers,
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgress(progress);
          },
        })
          .then((res) => {
            console.log("Video Upload ==>", res);
            setLoaderYoutube(false);
            setVideoContent({
              videoContentPath: `/home/ubuntu/videoContentData/${videoContentFileName}`,
            });
            props.saveData({
              videoContentPath: `/home/ubuntu/videoContentData/${videoContentFileName}`,
            });

            axios.post("/convertTohls", {
              videoFilePath: `/home/ubuntu/videoContentData/${videoContentFileName}`,
              videoFileName: videoContentFileName
            })
              .then((response) => {
                console.log(response)
                const convertedFiles = response.data.videoOutputFiles;
                const compressedFilePath = response.data.compressedVideoFilePath;
                console.log(convertedFiles)
                console.log(compressedFilePath)
                setVideoContent({
                  videoContentOtherTsPath: convertedFiles,
                  videoContentCompressedFilePath: compressedFilePath
                });

                props.saveData({
                  videoContentOtherTsPath: convertedFiles,
                  videoContentCompressedFilePath: compressedFilePath
                });


                props.changeActiveState(2);

              })
              .catch((zipError) => {
                console.log(zipError)
              })
          })
          .catch((err) => {
            setLoaderYoutube(false);
            console.log("e", err);
          });
      })();

    } catch (err) {
      console.error(err)
      setLoader(false);
      setSpinner(false)
      toastr.error(err);
    }
  }
  //code for download and upload video from youtube getting base64 file
  const handleYoutubeAndDirectLinkDownloadNew = async () => {
    setShowAlert(false)
    setSpinner(true)
    var videoBlob;
    var videoName;
    var videoType;
    var base64file;

    try {
      if (inputValue.includes('youtube')) {


        const response = await axios.post("/downloadvideo", {
          url: inputValue,
        }, {
          responseType: 'blob'
        });

        console.log('youtube video', response)

        videoBlob = new Blob([response.data])
        // base64file = response.data
        console.log(videoBlob)
        // props.onVideoUpload(URL.createObjectURL(videoBlob));

        const cHeader = response.headers['content-disposition']
        const fileMatch = cHeader.match(/vInfo="(.+)"/)
        if (fileMatch && fileMatch.length > 1) {
          const videoInfo = fileMatch[1]
          const videoData = JSON.parse(videoInfo)
          videoName = videoData[0].title.replace(/\s+/g, "")
          videoType = 'video/mp4'
          videoContentFileName = `${videoName}.mp4`
        }

        setSpinner(false)

      } else if (inputValue.includes(".mp4")) {
        console.log('direct link')
        const response = await axios.get(inputValue, { responseType: 'blob' });
        videoBlob = response.data;
        videoName = inputValue.match(/(?<=\/)(\w|-)+(?:\.\w+)?(?=$|\?)/)[0];
        videoType = videoBlob.type;
        videoContentFileName = `${videoName}.mp4`
      } else {
        toastr.error("Invalid Link");
        setSpinner(false)

      }

      const videoFile = new File([videoBlob], `video_${videoName}`, { type: 'video/mp4' })
      const videoUrl = URL.createObjectURL(videoFile)
      // console.log(videoUrl)

      setLoaderYoutube(true);
      props.onVideoUpload(videoUrl);


      const fileNameRaw = videoName

      const fileNameWithoutSpaces = fileNameRaw.replace(/ /g, '_');
  
      const fileNameWithoutExtension = fileNameWithoutSpaces.replace(/\.mp4$/, '');
  
      // Remove special characters except underscores
      const title = fileNameWithoutExtension.replace(/[^\w\s]/g, '');


      props.onVideoName(title)

      console.log(videoBlob)

      const formData = new FormData()
      let newFile = videoFile
      formData.append("data", videoFile)


      const toBase64 = (videoFile) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(videoFile);
          reader.onload = () => {
            var media = new Audio(reader.result);
            media.onloadedmetadata = function () {
              media.duration; // this would give duration of the video/audio file
              setVideoContent((videoContent) => ({
                ...videoContent,
                videoContentOtherDuration: media.duration,
              }));
              props.saveData({ videoContentOtherDuration: media.duration });
            };
            resolve(reader.result);
          };
          reader.onerror = (error) => reject(error);
          console.log(reader.result);
        });

      // const getVideoDuration = (videoFile) =>
      //   new Promise((resolve, reject) => {
      //     const videoElement = document.createElement('video');
      //     videoElement.preload = 'metadata';
      //     videoElement.src = URL.createObjectURL(videoFile);
      //     videoElement.onloadedmetadata = () => {
      //       const duration = videoElement.duration;
      //       resolve(duration);
      //     };
      //     videoElement.onerror = (error) => reject(error);
      //   });

      // const duration = await getVideoDuration(videoFile);
      // console.log(duration);
      // setVideoContent((videoContent) => ({
      //   ...videoContent,
      //   videoContentOtherDuration: duration,
      // }));
      // props.saveData({ videoContentOtherDuration: duration });



      var newBaseData = toBase64(newFile);
      const newBaseContent = {};

      (async () => {
        newBaseContent.data = await newBaseData;
        console.log(newBaseContent.data)
        newBaseContent.fileName = `${videoName}.mp4`;
        newBaseContent.type = videoType;
        newBaseContent.userID = userInfo.userID;
        newBaseContent.userRole = userInfo.userRole;
        newBaseContent.companyID = userInfo.companyID;
        console.log(newBaseContent);
        const body = {
          url: "http://10.0.3.69:3022/upload/uploadFile",
          requestBody: newBaseContent,
        };
        axios.post("/v1.0/connectOP", body, {
          headers: headers,
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgress(progress);
          },
        })
          .then((res) => {
            console.log("Video Upload ==>", res);
            setLoaderYoutube(false);
            setVideoContent({
              videoContentPath: `/home/ubuntu/videoContentData/${videoContentFileName}`,
            });
            props.saveData({
              videoContentPath: `/home/ubuntu/videoContentData/${videoContentFileName}`,
            });

            axios.post("/convertTohls", {
              videoFilePath: `/home/ubuntu/videoContentData/${videoContentFileName}`,
              videoFileName: videoContentFileName
            })
              .then((response) => {
                console.log(response)
                const convertedFiles = response.data.videoOutputFiles;
                const compressedFilePath = response.data.compressedVideoFilePath;
                console.log(convertedFiles)
                console.log(compressedFilePath)
                setVideoContent({
                  videoContentOtherTsPath: convertedFiles,
                  videoContentCompressedFilePath: compressedFilePath
                });

                props.saveData({
                  videoContentOtherTsPath: convertedFiles,
                  videoContentCompressedFilePath: compressedFilePath
                });

                setSpinner(false)

                props.changeActiveState(2);

              })
              .catch((zipError) => {
                console.log(zipError)
              })
          })
          .catch((err) => {
            setLoaderYoutube(false);
            console.log("e", err);
          });
      })();

    } catch (err) {
      console.error(err)
      setLoader(false);
      setSpinner(false)
      toastr.error(err);
    }
  }





  const fetchCdnLinkInfo1 = async () => {
    const videoUrl = CdnLink;
    const videoName = CdnLink.match(/(?<=\/)(\w|-)+(?:\.\w+)?(?=$|\?)/)[0].replace(/\s/g, '');
    console.log(videoName)
    props.onVideoUpload(CdnLink)
    props.onVideoName(videoName)
    videoContentFileName = `${videoName}.mp4`;

    try {
      const videoElement = document.createElement('video');
      videoElement.src = videoUrl;
      videoElement.addEventListener('loadedmetadata', () => {
        const durationInSeconds = videoElement.duration;
        console.log(durationInSeconds);
        setVideoContent((videoContent) => ({
          ...videoContent,
          videoContentOtherDuration: durationInSeconds,
        }));
        props.saveData({ videoContentOtherDuration: durationInSeconds });
      });
      // document.body.appendChild(videoElement);
    } catch (err) {
      console.log(err)
    }
    setVideoContent({
      videoContentPath: CdnLink,
    });
    props.saveData({
      videoContentPath: CdnLink,
    });
    props.changeActiveState(2);
    console.log(videoContent);
  }


  return (
    <>
      <Col md="12">
        <div style={{ padding: '1% 3%', height: '64vh', overflow: 'auto' }} >
          <Row className="mt-5">
            <Col sm="12">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <Label className="text-left" htmlFor="youtube-link">Enter link:</Label>
                <Input className="w-75" type="text" id="youtube-link" name="youtube-link" placeholder="https://www.youtube.com/watch?v=..." value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
                <div className="d-flex justify-content-center align-items-center">
                  <div>
                    <Button className="bg-primary m-1 mt-2" onClick={() => handleYoutubeAndDirectLinkDownloadNew()}>Upload</Button>
                  </div>
                </div>

              </div>
              {loaderYoutube &&
                <Progress
                  className="mt-2"
                  animated
                  color="success"
                  style={{
                    height: '15px'
                  }}
                  striped
                  value={uploadProgress}
                >{uploadProgress}%</Progress>
              }
              {
                spinner &&
                <div className="d-flex justify-content-center align-items-center mt-1">

                  <Button
                    color="primary"
                    disabled
                  >
                    <Spinner size="sm">
                      Loading...
                    </Spinner>
                    <span>
                      {' '}Downloading and Compressing Video Please Wait...
                    </span>
                  </Button>
                </div>

              }
            </Col>
          </Row>
        </div>
      </Col >
      <Modal size="sm" isOpen={showAlert} toggle={() => { setShowAlert(!showAlert) }} backdrop={'static'} centered={true}
        id="staticBackdrop"
      >
        <div className="modal-content">
          <ModalBody className="px-2 py-4 text-center ">
            <i className="bx bx-error-circle text-warning fon-size-32" style={{ fontSize: '2rem' }}></i> &nbsp;
            <h5 className="text-muted font-size-16 mb-4">   video is more than 20 minutes in length would you like to download?</h5>
            <div className="hstack gap-2 justify-content-center mb-0">
              <button type="button" className="btn btn-light" onClick={() => { setShowAlert(!showAlert) }}>No</button>
              <button type="button" className="btn btn-primary" onClick={handleYoutubeAndDirectLinkDownloadNew}>Yes</button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </>

  )
}

Link_Upload.propTypes = {}

export default Link_Upload