/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from "axios";
import Select from "react-select";
import SweetAlert from 'react-bootstrap-sweetalert';
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Spinner,
    Label, Form, FormFeedback, Modal, ModalBody
} from "reactstrap"

import jsPDF from "jspdf";
import "jspdf-autotable";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import { MultiSelect } from "react-multi-select-component";

import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import moment from "moment";

import excelFile from 'assets/files/AdsTxtFile.xlsx'
import PDFFile from 'assets/files/AdsTxtEntries.pdf'

import {
    getPAgencyRevenueDisplayReports as onGetctrDisplayReports,
} from "store/Proxy/ProxyAdUnits/actions";

import {
    getAgencyScheduleReports as onGetAgencyScheduleReports,
    addNewAgencyScheduleReport as onADDAgencyScheduleReport,
    updateAgencyScheduleReport as onUpdateAgencyScheduleReport,
    readAgencyScheduleReport as onReadAgencyScheduleReport,
    addAgencyScheduleReportSuccess,
    addAgencyScheduleReportFail,
    updateAgencyScheduleReportSuccess,
    updateAgencyScheduleReportFail,
} from "store/AgencyConsole/ScheduleReport/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 2 : 1

var fromDate = moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days')
var toDate = moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')
var loader = false

var globalFilterData = {}

const Agency_ctr_Report = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const dispatch = useDispatch();
    const [reportOpen, setReportOpen] = useState(false);
    const [btnprimary1, setBtnprimary1] = useState(false)
    const [totalImpressions, setTotalImpressions] = useState(0)
    const [totalClicks, setTotalClicks] = useState(0)
    const [avgCTR, setAvgCTR] = useState(0)
    const [TotalConversions, setTotalConversions] = useState(0)
    const [AvgconversionRatio, setAvgconversionRatio] = useState(0)
    const [totalCost, setTotalCost] = useState(0)
    const [totalCPM, setTotalCPM] = useState(0)
    const [TotalvideoCompletions, setTotalvideoCompletions] = useState(0)
    const [AvgVideoConversionRatio, setAvgVideoConversionRatio] = useState(0)

    const adminDetails = JSON.parse(localStorage.getItem('AdminDetails'));

    var currentTimeStamp = Date.now();

    const [AccountManager, setAccountManager] = useState([]);

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const [selectAll, setSelectAll] = useState(false)
    const [selectMetrice, setSelectMetrice] = useState(false)
    const [range, setRange] = useState('Day')
    // const [loader, setLoader] = useState(false)
    const [selectAllMTC, setSelectAllMTC] = useState(false)

    const [agency, setagency] = useState([]);
    const [brand, setbrand] = useState([]);
    const [campaignName, setcampaignName] = useState([]);
    const [placementName, setplacementName] = useState([]);
    const [device, setDomian] = useState([]);
    const [adopsManager, setadopsManager] = useState([]);
    const [adUnitSize, setadUnitSize] = useState([]);
    const [inventoryType, setinventoryType] = useState([]);
    const [publisherManager, setpublisherManager] = useState([]);
    const [Device, setDevice] = useState([]);

    const [selectedagency, setSelectedagency] = useState([]);
    const [selectedbrand, setSelectedbrand] = useState([]);
    const [selectedcampaignName, setSelectedcampaignName] = useState([]);
    const [selectedplacementName, setSelectedplacementName] = useState([]);
    const [selecteddevice, setSelecteddevice] = useState([]);
    const [selectedadopsManager, setSelectedadopsManager] = useState([]);
    const [selectedadUnitSize, setSelectedadUnitSize] = useState([]);
    const [selectedinventoryType, setSelectedinventoryType] = useState([]);
    const [selectedpublisherManager, setSelectedpublisherManager] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState([]);


    const [selectAllDM, setSelectAllDM] = useState({
        brand: false,
        campaignName: false,
        placementName: false,
        device: false,
        accountManager: false,
        adopsManager: false,
        adUnitSize: false,
        inventoryType: false,
        publisherManager: false,
    });

    const [filters, setFilters] = useState({
        brand: [],
        campaignName: [],
        placementName: [],
        device: [],
        adopsManager: [],
        accountManager: [],
        adUnitSize: [],
        inventoryType: [],
        publisherManager: [],
    });

    const [filterData, setFilterData] = useState({
        brand: [],
        campaignName: [],
        placementName: [],
        device: [],
        adopsManager: [],
        accountManager: [],
        adUnitSize: [],
        inventoryType: [],
        publisherManager: [],
    })

    const [selectedMTC, setSelectedMTC] = useState({
        impressions: false,
        clicks: false,
        ctr: false,
        conversions: false,
        conversionRatio: false,
        cost: false,
        cpm: false,
        videoCompletions: false,
        videoCompletionsRate: false,
    });

    const [finalData, setFinalData] = useState({
        dateRange: 'yesterday',
        range: 'Cumulative',
        frequency: 'daily',
        scheduleName: '',
        emails: [],

    });

    const [emailData, setEmailData] = useState('')
    const [submitted, setSubmitted] = useState(false);
    const [scheduleReport, setAgencyScheduleReport] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const { loading } = useSelector(state => ({
        loading: state.proxyAdUnit.loading,
    }));

    console.log(loading)


    const handleSelectbrand = publisherManager => {
        setSelectedbrand(publisherManager)
        let listData = []
        publisherManager ? (listData.push(publisherManager.value), setFilters(filters => ({ ...filters, brand: listData })), changeData('brand', listData)) : (setFilters(filters => ({ ...filters, brand: [] }), resetSelectedData('brand')))

    }

    const handleSelectcampaignName = partner => {
        setSelectedcampaignName(partner)
        let listData = []
        partner ? (listData.push(partner.value), setFilters(filters => ({ ...filters, campaignName: listData })), changeData('campaignName', listData)) : (setFilters(filters => ({ ...filters, campaignName: [] }), resetSelectedData('campaignName')))

    }

    const handleSelectplacementName = publisher => {
        setSelectedplacementName(publisher)
        let listData = []
        publisher ? (listData.push(publisher.value), setFilters(filters => ({ ...filters, placementName: listData })), changeData('placementName', listData)) : (setFilters(filters => ({ ...filters, placementName: [] }), resetSelectedData('placementName')))

    }

    const handleSelectDomian = domian => {
        setSelecteddevice(domian)
        let listData = []
        domian ? (listData.push(domian.value), setFilters(filters => ({ ...filters, device: listData })), changeData('device', listData)) : (setFilters(filters => ({ ...filters, device: [] }), resetSelectedData('device')))

    }

    const handleSelectadopsManager = adUnitName => {
        setSelectedadopsManager(adUnitName)
        let listData = []
        adUnitName ? (listData.push(adUnitName.value), setFilters(filters => ({ ...filters, adopsManager: listData })), changeData('adopsManager', listData)) : (setFilters(filters => ({ ...filters, adopsManager: [] }), resetSelectedData('adopsManager')))

    }


    const handleSelectadUnitSize = adUnitSize => {
        setSelectedadUnitSize(adUnitSize)
        let listData = []
        adUnitSize ? (listData.push(adUnitSize.value), setFilters(filters => ({ ...filters, adUnitSize: listData })), changeData('adUnitSize', listData)) : (setFilters(filters => ({ ...filters, adUnitSize: [] }), resetSelectedData('adUnitSize')))

    }

    const handleSelectinventoryType = inventoryType => {
        setSelectedinventoryType(inventoryType)
        let listData = []
        inventoryType ? (listData.push(inventoryType.value), setFilters(filters => ({ ...filters, inventoryType: listData })), changeData('inventoryType', listData)) : (setFilters(filters => ({ ...filters, inventoryType: [] }), resetSelectedData('adUnitSize')))

    }

    const handleSelectpublisherManager = dealType => {
        setSelectedpublisherManager(dealType)
        let listData = []
        dealType ? (listData.push(dealType.value), setFilters(filters => ({ ...filters, publisherManager: listData }))) : setFilters(filters => ({ ...filters, publisherManager: [] }));
    }

    const changeFilter = (e) => {
        const { name, value } = e.target;
        setFilters(filters => ({ ...filters, [name]: value }));
        if (name === 'Domain') {
            setFilters(filters => ({
                ...filters, AdUnitName: '',
                AdUnitSize: '',
                InventoryType: '',
                DealType: '',
                Device: '',
            }));
        } else if (name === 'AdUnitName') {
            setFilters(filters => ({
                ...filters,
                AdUnitSize: '',
                InventoryType: '',
                DealType: '',
                Device: '',
            }));
        } else if (name === 'Device') {
            setFilters(filters => ({
                ...filters,
                AdUnitSize: '',
                DealType: '',
                InventoryType: '',
            }));
        }
        else if (name === 'AdUnitSize') {
            setFilters(filters => ({
                ...filters,
                InventoryType: '',
                DealType: '',
            }));
        } else if (name === 'DealType') {
            setFilters(filters => ({
                ...filters,
                InventoryType: '',
            }));
        }
        changeData(e)
    }

    const changeMTCSelect = (e) => {
        setSelectAllMTC(e.target.checked)
        setSelectedMTC({
            impressions: e.target.checked,
            clicks: e.target.checked,
            ctr: e.target.checked,
            conversions: e.target.checked,
            conversionRatio: e.target.checked,
            cost: e.target.checked,
            cpm: e.target.checked,
            videoCompletions: e.target.checked,
            videoCompletionsRate: e.target.checked,
        })
    }

    const changeSelectionMTC = (e) => {
        const { name, value } = e.target;
        setSelectedMTC(selectedMTC => ({ ...selectedMTC, [name]: e.target.checked }));
        let newMTC = { ...selectedMTC, [name]: e.target.checked }
        let MtcArr = Object.values(newMTC)
        if (!MtcArr.includes(false)) {
            setSelectAllMTC(true)
        } else {
            setSelectAllMTC(false)
        }
    }

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            Partner: userInfo.name,
            name: userInfo.name
        }
    }

    useEffect(() => {
        globalFilterData = {}
        fromDate = moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days')
        toDate = moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')

        body.url = "http://127.0.0.1:4011/Agency/Filter"
        axios.post("/v1.0/connectPartner", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data) {
                    objectModalData(res.data)
                    globalFilterData = res.data
                }
            })
            .catch((err) => {
                console.log("e", err)
            });

    }, []);

    const changeData = (name, data) => {
        resetSelectedData(name)
        body.url = "http://127.0.0.1:4011/v1.0/Query/Agency/DisplayGetSpecificFilter"
        body.requestBody = {
            "brand": name === 'brand' ? data : [],
            "campaignName": name === 'campaignName' ? data : [],
            "placementName": name === 'placementName' ? data : [],
            "device": name === 'device' ? data : [],
            adopsManager: name === 'adopsManager' ? data : [],
            accountManager: name === 'accountManager' ? data : [],
            adUnitSize: name === 'adUnitSize' ? data : [],
            inventoryType: name === 'inventoryType' ? data : [],
            publisherManager: name === 'publisherManager' ? data : [],
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            Partner: userInfo.name,
            name: userInfo.name
        }
        axios.post("/v1.0/connectPartner", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data) {
                    objectModalData(res.data)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    };

    const resetSelectedData = (name) => {
        if (name === 'brand') {
            setSelectedcampaignName(null)
            setSelectedplacementName(null)
            setSelecteddevice(null)
            setSelectedadopsManager(null)
            setSelectedadUnitSize(null)
            setSelectedinventoryType(null)
            setSelectedpublisherManager(null)
            setSelecteddevice(null)
            setFilters(filters => ({
                ...filters,
                campaignName: [],
                placementName: [],
                device: [],
                adopsManager: [],
                adUnitSize: [],
                inventoryType: [],
                publisherManager: [],
            }));
        }
        else if (name === 'campaignName') {
            setSelectedplacementName(null)
            setSelecteddevice(null)
            setSelectedadopsManager(null)
            setSelectedadUnitSize(null)
            setSelectedinventoryType(null)
            setSelectedpublisherManager(null)
            setSelecteddevice(null)
            setFilters(filters => ({
                ...filters,
                placementName: [],
                device: [],
                adopsManager: [],
                adUnitSize: [],
                inventoryType: [],
                publisherManager: [],
            }));
        }
        else if (name === 'placementName') {
            setSelecteddevice(null)
            setSelectedadopsManager(null)
            setSelectedadUnitSize(null)
            setSelectedinventoryType(null)
            setSelectedpublisherManager(null)
            setSelecteddevice(null)
            setFilters(filters => ({
                ...filters,
                device: [],
                adopsManager: [],
                adUnitSize: [],
                inventoryType: [],
                publisherManager: [],
            }));
        }
        else if (name === 'adUnitSize') {
            setSelecteddevice(null)
            setSelectedadopsManager(null)
            setSelectedinventoryType(null)
            setSelectedpublisherManager(null)
            setSelecteddevice(null)
            setFilters(filters => ({
                ...filters,
                device: [],
                adopsManager: [],
                inventoryType: [],
                publisherManager: [],
            }));
        }
        else if (name === 'device') {
            setSelectedadopsManager(null)
            setSelectedinventoryType(null)
            setSelectedpublisherManager(null)
            setFilters(filters => ({
                ...filters,
                adopsManager: [],
                inventoryType: [],
                publisherManager: [],
            }));
        }
        else if (name === 'inventoryType') {
            setSelectedadopsManager(null)
            setSelectedpublisherManager(null)
            setFilters(filters => ({
                ...filters,
                adopsManager: [],
                publisherManager: [],
            }));
        }

        else if (name === 'adopsManager') {
            setSelectedpublisherManager(null)
            setFilters(filters => ({
                ...filters,
                publisherManager: [],
            }));
        }


        else {
        }
    }

    const objectModalData = (response) => {
        if (response.brand) {
            setbrand(objectModal(response.brand))
        }
        if (response.campaignName) {
            setcampaignName(objectModal(response.campaignName))
        }
        if (response.placementName) {
            setplacementName(objectModal(response.placementName))
        }
        if (response.device) {
            setDomian(objectModal(response.device))
        }
        if (response.adopsManager) {
            setadopsManager(objectModal(response.adopsManager))
        }
        if (response.accountManager) {
            setaccountManager(objectModal(response.accountManager))
        }
        if (response.adUnitSize) {
            setadUnitSize(objectModal(response.adUnitSize))
        }
        if (response.inventoryType) {
            setinventoryType(objectModal(response.inventoryType))
        }
        if (response.publisherManager) {
            setpublisherManager(objectModal(response.publisherManager))
        }
        if (response.deviceType) {
            setDevice(objectModal(response.deviceType))
        }
    }

    const objectModal = (response) => {
        console.log
        var dataList = []
        response.map((item, index) => {
            let obj = {}
            obj.value = item
            obj.label = item
            dataList.push(obj)
        })
        return dataList
    }

    const changeDMSelect = (e) => {
        setSelectAll(e.target.checked)
        setSelectAllDM({
            brand: e.target.checked,
            campaignName: e.target.checked,
            placementName: e.target.checked,
            device: e.target.checked,
            adopsManager: e.target.checked,
            accountManager: e.target.checked,
            adUnitSize: e.target.checked,
            inventoryType: e.target.checked,
            publisherManager: e.target.checked,
        })
    }

    const changeSelection = (e) => {
        setSelectAll(false)
        const { name, value } = e.target;
        setSelectAllDM(selectAllDM => ({ ...selectAllDM, [name]: e.target.checked }));
    }

    const changeRange = (e) => {
        setRange(e.target.value)
    }

    const { errorMsg, successMsg } = useSelector(state => ({
        errorMsg: state.proxyAdUnit.errorMsg,
        successMsg: state.proxyAdUnit.successMsg,
    }));

    console.log(errorMsg, successMsg)

    const { revenueDisplayReports } = useSelector(state => ({
        revenueDisplayReports: state.proxyAdUnit.pAgencyRevenueDisplayRpt,
    }));

    console.log(revenueDisplayReports)

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const revenueDisplayReportsColumns = [
        {
            text: "No",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
            footer: ''
        },
    ];

    const createColoumn = () => {

    }

    const { errorMsgSchedule, successMsgSchedule } = useSelector(state => ({
        errorMsgSchedule: state.agencyScheduleReports.errorMsg,
        successMsgSchedule: state.agencyScheduleReports.successMsg,
    }));

    useEffect(() => {
        if (successMsgSchedule !== null) {
            if (successMsgSchedule.success) {
                dispatch(addAgencyScheduleReportSuccess(""));
                dispatch(addAgencyScheduleReportFail(""));
                dispatch(updateAgencyScheduleReportSuccess(""));
                dispatch(updateAgencyScheduleReportFail(""));
                toastr.success('AgencyScheduleReport Scheduled Successfully!...')
                dispatch(onGetAgencyScheduleReports({ filter: 'Active' }));
            }
        }
    }, [successMsgSchedule]);

    useEffect(() => {
        console.log(errorMsgSchedule)
        if (errorMsgSchedule !== null && errorMsgSchedule !== '') {
        }
    }, [errorMsgSchedule]);

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );


    const pageOptions = {
        paginationSize: 4,
        pageStartIndex: 1,
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };

    const defaultSorted = [
        {
            dataField: "requests", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const queryReportforData = (e) => {
        if (scheduleReport) {
            handleSubmit()
            scheduleReportData()
        } else {
            let DimArr = Object.values(selectAllDM)
            let MtcArr = Object.values(selectedMTC)
            if (!MtcArr.includes(true)) {
                toastr.warning('Please select atleast one Metrices')
            }
            else {
                handleSubmit()
            }

        }
    }

    const confirmAgencyScheduleReport = (e) => {
        setAgencyScheduleReport(true);
        setShowAlert(false)
    }

    const scheduleReportData = async (e) => {
        setSubmitted(true);
        let DimArr = Object.values(selectAllDM)
        let MtcArr = Object.values(selectedMTC)
        if (!MtcArr.includes(true)) {
            toastr.warning('Please select atleast one Metrices')
        }
        else {
            if (finalData.scheduleName && finalData.emails.length > 0) {
                let query = {
                    "settings": {
                        "dimensions": selectAllDM,
                        "filters": filters,
                        "metrices": selectedMTC,
                        "range": range,
                        currencyType:userInfo.partnerCurrencyType
                    },
                    "scheduleName": finalData.scheduleName,
                    "partnerName": userInfo.name,
                    "dateRange": finalData.dateRange,
                    "emails": finalData.emails,
                    "frequency": finalData.frequency,
                }

                console.log(query)
                dispatch(onADDAgencyScheduleReport({ ...query }));
            }
        }
    }

    const handleSubmit = async (e) => {
        setShowAlert(false)
        let DimArr = Object.values(selectAllDM)
        let MtcArr = Object.values(selectedMTC)
        if (!MtcArr.includes(true)) {
            toastr.warning('Please select atleast one  Metrices')
        }
        else {
            // setLoader(true)
            loader = true
            let query = {
                "dimensions": selectAllDM,
                "startDate": moment(fromDate).format("YYYY-MM-DD"),
                "endDate": moment(toDate).format("YYYY-MM-DD"),
                "filters": filters,
                "metrices": selectedMTC,
                "range": range,
                currencyType:userInfo.partnerCurrencyType,
                Partner: userInfo.name
            }
            // if (adminDetails) {
            //     query = {
            //         ...query, userlog: {
            //             userID: adminDetails.userID,
            //             userRole: adminDetails.userRole,
            //             companyID: adminDetails.companyID,
            //             name: adminDetails.name
            //         }
            //     }
            // }
            console.log(query)
            dispatch(onGetctrDisplayReports({ ...query }));
            setReportOpen(true)
        }
    }

    if (revenueDisplayReports.length > 0) {
        let keys = [];
        for (var k in revenueDisplayReports[0]) keys.push(k);
        if (keys.includes("hour")) {
            revenueDisplayReports.map((item, index) => {
                item.hour = parseInt(item.hour)
            })
        }
        console.log(keys)
        if (keys.includes("Date")) {
            revenueDisplayReportsColumns.push({
                dataField: "Date",
                text: "Date",
                sort: true,
                style: {
                    minWidth: "10em", wordBreak: 'break-word'
                },
                footer: 'Total'
            })
        }
        if (keys.includes("Month")) {
            revenueDisplayReportsColumns.push({
                dataField: "Month",
                text: "Month",
                sort: true,
                footer: 'Total'
            })
        }
        if (keys.includes("hour")) {
            revenueDisplayReportsColumns.push({
                dataField: "hour",
                text: "Hour",
                sort: true,
                footer: ''
            })
        }
        if (keys.includes("brand")) {
            revenueDisplayReportsColumns.push({
                dataField: "brand",
                text: "Brand",
                sort: true,
                style: {
                    minWidth: "10em", wordBreak: 'break-word'
                },
                footer: ''
            })
        }
        if (keys.includes("campaignName")) {
            revenueDisplayReportsColumns.push({
                dataField: "campaignName",
                text: "Campaign",
                sort: true,
                style: {
                    minWidth: "20em", wordBreak: 'break-word'
                },
                footer: ''
            })
        }
        if (keys.includes("placementName")) {
            revenueDisplayReportsColumns.push({
                dataField: "placementName",
                text: "Placement",
                sort: true,
                style: {
                    minWidth: "20em", wordBreak: 'break-word'
                },
                footer: ''
            })
        }
        if (keys.includes("adUnitSize")) {
            revenueDisplayReportsColumns.push({
                dataField: "adUnitSize",
                text: "AdUnit Size",
                sort: true,
                style: { wordBreak: 'break-word' },
                footer: ''
            })
        }
        if (keys.includes("device")) {
            revenueDisplayReportsColumns.push({
                dataField: "device",
                text: "Device",
                sort: true,
                style: { wordBreak: 'break-word' },
                footer: ''
            })
        }
        if (keys.includes("inventoryType")) {
            revenueDisplayReportsColumns.push({
                dataField: "inventoryType",
                text: "Inventory Type",
                sort: true,
                style: { wordBreak: 'break-word' },
                footer: ''
            })
        }

        if (keys.includes("adopsManager")) {
            revenueDisplayReportsColumns.push({
                dataField: "adopsManager",
                text: "Campaign Manager",
                sort: true,
                style: { wordBreak: 'break-word' },
                footer: '',
                headerStyle: (colum, colIndex) => {
                    return { width: '80px', textAlign: 'center' };
                }
            })
        }
        if (keys.includes("accountManager")) {
            revenueDisplayReportsColumns.push({
                dataField: "accountManager",
                text: "Account Manager",
                sort: true,
                style: { wordBreak: 'break-word' },
                footer: '',
                headerStyle: (colum, colIndex) => {
                    return { width: '80px', textAlign: 'center' };
                }
            })
        }
        if (keys.includes("publisherManager")) {
            revenueDisplayReportsColumns.push({
                dataField: "publisherManager",
                text: "Sales Manager",
                sort: true,
                style: { wordBreak: 'break-word' },
                footer: ''
            })
        }


        if (keys.includes("impressions")) {
            revenueDisplayReportsColumns.push({
                dataField: "impressions",
                text: "Impressions",
                sort: true,
                style: { wordBreak: 'break-word' },
                footer: `${totalImpressions}`
            })
        }
        if (keys.includes("clicks")) {
            revenueDisplayReportsColumns.push({
                dataField: "clicks",
                text: "Clicks",
                sort: true,
                footer: `${totalClicks}`
            })
        }
        if (keys.includes("ctr")) {
            revenueDisplayReportsColumns.push({
                dataField: "ctr",
                text: "CTR",
                sort: true,
                footer: `${avgCTR}`,
                formatter: (cellContent, ctr) => (
                    <>
                        <span>{((parseFloat(ctr.ctr)).toFixed(2))}</span>
                    </>
                ),
            })
        }
        if (keys.includes("conversions")) {
            revenueDisplayReportsColumns.push({
                dataField: "conversions",
                text: "Conversions",
                sort: true,
                footer: `${userInfo.partnerCurrencyType === 'USD' ? "$" : "₹"}${TotalConversions}`
            })
        }
        if (keys.includes("conversionRatio")) {
            revenueDisplayReportsColumns.push({
                dataField: "conversionRatio",
                text: "Conversion Ratio",
                sort: true,
                footer: `${AvgconversionRatio}` + '%'
            })
        }
        if (keys.includes("cost")) {
            revenueDisplayReportsColumns.push({
                dataField: "cost",
                text: "Cost",
                sort: true,
                style: { wordBreak: 'break-word' },
                footer: `${userInfo.partnerCurrencyType === 'USD' ? "$" : "₹"}${totalCost}`
            })
        }
        if (keys.includes("cpm")) {
            revenueDisplayReportsColumns.push({
                dataField: "cpm",
                text: "CPM",
                sort: true,
                style: { wordBreak: 'break-word' },
                footer: `${userInfo.partnerCurrencyType === 'USD' ? "$" : "₹"}${totalCPM}`
            })
        }
        if (keys.includes("videoCompletions")) {
            revenueDisplayReportsColumns.push({
                dataField: "videoCompletions",
                text: "Video Completion",
                sort: true,
                style: { wordBreak: 'break-word' },
                footer: `${TotalvideoCompletions}`
            })
        }
        if (keys.includes("videoCompletionsRate")) {
            revenueDisplayReportsColumns.push({
                dataField: "videoCompletionsRate",
                text: "Video Completion Rate",
                sort: true,
                style: { wordBreak: 'break-word' },
                footer: `${AvgVideoConversionRatio}` + '%'
            })
        }

        loader = false
        console.log(revenueDisplayReportsColumns)
    } else {
        loader = false
    }

    useEffect(() => {
        console.log(revenueDisplayReports)

        if (revenueDisplayReports !== null && revenueDisplayReports.length > 0) {
            // setLoader(false)
            loader = false
            let total1 =0
            let total2 =0
            let total3 =0
            let total4 =0
            let total5 =0
            let total6 =0
            let avg1 =0
            let avg2 =0
            let avg3 =0
            let avgCPM =0

            revenueDisplayReports.map((item, index) => {
                if (item.Date) {
                    item.Date = moment(item.Date).format("DD/MM/YYYY");
                }
                if (item.impressions) {
                    item.impressions = Number(item.impressions)
                    total1 = total1 + parseInt(item.impressions)
                }
                if (item.clicks) {
                    item.clicks = Number(item.clicks)
                    total2 = total2 + (parseInt(item.clicks) || 0)
                }
                if (item.conversions) {
                    item.conversions = Number(item.conversions)
                    total3 = total3 + (parseFloat(item.conversions))
                }
                if (item.cost) {
                    item.cost = Number(item.cost)
                    total4 = total4 + (parseFloat(item.cost))
                }
                if (item.cpm) {
                    item.cpm = Number(item.cpm)
                    total5 = total5 + (parseFloat(item.cpm))
                }
                if (item.videoCompletions) {
                    item.videoCompletions = Number(item.videoCompletions)
                    total6 = total6 + (parseFloat(item.videoCompletions))
                }
                if (item.ctr) {
                    item.ctr = Number(item.ctr)
                    avg1 = avg1 + (parseFloat(item.ctr))
                }
                if (item.conversionRatio) {
                    item.conversionRatio = Number(item.conversionRatio)
                    avg2 = avg2 + (parseFloat(item.conversionRatio))
                }
                if (item.videoCompletionsRate) {
                    item.videoCompletionsRate = Number(item.videoCompletionsRate)
                    avg3 = avg3 + (parseFloat(item.videoCompletionsRate))
                }
            })
            if (avg1 > 0) {
                let avg =  (total2 / total1)*100
                setAvgCTR(avg.toFixed(2))
            }
            if (avg2 > 0) {
                let avg =  (total3 / total1)*100
                setAvgconversionRatio(avg.toFixed(2))
            }
            if (total6 > 0) {
                let avg =  (total6 / total1)*100
                setAvgVideoConversionRatio(avg.toFixed(2))
            }
            if(total5 > 0){
                avgCPM =  (total5 / revenueDisplayReports.length)
                setTotalCPM(avgCPM.toFixed(2))
            }
            if(total1 > 0){
                let avg = (total1 / 1000)*avgCPM
                setTotalCost(avg.toFixed(2))
            }
            setTotalImpressions(total1)
            setTotalClicks(total2)
            setTotalConversions(total3)
            setTotalvideoCompletions(total6)
        } else {
            loader = false
        }

    }, [revenueDisplayReports]);

    var CSVProp
    const CSVClick = () => {
        CSVProp.onExport();
    }

    const MyExportCSV = (csvprops) => {
        CSVProp = csvprops
        return (<></>);
    };

    const copyTable = () => {
        console.log(CSVProp, JSON.stringify(CSVProp))
        navigator.clipboard.writeText(CSVProp)
        // const elTable = document.querySelector('table');

        // let range, sel;

        // if (document.createRange && window.getSelection) {

        //   range = document.createRange();
        //   sel = window.getSelection();
        //   sel.removeAllRanges();

        //   try {
        //     range.selectNodeContents(elTable);
        //     sel.addRange(range);
        //   } catch (e) {
        //     range.selectNode(elTable);
        //     sel.addRange(range);
        //   }

        //   document.execCommand('copy');
        // }

        // sel.removeAllRanges();

        console.log('Element Copied! Paste it in a file')
    }

    const exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Proxy AdUnit Report";
        const headers = revenueDisplayReportsColumns;

        // const data = this.state.people.map(elt=> [elt.name, elt.profession]);

        let content = {
            startY: 50,
            head: headers,
            body: revenueDisplayReports
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("report.pdf")
    }

    const handleData = (e) => {
        const { name, value } = e.target;
        setFinalData(finalData => ({ ...finalData, [name]: value }));
    }

    const handleChangeEmail = (e) => {
        const { name, value } = e.target;
        setEmailData(value)
        let urls = value.split(/[\s,]+/);
        setFinalData(finalData => ({ ...finalData, [name]: urls }));
    }

    const [state, setState] = useState({
        start: moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days'),
        end: moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),
    });

    const { start, end } = state;

    const handleEvent = (startDate, endDate, label) => {
        console.log(startDate, endDate)
        setState({ start: startDate, end: endDate, });
        fromDate = startDate._d.toISOString();
        toDate = endDate._d.toISOString();
    };

    const labelDate = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');

    return (
        <Row>

            {!reportOpen &&
                <Container fluid>
                    <Row>
                        <div className="col-12">
                            <Card>
                                <CardBody>
                                    <Form onSubmit={handleSubmit}>
                                        <Row style={{ padding: '1% 3%', }}>

                                            <Col xs={4} >
                                                <div className="mb-3">
                                                    <h5>SELECT DATE</h5> <br />
                                                    {/* <DateRangePickerReport datePick={changeDatePick} startDate={dateFromDate} endDate={dateToDate} /> */}

                                                    <DateRangePicker
                                                        initialSettings={{
                                                            opens: 'left',
                                                            startDate: start.toDate(),
                                                            endDate: end.toDate(),
                                                            // minDate: moment().subtract(estCurrentDate > 1 ? 31 : 30, 'days'),
                                                            maxDate: moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),
                                                            ranges: {
                                                                // Today: [ moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),
                                                                // moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),],
                                                                Yesterday: [
                                                                    moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
                                                                    moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
                                                                ],
                                                                'Last 7 Days': [moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days'), moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')],
                                                                'Last 30 Days': [moment().subtract(estCurrentDate > 1 ? 31 : 30, 'days'), moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')],
                                                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                                'Last Month': [
                                                                    moment().subtract(1, 'month').startOf('month'),
                                                                    moment().subtract(1, 'month').endOf('month'),
                                                                ],
                                                            },
                                                        }}
                                                        onCallback={handleEvent}
                                                    >
                                                        <button className='datePicker' type="button">
                                                            <i className="bx bx-calendar font-size-18 " id="edittooltip" style={{ margin: '3% 3% 2%' }} ></i>{labelDate}
                                                        </button>
                                                    </DateRangePicker>
                                                </div>
                                                <br />
                                                <h5>FILTERS</h5>
                                                <div className="mb-3">
                                                    <Label className="form-label">Brand</Label>
                                                    <Select
                                                        value={selectedbrand}
                                                        onChange={s => {
                                                            handleSelectbrand(s)
                                                        }}
                                                        options={brand}
                                                        isClearable={true}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label className="form-label">Campaign </Label>
                                                    <Select
                                                        value={selectedcampaignName}
                                                        onChange={s => {
                                                            handleSelectcampaignName(s)
                                                        }}
                                                        options={campaignName}
                                                        isClearable={true}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label">Placement</Label>
                                                    <Select
                                                        value={selectedplacementName}
                                                        onChange={s => {
                                                            handleSelectplacementName(s)
                                                        }}
                                                        options={placementName}
                                                        isClearable={true}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label">Ad Size</Label>
                                                    <Select
                                                        value={selectedadUnitSize}
                                                        onChange={s => {
                                                            handleSelectadUnitSize(s)
                                                        }}
                                                        options={adUnitSize}
                                                        isClearable={true}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label">Device Type</Label>
                                                    <Select
                                                        value={selecteddevice}
                                                        onChange={s => {
                                                            handleSelectDomian(s)
                                                        }}
                                                        options={device}
                                                        isClearable={true}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label">Inventory Type </Label>
                                                    <Select
                                                        value={selectedinventoryType}
                                                        onChange={s => {
                                                            handleSelectinventoryType(s)
                                                        }}
                                                        options={inventoryType}
                                                        isClearable={true}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label>Campaign Manager</Label>
                                                    <Select
                                                        value={selectedadopsManager}
                                                        onChange={s => {
                                                            handleSelectadopsManager(s)
                                                        }}
                                                        options={adopsManager}
                                                        isClearable={true}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label className="form-label">Sales Manager</Label>
                                                    <Select
                                                        value={selectedpublisherManager}
                                                        onChange={s => {
                                                            handleSelectpublisherManager(s)
                                                        }}
                                                        options={publisherManager}
                                                        isClearable={true}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={4} style={{ padding: '0% 3%', }}>

                                                <div className="mb-3">
                                                    <h5> GROUP BY</h5><br />
                                                    <Input
                                                        name="range"
                                                        type="select"
                                                        className="form-select"
                                                        onChange={changeRange}
                                                        value={range}
                                                    >
                                                        <option value='' disabled>Select</option>
                                                        <option value='Month'>Month</option>
                                                        <option value='Day'>Day</option>
                                                        <option value='Cumulative'>Cumulative</option>
                                                    </Input>
                                                </div>
                                                <br />
                                                <h5 className="mb-3 ">DIMENSIONS</h5>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAll}
                                                        onChange={changeDMSelect}
                                                        id="ctrSelectAll"
                                                    />
                                                    <label
                                                        className="form-check-label  m-2 mt-1"
                                                        htmlFor="ctrSelectAll"
                                                    >
                                                        Select All
                                                    </label>
                                                </div>

                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.brand}
                                                        name="brand"
                                                        onChange={changeSelection}
                                                        id="brand"
                                                    />
                                                    <label
                                                        className="form-check-label  m-2 mt-1"
                                                        htmlFor="brand"
                                                    >
                                                        Brand
                                                    </label>
                                                </div>

                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.campaignName}
                                                        name="campaignName"
                                                        onChange={changeSelection}
                                                        id="supplycampaignName"
                                                    />
                                                    <label
                                                        className="form-check-label  m-2 mt-1"
                                                        htmlFor="supplycampaignName"
                                                    >
                                                        Campaign
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.placementName}
                                                        name="placementName"
                                                        onChange={changeSelection}
                                                        id="placementName"
                                                    />
                                                    <label
                                                        className="form-check-label  m-2 mt-1"
                                                        htmlFor="placementName"
                                                    >
                                                        Placement
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.adUnitSize}
                                                        name="adUnitSize"
                                                        onChange={changeSelection}
                                                        id="adUnitSize"
                                                    />
                                                    <label
                                                        className="form-check-label  m-2 mt-1"
                                                        htmlFor="adUnitSize"
                                                    >
                                                        Ad Size
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.device}
                                                        name="device"
                                                        onChange={changeSelection}
                                                        id="device"
                                                    />
                                                    <label
                                                        className="form-check-label  m-2 mt-1"
                                                        htmlFor="device"
                                                    >
                                                        Device Type
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.inventoryType}
                                                        name="inventoryType"
                                                        onChange={changeSelection}
                                                        id="inventoryType"
                                                    />
                                                    <label
                                                        className="form-check-label  m-2 mt-1"
                                                        htmlFor="inventoryType"
                                                    >
                                                        Inventory Type
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.adopsManager}
                                                        name="adopsManager"
                                                        onChange={changeSelection}
                                                        id="adopsManager"
                                                    />
                                                    <label
                                                        className="form-check-label  m-2 mt-1"
                                                        htmlFor="adopsManager"
                                                    >
                                                        Campaign Manager
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.accountManager}
                                                        name="accountManager"
                                                        onChange={changeSelection}
                                                        id="accountManager"
                                                    />
                                                    <label
                                                        className="form-check-label  m-2 mt-1"
                                                        htmlFor="accountManager"
                                                    >
                                                        Account Manager
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.publisherManager}
                                                        name="publisherManager"
                                                        onChange={changeSelection}
                                                        id="publisherManager"
                                                    />
                                                    <label
                                                        className="form-check-label  m-2 mt-1"
                                                        htmlFor="publisherManager"
                                                    >
                                                        Sales Manager
                                                    </label>
                                                </div>


                                            </Col>
                                            <Col xs={4} style={{ padding: '0 3%', }}>
                                                <div className="mb-3">

                                                </div>
                                                <br />
                                                <h5 className="mb-3">METRICES</h5>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllMTC}
                                                        onChange={changeMTCSelect}
                                                        id="selectAllctr"
                                                    />
                                                    <label
                                                        className="form-check-label  m-2 mt-1"
                                                        htmlFor="selectAllctr"
                                                    >
                                                        Select All
                                                    </label>
                                                </div>

                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedMTC.impressions}
                                                        name="impressions"
                                                        onChange={changeSelectionMTC}
                                                        id="impressions"
                                                    />
                                                    <label
                                                        className="form-check-label  m-2 mt-1"
                                                        htmlFor="impressions"
                                                    >
                                                        Impressions
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedMTC.clicks}
                                                        name="clicks"
                                                        onChange={changeSelectionMTC}
                                                        id="clicks"
                                                    />
                                                    <label
                                                        className="form-check-label  m-2 mt-1"
                                                        htmlFor="clicks"
                                                    >
                                                        Clicks
                                                    </label>
                                                </div>

                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedMTC.ctr}
                                                        name="ctr"
                                                        onChange={changeSelectionMTC}
                                                        id="ctr"
                                                    />
                                                    <label
                                                        className="form-check-label  m-2 mt-1"
                                                        htmlFor="ctr"
                                                    >
                                                        CTR
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedMTC.conversions}
                                                        name="conversions"
                                                        onChange={changeSelectionMTC}
                                                        id="conversions"
                                                    />
                                                    <label
                                                        className="form-check-label  m-2 mt-1"
                                                        htmlFor="conversions"
                                                    >
                                                        Conversions
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedMTC.conversionRatio}
                                                        name="conversionRatio"
                                                        onChange={changeSelectionMTC}
                                                        id="conversionRatio"
                                                    />
                                                    <label
                                                        className="form-check-label  m-2 mt-1"
                                                        htmlFor="conversionRatio"
                                                    >
                                                        Conversions Ratio
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedMTC.cost}
                                                        name="cost"
                                                        onChange={changeSelectionMTC}
                                                        id="cost"
                                                    />
                                                    <label
                                                        className="form-check-label  m-2 mt-1"
                                                        htmlFor="cost"
                                                    >
                                                        Cost
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedMTC.cpm}
                                                        name="cpm"
                                                        onChange={changeSelectionMTC}
                                                        id="cpm"
                                                    />
                                                    <label
                                                        className="form-check-label  m-2 mt-1"
                                                        htmlFor="cpm"
                                                    >
                                                        CPM
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedMTC.videoCompletions}
                                                        name="videoCompletions"
                                                        onChange={changeSelectionMTC}
                                                        id="videoCompletions"
                                                    />
                                                    <label
                                                        className="form-check-label  m-2 mt-1"
                                                        htmlFor="videoCompletions"
                                                    >
                                                        Video Completion
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedMTC.videoCompletionsRate}
                                                        name="videoCompletionsRate"
                                                        onChange={changeSelectionMTC}
                                                        id="videoCompletionsRate"
                                                    />
                                                    <label
                                                        className="form-check-label  m-2 mt-1"
                                                        htmlFor="videoCompletionsRate"
                                                    >
                                                        Video Completion Rate
                                                    </label>
                                                </div>

                                            </Col>
                                        </Row>


                                        <div className="form-check " style={{ padding: '1% 4%', }}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={scheduleReport}
                                                name="AdUnitName"
                                                onChange={(e) => setAgencyScheduleReport(e.target.checked)}
                                            />
                                            <h5
                                                className="form-check-label  m-2 mt-1"
                                            >
                                                Schedule Report
                                            </h5>
                                        </div>
                                        {scheduleReport &&
                                            <div>
                                                <Row style={{ padding: '1% 3%', }}>
                                                    <Col xs={4} >
                                                        <div className="mb-3">
                                                            <h5>SELECT DATE:</h5> <br />
                                                            <Input
                                                                name="dateRange"
                                                                type="select"
                                                                className="form-select"
                                                                onChange={handleData}
                                                                value={finalData.dateRange}
                                                            >
                                                                <option value='' disabled>Select Date Range</option>
                                                                <option value='yesterday'>Yesterday</option>
                                                                <option value='last7Days'>Last 7 Days</option>
                                                                <option value='last30Days'>Last 30 Days</option>
                                                                <option value='thisMonth'>Current Month</option>
                                                                <option value='lastMonth'>Last Month</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col xs={4} style={{ padding: '0% 3%', }}>
                                                        <div className="mb-3">
                                                            <h5> GROUP BY:</h5><br />
                                                            <Input
                                                                name="range"
                                                                type="select"
                                                                className="form-select"
                                                                onChange={handleData}
                                                                value={finalData.range}
                                                            >
                                                                <option value='' disabled>Select</option>
                                                                <option value='Month'>Month</option>
                                                                <option value='Day'>Day</option>
                                                                <option value='Cumulative'>Cumulative</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col xs={4} style={{ padding: '0% 3%', }}>
                                                        <div className="mb-3">
                                                            <h5> FREQUENCY:</h5><br />
                                                            <Input
                                                                name="frequency"
                                                                type="select"
                                                                className="form-select"
                                                                onChange={handleData}
                                                                value={finalData.frequency}
                                                            >
                                                                <option value='daily' >Daily</option>
                                                                <option value='weekly'>Weekly</option>
                                                                <option value='monthly'>Monthly</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col xs={4} >
                                                        <div className="mb-3">
                                                            <h5> SCHEDULE REPORT NAME:</h5><br />
                                                            <Input
                                                                name="scheduleName"
                                                                type="text"
                                                                className={(submitted && !finalData.scheduleName ? ' is-invalid' : '')}
                                                                onChange={handleData}
                                                                value={finalData.scheduleName}
                                                            >
                                                            </Input>
                                                            {submitted && !finalData.scheduleName ? (
                                                                <FormFeedback type="invalid">{'Schedule Report Name is required'}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col xs={8} style={{ padding: '0% 3%', }}>
                                                        <div className="mb-3">
                                                            <h5> EMAIL:</h5><br />
                                                            <Input
                                                                name="emails"
                                                                type="text"
                                                                className={(submitted && finalData.emails.length === 0 ? ' is-invalid' : '')}
                                                                value={emailData}
                                                                onChange={handleChangeEmail}
                                                                placeholder="Enter multiple email addresses seperated by commas"
                                                            >
                                                            </Input>
                                                            {submitted && finalData.emails.length === 0 ? (
                                                                <FormFeedback type="invalid">{'Email is required'}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                        <Row>
                                            <Col>
                                                <div className=" text-sm-end">
                                                    {revenueDisplayReports.length > 0 && !reportOpen &&
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary save-user"
                                                            onClick={() => setReportOpen(true)}
                                                        >
                                                            Go Back
                                                        </button>
                                                    }
                                                    &nbsp;&nbsp;&nbsp;
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary save-user"
                                                        onClick={queryReportforData}
                                                        data-toggle="modal"
                                                    >
                                                        Query Report
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>
            }


            {reportOpen &&

                <Container fluid={true} style={{ pointerEvents: (loading) ? 'none' : 'unset' }}>
                    <div className="row mb-2 d-sm-flex  justify-content-between">
                        <div className=" text-sm-end">
                            <div className="btn-group">
                                <Dropdown
                                    isOpen={btnprimary1}
                                    toggle={() => setBtnprimary1(!btnprimary1)}
                                >
                                    <DropdownToggle tag="button" className="btn btn-primary">
                                        Export
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {/* <DropdownItem onClick={copyTable}>Copy</DropdownItem> */}
                                        <DropdownItem onClick={CSVClick}>CSV</DropdownItem>
                                        {/* <DropdownItem onClick={exportPDF}>PDF</DropdownItem> */}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            &nbsp;&nbsp;&nbsp;
                            <Button
                                color="primary"
                                className="font-16 btn-block btn btn-primary"
                                onClick={(e) => setReportOpen(false)}
                            >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                Modify Report
                            </Button>
                        </div>
                    </div>
                    {loading &&
                        <Spinner className="ms-2 loader" color="primary" />
                    }

                    {!loading &&
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody aria-hidden="true">
                                        {loader &&
                                            <>
                                                <Spinner className="ms-2 loader" color="primary" />
                                                <br />
                                                <h5 className="text-center mt-5" style={{ padding: '15%', fontSize: '25px' }}></h5>
                                                <br />
                                            </>
                                        }
                                        {revenueDisplayReports.length === 0 && !loader &&
                                            <h5 className="text-center" style={{ padding: '15%', fontSize: '25px' }}>No Data</h5>
                                        }
                                        {revenueDisplayReports.length > 0 && !loader &&
                                            <ToolkitProvider
                                                keyField={keyField}
                                                data={revenueDisplayReports}
                                                columns={revenueDisplayReportsColumns}
                                                // bootstrap4
                                                search
                                                exportCSV
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col sm="8">
                                                                <div className="search-box ms-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <SearchBar {...toolkitProps.searchProps} />
                                                                        <i className="bx bx-search-alt search-icon" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col sm="1">
                                                                <MyExportCSV {...toolkitProps.csvProps} />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12" className="table-responsive">
                                                                <div >
                                                                    <BootstrapTable
                                                                        keyField={keyField}
                                                                        responsive
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        classes={
                                                                            "table align-middle table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        pagination={paginationFactory(pageOptions)}
                                                                        noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }

                </Container>
            }

            {/* <SweetAlert
                custom
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="No"
                confirmBtnBsStyle="primary"
                cancelBtnBsStyle="light"
                title="Do you want to Schedule Report?"
                onConfirm={confirmAgencyScheduleReport}
                onCancel={handleSubmit}
                show={showAlert}
            >
            </SweetAlert> */}



            <Modal size="sm" isOpen={showAlert} toggle={() => { setShowAlert(!showAlert) }} backdrop={'static'} centered={true}
                id="staticBackdrop"
            >
                <div className="modal-content">
                    <ModalBody className="px-2 py-4 text-center">
                        <p className="text-muted font-size-16 mb-4">Do you want to Schedule Report?</p>

                        <div className="hstack gap-2 justify-content-center mb-0">
                            <button type="button" className="btn btn-light" onClick={handleSubmit}>No</button>
                            <button type="button" className="btn btn-primary" onClick={confirmAgencyScheduleReport}>Yes</button>
                        </div>
                    </ModalBody>
                </div>
            </Modal>


        </Row>
    )
}
Agency_ctr_Report.propTypes = {}

export default Agency_ctr_Report