/* PUBLISHER */
export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES"
export const NO_DATA = "NO_DATA"
export const GET_PUBLISHERS = "GET_PUBLISHERS"
export const GET_PUBLISHERS_SUCCESS = "GET_PUBLISHERS_SUCCESS"
export const GET_PUBLISHERS_FAIL = "GET_PUBLISHERS_FAIL"

export const ADD_PUBLISHER = "ADD_PUBLISHER"
export const ADD_PUBLISHER_SUCCESS = "ADD_PUBLISHER_SUCCESS"
export const ADD_PUBLISHER_ERROR = "ADD_PUBLISHER_ERROR"

export const UPDATE_PUBLISHER = "UPDATE_PUBLISHER"
export const UPDATE_PUBLISHER_SUCCESS = "UPDATE_PUBLISHER_SUCCESS"
export const UPDATE_PUBLISHER_ERROR = "UPDATE_PUBLISHER_ERROR"

export const READ_PUBLISHER = "READ_PUBLISHER"
export const READ_PUBLISHER_SUCCESS = "READ_PUBLISHER_SUCCESS"

export const STATUS_PUBLISHER = "STATUS_PUBLISHER"
export const STATUS_PUBLISHER_SUCCESS = "STATUS_PUBLISHER_SUCCESS"

export const ARCHIVE_PUBLISHER = "ARCHIVE_PUBLISHER"
export const ARCHIVE_PUBLISHER_SUCCESS = "ARCHIVE_PUBLISHER_SUCCESS"