/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
} from "reactstrap"

import classnames from "classnames";

import DateRangePickers from 'helpers/DateRangePicker'
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone, PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import DetachLIbyAdUnit from "./DetachLIbyAdUnit";
import InvoiceTableHelper from "Product_Helpers/InvoiceTableHelper";
import LoadingTable from "pages/LoaderPages/LoadingTable";

var selectedItem = []
var selectedIndex = []

var lineItemsData = []
let pageNumber = 1
let pageSize = 10
let totalRecords = 0
let searchText = ''

const DetachLineItem = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [lineItems, setLineItems] = useState([])
    const [selectedAdUnit, setSelectedAdUnit] = useState({})
    const [selectedAdUnitState, setSelectedAdUnitState] = useState(false);
    const [loading, setLoading] = useState(true)
    const [switchLoader, setSwitchLoader] = useState(true)
    const handleSwitch = () => {
        setSwitchLoader(!switchLoader)
    }

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    useEffect(() => {
        getLineItem({ pageSize: pageSize, pageNumber: pageNumber, searchText: searchText })
    }, []);

    const getLineItem = async (selectedPages) => {
        setLoading(true)
        setSwitchLoader(true)
        const body = {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            name: userInfo.name, parentID: userInfo.parentID,
            isSupplyPartner: userInfo.isSupplyPartner,
            isForSupplyPartner: userInfo.isForSupplyPartner,
            searchText: selectedPages.searchText
        }
        axios.post(userInfo.isSupplyPartner ? `/v2.0/demandSupplyPartner/LineItems/read/forDetachment/pagination/SupplyPartner?page=${selectedPages.pageNumber}&size=${selectedPages.pageSize}` : `/v2.0/demand/LineItems/read/forDetachment/pagination?page=${selectedPages.pageNumber}&size=${selectedPages.pageSize}`, body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data.statusCode === 200) {
                    res.data.response.lineItemAdunits.map((item, index) => {
                        item.id = index + 1
                    })
                    setLoading(false)
                    setSwitchLoader(false)
                    setLineItems([...res.data.response.lineItemAdunits])
                    totalRecords = res.data.response.totalRecords
                }
            })
            .catch((err) => {
                setLoading(false)
                setSwitchLoader(false)
                console.log("e", err)
            });
    }

    const closeLineItem = (event) => {
        console.log(event)
        if (event === 'Update') {
            toastr.success('Line Items are Detached Successfully..')
        }
        setSelectedAdUnitState(false)
        getLineItem({ pageSize: pageSize, pageNumber: pageNumber, searchText: searchText })
    }

    const adUnitListColoumns = [
        {
            text: "ID",
            headerClasses: "ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            text: "AdUnit ID",
            dataField: "adUnitID",
            sort: true,
        },
        {
            text: "AdUnit",
            dataField: "adUnitName",
            sort: true,
        },
        {
            text: "Line Item Presents",
            dataField: "",
            sort: true,
            align: 'center',
            headerAlign: 'center',
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, lineItem) => (
                <>
                    <span className="">
                        {lineItem.lineItemData.length}
                    </span>
                </>
            ),
        },

        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',

            // eslint-disable-next-line react/display-name
            formatter: (cellContent, adUnit) => (
                <div className="d-flex gap-3">
                    <Link to={'#'} onClick={() => { tog_standard(adUnit); }} readOnly={(adUnit.lineItemData.length === 0)} >
                        <u>View Line Item</u>
                    </Link>
                </div>
            ),
        },
    ];

    function tog_standard(adUnit) {
        console.log(adUnit)
        if (adUnit !== '') {
            setSelectedAdUnit(adUnit)
            if (adUnit.lineItemData.length > 0) {
                adUnit.lineItemData.map((item, index) => {
                    item.id = index + 1
                })
            }
            console.log(adUnit.lineItemData)
            lineItemsData = adUnit.lineItemData
            console.log(lineItemsData)
            setSelectedAdUnitState(true)
        } else {
            setSelectedAdUnitState(false)
        }
    }

    const paginationChange = async (selectedPages) => {
        console.log(selectedPages)
        pageSize = selectedPages.pageSize
        pageNumber = selectedPages.pageNumber
        getLineItem({ pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText })

    }

    return (
        <React.Fragment >
            <Container fluid={true}>
                {selectedAdUnitState ? <DetachLIbyAdUnit selectedAdUnit={selectedAdUnit} closeDetach={closeLineItem} /> : <Row>
                    {switchLoader &&
                        <LoadingTable loading={loading} targetLoading={handleSwitch} />
                    }
                    {!switchLoader &&
                        <InvoiceTableHelper data={lineItems} Module='history' columns={adUnitListColoumns} paginationSelection={paginationChange} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize} />
                    }
                </Row>
                }
            </Container>
        </React.Fragment >
    )
}

DetachLineItem.propTypes = {}

export default DetachLineItem