/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import axios from "axios";

var itemInfo = {}

const Publisher_Supply_BreadCrumb = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));

    const headers = {
      "Content-Type": "application/json",
      "Accept": 'application/json',
      "Authorization": `Bearer ${userInfo.token}`
    }
  
    useEffect(() => {
      if(props.publisherId || props.adUnitGroupId){
        var filterBody = {
            "partnerID": 0,
            "publisherID": 0,
            "adUnitGroupID": 0,
            "adUnitID": 0,
            "childAdUnitID": 0,
            "playerSettingID": 0,
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            name:userInfo.name,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner
        }
        if (props.publisherId) {
          filterBody.publisherID = JSON.parse(props.publisherId)
        }
      else if (props.adUnitGroupId) {
          filterBody.adUnitGroupID = JSON.parse(props.adUnitGroupId)
        }
        axios.post(userInfo.isSupplyPartner ?"/v2.0/supplyPartner/getParent/SupplyPartner" : "/v2.0/supply/getParent", filterBody,
          {
            headers: headers
          })
          .then((res) => {
            console.log("cdsresult is ==>", res)
            if (res.data) {
              itemInfo = res.data.response
            }
          })
          .catch((err) => {
            console.log("e", err)
            // setInValid(true)
          });
      }
    }, [])

  return (
       <Row>
      <Col xs="12">
        <div className="page-title-box  align-items-center ">
          <h4 className="mb-2 font-size-18">{props.breadcrumbItem}</h4>
          <ol className="breadcrumb m-0">
            {props.publisherId &&
              <div className='d-flex'>
                <BreadcrumbItem>
                <Link to="/PublisherConsole_Publisher">All Publishers</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <span> {itemInfo.publisherName}</span>
                </BreadcrumbItem>
              </div>
            }
            {props.adUnitGroupId &&
              <div className='d-flex'>
              <BreadcrumbItem>
                <Link to="/PublisherConsole_Publisher">All Publishers</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to={`/Publisher_AdUnitGroup?publisherId=${itemInfo.publisherID}&publisherName=${itemInfo.publisherName}`} className="color-dark-blue signUp">
                    {itemInfo.publisherName}
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                <Link to="/Publisher_AdUnitGroup">All Ad Unit Groups</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <span> {itemInfo.adUnitGroupName}</span>
                </BreadcrumbItem>
              </div>
            }
          </ol>
        </div>
      </Col>
    </Row>
  )
}


Publisher_Supply_BreadCrumb.propTypes = {
    breadcrumbItem: PropTypes.string,
    title: PropTypes.string
}

export default Publisher_Supply_BreadCrumb