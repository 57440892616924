import React, { useState, useEffect , useCallback} from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import axios from "axios";
import {
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Label
} from "reactstrap";

import classnames from "classnames";
import NativeWidget from "./NativeWidget";
import RSSSettings from "./RSSSettings";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const NativeAd = (props) => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [isRight, setIsRight] = useState(false);
  const [tagList, setTagList] = useState([])

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 1000,
    extendedTimeOut: 1000
  };

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setIsRight(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const body = {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner
  }

  const toggleDrawer = (close) => (event) => {
    console.log(event)
    if (event === 'Create') {
      toastr.success('RSS Feed Settings are Updated Successfully!...')
    }
    setIsRight(close);
    fetchRSSFeeds()
  };

  const toggleRightCanvas = () => {
    setIsRight(!isRight);
    fetchRSSFeeds()
  };

  const fetchRSSFeeds = () => {
    axios.post("/v2.0/BX/JsonFile/list", body,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is ==>", res)
        if (res.data) {
          setTagList(res.data.feeds)
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  };

  useEffect(() => {
    fetchRSSFeeds()
  }, []);

  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content access-management"> */}
        <div className="page-content">
          <Helmet>
            <title>Native Ad| Bidsxchange</title>
          </Helmet>
          <Container fluid={true}>
            <Breadcrumbs title="House Ad" breadcrumbItem="Native Ad Generator" />
            <Card>
              <CardBody>
                <div className="row">
                  <div className="col-md-10">
                    <Nav tabs className="nav-tabs-custom nav-justified" style={{ width: '20pc' }}>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "1",
                          })}
                          onClick={() => {
                            toggleCustom("1");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-none d-sm-block">URL</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "2",
                          })}
                          onClick={() => {
                            toggleCustom("2");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-user"></i>
                          </span>
                          <span className="d-none d-sm-block">RSS</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                  <div className="col-md-2">
                    {customActiveTab === '2' &&
                        <div className="text-sm-end mb-3" >
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary "
                          style={{ marginRight: '2.5rem' }}
                          onClick={toggleDrawer(true)}
                        >
                          <i className="bx bx-settings"></i>
                          RSS Settings
                        </Button>
                      </div>
                    }
                  </div>
                </div>
                <TabContent
                  activeTab={customActiveTab}
                  className="p-3 text-muted"
                >
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <NativeWidget widgetType={customActiveTab} />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">

                        <NativeWidget widgetType={customActiveTab} RSSList={tagList} />
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Container>
        </div>
      {/* </div> */}
      <Offcanvas isOpen={isRight} direction='end' >
        <OffcanvasHeader toggle={toggleRightCanvas}>
          {'RSS Feed Settings'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <RSSSettings closeCanvas={toggleDrawer(false)} />
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment >
  )
}

export default NativeAd