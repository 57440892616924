/* AD_PUBLISHER */
export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES"
export const NO_DATA_HOUSE_AD_PUBLISHER = "NO_DATA_HOUSE_AD_PUBLISHER"
export const GET_AD_PUBLISHER = "GET_AD_PUBLISHER"
export const GET_AD_PUBLISHER_SUCCESS = "GET_AD_PUBLISHER_SUCCESS"
export const GET_AD_PUBLISHER_FAIL = "GET_AD_PUBLISHER_FAIL"

export const ADD_AD_PUBLISHER = "ADD_AD_PUBLISHER"
export const ADD_AD_PUBLISHER_SUCCESS = "ADD_AD_PUBLISHER_SUCCESS"
export const ADD_AD_PUBLISHER_ERROR = "ADD_AD_PUBLISHER_ERROR"

export const UPDATE_AD_PUBLISHER = "UPDATE_AD_PUBLISHER"
export const UPDATE_AD_PUBLISHER_SUCCESS = "UPDATE_AD_PUBLISHER_SUCCESS"
export const UPDATE_AD_PUBLISHER_ERROR = "UPDATE_AD_PUBLISHER_ERROR"

export const READ_AD_PUBLISHER = "READ_AD_PUBLISHER"
export const READ_AD_PUBLISHER_SUCCESS = "READ_AD_PUBLISHER_SUCCESS"

export const STATUS_AD_PUBLISHER = "STATUS_AD_PUBLISHER"
export const STATUS_AD_PUBLISHER_SUCCESS = "STATUS_AD_PUBLISHER_SUCCESS"

export const ARCHIVE_AD_PUBLISHER = "ARCHIVE_AD_PUBLISHER"
export const ARCHIVE_AD_PUBLISHER_SUCCESS = "ARCHIVE_AD_PUBLISHER_SUCCESS"

export const GET_AD_PUBLISHER_TYPE = "GET_AD_PUBLISHER_TYPE"
export const GET_AD_PUBLISHER_TYPE_SUCCESS = "GET_AD_PUBLISHER_TYPE_SUCCESS"
export const GET_AD_PUBLISHER_TYPE_FAIL = "GET_AD_PUBLISHER_TYPE_FAIL"

export const ADD_AD_PUBLISHER_TYPE = "ADD_AD_PUBLISHER_TYPE"
export const ADD_AD_PUBLISHER_TYPE_SUCCESS = "ADD_AD_PUBLISHER_TYPE_SUCCESS"
export const ADD_AD_PUBLISHER_TYPE_ERROR = "ADD_AD_PUBLISHER_TYPE_ERROR"