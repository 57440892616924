// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.creative-selections .card{
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    height: 12pc;
    width: 25pc;
  }
  
  .creative-selections .card .btn{
      position: absolute;
      bottom: 10%;
      left: 2%;
  }

.creative-selections {
    padding: 1% 5%;
}

  @media (min-width:1700px){
    .creative-selections {
        padding: 1% 15%;
    }
    .creative-selections .card{
        width: 30pc;
      }
  }`, "",{"version":3,"sources":["webpack://./src/pages/Demand/Creative/Creative.css"],"names":[],"mappings":"AAAA;IACI,iHAAiH;IACjH,YAAY;IACZ,WAAW;EACb;;EAEA;MACI,kBAAkB;MAClB,WAAW;MACX,QAAQ;EACZ;;AAEF;IACI,cAAc;AAClB;;EAEE;IACE;QACI,eAAe;IACnB;IACA;QACI,WAAW;MACb;EACJ","sourcesContent":[".creative-selections .card{\n    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;\n    height: 12pc;\n    width: 25pc;\n  }\n  \n  .creative-selections .card .btn{\n      position: absolute;\n      bottom: 10%;\n      left: 2%;\n  }\n\n.creative-selections {\n    padding: 1% 5%;\n}\n\n  @media (min-width:1700px){\n    .creative-selections {\n        padding: 1% 15%;\n    }\n    .creative-selections .card{\n        width: 30pc;\n      }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
