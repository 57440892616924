/* PARTNER */
export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES"
export const NO_DATA_LOGS = "NO_DATA_LOGS"

export const GET_LOGS = "GET_LOGS"
export const GET_LOGS_SUCCESS = "GET_LOGS_SUCCESS"
export const GET_LOGS_FAIL = "GET_LOGS_FAIL"

export const GET_LOGS_MASTER = "GET_LOGS_MASTER"
export const GET_LOGS_SUCCESS_MASTER = "GET_LOGS_SUCCESS_MASTER"
export const GET_LOGS_FAIL_MASTER = "GET_LOGS_FAIL_MASTER"