import {
    LOAD_ACTIVITIES,
    NO_DATA,
    GET_HEADERBIDS_SUCCESS,
    GET_HEADERBIDS_FAIL,
    ADD_HEADERBID_SUCCESS,
    ADD_HEADERBID_ERROR,
    UPDATE_HEADERBID_SUCCESS,
    UPDATE_HEADERBID_ERROR,
    UPDATE_ADUNITBID_SUCCESS,
    UPDATE_ADUNITBID_ERROR,
    READ_HEADERBID_SUCCESS,
    STATUS_HEADERBID_SUCCESS,
    ARCHIVE_HEADERBID_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    headerBids: [],
    headerBidsOld: [],
    pagination:{},
    paginationOld:{},
    error: {},
    loading: false,
    noData: false,
    successMsg: null,
    errorMsg: null,
    headerBid: null,
    response: null,
    archiveMsg: null,
}

const headerBids = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_ACTIVITIES:
            return {
                ...state,
                loading: true,
            }
        case NO_DATA:
            return {
                ...state,
                noData: true,
            }
        case GET_HEADERBIDS_SUCCESS:
            return {
                ...state,
                loading: false,
                headerBidsOld:action.payload.response ? action.payload.response  : [],
                paginationOld:action.payload ? action.payload : {},
                headerBids: action.payload.response ? action.payload.response.hbbuyers ? action.payload.response.hbbuyers : [] : [],
                pagination:action.payload.response ? action.payload.response : {}
            }

        case GET_HEADERBIDS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case ADD_HEADERBID_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_HEADERBID_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_HEADERBIDS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_HEADERBID_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_HEADERBIDS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_HEADERBID_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_HEADERBIDS_SUCCESS' ? null : action.payload,
            }
            case UPDATE_ADUNITBID_SUCCESS:
                return {
                    ...state,
                    successMsg: action.type === 'GET_HEADERBIDS_SUCCESS' ? null : action.payload,
                }
            case UPDATE_ADUNITBID_ERROR:
                return {
                    ...state,
                    error: action.payload,
                    errorMsg: action.type === 'GET_HEADERBIDS_SUCCESS' ? null : action.payload,
                }
        case READ_HEADERBID_SUCCESS:
            return {
                ...state,
                headerBid: action.payload,
            }
        case STATUS_HEADERBID_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_HEADERBID_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default headerBids