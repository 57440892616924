/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import {
  Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Spinner, NavItem, Collapse, Button,
  NavLink,
  TabContent,
  TabPane, InputGroup, Tooltip
} from "reactstrap";
import PropTypes from "prop-types";
import axios from "axios";
import Select, { StylesConfig } from 'react-select';
import classnames from "classnames"
import queryString from 'query-string';

import _ from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

import { TreeSelect } from 'antd';

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const ROW = ["AF", "AG", "AI", "AM", "AN", "AO", "AQ", "AR", "AS", "AW", "AZ", "BB", "BF", "BI", "BJ", "BL", "BM", "BN", "BO", "BR", "BS", "BT", "BV", "BW", "BZ", "CC", "CD", "CF", "CG", "CI", "CK", "CL", "CM", "CO", "CR", "CU", "CV", "CX", "DJ", "DM", "DO", "DZ", "EC", "EG", "EH", "ER", "ET", "FJ", "FK", "FM", "GA", "GD", "GE", "GF", "GH", "GL", "GM", "GN", "GP", "GQ", "GS", "GT", "GU", "GW", "GY", "HM", "HN", "HT", "IL", "IO", "IQ", "IR", "JM", "JO", "KG", "KI", "KM", "KN", "KP", "KR", "KY", "KZ", "LA", "LC", "LK", "LR", "LS", "LY", "MA", "MF", "MG", "MH", "ML", "MM", "MN", "MO", "MP", "MQ", "MR", "MS", "MU", "MV", "MW", "MX", "MZ", "NA", "NC", "NE", "NF", "NI", "NP", "NR", "NT", "NU", "PA", "PE", "PF", "PG", "PM", "PN", "PR", "PS", "PW", "PY", "RE", "RW", "SB", "SC", "SD", "SH", "SN", "SO", "SR", "ST", "SV", "SY", "SZ", "TC", "TD", "TF", "TG", "TJ", "TK", "TL", "TM", "TN", "TO", "TR", "TT", "TV", "TZ", "UG", "UM", "UY", "UZ", "VC", "VE", "VG", "VI", "VU", "WF", "WS", "YD", "YE", "YT", "ZM", "ZW"]
const APAC = ["BD", "CN", "HK", "ID", "IN", "JP", "KH", "MY", "PH", "PK", "SG", "SL", "TH", "TW", "VN"]
const Tier1 = ["AU", "CA", "NZ", "US"]
const GCC = ["AE", "BH", "KE", "KW", "LB", "NG", "OM", "QA", "SA", "ZA"]

let SizeData = [
  {
    "parentSize": "160x600",
    "childSizes": [
      "120x600"
    ]
  },
  {
    "parentSize": "300x600",
    "childSizes": [
      "120x600", "200x600", "160x600"
    ]
  },
  {
    "parentSize": "300x250",
    "childSizes": [
      "250x250"
    ]
  },
  {
    "parentSize": "320x50",
    "childSizes": [
      "300x50"
    ]
  },
  {
    "parentSize": "320x100",
    "childSizes": [
      "300x100", "300x50", "320x50"
    ]
  },
  {
    "parentSize": "320x442",
    "childSizes": []
  },
  {
    "parentSize": "320x480",
    "childSizes": []
  },
  {
    "parentSize": "336x280",
    "childSizes": []
  },
  {
    "parentSize": "468x100",
    "childSizes": []
  },
  {
    "parentSize": "728x90",
    "childSizes": [
      "468x60", "468x90"
    ]
  },
  {
    "parentSize": "800x485",
    "childSizes": []
  },
  {
    "parentSize": "800x600",
    "childSizes": []
  },
  {
    "parentSize": "970x90",
    "childSizes": [
      "728x90", "468x60", "468x90"
    ]
  },
  {
    "parentSize": "970x250",
    "childSizes": [
      "728x90", "468x60", "468x90", "970x90"
    ]
  },
]

const MobilewiseAssociate = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loader, setLoader] = useState(false);
  const [optimizationStrategyEnabled, setOptimizationStrategyEnabled] = useState(false);
  const [itemOpen, setItemOpen] = useState('')
  const [videosList, setVideosList] = useState([])
  const [originalParams, setOriginalParams] = useState([])
  const parsed = queryString.parse(window.location.search);
  const [adUnitSettings, setAdUnitSettings] = useState([{
    settingsEnabled: true,
    settingConfigMode: 'SimpleMode',
    settingInfo: originalParams
  }])
  const [value, setValue] = useState([])

  const [adUnitSettingsInfo, setAdUnitSettingsInfo] = useState({
    _size: '',
    size: '',
    timeout: 1500,
    floorPrice: {
      floorType: 'RON',
      floorValues: {
        'RON': {
          price: 0.01,
          enabled: true,
        }
      }
    },
    optimizationStrategyCall: false,
    optimizationStrategy: {
      type: 'floor',
      price: 0.03
    }
  })

  console.log(props)

  const handleChangeInfo = (e) => {
    const { name, value } = e.target;
    setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, [name]: value }));
    props.selectedMobileItems({ mobileSettings: { [name]: value } })
  }

  const optimizationCallState = (e) => {
    setOptimizationStrategyEnabled(e.target.checked)
    setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, optimizationStrategyCall: e.target.checked }));
    props.selectedMobileItems({ mobileSettings: { optimizationStrategyCall: e.target.checked } }) 
  }

  const [floorPriceValues, setFloorPriceValues] = useState({
    RON: 0.01,
    ROW: 0.01,
    GCC: 0.01,
    Tier1: 0.01,
    APAC: 0.01,
  })

  const changePostBidMeasurementType = (e) => {
    const { name, value } = e.target;
    setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, floorPrice: { ...adUnitSettingsInfo.floorPrice, [name]: value } }));
    props.selectedMobileItems({ mobileSettings: { floorPrice: { ...adUnitSettingsInfo.floorPrice, [name]: value } } }) 
    if (e.target.value === 'RON') {
      setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, ROW: 0.01, GCC: 0.01, Tier1: 0.01, APAC: 0.01 }));
      props.selectedMobileFloorValues({ floorPriceValues: { ...floorPriceValues, ROW: 0.01, GCC: 0.01, Tier1: 0.01, APAC: 0.01 } })
    } else if (e.target.value === 'GeoGroup') {
      setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, RON: 0.01 }));
      props.selectedMobileFloorValues({ floorPriceValues: { ...floorPriceValues, RON: 0.01 } }) 
    } else {
      setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, RON: 0.01, ROW: 0.01, GCC: 0.01, Tier1: 0.01, APAC: 0.01 }));
      props.selectedMobileFloorValues({ floorPriceValues: { ...floorPriceValues, RON: 0.01, ROW: 0.01, GCC: 0.01, Tier1: 0.01, APAC: 0.01 } })
    }
  }

  const floorPriceValueChange = (e) => {
    const { name, value } = e.target;
    console.log(floorPriceValues)
    setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, [name]: value }));
    props.selectedMobileFloorValues({ floorPriceValues: { ...floorPriceValues, [name]: value } })
  }

  const handleChangeStratergy = (e) => {
    setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, optimizationStrategy: { ...adUnitSettingsInfo.optimizationStrategy, price: 0.03, type: e.target.name } }));
    props.selectedMobileItems({ mobileSettings: { optimizationStrategy: { ...adUnitSettingsInfo.optimizationStrategy, price: 0.03, type: e.target.name } } }) 
  }

  const handleChangeStratergyPrice = (e) => {
    setAdUnitSettingsInfo(adUnitSettingsInfo => ({ ...adUnitSettingsInfo, optimizationStrategy: { ...adUnitSettingsInfo.optimizationStrategy, price: e.target.value } }));
    props.selectedMobileItems({ mobileSettings: { optimizationStrategy: { ...adUnitSettingsInfo.optimizationStrategy, price: e.target.value } } }) 
  }


  const openCollapsed = (panel) => (event, newExpanded) => {
    console.log(panel)
    setItemOpen(panel)
  };

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const body = {
    "url": '',
    "requestBody": {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name: userInfo.name,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner,
      hB_BuyerID: props.selectedBuyerID
    }
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 1000,
    extendedTimeOut: 1000
  };

  const addNewAdUnitSettings = () => {
    console.log(adUnitSettings)
    const newVcGroup = {
      settingsEnabled: true,
      settingConfigMode: 'SimpleMode',
      settingInfo: originalParams
    }
    setAdUnitSettings([...adUnitSettings, newVcGroup]);
    props.selectedMobileSettingsData({ settingsInfo: [...adUnitSettings, newVcGroup] })
  }


  useEffect(() => {
    console.log(props.selectedBidder)
    if (props.selectedBidder !== "") {
      const bodyForBidder = {
          userID: userInfo.userID,
          userRole: userInfo.userRole,
          companyID: userInfo.companyID,
          name: userInfo.name,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner,
          hB_BuyerID: props.selectedHBBuyer ? props.selectedHBBuyer.HB_BuyerID : props.selectedBuyerID
      }
      axios.post(userInfo.userRole === 'SupplyPartner' ? "/v2.0/headderBiddingSupplyPartner/HB_Buyers/read/getAllBidder/SupplyPartner" : "/v2.0/headderBidding/HB_Buyers/read/getAllBidder", bodyForBidder,
        {
          headers: headers
        })
        .then((res) => {
          console.log("result is headerBids ==>", res)
          if (res.data.statusCode === 200) {
            res.data.response.map((itemBid, index) => {
              if (props.selectedBidder === itemBid.bidderName) {
                if (itemBid.bidderJSON) {
                  itemBid.bidderJSON.map((ele, index) => {
                    ele.value = ""
                  })
                  let OriginalBidder = itemBid.bidderJSON
                  if (props.selectedHBAdUnit !== '' || props.selectedHBBuyer !== '') {
                    setIsEdit(true)
                    setVideosList([...itemBid.bidderJSON])
                    setOriginalParams([...itemBid.bidderJSON])
                    let videoContentList = [...adUnitSettings]
                    videoContentList[0] = { ...videoContentList[0], settingInfo: [...itemBid.bidderJSON] };
                    setAdUnitSettings([...videoContentList]);
                    let buyerID = props.selectedBuyerID ? props.selectedBuyerID : props.selectedHBBuyer.HB_BuyerID
                    const bodyforAdUnit = {
                        userID: userInfo.userID,
                        userRole: userInfo.userRole,
                        companyID: userInfo.companyID,
                        name: userInfo.name,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner,
                        adUnitID: props.selectedHBAdUnit ? props.selectedHBAdUnit.adUnitID : parsed.adUnitID
                    }
                    axios.post(userInfo.userRole === 'SupplyPartner' ? "/v2.0/headderBiddingSupplyPartner/HB_Buyers/getAssociateAdUnit/" +(props.selectedHBAdUnit ? props.selectedHBAdUnit.adUnitID : parsed.adUnitID) + "/buyer/SupplyPartner/" + buyerID : "/v2.0/headderBidding/HB_Buyers/getAssociateAdUnit/" +(props.selectedHBAdUnit ? props.selectedHBAdUnit.adUnitID : parsed.adUnitID) + "/buyer/" + buyerID, bodyforAdUnit,
                      {
                        headers: headers
                      })
                      .then((res) => {
                        console.log("result is notAssociated headerBids ==>", res)
                        if (res.data.response) {
                          console.log(res.data.response)
                          if (JSON.stringify(res.data.response.mobile) !== "{}") {
                            updateDataModal(res.data.response.mobile, OriginalBidder)
                          }
                        }
                      })
                      .catch((err) => {
                        console.log("e", err)
                      });
                  } else {
                    console.log(itemBid.bidderJSON)
                    setVideosList([...itemBid.bidderJSON])
                    setOriginalParams([...itemBid.bidderJSON])
                    let videoContentList = [...adUnitSettings]
                    videoContentList[0] = { ...videoContentList[0], settingInfo: [...itemBid.bidderJSON] };
                    setAdUnitSettings([...videoContentList]);
                    
                  }
                }
              }
            })
          }
        })
        .catch((err) => {
          console.log("e", err)
        });
    }
  }, [props.selectedBidder]);

  function updateDataModal(response, OriginalBidder) {
    setAdUnitSettingsInfo(response)
    props.selectedMobileItems({ mobileSettings: response }) 
    setOptimizationStrategyEnabled(response.optimizationStrategyCall ? response.optimizationStrategyCall : false)
    if(response.size.length > 0){
      setValue(objectModalEditSizes(response.size))
}
    if (response.floorPrice.floorType === 'RON') {
      setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, RON: response.floorPrice.floorValues.RON.price }));
      props.selectedMobileFloorValues({ floorPriceValues: { ...floorPriceValues, RON: response.floorPrice.floorValues.RON.price } })
    }
    else {
      if (response.floorPrice.floorValues.ROW.enabled) {
        setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, ROW: response.floorPrice.floorValues.ROW.price }));
        props.selectedMobileFloorValues({ floorPriceValues: { ...floorPriceValues, ROW: response.floorPrice.floorValues.ROW.price } })
      }
      if (response.floorPrice.floorValues.GCC.enabled) {
        setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, GCC: response.floorPrice.floorValues.GCC.price }));
        props.selectedMobileFloorValues({ floorPriceValues: { ...floorPriceValues, GCC: response.floorPrice.floorValues.GCC.price } })
      }
      if (response.floorPrice.floorValues.Tier1.enabled) {
        setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, Tier1: response.floorPrice.floorValues.Tier1.price }));
        props.selectedMobileFloorValues({ floorPriceValues: { ...floorPriceValues,  Tier1: response.floorPrice.floorValues.Tier1.price  } })
      }
      if (response.floorPrice.floorValues.APAC.enabled) {
        setFloorPriceValues(floorPriceValues => ({ ...floorPriceValues, APAC: response.floorPrice.floorValues.APAC.price }));
        props.selectedMobileFloorValues({ floorPriceValues: { ...floorPriceValues, APAC: response.floorPrice.floorValues.APAC.price } })
      }
    }
    if (response.adUnitSettings){
    if (response.adUnitSettings.length > 0) {
      let resultData = response.adUnitSettings
      response.adUnitSettings.map((item, i) => {
        let setsObjecttoArray = []
        if (item.settingInfo) {
          // setsObjecttoArray.push(item.settingInfo)
          setsObjecttoArray = Object.entries(item.settingInfo).reduce((acc, curr) => {
            const [key, val] = curr;
            acc.push({
              ParameterName: key,
              value: val,
              type: 'string',
              required: false,
            });
            return acc;
          }, []);
          console.log(setsObjecttoArray, OriginalBidder)
          const output = OriginalBidder.map(a => ({
            ...a,
            value: setsObjecttoArray.find(x => x.ParameterName === a.ParameterName ? x.value : "")
          }));
          console.log(output)
          let arr3 = setsObjecttoArray.concat(output.filter(({ ParameterName }) => !setsObjecttoArray.find(f => f.ParameterName == ParameterName)));
          console.log(arr3)
          setVideosList(([...arr3]))

          console.log(setsObjecttoArray)
          resultData[i].settingInfo = [...arr3]
        }
      })
      setAdUnitSettings(resultData);
      props.selectedMobileSettingsData({ settingsInfo: resultData })
    }else{
      setVideosList([...OriginalBidder])
      setOriginalParams([...OriginalBidder])
      let videoContentList = [...adUnitSettings]
      videoContentList[0] = { ...videoContentList[0], settingInfo: [...OriginalBidder] };
      setAdUnitSettings([...videoContentList]);
      props.selectedMobileSettingsData({ settingsInfo: videoContentList })
    }
  }
  }

  const objectModalEditSizes = (response) => {
    var dataList = []
    response.map((item, index) => {
      dataList.push(item.parentSize ? `${item.size}` + ',' +`${item.parentSize}` : item.size)
    })
    return dataList
  }

  const objectModalForCountries = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }

  const removePriorities = (index) => (e) => {
    console.log(index)
    const uPriorities = adUnitSettings.filter((gp, ind) => {
      return ind !== index
    }).map((gpm, p) => {
      return {
        ...gpm,
      }
    })
    setAdUnitSettings(uPriorities)
    props.selectedMobileSettingsData({ settingsInfo: uPriorities })
  }

  const handleChange = (vIndex, e) => {
    console.log("G", vIndex, e);
    const { name, value } = e.target;
    console.log("G", [name], value);
    const updatedVastTags = adUnitSettings.map((vcGroup, j) => {
      if (vIndex === j) {
        return {
          ...vcGroup, [name]: value
        }
      }
      return vcGroup;
    })
    setAdUnitSettings(updatedVastTags)
    props.selectedMobileSettingsData({ settingsInfo: updatedVastTags })
  }

  const handleChangeCheck = (vIndex, e) => {
    const { name, value } = e.target;
    const updatedVastTags = adUnitSettings.map((vcGroup, j) => {
      if (vIndex === j) {
        return {
          ...vcGroup, [name]: e.target.checked
        }
      }
      return vcGroup;
    })
    setAdUnitSettings(updatedVastTags)
    props.selectedMobileSettingsData({ settingsInfo: updatedVastTags })
  }

  const handleChangeMode = (index) => e => {
    const { name, value } = e.target;
    let videoContentList = [...adUnitSettings]
    console.log(videoContentList)
    videoContentList[index] = { ...videoContentList[index], settingConfigMode: name };
    console.log(videoContentList)
    setAdUnitSettings([...videoContentList]);
    props.selectedMobileSettingsData({ settingsInfo: [...videoContentList] })
  }

  const addDirectVideoContent = (index) => e => {
    console.log(index, adUnitSettings[index].settingInfo)
    let videoContentList = [...adUnitSettings[index].settingInfo]
    videoContentList.push({
      id: videoContentList.length + 1,
      ParameterName: '',
      value: '',
      type: 'string',
      required: false,
    })
    let newList = [...adUnitSettings]
    newList[index].settingInfo = [...videoContentList]
    console.log(newList)
    setAdUnitSettings([...newList]);
    props.selectedMobileSettingsData({ settingsInfo: [...newList] })
  }

  const handleChangeUser = (index, topIndex) => e => {
    const { name, value } = e.target;
    let videoContentList = [...adUnitSettings[topIndex].settingInfo]


    if (videoContentList[index].type === "integer") {
      videoContentList[index] = { ...videoContentList[index], [name]: Number(value) };
    } else if (videoContentList[index].type === "boolean") {
      videoContentList[index] = { ...videoContentList[index], [name]: JSON.parse(value) };
    } else {
      videoContentList[index] = { ...videoContentList[index], [name]: value };
    }


    let newList = [...adUnitSettings]
    newList[topIndex].settingInfo = [...videoContentList]
    console.log(newList)
    setAdUnitSettings([...newList]);
    props.selectedMobileSettingsData({ settingsInfo: [...newList] })
  }

  const removeDirectContent = (i, topIndex) => e => {
    let videoContentList = [...adUnitSettings[topIndex].settingInfo]
    videoContentList.splice(i, 1)
    let newList = [...adUnitSettings]
    newList[topIndex].settingInfo = [...videoContentList]
    console.log(newList)
    setAdUnitSettings([...newList]);
    props.selectedMobileSettingsData({ settingsInfo: [...newList] })
    // props.selectedItems({ hB_AdUnitsAssociatedInfo: [...newList] })
  }

  function allowOnlyNumbers(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code >= 48 && code < 58) && !(code >= 46 && code < 47)) { e.preventDefault(); }
  };

  const customStyles = {
    // For the select it self, not the options of the select
    control: (styles, { isDisabled }) => {
      return {
        ...styles,
        cursor: isDisabled ? 'not-allowed' : 'default',
        // This is an example: backgroundColor: isDisabled ? 'rgba(206, 217, 224, 0.5)' : 'white'
        color: isDisabled ? '#aaa' : 'white'
      }
    },
    // For the options
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        color: isDisabled ? '#000' : '#000',
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
  };

  const [adUnitData, setAdUnitData] = useState([])

  useEffect(() => {
    let trees = []
    SizeData.map((item, i) => {
      trees.push(createAdUnitsTree(item.parentSize, item.childSizes))
    })
    if (trees.length > 0) {
      setAdUnitData(trees)
    }
  }, []);

  const createAdUnitsTree = (groupName, options) => {
    return {
      label: groupName,
      value: groupName,
      title: groupName,
      children: objectModalAdUnits(options, groupName)
    };
  };

  const objectModalAdUnits = (response, parentId) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item +","+ parentId
      obj.label = item
      obj.title = item
      dataList.push(obj)
    })
    return dataList
  }

  const onChangeSize = (currentNode, selectedNodes, event) => {
    console.log(currentNode, selectedNodes, event)
    setValue(currentNode)
    let newArrSizes = []
    currentNode.map((itemBid, index) => {
      let valuesNode = itemBid.split(',')
      newArrSizes.push({size:valuesNode[0],parentSize:valuesNode.length > 1 ? valuesNode[1] : ""})
    })

      let separatedArray = selectedNodes
      let newArr = []
      separatedArray.map((itemBid, index) => {
        let valuesNode = itemBid.split('x')
        newArr.push(valuesNode.map(Number))
      })
      console.log(newArrSizes)
      props.selectedMobileItems({ mobileSettings: { _size: newArr,size:newArrSizes } }) 
  }

  return (
    <Row style={{ padding: '3%', width: '100%' }}>
      <Col xl={12} className="mb-4">
        <Row>
          <Col lg="12" className="p-2">
            <div className="content clearfix ">

              <div className="col-12">
                <div className="mb-3">
                  <Label className="form-label">Floor Price Type</Label>
                  <Input type="select" name="floorType"
                    onChange={changePostBidMeasurementType} value={adUnitSettingsInfo.floorPrice.floorType}
                    className={'form-control form-select'} >
                    <option value='RON'> RON </option>
                    <option value='GeoGroup'> Geo Group </option>
                  </Input>
                </div>
                <div className="mb-3">
                  {adUnitSettingsInfo.floorPrice.floorType === "RON" &&
                    <div className="row mb-3 m-1">
                      <div className="col-5 p-0"> <Label className="mt-2">RON Price </Label></div>
                      <div className="col-7 p-0">
                        <InputGroup>
                          <div className="input-group-text">$</div>
                          <Input
                            name="RON"
                            type="text"
                            onChange={floorPriceValueChange} onKeyPress={allowOnlyNumbers}
                            className={'form-control'}
                            value={floorPriceValues.RON}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  }

                  {adUnitSettingsInfo.floorPrice.floorType === "GeoGroup" &&
                    <div>
                      <div className="row mb-3 m-1">
                        <div className="col-2 p-0" > <Label className="mt-2">ROW </Label></div>
                        <div className="col-4 p-0" >
                          <Select
                            options={objectModalForCountries(ROW)}
                            classNamePrefix="select2-selection "
                            className="col-12"
                            isOptionDisabled={(option) => option === option}
                            styles={customStyles}
                            placeholder={"ROW Countries..."}
                          />
                        </div>
                        <div className="col-2 p-0">
                        </div>
                        <div className="col-4 p-0">
                          <InputGroup>
                            <div className="input-group-text">$</div>
                            <Input
                              name="ROW"
                              type="text" onKeyPress={allowOnlyNumbers}
                              onChange={floorPriceValueChange}
                              value={floorPriceValues.ROW}
                            />
                          </InputGroup>
                        </div>
                      </div>

                      <div className="row mb-3 m-1">
                        <div className="col-2 p-0"> <Label className="mt-2">GCC </Label></div>
                        <div className="col-4 p-0" >          <Select
                          options={objectModalForCountries(GCC)}
                          classNamePrefix="select2-selection "
                          className="col-12"
                          isOptionDisabled={(option) => option === option}
                          styles={customStyles}
                          placeholder={"GCC Countries..."}
                        /></div>
                        <div className="col-2 p-0">
                        </div>
                        <div className="col-4 p-0">
                          <InputGroup>
                            <div className="input-group-text">$</div>
                            <Input
                              name="GCC"
                              type="text" onKeyPress={allowOnlyNumbers}
                              onChange={floorPriceValueChange}
                              value={floorPriceValues.GCC}
                            />
                          </InputGroup>
                        </div>
                      </div>

                      <div className="row mb-3 m-1">
                        <div className="col-2 p-0"> <Label className="mt-2">Tier-1 </Label></div>
                        <div className="col-4 p-0" >           <Select
                          options={objectModalForCountries(Tier1)}
                          classNamePrefix="select2-selection "
                          className="col-12"
                          isOptionDisabled={(option) => option === option}
                          styles={customStyles}
                          placeholder={"Tier-1 Countries..."}
                        /></div>
                        <div className="col-2 p-0">
                        </div>
                        <div className="col-4 p-0">
                          <InputGroup>
                            <div className="input-group-text">$</div>
                            <Input
                              name="Tier1"
                              type="text" onKeyPress={allowOnlyNumbers}
                              onChange={floorPriceValueChange}
                              value={floorPriceValues.Tier1}
                            />
                          </InputGroup>
                        </div>
                      </div>

                      <div className="row mb-3 m-1">
                        <div className="col-2 p-0"> <Label className="ml-2 mt-2">APAC </Label></div>
                        <div className="col-4 p-0" >               <Select
                          options={objectModalForCountries(APAC)}
                          classNamePrefix="select2-selection "
                          className="col-12"
                          isOptionDisabled={(option) => option === option}
                          styles={customStyles}
                          placeholder={"APAC Countries..."}
                        /></div>
                        <div className="col-2 p-0">
                        </div>
                        <div className="col-4 p-0">
                          <InputGroup>
                            <div className="input-group-text">$</div>
                            <Input
                              name="APAC"
                              type="text" onKeyPress={allowOnlyNumbers}
                              onChange={floorPriceValueChange}
                              value={floorPriceValues.APAC}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </div>
                  }
                </div>
                <div className="mb-3">
                  <div className="form-check form-switch form-check-success form-switch-md  mt-1  " >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="optimizationStrategyEnabled"
                      name="optimizationStrategyEnabled"
                      checked={optimizationStrategyEnabled}
                      onChange={optimizationCallState}
                    />
                    <label className="form-check-label " htmlFor="optimizationStrategyEnabled">
                      {optimizationStrategyEnabled ? <span color='text-success' style={{ color: '#34c38f' }}> &nbsp;&nbsp;Optimization Stratergy  Enabled </span> : <span style={{ color: '#a3a3a3' }}> &nbsp;&nbsp;Optimization Stratergy  Disabled</span>}
                    </label>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="row">
                    <Col md={4}>
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="floor"
                          id="floor"
                          checked={adUnitSettingsInfo.optimizationStrategy.type === 'floor'}
                          onChange={handleChangeStratergy}
                        />
                        <label className="form-check-label" htmlFor="floor"> Optimization for Floor  </label>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="form-check mt-2 mb-2">
                        <input
                          className="form-check-input "
                          type="radio"
                          name="fill"
                          id="fill"
                          checked={adUnitSettingsInfo.optimizationStrategy.type === 'fill'}
                          onChange={handleChangeStratergy}
                        />
                        <label className="form-check-label" htmlFor="fill"> Optimization for Fill</label>
                      </div>
                    </Col>
                  </div>
                </div>
                <div className="mb-3">
                  <Label className="form-label">Optimization CPM</Label>
                  <InputGroup>
                    <div className="input-group-text">$</div>
                    <Input
                      name="price"
                      type="text"
                      onChange={handleChangeStratergyPrice} onKeyPress={allowOnlyNumbers}
                      className={'form-control'}
                      value={adUnitSettingsInfo.optimizationStrategy.price}
                      readOnly={adUnitSettingsInfo.optimizationStrategy.type === 'fill'}
                    />
                  </InputGroup>
                </div>
                <div className="mb-3">
                  <Label className="form-label">Size</Label>
                  <br></br>
                  <TreeSelect
                    showSearch
                    style={{ width: '100%' }}
                    value={value}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    placeholder="Please select"
                    allowClear
                    multiple
                    treeDefaultExpandAll
                    onChange={onChangeSize}
                    treeData={adUnitData}
                  />
                  {submitted && !adUnitSettingsInfo.size ? (
                    <FormFeedback type="invalid">{'Please Select Size'}</FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Timeout</Label>
                  <InputGroup>
                    <Input
                      name="timeout"
                      type="text"
                      onChange={handleChangeInfo}
                      className={'form-control'} onKeyPress={allowOnlyNumbers}
                      value={adUnitSettingsInfo.timeout}
                    />
                    <div className="input-group-text">ms</div>
                  </InputGroup>
                </div>
              </div>
              <div className="mt-4 mb-4">

                {adUnitSettings && adUnitSettings.map((item, index) => {
                  console.log("group............Item", item, "adUnitSettings==========", adUnitSettings);
                  return (
                    <div className="adUnitSettings-settings" key={index}>
                      <div className="accordion-item ml-0 mr-0 mb-4 mt-4">
                        <h2 className="accordion-header row m-0" id="headingOne">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium", { collapsed: itemOpen !== ('panel' + (index)) }
                            )}
                            type="button"
                            onClick={openCollapsed('panel' + (index))}
                            style={{ cursor: "pointer" }}
                          >
                            <h5 className="heading5 mt-2">Condition {index + 1}</h5>

                            {
                              adUnitSettings.length === 1 && <button className='btn  btn-danger disabled-rmg rem-grp-btn ' style={{ position: 'absolute', right: '3pc', margin: '1% 3% 1% auto', width: 'fit-content', background: '#dc3545' }} disabled>Remove Condition</button>
                            }
                            {
                              adUnitSettings.length !== 1 && <button className='btn btn-danger rem-grp-btn' style={{ position: 'absolute', right: '3pc', margin: '1% 3% 1% auto', width: 'fit-content', background: '#dc3545' }} onClick={removePriorities(index)}
                              >Remove Condition</button>
                            }
                          </button>

                        </h2>
                        {/* isOpen={itemOpen === ('panel'+(index))} */}
                        {/* <Collapse isOpen={true} className="accordion-collapse"> */}
                          <div className="accordion-body">
                            <div className="mb-1" style={{ position: 'absolute', right: '6.5pc' }}>
                              <div className="form-check form-switch form-check-success form-switch-md  mt-1  " >
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="settingsEnabled"
                                  name="settingsEnabled"
                                  checked={item.settingsEnabled}
                                  onChange={(e) => handleChangeCheck(index, e)}
                                />
                                <label className="form-check-label " htmlFor="settingsEnabled">
                                  {item.settingsEnabled ? <span color='text-success' style={{ color: '#34c38f' }}>Conditions Enabled </span> : <span style={{ color: '#a3a3a3' }}>Settings Disabled</span>}
                                </label>
                              </div>
                            </div>

                            <div className="mb-3">
                              <Label >Configuration </Label>
                              <div className="row">
                                <Col md={4}>
                                  <div className="form-check mt-2 mb-2">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="SimpleMode"
                                      id="SimpleMode"
                                      checked={item.settingConfigMode === 'SimpleMode'}
                                      onChange={handleChangeMode(index)}
                                    />
                                    <label className="form-check-label" htmlFor="SimpleMode"> Simple mode  </label>
                                  </div>
                                </Col>
                                <Col md={4}>
                                  <div className="form-check mt-2 mb-2">
                                    <input
                                      className="form-check-input "
                                      type="radio"
                                      name="RawMode"
                                      id="RawMode"
                                      checked={item.settingConfigMode === 'RawMode'}
                                      onChange={handleChangeMode(index)}
                                    />
                                    <label className="form-check-label" htmlFor="RawMode"> Raw mode</label>
                                  </div>
                                </Col>
                              </div>
                            </div>
                            {item.settingConfigMode === 'SimpleMode' &&
                              <div className=" mb-3">
                                {(Array.isArray(item.settingInfo)) && item.settingInfo.map((superSubItem, n) => {
                                  return (
                                    <div key={n}>
                                      <div className="contentSettings">
                                        {/* {i === 0 ? <h5 className='mt-3 mb-4'>Current User</h5> : <h5 className='mt-3 mb-4'>User {i}</h5>} */}
                                        <div className="row mt-1">
                                          <div className="col-4 ">
                                            <div className="mb-3">
                                              <Input
                                                name="ParameterName"
                                                type="text"
                                                onChange={handleChangeUser(n, index)}
                                                value={superSubItem.ParameterName}
                                                className={'form-control'}
                                                placeholder="Enter Key"
                                              />
                                            </div>
                                            <br />
                                          </div>
                                          <div className="col-7">
                                            <div className="mb-3">
                                              {superSubItem.type === 'integer' ?
                                                <Input
                                                  name="value"
                                                  type="text"
                                                  onChange={handleChangeUser(n, index)} onKeyPress={allowOnlyNumbers}
                                                  value={superSubItem.value}
                                                  className={'form-control'}
                                                  placeholder="Enter Value"
                                                /> : superSubItem.type === 'boolean' ?
                                                  <Input
                                                    name="value"
                                                    type="select"
                                                    onChange={handleChangeUser(n, index)}
                                                    value={superSubItem.value}
                                                    className={'form-control form-select'}
                                                  >
                                                    <option value="">Select  </option>
                                                    <option value={true}>True</option>
                                                    <option value={false}>False</option>
                                                  </Input> : <Input
                                                    name="value"
                                                    type="text"
                                                    onChange={handleChangeUser(n, index)}
                                                    value={superSubItem.value}
                                                    className={'form-control'}
                                                    placeholder="Enter Value"
                                                  />
                                              }
                                            </div>
                                            <br />
                                          </div>
                                          <div className="col-1 p-0">
                                            <button type='button' className="text-danger waves-effect btn btn-link" onClick={removeDirectContent(n, index)} style={{ position: 'absolute', right: '50%' }} >
                                              <i
                                                className="bx bx-trash font-size-18"
                                                id="edittooltip"
                                              ></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })
                                }
                                {/* {createContentUI()} */}
                                <div className='col-12'>
                                  <div className='d-flex'>
                                    <button type='button' className='mt-4 mb-4 btn btn-primary ml-auto' style={{ marginRight: '1rem  2.5rem 1rem auto' }} onClick={addDirectVideoContent(index)} >Add Parameter &nbsp;&nbsp; <i className="fa fa-plus"></i></button>

                                  </div>
                                </div>
                              </div>
                            }
                            {item.settingConfigMode === 'RawMode' &&
                              <div className="mb-3">
                                <Label className="form-label">JSON : </Label>
                                <Input
                                  name="settingInfo"
                                  type="textarea"
                                  rows="7"
                                  onChange={(e) => handleChange(index, e)}
                                  className={'form-control'}
                                  value={JSON.stringify(item.settingInfo)}
                                  readOnly
                                />
                              </div>
                            }
                          </div>
                        {/* </Collapse> */}
                      </div>
                    </div>
                  )
                }
                )}
                <div className='row'>
                  <Button
                    color="primary"
                    className="font-16 btn-block btn btn-primary"
                    style={{ margin: '1% 3.0rem 1% auto', width: 'auto' }}
                    onClick={addNewAdUnitSettings}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Add New Condition
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

MobilewiseAssociate.propTypes = {}

export default MobilewiseAssociate

