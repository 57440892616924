/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import moment from "moment";

import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Label,
    Spinner,
    Modal,InputGroup
} from "reactstrap"

import DateRangePickers from '../../helpers/DateRangePicker'
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';
import excelFile from '../../assets/files/AdsTxtFile.xlsx'
import PDFFile from '../../assets/files/AdsTxtEntries.pdf'
import axios from "axios";

// import images
import logodark from "assets/images/bidsxchange/logo-hd.png";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import LoadingTable from "pages/LoaderPages/LoadingTable";

import {
    getBillHistoryList as onGetBillHistoryList,
} from "store/Billing/actions";

var selectedIndex = ''

var filterCount = 0

const Invoice_Approval = props => {
    const dispatch = useDispatch();
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [invoiceData, setInvoiceData] = useState([])
    const [exportInvoiceData, setExportInvoiceData] = useState([])
    const [btnprimary1, setBtnprimary1] = useState(false)
    const [jsTagModalState, setJsTagModalState] = useState(false);
    const [totalInvoice, setTotalInvoice] = useState(0)
    const [months, setMonths] = useState([])
    const [partners, setPartners] = useState([])
    const [invoices, setInvoices] = useState([])
    const [deductionInvoices, setDeductionInvoices] = useState([{
        site: '',
        inventoryTypes: [],
        inventoryType: '',
        deducation: '',
        total: 0
    }
    ])
    const [monthSearch, setMonthSearch] = useState('')
    const [partnerSearch, setPartnerSearch] = useState('')
    const [inventoryTypeSearch, setInventoryTypeSearch] = useState('')
    const [statusSearch, setStatusSearch] = useState('')
    const [notesData, setNotesData] = useState({
        notes: 'Thanks for your business',
        terms: 'Publisher must raise Invoice from their own portal and not forward this invoice to us. Payments will be made with agreed Net term from date of receiving the invoice.'
    })
    const [partnerData, setPartnerData] = useState({
        partnerName: '',
        date: '',
        IsApproved: '',
        month: '',
        billID: ''
    })
    const [previousExtra, setPreviousExtra] = useState([])
    const [deductionReport, setDeductionReport] = useState(false);
    // const [selectedIndex, setSelectedIndex] = useState('');

    const [switchLoader, setSwitchLoader] = useState(true)
    const [loader, setLoader] = useState(false)

    const [filterState, setFilterState] = useState(false)


    const handleSwitch = () => {
        setSwitchLoader(!switchLoader)
    }

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 2000,
        timeOut: 5000,
        extendedTimeOut: 1000
    };

    const addNewInvoiceRow = (event) => {
        let arr = [...invoices]
        console.log(arr)
        arr.push({
            method: '+',
            rowAdded: 'new',
            date: "",
            inventoryType: "",
            billInventoryType: arr[0].billInventoryType,
            Extra: '',
            publisherRevenue: 0,
            accountManager: "",
            partner: "",
            invoiceID: 0,
            invoiceDate: "",
            publisherManager: "",
            approvedStatus: false
        })
        // let invoiceTotal = 0
        arr.map((item, index) => {
            item.id = index + 1
            // if(item.method === '-'){
            //     invoiceTotal = invoiceTotal - parseInt(item.publisherRevenue)
            // }else{
            //     invoiceTotal = invoiceTotal + parseInt(item.publisherRevenue)
            // }
        })
        // setTotalInvoice(invoiceTotal)
        console.log(arr)
        setInvoices(arr)
        console.log(invoices)
    }

    const removeInvoiceRow = (index) => (e) => {
        console.log(invoices)
        let arr = [...invoices]
        arr.splice(index, 1)
        setInvoices(arr)
    }

    const changeDatePick = (event) => {
        console.log(event)
        console.log("start: ", event.startDate._d);
        console.log("end: ", event.endDate._d);
        setFromDate(event.startDate._d.toISOString());
        setToDate(event.endDate._d.toISOString());
    }

    useEffect(() => {
        selectedIndex = ''
        // querySearch()

        let bodyMonth = {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                name: userInfo.name,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
        }
        axios.post("/v2.0/invoice/Invoices/History/Month", bodyMonth,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is Invoices ==>", res)
                if (res.data) {
                    setMonths(res.data.response)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });

        let bodyPartner = {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                name: userInfo.name,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
        }
        axios.post("/v2.0/invoice/Invoices/History/Partner", bodyPartner,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is Invoices ==>", res)
                if (res.data) {
                    setPartners(res.data.response)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }, []);

    const { billHistoryList } = useSelector(state => ({
        billHistoryList: state.billsHistory.billList
    }));

    const { loading } = useSelector(state => ({
        loading: state.billsHistory.loading,
    }));

    const { noData } = useSelector(state => ({
        noData: state.billsHistory.noData,
    }));

    useEffect(() => {
        if (billHistoryList !== null) {
            dispatch(onGetBillHistoryList({
                month: monthSearch,
                partner: partnerSearch,
                inventoryType: inventoryTypeSearch,
                approve: statusSearch,
                name: userInfo.name
            }));
        }
    }, []);

    useEffect(() => {
        console.log(billHistoryList)
        if (billHistoryList && billHistoryList.length) {
            console.log(billHistoryList)
            setInvoiceData([])
            setExportInvoiceData([])
            let invoiceList = []
            let exportList = []
            billHistoryList.map((item, index) => {
                console.log(item.Partners)
                item.Partners.map((ele, index) => {
                    ele.invoices.map((ar1, index) => {
                        ar1.billInventoryType = ele.partnerInventory ? ele.partnerInventory.split(',') : []
                    })
                    if (ele.invoices && ele.invoices.allData && ele.invoices.allData.extraCharge) {
                        if (ele.invoices.allData.extraCharge.onTotal && ele.invoices.allData.extraCharge.onTotal.length > 0) {
                            ele.invoices.allData.extraCharge.onTotal.map((data1, index) => {
                                data1.billInventoryType = ele.partnerInventory ? ele.partnerInventory.split(',') : []
                            })
                        }
                    }
                    console.log(ele.invoices)
                    let obj = Object()
                    obj.month = item.month
                    obj.date = item.date
                    obj.Partner = ele.Partner
                    obj.paymentStatus = ele.paymentStatus
                    obj.partnerInventory = ele.partnerInventory ? ele.partnerInventory : ''
                    obj.invoices = ele.invoices
                    obj.allData = ele
                    obj.billID = ele.iDInvoice
                    if (ele.invoices && ele.invoices.length > 0) {
                        let invoiceID = ''
                        let invoiceStatus = []
                        ele.invoices.map((item, index) => {
                            invoiceStatus.push(item.approvedStatus)
                            invoiceID = ele.invoices.length > 1 ? (invoiceID + ((item.inventoryType).slice(0, 1)).toUpperCase() + item.invoiceID + '/') : ((item.inventoryType).slice(0, 1)).toUpperCase() + item.invoiceID.toString()
                        })
                        let state = invoiceStatus.every(element => element === true);
                        let state2 = invoiceStatus.every(element => element === false);
                        if (!state && !state2) {
                            obj.IsApproved = 'partialApproved'
                        } else {
                            obj.IsApproved = ele.IsApproved
                        }
                        // console.log(obj.IsApproved, ele.Partner)
                        obj.invoiceID = '#INV-' + (invoiceID.slice(-1) === '/' ? invoiceID.slice(0, -1) + '' : invoiceID)

                    }

                    invoiceList.push(obj)
                })
                item.Partners.map((ele, index) => {
                    if (ele.invoices && ele.invoices.length > 0) {
                        ele.invoices.map((item1, index) => {
                            let obj = Object()
                            obj.month = item1.invoiceDate
                            obj.Partner = item1.partner
                            obj.site = (ele.Publisher && ele.Publisher.length) > 0 ? ele.Publisher[0].site ? ele.Publisher[0].site : '' : ''
                            obj.IsApproved = ele.IsApproved
                            obj.accountManager = item1.accountManager
                            obj.inventoryType = item1.inventoryType
                            obj.adjustment = ''
                            obj.operation = "+"
                            obj.publisherRevenue = Number(parseFloat(item1.publisherRevenue).toFixed(2))
                            exportList.push(obj)
                        })
                    }
                    if (ele.extraCharge && ele.extraCharge.onTotal && ele.extraCharge.onTotal.length > 0) {
                        ele.extraCharge.onTotal.map((item2, index) => {
                            let obj = Object()
                            obj.month = item.month
                            obj.Partner = ele.Partner
                            obj.site = (ele.Publisher && ele.Publisher.length) > 0 ? ele.Publisher[0].site ? ele.Publisher[0].site : '' : ''
                            obj.IsApproved = ele.IsApproved
                            obj.accountManager = item2.accountManager
                            obj.inventoryType = item2.inventoryType
                            obj.adjustment = item2.Extra
                            obj.operation = item2.extraMethod
                            obj.publisherRevenue = Number(parseFloat(item2.amount).toFixed(2))
                            exportList.push(obj)
                        })
                    }
                })
            })
            if (invoiceList.length > 0) {
                invoiceList.map((ele, index) => {
                    ele.id = index + 1
                })
                exportList.map((ele, index) => {
                    ele.id = index + 1
                })
                if (selectedIndex) {
                    invoiceList.map((ele, index) => {
                        if (ele.id === selectedIndex) {
                            console.log(ele.id, selectedIndex)
                            viewDeductInvoice(ele)
                        }
                    })
                }
                setInvoiceData(invoiceList)
                setExportInvoiceData(exportList)
                console.log(invoiceList, exportList)
            }
        }
    }, [billHistoryList]);

    const querySearch = (e) => {
        setInvoiceData([])
        setExportInvoiceData([])
        if (filterCount > 0) {
            setFilterState(true)
        } else {
            setFilterState(false)
        }
        setLoader(false)
        dispatch(onGetBillHistoryList({
            month: monthSearch,
            partner: partnerSearch,
            inventoryType: inventoryTypeSearch,
            approve: statusSearch,
            name: userInfo.name
        }));
    }

    const [selectedInvoiceID, setSelectedInvoiceID] = useState('')
    const viewInvoice = (data) => (e) => {
        console.log(data)
        selectedIndex = data.id
        if (data.invoices && data.invoices.length > 0) {
            let invoiceDataLists = []
            let invoiceID = ''
            data.invoices.map((ele, index) => {
                let obj = {}
                obj.inventoryType = ele.inventoryType
                obj.deducation = ''
                obj.total = Number(parseFloat(ele.publisherRevenue).toFixed(2))
                console.log(invoiceID)
                invoiceID = data.invoices.length > 1 ? (invoiceID + ((ele.inventoryType).slice(0, 1)).toUpperCase() + ele.invoiceID + '/') : ((ele.inventoryType).slice(0, 1)).toUpperCase() + ele.invoiceID.toString()
                invoiceDataLists.push(obj)
            })
            setSelectedInvoiceID(invoiceID.slice(-1) === '/' ? invoiceID.slice(0, -1) + '' : invoiceID)
            let invoiceDiductionLists = []
            console.log(data.allData)
            data.allData.Publisher.map((ele, index) => {
                let obj = {}
                obj.site = ele.site
                obj.inventoryTypes = ele.inventoryTypes
                obj.inventoryType = ele.inventoryTypes[0]
                obj.deducation = ''
                obj.total = 0
                invoiceDiductionLists.push(obj)
            })
            setDeductionInvoices(invoiceDiductionLists)
        }
        console.log(data.partnerInventory.split(','))
        data.invoices.map((ele, index) => {
            ele.method = '+'
            ele.rowAdded = 'old'
            ele.billInventoryType = data.partnerInventory ? data.partnerInventory.split(',') : []
        })

        let arr = [...data.invoices]

        if (data.allData.extraCharge) {
            if (data.allData.extraCharge.onTotal && data.allData.extraCharge.onTotal.length > 0) {
                data.allData.extraCharge.onTotal.map((ele, index) => {
                    ele.method = ele.extraMethod
                    ele.rowAdded = 'new'
                    ele.inventoryType = ele.inventoryType
                    ele.Extra = ele.Extra
                    ele.publisherRevenue = ele.amount ? Number(parseFloat(ele.amount).toFixed(2)) : 0
                    ele.billInventoryType = data.partnerInventory ? data.partnerInventory.split(',') : []
                })
                setPreviousExtra(data.allData.extraCharge.onTotal)
                arr = [...arr, ...data.allData.extraCharge.onTotal]
            }
        }
        arr.map((ele, index) => {
            ele.id = index + 1
            ele.publisherRevenue = Number(parseFloat(ele.publisherRevenue).toFixed(2))
        })
        console.log(arr)
        setPartnerData({
            partnerName: data.Partner,
            date: data.date,
            IsApproved: data.IsApproved,
            month: data.month,
            billID: data.billID
        })
        if (arr.length > 0) {
            let invoiceTotal = 0
            arr.map((item, index) => {
                if (item.method === '+') {
                    invoiceTotal = invoiceTotal + Number(parseFloat(item.publisherRevenue).toFixed(2))
                } else {
                    invoiceTotal = invoiceTotal - Number(parseFloat(item.publisherRevenue).toFixed(2))
                }
            })
            setTotalInvoice(Number(parseFloat(invoiceTotal).toFixed(2)))
        }
        setInvoices(arr)
        setJsTagModalState(true)
        console.log(arr)
    }

    const regenarate = (data) => (e) => {
        console.log(data)
        let bodyGenerate = {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                invoices: data.invoices,
                name: userInfo.name,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
        }
        console.log(bodyGenerate)
        axios.post("/v2.0/invoice/Invoices/History/reGenerate", bodyGenerate,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is Invoices Regenerate ==>", res)
                if (res.data) {
                    toastr.success('Invoice Regenerated Successfully...!')
                    querySearch()
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNotesData(notesData => ({ ...notesData, [name]: value }));
    }

    const handleChangeInvoice = (index) => (e) => {
        const { name, value } = e.target;
        let arr = [...invoices]
        arr[index][name] = value
        setInvoices(arr)
    }

    const handleChangeDeduction = (index) => (e) => {
        const { name, value } = e.target;
        let invoicearray = [...deductionInvoices]
        console.log(invoicearray[index], Number(value))
        let arr = [...deductionInvoices]
        // if(value !== ''){
        //     console.log(value)
        //     let conrt = Number(value)
        //     console.log(conrt)
        //     if( Number(value) > invoicearray[index].total ){
        //         arr[index][name] = 0
        //     }
        //     else{
        //         arr[index][name] = Number(value)
        //     }
        // }else{
        //     console.log(value)
        //     arr[index][name] = ''
        // }
        arr[index][name] = value
        console.log(arr)
        setDeductionInvoices(arr)
    }

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }





    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const invoiceDataListColumns = [
        {
            text: "ID",
            headerClasses: "ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            dataField: 'billID',
            text: 'ID',
            sort: true
        },
        {
            dataField: "month",
            text: "Month",
            sort: true,
        },

        {
            dataField: "Partner",
            text: "Partner",
            sort: false,
        },
        {
            dataField: "partnerInventory",
            text: "Inventory Type",
            sort: false,
        },
        {
            dataField: "IsApproved",
            text: "Status",
            sort: true,
            formatter: (cellContent, invoiceData) => (
                <>
                    {invoiceData.IsApproved === 'approved' &&
                        <span className="active-state">Approved</span>
                    }
                    {invoiceData.IsApproved === 'approvalPending' &&
                        <span className="inactive-state">Approval Pending</span>
                    }
                    {invoiceData.IsApproved === 'partialApproved' &&
                        <span className="support-ticket-urgent">Partial Approved</span>
                    }
                </>
            ),
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',

            // eslint-disable-next-line react/display-name
            formatter: (cellContent, invoiceData) => (
                <div className="d-flex gap-3" style={{ marginLeft: '0' }}>
                    <Link className="bordered" to="#" onClick={viewInvoice(invoiceData)} style={{ border: '1px solid', padding: '2px 5px', borderRadius: '5px', color: 'black' }}>
                        View
                    </Link>
                    <Link className="bordered" to="#" onClick={regenarate(invoiceData)} style={{ border: '1px solid', padding: '2px 5px', borderRadius: '5px', color: 'black' }}>
                        Regenerate
                    </Link>
                </div>
            ),
        },
    ];

    function priceFormatter(column, colIndex) {
        return (
            <div className="row" style={{ display: 'block' }}>
                <span>{column.text}</span> &nbsp;&nbsp;
                <span onClick={addNewInvoiceRow} style={{ cursor: 'pointer' }}>
                    (<i
                        className="mdi mdi-plus font-size-18"
                        id="edittooltip"
                    ></i>)
                </span> &nbsp;&nbsp;
                {/* <span  onClick={removeInvoiceRow} style={{ cursor:'pointer'}}>
                        ( <i
                      className="mdi mdi-minus font-size-18"
                      id="edittooltip"
                    ></i>)
                    </span> */}
            </div>

        );
    }

    const invoiceListColumns = [
        {
            text: "ID",
            headerClasses: "ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        // {
        //     dataField: "accountManager",
        //     text: "Account Manager",
        //     sort: false,
        //     footer: 'Total Amount',
        //     style: { wordBreak: 'break-word' },
        //     editable: (content, row, rowIndex, columnIndex) => row.rowAdded !== 'old'
        // },
        {
            dataField: "inventoryType",
            text: "Description",
            sort: false,
            footer: 'Total Amount',
            style: { wordBreak: 'break-word' },
            // headerFormatter: priceFormatter,
            editable: (content, row, rowIndex, columnIndex) => row.rowAdded !== 'old'
        },
        {
            dataField: 'method',
            text: 'Method',
            footer: '',
            editable: (content, row, rowIndex, columnIndex) => row.rowAdded !== 'old',
            editor: {
                type: Type.SELECT,
                options: [{
                    value: '+',
                    label: '+'
                }, {
                    value: '-',
                    label: '-'
                }]
            }
        },
        {
            dataField: "publisherRevenue",
            text: "Amount",
            sort: false,
            footer: `${totalInvoice}`,
            editable: (content, row, rowIndex, columnIndex) => row.rowAdded !== 'old',
            validator: (newValue, row, column) => {
                console.log(newValue, row, column, invoices)
                if (isNaN(newValue)) {
                    return {
                        valid: false,
                        message: 'Price should be numeric'
                    };
                } else {
                    return true;
                }
            }
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "",
            align: 'center',

            // eslint-disable-next-line react/display-name
            formatter: (cellContent, invoiceData, rowIndex) => (
                <div className="d-flex gap-3" style={{ marginLeft: '0' }}>
                    <button className="text-secondary" onClick={removeInvoiceRow} >
                        <i
                            className="mdi mdi-delete font-size-18"
                            id="edittooltip"
                        ></i>
                    </button>
                </div>
            ),
        },
    ];

    // useEffect(() => {
    //     if (invoices.length > 0) {
    //         let invoiceTotal = 0
    //         invoices.map((item, index) => {
    //             if (item.method === '+') {
    //                 invoiceTotal = invoiceTotal + item.publisherRevenue
    //             } else {
    //                 invoiceTotal = invoiceTotal - item.publisherRevenue
    //             }
    //         })
    //         setTotalInvoice(invoiceTotal)
    //     }
    // }, [invoices]);

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );


    const pageOptions = {
        paginationSize: 4,
        pageStartIndex: 1,
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };

    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const invoiceRecheck = (e) => {
        let arr = [...invoices]
        let arrPrev = [...previousExtra]
        var hasDuplicate = false;
        var hasEmpty = false;
        // arr.map(v => v.inventoryType).sort().sort((a, b) => {
        //     if (a === b) {
        //         hasDuplicate = true
        //     }
        // })
        arr.map(v => {
            console.log(v.inventoryType)
            if (v.inventoryType === '') {
                hasEmpty = true
            }
        })

        if (hasEmpty) {
            toastr.error('Discription must not be empty')
            return false
        } else if (hasDuplicate) {
            toastr.error('Discription already exist, please change Discription')
            return false
        } else {
            setLoader(true)
            console.log(deductionInvoices)
            // if (Number(item.deducation) > item.total) {
            //     console.log(Number(item.deducation),item.total)
            //     toastr.error(`Deduction amount must be lesser than ${item.inventoryType} Amount`)
            //     return false
            // } else {
            let invoiceTotal = 0
            console.log(invoices)
            invoices.map((item, index) => {
                if (item.method === '+') {
                    invoiceTotal = invoiceTotal + Number(parseFloat(item.publisherRevenue).toFixed(2))
                } else {
                    invoiceTotal = invoiceTotal - Number(parseFloat(item.publisherRevenue).toFixed(2))
                }
            })
            setTotalInvoice(Number(parseFloat(invoiceTotal).toFixed(2)))
            console.log(invoiceTotal)


            console.log(arr, arrPrev)
            let extraCharge = []
            arr.map((ele, index) => {
                let obj = Object()
                if (ele.rowAdded !== 'old') {
                    obj.billInventoryType = ele.billInventoryType
                    obj.inventoryType = ele.inventoryType
                    obj.Extra = ele.Extra
                    obj.amount = Number(parseFloat(ele.publisherRevenue).toFixed(2))
                    obj.extraMethod = ele.method
                    extraCharge.push(obj)
                }
            })
            // arrPrev.map((ele, index) => {
            //     let obj = Object()
            //     obj.Extra = ele.inventoryType
            //     obj.amount = Number(parseFloat(ele.publisherRevenue).toFixed(2))
            //     obj.extraMethod = ele.method
            //     extraCharge.push(obj)
            // })
            console.log(extraCharge)
            console.log(totalInvoice)
            let bodyRecheck = {
                    userID: userInfo.userID,
                    userRole: userInfo.userRole,
                    companyID: userInfo.companyID,
                    name: userInfo.name,
                    date: partnerData.date,
                    Partner: partnerData.partnerName,
                    invoiceNote: notesData.notes,
                    termsAndCondition: notesData.terms,
                    iDInvoice: "",
                    extraCharge: { onTotal: extraCharge, onReport: deductionInvoices },
                    totalAmount: totalInvoice,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
                }
            
            console.log(bodyRecheck)
            axios.post("/v2.0/invoice/Invoices/Payment/update", bodyRecheck,
                {
                    headers: headers
                })
                .then((res) => {
                    console.log("result is Invoices Recheck ==>", res)
                    if (res.data) {
                        querySearch()
                        setDeductionReport(false)
                    }
                })
                .catch((err) => {
                    console.log("e", err)
                });
            // }
        }
    }

    const invoiceApprove = (inventory) => (e) => {
        setLoader(true)
        let bodyApprove = {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                name: userInfo.name,
                date: partnerData.date,
                Partner: partnerData.partnerName,
                inventoryType: inventory,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
            }
        
        console.log(bodyApprove)

        axios.post("/v2.0/invoice/Invoices/Approve", bodyApprove,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is Invoices Approve ==>", res)
                if (res.data) {
                    // setPartners(res.data.response)
                    // selectedIndex = ''
                    toastr.success('Invoice Approved Successfully...!')
                    querySearch()
                    // closeReportModal()
                    // setJsTagModalState(false)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }

    const viewDeductInvoice = (data) => {
        console.log(data)
        selectedIndex = data.id
        if (data.invoices && data.invoices.length > 0) {
            let invoiceDiductionLists = []
            data.allData.Publisher.map((ele, index) => {
                let obj = {}
                obj.site = ele.site
                obj.inventoryTypes = ele.inventoryTypes
                obj.inventoryType = ele.inventoryTypes[0]
                obj.deducation = ''
                obj.total = 0
                invoiceDiductionLists.push(obj)
            })
            setDeductionInvoices(invoiceDiductionLists)
        }
        data.invoices.map((ele, index) => {
            ele.method = '+'
            ele.rowAdded = 'old'
        })
        let arr = [...data.invoices]

        if (data.allData.extraCharge) {
            if (data.allData.extraCharge.onTotal && data.allData.extraCharge.onTotal.length > 0) {
                data.allData.extraCharge.onTotal.map((ele, index) => {
                    ele.method = ele.extraMethod
                    ele.rowAdded = 'new'
                    ele.inventoryType = ele.inventoryType
                    ele.Extra = ele.Extra
                    ele.publisherRevenue = ele.amount ? Number(parseFloat(ele.amount).toFixed(2)) : 0
                })
                setPreviousExtra(data.allData.extraCharge.onTotal)
                arr = [...arr, ...data.allData.extraCharge.onTotal]
            }
        }
        arr.map((ele, index) => {
            ele.id = index + 1
            ele.publisherRevenue = Number(parseFloat(ele.publisherRevenue).toFixed(2))
        })
        setPartnerData({
            partnerName: data.Partner,
            date: data.date,
            IsApproved: data.IsApproved,
            month: data.month,
            billID: data.billID
        })
        if (arr.length > 0) {
            let invoiceTotal = 0
            arr.map((item, index) => {
                if (item.method === '+') {
                    invoiceTotal = invoiceTotal + Number(parseFloat(item.publisherRevenue).toFixed(2))
                } else {
                    invoiceTotal = invoiceTotal - Number(parseFloat(item.publisherRevenue).toFixed(2))
                }
            })
            setTotalInvoice(Number(parseFloat(invoiceTotal).toFixed(2)))
        }
        setInvoices(arr)
        setJsTagModalState(true)
    }

    useEffect(() => {
        console.log(loading)
        if (loading) {
            setSwitchLoader(true)
        }
    }, [loading]);

    const closeReportModal = (e) => {
        setJsTagModalState(false);
        setDeductionReport(false);
        selectedIndex = ''
        setTotalInvoice(0)
        setInvoices([])
        setPreviousExtra([])
    }

    function allowOnlyNumbers(e) {
        var code = ('charCode' in e) ? e.charCode : e.keyCode;
        if (!(code >= 48 && code < 58) && !(code >= 46 && code < 47)) { e.preventDefault(); }
    };

    var CSVProp
    const CSVClick = () => {
        CSVProp.onExport();
    }

    const MyExportCSV = (csvprops) => {
        CSVProp = csvprops
        return (<></>);
    };

    const ExportColumns = [{
        dataField: 'month',
        text: 'Month'
    }, {
        dataField: 'Partner',
        text: 'Partner'
    },
    {
        dataField: 'site',
        text: 'Site'
    },
    {
        dataField: 'accountManager',
        text: 'Account Manager'
    },
    {
        dataField: 'inventoryType',
        text: 'Inventory Type'
    },
    {
        dataField: 'adjustment',
        text: 'Adjustment'
    },
    {
        dataField: 'operation',
        text: 'Operation'
    },
    {
        dataField: 'publisherRevenue',
        text: 'Revenue'
    },
    {
        dataField: 'IsApproved',
        text: 'Status'
    },

    ];

    var currentTimeStamp = Date.now()

    const [isMenu, setisMenu] = useState(false)
    const toggleMenu = () => {
        setisMenu(!isMenu)
    }

    const clearAllFilter = (e) => {
        setStatusSearch('')
        setMonthSearch('')
        setInventoryTypeSearch('')
        setPartnerSearch('')
        filterCount = 0
        setFilterState(false)
        setInvoiceData([])
        setExportInvoiceData([])
        dispatch(onGetBillHistoryList({
            month: '',
            partner: '',
            inventoryType: '',
            approve: '',
            name: userInfo.name
        }));
    }

    console.log(invoices)


    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
            <div className="page-content">
                <Helmet>
                    <title>Invoice Approval | Bidsxchange</title>
                </Helmet>
                <Container fluid={true}>
                    <div className=" d-sm-flex  justify-content-between">
                        <Breadcrumbs title="Billing" breadcrumbItem="Invoice Approval" breadList="All Invoice Approvals" />

                        <div className="text-sm-end">
                            <div className="btn-group">
                                <Dropdown
                                    direction="left"
                                    isOpen={isMenu}
                                    toggle={toggleMenu}
                                >
                                    <DropdownToggle
                                        tag="button"
                                        className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                                    >
                                        <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                                        {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                                            Filter Applied
                                        </span> : <span className="d-none font-16 d-sm-inline-block">
                                            Filter
                                        </span>
                                        }
                                        {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px',color:'red' }}>{filterCount}</span>
                              } */}
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                                        <div className="dropdown-item-text">
                                            <div className="row">
                                                <h5 className="mb-0">Filters</h5>
                                            </div>
                                        </div>

                                        <DropdownItem divider />
                                        <Card>
                                            <CardBody aria-hidden="true">
                                                <Row >
                                                    <Col sm="12" className="mt-2">
                                                        <Label className="form-label">Month</Label>
                                                        <Input type="select" className="form-select" value={monthSearch} onChange={(e) => {
                                                            if (e.target.value === '') {
                                                                filterCount = filterCount > 0 ? filterCount - 1 : 0
                                                            }
                                                            if (monthSearch === '') {
                                                                filterCount = filterCount + 1
                                                            }
                                                            setMonthSearch(e.target.value);
                                                        }
                                                        }>
                                                            <option value=''> All </option>
                                                            {months.map((item) => (
                                                                <option key={item} value={item}>{item}</option>
                                                            ))}
                                                        </Input>
                                                    </Col>
                                                    <Col sm="12" className="mt-2">
                                                        <Label className="form-label">Partners</Label>
                                                        <Input type="select" className="form-select" value={partnerSearch} onChange={(e) => {
                                                            if (e.target.value === '') {
                                                                filterCount = filterCount > 0 ? filterCount - 1 : 0
                                                            }
                                                            if (partnerSearch === '') {
                                                                filterCount = filterCount + 1
                                                            }
                                                            setPartnerSearch(e.target.value)
                                                        }}>
                                                            <option value=''> All </option>
                                                            {partners.map((item) => (
                                                                <option key={item} value={item}>{item}</option>
                                                            ))}
                                                        </Input>
                                                    </Col>
                                                    <Col sm="12" className="mt-2">
                                                        <Label className="form-label">Inventory Type</Label>
                                                        <Input type="select" className="form-select" value={inventoryTypeSearch} onChange={(e) => {
                                                            if (e.target.value === '') {
                                                                filterCount = filterCount > 0 ? filterCount - 1 : 0
                                                            }
                                                            if (inventoryTypeSearch === '') {
                                                                filterCount = filterCount + 1
                                                            }
                                                            setInventoryTypeSearch(e.target.value)
                                                        }}>
                                                            <option value=''> All </option>
                                                            <option value='standardBanner' > Standard Banner</option>
                                                            <option value='highImpact' > highImpact</option>
                                                            <option value='video' > Video</option>
                                                            <option value='video_CTV' > Video CTV</option>
                                                        </Input>
                                                    </Col>
                                                    <Col sm="12" className="mt-2">
                                                        <Label className="form-label">Status</Label>
                                                        <Input type="select" className="form-select" value={statusSearch} onChange={(e) => {
                                                            if (e.target.value === '') {
                                                                filterCount = filterCount > 0 ? filterCount - 1 : 0
                                                            }
                                                            if (statusSearch === '') {
                                                                filterCount = filterCount + 1
                                                            }
                                                            setStatusSearch(e.target.value)
                                                        }}>
                                                            <option value=''> All </option>
                                                            <option value='approved' > Approved</option>
                                                            <option value='pending'>Approval pending</option>
                                                        </Input>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                        <DropdownItem divider />
                                        <Row>
                                            <Col sm="6">
                                                <DropdownItem
                                                    className="text-primary text-center"
                                                    onClick={clearAllFilter}
                                                >
                                                    Clear ALL
                                                    {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px' }}>{filterCount}</span>
                              } */}
                                                </DropdownItem>
                                            </Col>
                                            <Col sm="6">
                                                <DropdownItem
                                                    className="text-primary text-center"
                                                    onClick={querySearch}
                                                >
                                                    Apply Filter
                                                    {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px' }}>{filterCount}</span>
                              } */}
                                                </DropdownItem>
                                            </Col>
                                        </Row>

                                    </DropdownMenu>
                                </Dropdown>
                            </div> &nbsp;&nbsp;
                            <div className="btn-group">
                                <Dropdown
                                    isOpen={btnprimary1}
                                    toggle={() => setBtnprimary1(!btnprimary1)}
                                >
                                    <DropdownToggle tag="button" className="btn btn-primary">
                                        Export
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {/* <DropdownItem onClick={copyTable}>Copy</DropdownItem> */}
                                        <DropdownItem onClick={CSVClick}>CSV</DropdownItem>
                                        {/* <DropdownItem onClick={exportPDF}>PDF</DropdownItem> */}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>

                        </div>
                    </div>
                    {/* <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <Row className="mb-2">
                                        <Col sm="2">
                                            <Label className="form-label">Month</Label>
                                            <Input type="select" className="form-select" value={monthSearch} onChange={(e) => setMonthSearch(e.target.value)}>
                                                <option value=''> All </option>
                                                {months.map((item) => (
                                                    <option key={item} value={item}>{item}</option>
                                                ))}
                                            </Input>
                                        </Col>
                                        <Col sm="2">
                                            <Label className="form-label">Partners</Label>
                                            <Input type="select" className="form-select" value={partnerSearch} onChange={(e) => setPartnerSearch(e.target.value)}>
                                                <option value=''> All </option>
                                                {partners.map((item) => (
                                                    <option key={item} value={item}>{item}</option>
                                                ))}
                                            </Input>
                                        </Col>
                                        <Col sm="2">
                                            <Label className="form-label">Inventory Type</Label>
                                            <Input type="select" className="form-select" value={inventoryTypeSearch} onChange={(e) => setInventoryTypeSearch(e.target.value)}>
                                                <option value=''> All </option>
                                                <option value='standardBanner' > Standard Banner</option>
                                                <option value='highImpact' > highImpact</option>
                                                <option value='video' > Video</option>

                                            </Input>
                                        </Col>
                                        <Col sm="2">
                                            <Label className="form-label">Status</Label>
                                            <Input type="select" className="form-select" value={statusSearch} onChange={(e) => setStatusSearch(e.target.value)}>
                                                <option value=''> All </option>
                                                <option value='approved' > Approved</option>
                                                <option value='pending'>Approval pending</option>
                                            </Input>
                                        </Col>
                                        <Col sm="4">
                                            <div className=" text-sm-end">
                                                <Button
                                                    color="primary"
                                                    className="font-16 btn-block btn btn-primary"
                                                    onClick={querySearch}
                                                >
                                                    Query
                                                </Button>
                                                &nbsp;&nbsp;&nbsp;
                                                <div className="btn-group">
                                                    <Dropdown
                                                        isOpen={btnprimary1}
                                                        toggle={() => setBtnprimary1(!btnprimary1)}
                                                    >
                                                        <DropdownToggle tag="button" className="btn btn-primary">
                                                            Export
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem onClick={CSVClick}>CSV</DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row> */}
                    {switchLoader &&
                        <LoadingTable loading={loading} targetLoading={handleSwitch} />
                    }
                    {!switchLoader &&
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <ToolkitProvider
                                            keyField={keyField}
                                            data={invoiceData}
                                            columns={invoiceDataListColumns}
                                            // bootstrap4
                                            search
                                        >
                                            {toolkitProps => (
                                                <React.Fragment>
                                                    <Row className="mb-2">
                                                        <Col sm="4">
                                                            <div className="search-box ms-2 mb-2 d-inline-block">
                                                                <div className="position-relative">
                                                                    <SearchBar {...toolkitProps.searchProps} />
                                                                    <i className="bx bx-search-alt search-icon" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col sm="2"></Col>
                                                        <Col sm="6">
                                                            {/* <div className=" text-sm-end">
                                                                            <DateRangePickers datePick={changeDatePick} />
                                                                        </div> */}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl="12" className="table-responsive">
                                                            <div >
                                                                <BootstrapTable
                                                                    keyField={keyField}
                                                                    responsive
                                                                    bordered={false}
                                                                    striped={false}
                                                                    defaultSorted={defaultSorted}
                                                                    classes={
                                                                        "table align-middle table-nowrap"
                                                                    }
                                                                    headerWrapperClasses={"thead-light"}
                                                                    {...toolkitProps.baseProps}
                                                                    pagination={paginationFactory(pageOptions)}
                                                                    noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                // ref={node}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                            )}
                                        </ToolkitProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </Container>
            </div>
            {/* </div> */}
            <Modal
                size="xl"
                isOpen={jsTagModalState}
            >
                {(loader || loading) &&
                    <>
                        <Spinner className="ms-2 loader" color="primary" />
                    </>
                }
                <div className="modal-header">
                    <h3 className="modal-title mt-0" id="myModalLabel">
                        Invoice
                        <p style={{ fontSize: '14px', color: 'grey', margin: '0' }}>
                            {partnerData.billID}
                        </p>
                    </h3>
                    <button
                        type="button"
                        onClick={closeReportModal}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        disabled={loader || loading}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{ maxHeight: '70vh', overflow: 'auto' }}>
                    <div className="row mb-4">
                        <h4>
                            <img
                                src={logodark}
                                alt=""
                                height="50"
                                className="auth-logo-dark"
                            />
                        </h4>
                        <span> Bill To</span>
                        <h5>Streamlyn Semisoft Private Limited</h5>
                        <span style={{ width: '15pc' }}>
                            No.34, AVS Compound, 80FT Road, 4th Block, Koramangala, Bangalore, India, 560034
                        </span>
                    </div>

                    <br />
                    <div className="row mt-2">
                        <div className="col-md-8">
                            Bill From
                            <h6><b>{partnerData.partnerName}</b></h6>
                        </div>
                        <div className="col-md-4">
                            <div className="text-sm-end mt-2">
                                <span>Invoice Date : &nbsp;&nbsp;&nbsp; <b>{partnerData.month}</b></span> <br />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="react-bootstrap-table">
                            <table className="table table-bordered">
                                <thead><tr>
                                    <th >Items</th>
                                    <th >Description</th>
                                    <th >Method</th>
                                    <th >Amount (USD)</th>
                                    <th >Action</th>
                                    <th ></th>
                                </tr>
                                </thead>
                                <tbody>
                                    {invoices.map((item, index) => (
                                        <tr key={index}>
                                            <td style={{ wordBreak: 'break-word' }}>
                                                {item.rowAdded === 'old' ?
                                                    <Input
                                                        name="inventoryType"
                                                        type="text"
                                                        value={item.inventoryType === 'video_CTV' ? 'Video CTV' : item.inventoryType === 'video' ? 'Video' : item.inventoryType === 'highImpact' ? 'High Impact' : item.inventoryType === 'standardBanner' ? 'Standard Banner' : item.inventoryType}
                                                        onChange={handleChangeInvoice(index)}
                                                        readOnly={item.rowAdded === 'old'}
                                                    /> :
                                                    <Input type="select" name="inventoryType" className="form-select"
                                                        value={item.inventoryType}
                                                        onChange={handleChangeInvoice(index)}>
                                                        <option value=''> Select </option>
                                                        {item.billInventoryType && item.billInventoryType.length > 0 && item.billInventoryType.map((ele) => (
                                                            <option key={ele} value={ele}>{ele}</option>
                                                        ))}
                                                    </Input>
                                                }

                                            </td>
                                            <td style={{ wordBreak: 'break-word' }}>
                                                {item.rowAdded === 'old' ?
                                                    <Input
                                                        name="inventoryType"
                                                        type="text"
                                                        value={item.inventoryType === 'video_CTV' ? 'Video CTV Advertising Revenue' : item.inventoryType === 'video' ? 'Video Advertising Revenue' : item.inventoryType === 'highImpact' ? 'High Impact Advertising Revenue' : item.inventoryType === 'standardBanner' ? 'Standard Banner Advertising Revenue' : item.inventoryType}
                                                        onChange={handleChangeInvoice(index)}
                                                        readOnly={item.rowAdded === 'old'}
                                                    /> :
                                                    <Input
                                                        name="Extra"
                                                        type="text"
                                                        value={item.Extra}
                                                        onChange={handleChangeInvoice(index)}
                                                        style={{ display: item.rowAdded === 'new' ? 'block' : 'none' }}
                                                    />}
                                            </td>
                                            <td>
                                                <Input
                                                    name="method" type="select" className="form-select" value={item.method}
                                                    onChange={handleChangeInvoice(index)} disabled={item.rowAdded === 'old'}> <option value='+'>+</option>
                                                    <option value='-'>-</option></Input></td>
                                            <td>
                                                <InputGroup>
                                                    <div className="input-group-text">$</div>
                                                    <Input
                                                        name="publisherRevenue"
                                                        type="text"
                                                        value={item.publisherRevenue}
                                                        onChange={handleChangeInvoice(index)}
                                                        readOnly={item.rowAdded === 'old'}
                                                    />
                                                </InputGroup>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                {(item.inventoryType === 'video' || item.inventoryType === 'highImpact' || item.inventoryType === 'standardBanner' || item.inventoryType === 'video_CTV') && (item.rowAdded === 'old') ?
                                                    <Button
                                                        color="primary"
                                                        className="font-16 btn-block btn btn-primary"
                                                        onClick={invoiceApprove(item.inventoryType)}
                                                        disabled={item.approvedStatus || (loading || loader)}
                                                    >
                                                        Approve
                                                    </Button> : <div >
                                                        <Link className="text-secondary" to="#" onClick={removeInvoiceRow(index)} style={{ display: item.rowAdded === 'new' ? 'block' : 'none' }}><i className="mdi mdi-delete font-size-18" id="edittooltip"></i></Link>
                                                    </div>}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>Total Amount</th>
                                        <th></th>
                                        <th></th>
                                        <th>${totalInvoice}</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <div className="mb-1">
                            <Button
                                color="info"
                                className="font-16 btn-block btn btn-info mb-2"
                                style={{ padding: '2px 10px' }}
                                onClick={addNewInvoiceRow}
                                disabled={partnerData.IsApproved === 'approved' || (loading || loader)}
                            >
                                <i
                                    className="mdi mdi-plus font-size-18"
                                    id="edittooltip"
                                ></i>  Add New Invoice Record
                            </Button> &nbsp;&nbsp;&nbsp;
                            <Button
                                className="font-16 btn-block btn btn-primary mb-2"
                                style={{ padding: '2px 10px' }}
                                onClick={() => setDeductionReport(true)}
                                disabled={partnerData.IsApproved === 'approved' || (loading || loader)}
                            >
                                <i
                                    className="mdi mdi-plus font-size-18"
                                    id="edittooltip"
                                ></i>  Add Deduction in Report
                            </Button>
                        </div>
                    </div>
                    {deductionReport &&
                        <div className="row mt-4">
                            <div className="react-bootstrap-table">
                                <table className="table table-bordered">
                                    <thead><tr>
                                        <th >Site</th>
                                        <th >Inventory Type</th>
                                        <th >Amount (USD)</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {deductionInvoices.map((item, index) => (
                                            <tr key={index}>
                                                <td style={{ wordBreak: 'break-word' }}>
                                                    <Input
                                                        name="site"
                                                        type="text"
                                                        className="m-1"
                                                        value={item.site}
                                                        onChange={handleChangeDeduction(index)}
                                                        readOnly
                                                    />
                                                </td>
                                                <td style={{ wordBreak: 'break-word' }}>
                                                    <Input
                                                        name="inventoryType"
                                                        type="select"
                                                        value={item.inventoryType}
                                                        onChange={handleChangeDeduction(index)}
                                                        className="form-select m-1"
                                                    >
                                                        {item.inventoryTypes.map((item) => (
                                                            <option key={item} value={item}>{item}</option>
                                                        ))}
                                                    </Input>
                                                </td>
                                                <td>
                                                    <Input
                                                        name="deducation"
                                                        type="text"
                                                        className="m-1"
                                                        value={item.deducation}
                                                        onKeyPress={allowOnlyNumbers}
                                                        onChange={handleChangeDeduction(index)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    <div className="mb-3">
                        <Label className="form-label">Notes</Label>
                        <Input
                            name="notes"
                            type="textarea"
                            rows="1"
                            className="ml-1 "
                            value={notesData.notes}
                            onChange={handleChange}
                            readOnly={partnerData.IsApproved === 'approved' || (loading || loader)}
                        />
                    </div>
                    <div className="mb-3">
                        <Label className="form-label">Terms & Conditions</Label>
                        <Input
                            name="terms"
                            type="textarea"
                            rows="2"
                            className="ml-1 "
                            value={notesData.terms}
                            onChange={handleChange}
                            readOnly={partnerData.IsApproved === 'approved' || (loading || loader)}
                        />
                    </div>
                </div>
                <div className="modal-footer">
                    <Button
                        color="primary"
                        className="font-16 btn-block btn btn-primary"
                        onClick={invoiceRecheck}
                        disabled={partnerData.IsApproved === 'approved' || (loading || loader)}
                    >
                        Re-Calculate
                    </Button>
                    {/* <Button
                        color="primary"
                        className="font-16 btn-block btn btn-primary"
                        onClick={invoiceApprove}
                        disabled={partnerData.IsApproved === 'approved'}
                    >
                        Approve
                    </Button> */}
                    <button
                        type="button"
                        onClick={closeReportModal}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                        disabled={(loading || loader)}
                    >
                        Close
                    </button>

                </div>
            </Modal>
            <div style={{ display: 'none' }}>
                <ToolkitProvider

                    keyField="id"
                    data={exportInvoiceData}
                    columns={ExportColumns}
                    exportCSV={{
                        fileName: 'Approval-Invoices-' + currentTimeStamp + '.csv',
                    }}
                >
                    {
                        /* eslint-disable react/prop-types */
                        props => (
                            <div>
                                <BootstrapTable {...props.baseProps} />
                                <hr />
                                <MyExportCSV {...props.csvProps} />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>

        </React.Fragment >
    )
}

Invoice_Approval.propTypes = {}

export default Invoice_Approval