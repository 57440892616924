import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types'
// //Import Breadcrumb
import { Col, Container, Row, Input, Label, Form, Button, Spinner } from "reactstrap"

const SearchDebounce = props => {

    const [search, setSearch] = useState([])

    const debouncer = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer)
            timer = setTimeout(() => {
                timer = null
                func.apply(context, args)
            }, 5000)
        }
    }

    const handleChange = (e) => {
        fetch(`https://demo.dataverse.org/api/search?q=${e.target.value}`)
            .then(res => res.json())
            .then(json => setSearch(json.data.items))
    }

    const optimisedVersion = useCallback(debouncer(handleChange),[])

    return (
        <React.Fragment>
            <div className="card card-body ">
                <Row className="" >
                    <Col xs={6}>
                        <div className="mb-3">

                            <Label className="form-label col-6 mt-2">Search Contexts : </Label>&nbsp;
                            <Input
                                type="text"
                                className="form-control"
                                onChange={handleChange}
                                name="language" 
                                placeholder="Enter Something"
                            >
                            </Input>
                            {search?.length > 0 &&
                                <div className="autoComplete" style={{ borderRadius: '5px', background: '#fff', boxShadow: '0 2px 6px rgba(0,0,0,0.3)' }}>
                                    {search?.map((ele, i) =>
                                        <div key={i} className="autoCompleteItems" style={{ cursor: 'pointer', padding: '10px 5px', color: 'dangerblue',borderBottom:'1px solid #e6e6e6' }}>
                                            <span>{ele.name}</span>
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment >
    )
}

SearchDebounce.propTypes = {}

export default SearchDebounce