import React, { useState, useRef } from 'react';
import queryString from 'query-string';
import { Row, Col, Container, FormFeedback, Input, Label,  } from "reactstrap";
import { Link } from 'react-router-dom';
import axios from "axios";
import CarouselPage from "pages/Authentication/CarouselPage";
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import images
import logodark from "assets/images/favicons.png";
//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const ApprovePermission = () => {

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };

  const parsed = queryString.parse(window.location.search);

  console.log(JSON.stringify(parsed));
  const [rejectedState, setRejectedState] = useState(false);
  const [approveState, setApproveState] = useState(false);
  const [reason, setReason] = useState('');
  const linkRef = useRef();

  const handleApprove = (e) => {
    e.preventDefault();
    let body = {
      "url": 'https://report-api.bidsxchange.com/v1.0/Query/Revenue/RequestApprove',
      "requestBody": {
        reasonForRejection: reason,
        isApproved: true,
        token: parsed.token
      }
    }
    axios.post("/v1.0/connectReportAPI", body,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept": 'application/json'
        }
      })
      .then((res) => {
        console.log("result", res)
        if (res.data.success) {
          setApproveState(true)
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const handleReject = (e) => {
    e.preventDefault();
    setRejectedState(true)
    if (reason) {
      let body = {
        "url": 'https://report-api.bidsxchange.com/v1.0/Query/Revenue/RequestApprove',
        "requestBody": {
          reasonForRejection: reason,
          isApproved: false,
          token: parsed.token
        }
      }
      axios.post("/v1.0/connectReportAPI", body,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json'
          }
        })
        .then((res) => {
          console.log("result", res)
          if (res.data.success) {
            setApproveState(true)
          }
        })
        .catch((err) => {
          console.log("e", err)
        });
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>
          Approve Permission | Bidsxchange
        </title>
      </Helmet>
      <Container fluid className="p-0">
        <Row className="g-0">
          <CarouselPage />
          <Col md={5}>
            <div className="auth-full-page-content p-md-5 p-4 row w-100">
              {!approveState &&
                <div className="w-50" style={{ margin: "auto" }}>
                  <div className="d-flex flex-column h-100">
                    <div className="mb-1 mb-md-2">
                      <h4>
                        <img
                          src={logodark}
                          alt=""
                          height="30"
                          className="auth-logo-dark"
                        />&nbsp;&nbsp;
                      </h4>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Partner Requesting for API Access</h5>
                      </div>

                      <div className="mt-4">

                        <p>Request from :  Rashtra Deepika Ltd</p>
                        <p>For :  API Access</p>
                        <div className="mb-3">
                          <Label className="form-label">Reason for Rejection <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                          <Input type="text" name="reason" value={reason} onChange={(e) => setReason(e.target.value)} className={(rejectedState && !reason ? ' is-invalid' : '')} placeholder='Please Enter Reason for Rejection ' />
                          {rejectedState && !reason ? (
                            <FormFeedback type="invalid">{'Reason is required for Rejection'}</FormFeedback>
                          ) : null}
                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-secondary w-md "
                              type="submit"
                              onClick={handleReject}
                            >
                              Reject
                            </button> &nbsp;&nbsp;&nbsp;
                            <button
                              className="btn btn-primary w-md "
                              type="submit"
                              onClick={handleApprove}
                            >
                              Approve
                            </button>
                          </Col>
                        </Row>
                        <div className="mt-5 text-center">
                          <p>
                            Back to &nbsp;
                            <Link to="login" className="font-weight-medium text-primary">
                              Login
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {approveState &&
                <div className="my-auto ">
                  <div className="text-center">
                    <div className="avatar-md mx-auto">
                      <div className="avatar-title rounded-circle bg-light">
                        <i className="bx bx-mail-send h1 mb-0 text-primary"></i>
                      </div>
                    </div>
                    <div className="p-2 mt-4">
                      <h4>Success !</h4>
                      {approveState &&
                        <p className="text-muted">
                          You have Successfully granted Permission for Reporting API to Rashtra Deepika Ltd.</p>
                      }
                      {rejectedState &&
                        <p className="text-muted">
                          You have Successfully Rejected Permission for Reporting API to Rashtra Deepika Ltd.</p>
                      }
                      <div className="mt-4">
                        <Link to="dashboard" className="btn btn-success">
                          Back to Login
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
            <footer className="footer" style={{ left: '0px' }}>
              <Container fluid={true}>
                <Row>
                  <Col md={6}></Col>
                  <Col md={6}>
                    <div className="text-sm-end d-none d-sm-block">
                      2015 - {new Date().getFullYear()} © Bidsxchange.
                    </div>
                  </Col>
                </Row>
              </Container>
            </footer>
          </Col>
        </Row>

      </Container>
      <Link ref={linkRef} to="login"></Link>
    </React.Fragment>
  );
}

export default ApprovePermission