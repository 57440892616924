import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_PARTNERS,GET_PARTNERS_LIST,GET_USERS,ADD_PARTNER , UPDATE_PARTNER,ADD_USER,UPDATE_USER,READ_PARTNER,STATUS_PARTNER,ARCHIVE_PARTNER} from "./actionTypes"

import {
  loadActivities,
  noData,
  getPartnersSuccess,
  getPartnersFail,
  addPartnerSuccess,
  addPartnerFail,
  updatePartnerSuccess,
  updatePartnerFail,
  readPartnerSuccess,
  statusPartnerSuccess,
  archivePartnerSuccess,
  getUsersSuccess,
  getUsersFail,
  addUserSuccess,
  addUserFail,
  updateUserSuccess,
  updateUserFail,
  getPartnersListSuccess,
  getPartnersListFail
} from "./actions"

//Include Both Helper File with needed methods
import { getPartners,getPartnersOnType,getUserList,createPartner,updatePartner,createUsers,updateUsers,readPartner,statusChangePartner,archivePartner } from "../../helpers/Backend_helper"

function* fetchPartners({ payload: partners }) {
  try {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    yield put(loadActivities(true))
    var getPartnerList
    if(partners && partners.type){
      getPartnerList = yield call(getPartnersOnType,partners)
      if(userInfo.userRole === "DC_Manager"){
        getPartnerList.response = getPartnerList.response.filter((partnerTypeFil)=> partnerTypeFil.partnerType ==="agency");
      }
      getPartnerList.response.map((item, index) => {
        item.id = index + 1
    })
    if(getPartnerList.response.length === 0){
      yield put(noData(true))
    }
    yield put(getPartnersSuccess(getPartnerList.response))
    }else{
      if(partners){
          getPartnerList = yield call(getPartners,partners)
          if(userInfo.userRole === "DC_Manager"){
            getPartnerList.response = getPartnerList.response.filter((partnerTypeFil)=> partnerTypeFil.partnerType ==="agency");
          }
          console.log(getPartnerList)
          getPartnerList.response.partners.map((item, index) => {
            item.id = index + 1
            if(partners.partnerID){
              if(partners.partnerID === item.partnerID){
                item.emailShow = true
              }else{
                item.emailShow = false
              }
            }else{
              item.emailShow = false
            }
        })
        if(getPartnerList.response.partners.length === 0){
          yield put(noData(true))
        }
        yield put(getPartnersSuccess(getPartnerList))
      }else{
        getPartnerList = []
        yield put(getPartnersSuccess(getPartnerList))
      }

    }
  } catch (error) {
    yield put(getPartnersFail(error))
  }
}

function* fetchPartnersList({ payload: partners }) {
  try {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    yield put(loadActivities(true))
    var getPartnerList
    console.log(partners)
    if(partners && partners.type){
      getPartnerList = yield call(getPartnersOnType,partners)
      console.log(getPartnerList)
      if(userInfo.userRole === "DC_Manager"){
        getPartnerList.response = getPartnerList.response.filter((partnerTypeFil)=> partnerTypeFil.partnerType ==="agency");
      }
      getPartnerList.response.map((item, index) => {
        item.id = index + 1
    })
    if(getPartnerList.response.length === 0){
      yield put(noData(true))
    }
    yield put(getPartnersListSuccess(getPartnerList.response))
    }
  } catch (error) {
    yield put(getPartnersListFail(error))
  }
}

function* fetchUsers({ payload: users }) {
  try {
    yield put(loadActivities(true))
    const response = yield call(getUserList,users)
    console.log(response)
    response.response.users.map((item, index) => {
      item.id = index + 1
  })
  console.log(response)
  if(response.response.users.length === 0){
    yield put(noData(true))
  }
  if(response.response.users.length > 0){
    yield put(getUsersSuccess(response))
  }else{
    yield put(getUsersSuccess([]))
  }
 
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onReadPartner({ payload: partner }) {
  try {
    const response = yield call(readPartner, partner)
    yield put(readPartnerSuccess(response))
  } catch (error) {
    yield put(readPartnerSuccess(error))
  }
}

function* onAddPartner({ payload: partner }) {
  try {
    delete partner.confirmEmail
    const response = yield call(createPartner, partner)
    if(response.statusCode === 200){
      yield put(addPartnerSuccess(response))
    }else{
      yield put(addPartnerFail(response))
    }
  } catch (error) {
    yield put(addPartnerFail(error)) 
  }
}

function* onUpdatePartner({ payload: partner }) {
  try {
    const response = yield call(updatePartner, partner)
    if(response.statusCode === 200){
      yield put(updatePartnerSuccess(response))
    }else{
      yield put(updateUserFail(response))
    }
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onAddUser({ payload: User }) {
  try {
    delete User.confirmEmail
    const response = yield call(createUsers, User)
    if (response.statusCode === 200) {
      yield put(addUserSuccess(response))
    }else{
      yield put(addUserFail(response))
    }
  } catch (error) {
    yield put(addUserFail(error))
  }
}

function* onUpdateUser({ payload: User }) {
  try {
    const response = yield call(updateUsers, User)
    if (response.statusCode === 200) {
      yield put(updateUserSuccess(response))
    }else{
      yield put(addUserFail(response))
    }
  } catch (error) { 
    yield put(updateUserFail(error))
  }
}

function* onStatusPartner({ payload: partner }) {
  try {
    const response = yield call(statusChangePartner, partner)
    if(response.statusCode === 200){
      yield put(statusPartnerSuccess(response))
    }else{
      yield put(statusPartnerSuccess(response))
    }
  } catch (error) {
    yield put(updatePartnerFail(error))
  }
}

function* onArchivePartner({ payload: partner }) {
  try {
    const response = yield call(archivePartner, partner)
    yield put(archivePartnerSuccess(response))
  } catch (error) {
    yield put(archivePartnerSuccess(error))
  }
}

function* partnersSaga() {
  yield takeEvery(GET_PARTNERS, fetchPartners)
  yield takeEvery(GET_PARTNERS_LIST,fetchPartnersList)
  yield takeEvery(ADD_PARTNER, onAddPartner)
  yield takeEvery(UPDATE_PARTNER, onUpdatePartner)
  yield takeEvery(READ_PARTNER, onReadPartner)
  yield takeEvery(STATUS_PARTNER, onStatusPartner)
  yield takeEvery(ARCHIVE_PARTNER, onArchivePartner)
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(ADD_USER, onAddUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
}

export default partnersSaga;
