import {
    GET_PROXY_ADUNITS,
    GET_PROXY_ADUNITS_FAIL,
    GET_PROXY_ADUNITS_SUCCESS,
    GET_PROXY_ADUNITS_LOADING,
    ADD_PROXY_ADUNIT,
    ADD_PROXY_ADUNIT_SUCCESS,
    ADD_PROXY_ADUNIT_ERROR,
    UPDATE_PROXY_ADUNIT,
    UPDATE_PROXY_ADUNIT_SUCCESS,
    UPDATE_PROXY_ADUNIT_ERROR,
    READ_PROXY_ADUNIT,
    READ_PROXY_ADUNIT_SUCCESS,
    STATUS_PROXY_ADUNIT,
    STATUS_PROXY_ADUNIT_SUCCESS,
    ARCHIVE_PROXY_ADUNIT ,
    ARCHIVE_PROXY_ADUNIT_SUCCESS,

    GET_PROXY_ADUNITS_REPORTS,
    GET_PROXY_ADUNITS_REPORTS_FAIL,
    GET_PROXY_ADUNITS_REPORTS_SUCCESS,

    GET_REVENUE_DISPLAY_REPORTS,
    GET_REVENUE_DISPLAY_REPORTS_FAIL,
    GET_REVENUE_DISPLAY_REPORTS_SUCCESS,

    GET_PUBLISHER_REVENUE_DISPLAY_REPORTS,
    GET_PUBLISHER_REVENUE_DISPLAY_REPORTS_FAIL,
    GET_PUBLISHER_REVENUE_DISPLAY_REPORTS_SUCCESS,

    GET_AGENCY_REVENUE_DISPLAY_REPORTS,
    GET_AGENCY_REVENUE_DISPLAY_REPORTS_FAIL,
    GET_AGENCY_REVENUE_DISPLAY_REPORTS_SUCCESS,

    GET_PUBLISHER_AGENCY_REVENUE_DISPLAY_REPORTS,
    GET_PUBLISHER_AGENCY_REVENUE_DISPLAY_REPORTS_FAIL,
    GET_PUBLISHER_AGENCY_REVENUE_DISPLAY_REPORTS_SUCCESS,

    GET_ANALYTICS_DISPLAY_REPORTS,
    GET_ANALYTICS_DISPLAY_REPORTS_FAIL,
    GET_ANALYTICS_DISPLAY_REPORTS_SUCCESS,

    GET_PAGE_URL_21_REPORTS,
    GET_PAGE_URL_21_REPORTS_FAIL,
    GET_PAGE_URL_21_REPORTS_SUCCESS,

    LOAD_REPORTS

  } from "./actionTypes"

  export const loadReports = Params => ({
    type: LOAD_REPORTS,
    payload:Params
  })

  export const getProxyAdUnits = Params => ({
    type: GET_PROXY_ADUNITS,
    payload:Params
  })
  
  export const getProxyAdUnitsSuccess = ProxyAdUnits => ({
    type: GET_PROXY_ADUNITS_SUCCESS,
    payload: ProxyAdUnits,
  })

  export const getProxyAdUnitLoading = loading => ({
    type: GET_PROXY_ADUNITS_LOADING,
    payload: loading
  })

  export const getProxyAdUnitsFail = error => ({
    type: GET_PROXY_ADUNITS_FAIL,
    payload: error,
  })

  export const addNewProxyAdUnit = ProxyAdUnit => ({
    type: ADD_PROXY_ADUNIT,
    payload: ProxyAdUnit,
  })
  
  export const addProxyAdUnitSuccess = proxyAdUnit => ({
    type: ADD_PROXY_ADUNIT_SUCCESS,
    payload: proxyAdUnit,
  })
  
  export const addProxyAdUnitFail = proxyAdUnit => ({
    type: ADD_PROXY_ADUNIT_ERROR,
    payload: proxyAdUnit,
  })

  export const updateProxyAdUnit = proxyAdUnit => ({
    type: UPDATE_PROXY_ADUNIT,
    payload: proxyAdUnit,
  })
  
  export const updateProxyAdUnitSuccess = proxyAdUnit => ({
    type: UPDATE_PROXY_ADUNIT_SUCCESS,
    payload: proxyAdUnit,
  })
  
  export const updateProxyAdUnitFail = proxyAdUnit => ({
    type: UPDATE_PROXY_ADUNIT_ERROR,
    payload: proxyAdUnit,
  })

  export const readProxyAdUnit = proxyAdUnit => ({
    type: READ_PROXY_ADUNIT,
    payload: proxyAdUnit,
  })

  export const readProxyAdUnitSuccess = proxyAdUnit => ({
    type: READ_PROXY_ADUNIT_SUCCESS,
    payload: proxyAdUnit,
  })

  export const statusProxyAdUnit = proxyAdUnit => ({
    type: STATUS_PROXY_ADUNIT,
    payload: proxyAdUnit,
  })

  export const statusProxyAdUnitSuccess = proxyAdUnit => ({
    type: STATUS_PROXY_ADUNIT_SUCCESS,
    payload: proxyAdUnit,
  })

  export const archiveProxyAdUnit = proxyAdUnit => ({
    type: ARCHIVE_PROXY_ADUNIT,
    payload: proxyAdUnit,
  })

  export const archiveProxyAdUnitSuccess = proxyAdUnit => ({
    type: ARCHIVE_PROXY_ADUNIT_SUCCESS,
    payload: proxyAdUnit,
  })

  export const getProxyAdUnitsReports = Params => ({
    type: GET_PROXY_ADUNITS_REPORTS,
    payload:Params
  })
  
  export const getProxyAdUnitsReportsSuccess = ProxyAdUnitsReports => ({
    type: GET_PROXY_ADUNITS_REPORTS_SUCCESS,
    payload: ProxyAdUnitsReports,
  })

  export const getProxyAdUnitsReportsFail = error => ({
    type: GET_PROXY_ADUNITS_REPORTS_FAIL,
    payload: error,
  })

  export const getRevenueDisplayReports = Params => ({
    type: GET_REVENUE_DISPLAY_REPORTS,
    payload:Params
  })
  
  export const getRevenueDisplayReportsSuccess = RevenueDisplayReports => ({
    type: GET_REVENUE_DISPLAY_REPORTS_SUCCESS,
    payload: RevenueDisplayReports,
  })

  export const getRevenueDisplayReportsFail = error => ({
    type: GET_REVENUE_DISPLAY_REPORTS_FAIL,
    payload: error,
  })

  export const getPublisherRevenueDisplayReports = Params => ({
    type: GET_PUBLISHER_REVENUE_DISPLAY_REPORTS,
    payload:Params
  })
  
  export const getPublisherRevenueDisplayReportsSuccess = RevenueDisplayReports => ({
    type: GET_PUBLISHER_REVENUE_DISPLAY_REPORTS_SUCCESS,
    payload: RevenueDisplayReports,
  })

  export const getPublisherRevenueDisplayReportsFail = error => ({
    type: GET_PUBLISHER_REVENUE_DISPLAY_REPORTS_FAIL,
    payload: error,
  })

  export const getAgencyRevenueDisplayReports = Params => ({
    type: GET_AGENCY_REVENUE_DISPLAY_REPORTS,
    payload:Params
  })
  
  export const getAgencyRevenueDisplayReportsSuccess = RevenueDisplayReports => ({
    type: GET_AGENCY_REVENUE_DISPLAY_REPORTS_SUCCESS,
    payload: RevenueDisplayReports,
  })

  export const getAgencyRevenueDisplayReportsFail = error => ({
    type: GET_AGENCY_REVENUE_DISPLAY_REPORTS_FAIL,
    payload: error,
  })

  export const getPAgencyRevenueDisplayReports = Params => ({
    type: GET_PUBLISHER_AGENCY_REVENUE_DISPLAY_REPORTS,
    payload:Params
  })
  
  export const getPAgencyRevenueDisplayReportsSuccess = RevenueDisplayReports => ({
    type: GET_PUBLISHER_AGENCY_REVENUE_DISPLAY_REPORTS_SUCCESS,
    payload: RevenueDisplayReports,
  })

  export const getPAgencyRevenueDisplayReportsFail = error => ({
    type: GET_PUBLISHER_AGENCY_REVENUE_DISPLAY_REPORTS_FAIL,
    payload: error,
  })

  export const getAnalyticsDisplayReports = Params => ({
    type: GET_ANALYTICS_DISPLAY_REPORTS,
    payload:Params
  })
  
  export const getAnalyticsDisplayReportsSuccess = AnalyticsDisplayReports => ({
    type: GET_ANALYTICS_DISPLAY_REPORTS_SUCCESS,
    payload: AnalyticsDisplayReports,
  })

  export const getAnalyticsDisplayReportsFail = error => ({
    type: GET_ANALYTICS_DISPLAY_REPORTS_FAIL,
    payload: error,
  })

  export const getPageUrl21Reports = Params => ({
    type: GET_PAGE_URL_21_REPORTS,
    payload:Params
  })
  
  export const getPageUrl21ReportsSuccess = PageUrl21Reports => ({
    type: GET_PAGE_URL_21_REPORTS_SUCCESS,
    payload: PageUrl21Reports,
  })

  export const getPageUrl21ReportsFail = error => ({
    type: GET_PAGE_URL_21_REPORTS_FAIL,
    payload: error,
  })