/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef , useCallback} from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Input,
  Spinner,
  Label
} from "reactstrap"

import DateRangePickers from 'helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import {
  getAgencyScheduleReports as onGetAgencyScheduleReports,
  statusAgencyScheduleReports as onStatusAgencyScheduleReport,
  archiveAgencyScheduleReport as onArchiveAgencyScheduleReport,
  statusAgencyScheduleReportSuccess,
  archiveAgencyScheduleReportSuccess,
  addAgencyScheduleReportFail,
  updateAgencyScheduleReportFail,
} from "store/AgencyConsole/ScheduleReport/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"


import Agency_NewSchedule_Report from "./Agency_NewSchedule_Report";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var loader = false
var filterCount = 0

const Agency_ScheduleReport = props => {
    const dispatch = useDispatch();
    const [isRight, setIsRight] = useState(false);
    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
    const [statusSearch, setStatusSearch] = useState('Active')
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [isArchived, setIsArchived] = useState(false)
    const [selected, setSelected] = useState([])
  
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
  

    
  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setIsRight(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);
    const changeDatePick = (event) => {
      console.log(event)
      console.log("start: ", event.startDate._d);
      console.log("end: ", event.endDate._d);
      setFromDate(event.startDate._d.toISOString());
      setToDate(event.endDate._d.toISOString());
    }
  
    toastr.options = {
      positionClass: "toast-top-center",
      closeButton: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
      showDuration: 300,
      hideDuration: 1000,
      timeOut: 3000,
      extendedTimeOut: 1000
    };
  
    useEffect(() => {
      // loader = true
      dispatch(onGetAgencyScheduleReports(null));
    }, []);
  
    const selectRow = {
      mode: "checkbox",
      nonSelectable: [],
      selected: selected,
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          setSelected([...selected, row.id])
          setSelectedRowsStatus(true)
          selectedIndex.push(row.scheduleID)
          selectedItem.push(row.scheduleID)
        } else {
          setSelected(selected.filter(x => x !== row.id))
          let index = selectedIndex.indexOf(row.scheduleID)
          selectedIndex.splice(index, 1)
          let index2 = selectedItem.indexOf(row.scheduleID)
          selectedItem.splice(index2, 1)
          if (selectedIndex.length === 0) {
            setSelectedRowsStatus(false)
          }
        }
      },
      onSelectAll: (isSelect, rows, e) => {
        const ids = rows.map(r => r.id);
        if (isSelect) {
          setSelected(ids)
          rows.map((item, index) => {
            selectedIndex.push(item.scheduleID)
            selectedItem.push(item.scheduleID)
          })
          setSelectedRowsStatus(true)
        } else {
          setSelected([])
          setSelectedRowsStatus(false)
          selectedIndex = []
          selectedItem = []
        }
      }
    };

    const [filterState, setFilterState] = useState(false)

    const statusChange = (e) => {
      if (e.target.value === 'Active') {
        filterCount = filterCount > 0 ? filterCount - 1 : 0
      }
      if (statusSearch === 'Active') {
        filterCount = filterCount + 1
      }
      setStatusSearch(e.target.value)
    }
  
    const applyFilter = (e) => {
      dispatch(onGetAgencyScheduleReports({ filter: statusSearch }));
      if (filterCount > 0) {
        setFilterState(true)
      } else {
        setFilterState(false)
      }
    }
  
    const [isMenu, setisMenu] = useState(false)
    const toggleMenu = () => {
      setisMenu(!isMenu)
    }
  
    const clearAllFilter = (e) => {
      setStatusSearch('Active')
      filterCount = 0
      setFilterState(false)
      dispatch(onGetAgencyScheduleReports({ filter: 'Active' }));
    }
  
    const handleClose = () => {
      setSelectedRowsStatus(false)
      selectedItem = []
      setSelected([])
    }
  
    const { scheduleReports } = useSelector(state => ({
      scheduleReports: state.agencyScheduleReports.scrs,
    }));
    
    console.log(scheduleReports)
    
    const { loading } = useSelector(state => ({
      loading: false,
    }));

    const { noData } = useSelector(state => ({
      noData: state.agencyScheduleReports.noData,
    }));
  
    const { scheduleReportsResponse } = useSelector(state => ({
      scheduleReportsResponse: state.agencyScheduleReports.response,
    }));
  
    const { archiveAgencyScheduleReportsResponse } = useSelector(state => ({
      archiveAgencyScheduleReportsResponse: state.agencyScheduleReports.archiveMsg,
    }));
  
    // const { errorMsg, successMsg } = useSelector(state => ({
    //   errorMsg: state.agencyScheduleReports.errorMsg,
    //   successMsg: state.agencyScheduleReports.successMsg,
    // }));
  
    console.log(scheduleReportsResponse,loading)
    // debugger
    useEffect(() => {
      if (scheduleReportsResponse !== null) {
        if (scheduleReportsResponse.success) {
          toastr.success('AgencyScheduleReport Status Changed Successfully')
          dispatch(onGetAgencyScheduleReports({ filter: statusSearch }));
          dispatch(statusAgencyScheduleReportSuccess(null));
          selectedItem = []
          setSelected([])
          setSelectedRowsStatus(false)
  
        }
      }
    }, [dispatch, scheduleReportsResponse]);
  
    useEffect(() => {
      if (archiveAgencyScheduleReportsResponse !== null) {
        if (archiveAgencyScheduleReportsResponse.success) {
          toastr.success('AgencyScheduleReport Archived Successfully')
          dispatch(onGetAgencyScheduleReports({ filter: statusSearch }));
          dispatch(archiveAgencyScheduleReportSuccess(null));
          selectedItem = []
          setSelected([])
          setSelectedRowsStatus(false)
  
        }
      }
    }, [dispatch, archiveAgencyScheduleReportsResponse]);
  
    const toggleRightCanvas = () => {
      selectedData = ''
      dispatch(addAgencyScheduleReportFail(""));
      dispatch(updateAgencyScheduleReportFail(""));
      dispatch(onGetAgencyScheduleReports({ filter: statusSearch }));
      setIsRight(!isRight);
    };
  
    const toggleEnableScroll = () => {
      setIsEnableScroll(!isEnableScroll);
    };
  
    const toggleDrawer = (close, scheduleReport) => (event) => {
      console.log(event)
      if (event === 'Create') {
        toastr.success('AgencyScheduleReport Saved Successfully!...')
  
      }
      if (event === 'Update') {
        toastr.success('AgencyScheduleReport Updated and Scheduled Successfully!...')
  
      }
      selectedData = scheduleReport
      setIsArchived(scheduleReport.isScheduleArchived)
      dispatch(onGetAgencyScheduleReports({ filter: statusSearch }));
      dispatch(addAgencyScheduleReportFail(""));
      dispatch(updateAgencyScheduleReportFail(""));
      setIsRight(close);
    };
  
    useEffect(() => {
      if (scheduleReports !== null) {
        // loader = true
        dispatch(onGetAgencyScheduleReports({ filter: statusSearch }));
      }
    }, []);
  
    var node = useRef();
    const onPaginationPageChange = page => {
      if (
        node &&
        node.current &&
        node.current.props &&
        node.current.props.pagination &&
        node.current.props.pagination.options
      ) {
        node.current.props.pagination.options.onPageChange(page);
      }
    };
  
    const keyField = "id";
  
    const scheduleReportListColumns = [
      {
        text: "ID",
 headerClasses:"ID",
        dataField: "id",
        sort: true,
        hidden: true,
        // eslint-disable-next-line react/display-name
        formatter: user => <>{user.id}</>,
      },
    //   {
    //     text: "ID",
    //     dataField: "scheduleID",
    //     sort: true,
    //     formatter: (cellContent, scheduleReport) => (
    //       <>
    //         <span>{scheduleReport.scheduleID}</span>
    //       </>
    //     ),
    //   },
      {
        dataField: "scheduleCreateTime",
        text: "Creation Date",
        sort: true,
      },
  
      {
        text: "Schedule Name",
        dataField: "scheduleName",
        sort: true,
      },
      {
        text: "Frequency",
        dataField: "frequency",
        sort: true,
      },
      // {
      //   text: "Next Report On",
      //   dataField: "nextTime",
      //   sort: true,
      // },
      {
        dataField: "scheduleStatus",
        text: "Status",
        sort: true,
        formatter: (cellContent, scheduleReport) => (
          <>
              <span className={scheduleReport.newStatus === 'Active' ? "active-state" : scheduleReport.newStatus === 'Inactive' ? "inactive-state" : "archived-state" }>{scheduleReport.newStatus}</span>
          </>
        ),
      },
      {
        dataField: "",
        isDummyField: true,
        editable: false,
        text: "Action",
        align: 'center',
  
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, scheduleReport) => (
          <div className="d-flex gap-3" >
            <Link className="Email-state" to="#" onClick={toggleDrawer(true, scheduleReport)}>
              {scheduleReport.newStatus === 'Archived' &&
                'View'
              }
              {scheduleReport.newStatus !== 'Archived' &&
                'Edit'
              }
            </Link>
          </div>
        ),
      },
    ];
  
    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} entries
      </span>
    );
  
  
    const pageOptions = {
      paginationSize: 4,
      pageStartIndex: 1,
      sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
    };
  
  
  
    const defaultSorted = [
      {
        dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ];
  
    const archiveAgencyScheduleReport = async () => {
      dispatch(onArchiveAgencyScheduleReport({ idList: selectedItem }));
    }
  
    const statusAgencyScheduleReport = (state) => (event) => {
      console.log(state)
      dispatch(onStatusAgencyScheduleReport({ idList: selectedItem, scheduleStatus: state }));
    }
  
    console.log(scheduleReports)
  
    if (scheduleReports.length > 0) {
      // loader = false
      scheduleReports.map((item, index) => {
        if (item.isScheduleArchived) {
          selectRow.nonSelectable.push(index + 1)
        }
      })
    } 
  
  
  
    return (
      <React.Fragment>
            {/* {loading &&
              <Spinner className="ms-2 loader" color="primary" />
            } */}
            <div style={{ pointerEvents: (loading) ? 'none' : 'unset' }}>
              {/* Render Breadcrumbs */}
              <div className="row mb-2 d-sm-flex  justify-content-between">
                {/* <div className=" text-sm-end">
                  <Button
                    color="primary"
                    className="font-16 btn-block btn btn-primary"
                    onClick={toggleRightCanvas}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Add Schedule Report
                  </Button>
                </div> */}
                     <div className=" text-sm-end">
                <div className="btn-group">
                  <Dropdown
                    direction="left"
                    isOpen={isMenu}
                    toggle={toggleMenu}
                  >
                    <DropdownToggle
                      tag="button"
                      className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                    >
                      <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                      {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                        Filter Applied
                      </span> : <span className="d-none font-16 d-sm-inline-block">
                        Filter
                      </span>
                      }
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                      <div className="dropdown-item-text">
                        <div className="row">
                          <h5 className="mb-0">Filters</h5>
                        </div>
                      </div>

                      <DropdownItem divider />
                      <Card>
                        <CardBody aria-hidden="true">
                          <Row >
                            <Col sm="12" className="mt-2">
                              <Label className="form-label">Status</Label>
                              <div className=" text-sm-end">
                                <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                  <option value=''> All</option>
                                  <option value='Active' > Active</option>
                                  <option value='Inactive'> InActive</option>
                                  <option value='Archived'> Archived</option>
                                </Input>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      <DropdownItem divider />
                      <Row>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={clearAllFilter}
                          >
                            Clear ALL
                          </DropdownItem>
                        </Col>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={applyFilter}
                          >
                            Apply Filter
                          </DropdownItem>
                        </Col>
                      </Row>

                    </DropdownMenu>
                  </Dropdown>
                </div> 
                </div>
              </div>
              {loading &&
                <Spinner className="ms-2 loader" color="primary" />
              }
              {!loading &&
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody aria-hidden="true">
                              <ToolkitProvider
                                keyField={keyField}
                                data={scheduleReports}
                                columns={scheduleReportListColumns}
                                // bootstrap4
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row className="mb-2">
                                    <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar {...toolkitProps.searchProps} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="4"></Col>
                                  <Col sm="4" className="date-range ">
                                    <div className="text-sm-end">
                                      <DateRangePickers datePick={changeDatePick} />
                                    </div>
                                  </Col>
                                    </Row>
                                    <Row>
                                      <Col xl="12">
                                        {selectedRowsStatus && selectedIndex.length > 0 &&
                                          <div className="row m-0 React-Table-Selection">
                                            {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                            <div style={{ padding: '0.5% 2%' }}>
  
                                              {/* <button color="primary" className="btn btn-primary " onClick={statusAgencyScheduleReport(true)}>Activate</button> */}
                                              <button color="primary" className="btn btn-primary " onClick={statusAgencyScheduleReport(true)}>
                                                Activate
                                              </button>
                                              &nbsp;  &nbsp;
                                              <button color="primary" className="btn btn-primary " onClick={statusAgencyScheduleReport(false)}>
                                                DeActivate
                                              </button>
                                              &nbsp;  &nbsp;
                                              <Button color="primary" className="btn btn-primary " onClick={archiveAgencyScheduleReport} >
                                                Archive
                                              </Button>
                                              <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                                className="mdi mdi-close-thick font-size-18"
                                              ></i></Button>
                                            </div>
                                          </div>
                                        }
                                      </Col>
                                      <Col xl="12" className="table-responsive">
                                        <div >
                                          <BootstrapTable
                                            keyField={keyField}
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            selectRow={selectRow}
                                            classes={
                                              "table align-middle table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            {...toolkitProps.baseProps}
                                            pagination={paginationFactory(pageOptions)}
                                            noDataIndication={<h5 className="text-center">No Data</h5>}
                                          // ref={node}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              }
            </div>
  
        <Offcanvas isOpen={isRight} direction='end'  >
          <OffcanvasHeader toggle={toggleRightCanvas}>
            {selectedData ? 'Edit Schedule Report' : 'Add Schedule Report'}
          </OffcanvasHeader>
          <OffcanvasBody className="p-0">
            <Agency_NewSchedule_Report closeCanvas={toggleDrawer(false, '')} selectedAgencyScheduleReport={selectedData} selectedArchived={isArchived} />
          </OffcanvasBody>
        </Offcanvas>
      </React.Fragment>
    )
  }

  Agency_ScheduleReport.propTypes = {}

export default Agency_ScheduleReport