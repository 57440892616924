import {
    GET_PUB_PLAYLIST_SUCCESS,
    GET_PUB_PLAYLIST_FAIL,
    GET_PUB_PLAYLISTBYID_SUCCESS,
    GET_PUB_PLAYLISTBYID_FAIL,
    CREATE_PUB_PLAYLIST_SUCCESS,
    CREATE_PUB_PLAYLIST_FAIL,
    UPDATE_PUB_PLAYLIST_SUCCESS,
    UPDATE_PUB_PLAYLIST_FAIL,
    STATUS_PUB_PLAYLIST_SUCCESS,
    ARCHIVE_PUB_PLAYLIST_SUCCESS,
    ARCHIVE_PUB_PLAYLIST_FAIL
} from "./actionTypes"

const INIT_STATE = {
    playlist: [],
    eachPlaylistData: [],
    error: {},
    successMsg: null,
    errorMsg: null,
    response: null,
    archiveMsg: null,
    statusMsg: null
}

const pubPlaylistsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PUB_PLAYLIST_SUCCESS:
            return {
                ...state,
                playlist: action.payload
            }
        case GET_PUB_PLAYLIST_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_PUB_PLAYLISTBYID_SUCCESS:
            return {
                ...state,
                eachPlaylistData: action.payload
            }
        case GET_PUB_PLAYLISTBYID_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CREATE_PUB_PLAYLIST_SUCCESS:
            console.log(action.payload)
            return {
                ...state,
                successMsg: action.payload

            }
        case CREATE_PUB_PLAYLIST_FAIL:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === "CREATE_PUB_PLAYLIST_SUCCESS" ? null : action.payload,
            }
        case UPDATE_PUB_PLAYLIST_SUCCESS:
            return {
                ...state,
                successMsg: action.payload
            }
        case UPDATE_PUB_PLAYLIST_FAIL:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === "UPDATE_PUB_PLAYLIST_SUCCESS" ? null : action.payload,
            }
        case STATUS_PUB_PLAYLIST_SUCCESS:
            return {
                ...state,
                statusMsg: action.payload
            }
        case ARCHIVE_PUB_PLAYLIST_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload
            }
        case ARCHIVE_PUB_PLAYLIST_FAIL:
            return {
                ...state,
                archiveMsg: action.payload,

            }
        default:
            return state
    }
}

export default pubPlaylistsReducer

