// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vl {
    min-height: 25px;
    background-image: linear-gradient(#8b8585 60px, transparent 0), linear-gradient(lightgray 60px px, transparent 0);
    background-color: #8b8585;
    border-top-right-radius: 8px;
}

.vl1 {
    min-height: 25px;
    background-image: linear-gradient( lightgray 20px, transparent 0 ), linear-gradient( lightgray 20px, transparent 0 );
}

.vl2 {
    min-height: 18px;
    background-image: linear-gradient( lightgray 10px, transparent 0 ), linear-gradient( lightgray 10px, transparent 0 );
    border-top-left-radius: 5px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 15px;
}

.vl-mobile{
    min-height: 25px; 
    background-image: linear-gradient( #8b8585 60px, transparent 0 ), linear-gradient( lightgray 60px, transparent 0 );
    background-color: #8b8585;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/House_Ads/CustomTracking.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iHAAiH;IACjH,yBAAyB;IACzB,4BAA4B;AAChC;;AAEA;IACI,gBAAgB;IAChB,oHAAoH;AACxH;;AAEA;IACI,gBAAgB;IAChB,oHAAoH;IACpH,2BAA2B;IAC3B,+BAA+B;IAC/B,4BAA4B;IAC5B,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;IAChB,kHAAkH;IAClH,yBAAyB;IACzB,4BAA4B;IAC5B,2BAA2B;AAC/B","sourcesContent":[".vl {\n    min-height: 25px;\n    background-image: linear-gradient(#8b8585 60px, transparent 0), linear-gradient(lightgray 60px px, transparent 0);\n    background-color: #8b8585;\n    border-top-right-radius: 8px;\n}\n\n.vl1 {\n    min-height: 25px;\n    background-image: linear-gradient( lightgray 20px, transparent 0 ), linear-gradient( lightgray 20px, transparent 0 );\n}\n\n.vl2 {\n    min-height: 18px;\n    background-image: linear-gradient( lightgray 10px, transparent 0 ), linear-gradient( lightgray 10px, transparent 0 );\n    border-top-left-radius: 5px;\n    border-bottom-left-radius: 15px;\n    border-top-right-radius: 5px;\n    border-bottom-right-radius: 15px;\n}\n\n.vl-mobile{\n    min-height: 25px; \n    background-image: linear-gradient( #8b8585 60px, transparent 0 ), linear-gradient( lightgray 60px, transparent 0 );\n    background-color: #8b8585;\n    border-top-right-radius: 8px;\n    border-top-left-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
