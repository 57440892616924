import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_PUB_NOTIFICATIONS,GET_ACTION_NOTIFICATIONS,GET_NOTREAD_NOTIFICATIONS,ADD_PUB_NOTIFICATION , UPDATE_PUB_NOTIFICATION,READ_PUB_NOTIFICATION,STATUS_PUB_NOTIFICATION,ARCHIVE_PUB_NOTIFICATION} from "./actionTypes"

import {
  loadActivitiesPub_Notifications,
  noNotifications,
  getPub_NotificationsSuccess,
  getPub_NotificationsFail,
  addPub_NotificationSuccess,
  addPub_NotificationFail,
  updatePub_NotificationSuccess,
  updatePub_NotificationFail,
  readPub_NotificationSuccess,
  statusPub_NotificationSuccess,
  archivePub_NotificationSuccess,

  getNotRead_NotificationsSuccess,
  getNotRead_NotificationsFail,

  getAction_NotificationsSuccess,
  getAction_NotificationsFail,

} from "./actions"

//Include Both Helper File with needed methods
import { getPub_Notifications,getAction_Notifications,getNotRead_Notifications,postPub_Notification,updatePub_Notification,readPub_Notification,statusChangePub_Notification,archivePub_Notification } from "../../../helpers/Backend_helper"

function* fetchPub_Notifications({ payload: nots }) {
  try {
    yield put(loadActivitiesPub_Notifications(true))
    var getNotifList
      if(nots){
        getNotifList = yield call(getPub_Notifications,nots)
        console.log(getNotifList)
        getNotifList.response.map((item, index) => {
          item.id = index + 1
      })
      if(getNotifList.response.length === 0){
        yield put(noNotifications(true))
      }
        yield put(getPub_NotificationsSuccess(getNotifList))
      }
      else{
        getNotifList = []
        yield put(getPub_NotificationsSuccess(getNotifList))
      }
  } catch (error) {
    yield put(getPub_NotificationsFail(error))
  }
}

function* fetchNotRead_Notifications({ payload: nots }) {
  try {
    // yield put(loadActivitiesPub_Notifications(true))
    var getNotReadNotifList
      if(nots){
        getNotReadNotifList = yield call(getNotRead_Notifications,nots)
        console.log(getNotReadNotifList)
        getNotReadNotifList.response.map((item, index) => {
          item.id = index + 1
      })
      if(getNotReadNotifList.response.length === 0){
        yield put(noNotifications(true))
      }
        yield put(getNotRead_NotificationsSuccess(getNotReadNotifList.response))
      }
      else{
        getNotReadNotifList = []
        yield put(getNotRead_NotificationsSuccess(getNotReadNotifList))
      }
  } catch (error) {
    yield put(getNotRead_NotificationsFail(error))
  }
}

function* fetchAction_Notifications({ payload: nots }) {
  try {
    yield put(loadActivitiesPub_Notifications(true))

      const  response = yield call(getAction_Notifications,nots)
      console.log(response)
      response.responseData.map((item, index) => {
          item.id = index + 1
      })
      if(response.responseData.length === 0){
        yield put(noNotifications(true))
      }
        yield put(getAction_NotificationsSuccess(response))
  } catch (error) {
    yield put(getAction_NotificationsFail(error))
  }
}

function* onReadPub_Notification({ payload: nots }) {
  try {
    const response = yield call(readPub_Notification, nots)
    yield put(readPub_NotificationSuccess(response))
  } catch (error) {
    yield put(readPub_NotificationSuccess(error))
  }
}

function* onAddPub_Notification({ payload: nots }) {
  try {
    const response = yield call(postPub_Notification, nots)
    if(response.success){
      yield put(addPub_NotificationSuccess(response))
    }else{
      yield put(addPub_NotificationFail(response.message))
    }
  } catch (error) {
    yield put(addPub_NotificationFail(error))
  }
}

function* onUpdatePub_Notification({ payload: nots }) {
  try {
    const response = yield call(updatePub_Notification, nots)
    yield put(updatePub_NotificationSuccess(response))
  } catch (error) {
    yield put(statusPub_NotificationSuccess(error))
  }
}

function* onStatusPub_Notification({ payload: nots }) {
  try {
    const response = yield call(statusChangePub_Notification, nots)
    yield put(statusPub_NotificationSuccess(response))
  } catch (error) {
    yield put(updatePub_NotificationFail(error))
  }
}

function* onArchivePub_Notification({ payload: nots }) {
  try {
    const response = yield call(archivePub_Notification, nots)
    yield put(archivePub_NotificationSuccess(response))
  } catch (error) {
    yield put(archivePub_NotificationSuccess(error))
  }
}

function* pubNotificationSaga() {
  yield takeEvery(GET_PUB_NOTIFICATIONS, fetchPub_Notifications)
  yield takeEvery(GET_ACTION_NOTIFICATIONS, fetchAction_Notifications)
  yield takeEvery(GET_NOTREAD_NOTIFICATIONS,fetchNotRead_Notifications)
  yield takeEvery(ADD_PUB_NOTIFICATION, onAddPub_Notification)
  yield takeEvery(UPDATE_PUB_NOTIFICATION, onUpdatePub_Notification)
  yield takeEvery(READ_PUB_NOTIFICATION, onReadPub_Notification)
  yield takeEvery(STATUS_PUB_NOTIFICATION, onStatusPub_Notification)
  yield takeEvery(ARCHIVE_PUB_NOTIFICATION, onArchivePub_Notification)
}

export default pubNotificationSaga;
