import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_NET_BLOCKINGS,ADD_NET_BLOCKING , UPDATE_NET_BLOCKING,READ_NET_BLOCKING,STATUS_NET_BLOCKING,ARCHIVE_NET_BLOCKING} from "./actionTypes"

import {
  loadNetworkBlockings,
  noDataNetworkBlockings,
  getNetworkBlockingsSuccess,
  getNetworkBlockingsFail,
  addNetworkBlockingSuccess,
  addNetworkBlockingFail,
  updateNetworkBlockingSuccess,
  updateNetworkBlockingFail,
  readNetworkBlockingSuccess,
  statusNetworkBlockingSuccess,
  archiveNetworkBlockingSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getNetBlocking,updateNetBlocking,statusChangeNetBlocking,archiveNetBlocking } from "../../../helpers/Backend_helper"

function* fetchNetworkBlocking({ payload: adv }) {
  try {
    yield put(loadNetworkBlockings(true))
    const response = yield call(getNetBlocking,adv)
    console.log(response)
    response.response.map((item, index) => {
      item.id = index + 1
  })
  console.log(response)
  if(response.response.length === 0){
    yield put(noDataNetworkBlockings(true))
  }
    yield put(getNetworkBlockingsSuccess(response.response))
  } catch (error) {
    yield put(getNetworkBlockingsFail(error))
  }
}


function* onUpdateNetworkBlocking({ payload: adv }) {
  try {
    const response = yield call(updateNetBlocking, adv)
    if(response.success){
      yield put(updateNetworkBlockingSuccess(response))
    }else{
      yield put(updateNetworkBlockingFail(response))
    }
  } catch (error) {
    console.log(error)
    yield put(updateNetworkBlockingFail(error))
  }
}

function* onStatusNetworkBlocking({ payload: adv }) {
  try {
    const response = yield call(statusChangeNetBlocking, adv)
    yield put(statusNetworkBlockingSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(statusNetworkBlockingSuccess(error))
  }
}

function* onArchiveNetworkBlocking({ payload: adv }) {
  try {
    const response = yield call(archiveNetBlocking, adv)
    yield put(archiveNetworkBlockingSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(archiveNetworkBlockingSuccess(error))
  }
}

function* netBlockingsSaga() {
  yield takeEvery(GET_NET_BLOCKINGS, fetchNetworkBlocking)
  yield takeEvery(UPDATE_NET_BLOCKING, onUpdateNetworkBlocking)
  yield takeEvery(STATUS_NET_BLOCKING, onStatusNetworkBlocking)
  yield takeEvery(ARCHIVE_NET_BLOCKING, onArchiveNetworkBlocking)
}

export default netBlockingsSaga;
