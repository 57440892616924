import {
    GET_PLAYLIST,
    GET_PLAYLIST_SUCCESS,
    GET_PLAYLIST_FAIL,
    GET_PLAYLISTBYID,
    GET_PLAYLISTBYID_SUCCESS,
    GET_PLAYLISTBYID_FAIL,
    CREATE_PLAYLIST, 
    CREATE_PLAYLIST_SUCCESS,
    CREATE_PLAYLIST_FAIL,
    UPDATE_PLAYLIST,
    UPDATE_PLAYLIST_SUCCESS,
    UPDATE_PLAYLIST_FAIL,
    STATUS_PLAYLIST,
    STATUS_PLAYLIST_SUCCESS,
    ARCHIVE_PLAYLIST ,
    ARCHIVE_PLAYLIST_SUCCESS,
    ARCHIVE_PLAYLIST_FAIL
} from "./actionTypes"

export const getPlaylist = params => ({
    type: GET_PLAYLIST,
    payload: params
})

export const getPlaylistSuccess = playlist => ({
    type: GET_PLAYLIST_SUCCESS,
    payload: playlist
})

export const getPlaylistFail = error => ({
    type: GET_PLAYLIST_FAIL,
    payload: error
})
export const getPlaylistById = params => ({
    type: GET_PLAYLISTBYID,
    payload: params
})

export const getPlaylistSuccessById = playlist => ({
    type: GET_PLAYLISTBYID_SUCCESS,
    payload: playlist
})

export const getPlaylistFailById = error => ({
    type: GET_PLAYLISTBYID_FAIL,
    payload: error
})

export const createPlaylist = playlist => ({
    type: CREATE_PLAYLIST,
    payload: playlist
})

export const createPlaylistSuccess = playlist => ({
    type: CREATE_PLAYLIST_SUCCESS,
    payload: playlist
})

export const createPlaylistFail = error =>({
    type: CREATE_PLAYLIST_FAIL,
    payload: error
})

export const updatePlaylist = playlist =>({
    type: UPDATE_PLAYLIST,
    payload: playlist
})

export const updatePlaylistSuccess = playlist => ({
    type: UPDATE_PLAYLIST_SUCCESS,
    payload: playlist
})

export const updatePlaylistFail = error =>({
    type: UPDATE_PLAYLIST_FAIL,
    payload: error
})

export const statusPlaylist = playlist => ({
    type: STATUS_PLAYLIST,
    payload: playlist
  })

  export const statusPlaylistSuccess = playlist => ({
    type: STATUS_PLAYLIST_SUCCESS,
    payload: playlist
  })

  export const archivePlaylist = playlist => ({
    type: ARCHIVE_PLAYLIST,
    payload: playlist
  })

  export const archivePlaylistSuccess = playlist => ({
    type: ARCHIVE_PLAYLIST_SUCCESS,
    payload: playlist
  })

  export const archivePlaylistFail = error => ({
    type: ARCHIVE_PLAYLIST_FAIL,
    payload: error
  })