import PropTypes from "prop-types";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword, userForgetPasswordSuccess, userForgetPasswordError } from "../../store/actions";

// import images
import logodark from "../../assets/images/favicons.png";
import CarouselPage from "pages/Authentication/CarouselPage";

var loading = Boolean(false)

const ForgetPasswordPage = props => {
  const dispatch = useDispatch();
  const [timer, setTimer] = useState({ count: 0 })
  const linkRef = useRef();
  const increment = useRef(null)

  useEffect(() => {
    dispatch(userForgetPasswordSuccess(""));
    dispatch(userForgetPasswordError(""));
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      loading = true;
      dispatch(userForgetPassword(values, props.history));
    }
  });

  const handleStart = () => {

    increment.current = setInterval(() => {
      setTimer(prevState => ({
        count: prevState.count + 1
      }))
    }, 1000)
  }

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,

  }));

  if (forgetSuccessMsg) {
    loading = false;
    handleStart();
  }

  if (forgetError) {
    loading = false;
  }

  useEffect(() => {
    console.log(timer.count)
    if (timer.count === 5) {
      clearInterval(increment.current)
      setTimer({ count: 0 })
      dispatch(userForgetPasswordSuccess(""));
      linkRef.current.click()
    }
  }, [timer.count]);



  return (
    <React.Fragment>
      <Helmet>
        <title>
          Forget Password | Bidsxchange
        </title>
      </Helmet>
      <Container fluid className="p-0">
        <Row className="g-0">
          <CarouselPage />
          <Col md={5}>
            <div className="auth-full-page-content p-md-5 p-4">
              <div className="w-50" style={{ margin: "auto" }}>
                <div className="d-flex flex-column h-100">
                  <div className="mb-1 mb-md-2">
                    <h4>
                      <img
                        src={logodark}
                        alt=""
                        height="30"
                        className="auth-logo-dark"
                      />&nbsp;&nbsp;
                    </h4>
                  </div>
                  <div className="my-auto">
                    <div>
                      <h5 className="text-primary"> Forgot Password</h5>
                    </div>

                    <div className="mt-4">
                      <p className="mt-2 text-muted">
                        Don&apos;t worry, it happens to everyone. Just enter your email and
                        click &apos;Reset&apos;, and we will send you a link to reset your
                        password.
                      </p>
                      {forgetError && forgetError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {forgetError}
                        </Alert>
                      ) : null}
                      {forgetSuccessMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {forgetSuccessMsg}
                        </Alert>
                      ) : null}

                      <Form className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              className="btn btn-primary w-md "
                              type="submit"
                            >
                              <i className={loading ? "bx bx-hourglass bx-spin font-size-16 align-middle me-2 ml-2 mr-2" : ''} ></i>
                              Reset
                            </button>
                          </Col>
                        </Row>
                      </Form>

                      <div className="mt-5 text-center">
                        <p>
                          Go back to &nbsp;
                          <Link to="login" className="font-weight-medium text-primary">
                            Login
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </Col>
          <footer className="footer" style={{ textAlign: 'end' }}>
            <Container fluid={true}>
              <Row>
                <Col md={4}>    <div className="text-sm-start d-flex"> 2015 - {new Date().getFullYear()} © Bidsxchange. All rights reserved.</div></Col>
                <Col md={4}>
                  <div className="text-center">
                    <h6 style={{ letterSpacing: '6px' }}>BIDSXCHANGE</h6>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="text-sm-end d-flex">
                    <Col >
                      <a href="https://streamlyn.com/cookie-policy/" rel="noreferrer" className="under-line-hyper-link" target="_blank">Cookie Policy</a>
                      &nbsp;|&nbsp;
                      <a href="https://streamlyn.com/privacy-policy/" rel="noreferrer" className="under-line-hyper-link" target="_blank">Privacy Policy</a>
                    </Col>
                  </div>
                </Col>
              </Row>
            </Container>
          </footer>
        </Row>

      </Container>
      <Link ref={linkRef} to="login"></Link>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
