/* eslint-disable react/prop-types */
import React, { useState, useEffect, useId, useRef } from "react";
import {
    CardBody, Col, Form, Card, Label, Input, Button, CardTitle, Row, Container,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem, Spinner
} from "reactstrap";

//Import react quill editor
import ReactQuill from 'react-quill';

//redux
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";
import { withRouter, Link } from "react-router-dom";

import $ from "jquery";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import 'react-quill/dist/quill.snow.css';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import queryString from 'query-string';

var file
var fileNameEntry = ''

const Publisher_New_Support_Ticket = (props) => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [subject, setSubject] = useState("");
    const [userMsg, setUserMsg] = useState("");
    const [supportTicket, setSupportTicket] = useState([])
    const [userFilename, setUserFilename] = useState("")
    const [inputKey, setInputKey] = useState(Math.random().toString(36))
    const [manualType,setManualType] = useState("")

    const [dropdownOpenType, setDropdownOpenType] = useState(false);
    const [dropdownOpenPriority, setDropdownOpenPriority] = useState(false);
    const [loader, setLoader] = useState(false);

    const toggleType = () => setDropdownOpenType((prevState) => !prevState);
    const togglePriority = () => setDropdownOpenPriority((prevState) => !prevState);

    const [finalData, setFinalData] = useState(
        {
            typeOfSupportPriority: '',
            typeOfSupportTicket: ''
        }
    )

    const dispatch = useDispatch()
    const clearAll = () => {
        setSubject("")
        setUserMsg("")
    }

    const headers = {
        "Content-Type": "multipart/form-data",
        "Accept": 'multipart/form-data',
        "Authorization": `Bearer ${userInfo.token}`
    }

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const fileChange = (e) => {
        console.log("file changed")
        console.log(e.target.files[0])
        if (e.target.files[0] != undefined) {
            file = e.target.files[0]
            setUserFilename(e.target.files[0].name)
            if (file.size > 10485760) {
                toastr.error("File size must be less than 10 MB");
                console.log("large file")
                setUserFilename("")
                setInputKey(Math.random().toString(36))
                file = ''
            }
        } else {
            setUserFilename("")
        }
    }

    const handleChange = e => {
        const { name, value } = e.target
        setFinalData(finalData => ({ ...finalData, [name]: value }));
    }

    function _handleSubmit(e) {
        setLoader(true)
        console.log(e.target)
        e.preventDefault();
        let fileData = new FormData();
        fileData.append('file', file);
        fileData.append("supportTicketSubject", subject);
        fileData.append("message", userMsg)
        fileData.append('userID', userInfo.userID)
        fileData.append('userRole', userInfo.userRole)
        fileData.append('companyID', userInfo.companyID)
        fileData.append('name', userInfo.name)
        fileData.append('typeOfSupportPriority', finalData.typeOfSupportPriority)
        if(finalData.typeOfSupportTicket === 'Other'){
            fileData.append('typeOfSupportTicket',manualType)
        }else{
            fileData.append('typeOfSupportTicket', finalData.typeOfSupportTicket)
        }
        fileData.append('URI', 'https://nino.bidsxchange.com/Publisher_Support_Tickets')

        $.ajax({
            url: "/v2.0/support/SupportTickets/create",
            type: 'POST',
            data: fileData,
            beforeSend: function(xhr) {
                xhr.setRequestHeader('Authorization', `Bearer ${userInfo.token}`);
            },
            processData: false,
            contentType: false,
            mode: 'cors',
            cache: false,
            credentials: 'include',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            enctype: "multipart/form-data",
            success: function (data) {
                // Success..
                setLoader(false)
                console.log('success', data);
                setUserFilename('')
                setInputKey(Math.random().toString(36))
                file = ''
                props.closeCanvas('Create', data.supportTicketID)
                console.log(data)
            },
            // Fail..
            error: function (xhr, status, err) {
                console.log(xhr, status);
                console.log(err);
                setLoader(false)
                setInputKey(Math.random().toString(36))
                file = ''
            }
        });
    }

    const { errorMsg, successMsg } = useSelector(state => ({
        errorMsg: state.supportTickets.errorMsg,
        successMsg: state.supportTickets.successMsg,
    }));



    const handleClear = () => {
        setUserFilename('')
        setInputKey(Math.random().toString(36))
    }


    return (
        <Col md="12">
            {loader &&
                <Spinner className="ms-2 loader" color="primary" />
            }
            <Form onSubmit={_handleSubmit} method="post">
                {/* <Form action="https://support-ticket.bidsxchange.com/create" encType="multipart/form-data" method="post"> */}
                <div style={{ padding: '0% 3%', height: '80vh', overflow: 'auto' }}>
                    <Row >
                        <Col sm="12">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <div className="mb-3 m-3">
                                        <Label className="form-label">Subject</Label>
                                        <Input type="text" name='supportTicketSubject'
                                            placeholder="Subject"
                                            className={"form-control"}
                                            onChange={(e) => setSubject(e.target.value)}
                                            required>
                                        </Input>
                                    </div>
                                    <Input name='userID' className={"form-control"} value={userInfo.userID} readOnly hidden></Input>
                                    <Input name='userRole' className={"form-control"} value={userInfo.userRole} readOnly hidden></Input>
                                    <Input name='companyID' className={"form-control"} value={userInfo.companyID} readOnly hidden></Input>
                                    <Input name='name' className={"form-control"} value={userInfo.name} readOnly hidden></Input>
                                    <Input name='message' className={"form-control"} value={userMsg} readOnly hidden></Input>
                                    <Input name='URI' className={"form-control"} value={'https://nino.bidsxchange.com/Publisher_Support_Tickets'} readOnly hidden></Input>

                                    <div className="mb-3 m-3">
                                        <Label className="form-label">Select Ticket Type</Label>
                                        <Input
                                            name="typeOfSupportTicket"
                                            type="select"
                                            className="form-select"
                                            onChange={handleChange}
                                            value={finalData.typeOfSupportTicket}
                                        >
                                            <option value=''>Select</option>
                                            <option value='Report'>Report</option>
                                            <option value='ReportBug'>Report Bug</option>
                                            <option value='Invoice'>Invoice</option>
                                            <option value='Troubleshoot'>Troubleshoot</option>
                                            <option value='FeatureRequest'>Feature Request</option>
                                            <option value='PerformanceIssue'>Performance Issue</option>
                                            <option value='Other'>Other</option>
                                        </Input>
                                    </div>
                                    {finalData.typeOfSupportTicket === 'Other' &&
                                        <div className="mb-3 m-3">
                                            <Label className="form-label">Enter Type of Support Ticket</Label>
                                            <Input
                                                name="typeOfSupportTicket"
                                                type="text"
                                                className="form-text"
                                                onChange={(e)=> setManualType(e.target.value)}
                                                value= {manualType}
                                            >
                                            </Input>
                                        </div>
                                    }
                                    <div className="mb-3 m-3">
                                        <Label className="form-label">Select Priority</Label>
                                        <Input
                                            name="typeOfSupportPriority"
                                            type="select"
                                            className="form-select"
                                            onChange={handleChange}
                                            value={finalData.typeOfSupportPriority}
                                        >
                                            <option value=''>Select</option>
                                            <option value='Urgent'>Urgent</option>
                                            <option value='High'>High</option>
                                            <option value='Medium'>Medium</option>
                                            <option value='Low'>Low</option>
                                        </Input>
                                    </div>
                                    <div className="mb-3 m-3">
                                        <Label className="form-label">Your Message</Label>
                                        <ReactQuill theme="snow" name="message" value={userMsg} onChange={setUserMsg} /> 
                                        <div className="d-flex justify-content-start align-items-center">
                                            <div className="attach support-ticket-file  d-flex justify-content-start align-items-center">
                                                <i className='bx bx-paperclip bx-sm bx-rotate-270' ></i>
                                                <input style={{ cursor: "pointer" }} className="support-ticket-input" type="file" key={inputKey || ''} onChange={fileChange} name="file" accept=".png,.jpeg,.jpg,video/*" />
                                                {userFilename ? `${userFilename}` : "Attachment : filesize below 10MB"}
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center">
                                                {userFilename && <i className='bx bx-x' style={{ cursor: "pointer", fontSize: "1.5rem" }} onClick={() => handleClear()}></i>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
                    <div className="">
                        <Button type="submit"
                            color="primary"
                        >
                            Create Ticket
                        </Button>
                    </div>
                </Row>
            </Form>
        </Col>
    )
}

Publisher_New_Support_Ticket.propTypes = {}

export default Publisher_New_Support_Ticket