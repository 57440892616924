import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_BIDDERS, ADD_BIDDER, UPDATE_BIDDER, READ_BIDDER, STATUS_BIDDER, ARCHIVE_BIDDER } from "./actionTypes"

import {
  loadActivitiesBidders,
  noDataBidders,
  getBiddersSuccess,
  getBiddersFail,
  addBidderSuccess,
  addBidderFail,
  updateBidderSuccess,
  updateBidderFail,
  readBidderSuccess,
  statusBidderSuccess,
  archiveBidderSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getBidder, createBidder, updateBidder, readBidder, statusChangeBidder, archiveBidder } from "../../../helpers/Backend_helper"

function* fetchBidders({ payload: adv }) {
  try {
    console.log(adv)
    yield put(loadActivitiesBidders(true))
    var getBidderList
    if (adv) {
      getBidderList = yield call(getBidder, adv)
      getBidderList.data.map((item, index) => {
        item.id = index + 1
      })
      if (getBidderList.data.length === 0) {
        yield put(noDataBidders(true))
      }
      yield put(getBiddersSuccess(getBidderList.data))
    } else {
      getBidderList = []
      yield put(getBiddersSuccess(getBidderList))
    }
  } catch (error) {
    yield put(getBiddersFail(error))
  }
}

function* onReadBidder({ payload: adv }) {
  try {
    const response = yield call(readBidder, adv)
    yield put(readBidderSuccess(response))
  } catch (error) {
    yield put(readBidderSuccess(error))
  }
}

function* onAddBidder({ payload: adv }) {
  try {
    const response = yield call(createBidder, adv)
    if (response.success) {
      yield put(addBidderSuccess(response))
    } else {
      yield put(addBidderFail(response))
    }
  } catch (error) {
    yield put(addBidderFail(error))
  }
}

function* onUpdateBidder({ payload: adv }) {
  try {
    const response = yield call(updateBidder, adv)
    if (response.success) {
      yield put(updateBidderSuccess(response))
    } else {
      yield put(updateBidderFail(response))
    }
  } catch (error) {
    yield put(updateBidderFail(error))
  }
}

function* onStatusBidder({ payload: adv }) {
  try {
    const response = yield call(statusChangeBidder, adv)
    yield put(statusBidderSuccess(response))
  } catch (error) {
    yield put(statusBidderSuccess(error))
  }
}

function* onArchiveBidder({ payload: adv }) {
  try {
    const response = yield call(archiveBidder, adv)
    yield put(archiveBidderSuccess(response))
  } catch (error) {
    yield put(archiveBidderSuccess(error))
  }
}

function* bidderSaga() {
  yield takeEvery(GET_BIDDERS, fetchBidders)
  yield takeEvery(ADD_BIDDER, onAddBidder)
  yield takeEvery(UPDATE_BIDDER, onUpdateBidder)
  yield takeEvery(READ_BIDDER, onReadBidder)
  yield takeEvery(STATUS_BIDDER, onStatusBidder)
  yield takeEvery(ARCHIVE_BIDDER, onArchiveBidder)
}

export default bidderSaga;
