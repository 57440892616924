/* ADUNITGROUP */
export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES"
export const NO_DATA = "NO_DATA"
export const GET_ADUNITGROUPS = "GET_ADUNITGROUPS"
export const GET_ADUNITGROUPS_SUCCESS = "GET_ADUNITGROUPS_SUCCESS"
export const GET_ADUNITGROUPS_FAIL = "GET_ADUNITGROUPS_FAIL"

export const ADD_ADUNITGROUP = "ADD_ADUNITGROUP"
export const ADD_ADUNITGROUP_SUCCESS = "ADD_ADUNITGROUP_SUCCESS"
export const ADD_ADUNITGROUP_ERROR = "ADD_ADUNITGROUP_ERROR"

export const UPDATE_ADUNITGROUP = "UPDATE_ADUNITGROUP"
export const UPDATE_ADUNITGROUP_SUCCESS = "UPDATE_ADUNITGROUP_SUCCESS"
export const UPDATE_ADUNITGROUP_ERROR = "UPDATE_ADUNITGROUP_ERROR"

export const READ_ADUNITGROUP = "READ_ADUNITGROUP"
export const READ_ADUNITGROUP_SUCCESS = "READ_ADUNITGROUP_SUCCESS"

export const STATUS_ADUNITGROUP = "STATUS_ADUNITGROUP"
export const STATUS_ADUNITGROUP_SUCCESS = "STATUS_ADUNITGROUP_SUCCESS"

export const ARCHIVE_ADUNITGROUP = "ARCHIVE_ADUNITGROUP"
export const ARCHIVE_ADUNITGROUP_SUCCESS = "ARCHIVE_ADUNITGROUP_SUCCESS"