import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// import images
import CarouselPage from "pages/Authentication/CarouselPage";

const Register = props => {
  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fullName: '',
      email: '',
      country: '',
      companyName: '',
      companyWebsite: '',
      businessType: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      fullName: Yup.string().required("Please Enter Your Full Name"),
      country: Yup.string().required("Please Enter Country"),
      companyName: Yup.string().required("Please Enter Company Name"),
      companyWebsite: Yup.string().required("Please Enter Company Website"),
      businessType: Yup.string().required("Please Enter Business Name")
    }),
    onSubmit: (values) => {
      dispatch(registerUser(values));
    }
  });

  const { user, registrationError, loading } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }));

  // handleValidSubmit
  const handleValidSubmit = values => {
    dispatch(registerUser(values));
  };

  useEffect(() => {
    dispatch(apiError(""));
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Register | Bidsxchange</title>
      </Helmet>
      <Container fluid className="p-0">
        <Row className="g-0">
          <CarouselPage />

          <Col md={6}>
            <div className="auth-full-page-content p-md-5 p-4">
              <div className="w-50" style={{ margin: "auto" }}>
                <div className="d-flex flex-column h-100">
                  <div className="my-auto">
                    <div>
                      <h5 className="text-primary">Register </h5>
                      <p className="text-muted">
                        Get your Bidsxchange account now.
                      </p>
                    </div>
                    <div className="mt-4">
                      <Form className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {user && user ? (
                          <Alert color="success">
                            Register User Successfully
                          </Alert>
                        ) : null}

                        {registrationError && registrationError ? (
                          <Alert color="danger">{registrationError}</Alert>
                        ) : null}

                        <div className="mb-3">
                          <Label className="form-label">Full Name</Label>
                          <Input
                            name="fullName"
                            type="text"
                            className="form-control"
                            placeholder="Enter Full Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.fullName || ""}
                            invalid={
                              validation.touched.fullName && validation.errors.fullName ? true : false
                            }
                          />
                          {validation.touched.fullName && validation.errors.fullName ? (
                            <FormFeedback type="invalid">{validation.errors.fullName}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Company Email</Label>
                          <Input
                            id="company_email"
                            name="email"
                            className="form-control"
                            placeholder="Enter Company Email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>
                        <div>
                          <p className="mb-0">
                            By registering you agree to the BIDSXCHANGE
                          </p>
                        </div>

                        <div className="mt-4">
                          <button
                            className="btn btn-primary btn-block "
                            type="submit"
                          >
                            Register
                          </button>
                        </div>

                      </Form>

                      <div className="mt-5 text-center">
                        <p>
                          Already have an account ?
                          <Link to="login" className="font-weight-medium text-primary">
                            Login
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <footer className="footer" style={{ textAlign: 'end' }}>
            <Container fluid={true}>
              <Row>
                <Col md={4}>    <div className="text-sm-start d-flex"> 2015 - {new Date().getFullYear()} © Bidsxchange. All rights reserved.</div></Col>
                <Col md={4}>
                  <div className="text-center">
                    <h6 style={{ letterSpacing: '6px' }}>BIDSXCHANGE</h6>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="text-sm-end d-flex">
                    <Col >
                      <a href="https://streamlyn.com/cookie-policy/" rel="noreferrer" className="under-line-hyper-link" target="_blank">Cookie Policy</a>
                      &nbsp;|&nbsp;
                      <a href="https://streamlyn.com/privacy-policy/" rel="noreferrer" className="under-line-hyper-link" target="_blank">Privacy Policy</a>
                    </Col>
                  </div>
                </Col>
              </Row>
            </Container>
          </footer>
        </Row>

      </Container>
    </React.Fragment>
  );
};

export default Register;
