import {
    LOAD_MARKET_CONTENTS,
    GET_MARKET_CONTENTS,
    GET_MARKET_CONTENTS_SUCCESS,
    GET_MARKET_CONTENTS_FAIL,
    ADD_MARKET_CONTENTS,
    ADD_MARKET_CONTENTS_SUCCESS,
    ADD_MARKET_CONTENTS_FAIL,
    DELETE_MARKET_CONTENT,
    DELETE_MARKET_CONTENT_SUCCESS,
    DELETE_MARKET_CONTENT_ERROR
} from './actionTypes';

export const loadMarketContents = Params => ({
    type: LOAD_MARKET_CONTENTS,
    payload: Params
})

export const getMarketContents = Params => ({
    type: GET_MARKET_CONTENTS,
    payload: Params
})

export const getMarketContentsSuccess = MarketContents => ({
    type: GET_MARKET_CONTENTS_SUCCESS,
    payload: MarketContents, 
})

export const getMarketContentsFail = error => ({
    type: GET_MARKET_CONTENTS_FAIL,
    payload: error,
})

export const addMarketContents = Params => ({
    type: ADD_MARKET_CONTENTS,
    payload: Params
})

export const addMarketContentsSuccess = MarketContents => ({
    type: ADD_MARKET_CONTENTS_SUCCESS,
    payload: MarketContents, 
})

export const addMarketContentsFail = error => ({
    type: ADD_MARKET_CONTENTS_FAIL,
    payload: error,
})


export const deleteMarketContent = MarketContents => ({
    type: DELETE_MARKET_CONTENT,
    payload: MarketContents,
})

export const deleteMarketContentSuccess = MarketContents => ({
    type: DELETE_MARKET_CONTENT_SUCCESS,
    payload: MarketContents,
})

export const deleteMarketContentFail = error => ({
    type: DELETE_MARKET_CONTENT_ERROR,
    payload: error,
})
