import React, { useState, useEffect } from 'react'
import axios from 'axios';
import PropTypes from 'prop-types'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Breadcrumb from 'components/Common/Breadcrumb';
import { Container, Spinner, Row, Col, Card, CardBody, Offcanvas, OffcanvasHeader, OffcanvasBody, Button, Label, Input, Modal, ModalBody, Progress } from 'reactstrap';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import Add_Import from './Add_Import';
import { withRouter, Link } from "react-router-dom";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import {
  getCommonDevices as onGetAllContextual,
} from "store/CommonEvents/actions";

import { useSelector, useDispatch } from "react-redux";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import CreatableSelect from 'react-select/creatable';
import LoadingTable from "pages/LoaderPages/LoadingTable";

import {
  addNewVideoContent as onADDVideoContent,
  addVideoContentSuccess,
  addVideoContentFail
} from 'store/Media/VideoContent/actions'

import {
  addNewPubVideoContent as onAddPubVideoContent,
  addPubVideoContentSuccess,
  addPubVideoContentFail
} from 'store/Media_Publisher/VideoContent/actions'

// import {
//   addNewPubVideoContent as onADDVideoContent,
//   addPubVideoContentSuccess as addVideoContentSuccess,
//   addPubVideoContentFail as addVideoContentFail
// } from 'store/Media_Publisher/VideoContent/actions'

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';
import { head } from 'lodash';
import { createPlaylist } from 'store/actions';

var selectedItem = []
var selectedData = ''
var selectedIndex = []

const Video_Import_Pub = props => {
  const dispatch = useDispatch();

  const [addPlaylist, setAddPlaylist] = useState(false)
  const [playlist, setPlaylist] = useState([])
  const [playlistTitle, setPlaylistTitle] = useState('')
  const [selected, setSelected] = useState([])
  const [loader, setLoader] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [videoColor, setVideoColor] = useState('')
  const [resolution, setResolution] = useState('640:360')
  const [quality, setQuality] = useState('ultra')

  const [showAlert, setShowAlert] = useState(false);

  const userInfo = JSON.parse(localStorage.getItem('authUser'));

  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)


  const [videoContent, setVideoContent] = useState({
    clickThroughUrl: "",
    videoContentCategory: "",
    videoContentLanguage: ""
  });

  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [languageData, setLanguageData] = useState([])
  const [categoryData, setCategoryData] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [category, setCategory] = useState('');
  const [language, setLanguage] = useState('');
  const [progress, setProgress] = useState(0);
  const [downloadedVideos, setDownloadedVideos] = useState([]);
  const [switchLoader, setSwitchLoader] = useState(false)


  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  console.log(videoContent)

  useEffect(() => {
    console.log("--------------> called")
    setSelectedCategory(null);
    setSelectedLanguage(null);
    setVideoContent({
      clickThroughUrl: "",
      videoContentCategory: "",
      videoContentLanguage: ""
    });
    setProgress(0);
  }, [showAlert])

  
  // useEffect(() => {
  //   // console.log(loading)
  //   if (loading) {
  //     setSwitchLoader(true)
  //   }
  //   if (playlist && playlist.length !== 0) {
  //     setSwitchLoader(false)
  //   }
  // }, [loaderT]);


  console.log(selected)
  const options = {
    sizePerPage: 10,
    totalSize: 0,
    custom: true,
  };

  const getPlaylistInfo = (data) => {
    setPlaylistTitle(data[0].title)
    setPlaylist(data[1].videos)
  }

  const handleDownload_ = async (url) => {
    console.log(url)
    const newUrl = url
    setSpinner(true)
    var fileName
    try {
      const response = await axios.post("/downloadvideo", {
        url: inputValue,
        resolution: resolution,
        startTime: `:`,
        duration: `:`,
        preset: quality,
      }, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = progressEvent.total ? Math.round((progressEvent.loaded * 100) / progressEvent.total) : 100;
          console.log(progressEvent);
          // setDownloadProgress(percentCompleted);
        },
      });
      console.log(response)
      // setVideoBlob(new Blob([response.data]));
      const videoBlob = new Blob([response.data])
      setLoader(false)

      console.log(videoBlob)


      const contentDispositionHeader = response.headers['content-disposition'];
      const filenameMatch = contentDispositionHeader.match(/vInfo="(.+)"/);

      if (filenameMatch && filenameMatch.length > 1) {
        const videoInfo = filenameMatch[1];
        const list = JSON.parse(videoInfo)
        console.log(list)
        fileName = list[0].title
        // setSavePlaylist(...savePlaylist, list)
      } else {
        console.log("undefined")
      }

      // Create a download link and click it to start the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.mp4`);
      document.body.appendChild(link);
      link.click();
      setSpinner(false)

      // Clean up the download link
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      // handleDownload(videoBlob)
    } catch (error) {
      setLoader(false)
      console.error(error);
    }
  };

  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelected([...selected, row.id])
        setSelectedRowsStatus(true)
        selectedIndex.push(row.id)
        selectedItem.push(row.id)
      } else {
        setSelected(selected.filter(each => each !== row.id))
        let index = selectedIndex.indexOf(row.id)
        selectedIndex.splice(index, 1)
        let index2 = selectedItem.indexOf(row.id)
        selectedItem.splice(index2, 1)
        if (selectedItem.length === 0) {
          setSelectedRowsStatus(false)
        }
      }

    },
    onSelectAll: (isSelect, rows, e) => {
      const ids = rows.map(each => each.id);
      if (isSelect) {
        setSelected(ids)
        rows.map((item, index) => {
          selectedIndex.push(item.id)
          selectedItem.push(item.id)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
      }
    }
  };

  const { contextuals } = useSelector(state => ({
    contextuals: state.commonEvents.contextualAllData,
  }));

  useEffect(() => {
    if (contextuals !== null) {
      dispatch(onGetAllContextual({isSupplyPartner:userInfo.isSupplyPartner }));
    }
  }, []);

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
    selectedIndex = []
  }

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const keyField = "id";


  const get_info = async () => {

    try {
      console.log("get info called")
      setSwitchLoader(true)
      const playlistInfo = await axios.post("/downloadplaylist", {
        url: inputValue
      });
      console.log(playlistInfo)
      console.log(playlistInfo.data.videos);
      const playlist = playlistInfo.data.videos;
      setPlaylist(playlist)
      console.log(playlist);
      setSwitchLoader(false)
    } catch (error) {
      console.error(error);
      setSwitchLoader(false)

    }
  }


  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };


  const videoListCoulumns = [{
    dataField: 'id',
    text: "ID"
  },
  {
    dataField: 'thumbnail',
    text: "THUMBNAIL",
    style: { wordBreak: 'break-word' },
    formatter: (cellContent, eachRow) => (
      <img src={eachRow.thumbnail} alt="thumbnail" style={{ height: "3.5rem", width: "6 rem" }} />
    )
  },
  {
    dataField: 'title',
    text: "TITLE"
  },
  {
    dataField: 'shortUrl',
    text: "URL"
  },
  {
    dataField: 'duration',
    text: "DURATION"
  }
    // {
    //   dataField: '',
    //   text: "DOWNLOAD",
    //   formatter: (cellContent, row) => {
    //     let videoColor = "green ";
    //     if (parseInt(row.durationSec) > 900) {
    //       videoColor = "yellow";
    //     } else if (parseInt(row.durationSec) > 600) {
    //       videoColor = "orange";
    //     }
    //     console.log(row.durationSec)
    //     return (
    //       <div>
    //         <a onClick={() => { handleDownload_(row.shortUrl) }}>
    //           <u className={videoColor} >
    //             {parseInt(row.durationSec) > 600 ? 'Download' : 'Fast_Download'}
    //           </u>
    //           {/* {spinner && <i className='bx bx-loader-circle bx-md bx-spin'></i>} */}
    //         </a>
    //       </div>
    //     )
    //   }

    // },
  ]

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  const renderNoData = () => {
    return (
      <div className='d-flex justify-content-center align-items-center'>
        <i className='bx bx-import bx-lg m-1'></i>
        <div>
          <h3 className='mb-0'>No Video imports found!</h3>
          {/* <p>Sorry,there are no videos imports in your account or perhaps your search criteria did not match
            any available video imports.<br />
            Please add a new video import or revise the filters you used.
          </p> */}
        </div>

      </div>
    )
  }

  const toggleSetPlaylsit = () => {
    setAddPlaylist(addPlaylist => !addPlaylist)
    console.log(addPlaylist)
  }

  const isPlaylistLink = (url) => {
    // return /list=/.test(url);
    return url.includes("list=")
  }


  const videoInfo = async (url) => { 
    try {
      const videotInfoData = await axios.post("/getbasicinfo", {
        url: url
      })
      console.log(videotInfoData)
      const info = videotInfoData.data.data.vInfo
      return info
    } catch (err) {
      console.log(err)
    }
  }

  const handleInputChangeCategory = (inputValue, actionMeta) => {
    console.log(inputValue);
    setCategory(inputValue)
  };

  const handleDownloadClick = async () => {

    if (inputValue.includes('youtube')) {
      setPlaylist([])
      if (isPlaylistLink(inputValue)) {
        console.log('This is a playlist link');
        // Call your backend to download the playlist
        // Update the progress state and videoBlob state as needed
        // multi_Proxy_Test()
        get_info();
      } else {
        console.log('This is a video link');

        const links = inputValue.split(' ')
        let listInfo = []
        setSwitchLoader(true)
        for (let each in links) {

          console.log(links)
          if (links[each] !== ' ' && links[each].includes('youtube')) {

            const data = await videoInfo(links[each])
            console.log(data)
            listInfo = [...listInfo, data]
            setPlaylist(listInfo)
          }else{
            setSwitchLoader(false);
            toastr.error("invalid link")
          }
          setSwitchLoader(false)

          // downloadVideo(videoId)
        }

        // videoInfo();

      }
    } else {
      toastr.error("invalid link")
      setSwitchLoader(false)

    }
  }

  const handleCategoryChange = (e, actionMeta) => {
    console.log(e)
    if (actionMeta.action === 'create-option' && category !== '') {
      createData(category, 'Category')
    }
    setSelectedCategory(e)
    setVideoContent(videoContent => ({ ...videoContent, videoContentCategory: e.value }));
  }

  const handleInputChange = (inputValue, actionMeta) => {
    console.log(inputValue);
    setLanguage(inputValue)
  };

  const handleChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target;
    setVideoContent(videoContent => ({ ...videoContent, [name]: value }));
  }

  const objectModal = (response = []) => {
    return response.map((item, index) => {
      return {
        value: item,
        label: item
      }
    })
  }

  const handleLanguageChange = (e, actionMeta) => {
    let event = JSON.stringify(e)
    if (actionMeta.action === 'create-option' && language !== '') {
      createData(language, 'Language')
    }
    setSelectedLanguage(e)
    setVideoContent(videoContent => ({ ...videoContent, videoContentLanguage: e.value }));
  }

  const createData = (data, type) => {
    console.log(data, type)
    console.log('create Data')
    let newData = []
    newData.push(data)
    const body = {
      "url": "http://127.0.0.1:9091/cms/convert",
      "requestBody": {
        "vidpath": "/home/ubuntu/javap/ConvertVideos/videos/Arts/English/Securitylapse_DronespottedoverPrimeMinisterNarendraModi_sresidence.mp4",
        "thumbpath": "/home/ubuntu/javap/ConvertVideos/thumbnail/Arts/English/Securitylapse_DronespottedoverPrimeMinisterNarendraModi_sresidence.png",
        "category": "Arts",
        "language": "English",
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
      }
    }
    axios.post("/v1.0/connectReportAPI", body, {
      headers: headers
    })
      .then((res) => {
        console.log("Data Created", res)
        if (type === 'Language') {
          setLanguageData([])
          dispatch(onGetLanguages());
          setLanguageData(objectModal(videoContentLanguage))
        }
        else {
          setCategoryData([])
          dispatch(onGetCategories());
          setCategoryData(objectModal(videoContentCategory))
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  };

  useEffect(() => {
    if (contextuals && Object.keys(contextuals).length) {
      if (contextuals.language && contextuals.language.length > 0) {
        setLanguageData(objectModal(contextuals.language))
      }
      if (contextuals.category && contextuals.category.length > 0) {
        setCategoryData(objectModal(contextuals.category))
      }
    }
  }, [contextuals]);

  const createPlaylistHere = () => {
    setShowAlert(false)
  }

  const checkPartitionData = () => {
    if (videoContent.videoContentLanguage === '') {
      toastr.error('Please Enter Video Content Language')
      return false
    } else if (videoContent.videoContentCategory === '') {
      toastr.error("Pleae Enter Video Content Category")
    } else if (videoContent.clickThroughUrl === '') {
      toastr.error("Pleae Enter Click Through URL")
    } else {
      return true
    }
  }

  const downloadVideos = async () => {
    const videoDataArray = [];

    let validation = checkPartitionData();
    if (validation) {
      setLoader(true)
      setSpinner(true)

      for (const [index, videoId] of selected.entries()) {
        try {
          const response = await axios.post('/downloadvideoforplaylist', { url: `https://www.youtube.com/watch?v=${videoId}`, type: "content" });
          const videoData = response.data;


          const conversionResponse = await axios.post("/convertTohls", {
            videoFilePath: videoData.videoPath,
            videoFileName: videoData.filename
          });

          const fileNameRaw = videoData.filename

          const fileNameWithoutSpaces = fileNameRaw.replace(/ /g, '_');
      
          const fileNameWithoutExtension = fileNameWithoutSpaces.replace(/\.mp4$/, '');
      
          // Remove special characters except underscores
          const title = fileNameWithoutExtension.replace(/[^\w\s]/g, '');

          // Structure and save the video data
          const videoInfo = {
            videoContentOtherCategory: videoContent.videoContentCategory, // You can set the category
            videoContentOtherDuration: videoData.duration,
            videoContentOtherLanguage: videoContent.videoContentLanguage, // You can set the language
            videoContentOtherName: title,
            videoContentOtherMp4Path: videoData.videoPath,
            videoClickThroughUrl: videoContent.clickThroughUrl,
            videoContentOtherThumbnailPath: videoData.thumbnailPath,
            videoContentOtherCompressedMp4Path: conversionResponse.data.compressedVideoFilePath, // Set this path if you have a compressed version
            videoContentOtherTsPath: conversionResponse.data.videoOutputFiles, // Array of paths if you have segmented video files
            videoContentOtherM3u8Path: conversionResponse.data.m3u8FilePath, // M3U8 playlist path if applicable
            isUploadedContent: true,
            isSupplyPartner:userInfo.isSupplyPartner
          };

          dispatch(onAddPubVideoContent({...videoInfo}))
          videoDataArray.push(videoInfo);
          console.log(videoDataArray)
        } catch (error) {
          setLoader(false)
          setSpinner(false)
          console.error(`Error downloading video with ID ${videoId}: ${error.message}`);
          break;
        }

        // Update progress
        setProgress(Math.floor((index + 1) / selected.length * 100));
      }

      toastr.success("Successfully Converted")
      setLoader(false)
      setSpinner(false)
      setProgress(0)
      setShowAlert(false)
      handleClose()
      setDownloadedVideos(videoDataArray);
    }

  }

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.pubVideoContents.errorMsg,
    successMsg: state.pubVideoContents.successMsg,
}));


useEffect(() => {
    console.log(successMsg)
    if (successMsg !== null) {
        if (successMsg.success) {
            dispatch(addPubVideoContentSuccess(""));
            // setLoader(false)
            toastr.success("Video content added to the video Content library")

        }
    }
}, [successMsg]);

useEffect(() => {
    console.log(errorMsg)
    if (errorMsg !== null && errorMsg !== '') {
        // setLoader(false)
        dispatch(addPubVideoContentFail(""));
        toastr.error("Video content duplicates are not allowed!")
    }
}, [errorMsg]);


  return (
    <>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      <div className="page-content">
        <Helmet>
          <title>Video Import | Bidsxchange</title>
        </Helmet>
        <Container fluid={true} >
          <div className='d-flex justify-content-between align-items-center'>
            <Breadcrumb title="Video Import" breadcrumbItem="Video Import" />
            {/* <Button
                color="primary"
                className="font-16 btn-block btn btn-primary"
                onClick={toggleSetPlaylsit}>
                <i className="mdi mdi-plus-circle-outline me-1" />
                Import
              </Button> */}
          </div>
          {/* <Row>

              {/* {spinner && <i class='bx bx-loader-circle bx-md bx-spin'></i>

            </Row> */}

          <Row>
            <Col sm="12">
              <Row>
                <Col sm="12">
                  <div className="d-flex flex-column">
                    <Label htmlFor="youtube-link">Enter Playlist link:</Label>
                    <Input className='w-90' type="text" id="youtube-link" name="youtube-link" placeholder="https://www.youtube.com/watch?v=..." value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
                    {/* {videoId && <iframe src={embedUrl} title="Embedded YouTube video" width="560" height="315" />} */}
                    <div className="d-flex justify-content-end align-items-center">
                      <Button className="mt-3 mb-3 bg-primary" onClick={handleDownloadClick}>Get Info</Button>
                    </div>
                    {/* {loader &&
                                    <Progress
                                        className="mt-2"
                                        animated
                                        color="success"
                                        style={{
                                            height: '15px'
                                        }}
                                        striped
                                        value={uploadProgress}
                                    >{uploadProgress}%</Progress>
                                } */}

                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            {playlist.length !== 0 &&
              <Col lg="12">
                <ToolkitProvider
                  keyField={keyField}
                  data={playlist}
                  columns={videoListCoulumns}
                  // bootstrap4
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col sm="8">
                          <div className="search-box ms-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          {selectedRowsStatus && selectedIndex.length > 0 &&
                            <div className="row m-0 React-Table-Selection">
                              {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                              <div style={{ padding: '0.5% 2%' }}>

                                {/* <button color="primary" className="btn btn-primary " onClick={statusAdvertiser(true)}>Activate</button> */}
                                {false &&
                                  <button color="primary" className="btn btn-primary " onClick={''}>
                                    Download All
                                  </button>
                                }
                                &nbsp;  &nbsp;
                                {false &&
                                  <button color="primary" className="btn btn-primary " onClick={''}>
                                    Download Local
                                  </button>
                                }
                                &nbsp;  &nbsp;
                                {true &&
                                  <Button color="primary" className="btn btn-primary " onClick={() => setShowAlert(!showAlert)} >
                                    Add to Video Content
                                  </Button>
                                }
                                <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                  className="mdi mdi-close-thick font-size-18"
                                ></i></Button>
                              </div>
                            </div>
                          }
                        </Col>
                        <Col xl="12" className="table-responsive">
                          <div >
                            <BootstrapTable
                              keyField={keyField}
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              selectRow={selectRow}
                              // rowEvents={rowEvents}
                              classes={
                                "table align-middle table-nowrap"
                              }
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              pagination={paginationFactory(pageOptions)}
                              noDataIndication={() => renderNoData()}
                            // ref={node}
                            />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>

              </Col>
            }
          </Row>
          {switchLoader &&
              <LoadingTable loading={switchLoader} targetLoading={handleSwitch} />
            }
        </Container>
      </div>
      {/* </div> */}

      <Offcanvas isOpen={addPlaylist} direction='end'  >
        <OffcanvasHeader toggle={toggleSetPlaylsit}>
          {'Import'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Add_Import getPlaylistInfo={getPlaylistInfo} toggleSetPlaylsit={toggleSetPlaylsit} />
        </OffcanvasBody>
      </Offcanvas>

      <Modal size="xl" isOpen={showAlert} toggle={() => { setShowAlert(!showAlert) }} backdrop={'static'} centered={true}
        id="staticBackdrop"
      >
        <div className="modal-content">
          {true ?
            <ModalBody className="px-2 py-4 text-center ">
              <i className="bx bx-error-circle text-info fon-size-32" style={{ fontSize: '2rem' }}></i> &nbsp;
              <h5 className="text-muted font-size-16 mb-4"> Video Content Details</h5>
              <div className="hstack gap-2 justify-content-center mb-0">
                <Col sm={6} style={{ textAlign: "left" }} >
                  {/* <div className="mb-3">
                  <Label className="form-label ">Playlist Name</Label>

                  <Input
                    name="videoContentName"
                    type="text"
                    onChange={handleChange}
                    value={videoContent.videoContentName}
                    placeholder="Name..."
                  />

                </div> */}

                  <div className="mb-3">
                    <Label>Language</Label>
                    <CreatableSelect
                      onChange={(event, actionMeta) => {
                        handleLanguageChange(event, actionMeta)
                      }}
                      onInputChange={handleInputChange}
                      options={languageData}
                      value={selectedLanguage}
                    />

                  </div>

                  <div className="mb-3">
                    <Label>Category</Label>
                    <div className='row'>
                      <CreatableSelect
                        onChange={(event, actionMeta) => {
                          handleCategoryChange(event, actionMeta)
                        }}
                        onInputChange={handleInputChangeCategory}
                        options={categoryData}
                        value={selectedCategory}
                      />

                    </div>
                  </div>

                  <div className="mb-3">
                    <Label className="form-label ">Click Through URL</Label>

                    <Input
                      name="clickThroughUrl"
                      type="text"
                      onChange={handleChange}
                      value={videoContent.clickThroughUrl}
                      placeholder="Link..."
                    />

                  </div>

                  {
                    spinner &&
                    <div className="d-flex justify-content-center align-items-center mt-1">

                      <Button
                        color="primary"
                        disabled
                      >
                        <Spinner size="sm">
                          Loading...
                        </Spinner>

                        <span >
                          {' '}{"These files are being compressed and converted to a streamable format. Please wait..."}
                        </span>
                      </Button>
                    </div>

                  }

                  {loader &&
                    <Progress
                      className="mt-2 mb-2"
                      animated
                      color="success"
                      style={{
                        height: '15px'
                      }}
                      striped
                      value={progress}
                    >{progress}%</Progress>
                  }
                  <div>
                    <Button className='bg-primary' onClick={() => downloadVideos()}>Create</Button>
                    &nbsp;&nbsp;
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      // eslint-disable-next-line react/prop-types
                      onClick={() => setShowAlert(!showAlert)}
                    >
                      Back
                    </button>
                  </div>

                </Col>
              </div>
            </ModalBody>
            :
            <ModalBody className='px-2 py-4 text-center'>

              <div className='col-12 text-center' style={{ padding: '15%' }} >
                <div className="avatar-md mx-auto mb-3"><div className="avatar-title rounded-circle bg-light"><i className="bx  bx bxs-lock h1 mb-0 text-primary"></i></div></div>
                <h4>Video Import Feature has not been enabled for your account.  To access this feature please complete CMS configuration.</h4>
                <Link
                  type="button" to="#"
                  className="btn btn-outline-primary mt-4"
                // eslint-disable-next-line react/prop-types
                // onClick={handleRequest}
                >
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2 ml-2 mr-2" ></i>
                  Opt For our CDN
                </Link>
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary mt-4"
                  // eslint-disable-next-line react/prop-types
                  onClick={() => setShowAlert(!showAlert)}
                >
                  Explore
                </button>
              </div>
            </ModalBody>
          }
        </div>


      </Modal>
    </>
  )
}

Video_Import_Pub.propTypes = {}

export default Video_Import_Pub