import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";


// Pages Components
import WelcomeComp from "./WelcomeComp";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import DateRangePickers from '../../helpers/DateRangePicker'

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

const AdOpsDashBoard = props => {
    const [modal, setmodal] = useState(false);
    const [subscribemodal, setSubscribemodal] = useState(false);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
  
    const changeDatePick = (event) => {
      console.log(event)
      console.log("start: ", event.startDate._d);
      console.log("end: ", event.endDate._d);
      setFromDate(event.startDate._d.toISOString());
      setToDate(event.endDate._d.toISOString());
    }
  
    const { chartsData } = useSelector(state => ({
      chartsData: state.Dashboard.chartsData
    }));
  
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    console.log(userInfo)
  
 
    return (
      <React.Fragment>
        <div className="page-content">
          <Helmet>
            <title>AdOps Dashboard | Bidsxchange</title>
          </Helmet>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={props.t("AdOps Dashboard")}
              breadcrumbItem={props.t("AdOps Dashboard")}
            />
            <Row>
              <Col md="8">
              </Col>
              <Col md="4">
              <div className=" text-sm-end mb-4">
                   <DateRangePickers datePick={changeDatePick}  />
               </div>
              </Col>
            </Row>
  
            <Row>
              <Col xl="12">
                <WelcomeComp />
              </Col>
                </Row>
          </Container>
        </div>

      </React.Fragment>
    );
  };
  

  AdOpsDashBoard.propTypes = {
    t: PropTypes.any,
}

export default withTranslation()(AdOpsDashBoard)