import {
  LOAD_ACTIVITIES,
  NO_DATA_HOUSE_AD_CREATIVES,
    GET_HOUSE_AD_CREATIVES,
    GET_HOUSE_AD_CREATIVES_FAIL,
    GET_HOUSE_AD_CREATIVES_SUCCESS,
    ADD_HOUSE_AD_CREATIVE,
    ADD_HOUSE_AD_CREATIVE_SUCCESS,
    ADD_HOUSE_AD_CREATIVE_ERROR,
    UPDATE_HOUSE_AD_CREATIVE,
    UPDATE_HOUSE_AD_CREATIVE_SUCCESS,
    UPDATE_HOUSE_AD_CREATIVE_ERROR,
    READ_HOUSE_AD_CREATIVE,
    READ_HOUSE_AD_CREATIVE_SUCCESS,
    STATUS_HOUSE_AD_CREATIVE,
    STATUS_HOUSE_AD_CREATIVE_SUCCESS,
    ARCHIVE_HOUSE_AD_CREATIVE ,
    ARCHIVE_HOUSE_AD_CREATIVE_SUCCESS

  } from "./actionTypes"

  export const loadActivitiesAdHouseAdCreatives = Params => ({
    type: LOAD_ACTIVITIES,
    payload:Params
  })

  export const noHouseAdCreatives = Params => ({
    type: NO_DATA_HOUSE_AD_CREATIVES,
    payload:Params
  })

  export const getHouseAdCreatives = Params => ({
    type: GET_HOUSE_AD_CREATIVES,
    payload:Params
  })
  
  export const getHouseAdCreativesSuccess = HouseAdCreatives => ({
    type: GET_HOUSE_AD_CREATIVES_SUCCESS,
    payload: HouseAdCreatives,
  })

  export const getHouseAdCreativesFail = error => ({
    type: GET_HOUSE_AD_CREATIVES_FAIL,
    payload: error,
  })

  export const addNewHouseAdCreative = HouseAdCreative => ({
    type: ADD_HOUSE_AD_CREATIVE,
    payload: HouseAdCreative,
  })
  
  export const addHouseAdCreativeSuccess = creative => ({
    type: ADD_HOUSE_AD_CREATIVE_SUCCESS,
    payload: creative,
  })
  
  export const addHouseAdCreativeFail = creative => ({
    type: ADD_HOUSE_AD_CREATIVE_ERROR,
    payload: creative,
  })

  export const updateHouseAdCreative = creative => ({
    type: UPDATE_HOUSE_AD_CREATIVE,
    payload: creative,
  })
  
  export const updateHouseAdCreativeSuccess = creative => ({
    type: UPDATE_HOUSE_AD_CREATIVE_SUCCESS,
    payload: creative,
  })
  
  export const updateHouseAdCreativeFail = creative => ({
    type: UPDATE_HOUSE_AD_CREATIVE_ERROR,
    payload: creative,
  })

  export const readHouseAdCreative = creative => ({
    type: READ_HOUSE_AD_CREATIVE,
    payload: creative,
  })

  export const readHouseAdCreativeSuccess = creative => ({
    type: READ_HOUSE_AD_CREATIVE_SUCCESS,
    payload: creative,
  })

  export const statusHouseAdCreative = creative => ({
    type: STATUS_HOUSE_AD_CREATIVE,
    payload: creative,
  })

  export const statusHouseAdCreativeSuccess = creative => ({
    type: STATUS_HOUSE_AD_CREATIVE_SUCCESS,
    payload: creative,
  })

  export const archiveHouseAdCreative = creative => ({
    type: ARCHIVE_HOUSE_AD_CREATIVE,
    payload: creative,
  })

  export const archiveHouseAdCreativeSuccess = creative => ({
    type: ARCHIVE_HOUSE_AD_CREATIVE_SUCCESS,
    payload: creative,
  })