import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
//notification  

// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import {
    Col, Container, Row, Input, Label, Form, Button, Card,
    CardBody,
} from "reactstrap"
import Toaster from "pages/Toaster/Toaster";


//Notifications 


const New_Product_Notification = props => {
    const [notificationState, setNotificationState] = useState(false)
    const [notificationData, setNotificationData] = useState({
        notification: props.selectedPub_Notification.NotificationData || '',
    })

    //code by vani
    const [toastText, setToastText] = useState()
    const [warningChecked, setWarningChecked] = useState(false)
    const [infochecked, setInfoChecked] = useState(false)
    const [saveWarnData, setWarnData] = useState(false)
    const [saveInfoData, setInfoData] = useState(false)
    const notificationStateChange = e => {
        setNotificationState(e.target.checked)
    }
    //const [notificationText, setNotificationText] =useState(props.selectedPub_Notification.NotificationData)
   
    const handleChange = (e) => {
        const { name, value } = e.target;
        //code by vani 
        setToastText(e.target.value)
        //done
        // if (notificationState) {
        //     toastr.info(value)
        // }
        setNotificationData(notificationData => ({ ...notificationData, [name]: value }));
    }
   
 console.log(props.selectedPub_Notification,'testing')

    //code by vani
    function saveFunc() {
        if (warningChecked) {
            setWarnData(true)
            setInfoData(false)
        }
        else if (infochecked) {
            setInfoData(true)
            setWarnData(false)

        }
    }

    return (
        <React.Fragment>
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>

                            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customSwitchsizemd"
                                    checked={notificationState}
                                    onChange={notificationStateChange}
                                    style={{ cursor: 'pointer' }}
                                />
                                <label className="form-check-label">
                                    {notificationState ? <span color='text-success' >Disable Notification</span> : <span >Enable Notification</span>}
                                </label>



                            </div>
                            {/* {notificationState &&
                                <Input
                                    type="textarea"
                                    id="textarea"
                                    rows="3"
                                    onChange={handleChange} name="notification" value={notificationData.notification}
                                    className='form-control'
                                />
                            } */}{/*coded by vani*/}

                            <div className="form-check">
                                <input className="form-check-input" type="radio" onChange={() => { setInfoChecked(true); setWarningChecked(false) }} style={{ backgroundColor: infochecked ? 'green' : '' }} name="flexRadioDefault" id="flexRadioDefault1"  />
                                <label className="form-check-label"  >Info</label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" onChange={() => { setWarningChecked(true); setInfoChecked(false) }}
                                    name="flexRadioDefault" id="flexRadioDefault2" style={{ backgroundColor: warningChecked ? 'orange' : '' }} />
                                <label className="form-check-label" >Warning</label>
                            </div>
                            <Input
                                type="textarea"
                                id="textarea"
                                rows="3"
                                onChange={handleChange} name="notification" value={notificationData.notification}
                                className='form-control'
                                disabled={!notificationState}

                            />

                            <button
                                type="submit"
                                className="btn btn-primary save-user mt-4"
                                onClick={saveFunc}
                            >
                                Save
                            </button>
                            <Row>
                                <span className="mt-3"><b><i>Note: After every notification is enabled / disabled save the state by clicking the save button.</i></b></span>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {/*code by vani*/}
            {infochecked && saveInfoData && !warningChecked &&
                <Toaster status="info" msg={toastText}   timeOut={0} extendedTimeOut={0} />
            }
            {warningChecked && !infochecked && saveWarnData &&
                <Toaster status="warning" msg={toastText}   timeOut={0} extendedTimeOut={0}  />
            }
            {/*Done*/}
        </React.Fragment >
    )
}

//New_Product_Notification.propTypes = {}
//code by vani
New_Product_Notification.propTypes = {
    selectedPub_Notification: PropTypes.any,
  };

export default New_Product_Notification

