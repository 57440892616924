import {
    LOAD_ACTIVITIES,
    NO_DATA,
    GET_CHILDADUNITS_SUCCESS,
    GET_CHILDADUNITS_FAIL,
    ADD_CHILDADUNIT_SUCCESS,
    ADD_CHILDADUNIT_ERROR,
    UPDATE_CHILDADUNIT_SUCCESS,
    UPDATE_CHILDADUNIT_ERROR,
    READ_CHILDADUNIT_SUCCESS,
    STATUS_CHILDADUNIT_SUCCESS,
    ARCHIVE_CHILDADUNIT_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    childAdUnitList: [],
    pagination:{},
    error: {},
    noData:false,
    loading:false,
    successMsg: null,
    errorMsg: null,
    childAdUnit: null,
    response:null,
    archiveMsg:null,
}

const childAdUnits = (state = INIT_STATE, action) => {
    console.log
    switch (action.type) {
        case LOAD_ACTIVITIES:
            return {
                ...state,
                loading: true,
            }
            case NO_DATA:
                return {
                    ...state,
                    noData: true,
                }
        case GET_CHILDADUNITS_SUCCESS:
            return {
                ...state,
                loading:false,
                childAdUnitList: action.payload.response.childAdUnits ? action.payload.response.childAdUnits : [],
                pagination:action.payload.response
            }

        case GET_CHILDADUNITS_FAIL:
            return {
                ...state,
                loading:false,
                error: action.payload,
            }
        case ADD_CHILDADUNIT_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_CHILDADUNIT_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_CHILDADUNITS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_CHILDADUNIT_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_CHILDADUNITS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_CHILDADUNIT_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_CHILDADUNITS_SUCCESS' ? null : action.payload,
            }
        case READ_CHILDADUNIT_SUCCESS:
            return {
                ...state,
                childAdUnit: action.payload,
            }
        case STATUS_CHILDADUNIT_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_CHILDADUNIT_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default childAdUnits