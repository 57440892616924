/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
    Row, Col, Input, Label, Form, FormFeedback, DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown, Spinner, Collapse
} from "reactstrap";
import Select from "react-select";
import axios from "axios";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

var templateName = ''
var creativeBody = {}
var creativeBodyInfo = []

const TemplateSettings = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const dispatch = useDispatch();
    const [templateChanged, setTemplateChanged] = useState(false)
    console.log(props)

    const [creativeData, setCreativeData] = useState([])

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            name: userInfo.name,
        }
    }

    const [finalData, setFinalData] = useState({
        highImpactSettingTemplateName: '',
        highImpactSettingData: {}
    })

    useEffect(() => {
        if (props.selectedTemplate) {
            if (props.selectedHighImpact !== '') {
                setTemplateChanged(true)
                if (templateChanged) {
                    props.loaderState(true)
                    setFinalData({
                        highImpactSettingTemplateName: '',
                        highImpactSettingData: {}
                    })
                    creativeBody = {}
                    props.selectedItems({ highImpactSettingData: {} })
                    creativeBodyInfo = []
                    console.log(props.selectedTemplate)
                    handleFreshData(props.selectedTemplate)
                }
            } else {
                props.loaderState(true)
                setTemplateChanged(true)
                setFinalData({
                    highImpactSettingTemplateName: '',
                    highImpactSettingData: {}
                })
                creativeBody = {}
                props.selectedItems({ highImpactSettingData: {} })
                creativeBodyInfo = []
                console.log(props.selectedTemplate)
                handleFreshData(props.selectedTemplate)
            }

            // props.loaderState(true)
            // setTemplateChanged(true)
            // setFinalData({
            //     highImpactSettingTemplateName: '',
            //     highImpactSettingData: {}
            // })
            // creativeBody = {}
            // props.selectedItems({ highImpactSettingData: {} })
            // creativeBodyInfo = []
            // console.log(props.selectedTemplate)
            // handleFreshData(props.selectedTemplate)
        }
    }, [props.selectedTemplate])


    useEffect(() => {
        console.log(props.selectedHighImpact)
        creativeBodyInfo = []
        if (props.selectedHighImpact !== '') {
            props.loaderState(true)
            setFinalData({
                highImpactSettingTemplateName: '',
                highImpactSettingData: {}
            })
            creativeBody = {}
            props.selectedItems({ highImpactSettingData: {} })
            creativeBodyInfo.push(props.selectedHighImpact.highImpactSettingData)
            setFinalData(finalData => ({ ...finalData, highImpactSettingData: props.selectedHighImpact.highImpactSettingData }));
            props.selectedItems({ highImpactSettingData: props.selectedHighImpact.highImpactSettingData })
            handleData(props.selectedHighImpact.highImpactSettingTemplateName)
        }
        console.log(creativeBodyInfo)
    }, [])


    const contentChange = index => e => {
        console.log(e.target)
        let newArr = [...creativeData];
        if (e.target.type === 'checkbox') {
            newArr[index].checked = e.target.checked;
            setCreativeData(newArr);
        } else {
            newArr[index].value = e.target.value;
            setCreativeData(newArr);
        }
        let countChecked = 0
        console.log(creativeData)
        creativeData.map((item, index) => {
            const { label, value } = item;
            console.log(item, value)
            let numMul = isNaN(value)
            if (item.variableType === "NUMERIC" && numMul) {
                if (value) {
                    console.log(value)
                    creativeBody = { ...creativeBody, [label]: parseInt(value) }
                }
            } else if (item.variableType === "JSONArray") {
                if (typeof value === "string") {
                    let urls = value.split(/[\s,]+/);
                    creativeBody = { ...creativeBody, [label]: urls }
                } else {
                    creativeBody = { ...creativeBody, [label]: value }
                }
            } else if (item.variableType === "CHECKBOX") {
                if (item.checked) {
                    countChecked = countChecked + 1
                    creativeBody = { ...creativeBody, [label]: item.description }
                } else {
                    creativeBody = { ...creativeBody, [label]: '' }
                }
            }
            else {
                if (value === 'YES') {
                    creativeBody = { ...creativeBody, [label]: true }
                } else if (value === 'NO') {
                    creativeBody = { ...creativeBody, [label]: false }
                } else {
                    creativeBody = { ...creativeBody, [label]: value }
                }
            }
            console.log(creativeBody)
            setFinalData(finalData => ({ ...finalData, highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } }))
            props.selectedItems({ highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } })
        })
        console.log(creativeBody)
        console.log(finalData)
        setCreativeData(modifyData(countChecked, creativeData))
    }


    const handleData = async (template) => {
        console.log(creativeBody)
        const bodyData = {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                name: userInfo.name,
                creativeTemplateName: template,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
        }
        axios.post("/v2.0/operations/CreativeTemplates/getVariable", bodyData,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is etttgdfg ==>", res)
                if (res.data) {
                    setCreativeData([])
                    creativeBody = {}
                    templateName = res.data.response.creativeTemplateName
                    creativeBody = { ...creativeBody, url: res.data.response.url }
                    setFinalData(finalData => ({ ...finalData, highImpactSettingTemplateName: templateName, highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } }))
                    props.selectedItems({ highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody }, highImpactSettingTemplateName: templateName })
                    let countChecked = 0
                    res.data.response.creativeTemplateVariables.map((item, index) => {
                        let label = item.label
                        if (props.selectedHighImpact !== '') {
                            console.log(creativeBodyInfo)

                            if (creativeBodyInfo[0][label] === true) {
                                item.value = 'YES'
                                item.checked = true
                            } else if (creativeBodyInfo[0][label] === false) {
                                item.value = 'NO'
                                item.checked = false
                            } else {
                                item.value = creativeBodyInfo[0][label]
                            }
                            if (item.variableType === "CHECKBOX") {
                                if (creativeBodyInfo[0][label] !== '') {
                                    item.checked = true
                                    item.isDisabled = false
                                } else if (creativeBodyInfo[0][label] === '') {
                                    item.checked = false
                                    item.isDisabled = false
                                }
                            }
                        } else {
                            item.value = item.defaultValue ? item.defaultValue : ''
                            if (item.variableType === "CHECKBOX") {
                                item.checked = false;
                                item.isDisabled = false
                            }
                        }
                        if (label.includes('url')) {
                            item.isUrlContains = false
                        }
                    })
                    setCreativeData(modifyData(countChecked, res.data.response.creativeTemplateVariables));
                    res.data.response.creativeTemplateVariables.map((item, index) => {
                        if (item.variableType === "CHECKBOX") {
                            const { label, checked } = item;
                            creativeBody = { ...creativeBody, [label]: checked }
                        } else {
                            const { label, value } = item;
                            creativeBody = { ...creativeBody, [label]: value }
                        }
                        const { label, value } = item;
                        if (value === 'YES') {
                            creativeBody = { ...creativeBody, [label]: true }
                        } else if (value === 'NO') {
                            creativeBody = { ...creativeBody, [label]: false }
                        } else {
                            creativeBody = { ...creativeBody, [label]: value }
                        }
                        setFinalData(finalData => ({ ...finalData, highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } }))
                        props.selectedItems({ highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } })
                
                    })
                    props.loaderState(false)
                    setCreativeData(modifyData(countChecked, res.data.response.creativeTemplateVariables));
                }
            })
            .catch((err) => {
                props.loaderState(false)
                console.log("e", err)
            });
    }

    const handleFreshData = async (template) => {
        setCreativeData([])
        creativeBodyInfo = []
        creativeBody = {}
        const bodyData = {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                name: userInfo.name,
                creativeTemplateName: template,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
        }
        axios.post("/v2.0/operations/CreativeTemplates/getVariable", bodyData,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is etttgdfg ==>", res)
                if (res.data) {
                    templateName = res.data.response.creativeTemplateName
                    creativeBody = { ...creativeBody, url: res.data.response.url }
                    setFinalData(finalData => ({ ...finalData, highImpactSettingTemplateName: templateName, highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } }))
                    props.selectedItems({ highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody }, highImpactSettingTemplateName: templateName })
                    let countChecked = 0
                    res.data.response.creativeTemplateVariables.map((item, index) => {
                        let label = item.label
                        item.value = item.defaultValue ? item.defaultValue : ''
                        if (item.variableType === "CHECKBOX") {
                            item.checked = false;
                            item.isDisabled = false
                        }
                        if (item.variableType === "JSONArray") {
                            item.value = [""]
                        }
                        if (label.includes('url')) {
                            item.isUrlContains = false
                        }
                    })
                    setCreativeData(modifyData(countChecked, res.data.response.creativeTemplateVariables));
                    res.data.response.creativeTemplateVariables.map((item, index) => {
                        if (item.variableType === "CHECKBOX") {
                            const { label, checked } = item;
                            creativeBody = { ...creativeBody, [label]: checked }
                        } else {
                            const { label, value } = item;
                            creativeBody = { ...creativeBody, [label]: value }
                        }
                        const { label, value } = item;
                        if (value === 'YES') {
                            creativeBody = { ...creativeBody, [label]: true }
                        } else if (value === 'NO') {
                            creativeBody = { ...creativeBody, [label]: false }
                        } else {
                            creativeBody = { ...creativeBody, [label]: value }
                        }
                        setFinalData(finalData => ({ ...finalData, highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } }))
                        props.selectedItems({ highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } })                     
                    })
                    props.loaderState(false)
                }
            })
            .catch((err) => {
                props.loaderState(false)
                console.log("e", err)
            });
    }


    function allowAlphaNumericSpace(e) {
        var code = ('charCode' in e) ? e.charCode : e.keyCode;
        if (!(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault(); }
    };

    const modifyData = (count, Variabledata) => {
        console.log(Variabledata)
        Variabledata.map((item, index) => {
            index = index + 1
            if (item.variableType === "CHECKBOX") {
                if (count === 5) {
                    if (item.checked) {
                        item.isDisabled = false
                    } else {
                        item.isDisabled = true
                    }
                } else {
                    item.isDisabled = false
                }
            }
            if (index > 4 && count === 5 && index < Variabledata.length) {
                // console.log(index)
                // console.log(Variabledata[index])
                // console.log(Variabledata[index-1])
                if ((Variabledata[index].description).includes(Variabledata[index - 1].description)) {
                    if (Variabledata[index - 1].variableType === "CHECKBOX" && !Variabledata[index - 1].checked) {
                        Variabledata[index].isUrlContains = true
                    }
                }
            } else {
                if (index < Variabledata.length && (Variabledata[index].description).includes(Variabledata[index - 1].description)) {
                    Variabledata[index].isUrlContains = false
                }
            }
        })
        return Variabledata
    }


    const createUI = () => {
        return creativeData.map((item, i) =>
            <div key={i}>
                {item.variableType === "STRING" &&
                    <div className="mt-4" >
                        <Label className="form-label">{item.description}</Label>
                        {item.defaultValue &&
                            <Input
                                type="text" name={item.label} value={item.value} onChange={contentChange(i)} className='form-control' readOnly={props.selectedArchived || (item.isUrlContains ? item.isUrlContains : false)}
                            />
                        }
                        {!item.defaultValue &&
                            <Input
                                type="text" name={item.label} value={item.value} onChange={contentChange(i)} className='form-control' readOnly={props.selectedArchived || (item.isUrlContains ? item.isUrlContains : false)}
                            />
                        }

                    </div>
                }
                {item.variableType === "NUMERIC" &&
                    <div className="mt-4">
                        <Label className="form-label">{item.description}</Label>
                        <Input
                            type="number" name={item.label} value={item.value ? parseInt(item.value) : ''} onChange={contentChange(i)} className='form-control' readOnly={props.selectedArchived} onWheel={(e) => e.currentTarget.blur()}
                        />
                    </div>
                }
                {item.variableType === "LONG TEXT" &&
                    <div className="form-group mt-4">
                        <Label className="form-label">{item.description}</Label>
                        <Input type="textarea" id="textarea"
                            rows="3" placeholder={item.label} className="form-control" style={{ width: '100%' }}
                            name={item.label} value={item.value} onChange={contentChange(i)} readOnly={props.selectedArchived}
                        />
                    </div>
                }
                {item.variableType === "JSONArray" &&
                    <div className="form-group mt-4">
                        <Label className="form-label">{item.description}</Label>
                        <Input type="textarea" id="textarea"
                            rows="3" placeholder={item.label} className="form-control" style={{ width: '100%' }}
                            name={item.label} value={item.value} onChange={contentChange(i)} readOnly={props.selectedArchived}
                        />
                    </div>
                }
                {item.variableType === "LIST" &&
                    <div className="form-group mt-4">
                        <Label className="form-label">{item.description}</Label>
                        <Input
                            type="select"
                            className="form-select"
                            onChange={contentChange(i)}
                            name={item.label} value={item.value} disabled={props.selectedArchived}
                        >
                            {/* <option value=''> Select {item.label}</option> */}
                            {item.choices.map((item) => (
                                <option key={item} value={item}>{item}</option>
                            ))}
                        </Input>
                    </div>
                }
                {item.variableType === "CHECKBOX" &&
                    <div className="form-check mt-2 mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={item.checked}
                            disabled={props.selectedArchived || item.isDisabled}
                            onChange={contentChange(i)}
                        />
                        <label
                            className="form-check-label"
                        >
                            {item.description}
                        </label>
                    </div>
                }
            </div>
        )
    }

    return (
        <React.Fragment>

            <Row className="formBodyScroll" style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
                <Col xs={8}>
                    {props.selectedTemplate === "" ?
                        <h5 className="mb-4">Please Select Ad formats</h5> :
                        <div className="mb-3" >
                            <h5 className="mb-4">{templateName} Settings</h5>
                            <hr></hr>
                            <div className="mb-3">
                                {createUI()}
                            </div>
                        </div>
                    }
                </Col>
            </Row>
        </React.Fragment>
    );
};

TemplateSettings.propTypes = {}

export default TemplateSettings