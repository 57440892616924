import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from "axios";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Offcanvas,
    OffcanvasHeader,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    OffcanvasBody, Spinner, Label, Input, BreadcrumbItem, Modal, ModalBody,
} from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import Supply_Create_Partner from "./Supply_Create_Partner"

import {
    getPartners as onGetPartners,
    statusPartner as onStatusPartner,
    archivePartner as onArchivePartner,
    statusPartnerSuccess,
    archivePartnerSuccess,
    addPartnerFail,
    updatePartnerFail,
} from "store/PartnerManagement/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import LoadingTable from "pages/LoaderPages/LoadingTable";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var filterCount = 0
var selectedPartnerData = ''

var partners = []

const Supply_Partners_Mgmt = () => {
    const dispatch = useDispatch();
    const [isRight, setIsRight] = useState(false);
    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
    const [selected, setSelected] = useState([])
    const [switchLoader, setSwitchLoader] = useState(true)
    const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const userRole = localStorage.getItem('UserRole');
    const [statusSearch, setStatusSearch] = useState('Active')
    const [partnerTypeSearch, setPartnerTypeSearch] = useState('')
    const [showAlert, setShowAlert] = useState(false);

    const handleSwitch = () => {
        setSwitchLoader(!switchLoader)
    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            setIsRight(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const selectRow = {
        mode: "checkbox",
        nonSelectable: [],
        selected: selected,
        onSelect: (row, isSelect, rowIndex, e) => {
            console.log(row, isSelect, rowIndex)
            if (isSelect) {
                setSelected([...selected, row.id])
                setSelectedRowsStatus(true)
                selectedIndex.push(row.partnerID)
                selectedItem.push(row.partnerID)
                console.log(selectedIndex)
            } else {
                setSelected(selected.filter(x => x !== row.id))
                let index = selectedIndex.indexOf(row.partnerID)
                selectedIndex.splice(index, 1)
                if (selectedIndex.length === 0) {
                    setSelectedRowsStatus(false)
                }
                console.log(selectedIndex)
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            const ids = rows.map(r => r.id);
            if (isSelect) {
                setSelected(ids)
                rows.map((item, index) => {
                    selectedIndex.push(item.partnerID)
                })
                setSelectedRowsStatus(true)
            } else {
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
            }
        }
    };

    useEffect(() => {
        dispatch(onGetPartners(null));
    }, []);

    const [filterState, setFilterState] = useState(false)

    const statusChange = (e) => {
        if (e.target.value === 'Active') {
            filterCount = filterCount > 0 ? filterCount - 1 : 0
        }
        if (statusSearch === 'Active') {
            filterCount = filterCount + 1
        }
        setStatusSearch(e.target.value)
    }

    const partnerTypeChange = (e) => {
        if (e.target.value === '') {
            filterCount = filterCount > 0 ? filterCount - 1 : 0
        }
        if (partnerTypeSearch === '') {
            filterCount = filterCount + 1
        }
        setPartnerTypeSearch(e.target.value)
    }

    const applyFilter = (e) => {
        dispatch(onGetPartners({ filter: statusSearch, partnerType: partnerTypeSearch }));
        if (filterCount > 0) {
            setFilterState(true)
        } else {
            setFilterState(false)
        }
    }

    const [isMenu, setisMenu] = useState(false)
    const toggleMenu = () => {
        setisMenu(!isMenu)
    }

    const clearAllFilter = (e) => {
        setStatusSearch('Active')
        setPartnerTypeSearch('')
        filterCount = 0
        setFilterState(false)
        dispatch(onGetPartners({ filter: 'Active', partnerType: partnerTypeSearch }));
    }

    const handleClose = () => {
        setSelectedRowsStatus(false)
        selectedItem = []
        setSelected([])
        selectedIndex = []
    }



    // const [partners, setPartners] = useState([]);

    const { partnerDataInfo } = useSelector(state => ({
        partnerDataInfo: state.partners.partners
    }));

    const { loading } = useSelector(state => ({
        loading: state.partners.loading,
    }));

    const { noData } = useSelector(state => ({
        noData: state.partners.noData,
    }));

    const { partnersResponse } = useSelector(state => ({
        partnersResponse: state.partners.response,
    }));

    const { archivePartnersResponse } = useSelector(state => ({
        archivePartnersResponse: state.partners.archiveMsg,
    }));

    useEffect(() => {
        if (partnerDataInfo) {
            // setPartners(partnerDataInfo)
            partners = [...partnerDataInfo]
        }
    }, [partnerDataInfo]);

    useEffect(() => {
        console.log(loading)
        if (loading) {
            setSwitchLoader(true)
        }

    }, [loading]);

    console.log(partnersResponse)
    useEffect(() => {
        if (partnersResponse !== null) {
            if (partnersResponse.success) {
                toastr.success('Partner Status Changed Successfully')
                dispatch(onGetPartners({ filter: statusSearch, partnerType: partnerTypeSearch }));
                dispatch(statusPartnerSuccess(null));
                selectedItem = []
                selectedIndex = []
                setSelected([])
                setSelectedRowsStatus(false)
            }
        }
    }, [dispatch, partnersResponse]);

    useEffect(() => {
        if (archivePartnersResponse !== null) {
            if (archivePartnersResponse.success) {
                toastr.success('Partner Archived Successfully')
                dispatch(onGetPartners({ filter: statusSearch, partnerType: partnerTypeSearch }));
                dispatch(archivePartnerSuccess(null));
                selectedItem = []
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
            }
        }
    }, [dispatch, archivePartnersResponse]);

    const toggleRightCanvas = () => {
        selectedData = ''
        dispatch(onGetPartners({ filter: statusSearch, partnerType: partnerTypeSearch }));
        dispatch(addPartnerFail(null));
        dispatch(updatePartnerFail(null));
        setIsRight(!isRight);
    };
    const toggleEnableScroll = () => {
        setIsEnableScroll(!isEnableScroll);
    };

    const toggleDrawer = (close, partner) => (event) => {
        console.log(event)
        if (event === 'Create') {
            toastr.success('Partner Saved Successfully!...')
        }
        if (event === 'Update') {
            toastr.success('Partner Updated Successfully!...')
        }
        selectedData = partner
        dispatch(onGetPartners({ filter: statusSearch, partnerType: partnerTypeSearch }));
        dispatch(addPartnerFail(null));
        dispatch(updatePartnerFail(null));
        setIsRight(close);
    };

    useEffect(() => {
        if (partners !== null) {
            dispatch(onGetPartners({ filter: statusSearch, partnerType: partnerTypeSearch }));
        }
    }, []);

    const [emailShown, setEmailShown] = useState(false);
    const [password, setPassword] = useState('');

    const toggleEmailVisiblity = (partnerData) => (event) => {
        console.log(emailShown)
        setShowAlert(!showAlert)
        selectedPartnerData = partnerData
        // partnerData.emailShow = true
        // let partnersInfo = partners
        // partnersInfo.push(partnerData)
        // partners = partnersInfo
        // setEmailShown(emailShown ? false : true);
    };

    var Headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
    }

    const validateUser = (partnerData) => (event) => {
        const body = {
            username: userInfo.username,
            password: password
        }
        axios.post("/v1.0/authenticate/verify", body,
            {
                headers: Headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data.userVerified) {
                    setShowAlert(!showAlert)
                    dispatch(onGetPartners({ filter: statusSearch, partnerType: partnerTypeSearch,partnerID:partnerData.partnerID }));
                } else {
                    setShowAlert(!showAlert)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    };

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const partnerListColumns = [
        {
            text: "ID",
            headerClasses: "ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            text: "ID",
            headerClasses: "ID",
            dataField: "partnerID",
            sort: true,
        },
        {
            dataField: "userCreateTime",
            text: "Date",
            sort: true,
        },
        {
            text: "Name",
            dataField: "userName",
            sort: true,

        },
        {
            dataField: "userEmail",
            text: "Email",
            sort: true,
            hidden: (userInfo.userRole !== 'Root' || userRole !== 'Admin'),
            formatter: (cellContent, partner) => (
                <>
                    {partner.emailShow ? partner.userEmail : '************************'}
                    &nbsp;&nbsp;
                    {/* <span className="" >
                              <   i onClick={toggleEmailVisiblity(partner)} id="emailToggle" className={emailShown ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
                            </span> */}
                    <Link className="" to="#" onClick={toggleEmailVisiblity(partner)}>
                        <i
                            className="mdi mdi-eye font-size-18"
                            id="edittooltip"
                        ></i>
                    </Link>
                </>
            ),
        },
        {
            dataField: "partnerType",
            text: "Partner Type",
            sort: true,
        },
        {
            dataField: "userStatus",
            text: "Status",
            sort: true,
            formatter: (cellContent, partner) => (
                <>
                    {partner.userStatus &&
                        <span className="active-state">Active</span>
                    }
                    {!partner.userStatus &&
                        <span className="inactive-state">Inactive</span>
                    }
                </>
            ),
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',
            hidden: (UserRolePrivileges.PartnerManagement && UserRolePrivileges.PartnerManagement.PartnerAccess) && (!UserRolePrivileges.PartnerManagement.PartnerAccess.update),

            // eslint-disable-next-line react/display-name
            formatter: (cellContent, partner) => (
                <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
                    <Link className="text-success" to="#" onClick={toggleDrawer(true, partner)}>
                        <i
                            className="mdi mdi-pencil font-size-18"
                            id="edittooltip"
                        ></i>
                    </Link>
                </div>
            ),
        },
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );

    const { SearchBar } = Search;
    const pageOptions = {
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        totalSize: partners.length,
        custom: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };
    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const archivePartner = async () => {
        dispatch(onArchivePartner({ idList: selectedItem }));
    }

    const statusPartner = (state) => (event) => {
        console.log(state)
        dispatch(onStatusPartner({
            idList: selectedItem, userStatus: state, userlog: {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                name: userInfo.name
            },
        }));
    }

    if (partners.length > 0) {
        if (UserRolePrivileges.PartnerManagement && UserRolePrivileges.PartnerManagement.PartnerAccess) {
            if (!UserRolePrivileges.PartnerManagement.PartnerAccess.archive && !UserRolePrivileges.PartnerManagement.PartnerAccess.status) {
                partners.map((item, index) => {
                    selectRow.nonSelectable.push(index + 1)
                })
            } else {
                selectRow.nonSelectable = []
            }
        }
    }

    console.log(partners)

    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
            <div className="page-content">
                <Helmet>
                    <title>Supply Partner Management | Bidsxchange</title>
                </Helmet>
                <Container fluid={true}>
                    {/* Render Breadcrumbs */}

                    <div className=" d-sm-flex  justify-content-between">
                        <Breadcrumbs title="Home" breadcrumbItem="Supply Partner Management" />
                        {UserRolePrivileges.PartnerManagement && UserRolePrivileges.PartnerManagement.PartnerAccess && UserRolePrivileges.PartnerManagement.PartnerAccess.create &&
                            <div className="text-sm-end">
                                <div className="btn-group">
                                    <Dropdown
                                        direction="left"
                                        isOpen={isMenu}
                                        toggle={toggleMenu}
                                    >
                                        <DropdownToggle
                                            tag="button"
                                            className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                                        >
                                            <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                                            {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                                                Filter Applied
                                            </span> : <span className="d-none font-16 d-sm-inline-block">
                                                Filter
                                            </span>
                                            }
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                                            <div className="dropdown-item-text">
                                                <div className="row">
                                                    <h5 className="mb-0">Filters</h5>
                                                </div>
                                            </div>

                                            <DropdownItem divider />
                                            <Card>
                                                <CardBody aria-hidden="true">
                                                    <Row >
                                                        <Col sm="12" className="mt-2">
                                                            <Label className="form-label">Partner Type</Label>
                                                            <div className=" text-sm-end">
                                                                <Input type="select" className="form-select" value={partnerTypeSearch} onChange={partnerTypeChange}>
                                                                    <option value=''> All</option>
                                                                    <option value='network'>Network</option>
                                                                    <option value='publisher'>Publisher</option>
                                                                    <option value='agency'>Agency</option>
                                                                    <option value='platformPartner'>Platform Partner</option>
                                                                </Input>
                                                            </div>
                                                        </Col>
                                                        <Col sm="12" className="mt-2">
                                                            <Label className="form-label">Status</Label>
                                                            <div className=" text-sm-end">
                                                                <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                                                    <option value=''> All</option>
                                                                    <option value='Active' > Active</option>
                                                                    <option value='Inactive'> InActive</option>
                                                                </Input>
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                </CardBody>
                                            </Card>

                                            <DropdownItem divider />
                                            <Row>
                                                <Col sm="6">
                                                    <DropdownItem
                                                        className="text-primary text-center"
                                                        onClick={clearAllFilter}
                                                    >
                                                        Clear ALL
                                                    </DropdownItem>
                                                </Col>
                                                <Col sm="6">
                                                    <DropdownItem
                                                        className="text-primary text-center"
                                                        onClick={applyFilter}
                                                    >
                                                        Apply Filter
                                                    </DropdownItem>
                                                </Col>
                                            </Row>

                                        </DropdownMenu>
                                    </Dropdown>
                                </div> &nbsp;&nbsp;
                                <Button
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                    onClick={toggleRightCanvas}
                                >
                                    <i className="mdi mdi-plus-circle-outline me-1" />
                                    New Partner
                                </Button>
                            </div>}
                    </div>
                    {switchLoader &&
                        <LoadingTable loading={loading} targetLoading={handleSwitch} />
                    }
                    {!switchLoader &&
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField={keyField}
                                            columns={partnerListColumns}
                                            data={partners}
                                        >
                                            {({ paginationProps, paginationTableProps }) => {
                                                return (
                                                    <ToolkitProvider
                                                        keyField={keyField}
                                                        data={partners}
                                                        columns={partnerListColumns}
                                                        // bootstrap4
                                                        search
                                                    >
                                                        {toolkitProps => (
                                                            <React.Fragment>
                                                                <Row className="mb-2">
                                                                    <Col sm="4">
                                                                        <div className="search-box ms-2 mb-2 d-inline-block">
                                                                            <div className="position-relative">
                                                                                <SearchBar {...toolkitProps.searchProps} autocomplete="off" />
                                                                                <i className="bx bx-search-alt search-icon" />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm="8">

                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xl="12">
                                                                        {selectedRowsStatus && selectedIndex.length > 0 &&
                                                                            <div className="row m-0 React-Table-Selection">
                                                                                {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                                                                <div style={{ padding: '0.5% 2%' }}>

                                                                                    {/* <button color="primary" className="btn btn-primary " onClick={statusPartner(true)}>Activate</button> */}
                                                                                    {UserRolePrivileges.PartnerManagement && UserRolePrivileges.PartnerManagement.PartnerAccess && UserRolePrivileges.PartnerManagement.PartnerAccess.status &&
                                                                                        <button color="primary" className="btn btn-primary " onClick={statusPartner(true)}>
                                                                                            Activate
                                                                                        </button>
                                                                                    }
                                                                                    &nbsp;  &nbsp;
                                                                                    {UserRolePrivileges.PartnerManagement && UserRolePrivileges.PartnerManagement.PartnerAccess && UserRolePrivileges.PartnerManagement.PartnerAccess.status &&
                                                                                        <button color="primary" className="btn btn-primary " onClick={statusPartner(false)}>
                                                                                            DeActivate
                                                                                        </button>
                                                                                    }
                                                                                    &nbsp;  &nbsp;
                                                                                    {/* <Button color="primary" className="btn btn-primary " onClick={archivePartner} >
                                                                                        Archive
                                                                                    </Button> */}
                                                                                    <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                                                                        className="mdi mdi-close-thick font-size-18"
                                                                                    ></i></Button>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </Col>
                                                                    <Col xl="12">
                                                                        <div className="table-responsive">
                                                                            <BootstrapTable
                                                                                keyField={keyField}
                                                                                responsive
                                                                                bordered={false}
                                                                                striped={false}
                                                                                defaultSorted={defaultSorted}
                                                                                selectRow={selectRow}
                                                                                classes={
                                                                                    "table align-middle table-nowrap"
                                                                                }
                                                                                headerWrapperClasses={"thead-light"}
                                                                                {...toolkitProps.baseProps}
                                                                                {...paginationTableProps}
                                                                                noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                            // ref={node}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                {/* <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row> */}
                                                                <Row className="align-items-md-center mt-30">
                                                                    <Col className="inner-custom-pagination d-flex">
                                                                        <div className="d-inline">
                                                                            <PaginationTotalStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                        <div className="text-md-center d-inline " style={{ marginLeft: '5%' }} >
                                                                            <span>Show Rows : </span> &nbsp;&nbsp;
                                                                            <SizePerPageDropdownStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                        <div className="text-md-right ms-auto">
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                                );
                                            }}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </Container>
            </div>
            {/* </div> */}

            <Offcanvas isOpen={isRight} direction='end' className="CanvasLarge">
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {selectedData ? 'Edit Partner' : 'New Partner'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <Supply_Create_Partner closeCanvas={toggleDrawer(false, '')} selectedPartner={selectedData} />
                </OffcanvasBody>
            </Offcanvas>

            {/* <Offcanvas isOpen={isRight} scrollable toggle={toggleRightCanvas}>
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {selectedData ? 'Edit Partner' : 'New Partner'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <Create_Partner closeCanvas={toggleDrawer(false, '')} selectedPartner={selectedData} />
                </OffcanvasBody>
            </Offcanvas> */}

            <Modal size="sm" isOpen={showAlert} toggle={() => { setShowAlert(!showAlert) }} backdrop={'static'} centered={true}
                id="staticBackdrop"
            >
                <div className="modal-content">
                    <ModalBody className="px-2 py-4 text-center ">
                        <h5 className="text-muted font-size-16 mb-4">  Please Enter Your Password</h5>
                        <Input
                            name="password"
                            value={password}
                            type={"password"}
                            placeholder="Enter Password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className="hstack gap-2 justify-content-center mb-2 mt-3">
                            <button type="button" className="btn btn-light" onClick={() => { setShowAlert(!showAlert) }}>Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={validateUser(selectedPartnerData)}>Submit</button>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default Supply_Partners_Mgmt