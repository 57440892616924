/* eslint-disable react/prop-types */
import React from "react";
import {useRef} from 'react';
import $ from 'jquery';
import "./ProgressBar.scss"

const ProgressBar = (props) => {
    const ref = useRef(null);
    const {
        isLoading = props.percent === "loading",
        percent,
        size = "small",
        showInfo = false
    } = props;


    function progress(timeleft, timetotal, $element) {
        var progressBarWidth = timeleft * $element.width() / timetotal;
        $element.find('div').animate({ width: progressBarWidth }, timeleft == timetotal ? 0 : 1000, 'linear').html(timeleft + " seconds to go");
        if(timeleft > 0) {
            setTimeout(function() {
                progress(timeleft - 1, timetotal, $element);
            }, 1000);
        }
    };
    
    // progress(70, 100, $('#progressBar'));


    return (
        // <div className="progress-outer">
        //   <div
        //     className={`progress ${size ? "progress--" + size : ""} ${
        //       isLoading ? "progress--" + "loading" : ""
        //     }`}
        //   >
        //     <div className={`progress-bar`} style={{ width: percent + "%" }}></div>
        //   </div>

        //   {isLoading == false && showInfo ? (
        //     <span className="progress-info">{percent}%</span>
        //   ) : (
        //     ""
        //   )}
        // </div>
        <div id="wrapper">
        {/* <div id="mouse"></div>
        <div className="loader2">
        </div> */}
        <div className="loading-bar">
          <div className="progress-bar"></div>
        </div>
        <div className="status">
          <div className="state"></div>
          <div className="percentage"></div>
        </div>
      </div>



    );
};

export default ProgressBar;
