import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Input,
  Spinner,
  Label,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
} from "reactstrap"
import queryString from 'query-string';

import DateRangePickers from '../../../helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import {
  getChildAdUnit as onGetChildAdUnits,
  statusChildAdUnit as onStatusChildAdUnit,
  archiveChildAdUnit as onArchiveChildAdUnit,
  statusChildAdUnitSuccess,
  archiveChildAdUnitSuccess,
  addChildAdUnitFail,
  updateChildAdUnitFail,
} from "store/Supply/ChildAdUnit/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Create_ChildAdUnit from "./Create_ChildAdUnit";
import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";
import LoadingTable from "pages/LoaderPages/LoadingTable";
import LineItemDataTable from "Product_Helpers/ProductDataTable"
import ProductDataTableLI from "Product_Helpers/ProductDataTableLI";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var childAdUnitType = ''

var filterCount = 0
let pageNumber = 1
let pageSize = 10
let totalRecords = 0
let searchText = ''

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import ErrorPage from "pages/errorFile";
var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 2 : 1
var fromDate = ''
var toDate = ''

const ChildAdUnit = () => {
  const dispatch = useDispatch();
  const [isRight, setIsRight] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const parsed = queryString.parse(window.location.search);
  const [statusSearch, setStatusSearch] = useState('Active')
  const [isArchived, setIsArchived] = useState(false)
  const [selected, setSelected] = useState([])
  const [createState, setCreateState] = useState(true)
  const [btnprimary1, setBtnprimary1] = useState(false)
  const [childAdUnitTypeSearch, setChildAdUnitTypeSearch] = useState('')
  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));

  const [switchLoader, setSwitchLoader] = useState(true)

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }
  const [state, setState] = useState({
    start: moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
    end: moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
  });
  const { start, end } = state;
  const handleEvent = (startDate, endDate, label) => {
    setState({ start: startDate, end: endDate, });
    fromDate = startDate._d.toISOString();
    toDate = endDate._d.toISOString();
    filterCount = filterCount + 1
  }
  const labelDate = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {

      setIsRight(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const [filterState, setFilterState] = useState(false)

  const statusChange = (e) => {
    if (e.target.value === 'Active') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (statusSearch === 'Active') {
      filterCount = filterCount + 1
    }
    setStatusSearch(e.target.value)
  }

  const childAdUnitTypeChange = (e) => {
    if (e.target.value === '') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (childAdUnitTypeSearch === '') {
      filterCount = filterCount + 1
    }
    setChildAdUnitTypeSearch(e.target.value)
  }

  const applyFilter = (e) => {
    setisMenu(false)
    totalRecords = 0
    pageNumber = 1
    pageSize = 10
    if (parsed.adUnitId) {
      dispatch(onGetChildAdUnits({ adUnitID: parsed.adUnitId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, childAdUnitType: childAdUnitTypeSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', childAdUnitSize: '' }));
    } else {
      dispatch(onGetChildAdUnits({ adUnitID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, childAdUnitType: childAdUnitTypeSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', childAdUnitSize: '' }));
    }
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  const clearAllFilter = (e) => {
    setisMenu(false)
    setStatusSearch('Active')
    setChildAdUnitTypeSearch('')
    filterCount = 0
    setFilterState(false)
    fromDate = ''
    toDate = ''
    setState({
      start: moment().subtract(0, 'days'),
      end: moment().subtract(0, 'days'),
    })
    if (parsed.adUnitId) {
      dispatch(onGetChildAdUnits({ adUnitID: parsed.adUnitId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: 'Active', childAdUnitType: '', userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": '', "endDate": '', childAdUnitSize: '' }));
    } else {
      dispatch(onGetChildAdUnits({ adUnitID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: 'Active', childAdUnitType: '', userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": '', "endDate": '', childAdUnitSize: '' }));
    }
  }

  // const statusChange = (e) => {
  //   setStatusSearch(e.target.value)
  //   if (parsed.adUnitId) {
  //     dispatch(onGetChildAdUnits({ adUnitID: parsed.adUnitId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: e.target.value, childAdUnitType: childAdUnitTypeSearch,userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '', childAdUnitSize: '' }));
  //   } else {
  //     dispatch(onGetChildAdUnits({ adUnitID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: e.target.value, childAdUnitType: childAdUnitTypeSearch,userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '', childAdUnitSize: '' }));
  //   }
  // }

  // const childAdUnitTypeChange = (e) => {
  //   setChildAdUnitTypeSearch(e.target.value)
  //   if (parsed.adUnitId) {
  //     dispatch(onGetChildAdUnits({ adUnitID: parsed.adUnitId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, childAdUnitType: e.target.value,userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '', childAdUnitSize: '' }));
  //   } else {
  //     dispatch(onGetChildAdUnits({ adUnitID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, childAdUnitType: e.target.value,userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '', childAdUnitSize: '' }));
  //   }
  // }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };

  useEffect(() => {
    dispatch(onGetChildAdUnits(null));
    totalRecords = 0
    pageNumber = 1
    pageSize = 10
    searchText = ''
    if (parsed.status === 'ChildAdUnitCreateByAdUnit') {
      childAdUnitType = parsed.childAdUnitType
      setIsRight(true);
      setCreateState(!createState)
    }
  }, []);


  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      console.log(row, isSelect, rowIndex)
      if (isSelect) {
        setSelected([...selected, row.id])
        setSelectedRowsStatus(true)
        selectedIndex.push(row.childAdUnitID)
        selectedItem.push(row.childAdUnitID)
        console.log(selectedIndex)
      } else {
        setSelected(selected.filter(x => x !== row.id))
        let index = selectedIndex.indexOf(row.childAdUnitID)
        selectedIndex.splice(index, 1)
        let index2 = selectedItem.indexOf(row.childAdUnitID)
        selectedItem.splice(index2, 1)
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
        console.log(selectedIndex)
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      const ids = rows.map(r => r.id);
      if (isSelect) {
        setSelected(ids)
        rows.map((item, index) => {
          selectedIndex.push(item.childAdUnitID)
          selectedItem.push(item.childAdUnitID)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
      }

    }
  };

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
    selectedIndex = []
  }

  const { childAdUnits, paginationData } = useSelector(state => ({
    childAdUnits: state.childAdUnits.childAdUnitList,
    paginationData: state.childAdUnits.pagination
  }));

  totalRecords = paginationData ? paginationData.totalRecords ? paginationData.totalRecords : 0 : 0

  const { loading } = useSelector(state => ({
    loading: state.childAdUnits.loading,
  }));

  const { noData } = useSelector(state => ({
    noData: state.childAdUnits.noData,
  }));

  const { childAdUnitsResponse } = useSelector(state => ({
    childAdUnitsResponse: state.childAdUnits.response,
  }));

  const { archiveChildAdUnitsResponse } = useSelector(state => ({
    archiveChildAdUnitsResponse: state.childAdUnits.archiveMsg,
  }));

  // const { errorMsg, successMsg } = useSelector(state => ({
  //   errorMsg: state.childAdUnits.errorMsg,
  //   successMsg: state.childAdUnits.successMsg,
  // }));

  useEffect(() => {
    if (loading) {
      setSwitchLoader(true)
    }
  }, [loading]);

  console.log(childAdUnitsResponse)
  useEffect(() => {
    if (childAdUnitsResponse !== null) {
      if (childAdUnitsResponse.statusCode === 200) {
        toastr.success('ChildAdUnit Status Changed Successfully')
        if (parsed.adUnitId) {
          dispatch(onGetChildAdUnits({ adUnitID: parsed.adUnitId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, childAdUnitType: childAdUnitTypeSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', childAdUnitSize: '' }));
        } else {
          dispatch(onGetChildAdUnits({ adUnitID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, childAdUnitType: childAdUnitTypeSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', childAdUnitSize: '' }));
        }
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        dispatch(statusChildAdUnitSuccess(null));
      }
    }
  }, [dispatch, childAdUnitsResponse]);

  useEffect(() => {
    if (archiveChildAdUnitsResponse !== null) {
      if (archiveChildAdUnitsResponse.statusCode === 200) {
        toastr.success('ChildAdUnit Archived Successfully')
        if (parsed.adUnitId) {
          dispatch(onGetChildAdUnits({ adUnitID: parsed.adUnitId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, childAdUnitType: childAdUnitTypeSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', childAdUnitSize: '' }));
        } else {
          dispatch(onGetChildAdUnits({ adUnitID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, childAdUnitType: childAdUnitTypeSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', childAdUnitSize: '' }));
        }
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        dispatch(archiveChildAdUnitSuccess(null));
      }
    }
  }, [dispatch, archiveChildAdUnitsResponse]);

  const toggleRightCanvas = () => {
    selectedData = ''
    if (parsed.adUnitId) {
      dispatch(onGetChildAdUnits({ adUnitID: parsed.adUnitId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, childAdUnitType: childAdUnitTypeSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', childAdUnitSize: '' }));
    } else {
      dispatch(onGetChildAdUnits({ adUnitID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, childAdUnitType: childAdUnitTypeSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', childAdUnitSize: '' }));
    }
    dispatch(addChildAdUnitFail(""));
    dispatch(updateChildAdUnitFail(""));
    setIsRight(!isRight);
    setCreateState(!createState)
  };

  const toggleEnableScroll = () => {
    setIsEnableScroll(!isEnableScroll);
  };

  const toggleDrawer = (close, childAdUnit, childAdUnitTypes) => (event) => {
    console.log(event)
    if (event === 'Create') {
      toastr.success('ChildAdUnit Saved Successfully!...')
    }
    if (event === 'Update') {
      toastr.success('ChildAdUnit Updated Successfully!...')
    }
    selectedData = childAdUnit
    childAdUnitType = childAdUnitTypes
    setIsArchived(childAdUnit.isChildAdUnitArchived)
    if (parsed.adUnitId) {
      dispatch(onGetChildAdUnits({ adUnitID: parsed.adUnitId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, childAdUnitType: childAdUnitTypeSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', childAdUnitSize: '' }));
    } else {
      dispatch(onGetChildAdUnits({ adUnitID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, childAdUnitType: childAdUnitTypeSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', childAdUnitSize: '' }));
    }
    dispatch(addChildAdUnitFail(""));
    dispatch(updateChildAdUnitFail(""));
    setIsRight(close);
    setCreateState(!createState)
  };

  useEffect(() => {
    if (parsed.adUnitId) {
      dispatch(onGetChildAdUnits({ adUnitID: parsed.adUnitId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, childAdUnitType: childAdUnitTypeSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": '', "endDate": '', childAdUnitSize: '' }));
    }
  }, []);

  useEffect(() => {
    if (childAdUnits !== null && !parsed.adUnitId) {
      dispatch(onGetChildAdUnits({ adUnitID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, childAdUnitType: childAdUnitTypeSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": '', "endDate": '', childAdUnitSize: '' }));
    }
  }, []);

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const childAdUnitListColumns = [
    {
      text: "ID",
      headerClasses: "ID",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "ID",
      headerClasses: "ID",
      dataField: "childAdUnitID",
      sort: true,
    },
    {
      dataField: "childAdUnitModifyTime",
      text: "Modify Date",
      sort: true,
      style: {
        width: "12em"
      },
    },
    {
      text: "Child AdUnit Name",
      dataField: "childAdUnitName",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, childAdUnit) => (
        <>
          <span className="font-size-12 text-dark">
            {childAdUnit.childAdUnitName}
          </span>
        </>
      ),
    },
    {
      text: "Child AdUnit Type",
      dataField: "childAdUnitType",
      formatter: (cellContent, childAdUnit) => (
        <div className=' ' >
          {childAdUnit.childAdUnitType === 'play' &&
            <span>Player</span>
          }
          {childAdUnit.childAdUnitType === 'vast' &&
            <span>Vast</span>
          }
          {childAdUnit.childAdUnitType === 'stdb' &&
            <span>Standard Banner</span>
          }
          {childAdUnit.childAdUnitType === 'highImpact' &&
            <span>High Impact</span>
          }
          {childAdUnit.childAdUnitType === 'legacy' &&
            <span>Legacy</span>
          }
        </div>
      )
    },
    {
      dataField: "isHBEnabled",
      text: "HB",
      formatter: (cellContent, childAdUnit) => (
        <>
        <span className={childAdUnit.isHBEnabled ? "active-state" : "archived-state"}>{childAdUnit.isHBEnabled ? "Live" : "Disabled"}</span>
      </>
      ),
    },
    {
      dataField: "childAdUnitStatus",
      text: "Status",
      sort: true,
      formatter: (cellContent, childAdUnit) => (
        <>
          <span className={childAdUnit.newStatus === 'Active' ? "active-state" : childAdUnit.newStatus === 'Inactive' ? "inactive-state" : "archived-state"}>{childAdUnit.newStatus}</span>
        </>
      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',
      hidden: (UserRolePrivileges.Supply && UserRolePrivileges.Supply.ChildAdUnits) && (!UserRolePrivileges.Supply.ChildAdUnits.update),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, childAdUnit) => (
        <div className="d-flex gap-3" >
          <span className="Email-state " onClick={toggleDrawer(true, childAdUnit, childAdUnit.childAdUnitType)}>
            {childAdUnit.newStatus === 'Archived' &&
              'View'
            }
            {childAdUnit.newStatus !== 'Archived' &&
              'Edit'
            }

          </span>
        </div>
      ),
    },
  ];

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const archiveChildAdUnit = async () => {
    dispatch(onArchiveChildAdUnit({ childAdUnitIDList: selectedItem, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner }));
  }

  const statusChildAdUnit = async (state) => {
    console.log(state)
    dispatch(onStatusChildAdUnit({ childAdUnitIDList: selectedItem, childAdUnitStatus: state, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner }));
  }

  console.log(childAdUnits)

  if (childAdUnits.length > 0) {
    if (UserRolePrivileges.Supply && UserRolePrivileges.Supply.ChildAdUnits) {
      if (!UserRolePrivileges.Supply.ChildAdUnits.archive && !UserRolePrivileges.Supply.ChildAdUnits.status) {
        childAdUnits.map((item, index) => {
          selectRow.nonSelectable.push(index + 1)
        })
      } else {
        childAdUnits.map((item, index) => {
          if (item.isChildAdUnitArchived) {
            selectRow.nonSelectable.push(index + 1)
          }
        })
      }
    }
  }

  const paginationChange = async (selectedPages) => {
    console.log(selectedPages)
    pageSize = selectedPages.pageSize
    pageNumber = selectedPages.pageNumber
    if (parsed.adUnitId) {
      dispatch(onGetChildAdUnits({ adUnitID: parsed.adUnitId, pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText, filter: statusSearch, childAdUnitType: childAdUnitTypeSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', childAdUnitSize: '' }));
    } else {
      dispatch(onGetChildAdUnits({ adUnitID: '', pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText, filter: statusSearch, childAdUnitType: childAdUnitTypeSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', childAdUnitSize: '' }));
    }
  }

  const SupplyServiceStatus = JSON.parse(sessionStorage.getItem("SupplyServiceDown"));

  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      {SupplyServiceStatus ? <ErrorPage></ErrorPage> :
        <div className="page-content">
          <Helmet>
            <title>ChildAdUnit | Bidsxchange</title>
          </Helmet>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Row>
              <div className="col-8">
                {parsed.adUnitId ? (
                  <SupplyBreadCrumb title="Supply" breadcrumbItem="Child Ad Unit " adUnitId={parsed.adUnitId} />
                ) : (
                  <SupplyBreadCrumb title="Supply" breadcrumbItem="Child Ad Unit " breadList="All Child Ad Units" />
                )}
              </div>
              <div className="col-4">
                {UserRolePrivileges.Supply && UserRolePrivileges.Supply.ChildAdUnits && UserRolePrivileges.Supply.ChildAdUnits.create &&
                  <div className="text-sm-end">
                    <div className="btn-group">
                      <Dropdown
                        direction="left"
                        isOpen={isMenu}
                        toggle={() => { }}
                      >
                        <DropdownToggle
                          tag="button"
                          onClick={toggleMenu}
                          className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                        >
                          <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                          {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                            Filter Applied
                          </span> : <span className="d-none font-16 d-sm-inline-block">
                            Filter
                          </span>
                          }
                          {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px',color:'red' }}>{filterCount}</span>
                              } */}
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                          <div className="dropdown-item-text">
                            <div className="row">
                              <h5 className="mb-0">Filters</h5>
                            </div>
                          </div>

                          <DropdownItem divider />
                          <Card>
                            <CardBody aria-hidden="true">
                              <Row >
                                {/* <Col sm="12" className="mt-2">
                                <Label className="form-label">Child AdUnit Type</Label>
                                <div className=" text-sm-end">
                                  <Input type="select" className="form-select" value={childAdUnitTypeSearch} onChange={childAdUnitTypeChange}>
                                    <option value=''> All</option>
                                    <option value='highImpact'> High Impact</option>
                                    <option value='play'> Player</option>
                                    <option value='vast'> Vast</option>
                                  </Input>
                                </div>
                              </Col> */}
                                <Col sm="12" className="mt-2">
                                  <Label className="form-label">Status</Label>
                                  <div className=" text-sm-end">
                                    <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                      <option value=''> All</option>
                                      <option value='Active' > Active</option>
                                      <option value='Inactive'> InActive</option>
                                      <option value='Archived'> Archived</option>
                                    </Input>
                                  </div>
                                </Col>
                                <Col sm="12" className="date-range mt-2">
                                  <Label className="form-label">Filter by Modified Dates</Label>
                                  <div className="">
                                    <DateRangePicker
                                      initialSettings={{
                                        opens: 'left',
                                        startDate: start.toDate(),
                                        endDate: end.toDate(),
                                      }}
                                      onCallback={handleEvent}
                                    >
                                      <button className='datePicker w-100' type="button">
                                        <i className="bx bx-calendar font-size-18 " id="edittooltip" style={{ margin: '3% 3% 2%' }} ></i>{labelDate}
                                      </button>
                                    </DateRangePicker>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>

                          <DropdownItem divider />
                          <Row>
                            <Col sm="6">
                              <DropdownItem
                                className="text-primary text-center"
                                onClick={clearAllFilter}
                              >
                                Clear ALL
                                {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px' }}>{filterCount}</span>
                              } */}
                              </DropdownItem>
                            </Col>
                            <Col sm="6">
                              <DropdownItem
                                className="text-primary text-center"
                                onClick={applyFilter}
                              >
                                Apply Filter
                                {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px' }}>{filterCount}</span>
                              } */}
                              </DropdownItem>
                            </Col>
                          </Row>

                        </DropdownMenu>
                      </Dropdown>
                    </div> &nbsp;&nbsp;
                    <div className="btn-group">
                      {(parsed.status === 'ChildAdUnitCreateByAdUnit' || parsed.status === 'ChildAdUnitCreateByParent') ?
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={toggleDrawer(true, '', parsed.childAdUnitType)}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Add Child AdUnit &nbsp;
                        </Button> :
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={toggleDrawer(true, '', 'highImpact')}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Add Child AdUnit &nbsp;
                        </Button>
                        // <Dropdown
                        //   isOpen={btnprimary1}
                        //   toggle={() => setBtnprimary1(!btnprimary1)}
                        // >
                        //   <DropdownToggle tag="button" className="btn btn-primary">
                        //     <i className="mdi mdi-plus-circle-outline me-1" />  Add Child AdUnit &nbsp; <i className="mdi mdi-chevron-down" />
                        //   </DropdownToggle>
                        //   <DropdownMenu>
                        //     <DropdownItem onClick={toggleDrawer(true, '', 'highImpact')}>High Impact</DropdownItem>
                        //     <DropdownItem divider />
                        //     <DropdownItem header>Video</DropdownItem>
                        //     <DropdownItem onClick={toggleDrawer(true, '', 'play')}>Player</DropdownItem>
                        //     <DropdownItem onClick={toggleDrawer(true, '', 'vast')} disabled>Vast</DropdownItem>
                        //   </DropdownMenu>
                        // </Dropdown>
                      }
                    </div>
                  </div>
                }
              </div>
            </Row>
            <ProductDataTableLI statusAccess={(UserRolePrivileges.Supply && UserRolePrivileges.Supply.ChildAdUnits && UserRolePrivileges.Supply.ChildAdUnits.status) ? true : false}
              archiveAccess={(UserRolePrivileges.Supply && UserRolePrivileges.Supply.ChildAdUnits && UserRolePrivileges.Supply.ChildAdUnits.archive) ? true : false} loading={loading} data={childAdUnits} columns={childAdUnitListColumns} statusChange={statusChildAdUnit} archiveLineItem={archiveChildAdUnit} rowSelection={selectRow} handleClose={handleClose} selectedIndex={selectedIndex} paginationSelection={paginationChange} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize} />


          </Container>
        </div>
      }
      {/* </div> */}

      <Offcanvas isOpen={isRight} direction='end' >
        <OffcanvasHeader toggle={toggleRightCanvas}>
          {selectedData ? 'Edit Child AdUnit' : 'New ' + childAdUnitType + ' Child AdUnit'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Create_ChildAdUnit closeCanvas={toggleDrawer(false, '', '')} selectedArchived={isArchived} selectedChildAdUnitType={childAdUnitType} selectedChildAdUnit={selectedData} selectedAdUnit={(parsed.status === 'ChildAdUnitCreateByAdUnit' || parsed.status === 'ChildAdUnitCreateByParent') ? parsed : ''} />
        </OffcanvasBody>
      </Offcanvas>


    </React.Fragment>
  )
}

export default ChildAdUnit