import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody, Spinner, Label, BreadcrumbItem
} from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
    getPartners as onGetPartners,
    statusPartner as onStatusPartner,
    archivePartner as onArchivePartner,
    statusPartnerSuccess,
    archivePartnerSuccess,
    addPartnerFail,
    updatePartnerFail,
} from "store/PartnerManagement/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import LoadingTable from "pages/LoaderPages/LoadingTable";
import Supply_Publisher_Role_Access from "./Supply_Publisher_Role_Access";
import ProductDataTableLI from "Product_Helpers/ProductDataTableLI";

var selectedItem = []
var selectedData = ''
var selectedIndex = []

let pageNumber = 1
let pageSize = 10
let totalRecords = 0
let searchText = ''

const Supply_Publisher_Access_Management = props => {
    const dispatch = useDispatch();
    const [isRight, setIsRight] = useState(false);
    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
    const [selected, setSelected] = useState([])
    const [switchLoader, setSwitchLoader] = useState(true)
    const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const userRole = localStorage.getItem('UserRole');

    const handleSwitch = () => {
        setSwitchLoader(!switchLoader)
    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            setIsRight(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const selectRow = {
        mode: "checkbox",
        nonSelectable: [],
        selected: selected,
        onSelect: (row, isSelect, rowIndex, e) => {
            console.log(row, isSelect, rowIndex)
            if (isSelect) {
                setSelected([...selected, row.id])
                setSelectedRowsStatus(true)
                selectedIndex.push(row.partnerID)
                selectedItem.push(row.partnerID)
                console.log(selectedIndex)
            } else {
                setSelected(selected.filter(x => x !== row.id))
                let index = selectedIndex.indexOf(row.partnerID)
                selectedIndex.splice(index, 1)
                if (selectedIndex.length === 0) {
                    setSelectedRowsStatus(false)
                }
                console.log(selectedIndex)
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            const ids = rows.map(r => r.id);
            if (isSelect) {
                setSelected(ids)
                rows.map((item, index) => {
                    selectedIndex.push(item.partnerID)
                })
                setSelectedRowsStatus(true)
            } else {
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
            }
        }
    };

    const handleClose = () => {
        setSelectedRowsStatus(false)
        selectedItem = []
        setSelected([])
        selectedIndex = []
    }


        const { partners,paginationData } = useSelector(state => ({
            partners: state.partners.partners,
            paginationData: state.partners.pagination
        }));
      
        totalRecords = paginationData ? paginationData.totalRecords ? paginationData.totalRecords : 0 : 0

    const { loading } = useSelector(state => ({
        loading: state.partners.loading,
    }));

    const { partnersResponse } = useSelector(state => ({
        partnersResponse: state.partners.response,
    }));

    const { archivePartnersResponse } = useSelector(state => ({
        archivePartnersResponse: state.partners.archiveMsg,
    }));

    useEffect(() => {
        console.log(loading)
        if (loading) {
            setSwitchLoader(true)
        }
    }, [loading]);

    console.log(partnersResponse)
    useEffect(() => {
        if (partnersResponse !== null) {
            if (partnersResponse.success) {
                toastr.success('Partner Status Changed Successfully')
                dispatch(onGetPartners({ userRoleParent: 'SupplyPartner', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: 'Active', partnerType: '' }));
                dispatch(statusPartnerSuccess(null));
                selectedItem = []
                selectedIndex = []
                setSelected([])
                setSelectedRowsStatus(false)
            }
        }
    }, [dispatch, partnersResponse]);

    useEffect(() => {
        if (archivePartnersResponse !== null) {
            if (archivePartnersResponse.success) {
                toastr.success('Partner Archived Successfully')
                dispatch(onGetPartners({ userRoleParent: 'SupplyPartner', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: 'Active', partnerType: '' }));
                dispatch(archivePartnerSuccess(null));
                selectedItem = []
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
            }
        }
    }, [dispatch, archivePartnersResponse]);

    const toggleRightCanvas = () => {
        selectedData = ''
        dispatch(onGetPartners({ userRoleParent: 'SupplyPartner', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: 'Active', partnerType: '' }));
        dispatch(addPartnerFail(null));
        dispatch(updatePartnerFail(null));
        setIsRight(!isRight);
    };
    const toggleEnableScroll = () => {
        setIsEnableScroll(!isEnableScroll);
    };

    const toggleDrawer = (close, partner) => (event) => {
        console.log(event)
        if (event === 'Create') {
            toastr.success('Partner Saved Successfully!...')
        }
        if (event === 'Update') {
            toastr.success('Partner Updated Successfully!...')
        }
        selectedData = partner
        dispatch(onGetPartners({ userRoleParent: 'SupplyPartner', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: 'Active', partnerType: '' }));
        dispatch(addPartnerFail(null));
        dispatch(updatePartnerFail(null));
        setIsRight(close);
    };

    useEffect(() => {
        if (partners !== null) {
            dispatch(onGetPartners({ userRoleParent: 'SupplyPartner', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: 'Active', partnerType: '' }));
        }
    }, []);

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const partnerListColumns = [
        {
            text: "ID",
            headerClasses: "ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            text: "ID",
            headerClasses: "ID",
            dataField: "partnerID",
            sort: true,
        },
        {
            dataField: "userCreateTime",
            text: "Date",
            sort: true,
        },
        {
            text: "Name",
            dataField: "userName",
            sort: true,

        },
        {
            dataField: "userEmail",
            text: "Email",
            sort: true,
            hidden: (userInfo.userRole !== 'Root' || userRole !== 'Admin')
        },

        {
            dataField: "userStatus",
            text: "Status",
            sort: true,
            formatter: (cellContent, partner) => (
                <>
                    {partner.userStatus &&
                        <span className="active-state">Active</span>
                    }
                    {!partner.userStatus &&
                        <span className="inactive-state">Inactive</span>
                    }
                </>
            ),
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',
            // hidden: (UserRolePrivileges.PartnerManagement && UserRolePrivileges.PartnerManagement.PartnerAccess) && (!UserRolePrivileges.PartnerManagement.PartnerAccess.update),

            // eslint-disable-next-line react/display-name
            formatter: (cellContent, partner) => (
                <div className="d-flex gap-3" >
                    <Link className="Email-state" to="#" onClick={toggleDrawer(true, partner)}>
                    Edit
                    </Link>
                </div>
            ),
        },
    ];

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );


    const pageOptions = {
        paginationSize: 4,
        pageStartIndex: 1,
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };
    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const archivePartner = async () => {
        dispatch(onArchivePartner({ idList: selectedItem }));
    }

    const statusPartner = async (state) => {
        console.log(state)
        dispatch(onStatusPartner({ idList: selectedItem, userStatus: state }));
    }

    if (partners.length > 0) {
        if (UserRolePrivileges.PartnerManagement && UserRolePrivileges.PartnerManagement.PartnerAccess) {
            if (!UserRolePrivileges.PartnerManagement.PartnerAccess.archive && !UserRolePrivileges.PartnerManagement.PartnerAccess.status) {
                partners.map((item, index) => {
                    selectRow.nonSelectable.push(index + 1)
                })
            } else {
                selectRow.nonSelectable = []
            }
        }
    }

    const paginationChange = async (selectedPages) => {
        console.log(selectedPages)
        pageSize = selectedPages.pageSize
        pageNumber = selectedPages.pageNumber
        dispatch(onGetPartners({ userRoleParent: 'SupplyPartner',  pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText, filter: 'Active', partnerType: '' }));
    }

    console.log(partners)

    return (
        <React.Fragment>
            <div className="page-content">
                <Helmet>
                    <title>Supply Partner ACM | Bidsxchange</title>
                </Helmet>
                <Container fluid={true}>
                    <div className=" d-sm-flex  justify-content-between">
                        <Breadcrumbs title="Home" breadcrumbItem="Supply Partner ACM" />
                    </div>
                        <ProductDataTableLI statusAccess={(UserRolePrivileges.PartnerManagement && UserRolePrivileges.PartnerManagement.PartnerAccess && UserRolePrivileges.PartnerManagement.PartnerAccess.status) ? true : false}
                            archiveAccess={ false} loading={loading} data={partners} columns={partnerListColumns} statusChange={statusPartner}  rowSelection={selectRow} handleClose={handleClose} selectedIndex={selectedIndex} paginationSelection={paginationChange} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize} />
    
                </Container>
            </div>
            <Offcanvas isOpen={isRight} direction='end' className="CanvasLarge">
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {'Update Partner Access'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <Supply_Publisher_Role_Access closeCanvas={toggleDrawer(false, '')} selectedPartner={selectedData} />
                </OffcanvasBody>
            </Offcanvas>
        </React.Fragment>
    )
}


Supply_Publisher_Access_Management.propTypes = {}

export default Supply_Publisher_Access_Management