import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Input,
  Spinner,
  Label
} from "reactstrap"
import queryString from 'query-string';

import DateRangePickers from '../../../helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import {
  getNetworkSetting as onGetNetworkSetting,
  statusNetworkSetting as onStatusNetworkSetting,
  archiveNetworkSetting as onArchiveNetworkSetting,
  statusNetworkSettingSuccess,
  archiveNetworkSettingSuccess,
  addNetworkSettingFail,
  updateNetworkSettingFail

} from 'store/Supply/NetworkSettings/actions'

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb

import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";
import LoadingTable from "pages/LoaderPages/LoadingTable";
import NewNetworkSettings from "./NewNetworkSettings";
import ProductDataTableLI from "Product_Helpers/ProductDataTableLI";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var filterCount = 0

let pageNumber = 1
let pageSize = 10
let totalRecords = 0
let searchText = ''

const NetworkSetting = props => {
  const dispatch = useDispatch();
  const [isRight, setIsRight] = useState(false);
  const [isRights, setIsRights] = useState(false);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const parsed = queryString.parse(window.location.search);
  const [statusSearch, setStatusSearch] = useState('Active')
  const [isArchived, setIsArchived] = useState(false)
  const [selected, setSelected] = useState([])
  const [createState, setCreateState] = useState(true)
  const userInfo = JSON.parse(localStorage.getItem('authUser'));

  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));

  console.log(UserRolePrivileges)

  const [switchLoader, setSwitchLoader] = useState(true)

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  const [filterState, setFilterState] = useState(false)

  const statusChange = (e) => {
    if (e.target.value === 'Active') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (statusSearch === 'Active') {
      filterCount = filterCount + 1
    }
    setStatusSearch(e.target.value)
  }

  const applyFilter = (e) => {
    if (parsed.adUnitId) {
      dispatch(onGetNetworkSetting({ userRole: userInfo.userRole, userID: userInfo.userID, parentID: userInfo.parentID, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch, }));
    } else {
      dispatch(onGetNetworkSetting({  userRole: userInfo.userRole, userID: userInfo.userID, parentID: userInfo.parentID, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch, }));
    }
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }

  const clearAllFilter = (e) => {
    setStatusSearch('Active')
    filterCount = 0
    setFilterState(false)
    if (parsed.adUnitId) {
      dispatch(onGetNetworkSetting({ userRole: userInfo.userRole, userID: userInfo.userID, parentID: userInfo.parentID, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, isSupplyPartner:userInfo.isSupplyPartner,filter: 'Active', }));
    } else {
      dispatch(onGetNetworkSetting({  userRole: userInfo.userRole, userID: userInfo.userID, parentID: userInfo.parentID, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, isSupplyPartner:userInfo.isSupplyPartner,filter: 'Active', }));
    }
  }

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const changeDatePick = (event) => {
    console.log(event)
    console.log("start: ", event.startDate._d);
    console.log("end: ", event.endDate._d);
    setFromDate(event.startDate._d.toISOString());
    setToDate(event.endDate._d.toISOString());
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };

  useEffect(() => {
    dispatch(onGetNetworkSetting(null));
    totalRecords = 0
    pageNumber = 1
    pageSize = 10
    searchText = ''
    if (parsed.status === 'NetworkSettingCreateByAdUnit') {
      setIsRight(true);
      setCreateState(!createState)
    }
  }, []);


  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      console.log(row, isSelect, rowIndex)
      if (isSelect) {
        setSelected([...selected, row.id])
        setSelectedRowsStatus(true)
        selectedIndex.push(row.networkSettingID)
        selectedItem.push(row.networkSettingID)
        console.log(selectedIndex)
      } else {
        setSelected(selected.filter(x => x !== row.id))
        let index = selectedIndex.indexOf(row.networkSettingID)
        selectedIndex.splice(index, 1)
        let index2 = selectedItem.indexOf(row.networkSettingID)
        selectedItem.splice(index2, 1)
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
        console.log(selectedIndex)
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      const ids = rows.map(r => r.id);
      if (isSelect) {
        setSelected(ids)
        rows.map((item, index) => {
          selectedIndex.push(item.networkSettingID)
          selectedItem.push(item.networkSettingID)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
      }

    }
  };

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
    selectedIndex = []
  }

  const { NetworkSetting,paginationData } = useSelector(state => ({
    NetworkSetting: state.NetworkSettings.NetworkSettingList,
    paginationData: state.NetworkSettings.pagination
  }));

  totalRecords = paginationData ? paginationData.totalRecords ? paginationData.totalRecords : 0 : 0

  const { loading } = useSelector(state => ({
    loading: state.NetworkSettings.loading,
  }));

  console.log(loading)

  const { noData } = useSelector(state => ({
    noData: state.NetworkSettings.noData,
  }));

  console.log(noData)

  const { NetworkSettingResponse } = useSelector(state => ({
    NetworkSettingResponse: state.NetworkSettings.response,
  }));

  const { archiveNetworkSettingResponse } = useSelector(state => ({
    archiveNetworkSettingResponse: state.NetworkSettings.archiveMsg,
  }));



  useEffect(() => {
    if (loading) {
      setSwitchLoader(true)
    }
  }, [loading]);

  useEffect(() => {
    if (NetworkSettingResponse !== null) {
      if (NetworkSettingResponse.statusCode === 200) {
        toastr.success('Network Setting Status Changed Successfully')
        if (parsed.adUnitId) {
          dispatch(onGetNetworkSetting({ userRole: userInfo.userRole, userID: userInfo.userID, parentID: userInfo.parentID, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch, }));
        } else {
          dispatch(onGetNetworkSetting({  userRole: userInfo.userRole, userID: userInfo.userID, parentID: userInfo.parentID, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch, }));
        }
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        dispatch(statusNetworkSettingSuccess(null));
      }
    }
  }, [dispatch, NetworkSettingResponse]);

  useEffect(() => {
    if (archiveNetworkSettingResponse !== null) {
      if (archiveNetworkSettingResponse.statusCode === 200) {
        toastr.success('Network Setting Archived Successfully')
        if (parsed.adUnitId) {
          dispatch(onGetNetworkSetting({  userRole: userInfo.userRole, userID: userInfo.userID, parentID: userInfo.parentID, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch, }));
        } else {
          dispatch(onGetNetworkSetting({  userRole: userInfo.userRole, userID: userInfo.userID, parentID: userInfo.parentID, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch, }));
        }
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        dispatch(archiveNetworkSettingSuccess(null));
      }
    }
  }, [dispatch, archiveNetworkSettingResponse]);

  const toggleRightCanvas = () => {
    selectedData = ''
    if (parsed.adUnitId) {
      dispatch(onGetNetworkSetting({  userRole: userInfo.userRole, userID: userInfo.userID, parentID: userInfo.parentID, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch, }));
    } else {
      dispatch(onGetNetworkSetting({ userRole: userInfo.userRole, userID: userInfo.userID, parentID: userInfo.parentID, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch, }));
    }
    dispatch(addNetworkSettingFail(""));
    dispatch(updateNetworkSettingFail(""));
    setIsRight(!isRight);
    setCreateState(!createState)
  };

  const toggleRightCanvass = () => {
    selectedData = ''
    if (parsed.adUnitId) {
      dispatch(onGetNetworkSetting({ userRole: userInfo.userRole, userID: userInfo.userID, parentID: userInfo.parentID, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch, }));
    } else {
      dispatch(onGetNetworkSetting({ userRole: userInfo.userRole, userID: userInfo.userID, parentID: userInfo.parentID, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch, }));
    }
    dispatch(addNetworkSettingFail(""));
    dispatch(updateNetworkSettingFail(""));
    setIsRights(!isRights);
  };

  const toggleEnableScroll = () => {
    setIsEnableScroll(!isEnableScroll);
  };

  const toggleDrawer = (close, networks) => (event) => {
    console.log(event)
    if (event === 'Create') {
      toastr.success('Network Setting Saved Successfully!...')
    }
    if (event === 'Update') {
      toastr.success('Network Setting Updated Successfully!...')
    }
    selectedData = networks
    setIsArchived(networks.isNetworkSettingArchived)
    if (parsed.adUnitId) {
      dispatch(onGetNetworkSetting({ userRole: userInfo.userRole, userID: userInfo.userID, parentID: userInfo.parentID, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch, }));
    } else {
      dispatch(onGetNetworkSetting({  userRole: userInfo.userRole, userID: userInfo.userID, parentID: userInfo.parentID, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch, }));
    }
    dispatch(addNetworkSettingFail(""));
    dispatch(updateNetworkSettingFail(""));
    setIsRight(close);
    setCreateState(!createState)
  };

  const toggleDrawers = (close, networks) => (event) => {
    console.log(event)
    if (parsed.adUnitId) {
      dispatch(onGetNetworkSetting({  userRole: userInfo.userRole, userID: userInfo.userID, parentID: userInfo.parentID, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch, }));
    } else {
      dispatch(onGetNetworkSetting({ userRole: userInfo.userRole, userID: userInfo.userID, parentID: userInfo.parentID, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch, }));
    }
    dispatch(addNetworkSettingFail(""));
    dispatch(updateNetworkSettingFail(""));
    setIsRights(close);
  };


  useEffect(() => {
    if (NetworkSetting !== null) {
      dispatch(onGetNetworkSetting({ userRole: userInfo.userRole, userID: userInfo.userID, parentID: userInfo.parentID, "startDate": '', "endDate": '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch, }));
    }
  }, []);

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const networksListColumns = [
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "networkSettingID",
      sort: true,
    },
    {
      dataField: "networkSettingCreateTime",
      text: "Creation Date",
      sort: true,
    },
    {
      text: "Network Name",
      dataField: "networkSettingName",
      sort: true,
    },

    {
      dataField: "networkSettingStatus",
      text: "Status",
      sort: true,
      formatter: (cellContent, networks) => (
        <>
          <span className={networks.newStatus === 'Active' ? "active-state" : networks.newStatus === 'Inactive' ? "inactive-state" : "archived-state"}>{networks.newStatus}</span>
        </>
      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, networks) => (
        <div className="d-flex gap-3" >
          <span className="Email-state " onClick={toggleDrawer(true, networks)}>
            {networks.newStatus === 'Archived' &&
              'View'
            }
            {networks.newStatus !== 'Archived' &&
              'Edit'
            }

          </span>
        </div>
      ),
    },
  ];

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };


  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const archiveNetworkSetting = async () => {
    dispatch(onArchiveNetworkSetting({ networkSettingIDList: selectedItem ,userRole: userInfo.userRole, userID: userInfo.userID, parentID: userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner}));
  }

  const statusNetworkSetting = async (state) => {
    dispatch(onStatusNetworkSetting({ networkSettingIDList: selectedItem, networkSettingStatus: state ,userRole: userInfo.userRole, userID: userInfo.userID, parentID: userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner}));
  }

  const paginationChange = async (selectedPages) => {
    console.log(selectedPages)
    pageSize = selectedPages.pageSize
    pageNumber = selectedPages.pageNumber
      dispatch(onGetNetworkSetting({ userRole: userInfo.userRole, userID: userInfo.userID, parentID: userInfo.parentID, "startDate": '', "endDate": '', pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText, isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch, }));
  }


  if (NetworkSetting.length > 0) {
    if (UserRolePrivileges.isNetworkSettingAccess) {
      if (!UserRolePrivileges.isNetworkSettingAccess) {
        NetworkSetting.map((item, index) => {
          selectRow.nonSelectable.push(index + 1)
        })
      } else {
        NetworkSetting.map((item, index) => {
          if (item.isNetworkSettingArchived
          ) {
            selectRow.nonSelectable.push(index + 1)
          }
        })
      }
    }
  }


  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      <div className="page-content">
        <Helmet>
          <title>Network Settings | Bidsxchange</title>
        </Helmet>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <div className=" d-sm-flex  justify-content-between">
              <SupplyBreadCrumb title="Supply" breadcrumbItem="Network Settings " />
            <div className="text-sm-end">
            <div className="btn-group">
                  <Dropdown
                    direction="left"
                    isOpen={isMenu}
                    toggle={toggleMenu}
                  >
                    <DropdownToggle
                      tag="button"
                      className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                    >
                      <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                      {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                        Filter Applied
                      </span> : <span className="d-none font-16 d-sm-inline-block">
                        Filter
                      </span>
                      }
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                      <div className="dropdown-item-text">
                        <div className="row">
                          <h5 className="mb-0">Filters</h5>
                        </div>
                      </div>

                      <DropdownItem divider />
                      <Card>
                        <CardBody aria-hidden="true">
                          <Row >
                            <Col sm="12" className="mt-2">
                              <Label className="form-label">Status</Label>
                              <div className=" text-sm-end">
                                <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                  <option value=''> All</option>
                                  <option value='Active' > Active</option>
                                  <option value='Inactive'> InActive</option>
                                  <option value='Archived'> Archived</option>
                                </Input>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      <DropdownItem divider />
                      <Row>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={clearAllFilter}
                          >
                            Clear ALL
                          </DropdownItem>
                        </Col>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={applyFilter}
                          >
                            Apply Filter
                          </DropdownItem>
                        </Col>
                      </Row>

                    </DropdownMenu>
                  </Dropdown>
                </div> &nbsp;&nbsp;
                <Button
                  color="primary"
                  className="font-16 btn-block btn btn-primary"
                  onClick={toggleRightCanvas}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  New Network Setting
                </Button>
            </div>
          </div>
          <ProductDataTableLI statusAccess={(UserRolePrivileges.isNetworkSettingAccess) ? true : false}
            archiveAccess={(UserRolePrivileges.isNetworkSettingAccess) ? true : false} loading={loading}
             data={NetworkSetting} columns={networksListColumns} statusChange={statusNetworkSetting} archiveLineItem={archiveNetworkSetting} rowSelection={selectRow}
              handleClose={handleClose} selectedIndex={selectedIndex} paginationSelection={paginationChange} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize} />

        </Container>
      </div>
      {/* </div> */}

      <Offcanvas isOpen={isRight} direction='end' >
        <OffcanvasHeader toggle={toggleRightCanvas}>
          {selectedData ? 'Edit Network Settings' : 'New Network Settings'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <NewNetworkSettings closeCanvas={toggleDrawer(false, '')} selectedArchived={isArchived} selectedNetwork={selectedData} />
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  )
}

NetworkSetting.propTypes = {}

export default NetworkSetting