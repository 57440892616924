import React, { useEffect, useState, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import PropTypes from 'prop-types'
import { Col, Container, Row, Input, Label, Card, CardBody, Form, Button,Spinner } from "reactstrap"
import { withRouter, Link } from "react-router-dom";
import axios from "axios";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

var loading = Boolean(false)

const Reporting_API = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [requestState, setRequestState] = useState(false)
    const [pendingState, setPendingState] = useState(false)
    const [apiData, setApiData] = useState({ 'API Endpoint': '','Request Body sample':{"*******": "*******************", "******": "************************", "*******": "*******", "*********": { "*******": "****-**-**", "******": "****-**-**" }}})
    const [loading, setLoading] = useState(false)

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
            userEmail: userInfo.username,
            companyID: userInfo.companyID,
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            userName: userInfo.name,
            parentID:userInfo.parentID
    }

    const handleRequest = (e) => {
        setLoading(true)
        // loading = true;
        axios.post("https://report-api.bidsxchange.com/v1.0/Query/Revenue/Requests", body,
            {
                headers: headers
            })
            .then((res) => {
                setLoading(false)
                console.log("result is ==>", res)
                if (res.data.success) {
                    setPendingState(true)
                    setRequestState(false)
                    toastr.success('Request has been sent to Admin E-mail Successfully...')
                    
                } else {
                    toastr.warning('Already Request raised...')
                    setPendingState(true)
                    setRequestState(false)
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log("e", err)
            });
    }

    const handleChangeToken = (e) => {
        // loading = true;
        axios.post( "https://report-api.bidsxchange.com/v1.0/Query/Revenue/RegenatedToken", body,
            {
                headers: headers
            })
            .then((res) => {
                setLoading(false)
                console.log("result is ==>", res)
                if (res.data.success) {
                    toastr.success('Token Regenerated Successfully...')
                    setApiData(apiData => ({ ...apiData, APIToken: res.data.token }));
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log("e", err)
            });
    }

    useEffect(() => {
        getRequestApprovalData()
    }, []);

    const getRequestApprovalData = () => {
        setLoading(true)
        axios.post( "https://report-api.bidsxchange.com/v1.0/Query/Revenue/GetRequest/ForPratner", body,
            {
                headers: headers
            })
            .then((res) => {
                setLoading(false)
                console.log("result is ==>", res)
                if (res.data) {
                    if (res.data.data.userName ) {
                        if (res.data.data.isApproved === true) {
                            setRequestState(true)
                            setApiData(res.data.data)
                        } else {
                            setPendingState(true)
                            setRequestState(false)
                        }
                    }
                    if (!res.data.data.userName && !res.data.data.isApproved  ) { 
                        setRequestState(false)
                    }
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log("e", err)
            });
    }



    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
                <div className="page-content">
                    <Helmet>
                        <title>Reporting API | Bidsxchange</title>
                    </Helmet>
                    <Container fluid={true}>
                        <Breadcrumbs title="Settings" breadcrumbItem="Reporting API" />
                        {loading &&
                            <Spinner className="ms-2 loader" color="primary" />
                        }
                        {!loading &&
                            <div className="col-12">
                            <Row>
                                <div className="col-12">
                                    <Card>
                                        <CardBody>
                                            {requestState && !pendingState &&
                                                <Row style={{ padding: '1% 3%', }}>
                                                    <Col xs={4} >
                                                        <div className="mb-3">
                                                            <Label className="form-label">Request Type</Label>
                                                            <Input
                                                                name="Domain"
                                                                type="text"
                                                                className="form-control"
                                                                value="POST"
                                                                readOnly
                                                            >
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col xs={8} >
                                                        <div className="mb-3">
                                                            <Label className="form-label">API End Point</Label>
                                                            <Input
                                                                name="Domain"
                                                                type="text"
                                                                className="form-control"
                                                                value={apiData['API Endpoint']}
                                                                readOnly
                                                            >
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col xs={6} >
                                                        <div className="mb-2">
                                                            <Label className="form-label">API Token</Label>
                                                            <Input
                                                                name="Domain"
                                                                type="text"
                                                                className="form-control "
                                                                value={apiData['APIToken']}
                                                                readOnly
                                                            >
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col xs={6} >
                                                        <div className="mb-2 mt-3">
                                                                     <button
                                                                            type="button" 
                                                                            className="btn btn-primary mt-2"
                                                                            // eslint-disable-next-line react/prop-types
                                                                            
                                                                            onClick={handleChangeToken}
                                                                        >
                                                                            <i className={loading ? "bx bx-hourglass bx-spin font-size-16 align-middle me-2 ml-2 mr-2" : ''} ></i>
                                                                            Regenerate Token
                                                                        </button>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} >
                                                        <div className="mb-2">
                                                            <Label className="form-label">Request Body</Label>
                                                            <Input
                                                                name="Domain"
                                                                type="textarea"
                                                                rows="2"
                                                                className="form-control mt-2"
                                                                value={JSON.stringify(apiData['Request Body sample'])}
                                                                readOnly
                                                            >
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Label className="form-label">Request Header: content-type: application/json</Label>
                                                    <br />
                                                    <Label className="form-label">Notes:  </Label>
                                                    <ul className="ml-4" style={{marginLeft:'1%'}}>
                                                        <li>Date format must be used, with the EST time zone, YYYY-MM-DD in Request Body.</li>
                                                        <li>Put this API Token in the header as an authorization token in Request Header.</li>
                                                        <li>video/standardBanner/highImapct/all are the Inventory type in Request Body.</li>
                                                    </ul>
                                                </Row>
                                            }
                                            {!requestState && 
                                                <Row style={{ padding: '1% 3%', }}>
                                                    <Col xs={4} >
                                                        <div className="mb-3">
                                                            <Label className="form-label">Request Type</Label>
                                                            <Input
                                                                name="Domain"
                                                                type="text"
                                                                className="form-control"
                                                                value="*****"
                                                                readOnly
                                                            >
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col xs={8} >
                                                        <div className="mb-3">
                                                            <Label className="form-label">API End Point</Label>
                                                            <Input
                                                                name="Domain"
                                                                type="text"
                                                                className="form-control"
                                                                value="https://****-**********-****************.*****"
                                                                readOnly
                                                            >
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} >
                                                        <div className="mb-2">
                                                            <Label className="form-label">API Token</Label>
                                                            <Input
                                                                name="Domain"
                                                                type="text"
                                                                className="form-control mt-2"
                                                                value="****************************************"
                                                                readOnly
                                                            >
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} >
                                                        <div className="mb-2">
                                                            <Label className="form-label">Request Body</Label>
                                                            <Input
                                                                name="Domain"
                                                                type="textarea"
                                                                rows="2"
                                                                className="form-control mt-2"
                                                                value={JSON.stringify(apiData['Request Body sample'])}
                                                                readOnly
                                                            >
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Label className="form-label">Request Header: ******************************</Label>
                                                    <br />
                                                    <Label className="form-label">Note: ******************************</Label>
                                                </Row>
                                            }
                                        </CardBody>
                                    </Card>
                                </div>
                            </Row>
                            {!requestState && !pendingState &&
                                <Row style={{ position: 'relative', marginTop: '-26pc', height: '70vh', backgroundColor: '#ffffffeb' }}>
                                    <div className='col-12 text-center' style={{ padding: '15%' }} >
                                        <div className="avatar-md mx-auto mb-3"><div className="avatar-title rounded-circle bg-light"><i className="bx  bx bxs-lock h1 mb-0 text-primary"></i></div></div>
                                        <h4>Reporting API Feature has not been enabled for your account.  To access this feature please raise a request.</h4>
                                        <Link
                                            type="button" to="#"
                                            className="btn btn-outline-primary mt-4"
                                            // eslint-disable-next-line react/prop-types
                                            onClick={handleRequest}
                                        >
                                            <i className={loading ? "bx bx-hourglass bx-spin font-size-16 align-middle me-2 ml-2 mr-2" : ''} ></i>
                                            Request Reporting API
                                        </Link>
                                    </div>
                                </Row>
                            }
                            {pendingState && !requestState &&
                                <Row style={{ position: 'relative', marginTop: '-26pc', height: '70vh', backgroundColor: '#ffffffeb' }}>
                                    <div className='col-12 text-center' style={{ padding: '15%' }} >
                                        <div className="avatar-md mx-auto mb-3"><div className="avatar-title rounded-circle bg-light"><i className="bx  bx bxs-lock h1 mb-0 text-primary"></i></div></div>
                                        <h4>Reporting API Feature has not been enabled for your account.  To access this feature please raise a request.</h4>
                                        <Button
                                            type="button" 
                                            className="btn btn-secondary mt-4"
                                            // eslint-disable-next-line react/prop-types
                                            disabled
                                            onClick={handleRequest}
                                        >
                                            <i className={loading ? "bx bx-hourglass bx-spin font-size-16 align-middle me-2 ml-2 mr-2" : ''} ></i>
                                            API Request Raised
                                        </Button>
                                    </div>
                                </Row>
                            }
                            </div>
                        }

                    </Container>
                </div>
            {/* </div> */}
        </React.Fragment >
    )
}

Reporting_API.propTypes = {}

export default Reporting_API