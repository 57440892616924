/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Row, Col, CardBody, Card, Input, Label, Form, FormFeedback, Spinner, InputGroup, NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import classnames from "classnames"

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";



import ToasterWarning from "pages/Toaster/ToasterWarning";



const AdUnitDetails = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [adUnit, setAdUnit] = useState();
  const [adUnitAss, setadUnitAss] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  console.log(props)

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 2) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  useEffect(() => {
    if (props.selectedAdUnit !== '') {
      setIsEdit(true)
      setAdUnit(props.selectedAdUnit)
      getParentAdUnit()
      setLoader(true)
    }
  }, []);

  const getParentAdUnit = () => {
    var filterBody = {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name: userInfo.name, parentID: userInfo.parentID,
      isSupplyPartner: userInfo.isSupplyPartner,
      isForSupplyPartner: userInfo.isForSupplyPartner
    }
    axios.post(userInfo.isSupplyPartner ? "/v2.0/hirarchy/getAll/SupplyPartner" + props.selectedAdUnit.adUnitID : "/v2.0/hirarchy/getAll/" + props.selectedAdUnit.adUnitID, filterBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("cdsresult is ==>", res)
        if (res.data.statusCode === 200) {
          setLoader(false)
          setadUnitAss(res.data.response)
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }


  return (
    <React.Fragment>
      {loader ?
        <Spinner className="ms-2 loader" color="primary" />
        : adUnitAss ?
          <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
            <Row style={{ padding: '3%', height: '85vh', overflow: 'auto' }}>
              <Col xs={12}>
                <Row>
                  <Col xs={6}>
                    <div className="d-flex">
                      <Label>AdUnit :</Label> &nbsp;&nbsp;
                      <Label>{props.selectedAdUnit.adUnitID}</Label>&nbsp;&nbsp;
                      <Label>{adUnitAss.AdUnitName}</Label>&nbsp;&nbsp;
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="d-flex">
                      <Label>AdUnit Group :</Label> &nbsp;&nbsp;
                      <Label>{adUnitAss.AdUnitGroup.AdUnitGroupID}</Label>&nbsp;&nbsp;
                      <Label>{adUnitAss.AdUnitGroup.AdUnitGroupName}</Label>&nbsp;&nbsp;
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="d-flex">
                      <Label>Publisher :</Label> &nbsp;&nbsp;
                      <Label>{adUnitAss.Publisher.PublisherID}</Label>&nbsp;&nbsp;
                      <Label>{adUnitAss.Publisher.PublisherName}</Label>&nbsp;&nbsp;
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="d-flex">
                      <Label>Partner :</Label> &nbsp;&nbsp;
                      <Label>{adUnitAss.partner.partnerID}</Label>&nbsp;&nbsp;
                      <Label>{adUnitAss.partner.partnerName}</Label>&nbsp;&nbsp;
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg="12" className="p-0">
                    <div className=" wizard clearfix vertical">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({ current: activeTab === 1 })}
                              onClick={() => {
                                setactiveTab(1)
                              }}
                            // disabled={!(passedSteps || []).includes(1)}
                            >
                              Demand Association
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 2 })}
                              onClick={() => {
                                setactiveTab(2)
                              }}
                            // disabled={!(passedSteps || []).includes(2)}
                            >
                              Settings Association
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix ">
                        <TabContent activeTab={activeTab} className="body">
                          <TabPane tabId={1}>
                          </TabPane>
                          <TabPane tabId={2}>


                          </TabPane>
                        </TabContent>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                toggleTab(activeTab - 1)
                              }}
                            >
                              Previous
                            </Link>
                          </li>
                          <li
                            className={activeTab === 2 ? "next disabled" : "next"}
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                toggleTab(activeTab + 1)
                              }}
                            >
                              Next
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>

              </Col>
            </Row>
            <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
              <Col>
                <div className="text-start">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    // eslint-disable-next-line react/prop-types
                    onClick={props.closeCanvas}
                  >
                    Back
                  </button>
                </div>
              </Col>
            </Row>
          </div> : <p>No Data Available</p>}
    </React.Fragment>
  );
};

export default AdUnitDetails