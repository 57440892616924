/* BIDDER */
export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES"
export const NO_DATA = "NO_DATA"
export const GET_BIDDERS = "GET_BIDDERS"
export const GET_BIDDERS_SUCCESS = "GET_BIDDERS_SUCCESS"
export const GET_BIDDERS_FAIL = "GET_BIDDERS_FAIL"

export const ADD_BIDDER = "ADD_BIDDER"
export const ADD_BIDDER_SUCCESS = "ADD_BIDDER_SUCCESS"
export const ADD_BIDDER_ERROR = "ADD_BIDDER_ERROR"

export const UPDATE_BIDDER = "UPDATE_BIDDER"
export const UPDATE_BIDDER_SUCCESS = "UPDATE_BIDDER_SUCCESS"
export const UPDATE_BIDDER_ERROR = "UPDATE_BIDDER_ERROR"

export const READ_BIDDER = "READ_BIDDER"
export const READ_BIDDER_SUCCESS = "READ_BIDDER_SUCCESS"

export const STATUS_BIDDER = "STATUS_BIDDER"
export const STATUS_BIDDER_SUCCESS = "STATUS_BIDDER_SUCCESS"

export const ARCHIVE_BIDDER = "ARCHIVE_BIDDER"
export const ARCHIVE_BIDDER_SUCCESS = "ARCHIVE_BIDDER_SUCCESS"