import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ADUNITS, GET_ADUNITS_TC, GET_ADUNITS_HIS, ADD_ADUNIT, UPDATE_ADUNIT, READ_ADUNIT, STATUS_ADUNIT, ARCHIVE_ADUNIT } from "./actionTypes"

import {
  loadActivitiesAdUnit,
  noDataAdUnit,
  getAdUnitSuccess,
  getAdUnitFail,
  getAdUnitTcSuccess,
  getAdUnitTcFail,
  getAdUnitHISSuccess,
  getAdUnitHISFail,
  addAdUnitSuccess,
  addAdUnitFail,
  updateAdUnitSuccess,
  updateAdUnitFail,
  readAdUnitSuccess,
  statusAdUnitSuccess,
  archiveAdUnitSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getAdUnit, getAdUnitRichMedia, getAdUnitStandardBanner, getAdUnitbyTC, getAdUnitbyHIS, getAdUnitVideo, getAdUnitsByAdUnitGroup, createAdUnit, updateAdUnit, readAdUnit, statusChangeAdUnit, archiveAdUnit } from "../../../helpers/Backend_helper"

function* fetchAdUnits({ payload: adUnit }) {
  try {
    yield put(loadActivitiesAdUnit(true))
    var getAdUnitList
    // if(adUnit === 'richMedia'){
    //   getAdUnitList = yield call(getAdUnitRichMedia)
    //   getAdUnitList.response.map((item, index) => {
    //     item.id = index + 1
    // })
    // yield put(getAdUnitSuccess(getAdUnitList.response))
    // }else if(adUnit === 'standardBanner'){
    //   getAdUnitList = yield call(getAdUnitStandardBanner)
    //   getAdUnitList.response.map((item, index) => {
    //     item.id = index + 1
    // })
    // yield put(getAdUnitSuccess(getAdUnitList.response))
    // }else if(adUnit === 'video'){
    //   getAdUnitList = yield call(getAdUnitVideo)
    //   getAdUnitList.response.map((item, index) => {
    //     item.id = index + 1
    // })
    // yield put(getAdUnitSuccess(getAdUnitList.response))
    // }
    if (adUnit && adUnit.adUnitGroupID !== '') {
      getAdUnitList = yield call(getAdUnitsByAdUnitGroup, adUnit)
      if (getAdUnitList.statusCode === 200) {
        getAdUnitList.response.adUnits.map((item, index) => {
          item.id = index + 1
        })
        if (getAdUnitList.response.adUnits.length === 0) {
          yield put(noDataAdUnit(true))
        }
        yield put(getAdUnitSuccess(getAdUnitList))
      }
      } else if (adUnit && adUnit.adUnitGroupID === '') {
        delete adUnit.adUnitGroupID
        getAdUnitList = yield call(getAdUnit, adUnit)
        if (getAdUnitList.statusCode === 200) {
        getAdUnitList.response.adUnits.map((item, index) => {
          item.id = index + 1
        })
        if (getAdUnitList.response.adUnits.length === 0) {
          yield put(noDataAdUnit(true))
        }
        yield put(getAdUnitSuccess(getAdUnitList))
      }
      } else {
        getAdUnitList = []
        yield put(getAdUnitSuccess(getAdUnitList))
      }
    } catch (error) {
      yield put(getAdUnitFail(error))
    }
  }

function* fetchAdUnitsTC({ payload: adUnit }) {
    try {
      var getAdUnitListbyTC
      if (adUnit) {
        getAdUnitListbyTC = yield call(getAdUnitbyTC, adUnit)
        if (getAdUnitListbyTC.statusCode === 200) {
          getAdUnitListbyTC.data.map((item, index) => {
          item.id = index + 1
        })
        console.log(getAdUnitListbyTC)
        yield put(getAdUnitTcSuccess(getAdUnitListbyTC.data))
      }
      } else {
        getAdUnitListbyTC = []
        yield put(getAdUnitTcSuccess(getAdUnitListbyTC))
      }
    } catch (error) {
      yield put(getAdUnitTcFail(error))
    }
  }

  function* fetchAdUnitsHIS({ payload: adUnit }) {
    try {
      var getAdUnitListbyHIS
      if (adUnit) {
        getAdUnitListbyHIS = yield call(getAdUnitbyHIS, adUnit)
        if (getAdUnitListbyHIS.statusCode === 200) {
        getAdUnitListbyHIS.data.map((item, index) => {
          item.id = index + 1
        })
        console.log(getAdUnitListbyHIS)
        yield put(getAdUnitHISSuccess(getAdUnitListbyHIS.data))
      }
      } else {
        getAdUnitListbyHIS = []
        yield put(getAdUnitHISSuccess(getAdUnitListbyHIS))
      }
    } catch (error) {
      yield put(getAdUnitHISFail(error))
    }
  }

  function* onReadAdUnit({ payload: adUnit }) {
    try {
      const response = yield call(readAdUnit, adUnit)
      yield put(readAdUnitSuccess(response))
    } catch (error) {
      yield put(readAdUnitSuccess(error))
    }
  }

  function* onAddAdUnit({ payload: adUnit }) {
    try {
      const response = yield call(createAdUnit, adUnit)
      if (response.statusCode === 200) {
        yield put(addAdUnitSuccess(response))
      } else {
        yield put(addAdUnitFail(response))
      }
    } catch (error) {
      yield put(addAdUnitFail(error))
    }
  }

  function* onUpdateAdUnit({ payload: adUnit }) {
    try {
      const response = yield call(updateAdUnit, adUnit)
      if (response.statusCode === 200) {
        yield put(updateAdUnitSuccess(response))
      } else {
        yield put(updateAdUnitFail(response))
      }
    } catch (error) {
      yield put(updateAdUnitFail(error))
    }
  }

  function* onStatusAdUnit({ payload: adUnit }) {
    try {
      const response = yield call(statusChangeAdUnit, adUnit)
      if (response.statusCode === 200) {
      yield put(statusAdUnitSuccess(response))
      }
    } catch (error) {
      yield put(statusAdUnitSuccess(error))
    }
  }

  function* onArchiveAdUnit({ payload: adUnit }) {
    try {
      const response = yield call(archiveAdUnit, adUnit)
      if (response.statusCode === 200) {
      yield put(archiveAdUnitSuccess(response))
      }
    } catch (error) {
      yield put(archiveAdUnitSuccess(error))
    }
  }

  function* adUnitSaga() {
    yield takeEvery(GET_ADUNITS, fetchAdUnits)
    yield takeEvery(GET_ADUNITS_TC, fetchAdUnitsTC)
    yield takeEvery(GET_ADUNITS_HIS, fetchAdUnitsHIS)
    yield takeEvery(ADD_ADUNIT, onAddAdUnit)
    yield takeEvery(UPDATE_ADUNIT, onUpdateAdUnit)
    yield takeEvery(READ_ADUNIT, onReadAdUnit)
    yield takeEvery(STATUS_ADUNIT, onStatusAdUnit)
    yield takeEvery(ARCHIVE_ADUNIT, onArchiveAdUnit)
  }

  export default adUnitSaga;
