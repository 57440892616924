import {
    LOAD_ACTIVITIES,
    NO_DATA_HOUSE_AD_PUBLISHER,
    GET_AD_PUBLISHER_SUCCESS,
    GET_AD_PUBLISHER_FAIL,
    ADD_AD_PUBLISHER_SUCCESS,
    ADD_AD_PUBLISHER_ERROR,
    UPDATE_AD_PUBLISHER_SUCCESS,
    UPDATE_AD_PUBLISHER_ERROR,
    READ_AD_PUBLISHER_SUCCESS,
    STATUS_AD_PUBLISHER_SUCCESS,
    ARCHIVE_AD_PUBLISHER_SUCCESS,
    GET_AD_PUBLISHER_TYPE_SUCCESS,
    GET_AD_PUBLISHER_TYPE_FAIL,
    ADD_AD_PUBLISHER_TYPE_SUCCESS,
    ADD_AD_PUBLISHER_TYPE_ERROR,
} from "./actionTypes"

const INIT_STATE = {
    adPubs: [],
    pagination:{},
    ctgTypes:[],
    error: {},
    loading:false,
    noData:false,
    successMsg: null,
    errorMsg: null,
    adPublisher: null,
    response:null,
    archiveMsg:null,
}

const adPublishers = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_ACTIVITIES:
            return {
                ...state,
                loading: true,
            }
            case NO_DATA_HOUSE_AD_PUBLISHER:
                return {
                    ...state,
                    noData: true,
                }
        case GET_AD_PUBLISHER_SUCCESS:
            return {
                ...state,
                loading:false,
                adPubs: action.payload.response ? action.payload.response : [],
                pagination:action.payload
            }

        case GET_AD_PUBLISHER_FAIL:
            return {
                ...state,
                loading:false,
                error: action.payload,
            }
        case ADD_AD_PUBLISHER_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_AD_PUBLISHER_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_AD_PUBLISHER_SUCCESS' ? null : action.payload,
            }
            case GET_AD_PUBLISHER_TYPE_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    ctgTypes: action.payload,
                }
    
            case GET_AD_PUBLISHER_TYPE_FAIL:
                return {
                    ...state,
                    loading:false,
                    error: action.payload,
                }
            case ADD_AD_PUBLISHER_TYPE_SUCCESS:
    
                return {
                    ...state,
                    successMsg: action.payload,
                }
            case ADD_AD_PUBLISHER_TYPE_ERROR:
                return {
                    ...state,
                    error: action.payload,
                    errorMsg: action.type === 'GET_AD_PUBLISHER_SUCCESS' ? null : action.payload,
                }
        case UPDATE_AD_PUBLISHER_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_AD_PUBLISHER_SUCCESS' ? null : action.payload,
            }
        case UPDATE_AD_PUBLISHER_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_AD_PUBLISHER_SUCCESS' ? null : action.payload,
            }
        case READ_AD_PUBLISHER_SUCCESS:
            return {
                ...state,
                adPublisher: action.payload,
            }
        case STATUS_AD_PUBLISHER_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_AD_PUBLISHER_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default adPublishers