import {
  LOAD_IPMAPS,
  NO_DATA_IPMAPS,
    GET_IPMAPS,
    GET_IPMAPS_FAIL,
    GET_IPMAPS_SUCCESS,
    ADD_IPMAP,
    ADD_IPMAP_SUCCESS,
    ADD_IPMAP_ERROR,
    UPDATE_IPMAP,
    UPDATE_IPMAP_SUCCESS,
    UPDATE_IPMAP_ERROR,
    READ_IPMAP,
    READ_IPMAP_SUCCESS,
    STATUS_IPMAP,
    STATUS_IPMAP_SUCCESS,
    ARCHIVE_IPMAP ,
    ARCHIVE_IPMAP_SUCCESS

  } from "./actionTypes"

  export const loadIpMaps = Params => ({
    type: LOAD_IPMAPS,
    payload:Params
  })

  export const noIpMaps = Params => ({
    type: NO_DATA_IPMAPS,
    payload:Params
  })

  export const getIpMaps = () => ({
    type: GET_IPMAPS,
  })
  
  export const getIpMapsSuccess = IpMaps => ({
    type: GET_IPMAPS_SUCCESS,
    payload: IpMaps,
  })

  export const getIpMapsFail = error => ({
    type: GET_IPMAPS_FAIL,
    payload: error,
  })

  export const addNewIpMap = IpMap => ({
    type: ADD_IPMAP,
    payload: IpMap,
  })
  
  export const addIpMapSuccess = IpMap => ({
    type: ADD_IPMAP_SUCCESS,
    payload: IpMap,
  })
  
  export const addIpMapFail = IpMap => ({
    type: ADD_IPMAP_ERROR,
    payload: IpMap,
  })

  export const updateIpMap = IpMap => ({
    type: UPDATE_IPMAP,
    payload: IpMap,
  })
  
  export const updateIpMapSuccess = IpMap => ({
    type: UPDATE_IPMAP_SUCCESS,
    payload: IpMap,
  })
  
  export const updateIpMapFail = IpMap => ({
    type: UPDATE_IPMAP_ERROR,
    payload: IpMap,
  })

  export const readIpMap = IpMap => ({
    type: READ_IPMAP,
    payload: IpMap,
  })

  export const readIpMapSuccess = IpMap => ({
    type: READ_IPMAP_SUCCESS,
    payload: IpMap,
  })

  export const statusIpMap = IpMap => ({
    type: STATUS_IPMAP,
    payload: IpMap,
  })

  export const statusIpMapSuccess = IpMap => ({
    type: STATUS_IPMAP_SUCCESS,
    payload: IpMap,
  })

  export const archiveIpMap = IpMap => ({
    type: ARCHIVE_IPMAP,
    payload: IpMap,
  })

  export const archiveIpMapSuccess = IpMap => ({
    type: ARCHIVE_IPMAP_SUCCESS,
    payload: IpMap,
  })