import { call, put, takeEvery } from "redux-saga/effects"

import { GET_PUB_PLAYLIST, CREATE_PUB_PLAYLIST,UPDATE_PUB_PLAYLIST,STATUS_PUB_PLAYLIST,ARCHIVE_PLAYLIST,GET_PUB_PLAYLISTBYID } from './actionTypes'

import {
    getPubPlaylistSuccess,
    getPubPlaylistFail,
    getPubPlaylistSuccessById,
    getPubPlaylistFailById,
    createPubPlaylistSuccess,
    createPubPlaylistFail,
    updatePubPlaylistSuccess,
    updatePubPlaylistFail,
    statusPubPlaylistSuccess,
    archivePubPlaylistSuccess,
    archivePubPlaylistFail
} from './actions'

import { getPubPlaylist,getPubPlaylistById, createPubPlaylist, updatePubPlaylist , statusPubPlaylist, archivePubPlaylist} from "helpers/Backend_helper"

function* fetchPubPlaylists({ payload: ply }) {
    try {
        const response = yield call(getPubPlaylist, ply)
        console.log(ply)
        console.log('saga response', response)
        response.response.map((item, index) => {
            item.id = index + 1
            item.checked = false
        })
        yield put(getPubPlaylistSuccess(response.response))
    } catch (err) {
        yield put(getPubPlaylistFail(err))
    }
}

function* fetchPubPlaylistsById({ payload: ply }) {
    try {
        const response = yield call(getPubPlaylistById, ply)
        console.log(ply)
        console.log('saga response', response)
        // response.response.map((item, index) => {
        //     item.id = index + 1
        //     item.checked = false
        // })
        // console.log(response.response)
        yield put(getPubPlaylistSuccessById(response.response))
    } catch (err) {
        yield put(getPubPlaylistFailById(err))
    }
}

function* onCreatePubPlaylist({ payload: playlist }) {
    try {
        const response = yield call(createPubPlaylist, playlist)
        console.log("saga response", response)
        if (response.success) {
            console.log("called saga success")
            yield put(createPubPlaylistSuccess(response))
        }
        else {
            yield put(createPubPlaylistFail(response.message))
        }
    } catch(err){
        yield put(createPubPlaylistFail(err))
    }
}

function* onUpdatePubPlaylist({payload: playlist}){
    try{
        const response = yield call(updatePubPlaylist, playlist)
        console.log("saga response", response)
        if(response.success){
            console.log("success")
            yield put(updatePubPlaylistSuccess(response))
        }else{
            yield put(updatePubPlaylistFail(response.message))
        }
    } catch(err){
        yield put(updatePubPlaylistFail(err))
    }
}

function* onArchivePubPlaylist({payload: playlist}){
    try{
        const response = yield call(archivePubPlaylist, playlist)
        console.log("saga response ", response)
        if(response.success){
            yield put(archivePubPlaylistSuccess(response))
        }else{
            yield put(archivePubPlaylistFail(response))
        }
    }catch(err){
        yield put(archivePubPlaylistFail(err))
    }
}

function* onStatusPubPlaylist({payload: playlist}){
    try{
        const response = yield call(statusPubPlaylist,playlist)
        yield put(statusPubPlaylistSuccess(response))
    }catch(err){
        yield put(statusPubPlaylistSuccess(err))
    }
}

function* pubPlaylistSaga() {
    yield takeEvery(GET_PUB_PLAYLIST,fetchPubPlaylists)
    yield takeEvery(GET_PUB_PLAYLISTBYID, fetchPubPlaylistsById)
    yield takeEvery(CREATE_PUB_PLAYLIST,onCreatePubPlaylist)
    yield takeEvery(UPDATE_PUB_PLAYLIST,onUpdatePubPlaylist)
    yield takeEvery(STATUS_PUB_PLAYLIST,onStatusPubPlaylist)
    yield takeEvery(ARCHIVE_PLAYLIST,onArchivePubPlaylist)
}

export default pubPlaylistSaga;