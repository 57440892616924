import React, { useState, useEffect } from "react";

function Greeting() {
    const [greeting, setGreeting] = useState("");

    useEffect(() => {
        const currentHour = new Date().getHours();
        let message = "";

        if (currentHour >= 0 && currentHour < 12) {
            message = "Good Morning";
        } else if (currentHour >= 12 && currentHour < 17) {
            message = "Good Afternoon";
        } else {
            message = "Good Evening";
        }

        setGreeting(message);
    }, []);

    return <h5 className="text-center" style={{ marginBottom: '1%', color: '#524848',fontSize:'3.5pc',fontWeight:'600',marginTop:'1%' }}>{greeting} !</h5>;
}

export default Greeting;
