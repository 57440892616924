import {
    LOAD_ACTIVITIES,
    NO_DATA,
    GET_BIDDERS,
    GET_BIDDERS_FAIL,
    GET_BIDDERS_SUCCESS,
    ADD_BIDDER,
    ADD_BIDDER_SUCCESS,
    ADD_BIDDER_ERROR,
    UPDATE_BIDDER,
    UPDATE_BIDDER_SUCCESS,
    UPDATE_BIDDER_ERROR,
    READ_BIDDER,
    READ_BIDDER_SUCCESS,
    STATUS_BIDDER,
    STATUS_BIDDER_SUCCESS,
    ARCHIVE_BIDDER ,
    ARCHIVE_BIDDER_SUCCESS

  } from "./actionTypes"

  export const loadActivitiesBidders = Params => ({
    type: LOAD_ACTIVITIES,
    payload:Params
  })

  export const noDataBidders = Params => ({
    type: NO_DATA,
    payload:Params
  })

  export const getBidders = Params => ({
    type: GET_BIDDERS,
    payload:Params
  })
  
  export const getBiddersSuccess = Bidders => ({
    type: GET_BIDDERS_SUCCESS,
    payload: Bidders,
  })

  export const getBiddersFail = error => ({
    type: GET_BIDDERS_FAIL,
    payload: error,
  })

  export const addNewBidder = Bidder => ({
    type: ADD_BIDDER,
    payload: Bidder,
  })
  
  export const addBidderSuccess = bidder => ({
    type: ADD_BIDDER_SUCCESS,
    payload: bidder,
  })
  
  export const addBidderFail = bidder => ({
    type: ADD_BIDDER_ERROR,
    payload: bidder,
  })

  export const updateBidder = bidder => ({
    type: UPDATE_BIDDER,
    payload: bidder,
  })
  
  export const updateBidderSuccess = bidder => ({
    type: UPDATE_BIDDER_SUCCESS,
    payload: bidder,
  })
  
  export const updateBidderFail = bidder => ({
    type: UPDATE_BIDDER_ERROR,
    payload: bidder,
  })

  export const readBidder = bidder => ({
    type: READ_BIDDER,
    payload: bidder,
  })

  export const readBidderSuccess = bidder => ({
    type: READ_BIDDER_SUCCESS,
    payload: bidder,
  })

  export const statusBidder = bidder => ({
    type: STATUS_BIDDER,
    payload: bidder,
  })

  export const statusBidderSuccess = bidder => ({
    type: STATUS_BIDDER_SUCCESS,
    payload: bidder,
  })

  export const archiveBidder = bidder => ({
    type: ARCHIVE_BIDDER,
    payload: bidder,
  })

  export const archiveBidderSuccess = bidder => ({
    type: ARCHIVE_BIDDER_SUCCESS,
    payload: bidder,
  })