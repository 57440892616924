import React, { useEffect, useState, useRef } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Input,
  Spinner,
  Label,
  Modal, FormFeedback,BreadcrumbItem
} from "reactstrap"

import DateRangePickers from '../../../helpers/DateRangePicker'

import axios from "axios";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import {
  getAction_Notifications as onGetAction_Notifications,
} from "store/Notifications/Publisher_Notification/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import New_Publisher_Notification from "./New_Publisher_Notification";
import LoadingTable from "pages/LoaderPages/LoadingTable";
import AdminSidebar from "../Admin_Sidebar/AdminSidebar";

var selectedItem = []
var selectedData = ''
var selectedIndex = []

const Action_Notification = props => {
  const dispatch = useDispatch();
  const [isRight, setIsRight] = useState(false);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const [statusSearch, setStatusSearch] = useState('Active')
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [isArchived, setIsArchived] = useState(false)
  const [selected, setSelected] = useState([])
  // const [actionNotifications, setPubNotifications] = useState([])
  const [selectedNotification, setSelectedNotification] = useState({})
  const [jsTagModalState, setJsTagModalState] = useState(false);

  const [switchLoader, setSwitchLoader] = useState(true)
  // const [loading, setLoading] = useState(false)

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const body = {
    "url": '',
    "requestBody": {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name:userInfo.name
    }
  }

  const { actionNotifications } = useSelector(state => ({
    actionNotifications: state.pub_notifications.actionNotifications,
  }));

  const { loading } = useSelector(state => ({
    loading: state.pub_notifications.loading,
  }));

  useEffect(() => {
    if (actionNotifications !== null) {
      dispatch(onGetAction_Notifications());
    }
  }, []);

  // useEffect(() => {
  //   getNotifications()
  // }, []);

  // const getNotifications = () => {
  //   setLoading(true)
  //   body.url = "/v2.0/alert/Notifications/read/partnerInteraction"
  //   axios.post("/connectOP", body,
  //     {
  //       headers: headers
  //     })
  //     .then((res) => {
  //       console.log("result is ==>", res)
  //       setLoading(false)
  //       if (res.data) {
  //         res.data.map((item, index) => {
  //           item.id = index + 1
  //         })
  //         setPubNotifications(res.data)
  //       }
  //     })
  //     .catch((err) => {
  //       setLoading(false)
  //       console.log("e", err)
  //     });
  // }

  const [rejectedState, setRejectedState] = useState(false);
  const [approveState, setApproveState] = useState(false);
  const [reason, setReason] = useState('');
  const linkRef = useRef();

  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelected([...selected, row.id])
        setSelectedRowsStatus(true)
        selectedIndex.push(row.notificationID)
        selectedItem.push(row.notificationID)
      } else {
        setSelected(selected.filter(x => x !== row.id))
        let index = selectedIndex.indexOf(row.notificationID)
        selectedIndex.splice(index, 1)
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      const ids = rows.map(r => r.id);
      if (isSelect) {
        setSelected(ids)
        rows.map((item, index) => {
          selectedIndex.push(item.notificationID)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
      }
    }
  };





  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const pubNotificationListColumns = [
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "notificationID",
      sort: true,
      formatter: (cellContent, pubNotification) => (
        <>
          <span>{pubNotification.notificationID}</span>
        </>
      ),
    },
    {
      dataField: "createTime",
      text: "Creation Date",
      sort: true,
    },

    {
      text: "Notification Name",
      dataField: "notificationName",
      sort: true,
    },
    {
      text: "Notification Data",
      dataField: "message",
      sort: true,
    },
    {
      text: "Request For ",
      dataField: "notificationInfo",
      sort: true,
    },
    {
      dataField: "NotificationStatus",
      text: "Status",
      sort: true,
      formatter: (cellContent, pubNotification) => (
        <>
          <span className={(pubNotification.isApproved && !pubNotification.isRejected) ? "active-state" : (!pubNotification.isApproved && pubNotification.isRejected) ? "inactive-state" : 'archived-state'}>{(pubNotification.isApproved && !pubNotification.isRejected) ? "Approved" : (!pubNotification.isApproved && pubNotification.isRejected) ? "Rejected" : 'Pending'}</span>
        </>

      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',

      // eslint-disable-next-line react/display-name
      formatter: (cellContent, pubNotification) => (
        <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
          <Link to={'#'} onClick={() => { tog_standard(pubNotification); }}>
            <u>View</u>
          </Link>
        </div>
      ),
    },
  ];

  function tog_standard(notification) {
    console.log(notification)
    if (notification !== '') {
      setSelectedNotification(notification)
      readNotification(notification.notificationID)
    } else {
      setJsTagModalState(!jsTagModalState);
    }
  }

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };


  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const handleApprove = (e) => {

    let body = {
      "url": 'https://report-api.bidsxchange.com/v1.0/Query/Revenue/RequestApprove',
      "requestBody": {
        reasonForRejection: reason,
        isApproved: true,
        token: selectedNotification.tokenForToUseAPI
      }
    }
    if (selectedNotification.notificationInfo === 'AdFormat') {
      body.url = "http://127.0.0.1:3006/v1.0/exploreAdFormat/read/approve"
    }
    axios.post("/v1.0/connectReportAPI", body,
      {
        headers: {
          "Content-Type": "application/json",
          "Accept": 'application/json'
        }
      })
      .then((res) => {
        console.log("result", res)
        if (res.data && res.data.success) {
          toastr.success("Request Approved Successfully...")
          setJsTagModalState(false);
          dispatch(onGetAction_Notifications());
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const handleReject = (e) => {
    setRejectedState(true)
    if (reason) {
      let body = {
        "url": 'https://report-api.bidsxchange.com/v1.0/Query/Revenue/RequestApprove',
        "requestBody": {
          reasonForRejection: reason,
          isApproved: false,
          token: selectedNotification.tokenForToUseAPI
        }
      }
      if (selectedNotification.notificationInfo === 'AdFormat') {
        body.url = "http://127.0.0.1:3006/v1.0/exploreAdFormat/read/approve"
      }
      axios.post("/v1.0/connectReportAPI", body,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json'
          }
        })
        .then((res) => {
          console.log("result", res)
          if (res.data && res.data.success) {
            toastr.success("Request Rejected Successfully...")
            setJsTagModalState(false);
            setRejectedState(false)
            dispatch(onGetAction_Notifications());
          }
        })
        .catch((err) => {
          console.log("e", err)
        });
    }
  }


  const readNotification = (notificationID) => {
    selectedItem.push(notificationID)
    console.log(notificationID)
    statusNotification()
  }

  const statusNotification = (event) => {
    console.log('clicked')
    let body2 = {
        userID: userInfo.userID,
        notificationID: selectedItem,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name:userInfo.name
    }
    axios.post("/v2.0/alert/Notifications/updateIsRead", body2,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is notifications ==>", res)
        if (res.success) {
          setJsTagModalState(!jsTagModalState);
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  useEffect(() => {
    console.log(loading)
    if (loading) {
      setSwitchLoader(true)
    }
  }, [loading]);

  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
         {/* <AdminSidebar />
      <div className="main-content access-management"> */}
        <div className="page-content">
          <Helmet>
            <title> Publisher Approval | Bidsxchange</title>
          </Helmet>
          <Container fluid style={{ pointerEvents: (loading) ? 'none' : 'unset' }}>
            {/* Render Breadcrumbs */}
            <div className=" d-sm-flex  justify-content-between">
              <Breadcrumbs title="Admin" breadcrumbItem=" Publisher Approval" breadList="All Publisher Approvals" />

            </div>
            {switchLoader &&
              <LoadingTable loading={loading} targetLoading={handleSwitch} />
            }
            {!switchLoader &&
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody aria-hidden="true">
                            <ToolkitProvider
                              keyField={keyField}
                              data={actionNotifications}
                              columns={pubNotificationListColumns}
                              // bootstrap4
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>

                                  <Row className="mb-2">
                                    <Col sm="4">
                                      <div className="search-box ms-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar {...toolkitProps.searchProps} />
                                          <i className="bx bx-search-alt search-icon" />
                                        </div>
                                      </div>
                                    </Col>

                                  </Row>
                                  <Row>
                                    {/* <Col xl="12">
                                        {selectedRowsStatus && selectedIndex.length > 0 &&
                                          <div className="row m-0 React-Table-Selection">
                                          <div style={{ padding: '0.5% 2%' }}>
                                              <button color="primary" className="btn btn-primary " onClick={statusPub_Notification(true)}>
                                                Activate
                                              </button>
                                              &nbsp;  &nbsp;
                                              <button color="primary" className="btn btn-primary " onClick={statusPub_Notification(false)}>
                                                DeActivate
                                              </button>
                                              &nbsp;  &nbsp;
                                              <Button color="primary" className="btn btn-primary " onClick={archivePub_Notification} >
                                                Archive
                                              </Button>
                                              <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                                className="mdi mdi-close-thick font-size-18"
                                              ></i></Button>
                                            </div>
                                          </div>
                                        }
                                      </Col> */}
                                    <Col xl="12" className="table-responsive">
                                      <div >
                                        <BootstrapTable
                                          keyField={keyField}
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          pagination={paginationFactory(pageOptions)}
                                          noDataIndication={<h5 className="text-center">No Data</h5>}
                                        // ref={node}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            }
          </Container>
        </div>
        {/* </div> */}
      {/* </div> */}

      {selectedNotification &&
        <Modal
          size="lg"
          isOpen={jsTagModalState}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {selectedNotification.notificationName}
            </h5>
            <button
              type="button"
              onClick={() => {
                setJsTagModalState(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="row mb-2">
              <div className="col-md-11">
                <h5>Message</h5>
              </div>
            </div>
            <p>{selectedNotification.message}</p>
            <br />
            {(!selectedNotification.isApproved && !selectedNotification.isRejected) &&
              <div className="mb-3">
                <Label className="form-label">Reason for Rejection <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Input type="text" name="reason" value={reason} onChange={(e) => setReason(e.target.value)} className={(rejectedState && !reason ? ' is-invalid' : '')} placeholder='Please Enter Reason for Rejection ' />
                {rejectedState && !reason ? (
                  <FormFeedback type="invalid">{'Reason is required for Rejection'}</FormFeedback>
                ) : null}
              </div>
            }
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                setJsTagModalState(false);
              }}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              Close
            </button>
            {(!selectedNotification.isApproved && !selectedNotification.isRejected) &&
              <Button
                color="danger"
                className="font-16 btn-block btn btn-danger ml-4"
                onClick={handleReject}
                disabled={(!selectedNotification.isApproved && selectedNotification.isRejected)}
              >
                Reject
              </Button>
            }
            &nbsp;&nbsp;
            {(!selectedNotification.isApproved && !selectedNotification.isRejected) &&
              <Button
                color="primary"
                className="font-16 btn-block btn btn-primary ml-4"
                onClick={handleApprove}
                disabled={selectedNotification.isApproved && !selectedNotification.isRejected}
              >
                Approve
              </Button>
            }
            {(selectedNotification.isApproved && !selectedNotification.isRejected) &&
              <Button
                color="primary"
                className="font-16 btn-block btn btn-primary ml-4"
                disabled={selectedNotification.isApproved && !selectedNotification.isRejected}
              >
                Approved
              </Button>
            }
            {(!selectedNotification.isApproved && selectedNotification.isRejected) &&
              <Button
                color="danger"
                className="font-16 btn-block btn btn-danger ml-4"
                disabled={!selectedNotification.isApproved && selectedNotification.isRejected}
              >
                Rejected
              </Button>
            }
          </div>
        </Modal>
      }
    </React.Fragment >
  )
}

Action_Notification.propTypes = {}

export default Action_Notification