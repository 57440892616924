import {
  LOAD_ACTIVITIES,
  NO_DATA_ROLES,
    GET_ROLES,
    GET_ROLES_FAIL,
    GET_ROLES_SUCCESS,
    ADD_ROLE,
    ADD_ROLE_SUCCESS,
    ADD_ROLE_ERROR,
    UPDATE_ROLE,
    UPDATE_ROLE_SUCCESS,
    UPDATE_ROLE_ERROR,
    READ_ROLE,
    READ_ROLE_SUCCESS,
    STATUS_ROLE,
    STATUS_ROLE_SUCCESS,
    ARCHIVE_ROLE ,
    ARCHIVE_ROLE_SUCCESS,

  } from "./actionTypes"

  export const loadActivitiesRoles = Params => ({
    type: LOAD_ACTIVITIES,
    payload:Params
  })

  export const noRoles = Params => ({
    type: NO_DATA_ROLES,
    payload:Params
  })

  export const getRoles = Params => ({
    type: GET_ROLES,
    payload:Params
  })
  
  export const getRolesSuccess = Roles => ({
    type: GET_ROLES_SUCCESS,
    payload: Roles,
  })

  export const getRolesFail = error => ({
    type: GET_ROLES_FAIL,
    payload: error,
  })

  export const addNewRole = Role => ({
    type: ADD_ROLE,
    payload: Role,
  })
  
  export const addRoleSuccess = Role => ({
    type: ADD_ROLE_SUCCESS,
    payload: Role,
  })
  
  export const addRoleFail = Role => ({
    type: ADD_ROLE_ERROR,
    payload: Role,
  })

  export const updateRole = Role => ({
    type: UPDATE_ROLE,
    payload: Role,
  })
  
  export const updateRoleSuccess = Role => ({
    type: UPDATE_ROLE_SUCCESS,
    payload: Role,
  })
  
  export const updateRoleFail = Role => ({
    type: UPDATE_ROLE_ERROR,
    payload: Role,
  })

  export const readRole = Role => ({
    type: READ_ROLE,
    payload: Role,
  })

  export const readRoleSuccess = Role => ({
    type: READ_ROLE_SUCCESS,
    payload: Role,
  })

  export const statusRole = Role => ({
    type: STATUS_ROLE,
    payload: Role,
  })

  export const statusRoleSuccess = Role => ({
    type: STATUS_ROLE_SUCCESS,
    payload: Role,
  })

  export const archiveRole = Role => ({
    type: ARCHIVE_ROLE,
    payload: Role,
  })

  export const archiveRoleSuccess = Role => ({
    type: ARCHIVE_ROLE_SUCCESS,
    payload: Role,
  })
