/* KEYWORD */
export const LOAD_KEYWORDS = "LOAD_KEYWORDS"
export const NO_DATA_KEYWORDS = "NO_DATA_KEYWORDS"
export const GET_KEYWORDS = "GET_KEYWORDS"
export const GET_KEYWORDS_SUCCESS = "GET_KEYWORDS_SUCCESS"
export const GET_KEYWORDS_FAIL = "GET_KEYWORDS_FAIL"

export const ADD_KEYWORD = "ADD_KEYWORD"
export const ADD_KEYWORD_SUCCESS = "ADD_KEYWORD_SUCCESS"
export const ADD_KEYWORD_ERROR = "ADD_KEYWORD_ERROR"

export const UPDATE_KEYWORD = "UPDATE_KEYWORD"
export const UPDATE_KEYWORD_SUCCESS = "UPDATE_KEYWORD_SUCCESS"
export const UPDATE_KEYWORD_ERROR = "UPDATE_KEYWORD_ERROR"

export const READ_KEYWORD = "READ_KEYWORD"
export const READ_KEYWORD_SUCCESS = "READ_KEYWORD_SUCCESS"

export const STATUS_KEYWORD = "STATUS_KEYWORD"
export const STATUS_KEYWORD_SUCCESS = "STATUS_KEYWORD_SUCCESS"

export const ARCHIVE_KEYWORD = "ARCHIVE_KEYWORD"
export const ARCHIVE_KEYWORD_SUCCESS = "ARCHIVE_KEYWORD_SUCCESS"