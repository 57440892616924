/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types'

const LoaderChart = props => {
    const [timer, setTimer] = useState({ count: 0 })
    const increment = useRef(null)

    useEffect(() => {
        handleStart()
    }, []);

    useEffect(() => {
        // console.log(timer.count,props.loading)
        if(props.loading){
            if (timer.count >= 6) {
                clearInterval(increment.current)
                setTimer({ count: 0 })
                props.targetLoading()
            }
        }else{
            if (timer.count >= 2) {
                clearInterval(increment.current)
                setTimer({ count: 0 })
                props.targetLoading()
            }
        }
    
    }, [timer.count,props.loading]);

    const handleStart = () => {
        increment.current = setInterval(() => {
            setTimer(prevState => ({
                count: prevState.count + 1
            }))
      console.log()
        }, 1000)
    }

  return (
        <div style={{minHeight: '565px',background:'gray'}} >
                
        </div>
  )
}

LoaderChart.propTypes = {}

export default LoaderChart