import {
    LOAD_KEYWORDS,
    NO_DATA_KEYWORDS,
    GET_KEYWORDS_SUCCESS,
    GET_KEYWORDS_FAIL,
    ADD_KEYWORD_SUCCESS,
    ADD_KEYWORD_ERROR,
    UPDATE_KEYWORD_SUCCESS,
    UPDATE_KEYWORD_ERROR,
    READ_KEYWORD_SUCCESS,
    STATUS_KEYWORD_SUCCESS,
    ARCHIVE_KEYWORD_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    keywordList: [],
    error: {},
    loading:false,
    noData:false,
    successMsg: null,
    errorMsg: null,
    keyword: null,
    response:null,
    archiveMsg:null,
}

const keywords = (state = INIT_STATE, action) => {
    
    switch (action.type) {
        case LOAD_KEYWORDS:
            return {
                ...state,
                loading: true,
            }
            case NO_DATA_KEYWORDS:
                return {
                    ...state,
                    noData: true,
                }
        case GET_KEYWORDS_SUCCESS:
            return {
                ...state,
                loading: false,
                keywordList: action.payload,
            }

        case GET_KEYWORDS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case ADD_KEYWORD_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_KEYWORD_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_KEYWORDS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_KEYWORD_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_KEYWORDS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_KEYWORD_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_KEYWORDS_SUCCESS' ? null : action.payload,
            }
        case READ_KEYWORD_SUCCESS:
            return {
                ...state,
                keyword: action.payload,
            }
        case STATUS_KEYWORD_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_KEYWORD_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default keywords