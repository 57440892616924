import {
    LOAD_NET_BLOCKINGS,
    NO_DATA,
    GET_NET_BLOCKINGS_SUCCESS,
    GET_NET_BLOCKINGS_FAIL,
    ADD_NET_BLOCKING_SUCCESS,
    ADD_NET_BLOCKING_ERROR,
    UPDATE_NET_BLOCKING_SUCCESS,
    UPDATE_NET_BLOCKING_ERROR,
    READ_NET_BLOCKING_SUCCESS,
    STATUS_NET_BLOCKING_SUCCESS,
    ARCHIVE_NET_BLOCKING_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
    blocks: [],
    error: {},
    loading:false,
    noData:false,
    successMsg: null,
    errorMsg: null,
    netBlocking: null,
    response:null,
    archiveMsg:null,
    apiCallMsg:null
}

const netBlockings = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_NET_BLOCKINGS:
            return {
                ...state,
                loading: true,
            }
        case NO_DATA:
                return {
                    ...state,
                    noData: true,
                }
        case GET_NET_BLOCKINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                blocks: action.payload,
            }

        case GET_NET_BLOCKINGS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case ADD_NET_BLOCKING_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_NET_BLOCKING_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_NET_BLOCKINGS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_NET_BLOCKING_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_NET_BLOCKINGS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_NET_BLOCKING_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_NET_BLOCKINGS_SUCCESS' ? null : action.payload,
            }
        case READ_NET_BLOCKING_SUCCESS:
            return {
                ...state,
                netBlocking: action.payload,
            }
        case STATUS_NET_BLOCKING_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_NET_BLOCKING_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default netBlockings