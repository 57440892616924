/* CHILDADUNIT */
export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES"
export const NO_DATA = "NO_DATA"
export const GET_CHILDADUNITS = "GET_CHILDADUNITS"
export const GET_CHILDADUNITS_SUCCESS = "GET_CHILDADUNITS_SUCCESS"
export const GET_CHILDADUNITS_FAIL = "GET_CHILDADUNITS_FAIL"

export const ADD_CHILDADUNIT = "ADD_CHILDADUNIT"
export const ADD_CHILDADUNIT_SUCCESS = "ADD_CHILDADUNIT_SUCCESS"
export const ADD_CHILDADUNIT_ERROR = "ADD_CHILDADUNIT_ERROR"

export const UPDATE_CHILDADUNIT = "UPDATE_CHILDADUNIT"
export const UPDATE_CHILDADUNIT_SUCCESS = "UPDATE_CHILDADUNIT_SUCCESS"
export const UPDATE_CHILDADUNIT_ERROR = "UPDATE_CHILDADUNIT_ERROR"

export const READ_CHILDADUNIT = "READ_CHILDADUNIT"
export const READ_CHILDADUNIT_SUCCESS = "READ_CHILDADUNIT_SUCCESS"

export const STATUS_CHILDADUNIT = "STATUS_CHILDADUNIT"
export const STATUS_CHILDADUNIT_SUCCESS = "STATUS_CHILDADUNIT_SUCCESS"

export const ARCHIVE_CHILDADUNIT = "ARCHIVE_CHILDADUNIT"
export const ARCHIVE_CHILDADUNIT_SUCCESS = "ARCHIVE_CHILDADUNIT_SUCCESS"