/* STBR_SETTING */
export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES"
export const NO_DATA = "NO_DATA"
export const GET_STBR_SETTINGS = "GET_STBR_SETTINGS"
export const GET_STBR_SETTINGS_SUCCESS = "GET_STBR_SETTINGS_SUCCESS"
export const GET_STBR_SETTINGS_FAIL = "GET_STBR_SETTINGS_FAIL"

export const ADD_STBR_SETTING = "ADD_STBR_SETTING"
export const ADD_STBR_SETTING_SUCCESS = "ADD_STBR_SETTING_SUCCESS"
export const ADD_STBR_SETTING_ERROR = "ADD_STBR_SETTING_ERROR"

export const UPDATE_STBR_SETTING = "UPDATE_STBR_SETTING"
export const UPDATE_STBR_SETTING_SUCCESS = "UPDATE_STBR_SETTING_SUCCESS"
export const UPDATE_STBR_SETTING_ERROR = "UPDATE_STBR_SETTING_ERROR"

export const READ_STBR_SETTING = "READ_STBR_SETTING"
export const READ_STBR_SETTING_SUCCESS = "READ_STBR_SETTING_SUCCESS"

export const STATUS_STBR_SETTING = "STATUS_STBR_SETTING"
export const STATUS_STBR_SETTING_SUCCESS = "STATUS_STBR_SETTING_SUCCESS"

export const ARCHIVE_STBR_SETTING = "ARCHIVE_STBR_SETTING"
export const ARCHIVE_STBR_SETTING_SUCCESS = "ARCHIVE_STBR_SETTING_SUCCESS"