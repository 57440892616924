/* eslint-disable react/prop-types */
import React from "react"
import PropTypes from 'prop-types';
import ReactApexChart from "react-apexcharts"

const StackedColumnChart = props => {
  
  const options = {
    chart: {
      height: 550,
      type: 'line',
      stacked: false,
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [1, 1, 4],
      curve: 'smooth'
    },
    title: {
      text: props.title,
      align: 'left',
      offsetX: 110
    },
    noData: {
      text: 'No Data Available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: 'gray',
        fontSize: '25px',
        fontFamily: undefined,
        fontWeight:'bold'
      }
    },
    xaxis: {
      categories: props.xAxisData,
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#ADB5BD'
        },
        labels: {
          style: {
            colors: '#ADB5BD',
          }
        },
        title: {
          text: "Total Code Served",
          style: {
            color: '#ADB5BD',
          }
        },
        tooltip: {
          enabled: true
        }
      },
      {
        seriesName: 'Estimated Revenue',
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#6C757D'
        },
        labels: {
          style: {
            colors: '#6C757D',
          }
        },
        title: {
          text: "Estimated Revenue",
          style: {
            color: '#6C757D',
          }
        },
      },
      {
        seriesName: 'Estimated eCPM',
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#343A40'
        },
        labels: {
          style: {
            colors: '#343A40',
          },
        },
        title: {
          text: "Estimated eCPM",
          style: {
            color: '#343A40',
          }
        }
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60
      },
    },
    colors: ["#ADB5BD","#000000", "#6C757D", ],
    legend: { 
      horizontalAlign: 'center',
      position: 'bottom',
      offsetX: 40
    }
  
    }
    return (
      <React.Fragment>
        <ReactApexChart
          options={options}
          series={[...props.chartData]}
          type="line"
          height="550"
        />
      </React.Fragment>
    );
  }

StackedColumnChart.propTypes = {
}
export default StackedColumnChart;
