import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_STBR_SETTINGS, ADD_STBR_SETTING, UPDATE_STBR_SETTING, READ_STBR_SETTING, STATUS_STBR_SETTING, ARCHIVE_STBR_SETTING } from "./actionTypes"

import {
  loadActivitiesStbrSetting,
  noDataStbrSetting,
  getStbrSettingsSuccess,
  getStbrSettingsFail,
  addStbrSettingSuccess,
  addStbrSettingFail,
  updateStbrSettingSuccess,
  updateStbrSettingFail,
  readStbrSettingSuccess,
  statusStbrSettingSuccess,
  archiveStbrSettingSuccess
} from "./actions"

//Include Both Helper File with needed methods
import {
  getStbrSetting,
  getStbrSettingsByAdUnit, createStbrSetting, updateStbrSetting, readStbrSetting, statusChangeStbrSetting, archiveStbrSetting
} from "../../../helpers/Backend_helper"

function* fetchStbrSettings({ payload: StbrSetting }) {
  console.log(StbrSetting)
  try {
    yield put(loadActivitiesStbrSetting(true))
    var getStbrSettingList
    if (StbrSetting && StbrSetting.adUnitID !== '') {
      getStbrSettingList = yield call(getStbrSettingsByAdUnit, StbrSetting)
      if (getStbrSettingList.statusCode === 200) {
        getStbrSettingList.response.StandardBannerSettings.map((item, index) => {
          item.id = index + 1
        })
        if (getStbrSettingList.response.StandardBannerSettings.length === 0) {
          yield put(noDataStbrSetting(true))
        }
        yield put(getStbrSettingsSuccess(getStbrSettingList))
      }
    } else if (StbrSetting && StbrSetting.adUnitID === '') {
      delete StbrSetting.adUnitID
      getStbrSettingList = yield call(getStbrSetting, StbrSetting)
      if (getStbrSettingList.statusCode === 200) {
        getStbrSettingList.response.StandardBannerSettings.map((item, index) => {
          item.id = index + 1
        })
        if (getStbrSettingList.response.StandardBannerSettings.length === 0) {
          yield put(noDataStbrSetting(true))
        }
        yield put(getStbrSettingsSuccess(getStbrSettingList))
      }
    } else {
      getStbrSettingList = []
      yield put(getStbrSettingsSuccess(getStbrSettingList))
    }
  } catch (error) {
    yield put(getStbrSettingsFail(error))
  }
}

function* onReadStbrSetting({ payload: StbrSetting }) {
  try {
    console.log(StbrSetting)
    const response = yield call(readStbrSetting, StbrSetting)
    yield put(readStbrSettingSuccess(response))
  } catch (error) {
    yield put(readStbrSettingSuccess(error))
  }
}

function* onAddStbrSetting({ payload: StbrSetting }) {
  try {
    const response = yield call(createStbrSetting, StbrSetting)
    if (response.statusCode === 200) {
      yield put(addStbrSettingSuccess(response))
    } else {
      yield put(addStbrSettingFail(response))
    }
  } catch (error) {
    yield put(addStbrSettingFail(error))
  }
}

function* onUpdateStbrSetting({ payload: StbrSetting }) {
  try {
    const response = yield call(updateStbrSetting, StbrSetting)
    if (response.statusCode === 200) {
    yield put(updateStbrSettingSuccess(response))
    }
  } catch (error) {
    yield put(statusStbrSettingSuccess(error))
  }
}

function* onStatusStbrSetting({ payload: StbrSetting }) {
  try {
    const response = yield call(statusChangeStbrSetting, StbrSetting)
    if (response.statusCode === 200) {
    yield put(statusStbrSettingSuccess(response))
    }
  } catch (error) {
    yield put(updateStbrSettingFail(error))
  }
}

function* onArchiveStbrSetting({ payload: StbrSetting }) {
  try {
    const response = yield call(archiveStbrSetting, StbrSetting)
    if (response.statusCode === 200) {
    yield put(archiveStbrSettingSuccess(response))
    }
  } catch (error) {
    yield put(archiveStbrSettingSuccess(error))
  }
}

function* StbrSettingSaga() {
  yield takeEvery(GET_STBR_SETTINGS, fetchStbrSettings)
  yield takeEvery(ADD_STBR_SETTING, onAddStbrSetting)
  yield takeEvery(UPDATE_STBR_SETTING, onUpdateStbrSetting)
  yield takeEvery(READ_STBR_SETTING, onReadStbrSetting)
  yield takeEvery(STATUS_STBR_SETTING, onStatusStbrSetting)
  yield takeEvery(ARCHIVE_STBR_SETTING, onArchiveStbrSetting)
}

export default StbrSettingSaga;
