import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from 'prop-types'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import axios from "axios";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    CardFooter,
    Modal,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Spinner
} from "reactstrap"

import DateRangePickers from 'helpers/DateRangePicker'
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import P_Ads_Txt_Crawled from "./P_Ads_Txt_Crawled";

import LoadingTable from "pages/LoaderPages/LoadingTable";

var selectedData = ''

const Publisher_Ads_Txt_Management = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [adsTxtEntries, setAdsTxtEntries] = useState([])
    const [btnprimary1, setBtnprimary1] = useState(false)
    const [adsModal, setAdsModal] = useState(false);
    const [isRight, setIsRight] = useState(false);

    const [switchLoader, setSwitchLoader] = useState(true)
    const [loading, setLoading] = useState(false)

    const handleSwitch = () => {
        setSwitchLoader(!switchLoader)
    }

    
  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setIsRight(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

    const changeDatePick = (event) => {
        console.log(event)
        console.log("start: ", event.startDate._d);
        console.log("end: ", event.endDate._d);
        setFromDate(event.startDate._d.toISOString());
        setToDate(event.endDate._d.toISOString());
    }

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            partnerName: userInfo.name
    }

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    useEffect(() => {
        setLoading(true)
        axios.post("/v2.0/adstxt/ads.txtMonitor/read/forPartner", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                setLoading(false)
                if (res.data.response.length > 0) {
                    res.data.response.map((item, index) => {
                        item.id = index + 1
                    })
                    setAdsTxtEntries([...res.data.response])
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log("e", err)
            });

    }, []);

    const toggleRightCanvas = () => {
        // selectedData = ''
        setIsRight(!isRight);
    };

    const toggleDrawer = (close, adsTxt) => (event) => {
        console.log(event)
        selectedData = adsTxt
        setIsRight(close);
    };

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const adsTxtEntriesListColumns = [
        {
            text: "ID",
 headerClasses:"ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            dataField: "AdsTxtEntrySite",
            text: "Entry Site",
            sort: true,
        },
        {
            dataField: "AdsTxtEntryPublisher",
            text: "Publisher",
            sort: true,
        },
        // {
        //     dataField: "domian",
        //     text: "Domain",
        //     sort: true,
        // },
        // {
        //     text: "queries",
        //     dataField: "Queries - last 7 days",
        //     sort: true,
        // },

        // {
        //     dataField: "status",
        //     text: "Status",
        //     sort: false,
        // },
        // {
        //     dataField: "impact",
        //     text: "impact",
        //     sort: false,
        // },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',
            formatter: (cellContent, adsTxtEntries) => (
                <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
                    <Link className="text-success" to="#" onClick={toggleDrawer(true, adsTxtEntries)}>
                        View
                    </Link>
                </div>
            ),
        }
    ];

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} entries
      </span>
    );
  
  
    const pageOptions = {
      paginationSize: 4,
      pageStartIndex: 1,
      sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
    };
    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    useEffect(() => {
        console.log(loading)
        if (loading) {
            setSwitchLoader(true)
        }
    }, [loading]);

    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
                <div className="page-content">
                    <Helmet>
                        <title>Ads.Txt Queries | Bidsxchange</title>
                    </Helmet>
                    <Container fluid style={{ pointerEvents: (loading) ? 'none' : 'unset' }}>
                        <Breadcrumbs title="Admin" breadcrumbItem="Ads.Txt Queries" />
                        {switchLoader &&
                            <LoadingTable loading={loading} targetLoading={handleSwitch} />
                        }
                        {!switchLoader &&
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                                        <ToolkitProvider
                                                            keyField={keyField}
                                                            data={adsTxtEntries}
                                                            columns={adsTxtEntriesListColumns}
                                                            // bootstrap4
                                                            search
                                                        >
                                                            {toolkitProps => (
                                                                <React.Fragment>
                                                                    <Row className="mb-2">
                                                                        <Col sm="3">
                                                                            <div className="search-box ms-2 mb-2 d-inline-block">
                                                                                <div className="position-relative">
                                                                                    <SearchBar {...toolkitProps.searchProps} />
                                                                                    <i className="bx bx-search-alt search-icon" />
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xl="12" className="table-responsive">
                                                                            <div >
                                                                                <BootstrapTable
                                                                                    keyField={keyField}
                                                                                    responsive
                                                                                    bordered={false}
                                                                                    striped={false}
                                                                                    defaultSorted={defaultSorted}
                                                                                    classes={
                                                                                        "table align-middle table-nowrap"
                                                                                    }
                                                                                    headerWrapperClasses={"thead-light"}
                                                                                    {...toolkitProps.baseProps}
                                                                                    pagination={paginationFactory(pageOptions)}
                                                                                    noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                                // ref={node}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </React.Fragment>
                                                            )}
                                                        </ToolkitProvider>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        }
                    </Container>
                </div>
            {/* </div> */}
            {/* <Modal
                size="lg"
                isOpen={adsModal}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Here the latest ads.txt file crawled
                    </h5>
                    
                    <button
                        type="button"
                        onClick={() => {
                            setAdsModal(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                  
                </div>
                <div className="modal-body">
                    <Card>
                        <CardBody>
                        <p>Last Crawled 28/07/2022 10:00 AM</p>
                        </CardBody>
                        <CardFooter>

                        </CardFooter>
                    </Card>
                    <br />
                    <div className="d-flex">
                        <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                        >
                            Copy Text to ClipBoard
                        </Button>&nbsp;&nbsp;&nbsp;
                        <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                        >
                            Download Ads.txt File
                        </Button>
                    </div>
                </div>
            </Modal> */}

            <Offcanvas isOpen={isRight} direction='end'  >
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {' Ads.Txt Crawled Data'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <P_Ads_Txt_Crawled closeCanvas={toggleDrawer(false, '')} selectedAdsTxtEntries={selectedData} />
                </OffcanvasBody>
            </Offcanvas>

        </React.Fragment >
    )
}

Publisher_Ads_Txt_Management.propTypes = {}

export default Publisher_Ads_Txt_Management