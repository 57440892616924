/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody, Spinner, Label, Input, BreadcrumbItem
} from "reactstrap"

import axios from "axios";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import queryString from 'query-string';

//redux
import { useSelector, useDispatch } from "react-redux";
import LoadingTable from "pages/LoaderPages/LoadingTable";
import AssociateBuyerSettings from "./AssociateBuyerSettings";


var selectedData = ''

const AssociateExistingBuyer = props => {
    const [isRight, setIsRight] = useState(false);
    const [switchLoader, setSwitchLoader] = useState(true)
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [buyersListData, setBuyersListData] = useState([])
    const parsed = queryString.parse(window.location.search);
    const nextRef = useRef();

    const handleSwitch = () => {
        setSwitchLoader(!switchLoader)
    }

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            name: userInfo.name,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
        }
    

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    useEffect(() => {
        if (parsed.status === 'AssociateBuyers') {
            selectedData = ''
            setIsRight(!isRight);
        }
    }, []);

    const { loading } = useSelector(state => ({
        loading: state.headerBids.loading,
    }));

    useEffect(() => {
        console.log(loading)
        if (loading) {
            setSwitchLoader(true)
        }

    }, [loading]);



    const toggleRightCanvas = () => {
        selectedData = ''
        setIsRight(!isRight);
        // if (parsed.status === 'EditAssociateAdUnits') {
        //     nextRef.current.click()
        //     setIsRight(!isRight);
        // }
    };

    const toggleEnableScroll = () => {
        setIsEnableScroll(!isEnableScroll);
    };

    const toggleDrawer = (close, adUnit) => (event) => {
        console.log(event)
        // nextRef.current.click()
        if (event === 'Create') {
            toastr.success('HBBuyer Saved Successfully!...')
        }
        if (event === 'Update') {
            toastr.success('HBBuyer Updated Successfully!...')
            // nextRef.current.click()
            retrieveAdUnits()
        }
        selectedData = adUnit
        setIsRight(close);
    };

    useEffect(() => {
        retrieveAdUnits()
    }, []);

    const retrieveAdUnits = () => {
        axios.post(userInfo.userRole === 'SupplyPartner' ? "/v2.0/headderBiddingSupplyPartner/HB_Buyers/associatedBuyers/SupplyPartner/" + parsed.adUnitID : "/v2.0/headderBidding/HB_Buyers/associatedBuyers/" + parsed.adUnitID, body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is headerBids ==>", res)
                if (res.data.response) {
                    res.data.response.map((item, index) => {
                        item.id = index + 1
                    })
                    setBuyersListData(res.data.response)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }



    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const buyersListColumns = [
        {
            text: "ID",
            headerClasses: "ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            text: "ID",
            headerClasses: "ID",
            dataField: "hbBuyerID",
            sort: true,
        },
        {
            text: "Buyer Name",
            dataField: "hbBuyerName",
            sort: true,
        },

        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',

            // eslint-disable-next-line react/display-name
            formatter: (cellContent, adUnit) => (
                <div className="d-flex gap-3">
                    <span className="text-success " onClick={toggleDrawer(true, adUnit)} >
                        <i
                            className="mdi mdi-pencil font-size-18"
                            id="edittooltip"
                        ></i>
                    </span>
                    {/* <span className="text-secondary " onClick={() => { tog_standard(adUnit); }} style={{ cursor: 'pointer' }} >
                        <i
                            className="bx bx-unlink font-size-18"
                            id="edittooltip"
                        ></i>
                    </span> */}
                </div>
            ),
        },
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );

    const { SearchBar } = Search;
    const pageOptions = {
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        totalSize: buyersListData.length,
        custom: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };


    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
            <div className="page-content">
                <Helmet>
                    <title>Associate Buyer | Bidsxchange</title>
                </Helmet>
                <Container fluid={true}>

                    <div className=" d-sm-flex  justify-content-between">
                        <Breadcrumbs title="Demand" breadcrumbItem="Associate Buyer " adUnitName={parsed.adUnitName} />
                        {/* <div className="text-sm-end">
                            <h5>Ad Unit : {parsed.adUnitName}</h5> &nbsp;&nbsp;
                        </div> */}
                    </div>
                    {switchLoader &&
                        <LoadingTable loading={loading} targetLoading={handleSwitch} />
                    }
                    {!switchLoader &&
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField={keyField}
                                            columns={buyersListColumns}
                                            data={buyersListData}
                                        >
                                            {({ paginationProps, paginationTableProps }) => {
                                                return (
                                                    <ToolkitProvider
                                                        keyField={keyField}
                                                        data={buyersListData}
                                                        columns={buyersListColumns}
                                                        // bootstrap4
                                                        search
                                                    >
                                                        {toolkitProps => (
                                                            <React.Fragment>
                                                                <Row className="mb-2">
                                                                    <Col sm="4">
                                                                        <div className="search-box ms-2 mb-2 d-inline-block">
                                                                            <div className="position-relative">
                                                                                <SearchBar {...toolkitProps.searchProps} />
                                                                                <i className="bx bx-search-alt search-icon" />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm="8" >
                                                                        <div className="text-sm-end">
                                                                            <Button
                                                                                color="primary"
                                                                                className="font-16 btn-block btn btn-primary"
                                                                                onClick={toggleRightCanvas}
                                                                            >
                                                                                <i className="mdi mdi-plus-circle-outline me-1" />
                                                                                Associate Buyer
                                                                            </Button>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xl="12">
                                                                        <div className="table-responsive">
                                                                            <BootstrapTable
                                                                                keyField={keyField}
                                                                                responsive
                                                                                bordered={false}
                                                                                striped={false}
                                                                                defaultSorted={defaultSorted}
                                                                                // selectRow={selectRow}
                                                                                classes={
                                                                                    "table align-middle table-nowrap"
                                                                                }
                                                                                headerWrapperClasses={"thead-light"}
                                                                                {...toolkitProps.baseProps}
                                                                                {...paginationTableProps}
                                                                                noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                            // ref={node}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="align-items-md-center mt-30">
                                                                    <Col className="inner-custom-pagination d-flex">
                                                                        <div className="d-inline">
                                                                            <PaginationTotalStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                        <div className="text-md-center d-inline " style={{ marginLeft: '5%' }} >
                                                                            <span>Show Rows : </span> &nbsp;&nbsp;
                                                                            <SizePerPageDropdownStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                        <div className="text-md-right ms-auto">
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                                );
                                            }}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </Container>
            </div>
            {/* </div> */}

            <Offcanvas isOpen={isRight} direction='end'>
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {selectedData ? 'Edit Associated Buyer' : 'Associate Buyer'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <AssociateBuyerSettings closeCanvas={toggleDrawer(false, '')} selectedHBBuyer={selectedData} selectedBuyerID={parsed.buyerID} selectedBidder={parsed.bidderName} />
                </OffcanvasBody>
            </Offcanvas>
            {parsed.status === 'EditAssociateAdUnits' &&
                <Link ref={nextRef} to={`/HB_AdUnits?buyerID=${parsed.buyerID}&adUnitName=${parsed.adUnitName}&bidderName=${parsed.bidderName}&adUnitID=${parsed.adUnitID}&status=EditAssociateAdUnits`}  ></Link>
            }

        </React.Fragment>
    )
}

AssociateExistingBuyer.propTypes = {

}

export default AssociateExistingBuyer
