import {
  LOAD_ACTIVITIES_IOS,
  NO_DATA,
    GET_IOS,
    GET_IOS_FAIL,
    GET_IOS_SUCCESS,
    ADD_IOS,
    ADD_IOS_SUCCESS,
    ADD_IOS_ERROR,
    UPDATE_IOS,
    UPDATE_IOS_SUCCESS,
    UPDATE_IOS_ERROR,
    READ_IOS,
    READ_IOS_SUCCESS,
    STATUS_IOS,
    STATUS_IOS_SUCCESS,
    ARCHIVE_IOS ,
    ARCHIVE_IOS_SUCCESS

  } from "./actionTypes"

  export const loadActivitiesIOs = Params => ({
    type: LOAD_ACTIVITIES_IOS,
    payload:Params
  })

  export const noDataIOs = Params => ({
    type: NO_DATA,
    payload:Params
  })

  export const getIOs = Params => ({
    type: GET_IOS,
    payload:Params
  })
  
  export const getIOsSuccess = IOs => ({
    type: GET_IOS_SUCCESS,
    payload: IOs,
  })

  export const getIOsFail = error => ({
    type: GET_IOS_FAIL,
    payload: error,
  })

  export const addNewIOs = IOs => ({
    type: ADD_IOS,
    payload: IOs,
  })
  
  export const addIOsSuccess = iOs => ({
    type: ADD_IOS_SUCCESS,
    payload: iOs,
  })
  
  export const addIOsFail = iOs => ({
    type: ADD_IOS_ERROR,
    payload: iOs,
  })

  export const updateIOs = iOs => ({
    type: UPDATE_IOS,
    payload: iOs,
  })
  
  export const updateIOsSuccess = iOs => ({
    type: UPDATE_IOS_SUCCESS,
    payload: iOs,
  })
  
  export const updateIOsFail = iOs => ({
    type: UPDATE_IOS_ERROR,
    payload: iOs,
  })

  export const readIOs = iOs => ({
    type: READ_IOS,
    payload: iOs,
  })

  export const readIOsSuccess = iOs => ({
    type: READ_IOS_SUCCESS,
    payload: iOs,
  })

  export const statusIOs = iOs => ({
    type: STATUS_IOS,
    payload: iOs,
  })

  export const statusIOsSuccess = iOs => ({
    type: STATUS_IOS_SUCCESS,
    payload: iOs,
  })

  export const archiveIOs = iOs => ({
    type: ARCHIVE_IOS,
    payload: iOs,
  })

  export const archiveIOsSuccess = iOs => ({
    type: ARCHIVE_IOS_SUCCESS,
    payload: iOs,
  })