/* CATEGORY */
export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES"
export const NO_DATA_HOUSE_CATEGORY = "NO_DATA_HOUSE_CATEGORY"
export const GET_CATEGORY = "GET_CATEGORY"
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS"
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL"

export const ADD_CATEGORY = "ADD_CATEGORY"
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS"
export const ADD_CATEGORY_ERROR = "ADD_CATEGORY_ERROR"

export const UPDATE_CATEGORY = "UPDATE_CATEGORY"
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS"
export const UPDATE_CATEGORY_ERROR = "UPDATE_CATEGORY_ERROR"

export const READ_CATEGORY = "READ_CATEGORY"
export const READ_CATEGORY_SUCCESS = "READ_CATEGORY_SUCCESS"

export const STATUS_CATEGORY = "STATUS_CATEGORY"
export const STATUS_CATEGORY_SUCCESS = "STATUS_CATEGORY_SUCCESS"

export const ARCHIVE_CATEGORY = "ARCHIVE_CATEGORY"
export const ARCHIVE_CATEGORY_SUCCESS = "ARCHIVE_CATEGORY_SUCCESS"

export const GET_CATEGORY_TYPE = "GET_CATEGORY_TYPE"
export const GET_CATEGORY_TYPE_SUCCESS = "GET_CATEGORY_TYPE_SUCCESS"
export const GET_CATEGORY_TYPE_FAIL = "GET_CATEGORY_TYPE_FAIL"

export const ADD_CATEGORY_TYPE = "ADD_CATEGORY_TYPE"
export const ADD_CATEGORY_TYPE_SUCCESS = "ADD_CATEGORY_TYPE_SUCCESS"
export const ADD_CATEGORY_TYPE_ERROR = "ADD_CATEGORY_TYPE_ERROR"