/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import React, { useState, useRef, useEffect } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import {
    Card,
    CardBody,
    CardTitle,
    Container,
    Row, Col, Input,
    Button,
    Spinner, Label, Form
} from "reactstrap"

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import AppBlock from "assets/files/Appblock.xlsx"
import DeviceBlock from "assets/files/deviceBlock.xlsx"
import DomainBlock from "assets/files/domainBlock.xlsx"
import UserAgentBlock from "assets/files/userAgentBlock.xlsx"
import IPV6Block from "assets/files/IPV6Block.xlsx"
import IPV4Block from "assets/files/IPV4block.xlsx"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';


var fileNameEntry = ''
var list = []
var brandList = []
var itemList = []

const UploadBlockings = props => {
    /* eslint-disable react/prop-types */
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [videoContent, setVideoContent] = useState({
        videoName: '',
        videoUrl: '',
        thumbnail: '',
        duration: false,
        language: '',
        category: ''
    });
    const [loader, setLoader] = useState(false);
    const [publisherReport, setPublisherReport] = useState({
        fileName: '',
        networkBlockType: props.networkBlockType,
        groupName: '',
        networkBlockList: [],
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
    });
    const ref = useRef();
    const [inputKey, setInputKey] = useState(Math.random().toString(36))
    const [downloadFileBtn, setDownloadFileBtn] = useState(false)
    const [downloadUrl, setDownloadUrl] = useState('')
    const [itemBlock, setItemBlock] = useState('')

    const [isEdit, setIsEdit] = useState(false);
    const [tags, setTags] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [itemListData, setItemListData] = useState([]);
   


    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
        }
    }

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const dataFeedChange = (e) => {
        const { name, value } = e.target;
        setPublisherReport(publisherReport => ({ ...publisherReport, [name]: value }));
    }

    const fileChange = (file) => {
        console.log(file.target.files[0])
        console.log(file.target.files[0].name)
        fileName = file.target.files[0].name
        encodeFileBase64(file.target.files[0])
    };

    useEffect(() => {
        if (props.selectedData !== '') {
            list = []
            brandList = []
            let dfs = []
            console.log(props.selectedData)
            setIsEdit(true)
            //   setPublisherReport(props.selectedData)
            if (props.networkBlockType === "device") {
                if (props.selectedData.deviceControlList.length > 0) {
                    brandList = props.selectedData.deviceControlList.map((item, index) =>
                        Object.assign({}, { text: item, id: item })
                    )
                    console.log(brandList)
                    props.selectedData.deviceControlList.map((item, index) => {
                        list.push(item)
                        dfs.push({ name: item, id: index + 1 })
                        itemList = dfs
                        setItemListData(dfs)
                        return itemList
                    })
                    setTags(brandList)
                }
                setPublisherReport(publisherReport => ({ ...publisherReport, networkBlockList: props.selectedData.deviceControlList, groupName: props.selectedData.deviceControlGroup }));
            }
            if (publisherReport.networkBlockType === "userAgent") {
                if (props.selectedData.userAgentControlList.length > 0) {
                    brandList = props.selectedData.userAgentControlList.map((item, index) =>
                        Object.assign({}, { text: item, id: item })
                    )
                    console.log(brandList)
                    props.selectedData.userAgentControlList.map((item, index) => {
                        list.push(item)
                        dfs.push({ name: item, id: index + 1 })
                        itemList = dfs
                        setItemListData(dfs)
                        return itemList
                    })
                    setTags(brandList)
                }
                setPublisherReport(publisherReport => ({ ...publisherReport, networkBlockList: props.selectedData.userAgentControlList, groupName: props.selectedData.userAgentControlGroup }));
            }
            if (publisherReport.networkBlockType === "domain") {
                if (props.selectedData.domainControlList.length > 0) {
                    brandList = props.selectedData.domainControlList.map((item, index) =>
                        Object.assign({}, { text: item, id: item })
                    )
                    console.log(brandList)
                    props.selectedData.domainControlList.map((item, index) => {
                        list.push(item)
                        dfs.push({ name: item, id: index + 1 })
                        itemList = dfs
                        setItemListData(dfs)
                        return itemList
                    })
                    setTags(brandList)
                }
                setPublisherReport(publisherReport => ({ ...publisherReport, networkBlockList: props.selectedData.domainControlList, groupName: props.selectedData.domainControlGroup }));
            }
            if (publisherReport.networkBlockType === "app") {
                if (props.selectedData.appControlList.length > 0) {
                    brandList = props.selectedData.appControlList.map((item, index) =>
                        Object.assign({}, { text: item, id: item })
                    )
                    console.log(brandList)
                    props.selectedData.appControlList.map((item, index) => {
                        list.push(item)
                        dfs.push({ name: item, id: index + 1 })
                        itemList = dfs
                        setItemListData(dfs)
                        return itemList
                    })
                    setTags(brandList)
                }
                setPublisherReport(publisherReport => ({ ...publisherReport, networkBlockList: props.selectedData.appControlList, groupName: props.selectedData.appControlGroup }));
            }
            if (publisherReport.networkBlockType === "ipv6") {
                if (props.selectedData.iPV6ControlList.length > 0) {
                    brandList = props.selectedData.iPV6ControlList.map((item, index) =>
                        Object.assign({}, { text: item, id: item })
                    )
                    console.log(brandList)
                    props.selectedData.iPV6ControlList.map((item, index) => {
                        list.push(item)
                        dfs.push({ name: item, id: index + 1 })
                        itemList = dfs
                        setItemListData(dfs)
                        return itemList
                    })
                    setTags(brandList)
                }
                setPublisherReport(publisherReport => ({ ...publisherReport, networkBlockList: props.selectedData.iPV6ControlList, groupName: props.selectedData.iPV6ControlGroup }));
            }
            if (publisherReport.networkBlockType === "ipv4") {
                if (props.selectedData.iPV4ControlList.length > 0) {
                    brandList = props.selectedData.iPV4ControlList.map((item, index) =>
                        Object.assign({}, { text: item, id: item })
                    )
                    console.log(brandList)
                    props.selectedData.iPV4ControlList.map((item, index) => {
                        list.push(item)
                        dfs.push({ name: item, id: index + 1 })
                        itemList = dfs
                        setItemListData(dfs)
                        return itemList
                    })
                    setTags(brandList)
                }
                setPublisherReport(publisherReport => ({ ...publisherReport, networkBlockList: props.selectedData.iPV4ControlList, groupName: props.selectedData.iPV4ControlGroup }));
            }
        } else {
            list = []
            brandList = []
        }
    }, []);

    const encodeFileBase64 = (file) => {
        var reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
                var base64 = reader.result.replace("data:image/png;base64,", "");
                console.log(base64)
                if (base64) {
                    setVideoContent({ ...videoContent, videoUrl: base64 })
                }
                console.log(videoContent)
            }
            reader.onerror = (error) => {
                console.log("error: ", error)
            };
        }
    }

    const fileChangepublisherReport = (file) => {
        setDownloadFileBtn(false)
        if (file.target.files[0].name) {
            setLoader(true)
        } else {
            setLoader(false)
        }
        console.log(file.target.files[0])
        const fileName = file.target.files[0].name
        fileNameEntry = file.target.files[0].name
        const fileType = file.target.files[0].type
        console.log("fileName=====================", fileName, file)

        const formData = new FormData();
        let newFile = file.target.files[0];
        formData.append("data", newFile);

        const toBase64 = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);

        });

        var newBaseData = toBase64(newFile);
        const newBaseContent = {};
        (async () => {
            newBaseContent.data = await newBaseData;
            newBaseContent.fileName = fileName;
            newBaseContent.type = fileType;
            newBaseContent.location = 'uploadedDataSettings';
            newBaseContent.userID = userInfo.userID;
            newBaseContent.userRole = userInfo.userRole;
            newBaseContent.companyID = userInfo.companyID;
            console.log(newBaseContent)
            axios.post("/v2.0/reportUpload/upload/uploadFile", newBaseContent, {
                headers: headers
            })
                .then((res) => {
                    console.log("publisherReport Upload ==>", res)
                    setLoader(false)
                    if (res.data.success) {
                        setPublisherReport(publisherReport => ({ ...publisherReport, fileName: fileName }));
                    }
                    else {
                        ref.current.value = "";
                        toastr.error(res.data.message)
                        setInputKey(Math.random().toString(36))
                        fileNameEntry = ''
                    }
                })
                .catch((err) => {
                    setLoader(false)
                    console.log("e", err)
                    setInputKey(Math.random().toString(36))
                    fileNameEntry = ''
                });
        })();
    };

    const handleDelete = (data) => (e) => {
        // setTags(tags.filter((tag, index) => index !== i));
        console.log(list,itemList)
        list.splice(data.id - 1, 1)
        let arr = [...itemList]
        let index = arr.findIndex(x => x.name === data.name)
        console.log(index)
        arr.splice(index, 1)
        arr.map((item, index) => {
            item.id = index + 1
        })
        itemList = arr
        setItemListData(arr)
        console.log(list, arr)
        setPublisherReport(publisherReport => ({ ...publisherReport, networkBlockList: list }));
    };

    const handleAddition = tag => {
        if (tag.code == "Enter") {
            console.log(list,itemListData,itemListData.length)
            let arr2 = [{ name: tag.target.value, id: itemListData.length + 1 }]
            list.push(tag.target.value)
            let arr4 = [...itemListData,...arr2]
            itemList = arr4
            setItemListData(arr4)
            console.log(itemList,arr4)
            setPublisherReport(publisherReport => ({ ...publisherReport, networkBlockList: list }));
            setItemBlock('')
            tag.preventDefault();
            return
        }


        // list.push(tag.id)
        // setTags([...tags, tag]);
        // setPublisherReport(publisherReport => ({ ...publisherReport, networkBlockList: list }));
        // return
    };

    const keyField = "id";

    const roleListColumns = [
        {
            text: "Sl.No",
            dataField: "id",
            sort: true,
        },
        {
            text: "Name",
            dataField: "name",
            sort: true,
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',

            // eslint-disable-next-line react/display-name
            formatter: (cellContent, deviceControl) => (
                <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
                    <Link className="text-danger" to="#" onClick={handleDelete(deviceControl)}>
                        <i
                            className="mdi mdi-delete font-size-18"
                            id="edittooltip"
                        ></i>
                    </Link>
                </div>
            ),
        },
    ];

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );


    const pageOptions = {
        paginationSize: 4,
        pageStartIndex: 1,
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };
    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "asc", // desc or asc
        },
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        console.log(publisherReport)
        if (publisherReport.groupName && (publisherReport.networkBlockList.length > 0 || publisherReport.fileName)) {
            let requestBody = {
                networkBlockType: publisherReport.networkBlockType,
            }
            if (publisherReport.networkBlockType === "device") {
                if (props.selectedData !== '') {
                    requestBody = { ...requestBody, deviceControlID: props.selectedData.deviceControlID }
                }
                if (publisherReport.networkBlockList.length > 0) {
                    requestBody = { ...requestBody, deviceControlGroup: publisherReport.groupName, deviceControlList: publisherReport.networkBlockList, isManual: true }
                }
                if (publisherReport.networkBlockList.length === 0 && publisherReport.fileName) {
                    requestBody = { ...requestBody, deviceControlGroup: publisherReport.groupName, fileName: publisherReport.fileName, isManual: false }
                }
            }
            if (publisherReport.networkBlockType === "userAgent") {
                if (props.selectedData !== '') {
                    requestBody = { ...requestBody, userAgentControlID: props.selectedData.userAgentControlID }
                }
                if (publisherReport.networkBlockList.length > 0) {
                    requestBody = { ...requestBody, userAgentControlGroup: publisherReport.groupName, userAgentControlList: publisherReport.networkBlockList, isManual: true }
                }
                if (publisherReport.networkBlockList.length === 0 && publisherReport.fileName) {
                    requestBody = { ...requestBody, userAgentControlGroup: publisherReport.groupName, fileName: publisherReport.fileName, isManual: false }
                }
            }
            if (publisherReport.networkBlockType === "domain") {
                if (props.selectedData !== '') {
                    requestBody = { ...requestBody, domainControlID: props.selectedData.domainControlID }
                }
                if (publisherReport.networkBlockList.length > 0) {
                    requestBody = { ...requestBody, domainControlGroup: publisherReport.groupName, domainControlList: publisherReport.networkBlockList, isManual: true }
                }
                if (publisherReport.networkBlockList.length === 0 && publisherReport.fileName) {
                    requestBody = { ...requestBody, domainControlGroup: publisherReport.groupName, fileName: publisherReport.fileName, isManual: false }
                }
            }
            if (publisherReport.networkBlockType === "app") {
                if (props.selectedData !== '') {
                    requestBody = { ...requestBody, appControlID: props.selectedData.appControlID }
                }
                if (publisherReport.networkBlockList.length > 0) {
                    requestBody = { ...requestBody, appControlGroup: publisherReport.groupName, appControlList: publisherReport.networkBlockList, isManual: true }
                }
                if (publisherReport.networkBlockList.length === 0 && publisherReport.fileName) {
                    requestBody = { ...requestBody, appControlGroup: publisherReport.groupName, fileName: publisherReport.fileName, isManual: false }
                }
            }
            if (publisherReport.networkBlockType === "ipv6") {
                if (props.selectedData !== '') {
                    requestBody = { ...requestBody, iPV6ControlID: props.selectedData.iPV6ControlID }
                }
                if (publisherReport.networkBlockList.length > 0) {
                    requestBody = { ...requestBody, iPV6ControlGroup: publisherReport.groupName, iPV6ControlList: publisherReport.networkBlockList, isManual: true }
                }
                if (publisherReport.networkBlockList.length === 0 && publisherReport.fileName) {
                    requestBody = { ...requestBody, iPV6ControlGroup: publisherReport.groupName, fileName: publisherReport.fileName, isManual: false }
                }
            }
            if (publisherReport.networkBlockType === "ipv4") {
                if (props.selectedData !== '') {
                    requestBody = { ...requestBody, iPV4ControlID: props.selectedData.iPV4ControlID }
                }
                if (publisherReport.networkBlockList.length > 0) {
                    requestBody = { ...requestBody, iPV4ControlGroup: publisherReport.groupName, iPV4ControlList: publisherReport.networkBlockList, isManual: true }
                }
                if (publisherReport.networkBlockList.length === 0 && publisherReport.fileName) {
                    requestBody = { ...requestBody, iPV4ControlGroup: publisherReport.groupName, fileName: publisherReport.fileName, isManual: false }
                }
            }
            console.log(requestBody)
            debugger
            setLoader(true)
            axios.post(props.selectedData !== '' ? "/v2.0/network/NetworkBlock/update" : "/v2.0/network/NetworkBlock/create", requestBody, {
                headers: headers
            })
                .then((res) => {
                    console.log("publisherReport Upload ==>", res)
                    setLoader(false)
                    if (res.data) {
                        if (res.data.success) {
                            if (props.selectedData !== '') {
                                props.closeCanvas('Update')
                            } else {
                                props.closeCanvas('Create')
                            }
                        } else {
                            toastr.error(res.data.message)
                            if (res.data && res.data.DownloadfileName) {
                                setDownloadFileBtn(true)
                                setDownloadUrl(res.data.DownloadfileName)
                            }
                            setInputKey(Math.random().toString(36))
                            fileNameEntry = ''
                        }
                    } else {
                        toastr.error(res.data)
                        setInputKey(Math.random().toString(36))
                        fileNameEntry = ''
                    }
                })
                .catch((err) => {
                    setLoader(false)
                    setInputKey(Math.random().toString(36))
                    fileNameEntry = ''
                    console.log("e", err)
                    toastr.error('File Upload error')
                });
        }
    }

    const [urlsState, setUrlsState] = useState(false)

    const urlStateChange = e => {
        setUrlsState(e.target.checked)
    }

    return (
        <React.Fragment>
            <div >
                {loader &&
                    <Spinner className="ms-2 loader" color="primary" />
                }
                <Form onSubmit={handleSubmit}>
                    <div className="formBodyScroll" style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
                        <div className="col-md-12">
                            {/* <div className="mb-3 mt-3">
                            <Label>Blocking Type</Label>
                            <Input type="text" className="form-control" name="networkBlockType" value={publisherReport.networkBlockType} onChange={dataFeedChange} readOnly>
                            </Input>
                            <Input type="select" className="form-select" name="networkBlockType" value={publisherReport.networkBlockType} onChange={dataFeedChange}>
                                <option value='device' > Device </option>
                                <option value='userAgent'>User Agent </option>
                                <option value='domain'> Domain  </option>
                                <option value='app' > App </option>
                                <option value='ipv6'> IPV6 </option>
                                <option value='ipv4'> IPV4  </option>
                            </Input>
                        </div> */}
                            <div className="mb-3 mt-3">
                                <Label>Group Name</Label>
                                <Input type="text" className="form-control" name="groupName" value={publisherReport.groupName} onChange={dataFeedChange}>
                                </Input>
                            </div>

                            <div className="player-content player-content2 mb-3">
                                <div className="d-flex">
                                    <label className="form-check-label mb-3 " style={{ marginTop: '0.8pc' }}>
                                        <span color='text-success' style={{ color: urlsState ? '#a3a3a3' : '#34c38f', marginTop: '' }}>Manual Items</span>&nbsp;&nbsp;
                                    </label>
                                    <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >

                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="customSwitchsizemd"
                                            checked={urlsState}
                                            onChange={urlStateChange}
                                        />
                                        <label className="form-check-label">
                                            <span color='text-success' style={{ color: urlsState ? '#34c38f' : '#a3a3a3' }}>Items Upload by File</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="mb-3">
                                <Label>Blocking List</Label>

                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <ToolkitProvider
                                                keyField={keyField}
                                                data={itemListData}
                                                columns={roleListColumns}
                                                // bootstrap4
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col sm="4">
                                                                <div className="search-box ms-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <SearchBar {...toolkitProps.searchProps} />
                                                                        <i className="bx bx-search-alt search-icon" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12" className="table-responsive">
                                                                <div className="">
                                                                    <BootstrapTable
                                                                        keyField={keyField}
                                                                        responsive
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        classes={
                                                                            "table align-middle table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        pagination={paginationFactory(pageOptions)}
                                                                        noDataIndication={<h5 className="text-center">No Data</h5>} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </div>

                            {!urlsState &&
                                <div className="mb-3">
                                    <Label className="form-label">Input Blocking List</Label>
                                    <Input type="text" className="form-control" name="itemBlock" value={itemBlock} onChange={(e) => { setItemBlock(e.target.value) }} onKeyPress={handleAddition} placeholder="Press enter to add new Item">
                                    </Input>
                                    {/* <ReactTags
                                                         tags={tags}
                                                         handleDelete={handleDelete}
                                                         handleAddition={handleAddition}
                                                         inputFieldPosition="inline"
                                                         style={{ width: '100%' }}
                                                         autocomplete
                                                         placeholder="Press enter to add new Item"
                                                     /> */}
                                </div>
                            }

                            {urlsState &&

                                <Card>
                                    <CardTitle className="mt-0 card-header bg-transparent border-bottom">
                                        Network Uploads
                                    </CardTitle>
                                    <CardBody>
                                        <Input className="form-control" type="file" key={inputKey || ''} id="formFile" ref={ref} onChange={fileChangepublisherReport} accept=" application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                        {/* <div className="col-12  ">
                                    <div className="row justify-content-center mt-2 ">
                                        <Button color="primary" className="font-16 btn-block btn btn-primary w-25" onClick={publisherReportUpload} disabled={fileNameEntry === ''}>Upload</Button> &nbsp;&nbsp;
                                        {downloadFileBtn &&
                                            <Button color="primary" className="font-16 btn-block btn btn-primary w-25" onClick={() => downloadCSV(downloadUrl, 'Error File-' + currentTimeStamp + '.csv')}>Download Error File</Button>
                                        }
                                    </div>
                                </div> */}
                                        <hr className="hr" />
                                        <span className=" mt-3 mb-1 row justify-content-center">
                                            Upload Data in Excel format (.xlsx file)
                                        </span>
                                        <Row className="justify-content-center text-center">
                                            <Link to={publisherReport.networkBlockType === 'device' ? DeviceBlock : publisherReport.networkBlockType === 'userAgent' ? UserAgentBlock : publisherReport.networkBlockType === 'domain' ?
                                                DomainBlock : publisherReport.networkBlockType === 'app' ? AppBlock : publisherReport.networkBlockType === 'iPV6Block' ? IPV6Block : IPV4Block} target="_blank" className=" color-dark-blue"
                                                download={publisherReport.networkBlockType === 'device' ? 'NetworkBlocking_Device' : publisherReport.networkBlockType === 'userAgent' ? 'NetworkBlocking_UserAgent' : publisherReport.networkBlockType === 'domain' ?
                                                    'NetworkBlocking_Domian' : publisherReport.networkBlockType === 'app' ? 'NetworkBlocking_App' : publisherReport.networkBlockType === 'ipv6' ? 'NetworkBlocking_IPV6' : 'NetworkBlocking_IPV4' + ".xlsx"} style={{ width: 'fit-content' }}>
                                                Download Template for Data Upload
                                            </Link>
                                        </Row>
                                    </CardBody>
                                </Card>
                            }
                        </div>
                    </div>

                    <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
                        <Col>
                            <div className="text-start">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    // eslint-disable-next-line react/prop-types
                                    onClick={props.closeCanvas}
                                >
                                    Back
                                </button>
                                &nbsp;&nbsp;
                                {!isEdit &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Save
                                    </button>
                                }
                                &nbsp;&nbsp;
                                {isEdit &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Update
                                    </button>
                                }
                            </div>
                        </Col>
                    </Row>
                </Form>

            </div>

        </React.Fragment >
    )
}

UploadBlockings.propTypes = {}

export default UploadBlockings