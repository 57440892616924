/* eslint-disable react/prop-types */
import React, { useEffect,useState} from "react";
import { Button } from "reactstrap";
import { Row, Col, Input, Label, Form, FormFeedback,  Spinner,
  Toast,
  ToastHeader,
  ToastBody, } from "reactstrap";

//Notifications 
import axios from "axios";

 const ToasterWarning = (props) => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
        const [toast, setToast] = useState(true);

        const headers = {
          "Content-Type": "application/json",
          "Accept": 'application/json',
          "Authorization": `Bearer ${userInfo.token}`
        }
      
        const toggleToast = () => {
          setToast(false);
          let body = {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            path:props.msg.path,
            message:props.msg.message
          }
          console.log(body,props.msg)
          axios.post("/v1.0/sendMailforError", body,
            {
              headers: headers
            })
            .then((res) => {
              console.log("result is ==>", res)
              props.closeWarning('')
            })
            .catch((err) => {
              console.log("e", err)
            });
        };
    
      return (
        <Toast isOpen={toast} className=" mb-3" style={{ position: 'absolute',zIndex:'99999', backgroundColor: '#F1B44C', width: 'auto',top:'2%' }}>
        <ToastBody className="p-0 justify-content-center d-flex"  >
          <div className="row">
            <div className="col-1">
                <div className="" style={{padding:'15px 0 0 15px'}}>
                  <i className="dripicons-warning" style={{fontSize:'2.5em',color:"white"}}></i>
                </div>
            </div>
            <div className="col-9">
              <div className="notification" style={{ padding: '0.7pc 2pc' }}>
                  <span>Oops!!, something went wrong, Please try again later.</span> &nbsp;&nbsp;
                  <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={toggleToast}
                >
                  Retry
                </button>
              </div>
            </div>
            <div className="col-2">
              <div className="text-sm-end">
                <button
                  type="button"
                  onClick={toggleToast}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  style={{ position: 'relative', opacity: '1' }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
        </ToastBody>
      </Toast>
      )
}

export default ToasterWarning