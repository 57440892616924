import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"

//Include Both Helper File with needed methods
import {
  postFakeRegister,
  postJwtRegister,
  postRegister
} from "../../../helpers/Backend_helper"

// initialize relavant method of both Auth
// const fireBaseBackend = getFirebaseBackend()

// Is user register successfull then direct plot user in redux.
// function* registerUser({ payload: { user } }) {
//   try {
//     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//       const response = yield call(
//         fireBaseBackend.registerUser,
//         user.email,
//         user.password
//       )
//       yield put(registerUserSuccessful(response))
//     } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
//       const response = yield call(postJwtRegister, "/post-jwt-register", user)
//       yield put(registerUserSuccessful(response))
//     } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
//       const response = yield call(postFakeRegister, user)
//       yield put(registerUserSuccessful(response))
//     }
//   } catch (error) {
//     yield put(registerUserFailed(error))
//   }
// }

function* registerUser({ payload: { user } }) {
  try {

        const response = yield call(postRegister, {
               fullName: user.fullName,
               email: user.email,
               country: user.country,
               companyName: user.companyName,
               companyWebsite: user.companyWebsite,
               businessType: user.businessType
            });
            console.log(response)
      if(response.token){
        yield put(registerUserSuccessful(response))
      }else{
        yield put(registerUserFailed(response.authorization));
      }
  } catch (error) {
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
