/* eslint-disable react/prop-types */
import React, { useState, useRef,useEffect } from 'react';
import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import _ from "lodash";
import { Input, Dropdown } from "reactstrap";

class Widget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nodes: [
        {
          label: "Africa",
          value: "Africa",
          children: [
            {
              label: "Algeria",
              value: "DZ",
            },
            {
              label: "Angola",
              value: "AO",
            },
            {
              label: "Benin",
              value: "BJ",
            },
            {
              label: "Botswana",
              value: "BW",
            },
            {
              label: "Burkina Faso",
              value: "BF",
            },
            {
              label: "Burundi",
              value: "BI",
            },
            {
              label: "Cameroon",
              value: "CM",
            },
            {
              label: "Cape Verde",
              value: "CV",
            },
            {
              label: "Central African Republic",
              value: "CF",
            },
            {
              label: "Chad",
              value: "TD",
            },
            {
              label: "Comoros",
              value: "KM",
            },
            {
              label: "Congo - Brazzaville",
              value: "CG",
            },
            {
              label: "Congo - Kinshasa",
              value: "CD",
            },
            {
              label: "Côte d’Ivoire",
              value: "CI",
            },
            {
              label: "Djibouti",
              value: "DJ",
            },
            {
              label: "Egypt",
              value: "EG",
            },
            {
              label: "Equatorial Guinea",
              value: "GQ",
            },
            {
              label: "Eritrea",
              value: "ER",
            },
            {
              label: "Ethiopia",
              value: "ET",
            },
            {
              label: "Gabon",
              value: "GA",
            },
            {
              label: "Gambia",
              value: "GM",
            },
            {
              label: "Ghana",
              value: "GH",
            },
            {
              label: "Guinea",
              value: "GN",
            },
            {
              label: "Guinea-Bissau",
              value: "GW",
            },
            {
              label: "Kenya",
              value: "KE",
            },
            {
              label: "Lesotho",
              value: "LS",
            },
            {
              label: "Liberia",
              value: "LR",
            },
            {
              label: "Libya",
              value: "LY",
            },
            {
              label: "Madagascar",
              value: "MG",
            },
            {
              label: "Malawi",
              value: "MW",
            },
            {
              label: "Mali",
              value: "ML",
            },
            {
              label: "Mauritania",
              value: "MR",
            },
            {
              label: "Mauritius",
              value: "MU",
            },
            {
              label: "Mayotte",
              value: "YT",
            },
            {
              label: "Morocco",
              value: "MA",
            },
            {
              label: "Mozambique",
              value: "MZ",
            },
            {
              label: "Namibia",
              value: "NA",
            },
            {
              label: "Niger",
              value: "NE",
            },
            {
              label: "Nigeria",
              value: "NG",
            },
            {
              label: "Rwanda",
              value: "RW",
            },
            {
              label: "Réunion",
              value: "RE",
            },
            {
              label: "Saint Helena",
              value: "SH",
            },
            {
              label: "Senegal",
              value: "SN",
            },
            {
              label: "Seychelles",
              value: "SC",
            },
            {
              label: "Sierra Leone",
              value: "SL",
            },
            {
              label: "Somalia",
              value: "SO",
            },
            {
              label: "South Africa",
              value: "ZA",
            },
            {
              label: "Sudan",
              value: "SD",
            },
            {
              label: "Swaziland",
              value: "SZ",
            },
            {
              label: "São Tomé and Príncipe",
              value: "ST",
            },
            {
              label: "Tanzania",
              value: "TZ",
            },
            {
              label: "Togo",
              value: "TG",
            },
            {
              label: "Tunisia",
              value: "TN",
            },
            {
              label: "Uganda",
              value: "UG",
            },
            {
              label: "Western Sahara",
              value: "EH",
            },
            {
              label: "Zambia",
              value: "ZM",
            },
            {
              label: "Zimbabwe",
              value: "ZW",
            },
          ],
        },
        {
          label: "America",
          value: "America",
          children: [
            {
              label: "Anguilla",
              value: "AI",
            },
            {
              label: "Antigua and Barbuda",
              value: "AG",
            },
            {
              label: "Argentina",
              value: "AR",
            },
            {
              label: "Aruba",
              value: "AW",
            },
            {
              label: "Bahamas",
              value: "BS",
            },
            {
              label: "Barbados",
              value: "BB",
            },
            {
              label: "Belize",
              value: "BZ",
            },
            {
              label: "Bermuda",
              value: "BM",
            },
            {
              label: "Bolivia",
              value: "BO",
            },
            {
              label: "Brazil",
              value: "BR",
            },
            {
              label: "British Virgin Islands",
              value: "VG",
            },
            {
              label: "Canada",
              value: "CA",
            },
            {
              label: "Cayman Islands",
              value: "KY",
            },
            {
              label: "Chile",
              value: "CL",
            },
            {
              label: "Colombia",
              value: "CO",
            },
            {
              label: "Costa Rica",
              value: "CR",
            },
            {
              label: "Cuba",
              value: "CU",
            },
            {
              label: "Dominica",
              value: "DM",
            },
            {
              label: "Dominican Republic",
              value: "DO",
            },
            {
              label: "Ecuador",
              value: "EC",
            },
            {
              label: "El Salvador",
              value: "SV",
            },
            {
              label: "Falkland Islands",
              value: "FK",
            },
            {
              label: "French Guiana",
              value: "GF",
            },
            {
              label: "Greenland",
              value: "GL",
            },
            {
              label: "Grenada",
              value: "GD",
            },
            {
              label: "Guadeloupe",
              value: "GP",
            },
            {
              label: "Guatemala",
              value: "GT",
            },
            {
              label: "Guyana",
              value: "GY",
            },
            {
              label: "Haiti",
              value: "HT",
            },
            {
              label: "Honduras",
              value: "HN",
            },
            {
              label: "Jamaica",
              value: "JM",
            },
            {
              label: "Martinique",
              value: "MQ",
            },
            {
              label: "Mexico",
              value: "MX",
            },
            {
              label: "Montserrat",
              value: "MS",
            },
            {
              label: "Netherlands Antilles",
              value: "AN",
            },
            {
              label: "Nicaragua",
              value: "NI",
            },
            {
              label: "Panama",
              value: "PA",
            },
            {
              label: "Paraguay",
              value: "PY",
            },
            {
              label: "Peru",
              value: "PE",
            },
            {
              label: "Puerto Rico",
              value: "PR",
            },
            {
              label: "Saint Barthélemy",
              value: "BL",
            },
            {
              label: "Saint Kitts and Nevis",
              value: "KN",
            },
            {
              label: "Saint Lucia",
              value: "LC",
            },
            {
              label: "Saint Martin",
              value: "MF",
            },
            {
              label: "Saint Pierre and Miquelon",
              value: "PM",
            },
            {
              label: "Saint Vincent and the Grenadines",
              value: "VC",
            },
            {
              label: "Suriname",
              value: "SR",
            },
            {
              label: "Trinidad and Tobago",
              value: "TT",
            },
            {
              label: "Turks and Caicos Islands",
              value: "TC",
            },
            {
              label: "U.S. Virgin Islands",
              value: "VI",
            },
            {
              label: "United States",
              value: "US",
            },
            {
              label: "Uruguay",
              value: "UY",
            },
            {
              label: "Venezuela",
              value: "VE",
            },
          ],
        },
        {
          label: "Asia",
          value: "Asia",
          children: [
            {
              label: "Afghanistan",
              value: "AF",
            },
            {
              label: "Armenia",
              value: "AM",
            },
            {
              label: "Azerbaijan",
              value: "AZ",
            },
            {
              label: "Bahrain",
              value: "BH",
            },
            {
              label: "Bangladesh",
              value: "BD",
            },
            {
              label: "Bhutan",
              value: "BT",
            },
            {
              label: "Brunei",
              value: "BN",
            },
            {
              label: "Cambodia",
              value: "KH",
            },
            {
              label: "China",
              value: "CN",
            },
            {
              label: "Georgia",
              value: "GE",
            },
            {
              label: "Hong Kong SAR China",
              value: "HK",
            },
            {
              label: "India",
              value: "IN",
            },
            {
              label: "Indonesia",
              value: "ID",
            },
            {
              label: "Iran",
              value: "IR",
            },
            {
              label: "Iraq",
              value: "IQ",
            },
            {
              label: "Israel",
              value: "IL",
            },
            {
              label: "Japan",
              value: "JP",
            },
            {
              label: "Jordan",
              value: "JO",
            },
            {
              label: "Kazakhstan",
              value: "KZ",
            },
            {
              label: "Kuwait",
              value: "KW",
            },
            {
              label: "Kyrgyzstan",
              value: "KG",
            },
            {
              label: "Laos",
              value: "LA",
            },
            {
              label: "Lebanon",
              value: "LB",
            },
            {
              label: "Macau SAR China",
              value: "MO",
            },
            {
              label: "Malaysia",
              value: "MY",
            },
            {
              label: "Maldives",
              value: "MV",
            },
            {
              label: "Mongolia",
              value: "MN",
            },
            {
              label: "Myanmar [Burma]",
              value: "MM",
            },
            {
              label: "Nepal",
              value: "NP",
            },
            {
              label: "Neutral Zone",
              value: "NT",
            },
            {
              label: "North Korea",
              value: "KP",
            },
            {
              label: "Oman",
              value: "OM",
            },
            {
              label: "Pakistan",
              value: "PK",
            },
            {
              label: "Palestinian Territories",
              value: "PS",
            },
            {
              label: "People`s Democratic Republic of Yemen",
              value: "YD",
            },
            {
              label: "Philippines",
              value: "PH",
            },
            {
              label: "Qatar",
              value: "QA",
            },
            {
              label: "Saudi Arabia",
              value: "SA",
            },
            {
              label: "Singapore",
              value: "SG",
            },
            {
              label: "South Korea",
              value: "KR",
            },
            {
              label: "Sri Lanka",
              value: "LK",
            },
            {
              label: "Syria",
              value: "SY",
            },
            {
              label: "Taiwan",
              value: "TW",
            },
            {
              label: "Tajikistan",
              value: "TJ",
            },
            {
              label: "Thailand",
              value: "TH",
            },
            {
              label: "Timor-Leste",
              value: "TL",
            },
            {
              label: "Turkey",
              value: "TR",
            },
            {
              label: "Turkmenistan",
              value: "™",
            },
            {
              label: "United Arab Emirates",
              value: "AE",
            },
            {
              label: "Uzbekistan",
              value: "UZ",
            },
            {
              label: "Vietnam",
              value: "VN",
            },
            {
              label: "Yemen",
              value: "YE",
            },
          ],
        },
        {
          label: "Europe",
          value: "Europe",
          children: [
            {
              label: "Albania",
              value: "AL",
            },
            {
              label: "Andorra",
              value: "AD",
            },
            {
              label: "Belarus",
              value: "BY",
            },
            {
              label: "BosniaandHerzegovina",
              value: "BA",
            },
            {
              label: "EastGermany",
              value: "DD",
            },
            {
              label: "FaroeIslands",
              value: "FO",
            },
            {
              label: "Gibraltar",
              value: "GI",
            },
            {
              label: "Guernsey",
              value: "GG",
            },
            {
              label: "Iceland",
              value: "IS",
            },
            {
              label: "IsleofMan",
              value: "IM",
            },
            {
              label: "Jersey",
              value: "JE",
            },
            {
              label: "Liechtenstein",
              value: "LI",
            },
            {
              label: "Macedonia",
              value: "MK",
            },
            {
              label: "MetropolitanFrance",
              value: "FX",
            },
            {
              label: "Moldova",
              value: "MD",
            },
            {
              label: "Monaco",
              value: "MC",
            },
            {
              label: "Montenegro",
              value: "ME",
            },
            {
              label: "Norway",
              value: "NO",
            },
            {
              label: "Russia",
              value: "RU",
            },
            {
              label: "SanMarino",
              value: "SM",
            },
            {
              label: "Serbia",
              value: "RS",
            },
            {
              label: "SerbiaandMontenegro",
              value: "CS",
            },
            {
              label: "SvalbardandJanMayen",
              value: "SJ",
            },
            {
              label: "Switzerland",
              value: "CH",
            },
            {
              label: "Ukraine",
              value: "UA",
            },
            {
              label: "UnionofSovietSocialistRepublics",
              value: "SU",
            },
            {
              label: "VaticanCity",
              value: "VA",
            },
            {
              label: "ÅlandIslands",
              value: "AX",
            },
          ],
        },
        {
          label: "GDPR",
          value: "GDPR",
          children: [
            {
              label: "Austria",
              value: "AT",
            },
            {
              label: "Belgium",
              value: "BE",
            },
            {
              label: "Bulgaria",
              value: "BG",
            },
            {
              label: "Croatia",
              value: "HR",
            },
            {
              label: "Cyprus",
              value: "CY",
            },
            {
              label: "Czech Republic",
              value: "CZ",
            },
            {
              label: "Denmark",
              value: "DK",
            },
            {
              label: "Estonia",
              value: "EE",
            },
            {
              label: "Finland",
              value: "FI",
            },
            {
              label: "France",
              value: "FR",
            },
            {
              label: "Germany",
              value: "DE",
            },
            {
              label: "Greece",
              value: "GR",
            },
            {
              label: "Hungary",
              value: "HU",
            },
            {
              label: "Ireland",
              value: "IE",
            },
            {
              label: "Italy",
              value: "IT",
            },
            {
              label: "Latvia",
              value: "LV",
            },
            {
              label: "Lithuania",
              value: "LT",
            },
            {
              label: "Luxembourg",
              value: "LU",
            },
            {
              label: "Malta",
              value: "MT",
            },
            {
              label: "Netherlands",
              value: "NL",
            },
            {
              label: "Poland",
              value: "PL",
            },
            {
              label: "Portugal",
              value: "PT",
            },
            {
              label: "Romania",
              value: "RO",
            },
            {
              label: "Slovakia",
              value: "SK",
            },
            {
              label: "Slovenia",
              value: "SI",
            },
            {
              label: "Spain",
              value: "ES",
            },
            {
              label: "Sweden",
              value: "SE",
            },
            {
              label: "United Kingdom",
              value: "GB",
            },
          ],
        },
        {
          label: "Oceania",
          value: "Oceania",
          children: [
            {
              label: "American Samoa",
              value: "AS",
            },
            {
              label: "Antarctica",
              value: "AQ",
            },
            {
              label: "Australia",
              value: "AU",
            },
            {
              label: "Bouvet Island",
              value: "BV",
            },
            {
              label: "British Indian Ocean Territory",
              value: "IO",
            },
            {
              label: "Christmas Island",
              value: "CX",
            },
            {
              label: "Cocos [Keeling] Islands",
              value: "CC",
            },
            {
              label: "Cook Islands",
              value: "CK",
            },
            {
              label: "Fiji",
              value: "FJ",
            },
            {
              label: "French Polynesia",
              value: "PF",
            },
            {
              label: "French Southern Territories",
              value: "TF",
            },
            {
              label: "Guam",
              value: "GU",
            },
            {
              label: "Heard Island and McDonald Islands",
              value: "HM",
            },
            {
              label: "Kiribati",
              value: "KI",
            },
            {
              label: "Marshall Islands",
              value: "MH",
            },
            {
              label: "Micronesia",
              value: "FM",
            },
            {
              label: "Nauru",
              value: "NR",
            },
            {
              label: "New Caledonia",
              value: "NC",
            },
            {
              label: "New Zealand",
              value: "NZ",
            },
            {
              label: "Niue",
              value: "NU",
            },
            {
              label: "Norfolk Island",
              value: "NF",
            },
            {
              label: "Northern Mariana Islands",
              value: "MP",
            },
            {
              label: "Palau",
              value: "PW",
            },
            {
              label: "Papua New Guinea",
              value: "PG",
            },
            {
              label: "Pitcairn Islands",
              value: "PN",
            },
            {
              label: "Samoa",
              value: "WS",
            },
            {
              label: "Solomon Islands",
              value: "SB",
            },
            {
              label: "South Georgia and the South Sandwich Islands",
              value: "GS",
            },
            {
              label: "Tokelau",
              value: "TK",
            },
            {
              label: "Tonga",
              value: "TO",
            },
            {
              label: "Tuvalu",
              value: "TV",
            },
            {
              label: "U.S. Minor Outlying Islands",
              value: "UM",
            },
            {
              label: "Vanuatu",
              value: "VU",
            },
            {
              label: "Wallis and Futuna",
              value: "WF",
            },
          ],
        },
      ],
      checked: [],
      expanded: [],
      isDropdownExpanded: false,
      keyword: ""
    };
  }

  onCheck = checked => {
    this.setState({ checked }, () => {
      console.log(this.state.checked);
    });
  };

  onExpand = expanded => {
    this.setState({ expanded }, () => {
      console.log(this.state.expanded);
    });
  };

  renderTree = () => {
    this.setState(
      prevState => {
        return {
          ...prevState,
          isDropdownExpanded: !prevState.isDropdownExpanded
        };
      },
      () => {
        console.log(this.state);
      }
    );
  };

  onSearchInputChange = (event, data, searchedNodes) => {
    this.setState(prevState => {
      if (prevState.keyword.trim() && !data.value.trim()) {
        return {
          expanded: [],
          keyword: data.value
        };
      }
      return {
        expanded: this.getAllValuesFromNodes(searchedNodes, true),
        keyword: data.value
      };
    });
  };

  getAllValuesFromNodes = (nodes, firstLevel) => {
    if (firstLevel) {
      const values = [];
      for (let n of nodes) {
        values.push(n.value);
        if (n.children) {
          values.push(...this.getAllValuesFromNodes(n.children, false));
        }
      }
      return values;
    } else {
      const values = [];
      for (let n of nodes) {
        values.push(n.value);
        if (n.children) {
          values.push(...this.getAllValuesFromNodes(n.children, false));
        }
      }
      return values;
    }
  };

  keywordFilter = (nodes, keyword) => {
    let newNodes = [];
    for (let n of nodes) {
      if (n.children) {
        const nextNodes = this.keywordFilter(n.children, keyword);
        if (nextNodes.length > 0) {
          n.children = nextNodes;
        } else if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
          n.children = nextNodes.length > 0 ? nextNodes : [];
        }
        if (
          nextNodes.length > 0 ||
          n.label.toLowerCase().includes(keyword.toLowerCase())
        ) {
          n.label = this.getHighlightText(n.label, keyword);
          newNodes.push(n);
        }
      } else {
        if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
          n.label = this.getHighlightText(n.label, keyword);
          newNodes.push(n);
        }
      }
    }
    return newNodes;
  };

  getHighlightText = (text, keyword) => {
    const startIndex = text.indexOf(keyword);
    return startIndex !== -1 ? (
      <span>
        {text.substring(0, startIndex)}
        <span style={{ color: "red" }}>
          {text.substring(startIndex, startIndex + keyword.length)}
        </span>
        {text.substring(startIndex + keyword.length)}
      </span>
    ) : (
      <span>{text}</span>
    );
  };

  render() {
    const { checked, expanded, nodes, isDropdownExpanded } = this.state;
    let searchedNodes = this.state.keyword.trim()
      ? this.keywordFilter(_.cloneDeep(nodes), this.state.keyword)
      : nodes;
    return (
      <div>
        <Dropdown
          text="Select"
          fluid
          selection
          options={[]}
          onClick={this.renderTree}
        />
        {isDropdownExpanded && (
          <div>
            <Input
              style={{ marginBottom: "20px" }}
              fluid
              icon="search"
              placeholder="Search"
              iconPosition="left"
              onChange={(event, data) => {
                this.onSearchInputChange(event, data, searchedNodes);
              }}
            />
            <CheckboxTree
              nodes={searchedNodes}
              checked={checked}
              expanded={expanded}
              onCheck={this.onCheck}
              onExpand={this.onExpand}
              showNodeIcon={true}
            />
          </div>
        )}
      </div>
    );
  }
}

export default Widget;
