import PropTypes from "prop-types";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import React, { useState, useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container,Button, Form, Input, FormFeedback, Label } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link, Redirect } from "react-router-dom";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
// actions
import { loginUsers, apiError } from "../../store/actions";
import CarouselPage from "pages/Authentication/CarouselPage";

const Login = props => {
  const dispatch = useDispatch();
  const [isFollowed, setIsFollowed] = React.useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  useEffect(() => {
    localStorage.removeItem('authUser');
    localStorage.removeItem('UserRole');
    localStorage.removeItem('ParentRole');
    localStorage.removeItem('Terms');
    localStorage.removeItem('NotificationStatus');
    localStorage.removeItem('UserRolePrivileges');
    localStorage.removeItem('AdminRolePrivileges');
    localStorage.removeItem('previousNavigation');
    localStorage.removeItem('UserSwitching');
    localStorage.removeItem('AdminDetails');
    localStorage.removeItem('activeTab');
    localStorage.removeItem('InventoryType');
    localStorage.removeItem('userSessionState');
    localStorage.removeItem('darkMode');
    localStorage.setItem("loginStatus", 'LoggedOut');
    localStorage.setItem("alertStatus", 'closed');
    sessionStorage.setItem("DemandServiceDown", JSON.stringify(false));
    sessionStorage.setItem("SupplyServiceDown", JSON.stringify(false));
    sessionStorage.setItem("BXServiceDown", JSON.stringify(false));
    dispatch(apiError(null));
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUsers(values, props.history, 'login'));
    }
  });

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

  const [visible, setVisible] = useState(true);


  const onDismiss = () =>{
    dispatch(apiError(null));
  } ;

  return (
    <React.Fragment>
      <Helmet>
        <title>Login | Bidsxchange</title>
      </Helmet>
      <Container fluid className="p-0">
        <Row className="g-0">
          <CarouselPage />
          {/* <Redirect to="/page-maintenance" /> */}
          <Col md={6} style={{ backgroundColor: 'white' }}>
            <div className="auth-full-page-content p-md-5 p-4" style={{fontSize:'12px'}}>
              <div className="w-40" style={{ margin: "auto",width:'345px' }}>
                <div className="d-flex flex-column h-100">
                  <div className="my-auto">
                    <div>
                      <h3 className="text-primary">Log in to your Account</h3>
                      {/* <p className="text-muted ">
                        Sign in and start managing your AdServer!
                      </p> */}
                    </div>
                    <div className="mt-4">
                      <Form className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {error ? <Alert color="danger"   toggle={onDismiss}>{error}</Alert> : null}
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="username"
                            className="form-control"
                            placeholder="Enter Email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.username || ""}
                            invalid={
                              validation.touched.username && validation.errors.username ? true : false
                            }
                          
                          />
                          {validation.touched.username && validation.errors.username ? (
                            <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-1">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type={passwordShown ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                        
                          />
                          {!(validation.touched.password && validation.errors.password) &&
                            <span className="passwordIcon" >
                              <   i onClick={togglePasswordVisiblity} id="passwordToggle" className={passwordShown ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
                            </span>
                          }
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                        </div>
                        <div className=" text-left">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock me-1" />
                            Forgot password?
                          </Link>
                        </div>
                        
                        <div className="mt-3 d-flex flex-row justify-content-end">
                          <Button
                            className="w-100 btn btn-primary btn-block login-btn "
                            type="submit"
                            style={{fontSize:'14px'}}
                          >
                            Log In
                          </Button>
                        </div>
                        <div className="mt-2 text-center">
                          <ul className="list-inline">
                          </ul>
                        </div>
                      </Form>
                      <div className="mt-2 text-center">
                        <p>
                          Don&apos;t have an account ?
                          <Link
                            to="register"
                            className="fw-medium text-primary"
                          >&nbsp;
                            Signup 
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </Col>
          <footer className="footer" style={{ textAlign: 'end',fontSize:'12px' }}>
            <Container fluid={true}>
              <Row>
                <Col md={4}>    <div className="text-sm-start d-flex"> 2015 - {new Date().getFullYear()} © Bidsxchange. All rights reserved.</div></Col>
                <Col md={4}>
                  <div className="text-center">
                    <h5 style={{ letterSpacing: '6px',fontSize:'0.9rem' }}>BIDSXCHANGE</h5>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="text-sm-end d-flex">
                    <Col >
                      <a href="https://streamlyn.com/cookie-policy/" rel="noreferrer" className="under-line-hyper-link" target="_blank">Cookie Policy</a>
                      &nbsp;|&nbsp;
                      <a href="https://streamlyn.com/privacy-policy/" rel="noreferrer" className="under-line-hyper-link" target="_blank">Privacy Policy</a>
                    </Col>
                  </div>
                </Col>
              </Row>
            </Container>
          </footer>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
