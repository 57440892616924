/* eslint-disable react/prop-types */
import React, { useEffect, useState,useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Spinner } from "reactstrap";
import PropTypes from "prop-types";
import axios from "axios";
import { withRouter, Link } from "react-router-dom";

//redux
import { useSelector, useDispatch } from "react-redux";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// action
import { userForgetPassword, userForgetPasswordSuccess, userForgetPasswordError } from "store/actions";

var loading = Boolean(false)

const Basic_Info = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    // const [partner, setPartner] = useState({
    //   password: ''
    // });
    const [isEdit, setIsEdit] = useState(false);
    const [regionList, setRegionList] = useState();
    const [selectedRegion, setSelectedRegion] = useState();
    const [selectedCountry, setSelectedCountry] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [form10F, setForm10F] = useState('')
    const [NPE, setNPE] = useState('')
    const [TRC, setTRC] = useState('')
    const [loader, setLoader] = useState(false);
    const [forcePassword, setForcePassword] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(userForgetPasswordSuccess(""));
      dispatch(userForgetPasswordError(""));
    }, []);

    toastr.options = {
      positionClass: "toast-top-center",
      closeButton: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
      showDuration: 300,
      hideDuration: 1000,
      timeOut: 3000,
      extendedTimeOut: 1000
    };
  
    const headers = {
      "Content-Type": "application/json",
      "Accept": 'application/json',
      "Authorization": `Bearer ${userInfo.token}`
    }
  
    const body = {
      "url": '',
      "requestBody": {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
      }
    }

    const [partner, setPartner] = useState({
        userName: '',
        userRole: 'Partner',
        userEmail: '',
        confirmEmail: '',
        userlog: {
          userID: userInfo.userID,
          userRole: userInfo.userRole,
          companyID: userInfo.companyID,
        },
        companyID: userInfo.companyID,
  
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setPartner(partner => ({ ...partner, [newKey]: value }));
      }

      const handleSubmit = (e) => {
        loading = true;
        dispatch(userForgetPassword({email:userInfo.username}, props.history));
      }

      const { forgetError, forgetSuccessMsg } = useSelector(state => ({
        forgetError: state.ForgetPassword.forgetError,
        forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
     
      }));
    
      if(forgetSuccessMsg){
        loading = false;
        toastr.success(forgetSuccessMsg)
        dispatch(userForgetPasswordSuccess(""));
        dispatch(userForgetPasswordError(""));
      }
    
      if(forgetError){
        loading = false;
        toastr.error(forgetError)
        dispatch(userForgetPasswordSuccess(""));
        dispatch(userForgetPasswordError(""));
      }


    return (
        <div>
            <Col xl={6} className="mb-4">
                <div className="mb-3">
                    <Label className="form-label"> Name</Label>
                    <Input
                        name="userName"
                        onChange={handleChange}
                        value={userInfo.name}
                        readOnly
                    />
                </div>
                <div className="mb-3">
                    <Label className="form-label"> Email </Label>
                    <Input
                        name="userEmail"
                        type="email"
                        onChange={handleChange}
                        value={userInfo.username}
                        readOnly
                    />
                </div>
      
                    <div className="mb-3">
             
                        <div className="row">
                            <div className="col-12">
                            <Label className="form-label mt-2">Reset Password </Label>
                            </div>
                            <div className="col-12">
                                <Link
                                    type="button" to="#"
                                    className="btn btn-outline-primary "
                                    // eslint-disable-next-line react/prop-types
                                    onClick={handleSubmit}
                                >
                                     <i   className={loading ? "bx bx-hourglass bx-spin font-size-16 align-middle me-2 ml-2 mr-2" : '' } ></i> 
                                    Reset Password
                                </Link>
                            </div>
                        </div>
                    </div>
            </Col>
        </div>

    )
}

Basic_Info.propTypes = {}

export default Basic_Info