/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from "axios";
import {
  Row, Col, Input, Label, Form, FormFeedback,
  FormGroup,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Container, InputGroup
} from "reactstrap";

const PlayerGesture = (props) => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [gesture, setGesture] = useState({
    isResponsive: true,
    playerHeight: 0,
    playerWidth: 0,
    showPlaylistGallery: false,
    playerBackgroundColor: '',
    background: '',
    playerMode: 'inRead',
    playerCloseFloatingButton: false,
    playerFloatOnBottom: false,
    playerFloatView: false,
    playerResizingMethod: false,
    playerPlayPauseButton: false,
    playerfloatingSize: 50,
    position: 'bottomRight',
    bottomMargin: '',
    rightMargin: '',
    contentIdentifier: "",
    contentHelpers: " ",
    contentNumbers: 1,
    floatingOnStart: true,
    floatingCloseButton: true,
    floatingPlacement: 'right',
    floatingPercentage: 50,
    stickyPosition: "bottom",
    stickyPlacement: "right",
    playerAlignment: "center",
    customCss: ''
  });

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const body = {
    "url": '',
    "requestBody": {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
    }
  }

  useEffect( () => {
    console.log(props.selectedPlayer)
    if (props.selectedPlayer !== '') {
      if (props.selectedPlayer.playerSettingsGestures && props.selectedPlayer.playerSettingsGestures.length > 0) {
        setGesture({ ...gesture, ...props.selectedPlayer.playerSettingsGestures[0] })
        props.selectedItems({ ...gesture, ...props.selectedPlayer.playerSettingsGestures[0] })
      }
    }
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGesture(gesture => ({ ...gesture, [name]: value }));
    props.selectedItems({ ...gesture, [name]: value })
  }

  const handleCheck = (event) => {
    setGesture({
      ...gesture,
      [event.target.name]: event.target.checked,
    });
    props.selectedItems({ ...gesture, [event.target.name]: event.target.checked })
  };

  return (
    <div className=''>
      {/* <PlayerSettings /> */}
      <div className='player-content'>
        <div className="container-fluid mt-3">
          <h4 className="heading3">Instream Player</h4>
          <span className=" mt-2  heading5 ml-1">Size</span>
          <div className="form-group row  mt-3 ml-3">
            <div className="mb-1">
              <div className="form-check form-switch form-check-success form-switch-md mt-2  col-4" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="inResposive"
                  checked={gesture.isResponsive} onChange={handleCheck} name="isResponsive"
                />
                <label className="form-check-label " htmlFor="inResposive">
                  <h5 className="heading5 mt-1">Responsive</h5>
                </label>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="form-group ">
                <Label className="form-label">
                  Max Height
                </Label>
                <Input
                  type="text"
                  label="Height"
                  value={gesture.playerHeight}
                  onChange={handleChange}
                  name="playerHeight"
                />
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-5">
              <div className="form-group ">
                <Label className="form-label">
                  Min Width
                </Label>
                <Input
                  type="text"
                  label="Width"
                  value={gesture.playerWidth}
                  onChange={handleChange}
                  name="playerWidth"
                />
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
              <input
                type="checkbox"
                className="form-check-input"
                id="floatingCloseButton"
                checked={gesture.floatingCloseButton} onChange={handleCheck} name="floatingCloseButton"
              />
              <label className="form-check-label " htmlFor="floatingCloseButton">
                <h5 className="heading5 mt-1">Floating Close Button</h5>
              </label>
            </div>
          </div>
          <div className="row mb-3">
            <div className='col-lg-5'>
              <Label className="form-label">
                Floating Placement
              </Label>
              <Input
                type="select"
                className="form-select"
                value={gesture.floatingPlacement}
                onChange={handleChange}
                name="floatingPlacement"

              >
                <option value="right"  >
                  Right
                </option>
                <option value="left"  >
                  Left
                </option>
              </Input>
            </div>
          </div>
          <div className="mb-3">
            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
              <input
                type="checkbox"
                className="form-check-input"
                id="floatingOnStart"
                checked={gesture.floatingOnStart} onChange={handleCheck} name="floatingOnStart"
              />
              <label className="form-check-label " htmlFor="floatingOnStart">
                <h5 className="heading5 mt-1">Floating On Start</h5>
              </label>
            </div>
          </div>
          <div className="row mb-3">
            <div className='col-lg-5'>
              <Label className="form-label">
                Floating Percentage
              </Label>
              <Input
                type="text"
                value={gesture.floatingPercentage}
                onChange={handleChange}
                name="floatingPercentage"
              />
            </div>
          </div>
          <div className="mb-3">
            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
              <input
                type="checkbox"
                className="form-check-input"
                id="showPlaylistGallery"
                checked={gesture.showPlaylistGallery} onChange={handleCheck} name="showPlaylistGallery"
              />
              <label className="form-check-label " htmlFor="showPlaylistGallery">
                <h5 className="heading5 mt-1">Show Playlist Gallery</h5>
              </label>
            </div>
          </div>
          <div className="row mt-2 ml-3 ">
            <div className='col-lg-5 '>
              <Label className="form-label">
                Background Color
              </Label>
              <Input
                type="text"
                value={gesture.playerBackgroundColor}
                onChange={handleChange}
                name="playerBackgroundColor"
                readOnly={!gesture.showPlaylistGallery}
              />
            </div>
            <div className='col-lg-1'></div>
            <div className='col-lg-5 '>
              <Label className="form-label">
                Content Numbers
              </Label>
              <Input
                type="text"
                value={gesture.contentNumbers}
                onChange={handleChange}
                name="contentNumbers"
                readOnly={!gesture.showPlaylistGallery}
              />
            </div>
          </div>
          <div className="form-group row mt-3 ml-3">
            <div className='col-lg-5'>
              <Label className="form-label">
                Player Mode
              </Label>
              <Input
                type="select"
                className="form-select"
                value={gesture.playerMode}
                onChange={handleChange}
                name="playerMode"
                disabled={!gesture.showPlaylistGallery}
              >
                <option value="inRead"  >
                  In Read
                </option>
                <option value="floating"  >
                  Floating
                </option>
                <option value="sticky"  >
                  Sticky
                </option>
              </Input>
            </div>
          </div>
          <div className="form-group row mt-3 ml-3">
            <div className='col-lg-1'></div>
          </div>

          {gesture.playerMode === 'floating' &&

            <div className="row mb-4">
              <div className="col-md-5">
                <div className="form-group mt-3">
                  <input type="checkbox" id="prevBtn" className="form-check-input mt-1 p-0" checked={gesture.playerCloseFloatingButton} onChange={handleCheck} name="playerCloseFloatingButton" />
                  <label className="col-9 mt-0 mb-0 m-3">Close Floating Button</label>
                </div>
                <div className="form-group mt-3">
                  <input type="checkbox" id="nextBtn" className="form-check-input mt-1 p-0" checked={gesture.playerFloatOnBottom} onChange={handleCheck} name="playerFloatOnBottom" />
                  <label className="col-9 mt-0 mb-0 m-3">Float On Bottom</label>
                </div>
                <div className="form-group mt-3">
                  <input type="checkbox" id="contentrb" className="form-check-input mt-1 p-0" checked={gesture.playerFloatView} onChange={handleCheck} name="playerFloatView" />
                  <label className="col-9 mt-0 mb-0 m-3">Float On View</label>

                </div>
                <div className="form-group mt-3">
                  <input type="checkbox" className="form-check-input mt-1 p-0" checked={gesture.playerResizingMethod} onChange={handleCheck} name="playerResizingMethod" />
                  <span className="col-9 mt-0 mb-0 m-3">Resizing Method</span>
                </div>
                <div className="form-group mt-3">
                  <input type="checkbox" className="form-check-input  mt-1 p-0" checked={gesture.playerPlayPauseButton} onChange={handleCheck} name="playerPlayPauseButton" />
                  <span className="col-9 mt-0 mb-0 m-3">Play/Pause Button</span>
                </div>
                <div className="form-group mt-3">
                  <Label className="form-label">Size When Floating</Label>
                  <InputGroup >
                    <Input type="number" name="playerfloatingSize" value={gesture.playerfloatingSize} onChange={handleChange}
                    /> <div className="input-group-text">%</div>
                  </InputGroup>
                </div>
              </div>
            </div>
          }
          {(gesture.playerMode === 'floating' || gesture.playerMode === 'sticky') &&
            <div className="row mb-4">
              <div className="col-md-5">
                <div className="form-group ">
                  <Label className="form-label">Position</Label>
                  <Input
                    type="select"
                    className="form-select  mt-2 mb-1"
                    value={gesture.position}
                    onChange={handleChange}
                    name="position"
                  >
                    <option value="bottomRight"  >
                      Bottom Right
                    </option>
                    <option value="bottomLeft"  >
                      Bottom Left
                    </option>
                    <option value="topRight"  >
                      Top Right
                    </option>
                    <option value="topLeft"  >
                      Top Left
                    </option>
                    <option value="bottom"  >
                      Bottom
                    </option>
                    <option value="top"  >
                      Top
                    </option>
                  </Input>
                </div>
                <div className="form-group  mt-3">
                  <Label className="form-label">Bottom Margin</Label>
                  <InputGroup >
                    <Input
                      type="number" name="bottomMargin" placeholder="0" value={gesture.bottomMargin} onChange={handleChange}
                    /> <div className="input-group-text">px</div>
                  </InputGroup>
                </div>
                <div className="form-group mt-3">
                  <Label className="form-label">Right Margin</Label>
                  <InputGroup >
                    <Input
                      type="number" name="rightMargin" placeholder="0" value={gesture.rightMargin} onChange={handleChange}
                    /><div className="input-group-text">px</div>
                  </InputGroup>
                </div>
              </div>
            </div>
          }
          <div className="row ml-3">
            <div className='col-lg-5'>
              <Label className="form-label">
                content Identifier
              </Label>
              <Input
                type="select"
                className="form-select"
                value={gesture.contentIdentifier}
                onChange={handleChange}
                name="contentIdentifier"
              >
                <option value="tag">Tag</option>
                <option value="class">class</option>
                <option value="id">id</option>
              </Input>
            </div>
            <div className='col-lg-1'></div>
            <div className='col-lg-5'>
              <Label className="form-label">
                Custom CSS
              </Label>
              <Input
                type="textarea"
                rows="5"
                value={gesture.customCss}
                onChange={handleChange}
                name="customCss"
              />
            </div>
          </div>
          <div className="form-group row ml-3">
            <div className='col-lg-5'>
              <Label className="form-label">
                Content Helpers
              </Label>
              <Input
                type="text"
                value={gesture.contentHelpers}
                onChange={handleChange}
                name="contentHelpers"
              />
            </div>
            <div className='col-lg-1'></div>
          </div>
          <div className="form-group row ml-3">
            <div className='col-lg-5'>
              <Label className="form-label">
                Player Alignment
              </Label>
              <Input
                type="text"
                value={gesture.playerAlignment}
                onChange={handleChange}
                name="playerAlignment"
              />
            </div>
            <div className='col-lg-1'></div>
          </div>
          <div className="row ml-3 mt-3">
            <div className='col-lg-5'>
              <Label className="form-label">
                Sticky Position
              </Label>
              <Input
                type="select"
                className="form-select"
                value={gesture.stickyPosition}
                onChange={handleChange}
                name="stickyPosition"
              >
                <option value="bottom">Bottom</option>
                <option value="top">Top</option>
                <option value="middle">Middle</option>
              </Input>
            </div>
            <div className='col-lg-1'></div>
            <div className='col-lg-5'>
              <Label className="form-label">
                Sticky Placement
              </Label>
              <Input
                type="select"
                className="form-select"
                value={gesture.stickyPlacement}
                onChange={handleChange}
                name="stickyPlacement"
              >
                <option value="right">Right</option>
                <option value="left">Left</option>
              </Input>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlayerGesture