import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_SIZES,GET_DEVICES,GET_OSS,GET_BROWSERS,GET_DOMAINS,GET_PAGE_URLS,GET_KEYWORDS,GET_LANGUAGES,
  GET_CATEGORYS,GET_BRAND_AFFINITYS,GET_REGIONS,GET_CITYS,GET_INTERESTS} from "./actionTypes"

import {
  getCommonSizesSuccess,
  getCommonSizesFail,
  getCommonDeviceSuccess,
  getCommonDeviceFail,
  getCommonOSSuccess,
  getCommonOSFail,
  getCommonBrowserSuccess,
  getCommonBrowserFail,
  getCommonDomainSuccess,
  getCommonDomainFail,
  getCommonPageUrlSuccess,
  getCommonPageUrlFail,
  getCommonInterestSuccess,
  getCommonInterestFail,
  getCommonKeywordSuccess,
  getCommonKeywordFail,
  getCommonLanguageSuccess,
  getCommonLanguageFail,
  getCommonCategorySuccess,
  getCommonCategoryFail,
  getCommonBrandAffinitySuccess,
  getCommonBrandAffinityFail,
  getCommonRegionSuccess,
  getCommonRegionFail,
  getCommonCitySuccess,
  getCommonCityFail

} from "./actions"

//Include Both Helper File with needed methods
import { getSizes,getDevices,getOSs,getBrowsers,getDomains,getPageUrls,getInterests,
  getKeywords,getLanguages,getCategories,getBrandAffinities,getRegions,getCities} from "../../helpers/Backend_helper"

function* fetchSizes() {
  try {
    const response = yield call(getSizes)
    console.log(response)
  console.log(response)
    yield put(getCommonSizesSuccess(response.response))
  } catch (error) {
    yield put(getCommonSizesFail(error))
  }
}

function* fetchDevices({ payload: adv }) {
  try {
    const response = yield call(getDevices,adv)
    console.log(response)
  console.log(response)
    yield put(getCommonDeviceSuccess(response))
  } catch (error) {
    yield put(getCommonDeviceFail(error))
  }
}

function* fetchOSs() {
  try {
    const response = yield call(getOSs)
    console.log(response)
  console.log(response)
    yield put(getCommonOSSuccess(response))
  } catch (error) {
    yield put(getCommonOSFail(error))
  }
}

function* fetchBrowsers() {
  try {
    const response = yield call(getBrowsers)
    console.log(response)
  console.log(response)
    yield put(getCommonBrowserSuccess(response.webBrowsers))
  } catch (error) {
    yield put(getCommonBrowserFail(error))
  }
}

function* fetchDomains() {
  try {
    const response = yield call(getDomains)
    console.log(response)
  console.log(response)
    yield put(getCommonDomainSuccess(response.website))
  } catch (error) {
    yield put(getCommonDomainFail(error))
  }
}

function* fetchPageUrls() {
  try {
    const response = yield call(getPageUrls)
    console.log(response)
  console.log(response.response)
    yield put(getCommonPageUrlSuccess(response.response))
  } catch (error) {
    yield put(getCommonPageUrlFail(error))
  }
}

function* fetchInterests() {
  try {
    const response = yield call(getInterests)
    console.log(response)
  console.log(response.response)
    yield put(getCommonInterestSuccess(response.response))
  } catch (error) {
    yield put(getCommonInterestFail(error))
  }
}

function* fetchKeywords() {
  try {
    const response = yield call(getKeywords)
    console.log(response)
  console.log(response.response)
    yield put(getCommonKeywordSuccess(response.response))
  } catch (error) {
    yield put(getCommonKeywordFail(error))
  }
}

function* fetchLanguages() {
  try {
    const response = yield call(getLanguages)
    console.log(response)
  console.log(response)
    yield put(getCommonLanguageSuccess(response.response[1].value))
  } catch (error) {
    yield put(getCommonLanguageFail(error))
  }
}

function* fetchCategories() {
  try {
    const response = yield call(getLanguages)
    console.log(response)
  console.log(response)
    yield put(getCommonCategorySuccess(response.response[0].value))
  } catch (error) {
    yield put(getCommonCategoryFail(error))
  }
}

function* fetchBrandAffinities() {
  try {
    const response = yield call(getBrandAffinities)
    console.log(response)
  console.log(response.response)
    yield put(getCommonBrandAffinitySuccess(response.response))
  } catch (error) {
    yield put(getCommonBrandAffinityFail(error))
  }
}

function* fetchRegions() {
  try {
    const response = yield call(getRegions)
    console.log(response)
    console.log(response)
    yield put(getCommonRegionSuccess(response))
  } catch (error) {
    yield put(getCommonRegionFail(error))
  }
}

function* fetchCities() {
  try {
    const response = yield call(getCities)
    console.log(response)
  console.log(response)
    yield put(getCommonCitySuccess(response))
  } catch (error) {
    yield put(getCommonCityFail(error))
  }
}

function* commonEventsSaga() {
  yield takeEvery(GET_SIZES, fetchSizes)
  yield takeEvery(GET_DEVICES, fetchDevices)
  yield takeEvery(GET_OSS, fetchOSs)
  yield takeEvery(GET_BROWSERS, fetchBrowsers)
  yield takeEvery(GET_DOMAINS, fetchDomains)
  yield takeEvery(GET_PAGE_URLS, fetchPageUrls)
  yield takeEvery(GET_INTERESTS, fetchInterests)
  yield takeEvery(GET_KEYWORDS, fetchKeywords)
  yield takeEvery(GET_LANGUAGES, fetchLanguages)
  yield takeEvery(GET_CATEGORYS, fetchCategories)
  yield takeEvery(GET_BRAND_AFFINITYS, fetchBrandAffinities)
  yield takeEvery(GET_REGIONS, fetchRegions)
  yield takeEvery(GET_CITYS, fetchCities)
}

export default commonEventsSaga;
