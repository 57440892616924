import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_SCRAPPYS, ADD_SCRAPPY, UPDATE_SCRAPPY, READ_SCRAPPY, STATUS_SCRAPPY, ARCHIVE_SCRAPPY } from "./actionTypes"

import {
  loadActivitiesAdScrappys,
  noScrappys,
  getScrappysSuccess,
  getScrappysFail,
  addScrappySuccess,
  addScrappyFail,
  updateScrappySuccess,
  updateScrappyFail,
  readScrappySuccess,
  statusScrappySuccess,
  archiveScrappySuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getScrappys, postScrappys, updateScrappys, readScrappys, statusChangeScrappys, archiveScrappys } from "../../../helpers/Backend_helper"

function* fetchScrappys({ payload: crtv }) {
  try {
    yield put(loadActivitiesAdScrappys(true))
    var getCrtList = []
    console.log(crtv)
    if (crtv) {
      getCrtList = yield call(getScrappys, crtv)
      console.log(getCrtList)
      if (getCrtList.data.statusCode === 200) {
      getCrtList.data.response.map((item, index) => {
          item.id = index + 1
        })
        if (getCrtList.data.response.length === 0) {
          yield put(noScrappys(true))
        }
        yield put(getScrappysSuccess(getCrtList.data))
      }
    }
    else {
      getCrtList = []
      yield put(getScrappysSuccess(getCrtList))

    }
  } catch (error) {
    yield put(getScrappysFail(error))
  }
}

function* onReadScrappy({ payload: crtv }) {
  try {
    const response = yield call(readScrappys, crtv)
    yield put(readScrappySuccess(response))
  } catch (error) {
    yield put(readScrappySuccess(error))
  }
}

function* onAddScrappy({ payload: crtv }) {
  try {
    console.log(crtv)
    const response = yield call(postScrappys, crtv)
    console.log(response)
    if (response.data.success) {
      yield put(addScrappySuccess(response.data))
    } else {
      yield put(addScrappyFail(response.data))
    }
  } catch (error) {
    console.log(error.response.data)
    yield put(addScrappyFail(error.response.data))
  }
}

function* onUpdateScrappy({ payload: crtv }) {
  try {
    const response = yield call(updateScrappys, crtv)
    if (response.data.success) {
      yield put(updateScrappySuccess(response.data))
    } else {
      yield put(updateScrappyFail(response.data))
    }
  } catch (error) {
    yield put(updateScrappyFail(error.response.data))
  }
}

function* onStatusScrappy({ payload: crtv }) {
  try {
    const response = yield call(statusChangeScrappys, crtv)
    console.log(response)
    if (response.data.statusCode === 200) {
    yield put(statusScrappySuccess(response.data))
    }
  } catch (error) {
    yield put(updateScrappyFail(error))
  }
}

function* onArchiveScrappy({ payload: crtv }) {
  try {
    const response = yield call(archiveScrappys, crtv)
    console.log(response)
    if (response.data.success) {
    yield put(archiveScrappySuccess(response.data))
    }
  } catch (error) {
    yield put(archiveScrappySuccess(error))
  }
}

function* scrappySaga() {
  yield takeEvery(GET_SCRAPPYS, fetchScrappys)
  yield takeEvery(ADD_SCRAPPY, onAddScrappy)
  yield takeEvery(UPDATE_SCRAPPY, onUpdateScrappy)
  yield takeEvery(READ_SCRAPPY, onReadScrappy)
  yield takeEvery(STATUS_SCRAPPY, onStatusScrappy)
  yield takeEvery(ARCHIVE_SCRAPPY, onArchiveScrappy)
}

export default scrappySaga;
