import {
    GET_SIZES,
    GET_SIZES_FAIL,
    GET_SIZES_SUCCESS,
    GET_DEVICES,
    GET_DEVICE_SUCCESS,
    GET_DEVICE_FAIL,
    GET_OSS,
    GET_OS_SUCCESS,
    GET_OS_FAIL,
    GET_BROWSERS,
    GET_BROWSER_SUCCESS,
    GET_BROWSER_FAIL,
    GET_DOMAINS,
    GET_DOMAIN_SUCCESS,
    GET_DOMAIN_FAIL,
    GET_PAGE_URLS,
    GET_PAGE_URL_SUCCESS,
    GET_PAGE_URL_FAIL,
    GET_INTERESTS,
    GET_INTEREST_SUCCESS,
    GET_INTEREST_FAIL,
    GET_KEYWORDS,
    GET_KEYWORD_SUCCESS,
    GET_KEYWORD_FAIL,
    GET_LANGUAGES,
    GET_LANGUAGE_SUCCESS,
    GET_LANGUAGE_FAIL,
    GET_CATEGORYS,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAIL,
    GET_BRAND_AFFINITYS,
    GET_BRAND_AFFINITY_SUCCESS,
    GET_BRAND_AFFINITY_FAIL,
    GET_REGIONS,
    GET_REGION_SUCCESS,
    GET_REGION_FAIL,
    GET_CITYS,
    GET_CITY_SUCCESS,
    GET_CITY_FAIL,

  } from "./actionTypes"

  export const getCommonSizes = () => ({
    type: GET_SIZES,
  })
  
  export const getCommonSizesSuccess = Sizes => ({
    type: GET_SIZES_SUCCESS,
    payload: Sizes,
  })

  export const getCommonSizesFail = error => ({
    type: GET_SIZES_FAIL,
    payload: error,
  })

  export const getCommonDevices = Params => ({
    type: GET_DEVICES,
    payload:Params
  })
  
  export const getCommonDeviceSuccess = Devices => ({
    type: GET_DEVICE_SUCCESS,
    payload: Devices,
  })

  export const getCommonDeviceFail = error => ({
    type: GET_DEVICE_FAIL,
    payload: error,
  })

  export const getCommonOSs = () => ({
    type: GET_OSS,
  })
  
  export const getCommonOSSuccess = OSs => ({
    type: GET_OS_SUCCESS,
    payload: OSs,
  })

  export const getCommonOSFail = error => ({
    type: GET_OS_FAIL,
    payload: error,
  })

  export const getCommonBrowsers = () => ({
    type: GET_BROWSERS,
  })
  
  export const getCommonBrowserSuccess = Browsers => ({
    type: GET_BROWSER_SUCCESS,
    payload: Browsers,
  })

  export const getCommonBrowserFail = error => ({
    type: GET_BROWSER_FAIL,
    payload: error,
  })

  export const getCommonDomains = () => ({
    type: GET_DOMAINS,
  })
  
  export const getCommonDomainSuccess = Domains => ({
    type: GET_DOMAIN_SUCCESS,
    payload: Domains,
  })

  export const getCommonDomainFail = error => ({
    type: GET_DOMAIN_FAIL,
    payload: error,
  })

  export const getCommonPageUrls = () => ({
    type: GET_PAGE_URLS,
  })
  
  export const getCommonPageUrlSuccess = PageUrls => ({
    type: GET_PAGE_URL_SUCCESS,
    payload: PageUrls,
  })

  export const getCommonPageUrlFail = error => ({
    type: GET_PAGE_URL_FAIL,
    payload: error,
  })
  export const getCommonInterests = () => ({
    type: GET_INTERESTS,
  })
  
  export const getCommonInterestSuccess = Interest => ({
    type: GET_INTEREST_SUCCESS,
    payload: Interest,
  })

  export const getCommonInterestFail = error => ({
    type: GET_INTEREST_FAIL,
    payload: error,
  })
  export const getCommonKeywords = () => ({
    type: GET_KEYWORDS,
  })
  
  export const getCommonKeywordSuccess = Keywords => ({
    type: GET_KEYWORD_SUCCESS,
    payload: Keywords,
  })

  export const getCommonKeywordFail = error => ({
    type: GET_KEYWORD_FAIL,
    payload: error,
  })

  export const getCommonLanguages = () => ({
    type: GET_LANGUAGES,
  })
  
  export const getCommonLanguageSuccess = Languages => ({
    type: GET_LANGUAGE_SUCCESS,
    payload: Languages,
  })

  export const getCommonLanguageFail = error => ({
    type: GET_LANGUAGE_FAIL,
    payload: error,
  })

  export const getCommonCategories = () => ({
    type: GET_CATEGORYS,
  })
  
  export const getCommonCategorySuccess = Categories => ({
    type: GET_CATEGORY_SUCCESS,
    payload: Categories,
  })

  export const getCommonCategoryFail = error => ({
    type: GET_CATEGORY_FAIL,
    payload: error,
  })

  export const getCommonBrandAffinities = () => ({
    type: GET_BRAND_AFFINITYS,
  })
  
  export const getCommonBrandAffinitySuccess = BrandAffinities => ({
    type: GET_BRAND_AFFINITY_SUCCESS,
    payload: BrandAffinities,
  })

  export const getCommonBrandAffinityFail = error => ({
    type: GET_BRAND_AFFINITY_FAIL,
    payload: error,
  })

  export const getCommonRegions = () => ({
    type: GET_REGIONS,
  })
  
  export const getCommonRegionSuccess = Regions => ({
    type: GET_REGION_SUCCESS,
    payload: Regions,
  })

  export const getCommonRegionFail = error => ({
    type: GET_REGION_FAIL,
    payload: error,
  })

  export const getCommonCities = () => ({
    type: GET_CITYS,
  })
  
  export const getCommonCitySuccess = Cities => ({
    type: GET_CITY_SUCCESS,
    payload: Cities,
  })

  export const getCommonCityFail = error => ({
    type: GET_CITY_FAIL,
    payload: error,
  })