/* eslint-disable react/prop-types */
import React, { useRef, useEffect, useState } from 'react';
// import { Card, CardBody, Col, Form, Input, Label, Row, Spinner, Button,Container } from "reactstrap"
import Breadcrumb from 'components/Common/Breadcrumb';
import Sidebar from 'components/VerticalLayout/Sidebar';
import Header from 'components/VerticalLayout/Header';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import YouTube from 'react-youtube';
import {
  Card, CardBody, Col, Form, Input, Label, Row, Spinner, Button, Progress, Nav,
  NavItem,
  NavLink, TabContent,
  TabPane,
  Modal, ModalBody, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Container
} from "reactstrap"
import axios from "axios"
import classnames from "classnames";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

var videoContentFileName = ""
var file = ''


const VideoConverter = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [url, setUrl] = useState("")
  const [loader, setLoader] = useState(false)

  const [objUrl, setObjUrl] = useState('')

  // function handleVideoTimeUpdate() {
  //   const video = videoRef.current;
  //   const canvas = canvasRef.current;
  //   canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
  //   const thumbnailUrl = canvas.toDataURL();
  //   console.log(canvas);
  //   console.log(thumbnailUrl);
  //   setThumbnailUrl(thumbnailUrl);
  // }

  // console.log(thumbnailUrl);

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 1,
    },
  };



  const [userFile, setUserFile] = useState(Math.random().toString(36))
  const [CdnLink, setCdnLink] = useState('')
  // const [loader, setLoader] = useState(false)
  const [loaderYoutube, setLoaderYoutube] = useState(false)
  const [loaderFile, setLoaderFile] = useState(false)


  const [spinner, setSpinner] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0);

  const [spinnerFile, setSpinnerFile] = useState(false)
  const [uploadProgressFile, setUploadProgressFile] = useState(0);


  const [inputValue, setInputValue] = useState('');
  const [videoId, setVideoId] = useState('');

  const [showAlert, setShowAlert] = useState(false);

  const [btnprimary1, setBtnprimary1] = useState(false)
  const [resolution, setResolution] = useState('640:360')


  const [btnprimary2, setBtnprimary2] = useState(false)
  const [quality, setQuality] = useState('high')

  const [showAlertFile, setShowAlertFile] = useState(false);


  const [btnprimary3, setBtnprimary3] = useState(false)
  const [resolutionFile, setResolutionFile] = useState('640:360')




  const [btnprimary4, setBtnprimary4] = useState(false)
  const [qualityFile, setQualityFile] = useState('high')

  // const [startTimeSec, setStartTimeSec] = useState('');
  // const [startTimeMin, setStartTimeMin] = useState('');

  // const [durationSec, setDurationSec] = useState('');
  // const [durationMin, setDurationMin] = useState('');

  const [customActiveTab, setcustomActiveTab] = useState("1");



  const [trimEnabled, setTrimEnabled] = useState(false);

  const [file, setFile] = useState()


  const handleStartTimeChangeSec = (event) => {
    setStartTimeSec(event.target.value);
  };
  const handleStartTimeChangeMin = (event) => {
    setStartTimeMin(event.target.value);
  };

  const handleDurationChangeSec = (event) => {
    setDurationSec(event.target.value);
  };

  const handleDurationChangeMin = (event) => {
    setDurationMin(event.target.value);
  };

  const handleTrimChange = () => {
    setTrimEnabled(!trimEnabled);
  };

  function handleVideoTimeUpdate() {
    console.log("called handle video time update")
    const video = videoRef.current;
    const canvas = canvasRef.current;
    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
    const thumbnailUrl = canvas.toDataURL();
    // canvas.toBlob((blob) => {
    //     uploadSelectedFrame(blob);
    // }, 'image/png', 0.2);
    console.log(thumbnailUrl);
    setThumbnailUrl(thumbnailUrl);
  }


  const [videoContent, setVideoContent] = useState({
    videoContentPath: '',
    videoContentDuration: '',
  });




  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };


  const pathValidator = () => {
    if (inputValue.includes('youtube')) {
      directYoutubePath()
    } else {
      handleDirectLinkDownload()
    }
  }



  const chooseIt = () => {
    setShowAlert(true)
    // pathValidator()
  }
  const chooseItFile = () => {
    setShowAlertFile(true)
  }


  const handleVideoLoadedData = () => {
    setVideoLoaded(true);
  }



  const handleYoutubeAndDirectLinkDownload = async (type) => {
    setShowAlert(false);
    setSpinner(true);
    var videoBlob;
    var videoName;
    var videoType;
    var videoContentFileName;
    var thumbnail;

    try {
      if (inputValue.includes('youtube')) {
        console.log('youtube video');
        const response = await axios.post("/downloadvideotool", {
          url: inputValue,
          resolution: resolution,
          outputType: type,
          // startTime: `${startTimeMin}:${startTimeSec}`,
          // duration: `${durationMin}:${durationSec}`
          preset: quality
        }, {
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            const percentCompleted = progressEvent.total ? Math.round((progressEvent.loaded * 100) / progressEvent.total) : 100;
            // setDownloadProgress(percentCompleted);
          },
        });
        videoBlob = new Blob([response.data]);


        const cHeader = response.headers['content-disposition']
        const fileMatch = cHeader.match(/vInfo="(.+)"/)
        if (fileMatch && fileMatch.length > 1) {
          const videoInfo = fileMatch[1]
          console.log(fileMatch)
          const videoData = JSON.parse(videoInfo)
          videoContentFileName = videoData[0].title
          console.log(videoContentFileName)
          videoName = videoData[0].title.replace(/\s+/g, "")
          thumbnail = videoData[0].thumbnailPath
        }

        console.log(thumbnail)

        axios.post('/downloadthumbnail', { path: thumbnail }, { responseType: 'arraybuffer' })
          .then(response => {
            // Create a Blob from the image data
            const thumbnailBlob = new Blob([response.data], { type: response.headers['content-type'] });

            // Create a download link
            const link = document.createElement('a');
            link.href = URL.createObjectURL(thumbnailBlob);
            link.download = `${videoName}.jpg`; // Set the desired filename

            // Simulate a click to trigger the download
            link.click();

            // Clean up the temporary anchor element
            URL.revokeObjectURL(link.href);
          })
          .catch(error => {
            console.error('Error downloading thumbnail:', error);
            // Handle the error as needed
          });

        if (type === 'compression') {
          videoType = 'video/mp4';
          videoContentFileName = `${videoName}.mp4`; // You need to set this based on your naming logic
        } else if (type === 'hls') {
          videoType = 'application/zip'; // Correct MIME type for zip files
          videoContentFileName = `${videoName}.zip`; // Set the appropriate file name with .zip extension
        }


        // Trigger the download by simulating a click on a temporary anchor element
        const downloadUrl = URL.createObjectURL(videoBlob);
        // setObjUrl(URL.createObjectURL(videoBlob))

        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `${videoContentFileName}`;
        link.click();

        // Clean up the temporary anchor element
        URL.revokeObjectURL(downloadUrl);
        setSpinner(false)
      } else {
        toastr.info("Please enter valid Url")
        setLoader(false)
        setSpinner(false)
      }

      // ...
    } catch (err) {
      console.error(err);
      setLoader(false);
      setSpinner(false);
      toastr.error(err);
    }
  };

  const fileChange = (file) => {
    setFile(file)
    setObjUrl(URL.createObjectURL(file.target.files[0]))
    videoContentFileName = file.target.files[0].name
  };

  const handleVideoCompression = async (type) => {
    setShowAlertFile(false)
    var videoBlob;
    var videoName;
    var videoType;

    if (file.target.files[0].size <= 714572800) {


      console.log("called handle video compression")
      setLoader(true);
      console.log(file.target.files[0].size)
      setLoaderFile(true)

      const fileName = file.target.files[0].name
      const fileType = file.target.files[0].type;
      console.log("file=====================", file);
      videoContentFileName = fileName;
      console.log(videoContentFileName);
      const formData = new FormData();
      let newFile = file.target.files[0];
      formData.append("file", newFile);

      // const toBase64 = (file) =>
      //   new Promise((resolve, reject) => {
      //     const reader = new FileReader();
      //     reader.readAsDataURL(file);
      //     reader.onload = () => {
      //       var media = new Audio(reader.result);
      //       media.onloadedmetadata = function () {
      //         media.duration; // this would give duration of the video/audio file
      //       };
      //       resolve(reader.result);
      //     };
      //     reader.onerror = (error) => reject(error);
      //     console.log(reader.result);
      //   });

      // var newBaseData = toBase64(newFile);
      const newBaseContent = {};
      (async () => {
        // newBaseContent.data = await newBaseData;
        // newBaseContent.fileName = fileName;
        // newBaseContent.type = fileType;
        // newBaseContent.location = 'videoContentData';
        // newBaseContent.userID = userInfo.userID;
        // newBaseContent.userRole = userInfo.userRole;
        // newBaseContent.companyID = userInfo.companyID;
        // console.log(newBaseContent);
        // const bodys = {
        //   url: "http://10.0.3.69:3022/upload/uploadFile",
        //   requestBody: newBaseContent,
        // };
        // const bodyss = {
        //   data: await toBase64(newFile),
        //   fileName: fileName,
        //   type: fileType,
        //   location: 'videoContentData',
        //   userID: userInfo.userID,
        //   userRole: userInfo.userRole,
        //   companyID: userInfo.companyID
        // }
        const body = formData
        console.log(body)
        axios
          .post("/uploadFile", body, {
            headers: headers,
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              setUploadProgress(progress);
            },
          })
          .then(async (res) => {
            console.log("Video Upload ==>", res);
            setLoaderFile(false)
            setSpinner(true)


            const response = await axios.post("/processlocalvideo", {
              videoFilePath: `/home/ubuntu/videoContentData/${videoContentFileName}`,
              videoFileName: videoContentFileName,
              resolution: resolution,
              outputType: type,
              preset: quality

            }, {
              responseType: 'blob'
            })
            videoBlob = new Blob([response.data]);
            videoContentFileName = videoContentFileName.replace('.mp4', '')
            console.log(videoContentFileName)

            if (type === 'compression') {
              videoType = 'video/mp4';
              videoContentFileName = `${videoContentFileName}.mp4`; // You need to set this based on your naming logic
            } else if (type === 'hls') {
              videoType = 'application/zip'; // Correct MIME type for zip files
              videoContentFileName = `${videoContentFileName}.zip`; // Set the appropriate file name with .zip extension
            }

            // Trigger the download by simulating a click on a temporary anchor element
            const downloadUrl = URL.createObjectURL(videoBlob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = `${videoContentFileName}`;
            link.click();

            // Clean up the temporary anchor element
            URL.revokeObjectURL(downloadUrl);
            setSpinner(false)


          })
          .catch((err) => {
            setLoaderFile(false)
            setLoader(false);
            setSpinner(false);
            console.log("e", err.code);
            toastr.error(err);
            setUserFile(Math.random().toString(36))
          });
      })();

    } else {
      setLoaderFile(false)
      setLoader(false);
      setSpinner(false);
      toastr.error("please select video less than 300mb")
      setUserFile(Math.random().toString(36))
    }
  }

  const handleDownloadScreenshot = () => {
    console.log(videoContentFileName)
    const link = document.createElement('a');
    link.href = thumbnailUrl;
    link.download = `${videoContentFileName.replace(".mp4", '')}.jpg`;
    link.click();

    // Clean up the temporary anchor element
    URL.revokeObjectURL(objUrl);
  }

  return (

    <>
      {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
      <div className="page-content">
        <Helmet>
          <title>Video Compressor and Converter | Bidsxchange</title>
        </Helmet>
        <Container fluid={true}>
          <Breadcrumb title="Video Compressor and Downloder" breadcrumbItem="Video Compressor and Converter" />
          <Row >
            <Col md="12">
              {/* {loader &&
                <Spinner className="ms-2 loader" color="primary" />
              } */}
              {/* <Form action="https://support-ticket.bidsxchange.com/create" encType="multipart/form-data" method="post"> */}
              <div style={{ padding: '1% 3%', height: '100vh', overflow: 'auto' }}>
                <Nav tabs className="nav-tabs-custom nav-justified" style={{ width: '30pc' }}>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleCustom("1");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-home"></i>
                      </span>
                      <span className="d-none d-sm-block" style={{ fontSize: '13px' }}>Direct File</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleCustom("2");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-user"></i>
                      </span>
                      <span className="d-none d-sm-block" style={{ fontSize: '13px' }}>link</span>
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "3",
                      })}
                      onClick={() => {
                        toggleCustom("3");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-user"></i>
                      </span>
                      <span className="d-none d-sm-block" style={{ fontSize: '13px' }}>Thumbnail Creator</span>
                    </NavLink>
                  </NavItem> */}
                </Nav>
                <TabContent
                  activeTab={customActiveTab}
                  className="p-3 text-muted"
                >
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <Label className="text-left" htmlFor="direct-file">Select File</Label>
                          <Input className="w-75" type="file" id="direct-file" accept='video/mp4' key={userFile || ''} onChange={fileChange} />
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="btn-group m-1 mt-2">
                              <Dropdown isOpen={btnprimary3} toggle={() => setBtnprimary3(!btnprimary3)}>
                                <DropdownToggle caret>
                                  Resolution: {resolutionFile === '640:360' ? '360p' : resolutionFile === '426:240' ? '240p' : '144p'}
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem onClick={() => setResolutionFile('640:360')}>360p</DropdownItem>
                                  <DropdownItem onClick={() => setResolutionFile('426:240')}>240p</DropdownItem>
                                  <DropdownItem onClick={() => setResolutionFile('256:144')}>144p</DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </div>

                            <div className="btn-group m-1 mt-2">
                              <Dropdown isOpen={btnprimary4} toggle={() => setBtnprimary4(!btnprimary4)}>
                                <DropdownToggle caret>
                                  Compression Level : {qualityFile}
                                </DropdownToggle>
                                <DropdownMenu>
                                  {/* <DropdownItem onClick={() => setQualityFile('ultra')}>Ultra</DropdownItem>
                                  <DropdownItem onClick={() => setQualityFile('highest')}>Highest</DropdownItem> */}
                                  <DropdownItem onClick={() => setQualityFile('high')}>High </DropdownItem>
                                  <DropdownItem onClick={() => setQualityFile('medium')}>Medium</DropdownItem>
                                  <DropdownItem onClick={() => setQualityFile('low')}>Low</DropdownItem>
                                  {/* <DropdownItem onClick={() => setQualityFile('lowest')}>Lowest</DropdownItem> */}
                                </DropdownMenu>
                              </Dropdown>
                            </div>


                            <div>
                              <Button className="mt-1" onClick={() => chooseItFile()}>Download</Button>
                            </div>


                          </div>
                          <div style={{ minWidth: '30vw' }}>
                            {loaderFile &&
                              <Progress
                                className="mt-2"
                                animated
                                color="primary"
                                style={{
                                  height: '15px'
                                }}
                                striped
                                value={uploadProgress}
                              >{uploadProgress}%</Progress>
                            }

                            {
                              spinner &&
                              <div className="d-flex justify-content-center align-items-center mt-1">

                                <Button
                                  color="primary"
                                  disabled
                                >
                                  <Spinner size="sm">
                                    Loading...
                                  </Spinner>
                                  <span>
                                    {' '} The file is being compressed and converting. Please wait...
                                  </span>
                                </Button>
                              </div>

                            }
                          </div>

                        </div>
                      </Col>
                    </Row>
                    {objUrl &&
                      <Row>
                        <Col sm="12">
                          <Row>
                            <Col md="12" className='mt-4'>
                              <p style={{ fontWeight: "500" }}>Choose Your Thumbnail: </p>
                              <div className='d-flex justify-content-around align-items-start'>
                                <div className='d-flex flex-column justify-content-between'>


                                  <video className='m-2' ref={videoRef} src={objUrl} controls width={426} height={240} crossOrigin="anonymous" onLoadedData={handleVideoLoadedData}>
                                    {/* <source  type="video/mp4" /> */}
                                  </video>
                                  <Button className=' text-right m-3' onClick={handleVideoTimeUpdate} disabled={!videoLoaded}>capture</Button>
                                </div>
                                <canvas ref={canvasRef} width={640} height={360} className="d-none" />
                                {thumbnailUrl ? <div className='d-flex flex-column'> <img className='single-frame m-1 ' src={thumbnailUrl} crossOrigin="anonymous" alt="Video Thumbnail" /><Button className='mt-2' onClick={() => handleDownloadScreenshot()}>Download</Button></div> : <div className='single-frame d-flex justify-content-center align-items-center' style={{ backgroundColor: "gray" }}> <h6>Capture dynamically which frame you want</h6></div>}
                                {/* <Button className='m-3' onClick={uploadSelectedFrame} >Save Frame</Button> */}
                                {/* {thumbnailUrl && <img className='single-frame' src={thumbnailUrl} crossOrigin="anonymous" alt="Video Thumbnail" />} */}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    }

                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <Label className="text-left" htmlFor="youtube-link">Enter link:</Label>

                          <Input className="w-75" type="text" id="youtube-link" name="youtube-link" placeholder="https://www.youtube.com/watch?v=... or mp4 link" required value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
                          {inputValue.includes('youtube') &&
                            <div className='p-3'>
                              <YouTube videoId={inputValue.split('v=')[1]} opts={opts} />
                            </div>
                          }

                          <div className="d-flex justify-content-center align-items-center">
                            <div className="btn-group m-1 mt-2">
                              <Dropdown isOpen={btnprimary1} toggle={() => setBtnprimary1(!btnprimary1)}>
                                <DropdownToggle caret>
                                  Resolution: {resolution === '640:360' ? '360p' : resolution === '426:240' ? '240p' : '144p'}
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem onClick={() => setResolution('640:360')}>360p</DropdownItem>
                                  <DropdownItem onClick={() => setResolution('426:240')}>240p</DropdownItem>
                                  <DropdownItem onClick={() => setResolution('256:144')}>144p</DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </div>

                            <div className="btn-group m-1 mt-2">
                              <Dropdown isOpen={btnprimary2} toggle={() => setBtnprimary2(!btnprimary2)}>
                                <DropdownToggle caret>
                                  Compression Level : {quality}
                                </DropdownToggle>
                                <DropdownMenu>
                                  {/* <DropdownItem onClick={() => setQuality('ultra')}>Ultra</DropdownItem>
                                  <DropdownItem onClick={() => setQuality('highest')}>Highest</DropdownItem> */}
                                  <DropdownItem onClick={() => setQuality('high')}>High </DropdownItem>
                                  <DropdownItem onClick={() => setQuality('medium')}>Medium</DropdownItem>
                                  <DropdownItem onClick={() => setQuality('low')}>Low</DropdownItem>
                                  {/* <DropdownItem onClick={() => setQuality('lowest')}>Lowest</DropdownItem> */}
                                </DropdownMenu>
                              </Dropdown>
                            </div>


                            {/* <div>
                        <Button className=" m-1 mt-2" onClick={() => videoDurationValidator()}>Compress and upload</Button>
                      </div> */}
                            <div>
                              <Button className="mt-1" onClick={() => chooseIt()}>Download</Button>

                            </div>
                            {/* <div>
                          <Button className="mt-1" onClick={() => handleYoutubeAndDirectLinkDownload('hls')}>Download Hls</Button>
                        </div> */}
                          </div>
                          {/* <div className="form-check d-flex justify-content-start mt-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="trimCheckbox"
                        checked={trimEnabled}
                        onChange={handleTrimChange}
                      />
                      &nbsp;&nbsp;
                      <label className="form-check-label  m-2 mt-1 ml-1 " htmlFor="trimCheckbox">
                        Trim
                      </label>
                    </div>

                    <div className="form-group d-flex align-items-center justify-content-center">
                      <label htmlFor="timeInputs" className="mt-2">Start Time   </label>
                      &nbsp;&nbsp;
                      <input
                        type="text"
                        className="form-control form-control-sm mr-2 text-center"
                        style={{ maxWidth: '3vw' }}
                        id="startTimeSec"
                        placeholder="MM"
                        value={startTimeMin}
                        onChange={handleStartTimeChangeMin}
                        disabled={!trimEnabled}
                      />
                      <span className="m-2">:</span>
                      <input
                        type="text"
                        className="form-control form-control-sm mr-2 text-center"
                        style={{ maxWidth: '3vw' }}
                        id="duration"
                        placeholder="SS"
                        value={startTimeSec}
                        onChange={handleStartTimeChangeSec}
                        disabled={!trimEnabled}
                      />

                    </div>
                    <div className="form-group d-flex align-items-center justify-content-center">
                      <label htmlFor="timeInputs" className="mt-2">Duration</label>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <input
                        type="text"
                        className="form-control form-control-sm mr-2 text-center"
                        style={{ maxWidth: '3vw' }}
                        id="startTimeSec"
                        placeholder="MM"
                        value={durationMin}
                        onChange={handleDurationChangeMin}
                        disabled={!trimEnabled}
                      />
                      <span className="m-2">:</span>
                      <input
                        type="text"
                        className="form-control form-control-sm mr-2 text-center"
                        style={{ maxWidth: '3vw' }}
                        id="duration"
                        placeholder="SS"
                        value={durationSec}
                        onChange={handleDurationChangeSec}
                        disabled={!trimEnabled}
                      />

                    </div> */}

                        </div>
                        {loaderYoutube &&
                          <Progress
                            className="mt-2"
                            animated
                            color="primary"
                            style={{
                              height: '15px'
                            }}
                            striped
                            value={uploadProgress}
                          >{uploadProgress}%</Progress>
                        }
                        {
                          spinner &&
                          <div className="d-flex justify-content-center align-items-center mt-1">

                            <Button
                              color="primary"
                              disabled
                            >
                              <Spinner size="sm">
                                Loading...
                              </Spinner>
                              <span>
                                {' '} The file is being compressed and converting. Please wait...
                              </span>
                            </Button>
                          </div>

                        }
                      </Col>
                    </Row>

                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="12">
                            <p style={{ fontWeight: "500" }}>Choose Your Thumbnail: </p>
                            <div className='d-flex justify-content-around align-items-start'>
                              <div className='d-flex flex-column justify-content-between'>


                                <video className='m-2' ref={videoRef} src={objUrl} controls width={426} height={240} crossOrigin="anonymous" onLoadedData={handleVideoLoadedData}>
                                  {/* <source  type="video/mp4" /> */}
                                </video>
                                <Button className=' text-right m-3' onClick={handleVideoTimeUpdate} disabled={!videoLoaded}>capture</Button>
                              </div>
                              <canvas ref={canvasRef} width={640} height={360} className="d-none" />
                              {thumbnailUrl ? <div className='d-flex flex-column'> <img className='single-frame m-1 ' src={thumbnailUrl} crossOrigin="anonymous" alt="Video Thumbnail" /><Button className='mt-2' onClick={() => handleDownloadScreenshot()}>Download</Button></div> : <div className='single-frame d-flex justify-content-center align-items-center' style={{ backgroundColor: "gray" }}> <h6>Capture dynamically which frame you want</h6></div>}
                              {/* <Button className='m-3' onClick={uploadSelectedFrame} >Save Frame</Button> */}
                              {/* {thumbnailUrl && <img className='single-frame' src={thumbnailUrl} crossOrigin="anonymous" alt="Video Thumbnail" />} */}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>


              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* </div> */}
      <Modal size="md" isOpen={showAlert} toggle={() => { setShowAlert(!showAlert) }} backdrop={'static'} centered={true}
        id="staticBackdrop"
      >
        <div className="modal-content">
          <ModalBody className="px-2 py-4 text-center ">
            <i className="bx bx-error-circle text-warning fon-size-32" style={{ fontSize: '2rem' }}></i> &nbsp;
            <h5 className="text-muted font-size-16 mb-4">   Choose Which Format You Want !</h5>
            <div className="hstack gap-2 justify-content-center mb-0">
              <button type="button" className="btn btn-light" onClick={() => handleYoutubeAndDirectLinkDownload('compression')}>Download Compressed Video</button>
              <button type="button" className="btn btn-primary" onClick={() => handleYoutubeAndDirectLinkDownload("hls")}>Download Streaming Format</button>
            </div>
          </ModalBody>
        </div>
      </Modal>
      <Modal size="md" isOpen={showAlertFile} toggle={() => { setShowAlertFile(!showAlertFile) }} backdrop={'static'} centered={true}
        id="staticBackdrop"
      >
        <div className="modal-content">
          <ModalBody className="px-2 py-4 text-center ">
            <i className="bx bx-error-circle text-warning fon-size-32" style={{ fontSize: '2rem' }}></i> &nbsp;
            <h5 className="text-muted font-size-16 mb-4">   Choose Which Format You Want !</h5>
            <div className="hstack gap-2 justify-content-center mb-0">
              <button type="button" className="btn btn-light" onClick={() => handleVideoCompression('compression')}>Compressed Video</button>
              <button type="button" className="btn btn-primary" onClick={() => handleVideoCompression("hls")}>Streaming Format</button>
            </div>
          </ModalBody>
        </div>
      </Modal>

    </>
  )
}

VideoConverter.propTypes = {}

export default VideoConverter

