import {
    LOAD_PAGEURLS,
    NO_DATA_PAGEURLS,
    GET_PAGE_URLS_SUCCESS,
    GET_PAGE_URLS_FAIL,
    ADD_PAGE_URL_SUCCESS,
    ADD_PAGE_URL_ERROR,
    UPDATE_PAGE_URL_SUCCESS,
    UPDATE_PAGE_URL_ERROR,
    READ_PAGE_URL_SUCCESS,
    STATUS_PAGE_URL_SUCCESS,
    ARCHIVE_PAGE_URL_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    pageUrlList: [],
    error: {},
    loading:false,
    noData:false,
    successMsg: null,
    errorMsg: null,
    pageUrl: null,
    response:null,
    archiveMsg:null,
}

const pageUrls = (state = INIT_STATE, action) => {
    
    switch (action.type) {
        case LOAD_PAGEURLS:
            return {
                ...state,
                loading: true,
            }
            case NO_DATA_PAGEURLS:
                return {
                    ...state,
                    noData: true,
                }
        case GET_PAGE_URLS_SUCCESS:
            return {
                ...state,
                loading: false,
                pageUrlList: action.payload,
            }

        case GET_PAGE_URLS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case ADD_PAGE_URL_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_PAGE_URL_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_PAGE_URLS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_PAGE_URL_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_PAGE_URLS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_PAGE_URL_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_PAGE_URLS_SUCCESS' ? null : action.payload,
            }
        case READ_PAGE_URL_SUCCESS:
            return {
                ...state,
                pageUrl: action.payload,
            }
        case STATUS_PAGE_URL_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_PAGE_URL_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default pageUrls