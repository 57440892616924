import {

    GET_ADMIN_ANALYTICS_REPORTS_FAIL,
    GET_ADMIN_ANALYTICS_REPORTS_SUCCESS,

    GET_AS_ANALYTICS_REPORTS_FAIL,
    GET_AS_ANALYTICS_REPORTS_SUCCESS,

    GET_YM_ANALYTICS_REPORTS_FAIL,
    GET_YM_ANALYTICS_REPORTS_SUCCESS,

    GET_MIS_ANALYTICS_REPORTS_FAIL,
    GET_MIS_ANALYTICS_REPORTS_SUCCESS,

    GET_PAGE_URL_REPORTS_FAIL,
    GET_PAGE_URL_REPORTS_SUCCESS,

    GET_TEAM_PAGE_URL_REPORTS_FAIL,
    GET_TEAM_PAGE_URL_REPORTS_SUCCESS,

    GET_TEAM_HISTORY_REPORTS_FAIL,
    GET_TEAM_HISTORY_REPORTS_SUCCESS,

    LOAD_REPORTS
} from "./actionTypes"

const INIT_STATE = {
    proxyAdU: [],
    proxyAdURpt: [],
    loading: false,
    revenueDisplayRpt: [],
    publisherRevenueDisplayRpt: [],
    adminAnalyticsRpt: [],
    asAnalyticsRpt: [],
    ymAnalyticsRpt: [],
    misAnalyticsRpt: [],
    pageUrlRpt: [],
    teamPageUrlRpt: [],
    teamHistoryRpt: [],
    proxyLoading: {},
    error: {},
    successMsg: null,
    errorMsg: null,
    response: null,
    archiveMsg: null,
}

const adminReports = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_REPORTS:
            return {
                ...state,
                loading: true,
            }

        case GET_ADMIN_ANALYTICS_REPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                adminAnalyticsRpt: action.payload,
            }

        case GET_ADMIN_ANALYTICS_REPORTS_FAIL:
            return {
                ...state,
                loading: false,
                errorMsg: action.payload,
            }
        case GET_AS_ANALYTICS_REPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                asAnalyticsRpt: action.payload,
            }

        case GET_AS_ANALYTICS_REPORTS_FAIL:
            return {
                ...state,
                loading: false,
                errorMsg: action.payload,
            }
        case GET_YM_ANALYTICS_REPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                ymAnalyticsRpt: action.payload,
            }

        case GET_YM_ANALYTICS_REPORTS_FAIL:
            return {
                ...state,
                loading: false,
                errorMsg: action.payload,
            }
        case GET_MIS_ANALYTICS_REPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                misAnalyticsRpt: action.payload,
            }

        case GET_MIS_ANALYTICS_REPORTS_FAIL:
            return {
                ...state,
                loading: false,
                errorMsg: action.payload,
            }
        case GET_PAGE_URL_REPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                pageUrlRpt: action.payload,
            }

        case GET_PAGE_URL_REPORTS_FAIL:
            return {
                ...state,
                loading: false,
                errorMsg: action.payload,
            }
        case GET_TEAM_PAGE_URL_REPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                teamPageUrlRpt: action.payload,
            }

        case GET_TEAM_PAGE_URL_REPORTS_FAIL:
            return {
                ...state,
                loading: false,
                errorMsg: action.payload,
            }
        case GET_TEAM_HISTORY_REPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                teamHistoryRpt: action.payload,
            }

        case GET_TEAM_HISTORY_REPORTS_FAIL:
            return {
                ...state,
                loading: false,
                errorMsg: action.payload,
            }
        default:
            return state
    }
}

export default adminReports