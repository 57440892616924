/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Row, Col, CardBody, Card, Input, Label, Form, FormFeedback, Spinner, InputGroup, NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import classnames from "classnames"

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// actions
import {
  addNewAdUnit as onADDAdUnit,
  updateAdUnit as onUpdateAdUnit,
  readAdUnit as onReadAdUnit,
  addAdUnitSuccess,
  addAdUnitFail,
  updateAdUnitSuccess,
  updateAdUnitFail,
} from "store/Supply/AdUnit/actions";

import {
  getPartnersList as onGetPartners,
} from "store/PartnerManagement/actions";

import {
  getPublisher as onGetPublishers,
} from "store/Supply/Publisher/actions";

import {
  getAdUnitGroup as onGetAdUnitGroups,
} from "store/Supply/AdUnitGroup/actions";

import {
  getCommonSizes as onGetSizes,
} from "store/CommonEvents/actions";
import Toaster from "pages/Toaster/Toaster";

import {
  getNetworkSetting as onGetNetworkSetting,
} from 'store/Supply/NetworkSettings/actions'

import ToasterWarning from "pages/Toaster/ToasterWarning";

let DataFeedData = {
  deviceID: false,
  ipv4: false,
  ipv6: false
}

var buyerList = []

const Create_AdUnit = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [adUnit, setAdUnit] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [partnerData, setPartnerData] = useState([])
  const [publisherData, setPublisherData] = useState([])
  const [adUnitGroupData, setAdUnitGroupData] = useState([])
  const [sizeData, setSizeData] = useState([])
  const [selectedPublisher, setSelectedPublisher] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(userInfo.userRole === 'Partner' ? { value: userInfo.userID, label: userInfo.name } : null);
  const [selectedAdUnitGroup, setSelectedAdUnitGroup] = useState(null);
  const [selectedDealType, setSelectedDealType] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const nextRef = useRef();
  const [nextTab, setNextTab] = useState(false);
  const [loader, setLoader] = useState(false);
  console.log(props)
  const [AMPState, setAMPState] = useState(false)
  const [HBState, setHBState] = useState(false)
  const [networkState, setNetworkState] = useState(false)
  const [overALLState, setOverALLState] = useState(false)
  const [childAdUnitType, setChildAdUnitType] = useState('')
  const [networkData, setNetworkData] = useState([])
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [dealType, setDealType] = useState('')
  const [dealPricing, setDealPricing] = useState({
    "Type": "revShare",
    "Publisher": 60,
    "Us": 40
  })

  const [preBidServiceState, setPreBidServiceState] = useState(false)
  const [postBidServiceState, setPostBidServiceState] = useState(false)
  const [preBidMeasurementType, setPreBidMeasurementType] = useState('')
  const [postBidMeasurementType, setPostBidMeasurementType] = useState('')
  const [preBidBlockingData, setPreBidBlockingData] = useState([
    { label: 'IP', value: 'ip' }
  ])
  const [selectedPreBidBlockingData, setSelectedPreBidBlockingData] = useState([]);
  const [preBidMeasurementValues, setPreBidMeasurementValues] = useState({
    overAll: '',
    desktop: '',
    mobile: '',
    app: '',
    ctv: ''
  })
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [buyersData, setBuyersData] = useState([])
  const [selectedBuyer, setSelectedBuyer] = useState([]);


  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 2) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const handleSelectPreBidBlockingData = DataFeedBlockingData => {
    setSelectedPreBidBlockingData(DataFeedBlockingData);
    if (DataFeedBlockingData.length !== 3) {
      DataFeedData = { ...DataFeedData, deviceID: false, ipv4: false, ipv6: false }
    }
    DataFeedBlockingData.map((item, index) => {
      DataFeedData = { ...DataFeedData, [item.value]: true }
    })
    console.log(DataFeedBlockingData)
    console.log(DataFeedData)
  }

  const changePreBidMeasurementType = (e) => {
    setPreBidMeasurementType(e.target.value)
    if (e.target.value === 'overAll') {
      setPreBidMeasurementValues(preBidMeasurementValues => ({ ...preBidMeasurementValues, desktop: '', mobile: '', app: '', ctv: '' }));
    } else if (e.target.value === 'deviceWise') {
      setPreBidMeasurementValues(preBidMeasurementValues => ({ ...preBidMeasurementValues, overAll: '' }));
    } else {
      setPreBidMeasurementValues(preBidMeasurementValues => ({ ...preBidMeasurementValues, overAll: '', desktop: '', mobile: '', app: '', ctv: '' }));
    }
  }

  const preBidhandleChange = (e) => {
    const { name, value } = e.target;
    setPreBidMeasurementValues(preBidMeasurementValues => ({ ...preBidMeasurementValues, [name]: value }));
  }

  const [postBidMeasurementValues, setPostBidMeasurementValues] = useState({
    overAll: '',
    desktop: '',
    mobile: '',
    app: '',
    ctv: ''
  })

  const changePostBidMeasurementType = (e) => {
    setPostBidMeasurementType(e.target.value)
    if (e.target.value === 'overAll') {
      setPostBidMeasurementValues(postBidMeasurementValues => ({ ...postBidMeasurementValues, desktop: '', mobile: '', app: '', ctv: '' }));
    } else if (e.target.value === 'deviceWise') {
      setPostBidMeasurementValues(postBidMeasurementValues => ({ ...postBidMeasurementValues, overAll: '' }));
    } else {
      setPostBidMeasurementValues(postBidMeasurementValues => ({ ...postBidMeasurementValues, overAll: '', desktop: '', mobile: '', app: '', ctv: '' }));
    }
  }

  const postBidhandleChange = (e) => {
    const { name, value } = e.target;
    setPostBidMeasurementValues(postBidMeasurementValues => ({ ...postBidMeasurementValues, [name]: value }));
  }

  useEffect(() => {
    if (props.selectedAdUnit !== '') {
      if (props.selectedAdUnit.isHBEnabled) {
        setHBState(props.selectedAdUnit.isHBEnabled)
      }

      // if (JSON.stringify(props.selectedAdUnit.postBidServiceData) !== "{}") {
      //   setPostBidMeasurementType(props.selectedAdUnit.postBidServiceData.measurementType)
      //   if (props.selectedAdUnit.postBidServiceData.measurementType === 'deviceWise') {
      //     setPostBidMeasurementValues(postBidMeasurementValues => ({
      //       ...postBidMeasurementValues, desktop: props.selectedAdUnit.postBidServiceData.desktop ? props.selectedAdUnit.postBidServiceData.desktop : '',
      //       mobile: props.selectedAdUnit.postBidServiceData.phone ? props.selectedAdUnit.postBidServiceData.phone : '',
      //       app: props.selectedAdUnit.postBidServiceData.app ? props.selectedAdUnit.postBidServiceData.app : '',
      //       ctv: props.selectedAdUnit.postBidServiceData.tv ? props.selectedAdUnit.postBidServiceData.tv : ''
      //     }));
      //   }
      //   if (props.selectedAdUnit.postBidServiceData.measurementType === 'overAll') {
      //     setPostBidMeasurementValues(postBidMeasurementValues => ({ ...postBidMeasurementValues, overAll: props.selectedAdUnit.postBidServiceData.overAll ? props.selectedAdUnit.postBidServiceData.overAll : '' }));
      //   }
      // }

      // if (JSON.stringify(props.selectedAdUnit.preBidServiceData) !== "{}") {
      //   let selectData = []
      //   if (props.selectedAdUnit.preBidServiceData.deviceID) { selectData.push({ label: 'deviceID', value: 'deviceID' }) }
      //   if (props.selectedAdUnit.preBidServiceData.ipv4) { selectData.push({ label: 'IPV4', value: 'ipv4' }) }
      //   if (props.selectedAdUnit.preBidServiceData.ipv6) { selectData.push({ label: 'IPV6', value: 'ipv6' }) }
      //   DataFeedData = { ...DataFeedData, deviceID: props.selectedAdUnit.preBidServiceData.deviceID, ipv4: props.selectedAdUnit.preBidServiceData.ipv4, ipv6: props.selectedAdUnit.preBidServiceData.ipv6 }
      //   setSelectedPreBidBlockingData(selectData)
      // }

    }
  }, []);

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const body = {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name: userInfo.name,
      filter: "Active"
  }

  var dealTypeOptions = [
    { value: 'revShare', label: 'RevShare' },
    { value: 'CPM', label: 'CPM' },
    { value: 'fixedCost', label: 'Fixed Cost' },
  ]


  useEffect(() => {
    dispatch(onGetPartners(null));
    dispatch(onGetPublishers(null));
    dispatch(onGetAdUnitGroups(null));
  }, [])

  useEffect(() => {
    axios.post("/v2.0/headderBidding/HB_Buyers/read", body,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is headerBids ==>", res)
        if (res.data.data) {
          setBuyersData(objectModalBuyers(res.data.data))
        }
      })
      .catch((err) => {
        console.log("e", err)
      });

  }, []);

  const objectModalBuyers = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.hB_BuyerID
      obj.label = item.hB_BuyerName
      dataList.push(obj)
    })
    return dataList
  }

  useEffect(() => {
    if (props.selectedAdUnit !== '') {
      setIsEdit(true)
      setAdUnit(props.selectedAdUnit)
      setAMPState(props.selectedAdUnit.adUnitAMP ? true : false)
      setPreBidServiceState(props.selectedAdUnit.isPreBidServiceEnabled ? true : false)
      setPostBidServiceState(props.selectedAdUnit.isPostBidServiceEnabled ? true : false)
      setNetworkState(props.selectedAdUnit.isAntiMalicious ? true : false)
      setSelectedDealType({ value: props.selectedAdUnit.adUnitDealType, label: props.selectedAdUnit.adUnitDealType });
      setDealType(props.selectedAdUnit.adUnitDealType)
      setSelectedSize({ value: props.selectedAdUnit.adUnitSize, label: props.selectedAdUnit.adUnitSize });
      if (props.selectedAdUnit.networkSettingID && props.selectedAdUnit.networkSettingID !== 0) {
        setSelectedNetwork({ value: props.selectedAdUnit.networkSettingID, label: props.selectedAdUnit.networkSettingName });
      }
      getParentAdUnit()
    }
    if (props.selectedAdUnitGroup !== '' || props.selectedAdUnit === '') {
      getParentAdUnit()
    }
  }, []);

  const getParentAdUnit = (publisherId, adUnitGroupId) => {
    var filterBody = {
        "partnerID": 0,
        "publisherID": 0,
        "adUnitGroupID": 0,
        "adUnitID": 0,
        "childAdUnitID": 0,
        "playerSettingID": 0,
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name:userInfo.name,parentID:userInfo.parentID
        
    }
    if (props.selectedAdUnit !== '') {
      filterBody.adUnitGroupID = props.selectedAdUnit.adUnitGroupID
    }
    if (props.selectedAdUnitGroup !== '') {
      filterBody.adUnitGroupID = props.selectedAdUnitGroup.adUnitGroupId
    }
    if (publisherId) {
      filterBody.publisherID = publisherId
    }
    if (adUnitGroupId) {
      filterBody.adUnitGroupID = adUnitGroupId
    }
    axios.post("/v2.0/supply/getParent", filterBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("cdsresult is ==>", res)
        if (res.data) {
          setSelectedPublisher({ value: res.data.publisherID, label: res.data.publisherName });
          setSelectedPartner({ value: res.data.partnerID, label: res.data.username });
          if (!publisherId) {
            setSelectedAdUnitGroup({ value: res.data.adUnitGroupID, label: res.data.adUnitGroupName });
          }
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      adUnitName: (adUnit && adUnit.adUnitName) || '',
      partnerID: (selectedPartner ? selectedPartner.value : '') || '',
      publisherID: (selectedPublisher ? selectedPublisher.value : '') || '',
      adUnitGroupID: (selectedAdUnitGroup ? selectedAdUnitGroup.value : '') || '',
      // adUnitPricing: (adUnit && parseFloat(adUnit.adUnitPricing)) || '0',
      adUnitAMP: (AMPState) || false,
      networkSettingID: (selectedNetwork ? selectedNetwork.value : 0) || 0,
      isAntiMalicious: (networkState) || false,
      adUnitDealType: (selectedDealType ? selectedDealType.value : '') || '',
      revShareValue: (adUnit && adUnit.revShareValue) || '0',
      CPMRate: (adUnit && adUnit.CPMRate) || '0',
      fixedCostValue: (adUnit && adUnit.fixedCostValue) || '0',
      fixedCostPeriod: (adUnit && adUnit.fixedCostPeriod) || 'day',
      adUnitInventoryVerification: (adUnit && adUnit.adUnitInventoryVerification ? adUnit.adUnitInventoryVerification : '') || '',
      adUnitType: props.selectedAdUnitType || props.selectedAdUnit.adUnitType,
      adUnitSize: (selectedSize ? selectedSize.value : '') || '',
      playerSettings: '',
      isPreBidServiceEnabled: (preBidServiceState) || false,
      isPostBidServiceEnabled: (postBidServiceState) || false,
      preBidService: (adUnit && adUnit.preBidService ? adUnit.preBidService : '') || '',
      postBidService: (adUnit && adUnit.postBidService ? adUnit.postBidService : '') || '',
      postBidServiceData: {},
      adUnitInventoryMeasurement: {},
      isHBEnabled: (HBState) || false,
      hB_ProductName: (adUnit && adUnit.hB_ProductName) || '',
      AdUnitHBID: (buyerList) || [],
    },
    validationSchema: Yup.object({
      adUnitName: Yup.string().required("AdUnit Name is required"),
      partnerID: Yup.string().required("Partner is required"),
      adUnitGroupID: Yup.string().required("Ad Unit Group  is required"),
      adUnitSize: Yup.string().required("Ad Unit Size  is required"),
      publisherID: Yup.string().required("Publisher is required"),
    }),
    onSubmit: (values) => {
      console.log(values)
      setLoader(true)
      setChildAdUnitType(values.adUnitType)
      let postBidMeasurements = {}
      if (postBidServiceState) {
        if (postBidMeasurementType === 'overAll') {
          postBidMeasurements = {
            measurementType: postBidMeasurementType,
            overAll: 0
          }
          postBidMeasurements = { ...postBidMeasurements, overAll: postBidMeasurementValues.overAll ? Number(postBidMeasurementValues.overAll) : 0 }
        }
        if (postBidMeasurementType === 'deviceWise') {
          postBidMeasurements = {
            measurementType: postBidMeasurementType,
            desktop: 0,
            phone: 0,
            app: 0,
            tv: 0
          }
          if (postBidMeasurementValues.desktop) {
            postBidMeasurements = { ...postBidMeasurements, desktop: Number(postBidMeasurementValues.desktop) }
          }
          if (postBidMeasurementValues.mobile) {
            postBidMeasurements = { ...postBidMeasurements, phone: Number(postBidMeasurementValues.mobile) }
          }
          if (postBidMeasurementValues.app) {
            postBidMeasurements = { ...postBidMeasurements, app: Number(postBidMeasurementValues.app) }
          }
          if (postBidMeasurementValues.ctv) {
            postBidMeasurements = { ...postBidMeasurements, tv: Number(postBidMeasurementValues.ctv) }
          }
        }
      }
      console.log(postBidMeasurements, DataFeedData)
      if (props.selectedAdUnit !== '') {
        dispatch(onUpdateAdUnit({ ...values, adUnitID: props.selectedAdUnit.adUnitID, oldPlayerSettings: props.selectedAdUnit.playListIDs, adUnitName: values.adUnitName.trim(), preBidServiceData: DataFeedData, postBidServiceData: postBidMeasurements, OldAdUnitHBID: props.selectedAdUnit.AdUnitHBID ? props.selectedAdUnit.AdUnitHBID : [], userRole: userInfo.userRole, userID: userInfo.userID,parentID:userInfo.parentID }));
      } else {
        dispatch(onADDAdUnit({ ...values, adUnitName: values.adUnitName.trim(), preBidServiceData: DataFeedData, postBidServiceData: postBidMeasurements, userRole: userInfo.userRole, userID: userInfo.userID,parentID:userInfo.parentID }));
      }
    }
  });

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.adUnits.errorMsg,
    successMsg: state.adUnits.successMsg,
  }));


  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.statusCode === 200) {
        dispatch(addAdUnitSuccess(""));
        dispatch(addAdUnitFail(""));
        dispatch(updateAdUnitSuccess(""));
        dispatch(updateAdUnitFail(""));
        setLoader(false)
        if (nextTab && (validation.values.adUnitType === 'highImpact' || validation.values.adUnitType === 'play')) {
          props.closeCanvas('')
          nextRef.current.click()
        } else {
          if (props.selectedAdUnit !== '') {
            props.closeCanvas('Update')
          } else {
            props.closeCanvas('Create')
          }
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if (errorMsg.status && errorMsg.status === 500) {
      } else {
        dispatch(addAdUnitSuccess(""));
        dispatch(addAdUnitFail(""));
        dispatch(updateAdUnitSuccess(""));
        dispatch(updateAdUnitFail(""));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addAdUnitSuccess(""));
    dispatch(addAdUnitFail(""));
    dispatch(updateAdUnitSuccess(""));
    dispatch(updateAdUnitFail(""));
    props.closeCanvas('')
  };

  const { partners, publishers, adUnitGroups, sizes } = useSelector(state => ({
    partners: state.partners.partnerList,
    publishers: state.publishers.publisherList,
    adUnitGroups: state.adUnitGroups.adUnitGroupsList,
    sizes: state.commonEvents.sizeList

  }));

  const { NetworkSettings } = useSelector(state => ({
    NetworkSettings: state.NetworkSettings.NetworkSettingList,
  }));


  useEffect(() => {
    if (publishers !== null && adUnitGroups !== null && sizes !== null) {
      dispatch(onGetPublishers({ partnerID: '', filter: 'Active', type: (validation.values.adUnitType === 'play' || validation.values.adUnitType === 'vast3.0' || validation.values.adUnitType === 'vpaid2.0') ? 'video' : validation.values.adUnitType, userRole: userInfo.userRole, userID: userInfo.userID,parentID:userInfo.parentID,pageSize: 0, pageNumber: 0, searchText: '' }));
      dispatch(onGetAdUnitGroups({ publisherID: '', filter: 'Active', adUnitGroupType: (validation.values.adUnitType === 'play' || validation.values.adUnitType === 'vast3.0' || validation.values.adUnitType === 'vpaid2.0') ? 'video' : validation.values.adUnitType, userRole: userInfo.userRole, userID: userInfo.userID ,parentID:userInfo.parentID,pageSize: 0, pageNumber: 0, searchText: ''}));
      dispatch(onGetSizes());
    }
  }, []);

  useEffect(() => {
    if (NetworkSettings !== null) {
      dispatch(onGetNetworkSetting({ filter: 'Active' }));
    }
  }, []);

  useEffect(() => {
    if (NetworkSettings.length > 0) {
      setNetworkData(objectNetworkModal(NetworkSettings))
    }
  }, [NetworkSettings]);

  useEffect(() => {
    if (partners !== null) {
      dispatch(onGetPartners({ type: (validation.values.adUnitType === 'play' || validation.values.adUnitType === 'vast3.0' || validation.values.adUnitType === 'vpaid2.0') ? 'video' : validation.values.adUnitType }));
    }
  }, []);

  useEffect(() => {
    if (partners.length > 0) {
      setPartnerData(objectModal(partners))
      console.log(partners)
    }
  }, [partners]);

  useEffect(() => {
    if (publishers.length > 0) {
      setPublisherData(objectPublisherModal(publishers))
      console.log(publishers)
    }
  }, [publishers]);

  useEffect(() => {
    if (adUnitGroups.length > 0) {
      setAdUnitGroupData(objectAdUnitGroupModal(adUnitGroups))
      console.log(adUnitGroups)
    }
  }, [adUnitGroups]);

  useEffect(() => {
    if (sizes && sizes.length > 0) {
      if (props.selectedAdUnitType === 'play' || props.selectedAdUnitType === 'highImpact') {
        setSizeData([{ value: '1x1', label: '1x1' }])
        setSelectedSize({ value: '1x1', label: '1x1' })
      } else {
        let sizess = objectSizesModal(sizes)
        setSizeData([...sizess, ...[{ value: '800x485', label: '800x485' }, { value: '320x442', label: '320x442' }, { value: '320x480', label: '320x480' }, { value: '360x480', label: '360x480' }, { value: '640x480', label: '640x480' }]])
      }
      console.log(sizes)
    }
  }, [sizes]);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.userID
      obj.label = item.userName
      dataList.push(obj)
    })
    return dataList
  }

  const objectPublisherModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.publisherID
      obj.label = item.publisherName
      dataList.push(obj)
    })
    return dataList
  }

  const objectAdUnitGroupModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.adUnitGroupID
      obj.label = item.adUnitGroupName
      dataList.push(obj)
    })
    return dataList
  }

  const objectSizesModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }

  const objectNetworkModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.networkSettingID
      obj.label = item.networkSettingName
      dataList.push(obj)
    })
    return dataList
  }

  function handleSelectPartner(selectPartner) {
    setAdUnit(validation.values)
    setSelectedPartner(selectPartner);
    dispatch(onGetPublishers({ partnerID: selectPartner.value, filter: 'Active', type: (validation.values.adUnitType === 'play' || validation.values.adUnitType === 'vast3.0' || validation.values.adUnitType === 'vpaid2.0') ? 'video' : validation.values.adUnitType, userRole: userInfo.userRole, userID: userInfo.userID,parentID:userInfo.parentID,pageSize: 0, pageNumber: 0, searchText: '' }));
    setSelectedPublisher(null)
  }

  function handleSelectPublisher(selectPublisher) {
    getParentAdUnit(selectPublisher.value, '')
    setAdUnit(validation.values)
    setSelectedPublisher(selectPublisher);
    dispatch(onGetAdUnitGroups({ publisherID: selectPublisher.value, filter: 'Active', adUnitGroupType: (validation.values.adUnitType === 'play' || validation.values.adUnitType === 'vast3.0' || validation.values.adUnitType === 'vpaid2.0') ? 'video' : validation.values.adUnitType, userRole: userInfo.userRole, userID: userInfo.userID,parentID:userInfo.parentID,pageSize: 0, pageNumber: 0, searchText: '' }));
    setSelectedAdUnitGroup(null)
  }

  function handleSelectAdUnitGroup(selectAdUnitGroup) {
    getParentAdUnit('', selectAdUnitGroup.value)
    setAdUnit(validation.values)
    setSelectedAdUnitGroup(selectAdUnitGroup);
  }

  function handleSelectDealType(selectDealType) {
    setAdUnit(validation.values)
    setSelectedDealType(selectDealType);
    setDealType(selectDealType.value)
    setDealPricing(dealPricing => ({ ...dealPricing, Type: selectDealType.value }));
  }

  function handleNetowrkSelection(Network) {
    setAdUnit(validation.values)
    setSelectedNetwork(Network);
  }

  function handleSelectSize(selectSize) {
    setAdUnit(validation.values)
    setSelectedSize(selectSize);
  }

  function allowOnlyNumbers(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code >= 48 && code < 58) && !(code >= 46 && code < 47)) { e.preventDefault(); }
  };

  function allowAlphaNumericSpace(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault(); }
  };

  const AMPStateChange = e => {
    setAdUnit(validation.values)
    setAMPState(e.target.checked)
  }

  const HBStateChange = e => {
    setAdUnit(validation.values)
    setHBState(e.target.checked)
  }

  const PreBidStateChange = e => {
    setAdUnit(validation.values)
    setPreBidServiceState(e.target.checked)
  }
  const PostBidStateChange = e => {
    setAdUnit(validation.values)
    setPostBidServiceState(e.target.checked)
  }

  const NetworkStateChange = e => {
    setAdUnit(validation.values)
    setNetworkState(e.target.checked)
  }

  const [controls, setControls] = useState({
    device: false,
    mobile: false,
    app: false,
    ctv: false,
  })

  const handleCheck = (event) => {
    setOverALLState(false)
    setControls({
      ...controls,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSelectBuyer = buyer => {
    buyerList = []
    setSelectedBuyer(buyer);
    buyer.map((item, index) => {
      buyerList.push(item.value)
    })
    setAdUnit(validation.values)
  }

  return (
    <React.Fragment>
      {loader &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>

        <Form onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); return false; }}>
          <Row style={{ padding: '3%', height: '85vh', overflow: 'auto' }}>
            <Col xs={12}>

              <Row >
                <Col lg="12" className="p-0">
                      <div className=" wizard clearfix vertical">
                        <div className="steps clearfix">
                          <ul>
                            <NavItem
                              className={classnames({ current: activeTab === 1 })}
                            >
                              <NavLink
                                className={classnames({ current: activeTab === 1 })}
                                onClick={() => {
                                  setactiveTab(1)
                                }}
                              // disabled={!(passedSteps || []).includes(1)}
                              >
                                <span className="number">01.</span>General Settings
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({ current: activeTab === 2 })}
                            >
                              <NavLink
                                className={classnames({ active: activeTab === 2 })}
                                onClick={() => {
                                  setactiveTab(2)
                                }}
                              // disabled={!(passedSteps || []).includes(2)}
                              >
                                <span className="number ">02.</span> Network and IV Settings
                              </NavLink>
                            </NavItem>
                          </ul>
                        </div>
                        <div className="content clearfix ">
                          <TabContent activeTab={activeTab} className="body">
                            <TabPane tabId={1}>
                              <div className="col-12">
                                {userInfo.userRole !== 'Partner' &&
                                  <div className="mb-3">
                                    <Label>Partner <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                    <Select
                                      value={selectedPartner}
                                      onChange={s => {
                                        handleSelectPartner(s)
                                      }}
                                      options={partnerData}
                                      invalid={
                                        validation.touched.partnerID && validation.errors.partnerID ? true : false
                                      }
                                      classNamePrefix="select2-selection"
                                      isDisabled={props.selectedArchived || isEdit}
                                      className={(validation.touched.partnerID && validation.errors.partnerID ? ' is-invalid' : '')}
                                    />
                                    {validation.touched.partnerID && validation.errors.partnerID ? (
                                      <FormFeedback type="invalid">{validation.errors.partnerID}</FormFeedback>
                                    ) : null}

                                  </div>
                                }
                                <div className="mb-3">
                                  <Label>Publisher <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                  <Select
                                    value={selectedPublisher}
                                    onChange={s => {
                                      handleSelectPublisher(s)
                                    }}
                                    options={publisherData}
                                    invalid={
                                      validation.touched.publisherID && validation.errors.publisherID ? true : false
                                    }
                                    classNamePrefix="select2-selection"
                                    isDisabled={props.selectedArchived || isEdit}
                                    className={(validation.touched.publisherID && validation.errors.publisherID ? ' is-invalid' : '')}
                                  />
                                  {validation.touched.publisherID && validation.errors.publisherID ? (
                                    <FormFeedback type="invalid">{validation.errors.publisherID}</FormFeedback>
                                  ) : null}

                                </div>
                                <div className="mb-3">
                                  <Label>Ad Unit Group <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                  <Select
                                    value={selectedAdUnitGroup}
                                    onChange={s => {
                                      handleSelectAdUnitGroup(s)
                                    }}
                                    options={adUnitGroupData}
                                    invalid={
                                      validation.touched.adUnitGroupID && validation.errors.adUnitGroupID ? true : false
                                    }
                                    classNamePrefix="select2-selection"
                                    className={(validation.touched.adUnitGroupID && validation.errors.adUnitGroupID ? ' is-invalid' : '')}
                                    isDisabled={props.selectedArchived || isEdit}
                                  />
                                  {validation.touched.adUnitGroupID && validation.errors.adUnitGroupID ? (
                                    <FormFeedback type="invalid">{validation.errors.adUnitGroupID}</FormFeedback>
                                  ) : null}

                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Ad Unit Name <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                  <Input
                                    name="adUnitName"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    onKeyPress={allowAlphaNumericSpace}
                                    maxLength={110}
                                    value={validation.values.adUnitName || ""}

                                    invalid={
                                      validation.touched.adUnitName && validation.errors.adUnitName ? true : false
                                    }
                                    readOnly={props.selectedArchived}
                                  />
                                  {validation.touched.adUnitName && validation.errors.adUnitName ? (
                                    <FormFeedback type="invalid">{validation.errors.adUnitName}</FormFeedback>
                                  ) : null}
                                </div>
                              </div>

                              <div className="">
                                <div className="row">
                                  <div className="col-12">
                                    <Label >AMP : </Label>
                                    <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customSwitchsizemd30"
                                        checked={AMPState}
                                        onChange={AMPStateChange}
                                        disabled={props.selectedArchived}
                                      />
                                      <label className="form-check-label" htmlFor="customSwitchsizemd30">
                                        {AMPState ? <span color='text-success' style={{ color: '#34c38f' }}>Enabled </span> : <span style={{ color: '#a3a3a3' }}>Disabled</span>}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    {(validation.values.adUnitType === 'highImpact' || validation.values.adUnitType === 'stdb') &&
                                      <div className="mb-3">
                                        <Label >Malicious State : </Label>
                                        <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  " >
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="customSwitchsizemd31"
                                            checked={networkState}
                                            onChange={NetworkStateChange}
                                            disabled={props.selectedArchived}
                                          />
                                          <label className="form-check-label" htmlFor="customSwitchsizemd31">
                                            {networkState ? <span color='text-success' style={{ color: '#34c38f' }}>Anti Malicious is Activated </span> : <span style={{ color: '#a3a3a3' }}>Anti Malicious is Deactivated </span>}
                                          </label>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>

                              </div>
                              <div className="">
                                <div className="row">
                                  <div className="col-12">
                                    <Label >Header Bidding : </Label>
                                    <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2 " >
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customSwitchsizemd32"
                                        checked={HBState}
                                        onChange={HBStateChange}
                                        disabled={props.selectedArchived}
                                      />
                                      <label className="form-check-label" htmlFor="customSwitchsizemd32">
                                        {HBState ? <span color='text-success' style={{ color: '#34c38f' }}>Enabled </span> : <span style={{ color: '#a3a3a3' }}>Disabled</span>}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12">
                                {/* <div className="mb-3">
                                  <Label className="form-label">HB Product Name</Label>
                                  <Input
                                    name="hB_ProductName"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.hB_ProductName || ""}
                                    readOnly={props.selectedArchived}
                                  />
                                </div> */}
                                {/* <div className="mb-3">
                                  <Label className="form-label">Associate Buyers</Label>
                                  <MultiSelect
                                    options={buyersData}
                                    value={selectedBuyer}
                                    onChange={handleSelectBuyer}
                                    overrideStrings={{ "selectSomeItems": "Please Select Buyers" }}
                                    disabled={props.selectedArchived}
                                  />
                                </div> */}
                                <div className="mb-3">
                                  <Label>Ad Unit Deal Type</Label>
                                  <Select
                                    value={selectedDealType}
                                    onChange={s => {
                                      handleSelectDealType(s)
                                    }}
                                    options={dealTypeOptions}
                                    classNamePrefix="select2-selection"
                                    isDisabled={props.selectedArchived}
                                  />
                                </div>
                                <div className="mb-3">
                                  {dealType === 'revShare' &&
                                    <InputGroup>
                                      <div className="input-group-text">%</div>
                                      <Input
                                        name="revShareValue" onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.revShareValue}
                                        type="text" onKeyPress={allowOnlyNumbers}
                                        readOnly={props.selectedArchived}
                                      />
                                    </InputGroup>
                                  } {dealType === 'CPM' &&
                                    <InputGroup>
                                      <div className="input-group-text">$</div>
                                      <Input
                                        name="CPMRate" onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.CPMRate}
                                        type="text" onKeyPress={allowOnlyNumbers}
                                        readOnly={props.selectedArchived}
                                      />
                                    </InputGroup>}{dealType === 'fixedCost' &&
                                      <div className='row'>
                                        <div className='col-8'>
                                          <Label >Fixed Cost </Label>
                                          <InputGroup>
                                            <div className="input-group-text">$</div>
                                            <Input
                                              name="fixedCostValue" onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.fixedCostValue}
                                              type="text" onKeyPress={allowOnlyNumbers}
                                              readOnly={props.selectedArchived}
                                            />
                                          </InputGroup>
                                        </div>
                                        <div className='col-4'>
                                          <Label >Per</Label>
                                          <Input type="select" className="form-select" name="fixedCostPeriod" onChange={validation.handleChange} readOnly={props.selectedArchived}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.fixedCostPeriod}>
                                            <option value='day'>  Day</option>
                                            <option value='month'> Month</option>
                                          </Input>
                                        </div>
                                      </div>
                                  }


                                </div>
                                <div className="mb-3">
                                  <Label>Ad Unit Size <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                                  <Select
                                    value={selectedSize}
                                    onChange={s => {
                                      handleSelectSize(s)
                                    }}
                                    options={sizeData}
                                    invalid={
                                      validation.touched.adUnitSize && validation.errors.adUnitSize ? true : false
                                    }
                                    classNamePrefix="select2-selection"
                                    isDisabled={props.selectedArchived}
                                    className={(validation.touched.adUnitSize && validation.errors.adUnitSize ? ' is-invalid' : '')}
                                  />
                                  {validation.touched.adUnitSize && validation.errors.adUnitSize ? (
                                    <FormFeedback type="invalid">{validation.errors.adUnitSize}</FormFeedback>
                                  ) : null}

                                </div>
                              </div>
                            </TabPane>
                            <TabPane tabId={2}>
                              <div className="col-12">
                                <div className="mb-3">
                                  <Label>Attach Network Settings</Label>
                                  <Select
                                    value={selectedNetwork}
                                    onChange={s => {
                                      handleNetowrkSelection(s)
                                    }}
                                    options={networkData}
                                    isClearable={true}
                                    classNamePrefix="select2-selection"
                                    isDisabled={props.selectedArchived}
                                  />
                                </div>

                                <div className="mb-3">
                                  <Label className="form-label">Inventory Verification</Label>
                                  <div className="mb-3">
                                    <Label >Prebid : </Label>
                                    <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customSwitchsizemd33"
                                        checked={preBidServiceState}
                                        onChange={PreBidStateChange}
                                        disabled={props.selectedArchived}
                                      />
                                      <label className="form-check-label" htmlFor="customSwitchsizemd33">
                                        {preBidServiceState ? <span color='text-success' style={{ color: '#34c38f' }}>Enabled </span> : <span style={{ color: '#a3a3a3' }}>Disabled</span>}
                                      </label>
                                    </div>
                                  </div>

                                  <div className="mb-3">
                                    <div className="mb-3">
                                      <Label className="form-label">Prebid Inventory Verification Vendor</Label>
                                      <Input
                                        type="select"
                                        className="form-select"
                                        name="preBidService"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.preBidService || ""}
                                        readOnly={props.selectedArchived}
                                        disabled={!preBidServiceState}
                                      >

                                        <option value="" >
                                          Select...
                                        </option>
                                        <option value="pixalate">Pixalate</option>
                                        <option value="fraudlogix">Fraudlogix</option>
                                      </Input>
                                    </div>
                                    <div className="mb-3">
                                      <Label className="form-label">Data Feed</Label>
                                      <MultiSelect
                                        options={preBidBlockingData}
                                        value={selectedPreBidBlockingData}
                                        onChange={handleSelectPreBidBlockingData}
                                        overrideStrings={{ "selectSomeItems": "Please Select Data Feed" }}
                                        disabled={props.selectedArchived || !validation.values.preBidService}
                                      />
                                    </div>
                                  </div>

                                  <div className="mb-3">
                                    <Label >Postbid : </Label>
                                    <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customSwitchsizemd34"
                                        checked={postBidServiceState}
                                        onChange={PostBidStateChange}
                                        disabled={props.selectedArchived}
                                      />
                                      <label className="form-check-label" htmlFor="customSwitchsizemd34">
                                        {postBidServiceState ? <span color='text-success' style={{ color: '#34c38f' }}>Enabled </span> : <span style={{ color: '#a3a3a3' }}>Disabled</span>}
                                      </label>
                                    </div>
                                  </div>
                                  <Label className="form-label">Postbid Inventory Verification Vendor</Label>
                                  <Input
                                    type="select"
                                    className="form-select"
                                    name="postBidService"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.postBidService || ""}
                                    readOnly={props.selectedArchived}
                                    disabled={!postBidServiceState}
                                  >

                                    <option value="" >
                                      Select...
                                    </option>
                                    <option value="pixalate">Pixalate</option>
                                    <option value="fraudlogix">Fraudlogix</option>
                                  </Input>
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">Postbid Measurement</Label>
                                  <Input
                                    type="select"
                                    className="form-select"
                                    name="postBidMeasurementType"
                                    onChange={changePostBidMeasurementType}
                                    value={postBidMeasurementType}
                                    disabled={!validation.values.postBidService}
                                  >

                                    <option value=""  >
                                      Select...
                                    </option>
                                    <option value="overAll">Over All Measurement</option>
                                    <option value="deviceWise">Device wise Measurement </option>
                                  </Input>
                                </div>
                                <div className="mb-3">
                                  {postBidMeasurementType === "overAll" &&
                                    <div className="row mb-3 m-1">
                                      <div className="col-5"> <Label className="mt-2">Over ALL Measurement </Label></div>
                                      <div className="col-7">
                                        <InputGroup>
                                          <div className="input-group-text">%</div>
                                          <Input
                                            name="overAll"
                                            type="text" onKeyPress={allowOnlyNumbers}
                                            onChange={postBidhandleChange}
                                            value={postBidMeasurementValues.overAll}
                                          />
                                        </InputGroup>
                                      </div>
                                    </div>
                                  }

                                  {postBidMeasurementType === "deviceWise" &&
                                    <div>
                                      <div className="row mb-3 m-1">
                                        <div className="col-5" > <Label className="mt-2">Desktop </Label></div>
                                        <div className="col-7">
                                          <InputGroup>
                                            <div className="input-group-text">%</div>
                                            <Input
                                              name="desktop"
                                              type="text" onKeyPress={allowOnlyNumbers}
                                              onChange={postBidhandleChange}
                                              value={postBidMeasurementValues.desktop}
                                            />
                                          </InputGroup>
                                        </div>
                                      </div>

                                      <div className="row mb-3 m-1">
                                        <div className="col-5"> <Label className="mt-2">Mobile </Label></div>
                                        <div className="col-7">
                                          <InputGroup>
                                            <div className="input-group-text">%</div>
                                            <Input
                                              name="mobile"
                                              type="text" onKeyPress={allowOnlyNumbers}
                                              onChange={postBidhandleChange}
                                              value={postBidMeasurementValues.mobile}
                                            />
                                          </InputGroup>
                                        </div>
                                      </div>

                                      <div className="row mb-3 m-1">
                                        <div className="col-5"> <Label className="mt-2">App </Label></div>
                                        <div className="col-7">
                                          <InputGroup>
                                            <div className="input-group-text">%</div>
                                            <Input
                                              name="app"
                                              type="text" onKeyPress={allowOnlyNumbers}
                                              onChange={postBidhandleChange}
                                              value={postBidMeasurementValues.app}
                                            />
                                          </InputGroup>
                                        </div>
                                      </div>

                                      <div className="row mb-3 m-1">
                                        <div className="col-5"> <Label className="ml-2 mt-2">CTV </Label></div>
                                        <div className="col-7">
                                          <InputGroup>
                                            <div className="input-group-text">%</div>
                                            <Input
                                              name="ctv"
                                              type="text" onKeyPress={allowOnlyNumbers}
                                              onChange={postBidhandleChange}
                                              value={postBidMeasurementValues.ctv}
                                            />
                                          </InputGroup>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                </div>
                              </div>


                            </TabPane>
                          </TabContent>
                        </div>
                        <div className="actions clearfix">
                          <ul>
                            <li
                              className={
                                activeTab === 1 ? "previous disabled" : "previous"
                              }
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTab(activeTab - 1)
                                }}
                              >
                                Previous
                              </Link>
                            </li>
                            <li
                              className={activeTab === 2 ? "next disabled" : "next"}
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  toggleTab(activeTab + 1)
                                }}
                              >
                                Next
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                </Col>
              </Row>

            </Col>
          </Row>
          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
                &nbsp;&nbsp;
                {isEdit && !props.selectedArchived &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }
                &nbsp;&nbsp;
                {!isEdit && (validation.values.adUnitType === 'highImpact') &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                    onClick={() => setNextTab(true)}
                  >
                    Save & Add Child Ad Unit
                  </button>
                }
                {nextTab && successMsg &&
                  <Link ref={nextRef} to={`/ChildAdUnit?adUnitId=${successMsg.response.adUnitID}&adUnitName=${successMsg.response.adUnitName}&childAdUnitType=${childAdUnitType}&status=ChildAdUnitCreateByAdUnit`}  > </Link>
                }
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
          <Toaster status="error" msg={errorMsg.message} />
        }
      </div>
      {errorMsg !== null && errorMsg !== '' && errorMsg.status && errorMsg.status === 500 &&
        <div className="p-0 justify-content-center d-flex">
          <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')} />
        </div>
      }
    </React.Fragment>
  );
};

export default withRouter(Create_AdUnit);

Create_AdUnit.propTypes = {
  history: PropTypes.object,
};