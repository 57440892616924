/* eslint-disable react/prop-types */
import React from "react"
import PropTypes from 'prop-types';
import ReactApexChart from "react-apexcharts"

const Publisher_StackChart = props => {

  const options = {
    chart: {
      height: 550,
      type: 'line',
      stacked: false,
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [1, 1, 4],
      curve: 'smooth'
    },
    title: {
      text: props.title,
      align: 'left',
      offsetX: 110
    },
    noData: {
      text: 'No Data Available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: 'gray',
        fontSize: '25px',
        fontFamily: undefined,
        fontWeight: 'bold'
      }
    },
    xaxis: {
      categories: props.xAxisData,
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#556EE6'
        },
        labels: {
          style: {
            colors: '#556EE6',
          }
        },
        title: {
          text: "Total Code Served",
          style: {
            color: '#556EE6',
          }
        },
        tooltip: {
          enabled: true
        }
      },
      {
        seriesName: 'Estimated Revenue',
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#F1B44C'
        },
        labels: {
          style: {
            colors: '#F1B44C',
          }
        },
        title: {
          text: "Estimated Revenue",
          style: {
            color: '#F1B44C',
          }
        },
      },
      {
        seriesName: 'Estimated eCPM',
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#34C38F'
        },
        labels: {
          style: {
            colors: '#34C38F',
          },
        },
        title: {
          text: "Estimated eCPM",
          style: {
            color: '#34C38F',
          }
        }
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60
      },
    },
    colors: ["#556EE6", "#34c38f", "#F1B44C",],
    legend: {
      horizontalAlign: 'center',
      position: 'bottom',
      offsetX: 40
    }

  }
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...props.chartData]}
        type="line"
        height="550"
      />
    </React.Fragment>
  );
}

Publisher_StackChart.propTypes = {}

export default Publisher_StackChart