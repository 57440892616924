import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_NETWORK_SETTINGS,ADD_NETWORK_SETTING , UPDATE_NETWORK_SETTING,READ_NETWORK_SETTING,STATUS_NETWORK_SETTING,ARCHIVE_NETWORK_SETTING} from "./actionTypes"

import {
  loadActivitiesNetworkSetting,
  noDataNetworkSetting,
  getNetworkSettingsSuccess,
  getNetworkSettingsFail,
  addNetworkSettingSuccess,
  addNetworkSettingFail,
  updateNetworkSettingSuccess,
  updateNetworkSettingFail,
  readNetworkSettingSuccess,
  statusNetworkSettingSuccess,
  archiveNetworkSettingSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getNetworkSetting,
  getNetworkSettingsByAdUnit,createNetworkSetting,updateNetworkSetting,readNetworkSetting,statusChangeNetworkSetting,archiveNetworkSetting } from "../../../helpers/Backend_helper"

function* fetchNetworkSettings({ payload: NetworkSetting }) {
  console.log(NetworkSetting)
  try {
    yield put(loadActivitiesNetworkSetting(true))
    var getNetworkSettingList
  
     if(NetworkSetting){
      getNetworkSettingList = yield call(getNetworkSetting,NetworkSetting)
      getNetworkSettingList.response.map((item, index) => {
        item.id = index + 1
    })
    if(getNetworkSettingList.response.length === 0){
      yield put(noDataNetworkSetting(true))
    }
      yield put(getNetworkSettingsSuccess(getNetworkSettingList.response))
    }else {
      getNetworkSettingList = []
      yield put(getNetworkSettingsSuccess(getNetworkSettingList))
    }
  } catch (error) {
    yield put(getNetworkSettingsFail(error))
  }
}

function* onReadNetworkSetting({ payload: NetworkSetting }) {
  try {
    console.log(NetworkSetting)
    const response = yield call(readNetworkSetting, NetworkSetting)
    yield put(readNetworkSettingSuccess(response))
  } catch (error) {
    yield put(readNetworkSettingSuccess(error))
  }
}

function* onAddNetworkSetting({ payload: NetworkSetting }) {
  try {
    const response = yield call(createNetworkSetting, NetworkSetting)
    if(response.success){
      yield put(addNetworkSettingSuccess(response))
    }else{
      yield put(addNetworkSettingFail(response))
    }
  } catch (error) {
    yield put(addNetworkSettingFail(error))
  }
}

function* onUpdateNetworkSetting({ payload: NetworkSetting }) {
  try {
    const response = yield call(updateNetworkSetting, NetworkSetting)
    yield put(updateNetworkSettingSuccess(response))
  } catch (error) {
    yield put(statusNetworkSettingSuccess(error))
  }
}

function* onStatusNetworkSetting({ payload: NetworkSetting }) {
  try {
    const response = yield call(statusChangeNetworkSetting, NetworkSetting)
    yield put(statusNetworkSettingSuccess(response))
  } catch (error) {
    yield put(updateNetworkSettingFail(error))
  }
}

function* onArchiveNetworkSetting({ payload: NetworkSetting }) {
  try {
    const response = yield call(archiveNetworkSetting, NetworkSetting)
    yield put(archiveNetworkSettingSuccess(response))
  } catch (error) {
    yield put(archiveNetworkSettingSuccess(error))
  }
}

function* NetworkSettingSaga() {
  yield takeEvery(GET_NETWORK_SETTINGS, fetchNetworkSettings)
  yield takeEvery(ADD_NETWORK_SETTING, onAddNetworkSetting)
  yield takeEvery(UPDATE_NETWORK_SETTING, onUpdateNetworkSetting)
  yield takeEvery(READ_NETWORK_SETTING, onReadNetworkSetting)
  yield takeEvery(STATUS_NETWORK_SETTING, onStatusNetworkSetting)
  yield takeEvery(ARCHIVE_NETWORK_SETTING, onArchiveNetworkSetting)
}

export default NetworkSettingSaga;
