import {
    GET_PLAYLIST_SUCCESS,
    GET_PLAYLIST_FAIL,
    GET_PLAYLISTBYID_SUCCESS,
    GET_PLAYLISTBYID_FAIL,
    CREATE_PLAYLIST_SUCCESS,
    CREATE_PLAYLIST_FAIL,
    UPDATE_PLAYLIST_SUCCESS,
    UPDATE_PLAYLIST_FAIL,
    STATUS_PLAYLIST_SUCCESS,
    ARCHIVE_PLAYLIST_SUCCESS,
    ARCHIVE_PLAYLIST_FAIL 
} from "./actionTypes"

const INIT_STATE = {
    playlist: [],
    eachPlaylistData: [],
    error: {},
    successMsg: null,
    errorMsg: null,
    response: null,
    archiveMsg: null,
    statusMsg: null
}

const playlistsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PLAYLIST_SUCCESS:
            return {
                ...state,
                playlist: action.payload
            }
        case GET_PLAYLIST_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_PLAYLISTBYID_SUCCESS:
            return {
                ...state,
                eachPlaylistData: action.payload
            }
        case GET_PLAYLISTBYID_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case CREATE_PLAYLIST_SUCCESS:
            console.log(action.payload)
            return {
                ...state,
                successMsg: action.payload

            }
        case CREATE_PLAYLIST_FAIL:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === "CREATE_PLAYLIST_SUCCESS" ? null : action.payload,
            }
        case UPDATE_PLAYLIST_SUCCESS:
            return {
                ...state,
                successMsg: action.payload
            }
        case UPDATE_PLAYLIST_FAIL:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === "UPDATE_PLAYLIST_SUCCESS" ? null : action.payload,
            }
        case STATUS_PLAYLIST_SUCCESS:
            return {
                ...state,
                statusMsg: action.payload
            }
        case ARCHIVE_PLAYLIST_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload
            }
        case ARCHIVE_PLAYLIST_FAIL:
            return {
                ...state,
                archiveMsg: action.payload,

            }
        default:
            return state
    }
}

export default playlistsReducer

