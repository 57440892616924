/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from "axios";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Spinner,
    Label, Form,
    Collapse,
    InputGroup
} from "reactstrap"

import moment from "moment";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";


var fromDate = moment().subtract(6, 'days')
var toDate = moment()
var loader = false

const CMS_Configuration = props => {

    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [isEdit, setIsEdit] = useState(false)
    const dispatch = useDispatch();
    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const [partner, setPartner] = useState({
        userRole: 'Partner',
        companyID: userInfo.companyID,
        isCDNEnabled: false,
        domainType: "",
        bucketType: "",
        bucketName: "",
        cDNBucketCname: ""
      });

    const [categoryList, setCategoryList] = useState([
        {
            label: 'Arts & Entertainment',
            value: false
        },
        {
            label: 'Health & Fitness',
            value: false
        },
        {
            label: 'Science',
            value: false
        },
        {
            label: 'Automotive',
            value: false
        },
        {
            label: 'Hobbies & Interests',
            value: false
        },
        {
            label: 'Shopping',
            value: false
        },
        {
            label: 'business',
            value: false
        },
        {
            label: 'Home & Garden',
            value: false
        },
        {
            label: 'Society',
            value: false
        },
        {
            label: 'Careers',
            value: false
        },
        {
            label: 'News',
            value: false
        },
        {
            label: 'Sports',
            value: false
        },
        {
            label: 'Dating',
            value: false
        },
        {
            label: 'Finance',
            value: false
        },
        {
            label: 'Style & Fashion',
            value: false
        },
        {
            label: 'Education',
            value: false
        },
        {
            label: 'Pets',
            value: false
        },
        {
            label: 'Technology & Computing',
            value: false
        },
        {
            label: 'Family & Parenting',
            value: false
        },
        {
            label: 'Real Estate',
            value: false
        },
        {
            label: 'Travel',
            value: false
        },
        {
            label: 'Food & Drink',
            value: false
        },
    ]
    );



    const [subCategoryList, setSubCategoryList] = useState([{ categoryName: '' }])



    const [domainList, setDomainList] = useState([{ domainName: '' }])



    function isValidDomain(str) {
        let regex = new RegExp(/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/);
        if (str == null) {
            return false;
        }
        if (regex.test(str) == true) {
            return true;
        }
        else {
            return false;
        }
    }

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
            partnerID: userInfo.userID,
            userRole: userInfo.userRole,
            partnerName: userInfo.name,
            parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner
    }

    // useEffect(() => {
    //     readBrandSafety()
    // }, []);

    // const readBrandSafety = () => {
    //     axios.post("/v2.0/operations/BrandSafety/read", body,
    //         {
    //             headers: headers
    //         })
    //         .then((res) => {
    //             console.log("result is ==>", res)
    //             if (res.data.response.length > 0) {
    //                 setIsEdit(true)
    //                 setCategoryList(res.data.response[0].brandSafety.blockAdvertiserCategory)
    //                 setSubCategoryList(res.data.response[0].brandSafety.addSubCategoryToBlock)
    //                 setDomainList(res.data.response[0].brandSafety.blockAdvertiserDomain)
    //             } else {
    //                 setIsEdit(false)
    //             }
    //         })
    //         .catch((err) => {
    //             console.log("e", err)
    //         });
    // }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPartner(partner => ({ ...partner, [name]: value }));
      }

    const handleSubmit = async (e) => {
        e.preventDefault();
                axios.post("/v2.0/operations/updatePartner/cdn/Bypartner/" + userInfo.userID, partner,
                    {
                        headers: headers
                    })
                    .then((res) => {
                        console.log("result is ==>", res)
                        if (res.data.success) {
                            if (isEdit) {
                                toastr.success('CMS Configuration Updated Successfully!...')
                            } else {
                                toastr.success('CMS Configuration Created Successfully!...')
                            }
                            readBrandSafety()
                        }
                    })
                    .catch((err) => {
                        console.log("e", err)
                    });
   
    
    }

    const CDNEnabledStateChange = e => {
        if (e.target.checked) {
          setPartner(partner => ({ ...partner, domainType: 'inHouse', bucketType: 'default', isCDNEnabled: e.target.checked }))
        } else {
          setPartner(partner => ({ ...partner, domainType: '', bucketType: '', isCDNEnabled: e.target.checked, bucketName: '' }))
        }
      }
    
      const handleCheckType = (e) => {
        const { name, value } = e.target;
        setPartner(partner => ({ ...partner, bucketType: name }))
      }
    
      function dontAllowDots(e) {
        var code = ('charCode' in e) ? e.charCode : e.keyCode;
        console.log(code)
        if ((code > 45 && code < 47)) { e.preventDefault(); }
      };
    



    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
            <div className="page-content">
                <Helmet>
                    <title>CMS Configuration | Bidsxchange</title>
                </Helmet>
                <Container fluid={true}>
                    <SupplyBreadCrumb title="Admin" breadcrumbItem="CMS Configuration" />
                    <Row>
                        <div className="col-12">
                            <Card>
                                <CardBody>
                                    <Form onSubmit={handleSubmit}>
                                        <Row >
                                            <div className="col-sm-8 col-xxl-6">
                                                <div className="mb-3">
                                                    <Label>CMS : </Label>
                                                    <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="customSwitchsizemd1"
                                                            checked={partner.isCDNEnabled}
                                                            onChange={CDNEnabledStateChange}
                                                        />
                                                        <label className="form-check-label  m-2 mt-1" htmlFor="customSwitchsizemd1">
                                                            {partner.isCDNEnabled ? <span color='text-success' style={{ color: '#000000' }}>Enabled </span> : <span style={{ color: '#a3a3a3' }}>Disabled</span>}
                                                        </label>
                                                    </div>
                                                </div>
                                                {partner.isCDNEnabled &&
                                                    <>
                                                        <div className='mb-3'>
                                                            <Label >Domain Type</Label>
                                                            <Input type="select" className="form-select" name="domainType" onChange={handleChange}
                                                                value={partner.domainType}>
                                                                <option value='inHouse'> InHouse Domain</option>
                                                                <option value='custom'> Custom Domain</option>
                                                            </Input>
                                                        </div>
                                                        {partner.domainType !== 'custom' &&
                                                            <div className="mb-3">
                                                                <Label >Bucket Type</Label>
                                                                <div className="row">
                                                                    <Col md={2}>
                                                                        <div className="form-check mt-2 mb-2">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="default"
                                                                                id="default"
                                                                                checked={partner.bucketType === 'default'}
                                                                                onChange={handleCheckType}
                                                                            />
                                                                            <label className="form-check-label  m-2 mt-1" htmlFor="default"> Default  </label>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={2}>
                                                                        <div className="form-check mt-2 mb-2">
                                                                            <input
                                                                                className="form-check-input "
                                                                                type="radio"
                                                                                name="custom"
                                                                                id="custom"
                                                                                checked={partner.bucketType === 'custom'}
                                                                                onChange={handleCheckType}
                                                                            />
                                                                            <label className="form-check-label  m-2 mt-1" htmlFor="custom"> Custom</label>
                                                                        </div>
                                                                    </Col>
                                                                </div>
                                                            </div>
                                                        }
                                                        {partner.bucketType === 'custom' && partner.domainType !== 'custom' &&
                                                            <div className="mb-3">
                                                                <Label className="form-label">InHouse Bucket Name </Label>
                                                                <InputGroup>
                                                                    <Input
                                                                        name="bucketName"
                                                                        className={'form-control'}
                                                                        onChange={handleChange}
                                                                        value={partner.bucketName}
                                                                        onKeyPress={dontAllowDots}
                                                                        placeholder="Please Enter your Bucket Name"
                                                                    />
                                                                    <div className="input-group-text">.bidsxchange.com</div>
                                                                </InputGroup>
                                                            </div>
                                                        }

                                                        {partner.domainType === 'custom' &&
                                                            <div>
                                                                {/* <div className="mb-3">
                                    <Label className="form-label">CName </Label>
                                    <Input
                                      name="cname"
                                      className={'form-control'}
                                      placeholder="Please Enter CName"
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label className="form-label">Data </Label>
                                    <Input
                                      name="cname"
                                      className={'form-control'}
                                      placeholder="Please Enter Data"
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label className="form-label">TTL </Label>
                                    <Input
                                      name="cname"
                                      className={'form-control'}
                                      placeholder="Please Enter TTL"
                                    />
                                  </div> */}

                                                                <h5 className="mb-3">Steps for Creating Custom Domain</h5>
                                                                <div className="mb-3">
                                                                    <ol>
                                                                        <li>Sign in to your GoDaddy <a href="https://dcc.godaddy.com/control/portfolio" target="_blank" rel="noreferrer">Domain Portfolio</a>. (Need help logging in? <a target="_blank" href="https://in.godaddy.com/help/i-cant-sign-in-to-my-godaddy-account-19319" rel="noreferrer">Find your username or password</a>.)</li>
                                                                        <li>Select <strong>Domain Edit Options</strong> next to your domain and then select <strong>Edit DNS</strong>. You may need to scroll down to see <strong>Edit DNS</strong>.
                                                                        </li>
                                                                        <li>Select <strong>Add New Record</strong></li>

                                                                        <li>Select <strong>CNAME</strong> from the <strong>Type</strong> menu.</li>
                                                                        <li>Enter the details for your new CNAME record:
                                                                            <ul>
                                                                                <li><strong>Name</strong>: The hostname or prefix of the record, without the domain name, such as <em>blog</em> or <em>shop</em>. The <strong>Name</strong> must follow these guidelines:
                                                                                    <ul>
                                                                                        <li>Periods (.) are allowed but not as the first or last character</li>
                                                                                        <li>Consecutive periods (…) are not allowed</li>
                                                                                        <li>Cannot exceed 63 characters</li>
                                                                                        <li>Cannot be the <em>@</em> symbol.</li>
                                                                                        <li>Not already in use by an existing <strong>A record</strong>, <strong>TXT record</strong> or <strong>MX record</strong>.</li>
                                                                                    </ul></li>
                                                                                <li><strong>Value</strong>: The URL you are setting as the destination for the host. Type <em>@</em> to point directly to your root domain name.</li>
                                                                                <li><strong>TTL (Time to Live)</strong>: The amount of time the server should cache information before refreshing. The default setting is 1 hour.</li>
                                                                            </ul></li>
                                                                        <li>Select <strong>Save</strong> to add your new record. If you added multiple records at the same time, select <strong>Save All Records</strong>.</li>
                                                                    </ol>
                                                                </div>
                                                            </div>
                                                        }

                                                        {partner.domainType === 'custom' &&
                                                            <div className="mb-3">
                                                                <Label className="form-label">Custom Bucket Name </Label>
                                                                <Input
                                                                    name="bucketName"
                                                                    className={'form-control'}
                                                                    onChange={handleChange}
                                                                    value={partner.bucketName}
                                                                    placeholder="Please Enter your Bucket Name"
                                                                />
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>

                </Container>
            </div>
            {/* </div> */}
        </React.Fragment >
    )
}

CMS_Configuration.propTypes = {}

export default CMS_Configuration