import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';

import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Label,
    Modal,
    Spinner
} from "reactstrap"

import DateRangePickers from 'helpers/DateRangePicker'
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';
import excelFile from 'assets/files/AdsTxtFile.xlsx'
import PDFFile from 'assets/files/AdsTxtEntries.pdf'
import axios from "axios";

// import images
import logodark from "assets/images/bidsxchange/logo-hd.png";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import LoadingTable from "pages/LoaderPages/LoadingTable";

//redux
import { useSelector, useDispatch } from "react-redux";

var filterCount = 0

const Publisher_Invoices = props => {
    const dispatch = useDispatch();
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [invoiceData, setInvoiceData] = useState([])
    const [btnprimary1, setBtnprimary1] = useState(false)
    const [jsTagModalState, setJsTagModalState] = useState(false);
    const [totalInvoice, setTotalInvoice] = useState(0)
    const [months, setMonths] = useState([])
    const [partners, setPartners] = useState([])
    const [invoices, setInvoices] = useState([])

    const [monthSearch, setMonthSearch] = useState('')
    const [partnerSearch, setPartnerSearch] = useState('')
    const [inventoryTypeSearch, setInventoryTypeSearch] = useState('')
    const [statusSearch, setStatusSearch] = useState('')
    const [partnerData, setPartnerData] = useState({
        partnerName: '',
        date: '',
        month: '',
        billID: ''
    })

    const [notesData, setNotesData] = useState({
        notes: 'Thanks for your business',
        terms: 'Publisher must raise Invoice from their own portal and not forward this invoice to us. Payments will be made with agreed Net term from date of receiving the invoice.'
    })

    const [switchLoader, setSwitchLoader] = useState(true)
    const [loading, setLoading] = useState(false)
    const [filterState, setFilterState] = useState(false)

    const handleSwitch = () => {
        setSwitchLoader(!switchLoader)
    }

    const changeDatePick = (event) => {
        console.log(event)
        console.log("start: ", event.startDate._d);
        console.log("end: ", event.endDate._d);
        setFromDate(event.startDate._d.toISOString());
        setToDate(event.endDate._d.toISOString());
    }

    const handleGeneratePdf = () => {
        const divToPrint = document.getElementById("Invoice");

        html2canvas(divToPrint).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = 190;
            const pageHeight = 400;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            const doc = new jsPDF('pt', 'mm');
            let position = 0;
            doc.addImage(imgData, 'PNG', 5, 0, imgWidth, imgHeight + 25);
            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'PNG', 5, position, imgWidth, imgHeight + 25);
                heightLeft -= pageHeight;
            }
            if (partnerData) {
                doc.save(partnerData.partnerName + '-Invoice.pdf');
            }

        });
    };

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            name: userInfo.name,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
        }
    }

    useEffect(() => {
        querySearch()

        let bodyMonth = {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                partner: userInfo.name,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
        }
        axios.post("/v2.0/partnerInvoice/Invoices/History/Month/forPartner", bodyMonth,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is Invoices ==>", res)
                if (res.data.success) {
                    setMonths(res.data.response)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }, []);

    const querySearch = (e) => {
        setLoading(true)
        if (filterCount > 0) {
            setFilterState(true)
        } else {
            setFilterState(false)
        }
        body.url = ""
        body.requestBody = {
            ...body.requestBody,
            month: filterCount > 0 ? monthSearch : '',
            partner: userInfo.name
        }
        axios.post("/v2.0/partnerInvoice/Invoices/History/list/forPartner", body.requestBody,
            {
                headers: headers
            })
            .then((res) => {
                setLoading(false)
                console.log("result is Invoices ==>", res)
                if (res.data.success) {
                    let invoiceList = []
                    res.data.response.map((item, index) => {
                        item.Partners.map((ele, index) => {
                            let obj = Object()
                            obj.month = item.month
                            obj.date = item.date
                            obj.Partner = ele.Partner
                            obj.paymentStatus = ele.paymentStatus
                            obj.IsApproved = ele.IsApproved
                            obj.invoices = ele.invoices
                            obj.allData = ele
                            obj.billID = ele.iDInvoice
                            if (ele.invoices && ele.invoices.length > 0) {
                                let invoiceID = ''
                                ele.invoices.map((item, index) => {
                                    invoiceID = ele.invoices.length > 1 ? (invoiceID + ((item.inventoryType).slice(0, 1)).toUpperCase() + item.invoiceID + '/') : ((item.inventoryType).slice(0, 1)).toUpperCase() + item.invoiceID.toString()
                                })
                                obj.invoiceID = '#INV-' + (invoiceID.slice(-1) === '/' ? invoiceID.slice(0, -1) + '' : invoiceID)
                            }
                            invoiceList.push(obj)
                        })
                    })
                    if (invoiceList.length > 0) {
                        invoiceList.map((ele, index) => {
                            ele.id = index + 1
                        })
                        setInvoiceData(invoiceList)
                        setLoading(false)
                    } else {
                        setLoading(false)
                    }
                } else {
                    setLoading(false)
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log("e", err)
            });
    }

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const invoiceDataListColumns = [
        {
            text: "ID",
 headerClasses:"ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            dataField: 'billID',
            text: 'ID',
            sort: true
        },
        {
            dataField: "month",
            text: "Month",
            sort: true,
        },

        {
            dataField: "Partner",
            text: "Partner",
            sort: false,
        },
        {
            dataField: "IsApproved",
            text: "Status",
            sort: true,
            formatter: (cellContent, invoiceData) => (
                <>
                    {invoiceData.IsApproved === 'approved' &&
                        <span className="active-state">Approved</span>
                    }
                    {invoiceData.IsApproved !== 'approved' &&
                        <span className="inactive-state">Approval pending</span>
                    }
                </>
            ),
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',

            // eslint-disable-next-line react/display-name
            formatter: (cellContent, invoiceData) => (
                <div className="d-flex gap-3" style={{ marginLeft: '0' }}>
                    <Link className="bordered" to="#" onClick={viewInvoice(invoiceData)} style={{ border: '1px solid', padding: '2px 5px', borderRadius: '5px', color: 'black' }}>
                        {/* <i
                      className="mdi mdi-dots-vertical font-size-18"
                      id="edittooltip"
                    ></i> */}
                        View
                    </Link>
                </div>
            ),
        },
    ];

    const invoiceListColumns = [
        {
            text: "ID",
 headerClasses:"ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },

        {
            dataField: "inventoryType",
            text: "Description",
            sort: false,
            footer: 'Total Amount',
            style: { wordBreak: 'break-word' },
            // headerFormatter: priceFormatter,
            editable: (content, row, rowIndex, columnIndex) => row.rowAdded !== 'old'
        },
        {
            dataField: "publisherRevenue",
            text: "Amount (USD)",
            sort: false,
            footer: `${totalInvoice}`
        },

    ];

    useEffect(() => {
        if (invoices.length > 0) {
            let invoiceTotal = 0
            invoices.map((item, index) => {
                if (item.method === '+') {
                    invoiceTotal = invoiceTotal + Number(parseFloat(item.publisherRevenue).toFixed(2))
                } else {
                    invoiceTotal = invoiceTotal - Number(parseFloat(item.publisherRevenue).toFixed(2))
                }
            })
            setTotalInvoice(Number(parseFloat(invoiceTotal).toFixed(2)))
        }

    }, [invoices]);

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} entries
      </span>
    );
  
  
    const pageOptions = {
      paginationSize: 4,
      pageStartIndex: 1,
      sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
    };

    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const [selectedInvoiceID, setSelectedInvoiceID] = useState('')
    const viewInvoice = (data) => (e) => {
        console.log(data)
        data.invoices.map((ele, index) => {
            ele.method = '+'
            ele.rowAdded = 'old'
        })
        if (data.invoices && data.invoices.length > 0) {
            let invoiceID = ''
            data.invoices.map((ele, index) => {
                invoiceID = data.invoices.length > 1 ? (invoiceID + ((ele.inventoryType).slice(0, 1)).toUpperCase() + ele.invoiceID + '/') : ((ele.inventoryType).slice(0, 1)).toUpperCase() + ele.invoiceID.toString()
            })
            setSelectedInvoiceID(invoiceID.slice(-1) === '/' ? invoiceID.slice(0, -1) + '' : invoiceID)
        }
        let arr = [...data.invoices]
        if (data.allData.extraCharge) {
            if (data.allData.extraCharge.onTotal && data.allData.extraCharge.onTotal.length > 0) {
                data.allData.extraCharge.onTotal.map((ele, index) => {
                    ele.method = ele.extraMethod
                    ele.rowAdded = 'old'
                    ele.inventoryType = ele.Extra
                    ele.publisherRevenue = ele.amount ? Number(parseFloat(ele.amount).toFixed(2)) : 0
                })
                arr = [...arr, ...data.allData.extraCharge.onTotal]
            }
        }
        setNotesData(notesData => ({ ...notesData, notes: data.allData.invoiceNote ? data.allData.invoiceNote : notesData.notes, terms: data.allData.termsAndCondition ? data.allData.termsAndCondition : notesData.terms }));
        arr.map((ele, index) => {
            ele.id = index + 1
            ele.publisherRevenue = Number(parseFloat(ele.publisherRevenue).toFixed(2))
            ele.inventoryType = ele.inventoryType === 'video' ? 'Video Advertising Revenue' : ele.inventoryType === 'highImpact' ? 'High Impact Advertising Revenue' : ele.inventoryType === 'standardBanner' ? 'Standard Banner Advertising Revenue' : ele.inventoryType
        })
        console.log(arr)
        setPartnerData({
            partnerName: data.Partner,
            date: data.date,
            month: data.month,
            billID: data.billID
        })
        setInvoices(arr)
        setJsTagModalState(true)
    }

    const invoiceRevoke = (e) => {
        let bodyApprove = {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                date: partnerData.date,
                Partner: partnerData.partnerName,
        }
        console.log(bodyApprove)
        axios.post("/v2.0/partnerInvoice/Invoices/History/revoke", bodyApprove,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is Invoices Approve ==>", res)
                if (res.data.success) {
                    // setPartners(res.data.response)
                    querySearch()
                    setJsTagModalState(false)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }

    useEffect(() => {
        console.log(loading)
        if (loading) {
            setSwitchLoader(true)
        }
    }, [loading]);

    const [isMenu, setisMenu] = useState(false)
    const toggleMenu = () => {
        setisMenu(!isMenu)
    }

    const clearAllFilter = (e) => {
        setMonthSearch('')
        filterCount = 0
        setFilterState(false)
        setInvoiceData([])
        querySearch()
    }

    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
            <div className="page-content">
                <Helmet>
                    <title>Invoice History | Bidsxchange</title>
                </Helmet>
                <Container fluid style={{ pointerEvents: (loading) ? 'none' : 'unset' }}>
                    <div className=" d-sm-flex  justify-content-between">
                        <Breadcrumbs title="Billing" breadcrumbItem="Invoice History" />
                        <div className="text-sm-end">
                            <div className="btn-group">
                                <Dropdown
                                    direction="left"
                                    isOpen={isMenu}
                                    toggle={toggleMenu}
                                >
                                    <DropdownToggle
                                        tag="button"
                                        className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                                    >
                                        <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                                        {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                                            Filter Applied
                                        </span> : <span className="d-none font-16 d-sm-inline-block">
                                            Filter
                                        </span>
                                        }
                                        {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px',color:'red' }}>{filterCount}</span>
                              } */}
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                                        <div className="dropdown-item-text">
                                            <div className="row">
                                                <h5 className="mb-0">Filters</h5>
                                            </div>
                                        </div>

                                        <DropdownItem divider />
                                        <Card>
                                            <CardBody aria-hidden="true">
                                                <Row >
                                                    <Col sm="12" className="mt-2">
                                                        <Label className="form-label">Month</Label>
                                                        <Input type="select" className="form-select" value={monthSearch} onChange={(e) => {
                                                            if (e.target.value === '') {
                                                                filterCount = filterCount > 0 ? filterCount - 1 : 0
                                                            }
                                                            if (monthSearch === '') {
                                                                filterCount = filterCount + 1
                                                            }
                                                            setMonthSearch(e.target.value);
                                                        }
                                                        }>
                                                            <option value=''> All </option>
                                                            {months.map((item) => (
                                                                <option key={item} value={item}>{item}</option>
                                                            ))}
                                                        </Input>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                        <DropdownItem divider />
                                        <Row>
                                            <Col sm="6">
                                                <DropdownItem
                                                    className="text-primary text-center"
                                                    onClick={clearAllFilter}
                                                >
                                                    Clear ALL
                                                </DropdownItem>
                                            </Col>
                                            <Col sm="6">
                                                <DropdownItem
                                                    className="text-primary text-center"
                                                    onClick={querySearch}
                                                >
                                                    Apply Filter
                                                </DropdownItem>
                                            </Col>
                                        </Row>

                                    </DropdownMenu>
                                </Dropdown>
                            </div>

                        </div>
                    </div>
                    {switchLoader &&
                        <LoadingTable loading={loading} targetLoading={handleSwitch} />
                    }
                    {!switchLoader &&
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                                    <ToolkitProvider
                                                        keyField={keyField}
                                                        data={invoiceData}
                                                        columns={invoiceDataListColumns}
                                                        // bootstrap4
                                                        search
                                                    >
                                                        {toolkitProps => (
                                                            <React.Fragment>
                                                                <Row className="mb-2">
                                                                    <Col sm="4">
                                                                        <div className="search-box ms-2 mb-2 d-inline-block">
                                                                            <div className="position-relative">
                                                                                <SearchBar {...toolkitProps.searchProps} />
                                                                                <i className="bx bx-search-alt search-icon" />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm="2"></Col>
                                                                    <Col sm="6">
                                                                        {/* <div className=" text-sm-end">
                                                                            <DateRangePickers datePick={changeDatePick} />
                                                                        </div> */}
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xl="12" className="table-responsive">
                                                                        <div >
                                                                            <BootstrapTable
                                                                                keyField={keyField}
                                                                                responsive
                                                                                bordered={false}
                                                                                striped={false}
                                                                                defaultSorted={defaultSorted}
                                                                                classes={
                                                                                    "table align-middle table-nowrap"
                                                                                }
                                                                                headerWrapperClasses={"thead-light"}
                                                                                {...toolkitProps.baseProps}
                                                                                pagination={paginationFactory(pageOptions)}
                                                                                noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                            // ref={node}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </Container>
            </div>
            {/* </div> */}
            <Modal
                size="lg"
                isOpen={jsTagModalState}
            >
                <div className="modal-content" id="Invoice">
                    <div className="modal-header" >
                        <h3 className="modal-title mt-0" id="myModalLabel">
                            Invoice
                            <p style={{ fontSize: '14px', color: 'grey', margin: '0' }}>
                                {partnerData.billID}
                            </p>
                        </h3>
                        <button
                            type="button"
                            onClick={() => {
                                setJsTagModalState(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ maxHeight: 'fit-content', overflow: 'auto' }}>
                        <div className="row mb-4">
                            <h4>
                                <img
                                    src={logodark}
                                    alt=""
                                    height="50"
                                    className="auth-logo-dark"
                                />
                            </h4>
                            <span> Bill To</span>
                            <h5>Streamlyn Semisoft Private Limited</h5>
                            <span style={{ width: '15pc' }}>
                                No.34, AVS Compound, 80FT Road, 4th Block, Koramangala, Bangalore, India, 560034
                            </span>
                        </div>

                        <br />
                        <div className="row mt-2">
                            <div className="col-md-8">
                                Bill From
                                <h6><b>{partnerData.partnerName}</b></h6>
                            </div>
                            <div className="col-md-4">
                                <div className="text-sm-end mt-2">
                                    <span>Invoice Date : &nbsp;&nbsp;&nbsp; <b>{partnerData.month}</b></span> <br />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <BootstrapTable
                                keyField="id"
                                data={invoices}
                                columns={invoiceListColumns}
                            />
                        </div>
                        {notesData.notes &&
                            <div className="row mt-4">
                                <h5>Notes </h5>
                                <span>{notesData.notes}</span>
                            </div>
                        }
                        {notesData.terms &&
                            <div className="row mt-4">
                                <h5>Terms & Conditions </h5>
                                <span>{notesData.terms}</span>
                            </div>
                        }

                    </div>
                </div>

                <div className="modal-footer">
                    <button className="btn btn-primary " onClick={handleGeneratePdf}>
                        Download Invoice
                    </button> &nbsp;&nbsp;
                    <button
                        type="button"
                        onClick={() => {
                            setJsTagModalState(false);
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                    >
                        Close
                    </button>

                </div>
            </Modal>
        </React.Fragment >
    )
}

Publisher_Invoices.propTypes = {}

export default Publisher_Invoices