/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Label,
    Input, Spinner
} from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import New_User from "./New_User";

import {
    getUsers as onGetUsers,
    statusPartner as onStatusPartner,
    archivePartner as onArchivePartner,
    statusPartnerSuccess,
    archivePartnerSuccess,
    addPartnerFail,
    updatePartnerFail,
} from "store/PartnerManagement/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import LoadingTable from "pages/LoaderPages/LoadingTable";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var filterCount = 0

let pageNumber = 1
let pageSize = 10
let totalRecords = 0
let searchText = ''

const User_Management = props => {
    const dispatch = useDispatch();
    const [isRight, setIsRight] = useState(false);
    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
    const [selected, setSelected] = useState([])
    const [statusSearch, setStatusSearch] = useState('Active')
    const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));
    const userInfo = JSON.parse(localStorage.getItem('authUser'));

    const [switchLoader, setSwitchLoader] = useState(true)

    const handleSwitch = () => {
        setSwitchLoader(!switchLoader)
    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            setIsRight(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 5000,
        extendedTimeOut: 1000
    };

    const selectRow = {
        mode: "checkbox",
        nonSelectable: [],
        selected: selected,
        onSelect: (row, isSelect, rowIndex, e) => {
            console.log(row, isSelect, rowIndex)
            if (isSelect) {
                setSelected([...selected, row.id])
                setSelectedRowsStatus(true)
                selectedIndex.push(row.userID)
                selectedItem.push(row.userID)
                console.log(selectedIndex)
            } else {
                setSelected(selected.filter(x => x !== row.id))
                let index = selectedIndex.indexOf(row.userID)
                selectedIndex.splice(index, 1)
                if (selectedIndex.length === 0) {
                    setSelectedRowsStatus(false)
                }
                console.log(selectedIndex)
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            const ids = rows.map(r => r.id);
            if (isSelect) {
                setSelected(ids)
                rows.map((item, index) => {
                    selectedIndex.push(item.userID)
                })
                setSelectedRowsStatus(true)
            } else {
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
            }
        }
    };

    const handleClose = () => {
        setSelectedRowsStatus(false)
        selectedItem = []
        setSelected([])
        selectedIndex = []
    }

    const { partners } = useSelector(state => ({
        partners: state.partners.users,
    }));

    console.log(partners)

    const { loading } = useSelector(state => ({
        loading: state.partners.loading,
    }));

    const { partnersResponse } = useSelector(state => ({
        partnersResponse: state.partners.response,
    }));

    const { archivePartnersResponse } = useSelector(state => ({
        archivePartnersResponse: state.partners.archiveMsg,
    }));

    useEffect(() => {
        console.log(loading)
        if (loading) {
            setSwitchLoader(true)
        }

    }, [loading]);

    console.log(partnersResponse)
    useEffect(() => {
        if (partnersResponse !== null) {
            if (partnersResponse.statusCode === 200) {
                toastr.success('User Status Updated Successfully')
                dispatch(onGetUsers({ userRole: userInfo.userRole,filter: statusSearch, role: '' }));
                dispatch(statusPartnerSuccess(null));
                selectedItem = []
                selectedIndex = []
                setSelected([])
                setSelectedRowsStatus(false)
            }
        }
    }, [dispatch, partnersResponse]);

    useEffect(() => {
        if (archivePartnersResponse !== null) {
            if (archivePartnersResponse.statusCode === 200) {
                toastr.success('User Archived Successfully')
                dispatch(onGetUsers({ userRole: userInfo.userRole,filter: statusSearch, role: '' }));
                dispatch(archivePartnerSuccess(null));
                selectedItem = []
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
            }
        }
    }, [dispatch, archivePartnersResponse]);

    const toggleRightCanvas = () => {
        selectedData = ''
        dispatch(onGetUsers({ userRole: userInfo.userRole,filter: statusSearch, role: '' }));
        dispatch(addPartnerFail(null));
        dispatch(updatePartnerFail(null));
        setIsRight(!isRight);
    };
    const toggleEnableScroll = () => {
        setIsEnableScroll(!isEnableScroll);
    };

    const toggleDrawer = (close, partner) => (event) => {
        console.log(event)
        if (event === 'Create') {
            toastr.success('User Saved Successfully!...')
        }
        if (event === 'Update') {
            toastr.success('User Updated Successfully!...')
        }
        selectedData = partner
        dispatch(onGetUsers({ userRole: userInfo.userRole,filter: statusSearch, role: '' }));
        dispatch(addPartnerFail(null));
        dispatch(updatePartnerFail(null));
        setIsRight(close);
    };

    const [filterState, setFilterState] = useState(false)

    const statusChange = (e) => {
        if (e.target.value === 'Active') {
            filterCount = filterCount > 0 ? filterCount - 1 : 0
        }
        if (statusSearch === 'Active') {
            filterCount = filterCount + 1
        }
        setStatusSearch(e.target.value)
    }

    const applyFilter = (e) => {
        dispatch(onGetUsers({ userRole: userInfo.userRole,filter: statusSearch, role: '' }));
        if (filterCount > 0) {
            setFilterState(true)
        } else {
            setFilterState(false)
        }
    }

    const [isMenu, setisMenu] = useState(false)
    const toggleMenu = () => {
        setisMenu(!isMenu)
    }

    const clearAllFilter = (e) => {
        setStatusSearch('Active')
        filterCount = 0
        setFilterState(false)
        dispatch(onGetUsers({ userRole: userInfo.userRole,filter: 'Active', role: '' }));
    }

    useEffect(() => {
        if (partners !== null) {
            dispatch(onGetUsers({ userRole: userInfo.userRole,filter: statusSearch, role: '' }));
        }
    }, []);

    useEffect(() => {
        if (props.activeId === '1') {
            dispatch(onGetUsers({ userRole: userInfo.userRole,filter: 'Active', role: '' }));
            setStatusSearch('Active')
        }
    }, [props.activeId]);

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const partnerListColumns = [
        {
            text: "ID",
 headerClasses:"ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            text: "ID",
 headerClasses:"ID",
            dataField: "userID",
            sort: true,
        },
        // {
        //     dataField: "userCreateTime",
        //     text: "Date",
        //     sort: true,
        // },
        {
            text: "Name",
            dataField: "userName",
            sort: true,
        },
        {
            dataField: "userEmail",
            text: "Email",
            sort: true,
        },

        {
            dataField: "userRole",
            text: "Role",
            sort: true,
        },
        {
            dataField: "inventoryType",
            text: "Inventory Type",
            sort: true,
            formatter: (cellContent, user) => (
                <>
                    <span className=''>{user.inventoryType === 'both' ? "Display & Video" : user.inventoryType === 'display' ? 'Display' : 'Video'}</span>
                </>
            ),
        },
        {
            dataField: "userStatus",
            text: "Status",
            sort: true,
            formatter: (cellContent, partner) => (
                <>
                    <span className={partner.newStatus === 'Active' ? "active-state" : partner.newStatus === 'Inactive' ? "inactive-state" : "archived-state"}>{partner.newStatus}</span>
                </>
            ),
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',
            hidden: (UserRolePrivileges.adminAccess && UserRolePrivileges.adminAccess.UserManagement) && (!UserRolePrivileges.adminAccess.UserManagement.update),
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, partner) => (
                <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
                    <Link className="text-success" to="#" onClick={toggleDrawer(true, partner)}>
                        <i
                            className="mdi mdi-pencil font-size-18"
                            id="edittooltip"
                        ></i>
                    </Link>
                </div>
            ),
        },
    ];

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} entries
      </span>
    );
  
  
    const pageOptions = {
      paginationSize: 4,
      pageStartIndex: 1,
      sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
    };
    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const archivePartner = async () => {
        dispatch(onArchivePartner({ idList: selectedItem }));
    }

    const statusPartner = (state) => (event) => {
        console.log(state)
        dispatch(onStatusPartner({
            idList: selectedItem, userStatus: state, userlog: {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                name: userInfo.name
            },
        }));
    }

    console.log(partners)

    if (partners.length > 0) {
        if (UserRolePrivileges.adminAccess && UserRolePrivileges.adminAccess.UserManagement) {
            if (!UserRolePrivileges.adminAccess.UserManagement.archive && !UserRolePrivileges.adminAccess.UserManagement.status) {
                partners.map((item, index) => {
                    selectRow.nonSelectable.push(index + 1)
                })
            } else {
                partners.map((item, index) => {
                    if (item.isUserArchived) {
                        selectRow.nonSelectable.push(index + 1)
                    }
                })
            }
        }
    }

    return (
        <Row>
            <Col lg="12" >
                {UserRolePrivileges.adminAccess && UserRolePrivileges.adminAccess.UserManagement && UserRolePrivileges.adminAccess.UserManagement.create &&
                    <div className=" text-sm-end mb-2">
                        <div className="btn-group">
                            <Dropdown
                                direction="left"
                                isOpen={isMenu}
                                toggle={toggleMenu}
                            >
                                <DropdownToggle
                                    tag="button"
                                    className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                                >
                                    <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                                    {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                                        Filter Applied
                                    </span> : <span className="d-none font-16 d-sm-inline-block">
                                        Filter
                                    </span>
                                    }
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                                    <div className="dropdown-item-text">
                                        <div className="row">
                                            <h5 className="mb-0">Filters</h5>
                                        </div>
                                    </div>

                                    <DropdownItem divider />
                                    <Card>
                                        <CardBody aria-hidden="true">
                                            <Row >
                                                <Col sm="12" className="mt-2">
                                                    <Label className="form-label">Status</Label>
                                                    <div className=" text-sm-end">
                                                        <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                                            <option value=''> All</option>
                                                            <option value='Active' > Active</option>
                                                            <option value='Inactive'> InActive</option>
                                                            <option value='Archived'> Archived</option>
                                                        </Input>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>

                                    <DropdownItem divider />
                                    <Row>
                                        <Col sm="6">
                                            <DropdownItem
                                                className="text-primary text-center"
                                                onClick={clearAllFilter}
                                            >
                                                Clear ALL
                                            </DropdownItem>
                                        </Col>
                                        <Col sm="6">
                                            <DropdownItem
                                                className="text-primary text-center"
                                                onClick={applyFilter}
                                            >
                                                Apply Filter
                                            </DropdownItem>
                                        </Col>
                                    </Row>

                                </DropdownMenu>
                            </Dropdown>
                        </div> &nbsp;&nbsp;
                        <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                            onClick={toggleRightCanvas}
                        >
                            <i className="mdi mdi-plus-circle-outline me-1" />
                            New User
                        </Button>
                    </div>
                }
            </Col>
            {switchLoader &&
                <LoadingTable loading={loading} targetLoading={handleSwitch} />
            }
            {!switchLoader &&
                <Col lg="12">
                    <Card>
                        <CardBody>
                                        <ToolkitProvider
                                            keyField={keyField}
                                            data={partners}
                                            columns={partnerListColumns}
                                            // bootstrap4
                                            search
                                        >
                                            {toolkitProps => (
                                                <React.Fragment>
                                                    <Row className="mb-2">
                                                        <Col sm="4">
                                                            <div className="search-box ms-2 mb-2 d-inline-block">
                                                                <div className="position-relative">
                                                                    <SearchBar {...toolkitProps.searchProps} />
                                                                    <i className="bx bx-search-alt search-icon" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl="12">
                                                            {selectedRowsStatus && selectedIndex.length > 0 &&
                                                                <div className="row m-0 React-Table-Selection">
                                                                    {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                                                    <div style={{ padding: '0.5% 2%' }}>

                                                                        {/* <button color="primary" className="btn btn-primary " onClick={statusPartner(true)}>Activate</button> */}
                                                                        {UserRolePrivileges.adminAccess && UserRolePrivileges.adminAccess.UserManagement && UserRolePrivileges.adminAccess.UserManagement.status &&
                                                                            <button color="primary" className="btn btn-primary " onClick={statusPartner(true)}>
                                                                                Activate
                                                                            </button>
                                                                        }
                                                                        &nbsp;  &nbsp;
                                                                        {UserRolePrivileges.adminAccess && UserRolePrivileges.adminAccess.UserManagement && UserRolePrivileges.adminAccess.UserManagement.status &&
                                                                            <button color="primary" className="btn btn-primary " onClick={statusPartner(false)}>
                                                                                DeActivate
                                                                            </button>
                                                                        }
                                                                        &nbsp;  &nbsp;
                                                                        {UserRolePrivileges.adminAccess && UserRolePrivileges.adminAccess.UserManagement && UserRolePrivileges.adminAccess.UserManagement.archive &&
                                                                            <Button color="primary" className="btn btn-primary " onClick={archivePartner} >
                                                                                Archive
                                                                            </Button>
                                                                        }
                                                                        <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                                                            className="mdi mdi-close-thick font-size-18"
                                                                        ></i></Button>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col xl="12" className="table-responsive">
                                                            <div >
                                                                <BootstrapTable
                                                                    keyField={keyField}
                                                                    responsive
                                                                    bordered={false}
                                                                    striped={false}
                                                                    defaultSorted={defaultSorted}
                                                                    selectRow={selectRow}
                                                                    classes={
                                                                        "table align-middle table-nowrap"
                                                                    }
                                                                    headerWrapperClasses={"thead-light"}
                                                                    {...toolkitProps.baseProps}
                                                                    pagination={paginationFactory(pageOptions)}
                                                                    noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                // ref={node}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                            )}
                                        </ToolkitProvider>
                        </CardBody>
                    </Card>
                </Col>
            }
            <Offcanvas isOpen={isRight} direction='end'  >
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {'New User'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <New_User closeCanvas={toggleDrawer(false, '')} selectedUser={selectedData} />
                </OffcanvasBody>
            </Offcanvas>
        </Row>

    )
}

User_Management.propTypes = {}

export default User_Management