import React, { useEffect, useState, useRef , useCallback} from "react";
import PropTypes from 'prop-types'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Label
} from "reactstrap"

import DateRangePickers from '../../../helpers/DateRangePicker'
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';

import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import NewCategory from "./NewCategory";

import {
    getCategory as onGetCategory,
    statusCategory as onStatusCategory,
    archiveCategory as onArchiveCategory,
    statusCategorySuccess,
    archiveCategorySuccess,
    addCategoryFail,
    updateCategoryFail,
} from "store/customTracking/Categories/actions";
import LoadingTable from "pages/LoaderPages/LoadingTable";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var loader = false
var filterCount = 0

const Category = props => {
    const dispatch = useDispatch();
    const [isRight, setIsRight] = useState(false);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [proxy, setAnalyticalReports] = useState([])

    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [isArchived, setIsArchived] = useState(false)
    const [selected, setSelected] = useState([])

    const [statusSearch, setStatusSearch] = useState('Active')

    const [switchLoader, setSwitchLoader] = useState(true)

    const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));


    const handleSwitch = () => {
        setSwitchLoader(!switchLoader)
    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
          setIsRight(false);
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
          document.removeEventListener("keydown", escFunction, false);
        };
      }, [escFunction]);


      const [filterState, setFilterState] = useState(false)

      const statusChange = (e) => {
        if (e.target.value === 'Active') {
          filterCount = filterCount > 0 ? filterCount - 1 : 0
        }
        if (statusSearch === 'Active') {
          filterCount = filterCount + 1
        }
        setStatusSearch(e.target.value)
      }
    
      const applyFilter = (e) => {
        dispatch(onGetCategory({
            filter: statusSearch
        }));
        if (filterCount > 0) {
          setFilterState(true)
        } else {
          setFilterState(false)
        }
      }
    
      const [isMenu, setisMenu] = useState(false)
      const toggleMenu = () => {
        setisMenu(!isMenu)
      }
    
      const clearAllFilter = (e) => {
        setStatusSearch('Active')
        filterCount = 0
        setFilterState(false)
        dispatch(onGetCategory({
            filter: 'Active'
        }));
      }

    const changeDatePick = (event) => {
        console.log(event)
        console.log("start: ", event.startDate._d);
        console.log("end: ", event.endDate._d);
        setFromDate(event.startDate._d.toISOString());
        setToDate(event.endDate._d.toISOString());
    }

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    useEffect(() => {
        loader = true
        // dispatch(onGetCategory(null));
    }, []);

    const selectRow = {
        mode: "checkbox",
        nonSelectable: [],
        selected: selected,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                setSelected([...selected, row.id])
                setSelectedRowsStatus(true)
                selectedIndex.push(row.houseAdsCategoryID)
                selectedItem.push(row.houseAdsCategoryID)
            } else {
                setSelected(selected.filter(x => x !== row.id))
                let index = selectedIndex.indexOf(row.houseAdsCategoryID)
                selectedIndex.splice(index, 1)
                let index2 = selectedItem.indexOf(row.houseAdsCategoryID)
                selectedItem.splice(index2, 1)
                if (selectedIndex.length === 0) {
                    setSelectedRowsStatus(false)
                }
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            const ids = rows.map(r => r.id);
            if (isSelect) {
                setSelected(ids)
                rows.map((item, index) => {
                    selectedIndex.push(item.houseAdsCategoryID)
                    selectedItem.push(item.houseAdsCategoryID)
                })
                setSelectedRowsStatus(true)
            } else {
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
                selectedItem = []
            }
        }
    };

    const handleClose = () => {
        setSelectedRowsStatus(false)
        selectedItem = []
        setSelected([])
        selectedIndex = []
    }

    const { categories } = useSelector(state => ({
        categories: state.categories.ctgs,
    }));

    const { loading } = useSelector(state => ({
        loading: state.categories.loading,
    }));

    const { noData } = useSelector(state => ({
        noData: state.categories.noData,
      }));

    const { categoriesResponse } = useSelector(state => ({
        categoriesResponse: state.categories.response,
    }));

    const { archiveCategoryResponse } = useSelector(state => ({
        archiveCategoryResponse: state.categories.archiveMsg,
    }));

    useEffect(() => {
        console.log(loading)
        if (loading) {
            setSwitchLoader(true)
        }

    }, [loading]);

    // const { errorMsg, successMsg } = useSelector(state => ({
    //   errorMsg: state.categories.errorMsg,
    //   successMsg: state.categories.successMsg,
    // }));

    console.log(categoriesResponse)
    useEffect(() => {
        if (categoriesResponse !== null) {
            if (categoriesResponse.success) {
                toastr.success('Category Status Changed Successfully')
                dispatch(onGetCategory({ filter: statusSearch }));
                dispatch(statusCategorySuccess(null));
                selectedItem = []
                setSelected([])
                selectedIndex = []
                setSelectedRowsStatus(false)
            }
        }
    }, [dispatch, categoriesResponse]);

    useEffect(() => {
        if (archiveCategoryResponse !== null) {
            if (archiveCategoryResponse.success) {
                toastr.success('Category Archived Successfully')
                dispatch(onGetCategory({ filter: statusSearch }));
                dispatch(archiveCategorySuccess(null));
                selectedItem = []
                setSelected([])
                selectedIndex = []
                setSelectedRowsStatus(false)
            }
        }
    }, [dispatch, archiveCategoryResponse]);

    const toggleRightCanvas = () => {
        selectedData = ''
        dispatch(addCategoryFail(""));
        dispatch(updateCategoryFail(""));
        dispatch(onGetCategory({ filter: statusSearch }));
        setIsRight(!isRight);
    };

    const toggleEnableScroll = () => {
        setIsEnableScroll(!isEnableScroll);
    };

    const toggleDrawer = (close, category) => (event) => {
        console.log(event)
        if (event === 'Create') {
            toastr.success('Category Saved Successfully!...')
        }
        if (event === 'Update') {
            toastr.success('Category Updated Successfully!...')
        }
        selectedData = category
        setIsArchived(category.isHouseAdsCategoryArchived)
        dispatch(onGetCategory({ filter: statusSearch }));
        dispatch(addCategoryFail(""));
        dispatch(updateCategoryFail(""));
        setIsRight(close);
    };

    useEffect(() => {
        if (categories ) {
            loader = true
            dispatch(onGetCategory({ filter: statusSearch }));
        }
    }, []);

    // const toggleRightCanvas = () => {
    //     setIsRight(!isRight);
    // };

    // const toggleDrawer = (close) => (event) => {
    //     console.log(event)
    //     setIsRight(close);
    // };

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const categoryListColumns = [
        {
            text: "id",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            dataField: "houseAdsCategoryID",
            text: "ID",
            sort: true,
        },
        {
            dataField: "houseAdsCategoryCreateTime",
            text: "Creation Date",
            sort: true,
        },
        {
            dataField: "houseAdsCategoryName",
            text: "Name",
            sort: true,
            style: { wordBreak: 'break-word' }
        },
        {
            dataField: "houseAdsCategoryStatus",
            text: "Status",
            sort: true,
            formatter: (cellContent, category) => (
                <>
                    {category.houseAdsCategoryStatus && !category.isHouseAdsCategoryArchived &&
                        <span className="active-state">Active</span>
                    }
                    {!category.houseAdsCategoryStatus && !category.isHouseAdsCategoryArchived &&
                        <span className="inactive-state">Inactive</span>
                    }
                    {category.isHouseAdsCategoryArchived &&
                        <span className="archived-state">Archived</span>
                    }
                </>

            ),
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',
            hidden: (UserRolePrivileges.HouseAds && UserRolePrivileges.HouseAds.HouseAdsCategories) && (!UserRolePrivileges.HouseAds.HouseAdsCategories.update),
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, category) => (
                <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
                    <Link className="text-success" to="#" onClick={toggleDrawer(true, category)}>
                        {category.isHouseAdsCategoryArchived &&
                            <i
                                className="mdi mdi-eye font-size-18"
                                id="edittooltip"
                            ></i>
                        }
                        {!category.isHouseAdsCategoryArchived &&
                            <i
                                className="mdi mdi-pencil font-size-18"
                                id="edittooltip"
                            ></i>
                        }
                    </Link>
                </div>
            ),
        },
    ];

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} entries
      </span>
    );
  
  
    const pageOptions = {
      paginationSize: 4,
      pageStartIndex: 1,
      sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
    };

    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const archiveCategory = async () => {
        dispatch(onArchiveCategory({ idList: selectedItem }));
    }

    const statusCategory = (state) => (event) => {
        console.log(state)
        dispatch(onStatusCategory({ idList: selectedItem, houseAdsCategoryStatus: state }));
    }

    console.log(categories)

    if (categories.length > 0) {
        if (UserRolePrivileges.HouseAds && UserRolePrivileges.HouseAds.HouseAdsCategories) {
            if (!UserRolePrivileges.HouseAds.HouseAdsCategories.archive && !UserRolePrivileges.HouseAds.HouseAdsCategories.status) {
                categories.map((item, index) => {
                    selectRow.nonSelectable.push(index + 1)
                })
            } else {
                categories.map((item, index) => {
                    if (item.isHouseAdsCategoryArchived) {
                        selectRow.nonSelectable.push(index + 1)
                    }
                })
            }
        }
    }

    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
                <div className="page-content">
                    <Helmet>
                        <title>Categories | Bidsxchange</title>
                    </Helmet>
                    <Container fluid={true}>
                        <div className=" d-sm-flex  justify-content-between">
                            <Breadcrumbs title="Custom Tracking" breadcrumbItem="Categories" />
                            {UserRolePrivileges.HouseAds && UserRolePrivileges.HouseAds.HouseAdsCategories && UserRolePrivileges.HouseAds.HouseAdsCategories.create &&
                                <div className=" text-sm-end">
                                       <div className="btn-group">
                  <Dropdown
                    direction="left"
                    isOpen={isMenu}
                    toggle={toggleMenu}
                  >
                    <DropdownToggle
                      tag="button"
                      className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                    >
                      <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                      {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                        Filter Applied
                      </span> : <span className="d-none font-16 d-sm-inline-block">
                        Filter
                      </span>
                      }
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                      <div className="dropdown-item-text">
                        <div className="row">
                          <h5 className="mb-0">Filters</h5>
                        </div>
                      </div>

                      <DropdownItem divider />
                      <Card>
                        <CardBody aria-hidden="true">
                          <Row >
                            <Col sm="12" className="mt-2">
                              <Label className="form-label">Status</Label>
                              <div className=" text-sm-end">
                                <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                  <option value=''> All</option>
                                  <option value='Active' > Active</option>
                                  <option value='Inactive'> InActive</option>
                                  <option value='Archived'> Archived</option>
                                </Input>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      <DropdownItem divider />
                      <Row>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={clearAllFilter}
                          >
                            Clear ALL
                          </DropdownItem>
                        </Col>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={applyFilter}
                          >
                            Apply Filter
                          </DropdownItem>
                        </Col>
                      </Row>

                    </DropdownMenu>
                  </Dropdown>
                </div> &nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        className="font-16 btn-block btn btn-primary"
                                        onClick={toggleRightCanvas}
                                    >
                                        <i className="mdi mdi-plus-circle-outline me-1" />
                                        Add Category
                                    </Button>
                                </div>
                            }
                        </div>
                        {switchLoader &&
                            <LoadingTable loading={loading} targetLoading={handleSwitch} />
                        }
                        {!switchLoader &&
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody aria-hidden="true">
                                                        <ToolkitProvider
                                                            keyField={keyField}
                                                            data={categories}
                                                            columns={categoryListColumns}
                                                            // bootstrap4
                                                            search
                                                        >
                                                            {toolkitProps => (
                                                                <React.Fragment>
                                                                    <Row className="mb-2">
                                                                    <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar {...toolkitProps.searchProps} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="4"></Col>
                                  <Col sm="4" className="date-range ">
                                    <div className="text-sm-end">
                                      <DateRangePickers datePick={changeDatePick} />
                                    </div>
                                  </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xl="12">
                                                                            {selectedRowsStatus && selectedIndex.length > 0 &&
                                                                                <div className="row m-0 React-Table-Selection">
                                                                                    {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                                                                    <div style={{ padding: '0.5% 2%' }}>

                                                                                        {/* <button color="primary" className="btn btn-primary " onClick={statusCategory(true)}>Activate</button> */}
                                                                                        {UserRolePrivileges.HouseAds && UserRolePrivileges.HouseAds.HouseAdsCategories && UserRolePrivileges.HouseAds.HouseAdsCategories.status &&
                                                                                            <button color="primary" className="btn btn-primary " onClick={statusCategory(true)}>
                                                                                                Activate
                                                                                            </button>
                                                                                        }
                                                                                        &nbsp;  &nbsp;
                                                                                        {UserRolePrivileges.HouseAds && UserRolePrivileges.HouseAds.HouseAdsCategories && UserRolePrivileges.HouseAds.HouseAdsCategories.status &&

                                                                                            <button color="primary" className="btn btn-primary " onClick={statusCategory(false)}>
                                                                                                DeActivate
                                                                                            </button>
                                                                                        }
                                                                                        &nbsp;  &nbsp;
                                                                                        {UserRolePrivileges.HouseAds && UserRolePrivileges.HouseAds.HouseAdsCategories && UserRolePrivileges.HouseAds.HouseAdsCategories.archive &&

                                                                                            <Button color="primary" className="btn btn-primary " onClick={archiveCategory} >
                                                                                                Archive
                                                                                            </Button>
                                                                                        }
                                                                                        <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                                                                            className="mdi mdi-close-thick font-size-18"
                                                                                        ></i></Button>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </Col>
                                                                        <Col xl="12" className="table-responsive">
                                                                            <div >
                                                                                <BootstrapTable
                                                                                    keyField={keyField}
                                                                                    responsive
                                                                                    bordered={false}
                                                                                    striped={false}
                                                                                    defaultSorted={defaultSorted}
                                                                                    selectRow={selectRow}
                                                                                    classes={
                                                                                        "table align-middle table-nowrap"
                                                                                    }
                                                                                    headerWrapperClasses={"thead-light"}
                                                                                    {...toolkitProps.baseProps}
                                                                                    pagination={paginationFactory(pageOptions)}
                                                                                    noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </React.Fragment>
                                                            )}
                                                        </ToolkitProvider>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        }
                    </Container>
                </div>
            {/* </div> */}
            <Offcanvas isOpen={isRight} direction='end'  >
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {'Add Category'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <NewCategory closeCanvas={toggleDrawer(false, '')} selectedCategory={selectedData} selectedArchived={isArchived} />
                </OffcanvasBody>
            </Offcanvas>
        </React.Fragment >
    )
}

Category.propTypes = {}

export default Category