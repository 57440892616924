/* PUB_NOTIFICATION */
export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES"
export const NO_DATA_PUB_NOTIFICATIONS = "NO_DATA_PUB_NOTIFICATIONS"
export const GET_PUB_NOTIFICATIONS = "GET_PUB_NOTIFICATIONS"
export const GET_PUB_NOTIFICATIONS_SUCCESS = "GET_PUB_NOTIFICATIONS_SUCCESS"
export const GET_PUB_NOTIFICATIONS_FAIL = "GET_PUB_NOTIFICATIONS_FAIL"

export const ADD_PUB_NOTIFICATION = "ADD_PUB_NOTIFICATION"
export const ADD_PUB_NOTIFICATION_SUCCESS = "ADD_PUB_NOTIFICATION_SUCCESS"
export const ADD_PUB_NOTIFICATION_ERROR = "ADD_PUB_NOTIFICATION_ERROR"

export const UPDATE_PUB_NOTIFICATION = "UPDATE_PUB_NOTIFICATION"
export const UPDATE_PUB_NOTIFICATION_SUCCESS = "UPDATE_PUB_NOTIFICATION_SUCCESS"
export const UPDATE_PUB_NOTIFICATION_ERROR = "UPDATE_PUB_NOTIFICATION_ERROR"

export const READ_PUB_NOTIFICATION = "READ_PUB_NOTIFICATION"
export const READ_PUB_NOTIFICATION_SUCCESS = "READ_PUB_NOTIFICATION_SUCCESS"

export const STATUS_PUB_NOTIFICATION = "STATUS_PUB_NOTIFICATION"
export const STATUS_PUB_NOTIFICATION_SUCCESS = "STATUS_PUB_NOTIFICATION_SUCCESS"

export const ARCHIVE_PUB_NOTIFICATION = "ARCHIVE_PUB_NOTIFICATION"
export const ARCHIVE_PUB_NOTIFICATION_SUCCESS = "ARCHIVE_PUB_NOTIFICATION_SUCCESS"

export const GET_NOTREAD_NOTIFICATIONS = "GET_NOTREAD_NOTIFICATIONS"
export const GET_NOTREAD_NOTIFICATIONS_SUCCESS = "GET_NOTREAD_NOTIFICATIONS_SUCCESS"
export const GET_NOTREAD_NOTIFICATIONS_FAIL = "GET_NOTREAD_NOTIFICATIONS_FAIL"

export const GET_ACTION_NOTIFICATIONS = "GET_ACTION_NOTIFICATIONS"
export const GET_ACTION_NOTIFICATIONS_SUCCESS = "GET_ACTION_NOTIFICATIONS_SUCCESS"
export const GET_ACTION_NOTIFICATIONS_FAIL = "GET_ACTION_NOTIFICATIONS_FAIL"