/* BRAND_AFFINITY */
export const LOAD_BAY = "LOAD_BAY"
export const NO_DATA_BAY = "NO_DATA_BAY"
export const GET_BRAND_AFFINITYS = "GET_BRAND_AFFINITYS"
export const GET_BRAND_AFFINITYS_SUCCESS = "GET_BRAND_AFFINITYS_SUCCESS"
export const GET_BRAND_AFFINITYS_FAIL = "GET_BRAND_AFFINITYS_FAIL"

export const ADD_BRAND_AFFINITY = "ADD_BRAND_AFFINITY"
export const ADD_BRAND_AFFINITY_SUCCESS = "ADD_BRAND_AFFINITY_SUCCESS"
export const ADD_BRAND_AFFINITY_ERROR = "ADD_BRAND_AFFINITY_ERROR"

export const UPDATE_BRAND_AFFINITY = "UPDATE_BRAND_AFFINITY"
export const UPDATE_BRAND_AFFINITY_SUCCESS = "UPDATE_BRAND_AFFINITY_SUCCESS"
export const UPDATE_BRAND_AFFINITY_ERROR = "UPDATE_BRAND_AFFINITY_ERROR"

export const READ_BRAND_AFFINITY = "READ_BRAND_AFFINITY"
export const READ_BRAND_AFFINITY_SUCCESS = "READ_BRAND_AFFINITY_SUCCESS"

export const STATUS_BRAND_AFFINITY = "STATUS_BRAND_AFFINITY"
export const STATUS_BRAND_AFFINITY_SUCCESS = "STATUS_BRAND_AFFINITY_SUCCESS"

export const ARCHIVE_BRAND_AFFINITY = "ARCHIVE_BRAND_AFFINITY"
export const ARCHIVE_BRAND_AFFINITY_SUCCESS = "ARCHIVE_BRAND_AFFINITY_SUCCESS"