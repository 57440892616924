export const GET_PUB_PLAYLIST = "GET_PUB_PLAYLIST"
export const GET_PUB_PLAYLIST_SUCCESS = "GET_PUB_PLAYLIST_SUCCESS"
export const GET_PUB_PLAYLIST_FAIL = "GET_PUB_PLAYLIST_FAIL"

export const GET_PUB_PLAYLISTBYID = "GET_PUB_PLAYLISTBYID"
export const GET_PUB_PLAYLISTBYID_SUCCESS = "GET_PUB_PLAYLISTBYID_SUCCESS"
export const GET_PUB_PLAYLISTBYID_FAIL = "GET_PUB_PLAYLISTBYID_FAIL"

export const CREATE_PUB_PLAYLIST = "CREATE_PUB_PLAYLIST"
export const CREATE_PUB_PLAYLIST_SUCCESS = "CREATE_PUB_PLAYLIST_SUCCESS"
export const CREATE_PUB_PLAYLIST_FAIL = "CREATE_PUB_PLAYLIST_FAIL"

export const UPDATE_PUB_PLAYLIST = "UPDATE_PUB_PLAYLIST"
export const UPDATE_PUB_PLAYLIST_SUCCESS = "UPDATE_PUB_PLAYLIST_SUCCESS"
export const UPDATE_PUB_PLAYLIST_FAIL = "UPDATE_PUB_PLAYLIST_FAIL"

export const STATUS_PUB_PLAYLIST = "STATUS_PUB_PLAYLIST"
export const STATUS_PUB_PLAYLIST_SUCCESS = "STATUS_PUB_PLAYLIST_SUCCESS"

export const ARCHIVE_PLAYLIST = "ARCHIVE_PLAYLIST"
export const ARCHIVE_PUB_PLAYLIST_SUCCESS = "ARCHIVE_PUB_PLAYLIST_SUCCESS"
export const ARCHIVE_PUB_PLAYLIST_FAIL = "ARCHIVE_PUB_PLAYLIST_FAIL"