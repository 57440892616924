/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Row, Col, Input, Label, Form, FormFeedback, Spinner, DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// actions
import {
  addNewAdUnitGroup as onADDAdUnitGroup,
  updateAdUnitGroup as onUpdateAdUnitGroup,
  readAdUnitGroup as onReadAdUnitGroup,
  addAdUnitGroupSuccess,
  addAdUnitGroupFail,
  updateAdUnitGroupSuccess,
  updateAdUnitGroupFail,
} from "store/Supply/AdUnitGroup/actions";

import {
  getPartners as onGetPartners,
} from "store/PartnerManagement/actions";

import {
  getPublisher as onGetPublishers,
} from "store/Supply/Publisher/actions";

import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";


const Create_AdUnitGroup = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [adUnitGroup, setAdUnitGroup] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [partnerData, setPartnerData] = useState([])
  const [publisherData, setPublisherData] = useState([])
  const [selectedPublisher, setSelectedPublisher] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(userInfo.userRole === 'Partner' ? { value: userInfo.userID, label: userInfo.name } : null);
  const nextRef = useRef();
  const [nextTab, setNextTab] = useState(false);
  const [btnprimary1, setBtnprimary1] = useState(false)
  const [adUnitType, setAdUnitType] = useState('');
  const [loader, setLoader] = useState(false);
  console.log(props)

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  useEffect(() => {
    dispatch(onGetPartners(null));
    dispatch(onGetPublishers(null));
  }, [])

  useEffect(() => {
    if (props.selectedAdUnitGroup !== '') {
      setIsEdit(true)
      setAdUnitGroup(props.selectedAdUnitGroup)
      getParentAdUnitGroup()
    }
    if (props.selectedPublisher !== '' || props.selectedAdUnitGroup === '') {
      setAdUnitGroup({ publisherID: props.selectedPublisher.publisherId })
      getParentAdUnitGroup()
      console.log()
    }
  }, []);

  const getParentAdUnitGroup = (publisherId) => {
    var filterBody = {
        "partnerID": 0,
        "publisherID": 0,
        "adUnitGroupID": 0,
        "adUnitID": 0,
        "childAdUnitID": 0,
        "playerSettingID": 0,
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name:userInfo.name,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner,
    }
    if (props.selectedAdUnitGroup !== '') {
      filterBody.publisherID = props.selectedAdUnitGroup.publisherID
    }
    if (props.selectedPublisher !== '') {
      filterBody.publisherID = props.selectedPublisher.publisherId
    }
    if (publisherId) {
      filterBody.publisherID = publisherId
    }
    axios.post(userInfo.isSupplyPartner ?"/v2.0/supplyPartner/getParent/SupplyPartner" :  "/v2.0/supply/getParent", filterBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("cdsresult is ==>", res)
        if (res.data.response) {
          setSelectedPublisher({ value: res.data.response.publisherID, label: res.data.response.publisherName });
          setSelectedPartner({ value: res.data.response.partnerID, label: res.data.response.username });
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      adUnitGroupName: (adUnitGroup && adUnitGroup.adUnitGroupName) || '',
      partnerID: (selectedPartner ? selectedPartner.value : '') || '',
      publisherID: (selectedPublisher ? selectedPublisher.value : '') || '',
      adUnitGroupData: {},
      adUnitGroupType: props.selectedAdUnitGroupType || props.selectedPublisher.adUnitGroupType
    },
    validationSchema: Yup.object({
      adUnitGroupName: Yup.string().required("AdUnitGroup Name is required"),
      partnerID: Yup.string().required("Partner is required"),
      publisherID: Yup.string().required("Publisher is required"),
    }),
    onSubmit: (values) => {
      console.log(values)
      setLoader(true)
      setAdUnitType(values.adUnitGroupType)
      if (props.selectedAdUnitGroup !== '') {
        dispatch(onUpdateAdUnitGroup({ ...values, adUnitGroupID: props.selectedAdUnitGroup.adUnitGroupID, adUnitGroupName: values.adUnitGroupName.trim(), userRole: userInfo.userRole, userID: userInfo.userID,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner,  }));
      } else {
        dispatch(onADDAdUnitGroup({ ...values, adUnitGroupName: values.adUnitGroupName.trim(), userRole: userInfo.userRole, userID: userInfo.userID,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner,  }));
      }
    }
  });

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.adUnitGroups.errorMsg,
    successMsg: state.adUnitGroups.successMsg,
  }));

  console.log(errorMsg, successMsg)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.statusCode === 200) {
        dispatch(addAdUnitGroupSuccess(""));
        dispatch(addAdUnitGroupFail(""));
        dispatch(updateAdUnitGroupSuccess(""));
        dispatch(updateAdUnitGroupFail(""));
        setLoader(false)
        if (nextTab) {
          props.closeCanvas('')
          nextRef.current.click()
        } else {
          if (props.selectedAdUnitGroup !== '') {
            props.closeCanvas('Update')
          } else {
            props.closeCanvas('Create')
          }
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if (errorMsg.status && errorMsg.status === 500) {
      } else {
        dispatch(addAdUnitGroupSuccess(""));
        dispatch(addAdUnitGroupFail(""));
        dispatch(updateAdUnitGroupSuccess(""));
        dispatch(updateAdUnitGroupFail(""));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addAdUnitGroupSuccess(""));
    dispatch(addAdUnitGroupFail(""));
    dispatch(updateAdUnitGroupSuccess(""));
    dispatch(updateAdUnitGroupFail(""));
    props.closeCanvas('')
  };

  const { partners } = useSelector(state => ({
    partners: state.partners.partners,
  }));

  const { publishers } = useSelector(state => ({
    publishers: state.publishers.publisherList,
  }));

  console.log(partners, publishers)

  useEffect(() => {
    if (partners !== null) {
      // dispatch(onGetPartners({type:props.selectedAdUnitGroupType}));
      dispatch(onGetPartners({ filter: 'Active',isSupplyPartner: userInfo.isSupplyPartner}));
    }
  }, []);

  useEffect(() => {
    if (publishers !== null) {
      console.log(publishers)
      // dispatch(onGetPublishers({partnerID:'',filter:'Active',type:props.selectedAdUnitGroupType}));
      dispatch(onGetPublishers({ partnerID: '', filter: 'Active', type: '', userRole: userInfo.userRole, userID: userInfo.userID,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner,pageSize: 0, pageNumber: 0, searchText: ''}));
    }
  }, []);

  useEffect(() => {
    if (partners && partners.length > 0) {
      setPartnerData(objectModal(partners))
      console.log(partners)
    }
  }, [partners]);

  useEffect(() => {
    if (publishers && publishers.length > 0) {
      setPublisherData(objectPublisherModal(publishers))
      console.log(publishers)
    }
    if (publishers && publishers.length === 0) {
      setPublisherData([])
    }
  }, [publishers]);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.partnerID
      obj.label = item.userName
      dataList.push(obj)
    })
    return dataList
  }

  const objectPublisherModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.publisherID
      obj.label = item.publisherName
      dataList.push(obj)
    })
    return dataList
  }

  function handleSelectPartner(selectPartner) {
    console.log(selectPartner)
    setSelectedPartner(selectPartner);
    // dispatch(onGetPublishers({partnerID:selectPartner.value,filter:'Active',type:props.selectedAdUnitGroupType}));
    dispatch(onGetPublishers({ partnerID: selectPartner.value, filter: 'Active', type: '', userRole: userInfo.userRole, userID: userInfo.userID,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner,pageSize: 0, pageNumber: 0, searchText: '' }));
    setSelectedPublisher(null)
  }

  function handleSelectPublisher(selectPublisher) {
    getParentAdUnitGroup(selectPublisher.value)
    console.log(selectPublisher)
    setSelectedPublisher(selectPublisher);
  }

  function allowAlphaNumericSpace(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault(); }
  };

  return (
    <React.Fragment>
      {loader &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>

        <Form onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); return false; }}>
          <Row form style={{ padding: '3%' }}>
            <Col xs={12}>
              {userInfo.userRole !== 'Partner' &&
                <div className="mb-3">
                  <Label>Partner <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                  <Select
                    value={selectedPartner}
                    onChange={s => {
                      handleSelectPartner(s)
                    }}
                    options={partnerData}
                    invalid={
                      validation.touched.partnerID && validation.errors.partnerID ? true : false
                    }
                    classNamePrefix="select2-selection"
                    className={(validation.touched.partnerID && validation.errors.partnerID ? ' is-invalid' : '')}
                    isDisabled={props.selectedArchived || isEdit || props.selectedPublisher !== ''}
                  />
                  {validation.touched.partnerID && validation.errors.partnerID ? (
                    <FormFeedback type="invalid">{validation.errors.partnerID}</FormFeedback>
                  ) : null}

                </div>
              }
              <div className="mb-3">
                <Label>Publisher <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Select
                  value={selectedPublisher}
                  onChange={s => {
                    handleSelectPublisher(s)
                  }}
                  options={publisherData}
                  invalid={
                    validation.touched.publisherID && validation.errors.publisherID ? true : false
                  }
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived || isEdit || props.selectedPublisher !== ''}
                  className={(validation.touched.partnerID && validation.errors.partnerID ? ' is-invalid' : '')}
                />
                {validation.touched.publisherID && validation.errors.publisherID ? (
                  <FormFeedback type="invalid">{validation.errors.publisherID}</FormFeedback>
                ) : null}

              </div>
              <div className="mb-3">
                <Label className="form-label">AdUnitGroup Name <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Input
                  name="adUnitGroupName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  onKeyPress={allowAlphaNumericSpace}
                  maxLength={65}
                  value={validation.values.adUnitGroupName || ""}
                  invalid={
                    validation.touched.adUnitGroupName && validation.errors.adUnitGroupName ? true : false
                  }
                  readOnly={props.selectedArchived}
                  className={(validation.touched.partnerID && validation.errors.partnerID ? ' is-invalid' : '')}
                />
                {validation.touched.adUnitGroupName && validation.errors.adUnitGroupName ? (
                  <FormFeedback type="invalid">{validation.errors.adUnitGroupName}</FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
            <Col>
              <div className="text-start">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
                &nbsp;&nbsp;
                {isEdit && !props.selectedArchived &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }
                &nbsp;&nbsp;
                {!isEdit &&
                  <div className="btn-group">
                    {validation.values.adUnitGroupType === 'video' &&
                      <Dropdown
                        isOpen={btnprimary1}
                        toggle={() => setBtnprimary1(!btnprimary1)}
                      >
                        <DropdownToggle tag="button" type="button" className="btn btn-primary">
                          <i className="mdi mdi-plus-circle-outline me-1" />Save & Add Ad Unit &nbsp; <i className="mdi mdi-chevron-down" />
                        </DropdownToggle>

                        <DropdownMenu>
                          <DropdownItem header>Video</DropdownItem>
                          <button type="submit" onClick={() => { setNextTab(true); setAdUnitType('play') }} style={{ border: 'none', background: 'none', margin: '0 15% 3%' }}>Player</button>
                          <br />
                          <button type="submit" onClick={() => { setNextTab(true); setAdUnitType('vast3.0') }} style={{ border: 'none', background: 'none', margin: '0 15% 3%' }} disabled>Vast</button>
                          <br />
                          <button type="submit" onClick={() => { setNextTab(true); setAdUnitType('vpaid2.0') }} style={{ border: 'none', background: 'none', margin: '0 15% 3%' }} disabled>VPaid 2.0</button>

                          {/* <DropdownItem divider />
                                    <DropdownItem header>Video</DropdownItem>
                                    <button type="submit" onClick={() => { setNextTab(true); setAdUnitGroupType('play') }} style={{ border: 'none', background: 'none', margin: '0 15% 3%' }}>Video</button>
                                    <br />
                                    <button type="submit" onClick={() => { setNextTab(true); setAdUnitGroupType('vast') }} style={{ border: 'none', background: 'none', margin: '0 15% 3%' }} disabled>Vast</button> */}
                        </DropdownMenu>
                      </Dropdown>
                    }
                    {validation.values.adUnitGroupType !== 'video' &&
                      <button
                        type="submit"
                        className="btn btn-primary save-user"
                        onClick={() => setNextTab(true)}
                      >
                        Save & Add Ad Unit
                      </button>
                    }
                  </div>
                }
                {nextTab && successMsg &&
                  <Link ref={nextRef} to={`/AdUnit?adUnitGroupId=${successMsg.response.AdUnitGroupData.adUnitGroupID}&adUnitGroupName=${successMsg.response.AdUnitGroupData.adUnitGroupName}&adUnitType=${adUnitType}&status=AdUnitCreateByAdUnitGroup`}  > </Link>
                }
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
          <Toaster status="error" msg={errorMsg.message} />
        }
      </div>
      {errorMsg !== null && errorMsg !== '' && errorMsg.status && errorMsg.status === 500 &&
        <div className="p-0 justify-content-center d-flex">
          <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')} />
        </div>
      }
    </React.Fragment>
  );
};

export default withRouter(Create_AdUnitGroup);

Create_AdUnitGroup.propTypes = {
  history: PropTypes.object,
};