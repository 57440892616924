/* INTEREST */
export const LOAD_INTERESTS = "LOAD_INTERESTS"
export const NO_DATA_INTERESTS = "NO_DATA_INTERESTS"
export const GET_INTERESTS = "GET_INTERESTS"
export const GET_INTERESTS_SUCCESS = "GET_INTERESTS_SUCCESS"
export const GET_INTERESTS_FAIL = "GET_INTERESTS_FAIL"

export const ADD_INTEREST = "ADD_INTEREST"
export const ADD_INTEREST_SUCCESS = "ADD_INTEREST_SUCCESS"
export const ADD_INTEREST_ERROR = "ADD_INTEREST_ERROR"

export const UPDATE_INTEREST = "UPDATE_INTEREST"
export const UPDATE_INTEREST_SUCCESS = "UPDATE_INTEREST_SUCCESS"
export const UPDATE_INTEREST_ERROR = "UPDATE_INTEREST_ERROR"

export const READ_INTEREST = "READ_INTEREST"
export const READ_INTEREST_SUCCESS = "READ_INTEREST_SUCCESS"

export const STATUS_INTEREST = "STATUS_INTEREST"
export const STATUS_INTEREST_SUCCESS = "STATUS_INTEREST_SUCCESS"

export const ARCHIVE_INTEREST = "ARCHIVE_INTEREST"
export const ARCHIVE_INTEREST_SUCCESS = "ARCHIVE_INTEREST_SUCCESS"