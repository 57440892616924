import {
    GET_VIDEO_CONTENTS_SUCCESS,
    GET_VIDEO_CONTENTS_FAIL,
    ADD_VIDEO_CONTENT_SUCCESS,
    ADD_VIDEO_CONTENT_ERROR,
    LOAD_VIDEO_CONTENTS,
    DELETE_VIDEO_CONTENT_SUCCESS,
    DELETE_VIDEO_CONTENT_ERROR
} from "./actionTypes"

const INIT_STATE = {
    vcons: [],
    error: {},
    playlistErr : null,
    queuedMsg: null,
    successMsg: null,
    errorMsg: null,
    videoContent: null,
    response: null,
    loading: false
} 

const videoContents = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_VIDEO_CONTENTS:
            return {
                ...state,
                loading: true,
            }
        case GET_VIDEO_CONTENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                vcons: action.payload,
            }

        case GET_VIDEO_CONTENTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case ADD_VIDEO_CONTENT_SUCCESS:

            return {
                ...state,
                loading: false,
                successMsg: action.payload,
            }
        case ADD_VIDEO_CONTENT_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
                errorMsg: action.type === 'GET_VIDEO_CONTENTS_SUCCESS' ? null : action.payload,
            }
        case DELETE_VIDEO_CONTENT_SUCCESS:

            return {
                ...state,
                loading: false,
                queuedMsg: action.payload,
            }
        case DELETE_VIDEO_CONTENT_ERROR:
            return {
                ...state,
                loading: false,
                playlistErr: action.payload,
                errorMsg: action.type === 'GET_VIDEO_CONTENTS_SUCCESS' ? null : action.payload,
            }

        default:
            return state
    }
}

export default videoContents