import {
  LOAD_ACTIVITIES,
  NO_DATA_PARTNER,
    GET_PARTNERS,
    GET_PARTNERS_FAIL,
    GET_PARTNERS_SUCCESS,
    ADD_PARTNER,
    ADD_PARTNER_SUCCESS,
    ADD_PARTNER_ERROR,
    UPDATE_PARTNER,
    UPDATE_PARTNER_SUCCESS,
    UPDATE_PARTNER_ERROR,
    READ_PARTNER,
    READ_PARTNER_SUCCESS,
    STATUS_PARTNER,
    STATUS_PARTNER_SUCCESS,
    ARCHIVE_PARTNER ,
    ARCHIVE_PARTNER_SUCCESS,

    GET_USERS,
    GET_USERS_FAIL,
    GET_USERS_SUCCESS,
    ADD_USER,
    ADD_USER_SUCCESS,
    ADD_USER_ERROR,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,

    GET_PARTNERS_LIST,
    GET_PARTNERS_SUCCESS_LIST,
    GET_PARTNERS_FAIL_LIST

  } from "./actionTypes"

  export const loadActivities = Params => ({
    type: LOAD_ACTIVITIES,
    payload:Params
  })

  export const noData = Params => ({
    type: NO_DATA_PARTNER,
    payload:Params
  })

  export const getPartners = Params => ({
    type: GET_PARTNERS,
    payload:Params
  })
  
  export const getPartnersSuccess = Partners => ({
    type: GET_PARTNERS_SUCCESS,
    payload: Partners,
  })

  export const getPartnersFail = error => ({
    type: GET_PARTNERS_FAIL,
    payload: error,
  })

  export const getPartnersList = Params => ({
    type: GET_PARTNERS_LIST,
    payload:Params
  })
  
  export const getPartnersListSuccess = Partners => ({
    type: GET_PARTNERS_SUCCESS_LIST,
    payload: Partners,
  })

  export const getPartnersListFail = error => ({
    type: GET_PARTNERS_FAIL_LIST,
    payload: error,
  })

  export const addNewPartner = Partner => ({
    type: ADD_PARTNER,
    payload: Partner,
  })
  
  export const addPartnerSuccess = Partner => ({
    type: ADD_PARTNER_SUCCESS,
    payload: Partner,
  })
  
  export const addPartnerFail = Partner => ({
    type: ADD_PARTNER_ERROR,
    payload: Partner,
  })

  export const updatePartner = Partner => ({
    type: UPDATE_PARTNER,
    payload: Partner,
  })
  
  export const updatePartnerSuccess = Partner => ({
    type: UPDATE_PARTNER_SUCCESS,
    payload: Partner,
  })
  
  export const updatePartnerFail = Partner => ({
    type: UPDATE_PARTNER_ERROR,
    payload: Partner,
  })

  export const readPartner = Partner => ({
    type: READ_PARTNER,
    payload: Partner,
  })

  export const readPartnerSuccess = Partner => ({
    type: READ_PARTNER_SUCCESS,
    payload: Partner,
  })

  export const statusPartner = Partner => ({
    type: STATUS_PARTNER,
    payload: Partner,
  })

  export const statusPartnerSuccess = Partner => ({
    type: STATUS_PARTNER_SUCCESS,
    payload: Partner,
  })

  export const archivePartner = Partner => ({
    type: ARCHIVE_PARTNER,
    payload: Partner,
  })

  export const archivePartnerSuccess = Partner => ({
    type: ARCHIVE_PARTNER_SUCCESS,
    payload: Partner,
  })

  export const getUsers = Params => ({
    type: GET_USERS,
    payload:Params
  })
  
  export const getUsersSuccess = Users => ({
    type: GET_USERS_SUCCESS,
    payload: Users,
  })

  export const getUsersFail = error => ({
    type: GET_USERS_FAIL,
    payload: error,
  })

  export const addNewUser = User => ({
    type: ADD_USER,
    payload: User,
  })
  
  export const addUserSuccess = User => ({
    type: ADD_USER_SUCCESS,
    payload: User,
  })
  
  export const addUserFail = User => ({
    type: ADD_USER_ERROR,
    payload: User,
  })

  export const updateUser = User => ({
    type: UPDATE_USER,
    payload: User,
  })
  
  export const updateUserSuccess = User => ({
    type: UPDATE_USER_SUCCESS,
    payload: User,
  })
  
  export const updateUserFail = User => ({
    type: UPDATE_USER_ERROR,
    payload: User,
  })