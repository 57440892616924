import {
  LOAD_ACTIVITIES,
  NO_DATA_PUB_NOTIFICATIONS,
    GET_PUB_NOTIFICATIONS,
    GET_PUB_NOTIFICATIONS_FAIL,
    GET_PUB_NOTIFICATIONS_SUCCESS,
    ADD_PUB_NOTIFICATION,
    ADD_PUB_NOTIFICATION_SUCCESS,
    ADD_PUB_NOTIFICATION_ERROR,
    UPDATE_PUB_NOTIFICATION,
    UPDATE_PUB_NOTIFICATION_SUCCESS,
    UPDATE_PUB_NOTIFICATION_ERROR,
    READ_PUB_NOTIFICATION,
    READ_PUB_NOTIFICATION_SUCCESS,
    STATUS_PUB_NOTIFICATION,
    STATUS_PUB_NOTIFICATION_SUCCESS,
    ARCHIVE_PUB_NOTIFICATION ,
    ARCHIVE_PUB_NOTIFICATION_SUCCESS,

    GET_NOTREAD_NOTIFICATIONS,
    GET_NOTREAD_NOTIFICATIONS_SUCCESS,
    GET_NOTREAD_NOTIFICATIONS_FAIL,

    GET_ACTION_NOTIFICATIONS,
    GET_ACTION_NOTIFICATIONS_FAIL,
    GET_ACTION_NOTIFICATIONS_SUCCESS,

  } from "./actionTypes"

  export const loadActivitiesPub_Notifications = Params => ({
    type: LOAD_ACTIVITIES,
    payload:Params
  })

  export const noNotifications = Params => ({
    type: NO_DATA_PUB_NOTIFICATIONS,
    payload:Params
  })

  export const getPub_Notifications = Params => ({
    type: GET_PUB_NOTIFICATIONS,
    payload:Params
  })
  
  export const getPub_NotificationsSuccess = Pub_Notifications => ({
    type: GET_PUB_NOTIFICATIONS_SUCCESS,
    payload: Pub_Notifications,
  })

  export const getPub_NotificationsFail = error => ({
    type: GET_PUB_NOTIFICATIONS_FAIL,
    payload: error,
  })

  export const getAction_Notifications = Params => ({
    type: GET_ACTION_NOTIFICATIONS,
    payload:Params
  })
  
  export const getAction_NotificationsSuccess = Pub_Notifications => ({
    type: GET_ACTION_NOTIFICATIONS_SUCCESS,
    payload: Pub_Notifications,
  })

  export const getAction_NotificationsFail = error => ({
    type: GET_ACTION_NOTIFICATIONS_FAIL,
    payload: error,
  })

  export const getNotRead_Notifications = Params => ({
    type: GET_NOTREAD_NOTIFICATIONS,
    payload:Params
  })
  
  export const getNotRead_NotificationsSuccess = NotRead_Notifications => ({
    type: GET_NOTREAD_NOTIFICATIONS_SUCCESS,
    payload: NotRead_Notifications,
  })

  export const getNotRead_NotificationsFail = error => ({
    type: GET_NOTREAD_NOTIFICATIONS_FAIL,
    payload: error,
  })

  export const addNewPub_Notification = Pub_Notification => ({
    type: ADD_PUB_NOTIFICATION,
    payload: Pub_Notification,
  })
  
  export const addPub_NotificationSuccess = notification => ({
    type: ADD_PUB_NOTIFICATION_SUCCESS,
    payload: notification,
  })
  
  export const addPub_NotificationFail = notification => ({
    type: ADD_PUB_NOTIFICATION_ERROR,
    payload: notification,
  })

  export const updatePub_Notification = notification => ({
    type: UPDATE_PUB_NOTIFICATION,
    payload: notification,
  })
  
  export const updatePub_NotificationSuccess = notification => ({
    type: UPDATE_PUB_NOTIFICATION_SUCCESS,
    payload: notification,
  })
  
  export const updatePub_NotificationFail = notification => ({
    type: UPDATE_PUB_NOTIFICATION_ERROR,
    payload: notification,
  })

  export const readPub_Notification = notification => ({
    type: READ_PUB_NOTIFICATION,
    payload: notification,
  })

  export const readPub_NotificationSuccess = notification => ({
    type: READ_PUB_NOTIFICATION_SUCCESS,
    payload: notification,
  })

  export const statusPub_Notification = notification => ({
    type: STATUS_PUB_NOTIFICATION,
    payload: notification,
  })

  export const statusPub_NotificationSuccess = notification => ({
    type: STATUS_PUB_NOTIFICATION_SUCCESS,
    payload: notification,
  })

  export const archivePub_Notification = notification => ({
    type: ARCHIVE_PUB_NOTIFICATION,
    payload: notification,
  })

  export const archivePub_NotificationSuccess = notification => ({
    type: ARCHIVE_PUB_NOTIFICATION_SUCCESS,
    payload: notification,
  })