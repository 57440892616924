/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback, Spinner, Button, Tooltip } from "reactstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import "./Ads_Txt.css"


// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";


const Ads_Txt_Crawled = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  console.log(props)

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isCopyTooltipOpen, setCopyIsTooltipOpen] = useState(false);
  const [isTooltipOpen1, setIsTooltipOpen1] = useState(false);

  const onToggleTooltip = (e) => {
    setIsTooltipOpen(!isTooltipOpen)
  }

  const onCopyToggleTooltip = (e) => {
    setCopyIsTooltipOpen(!isCopyTooltipOpen)
  }

  const onToggleTooltip1 = (e) => {
    setIsTooltipOpen1(!isTooltipOpen1)
  }

  const [isLatestTooltipOpen, setIsLatestTooltipOpen] = useState(false);
  const [isCopyLatestTooltipOpen, setCopyLatestIsTooltipOpen] = useState(false);
  const [isLatestTooltipOpen1, setLatestIsTooltipOpen1] = useState(false);
  const [errorsState, setErrorsState] = useState(false);

  const onLatestToggleTooltip = (e) => {
    setIsLatestTooltipOpen(!isLatestTooltipOpen)
  }

  const onCopyLatestToggleTooltip = (e) => {
    setCopyLatestIsTooltipOpen(!isCopyLatestTooltipOpen)
  }

  const onToggleLatestTooltip1 = (e) => {
    setLatestIsTooltipOpen1(!isLatestTooltipOpen1)
  }

  const [isErrorTooltipOpen, setIsErrorTooltipOpen] = useState(false);

  const onErrorToggleTooltip = (e) => {
    setIsErrorTooltipOpen(!isErrorTooltipOpen)
  }

  const copyCrawled = (data) => (e) => {
    setCopyIsTooltipOpen(false)
    const cb = navigator.clipboard;
    const paragraph = document.getElementById(data);
    cb.writeText(paragraph.innerText)
  }

  const downloadTxtFile = (data, name, crawled) => (e) => {
    const element = document.createElement("a");
    const paragraph = document.getElementById(data).innerText
    const file = new Blob([paragraph], {
      type: "text/plain"
    });
    element.href = URL.createObjectURL(file);
    element.download = ((name + crawled) + ".txt");
    document.body.appendChild(element);
    element.click();
  };

  return (
    <React.Fragment>
      {loader &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="row" style={{ pointerEvents: (loader) ? 'none' : 'unset', padding: '3%' }}>

        <div className="col-5">
          <div className="row mb-2">
            <div className="col-md-8">
              <h5>Previous Crawled Data</h5>
            </div>
            {/* <div className="col-md-1">
              <span className=" " id="controlledTooltip" onClick={copyCrawled('previousCrawled')} onMouseLeave={() => {
                setIsTooltipOpen(false)
              }} style={{ cursor: 'pointer' }}>
                <i
                  className="bx bx-copy-alt font-size-18"
                  id="edittooltip"
                ></i>
              </span>
            </div>
            <div className="col-md-1">
              <span className="" id="controlledTooltip1" onClick={downloadTxtFile('previousCrawled', props.selectedAdsTxtEntries.AdsTxtEntrySite, " Previous Crawled")} onMouseLeave={() => {
                setIsTooltipOpen1(false)
              }} style={{ cursor: 'pointer' }}>
                <i
                  className="bx bx-download font-size-18"
                  id="edittooltip"
                ></i>
              </span>
            </div> */}
            <div className="col-md-2" style={{width: "10%"}}>
              <Button className="primary " size="sm"  id="controlledTooltip" onClick={copyCrawled('previousCrawled')}>copy</Button>
            </div>
            <div className="col-md-2">
              <Button className="primary mr-3" size="sm" id="controlledTooltip1" onClick={downloadTxtFile('previousCrawled', props.selectedAdsTxtEntries.AdsTxtEntrySite, " Previous Crawled")}>download</Button>
            </div>
          </div>
          {/* <Tooltip
            isOpen={isCopyTooltipOpen}
            placement="top"
            target="controlledTooltip"
            toggle={onCopyToggleTooltip}
            trigger="hover"
          >
            Copy Previous Crawled data
          </Tooltip> */}
          <ReactTooltip 
            anchorSelect="#controlledTooltip"
            place="bottom"
            variant="dark"
            openOnClick="true"
            delayHide={800}
            content="Copied Previous Crawled data"
            appendToBody={true}
          />
          {/* <Tooltip
            isOpen={isTooltipOpen}
            placement="top"
            target="controlledTooltip"
            toggle={onToggleTooltip}
            trigger="click"
          >
            Copied
          </Tooltip> */}
          <ReactTooltip 
            anchorSelect="#controlledTooltip1"
            place="bottom"
            variant="dark"
            content="Download Previous Crawled data"

          />
          {/* <Tooltip
            isOpen={isTooltipOpen1}
            placement="top"
            target="controlledTooltip1"
            toggle={onToggleTooltip1}
            trigger="hover"
          >
            Download Previous Crawled data
          </Tooltip> */}
          <div className="mb-3 mt-2 scrollbar-adtxt" id="previousCrawled" style={{ border: '1px solid #e9ecef', height: '62vh', overflow: 'auto', padding: '2%', borderRadius: '5px' }}>
            {props.selectedAdsTxtEntries.PreviousCronAdsTxtEntriesValidationData.map((subItem, i) => {
              return (
                <div className="crawledData" key={i}>
                  <div className="row mt-2">
                    <span>{i + 1}. &nbsp;&nbsp; {subItem}</span>
                  </div>
                </div>
              )
            })
            }
          </div>
          <h6>Previous Crawled on : {props.selectedAdsTxtEntries.PreviousCronAdsTxtEntryTimeChecked}</h6>

        </div>
        <div className="col-1">
          <div className="mb-3">
          </div>
        </div>
        <div className="col-5">
          <div className="row mb-2">
            <div className="col-md-8">
              <h5>Latest Crawled Data</h5>
            </div>
            {/* <div className="col-md-1">
              <span className=" " id="controlledTooltip3" onClick={copyCrawled('latestCrawled')} onMouseLeave={() => {
                setIsLatestTooltipOpen(false)
              }} style={{ cursor: 'pointer' }}>
                <i
                  className="bx bx-copy-alt font-size-18"
                  id="edittooltip"
                ></i>
              </span>
            </div>
            <div className="col-md-1">
              <span className="" id="controlledTooltip4" onClick={downloadTxtFile('latestCrawled', props.selectedAdsTxtEntries.AdsTxtEntrySite, " Latest Crawled")} onMouseLeave={() => {
                setLatestIsTooltipOpen1(false)
              }} style={{ cursor: 'pointer' }}>
                <i
                  className="bx bx-download font-size-18"
                  id="edittooltip" 
                ></i>
              </span>
            </div> */}
            <div className="col-md-2" style={{width: "10%"}}>
              <Button className="primary " size="sm"  id="controlledTooltip3" onClick={copyCrawled('latestCrawled')}>copy</Button>
            </div>
            <div className="col-md-2">
              <Button className="primary mr-3" size="sm" id="controlledTooltip4" onClick={downloadTxtFile('latestCrawled', props.selectedAdsTxtEntries.AdsTxtEntrySite, " Latest Crawled")}>download</Button>
            </div>
          </div>
          <ReactTooltip 
            anchorSelect="#controlledTooltip3"
            place="bottom"
            variant="dark"
            openOnClick="true"
            delayHide={800}
            content="Copied Latest Crawled data"
            appendToBody={true}
          />
          <ReactTooltip 
            anchorSelect="#controlledTooltip4"
            place="bottom"
            variant="dark"
            content="Download Latest Crawled data"
          />
          {/* <Tooltip
            isOpen={isCopyLatestTooltipOpen}
            placement="top"
            target="controlledTooltip3"
            toggle={onCopyLatestToggleTooltip}
            trigger="hover"
          >
            Copy Latest Crawled data
          </Tooltip>
          <Tooltip
            isOpen={isLatestTooltipOpen}
            placement="top"
            target="controlledTooltip3"
            toggle={onLatestToggleTooltip}
            trigger="click"
          >
            Copied
          </Tooltip>
          <Tooltip
            isOpen={isLatestTooltipOpen1}
            placement="top"
            target="controlledTooltip4"
            toggle={onToggleLatestTooltip1}
            trigger="hover"
          >
            Download Latest Crawled data
          </Tooltip> */}
          <div className="mb-3 mt-2 scrollbar-adtxt" id="latestCrawled" style={{ border: '1px solid #e9ecef', height: '62vh', overflow: 'auto', padding: '2%', borderRadius: '5px' }}>
            {
              props.selectedAdsTxtEntries.TodaysCronAdsTxtEntriesValidationData.map((subItem, i) => {
                return (
                  <div className="crawledData row mt-2" key={i}>
                    {!subItem.found &&
                      <span className="col-1"> <span className="radioStyle high text-center"></span> </span>
                    }
                    {subItem.found &&
                      <span className="col-1"> <span className="radioStyle low text-center"></span></span>
                    }
                    <span className="col-11">{i + 1}. &nbsp;&nbsp; {subItem.adsTXT}</span>
                  </div>
                )
              })
            }
          </div>
          <h6>Latest Crawled on : {props.selectedAdsTxtEntries.TodayCronAdsTxtEntryTimeChecked}</h6>
          <Row>
            <Col xs={12}>
              <div className="text-sm-end">
                <Button
                  color="primary"
                  className="font-16 btn-block btn btn-primary mt-3"
                  id="errorControlledTooltip"
                  onClick={copyCrawled('latestErrorsCrawled')} onMouseLeave={() => {
                    setIsErrorTooltipOpen(false)
                  }}
                >
                  Copy Latest Errors
                </Button> &nbsp;&nbsp;
                <Button
                  color="primary"
                  className="font-16 btn-block btn btn-primary mt-3"
                  onClick={downloadTxtFile('latestErrorsCrawled', props.selectedAdsTxtEntries.AdsTxtEntrySite, " Latest Errors")}
                >
                  Download Latest Errors File
                </Button>
              </div>

            </Col>
            <Tooltip
              isOpen={isErrorTooltipOpen}
              placement="top"
              target="errorControlledTooltip"
              toggle={onErrorToggleTooltip}
              trigger="click"
            >
              Copied
            </Tooltip>
          </Row>
          <div className="m-0 p-0 " id="latestErrorsCrawled" style={{ height: '0px', width: '0px', overflow: 'auto', }}>
            {
              props.selectedAdsTxtEntries.TodaysCronAdsTxtEntriesNotFoundRecords.length > 0 && props.selectedAdsTxtEntries.TodaysCronAdsTxtEntriesNotFoundRecords.map((subItem, i) => {
                return (
                  <div className="crawledData row mt-2" key={i}>
                    <span className="col-11">{i + 1}. &nbsp;&nbsp; {subItem}</span>
                  </div>
                )
              })
            }
          </div>

        </div>

      </div>

    </React.Fragment>
  );
};

Ads_Txt_Crawled.propTypes = {}

export default Ads_Txt_Crawled




