import PropTypes from "prop-types";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import React, { useState, useEffect } from "react";

import {
  Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
} from "reactstrap";


//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link, Redirect } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import user1 from "assets/images/users/avatar-1.jpg"

// actions
import { loginUser, loginUsers, apiError, socialLogin } from "../../store/actions";

// import images
import logodark from "../../assets/images/favicons.png";

//Import config
import { facebook, google } from "../../config";
import CarouselPage from "pages/Authentication/CarouselPage";
import Footer from "components/VerticalLayout/Footer";
import Select from "react-select";


const GoogleLoginApp = props => {
  const dispatch = useDispatch();
  const [passwordShown, setPasswordShown] = useState(false);
  const [steps, setSteps] = useState(1);
  const [menu, setMenu] = useState(false)
  const [menu2, setMenu2] = useState(false)
  const [userInformation, setUserInformation] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    gender: '',
    username: '',
    password: '',
    confirm: '',
    phoneNumber: '',
    code: '',
    month: '',
    day: '',
    year: ''
  })

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInformation(userInformation => ({ ...userInformation, [name]: value }));
  }

  const stepIncrease = (e) => {
    setSteps(steps + 1)
  }

  const stepIncreaseforCreateAccount = (e) => {
    setSteps(3)
  }

  useEffect(() => {

    localStorage.removeItem('authUser');
    localStorage.removeItem('UserRole');
    localStorage.removeItem('ParentRole');
    localStorage.removeItem('Terms');
    localStorage.removeItem('NotificationStatus');
    localStorage.removeItem('UserRolePrivileges');
    localStorage.removeItem('previousNavigation');
    localStorage.removeItem('UserSwitching');
    localStorage.removeItem('AdminDetails');
    localStorage.removeItem('activeTab');
    localStorage.removeItem('InventoryType');
    localStorage.removeItem('userSessionState');
    localStorage.removeItem('darkMode');
    localStorage.setItem("loginStatus", 'LoggedOut');
    localStorage.setItem("alertStatus", 'closed');
    dispatch(apiError(null));
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUsers(values, props.history, 'login'));
    }
  });

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

  // const signIn = (res, type) => {
  //   if (type === "google" && res) {
  //     const postData = {
  //       name: res.profileObj.name,
  //       username: res.profileObj.username,
  //       token: res.tokenObj.access_token,
  //       idToken: res.tokenId,
  //     };
  //     dispatch(socialLogin(postData, props.history, type));
  //   } else if (type === "facebook" && res) {
  //     const postData = {
  //       name: res.name,
  //       username: res.username,
  //       token: res.accessToken,
  //       idToken: res.tokenId,
  //     };
  //     dispatch(socialLogin(postData, props.history, type));
  //   }
  // };

  //handleGoogleLoginAppResponse
  // const googleResponse = response => {
  //   signIn(response, "google");
  // };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  // const facebookResponse = response => {
  //   signIn(response, "facebook");
  // };

  const [selectedLanguage, setSelectedLanguage] = useState({ value: 'English (United States)', label: "English (United States)" });
  const [languageData, setSizeData] = useState([
    { value: 'Tamil', label: "Tamil" },
    { value: 'telugu', label: "telugu" },
    { value: 'Kannada', label: "Kannada" },
    { value: 'Marathi', label: "Marathi" },
    { value: 'Hindi', label: "Hindi" },
    { value: 'English (United States)', label: "English (United States)" },
    { value: "Bengal", label: "Bengal" },
    { value: "Chinese", label: "Chinese" },
    { value: "Punjabi", label: "Punjabi" },])

  function handleSelectLanguage(selectLanguage) {
    setSelectedLanguage(selectLanguage);
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Login | Bidsxchange</title>
      </Helmet>
      <Container fluid className="p-0">
        <Row className="g-0">
          <Col md={12} style={{ backgroundColor: 'white' }}>

            <div className="auth-full-page-content googleLogin justify-content-center flex-column p-md-5 p-4">
              <Card className="loginCard" >
                <CardBody>
                  <Row>
                    <Col md={5} sm={12}>
                      <div className="d-flex flex-column">
                        <div className="mb-1 mb-md-2">
                          <h4>
                            <img
                              src={logodark}
                              alt=""
                              height="50"
                              className="auth-logo-dark"
                            />
                            {/* &nbsp;&nbsp; BIDSXCHANGE */}
                          </h4>
                        </div>
                        {steps === 1 &&
                          <div>
                            <h2 className="text-white ">Sign in</h2>
                            <p className="text-white ">
                                 to continue to Bidsxchange
                            </p>
                          </div>
                        }
                        {steps === 2 &&
                          <div>
                            <h2 className="text-white ">Welcome</h2>
                            <img
                                  className="rounded-circle header-profile-user"
                                  src={user1}
                                  alt="Header Avatar"
                                />
                                <span className="d-none d-xl-inline-block ms-2 me-1 text-white">{validation.values.username || ""}</span>
                          </div>
                        }
                      </div>
                    </Col>
                    <Col md={7} sm={12}>
                      <div className="my-auto">
                        <div className="mt-4">
                          <Form className="form-horizontal"
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            {error ? <Alert color="danger">{error}</Alert> : null}
                            {steps === 1 &&

                              <>
                                <div style={{ marginTop: '3.7pc' }}>
                                  <Input
                                    name="username"
                                    className="form-control"
                                    placeholder="Email or Phone"
                                    type="email"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.username || ""}
                                    style={{ lineHeight: '2' }}
                                    invalid={
                                      (validation.touched.username && validation.errors.username) && (!menu2) ? true : false
                                    }
                                  />
                                  {(validation.touched.username && validation.errors.username) && (!menu2) ? (
                                    <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                                  ) : null}
                                </div>
                                <div className="mt-3 text-left">
                                <Input
                                    name="password"
                                    value={validation.values.password || ""}
                                    type={passwordShown ? "text" : "password"}
                                    placeholder="Enter your Password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.password && validation.errors.password ? true : false
                                    }
                                    style={{ lineHeight: '2' }}
                                  />
                                  {validation.touched.password && validation.errors.password ? (
                                    <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                  ) : null}
                                </div>
                                <div className="mt-1 text-left">
                                  <div className="form-check mt-2 mb-2">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={passwordShown}
                                      onChange={(e) => setPasswordShown(e.target.checked)}
                                      id="supplyPartner"
                                    />
                                    <label
                                      className="form-check-label  m-2 mt-1 text-white"
                                      htmlFor="supplyPartner"
                                    >
                                      Show Password
                                    </label>
                                  </div>
                                </div>
                                <div className=" d-flex flex-row justify-content-end nextButton"  >
                                  <Link to="/forgot-password" className="text-white" style={{ margin: 'auto 3rem' }}>
                                    Forgot Password?
                                  </Link> &nbsp;&nbsp;&nbsp;
                                  <button
                                    className="btn btn-primary btn-block btn-rounded"  style={{ minWidth: '5rem' }}
                                    type="submit" disabled={validation.values.password === ""}
                                  >
                                    Sign In
                                  </button>
                                </div>
                              </>

                            }
                            {steps === 2 &&
                              <>
                                <div style={{ marginTop: '3.7pc' }}>
                                  <Input
                                    name="password"
                                    value={validation.values.password || ""}
                                    type={passwordShown ? "text" : "password"}
                                    placeholder="Enter your Password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.password && validation.errors.password ? true : false
                                    }
                                    style={{ lineHeight: '2' }}
                                  />
                                  {validation.touched.password && validation.errors.password ? (
                                    <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                  ) : null}
                                </div>
                                <div className="mt-1 text-left">
                                  <div className="form-check mt-2 mb-2">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={passwordShown}
                                      onChange={(e) => setPasswordShown(e.target.checked)}
                                      id="supplyPartner"
                                    />
                                    <label
                                      className="form-check-label  m-2 mt-1"
                                      htmlFor="supplyPartner"
                                    >
                                      Show Password
                                    </label>
                                  </div>
                                </div>
                                <div className=" d-flex flex-row justify-content-end nextButton"  >
                                  <Link to="/forgot-password" className="text-white" style={{ margin: 'auto 3rem' }}>
                                    Forgot Password?
                                  </Link> &nbsp;&nbsp;&nbsp;
                                  <button
                                    className="btn btn-primary btn-block btn-rounded"  style={{ minWidth: '5rem' }}
                                    type="submit" disabled={validation.values.password === ""}
                                  >
                                    Sign In
                                  </button>
                                </div>
                              </>
                            }
                          </Form>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <br />
              <div className="loginCardFooter" >
                <Row>
                  <Col md={4}>
                    {/* <Select
                      value={selectedLanguage}
                      onChange={s => {
                        handleSelectLanguage(s)
                      }}
                      options={languageData}
                      classNamePrefix="select2-selection"
                    /> */}
                  </Col>
                  <Col md={8}>
                    <div className="d-flex justify-content-end ">
                    <a href="https://streamlyn.com/cookie-policy/" rel="noreferrer" className="text-white" style={{ margin: '0 2.5rem' }}  target="_blank">Cookie Policy</a>
                     &nbsp;&nbsp;&nbsp;
                      <a href="https://streamlyn.com/privacy-policy/" rel="noreferrer" className="text-white" target="_blank">Privacy Policy</a>&nbsp;&nbsp;&nbsp;
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            {/* <footer className="footer" style={{textAlign:'end'}}>
              <Container fluid={true}>
                <Row>
                  <Col md={6}>
                    <div className="text-sm-start d-flex">
                      <Col className="d-flex">
                        <a href="https://streamlyn.com/cookie-policy/" rel="noreferrer" className="under-line-hyper-link"  target="_blank">Cookie Policy</a>
                        &nbsp;|&nbsp;
                        <a href="https://streamlyn.com/privacy-policy/" rel="noreferrer" className="under-line-hyper-link"  target="_blank">Privacy Policy</a>
                      </Col>
                    </div>
                  </Col>
                  <Col md={6}> 2015 - {new Date().getFullYear()} © Bidsxchange.</Col>
                </Row>
              </Container>
            </footer> */}
          </Col>
        </Row>
      </Container>
    </React.Fragment >
  );
};

export default withRouter(GoogleLoginApp);

GoogleLoginApp.propTypes = {
  history: PropTypes.object,
};
