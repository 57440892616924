import {
    LOAD_ACTIVITIES,
    NO_DATA,
    GET_BIDDERS_SUCCESS,
    GET_BIDDERS_FAIL,
    ADD_BIDDER_SUCCESS,
    ADD_BIDDER_ERROR,
    UPDATE_BIDDER_SUCCESS,
    UPDATE_BIDDER_ERROR,
    READ_BIDDER_SUCCESS,
    STATUS_BIDDER_SUCCESS,
    ARCHIVE_BIDDER_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    bidders: [],
    error: {},
    loading: false,
    noData: false,
    successMsg: null,
    errorMsg: null,
    bidder: null,
    response: null,
    archiveMsg: null,
}

const bidders = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_ACTIVITIES:
            return {
                ...state,
                loading: true,
            }
        case NO_DATA:
            return {
                ...state,
                noData: true,
            }
        case GET_BIDDERS_SUCCESS:
            return {
                ...state,
                loading: false,
                bidders: action.payload,
            }

        case GET_BIDDERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case ADD_BIDDER_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_BIDDER_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_BIDDERS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_BIDDER_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_BIDDERS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_BIDDER_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_BIDDERS_SUCCESS' ? null : action.payload,
            }
        case READ_BIDDER_SUCCESS:
            return {
                ...state,
                bidder: action.payload,
            }
        case STATUS_BIDDER_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_BIDDER_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default bidders