/* WEBSITE_CATEGORIZATION */
export const LOAD_WBCTG = "LOAD_WBCTG"
export const NO_DATA_WBCTG = "NO_DATA_WBCTG"
export const GET_WEBSITE_CATEGORIZATIONS = "GET_WEBSITE_CATEGORIZATIONS"
export const GET_WEBSITE_CATEGORIZATIONS_SUCCESS = "GET_WEBSITE_CATEGORIZATIONS_SUCCESS"
export const GET_WEBSITE_CATEGORIZATIONS_FAIL = "GET_WEBSITE_CATEGORIZATIONS_FAIL"

export const ADD_WEBSITE_CATEGORIZATION = "ADD_WEBSITE_CATEGORIZATION"
export const ADD_WEBSITE_CATEGORIZATION_SUCCESS = "ADD_WEBSITE_CATEGORIZATION_SUCCESS"
export const ADD_WEBSITE_CATEGORIZATION_ERROR = "ADD_WEBSITE_CATEGORIZATION_ERROR"

export const UPDATE_WEBSITE_CATEGORIZATION = "UPDATE_WEBSITE_CATEGORIZATION"
export const UPDATE_WEBSITE_CATEGORIZATION_SUCCESS = "UPDATE_WEBSITE_CATEGORIZATION_SUCCESS"
export const UPDATE_WEBSITE_CATEGORIZATION_ERROR = "UPDATE_WEBSITE_CATEGORIZATION_ERROR"

export const READ_WEBSITE_CATEGORIZATION = "READ_WEBSITE_CATEGORIZATION"
export const READ_WEBSITE_CATEGORIZATION_SUCCESS = "READ_WEBSITE_CATEGORIZATION_SUCCESS"

export const STATUS_WEBSITE_CATEGORIZATION = "STATUS_WEBSITE_CATEGORIZATION"
export const STATUS_WEBSITE_CATEGORIZATION_SUCCESS = "STATUS_WEBSITE_CATEGORIZATION_SUCCESS"

export const ARCHIVE_WEBSITE_CATEGORIZATION = "ARCHIVE_WEBSITE_CATEGORIZATION"
export const ARCHIVE_WEBSITE_CATEGORIZATION_SUCCESS = "ARCHIVE_WEBSITE_CATEGORIZATION_SUCCESS"

/* Languages */
export const GET_LANGUAGES = "GET_LANGUAGES"
export const GET_LANGUAGE_SUCCESS = "GET_LANGUAGE_SUCCESS"
export const GET_LANGUAGE_FAIL = "GET_LANGUAGE_FAIL"