import {
  LOAD_ACTIVITIES,
  NO_DATA,
    GET_CHILDADUNITS,
    GET_CHILDADUNITS_FAIL,
    GET_CHILDADUNITS_SUCCESS,
    ADD_CHILDADUNIT,
    ADD_CHILDADUNIT_SUCCESS,
    ADD_CHILDADUNIT_ERROR,
    UPDATE_CHILDADUNIT,
    UPDATE_CHILDADUNIT_SUCCESS,
    UPDATE_CHILDADUNIT_ERROR,
    READ_CHILDADUNIT,
    READ_CHILDADUNIT_SUCCESS,
    STATUS_CHILDADUNIT,
    STATUS_CHILDADUNIT_SUCCESS,
    ARCHIVE_CHILDADUNIT ,
    ARCHIVE_CHILDADUNIT_SUCCESS

  } from "./actionTypes"

  export const loadActivitiesChildAdUnit = Params => ({
    type: LOAD_ACTIVITIES,
    payload:Params
  })

  export const noDataChildAdUnit = Params => ({
    type: NO_DATA,
    payload:Params
  })

  export const getChildAdUnit = Params => ({
    type: GET_CHILDADUNITS,
    payload:Params
  })
  
  export const getChildAdUnitsSuccess = ChildAdUnits => ({
    type: GET_CHILDADUNITS_SUCCESS,
    payload: ChildAdUnits,
  })

  export const getChildAdUnitsFail = error => ({
    type: GET_CHILDADUNITS_FAIL,
    payload: error,
  })

  export const addNewChildAdUnit = ChildAdUnit => ({
    type: ADD_CHILDADUNIT,
    payload: ChildAdUnit,
  })
  
  export const addChildAdUnitSuccess = childAdUnit => ({
    type: ADD_CHILDADUNIT_SUCCESS,
    payload: childAdUnit,
  })
  
  export const addChildAdUnitFail = childAdUnit => ({
    type: ADD_CHILDADUNIT_ERROR,
    payload: childAdUnit,
  })

  export const updateChildAdUnit = childAdUnit => ({
    type: UPDATE_CHILDADUNIT,
    payload: childAdUnit,
  })
  
  export const updateChildAdUnitSuccess = childAdUnit => ({
    type: UPDATE_CHILDADUNIT_SUCCESS,
    payload: childAdUnit,
  })
  
  export const updateChildAdUnitFail = childAdUnit => ({
    type: UPDATE_CHILDADUNIT_ERROR,
    payload: childAdUnit,
  })

  export const readChildAdUnit = childAdUnit => ({
    type: READ_CHILDADUNIT,
    payload: childAdUnit,
  })

  export const readChildAdUnitSuccess = childAdUnit => ({
    type: READ_CHILDADUNIT_SUCCESS,
    payload: childAdUnit,
  })

  export const statusChildAdUnit = childAdUnit => ({
    type: STATUS_CHILDADUNIT,
    payload: childAdUnit,
  })

  export const statusChildAdUnitSuccess = childAdUnit => ({
    type: STATUS_CHILDADUNIT_SUCCESS,
    payload: childAdUnit,
  })

  export const archiveChildAdUnit = childAdUnit => ({
    type: ARCHIVE_CHILDADUNIT,
    payload: childAdUnit,
  })

  export const archiveChildAdUnitSuccess = childAdUnit => ({
    type: ARCHIVE_CHILDADUNIT_SUCCESS,
    payload: childAdUnit,
  })