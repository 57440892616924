/* SUPPORT_TICKET_ADMIN */
export const LOAD_SUPPORT_TICKET_ADMINS = "LOAD_SUPPORT_TICKET_ADMINS"
export const NO_DATA_SUPPORT_TICKET_ADMINS = "NO_DATA_SUPPORT_TICKET_ADMINS"
export const GET_SUPPORT_TICKET_ADMINS = "GET_SUPPORT_TICKET_ADMINS"
export const GET_SUPPORT_TICKET_ADMINS_SUCCESS = "GET_SUPPORT_TICKET_ADMINS_SUCCESS"
export const GET_SUPPORT_TICKET_ADMINS_FAIL = "GET_SUPPORT_TICKET_ADMINS_FAIL"

export const ADD_CHAT_ADMIN = "ADD_CHAT_ADMIN"
export const ADD_CHAT_ADMIN_SUCCESS = "ADD_CHAT_ADMIN_SUCCESS"
export const ADD_CHAT_ADMIN_ERROR = "ADD_CHAT_ADMIN_ERROR"

export const STATUS_CHAT_ADMIN = "STATUS_CHAT_ADMIN"
export const STATUS_CHAT_ADMIN_SUCCESS = "STATUS_CHAT_ADMIN_SUCCESS"

