/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react"
import {
  Card, CardBody, Col, Form, Input, Label, Row, Spinner, Button, Progress, Nav,
  NavItem,
  NavLink, TabContent,
  TabPane,
  Modal, ModalBody, Dropdown, DropdownMenu, DropdownItem, DropdownToggle
} from "reactstrap"
import axios from "axios"
import classnames from "classnames";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Select_Thumbnail from "./Select_Thumbnail";

// import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';





// var path = require('path');


const Video_Upload = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));

  const [userFile, setUserFile] = useState(Math.random().toString(36))
  const [CdnLink, setCdnLink] = useState('')
  const [loader, setLoader] = useState(false)
  const [loaderYoutube, setLoaderYoutube] = useState(false)

  const [spinner, setSpinner] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0);

  const [autoGeneratedThumbnail, setAutoGeneratedThumnail] = useState('')

  const [uploadStatus, setUploadStatus] = useState(' Video is downloading, please wait a moment...')

  const [linkType, setLinkType] = useState('mp4')

  const [inputValue, setInputValue] = useState('');
  const [videoId, setVideoId] = useState('');

  const [showAlert, setShowAlert] = useState(false);

  const [btnprimary1, setBtnprimary1] = useState(false)
  const [resolution, setResolution] = useState('640:360')

  const [btnprimary2, setBtnprimary2] = useState(false)
  const [quality, setQuality] = useState('ultra')

  const [startTimeSec, setStartTimeSec] = useState('');
  const [startTimeMin, setStartTimeMin] = useState('');

  const [durationSec, setDurationSec] = useState('');
  const [durationMin, setDurationMin] = useState('');


  const [trimEnabled, setTrimEnabled] = useState(false);

  const [currentState, setCurrentState] = useState(true)

  const handleStartTimeChangeSec = (event) => {
    setStartTimeSec(event.target.value);
  };
  const handleStartTimeChangeMin = (event) => {
    setStartTimeMin(event.target.value);
  };

  const handleDurationChangeSec = (event) => {
    setDurationSec(event.target.value);
  };

  const handleDurationChangeMin = (event) => {
    setDurationMin(event.target.value);
  };

  const handleTrimChange = e => {
    setTrimEnabled(!trimEnabled);
    if (!e.target.checked) {
      setStartTimeMin('')
      setStartTimeSec('')
      setDurationMin('')
      setDurationSec('')
    }
  };

  var videoContentFileName = ""

  console.log(props.uploadType)

  const [videoContent, setVideoContent] = useState({
    videoContentOtherMp4Path: '',
    videoContentOtherDuration: '',
    videoContentOtherCompressedMp4Path: '',
    videoContentOtherM3u8Path: '',
    videoContentOtherThumbnailPath: ''

  });


  const [customActiveTab, setcustomActiveTab] = useState("1");


  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };


  const fileChange = (file) => {

    if (file.target.files[0].size <= 314572800) {


      setLoader(true);
      console.log(file.target.files[0])
      props.onVideoUpload(URL.createObjectURL(file.target.files[0]));

      const fileNameRaw = file.target.files[0].name

      const fileNameWithoutSpaces = fileNameRaw.replace(/ /g, '_');

      const fileNameWithoutExtension = fileNameWithoutSpaces.replace(/\.mp4$/, '');

      // Remove special characters except underscores
      const title = fileNameWithoutExtension.replace(/[^\w\s-]/g, '');
      props.onVideoName(title)

      // Create a video element dynamically
      const videoElement = document.createElement('video');

      // Set up event listener for metadata loaded
      videoElement.onloadedmetadata = function () {
        const durationInSeconds = videoElement.duration;
        console.log('Video duration:', durationInSeconds, 'seconds');

        setVideoContent((videoContent) => ({
          ...videoContent,
          videoContentOtherDuration: durationInSeconds,
        }));
        props.saveData({ videoContentOtherDuration: durationInSeconds });

        // Do something with the duration (e.g., send it to the server)
        // For example, you might want to update the state or trigger an API call
      };

      // Set the video source using the file
      videoElement.src = URL.createObjectURL(file.target.files[0]);

      // Append the video element to the body to load metadata
      document.body.appendChild(videoElement);

      // Clean up: remove the video element from the body after metadata is loaded
      videoElement.addEventListener('loadedmetadata', function () {
        document.body.removeChild(videoElement);
      });

      const fileName = file.target.files[0].name
      const fileType = file.target.files[0].type;
      console.log("file=====================", file);
      videoContentFileName = fileName;
      console.log(videoContentFileName);
      const formData = new FormData();
      let newFile = file.target.files[0];
      formData.append("file", newFile);

      const newBaseContent = {};
      (async () => {

        const body = formData
        axios
          .post("/uploadFile", body, {
            headers: headers,
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              setUploadProgress(progress);
            },
          })
          .then((res) => {
            console.log("Video Upload ==>", res);
            setLoader(false);
            setVideoContent({
              videoContentOtherMp4Path: `/app/videocontents/videos/${videoContentFileName}`,
              isUploadedContent: true
            });
            props.saveData({
              videoContentOtherMp4Path: `/app/videocontents/videos/${videoContentFileName}`,
              isUploadedContent: true
            });
            setSpinner(true)

            axios.post("/convertTohls", {
              videoFilePath: `/app/videocontents/videos/${videoContentFileName}`,
              videoFileName: videoContentFileName
            })
              .then((response) => {

                console.log(response)
                const convertedFiles = response.data.videoOutputFiles;
                const compressedFilePath = response.data.compressedVideoFilePath;
                console.log(convertedFiles)
                console.log(compressedFilePath)
                setVideoContent({
                  videoContentOtherTsPath: convertedFiles,
                  videoContentOtherCompressedMp4Path: compressedFilePath,
                  videoContentOtherM3u8Path: response.data.m3u8FilePath
                });

                props.saveData({
                  videoContentOtherTsPath: convertedFiles,
                  videoContentOtherCompressedMp4Path: compressedFilePath,
                  videoContentOtherM3u8Path: response.data.m3u8FilePath
                });
                console.log(response.data.m3u8FilePath)
                setSpinner(false)
                // props.changeActiveState(2);

              })
              .catch((zipError) => {
                console.log(zipError)
                setLoader(false);
                setSpinner(false)
                toastr.error(zipError);
                setUserFile(Math.random().toString(36))
              })


          })
          .catch((err) => {
            setLoader(false);
            console.log("e", err.code);
            toastr.error(err);
            setUserFile(Math.random().toString(36))

          });
      })();
    } else {
      setLoader(false);
      setSpinner(false);
      toastr.error("please select video less than 300mb")
      setUserFile(Math.random().toString(36))
    }
  };


  const videoDurationValidator = async () => {
    // setSpinner(true);
    let fileName;
    try {
      const response = await axios.post("/getbasicinfo", {
        url: inputValue
      })
      console.log(response)
      if (parseInt(response.data.data.vInfo.duration) <= 1200) {
        console.log("video is less than 20 mins")
        handleYoutubeAndDirectLinkDownloadNew()
      } else {
        console.log("vieo is greater than 20 mins")
        setShowAlert(true)
      }
    } catch (error) {
      setSpinner(false);
      console.error(error);
    }
  }




  const handleDirectLinkDownload = async () => {
    setShowAlert(false)
    setSpinner(true)
    var videoBlob;
    var videoName;
    var videoType;
    var base64file;

    try {

      const response = await axios.get(inputValue, { responseType: 'blob' });
      setSpinner(false)
      videoBlob = response.data;
      videoName = inputValue.match(/(?<=\/)(\w|-)+(?:\.\w+)?(?=$|\?)/)[0];
      videoType = videoBlob.type;
      videoContentFileName = videoName
      console.log(videoContentFileName)


      const videoFile = new File([videoBlob], videoName, { type: 'video/mp4' })
      const videoUrl = URL.createObjectURL(videoFile)
      // console.log(videoUrl)

      props.onVideoUpload(videoUrl);


      const fileNameRaw = videoName

      const fileNameWithoutSpaces = fileNameRaw.replace(/ /g, '_');

      const fileNameWithoutExtension = fileNameWithoutSpaces.replace(/\.mp4$/, '');

      // Remove special characters except underscores
      const title = fileNameWithoutExtension.replace(/[^\w\s-]/g, '');


      props.onVideoName(title)

      console.log(videoBlob)

      // Create a video element dynamically
      const videoElement = document.createElement('video');

      // Set up event listener for metadata loaded
      videoElement.onloadedmetadata = function () {
        const durationInSeconds = videoElement.duration;
        console.log('Video duration:', durationInSeconds, 'seconds');

        setVideoContent((videoContent) => ({
          ...videoContent,
          videoContentOtherDuration: durationInSeconds,
        }));
        props.saveData({ videoContentOtherDuration: durationInSeconds });

        // Do something with the duration (e.g., send it to the server)
        // For example, you might want to update the state or trigger an API call
      };

      // Set the video source using the file
      videoElement.src = URL.createObjectURL(file.target.files[0]);

      // Append the video element to the body to load metadata
      document.body.appendChild(videoElement);

      // Clean up: remove the video element from the body after metadata is loaded
      videoElement.addEventListener('loadedmetadata', function () {
        document.body.removeChild(videoElement);
      });

      const formData = new FormData()
      let newFile = videoFile
      formData.append("file", videoFile)


      // const toBase64 = (videoFile) =>
      //   new Promise((resolve, reject) => {
      //     const reader = new FileReader();
      //     reader.readAsDataURL(videoFile);
      //     reader.onload = () => {
      //       var media = new Audio(reader.result);
      //       media.onloadedmetadata = function () {
      //         media.duration; // this would give duration of the video/audio file
      //         setVideoContent((videoContent) => ({
      //           ...videoContent,
      //           videoContentOtherDuration: media.duration,
      //         }));
      //         props.saveData({ videoContentOtherDuration: media.duration });
      //       };
      //       resolve(reader.result);
      //     };
      //     reader.onerror = (error) => reject(error);
      //     console.log(reader.result);
      //   });

      // const getVideoDuration = (videoFile) =>
      //   new Promise((resolve, reject) => {
      //     const videoElement = document.createElement('video');
      //     videoElement.preload = 'metadata';
      //     videoElement.src = URL.createObjectURL(videoFile);
      //     videoElement.onloadedmetadata = () => {
      //       const duration = videoElement.duration;
      //       resolve(duration);
      //     };
      //     videoElement.onerror = (error) => reject(error);
      //   });

      // const duration = await getVideoDuration(videoFile);
      // console.log(duration);
      // setVideoContent((videoContent) => ({
      //   ...videoContent,
      //   videoContentOtherDuration: duration,
      // }));
      // props.saveData({ videoContentOtherDuration: duration });



      // var newBaseData = toBase64(newFile);
      const newBaseContent = {};

      (async () => {
        // newBaseContent.data = await newBaseData;
        // console.log(newBaseContent.data)
        // newBaseContent.fileName = `${videoName}.mp4`;
        // newBaseContent.type = videoType;
        // newBaseContent.location = 'videoContentData';
        // newBaseContent.userID = userInfo.userID;
        // newBaseContent.userRole = userInfo.userRole;
        // newBaseContent.companyID = userInfo.companyID;
        // console.log(newBaseContent);
        // const body = {
        //   url: "http://10.0.3.69:3022/upload/uploadFile",
        //   requestBody: newBaseContent,
        // };

        const body = formData

        setLoaderYoutube(true);


        axios.post("/uploadFile", body, {
          headers: headers,
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgress(progress);
          },
        })
          .then((res) => {
            console.log("Video Upload ==>", res);
            setLoaderYoutube(false);
            setVideoContent({
              videoContentOtherMp4Path: `/app/videocontents/videos/${videoContentFileName}`,
            });
            props.saveData({
              videoContentOtherMp4Path: `/app/videocontents/videos/${videoContentFileName}`,
            });

            setUploadStatus(' The file is being compressed and converted to a streamable format. Please wait...')
            setSpinner(true)

            axios.post("/convertTohls", {
              videoFilePath: `/app/videocontents/videos/${videoContentFileName}`,
              videoFileName: videoContentFileName
            })
              .then((response) => {
                console.log(response)
                const convertedFiles = response.data.videoOutputFiles;
                const compressedFilePath = response.data.compressedVideoFilePath;
                console.log(convertedFiles)
                console.log(compressedFilePath)
                setVideoContent({
                  videoContentOtherTsPath: convertedFiles,
                  videoContentOtherCompressedMp4Path: compressedFilePath,
                  videoContentOtherM3u8Path: response.data.m3u8FilePath,
                  isUploadedContent: true

                });

                props.saveData({
                  videoContentOtherTsPath: convertedFiles,
                  videoContentOtherCompressedMp4Path: compressedFilePath,
                  videoContentOtherM3u8Path: response.data.m3u8FilePath,
                  isUploadedContent: true

                });

                setSpinner(false)

                // props.changeActiveState(2);

              })
              .catch((Error) => {
                console.log(Error)
                setLoaderYoutube(false);
                setSpinner(false)
              })
          })
          .catch((err) => {
            setLoaderYoutube(false);
            setSpinner(false)
            console.log("e", err);
          });
      })();

    } catch (err) {
      console.error(err)
      setLoader(false);
      setSpinner(false)
      setLoaderYoutube(false)
      toastr.error(err);
    }
  }


  const directYoutubePath = async () => {
    setShowAlert(false)
    setSpinner(true)

    try {
      const response = await axios.post('/downloadvideoforplaylist', { url: inputValue, type: 'content' });
      const videoData = response.data;

      setAutoGeneratedThumnail(videoData.thumbnailData)

      setUploadStatus(' The file is being compressed and converted to a streamable format. Please wait...')

      const conversionResponse = await axios.post("/convertTohls", {
        videoFilePath: videoData.videoPath,
        videoFileName: videoData.filename
      });

      const fileNameRaw = videoData.filename

      const fileNameWithoutSpaces = fileNameRaw.replace(/ /g, '_');

      const fileNameWithoutExtension = fileNameWithoutSpaces.replace(/\.mp4$/, '');

      // Remove special characters except underscores
      const title = fileNameWithoutExtension.replace(/[^\w\s]/g, '');

      props.onVideoName(title)


      console.log(videoData)
      console.log(conversionResponse)

      const convertedFiles = conversionResponse.data.videoOutputFiles
      const compressedFilePath = conversionResponse.data.compressedVideoFilePath

      setVideoContent((videoContent) => ({
        ...videoContent,
        videoContentOtherDuration: videoData.duration,
        videoContentOtherMp4Path: `/app/videocontents/videos/${videoData.filename}.mp4`,
        videoContentOtherTsPath: convertedFiles,
        videoContentOtherCompressedMp4Path: compressedFilePath,
        videoContentOtherM3u8Path: conversionResponse.data.m3u8FilePath,
        videoContentOtherThumbnailPath: videoData.thumbnailPath,
        isUploadedContent: true


      }));
      props.saveData({
        videoContentOtherDuration: videoData.duration,
        videoContentOtherMp4Path: `/app/videocontents/videos/${videoData.filename}.mp4`,
        videoContentOtherTsPath: convertedFiles,
        videoContentOtherCompressedMp4Path: compressedFilePath,
        videoContentOtherM3u8Path: conversionResponse.data.m3u8FilePath,
        videoContentOtherThumbnailPath: videoData.thumbnailPath,
        isUploadedContent: true


      });
      setSpinner(false)
      console.log(videoContent)

    } catch (err) {
      setSpinner(false)
      toastr.error("Errror occured when uploading content!")
    }
  }

  const pathValidator = () => {
    if (inputValue.includes('youtube')) {
      directYoutubePath()
      setLinkType('youtube')
    } else {
      handleDirectLinkDownload()
      setLinkType('mp4')
    }
  }




  const fetchCdnLinkInfo1 = async () => {
    const videoUrl = CdnLink;
    const videoName = CdnLink
      .match(/(?<=\/)(\w|-)+(?:\.\w+)?(?=$|\?)/)[0] // Get the filename including extension
      .replace(/\s/g, '') // Remove spaces
      .replace(/\.mp4$/, ''); // Remove ".mp4" extension
    console.log(videoName)
    props.onVideoUpload(CdnLink)


    const fileNameRaw = videoName

    const fileNameWithoutSpaces = fileNameRaw.replace(/ /g, '_');

    const fileNameWithoutExtension = fileNameWithoutSpaces.replace(/\.mp4$/, '');

    // Remove special characters except underscores
    const title = fileNameWithoutExtension.replace(/[^\w\s]/g, '');

    props.onVideoName(title) 


    videoContentFileName = `${videoName}.mp4`;

    try {
      const videoElement = document.createElement('video');
      videoElement.src = videoUrl;
      videoElement.addEventListener('loadedmetadata', () => {
        const durationInSeconds = videoElement.duration;
        console.log(durationInSeconds);
        setVideoContent((videoContent) => ({
          ...videoContent,
          videoContentOtherDuration: durationInSeconds,
        }));
        props.saveData({ videoContentOtherDuration: durationInSeconds });
      });
      // document.body.appendChild(videoElement);
    } catch (err) {
      console.log(err)
    }
    setVideoContent({
      videoContentOtherMp4Path: CdnLink,
      videoContentOtherCompressedMp4Path: CdnLink,
      videoContentOtherM3u8Path: CdnLink,
      isUploadedContent: false

    });
    props.saveData({
      videoContentOtherMp4Path: CdnLink,
      videoContentOtherCompressedMp4Path: CdnLink,
      videoContentOtherM3u8Path: CdnLink,
      isUploadedContent: false

    });
    // props.changeActiveState(2);
    console.log(videoContent);
  }

  const updateThumbnailData = () => {
    setAutoGeneratedThumnail('')
  }


  return (
    <div className="d-flex">
      <Col md="8">

        {props.uploadType === 'video' ?
          <Row className="mt-2">
            <div className="container-fluid d-flex ">
              <label className="form-check-label  m-2 mt-1 mb-3 " style={{ marginTop: '0.8pc' }}>
                <span color='text-success' style={{ color: currentState ? '#000000' : '#a3a3a3', marginTop: '' }}>Upload Content</span>&nbsp;&nbsp;
              </label>
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2" >

                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={!currentState}
                  onChange={() => setCurrentState(!currentState)}
                />

              </div>
              <label className="form-check-label  m-2 mt-1 mb-3" style={{ marginTop: '0.8pc' }}>
                <span color='text-success' style={{ color: currentState ? '#a3a3a3' : '#000000' }}>Content Link</span>
              </label>
            </div>
            {currentState &&
              <Col sm="6">
                <div className="mt-2 mb-3">
                  <Label className="form-label">Upload Video</Label>
                  <Input className="w-100" type="file" accept="video/mp4" key={userFile || ''} onChange={fileChange} />
                  <span className="notes text-left">Note: Allowed file types: MP4</span>
                </div>
                {loader &&
                  <Progress
                    className="mt-2"
                    animated
                    color="success"
                    style={{
                      height: '15px'
                    }}
                    striped
                    value={uploadProgress}
                  >{uploadProgress}%</Progress>
                }
                {
                  spinner &&
                  <div className="d-flex justify-content-center align-items-center mt-1">

                    <Button
                      color="primary"
                      disabled
                    >
                      <Spinner size="sm">
                        Loading...
                      </Spinner>
                      <span>
                        {' '}The file is being compressed and converted to a streamable format. Please wait...
                      </span>
                    </Button>
                  </div>

                }

              </Col>
            }
            {!currentState &&
              <Col sm="6">
                <div className="mt-2 mb-3">
                  <Label className="text-left form-label" htmlFor="youtube-link">Enter CDN Link</Label>
                  <Input className="w-100" type="text" id="youtube-link" name="youtube-link" placeholder=".mp4 link" value={CdnLink} onChange={(e) => setCdnLink(e.target.value)} />
                  <div className="">
                    <Button className=" mt-2" onClick={() => fetchCdnLinkInfo1()}>Get CDN Info</Button>
                  </div>
                  {spinner &&
                    <div className="d-flex justify-content-center align-items-center">
                      <Button
                        color="primary"
                        disabled
                      >
                        <Spinner size="sm">
                          Loading...
                        </Spinner>
                        <span>
                          {' '}Downloading...
                        </span>
                      </Button>
                    </div>
                  }
                </div>
              </Col>
            }
          </Row>
          :
          <Row className="mt-5">
            <Col sm="8">
              <div className="d-flex flex-column ">
                <Label className="text-left" htmlFor="youtube-link">Enter Link</Label>
                <Input className="w-100" type="text" id="youtube-link" name="youtube-link" placeholder="https://www.youtube.com/watch?v=... or .mp4 link" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
                <span>Note: Use .Mp4 links to save video in our CDN.</span>
                <div className="d-flex ">
                  <div>
                    <Button className="bg-primary m-1 mt-2" onClick={() => pathValidator()}>Upload</Button>
                  </div>
                </div>

              </div>
              {loaderYoutube &&
                <Progress
                  className="mt-2"
                  animated
                  color="success"
                  style={{
                    height: '15px'
                  }}
                  striped
                  value={uploadProgress}
                >{uploadProgress}%</Progress>
              }
              {
                spinner &&
                <div className="d-flex justify-content-center align-items-center mt-1">

                  <Button
                    color="primary"
                    disabled
                  >
                    <Spinner size="sm">
                      Loading...
                    </Spinner>

                    <span >
                      {' '}{uploadStatus}
                    </span>
                  </Button>
                </div>

              }
            </Col>
          </Row>}
        <Row className="mt-2">
          <Select_Thumbnail updateThumbnailData={updateThumbnailData} type={props.uploadType} linkType={linkType} saveData={props.saveData} videoContentPath={props.videoContentOtherMp4Path} videoFile={props.videoFile} videoFileName={props.videoFileName} />
        </Row>
        {/* </Form> */}
      </Col >
      <Col md="4" className="d-flex flex-column justify-content-center align-items-center">
        {autoGeneratedThumbnail && linkType === 'youtube' &&
          <>
            <p>Auto Generated Thumbnail Preview:</p>
            <img
              src={`data:image/jpeg;base64,${autoGeneratedThumbnail}`} // Assuming the image is in JPEG format
              alt="Thumbnail"
              width="400" // Adjust the width value as needed
              height="250" // Adjust the height value as needed
            />

          </>

        }
      </Col>
      {/* <Modal size="sm" isOpen={showAlert} toggle={() => { setShowAlert(!showAlert) }} backdrop={'static'} centered={true}
        id="staticBackdrop"
      >
        <div className="modal-content">
          <ModalBody className="px-2 py-4 text-center ">
            <i className="bx bx-error-circle text-warning fon-size-32" style={{ fontSize: '2rem' }}></i> &nbsp;
            <h5 className="text-muted font-size-16 mb-4">   video is more than 20 minutes in length would you like to download?</h5>
            <div className="hstack gap-2 justify-content-center mb-0">
              <button type="button" className="btn btn-light" onClick={() => { setShowAlert(!showAlert) }}>No</button>
              <button type="button" className="btn btn-primary" onClick={handleYoutubeAndDirectLinkDownloadNew}>Yes</button>
            </div>
          </ModalBody>
        </div>
      </Modal> */}
    </div>

  )
}

Video_Upload.propTypes = {}

export default Video_Upload