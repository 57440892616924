import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_HIGH_IMPACT_SETTINGS, ADD_HIGH_IMPACT_SETTING, UPDATE_HIGH_IMPACT_SETTING, READ_HIGH_IMPACT_SETTING, STATUS_HIGH_IMPACT_SETTING, ARCHIVE_HIGH_IMPACT_SETTING } from "./actionTypes"

import {
  loadActivitiesHighImpactSetting,
  noDataHighImpactSetting,
  getHighImpactSettingsSuccess,
  getHighImpactSettingsFail,
  addHighImpactSettingSuccess,
  addHighImpactSettingFail,
  updateHighImpactSettingSuccess,
  updateHighImpactSettingFail,
  readHighImpactSettingSuccess,
  statusHighImpactSettingSuccess,
  archiveHighImpactSettingSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getHighImpactSetting, getHighImpactSettingsByAdUnit, createHighImpactSetting, updateHighImpactSetting, readHighImpactSetting, statusChangeHighImpactSetting, archiveHighImpactSetting } from "../../../helpers/Backend_helper"

function* fetchHighImpactSettings({ payload: highImpactSetting }) {
  try {
    yield put(loadActivitiesHighImpactSetting(true))
    var getHighImpactSettingList
    if (highImpactSetting && highImpactSetting.adUnitID !== '') {
      getHighImpactSettingList = yield call(getHighImpactSettingsByAdUnit, highImpactSetting)
      if (getHighImpactSettingList.statusCode === 200) {
        getHighImpactSettingList.response.HighImpactSettings.map((item, index) => {
          item.id = index + 1
        })
        if (getHighImpactSettingList.response.HighImpactSettings.length === 0) {
          yield put(noDataHighImpactSetting(true))
        }
        yield put(getHighImpactSettingsSuccess(getHighImpactSettingList))
      }
    } else if (highImpactSetting && highImpactSetting.adUnitID === '') {
      delete highImpactSetting.adUnitID
      getHighImpactSettingList = yield call(getHighImpactSetting, highImpactSetting)
      if (getHighImpactSettingList.statusCode === 200) {
        getHighImpactSettingList.response.HighImpactSettings.map((item, index) => {
          item.id = index + 1
        })
        if (getHighImpactSettingList.response.HighImpactSettings.length === 0) {
          yield put(noDataHighImpactSetting(true))
        }
        yield put(getHighImpactSettingsSuccess(getHighImpactSettingList))
      }
    } else {
      getHighImpactSettingList = []
      yield put(getHighImpactSettingsSuccess(getHighImpactSettingList))
    }
  } catch (error) {
    yield put(getHighImpactSettingsFail(error))
  }
}

function* onReadHighImpactSetting({ payload: highImpactSetting }) {
  try {
    const response = yield call(readHighImpactSetting, highImpactSetting)
    yield put(readHighImpactSettingSuccess(response))
  } catch (error) {
    yield put(readHighImpactSettingSuccess(error))
  }
}

function* onAddHighImpactSetting({ payload: highImpactSetting }) {
  try {
    const response = yield call(createHighImpactSetting, highImpactSetting)
    if (response.statusCode === 200) {
      yield put(addHighImpactSettingSuccess(response))
    } else {
      yield put(addHighImpactSettingFail(response))
    }
  } catch (error) {
    yield put(addHighImpactSettingFail(error))
  }
}

function* onUpdateHighImpactSetting({ payload: highImpactSetting }) {
  try {
    const response = yield call(updateHighImpactSetting, highImpactSetting)
    if (response.statusCode === 200) {
      yield put(updateHighImpactSettingSuccess(response))
    } else {
      yield put(updateHighImpactSettingFail(response))
    }
  } catch (error) {
    yield put(updateHighImpactSettingFail(error))
  }
}

function* onStatusHighImpactSetting({ payload: highImpactSetting }) {
  try {
    const response = yield call(statusChangeHighImpactSetting, highImpactSetting)
    if (response.statusCode === 200) {
      yield put(statusHighImpactSettingSuccess(response))
    }
  } catch (error) {
    yield put(statusHighImpactSettingSuccess(error))
  }
}

function* onArchiveHighImpactSetting({ payload: highImpactSetting }) {
  try {
    const response = yield call(archiveHighImpactSetting, highImpactSetting)
    if (response.statusCode === 200) {
      yield put(archiveHighImpactSettingSuccess(response))
    }
  } catch (error) {
    yield put(archiveHighImpactSettingSuccess(error))
  }
}

function* highImpactSettingSaga() {
  yield takeEvery(GET_HIGH_IMPACT_SETTINGS, fetchHighImpactSettings)
  yield takeEvery(ADD_HIGH_IMPACT_SETTING, onAddHighImpactSetting)
  yield takeEvery(UPDATE_HIGH_IMPACT_SETTING, onUpdateHighImpactSetting)
  yield takeEvery(READ_HIGH_IMPACT_SETTING, onReadHighImpactSetting)
  yield takeEvery(STATUS_HIGH_IMPACT_SETTING, onStatusHighImpactSetting)
  yield takeEvery(ARCHIVE_HIGH_IMPACT_SETTING, onArchiveHighImpactSetting)
}

export default highImpactSettingSaga;
