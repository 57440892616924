import {
  LOAD_ACTIVITIES,
  NO_DATA_SCRAPPYS,
    GET_SCRAPPYS,
    GET_SCRAPPYS_FAIL,
    GET_SCRAPPYS_SUCCESS,
    ADD_SCRAPPY,
    ADD_SCRAPPY_SUCCESS,
    ADD_SCRAPPY_ERROR,
    UPDATE_SCRAPPY,
    UPDATE_SCRAPPY_SUCCESS,
    UPDATE_SCRAPPY_ERROR,
    READ_SCRAPPY,
    READ_SCRAPPY_SUCCESS,
    STATUS_SCRAPPY,
    STATUS_SCRAPPY_SUCCESS,
    ARCHIVE_SCRAPPY ,
    ARCHIVE_SCRAPPY_SUCCESS

  } from "./actionTypes"

  export const loadActivitiesAdScrappys = Params => ({
    type: LOAD_ACTIVITIES,
    payload:Params
  })

  export const noScrappys = Params => ({
    type: NO_DATA_SCRAPPYS,
    payload:Params
  })

  export const getScrappys = Params => ({
    type: GET_SCRAPPYS,
    payload:Params
  })
  
  export const getScrappysSuccess = Scrappys => ({
    type: GET_SCRAPPYS_SUCCESS,
    payload: Scrappys,
  })

  export const getScrappysFail = error => ({
    type: GET_SCRAPPYS_FAIL,
    payload: error,
  })

  export const addNewScrappy = Scrappy => ({
    type: ADD_SCRAPPY,
    payload: Scrappy,
  })
  
  export const addScrappySuccess = creative => ({
    type: ADD_SCRAPPY_SUCCESS,
    payload: creative,
  })
  
  export const addScrappyFail = creative => ({
    type: ADD_SCRAPPY_ERROR,
    payload: creative,
  })

  export const updateScrappy = creative => ({
    type: UPDATE_SCRAPPY,
    payload: creative,
  })
  
  export const updateScrappySuccess = creative => ({
    type: UPDATE_SCRAPPY_SUCCESS,
    payload: creative,
  })
  
  export const updateScrappyFail = creative => ({
    type: UPDATE_SCRAPPY_ERROR,
    payload: creative,
  })

  export const readScrappy = creative => ({
    type: READ_SCRAPPY,
    payload: creative,
  })

  export const readScrappySuccess = creative => ({
    type: READ_SCRAPPY_SUCCESS,
    payload: creative,
  })

  export const statusScrappy = creative => ({
    type: STATUS_SCRAPPY,
    payload: creative,
  })

  export const statusScrappySuccess = creative => ({
    type: STATUS_SCRAPPY_SUCCESS,
    payload: creative,
  })

  export const archiveScrappy = creative => ({
    type: ARCHIVE_SCRAPPY,
    payload: creative,
  })

  export const archiveScrappySuccess = creative => ({
    type: ARCHIVE_SCRAPPY_SUCCESS,
    payload: creative,
  })