import React, { useEffect, useState, useRef } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardImg,
  CardTitle,
  CardText,
  CardBody,
  Col,
  Box,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap"

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//Import CSS
import './ScreenshotTool.css'


import Chrome_ExtensionDesktop from '../../../assets/screenshotTools/Streamlyn Chrome Extension Desktop.zip'
import Chrome_ExtensionMobile from '../../../assets/screenshotTools/Streamlyn Chrome Extension Mobile.zip'
import Chrome_ExtensionVideo from '../../../assets/screenshotTools/Streamlyn Chrome Extension Video'

// Videos
import Chrome_ExtensionInstallationVideo from '../../../assets/screenshotTools/Intallation_of_Chrome_Extension.mp4'
import SST_DSK from '../../../assets/screenshotTools/SST_Dsk.mp4'
import SST_Mobile from '../../../assets/screenshotTools/SST_Mobile.mp4'
import SST_Video from '../../../assets/screenshotTools/SST_Video.mp4'



const PagesStarter = () => {

  const [desktopVideo, setDesktopVideo] = useState(false);
  const [ssdVideo, setSsdVideo] = useState(false);
  const [mobileVideo, setMobileVideo] = useState(false);
  const [bothDMVideo, setBothDMVideo] = useState(false);

  const videoCSERef = useRef(null);
  const videoDskRef = useRef(null);
  const videoMbRef = useRef(null);
  const videoRef = useRef(null);


  const handlePlayDesktopVideo = () => {
    setDesktopVideo(!desktopVideo)
    videoDskRef.current.play();
  }

  const handleStopDesktopVideo = () => {
    videoDskRef.current.pause();
    setDesktopVideo(!desktopVideo)
  }

  const handlePlayInstallationVideo = () => {
    setSsdVideo(!ssdVideo)
    videoCSERef.current.play();
  }

  const handleStopInstallationVideo = () => {
    videoCSERef.current.pause();
    setSsdVideo(!ssdVideo)
  }

  const handlePlayMobileVideo = () => {
    setMobileVideo(!mobileVideo)
    videoMbRef.current.play();
  }

  const handleStopMobileVideo = () => {
    videoMbRef.current.pause();
    setMobileVideo(!mobileVideo)
  }

  const handlePlayVideo = () => {
    setBothDMVideo(!bothDMVideo)
    videoRef.current.play();
  }

  const handleStopVideo = () => {
    videoRef.current.pause();
    setBothDMVideo(!bothDMVideo)
  }

  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
        <div className="page-content">
          <Helmet>
            <title>ScreenshotTool| Bidsxchange</title>
          </Helmet>
          <Container fluid>
            <Breadcrumbs title="Tools" breadcrumbItem="ScreenShotTool" />
            <div className="accordion" id="accordionExample">
              <div className="card">
                <div className="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">

                  <h5 className="mb-0">Desktop Banners</h5>
                  <div className="block-handle mb-1"></div>
                </div>
                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                  <div className="card-body">

                    {/* <video src={Chrome_Extension} ref={videoRef} type="video/mp4"></video> */}
                    <Link to={Chrome_ExtensionDesktop} target="_blank" className="color-dark-blue signUp ml-3" download="Streamlyn Chrome Extension Desktop.zip">Download Extension</Link>
                    <h6 className='ml-3 mt-3'>* Steps to Install Plugin: <span onClick={handlePlayInstallationVideo} className="color-dark-blue signUp" style={{ cursor: 'pointer' }}>Watch Video</span></h6>
                    <div className='ml-5'>
                      <li> Open the Chrome Browser, Click on Chrome Settings- More Tools - Extensions</li>
                      <li>Enable the Developer mode on the top right.</li>
                      <li>Click on Load unpacked.</li>
                      <li>Add the plugin zip folder from the download directory</li>
                    </div>
                    <h6 className='ml-3 mt-3'>* Steps for Screenshot Process: <span onClick={handlePlayDesktopVideo} className="color-dark-blue signUp" style={{ cursor: 'pointer' }}>Watch Video</span></h6>
                    <div className='ml-5'>
                      <li> Click on the Get Div button to capture the required divs</li>
                      <li>Select the required size from the dropdown and click on the scroll to div button.</li>
                      <li>You will be scrolled to the respective div.</li>
                      <li>Enter the div width & height and render the tag as done for the standard tag</li>
                      <li>Take the screenshot with the following command Ctrl + Shift + p and in the terminal type capture to screenshot</li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion mt-3" id="accordionExample">
              <div className="card">
                <div className="card-header" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                  <h5 className="mb-0">Mobile Banners</h5>
                  <div className="block-handle"></div>

                </div>
                <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionExample">
                  <div className="card-body">
                    <Link to={Chrome_ExtensionMobile} target="_blank" className="color-dark-blue signUp ml-3" download="Streamlyn Chrome Extension Mobile.zip">Download Extension</Link>
                    <h6 className='ml-3 mt-3'>* Steps for Screenshot Process: <span onClick={handlePlayMobileVideo} className="color-dark-blue signUp" style={{ cursor: 'pointer' }}>Watch Video</span></h6>
                    <div className='ml-5'>
                      <li> Click on the Get Div button to capture the required divs</li>
                      <li>Select the required size from the dropdown and click on the scroll to div button.</li>
                      <li>You will be scrolled to the respective div.</li>
                      <li>Enter the div width & height and render the tag as done for the standard tag</li>
                      <li>Take the screenshot with the following command Ctrl + Shift + p and in the terminal type capture to screenshot</li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion mt-3" id="accordionExample">
              <div className="card">
                <div className="card-header" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                  <h5 className="mb-0">Desktop & Mobile Video</h5>
                  <div className="block-handle"></div>
                </div>
                <div id="collapseThree" className="collapse show" aria-labelledby="headingThree" data-parent="#accordionExample">
                  <div className="card-body">
                    <Link to={Chrome_ExtensionVideo} target="_blank" className="color-dark-blue signUp ml-3" download="Streamlyn Chrome Extension Video">Download Extension</Link>
                    <h6 className='ml-3 mt-3'>* Steps for Screenshot Process: <span onClick={handlePlayVideo} className="color-dark-blue signUp" style={{ cursor: 'pointer' }}>Watch Video</span></h6>
                    <div className='ml-5'>
                      <li> Click on the Get Div button to capture the required divs</li>
                      <li>Select the required size from the dropdown and click on the scroll to div button.</li>
                      <li>You will be scrolled to the respective div.</li>
                      <li>Enter the div width & height and render the tag as done for the standard tag</li>
                      <li>Take the screenshot with the following command Ctrl + Shift + p and in the terminal type capture to screenshot</li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="light2_install" style={{ display: (desktopVideo) ? 'block' : 'none' }}>
              <span className="boxclose " id="boxclose2_install" onClick={handleStopDesktopVideo} style={{ cursor: 'pointer' }} >x</span>
              <video ref={videoDskRef} width="600" src={SST_DSK} type="video/mp4" controls>
              </video>
            </div>
            <div id="light2_install" style={{ display: (ssdVideo) ? 'block' : 'none' }}>
              <span className="boxclose " id="boxclose2_install" onClick={handleStopInstallationVideo} style={{ cursor: 'pointer' }} >x</span>
              <video ref={videoCSERef} width="600" src={Chrome_ExtensionInstallationVideo} type="video/mp4" controls>
              </video>
            </div>
            <div id="light2_install" style={{ display: (mobileVideo) ? 'block' : 'none' }}>
              <span className="boxclose " id="boxclose2_install" onClick={handleStopMobileVideo} style={{ cursor: 'pointer' }} >x</span>
              <video ref={videoMbRef} width="600" src={SST_Mobile} type="video/mp4" controls>
              </video>
            </div>
            <div id="light2_install" style={{ display: (bothDMVideo) ? 'block' : 'none' }}>
              <span className="boxclose " id="boxclose2_install" onClick={handleStopVideo} style={{ cursor: 'pointer' }} >x</span>
              <video ref={videoRef} width="600" src={SST_Video} type="video/mp4" controls>
              </video>
            </div>
          </Container>
        </div>
      {/* </div> */}

    </React.Fragment>
  )
}

export default withRouter(PagesStarter)


