import React, { useState, useEffect, useRef } from 'react';

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import { capitalize, upperCase } from 'lodash';

var userInfo = JSON.parse(localStorage.getItem('authUser'));
var Initial = ''

const ProfileImage = () => {
    const userRole = localStorage.getItem('UserRole');
    const adminDetails = JSON.parse(localStorage.getItem('AdminDetails'));

    useEffect (()=>{
        if(adminDetails){
           userInfo = JSON.parse(localStorage.getItem('AdminDetails'));
           if(userInfo){
            Initial = userInfo.name[0]+userInfo.name[1]
           }
        }else{
           userInfo = JSON.parse(localStorage.getItem('authUser'));
           if(userInfo){
            Initial = userInfo.name[0]+userInfo.name[1]
           }
        }
      },[])
 
    return (
        <div style={{
            display: 'inline-block',
            backgroundColor: '#303d4e',
            color: 'white',
            width: 30,
            height: 30,
            borderRadius: '50%',
            textAlign: 'center',
            fontSize: 12,
            marginRight: 0,
            paddingTop: 7,
            textTransform: 'upperCase'

        }}>
            {Initial}

        </div>


    );

};

export default ProfileImage;
