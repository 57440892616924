/* PAGE_URL */
export const LOAD_PAGEURLS = "LOAD_PAGEURLS"
export const NO_DATA_PAGEURLS = "NO_DATA_PAGEURLS"
export const GET_PAGE_URLS = "GET_PAGE_URLS"
export const GET_PAGE_URLS_SUCCESS = "GET_PAGE_URLS_SUCCESS"
export const GET_PAGE_URLS_FAIL = "GET_PAGE_URLS_FAIL"

export const ADD_PAGE_URL = "ADD_PAGE_URL"
export const ADD_PAGE_URL_SUCCESS = "ADD_PAGE_URL_SUCCESS"
export const ADD_PAGE_URL_ERROR = "ADD_PAGE_URL_ERROR"

export const UPDATE_PAGE_URL = "UPDATE_PAGE_URL"
export const UPDATE_PAGE_URL_SUCCESS = "UPDATE_PAGE_URL_SUCCESS"
export const UPDATE_PAGE_URL_ERROR = "UPDATE_PAGE_URL_ERROR"

export const READ_PAGE_URL = "READ_PAGE_URL"
export const READ_PAGE_URL_SUCCESS = "READ_PAGE_URL_SUCCESS"

export const STATUS_PAGE_URL = "STATUS_PAGE_URL"
export const STATUS_PAGE_URL_SUCCESS = "STATUS_PAGE_URL_SUCCESS"

export const ARCHIVE_PAGE_URL = "ARCHIVE_PAGE_URL"
export const ARCHIVE_PAGE_URL_SUCCESS = "ARCHIVE_PAGE_URL_SUCCESS"