import React from "react"
import { createRoot } from 'react-dom/client'; 
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import './index.css';
import './lettersSpacing.css';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';

import store from "./store"
import ErrorPage from "pages/errorFile"
import { NextUIProvider } from "@nextui-org/react";

const container = document.getElementById('root');
const root = createRoot(container);

// const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <NextUIProvider>
    <HelmetProvider>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
    </HelmetProvider>
  </NextUIProvider>
);

serviceWorker.unregister()
