import { Helmet, HelmetProvider } from 'react-helmet-async';
import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  Modal,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import axios from "axios";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

  //redux
  import { useSelector, useDispatch } from "react-redux";

var selectedItem = []
var selectedIndex = []

import {
  getNotRead_Notifications as onGetNotificationLists,
} from "store/Notifications/Publisher_Notification/actions";

const P_Notifications = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const dispatch = useDispatch();
    const [selected, setSelected] = useState([])
    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
    const [notifications, setNotifications] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedNotification, setSelectedNotification] = useState({})
    const [jsTagModalState, setJsTagModalState] = useState(false);

    toastr.options = {
      positionClass: "toast-top-center",
      closeButton: true,
      preventDuplicates: true,
      newestOnTop: true,
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
      showDuration: 300,
      hideDuration: 1000,
      timeOut: 3000,
      extendedTimeOut: 1000
    };
  
    const { notificationLists } = useSelector(state => ({
      notificationLists: state.pub_notifications.notReadNotList,
    }));
  
    const headers = {
      "Content-Type": "application/json",
      "Accept": 'application/json',
      "Authorization": `Bearer ${userInfo.token}`
    }
  
    const body = {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        isRead:'',parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner
    }
  
    const selectRow = {
      mode: "checkbox",
      nonSelectable: [],
      selected: selected,
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          setSelected([...selected, row.id])
          setSelectedRowsStatus(true)
          selectedIndex.push(row.notificationID)
          selectedItem.push(row.notificationID)
        } else {
          setSelected(selected.filter(x => x !== row.id))
          let index = selectedIndex.indexOf(row.notificationID)
          selectedIndex.splice(index, 1)
          let index2 = selectedItem.indexOf(row.notificationID)
          selectedItem.splice(index2, 1)
          if (selectedIndex.length === 0) {
            setSelectedRowsStatus(false)
          }
        }
      },
      onSelectAll: (isSelect, rows, e) => {
        const ids = rows.map(r => r.id);
        if (isSelect) {
          setSelected(ids)
          rows.map((item, index) => {
            selectedIndex.push(item.notificationID)
            selectedItem.push(item.notificationID)
          })
          setSelectedRowsStatus(true)
        } else {
          setSelected([])
          setSelectedRowsStatus(false)
          selectedIndex = []
          selectedItem = []
        }
      }
    };
  
    const handleClose = () => {
      setSelectedRowsStatus(false)
      selectedItem = []
      setSelected([])
      selectedIndex = []
    }
  
    useEffect(() => {
      getNotifications()
    }, []);
  
    const getNotifications = () => {
      axios.post(userInfo.isSupplyPartner ? "/v2.0/notifications/readByUserID/SupplyPartner" : "/v2.0/notifications/readByUserID", body,
        {
          headers: headers
        })
        .then((res) => {
          console.log("result is notifications ==>", res)
          if (res.success) {
            res.response.data.map((ele, index) => {
              ele.id = index + 1
            })
            setNotifications(res.response.data)
          }
        })
        .catch((err) => {
          console.log("e", err)
        });
    }
  
    var node = useRef();
    const onPaginationPageChange = page => {
      if (
        node &&
        node.current &&
        node.current.props &&
        node.current.props.pagination &&
        node.current.props.pagination.options
      ) {
        node.current.props.pagination.options.onPageChange(page);
      }
    };
  
    const keyField = "id";
  
    const notificationListColumns = [
      {
        text: "ID",
 headerClasses:"ID",
        dataField: "id",
        sort: true,
        hidden: true,
        // eslint-disable-next-line react/display-name
        formatter: user => <>{user.id}</>,
      },
      {
        text: "ID",
 headerClasses:"ID",
        dataField: "notificationID",
        sort: true,
        formatter: (cellContent, notification) => (
          <>
            <span>{notification.notificationID}</span>
          </>
        ),
      },
      {
        dataField: "notificationCreateTime",
        text: "Creation Time",
        sort: true,
      },
  
      {
        text: "Notification Name",
        dataField: "notificationName",
        sort: true,
      },
  
      {
        text: "Message",
        dataField: "message",
        sort: true,
      },
      {
        text: "Status",
        dataField: "isRead",
        sort: true,
        formatter: (cellContent, notification) => (
          <>
              <span className={notification.isRead ? "active-state"  : "archived-state" }>{notification.isRead ? "Read"  : "UnRead" }</span>
          </>
        ),
      },
      {
        dataField: "",
        isDummyField: true,
        editable: false,
        text: "Action",
        align: 'center',
  
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, notification) => (
          <div className="d-flex gap-3">
                  <Link  to={'#'} onClick={() => { tog_standard(notification); }}>
                  <u>View</u> 
                </Link>
          </div>
        ),
      },
  
    ];

    function tog_standard(notification) {
      console.log(notification)
      if (notification !== '') {
        setSelectedNotification(notification)
        setJsTagModalState(!jsTagModalState);
      } else {
        setJsTagModalState(!jsTagModalState);
      }
    }
  
    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} entries
      </span>
    );
  
  
    const pageOptions = {
      paginationSize: 4,
      pageStartIndex: 1,
      sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true, // Hide the going to First and Last page button
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
    };
  
   const rowStyle = (row, rowIndex) => {
      const style = {};
      if (row.isRead) {
        style.backgroundColor = '#adb5bd36';
      } else {
        style.backgroundColor = 'rgba(54, 163, 173, .10)';
      }
      return style;
    }
  
  
    const defaultSorted = [
      {
        dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ];
  
  const readNotification = (notificationID) => (event) => {
    selectedItem.push(notificationID)
    console.log(notificationID)
    statusNotification()
  }
  
    const statusNotification = (event) => {
      console.log('clicked')
      let body2 = {
          userID: userInfo.userID,
          notificationID: selectedItem,
          userRole: userInfo.userRole,
          companyID: userInfo.companyID,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner
      }
        axios.post(userInfo.isSupplyPartner ? "/v2.0/notifications/updateIsRead/SupplyPartner" : "/v2.0/notifications/updateIsRead", body2,
          {
            headers: headers
          })
          .then((res) => {
            console.log("result is notifications ==>", res)
            if (res.success) {
              toastr.success('Notification Marked as Read Successfully!...')
              getNotifications()
              selectedItem = []
              setSelected([])
              setSelectedRowsStatus(false)
              setJsTagModalState(false)
              dispatch(onGetNotificationLists({isRead:'notRead', userRole: userInfo.userRole ,isSupplyPartner:userInfo.isSupplyPartner}));
            }
          })
          .catch((err) => {
            console.log("e", err)
          });
    }
  
    console.log(notifications)
  
    if (notifications.length > 0) {
      // loader = false
      notifications.map((item, index) => {
        if (item.isRead) {
          selectRow.nonSelectable.push(index + 1)
        }
      })
    }
  
  return (
    <div>          {loading &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      {!loading &&
        <Row>
          <Col lg="12">
            <Card  className="m-0">
              <CardBody aria-hidden="true" >
                      <ToolkitProvider
                        keyField={keyField}
                        data={notifications}
                        columns={notificationListColumns}
                        // bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="8">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                {selectedRowsStatus && selectedIndex.length > 0 &&
                                  <div className="row m-0 React-Table-Selection">
                                    {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                    <div style={{ padding: '0.5% 2%' }}>

                                      <button color="primary" className="btn btn-primary " onClick={statusNotification}>
                                        Mark as Read
                                      </button>
                                      <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                        className="mdi mdi-close-thick font-size-18"
                                      ></i></Button>
                                    </div>
                                  </div>
                                }
                              </Col>
                              <Col xl="12" className="table-responsive">
                                <div  >
                                  <BootstrapTable
                                    keyField={keyField}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    rowStyle={rowStyle}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    pagination={paginationFactory(pageOptions)}
                                    noDataIndication={<h5 className="text-center">No Data</h5>}
                                  // ref={node}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>

      }
      {selectedNotification &&
            <Modal
            size="lg"
            isOpen={jsTagModalState}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                {selectedNotification.notificationName}
              </h5>
              <button
                type="button"
                onClick={() => {
                  setJsTagModalState(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            
            <div className="modal-body">
              <div className="row mb-2">
                <div className="col-md-11">
                  <h5>Message</h5>
                </div>
              </div>
              <Input
                type="textarea"
                id="textarea"
                rows="8"
                readOnly
                name="message" value={selectedNotification.message}
              />
              <br />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setJsTagModalState(false);
                }}
                className="btn btn-secondary "
                data-dismiss="modal"
              >
                Close
              </button>
        
              <Button
                color="primary"
                className="font-16 btn-block btn btn-primary"
                onClick={readNotification(selectedNotification.notificationID)}
                disabled={selectedNotification.isRead}
              >
                Mark as Read
              </Button>
            </div>
          </Modal>
      }

  </div>
  )
}

P_Notifications.propTypes = {}

export default P_Notifications