/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Row, Col, Label, Form,
} from "reactstrap";
import { MultiSelect } from "react-multi-select-component";

//redux
import { useSelector, useDispatch } from "react-redux";

const Technology_Selection = (props) => {
  const [lineItem, setLineItem] = useState({
    devicesIn: [],
    devicesEx: [],
    webBrowsersIn: [],
    webBrowsersEx: [],
    oSsIn: [],
    oSsEx: [],
  })

  const [targetStates, setTargetStates] = useState({
    devices: false,
    OSs: false,
    webBrowsers: false,
  })

  const [deviceData, setDeviceData] = useState([])
  const [oSData, setOSData] = useState([])
  const [browserData, setBrowserData] = useState([])
  const [selectedDevice, setSelectedDevice] = useState([]);
  const [selectedOS, setSelectedOS] = useState([]);
  const [selectedBrowser, setSelectedBrowser] = useState([]);

  const [deviceState, setDeviceState] = useState(true)
  const [oSState, setOSState] = useState(true)
  const [browserState, setBrowserState] = useState(true)

  useEffect(() => {
    if (props.lineItemData !== '') {
      setLineItem(props.lineItemData)
      setSelectedDevice(props.lineItemData.devicesIn.length > 0 ? objectModals(props.lineItemData.devicesIn) : props.lineItemData.devicesEx.length > 0 ? objectModals(props.lineItemData.devicesEx) : [])
      setSelectedBrowser(props.lineItemData.webBrowsersIn.length > 0 ? objectModals(props.lineItemData.webBrowsersIn) : props.lineItemData.webBrowsersEx.length > 0 ? objectModals(props.lineItemData.webBrowsersEx) : [])
      setSelectedOS(props.lineItemData.oSsIn.length > 0 ? objectModals(props.lineItemData.oSsIn) : props.lineItemData.oSsEx.length > 0 ? objectModals(props.lineItemData.oSsEx) : [])

      setDeviceState(props.lineItemData.devicesIn.length > 0 ? true : props.lineItemData.devicesEx.length  > 0 ? false : true)
      setBrowserState(props.lineItemData.webBrowsersIn.length > 0 ? true : props.lineItemData.webBrowsersEx.length > 0 ? false : true)
      setOSState(props.lineItemData.oSsIn.length > 0 ? true : props.lineItemData.oSsEx.length > 0 ? false : true)

      setTargetStates(targetStates => ({ ...targetStates, 
        devices:props.lineItemData.devicesIn.length > 0 || props.lineItemData.devicesEx.length  > 0 ? true : false,
        OSs:props.lineItemData.oSsIn.length > 0 || props.lineItemData.oSsEx.length  > 0 ? true : false,
        webBrowsers:props.lineItemData.webBrowsersIn.length > 0 || props.lineItemData.webBrowsersEx.length  > 0 ? true : false
      }))

      props.targetedSelectedItems({
        devices:props.lineItemData.devicesIn.length > 0 || props.lineItemData.devicesEx.length  > 0 ? true : false,
        OSs:props.lineItemData.oSsIn.length > 0 || props.lineItemData.oSsEx.length  > 0 ? true : false,
        webBrowsers:props.lineItemData.webBrowsersIn.length > 0 || props.lineItemData.webBrowsersEx.length  > 0 ? true : false
      })
    }else{
      // setSelectedDevice([{value:"tablet",label:'Tablet'},{value:"phone",label:'Phone'},{value:"tv",label:'TV'},
      // {value:"desktop",label:'Desktop'},{value:"stb",label:'Set Top Box'},{value:"gc",label:'Game Console'}])
      // setSelectedBrowser(objectModals(["Apple Safari","Google Chrome","GreenBrowser","Internet Explorer","Maxthon","Microsoft Edge","Mozilla Firefox","NetCaptor","Netscape","Opera","Android",
      // "Slimjet","UC Browser",'UnKnown']))
      // setSelectedOS(objectModals(['Windows', 'Linux', 'Unix', 'MacOS','UnKnown']))
    }
  }, []);

  const objectModals = (response) => {
    var dataList = []
    response.map((item, index) => {
        let obj = {}
        obj.value = item
        obj.label = item
        dataList.push(obj)
    })
    return dataList
}

  useEffect(() => {
    console.log(props)
    if (props.contextualData.devices && props.contextualData.devices.length > 0) {
      setDeviceData([{value:"tablet",label:'Tablet'},{value:"phone",label:'Phone'},{value:"tv",label:'TV'},
      {value:"desktop",label:'Desktop'},{value:"stb",label:'Set Top Box'},{value:"gc",label:'Game Console'},{value:'UnKnown',label:'UnKnown'}])
    }
    if (props.contextualData.OSs && props.contextualData.OSs.length > 0) {
      setOSData(objectModal(props.contextualData.OSs))
    }
    if (props.contextualData.webBrowsers && props.contextualData.webBrowsers.length > 0) {
      setBrowserData(objectModal(props.contextualData.webBrowsers))
    }
  }, [props.contextualData]);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }

  const handleSelectDevice = device => {
    var devicesList = []
    setSelectedDevice(device);
    device.map((item, index) => {
      devicesList.push(item.value)
    })
    deviceState ? setLineItem(lineItem => ({ ...lineItem, devicesIn: devicesList })) : setLineItem(lineItem => ({ ...lineItem, devicesEx: devicesList }))
    deviceState ? props.selectedItems({ devicesIn: devicesList }) : props.selectedItems({ devicesEx: devicesList })
  }

  const handleSelectOS = oS => {
    setSelectedOS(oS);
    var OSsList = []
    oS.map((item, index) => {
      OSsList.push(item.value)
    })
    oSState ? setLineItem(lineItem => ({ ...lineItem, oSsIn: OSsList })) : setLineItem(lineItem => ({ ...lineItem, oSsEx: OSsList }))
    oSState ? props.selectedItems({ oSsIn: OSsList }) : props.selectedItems({ oSsEx: OSsList })
  }

  const handleSelectBrowser = browser => {
    setSelectedBrowser(browser);
    var browsersList = []
    browser.map((item, index) => {
      browsersList.push(item.value)
    })
    browserState ? setLineItem(lineItem => ({ ...lineItem, webBrowsersIn: browsersList })) : setLineItem(lineItem => ({ ...lineItem, webBrowsersEx: browsersList }));
    browserState ? props.selectedItems({ webBrowsersIn: browsersList }) : props.selectedItems({ webBrowsersEx: browsersList });
  }

  const deviceStateChange = (e) => {
    setDeviceState(e.target.checked)
    var devicess = []
    if (lineItem.devicesIn.length > 0) {
      devicess = lineItem.devicesIn
    } else {
      devicess = lineItem.devicesEx
    }
    if (e.target.checked) {
      setLineItem(lineItem => ({ ...lineItem, devicesIn: devicess, devicesEx: [] }));
      props.selectedItems({ devicesIn: devicess, devicesEx: [] })
    } else {
      setLineItem(lineItem => ({ ...lineItem, devicesEx: devicess, devicesIn: [] }));
      props.selectedItems({ devicesEx: devicess, devicesIn: [] })
    }
  }

  const oSStateChange = (e) => {
    setOSState(e.target.checked)
    var oss = []
    if (lineItem.oSsIn.length > 0) {
      oss = lineItem.oSsIn
    } else {
      oss = lineItem.oSsEx
    }
    if (e.target.checked) {
      setLineItem(lineItem => ({ ...lineItem, oSsIn: oss, oSsEx: [] }));
      props.selectedItems({ oSsIn: oss, oSsEx: [] })
    } else {
      setLineItem(lineItem => ({ ...lineItem, oSsEx: oss, oSsIn: [] }));
      props.selectedItems({ oSsEx: oss, oSsIn: [] })
    }
  }

  const browserStateChange = (e) => {
    setBrowserState(e.target.checked)
    var browserss = []
    if (lineItem.webBrowsersIn.length > 0) {
      browserss = lineItem.webBrowsersIn
    } else {
      browserss = lineItem.webBrowsersEx
    }
    if (e.target.checked) {
      setLineItem(lineItem => ({ ...lineItem, webBrowsersIn: browserss, webBrowsersEx: [] }));
      props.selectedItems({ webBrowsersIn: browserss, webBrowsersEx: [] })
    } else {
      setLineItem(lineItem => ({ ...lineItem, webBrowsersEx: browserss, webBrowsersIn: [] }));
      props.selectedItems({ webBrowsersEx: browserss, webBrowsersIn: [] })
    }
  }

  const handleCheck = e => {
    const { name, value } = e.target;
      setTargetStates(targetStates => ({ ...targetStates, [name]: e.target.checked  }));
      props.targetedSelectedItems({ [name]: e.target.checked})
      if(name === 'devices'){
        setSelectedDevice([])
        setLineItem(lineItem => ({ ...lineItem, devicesEx: [], devicesIn: [] }));
        props.selectedItems({ devicesEx: [], devicesIn: [] })
      }
      if(name === 'OSs'){
        setSelectedOS([])
        setLineItem(lineItem => ({ ...lineItem, oSsEx: [], oSsIn: [] }));
        props.selectedItems({ oSsEx: [], oSsIn: [] })
      }
      if(name === 'webBrowsers'){
        setSelectedBrowser([])
        setLineItem(lineItem => ({ ...lineItem, webBrowsersEx: [], webBrowsersIn: [] }));
        props.selectedItems({ webBrowsersEx: [], webBrowsersIn: [] })
      }
  }

  return (

    <Form>
      <Row>
        <Col lg="8">
          <div className="mb-3">
          <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="devices" id='devices' checked={targetStates.devices}  onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='devices'>Device Targeting</Label>
            </div>
            <div className='row'>
              <MultiSelect
                options={deviceData}
                value={selectedDevice}
                onChange={handleSelectDevice}
                overrideStrings={{ "selectSomeItems": "Please Select Devices" }}
                className="col-9"
                disabled={props.selectedArchived || !targetStates.devices}
              />
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-3" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd8"
                  checked={deviceState}
                  onChange={deviceStateChange}
                  disabled={props.selectedArchived || !targetStates.devices}
                />
                <label htmlFor='customSwitchsizemd8' className="form-check-label">
                  {deviceState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
          <div className="mb-3">
          <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="OSs" id='OSs' checked={targetStates.OSs}  onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='OSs'>Operating System</Label>
            </div>
            <div className='row'>
              <MultiSelect
                options={oSData}
                value={selectedOS}
                onChange={handleSelectOS}
                overrideStrings={{ "selectSomeItems": "Please Select Operating System" }}
                className="col-9"
                disabled={props.selectedArchived || !targetStates.OSs}
              />
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-3" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd9"
                  checked={oSState}
                  onChange={oSStateChange}
                  disabled={props.selectedArchived || !targetStates.OSs}
                />
                <label htmlFor='customSwitchsizemd9' className="form-check-label">
                  {oSState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
          <div className="mb-3">
          <div className="form-group d-flex mt-1">
              <input type="checkbox" className="form-check-input  p-0 mt-1 mb-2" name="webBrowsers" id='webBrowsers' checked={targetStates.webBrowsers}  onChange={handleCheck} />
              <Label className='m-3 mt-0 ' htmlFor='webBrowsers'>Web Browser Targeting</Label>
            </div>
            <div className='row'>
              <MultiSelect
                options={browserData}
                value={selectedBrowser}
                onChange={handleSelectBrowser}
                overrideStrings={{ "selectSomeItems": "Please Select Web Browsers" }}
                className="col-9"
                disabled={props.selectedArchived || !targetStates.webBrowsers}
              />
              <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-3" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd10"
                  checked={browserState}
                  onChange={browserStateChange}
                  disabled={props.selectedArchived || !targetStates.webBrowsers}
                />
                <label htmlFor='customSwitchsizemd10' className="form-check-label">
                  {browserState ? <span color='text-success' style={{ color: '#34c38f' }}>Include</span> : <span style={{ color: '#a3a3a3' }}>Exclude</span>}
                </label>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default Technology_Selection