import {
  LOAD_INTERESTS,
  NO_DATA_INTERESTS,
    GET_INTERESTS,
    GET_INTERESTS_FAIL,
    GET_INTERESTS_SUCCESS,
    ADD_INTEREST,
    ADD_INTEREST_SUCCESS,
    ADD_INTEREST_ERROR,
    UPDATE_INTEREST,
    UPDATE_INTEREST_SUCCESS,
    UPDATE_INTEREST_ERROR,
    READ_INTEREST,
    READ_INTEREST_SUCCESS,
    STATUS_INTEREST,
    STATUS_INTEREST_SUCCESS,
    ARCHIVE_INTEREST ,
    ARCHIVE_INTEREST_SUCCESS

  } from "./actionTypes"

  export const loadInterests = Params => ({
    type: LOAD_INTERESTS,
    payload:Params
  })

  export const noInterests = Params => ({
    type: NO_DATA_INTERESTS,
    payload:Params
  })

  export const getInterests = () => ({
    type: GET_INTERESTS,
  })
  
  export const getInterestsSuccess = Interests => ({
    type: GET_INTERESTS_SUCCESS,
    payload: Interests,
  })

  export const getInterestsFail = error => ({
    type: GET_INTERESTS_FAIL,
    payload: error,
  })

  export const addNewInterest = Interest => ({
    type: ADD_INTEREST,
    payload: Interest,
  })
  
  export const addInterestSuccess = Interest => ({
    type: ADD_INTEREST_SUCCESS,
    payload: Interest,
  })
  
  export const addInterestFail = Interest => ({
    type: ADD_INTEREST_ERROR,
    payload: Interest,
  })

  export const updateInterest = Interest => ({
    type: UPDATE_INTEREST,
    payload: Interest,
  })
  
  export const updateInterestSuccess = Interest => ({
    type: UPDATE_INTEREST_SUCCESS,
    payload: Interest,
  })
  
  export const updateInterestFail = Interest => ({
    type: UPDATE_INTEREST_ERROR,
    payload: Interest,
  })

  export const readInterest = Interest => ({
    type: READ_INTEREST,
    payload: Interest,
  })

  export const readInterestSuccess = Interest => ({
    type: READ_INTEREST_SUCCESS,
    payload: Interest,
  })

  export const statusInterest = Interest => ({
    type: STATUS_INTEREST,
    payload: Interest,
  })

  export const statusInterestSuccess = Interest => ({
    type: STATUS_INTEREST_SUCCESS,
    payload: Interest,
  })

  export const archiveInterest = Interest => ({
    type: ARCHIVE_INTEREST,
    payload: Interest,
  })

  export const archiveInterestSuccess = Interest => ({
    type: ARCHIVE_INTEREST_SUCCESS,
    payload: Interest,
  })