/* IOS */
export const LOAD_ACTIVITIES_IOS = "LOAD_ACTIVITIES_IOS"
export const NO_DATA = "NO_DATA"
export const GET_IOS = "GET_IOS"
export const GET_IOS_SUCCESS = "GET_IOS_SUCCESS"
export const GET_IOS_FAIL = "GET_IOS_FAIL"

export const ADD_IOS = "ADD_IOS"
export const ADD_IOS_SUCCESS = "ADD_IOS_SUCCESS"
export const ADD_IOS_ERROR = "ADD_IOS_ERROR"

export const UPDATE_IOS = "UPDATE_IOS"
export const UPDATE_IOS_SUCCESS = "UPDATE_IOS_SUCCESS"
export const UPDATE_IOS_ERROR = "UPDATE_IOS_ERROR"

export const READ_IOS = "READ_IOS"
export const READ_IOS_SUCCESS = "READ_IOS_SUCCESS"

export const STATUS_IOS = "STATUS_IOS"
export const STATUS_IOS_SUCCESS = "STATUS_IOS_SUCCESS"

export const ARCHIVE_IOS = "ARCHIVE_IOS"
export const ARCHIVE_IOS_SUCCESS = "ARCHIVE_IOS_SUCCESS"