import React, { useEffect, useState, useRef } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Input,
    Label, Spinner
} from "reactstrap"

import DateRangePickers from '../../../helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
// import Create_PM from "./Create_PM"

import {
    getPartners as onGetPartners,
    statusPartner as onStatusPartner,
    archivePartner as onArchivePartner,
    statusPartnerSuccess,
    archivePartnerSuccess
} from "store/PartnerManagement/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";
import LoadingTable from "pages/LoaderPages/LoadingTable";

var selectedItem = ''
var selectedData = ''
var selectedIndex = []

const Supply_Partner = () => {
    const dispatch = useDispatch();
    const [isRight, setIsRight] = useState(false);
    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)

    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());

    const [switchLoader, setSwitchLoader] = useState(true)
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const userRole = localStorage.getItem('UserRole');

    const handleSwitch = () => {
        setSwitchLoader(!switchLoader)
    }

    const changeDatePick = (event) => {
        console.log(event)
        console.log("start: ", event.startDate._d);
        console.log("end: ", event.endDate._d);
        setFromDate(event.startDate._d.toISOString());
        setToDate(event.endDate._d.toISOString());
    }


    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        progressBar: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 5000,
        extendedTimeOut: 1000
    };

    const selectRow = {
        mode: "checkbox",
        onSelect: (row, isSelect, rowIndex, e) => {
            console.log(row, isSelect, rowIndex)
            if (isSelect) {
                setSelectedRowsStatus(true)
                selectedIndex.push(row.partnerID)
                selectedItem = row.partnerID
                console.log(selectedIndex)
            } else {
                let index = selectedIndex.indexOf(row.partnerID)
                selectedIndex.splice(index, 1)
                if (selectedIndex.length === 0) {
                    setSelectedRowsStatus(false)
                }
                console.log(selectedIndex)
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            console.log(isSelect);
            console.log(rows);
            console.log(e);
        }
    };

    const handleClose = () => {
        setSelectedRowsStatus(false)
    }

    const { partners } = useSelector(state => ({
        partners: state.partners.partners,
    }));

    const { loading } = useSelector(state => ({
        loading: state.partners.loading,
    }));

    const { noData } = useSelector(state => ({
        noData: state.partners.noData,
    }));

    const { partnersResponse } = useSelector(state => ({
        partnersResponse: state.partners.response,
    }));

    const { archivePartnersResponse } = useSelector(state => ({
        archivePartnersResponse: state.partners.archiveMsg,
    }));

    useEffect(() => {
        if (loading) {
            setSwitchLoader(true)
        }
    }, [loading]);

    console.log(partnersResponse)
    useEffect(() => {
        if (partnersResponse !== null) {
            if (partnersResponse.success) {
                toastr.success('Partner Status Changed Successfully')
                dispatch(onGetPartners({ filter: 'Active' }));
                dispatch(statusPartnerSuccess(null));
                setSelectedRowsStatus(false)
                selectedItem = ''
                selectedIndex = []
                selectRow.selected = []
            }
        }
    }, [dispatch, partnersResponse]);

    useEffect(() => {
        if (archivePartnersResponse !== null) {
            if (archivePartnersResponse.success) {
                toastr.success('Partner Archived Successfully')
                dispatch(onGetPartners({ filter: 'Active' }));
                dispatch(archivePartnerSuccess(null));
                setSelectedRowsStatus(false)
                selectedItem = ''
                selectRow.selected = []
                selectedIndex = []
            }
        }
    }, [dispatch, archivePartnersResponse]);

    const toggleRightCanvas = () => {
        selectedData = ''
        dispatch(onGetPartners({ filter: 'Active' }));
        setIsRight(!isRight);
    };
    const toggleEnableScroll = () => {
        setIsEnableScroll(!isEnableScroll);
    };

    const toggleDrawer = (close, partner) => (event) => {
        console.log(event)
        if (event === 'Create') {
            toastr.success('Partner Saved Successfully!...')
        }
        if (event === 'Update') {
            toastr.success('Partner Updated Successfully!...')
        }
        selectedData = partner
        dispatch(onGetPartners({ filter: 'Active' }));
        setIsRight(close);
    };

    useEffect(() => {
        if (partners !== null) {
            dispatch(onGetPartners({ filter: 'Active' }));
        }
    }, []);

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const partnerListColumns = [
        {
            text: "ID",
            headerClasses: "ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            text: "ID",
            headerClasses: "ID",
            dataField: "partnerID",
            sort: true,
        },
        {
            dataField: "userCreateTime",
            text: "Creation Date",
            sort: true,
        },
        {
            text: "Partner Name",
            dataField: "userName",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, partner) => (
                <>
                    <h5 className="font-size-12 mb-1">
                        <Link to={`/Publisher?partnerId=${partner.partnerID}&partnerName=${partner.userName}&status=PublisherCreateByParent`} className="color-dark-blue ">
                            {partner.userName}
                        </Link>
                    </h5>
                </>
            ),
        },
        {
            dataField: "userEmail",
            text: "CSM Email",
            sort: true,
            hidden: (userInfo.userRole !== 'Root' || userRole !== 'Admin')
        },

        {
            dataField: "userStatus",
            text: "Status",
            sort: true,
            formatter: (cellContent, partner) => (
                <>
                    <span className={partner.newStatus === 'Active' ? "active-state" : partner.newStatus === 'Inactive' ? "inactive-state" : "archived-state"}>{partner.newStatus}</span>
                </>
            ),
        },
        // {
        //     dataField: "",
        //     isDummyField: true,
        //     editable: false,
        //     text: "Action",
        //     align: 'center',

        //     // eslint-disable-next-line react/display-name
        //     formatter: (cellContent, partner) => (
        //         <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
        //             <Link className="text-success" to="#" onClick={toggleDrawer(true, partner)}>
        //                 <i
        //                     className="mdi mdi-pencil font-size-18"
        //                     id="edittooltip"
        //                 ></i>
        //             </Link>
        //         </div>
        //     ),
        // },
    ];

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );


    const pageOptions = {
        paginationSize: 4,
        pageStartIndex: 1,
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };

    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const archivePartner = async () => {
        dispatch(onArchivePartner({ partnerID: selectedItem }));
    }

    const statusPartner = (state) => (event) => {
        console.log(state)
        dispatch(onStatusPartner({ partnerID: selectedItem, userStatus: state }));
    }

    console.log(partners)

    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
            <div className="page-content">
                <Helmet>
                    <title>Partner Management | Bidsxchange</title>
                </Helmet>
                <Container fluid={true}>
                    {/* Render Breadcrumbs */}
                    <div className="page-title-box d-sm-flex  justify-content-between">
                        {/* <h4 className=" font-size-18">Supply Partner</h4> */}
                        <SupplyBreadCrumb title="Supply" breadcrumbItem="Supply Partner" breadList="All Supply Partners" />
                    </div>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    {switchLoader &&
                                        <LoadingTable loading={loading} targetLoading={handleSwitch} />
                                    }
                                    {!switchLoader &&

                                        <ToolkitProvider
                                            keyField={keyField}
                                            data={partners}
                                            columns={partnerListColumns}
                                            // bootstrap4
                                            search
                                        >
                                            {toolkitProps => (
                                                <React.Fragment>
                                                    <Row className="mb-2">
                                                        <Col sm="4">
                                                            <div className="search-box ms-2 mb-2 d-inline-block">
                                                                <div className="position-relative">
                                                                    <SearchBar {...toolkitProps.searchProps} />
                                                                    <i className="bx bx-search-alt search-icon" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col sm="4"></Col>
                                                        <Col sm="4" className="date-range ">
                                                            <div className="text-sm-end">
                                                                <DateRangePickers datePick={changeDatePick} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        {/* <Col xl="12">
                                                                        {selectedRowsStatus && selectedIndex.length > 0 &&
                                                                            <div className="row m-0" style={{ width: '100%', backgroundColor: '#556ee6', color: 'white', padding: '10px' }}>
                                                                                <div style={{ padding: '0 2%' }}>
                                                                                    <button color="primary" className="btn btn-primary " onClick={statusPartner(true)}>
                                                                                        Activate
                                                                                    </button>
                                                                                    &nbsp;  &nbsp;
                                                                                    <button color="primary" className="btn btn-primary " onClick={statusPartner(false)}>
                                                                                        DeActivate
                                                                                    </button>
                                                                                    &nbsp;  &nbsp;
                                                                                    <Button color="primary" className="btn btn-primary " onClick={archivePartner} >
                                                                                        Archive
                                                                                    </Button>
                                                                                    <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                                                                        className="mdi mdi-close-thick font-size-18"
                                                                                    ></i></Button>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </Col> */}
                                                        <Col xl="12" className="table-responsive">
                                                            <div >
                                                                <BootstrapTable
                                                                    keyField={keyField}
                                                                    responsive
                                                                    bordered={false}
                                                                    striped={false}
                                                                    defaultSorted={defaultSorted}
                                                                    classes={
                                                                        "table align-middle table-nowrap"
                                                                    }
                                                                    headerWrapperClasses={"thead-light"}
                                                                    {...toolkitProps.baseProps}
                                                                    pagination={paginationFactory(pageOptions)}
                                                                    noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                // ref={node}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                            )}
                                        </ToolkitProvider>

                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* </div> */}

            {/* <Offcanvas isOpen={isRight} scrollable toggle={toggleRightCanvas}>
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {selectedData ? 'Edit Partner' : 'New Partner'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <Create_PM closeCanvas={toggleDrawer(false, '')} selectedPartner={selectedData} />
                </OffcanvasBody>
            </Offcanvas> */}


        </React.Fragment>
    )
}

export default Supply_Partner
