import {
    LOAD_ACTIVITIES,
    GET_TCS_SUCCESS,
    GET_TCS_FAIL,
    ADD_TC_SUCCESS,
    ADD_TC_ERROR,
    UPDATE_TC_SUCCESS,
    UPDATE_TC_ERROR,
    READ_TC_SUCCESS,
    STATUS_TC_SUCCESS,
    ARCHIVE_TC_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    tcsList: [],
    error: {},
    loading:false,
    successMsg: null,
    errorMsg: null,
    tc: null,
    response:null,
    archiveMsg:null,
}

const tcs = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_ACTIVITIES:
            return {
                ...state,
                loading: true,
            }
        case GET_TCS_SUCCESS:
            return {
                ...state,
                loading:false,
                tcsList: action.payload,
            }

        case GET_TCS_FAIL:
            return {
                ...state,
                loading:false,
                error: action.payload,
            }
        case ADD_TC_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_TC_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_TCS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_TC_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_TCS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_TC_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_TCS_SUCCESS' ? null : action.payload,
            }
        case READ_TC_SUCCESS:
            return {
                ...state,
                tc: action.payload,
            }
        case STATUS_TC_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_TC_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default tcs