/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
    Row, Col, Input, Label, Form, FormFeedback, DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown, Spinner
} from "reactstrap";
import Select from "react-select";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

//redux
// import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

// actions
import {
    addNewBidder as onADDBidder,
    updateBidder as onUpdateBidder,
    addBidderSuccess,
    addBidderFail,
    updateBidderSuccess,
    updateBidderFail,
} from "store/Demand/Bidder/actions";

import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";
import bidders from "store/Demand/Bidder/reducer";

const NewBidder = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useDispatch();
    const [bidder, setBidder] = useState({
        bidderName:'',
        bidderJSON:[]
    });
    const [videosList, setVideosList] = useState([])
    const [loader, setLoader] = useState(false);
    console.log(props)

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }


    useEffect(() => {
        if (props.selectedBidder !== '') {
            setIsEdit(true)
            console.log(props.selectedBidder)
            setBidder(props.selectedBidder)
            setVideosList(props.selectedBidder.bidderJSON)
        }
    }, []);

    const { errorMsg, successMsg } = useSelector(state => ({
        errorMsg: state.bidders.errorMsg,
        successMsg: state.bidders.successMsg,
    }));

    console.log(errorMsg, successMsg)

    useEffect(() => {
        if (successMsg !== null) {
            if (successMsg.statusCode === 200) {
                dispatch(addBidderSuccess(""));
                dispatch(addBidderFail(""));
                dispatch(updateBidderSuccess(""));
                dispatch(updateBidderFail(""));
                setLoader(false)
                    if (props.selectedBidder !== '') {
                        props.closeCanvas('Update')
                    } else {
                        props.closeCanvas('Create')
                    }
            }
        }
    }, [successMsg]);

    useEffect(() => {
        if (errorMsg !== null && errorMsg !== '') {
            setLoader(false)
            if (errorMsg.status && errorMsg.status === 500) {
            } else {
                dispatch(addBidderFail(""));
                dispatch(updateBidderFail(""));
            }
        }
    }, [errorMsg]);

    const toggleToast = (n) => (e) => {
        dispatch(addBidderFail(""));
        dispatch(updateBidderFail(""));
        props.closeCanvas('')
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBidder(bidder => ({ ...bidder, [name]: value }));
      }


    const addDirectVideoContent = () => {
        let videoContentList = [...videosList]
        videoContentList.push({
            ParameterName: '',
            type: '',
            required: false,
        })
        setVideosList([...videoContentList])
    }

    const handleChangeUser = (index) => e => {
        const { name, value } = e.target;
        let videoContentList = [...videosList]
        videoContentList[index] = { ...videoContentList[index], [name]: value };
        setVideosList([...videoContentList])
        setBidder(bidder => ({ ...bidder, bidderJSON: [...videoContentList] }));
    }

    const handleChangeCheck = (index) => e => {
        const { name, value } = e.target;
        let videoContentList = [...videosList]
        videoContentList[index] = { ...videoContentList[index], [name]: e.target.checked };
        setVideosList([...videoContentList])
        setBidder(bidder => ({ ...bidder, bidderJSON: [...videoContentList] }));
    }

    const removeDirectContent = (i) => e => {
        let videoContentList = [...videosList]
        videoContentList.splice(i, 1)
        setVideosList([...videoContentList])
        setBidder(bidder => ({ ...bidder, bidderJSON: [...videoContentList] }));
    }

    const createContentUI = () => {
        return videosList.map((item, i) =>
            <div key={i}>
                <div className="contentSettings">
                    {/* {i === 0 ? <h5 className='mt-3 mb-4'>Current User</h5> : <h5 className='mt-3 mb-4'>User {i}</h5>} */}
                    <div className="row mt-1">
                        <div className="col-4">
                            <div className="mb-3">
                                <Input
                                    name="ParameterName"
                                    onChange={handleChangeUser(i)}
                                    value={item.ParameterName}
                                    className={'form-control'}
                                    placeholder="Enter ParameterName"
                                />
                            </div>
                            <br />
                        </div>
                        <div className="col-3">
                            <div className="mb-3">
                                <Input
                                    name="type"
                                    onChange={handleChangeUser(i)}
                                    value={item.type}
                                    className={'form-control'}
                                    placeholder="Enter Data Type"
                                />
                            </div>
                            <br />
                        </div>
                        <div className="col-3">
                            <div className="mb-3" >
                                <div className="form-check form-switch form-check-success form-switch-md  mt-1  " >
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="settingsEnabled"
                                        name="required"
                                        checked={item.required}
                                        onChange={handleChangeCheck(i)}
                                    />
                                    <label className="form-check-label  m-2 mt-1 " htmlFor="settingsEnabled">
                                        {item.required ? <span color='text-success' style={{ color: '#000000' }}>Required </span> : <span style={{ color: '#a3a3a3' }}>Optional</span>}
                                    </label>
                                </div>
                            </div>
                            <br />
                        </div>
                        <div className="col-1 p-0">
                            <Link className="text-danger" to="#" onClick={removeDirectContent(i)} style={{ position: 'absolute', top: '0.5pc', right: '50%' }} >
                                <i
                                    className="bx bx-trash font-size-18"
                                    id="edittooltip"
                                ></i>
                            </Link>
                            {/* <button type='link' className=' btn-danger  ' style={{ position: 'absolute', background: '#dc3545', right: '0' }} 
                    ><i className="bx bx-trash"></i></button> */}
                        </div>
                    </div>
                </div>
            </div>

        )

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(bidder)
          if (bidder.bidderName && bidder.bidderJSON.length > 0) {
            setLoader(true)
            if (props.selectedBidder !== '') {
              dispatch(onUpdateBidder({ ...bidder, bidderID: props.selectedBidder.bidderID,bidderName:bidder.bidderName.trim() }));
            } else {
              dispatch(onADDBidder({ ...bidder,bidderName:bidder.bidderName.trim() }));
            }
          }
        
      }

    return (
        <React.Fragment>
            {loader &&
                <Spinner className="ms-2 loader" color="primary" />
            }
            <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
            <Form onSubmit={handleSubmit}>
                    <Row style={{ padding: '3%' }}>
                        <Col xs={6}>
                            <div className="mb-3">
                                <Label>Bidder Name</Label>
                                <Input
                                    name="bidderName"
                                    onChange={handleChange}
                                    value={bidder.bidderName}
                                    className={'form-control'}
                                    placeholder="Enter Bidder Name"
                                />

                            </div>
                        </Col>
                            <div className=" mb-3">
                                {createContentUI()}
                                <div className='col-12'>
                                    <div className='d-flex'>
                                        <button type='button' className='mt-4 mb-4 btn btn-primary ml-auto' style={{ marginRight: '1rem  2.5rem 1rem auto' }} onClick={addDirectVideoContent} >Add Parameter &nbsp;&nbsp; <i className="fa fa-plus"></i></button>

                                    </div>
                                </div>
                            </div>
                    
                    </Row>
                    <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
                        <Col>
                            <div className="text-start">
                                &nbsp;&nbsp;
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    // eslint-disable-next-line react/prop-types
                                    onClick={props.closeCanvas}
                                >
                                    Back
                                </button>
                                &nbsp;&nbsp;
                                {!isEdit &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Save
                                    </button>
                                }

                                &nbsp;&nbsp;

                                &nbsp;&nbsp;
                                {isEdit && !props.selectedArchived &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Update
                                    </button>
                                }
                            </div>
                        </Col>
                    </Row>
                </Form>
                {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
                    <Toaster status="error" msg={errorMsg.message} />
                }
            </div>
            {errorMsg !== null && errorMsg !== '' && errorMsg.status && errorMsg.status === 500 &&
                <div className="p-0 justify-content-center d-flex">
                    <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')} />
                </div>
            }
        </React.Fragment>
    );
};

NewBidder.propTypes = {}

export default NewBidder