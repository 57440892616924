import React, { useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import AnalyticsDashBoard from "./AnalyticsDashBoard";
import LiveServerDashboard from "./liveServerDashboard";
import DateRangePickers from 'helpers/DateRangePicker'

const ServerDashboard = props => {
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const changeDatePick = (event) => {
    console.log(event)
    console.log("start: ", event.startDate._d);
    console.log("end: ", event.endDate._d);
    setFromDate(event.startDate._d.toISOString());
    setToDate(event.endDate._d.toISOString());
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Helmet>
          {/*<title>Server Dashboard | Bidsxchange</title>*/}
          <title>Server Dashboard | Bidsxchange  </title>
        </Helmet>
        <Container fluid>
          <Row>
            <Col md="3">
              <Breadcrumbs
                title="Server Dashboard"
                breadcrumbItem="Server Dashboard"
              />
            </Col>
            <Col md="5">
            <div className="d-flex justify-content-center mb-4">
                    <Nav tabs className="nav-tabs-custom nav-justified" style={{ width: '30pc' }}>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "1",
                          })}
                          onClick={() => {
                            toggleCustom("1");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-none d-sm-block" style={{ fontSize: '13px' }}>Summary Dashboard</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "2",
                          })}
                          onClick={() => {
                            toggleCustom("2");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-user"></i>
                          </span>
                          <span className="d-none d-sm-block" style={{ fontSize: '13px' }}>Live Dashboard</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
            </Col>
            <Col md="4">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-end mb-4">
                    &nbsp;&nbsp;
                    <DateRangePickers datePick={changeDatePick} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <TabContent
            activeTab={customActiveTab}
            className=" text-muted"
          >
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <AnalyticsDashBoard activeId={customActiveTab} />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <LiveServerDashboard activeId={customActiveTab} />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </React.Fragment >
  )
}

ServerDashboard.propTypes = {}

export default ServerDashboard