/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback, Spinner,Table,Button } from "reactstrap";
import CreatableSelect from 'react-select/creatable';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

import {
    getCategory as onGetCategories,
} from "store/customTracking/Categories/actions";

// actions
import {
    addNewScrappy as onADDScrappy,
    updateScrappy as onUpdateScrappy,
    readScrappy as onReadScrappy,
    addScrappySuccess,
    addScrappyFail,
    updateScrappySuccess,
    updateScrappyFail,
} from "store/customTracking/Scrappys/actions";

import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

const NewScrappy = props => {
    const dispatch = useDispatch();
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [submitted, setSubmitted] = useState(false);
    const [loader, setLoader] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    console.log(props)

    const [finalData, setFinalData] = useState({
        scrapySite: '',
        scrapyApplicationType: "",
        scrapyDevice: '',
        scrapyUrl: '',
        scrapyScreenshots: [],
        scrappyFinalUrl: '',
    })

        toastr.options = {
            positionClass: "toast-top-right",
            closeButton: true,
            preventDuplicates: true,
            newestOnTop: true,
            showEasing: 'swing',
            hideEasing: 'linear',
            showMethod: 'fadeIn',
            hideMethod: 'fadeOut',
            showDuration: 300,
            hideDuration: 1000,
            timeOut: 3000,
            extendedTimeOut: 1000
        };

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`,
        UserID: userInfo.userID,
        UserRole: userInfo.userRole,
    }

    useEffect(() => {
        if (props.selectedScrappy !== '') {
            setIsEdit(true)
            setFinalData(props.selectedScrappy);
        }
    }, [])

    const handleImageUpload = (event) => {
        const files = event.target.files;
        const newImages = [...finalData.scrapyScreenshots];
        let validFiles = [];
        let fileExceedsLimit = false;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.size > 1024 * 1024) {
              fileExceedsLimit = true;
              break;
            } else {
              validFiles.push(file);
            }
          }

          if (fileExceedsLimit) {
            toastr.error("One or more files exceed the 1MB limit and were not uploaded.");
            event.target.value = ""; // Reset file input
            return;
          }

          validFiles.forEach((file) => {
            if (file.type.startsWith("image/")) {
              const reader = new FileReader();
              reader.onloadend = () => {
                newImages.push({ [`image_${newImages.length + 1}`]: reader.result });
                setFinalData((prevData) => ({
                  ...prevData,
                  scrapyScreenshots: newImages,
                }));
              };
              reader.readAsDataURL(file);
            }
          });
        event.target.value = ""; // Reset file input
    };

    const handleDelete = (index) => {
            setFinalData((prevData) => ({
                ...prevData,
            scrapyScreenshots: prevData.scrapyScreenshots.filter((_, i) => i !== index),
            }));
    };

    const handleChange = (e) => {
        console.log(e.target)
        const { name, value } = e.target;
        console.log(e.target.type)
        if (name === 'scrapyApplicationType' && value === 'app') {
                setFinalData(finalData => ({ ...finalData, scrapyDevice: 'Mobile' ,[name]: value }));
        } else {
            setFinalData(finalData => ({ ...finalData, [name]: value }));
        }

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(finalData)
        setSubmitted(true);
        if (finalData.scrapySite) {
            setLoader(true)
            if (props.selectedScrappy !== '') {
                dispatch(onUpdateScrappy({ ...finalData, scrapySite: finalData.scrapySite.trim() }));
            } else {
                dispatch(onADDScrappy({ ...finalData, scrapySite: finalData.scrapySite.trim() }));
            }
        }
    }

    const { errorMsg, successMsg } = useSelector(state => ({
        errorMsg: state.scrappy.errorMsg,
        successMsg: state.scrappy.successMsg,
    }));

    console.log(errorMsg, successMsg)

    useEffect(() => {
        if (successMsg !== null) {
            if (successMsg.success) {
                let bodyApprove ={}
                if (props.selectedScrappy !== '') {
                    bodyApprove = {...finalData,
                        userID: userInfo.userID,
                        userRole: userInfo.userRole,
                        companyID: userInfo.companyID,
                        name: userInfo.name,
                        userName: userInfo.name,
                    }
                }else{
                    bodyApprove = {...finalData,scrappyFinalUrl:successMsg.github_url,scrapySite: finalData.scrapySite.trim(),
                        userID: userInfo.userID,
                        userRole: userInfo.userRole,
                        companyID: userInfo.companyID,
                        name: userInfo.name,
                        userName: userInfo.name,
                    }
                }
                axios.post(props.selectedScrappy !== '' ? "/api/scrapy/"+ props.selectedScrappy.scrapyID : "/api/scrapy", bodyApprove,
                    {
                        headers: headers
                    })
                    .then((res) => {
                        console.log("result is Scrapy ==>", res)
                        if (res.data) {
                            dispatch(addScrappySuccess(""));
                            dispatch(addScrappyFail(""));
                            dispatch(updateScrappySuccess(""));
                            dispatch(updateScrappyFail(""));
                            setLoader(false)
                            if (props.selectedScrappy !== '') {
                                props.closeCanvas('Update')
                            } else {
                                props.closeCanvas('Create')
                            }
                        }
                    })
                    .catch((err) => {
                        console.log("e", err)
                    });
            }
        }
    }, [successMsg]);

    useEffect(() => {
        if (errorMsg !== null && errorMsg !== '') {
            setLoader(false)
            if (errorMsg.status && errorMsg.status === 500) {
            } else {
                dispatch(addScrappySuccess(""));
                dispatch(addScrappyFail(""));
                dispatch(updateScrappySuccess(""));
                dispatch(updateScrappyFail(""));
            }
        }
    }, [errorMsg]);

    const toggleToast = (n) => (e) => {
        dispatch(addScrappySuccess(""));
        dispatch(addScrappyFail(""));
        dispatch(updateScrappySuccess(""));
        dispatch(updateScrappyFail(""));
        props.closeCanvas('')
    };

    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };

    function allowAlphaNumericSpace(e) {
        var code = ('charCode' in e) ? e.charCode : e.keyCode;
        if (!(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault(); }
    };

    return (
        <React.Fragment>
            {loader &&
                <Spinner className="ms-2 loader" color="primary" />
            }
            <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
                <Form onSubmit={handleSubmit}>
                    <Row style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
                        <Col xs={12}>
                            <div className="mb-3">
                                <Label className="form-label"> Site Name</Label>
                                <Input
                                    name="scrapySite"
                                    type="text"
                                    onChange={handleChange}
                                    maxLength={65}
                                    value={finalData.scrapySite}
                                    onKeyPress={allowAlphaNumericSpace}
                                    className={'form-control' + (submitted && !finalData.scrapySite ? ' is-invalid' : '')}
                                    readOnly={props.selectedArchived || props.selectedScrappy !== ''}
                                />
                                {submitted && !finalData.scrapySite ? (
                                    <FormFeedback type="invalid">{' Site Name is required'}</FormFeedback>
                                ) : null}
                            </div>

                            <div className="mb-3">
                                <Label className="form-label"> Application Type</Label>
                                <Input
                                    name="scrapyApplicationType"
                                    type="select"
                                    value={finalData.scrapyApplicationType}
                                    className={'form-control form-select' + (submitted && !finalData.scrapyApplicationType ? ' is-invalid' : '')}
                                    onChange={handleChange}
                                    disabled={props.selectedScrappy !== ''}
                                >
                                    <option value=''>Select</option>
                                    <option value='app'>App</option>
                                    <option value='site'>Site</option>
                                </Input>
                                {submitted && !finalData.scrapyApplicationType ? (
                                    <FormFeedback type="invalid">{'Application Type is required'}</FormFeedback>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label"> Device Type</Label>
                                <Input
                                    name="scrapyDevice"
                                    type="select"
                                    value={finalData.scrapyDevice}
                                    className={'form-control form-select' + (submitted && !finalData.scrapyDevice ? ' is-invalid' : '')}
                                    onChange={handleChange}
                                    disabled={finalData.scrapyApplicationType === 'app'}
                                >
                                    <option value=''>Select</option>
                                    <option value='Desktop'>Desktop</option>
                                    <option value='Mobile'>Mobile</option>
                                </Input>
                                {submitted && !finalData.scrapyDevice ? (
                                    <FormFeedback type="invalid">{'Device Type is required'}</FormFeedback>
                                ) : null}
                            </div>
                            {finalData.scrapyApplicationType === 'site' &&
                                <div className="mb-3">
                                    <Label >
                                        URL
                                    </Label>
                                    <Input
                                        type="text" name="scrapyUrl"
                                        value={finalData.scrapyUrl}
                                        className={'form-control' + (submitted && !finalData.scrapyUrl ? ' is-invalid' : '')}
                                        readOnly={props.selectedArchived}
                                        onChange={handleChange}
                                    />
                                </div>
                            }
                            {finalData.scrapyApplicationType === 'app' &&
                                <div className="mb-3">
                                    <Label className="form-label">Browse Image File</Label>
                                    <div>
                                        <Input type="file" accept="image/x-png,image/gif,image/jpeg" multiple onChange={handleImageUpload} />
                                        <span className="notes">Note: Allowed file types: gif, jpg, png, jpeg. Maximum allowed file size: 1 MB.</span>
                                    </div>
                                </div>
                            }
                            {finalData.scrapyApplicationType === 'app' &&
                            <>  
                                {finalData.scrapyScreenshots.length > 0 ? (
                                <Table bordered>
                                    <thead>
                                        <tr className="table-light">
                                            <th className="text-center">#</th>
                                            <th className="text-center">Image</th>
                                            <th className="text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {finalData.scrapyScreenshots.map((image, index) => {
                                            const imageKey = Object.keys(image)[0];
                                            return (
                                              <tr key={index} className="text-center align-middle">
                                                <td>{index + 1}</td>
                                                <td>
                                                  <img
                                                    src={image[imageKey]}
                                                    alt={`Uploaded ${index}`}
                                                    className="img-thumbnail"
                                                    style={{ width: "60px", height: "60px" }}
                                                  />
                                                </td>
                                                <td>
                                                  <Button color="danger" onClick={() => handleDelete(index)}>
                                                    Delete
                                                  </Button>
                                                </td>
                                              </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            ) : (
                                <p className="text-center text-muted">No images uploaded yet.</p>
                            )}
                            </>
                            }
                        </Col>
                    </Row>
                    <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
                        <Col>
                            <div className="text-start">
                                &nbsp;&nbsp;
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    // eslint-disable-next-line react/prop-types
                                    onClick={props.closeCanvas}
                                >
                                    Back
                                </button>
                                &nbsp;&nbsp;
                                {!isEdit &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Save
                                    </button>
                                }
                                &nbsp;&nbsp;

                                {isEdit && !props.selectedArchived &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Update
                                    </button>
                                }
                            </div>
                        </Col>
                    </Row>
                </Form>
                {errorMsg !== null && errorMsg !== '' && !errorMsg.success &&
                    <Toaster status="error" msg={errorMsg.error_git} />
                }
            </div>
            {errorMsg !== null && errorMsg !== '' && errorMsg.success && errorMsg.status === 500 &&
                <div className="p-0 justify-content-center d-flex">
                    <ToasterWarning msg={errorMsg.error_git} closeWarning={toggleToast('')} />
                </div>
            }
        </React.Fragment>
    )
}


NewScrappy.propTypes = {}

export default NewScrappy