import {

    GET_ADMIN_ANALYTICS_REPORTS,
    GET_ADMIN_ANALYTICS_REPORTS_FAIL,
    GET_ADMIN_ANALYTICS_REPORTS_SUCCESS,

    GET_AS_ANALYTICS_REPORTS,
    GET_AS_ANALYTICS_REPORTS_SUCCESS,
    GET_AS_ANALYTICS_REPORTS_FAIL,

    GET_YM_ANALYTICS_REPORTS,
    GET_YM_ANALYTICS_REPORTS_SUCCESS,
    GET_YM_ANALYTICS_REPORTS_FAIL,

    GET_MIS_ANALYTICS_REPORTS,
    GET_MIS_ANALYTICS_REPORTS_SUCCESS,
    GET_MIS_ANALYTICS_REPORTS_FAIL,

    GET_PAGE_URL_REPORTS,
    GET_PAGE_URL_REPORTS_FAIL,
    GET_PAGE_URL_REPORTS_SUCCESS,

    GET_TEAM_PAGE_URL_REPORTS,
    GET_TEAM_PAGE_URL_REPORTS_FAIL,
    GET_TEAM_PAGE_URL_REPORTS_SUCCESS,

    GET_TEAM_HISTORY_REPORTS,
    GET_TEAM_HISTORY_REPORTS_FAIL,
    GET_TEAM_HISTORY_REPORTS_SUCCESS,

    LOAD_REPORTS

  } from "./actionTypes"

  export const loadReportsAdmin = Params => ({
    type: LOAD_REPORTS,
    payload:Params
  })

  

  export const getAdminAnalyticsReports = Params => ({
    type: GET_ADMIN_ANALYTICS_REPORTS,
    payload:Params
  })
  
  export const getAdminAnalyticsReportsSuccess = AnalyticsDisplayReports => ({
    type: GET_ADMIN_ANALYTICS_REPORTS_SUCCESS,
    payload: AnalyticsDisplayReports,
  })

  export const getAdminAnalyticsReportsFail = error => ({
    type: GET_ADMIN_ANALYTICS_REPORTS_FAIL,
    payload: error,
  })

  export const getASAnalyticsReports = Params => ({
    type: GET_AS_ANALYTICS_REPORTS,
    payload:Params
  })
  
  export const getASAnalyticsReportsSuccess = AnalyticsDisplayReports => ({
    type: GET_AS_ANALYTICS_REPORTS_SUCCESS,
    payload: AnalyticsDisplayReports,
  })

  export const getASAnalyticsReportsFail = error => ({
    type: GET_AS_ANALYTICS_REPORTS_FAIL,
    payload: error,
  })

  export const getMISAnalyticsReports = Params => ({
    type: GET_MIS_ANALYTICS_REPORTS,
    payload:Params
  })
  
  export const getMISAnalyticsReportsSuccess = AnalyticsDisplayReports => ({
    type: GET_MIS_ANALYTICS_REPORTS_SUCCESS,
    payload: AnalyticsDisplayReports,
  })

  export const getMISAnalyticsReportsFail = error => ({
    type: GET_MIS_ANALYTICS_REPORTS_FAIL,
    payload: error,
  })

  export const getYMAnalyticsReports = Params => ({
    type: GET_YM_ANALYTICS_REPORTS,
    payload:Params
  })
  
  export const getYMAnalyticsReportsSuccess = AnalyticsDisplayReports => ({
    type: GET_YM_ANALYTICS_REPORTS_SUCCESS,
    payload: AnalyticsDisplayReports,
  })

  export const getYMAnalyticsReportsFail = error => ({
    type: GET_YM_ANALYTICS_REPORTS_FAIL,
    payload: error,
  })

  export const getPageUrlReports = Params => ({
    type: GET_PAGE_URL_REPORTS,
    payload:Params
  })
  
  export const getPageUrlReportsSuccess = PageUrlReports => ({
    type: GET_PAGE_URL_REPORTS_SUCCESS,
    payload: PageUrlReports,
  })

  export const getPageUrlReportsFail = error => ({
    type: GET_PAGE_URL_REPORTS_FAIL,
    payload: error,
  })

  export const getTeamPageUrlReports = Params => ({
    type: GET_TEAM_PAGE_URL_REPORTS,
    payload:Params
  })
  
  export const getTeamPageUrlReportsSuccess = PageUrlReports => ({
    type: GET_TEAM_PAGE_URL_REPORTS_SUCCESS,
    payload: PageUrlReports,
  })

  export const getTeamPageUrlReportsFail = error => ({
    type: GET_TEAM_PAGE_URL_REPORTS_FAIL,
    payload: error,
  })

  export const getTeamHistoryReports = Params => ({
    type: GET_TEAM_HISTORY_REPORTS,
    payload:Params
  })
  
  export const getTeamHistoryReportsSuccess = PageUrlReports => ({
    type: GET_TEAM_HISTORY_REPORTS_SUCCESS,
    payload: PageUrlReports,
  })

  export const getTeamHistoryReportsFail = error => ({
    type: GET_TEAM_HISTORY_REPORTS_FAIL,
    payload: error,
  })