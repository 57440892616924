import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ADUNITGROUPS, ADD_ADUNITGROUP, UPDATE_ADUNITGROUP, READ_ADUNITGROUP, STATUS_ADUNITGROUP, ARCHIVE_ADUNITGROUP } from "./actionTypes"

import {
  loadActivitiesAdUnitGroup,
  noDataAdUnitGroup,
  getAdUnitGroupsSuccess,
  getAdUnitGroupsFail,
  addAdUnitGroupSuccess,
  addAdUnitGroupFail,
  updateAdUnitGroupSuccess,
  updateAdUnitGroupFail,
  readAdUnitGroupSuccess,
  statusAdUnitGroupSuccess,
  archiveAdUnitGroupSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getAdUnitGroup, getAdUnitGroupsByPublisher, createAdUnitGroup, updateAdUnitGroup, readAdUnitGroup, statusChangeAdUnitGroup, archiveAdUnitGroup } from "../../../helpers/Backend_helper"

function* fetchAdUnitGroups({ payload: adGroup }) {
  try {
    yield put(loadActivitiesAdUnitGroup(true))
    var getAdGroupList
    if (adGroup && adGroup.publisherID !== '') {
      getAdGroupList = yield call(getAdUnitGroupsByPublisher, adGroup)
      if (getAdGroupList.statusCode === 200) {
        getAdGroupList.response.adUnitGroups.map((item, index) => {
          item.id = index + 1
        })
        if (getAdGroupList.response.adUnitGroups.length === 0) {
          yield put(noDataAdUnitGroup(true))
        }
        yield put(getAdUnitGroupsSuccess(getAdGroupList))
      }
    } else if (adGroup && adGroup.publisherID === '') {
      delete adGroup.publisherID
      getAdGroupList = yield call(getAdUnitGroup, adGroup)
      if (getAdGroupList.statusCode === 200) {
      getAdGroupList.response.adUnitGroups.map((item, index) => {
        item.id = index + 1
      })
      if (getAdGroupList.response.adUnitGroups.length === 0) {
        yield put(noDataAdUnitGroup(true))
      }
      yield put(getAdUnitGroupsSuccess(getAdGroupList))
    }
    } else {
      getAdGroupList = []
      yield put(getAdUnitGroupsSuccess(getAdGroupList))
    }
  } catch (error) {
    yield put(getAdUnitGroupsFail(error))
  }
}

function* onReadAdUnitGroup({ payload: adGroup }) {
  try {
    const response = yield call(readAdUnitGroup, adGroup)
    yield put(readAdUnitGroupSuccess(response))
  } catch (error) {
    yield put(readAdUnitGroupSuccess(error))
  }
}

function* onAddAdUnitGroup({ payload: adGroup }) {
  try {
    const response = yield call(createAdUnitGroup, adGroup)
    if (response.statusCode === 200) {
      yield put(addAdUnitGroupSuccess(response))
    } else {
      yield put(addAdUnitGroupFail(response))
    }
  } catch (error) {
    yield put(addAdUnitGroupFail(error))
  }
}

function* onUpdateAdUnitGroup({ payload: adGroup }) {
  try {
    const response = yield call(updateAdUnitGroup, adGroup)
    if (response.statusCode === 200) {
      yield put(updateAdUnitGroupSuccess(response))
    } else {
      yield put(updateAdUnitGroupFail(response))
    }
  } catch (error) {
    yield put(updateAdUnitGroupFail(error))
  }
}

function* onStatusAdUnitGroup({ payload: adGroup }) {
  try {
    const response = yield call(statusChangeAdUnitGroup, adGroup)
    if (response.statusCode === 200) {
    yield put(statusAdUnitGroupSuccess(response))
    }
  } catch (error) {
    yield put(statusAdUnitGroupSuccess(error))
  }
}

function* onArchiveAdUnitGroup({ payload: adGroup }) {
  try {
    const response = yield call(archiveAdUnitGroup, adGroup)
    if (response.statusCode === 200) {
    yield put(archiveAdUnitGroupSuccess(response))
    }
  } catch (error) {
    yield put(archiveAdUnitGroupSuccess(error))
  }
}

function* adUnitGroupsSaga() {
  yield takeEvery(GET_ADUNITGROUPS, fetchAdUnitGroups)
  yield takeEvery(ADD_ADUNITGROUP, onAddAdUnitGroup)
  yield takeEvery(UPDATE_ADUNITGROUP, onUpdateAdUnitGroup)
  yield takeEvery(READ_ADUNITGROUP, onReadAdUnitGroup)
  yield takeEvery(STATUS_ADUNITGROUP, onStatusAdUnitGroup)
  yield takeEvery(ARCHIVE_ADUNITGROUP, onArchiveAdUnitGroup)
}

export default adUnitGroupsSaga;
