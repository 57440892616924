import {
    LOAD_SCHEDULE_REPORTS,
    NO_DATA_SCHEDULE_REPORTS,
    GET_SCHEDULE_REPORTS,
    GET_SCHEDULE_REPORTS_FAIL,
    GET_SCHEDULE_REPORTS_SUCCESS,
    ADD_SCHEDULE_REPORT,
    ADD_SCHEDULE_REPORT_SUCCESS,
    ADD_SCHEDULE_REPORT_ERROR,
    UPDATE_SCHEDULE_REPORT,
    UPDATE_SCHEDULE_REPORT_SUCCESS,
    UPDATE_SCHEDULE_REPORT_ERROR,
    READ_SCHEDULE_REPORT,
    READ_SCHEDULE_REPORT_SUCCESS,
    STATUS_SCHEDULE_REPORT,
    STATUS_SCHEDULE_REPORT_SUCCESS,
    ARCHIVE_SCHEDULE_REPORT ,
    ARCHIVE_SCHEDULE_REPORT_SUCCESS

  } from "./actionTypes"

  export const loadScheduleReports = Params => ({
    type: LOAD_SCHEDULE_REPORTS,
    payload:Params
  })

  export const noScheduleReports = Params => ({
    type: NO_DATA_SCHEDULE_REPORTS,
    payload:Params
  })

  export const getScheduleReports = Params => ({
    type: GET_SCHEDULE_REPORTS,
    payload:Params
  })
  
  export const getScheduleReportsSuccess = ScheduleReports => ({
    type: GET_SCHEDULE_REPORTS_SUCCESS,
    payload: ScheduleReports,
  })

  export const getScheduleReportsFail = error => ({
    type: GET_SCHEDULE_REPORTS_FAIL,
    payload: error,
  })

  export const addNewScheduleReport = ScheduleReport => ({
    type: ADD_SCHEDULE_REPORT,
    payload: ScheduleReport,
  })
  
  export const addScheduleReportSuccess = scheduleReport => ({
    type: ADD_SCHEDULE_REPORT_SUCCESS,
    payload: scheduleReport,
  })
  
  export const addScheduleReportFail = scheduleReport => ({
    type: ADD_SCHEDULE_REPORT_ERROR,
    payload: scheduleReport,
  })

  export const updateScheduleReport = scheduleReport => ({
    type: UPDATE_SCHEDULE_REPORT,
    payload: scheduleReport,
  })
  
  export const updateScheduleReportSuccess = scheduleReport => ({
    type: UPDATE_SCHEDULE_REPORT_SUCCESS,
    payload: scheduleReport,
  })
  
  export const updateScheduleReportFail = scheduleReport => ({
    type: UPDATE_SCHEDULE_REPORT_ERROR,
    payload: scheduleReport,
  })

  export const readScheduleReport = scheduleReport => ({
    type: READ_SCHEDULE_REPORT,
    payload: scheduleReport,
  })

  export const readScheduleReportSuccess = scheduleReport => ({
    type: READ_SCHEDULE_REPORT_SUCCESS,
    payload: scheduleReport,
  })

  export const statusScheduleReport = scheduleReport => ({
    type: STATUS_SCHEDULE_REPORT,
    payload: scheduleReport,
  })

  export const statusScheduleReportSuccess = scheduleReport => ({
    type: STATUS_SCHEDULE_REPORT_SUCCESS,
    payload: scheduleReport,
  })

  export const archiveScheduleReport = scheduleReport => ({
    type: ARCHIVE_SCHEDULE_REPORT,
    payload: scheduleReport,
  })

  export const archiveScheduleReportSuccess = scheduleReport => ({
    type: ARCHIVE_SCHEDULE_REPORT_SUCCESS,
    payload: scheduleReport,
  })