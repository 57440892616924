import {
    LOAD_ACTIVITIES,
    NO_DATA,
    GET_HEADERBIDS,
    GET_HEADERBIDS_FAIL,
    GET_HEADERBIDS_SUCCESS,
    ADD_HEADERBID,
    ADD_HEADERBID_SUCCESS,
    ADD_HEADERBID_ERROR,
    UPDATE_HEADERBID,
    UPDATE_HEADERBID_SUCCESS,
    UPDATE_HEADERBID_ERROR,
    UPDATE_ADUNITBID,
    UPDATE_ADUNITBID_SUCCESS,
    UPDATE_ADUNITBID_ERROR,
    READ_HEADERBID,
    READ_HEADERBID_SUCCESS,
    STATUS_HEADERBID,
    STATUS_HEADERBID_SUCCESS,
    ARCHIVE_HEADERBID ,
    ARCHIVE_HEADERBID_SUCCESS

  } from "./actionTypes"

  export const loadActivitiesHeaderBids = Params => ({
    type: LOAD_ACTIVITIES,
    payload:Params
  })

  export const noDataHeaderBids = Params => ({
    type: NO_DATA,
    payload:Params
  })

  export const getHeaderBids = Params => ({
    type: GET_HEADERBIDS,
    payload:Params
  })
  
  export const getHeaderBidsSuccess = HeaderBids => ({
    type: GET_HEADERBIDS_SUCCESS,
    payload: HeaderBids,
  })

  export const getHeaderBidsFail = error => ({
    type: GET_HEADERBIDS_FAIL,
    payload: error,
  })

  export const addNewHeaderBid = HeaderBid => ({
    type: ADD_HEADERBID,
    payload: HeaderBid,
  })
  
  export const addHeaderBidSuccess = headerBid => ({
    type: ADD_HEADERBID_SUCCESS,
    payload: headerBid,
  })
  
  export const addHeaderBidFail = headerBid => ({
    type: ADD_HEADERBID_ERROR,
    payload: headerBid,
  })

  export const updateHeaderBid = headerBid => ({
    type: UPDATE_HEADERBID,
    payload: headerBid,
  })
  
  export const updateHeaderBidSuccess = headerBid => ({
    type: UPDATE_HEADERBID_SUCCESS,
    payload: headerBid,
  })
  
  export const updateHeaderBidFail = headerBid => ({
    type: UPDATE_HEADERBID_ERROR,
    payload: headerBid,
  })

  export const updateAdUnitBid = headerBid => ({
    type: UPDATE_ADUNITBID,
    payload: headerBid,
  })
  
  export const updateAdUnitBidSuccess = headerBid => ({
    type: UPDATE_ADUNITBID_SUCCESS,
    payload: headerBid,
  })
  
  export const updateAdUnitBidFail = headerBid => ({
    type: UPDATE_ADUNITBID_ERROR,
    payload: headerBid,
  })

  export const readHeaderBid = headerBid => ({
    type: READ_HEADERBID,
    payload: headerBid,
  })

  export const readHeaderBidSuccess = headerBid => ({
    type: READ_HEADERBID_SUCCESS,
    payload: headerBid,
  })

  export const statusHeaderBid = headerBid => ({
    type: STATUS_HEADERBID,
    payload: headerBid,
  })

  export const statusHeaderBidSuccess = headerBid => ({
    type: STATUS_HEADERBID_SUCCESS,
    payload: headerBid,
  })

  export const archiveHeaderBid = headerBid => ({
    type: ARCHIVE_HEADERBID,
    payload: headerBid,
  })

  export const archiveHeaderBidSuccess = headerBid => ({
    type: ARCHIVE_HEADERBID_SUCCESS,
    payload: headerBid,
  })