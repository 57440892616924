/* PROXY_ADUNIT */
export const GET_ADMIN_ANALYTICS_REPORTS = "GET_ADMIN_ANALYTICS_REPORTS"
export const GET_ADMIN_ANALYTICS_REPORTS_SUCCESS = "GET_ADMIN_ANALYTICS_REPORTS_SUCCESS"
export const GET_ADMIN_ANALYTICS_REPORTS_FAIL = "GET_ADMIN_ANALYTICS_REPORTS_FAIL"

export const GET_AS_ANALYTICS_REPORTS = "GET_AS_ANALYTICS_REPORTS"
export const GET_AS_ANALYTICS_REPORTS_SUCCESS = "GET_AS_ANALYTICS_REPORTS_SUCCESS"
export const GET_AS_ANALYTICS_REPORTS_FAIL = "GET_AS_ANALYTICS_REPORTS_FAIL"

export const GET_YM_ANALYTICS_REPORTS = "GET_YM_ANALYTICS_REPORTS"
export const GET_YM_ANALYTICS_REPORTS_SUCCESS = "GET_YM_ANALYTICS_REPORTS_SUCCESS"
export const GET_YM_ANALYTICS_REPORTS_FAIL = "GET_YM_ANALYTICS_REPORTS_FAIL"

export const GET_MIS_ANALYTICS_REPORTS = "GET_MIS_ANALYTICS_REPORTS"
export const GET_MIS_ANALYTICS_REPORTS_SUCCESS = "GET_MIS_ANALYTICS_REPORTS_SUCCESS"
export const GET_MIS_ANALYTICS_REPORTS_FAIL = "GET_MIS_ANALYTICS_REPORTS_FAIL"

export const GET_PAGE_URL_REPORTS = "GET_PAGE_URL_REPORTS"
export const GET_PAGE_URL_REPORTS_SUCCESS = "GET_PAGE_URL_REPORTS_SUCCESS"
export const GET_PAGE_URL_REPORTS_FAIL = "GET_PAGE_URL_REPORTS_FAIL"

export const GET_TEAM_PAGE_URL_REPORTS = "GET_TEAM_PAGE_URL_REPORTS"
export const GET_TEAM_PAGE_URL_REPORTS_SUCCESS = "GET_TEAM_PAGE_URL_REPORTS_SUCCESS"
export const GET_TEAM_PAGE_URL_REPORTS_FAIL = "GET_TEAM_PAGE_URL_REPORTS_FAIL"

export const GET_TEAM_HISTORY_REPORTS = "GET_TEAM_HISTORY_REPORTS"
export const GET_TEAM_HISTORY_REPORTS_SUCCESS = "GET_TEAM_HISTORY_REPORTS_SUCCESS"
export const GET_TEAM_HISTORY_REPORTS_FAIL = "GET_TEAM_HISTORY_REPORTS_FAIL"

export const LOAD_REPORTS = "LOAD_REPORTS"