import {
  LOAD_ACTIVITIES,
  NO_DATA,
    GET_PLAYERSETTINGS,
    GET_PLAYERSETTINGS_FAIL,
    GET_PLAYERSETTINGS_SUCCESS,
    ADD_PLAYERSETTING,
    ADD_PLAYERSETTING_SUCCESS,
    ADD_PLAYERSETTING_ERROR,
    UPDATE_PLAYERSETTING,
    UPDATE_PLAYERSETTING_SUCCESS,
    UPDATE_PLAYERSETTING_ERROR,
    READ_PLAYERSETTING,
    READ_PLAYERSETTING_SUCCESS,
    STATUS_PLAYERSETTING,
    STATUS_PLAYERSETTING_SUCCESS,
    ARCHIVE_PLAYERSETTING ,
    ARCHIVE_PLAYERSETTING_SUCCESS

  } from "./actionTypes"

  export const loadActivitiesPlayerSetting = Params => ({
    type: LOAD_ACTIVITIES,
    payload:Params
  })

  export const noDataPlayerSetting = Params => ({
    type: NO_DATA,
    payload:Params
  })

  export const getPlayerSetting = Params => ({
    type: GET_PLAYERSETTINGS,
    payload:Params
  })
  
  export const getPlayerSettingSuccess = PlayerSetting => ({
    type: GET_PLAYERSETTINGS_SUCCESS,
    payload: PlayerSetting,
  })

  export const getPlayerSettingFail = error => ({
    type: GET_PLAYERSETTINGS_FAIL,
    payload: error,
  })

  export const addNewPlayerSetting = PlayerSetting => ({
    type: ADD_PLAYERSETTING,
    payload: PlayerSetting,
  })
  
  export const addPlayerSettingSuccess = playerSetting => ({
    type: ADD_PLAYERSETTING_SUCCESS,
    payload: playerSetting,
  })
  
  export const addPlayerSettingFail = playerSetting => ({
    type: ADD_PLAYERSETTING_ERROR,
    payload: playerSetting,
  })

  export const updatePlayerSetting = playerSetting => ({
    type: UPDATE_PLAYERSETTING,
    payload: playerSetting,
  })
  
  export const updatePlayerSettingSuccess = playerSetting => ({
    type: UPDATE_PLAYERSETTING_SUCCESS,
    payload: playerSetting,
  })
  
  export const updatePlayerSettingFail = playerSetting => ({
    type: UPDATE_PLAYERSETTING_ERROR,
    payload: playerSetting,
  })

  export const readPlayerSetting = playerSetting => ({
    type: READ_PLAYERSETTING,
    payload: playerSetting,
  })

  export const readPlayerSettingSuccess = playerSetting => ({
    type: READ_PLAYERSETTING_SUCCESS,
    payload: playerSetting,
  })

  export const statusPlayerSetting = playerSetting => ({
    type: STATUS_PLAYERSETTING,
    payload: playerSetting,
  })

  export const statusPlayerSettingSuccess = playerSetting => ({
    type: STATUS_PLAYERSETTING_SUCCESS,
    payload: playerSetting,
  })

  export const archivePlayerSetting = playerSetting => ({
    type: ARCHIVE_PLAYERSETTING,
    payload: playerSetting,
  })

  export const archivePlayerSettingSuccess = playerSetting => ({
    type: ARCHIVE_PLAYERSETTING_SUCCESS,
    payload: playerSetting,
  })