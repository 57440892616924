/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import axios from "axios";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Spinner,
    Label, Form,FormFeedback
} from "reactstrap"

import jsPDF from "jspdf";
import "jspdf-autotable";

import DateRangePickers from 'helpers/DateRangePicker'
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';

import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import moment from "moment";

import excelFile from 'assets/files/AdsTxtFile.xlsx'
import PDFFile from 'assets/files/AdsTxtEntries.pdf'

// actions
import {
    addNewScheduleReport as onADDScheduleReport,
    updateScheduleReport as onUpdateScheduleReport,
    readScheduleReport as onReadScheduleReport,
    addScheduleReportSuccess,
    addScheduleReportFail,
    updateScheduleReportSuccess,
    updateScheduleReportFail,
} from "store/PublisherConsole/ScheduleReport/actions";

import Toaster from "pages/Toaster/Toaster";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";


var fromDate = moment().subtract(6, 'days')
var toDate = moment()
var loader = false

const NewSchedule_Report = (props) => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const dispatch = useDispatch();
    const [reportOpen, setReportOpen] = useState(false);
    const [btnprimary1, setBtnprimary1] = useState(false)
    const [requestTotal, setRequestTotal] = useState(0)
    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const [selectAll, setSelectAll] = useState(false)
    const [selectMetrice, setSelectMetrice] = useState(false)
    const [range, setRange] = useState('Day')
    const [selectAllMTC, setSelectAllMTC] = useState(false)
    const [scheduleReport, setScheduleReport] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [loader, setLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [clicksPresent, setClicksPresent] = useState(false)

    const [selectAllDM, setSelectAllDM] = useState({
        Domain: false,
        AdUnitName: false,
        Domain: false,
        AdUnitSize: false,
        InventoryType: false,
        DealType: false,
        Device: false,
    });

    const [filters, setFilters] = useState({
        Domain: '',
        AdUnitName: '',
        Device: '',
        AdUnitSize: '',
        DealType: '',
        InventoryType: '',
    });

    const [filterData, setFilterData] = useState({
        Domain: [],
        AdUnitName: [],
        AdUnitSize: [],
        InventoryType: [],
        DealType: [],
        Device: [],
    })

    const [selectedMTC, setSelectedMTC] = useState({
        TotalCodeServed: false,
        PaidImpressions: false,
        Revenue: false,
        RPM: false,
        FillRate: false,
    });


    const [finalData, setFinalData] = useState({
        dateRange: 'yesterday',
        range: 'Cumulative',
        frequency: 'daily',
        scheduleName: '',
        emails: [],

    });

    const [emailData, setEmailData] = useState('')

    console.log(props)

    useEffect(() => {
        if (props.selectedScheduleReport !== '') {
            setIsEdit(true)
            setFinalData(props.selectedScheduleReport)
            setFinalData(finalData => ({ ...finalData, range: props.selectedScheduleReport.settings.range }));
            setEmailData(props.selectedScheduleReport.emails)
            setSelectAllDM(props.selectedScheduleReport.settings.dimensions)
            setSelectedMTC(props.selectedScheduleReport.settings.metrices)
            setScheduleReport(props.selectedScheduleReport)
            setFilters(props.selectedScheduleReport.settings.filters)
            let DimArr = Object.values(selectAllDM)
            console.log(DimArr)
            if (!DimArr.includes(false)) {
                setSelectAll(true)
            } else {
                setSelectAll(false)
            }
        }
    }, []);

    const { errorMsg, successMsg } = useSelector(state => ({
        errorMsg: state.scheduleReports.errorMsg,
        successMsg: state.scheduleReports.successMsg,
    }));

    const { revenueDisplayReports } = useSelector(state => ({
        revenueDisplayReports: state.scheduleReports.revenueDisplayRpt,
    }));

    useEffect(() => {
        if (successMsg !== null) {
            if (successMsg.success) {
                dispatch(addScheduleReportSuccess(""));
                dispatch(addScheduleReportFail(""));
                dispatch(updateScheduleReportSuccess(""));
                dispatch(updateScheduleReportFail(""));
                setLoader(false)
                if (props.selectedScheduleReport !== '') {
                    props.closeCanvas('Update')
                } else {
                    props.closeCanvas('Create')
                }
            }
        }
    }, [successMsg]);

    useEffect(() => {
        console.log(errorMsg)
        if (errorMsg !== null && errorMsg !== '') {
            setLoader(false)
        }
    }, [errorMsg]);

    const changeFilter = (e) => {
        const { name, value } = e.target;
        setFilters(filters => ({ ...filters, [name]: value }));
        if(name === 'Domain'){
            setFilters(filters => ({ ...filters,  AdUnitName: '',
            AdUnitSize: '',
            InventoryType: '',
            DealType: '',
            Device: '',
         }));
        }else if(name === 'AdUnitName'){
            setFilters(filters => ({ ...filters,
            AdUnitSize: '',
            InventoryType: '',
            DealType: '',
            Device: '',
         }));
        }else if(name === 'Device'){
            setFilters(filters => ({ ...filters,
            AdUnitSize: '',
            DealType: '',
            InventoryType: '',
         }));
        }
        else if(name === 'AdUnitSize'){
            setFilters(filters => ({ ...filters,
            InventoryType: '',
            DealType: '',
         }));
        }else if(name === 'DealType'){
            setFilters(filters => ({ ...filters,
                InventoryType: '',
         }));
        }
        changeData(e)
    }

    const handleData = (e) => {
        const { name, value } = e.target;
        setFinalData(finalData => ({ ...finalData, [name]: value }));
    }

    const handleChangeEmail = (e) => {
        const { name, value } = e.target;
        setEmailData(value)
        let urls = value.split(/[\s,]+/);
        setFinalData(finalData => ({ ...finalData, [name]: urls }));
    }

    const changeMTCSelect = (e) => {
        setSelectAllMTC(e.target.checked)
        setSelectedMTC({
            TotalCodeServed: e.target.checked,
            PaidImpressions: e.target.checked,
            Revenue: e.target.checked,
            RPM: e.target.checked,
            FillRate: e.target.checked,
        })
        clicksPresent ? setSelectedMTC(selectedMTC => ({ ...selectedMTC, Click: e.target.checked })) : ''
    }

    const changeSelectionMTC = (e) => {
        const { name, value } = e.target;
        setSelectedMTC(selectedMTC => ({ ...selectedMTC, [name]: e.target.checked }));
        let newMTC = { ...selectedMTC, [name]: e.target.checked }
        let MtcArr = Object.values(newMTC)
        if (!MtcArr.includes(false)) {
            setSelectAllMTC(true)
        } else {
            setSelectAllMTC(false)
        }
    }

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            Partner: userInfo.name,
            "partnerName": userInfo.name,
            "partnerID": userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
    }

    useEffect(() => {


        axios.post("/v2.0/partnerRe/Query/Revenue/DisplayMatrixFilter", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data) {
                    if (res.data.dimensions.indexOf('Click') >= 0) {
                        setClicksPresent(true)
                    }
                }
            })
            .catch((err) => {
                console.log("e", err)
            });

  
        axios.post("/v2.0/partnerRe/Query/Revenue/DisplayGetFilter", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data) {
                    setFilterData(res.data)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
        fromDate = moment().subtract(6, 'days')
        toDate = moment()
        console.log("start: ", fromDate);
        console.log("end: ", toDate);
    }, []);

    const changeData = (data) => {
        const { name, value } = data.target;
        body = {
            ...body,
            "Domain": name === 'Domain' ? value : '',
            AdUnitName:name === 'AdUnitName' ? value : '',
            AdUnitSize:name === 'AdUnitSize' ? value : '',
            InventoryType:name === 'InventoryType' ? value : '',
            DealType:name === 'DealType' ? value : '',
            Device:name === 'Device' ? value : '',
        }
        axios.post("/v2.0/partnerRe/Query/Revenue/DisplayGetSpecificFilter", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data) {
                    setFilterData({ ...filterData, ...res.data })
                    console.log(filterData)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    };

    const changeDMSelect = (e) => {
        setSelectAll(e.target.checked)
        setSelectAllDM({
            Domain: e.target.checked,
            AdUnitName: e.target.checked,
            AdUnitSize: e.target.checked,
            InventoryType: e.target.checked,
            DealType: e.target.checked,
            Device: e.target.checked,
        })
    }

    const changeSelection = (e) => {
        const { name, value } = e.target;
        setSelectAllDM(selectAllDM => ({ ...selectAllDM, [name]: e.target.checked }));
        let newDim = { ...selectAllDM, [name]: e.target.checked }
        let DimArr = Object.values(newDim)
        console.log(DimArr)
        if (!DimArr.includes(false)) {
            setSelectAll(true)
        } else {
            setSelectAll(false)
        }
    }

    const changeRange = (e) => {
        setRange(e.target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        let DimArr = Object.values(selectAllDM)
        let MtcArr = Object.values(selectedMTC)
        if (!DimArr.includes(true) || !MtcArr.includes(true)) {
            toastr.warning('Please select atleast one Dimensions and Metrices')
        }
        else {
            if(finalData.scheduleName && finalData.emails.length > 0 )
            setLoader(true)
            let query = {
                "settings": {
                    "dimensions": selectAllDM,
                    "filters": filters,
                    "metrices": selectedMTC,
                    "range": range,
                },
                "scheduleName": finalData.scheduleName,
                "partnerName": userInfo.name,
                "dateRange": finalData.dateRange,
                "emails": finalData.emails,
                "frequency": finalData.frequency,
            }
            if(props.selectedScheduleReport !== ''){
                let final = {...finalData,...query}
                console.log(final)
                dispatch(onUpdateScheduleReport({ ...final }));
            }else{
                console.log(query)
                dispatch(onADDScheduleReport({ ...query }));
            }
   
        }
    }

    return (
        <div>
            {loader &&
                <Spinner className="ms-2 loader" color="primary" />
            }
            <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
                <Card>
                    <CardBody>
                        <Form onSubmit={handleSubmit}>
                            <Row style={{ padding: '1% 3%', }}>
                                <Col xs={4} >
                                    <div className="mb-3">
                                        <h5>SELECT DATE:</h5> <br />
                                        <Input
                                            name="dateRange"
                                            type="select"
                                            className="form-select"
                                            onChange={handleData}
                                            value={finalData.dateRange}
                                        >
                                            <option value='' disabled>Select Date Range</option>
                                            <option value='yesterday'>Yesterday</option>
                                            <option value='last7Days'>Last 7 Days</option>
                                            <option value='last30Days'>Last 30 Days</option>
                                            <option value='thisMonth'>Current Month</option>
                                            <option value='lastMonth'>Last Month</option>
                                        </Input>
                                    </div>
                                </Col>
                                <Col xs={4} style={{ padding: '0% 3%', }}>
                                    <div className="mb-3">
                                        <h5> GROUP BY:</h5><br />
                                        <Input
                                            name="range"
                                            type="select"
                                            className="form-select"
                                            onChange={handleData}
                                            value={finalData.range}
                                        >
                                            <option value='' disabled>Select</option>
                                            <option value='Month'>Month</option>
                                            <option value='Day'>Day</option>
                                            <option value='Cumulative'>Cumulative</option>
                                        </Input>
                                    </div>
                                </Col>
                                <Col xs={4} style={{ padding: '0% 3%', }}>
                                    <div className="mb-3">
                                        <h5> FREQUENCY:</h5><br />
                                        <Input
                                            name="frequency"
                                            type="select"
                                            className="form-select"
                                            onChange={handleData}
                                            value={finalData.frequency}
                                        >
                                            <option value='daily' >Daily</option>
                                            <option value='weekly'>Weekly</option>
                                            <option value='monthly'>Monthly</option>
                                        </Input>
                                    </div>
                                </Col>
                                <Col xs={4} >
                                    <div className="mb-3">
                                        <h5> SCHEDULE REPORT NAME:</h5><br />
                                        <Input
                                            name="scheduleName"
                                            type="text"
                                            className={ (submitted && !finalData.scheduleName ? ' is-invalid' : '') }
                                            onChange={handleData}
                                            value={finalData.scheduleName}
                                        >
                                        </Input>
                                    {submitted && !finalData.scheduleName ? (
                                    <FormFeedback type="invalid">{'Schedule Report Name is required'}</FormFeedback>
                                    ) : null}
                                    </div>
                                </Col>
                                <Col xs={8} style={{ padding: '0% 3%', }}>
                                    <div className="mb-3">
                                        <h5> EMAIL:</h5><br />
                                        <Input
                                            name="emails"
                                            type="text"
                                            className={ (submitted && finalData.emails.length === 0 ? ' is-invalid' : '') }
                                            value={emailData}
                                            onChange={handleChangeEmail}
                                            placeholder="Enter multiple email addresses seperated by commas"
                                        >
                                        </Input>
                                        {submitted && finalData.emails.length === 0 ? (
                                    <FormFeedback type="invalid">{'Email is required'}</FormFeedback>
                                    ) : null}
                                    </div>
                                </Col>
                                <Col xs={4} >

                                    <br />
                                    <h5>FILTERS</h5>
                                    <div className="mb-3">
                                        <Label className="form-label">Site Name</Label>
                                        <Input
                                            name="Domain"
                                            type="select"
                                            className="form-select"
                                            value={filters.Domain}
                                            onChange={changeFilter}
                                        >
                                            <option value=''>Select...</option>
                                            {filterData.Domain.map((elem) => (
                                                <option key={elem} value={elem}>{elem}</option>
                                            ))}
                                        </Input>
                                    </div>


                                    <div className="mb-3">
                                        <Label className="form-label">Ad Unit</Label>
                                        <Input
                                            name="AdUnitName"
                                            type="select"
                                            className="form-select"
                                            value={filters.AdUnitName}
                                            onChange={changeFilter}
                                        >
                                            <option value=''>Select...</option>
                                            {filterData.AdUnitName.map((elem) => (
                                                <option key={elem} value={elem}>{elem}</option>
                                            ))}
                                        </Input>
                                    </div>
                                    <div className="mb-3">
                                        <Label className="form-label">Device Type</Label>
                                        <Input
                                            name="Device"
                                            type="select"
                                            className="form-select"
                                            value={filters.Device}
                                            onChange={changeFilter}
                                        >
                                            <option value=''>Select...</option>
                                            {filterData.Device.map((elem) => (
                                                <option key={elem} value={elem}>{elem}</option>
                                            ))}
                                        </Input>
                                    </div>
                                    <div className="mb-3">
                                        <Label>AdUnit Size</Label>
                                        <Input
                                            name="AdUnitSize"
                                            type="select"
                                            className="form-select"
                                            value={filters.AdUnitSize}
                                            onChange={changeFilter}
                                        >
                                            <option value=''>Select...</option>
                                            {filterData.AdUnitSize.map((elem) => (
                                                <option key={elem} value={elem}>{elem}</option>
                                            ))}
                                        </Input>
                                    </div>
                                    <div className="mb-3">
                                        <Label className="form-label">Deal Type</Label>
                                        <Input
                                            name="DealType"
                                            type="select"
                                            className="form-select"
                                            value={filters.DealType}
                                            onChange={changeFilter}
                                        >
                                            <option value=''>Select...</option>
                                            {filterData.DealType.map((elem) => (
                                                <option key={elem} value={elem}>{elem}</option>
                                            ))}
                                        </Input>
                                    </div>
                                    <div className="mb-3">
                                        <Label className="form-label">Inventory Type </Label>
                                        <Input
                                            name="InventoryType"
                                            type="select"
                                            className="form-select"
                                            value={filters.InventoryType}
                                            onChange={changeFilter}
                                        >
                                            <option value=''>Select...</option>
                                            {filterData.InventoryType.map((elem) => (
                                                <option key={elem} value={elem}>{elem}</option>
                                            ))}
                                        </Input>
                                    </div>


                                </Col>
                                <Col xs={4} style={{ padding: '0% 3%', }}>


                                    <br />
                                    <h5 className="mb-3 ">DIMENSIONS</h5>
                                    <div className="form-check mt-2 mb-4">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={changeDMSelect}
                                        />
                                        <label
                                            className="form-check-label"
                                        >
                                            Select All
                                        </label>
                                    </div>
                                    <div className="form-check mt-2 mb-4">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={selectAllDM.Domain}
                                            name="Domain"
                                            onChange={changeSelection}
                                        />
                                        <label
                                            className="form-check-label"
                                        >
                                            Site Name
                                        </label>
                                    </div>


                                    <div className="form-check mt-2 mb-4">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={selectAllDM.AdUnitName}
                                            name="AdUnitName"
                                            onChange={changeSelection}
                                        />
                                        <label
                                            className="form-check-label"
                                        >
                                            Ad Unit
                                        </label>
                                    </div>
                                    <div className="form-check mt-2 mb-4">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={selectAllDM.Device}
                                            name="Device"
                                            onChange={changeSelection}
                                        />
                                        <label
                                            className="form-check-label"
                                        >
                                            Device Type
                                        </label>
                                    </div>
                                    <div className="form-check mt-2 mb-4">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={selectAllDM.AdUnitSize}
                                            name="AdUnitSize"
                                            onChange={changeSelection}
                                        />
                                        <label
                                            className="form-check-label"
                                        >
                                            AdUnit Size
                                        </label>
                                    </div>
                                    <div className="form-check mt-2 mb-4">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={selectAllDM.DealType}
                                            name="DealType"
                                            onChange={changeSelection}
                                        />
                                        <label
                                            className="form-check-label"
                                        >
                                            Deal Type
                                        </label>
                                    </div>
                                    <div className="form-check mt-2 mb-4">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={selectAllDM.InventoryType}
                                            name="InventoryType"
                                            onChange={changeSelection}
                                        />
                                        <label
                                            className="form-check-label"
                                        >
                                            Inventory Type
                                        </label>
                                    </div>


                                </Col>
                                <Col xs={4} style={{ padding: '0 3%', }}>

                                    <br />
                                    <h5 className="mb-3">METRICES</h5>
                                    <div className="form-check mt-2 mb-4">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={selectAllMTC}
                                            onChange={changeMTCSelect}
                                        />
                                        <label
                                            className="form-check-label"
                                        >
                                            Select All
                                        </label>
                                    </div>
                                    <div className="form-check mt-2 mb-4">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={selectedMTC.TotalCodeServed}
                                            name="TotalCodeServed"
                                            onChange={changeSelectionMTC}
                                        />
                                        <label
                                            className="form-check-label"
                                        >
                                            Total Code Served Count
                                        </label>
                                    </div>
                                    <div className="form-check mt-2 mb-4">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={selectedMTC.PaidImpressions}
                                            name="PaidImpressions"
                                            onChange={changeSelectionMTC}
                                        />
                                        <label
                                            className="form-check-label"
                                        >
                                            Paid Impressions
                                        </label>
                                    </div>

                                    {clicksPresent &&
                                        <div className="form-check mt-2 mb-4">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={selectedMTC.Click}
                                                name="Click"
                                                onChange={changeSelectionMTC}
                                                id="clicks"
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="clicks"
                                            >
                                                Clicks
                                            </label>
                                        </div>
                                    }

                                    <div className="form-check mt-2 mb-4">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={selectedMTC.Revenue}
                                            name="Revenue"
                                            onChange={changeSelectionMTC}
                                        />
                                        <label
                                            className="form-check-label"
                                        >
                                            Publisher Revenue
                                        </label>
                                    </div>
                                    <div className="form-check mt-2 mb-4">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={selectedMTC.RPM}
                                            name="RPM"
                                            onChange={changeSelectionMTC}
                                        />
                                        <label
                                            className="form-check-label"
                                        >
                                            RPM
                                        </label>
                                    </div>

                                    <div className="form-check mt-2 mb-4">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={selectedMTC.FillRate}
                                            name="FillRate"
                                            onChange={changeSelectionMTC}
                                        />
                                        <label
                                            className="form-check-label"
                                        >
                                            Fill Rate
                                        </label>
                                    </div>


                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="text-start">
                                        &nbsp;&nbsp;
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary"
                                            // eslint-disable-next-line react/prop-types
                                            onClick={props.closeCanvas}
                                        >
                                            Back
                                        </button>
                                        &nbsp;&nbsp;
                                        {!isEdit &&
                                            <button
                                                type="submit"
                                                className="btn btn-primary save-user"
                                            >
                                                Save
                                            </button>
                                        }
                                        &nbsp;&nbsp;

                                        {isEdit && !props.selectedArchived &&
                                            <button
                                                type="submit"
                                                className="btn btn-primary save-user"
                                            >
                                                Update
                                            </button>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default NewSchedule_Report