/* HOUSE_ADUNIT */
export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES"
export const NO_DATA_HOUSE_ADUNITS = "NO_DATA_HOUSE_ADUNITS"
export const GET_HOUSE_ADUNITS = "GET_HOUSE_ADUNITS"
export const GET_HOUSE_ADUNITS_SUCCESS = "GET_HOUSE_ADUNITS_SUCCESS"
export const GET_HOUSE_ADUNITS_FAIL = "GET_HOUSE_ADUNITS_FAIL"

export const ADD_HOUSE_ADUNIT = "ADD_HOUSE_ADUNIT"
export const ADD_HOUSE_ADUNIT_SUCCESS = "ADD_HOUSE_ADUNIT_SUCCESS"
export const ADD_HOUSE_ADUNIT_ERROR = "ADD_HOUSE_ADUNIT_ERROR"

export const UPDATE_HOUSE_ADUNIT = "UPDATE_HOUSE_ADUNIT"
export const UPDATE_HOUSE_ADUNIT_SUCCESS = "UPDATE_HOUSE_ADUNIT_SUCCESS"
export const UPDATE_HOUSE_ADUNIT_ERROR = "UPDATE_HOUSE_ADUNIT_ERROR"

export const READ_HOUSE_ADUNIT = "READ_HOUSE_ADUNIT"
export const READ_HOUSE_ADUNIT_SUCCESS = "READ_HOUSE_ADUNIT_SUCCESS"

export const STATUS_HOUSE_ADUNIT = "STATUS_HOUSE_ADUNIT"
export const STATUS_HOUSE_ADUNIT_SUCCESS = "STATUS_HOUSE_ADUNIT_SUCCESS"

export const ARCHIVE_HOUSE_ADUNIT = "ARCHIVE_HOUSE_ADUNIT"
export const ARCHIVE_HOUSE_ADUNIT_SUCCESS = "ARCHIVE_HOUSE_ADUNIT_SUCCESS"