// VIDEO CONTENT
export const LOAD_MARKET_CONTENTS = "LOAD_MARKET_CONTENTS"
export const GET_MARKET_CONTENTS = "GET_MARKET_CONTENTS"
export const GET_MARKET_CONTENTS_SUCCESS = "GET_MARKET_CONTENTS_SUCCESS"
export const GET_MARKET_CONTENTS_FAIL = "GET_MARKET_CONTENTS_FAIL"

export const ADD_MARKET_CONTENTS = "ADD_MARKET_CONTENTS"
export const ADD_MARKET_CONTENTS_SUCCESS = "ADD_MARKET_CONTENTS_SUCCESS"
export const ADD_MARKET_CONTENTS_FAIL = "ADD_MARKET_CONTENTS_FAIL"

export const DELETE_MARKET_CONTENT = "DELETE_MARKET_CONTENT"
export const DELETE_MARKET_CONTENT_SUCCESS = "DELETE_MARKET_CONTENT_SUCCESS"
export const DELETE_MARKET_CONTENT_ERROR = "DELETE_MARKET_CONTENT_ERROR"

 