/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux";
import {
    Card, CardBody, Col, Form, Input, Label, Row, Spinner, Button, CardTitle, Offcanvas,
    OffcanvasHeader,
    OffcanvasBody, FormFeedback, Progress
} from "reactstrap"

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import {
    createPlaylist as onCreatePlaylist,
    createPlaylistSuccess,
    createPlaylistFail
} from 'store/Media/Playlist/actions'

import {
    createPubPlaylist as onCreatePubPlaylist,
    createPubPlaylistSuccess, 
    createPubPlaylistFail
} from 'store/Media_Publisher/Playlist/actions'

var videoContentList = []


const Cdn_Playlists = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [loader, setLoader] = useState(false);
    const [videosList, setVideosList] = useState([])
    const [playlistData, setPlaylistData] = useState({
        playListOtherName: "",
        playListOtherVideoID: [],
        playListOtherSettings: '',
        filer: "",
        isCDNLink: true,
        playListOtherThumbNail: {
            type: "default",
            url: ''
        },
    }) 

    const [submitted, setSubmitted] = useState(false);
    const [infochecked, setInfoChecked] = useState(false)
    const [warningChecked, setWarningChecked] = useState(false)

    const dispatch = useDispatch()


    const escFunction = useCallback((event) => {
        console.log("called esc function", event.key)
        if (event.key === "Escape") {
            videoSelected = []
            selectedVideo = ''
            selectedIndex = []
            selectedItem = []
            setIsVideoRight(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    const removeDirectContent = (i) => e => {
        videoContentList.splice(i, 1)
        setVideosList([...videoContentList])
    }


    useEffect(() => {

        if (videoContentList.length === 0) {
            videoContentList.push({
                id: videoContentList.length + 1,
                videoName: '',
                videoURL: '',
                clickThroughURL: '',
                thumbnailURL: '',
            })
        }

        setVideosList([...videoContentList])

    }, [])

    const createContentUI = () => {
        return videosList.map((item, i) =>
            <div key={i}>
                <div className="contentSettings">
                    <h5 className='mt-3 mb-4'>Content {i + 1}</h5>
                    <div className="row mt-1">
                        <div className="col-10">
                            <div className="mb-2">
                                <Label className="form-label">Video Name</Label>
                                <Input
                                    name="videoContentName"
                                    type="text"
                                    onChange={handleDirectChange(i)}
                                    value={item.videoName}
                                    className={'form-control'}
                                />
                            </div>
                            <br />
                            <div className="mb-2">
                                <Label className="form-label">Video URL</Label>
                                <Input
                                    name="videoURL"
                                    type="text"
                                    onChange={handleDirectChange(i)}
                                    value={item.videoURL}
                                    className={'form-control'}
                                />
                            </div>

                            <div className="mb-2">
                                <Label className="form-label">Thumbnail URL</Label>
                                <Input
                                    name="thumbnailURL"
                                    type="text"
                                    onChange={handleDirectChange(i)}
                                    value={item.thumbnailURL}
                                    className={'form-control'}
                                />
                            </div>


                            <br />
                            <div className="mb-2">
                                <Label className="form-label">Click Through URL</Label>
                                <Input
                                    name="clickThroughURL"
                                    type="text"
                                    onChange={handleDirectChange(i)}
                                    value={item.clickThroughURL}
                                    className={'form-control'}
                                />
                            </div>
                            <br />
                        </div>
                        <div className="col-2 p-0">
                            <button type='button' className='btn btn-danger  ' style={{ position: 'absolute', background: '#dc3545', right: '0' }} onClick={removeDirectContent(i)} disabled={videoContentList.length === 1}
                            > <span className="text " style={{ cursor: 'pointer' }} >
                                    <i
                                        className="mdi mdi-trash-can font-size-18"
                                        id="edittooltip"
                                    ></i>
                                </span></button>
                        </div>
                    </div>
                </div>
            </div>

        )

    }

    const addDirectVideoContent = () => {

        videoContentList.push({
            id: videoContentList.length + 1,
            videoName: '',
            videoURL: '',
            clickThroughURL: '',
            thumbnailURL: '',

        })
        setVideosList([...videoContentList])
        console.log(videosList)

    }

    const handleDirectChange = (index, videoData) => e => {
        const { name, value } = e.target;
        console.log(name, value)
        if (name === 'videoContentName') {
            videoContentList[index] = { ...videoContentList[index], videoName: value };
            console.log(videoContentList)
            setVideosList([...videoContentList])
        }
        else if (name === 'videoURL') {
            videoContentList[index] = { ...videoContentList[index], videoURL: value };
            console.log(videoContentList)
            setVideosList([...videoContentList])
        }
        else if (name === 'clickThroughURL') {
            videoContentList[index] = { ...videoContentList[index], clickThroughURL: value };
            console.log(videoContentList)
            setVideosList([...videoContentList])
        }
        else if (name === 'thumbnailURL') {
            videoContentList[index] = { ...videoContentList[index], thumbnailURL: value };
            console.log(videoContentList)
            setVideosList([...videoContentList])
        }


        console.log(videoData)
        console.log(videosList)
        // if (name === 'videoUrl') {
        //     getDuration(value, index)
        // }
    }

    const _handleSubmit = (e) => {
        e.preventDefault()
        // setPlaylistData(prevState => ({
        //     ...prevState,
        //     playListJSON: videosList
        // }))
        setSubmitted(true);

        if (playlistData.playListOtherName &&
            playlistData.playListOtherSettings) {
            setLoader(true)
            dispatch(onCreatePubPlaylist({ ...playlistData, playListOtherJSON: videoContentList }))
            console.log({...playlistData, playListJSON: videoContentList})
        }

    }

    useEffect(() => {
        console.log("Updated Playlist Data:", playlistData);
        // Your other side effects or logic after state update
    }, [playlistData]);

    const { errorMsg, successMsg } = useSelector(state => ({

        errorMsg: state.pubPlaylistsReducer.errorMsg,
        successMsg: state.pubPlaylistsReducer.successMsg,
    }));

    console.log(errorMsg)
    console.log(successMsg)



    useEffect(() => {
        if (successMsg !== null) {
            if (successMsg.success) {
                dispatch(createPubPlaylistSuccess(""));
                dispatch(createPubPlaylistFail(""));
                setLoader(false)
                props.closeCanvas()
            }
        }
    }, [successMsg]);

    useEffect(() => {
        console.log(errorMsg)
        if (errorMsg !== null && errorMsg !== '') {
            setLoader(false)
            dispatch(createPubPlaylistFail(""));
            toastr.error(errorMsg)
        }
    }, [errorMsg]);


    return (
        <>
            <Col md="8">
                {loader &&
                    <Spinner className="ms-2 loader" color="primary" />
                }
                <Form onSubmit={_handleSubmit}>
                    {/* <Form action="https://support-ticket.bidsxchange.com/create" encType="multipart/form-data" method="post"> */}
                    <div style={{ padding: '1% 2%', height: '80vh', overflow: 'auto' }}>
                        <Row>
                            <Col lg="12">
                                <div>
                                    <div className="mb-3 m-3">
                                        <Label className="form-label">Name</Label>
                                        <Input
                                            type="text"
                                            name='playListOtherName'
                                            placeholder="Playlist name"
                                            className={`form-control col-8 ${(submitted && !playlistData.playListOtherName ? ' is-invalid' : '')}`}
                                            onChange={(e) => setPlaylistData(prevState => ({
                                                ...prevState,
                                                playListOtherName: e.target.value
                                            }))}
                                        />
                                        {submitted && !playlistData.playListOtherName ? (
                                            <FormFeedback type="invalid">{'Please Enter Playlist Name'}</FormFeedback>
                                        ) : null}
                                    </div>

                                    <div className="mb-3 m-3">
                                        <Label className="form-label">Playlist Settings</Label>
                                        <Input
                                            name='playListOtherSettings'
                                            placeholder="Player settings"
                                            type="select"
                                            // className={`form-control col-8 select2-selection ${(submitted && playlistData.playListOtherSettings !== [] ? ' is-invalid' : '')}`}
                                            className={`form-control col-8 select2-selection`}

                                            onChange={(e) => setPlaylistData(prevState => ({
                                                ...prevState,
                                                playListOtherSettings: e.target.value
                                            }))}

                                        >
                                            <option value=''>Select</option>
                                            <option value='ASC'>A to Z</option>
                                            <option value='DEC'>Z to A</option>
                                            <option value="shuffle">shuffle</option>
                                        </Input>

                                    </div>

                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <div className='mt-3'>
                                <div className="player-content">
                                    <div className="container-fluid">
                                        {createContentUI()}
                                        <div className='col-8'>
                                            <div className='d-flex'>
                                                <button type='button' className='mt-4 mb-4 btn btn-primary ml-auto' style={{ marginRight: '1rem  2.5rem 1rem auto' }} onClick={addDirectVideoContent} >Add New Content &nbsp;&nbsp; <i className="fa fa-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>
                    <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
                        <div className="">
                            <Button type="submit"
                                color="primary"
                                disabled={videoContentList.length === 0}
                            >
                                Create Playlist

                            </Button>
                        </div>
                    </Row>
                </Form>
            </Col>

        </>
    )
}

Cdn_Playlists.propTypes = {}

export default Cdn_Playlists