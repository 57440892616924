// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollbar-adtxt {
    scrollbar-color: #757575 #f2f2f2; /* Change the colors as per your requirement */
    scrollbar-width: thin;
    overflow: scroll;
  }
  
  .scrollbar-adtxt::-webkit-scrollbar {
    width: 8px;
    height: 8px; /* Set the same height as width to create a square scrollbar */
  }
  
  .scrollbar-adtxt::-webkit-scrollbar-thumb {
    background-color: #838383; /* Change the color as per your requirement */
  }
  
  .scrollbar-adtxt::-webkit-scrollbar-track {
    background-color: #f2f2f2; /* Change the color as per your requirement */
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Admin/Ads_Txt_Management/Ads_Txt.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC,EAAE,8CAA8C;IAChF,qBAAqB;IACrB,gBAAgB;EAClB;;EAEA;IACE,UAAU;IACV,WAAW,EAAE,8DAA8D;EAC7E;;EAEA;IACE,yBAAyB,EAAE,6CAA6C;EAC1E;;EAEA;IACE,yBAAyB,EAAE,6CAA6C;EAC1E","sourcesContent":[".scrollbar-adtxt {\n    scrollbar-color: #757575 #f2f2f2; /* Change the colors as per your requirement */\n    scrollbar-width: thin;\n    overflow: scroll;\n  }\n  \n  .scrollbar-adtxt::-webkit-scrollbar {\n    width: 8px;\n    height: 8px; /* Set the same height as width to create a square scrollbar */\n  }\n  \n  .scrollbar-adtxt::-webkit-scrollbar-thumb {\n    background-color: #838383; /* Change the color as per your requirement */\n  }\n  \n  .scrollbar-adtxt::-webkit-scrollbar-track {\n    background-color: #f2f2f2; /* Change the color as per your requirement */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
