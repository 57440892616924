import axios from "axios";

//apply base url for axios
const API_URL = "";

const axiosApi = axios.create({
  baseURL: API_URL,
});





// axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);


export async function toolsPost(url, data, config = {}) {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  console.log(userInfo)
  var Headers = {
    "Content-Type": "application/json",
    'Accept': "application/json",
    "Authorization": `Bearer ${userInfo.token}`
  }
  let body = {
      ...data, userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name: userInfo.name,
      partnerName: userInfo.name
    
  }
  console.log(body, Headers)
  return axiosApi
    .post(url, body, { headers: Headers })
    .then(response => response.data);
}


