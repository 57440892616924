/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Row, Col, Input, Label, Form, FormFeedback, DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown, Spinner, Collapse, NavItem,
  NavLink,
  TabContent,
  TabPane, Card, CardBody
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import queryString from 'query-string';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

import classnames from "classnames"

// actions
import Toaster from "pages/Toaster/Toaster";
//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'

// actions
import {
  addNewHighImpactSetting as onADDHighImpactSetting,
  updateHighImpactSetting as onUpdateHighImpactSetting,
  readHighImpactSetting as onReadHighImpactSetting,
  addHighImpactSettingSuccess,
  addHighImpactSettingFail,
  updateHighImpactSettingSuccess,
  updateHighImpactSettingFail,
} from "store/Supply/HighImpactSettings/actions";

import {
  getAdUnitHIS as onGetAdUnits,
} from "store/Supply/AdUnit/actions";

import {
  getCommonDevices as onGetAllContextual,
} from "store/CommonEvents/actions";

import GeneralSettings from "./GeneralSettings";
import GeoHighImpact from "./GeoHighImpact";
import TemplateSettings from "./TemplateSettings";
import ToasterWarning from "pages/Toaster/ToasterWarning";

var selectedAdUnits = []
var selectedlength = 0

const CreateHI = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loader, setLoader] = useState(false);
  const parsed = queryString.parse(window.location.search);
  console.log(props)

  const [templateSelect, setTemplateSelect] = useState('');
  const [templatesData, setTemplatesData] = useState([]);
  const [adUnitData, setAdUnitData] = useState([])

  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])

  const [selectedTemplateName, setSelectedTemplateName] = useState(props.selectedHighImpact !== '' ? props.selectedHighImpact.highImpactSettingTemplateName : '');

  const [searchValue, setSearchValue] = useState("");
  const [hoverStateID, setHoverStateID] = useState('');
  const [selectedAdUnitsData, setSelectedAdUnitsData] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [sizeData, setSizeData] = useState([
  { value:"1x1"  ,label:"1x1"},
  { value:"300x250" ,label:"300x250"},
  { value:"300x600" ,label:"300x600"},
  { value:"320x442" ,label:"320x442"},
  { value: '360x480', label: '360x480'},
  {value:'640x480',label:'640x480'},
  { value:"728x90"  ,label:"728x90"},
  { value:"120x600" ,label:"120x600"},
  { value:"160x600" ,label:"160x600"},
  { value:"970x250" ,label:"970x250"},
  { value:"970x90"  ,label:"970x90"},
  { value:"320x50"  ,label:"320x50"},
  { value:"320x100" ,label:"320x100"},
  { value:"320x480" ,label:"320x480"},
  { value:"300x50"  ,label:"300x50"},
  { value:"300x100" ,label:"300x100"},
  { value:"468x60"  ,label:"468x60"},
  { value:"468x90"  ,label:"468x90"},
  { value:"336x280" ,label:"336x280"},
  { value:"800x485" ,label:"800x485"},])

  const [finalData, setFinalData] = useState({
    highImpactSettingName: '',
    highImpactSettingTemplateName: props.selectedHighImpact !== '' ? props.selectedHighImpact.highImpactSettingTemplateName : '',
    highImpactSettingData: {},
    devicesIn: [],
    devicesEx: [],
    countriesIn: [],
    countriesEx: [],
    statesIn: [],
    statesEx: [],
    citiesIn: [],
    citiesEx: [],
    urlIn: [],
    urlEx: [],
    adUnitID: [],
    highImpactSettingSize: ''
  });
  const [contextualData, setContextualData] = useState({})
  const { contextuals } = useSelector(state => ({
    contextuals: state.commonEvents.contextualAllData,
  }));

  useEffect(() => {
    if (contextuals !== null) {
      dispatch(onGetAllContextual());
    }
  }, []);

  useEffect(() => {
    if (contextuals && JSON.stringify(contextuals) !== "{}") {
      setContextualData(contextuals)
    }
  }, [contextuals]);

  const filterNames = ({ label }) => {
    return label.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 3) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const handleEditState = (id) => (e) => {
    setHoverStateID(id)
  }


  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 1000,
    extendedTimeOut: 1000
  };

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  let body = {
    "url": '',
    "requestBody": {
      userID: userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name: userInfo.name,
    }
  }

  // useEffect(() => {
  //   dispatch(onGetAdUnits(null));
  // }, [])

  const { adUnits } = useSelector(state => ({
    adUnits: state.adUnits.adUnitListbyHIS
  }));

  console.log(adUnits)

  // useEffect(() => {
  //   if (adUnits !== null) {
  //     dispatch(onGetAdUnits({ type: 'highImpact', userRole: userInfo.userRole, userID: userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner }));
  //   }
  // }, []);

  useEffect(() => {
    if (adUnits && adUnits.length > 0) {
      // setAdUnitData(objectAdUnitModal(adUnits))
      console.log(adUnits)
    }
  }, [adUnits]);

  useEffect(() => {
    selectedAdUnits = []
    selectedlength = 0
    let bodyAdUnit = {
        userID: userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,
    }
    axios.post((userInfo.userRole === 'SupplyPartner') ? "/v2.0/supplyPartner/HighImpactSettings/read/AdunitHighImpact/SupplyPartner" : "/v2.0/supply/HighImpactSettings/read/AdunitHighImpact", bodyAdUnit,
      {
        headers: headers
      })
      .then((res) => {
        console.log(res)
        if (res.data) {
          let trees = []
          if (res.data.response.length > 0) {
            res.data.response.map((item, i) => {
              trees.push(createAdUnitsTree(item.aUnitName, item.adUnitID, item.child))
            })
          }
          console.log(trees)

          console.log(trees, trees.length, adUnitData)
          if (trees.length > 0) {
            if (props.selectedHighImpact !== '') {
              if (props.selectedHighImpact.adUnitIDName.length > 0) {
                let adUnitIds = []
                props.selectedHighImpact.adUnitIDName.map((elem, index) => {
                  selectedAdUnits.push({ ID: elem.ID, isChild: elem.IsChildAdunit })
                  adUnitIds.push({ ID: elem.ID, isChild: elem.IsChildAdunit })
                  sbc(elem.ID, true, elem.parentID ? elem.parentID : '', trees)
                })
                setFinalData(finalData => ({ ...finalData, adUnitID: adUnitIds }));
                setFinalData(finalData => ({ ...finalData, oldAdUnitID: adUnitIds }));
                setAdUnitsSelected(props.selectedHighImpact.adUnitIDName.length > 1 ? props.selectedHighImpact.adUnitIDName.length + ' Ad Units are Selected' : '1 Ad Unit is Selected')
              } else {
                setFinalData(finalData => ({ ...finalData, oldAdUnitID: [] }));
              }
            } else if (JSON.stringify(parsed) !== '{}') {
              if (parsed.status === 'HighImpactCreateByAdUnit') {
                selectedAdUnits.push({ ID: Number(parsed.adUnitID), isChild: false })
                setFinalData(finalData => ({ ...finalData, adUnitID: [{ ID: Number(parsed.adUnitID), isChild: false }] }));
                sbc(Number(parsed.adUnitID), true, '', trees)
                setAdUnitsSelected('1 Ad Unit is Selected')
              }
            }
            else {
              setAdUnitData(trees)
            }
          }
        }
      })
      .catch((err) => {
        console.log("e", err)
      });

  }, []);

  const createAdUnitsTree = (groupName, id, options) => {
    return {
      label: groupName,
      value: id,
      checked: false,
      ParentID: '',
      children: objectModalAdUnits(options, id)
    };
  };

  const objectModalAdUnits = (response, parentId) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.childAdUnitID
      obj.label = item.childAdUnitName
      obj.ParentID = parentId
      obj.checked = false
      dataList.push(obj)
    })
    return dataList
  }

  useEffect(() => {
    console.log(props.selectedHighImpact)
    if (props.selectedHighImpact !== '' && props.selectedHighImpactCreateStatus !== 'Duplicate') {
      setFinalData(finalData => ({ ...finalData, ...props.selectedHighImpact }));
      // setSelectedTemplateName(props.selectedHighImpact.highImpactSettingTemplateName)
      setTemplateSelect({ value: props.selectedHighImpact.highImpactSettingTemplateName, label: props.selectedHighImpact.highImpactSettingTemplateName })
      setIsEdit(true)
      setSelectedSize({value:props.selectedHighImpact.highImpactSettingSize,label:props.selectedHighImpact.highImpactSettingSize});
    }
    if (props.selectedHighImpact !== '' && props.selectedHighImpactCreateStatus === 'Duplicate') {
      delete props.selectedHighImpact.highImpactSettingCreateTime
      delete props.selectedHighImpact.highImpactSettingID
      delete props.selectedHighImpact.highImpactSettingModifyTime
      delete props.selectedHighImpact.highImpactSettingStatus
      delete props.selectedHighImpact.isHighImpactSettingArchived
      delete props.selectedHighImpact.newStatus
      setFinalData(finalData => ({ ...finalData, ...props.selectedHighImpact }));
      setSelectedTemplateName(props.selectedHighImpact.highImpactSettingTemplateName)
      setTemplateSelect({ value: props.selectedHighImpact.highImpactSettingTemplateName, label: props.selectedHighImpact.highImpactSettingTemplateName })
    }
    // if (props.selectedHighImpact === '') {
    //   setTemplateSelect({ value: '[BSRV-HI]--Slider', label: '[BSRV-HI]--Slider' })
    //   setFinalData(finalData => ({ ...finalData, highImpactSettingTemplateName: '[BSRV-HI]--Slider' }))
    // }
  }, [])

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.highImpactSettings.errorMsg,
    successMsg: state.highImpactSettings.successMsg,
  }));

  console.log(errorMsg, successMsg)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.statusCode === 200) {
        dispatch(addHighImpactSettingSuccess(""));
        dispatch(addHighImpactSettingFail(""));
        dispatch(updateHighImpactSettingSuccess(""));
        dispatch(updateHighImpactSettingFail(""));
        setLoader(false)
        if (props.selectedHighImpact !== '' && props.selectedHighImpactCreateStatus !== 'Duplicate') {
          props.closeCanvas('Update')
        } else {
          props.closeCanvas('Create')
        }

      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if (errorMsg.status && errorMsg.status === 500) {
      } else {
        dispatch(addHighImpactSettingSuccess(""));
        dispatch(addHighImpactSettingFail(""));
        dispatch(updateHighImpactSettingSuccess(""));
        dispatch(updateHighImpactSettingFail(""));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addHighImpactSettingSuccess(""));
    dispatch(addHighImpactSettingFail(""));
    dispatch(updateHighImpactSettingSuccess(""));
    dispatch(updateHighImpactSettingFail(""));
    props.closeCanvas('')
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFinalData(finalData => ({ ...finalData, [name]: value }));
  }

  useEffect(() => {
    let templateBody  = {
      userID: userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name: userInfo.name,

      creativeTemplateType: "highImpact",
      creativeTemplateFor: "HighImapct"
    }

    axios.post((userInfo.userRole === 'SupplyPartner') ? "/v2.0/operations/CreativeTemplates/list" : "/v2.0/operations/CreativeTemplates/list", templateBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is ==>", res)
        if (res.data) {
          console.log(res.data.response)
          res.data.response.map((item, index) => {
            item.id = index + 1
          })
          setTemplatesData(objectModals(res.data.response))
          console.log(templatesData)
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }, [])

  const objectModals = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.creativeTemplateName
      obj.label = item.creativeTemplateName
      dataList.push(obj)
    })
    return dataList
  }

  const templateChange = (event) => {
    console.log(event)
    if (event.value) {
      setTemplateSelect(event);
      // handleData(event.value)
      setSelectedTemplateName(event.value)
      setFinalData(finalData => ({ ...finalData, highImpactSettingTemplateName: event.value }))
    }
  };

  function allowAlphaNumericSpace(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault(); }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    console.log(finalData)
    if (finalData.highImpactSettingName && finalData.highImpactSettingSize && finalData.adUnitID && finalData.highImpactSettingTemplateName) {
      setLoader(true)
      if (props.selectedHighImpact !== '' && props.selectedHighImpactCreateStatus !== 'Duplicate') {
        dispatch(onUpdateHighImpactSetting({ ...finalData, highImpactSettingID: props.selectedHighImpact.highImpactSettingID, highImpactSettingName: finalData.highImpactSettingName.trim(),userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner }));
      } else {
        dispatch(onADDHighImpactSetting({ ...finalData, highImpactSettingName: finalData.highImpactSettingName.trim(),userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner }));
      }
    }
  }

  const getHighImpactDataData = async (selectedHighImpactDataData) => {
    console.log(selectedHighImpactDataData)
    // if(selectedHighImpactDataData.highImpactSettingData){
    //   if(isEmpty(selectedHighImpactDataData.highImpactSettingData)){
    //     setFinalData(finalData => ({ ...finalData, highImpactSettingData:{} }))
    //   }else{
    //     setFinalData(finalData => ({ ...finalData, ...selectedHighImpactDataData }))
    //     console.log(finalData)
    //   }
    // }else{
    //    setFinalData(finalData => ({ ...finalData,...selectedHighImpactDataData}));
    // }
    setFinalData(finalData => ({ ...finalData, ...selectedHighImpactDataData }));

  }

  const getLoaderStatus = async (loaderState) => {
    console.log(loaderState)
    setLoader(loaderState)
  }

  const [adUnitsSelected, setAdUnitsSelected] = useState('Please Select Ad Units')

  const onChangeAdUnit = (currentNode, selectedNodes) => {
    console.log(currentNode, selectedNodes)
    let index = selectedAdUnits.findIndex(x => x.ID === currentNode.value)
    if (currentNode.checked) {
      if (index === -1) {
        selectedAdUnits.push({
          ID: currentNode.value,
          isChild: currentNode._parent ? true : false
        })
      }
    } else {
      selectedAdUnits.splice(index, 1)
    }
    let array = [...adUnitData]
    selectedlength = selectedAdUnits.length
    let AdUnitSelecteds = selectedlength > 1 ? selectedlength + ' Ad Units are Selected' : selectedlength === 1 ? '1 Ad Unit is Selected' : 'Please Select Ad Units'
    setAdUnitsSelected(AdUnitSelecteds)
    sbc(currentNode.value, currentNode.checked, currentNode.ParentID, array)
  }

  const sbc = (selectedId, checked, parentId, array) => {
    console.log(selectedId, checked, parentId, array)
    if (array.length > 0) {
      if (parentId) {
        let parentIndex = array.findIndex(x => x.value === parentId)
        let childIndex = array[parentIndex].children.findIndex(x => x.value === selectedId)
        array[parentIndex].children[childIndex].checked = checked
        let selectedElementParent = array[parentIndex]
        array.splice(parentIndex, 1)
        array = [selectedElementParent, ...array]
      } else {
        let index = array.findIndex(x => x.value === selectedId)
        console.log(array,index)
        array[index].checked = checked
        let selectedElement = array[index]
        array.splice(index, 1)
        array = [selectedElement, ...array]
      }
      setAdUnitData(array)
      setFinalData(finalData => ({ ...finalData, adUnitID: selectedAdUnits }));
    }
  }

  function handleSelectSize(selectSize) {
    setFinalData(finalData => ({ ...finalData, highImpactSettingSize: selectSize.value }));
    setSelectedSize(selectSize);
  }

  return (
    <React.Fragment>
      {loader &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>

        <Form onSubmit={handleSubmit}>
          <div className="formBodyScroll" style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
            <Col xs={6}>
              <div className="mb-3 HIS">
                <Label>Ad Unit   <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <DropdownTreeSelect className={(submitted && finalData.adUnitID.length === 0 ? ' is-invalid' : '')} data={adUnitData} texts={{ placeholder: adUnitsSelected }} clearSearchOnChange={true} inlineSearchInput={true} keepOpenOnSelect={true} onChange={onChangeAdUnit} mode={'hierarchical'} />
                {/* <Row className="multi-Adunit">
                  <Col md={6}>
                    <Input
                      placeholder="Search..."
                      type="text"
                      name="search"
                      autoComplete="off"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                     
                    />
                     
                    <ul style={{ pointerEvents: (props.selectedArchived) ? 'none' : 'unset' }}>
                      {adUnitData.filter(filterNames).map((adUnit, index) => {
                        return <li key={adUnit.value} onMouseOver={handleEditState(adUnit.value)} onClick={selectAdUnit(adUnit, index)} onMouseLeave={handleEditState('')} className="d-flex" style={{ backgroundColor: (hoverStateID === adUnit.value) ? '#556ee61f' : '', cursor: 'pointer' }}>
                          <Col md={9}>
                            {adUnit.label}
                          </Col>
                          <Col md={3}>
                            <div className=" text-sm-end" >
                              {hoverStateID && hoverStateID === adUnit.value &&
                                <span style={{ cursor: 'pointer' }}>   <i
                                  className="bx bx-comment-check font-size-24"
                                  id="edittooltip"
                                ></i></span>
                              }
                            </div>
                          </Col>
                        </li>
                      })}
                      {adUnits.length === 0 &&
                        <h5 className="text-center mt-4">No Data</h5>}
                    </ul>
               
                  </Col>
                  <Col md={6}>
                    <ul style={{ height: '235px', minHeight: '235px', pointerEvents: (props.selectedArchived) ? 'none' : 'unset' }}  className={(submitted && finalData.adUnitID.length === 0 ? ' is-invalid' : '')}>
                      {selectedAdUnitsData.map((adUnit, index) => {
                        return <li key={adUnit.value} className="d-flex">
                          <Col md={9}>
                            {adUnit.label}
                          </Col>
                          <Col md={3}>
                            <div className=" text-sm-end">
                              <button type="button" className="btn-close text-sm-end" onClick={deSelectAdUnit(adUnit, index)} aria-label="Close"></button>
                            </div>
                          </Col>
                        </li>
                      })}
                    </ul>
                    {submitted && finalData.adUnitID.length === 0 ? (
                  <FormFeedback type="invalid">{'AdUnits are required'}</FormFeedback>
                ) : null}
                  </Col>
                </Row> */}
                {submitted && finalData.adUnitID.length === 0 ? (
                  <FormFeedback type="invalid">{'Ad Unit is required'}</FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Name  <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Input
                  name="highImpactSettingName"
                  type="text"
                  maxLength={110}
                  value={finalData.highImpactSettingName}
                  onChange={handleChange}
                  onKeyPress={allowAlphaNumericSpace}
                  className={(submitted && !finalData.highImpactSettingName ? ' is-invalid' : '')}
                />
                {submitted && !finalData.highImpactSettingName ? (
                  <FormFeedback type="invalid">{'High Impact Settings Name is required'}</FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Display Ad Formats  <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Select
                  value={templateSelect}
                  onChange={s => {
                    templateChange(s)
                  }}
                  options={templatesData}
                  classNamePrefix="select2-selection"
                  className={(submitted && !finalData.highImpactSettingTemplateName ? ' is-invalid' : '')}
                />
                {submitted && !finalData.highImpactSettingTemplateName ? (
                  <FormFeedback type="invalid">{'Please Select Display Ad Formats'}</FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Size </Label>
                {/* <Input
                  name="highImpactSettingSize"
                  type="select"
                  value={finalData.highImpactSettingSize}
                  onChange={handleChange}
                  className={"form-select " + (submitted && !finalData.highImpactSettingSize ? ' is-invalid' : '')}
                >
                  <option value=''>Select...</option>
                  <option value="1x1"> 1x1</option>
                  <option value="300x250"> 300x250</option>
                  <option value="300x600"> 300x600</option>
                  <option value="320x442"> 320x442 </option>
                  <option value="728x90">  728x90</option>
                  <option value="120x600"> 120x600 </option>
                  <option value="160x600"> 160x600 </option>
                  <option value="970x250"> 970x250 </option>
                  <option value="970x90">  970x90</option>
                  <option value="320x50">  320x50</option>
                  <option value="320x100"> 320x100 </option>
                  <option value="320x480"> 320x480 </option>
                  <option value="300x50">  300x50 </option>
                  <option value="300x100"> 300x100 </option>
                  <option value="468x60">  468x60 </option>
                  <option value="468x90">  468x90</option>
                  <option value="336x280"> 336x280 </option>
                  <option value="800x485"> 800x485 </option>
                  <option value="320x442"> 320x442 </option>

                </Input> */}
                      <Select
                  value={selectedSize}
                  onChange={s => {
                    handleSelectSize(s)
                  }}
                  options={sizeData}
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived}
                />
              </div>
                {/* {submitted && !finalData.highImpactSettingSize ? (
                  <FormFeedback type="invalid">{'Size is required'}</FormFeedback>
                ) : null} */}
            </Col>

            <Row >
              <Col lg="12" className="p-0">
                <Card>
                  <CardBody className="p-0">
                    <h4 className="card-title m-2">Settings</h4>
                    <div className=" wizard clearfix vertical">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({ current: activeTab === 1 })}
                              onClick={() => {
                                setactiveTab(1)
                              }}
                            // disabled={!(passedSteps || []).includes(1)}
                            >
                              <span className="number">1.</span>Ad Formats Settings
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 2 })}
                              onClick={() => {
                                setactiveTab(2)
                              }}
                            // disabled={!(passedSteps || []).includes(2)}
                            >
                              <span className="number ">02</span> General Settings
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 3 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 3 })}
                              onClick={() => {
                                setactiveTab(3)
                              }}
                            // disabled={!(passedSteps || []).includes(4)}
                            >
                              <span className="number">03</span> Geo Targeting
                            </NavLink>
                          </NavItem>

                        </ul>
                      </div>
                      <div className="content clearfix ">
                        <TabContent activeTab={activeTab} className="body">
                          <TabPane tabId={1}>
                            <TemplateSettings selectedItems={getHighImpactDataData} loaderState={getLoaderStatus} selectedHighImpact={props.selectedHighImpact} selectedTemplate={selectedTemplateName} submitted={submitted} tabId={activeTab} />
                          </TabPane>
                          <TabPane tabId={2}>
                            <div>
                              <GeneralSettings selectedItems={getHighImpactDataData} contextualData={contextualData} selectedHighImpact={props.selectedHighImpact} submitted={submitted} tabId={activeTab} />
                            </div>
                          </TabPane>
                          <TabPane tabId={3}>
                            <div>
                              <GeoHighImpact selectedItems={getHighImpactDataData} selectedHighImpact={props.selectedHighImpact} submitted={submitted} tabId={activeTab} />
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                toggleTab(activeTab - 1)
                              }}
                            >
                              Previous
                            </Link>
                          </li>
                          <li
                            className={activeTab === 3 ? "next disabled" : "next"}
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                toggleTab(activeTab + 1)
                              }}
                            >
                              Next
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '18px', margin: 0 }}>
            <Col>
              <div className="text-start">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
                &nbsp;&nbsp;
                {isEdit && !props.selectedArchived && 
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                    disabled={loader}
                  >
                    Update
                  </button>
                }
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                    disabled={loader}
                  >
                    Save
                  </button>
                }
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
          <Toaster status="error" msg={errorMsg.message} />
        }
      </div>
      {errorMsg !== null && errorMsg !== '' && errorMsg.status && errorMsg.status === 500 &&
        <div className="p-0 justify-content-center d-flex">
          <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')} />
        </div>
      }
    </React.Fragment>
  );
};

CreateHI.propTypes = {}

export default CreateHI