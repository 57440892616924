/* CREATIVE */
export const LOAD_CREATIVES = "LOAD_CREATIVES"
export const NO_DATA = "NO_DATA"
export const GET_CREATIVES = "GET_CREATIVES"
export const GET_CREATIVES_SUCCESS = "GET_CREATIVES_SUCCESS"
export const GET_CREATIVES_FAIL = "GET_CREATIVES_FAIL"

export const ADD_CREATIVE = "ADD_CREATIVE"
export const ADD_CREATIVE_SUCCESS = "ADD_CREATIVE_SUCCESS"
export const ADD_CREATIVE_ERROR = "ADD_CREATIVE_ERROR"

export const UPDATE_CREATIVE = "UPDATE_CREATIVE"
export const UPDATE_CREATIVE_SUCCESS = "UPDATE_CREATIVE_SUCCESS"
export const UPDATE_CREATIVE_ERROR = "UPDATE_CREATIVE_ERROR"

export const ADD_VIDEO_CREATIVE = "ADD_VIDEO_CREATIVE"
export const ADD_VIDEO_CREATIVE_SUCCESS = "ADD_VIDEO_CREATIVE_SUCCESS"
export const ADD_VIDEO_CREATIVE_ERROR = "ADD_VIDEO_CREATIVE_ERROR"

export const UPDATE_VIDEO_CREATIVE = "UPDATE_VIDEO_CREATIVE"
export const UPDATE_VIDEO_CREATIVE_SUCCESS = "UPDATE_VIDEO_CREATIVE_SUCCESS"
export const UPDATE_VIDEO_CREATIVE_ERROR = "UPDATE_VIDEO_CREATIVE_ERROR"

export const READ_CREATIVE = "READ_CREATIVE"
export const READ_CREATIVE_SUCCESS = "READ_CREATIVE_SUCCESS"

export const READ_VIDEO_CREATIVE = "READ_VIDEO_CREATIVE"
export const READ_VIDEO_CREATIVE_SUCCESS = "READ_VIDEO_CREATIVE_SUCCESS"

export const STATUS_CREATIVE = "STATUS_CREATIVE"
export const STATUS_CREATIVE_SUCCESS = "STATUS_CREATIVE_SUCCESS"

export const ARCHIVE_CREATIVE = "ARCHIVE_CREATIVE"
export const ARCHIVE_CREATIVE_SUCCESS = "ARCHIVE_CREATIVE_SUCCESS"