import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_MARKET_CONTENTS, ADD_MARKET_CONTENT, DELETE_MARKET_CONTENT, ADD_MARKET_CONTENTS } from "./actionTypes"

import {
    loadMarketContents,
    getMarketContentsSuccess,
    getMarketContentsFail,
    deleteMarketContentSuccess,
    deleteMarketContentFail,
    addMarketContentsSuccess,
    addMarketContentsFail
} from "./actions"

//Include Both Helper File with needed methods
import { getMarketContents, deleteMarketContent, addMarketContent } from "../../../helpers/Backend_helper"


function* fetchMarketContents({ payload: vcs }) {
    try {
        yield put(loadMarketContents(true))
        var getContentsList
        const userInfo = JSON.parse(localStorage.getItem('authUser'));
        if (vcs) {

            getContentsList = yield call(getMarketContents, vcs)

            console.log("saga console response==============", getContentsList)

            //filtering data 

            const filteredData = getContentsList.response.filter(item => !item.isDeleted);

            console.log("filtered data -------->", filteredData)

            filteredData.map((item, index) => {
                item.id = index + 1
                item.checked = false
            })
            console.log("modified Data ------------>", filteredData)
            // yield put(getMarketContentsSuccess(modifiedData))

            getContentsList.response.map((item, index) => {
                item.id = index + 1
                item.checked = false
            })
            yield put(getMarketContentsSuccess(getContentsList.response))

        } else {
            getContentsList = []
            yield put(getMarketContentsSuccess(getContentsList))
        }

    } catch (error) {
        yield put(getMarketContentsFail(error))
    }
}

function* onAddMarketContent({ payload: vid }) {
    console.log("called onAddMarketContent data")
    try {
        const response = yield call(addMarketContent, vid)
        console.log(response)
        if (response.success) {
            yield put(addMarketContentsSuccess(response))
        } else {
            yield put(addMarketContentsFail(response.data[0]))
        }
    } catch (error) {
        yield put(addMarketContentsFail(error))
    }
}



function* onDeleteMarketContent({ payload: vid }) {
    console.log("called ondeleteMarketContent data")
    try {
        const response = yield call(deleteMarketContent, vid)
        console.log(response.data[0])
        if (response.data[0].success) {
            console.log("call success")
            yield put(deleteMarketContentSuccess(response))
        } else {
            console.log(response.data[0].videoContentLinkPlayList)
            yield put(deleteMarketContentFail(response.data[0]))
        }
    } catch (error) {
        console.log(error)

        yield put(deleteMarketContentFail(error))
    }
}

function* MarketContentsSaga() {
    yield takeEvery(GET_MARKET_CONTENTS, fetchMarketContents)
    yield takeEvery(ADD_MARKET_CONTENTS, onAddMarketContent)
    yield takeEvery(DELETE_MARKET_CONTENT, onDeleteMarketContent)
}

export default MarketContentsSaga;
