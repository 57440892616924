import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_TCS,ADD_TC , UPDATE_TC,READ_TC,STATUS_TC,ARCHIVE_TC} from "./actionTypes"

import {
  loadActivitiesTC,
  getTcsSuccess,
  getTcsFail,
  addTcSuccess,
  addTcFail,
  updateTcSuccess,
  updateTcFail,
  readTcSuccess,
  statusTcSuccess,
  archiveTcSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getTcs,postTc,updateTc,readTc,statusChangeTc,archiveTc } from "../../../helpers/Backend_helper"

function* fetchTcs({ payload: tc }) {
  try {
    yield put(loadActivitiesTC(true))
    var getTcList
    if(tc && tc.type){
      getTcList = yield call(getTcsType,tc)
      getTcList.response.map((item, index) => {
        item.id = index + 1
    })
      yield put(getTcsSuccess(getTcList.response))
    }else{
      if(tc){
        getTcList = yield call(getTcs,tc)
        getTcList.response.map((item, index) => {
          item.id = index + 1
      })
        yield put(getTcsSuccess(getTcList.response))
      }
      else{
        getTcList = []
        yield put(getTcsSuccess(getTcList))
      }
    }

  } catch (error) {
    console.log(error)
    yield put(getTcsFail(error))
  }
}

function* onReadTc({ payload: tc }) {
  try {
    console.log(tc)
    const response = yield call(readTc, tc)
    yield put(readTcSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(readTcSuccess(error))
  }
}

function* onAddTc({ payload: tc }) {
  try {
    const response = yield call(postTc, tc)
    if(response.success){
      yield put(addTcSuccess(response))
    }else{
      yield put(addTcFail(response))
    }
  } catch (error) {
    console.log(error)
    yield put(addTcFail(error))
  }
}

function* onUpdateTc({ payload: tc }) {
  try {
    const response = yield call(updateTc, tc)
    if(response.success){
      yield put(updateTcSuccess(response))
    }else{
      yield put(updateTcFail(response))
    }
  } catch (error) {
    console.log(error)
    yield put(updateTcFail(error))
  }
}

function* onStatusTc({ payload: tc }) {
  try {
    const response = yield call(statusChangeTc, tc)
    yield put(statusTcSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(updateTcFail(error))
  }
}

function* onArchiveTc({ payload: tc }) {
  try {
    const response = yield call(archiveTc, tc)
    yield put(archiveTcSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(archiveTcSuccess(error))
  }
}

function* tcsSaga() {
  yield takeEvery(GET_TCS, fetchTcs)
  yield takeEvery(ADD_TC, onAddTc)
  yield takeEvery(UPDATE_TC, onUpdateTc)
  yield takeEvery(READ_TC, onReadTc)
  yield takeEvery(STATUS_TC, onStatusTc)
  yield takeEvery(ARCHIVE_TC, onArchiveTc)
}

export default tcsSaga;
