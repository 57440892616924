/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button, Input, Form
} from "reactstrap"
import { Dropdown } from "react-bootstrap";

import DateRangePickers from 'helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

const InvoiceTableHelper = props => {
  console.log(props)
  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [searchText, setSearchText] = useState('')

  const changeDatePick = (event) => {
    setFromDate(event.startDate._d.toISOString());
    setToDate(event.endDate._d.toISOString());
  }

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );

  const handleTableChange = (type, { page, sizePerPage }) => {
    const currentIndex = (page - 1) * sizePerPage;
    console.log(page, sizePerPage)
    props.paginationSelection({ pageNumber: page, pageSize: sizePerPage, searchText: searchText })
  }

  const handleSizeChange = (page, sizePerPage) => {
    console.log(page, sizePerPage)
    props.paginationSelection({ pageNumber: page, pageSize: sizePerPage, searchText: searchText })
  }

  const handleTextChange = (e) => {
    e.preventDefault();
    setSearchText(e.target.value)
    props.paginationSelection({ pageNumber: 1, pageSize: 10, searchText: e.target.value })
  }

  const RemotePagination = ({ data, page, columns, sizePerPage, onTableChange, totalSize }) => (

    <PaginationProvider
      pagination={
        paginationFactory({
          custom: true,
          page,
          sizePerPage,
          totalSize,
          paginationSize: 4,
          pageStartIndex: 1,
          sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
          alwaysShowAllBtns: true, // Always show next and previous button
          withFirstAndLast: true, // Hide the going to First and Last page button
          firstPageText: 'First',
          prePageText: 'Back',
          nextPageText: 'Next',
          lastPageText: 'Last',
          nextPageTitle: 'First page',
          prePageTitle: 'Pre page',
          firstPageTitle: 'Next page',
          lastPageTitle: 'Last page',
          showTotal: true,
          paginationTotalRenderer: customTotal,
          disablePageTitle: true,
        })
      }
    >
      {
        ({
          paginationProps,
          paginationTableProps
        }) => (
          <div>
            <Row>
              <Col xl="12 " className="table-responsive">
                <BootstrapTable
                  remote
                  keyField="id"
                  data={data}
                  columns={columns}
                  bordered={false}
                  striped={false}
                  classes={
                    "table align-middle table-nowrap"
                  }
                  headerWrapperClasses={"thead-light"}
                  noDataIndication={<h5 className="text-center">No Data</h5>}
                  onTableChange={onTableChange}
                  {...paginationTableProps}
                // ref={node}
                />
              </Col>
            </Row>
            <div>
              <Dropdown style={{ float: "left", paddingBottom: ".5rem" }}>
                <Dropdown.Toggle
                  variant="default"
                  className="border"
                  id="dropdown-basic"
                  style={{ boxShadow: "none" }}
                >
                  {sizePerPage}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={e => handleSizeChange(page, 10)}>
                    10
                  </Dropdown.Item>
                  <Dropdown.Item onClick={e => handleSizeChange(page, 30)}>
                    30
                  </Dropdown.Item>
                  <Dropdown.Item onClick={e => handleSizeChange(page, 50)}>
                    50
                  </Dropdown.Item>
                  <Dropdown.Item onClick={e => handleSizeChange(page, 100)}>
                    100
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <span className="react-bootstrap-table-pagination-total" style={{ position: 'absolute', bottom: '2.7pc' }}>
                Showing {((page - 1) * sizePerPage) + 1} to {((page - 1) * sizePerPage) + sizePerPage} of {totalSize} entries
              </span>
            </div>
            <div>
              <PaginationListStandalone
                {...paginationProps}
              />
            </div>
          </div>
        )
      }
    </PaginationProvider>

  );

  return (
    <>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row className="mb-2">
                <Col sm="4">
                  <Form>
                    <div className="search-box ms-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <label htmlFor="search-bar-0" className="search-label">
                          <span id="search-bar-0-label" className="sr-only"></span>
                          <Input type="text" name="searchText" value={searchText} onChange={handleTextChange} className="form-control " placeholder="Search" />
                        </label>
                        <i className="bx bx-search-alt search-icon"></i>
                      </div>
                    </div>
                  </Form>
                </Col>
                <Col sm="4"></Col>
                <Col sm="4" className="date-range ">
                  <div className="text-sm-end">
                    <DateRangePickers datePick={changeDatePick} />
                  </div>
                </Col>
              </Row>
              <RemotePagination
                data={props.data}
                page={props.pageNumber}
                columns={props.columns}
                sizePerPage={props.pageSize}
                totalSize={props.totalRecords}
                onTableChange={handleTableChange}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

InvoiceTableHelper.propTypes = {
}

export default InvoiceTableHelper
