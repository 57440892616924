import {
  LOAD_BAY,
  NO_DATA_BAY,
    GET_BRAND_AFFINITYS,
    GET_BRAND_AFFINITYS_FAIL,
    GET_BRAND_AFFINITYS_SUCCESS,
    ADD_BRAND_AFFINITY,
    ADD_BRAND_AFFINITY_SUCCESS,
    ADD_BRAND_AFFINITY_ERROR,
    UPDATE_BRAND_AFFINITY,
    UPDATE_BRAND_AFFINITY_SUCCESS,
    UPDATE_BRAND_AFFINITY_ERROR,
    READ_BRAND_AFFINITY,
    READ_BRAND_AFFINITY_SUCCESS,
    STATUS_BRAND_AFFINITY,
    STATUS_BRAND_AFFINITY_SUCCESS,
    ARCHIVE_BRAND_AFFINITY ,
    ARCHIVE_BRAND_AFFINITY_SUCCESS

  } from "./actionTypes"

  export const loadBay = Params => ({
    type: LOAD_BAY,
    payload:Params
  })

  export const noBays = Params => ({
    type: NO_DATA_BAY,
    payload:Params
  })


  export const getBrandAffinitys = () => ({
    type: GET_BRAND_AFFINITYS,
  })
  
  export const getBrandAffinitysSuccess = BrandAffinitys => ({
    type: GET_BRAND_AFFINITYS_SUCCESS,
    payload: BrandAffinitys,
  })

  export const getBrandAffinitysFail = error => ({
    type: GET_BRAND_AFFINITYS_FAIL,
    payload: error,
  })

  export const addNewBrandAffinity = BrandAffinity => ({
    type: ADD_BRAND_AFFINITY,
    payload: BrandAffinity,
  })
  
  export const addBrandAffinitySuccess = BrandAffinity => ({
    type: ADD_BRAND_AFFINITY_SUCCESS,
    payload: BrandAffinity,
  })
  
  export const addBrandAffinityFail = BrandAffinity => ({
    type: ADD_BRAND_AFFINITY_ERROR,
    payload: BrandAffinity,
  })

  export const updateBrandAffinity = BrandAffinity => ({
    type: UPDATE_BRAND_AFFINITY,
    payload: BrandAffinity,
  })
  
  export const updateBrandAffinitySuccess = BrandAffinity => ({
    type: UPDATE_BRAND_AFFINITY_SUCCESS,
    payload: BrandAffinity,
  })
  
  export const updateBrandAffinityFail = BrandAffinity => ({
    type: UPDATE_BRAND_AFFINITY_ERROR,
    payload: BrandAffinity,
  })

  export const readBrandAffinity = BrandAffinity => ({
    type: READ_BRAND_AFFINITY,
    payload: BrandAffinity,
  })

  export const readBrandAffinitySuccess = BrandAffinity => ({
    type: READ_BRAND_AFFINITY_SUCCESS,
    payload: BrandAffinity,
  })

  export const statusBrandAffinity = BrandAffinity => ({
    type: STATUS_BRAND_AFFINITY,
    payload: BrandAffinity,
  })

  export const statusBrandAffinitySuccess = BrandAffinity => ({
    type: STATUS_BRAND_AFFINITY_SUCCESS,
    payload: BrandAffinity,
  })

  export const archiveBrandAffinity = BrandAffinity => ({
    type: ARCHIVE_BRAND_AFFINITY,
    payload: BrandAffinity,
  })

  export const archiveBrandAffinitySuccess = BrandAffinity => ({
    type: ARCHIVE_BRAND_AFFINITY_SUCCESS,
    payload: BrandAffinity,
  })