/* PARTNER */
export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES"
export const NO_DATA_BILLING = "NO_DATA_BILLING"

export const GET_BILL_HISTORY = "GET_BILL_HISTORY"
export const GET_BILL_HISTORY_SUCCESS = "GET_BILL_HISTORY_SUCCESS"
export const GET_BILL_HISTORY_FAIL = "GET_BILL_HISTORY_FAIL"

export const GET_BILL_HISTORY_LIST = "GET_BILL_HISTORY_LIST"
export const GET_BILL_HISTORY_SUCCESS_LIST = "GET_BILL_HISTORY_SUCCESS_LIST"
export const GET_BILL_HISTORY_FAIL_LIST = "GET_BILL_HISTORY_FAIL_LIST"