import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_PAGE_URLS,ADD_PAGE_URL , UPDATE_PAGE_URL,READ_PAGE_URL,STATUS_PAGE_URL,ARCHIVE_PAGE_URL} from "./actionTypes"

import {
  loadPageUrls,
  noPageUrls,
  getPageUrlsSuccess,
  getPageUrlsFail,
  addPageUrlSuccess,
  addPageUrlFail,
  updatePageUrlSuccess,
  updatePageUrlFail,
  readPageUrlSuccess,
  statusPageUrlSuccess,
  archivePageUrlSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getPageUrls,createPageUrl,updatePageUrl,readPageUrl,statusChangePageUrl,archivePageUrl } from "../../../helpers/Backend_helper"

function* fetchPageUrls() {
  try {
    yield put(loadPageUrls(true))
    const response = yield call(getPageUrls)
    console.log(response)
    response.response.map((item, index) => {
      item.id = index + 1
  })
  console.log(response)
  if(response.response.length === 0){
    yield put(noPageUrls(true))
  }
    yield put(getPageUrlsSuccess(response.response))
  } catch (error) {
    yield put(getPageUrlsFail(error))
  }
}

function* onReadPageUrl({ payload: pageUrl }) {
  try {
    const response = yield call(readPageUrl, pageUrl)
    yield put(readPageUrlSuccess(response))
  } catch (error) {
    yield put(readPageUrlSuccess(error))
  }
}

function* onAddPageUrl({ payload: pageUrl }) {
  try {
    const response = yield call(createPageUrl, pageUrl)
    if(response.success){
      yield put(addPageUrlSuccess(response))
    }else{
      yield put(addPageUrlFail(response))
    }
  } catch (error) {
    yield put(addPageUrlFail(error))
  }
}

function* onUpdatePageUrl({ payload: pageUrl }) {
  try {
    const response = yield call(updatePageUrl, pageUrl)
    if(response.success){
      yield put(updatePageUrlSuccess(response))
    }else{
      yield put(updatePageUrlFail(response))
    }
  } catch (error) {
    yield put(updatePageUrlFail(error))
  }
}

function* onStatusPageUrl({ payload: pageUrl }) {
  try {
    const response = yield call(statusChangePageUrl, pageUrl)
    if(response.success){
      yield put(statusPageUrlSuccess(response))
    }else{
      yield put(statusPageUrlSuccess(response.message))
    }
  } catch (error) {
    yield put(updatePageUrlFail(error))
  }
}

function* onArchivePageUrl({ payload: pageUrl }) {
  try {
    const response = yield call(archivePageUrl, pageUrl)
    yield put(archivePageUrlSuccess(response))
  } catch (error) {
    yield put(archivePageUrlSuccess(error))
  }
}

function* pageUrlSaga() {
  yield takeEvery(GET_PAGE_URLS, fetchPageUrls)
  yield takeEvery(ADD_PAGE_URL, onAddPageUrl)
  yield takeEvery(UPDATE_PAGE_URL, onUpdatePageUrl)
  yield takeEvery(READ_PAGE_URL, onReadPageUrl)
  yield takeEvery(STATUS_PAGE_URL, onStatusPageUrl)
  yield takeEvery(ARCHIVE_PAGE_URL, onArchivePageUrl)
}

export default pageUrlSaga;
