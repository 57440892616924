/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import NotFoundImage from 'assets/images/bidsxchange/ErrorConnection.jpg';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";

const ErrorPage = ({ error, resetErrorBoundary }) => {
    console.log('Error occured', error);
    const nextRef = useRef();
    // console.log(window.history.previous.href)
    const toggleMenu = () => {
      console.log(window.history)
      // nextRef.current.click()
      window.history.back()
    }
    return (
        <div className="page-content" style={{backgroundColor:'#ffffff'}}>
        <Helmet>
          <title>Error Page | Bidsxchange</title>
        </Helmet>
      <div className='error-page'>
        <img src={NotFoundImage} alt='Page not found' />
        <p className='error-msg'>
          Oops! Something went wrong.
          <button className='btn' onClick={toggleMenu}>
            Refresh page
          </button>
          {/* <Link ref={nextRef} to={window.history.previous.href} className="color-dark-blue ">
            </Link> */}
        </p>
      </div>
      </div>
    );
  };

export default ErrorPage