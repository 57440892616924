/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import {
  Row, Col, Input, Label, Form, FormFeedback,
  FormGroup,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Container,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";

const VideoPlayerControls = (props) => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [controls, setControls] = useState({
    closeButtonPosition: "outer-right",
    bottomMargin: 0,
    rightMargin: 0,
    topMargin: 0,
    leftMargin: 0,
    closeButtonHeight: 0,
    closeButtonWidth: 0,
    closeButtonText: '',
    closeButtonInfoURL: '',
    previousButton: false,
    nextButton: false,
    contentReplayButton: false,
    contentForwardButton: false,
    playPauseButton: true,
    playOnViewPercentage: 50,
    fullScreen: true,
    duration: true,
    soundButton: true,
    volumeBar: true,
    autoplay: false,
    autoContinue: false,
    loop: false,
    brandIdentity: true,
    brandIdentityPosition: 'center',
    playOnView: true,
    autoSkip: false,
    loadAdOnView: true,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    muted: true,
    closeButton: true,
    logo: true,
    logoPosition: 'left',
    logoHeight: 0,
    logoWidth: 0,
    logoURL: "https://nino.bidsxchange.com/static/media/favicons.ca93cc9b.png",
    logoLpu: '',
    logoClickThroughURL: "https://streamlyn.com/products-seller",
    logoToolTip: '',
    logoTextFontSize: 14,
    logoText: "Ads by",
    vastTag: ''
  });

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const body = {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
  }

  useEffect( () => {
    if (props.settingsId) {

       axios.post("/v2.0/supply/PlayerSettings/read/" + props.settingsId, body,
        {
          headers: headers
        })
        .then((res) => {
          console.log("result is ==>", res)
          if (res.data) {
            console.log(res.data)
            setControls({ ...controls, ...res.data.response.playerControls })
          }
        })
        .catch((err) => {
          console.log("e", err)
        });
    }
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setControls((controls) => ({ ...controls, [name]: value }));
    props.selectedItems({ ...controls, [name]: value })
  };

  const handleCheck = (event) => {
    setControls({
      ...controls,
      [event.target.name]: event.target.checked,
    });
    props.selectedItems({ ...controls, [event.target.name]: event.target.checked })
  };


  return (
    <div>
      {/* <PlayerSettings /> */}
      <div className="player-content">
        <div className="container-fluid">

          {/* <div className="row mt-4 ml-4 mb-4">
            <div className="col-md-4">
              <div className="form-group row mt-3">
                <input type="checkbox" id="prevBtn" className="form-check-input mt-1 p-0" checked={controls.previousButton} onChange={handleCheck} name="previousButton"  />
                <label htmlFor="prevBtn" className="col-9 mt-1">Previous Button</label>
              </div>
              <div className="form-group row mt-3">
                <input type="checkbox" id="nextBtn" className="form-check-input mt-1 p-0" checked={controls.nextButton}  onChange={handleCheck} name="nextButton"/>
                <label htmlFor="nextBtn" className="col-9 mt-1">Next Button</label>
              </div>
              <div className="form-group row mt-3">
                <input type="checkbox" id="contentrb" className="form-check-input mt-1 p-0" checked={controls.contentReplayButton}  onChange={handleCheck} name="nextButton"/>
                <label htmlFor="contentrb" className="col-9 mt-1">Content Replay Button</label>

              </div>
              <div className="form-group row mt-3">
                <input type="checkbox" className="form-check-input mt-1 p-0" checked={controls.contentForwardButton}  onChange={handleCheck} name="nextButton"/>
                <span className="col-9 mt-1">Content orward Button</span>
              </div>
              <div className="form-group row mt-3">
                <input type="checkbox" className="form-check-input  mt-1 p-0"  checked={controls.playPauseButton}  onChange={handleCheck} name="playPauseButton"/>
                <span className="col-9 mt-1">Play/Pause Button</span>
              </div>
              <div className="form-group row">
                <Label className="form-label">PlayOnViewPercentage</Label>
                <Input
                  value={controls.playOnViewPercentage}
                  onChange={handleChange}
                  name="playOnViewPercentage"
                  className=" col-6  "
                >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="75">75</option>
                  <option value="100">100</option>
                </Input>
              </div>
              <div className="form-group row mt-3">
                <input type="checkbox" className="form-check-input  mt-1 p-0"  checked={controls.fullScreen}  onChange={handleCheck} name="fullScreen" />
                <span className="col-9 mt-1">Fullscreen Button</span>
              </div>
              <div className="form-group row mt-3">
                <input type="checkbox" className="form-check-input  mt-1 p-0" checked={controls.duration} onChange={handleCheck} name="duration" />
                <span className="col-9 mt-1">Duration</span>
              </div>
              <div className="form-group row mt-3">
                <input type="checkbox" className="form-check-input  mt-1 p-0" checked={controls.soundButton} onChange={handleCheck} name="soundButton" />
                <span className="col-9 mt-1">Sound Button</span>
              </div>
              
            </div>
            <div className="col-md-4 ">
            
              <div className="form-group row mt-3">
                <input type="checkbox" className="form-check-input  mt-1 p-0" checked={controls.volumeBar} onChange={handleCheck} name="volumeBar" />
                <span className="col-9 mt-1">Volume Bar</span>

              </div>
              <div className="form-group row mt-3">
                <input type="checkbox" className='form-check-input mt-1 p-0' checked={controls.autoplay} onChange={handleCheck} name="autoplay" />
                <span className='col-9 mt-1'>Autoplay</span>

              </div>
              <div className="form-group row mt-3">
                <input type="checkbox" className='form-check-input mt-1 p-0' checked={controls.autoContinue} onChange={handleCheck} name="autoContinue"/>
                <span className='col-9 mt-1'>Auto Continue</span>
              </div>
              <div className="form-group row mt-3">
                <input type="checkbox" className='form-check-input mt-1 p-0' checked={controls.loop} onChange={handleCheck} name="loop" />
                <span className='col-9 mt-1'>Loop</span>
              </div>
              <div className="form-group row mt-3">
                <input type="checkbox" className='form-check-input mt-1 p-0' checked={controls.brandIdentity} onChange={handleCheck} name="brandIdentity" />
                <span className='col-9 mt-1'>Brand Identity</span>
              </div>
              <div className="form-group row mt-3">
                <input type="checkbox" className='form-check-input mt-1 p-0' checked={controls.playOnView} onChange={handleCheck} name="playOnView" />
                <span className='col-9 mt-1'>Play On View</span>
              </div>
              <div className="form-group row mt-3">
                <input type="checkbox" className='form-check-input mt-1 p-0' checked={controls.autoSkip} onChange={handleCheck} name="autoSkip" />
                <span className='col-9 mt-1'>Enable Auto Skip</span>
              </div>
              <div className="form-group row mt-3">
                <input type="checkbox" className='form-check-input mt-1 p-0' checked={controls.loadAdOnView} onChange={handleCheck} name="loadAdOnView" />
                <span className='col-9 mt-1'>Load Ad On View</span>
              </div>
              <div className="form-group row mt-3">
                <input type="checkbox" className='form-check-input mt-1 p-0' checked={controls.muted} onChange={handleCheck} name="muted" />
                <span className='col-9 mt-1'>Muted</span>
              </div>
              <div className="form-group row mt-3">
                <input type="checkbox" className='form-check-input mt-1 p-0' checked={controls.playbackRates} onChange={handleCheck} name="autoSkip" />
                <span className='col-9 mt-1'>playback Rates</span>
              </div>
            </div>
            <div className="col-md-4">
            <div className="form-group row">
            <Label className="form-label">Margin Left</Label>
                <Input 
                  type="text"
                  value={controls.marginLeft}
                  onChange={handleChange}
                  name="marginLeft"
                  className="col-8"
                />
              </div>
              <div className="form-group row">
              <Label className="form-label">Margin Right</Label>
                <Input 
                  type="text"
                  value={controls.marginRight}
                  onChange={handleChange}
                  name="marginRight"
                  className="col-8"
                />
              </div>
              <div className="form-group row">
              <Label className="form-label">Margin Top</Label>
                <Input 
                  type="text"
                  value={controls.marginTop}
                  onChange={handleChange}
                  name="marginTop"
                  className="col-8"
                />
              </div>
              <div className="form-group row">
              <Label className="form-label">Margin Bottom</Label>
                <Input 
                  type="text"
                  value={controls.marginBottom}
                  onChange={handleChange}
                  name="marginBottom"
                  className="col-8"
                />
              </div>
            </div>
          </div> */}
          <h5 className="heading5 mt-4">Vast Settings</h5>
          <div className="form-group mb-3 ">
            <Label className="">Vast Tag</Label>
            <Input
              type="text"
              className="form-control"
              value={controls.vastTag}
              onChange={handleChange}
              name="vastTag"
            />
          </div>
          <div className="mb-3">
            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemd"
                checked={controls.closeButton} onChange={handleCheck} name="closeButton"
              />
              <label className="form-check-label " htmlFor="customSwitchsizemd">
                <h5 className="heading5 mt-1">Close Button</h5>
              </label>
            </div>
          </div>
          <div className="form-group mb-3 ">
            <Label className="form-label">Position</Label>
            <Input
              type="select"
              className="form-select"
              value={controls.closeButtonPosition}
              onChange={handleChange}
              name="closeButtonPosition"
              disabled={!controls.closeButton}
            >
              <option value="inner-left">Inner-left</option>
              <option value="inner-right">Inner-right</option>
              <option value="outer-left">Outer-left</option>
              <option value="outer-right">Outer-right</option>
            </Input>
          </div>
          <div className="mb-3">
            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
              <input
                type="checkbox"
                className="form-check-input"
                id="brandIdent"
                checked={controls.brandIdentity} onChange={handleCheck} name="brandIdentity"
              />
              <label className="form-check-label " htmlFor="brandIdent">
                <h5 className="heading5 mt-1">Brand Identity</h5>
              </label>
            </div>
          </div>
          <div className="form-group mb-3 ">
            <Label className="form-label">Position</Label>
            <Input
              type="select"
              className="form-select"
              value={controls.brandIdentityPosition}
              onChange={handleChange}
              name="brandIdentityPosition"
              disabled={!controls.brandIdentity}
            >
              <option value="left">Left</option>
              <option value="center">Center</option>
              <option value="right">Right</option>
            </Input>
          </div>
          {/* <div className="row mt-2 ml-4">
            <div className="col-md-5">
              <div className="form-group row">
              <Label className="form-label">Height</Label>
                <Input 
                  type="text"
                  value={controls.closeButtonHeight}
                  onChange={handleChange}
                  name="closeButtonHeight"
                />
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="form-group row">
              <Label className="form-label">Width</Label>
                <Input 
                  type="text"
                  value={controls.closeButtonWidth}
                  onChange={handleChange}
                  name="closeButtonWidth"
                />
              </div>
            </div>
          </div>
          <div className="row mt-2 ml-4">
            <div className="col-md-5">
              <div className="form-group row">
              <Label className="form-label">Left Margin</Label>
                <Input 
                  type="text"
                  value={controls.leftMargin}
                  onChange={handleChange}
                  name="leftMargin"
                />
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="form-group row">
              <Label className="form-label">Right Margin</Label>
                <Input 
                  type="text"
                  value={controls.rightMargin}
                  onChange={handleChange}
                  name="rightMargin"
                />
              </div>
            </div>
          </div>
          <div className="row mt-2 ml-4 mb-4">
            <div className="col-md-5">
              <div className="form-group row">
              <Label className="form-label">Top Margin</Label>
                <Input 
                  type="text"
                  value={controls.topMargin}
                  onChange={handleChange}
                  name="topMargin"
                />
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="form-group row">
              <Label className="form-label">Bottom Margin</Label>
                <Input 
                  type="text"
                  value={controls.bottomMargin}
                  onChange={handleChange}
                  name="bottomMargin"
                />
              </div>
            </div>
            </div>
            <div className="row mt-2 ml-4 mb-4">
            <div className="col-md-5">
              <div className="form-group row">
              <Label className="form-label">Close Button Text</Label>
                <Input 
                  type="text"
                  value={controls.closeButtonText}
                  onChange={handleChange}
                  name="closeButtonText"
                />
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="form-group row">
              <Label className="form-label">Close Button Info URL</Label>
                <Input 
                  type="text"
                  value={controls.closeButtonInfoURL}
                  onChange={handleChange}
                  name="closeButtonInfoURL"
                />
              </div>
            </div>
          </div> */}
          <div className="mb-3">
            <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
              <input
                type="checkbox"
                className="form-check-input"
                id="logo"
                checked={controls.logo} onChange={handleCheck} name="logo"
              />
              <label className="form-check-label " htmlFor="logo">
                <h5 className="heading5 mt-1">Logo</h5>
              </label>
            </div>
          </div>

          <div className="form-group mb-3 ">
            <Label className="form-label">Position</Label>
            <Input
              type="select"
              className="form-select"
              value={controls.logoPosition}
              onChange={handleChange}
              name="logoPosition"
              disabled={!controls.logo}
            >
              <option value="right">Right</option>
              <option value="left">Left</option>
            </Input>
          </div>
          {/* <div className="row mt-2 ml-4">
            <div className="col-md-5">
              <div className="form-group row">
              <Label className="form-label">Height</Label>
                <Input 
                  type="text"
                  value={controls.logoHeight}
                  onChange={handleChange}
                  name="logoHeight"
                />
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="form-group row">
              <Label className="form-label">Width</Label>
                <Input 
                  type="text"
                  value={controls.logoWidth}
                  onChange={handleChange}
                  name="logoWidth"
                />
              </div>
            </div>
          </div> */}

          <div className="form-group mb-3 ">
            <Label className="form-label">Link</Label>
            <Input
              type="text"
              value={controls.logoURL}
              onChange={handleChange}
              name="logoURL"
              readOnly={!controls.logo}
            />
          </div>
          <div className="form-group mb-3 ">
            <Label className="form-label">Click through URL</Label>
            <Input
              type="text"
              name="logoClickThroughURL"
              value={controls.logoClickThroughURL}
              onChange={handleChange}
              readOnly={!controls.logo}
            />
          </div>
          {/* <div className="row mt-2 ml-4">
            <div className="col-md-5">
              <div className="form-group row">
              <Label className="form-label">Text</Label>
                <Input 
                  type="text"
                  name="logoText"
                  value={controls.logoText}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="form-group row">
              <Label className="form-label">Logo Lpu</Label>
                  <Input 
                  type="text"
                  name="logoLpu"
                  value={controls.logoLpu}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="row mt-2 ml-4">
            <div className="col-md-5">
              <div className="form-group row">
              <Label className="form-label">Logo Tool Tip</Label>
                <Input 
                  type="text"
                  name="logoToolTip"
                  value={controls.logoToolTip}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="form-group row">
              <Label className="form-label">Logo Text FontSize</Label>
                  <Input 
                  type="text"
                  name="logoTextFontSize"
                  value={controls.logoTextFontSize}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div> */}
          <button
            type="submit"
            className="btn btn-primary save-user mt-4"
          >
            Preview Video
          </button>
        </div>
      </div>
    </div >
  )
}

export default VideoPlayerControls