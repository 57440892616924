import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_SUPPORT_TICKETS,ADD_SUPPORT_TICKET , ADD_CHAT} from "./actionTypes"

import {
  loadSupportTickets,
  noSupportTicketsPublishers,
  getSupportTicketsSuccess,
  getSupportTicketsFail,
  addSupportTicketSuccess,
  addSupportTicketFail,
  addChatSuccess,
  addChatFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getSupportTicket,postSupportTicket,postChat } from "helpers/Backend_helper"

function* fetchSupportTickets({ payload: spt }) {
  try {
    yield put(loadSupportTickets(true))
    var getSupportList
      if(spt){
        getSupportList = yield call(getSupportTicket,spt)
        console.log(getSupportList)
        getSupportList
        .map((item, index) => {
          item.id = index + 1
      })
      if(getSupportList.length === 0){
        yield put(noSupportTicketsPublishers(true))
      }
        yield put(getSupportTicketsSuccess(getSupportList)) 
      }
      else{
        getSupportList = []
        yield put(getSupportTicketsSuccess(getSupportList))
      }

  } catch (error) {
    console.log(error)
    yield put(getSupportTicketsFail(error))
  }
}

function* onAddSupportTicket({ payload: spt }) {
  try {
    const response = yield call(postSupportTicket, spt)
    if(response.success){
      yield put(addSupportTicketSuccess(response))
    }else{
      yield put(addSupportTicketFail(response))
    }
  } catch (error) {
    console.log(error)
    yield put(addSupportTicketFail(error))
  }
}

function* onAddChat({ payload: spt }) {
  try {
    const response = yield call(postChat, spt)
    if(response.success){
      yield put(addChatSuccess(response))
    }else{
      yield put(addChatFail(response.message))
    }
  } catch (error) {
    console.log(error)
    yield put(addChatFail(error))
  }
}

function* supportTicketsSaga() {
  yield takeEvery(GET_SUPPORT_TICKETS, fetchSupportTickets)
  yield takeEvery(ADD_SUPPORT_TICKET, onAddSupportTicket)
  yield takeEvery(ADD_CHAT, onAddChat)
}

export default supportTicketsSaga;
