/* HEADERBID */
export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES"
export const NO_DATA = "NO_DATA"
export const GET_HEADERBIDS = "GET_HEADERBIDS"
export const GET_HEADERBIDS_SUCCESS = "GET_HEADERBIDS_SUCCESS"
export const GET_HEADERBIDS_FAIL = "GET_HEADERBIDS_FAIL"

export const ADD_HEADERBID = "ADD_HEADERBID"
export const ADD_HEADERBID_SUCCESS = "ADD_HEADERBID_SUCCESS"
export const ADD_HEADERBID_ERROR = "ADD_HEADERBID_ERROR"

export const UPDATE_HEADERBID = "UPDATE_HEADERBID"
export const UPDATE_HEADERBID_SUCCESS = "UPDATE_HEADERBID_SUCCESS"
export const UPDATE_HEADERBID_ERROR = "UPDATE_HEADERBID_ERROR"

export const UPDATE_ADUNITBID = "UPDATE_ADUNITBID"
export const UPDATE_ADUNITBID_SUCCESS = "UPDATE_ADUNITBID_SUCCESS"
export const UPDATE_ADUNITBID_ERROR = "UPDATE_ADUNITBID_ERROR"

export const READ_HEADERBID = "READ_HEADERBID"
export const READ_HEADERBID_SUCCESS = "READ_HEADERBID_SUCCESS"

export const STATUS_HEADERBID = "STATUS_HEADERBID"
export const STATUS_HEADERBID_SUCCESS = "STATUS_HEADERBID_SUCCESS"

export const ARCHIVE_HEADERBID = "ARCHIVE_HEADERBID"
export const ARCHIVE_HEADERBID_SUCCESS = "ARCHIVE_HEADERBID_SUCCESS"