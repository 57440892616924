/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types'
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
  } from "reactstrap";

const Agency_LoadingDashboard = props => {

    const [reportData, setReportData] = useState([
        { title: "Impressions", iconClass: "bx-bar-chart", description: "0", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "Clicks", iconClass: "bx-bar-chart", description: "0", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "CTR", iconClass: "bx-dollar-circle", description: "$0", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "Conversions", iconClass: "bx-dollar-circle", description: "$0", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "Conversions Ratio", iconClass: "bx-show-alt", description: "0%", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "Cost", iconClass: "bx-show-alt", description: "0%", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "CPM", iconClass: "bx-show-alt", description: "0%", yesterdayRate: '0%', isIncreased: 'zero' },
      ])

      const [timer, setTimer] = useState({ count: 0 })
      const increment = useRef(null)
  
      useEffect(() => {
          handleStart()
      }, []);
  
      useEffect(() => {
          // console.log(timer.count,props.loading)
          if(props.loading){
              // if (timer.count >= 10) {
              //     clearInterval(increment.current)
              //     setTimer({ count: 0 })
              //     props.targetLoading()
              // }
          }else{
              if (timer.count >= 1) {
                  clearInterval(increment.current)
                  setTimer({ count: 0 })
                  props.targetLoading()
              }
          }
      
      }, [timer.count,props.loading]);
  
      const handleStart = () => {
          increment.current = setInterval(() => {
              setTimer(prevState => ({
                  count: prevState.count + 1
              }))
        console.log()
          }, 100)
      }


  return (
    <React.Fragment>
                {reportData.map((report, key) => (
                  <Col md="" key={"_col_" + key}>
                    <Card className="mini-stats-wid dashboard-cards placeholder-glow text-center">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className='justify-content-center' style={{ marginBottom: '3%' }}><span className="placeholder col-9 "></span></h4>
                            <h5 className='justify-content-center' style={{ marginBottom: '3%' }}><span className="placeholder col-5 "></span></h5>
{/*                           
                            &nbsp;&nbsp;From Previous Period */}
                          </div>
                          {/* <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div> */}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
    </React.Fragment>
  )
}

Agency_LoadingDashboard.propTypes = {}

export default Agency_LoadingDashboard