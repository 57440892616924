import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const AdminSidebar = props => {

    const ref = useRef();
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const userRole = localStorage.getItem('UserRole');
    const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));

    useEffect(() => {
      const pathName = props.location.pathname;
      if (pathName !== '/') {
        const initMenu = () => {
          new MetisMenu("#side-menu");
          let matchingMenuItem = null;
          const ul = document.getElementById("side-menu");
          const items = ul.getElementsByTagName("a");
          for (let i = 0; i < items.length; ++i) {
            if (pathName === items[i].pathname) {
              matchingMenuItem = items[i];
              break;
            }
          }
          if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
          }
        };
        initMenu();
      }
    }, [props.location.pathname]);
  
    useEffect(() => {
      ref.current.recalculate();
    });
  
    function scrollElement(item) {
      if (item) {
        const currentPosition = item.offsetTop;
        if (currentPosition > window.innerHeight) {
          ref.current.getScrollElement().scrollTop = currentPosition - 300;
        }
      }
    }
  
    function activateParentDropdown(item) {
      item.classList.add("active");
      const parent = item.parentElement;
      const parent2El = parent.childNodes[1];
      if (parent2El && parent2El.id !== "side-menu") {
        parent2El.classList.add("mm-show");
      }
  
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement;
  
        if (parent2) {
          parent2.classList.add("mm-show"); // ul tag
  
          const parent3 = parent2.parentElement; // li tag
  
          if (parent3) {
            parent3.classList.add("mm-active"); // li
            parent3.childNodes[0].classList.add("mm-active"); //a
            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.add("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.add("mm-show"); // li
                parent5.childNodes[0].classList.add("mm-active"); // a tag
              }
            }
          }
        }
        scrollElement(item);
        return false;
      }
      scrollElement(item);
      return false;
    }


    return (
        <React.Fragment>
            <div className="vertical-menu1" style={{ background:  '#34434c' }}>
      
                <div data-simplebar className="h-100">
                <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu1 ">
          {(userRole === 'Admin' || userRole === 'User') &&
            <ul className="metismenu list-unstyled" id="side-menu">

              <li className="menu-title" style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.isAdminAccess) ? 'block' : 'none' }}>{props.t("Admin")}</li>


   

                  {/* <li>
                      <Link to="/Global_Settings">{props.t("Global Settings")}</Link>
                    </li> */}
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.admin && UserRolePrivileges.admin.isAccessManagementAccess) ? 'block' : 'none' }}>
                    <Link to="/Network_Settings">{props.t("Network Settings")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.admin && UserRolePrivileges.admin.isAccessManagementAccess) ? 'block' : 'none' }}>
                    <Link to="/Access_Management">{props.t("Access Management")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.admin && UserRolePrivileges.admin.isAccessManagementAccess) ? 'block' : 'none' }}>
                    <Link to="/Publisher_Access_Management">{props.t("Publisher Access Management")}</Link>
                  </li>

                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.admin && UserRolePrivileges.admin.isAdsTxtManagementAccess) ? 'block' : 'none' }}>
                    <Link to="/#" className="has-arrow">
                      <span>{props.t("Ads.txt Management")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">

                      <li>
                        <Link to="/Ads_Txt_Queries">{props.t("Ads.txt Queries")}</Link>
                      </li>
                      <li>
                        <Link to="/Ads_Txt_Entries">{props.t("ADS.txt Entries")}</Link>
                      </li>
                      <li>
                        <Link to="/Publisher_Ads_Txt_Entries">{props.t("Publisher ADS.txt Entries")}</Link>
                      </li>
                    </ul>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.admin && UserRolePrivileges.admin.isMasterLogAccess) ? 'block' : 'none' }}>
                    <Link to="/Master_Log" >{props.t("Master Log")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.admin && UserRolePrivileges.admin.isPublisherLogAccess) ? 'block' : 'none' }}>
                    <Link to="/Publisher_Log" >{props.t("Publisher Log")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.adminAccess && UserRolePrivileges.adminAccess.isNotificationAccess) ? 'block' : 'none' }}>
                    <Link to="/Notification">{props.t("Notification")}</Link>
                  </li>
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.adminAccess && UserRolePrivileges.adminAccess.isPublisherApprovalAccess) ? 'block' : 'none' }}>
                    <Link to="/Publisher_Approval">{props.t("Publisher Approval")}</Link>
                  </li>
                  {/* <li>
                    <Link to="/Product_Notification">{props.t("Product Notification")}</Link>
                  </li> */}
                  <li style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges && UserRolePrivileges.adminAccess && UserRolePrivileges.adminAccess.isSupportTicketAccess) ? 'block' : 'none' }}>
                    <Link to="/Support_Tickets">{props.t("Support Tickets")}</Link>
                  </li>


              

            </ul>
          }

     
        </div>
      </SimpleBar>
                </div>
                <div className="sidebar-background"></div>
            </div>
        </React.Fragment>
    )
}

AdminSidebar.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(AdminSidebar));