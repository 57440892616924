/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { withRouter, Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button, Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
} from "reactstrap"

import classnames from "classnames";
import queryString from 'query-string';

import DateRangePickers from 'helpers/DateRangePicker'
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone, PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "components/Common/Breadcrumb"
import AssociateBuyers from "./AssociateBuyers";
import LoadingTable from "pages/LoaderPages/LoadingTable";
import InvoiceTableHelper from "Product_Helpers/InvoiceTableHelper";

var selectedItem = []
var selectedIndex = []

var buyersData = []
let pageNumber = 1
let pageSize = 10
let totalRecords = 0
let searchText = ''

const BuyerAdUnits_old = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [lineItems, setLineItems] = useState([])
    const [selectedAdUnit, setSelectedAdUnit] = useState({})
    const [isRight, setIsRight] = useState(false);
    const parsed = queryString.parse(window.location.search);
    const nextRef = useRef();

        const [loading, setLoading] = useState(true)
        const [switchLoader, setSwitchLoader] = useState(true)
        const handleSwitch = () => {
            setSwitchLoader(!switchLoader)
        }

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    useEffect(() => {
        getLineItem({ pageSize: pageSize, pageNumber: pageNumber, searchText: searchText })
    }, []);

    const getLineItem = async (selectedPages) => {
        setLoading(true)
        setSwitchLoader(true)
        const body = {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            name: userInfo.name, parentID: userInfo.parentID,
            isSupplyPartner: userInfo.isSupplyPartner,
            isForSupplyPartner: userInfo.isForSupplyPartner,
            searchText: selectedPages.searchText
        }
        axios.post(userInfo.isSupplyPartner ? `/v2.0/headderBiddingSupplyPartner/HB_Buyers/read/getActiveAdUnits/SupplyPartner?page=${selectedPages.pageNumber}&size=${selectedPages.pageSize}` : `/v2.0/operations/HB_Buyers/read/Adunits/Pagination?page=${selectedPages.pageNumber}&size=${selectedPages.pageSize}`, body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data.statusCode === 200) {
                    res.data.response.map((item, index) => {
                        item.id = index + 1
                    })
                    setLineItems([...res.data.response])
                    setLoading(false)
                    setSwitchLoader(false)
                    totalRecords = res.data.totalRecords
                    if (parsed.status === 'EditAssociateAdUnits') {
                        res.data.response.map((item, index) => {
                            if (item.adUnitID === Number(parsed.adUnitID)) {
                                setSelectedAdUnit(item)
                                setIsRight(!isRight);
                            }
                        })
                    }
            }
            })
            .catch((err) => {
                setLoading(false)
                setSwitchLoader(false)
                console.log("e", err)
            });
    }

    const toggleRightCanvas = () => {
        nextRef.current.click()
        setIsRight(!isRight);
    };

    const adUnitListColoumns = [
        {
            text: "ID",
            headerClasses: "ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            text: "AdUnit ID",
            dataField: "adUnitID",
            sort: true,
        },
        {
            text: "AdUnit",
            dataField: "adUnitName",
            sort: true,
            formatter: (cellContent, buyer) => (
                <>
                    <span className="font-size-12 mb-1">
                        <span className=" mb-1">{buyer.adUnitName}</span><br />
                        {buyer.buyersCount !== 0 &&
                            <Link to={`/AssociateBuyer_old?adUnitID=${buyer.adUnitID}&adUnitName=${buyer.adUnitName}&status=ViewBuyers`} className="color-dark-blue ">
                                Associate Buyer
                            </Link>
                        }
                        {buyer.buyersCount === 0 &&
                            <Link to={`/AssociateBuyer_old?adUnitID=${buyer.adUnitID}&adUnitName=${buyer.adUnitName}&status=AssociateBuyers`} style={{ color: 'red' }}>
                                * No Buyers Associated | <span style={{ color: 'blue' }}> + Associate Buyers </span>
                            </Link>
                        }
                    </span>
                </>
            ),
        },
        {
            text: "HB Buyers Presents",
            dataField: "buyersCount",
            sort: true,
            align: 'center',
            headerAlign: 'center',
        },

        // {
        //     dataField: "",
        //     isDummyField: true,
        //     editable: false,
        //     text: "Action",
        //     align: 'center',

        //     // eslint-disable-next-line react/display-name
        //     formatter: (cellContent, adUnit) => (
        //         <div className="d-flex gap-3">
        //             <button color="primary"
        //                 className="font-16 btn-block btn btn-primary" onClick={() => { tog_standard(adUnit); }} >
        //                 View Associated Buyers
        //             </button>
        //         </div>
        //     ),
        // },
    ];

    const paginationChange = async (selectedPages) => {
        console.log(selectedPages)
        pageSize = selectedPages.pageSize
        pageNumber = selectedPages.pageNumber
        getLineItem({ pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText })

    }

    return (
        <React.Fragment >
            <div className="page-content">
                <Helmet>
                    <title>AdUnits | Bidsxchange</title>
                </Helmet>
                <Container fluid={true}>
                    {/* Render Breadcrumbs */}

                    <div className=" d-sm-flex  justify-content-between">
                        <Breadcrumbs title="Demand" breadcrumbItem="Ad Unit" breadList="All Ad Units" />
                    </div>
                    <Col lg="12">
                        <Row>
                            {switchLoader &&
                                <LoadingTable loading={loading} targetLoading={handleSwitch} />
                            }
                            {!switchLoader &&
                                <InvoiceTableHelper data={lineItems} Module='history' columns={adUnitListColoumns} paginationSelection={paginationChange} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize} />
                            }
                        </Row>
                    </Col>
                </Container>
            </div>
            <Offcanvas isOpen={isRight} direction='end' >
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {'View Associated Buyers'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <AssociateBuyers closeCanvas={toggleRightCanvas} selectedAdUnits={selectedAdUnit} buyerInfo={buyersData} />
                </OffcanvasBody>
            </Offcanvas>
            <Link ref={nextRef} to={'#'} ></Link>
        </React.Fragment >
    )
}

BuyerAdUnits_old.propTypes = {}

export default BuyerAdUnits_old