/* ADVERTISER */
export const LOAD_ADVERTISERS = "LOAD_ADVERTISERS"
export const NO_DATA = "NO_DATA"
export const GET_ADVERTISERS = "GET_ADVERTISERS"
export const GET_ADVERTISERS_SUCCESS = "GET_ADVERTISERS_SUCCESS"
export const GET_ADVERTISERS_FAIL = "GET_ADVERTISERS_FAIL"

export const ADD_ADVERTISER = "ADD_ADVERTISER"
export const ADD_ADVERTISER_SUCCESS = "ADD_ADVERTISER_SUCCESS"
export const ADD_ADVERTISER_ERROR = "ADD_ADVERTISER_ERROR"

export const UPDATE_ADVERTISER = "UPDATE_ADVERTISER"
export const UPDATE_ADVERTISER_SUCCESS = "UPDATE_ADVERTISER_SUCCESS"
export const UPDATE_ADVERTISER_ERROR = "UPDATE_ADVERTISER_ERROR"

export const READ_ADVERTISER = "READ_ADVERTISER"
export const READ_ADVERTISER_SUCCESS = "READ_ADVERTISER_SUCCESS"

export const STATUS_ADVERTISER = "STATUS_ADVERTISER"
export const STATUS_ADVERTISER_SUCCESS = "STATUS_ADVERTISER_SUCCESS"

export const ARCHIVE_ADVERTISER = "ARCHIVE_ADVERTISER"
export const ARCHIVE_ADVERTISER_SUCCESS = "ARCHIVE_ADVERTISER_SUCCESS"
