/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Row, Col, Input, Label, Form, FormFeedback, DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown, Spinner
} from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
//redux
import { useSelector, useDispatch } from "react-redux";
// actions
import {
  addNewIOGroup as onADDIOGroup,
  updateIOGroup as onUpdateIOGroup,
  addIOGroupSuccess,
  addIOGroupFail,
  updateIOGroupSuccess,
  updateIOGroupFail,
} from "store/Demand/IOGroup/actions";
import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";
import {
  getAdvertisers as onGetAdvertisers,
} from "store/Demand/Advertiser/actions";
import Hierarchy_Selection from "Product_Helpers/Hierarchy_Selection";

const Create_IO_Group = props => {
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const [ioGroup, setIOGroup] = useState();
  const [advertiserData, setAdvertiserData] = useState([])
  const nextRef = useRef();
  const [nextTab, setNextTab] = useState(false);
  const [btnprimary1, setBtnprimary1] = useState(false)
  const [ioSType, setIOSType] = useState('');
  const [loader, setLoader] = useState(false);
  console.log(props)
  var advertiserID = ''

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }


  useEffect(() => {
    if (props.selectedIOGroup !== '') {
      setIsEdit(true)
      setIOGroup(props.selectedIOGroup)
      getParentIOG()
    }
    if (props.selectedAdvertiser !== '' && props.selectedIOGroup === '') {
      setIOGroup({ advertiserID: props.selectedAdvertiser.advertiserId })
      setSelectedAdvertiser({ value: props.selectedAdvertiser.advertiserId, label: props.selectedAdvertiser.advertiserName });
    }
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      ioGroupName: (ioGroup && ioGroup.ioGroupName) || '',
      advertiserID: (selectedAdvertiser ? selectedAdvertiser.value : '') || '',
    },
    validationSchema: Yup.object({
      ioGroupName: Yup.string().required("IOGroup Name is required"),
      advertiserID: Yup.string().required("Advertiser is Required"),
    }),
    onSubmit: (values) => {
      setLoader(true)
      console.log(values)
      if (props.selectedIOGroup !== '') {
        dispatch(onUpdateIOGroup({ ...values, ioGroupID: props.selectedIOGroup.ioGroupID, ioGroupName: values.ioGroupName.trim(),parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner ,userRole:userInfo.userRole}));
      } else {
        console.log(values, advertiserID)
        dispatch(onADDIOGroup({ ...values, ioGroupName: values.ioGroupName.trim(),parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner ,userRole:userInfo.userRole}));
      }
    }
  });

  const { advertisers } = useSelector(state => ({
    advertisers: state.advertisers.advs,
  }));

  const getParentIOG = () => {
    var filterBody = {
      "advertiserID": 0,
      "ioGroupID": 0,
      "ioID": 0,
      "lineItemID": 0,
      "creativeID": 0,
      "videoCreativeID": 0,
      userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name: userInfo.name,
    }
    if (props.selectedIOGroup !== '') {
      filterBody.ioGroupID = props.selectedIOGroup.ioGroupID
    }
    axios.post(userInfo.userRole === 'SupplyPartner' ?"/v2.0/demandSupplyPartner/getParent/SupplyPartner" : "/v2.0/demand/getParent", filterBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("cdsresult is ==>", res)
        if (res.data) {
          setSelectedAdvertiser({ label: res.data.response.advertiserName, value: res.data.response.advertiserID });
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  console.log(advertisers)

  useEffect(() => {
    if (advertisers !== null) {
      dispatch(onGetAdvertisers({ filter: 'Active', type: '', userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, pageSize: 0, pageNumber: 0, searchText: '' }));
    }
  }, []);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.advertiserID
      obj.label = item.advertiserName
      dataList.push(obj)
    })
    return dataList
  }

  useEffect(() => {
    if (advertisers && advertisers.length > 0) {
      setAdvertiserData(objectModal(advertisers))
      console.log(advertisers)
    }
  }, [advertisers]);

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.ioGroups.errorMsg,
    successMsg: state.ioGroups.successMsg,
  }));

  console.log(errorMsg, successMsg)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.statusCode === 200) {
        dispatch(addIOGroupSuccess(""));
        dispatch(addIOGroupFail(""));
        dispatch(updateIOGroupSuccess(""));
        dispatch(updateIOGroupFail(""));
        setLoader(false)
        if (nextTab) {
          props.closeCanvas('')
          nextRef.current.click()
        } else {
          if (props.selectedIOGroup !== '') {
            props.closeCanvas('Update')
          } else {
            props.closeCanvas('Create')
          }
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if (errorMsg.status && errorMsg.status === 500) {
      } else {
        dispatch(addIOGroupFail(""));
        dispatch(updateIOGroupFail(""));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addIOGroupFail(""));
    dispatch(updateIOGroupFail(""));
    props.closeCanvas('')
  };


  function handleSelectAdvertiser(selectAdvertiser) {
    console.log(selectAdvertiser)
    setSelectedAdvertiser(selectAdvertiser);
  }

  function allowAlphaNumericSpace(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault(); }
  };

  const getSelectionData = async (selectedCreativeData) => {
    setSelectedAdvertiser(selectedCreativeData)
    setIOGroup(validation.values)
  }

  return (
    <React.Fragment>
      {loader &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
        <Form onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); return false; }}>
          <Row style={{ padding: '3%' }}>
            <Col xs={12}>
              <div className="mb-3">
                <Label>Advertiser</Label>
                <Select
                  value={selectedAdvertiser}
                  onChange={s => {
                    handleSelectAdvertiser(s)
                  }}
                  options={advertiserData}
                  invalid={
                    validation.touched.advertiserID && validation.errors.advertiserID ? true : false
                  }
                  className={(validation.touched.advertiserID && validation.errors.advertiserID ? ' is-invalid' : '')}
                  classNamePrefix="select"
                  isDisabled={props.selectedArchived || isEdit}
                />
                {validation.touched.advertiserID && validation.errors.advertiserID ? (
                  <FormFeedback type="invalid">{validation.errors.advertiserID}</FormFeedback>
                ) : null}

              </div>
              {/* <Hierarchy_Selection moduleType="IOGroup" selectedArchived={props.selectedArchived} inventoryType={''} selectedItems={getSelectionData} submitted={validation.touched.advertiserID && validation.errors.advertiserID ? true : false} selectedData={props.selectedIOGroup} createStatus={isEdit} /> */}
              <div className="mb-3">
                <Label className="form-label">IO Group Name <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
                <Input
                  name="ioGroupName"
                  type="text"
                  maxLength={65}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  onKeyPress={allowAlphaNumericSpace}
                  value={validation.values.ioGroupName || ""}
                  invalid={
                    validation.touched.ioGroupName && validation.errors.ioGroupName ? true : false
                  }
                  readOnly={props.selectedArchived}
                />
                {validation.touched.ioGroupName && validation.errors.ioGroupName ? (
                  <FormFeedback type="invalid">{validation.errors.ioGroupName}</FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
            <Col>
              <div className="text-start">
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
                &nbsp;&nbsp;
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }

                &nbsp;&nbsp;
                {!isEdit &&
                  <div className="btn-group">
                    <Dropdown
                      isOpen={btnprimary1}
                      toggle={() => setBtnprimary1(!btnprimary1)}
                    >
                      <DropdownToggle tag="button" type="button" className="btn btn-primary">
                        <i className="mdi mdi-plus-circle-outline me-1" />Save & Add Insertion Order &nbsp; <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        {/* <DropdownItem header>Display</DropdownItem> */}
                        <button type="submit" onClick={() => { setNextTab(true); setIOSType('stdb') }} style={{ border: 'none', background: 'none', margin: '0 15% 3%' }}>Standard</button>
                        <br />
                        <button type="submit" onClick={() => { setNextTab(true); setIOSType('highImpact') }} style={{ border: 'none', background: 'none', margin: '0 15% 3%' }}>High Impact</button>
                        {/* <DropdownItem divider />
                       <DropdownItem header>Video</DropdownItem> */}
                        <button type="submit" onClick={() => { setNextTab(true); setIOSType('video') }} style={{ border: 'none', background: 'none', margin: '0 15% 3%' }}>Video</button>
                        {/* <br />
                       <button type="submit" onClick={() => {setNextTab(true) ; setIOSType('video')}} style={{border:'none',background:'none',margin:'0 15% 3%'}} disabled>Vast</button> */}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                }
                &nbsp;&nbsp;
                {isEdit && !props.selectedArchived &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
                {nextTab && successMsg &&
                  <Link ref={nextRef} to={`/Insertion_Order?ioGroupID=${successMsg.response.ioGroupID}&ioGroupName=${successMsg.response.ioGroupName}&iOType=${ioSType}&status=IOCreateByIOGroup`}  > </Link>
                }
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
          <Toaster status="error" msg={errorMsg.message} />
        }
      </div>
      {errorMsg !== null && errorMsg !== '' && errorMsg.status && errorMsg.status === 500 &&
        <div className="p-0 justify-content-center d-flex">
          <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')} />
        </div>
      }
    </React.Fragment>
  );
};

export default withRouter(Create_IO_Group);

Create_IO_Group.propTypes = {
  history: PropTypes.object,
};