import {
  LOAD_ACTIVITIES,
  NO_DATA_BILLING,
    GET_BILL_HISTORY,
    GET_BILL_HISTORY_FAIL,
    GET_BILL_HISTORY_SUCCESS,

    GET_BILL_HISTORY_LIST,
    GET_BILL_HISTORY_SUCCESS_LIST,
    GET_BILL_HISTORY_FAIL_LIST

  } from "./actionTypes"

  export const loadActivitiesBills = Params => ({
    type: LOAD_ACTIVITIES,
    payload:Params
  })

  export const noDataBills = Params => ({
    type: NO_DATA_BILLING,
    payload:Params
  })

  export const getBillHistory = Params => ({
    type: GET_BILL_HISTORY,
    payload:Params
  })
  
  export const getBillHistorySuccess = Partners => ({
    type: GET_BILL_HISTORY_SUCCESS,
    payload: Partners,
  })

  export const getBillHistoryFail = error => ({
    type: GET_BILL_HISTORY_FAIL,
    payload: error,
  })

  export const getBillHistoryList = Params => ({
    type: GET_BILL_HISTORY_LIST,
    payload:Params
  })
  
  export const getBillHistoryListSuccess = Partners => ({
    type: GET_BILL_HISTORY_SUCCESS_LIST,
    payload: Partners,
  })

  export const getBillHistoryListFail = error => ({
    type: GET_BILL_HISTORY_FAIL_LIST,
    payload: error,
  })
