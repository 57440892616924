/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect } from "react"
import {
  Card, CardBody, Col, Form, Input, Label, Row, Spinner, Button, CardTitle, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledDropdown, Offcanvas, OffcanvasBody, OffcanvasHeader
} from "reactstrap"

import Edit_Playlist from "./Edit_playlist";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';
import moment from "moment";
import VideoPlayer from "../Video_Content_Library/VideoPlayer";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//react-tooltip 
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { getPlaylistById as onGetPlaylistById } from "store/actions";
import { useDispatch, useSelector } from "react-redux";

var videoSelected = []
var selectedVideo = ''


const Playlist_View = props => {
  const dispatch = useDispatch();
  const [editPlaylistCanvas, setEditPlaylistCanvas] = useState(false)
  const [isVideoRight, setIsVideoRight] = useState(false);
  const [loader, setLoader] = useState(false)
  const { activePlaylistId } = props
  console.log(activePlaylistId)
  const [hoverStateID, setHoverStateID] = useState('');
  const [checkedAll, setCheckedAll] = useState(false);
  const [videosData, setVideosData] = useState([])
  const [selected, setSelected] = useState([])
  const [activeState, setActiveState] = useState('grid')
  const userInfo = JSON.parse(localStorage.getItem('authUser'));

  useEffect(() => {
    dispatch(onGetPlaylistById({ playlistID: activePlaylistId,isSupplyPartner:userInfo.isSupplyPartner }))
  }, [])

  const { playlistData } = useSelector(state => ({
    playlistData: state.playlistsReducer.eachPlaylistData
  }))

  console.log(playlistData)




  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      selectedVideo = ''
      setEditPlaylistCanvas(false)
      setIsVideoRight(false)
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);



  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 5000,
    extendedTimeOut: 1000
  };


  const listView = () => {
    setActiveState('list')
  }

  const gridView = () => {
    setActiveState('grid')
  }

  const videoListCoulumns = [
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "videoContentID",
      sort: true
    },
    {
      dataField: "videoContentMp4URL",
      isDummyField: true,
      editable: false,
      text: "video",
      align: 'left',
      formatter: (cellContent, eachRow) => (

        <img src={eachRow.videoContentThumbnailURL} alt="thumbnail" style={{ height: "3.5rem", width: "6 rem" }} />
        // <video style={{ height: "3.5rem", width: "6 rem" }}
        //   src={"https://"+eachRow.VideoContentMp4URL} crossOrigin="true" >
        // </video>
      )
    },
    {
      text: 'Name',
      dataField: 'videoContentName',
      formatter: (cellContent, eachRow) => {
        if (cellContent.length > 50) {
          return cellContent.substring(0, 50) + '...';
        }
        return cellContent;
      },
    },
    {
      text: 'Category',
      dataField: 'videoContentCategory'
    },
    {
      text: 'Language',
      dataField: 'videoContentLanguage'
    },
    {
      text: 'Duration',
      dataField: 'videoContentDuration',
      formatter: (cellContent, eachRow) => (
        <span className="mb-2">{moment.unix(eachRow.videoContentDuration).utc().format(' m [min] s [secs]')} </span>
      )
    },
    // {
    //   text: "Action",
    //   dataField: '',
    //   formatter: (cellContent, eachRow) => (
    //     <div className="d-flex">
    //       <div>
    //         <Button className="m-3" id={eachRow.videoContentThumbnailURL} onClick={() => copyThumbnail(eachRow.videoContentThumbnailURL)}>thumbnail</Button>
    //         <ReactTooltip
    //           anchorId={eachRow.videoContentThumbnailURL}
    //           place='top'
    //           variant='info'
    //           content='Copied thumbnail url'
    //           openOnClick="true"
    //           delayHide={800}
    //         />
    //       </div>
    //       <div>
    //         <Button className="m-3" id={eachRow.videoContentCompressedMp4URL} onClick={() => copyThumbnail(eachRow.videoContentCompressedMp4URL)}>Video</Button>
    //         <ReactTooltip
    //           anchorId={eachRow.videoContentCompressedMp4URL}
    //           place='top'
    //           variant='info'
    //           content='Copied Video url'
    //           openOnClick="true"
    //           delayHide={800}
    //         />
    //       </div>
    //     </div>
    //   ),
    //   events: {
    //     onClick: (e, column, columnIndex, row, rowIndex) => {
    //       e.stopPropagation()
    //       console.log("cliked")
    //     }
    //   }
    // }
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, eachRow) => (
        <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>

          <span>
            <UncontrolledDropdown
              className="dropRight  active"
            >
              <DropdownToggle
                tag="span" className="dropdown-toggle"
              >
                <i className="bx bx-copy-alt font-size-18" />
              </DropdownToggle>
              <DropdownMenu data-popper-placement="left-start">
                <DropdownItem onClick={() => copyThumbnail(eachRow.videoContentThumbnailURL)}>     Thumbnail &nbsp;
                  <i className="mdi mdi-plus-circle-outline me-1" /></DropdownItem>
                <DropdownItem onClick={() => copyThumbnail(eachRow.videoContentM3u8URL)}>     M3u8 url &nbsp;
                  <i className="mdi mdi-plus-circle-outline me-1" /></DropdownItem>
                <DropdownItem onClick={() => copyThumbnail(eachRow.videoContentMp4URL)}>     HQ url &nbsp;
                  <i className="mdi mdi-plus-circle-outline me-1" /></DropdownItem>
                <DropdownItem onClick={() => copyThumbnail(eachRow.videoContentCompressedMp4URL)}>     Compressed url &nbsp;
                  <i className="mdi mdi-plus-circle-outline me-1" /></DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </span>

          {/* <span onClick={() => { tog_standard(adUnit); }} style={{ cursor: 'pointer' }}>
            <i
              className="bx bx-copy-alt font-size-18"
              id="edittooltip"
            ></i>
          </span> */}
        </div>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation()
          console.log("cliked")
        }
      }
    },
    {
      text: "Status",
      dataField: '',
      formatter: (cellContent, eachRow) => (
        <>
          {eachRow.isUploaded === "Upload is success" &&
            <span className="active-state">Ready</span>
          }
          {eachRow.isUploaded === "Upload is not yet start" &&
            <span className="support-ticket-urgent">Pending</span>
          }
          {eachRow.isUploaded === "Upload is not success" &&
            <span className="support-ticket-high">Error</span>
          }
        </>
      )
    }
  ]
  // const selectRow = {
  //   mode: "checkbox",
  //   nonSelectable: [],
  //   selected: selected,
  //   onSelect: (row, isSelect, rowIndex, e) => {


  //   },
  //   onSelectAll: (isSelect, rows, e) => {
  //   }
  // };

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  const keyField = "videoContentID";

  const copyThumbnail = (value) => {
    console.log("this is video content alert============", value)
    window.navigator.clipboard.writeText(value);
  }

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );

  const pageOptions = {
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    totalSize: playlistData.length !== 0 && playlistData.playListVideoData.length,
    custom: true,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };

  const rowEvents = {
    onClick: (e, row) => {
      console.log(row)
      selectedVideo = row
      setIsVideoRight(!isVideoRight)
    }
  }




  console.log(playlistData)
  const editPlaylist = () => {
    setEditPlaylistCanvas(!editPlaylistCanvas)
  }

  const toggleEditPlaylistCanvas = () => {
    setEditPlaylistCanvas(!editPlaylistCanvas)

  }

  const toggleDrawer = () => {
    setEditPlaylistCanvas(false)
    dispatch(onGetPlaylistById({ playlistID: activePlaylistId ,isSupplyPartner:userInfo.isSupplyPartner}))
    // toastr.success('Video Playlist Updated Successfully!...')
  }

  const toggleRightVideoCanvas = (videoData) => (e) => {
    console.log(videoData)
    selectedVideo = videoData
    setIsVideoRight(!isVideoRight)
    console.log('called toggleRightVideoCanvas')
  }


  return (
    <>
      <Col md="12">
        {loader &&
          <Spinner className="ms-2 loader" color="primary" />
        }
        <Form method="post">
          {/* <Form action="https://support-ticket.bidsxchange.com/create" encType="multipart/form-data" method="post"> */}
          <div className="formBodyScroll" style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
            <Row>
              <Col sm="12">

                <div className="text-end d-flex justify-content-end" >
                  {playlistData && !playlistData.isCDNLink && 
                    <div>
                      <i className='bx bx-sm bxs-edit m-1' onClick={editPlaylist} style={{ cursor: "pointer" }}></i>
                    </div>
                  }
                  {playlistData && !playlistData.isCDNLink &&

                    <div style={{ backgroundColor: `${activeState === 'list' ? "#dedede" : "#ffffff"}` }}>
                      <i className='bx bx-sm bx-list-ul m-1' onClick={listView} style={{ cursor: "pointer" }}></i>
                    </div>
                  }
                  <div style={{ backgroundColor: `${activeState === 'grid' ? "#dedede" : "#ffffff"}` }}>
                    <i className='bx bx-sm bx-grid-alt m-1' onClick={gridView} style={{ cursor: "pointer" }}></i>
                  </div>
                </div>
              </Col>
            </Row>
            {activeState === 'grid' &&
              <Row>
                {playlistData.length !== 0 &&
                  playlistData.playListVideoData.map((item, index) => {
                    return (
                      <Col sm={4} xxl={3} key={index} >
                        {/* onMouseOver={handleEditState(item.videoContentURL)} onMouseLeave={handleEditState('')} style={{ backgroundColor: (item.checked || hoverStateID === item.url) ? '#556ee61f' : '', cursor: 'pointer', boxShadow: (hoverStateID && hoverStateID === item.url) ? 'rgb(149 157 165 / 20%) 0px 8px 24px' : 'none' }} */}
                        <Card style={{ backgroundColor: '', cursor: 'pointer', boxShadow: 'none' }}
                        >
                          <div>
                            {/* {(item.checked || hoverStateID === item.videoContentURL) && */}
                            {/* <div><div className="form-check mt-2 mb-2" style={{ position: 'absolute', left: '1pc', top: '0.5pc', zIndex: '200' }}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={item.videoContentURL} style={{ width: '1.5pc', height: '1.5pc' }}
                                checked={item.checked}
                              // onChange={selectVideo(item.id, index)}
                              />
                            </div> */}

                            <UncontrolledDropdown
                              className="dropleft  active" style={{ position: 'absolute', right: '0pc', top: '2.5pc', zIndex: '200', fontSize: '1.5rem' }}
                            >
                              <DropdownToggle
                                tag="span" className="dropdown-toggle"
                              >
                                <i className="bx bx-dots-vertical-rounded" />
                              </DropdownToggle>
                              <DropdownMenu data-popper-placement="left-start">
                                <DropdownItem onClick={() => copyThumbnail(item.videoContentThumbnailURL)}>     Thumbnail &nbsp;
                                  <i className="mdi mdi-plus-circle-outline me-1" /></DropdownItem>
                                <DropdownItem onClick={() => copyThumbnail(item.videoContentURL)}>     M3u8 url &nbsp;
                                  <i className="mdi mdi-plus-circle-outline me-1" /></DropdownItem>
                                <DropdownItem onClick={() => copyThumbnail(item.videoContentMp4URL)}>     HQ url &nbsp;
                                  <i className="mdi mdi-plus-circle-outline me-1" /></DropdownItem>
                                <DropdownItem onClick={() => copyThumbnail(item.videoContentCompressedMp4URL)}>     Compressed url &nbsp;
                                  <i className="mdi mdi-plus-circle-outline me-1" /></DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>

                          <CardBody className="row justify-content-center " style={{ cursor: 'pointer' }} onClick={toggleRightVideoCanvas(item)}>
                            <i className="bx bx-play-circle text-center" style={{ position: 'absolute', fontSize: '2rem', color: 'white', zIndex: '200', top: '5vw' }} />
                            {/* <video type="video/mp4" style={{ padding: '12px', borderRadius: '1.2rem', width: '100%' }}
                              src={item.videoContentURL}   >
                            </video> */}
                            <img className="img-container" src={playlistData.isCDNLink ? item.thumbnailURL : item.videoContentThumbnailURL} alt="thumbnail" style={{ padding: '12px', borderRadius: '1.2rem', width: '100%' }} />
                            {/* <video type="video/mp4" style={{padding:'12px',borderRadius:'1.2rem'}}
                            src={item.url}   onMouseOver={event => event.target.play()}   onMouseOut={event => event.target.pause()} >
                          </video> */}

                            {/* <h5 className="text-center m-3">{item.videoContentName}</h5> */}


                            {!item.isCDNLink ?
                              <h5 className="text-center m-3">{item.videoContentName}</h5>
                              :
                              <h5 className="text-center m-3">{item.videoName}</h5>
                            }

                            {/* {!item.isCDNLink ?
                              <h5 className="text-center m-3">
                                {item.videoContentName
                                  .replace(/TE/g, '')
                                  .replace(/EN/g, '')
                                  .replace(/BE/g, '')
                                  .replace(/VI/g, '')
                                  .replace(/KA/g, '')
                                  .replace(/TA/g, '')
                                  .replace(/en/g, '')
                                  .replace(/HI/g, '')
                                  .replace(/TA/g, '')
                                  .replace(/KA/g, '')
                                  .replace(/-/g, ' ')
                                  .length > 50
                                  ? item.videoContentName.replace(/TE/g, '').replace(/EN/g, '').replace(/-/g, ' ').substring(0, 50) + '...'
                                  : item.videoContentName.replace(/TE/g, '').replace(/EN/g, '').replace(/-/g, ' ')}
                              </h5>
                              :
                              <h5 className="text-center m-3">
                                {item.videoName
                                  .replace(/TE/g, '')
                                  .replace(/EN/g, '')
                                  .replace(/BE/g, '')
                                  .replace(/VI/g, '')
                                  .replace(/KA/g, '')
                                  .replace(/TA/g, '')
                                  .replace(/en/g, '')
                                  .replace(/HI/g, '')
                                  .replace(/TA/g, '')
                                  .replace(/KA/g, '')
                                  .replace(/-/g, ' ')
                                  .length > 50
                                  ? item.videoName.replace(/TE/g, '').replace(/EN/g, '').replace(/-/g, ' ').substring(0, 50) + '...'
                                  : item.videoName.replace(/TE/g, '').replace(/EN/g, '').replace(/-/g, ' ')}
                              </h5>


                            } */}

                            <h6 style={{ fontWeight: 600 }} className="text-center">Category :  <span style={{ fontWeight: 400 }}>{item.videoContentCategory} </span> &nbsp;&nbsp; |  &nbsp;&nbsp; Language :  <span style={{ fontWeight: 400 }}>{item.videoContentLanguage}</span> </h6>

                            {/* <Row className=" row mt-3">
                              <Col md={6}>
                                <Button
                                  color="primary"
                                  className="font-18 w-100 btn btn-primary"
                                  onClick={() => copyThumbnail(item.videoContentThumbnailURL)}
                                >
                                  Thumbnail &nbsp;
                                  <i className="mdi mdi-plus-circle-outline me-1" />
                                </Button>
                              </Col >
                              <Col md={6}>
                                <Button
                                  color="primary"
                                  className="font-18 w-100 btn btn-primary"
                                  onClick={() => copyThumbnail(item.url)}
                                >
                                  Video &nbsp;
                                  <i className="mdi mdi-plus-circle-outline me-1" />
                                </Button>
                              </Col>
                            </Row> */}
                          </CardBody>
                        </Card>
                      </Col>
                    )
                  })
                }
              </Row>
            }
            {activeState === 'list' &&
              <Row>
                <Col lg="12">

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField={keyField}
                    columns={videoListCoulumns}
                    data={playlistData.playListVideoData}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField={keyField}
                          data={playlistData.playListVideoData}
                          columns={videoListCoulumns}
                          // bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              {/* {activeState === 'list' &&
                              // <Row className="mb-2">
                              //   <Col sm="8">
                              //     <div className="search-box ms-2 mb-2 d-inline-block">
                              //       <div className="position-relative">
                              //         <SearchBar {...toolkitProps.searchProps} />
                              //         <i className="bx bx-search-alt search-icon" />
                              //       </div>
                              //     </div>
                              //   </Col>
                              // </Row>
                            } */}
                              <Row>
                                <Col xl="12">

                                </Col>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={keyField}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      // selectRow={selectRow}
                                      rowEvents={rowEvents}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      noDataIndication={<h5 className="text-center">No Data</h5>}
                                    // ref={node}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              {/* <Row className="align-items-md-center mt-30">
                     <Col className="pagination pagination-rounded justify-content-end mb-2">
                       <PaginationListStandalone
                         {...paginationProps}
                       />
                     </Col>
                   </Row> */}
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <PaginationTotalStandalone
                                      {...paginationProps}
                                    />
                                  </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                  <div className="text-md-center d-inline " style={{ marginLeft: '5%' }} >
                                    <span>Show Rows : </span> &nbsp;&nbsp;
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      );
                    }}
                  </PaginationProvider>
                </Col>
              </Row>
            }
          </div>
        </Form>
      </Col>
      <Offcanvas isOpen={editPlaylistCanvas} direction='end'  >
        <OffcanvasHeader toggle={toggleEditPlaylistCanvas}>
          {'Edit Playlist'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Edit_Playlist closeCanvas={toggleDrawer} playlistData={playlistData} />
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas isOpen={isVideoRight} direction='end' className="videoPlayer">
        <OffcanvasHeader toggle={toggleRightVideoCanvas('')} style={{ padding: '0.5rem 1rem' }}>
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h5 className="m-0"> Video Details </h5>
              </Col>
            </Row>
          </div>
        </OffcanvasHeader>
        <OffcanvasBody style={{ padding: '1rem 0.2rem' }}>
          <VideoPlayer selectedVideo={selectedVideo} isCDNLink={playlistData.isCDNLink} />
        </OffcanvasBody>
      </Offcanvas>
    </>
  )
}

Playlist_View.propTypes = {}

export default Playlist_View