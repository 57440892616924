import React, { useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  BreadcrumbItem
} from "reactstrap";

import classnames from "classnames";
import User_Management from "./User_Management";
import Roles_Management from "./Roles_Management";
import AdminSidebar from "../Admin_Sidebar/AdminSidebar";

const Access_Management = (props) => {

  const [customActiveTab, setcustomActiveTab] = useState("1");

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  // var bodyDoc = document.body;
  // if (window.screen.width <= 998) {
  //   bodyDoc.classList.add("sidebar-enable");
  // } else {
  //   bodyDoc.classList.add("vertical-collpsed");
  //   bodyDoc.classList.add("sidebar-enable");
  // }

  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />

      <div className="main-content access-management"> */}
      {/* <AdminSidebar />
      <div className="main-content access-management"> */}

      <div className="page-content">
        <Helmet>
          <title>Access Management | Bidsxchange</title>
        </Helmet>
        <Container fluid={true}>
          <Row>
            <Col md="3">
              <Breadcrumbs title="Admin" breadcrumbItem="Access Management" />
            </Col>
            <Col md="5">
              <Nav tabs className="nav-tabs-custom nav-justified" style={{ width: '20pc', position: 'absolute', zIndex: '10' }}>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleCustom("1");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i>
                    </span>
                    <span className="d-none d-sm-block" style={{ fontSize: '16px' }}>Users</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleCustom("2");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-user"></i>
                    </span>
                    <span className="d-none d-sm-block" style={{ fontSize: '16px' }}>Roles</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="4">
            </Col>

            {/* <ol className="breadcrumb m-0 mb-2 p-0">
            <div className='d-flex'>
                <BreadcrumbItem>
                  <Link to="/Dashboard" className="color-dark-blue signUp">Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                <Link to="/Admin" className="color-dark-blue signUp">Admin Settings</Link>
                </BreadcrumbItem>
              </div>
            </ol> */}

          </Row>
          <TabContent
            activeTab={customActiveTab}
            className=" text-muted"
            style={{marginTop:'-4pc'}}
          >
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <User_Management activeId={customActiveTab} />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <Roles_Management activeId={customActiveTab} />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Container>
      </div>
      {/* </div> */}
      {/* </div> */}
    </React.Fragment >
  )
}

export default Access_Management