// VIDEO CONTENT
export const LOAD_PUB_VIDEO_CONTENTS = "LOAD_PUB_VIDEO_CONTENTS"
export const GET_PUB_VIDEO_CONTENTS = "GET_PUB_VIDEO_CONTENTS"
export const GET_PUB_VIDEO_CONTENTS_SUCCESS = "GET_PUB_VIDEO_CONTENTS_SUCCESS"
export const GET_PUB_VIDEO_CONTENTS_FAIL = "GET_PUB_VIDEO_CONTENTS_FAIL"

export const ADD_PUB_VIDEO_CONTENT = "ADD_PUB_VIDEO_CONTENT"
export const ADD_PUB_VIDEO_CONTENT_SUCCESS = "ADD_PUB_VIDEO_CONTENT_SUCCESS"
export const ADD_PUB_VIDEO_CONTENT_ERROR = "ADD_PUB_VIDEO_CONTENT_ERROR"


export const DELETE_PUB_VIDEO_CONTENT = "DELETE_PUB_VIDEO_CONTENT"
export const DELETE_PUB_VIDEO_CONTENT_SUCCESS = "DELETE_PUB_VIDEO_CONTENT_SUCCESS"
export const DELETE_PUB_VIDEO_CONTENT_ERROR = "DELETE_PUB_VIDEO_CONTENT_ERROR"

