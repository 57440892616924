import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { Col, Container, Row, Input, Label, Form, Button } from "reactstrap"

const Summerize = props => {
    const [adTagContent, setAdTagContent] = useState('')
    const [urlLink, setTCName] = useState('')
    const [sentences, setUrlLink] = useState(5)
    const [summerisedText,setSummerisedText]= useState('')

    const handleChange = (e) => {
        setAdTagContent(e.target.value);
    }

    const handleSubmit = async (e) => {
        getJSON().then((json) => {
            console.log(json);
            setSummerisedText(json.summary)
          });
    }

    const getJSON = async (e) => {
        const formdata = new FormData();
        formdata.append("key", "87e595fe8d98e4957a1c4c8a892a4915");
        if(adTagContent){
            formdata.append("txt", adTagContent);
        }
        if(urlLink){
            formdata.append("url", urlLink);
        }
        formdata.append("sentences", sentences);

        const requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

       let response = await  fetch("https://api.meaningcloud.com/summarization-1.0", requestOptions);
       var json = await response.json();
       return json
            // .then(response => (
            //     {
            //     status: response.status,
            //     body: response.json()
            // }))
            // .then(({ status, body }) =>
            //  console.log(status, body),
            //  console.log(JSON.stringify(body)),
            //  console.log(JSON.stringify(body))
            //  )
            // .catch(error => console.log('error', error));

    }

    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
                <div className="page-content">
                    <Helmet>
                        <title>Summerization | Bidsxchange</title>
                    </Helmet>
                    <Container fluid={true}>
                        <Breadcrumbs title="Tools" breadcrumbItem="Text Summarization" />
                        <div className="card card-body ">
                            <div className="row">
                                <div className="mb-3">
                                    <Label className="form-label">Summerise Text</Label>
                                <textarea id="input" name="adTagContent" value={adTagContent} onChange={handleChange} className="form-control" placeholder="Either type or paste your web browser code here" style={{ resize: 'none', display: 'block', height: '271.8px' }}></textarea>
                                </div>
                                <h5>Or</h5>
                                <div className="mb-3">
                                <Label className="form-label">Summerise Link</Label>
                                <Input
                                name="tcname"
                                type="text"
                                value={urlLink}
                                onChange={(e) => setTCName(e.target.value)}
                                />
                                </div>
                                <div className="mb-3">
                                <Label className="form-label">Number of Sentences</Label>
                                <Input
                                name="tcname"
                                type="number"
                                value={sentences}
                                onChange={(e) => setUrlLink(e.target.value)}
                                />
                                </div>
                            </div>
                            <Row className="drawer-footer" >
                                <Col>
                                    <div className="text-start">
                               
                                            <button
                                                type="submit"
                                                className="btn btn-primary save-user"
                                                onClick={handleSubmit}
                                            >
                                                Summerise
                                            </button>
                                        
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="card card-body ">
                            <div className="row">
                                <div className="mb-3">
                                    <Label className="form-label">Summerised Text</Label>
                                <textarea id="input" name="summerisedText" value={summerisedText}  className="form-control" placeholder="Either type or paste your web browser code here" style={{ resize: 'none', display: 'block', height: '271.8px' }}></textarea>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            {/* </div > */}
        </React.Fragment >
    )
}

Summerize.propTypes = {}

export default Summerize