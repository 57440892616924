/* eslint-disable react/prop-types */
import React, { useState,useEffect,useId} from "react";
import { CardBody,Col,Form,Card,Label,Input,Button,CardTitle ,Row,Container} from "reactstrap";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

// Form Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";




const New_Support_Ticket = (props) => {
    const {toggleCreateSupportTickets} = props
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [subject,setSubject] = useState("");
    const [userMsg,setUserMsg] = useState("");
    const [supportTicket,setSupportTicket] = useState([])
    const [userSelectedFile, setUserSelectedFile] = useState(null)
    const [userDropFile,setUserDropFile] = useState(null)
    const msg =  'Support Ticket Successfully Created'

    const clearAll = () => {
        setSubject("")
        setUserMsg("")
        console.log("called")
    }

    /*toastr.options = {
        positionClass: "toast-top-center",
        closeButton: false,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 500,
        hideDuration: 1000,
        timeOut: 2000,
        extendedTimeOut: 1000
    };
    */

    const addNewTicket  = (e) => {
        e.preventDefault()
        setSupportTicket([...supportTicket,{
            "id": Math.floor((Math.random() * 100) + 1),
            "subject": subject,
            "user": userInfo.name,
            "createdAt": new Date().toLocaleString(),
            "lastReply": "__",
            "status": true,
            "chat":[{
                "userMsg": userMsg,
                "CreatedAt": new Date().toLocaleString(),
                "user": userInfo.name,
                "userFile": "https://pixabay.com/photos/fence-chain-link-bokeh-lights-1869401/",
            }]
        }])
        clearAll()
        toggleCreateSupportTickets()
        toastr.success('Support Tickets Saved Successfully!...')

        
    }
    useEffect(()=>{
        const prevChat = JSON.parse(localStorage.getItem('chats'));
        if(prevChat){
            setSupportTicket(prevChat)
        }
    },[])
    
    useEffect(()=>{
        localStorage.setItem("chats",JSON.stringify(supportTicket));
    },[supportTicket])

    const handleFileChange = (event) =>{
        event.preventDefault()
        setUserSelectedFile(event.target.files[0])
        console.log(userSelectedFile)
    }
    return(
        <Col md="10">
            <Form onSubmit={(e)=>addNewTicket(e)}>
                <Col sm="12">
                    <Card className="p-3" >
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <div className="mb-3 m-3">
                                        <Label className="visually-hidden">Subject</Label>
                                        <Input type="text" name='subject'
                                            placeholder="SUBJECT"
                                            className={"form-control"}
                                            onChange={(e)=> setSubject(e.target.value)}
                                            value={subject}
                                            required>
                                            
                                        </Input>
                                    </div>
                                    <div className="page-content">
                                        <Container fluid={true}>
                                            <Breadcrumbs title="Forms" breadcrumbItem="Form Editors" />
                                                <Row>
                                                    <Col>
                                                    <Card>
                                                        <CardBody>
                                                        <CardTitle className="h4">react-draft-wysiwyg</CardTitle>
                                                        <p className="card-title-desc">
                                                            Bootstrap-wysihtml5 is a javascript plugin that makes it
                                                            easy to create simple, beautiful wysiwyg editors with the
                                                            help of wysihtml5 and Twitter Bootstrap.
                                                        </p>

                                                        <Form method="post">
                                                            <Editor
                                                            toolbarClassName="toolbarClassName"
                                                            wrapperClassName="wrapperClassName"
                                                            editorClassName="editorClassName"
                                                            />
                                                        </Form>
                                                        </CardBody>
                                                    </Card>
                                                    </Col>
                                                </Row>		
                                            </Container>
                                        </div>
                                    {/*<div className="mb-3 m-3">
                                        <Label className="visually-hidden">Message</Label>
                                        <Input type="textarea" name='message'
                                            placeholder="YOUR MESSAGE"
                                            className={"form-control"}
                                            rows={10}
                                            cols={100}
                                            onChange={(e)=> setUserMsg(e.target.value)}
                                            value={userMsg}
                                            required>
                                            
                                            
                                        </Input>
                                    </div>
                                    */}
                                    <div className="m-3">
                                        <Button type="submit"
                                            color="primary"
                                        >
                                            Create Ticket
                                        </Button>
                                    </div>
                                </div>
                                <div >
                                    <div className="mb-3 m-3">
                                            <Label className="visually-hidden">Browse Files</Label>
                                            <Input type="file" name='file'
                                                placeholder="Browse Files"
                                                className={"form-control"}
                                                onChange={handleFileChange}
                                                >
                                            </Input>
                                    </div>
                                    <div className="mb-3 m-3">
                                        <Button  outline onClick={() => clearAll()}>
                                            Remove All
                                        </Button>
                                    </div>
                                </div>
                            </div>

                        </CardBody>
                    </Card>
                </Col>
            </Form>
        </Col>
    )
}

New_Support_Ticket.propTypes = {}

export default New_Support_Ticket