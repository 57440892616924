import {
  LOAD_ACTIVITIES,
  NO_DATA_HOUSE_CATEGORY,
    GET_CATEGORY,
    GET_CATEGORY_FAIL,
    GET_CATEGORY_SUCCESS,
    ADD_CATEGORY,
    ADD_CATEGORY_SUCCESS,
    ADD_CATEGORY_ERROR,
    UPDATE_CATEGORY,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_ERROR,
    READ_CATEGORY,
    READ_CATEGORY_SUCCESS,
    STATUS_CATEGORY,
    STATUS_CATEGORY_SUCCESS,
    ARCHIVE_CATEGORY ,
    ARCHIVE_CATEGORY_SUCCESS,
    GET_CATEGORY_TYPE,
    GET_CATEGORY_TYPE_FAIL,
    GET_CATEGORY_TYPE_SUCCESS,
    ADD_CATEGORY_TYPE,
    ADD_CATEGORY_TYPE_SUCCESS,
    ADD_CATEGORY_TYPE_ERROR,

  } from "./actionTypes"

  export const loadActivitiesCategory = Params => ({
    type: LOAD_ACTIVITIES,
    payload:Params
  })

  export const noCategory = Params => ({
    type: NO_DATA_HOUSE_CATEGORY,
    payload:Params
  })

  export const getCategory = Params => ({
    type: GET_CATEGORY,
    payload:Params
  })
  
  export const getCategorySuccess = Category => ({
    type: GET_CATEGORY_SUCCESS,
    payload: Category,
  })

  export const getCategoryFail = error => ({
    type: GET_CATEGORY_FAIL,
    payload: error,
  })

  export const addNewCategory = Category => ({
    type: ADD_CATEGORY,
    payload: Category,
  })
  
  export const addCategorySuccess = category => ({
    type: ADD_CATEGORY_SUCCESS,
    payload: category,
  })
  
  export const addCategoryFail = category => ({
    type: ADD_CATEGORY_ERROR,
    payload: category,
  })

  export const updateCategory = category => ({
    type: UPDATE_CATEGORY,
    payload: category,
  })
  
  export const updateCategorySuccess = category => ({
    type: UPDATE_CATEGORY_SUCCESS,
    payload: category,
  })
  
  export const updateCategoryFail = category => ({
    type: UPDATE_CATEGORY_ERROR,
    payload: category,
  })

  export const readCategory = category => ({
    type: READ_CATEGORY,
    payload: category,
  })

  export const readCategorySuccess = category => ({
    type: READ_CATEGORY_SUCCESS,
    payload: category,
  })

  export const statusCategory = category => ({
    type: STATUS_CATEGORY,
    payload: category,
  })

  export const statusCategorySuccess = category => ({
    type: STATUS_CATEGORY_SUCCESS,
    payload: category,
  })

  export const archiveCategory = category => ({
    type: ARCHIVE_CATEGORY,
    payload: category,
  })

  export const archiveCategorySuccess = category => ({
    type: ARCHIVE_CATEGORY_SUCCESS,
    payload: category,
  })


  export const getCategoryType = Params => ({
    type: GET_CATEGORY_TYPE,
    payload:Params
  })
  
  export const getCategoryTypeSuccess = Category => ({
    type: GET_CATEGORY_TYPE_SUCCESS,
    payload: Category,
  })

  export const getCategoryTypeFail = error => ({
    type: GET_CATEGORY_TYPE_FAIL,
    payload: error,
  })

  export const addNewCategoryType = Category => ({
    type: ADD_CATEGORY_TYPE,
    payload: Category,
  })
  
  export const addCategoryTypeSuccess = category => ({
    type: ADD_CATEGORY_TYPE_SUCCESS,
    payload: category,
  })
  
  export const addCategoryTypeFail = category => ({
    type: ADD_CATEGORY_TYPE_ERROR,
    payload: category,
  })