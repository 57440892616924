/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback, Spinner } from "reactstrap";
import CreatableSelect from 'react-select/creatable';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

import {
    getCategory as onGetCategories,
} from "store/customTracking/Categories/actions";

// actions
import {
    addNewHouseAdCreative as onADDCreative,
    updateHouseAdCreative as onUpdateCreative,
    readHouseAdCreative as onReadCreative,
    addHouseAdCreativeSuccess,
    addHouseAdCreativeFail,
    updateHouseAdCreativeSuccess,
    updateHouseAdCreativeFail,
} from "store/customTracking/Creatives/actions";

import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";

const NewCreatives = props => {
    const dispatch = useDispatch();
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [submitted, setSubmitted] = useState(false);
    const [loader, setLoader] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    console.log(props)

    const [finalData, setFinalData] = useState({
        houseAdsCreativeName: '',
        houseAdsCreativeType: "",
        houseAdsCreativeLandingPageUrl: '',
        houseAdsCreativeSize: '',
        houseAdsCategoryID: '',
        houseAdsCreative: '',
    })

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const { categories } = useSelector(state => ({
        categories: state.categories.ctgs,
    }));

    console.log(categories)

    useEffect(() => {
        if (props.selectedCreative !== '') {
            setIsEdit(true)
            setFinalData(props.selectedCreative);
        }
    }, [])


    useEffect(() => {
        if (categories !== null) {
            dispatch(onGetCategories({ filter: 'Active' }));
        }
    }, []);

    const fileChange = (file) => {
        console.log(file.target.files[0])
        console.log(file.target.files[0].name)
        // fileName = file.target.files[0].name
        encodeFileBase64(file.target.files[0])
    };

    const encodeFileBase64 = (file) => {
        var reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
                var base64 = reader.result
                if (base64.includes("data:image/png;base64,")) {
                    base64 = reader.result.replace("data:image/png;base64,", "");
                }
                else if (base64.includes("data:image/jpeg;base64,")) {
                    base64 = reader.result.replace("data:image/jpeg;base64,", "");
                } else if (base64.includes("data:image/gif;base64,")) {
                    base64 = reader.result.replace("data:image/gif;base64,", "");
                }
                else if (base64.includes("data:application/x-zip-compressed;base64,")) {
                    base64 = reader.result.replace("data:application/x-zip-compressed;base64,", "");
                }
                console.log(base64)
                if (base64) {
                    setFinalData({ ...finalData, houseAdsCreative: base64, })
                }
            }
            reader.onerror = (error) => {
                console.log("error: ", error)
            };
        }
    }

    const changeSelect = (e) => {
        console.log(e)
        const { name, value } = e.target;
        setFinalData(finalData => ({ ...finalData, [name]: value }));
    }

    const handleChange = (e) => {
        console.log(e.target)
        const { name, value } = e.target;
        console.log(e.target.type)
        if(e.target.type === 'number'){
            if(value){
                setFinalData(finalData => ({ ...finalData, [name]: parseInt(value) }));
            }
               else{
                setFinalData(finalData => ({ ...finalData, [name]: value }));
               }
        }else{
            setFinalData(finalData => ({ ...finalData, [name]: value }));
        }
      
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(finalData)
        setSubmitted(true);
        if (finalData.houseAdsCreativeName && finalData.houseAdsCreativeType  && finalData.houseAdsCreative && finalData.houseAdsCreativeSize && finalData.houseAdsCategoryID) {
            setLoader(true)
            if (props.selectedCreative !== '') {
                dispatch(onUpdateCreative({ ...finalData,houseAdsCreativeName:finalData.houseAdsCreativeName.trim() }));
            } else {
                dispatch(onADDCreative({ ...finalData,houseAdsCreativeName:finalData.houseAdsCreativeName.trim() }));
            }
        }
    }

    const { errorMsg, successMsg } = useSelector(state => ({
        errorMsg: state.creative.errorMsg,
        successMsg: state.creative.successMsg,
    }));

    console.log(errorMsg, successMsg)

    useEffect(() => {
        if (successMsg !== null) {
            if (successMsg.statusCode === 200) {
                dispatch(addHouseAdCreativeSuccess(""));
                dispatch(addHouseAdCreativeFail(""));
                dispatch(updateHouseAdCreativeSuccess(""));
                dispatch(updateHouseAdCreativeFail(""));
                setLoader(false)
                if (props.selectedCreative !== '') {
                    props.closeCanvas('Update')
                } else {
                    props.closeCanvas('Create')
                }
            }
        }
    }, [successMsg]);

    useEffect(() => {
        if (errorMsg !== null && errorMsg !== '') {
          setLoader(false)
          if(errorMsg.status && errorMsg.status === 500){
          }else{
            dispatch(addHouseAdCreativeSuccess(""));
            dispatch(addHouseAdCreativeFail(""));
            dispatch(updateHouseAdCreativeSuccess(""));
            dispatch(updateHouseAdCreativeFail(""));
          }
        }
      }, [errorMsg]);
    
      const toggleToast = (n) => (e) => {
        dispatch(addHouseAdCreativeSuccess(""));
        dispatch(addHouseAdCreativeFail(""));
        dispatch(updateHouseAdCreativeSuccess(""));
        dispatch(updateHouseAdCreativeFail(""));
            props.closeCanvas('')
      };

    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };

    function allowAlphaNumericSpace(e) {
        var code = ('charCode' in e) ? e.charCode : e.keyCode;
        if ( !(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault();}
      };

    return (
        <React.Fragment>
            {loader &&
                <Spinner className="ms-2 loader" color="primary" />
            }
            <div className="col-6" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
                <Form onSubmit={handleSubmit}>
                    <Row style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
                        <Col xs={12}>
                            <div className="mb-3">
                                <Label className="form-label">Creative Name</Label>
                                <Input
                                    name="houseAdsCreativeName"
                                    type="text"
                                    onChange={handleChange}
                                    maxLength={65}
                                    value={finalData.houseAdsCreativeName}
                                    onKeyPress={allowAlphaNumericSpace}
                                    className={'form-control' + (submitted && !finalData.houseAdsCreativeName ? ' is-invalid' : '')}
                                    readOnly={props.selectedArchived}
                                />
                                {submitted && !finalData.houseAdsCreativeName ? (
                                    <FormFeedback type="invalid">{'Creative Name is required'}</FormFeedback>
                                ) : null}
                            </div>

                            <div className="mb-3">
                                <Label className="form-label"> Creative Type</Label>
                                <Input
                                    name="houseAdsCreativeType"
                                    type="select"
                                    value={finalData.houseAdsCreativeType}
                                    className={'form-control form-select' + (submitted && !finalData.houseAdsCreativeType ? ' is-invalid' : '')}
                                    onChange={changeSelect}
                                >
                                    <option value=''>Select</option>
                                    <option value='js'>JS</option>
                                    <option value='image'>Image</option>
                                    <option value='html'>HTML</option>
                                </Input>
                                {submitted && !finalData.houseAdsCreativeType ? (
                                    <FormFeedback type="invalid">{'Creative Type is required'}</FormFeedback>
                                ) : null}
                            </div>
                            {finalData.houseAdsCreativeType === 'js' &&
                                <div className="mb-3">
                                    <Label >
                                        Input Creative Passback Tag
                                    </Label>
                                    <Input
                                        type="textarea"
                                        id="textarea"
                                        rows="5" name="houseAdsCreative"
                                        placeholder="Creative Passback Tag"
                                        value={finalData.houseAdsCreative}
                                        className={'form-control' + (submitted && !finalData.houseAdsCreative ? ' is-invalid' : '')}
                                        readOnly={props.selectedArchived}
                                        onChange={handleChange}
                                    />
                                       {submitted && !finalData.houseAdsCreative ? (
                                            <FormFeedback type="invalid">{'Creative Type is required'}</FormFeedback>
                                        ) : null}
                                </div>
                            }
                            {finalData.houseAdsCreativeType === 'image' &&
                                <div className="mb-3">
                                    <Label className="form-label">Browse Image File</Label>
                                    <div>
                                        <Input type="file" accept="image/x-png,image/gif,image/jpeg" onChange={fileChange} />
                                        <span className="notes">Note: Allowed file types: gif, jpg, png, jpeg. Maximum allowed file size: 15 MB.</span>
                                    </div>
                                </div>
                            }
                            {finalData.houseAdsCreativeType === 'html' &&
                                <div className="mb-3">
                                    <Label className="form-label">Browse Zip File</Label>
                                    <div>
                                        <Input type="file" onChange={fileChange} />
                                        <span className="notes">Note: Allowed file types: zip. Maximum allowed file size: 15 MB.</span>
                                    </div>
                                </div>
                            }
                            {submitted && !finalData.houseAdsCreativeType && !finalData.houseAdsCreative ? (
                                <FormFeedback type="invalid">{'Creative Content is required'}</FormFeedback>
                            ) : null}
                            <div className="mb-3">
                                <Label className="form-label">Ad Size</Label>
                                <Input type="select" name="houseAdsCreativeSize" value={finalData.houseAdsCreativeSize}
                                    className={'form-control form-select' + (submitted && !finalData.houseAdsCreativeSize ? ' is-invalid' : '')}
                                    onChange={changeSelect} >
                                    <option value=''> Select</option>
                                    <option value="0x0">0x0 </option>
                                    <option value="1x1">1x1 </option>
                                    <option value="300x250">300x250 </option>
                                    <option value="300x600">300x600 </option>
                                    <option value="320x442"> 320x442 </option>
                                    <option value="360x480">360x480</option>
                                    <option value="468x100">468x100 </option>
                                    <option value="640x480">640x480</option>
                                    <option value="728x90">728x90 </option>
                                    <option value="800x600">800x600 </option>
                                    <option value="120x600">120x600 </option>
                                    <option value="160x600">160x600 </option>
                                    <option value="970x250">970x250 </option>
                                    <option value="1280x250">1280x250 </option>
                                    <option value="970x90">970x90</option>
                                    <option value="320x50">320x50</option>
                                    <option value="320x100">320x100 </option>
                                    <option value="320x480">320x480 </option>
                                    <option value="300x50">300x50 </option>
                                    <option value="300x100">300x100 </option>
                                    <option value="468x60">468x60 </option>
                                    <option value="468x90">468x90</option>
                                    <option value="336x280">336x280 </option>
                                    <option value="800x485"> 800x485 </option>
                                </Input>
                                {submitted && !finalData.houseAdsCreativeSize  ? (
                                <FormFeedback type="invalid">{'Ad Size is required'}</FormFeedback>
                            ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label"> Select Category</Label>
                                <Input
                                    name="houseAdsCategoryID"
                                    type="select"
                                    value={finalData.houseAdsCategoryID}
                                    className={'form-control form-select' + (submitted && !finalData.houseAdsCategoryID ? ' is-invalid' : '')}
                                    onChange={changeSelect}
                                >
                                    <option value=''>Select</option>
                                    {categories.map((item) => (
                                        <option key={item.id} value={item.houseAdsCategoryID}>{item.houseAdsCategoryName}</option>
                                    ))}
                                </Input>
                                {submitted && !finalData.houseAdsCategoryID ? (
                                    <FormFeedback type="invalid">{'Category is required'}</FormFeedback>
                                ) : null}
                            </div>
                            {(finalData.houseAdsCreativeType === 'image' || finalData.houseAdsCreativeType === 'html') &&
                                <div className="mb-3">
                                    <Label className="form-label">Landing Page URL</Label>
                                    <Input
                                        name="houseAdsCreativeLandingPageUrl"
                                        type="text"
                                        onChange={handleChange}
                                        value={finalData.houseAdsCreativeLandingPageUrl}
                                    />
                                </div>
                            }

                        </Col>
                    </Row>
                    <Row className="drawer-footer"  style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
                        <Col>
                            <div className="text-start">
                                &nbsp;&nbsp;
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    // eslint-disable-next-line react/prop-types
                                    onClick={props.closeCanvas}
                                >
                                    Back
                                </button>
                                &nbsp;&nbsp;
                                {!isEdit &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Save
                                    </button>
                                }
                                &nbsp;&nbsp;

                                {isEdit && !props.selectedArchived &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Update
                                    </button>
                                }
                            </div>
                        </Col>
                    </Row>
                </Form>
                {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
            <Toaster status="error" msg={errorMsg.message}/>
        }
      </div>
        {errorMsg !== null && errorMsg !== '' &&  errorMsg.status && errorMsg.status === 500 && 
            <div className="p-0 justify-content-center d-flex">
            <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')}/>
          </div>
        }
        </React.Fragment>
    )
}


NewCreatives.propTypes = {}

export default NewCreatives