import React, { useEffect, useState, useRef, useCallback } from "react";

import { Helmet, HelmetProvider } from 'react-helmet-async';

import {
  Card,

  CardBody,
  Col,
  Box,
  Input,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Label
} from "reactstrap"
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Progress, Modal, ModalBody, } from 'reactstrap';


import {
  getCommonDevices as onGetAllContextual,
} from "store/CommonEvents/actions";


import {
  getMarketContents as onGetMarketContents,
  deleteMarketContent as onDeleteMarketContent,
  deleteMarketContentSuccess,
  deleteMarketContentFail
} from 'store/Media/MarketPlace/actions'


// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import preveiw from '../../../assets/images/media/preview.png'
import preview2 from '../../../assets/images/media/preview2.png'


//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//react-tooltip 
import "react-tooltip/dist/react-tooltip.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import SCSS
// import './VideoContent.scss'

import VideoPlayer from "../Video_Content_Library/VideoPlayer";
import moment from "moment";

import LoadingTable from "pages/LoaderPages/LoadingTable";
// import Create_Content_Selection from "./Create_Content_Selection";
import images from "assets/images";


var videoSelected = []
var selectedVideo = ''
var selectedIndex = []
var selectedItem = []
var filterCount = 0

const Content_Market_Place = props => {
  const dispatch = useDispatch();
  const [isRight, setIsRight] = useState(false);
  const [isVideoRight, setIsVideoRight] = useState(false);
  const [languageData, setLanguageData] = useState([])
  const [categoryData, setCategoryData] = useState([])
  const [menu1, setMenu1] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  console.log(userInfo)
  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedlanguage] = useState();
  const [selectedCateory, setSelectedCategory] = useState();
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false)

  const [btnprimary3, setBtnprimary3] = useState(false)

  const [showAlertFile, setShowAlertFile] = useState(false);
  const [showAlertRemove, setShowAlertRemove] = useState(false);
  const [showAlertAdd, setShowAlertAdd] = useState(false);


  const [contentId, setContentId] = useState('')

  const [languageSearch, setLanguageSearch] = useState('')
  const [categorySearch, setCategorySearch] = useState('')
  const [videosData, setVideosData] = useState([])

  const [activeState, setActiveState] = useState('grid')

  const [hoverStateID, setHoverStateID] = useState('');
  const [checkedAll, setCheckedAll] = useState(false);

  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const [selected, setSelected] = useState([])
  const [selectedId, setSelectedId] = useState([])

  const [additionalData, setAdditionalData] = useState([]);

  const addData = (videoContentName, videoContentID) => {
    setAdditionalData((prevData) => [...prevData, { videoContentName, videoContentID }]);
  };

  const removeData = (videoContentID) => {
    setAdditionalData((prevData) => prevData.filter(item => item.videoContentID !== videoContentID));
  };

  const [btnprimary1, setBtnprimary1] = useState(false)
  const [actionType, setActionType] = useState('')

  const [searchInput, setSearchInput] = useState('')

  const [switchLoader, setSwitchLoader] = useState(true)

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }


  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setIsRight(false)
      setIsVideoRight(false)
    }
  }, []);

  useEffect(() => {
    setSelectedRowsStatus(selected.length > 0);
  }, [selected]);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);



  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 5000,
    extendedTimeOut: 1000
  };


  const [filterState, setFilterState] = useState(false)

  const languageChange = (e) => {
    if (e.target.value === '') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (languageSearch === '') {
      filterCount = filterCount + 1
    }
    setLanguageSearch(e.target.value)
  }

  const categoryChange = (e) => {
    if (e.target.value === '') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (categorySearch === '') {
      filterCount = filterCount + 1
    }
    setCategorySearch(e.target.value)
  }

  const applyFilter = (e) => {
    dispatch(onGetMarketContents({ VideoContentLanguage: languageSearch, VideoContentCategory: categorySearch }));
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }

  const clearAllFilter = (e) => {
    setLanguageSearch('')
    setCategorySearch('')
    filterCount = 0
    setFilterState(false)
    dispatch(onGetMarketContents({ VideoContentLanguage: '', VideoContentCategory: '' }));
  }




  const { marketContents } = useSelector(state => ({
    marketContents: state.marketContents.vcons
  }));


  const { loading } = useSelector(state => ({
    loading: state.marketContents.loading,
  }));

  const { errorMsg, successMsg, playlistErr } = useSelector(state => ({
    errorMsg: state.marketContents.errorMsg,
    successMsg: state.marketContents.successMsg,
    playlistErr: state.marketContents.playlistErr
  }))

  console.log(playlistErr)
  console.log(successMsg)
  console.log(errorMsg)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg) {
        handleClose()
        setShowAlertRemove(false)

        dispatch(deleteMarketContentSuccess(""));
        // setLoader(false)
        toastr.info("video Content Removed!")
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (playlistErr !== null) {
      if (playlistErr.length !== 0) {

        if (playlistErr.videoContentLinkPlayList !== undefined) {
          const playlistNames = playlistErr.videoContentLinkPlayList.map((playlist) => playlist.PlayListName).join(", ");
          console.log(playlistErr);
          dispatch(deleteMarketContentFail(""));
          handleClose()
          setShowAlertRemove(false)
          // setLoader(false)
          toastr.info(`The video content cannot be deleted because it is part of the playlist(s):  ${playlistNames}`);
        } else if (playlistErr.videoContentLinkPartner !== undefined) {
          const partnerNames = playlistErr.videoContentLinkPartner.map((partner) => partner.UserRole).join(", ")
          dispatch(deleteMarketContentFail(""));
          handleClose()
          setShowAlertRemove(false)
          toastr.info(`The video content cannot be deleted because it is part of the partner(s):  ${partnerNames}`);
        }

      }
    }
  }, [playlistErr]);





  useEffect(() => {
    // console.log(loading)
    if (loading) {
      setSwitchLoader(true)
    }
    if (marketContents && marketContents.length) {
      setSwitchLoader(false)
    }
  }, [loading]);


  const [filteredVcons, setFilteredVCons] = useState();

  const { contextuals } = useSelector(state => ({
    contextuals: state.commonEvents.contextualAllData,
  }));

  useEffect(() => {
    if (contextuals !== null) {
      dispatch(onGetAllContextual());
    }
  }, []);

  useEffect(() => {
    if (marketContents !== null) {
      dispatch(onGetMarketContents({ VideoContentLanguage: languageSearch, VideoContentCategory: categorySearch }));
      handleSwitch()
    }
  }, [successMsg]);

  useEffect(() => {
    if (marketContents !== null && marketContents.length > 0) {
      setVideosData(marketContents)
    } else {
      setVideosData([])
    }
  }, [marketContents.length]);

  const handleRemoveContent = () => {
    console.log(additionalData)
    dispatch(onDeleteMarketContent({ data: [additionalData] }))
    setAdditionalData([])
  }


  const toggle = () => setOpen(!open);
  const toggleRightCanvas = (value) => {
    // selectedData = ''
    dispatch(onGetMarketContents({ VideoContentLanguage: languageSearch, VideoContentCategory: categorySearch }));
    setIsRight(!isRight);
    setActionType(value)
  };

  const copyThumbnail = (value, e) => {
    console.log("this is video content alert============", value)
    window.navigator.clipboard.writeText(value);
  }

  const toggleEnableScroll = () => {
    setIsEnableScroll(!isEnableScroll);
  };

  const toggleDrawer = (close, videoContent) => (event) => {
    console.log(event)
    if (event === 'Create') {
      toastr.success('VideoContent Queued For Uploading!...')
    }
    // selectedData = videoContent
    dispatch(onGetMarketContents({ VideoContentLanguage: languageSearch, VideoContentCategory: categorySearch }));
    setIsRight(close);
  };

  useEffect(() => {
    if (contextuals && Object.keys(contextuals).length !== 0) {
      if (contextuals.language && contextuals.language.length > 0) {
        setLanguageData(objectModal(contextuals.language))
      }
      if (contextuals.category && contextuals.category.length > 0) {
        setCategoryData(objectModal(contextuals.category))
      }
    }
  }, [contextuals]);


  const handleEditState = (id) => (e) => {
    setHoverStateID(id)
  }
  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }

  //using hooks
  const selectVideo = (item, index) => (e) => {
    const isChecked = e.target.checked;
    const updatedVideosData = videosData.map((content, i) => {
      console.log("-------------", index, i)
      if (i === index) {
        console.log("-----------------", index)
        return { ...content, checked: isChecked };
      }
      return content;
    });
    setVideosData(updatedVideosData);

    setSelected(prevSelected => {
      if (isChecked) {
        return [...prevSelected, item.id];
      } else {
        return prevSelected.filter(id => id !== item.id);
      }
    });

    setSelectedId(prevSelectedId => {
      if (isChecked) {
        return [...prevSelectedId, item.videoContentID];
      } else {
        return prevSelectedId.filter(id => id !== item.videoContentID);
      }
    });

    if (isChecked) {
      addData(item.videoContentName, item.videoContentID);
    } else {
      removeData(item.videoContentID);
    }



    setSelectedRowsStatus(selected.length > 0);
  };

  const selectAllVideo = (e) => {
    const isChecked = e.target.checked;
    const updatedVideosData = videosData.map((item) => {
      return { ...item, checked: isChecked }
    })
    setCheckedAll(isChecked)

    let selectedIds = []

    if (isChecked) {
      selectedIds = updatedVideosData
        .filter((item) => !selectedId.includes(item.videoContentID))
        .map((item) => item.videoContentID)
    }

    setSelectedId(isChecked ? updatedVideosData.map((item) => item.videoContentID) : [])
    setSelected(isChecked ? updatedVideosData.map((item) => item.id) : [])
    setSelectedRowsStatus(isChecked)

    if (isChecked) {
      updatedVideosData.forEach((item) => {
        addData(item.videoContentName, item.videoContentID);
      });
    } else {
      updatedVideosData.forEach((item) => {
        removeData(item.videoContentID);
      });
    }

    setVideosData(updatedVideosData)
  }


  const selectRow = {
    hideSelectColumn: true,
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      let marketContents = [...videosData];
      marketContents[row.id - 1].checked = isSelect;
      setVideosData(marketContents);

      if (isSelect) {
        setSelected([...selected, row.id]);
        setSelectedId([...selectedId, row.videoContentID]);
        addData(row.videoContentName, row.videoContentID);

      } else {
        setSelected(selected.filter((id) => id !== row.id));
        setSelectedId(selectedId.filter((id) => id !== row.videoContentID));
        removeData(row.videoContentID);

      }

      setSelectedRowsStatus(selected.length > 0);
    },
    onSelectAll: (isSelect, rows, e) => {
      let marketContents = [...videosData];
      let selectedIds = [];

      if (isSelect) {
        rows.forEach((row) => {
          selectedIds.push(row.videoContentID);
        });
      }

      setSelectedId(isSelect ? marketContents.map((item) => item.videoContentID) : []);
      setSelected(isSelect ? marketContents.map((item) => item.id) : []);
      setSelectedRowsStatus(isSelect);

      if (isSelect) {
        rows.forEach((item) => {
          addData(item.videoContentName, item.videoContentID);
        });
      } else {
        rows.forEach((item) => {
          removeData(item.videoContentID);
        });
      }

      setVideosData(
        marketContents.map((item) => ({
          ...item,
          checked: isSelect,
        }))
      );
    },
  };

  const handleClose = () => {
    videoSelected = []
    let videoLists = [...videosData]
    videoLists.map((item, index) => {
      item.checked = false
    })
    setCheckedAll(false)
    setVideosData(videoLists)
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
    selectedIndex = []
  }

  const handleCloseT = () => {

  }

  const handleSingleRemove = (videoContentName, videoContentID) => {
    console.log(videoContentID, videoContentName)
    debugger
    setAdditionalData({ videoContentName, videoContentID })
    setShowAlertRemove(true)
  }

  const handleDeleteModal = id => {
    setShowAlertFile(true)
    setContentId(id)
  }

  // const handleDelete = id => {
  //   console.log(id)

  //   dispatch(deleteMarketContent(id))
  //   setShowAlertFile(false)

  // }

  const selectVideoPlay = (videoData) => {
    selectedVideo = videoData
  }

  const toggleRightVideoCanvas = (videoData) => (e) => {
    selectedVideo = videoData
    setIsVideoRight(!isVideoRight)
    console.log('called toggleRightVideoCanvas')
  }

  console.log(videosData)



  const keyField = "id";

  const handleCloseTable = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
    selectedIndex = []
  }



  const videoListCoulumns = [
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "id",
      sort: true,
      hidden: true
    },
    {
      text: "ID",
 headerClasses:"ID",
      dataField: 'videoConentID',
      sort: true,
      formatter: (cellContent, eachRow) => (
        <>
          <span>{eachRow.videoContentID}</span>
        </>
      ),
    },
    {
      dataField: "videoContentMp4URL",
      editable: false,
      text: "video",
      align: 'left',
      formatter: (cellContent, eachRow) => (
        eachRow.isUploaded === "Upload is success" ?
          <img src={eachRow.videoContentThumbnailURL} alt="thumbnail" style={{ height: "3.5rem", width: "6 rem" }} />

          :
          <img src={preveiw} alt="thumbnail" style={{ height: "3.5rem", width: "6 rem" }} />

      )
    },
    {
      text: 'Name',
      dataField: 'videoContentName',
      formatter: (cellContent, eachRow) => {
        if (cellContent.length > 50) {
          return cellContent.substring(0, 50) + ' . . .';
        }
        return cellContent;
      },
    },
    {
      text: 'Category',
      dataField: 'videoContentCategory'
    },
    {
      text: 'Language',
      dataField: 'videoContentLanguage'
    },
    {
      text: 'Duration',
      dataField: 'videoContentDuration',
      formatter: (cellContent, eachRow) => (
        <span className="mb-2">{moment.unix(eachRow.videoContentDuration).utc().format(' m [min] s [secs]')} </span>
      )
    },
    // {
    //   text: "Action",
    //   dataField: '',
    //   formatter: (cellContent, eachRow) => (
    //     <>
    //       {eachRow.isUploaded === "Upload is success" &&
    //         <div className="d-flex">
    //           <div>
    //             <Button className="m-3" id={eachRow.videoContentThumbnailURL} onClick={() => copyThumbnail(eachRow.videoContentThumbnailURL)}>thumbnail</Button>
    //             <ReactTooltip
    //               anchorId={eachRow.videoContentThumbnailURL}
    //               place='top'
    //               variant='info'
    //               content='Copied thumbnail url'
    //               openOnClick="true"
    //               delayHide={800}
    //             />
    //           </div>
    //           {/* <div className="btn-group m-1 mt-2">
    //             <Dropdown isOpen={btnprimary3} toggle={() => setBtnprimary3(!btnprimary3)}>
    //               <DropdownToggle caret>
    //                 Choose Url
    //               </DropdownToggle>
    //               <DropdownMenu>
    //                 <DropdownItem onClick={() => copyThumbnail(eachRow.videoContentM3u8URL)}>M3U8 link</DropdownItem>
    //                 <DropdownItem onClick={() => copyThumbnail(eachRow.videoContentMp4URL)}>HQ link</DropdownItem>
    //                 <DropdownItem onClick={() => copyThumbnail(eachRow.videoContentCompressedMp4URL)}>Compressed link</DropdownItem>
    //               </DropdownMenu>
    //             </Dropdown>
    //           </div> */}
    //           <div>
    //             <Button className="m-3" id={eachRow.videoContentCompressedMp4URL} onClick={() => copyThumbnail(eachRow.videoContentCompressedMp4URL)}>Video</Button>
    //             <ReactTooltip
    //               anchorId={eachRow.videoContentCompressedMp4URL}
    //               place='top'
    //               variant='info'
    //               content='Copied Video url'
    //               openOnClick="true"
    //               delayHide={800}
    //             />
    //           </div>
    //         </div>
    //       }
    //       {eachRow.isUploaded === "Upload is not yet start" &&
    //         <div>
    //           <Progress
    //             animated
    //             color="info"
    //             style={{
    //               height: '15px'
    //             }}
    //             striped
    //             value={100}
    //           >In progress</Progress>
    //         </div>
    //       }
    //     </>
    //   ),
    //   events: {
    //     onClick: (e, column, columnIndex, row, rowIndex) => {
    //       e.stopPropagation()
    //       console.log("cliked")
    //     }
    //   }
    // },
    {
      text: "Status",
      dataField: '',
      formatter: (cellContent, eachRow) => (
        <>
          {eachRow.isUploaded === "Upload is success" &&
            <span className="active-state">Ready</span>
          }
          {eachRow.isUploaded === "Upload is not yet start" &&
            <span className="support-ticket-urgent">Pending</span>
          }
          {eachRow.isUploaded === "Upload is not success" &&
            <span className="support-ticket-high">Error</span>
          }
        </>
      )
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, eachRow) => (
        <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
          <span className="text-success " style={{ cursor: 'pointer' }} onClick={() => handleSingleRemove(eachRow.videoContentName, eachRow.videoContentID)}>
            <i
              className="mdi mdi-trash-can font-size-18"
              id="edittooltip"
            ></i>
          </span>
          <span>
            <UncontrolledDropdown
              className="dropRight  active"
            >
              <DropdownToggle
                tag="span" className="dropdown-toggle"
              >
                <i className="bx bx-copy-alt font-size-18" />
              </DropdownToggle>
              <DropdownMenu data-popper-placement="left-start">
                <DropdownItem onClick={() => copyThumbnail(eachRow.videoContentThumbnailURL)}>     Thumbnail &nbsp;
                  <i className="mdi mdi-plus-circle-outline me-1" /></DropdownItem>
                <DropdownItem onClick={() => copyThumbnail(eachRow.videoContentM3u8URL)}>     M3u8 url &nbsp;
                  <i className="mdi mdi-plus-circle-outline me-1" /></DropdownItem>
                <DropdownItem onClick={() => copyThumbnail(eachRow.videoContentMp4URL)}>     HQ url &nbsp;
                  <i className="mdi mdi-plus-circle-outline me-1" /></DropdownItem>
                <DropdownItem onClick={() => copyThumbnail(eachRow.videoContentCompressedMp4URL)}>     Compressed url &nbsp;
                  <i className="mdi mdi-plus-circle-outline me-1" /></DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </span>

          {/* <span onClick={() => { tog_standard(adUnit); }} style={{ cursor: 'pointer' }}>
            <i
              className="bx bx-copy-alt font-size-18"
              id="edittooltip"
            ></i>
          </span> */}
        </div>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation()
          console.log("cliked")
        }
      }
    }
  ]

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };



  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ];

  const listView = () => {
    setActiveState('list')
    // handleClose()
  }

  const gridView = () => {
    setActiveState('grid')
    // handleCloseT()
  }

  const rowEvents = {
    onClick: (e, row) => {
      if (row.isUploaded === "Upload is success") {
        selectedVideo = row
        setIsVideoRight(!isVideoRight)
      }
    }
  }


  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      <div className="page-content">
        <Helmet>
          <title>Content Marketplace | Bidsxchange</title>
        </Helmet>
        <Container fluid>
          <div className=" d-sm-flex  justify-content-between">
            <Breadcrumbs title="Media" breadcrumbItem="Content Marketplace" />
            <div className=" text-sm-end">
              <div className="btn-group">
                <Dropdown
                  direction="left"
                  isOpen={isMenu}
                  toggle={toggleMenu}
                >
                  <DropdownToggle
                    tag="button"
                    className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                  >
                    <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                    {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                      Filter Applied
                    </span> : <span className="d-none font-16 d-sm-inline-block">
                      Filter
                    </span>
                    }
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                    <div className="dropdown-item-text">
                      <div className="row">
                        <h5 className="mb-0">Filters</h5>
                      </div>
                    </div>

                    <DropdownItem divider />
                    <Card>
                      <CardBody aria-hidden="true">
                        <Row >
                          <Col sm="12" className="mt-2">
                            <Label className="form-label">Language</Label> <br />
                            <div className=" text-sm-end">
                              <Input type="select" className="form-select" value={languageSearch} onChange={languageChange}>
                                <option value=''> All Languages</option>
                                {languageData.map((item) => (
                                  <option
                                    key={item.value}
                                    value={item.value}
                                  >
                                    {item.label}
                                  </option>
                                ))}
                              </Input>
                            </div>
                          </Col>
                          <Col sm="12" className="mt-2">
                            <Label className="form-label">Category</Label> <br />
                            <div className=" text-sm-end">
                              <Input type="select" className="form-select" value={categorySearch} onChange={categoryChange}>
                                <option value=''> All Categories</option>
                                {categoryData.map((item) => (
                                  <option
                                    key={item.value}
                                    value={item.value}
                                  >
                                    {item.label}
                                  </option>
                                ))}
                              </Input>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>

                    <DropdownItem divider />
                    <Row>
                      <Col sm="6">
                        <DropdownItem
                          className="text-primary text-center"
                          onClick={clearAllFilter}
                        >
                          Clear ALL
                        </DropdownItem>
                      </Col>
                      <Col sm="6">
                        <DropdownItem
                          className="text-primary text-center"
                          onClick={applyFilter}
                        >
                          Apply Filter
                        </DropdownItem>
                      </Col>
                    </Row>

                  </DropdownMenu>
                </Dropdown>
              </div> &nbsp;&nbsp;
              {false && <Button
                color="primary"
                className="font-16 btn-block btn btn-primary"
                onClick={() => toggleRightCanvas('upload')}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Add VideoContent
              </Button>}
              {/* <div className="btn-group">
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                >
                  <DropdownToggle tag="button" className="btn btn-primary">
                    <i className="mdi mdi-plus-circle-outline me-1" />Add VideoContent&nbsp; <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => toggleRightCanvas('upload')}>upload Content</DropdownItem>
                    <DropdownItem onClick={() => toggleRightCanvas('link')}>Content Links</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div> */}
            </div>
          </div>
          <Card className="mt-2 p-3">
            <Row className="mb-2">
              <Col sm="12">
                <div className="text-end d-flex justify-content-end" >
                  <div style={{ backgroundColor: `${activeState === 'list' ? "#dedede" : "#ffffff"}` }}>
                    <i className='bx bx-sm bx-list-ul m-1' onClick={listView} style={{ cursor: "pointer" }}></i>
                  </div>
                  <div style={{ backgroundColor: `${activeState === 'grid' ? "#dedede" : "#ffffff"}` }}>
                    <i className='bx bx-sm bx-grid-alt m-1' onClick={gridView} style={{ cursor: "pointer" }}></i>
                  </div>
                </div>
              </Col>
            </Row>
            {activeState === 'grid' && !switchLoader &&
              <Row className="mb-2">
                <Col sm="4">
                  <div className="search-box ms-2 mb-2 d-inline-block mt-2">
                    <div className="position-relative">
                      <label htmlFor="search-bar-0" className="search-label">
                        <span id="search-bar-0-label" className="sr-only">Search this table</span>
                        <input id="search-bar-0" type="text" aria-labelledby="search-bar-0-label" className="form-control " placeholder="Search" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
                      </label><i className="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </Col>
              </Row>
            }
            <Row className="mb-2">
              <Col xl="12">
                {selectedRowsStatus && activeState === 'grid' &&
                  <div className="row m-0 React-Table-Selection">
                    <div style={{ padding: '0.5% 2%' }}>
                      <input
                        className="form-check-input"
                        type="checkbox" checked={checkedAll}
                        onChange={selectAllVideo}
                        style={{ marginTop: '0.4rem', padding: '10px' }}
                      />         &nbsp;  &nbsp;
                      <span color="inherit" style={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selected.length} Selected </span>           &nbsp;  &nbsp;

                      <button color="primary" className="btn btn-primary " onClick={() => setShowAlertRemove(true)}>
                        Remove from Marketplace
                      </button>




                      &nbsp;  &nbsp;
                      {/* <button color="primary" className="btn btn-primary " >
                        DeActivate
                      </button>
                      &nbsp;  &nbsp;
                      <Button color="primary" className="btn btn-primary " >
                        Archive
                      </Button> */}
                      <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                        className="mdi mdi-close-thick font-size-18"
                      ></i></Button>
                    </div>
                  </div>
                }
              </Col>
            </Row>

            {activeState === 'grid' &&
              <Row>

                {videosData !== null && videosData.length !== 0 && !switchLoader ?
                  videosData
                    .filter(item => item.videoContentName.includes(searchInput))
                    .map((item, index) => {
                      return (
                        <Col sm={4} xxl={3} key={index} >
                          {/* <Card onMouseOver={handleEditState(item.videoContentCompressedMp4URL)} onMouseLeave={handleEditState('')} style={{ backgroundColor: (item.checked || hoverStateID === item.videoContentCompressedMp4URL) ? '#556ee61f' : '', cursor: 'pointer', boxShadow: (hoverStateID && hoverStateID === item.videoContentCompressedMp4URL) ? 'rgb(149 157 165 / 20%) 0px 8px 24px' : 'none' }}> */}
                          <Card style={{ backgroundColor: '', cursor: 'pointer', boxShadow: 'none' }}>
                            <div>
                              {(item.checked || hoverStateID === item.videoContentCompressedMp4URL) &&
                                <div><div className="form-check mt-2 mb-2" style={{ position: 'absolute', left: '1pc', top: '0.5pc', zIndex: '200' }}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={item.videoContentCompressedMp4URL} style={{ width: '1.5pc', height: '1.5pc' }}
                                    checked={item.checked}
                                    onChange={selectVideo(item, index)}
                                  />
                                </div>
                                </div>
                              }
                              <UncontrolledDropdown
                                className="dropleft  active" style={{ position: 'absolute', right: '0pc', top: '2.5pc', zIndex: '500', fontSize: '1.5rem' }}
                              >
                                <DropdownToggle
                                  tag="span" className="dropdown-toggle"
                                >
                                  <i className="bx bx-dots-vertical-rounded" />
                                </DropdownToggle>
                                <DropdownMenu data-popper-placement="left-start">
                                  <DropdownItem onClick={(e) => copyThumbnail(item.videoContentThumbnailURL, e)}>     Thumbnail &nbsp;
                                    <i className="mdi mdi-plus-circle-outline me-1" /></DropdownItem>
                                  <DropdownItem onClick={(e) => copyThumbnail(item.videoContentM3u8URL, e)}>     M3u8 Url &nbsp;
                                    <i className="mdi mdi-plus-circle-outline me-1" /></DropdownItem>
                                  <DropdownItem onClick={(e) => copyThumbnail(item.videoContentMp4URL, e)}>     HD url &nbsp;
                                    <i className="mdi mdi-plus-circle-outline me-1" /></DropdownItem>
                                  <DropdownItem onClick={(e) => copyThumbnail(item.videoContentCompressedMp4URL, e)}>     Compressed url &nbsp;
                                    <i className="mdi mdi-plus-circle-outline me-1" /></DropdownItem>
                                  <DropdownItem onClick={(e) => handleSingleRemove(item.videoContentName, item.videoContentID)}>     Remove Content &nbsp;
                                    <i className="mdi mdi-trash-can me-1" /></DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>

                            <CardBody className="row justify-content-center " style={{ cursor: 'pointer' }} onClick={toggleRightVideoCanvas(item)}>
                              <i className="bx bx-play-circle text-center" style={{ position: 'absolute', fontSize: '2rem', color: 'white', zIndex: '200', top: '5vw' }} />

                              {/* <img src={item.videoContentThumbnailURL} alt="thumbnail" style={{ padding: '12px', borderRadius: '1.2rem', width: '100%' }} /> */}

                              {item.isUploaded === "Upload is success" ?
                                <img className="img-container" src={item.videoContentThumbnailURL} alt="thumbnail" style={{ padding: '12px', borderRadius: '1.2rem', width: '100%' }} />

                                :
                                <img className="img-container" src={preview2} alt="thumbnail" style={{ padding: '12px', borderRadius: '1.2rem', width: '100%' }} />}

                              {/* <h5 className="text-center m-3">
                                {item.videoContentName.length > 50
                                  ? item.videoContentName.substring(0, 50) + '...'
                                  : item.videoContentName}
                              </h5> */}

                              <h5 className="text-center m-3">
                                {item.videoContentName
                                  .replace(/TE/g, '')
                                  .replace(/EN/g, '')
                                  .replace(/BE/g, '')
                                  .replace(/VI/g, '')
                                  .replace(/KA/g, '')
                                  .replace(/TA/g, '')
                                  .replace(/en/g, '')
                                  .replace(/HI/g, '')
                                  .replace(/TA/g, '')
                                  .replace(/KA/g, '')
                                  .replace(/-/g, ' ')
                                  .length > 50
                                  ? (
                                    item.videoContentName
                                      .replace(/TE/g, '')
                                      .replace(/EN/g, '')
                                      .replace(/-/g, ' ')
                                      .substring(0, 50)
                                      .charAt(0)
                                      .toUpperCase() + item.videoContentName.replace(/TE/g, '').replace(/EN/g, '').replace(/-/g, ' ').substring(1, 50) + '...'
                                  )
                                  : (
                                    item.videoContentName
                                      .replace(/TE/g, '')
                                      .replace(/EN/g, '')
                                      .replace(/-/g, ' ')
                                      .charAt(0)
                                      .toUpperCase() + item.videoContentName.replace(/TE/g, '').replace(/EN/g, '').replace(/-/g, ' ').substring(1)
                                  )}
                              </h5>







                              <h6 style={{ fontWeight: 600 }} className="text-center">Category :  <span style={{ fontWeight: 400 }}>{item.videoContentCategory} </span> &nbsp;&nbsp; |  &nbsp;&nbsp; Language :  <span style={{ fontWeight: 400 }}>{item.videoContentLanguage}</span> </h6>

                              {/* <Row className=" row mt-3">
                              <Col md={6}>
                                <Button
                                  color="primary"
                                  className="font-18 w-100 btn btn-primary"
                                  onClick={() => copyThumbnail(item.videoContentThumbnailURL)}
                                >
                                  Thumbnail &nbsp;
                                  <i className="mdi mdi-plus-circle-outline me-1" />
                                </Button>
                              </Col >
                              <Col md={6}>
                                <Button
                                  color="primary"
                                  className="font-18 w-100 btn btn-primary"
                                  onClick={() => copyThumbnail(item.videoContentCompressedMp4URL)}
                                >
                                  Video &nbsp;
                                  <i className="mdi mdi-plus-circle-outline me-1" />
                                </Button>
                              </Col>
                            </Row> */}
                            </CardBody>
                          </Card>
                        </Col>
                      )
                    })
                  : switchLoader ? (
                    <LoadingTable loading={loading} targetLoading={handleSwitch} />

                  ) : (
                    <div style={{ padding: '1% 3%', height: '50vh', overflow: 'auto' }} className='d-flex justify-content-center'>
                      <h5>No Video Contents, Create One!</h5>
                    </div>
                  )
                }
              </Row>
            }
            {switchLoader &&
              <LoadingTable loading={loading} targetLoading={handleSwitch} />
            }
            {activeState === 'list' && !switchLoader &&
              <Row>
                <Col lg="12">
                  <ToolkitProvider
                    keyField={keyField}
                    data={videosData}
                    columns={videoListCoulumns}
                    // bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        {activeState === 'list' &&
                          <Row className="mb-2">
                            <Col sm="8">
                              <div className="search-box ms-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        }
                        <Row>
                          <Col xl="12">
                            {selectedRowsStatus && activeState === 'list' &&
                              <div className="row m-0 React-Table-Selection">
                                <div style={{ padding: '0.5% 2%' }}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox" checked={checkedAll}
                                    onChange={selectAllVideo}
                                    style={{ marginTop: '0.4rem', padding: '10px' }}
                                  />         &nbsp;  &nbsp;
                                  <span color="inherit" style={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selected.length} Selected </span>           &nbsp;  &nbsp;
                                  <button color="primary" className="btn btn-primary " onClick={() => setShowAlertRemove(true)}>
                                    Remove from Marketplace
                                  </button>



                                  &nbsp;  &nbsp;
                                  {/* <button color="primary" className="btn btn-primary " >
                                    DeActivate
                                  </button>
                                  &nbsp;  &nbsp;
                                  <Button color="primary" className="btn btn-primary " >
                                    Archive
                                  </Button> */}
                                  <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                    className="mdi mdi-close-thick font-size-18"
                                  ></i></Button>
                                </div>
                              </div>
                            }
                          </Col>
                          <Col xl="12" className="table-responsive">
                            <div >
                              <BootstrapTable
                                keyField={keyField}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                rowEvents={rowEvents}
                                classes={
                                  "table align-middle table-nowrap"
                                }
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                pagination={paginationFactory(pageOptions)}
                                noDataIndication={<h5 className="text-center">No Data</h5>}
                              // ref={node}
                              />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>

                </Col>
              </Row>
            }
          </Card>
        </Container>
      </div>
      {/* </div> */}



      <Offcanvas isOpen={isVideoRight} direction='end' className="videoPlayer">
        <OffcanvasHeader toggle={toggleRightVideoCanvas('')} style={{ padding: '0.5rem 1rem' }}>
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h5 className="m-0"> Video Details </h5>
              </Col>
            </Row>
          </div>
        </OffcanvasHeader>
        <OffcanvasBody style={{ padding: '1rem 0.2rem' }}>
          <VideoPlayer selectedVideo={selectedVideo} />
        </OffcanvasBody>
      </Offcanvas>
      <Modal size="md" isOpen={showAlertFile} toggle={() => { setShowAlertFile(!showAlertFile) }} backdrop={'static'} centered={false}
        id="staticBackdrop"
      >
        <div className="modal-content">
          <ModalBody className="px-2 py-4 text-center ">
            <i className="bx bx-error-circle text-warning fon-size-32" style={{ fontSize: '2rem' }}></i> &nbsp;
            <h5 className="text-muted font-size-16 mb-4">  Could you please confirm that you would like to delete this video content!</h5>
            <div className="hstack gap-2 justify-content-center mb-0">
              <button type="button" className="btn btn-light" onClick={() => setShowAlertFile(false)}>No</button>
              <button type="button" className="btn btn-primary" onClick={() => handleDelete(contentId)}>Proceed</button>
            </div>
          </ModalBody>
        </div>
      </Modal>

      <Modal size="md" isOpen={showAlertRemove} toggle={() => { setShowAlertRemove(!showAlertRemove) }} backdrop={'static'} centered={false}
        id="staticBackdrop"
      >
        <div className="modal-content">
          <ModalBody className="px-2 py-4 text-center ">
            <i className="bx bx-error-circle text-warning fon-size-32" style={{ fontSize: '2rem' }}></i> &nbsp;
            <h5 className="text-muted font-size-16 mb-4">  Are you sure want to remove from Marketplace?</h5>
            <div className="hstack gap-2 justify-content-center mb-0">
              <button type="button" className="btn btn-light" onClick={() => setShowAlertRemove(false)}>No</button>
              <button type="button" className="btn btn-primary" onClick={() => handleRemoveContent()}>Proceed</button>
            </div>
          </ModalBody>
        </div>
      </Modal>

      <Modal size="md" isOpen={showAlertAdd} toggle={() => { setShowAlertAdd(!showAlertAdd) }} backdrop={'static'} centered={false}
        id="staticBackdrop"
      >
        <div className="modal-content">
          <ModalBody className="px-2 py-4 text-center ">
            <i className="bx bx-error-circle text-warning fon-size-32" style={{ fontSize: '2rem' }}></i> &nbsp;
            <h5 className="text-muted font-size-16 mb-4">  Would you like to add this to the Video Content Library?</h5>
            <div className="hstack gap-2 justify-content-center mb-0">
              <button type="button" className="btn btn-light" onClick={() => setShowAlertAdd(false)}>No</button>
              <button type="button" className="btn btn-primary" onClick={() => setShowAlertAdd(false)}>Proceed</button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  )
}


Content_Market_Place.propTypes = {}

export default Content_Market_Place