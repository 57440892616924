import {
  LOAD_ACTIVITIES,
    GET_TCS,
    GET_TCS_FAIL,
    GET_TCS_SUCCESS,
    ADD_TC,
    ADD_TC_SUCCESS,
    ADD_TC_ERROR,
    UPDATE_TC,
    UPDATE_TC_SUCCESS,
    UPDATE_TC_ERROR,
    READ_TC,
    READ_TC_SUCCESS,
    STATUS_TC,
    STATUS_TC_SUCCESS,
    ARCHIVE_TC ,
    ARCHIVE_TC_SUCCESS

  } from "./actionTypes"

  export const loadActivitiesTC = Params => ({
    type: LOAD_ACTIVITIES,
    payload:Params
  })

  export const getTcs = Params => ({
    type: GET_TCS,
    payload:Params
  })
  
  export const getTcsSuccess = Tcs => ({
    type: GET_TCS_SUCCESS,
    payload: Tcs,
  })

  export const getTcsFail = error => ({
    type: GET_TCS_FAIL,
    payload: error,
  })

  export const addNewTc = Tc => ({
    type: ADD_TC,
    payload: Tc,
  })
  
  export const addTcSuccess = tc => ({
    type: ADD_TC_SUCCESS,
    payload: tc,
  })
  
  export const addTcFail = tc => ({
    type: ADD_TC_ERROR,
    payload: tc,
  })

  export const updateTc = tc => ({
    type: UPDATE_TC,
    payload: tc,
  })
  
  export const updateTcSuccess = tc => ({
    type: UPDATE_TC_SUCCESS,
    payload: tc,
  })
  
  export const updateTcFail = tc => ({
    type: UPDATE_TC_ERROR,
    payload: tc,
  })

  export const readTc = tc => ({
    type: READ_TC,
    payload: tc,
  })

  export const readTcSuccess = tc => ({
    type: READ_TC_SUCCESS,
    payload: tc,
  })

  export const statusTc = tc => ({
    type: STATUS_TC,
    payload: tc,
  })

  export const statusTcSuccess = tc => ({
    type: STATUS_TC_SUCCESS,
    payload: tc,
  })

  export const archiveTc = tc => ({
    type: ARCHIVE_TC,
    payload: tc,
  })

  export const archiveTcSuccess = tc => ({
    type: ARCHIVE_TC_SUCCESS,
    payload: tc,
  })