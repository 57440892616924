/* TC */
export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES"
export const GET_TCS = "GET_TCS"
export const GET_TCS_SUCCESS = "GET_TCS_SUCCESS"
export const GET_TCS_FAIL = "GET_TCS_FAIL"

export const ADD_TC = "ADD_TC"
export const ADD_TC_SUCCESS = "ADD_TC_SUCCESS"
export const ADD_TC_ERROR = "ADD_TC_ERROR"

export const UPDATE_TC = "UPDATE_TC"
export const UPDATE_TC_SUCCESS = "UPDATE_TC_SUCCESS"
export const UPDATE_TC_ERROR = "UPDATE_TC_ERROR"

export const READ_TC = "READ_TC"
export const READ_TC_SUCCESS = "READ_TC_SUCCESS"

export const STATUS_TC = "STATUS_TC"
export const STATUS_TC_SUCCESS = "STATUS_TC_SUCCESS"

export const ARCHIVE_TC = "ARCHIVE_TC"
export const ARCHIVE_TC_SUCCESS = "ARCHIVE_TC_SUCCESS"