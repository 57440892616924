/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from "axios";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Spinner,
    Label, Form,
    Collapse
} from "reactstrap"

import moment from "moment";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";


var fromDate = moment().subtract(6, 'days')
var toDate = moment()
var loader = false

// var categoryList = ['Arts & Entertainment','Health & Fitness','Science','Automotive','Hobbies & Interests','Shopping',
// 'business','Home & Garden','Society','Careers',
// 'News','Sports','Dating','Finance','Style & Fashion','Education','Pets',
// 'Technology & Computing','Family & Parenting','Real Estate','Travel','Food & Drink']

const Brand_Safety = props => {

    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [isEdit, setIsEdit] = useState(false)
    const dispatch = useDispatch();
    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const [categoryList, setCategoryList] = useState([
        {
            label: 'Arts & Entertainment',
            value: false
        },
        {
            label: 'Health & Fitness',
            value: false
        },
        {
            label: 'Science',
            value: false
        },
        {
            label: 'Automotive',
            value: false
        },
        {
            label: 'Hobbies & Interests',
            value: false
        },
        {
            label: 'Shopping',
            value: false
        },
        {
            label: 'business',
            value: false
        },
        {
            label: 'Home & Garden',
            value: false
        },
        {
            label: 'Society',
            value: false
        },
        {
            label: 'Careers',
            value: false
        },
        {
            label: 'News',
            value: false
        },
        {
            label: 'Sports',
            value: false
        },
        {
            label: 'Dating',
            value: false
        },
        {
            label: 'Finance',
            value: false
        },
        {
            label: 'Style & Fashion',
            value: false
        },
        {
            label: 'Education',
            value: false
        },
        {
            label: 'Pets',
            value: false
        },
        {
            label: 'Technology & Computing',
            value: false
        },
        {
            label: 'Family & Parenting',
            value: false
        },
        {
            label: 'Real Estate',
            value: false
        },
        {
            label: 'Travel',
            value: false
        },
        {
            label: 'Food & Drink',
            value: false
        },
    ]
    );

    const [blockedList, setBlockedList] = useState([
        {
            label: 'Adult',
            value: true
        },
        {
            label: 'Gambling',
            value: true
        },
        {
            label: 'Religion & Spirituality',
            value: true
        },
        {
            label: 'Alcohol',
            value: true
        },
        {
            label: 'Law, Govt & Politics',
            value: true
        },
        {
            label: 'Tobacco',
            value: true
        },
    ])

    const [subCategoryList, setSubCategoryList] = useState([{ categoryName: '' }])

    const handleSafetyChange = (index) => (e) => {
        let categoryLists = [...categoryList]
        categoryLists[index].value = e.target.checked
        setCategoryList(categoryLists)
    }
    const tagChange = (index) => (e) => {
        let subCategories = [...subCategoryList]
        subCategories[index].categoryName = e.target.value
        setSubCategoryList(subCategories)
    }

    const removeCategory = (index) => (e) => {
        let subCategories = [...subCategoryList]
        subCategories.splice(index, 1)
        setSubCategoryList(subCategories)
    }

    const addNewCategory = () => {
        let subCategories = [...subCategoryList]
        subCategories.push({ categoryName: '' })
        setSubCategoryList(subCategories)
    }

    const [domainList, setDomainList] = useState([{ domainName: '' }])

    const domainChange = (index) => (e) => {
        let domains = [...domainList]
        domains[index].domainName = e.target.value
        setDomainList(domains)
    }

    const removeDomian = (index) => (e) => {
        let domains = [...domainList]
        domains.splice(index, 1)
        setDomainList(domains)
    }

    const addNewDomain = () => {
        let domains = [...domainList]
        if (domains[domainList.length - 1].domainName === '') {
            toastr.warning('Please enter Domain Name')
        } else {
            let domainValidation = isValidDomain(domains[domainList.length - 1].domainName)
            console.log(domainValidation)
            if (domainValidation) {
                domains.push({ domainName: '' })
                setDomainList(domains)
            }
            else {
                toastr.warning('Please enter Valid Domain Name')
            }
        }
    }

    function isValidDomain(str) {
        let regex = new RegExp(/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/);
        if (str == null) {
            return false;
        }
        if (regex.test(str) == true) {
            return true;
        }
        else {
            return false;
        }
    }


    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
            partnerID: userInfo.userID,
            userRole: userInfo.userRole,
            partnerName: userInfo.name,
            parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
    }

    useEffect(() => {
        readBrandSafety()
    }, []);

    const readBrandSafety = () => {
        axios.post("/v2.0/operations/BrandSafety/read", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data.response.length > 0) {
                    setIsEdit(true)
                    setCategoryList(res.data.response[0].brandSafety.blockAdvertiserCategory)
                    setSubCategoryList(res.data.response[0].brandSafety.addSubCategoryToBlock)
                    setDomainList(res.data.response[0].brandSafety.blockAdvertiserDomain)
                } else {
                    setIsEdit(false)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let domains = [...domainList]
        if (domains[domainList.length - 1].domainName === '') {
            toastr.warning('Please enter Domain Name')
        } else {
            let domainValidation = isValidDomain(domains[domainList.length - 1].domainName)
            console.log(domainValidation)
            if (domainValidation) {
                let safetyBody = {
                    url: "/v2.0/operations/BrandSafety/create",
                    "requestBody": {
                        partnerID: userInfo.userID,
                        userRole: userInfo.userRole,
                        partnerName: userInfo.name,
                        "brandSafety": {
                            "blockAdvertiserCategory": categoryList,
                            "addSubCategoryToBlock": subCategoryList,
                            "blockAdvertiserDomain": domainList
                        },
                    }
                }
                if (isEdit) {
                    safetyBody.url = "/v2.0/operations/BrandSafety/update"
                }
                axios.post(safetyBody.url, safetyBody.requestBody,
                    {
                        headers: headers
                    })
                    .then((res) => {
                        console.log("result is ==>", res)
                        if (res.data.success) {
                            if (isEdit) {
                                toastr.success('Brand Safety Updated Successfully!...')
                            } else {
                                toastr.success('Brand Safety Created Successfully!...')
                            }
                            readBrandSafety()
                        }
                    })
                    .catch((err) => {
                        console.log("e", err)
                    });
            }
            else {
                toastr.warning('Please enter Valid Domain Name')
            }
        }
    }

    const createUI = () => {
        return categoryList.map((item, i) =>
            <div className="col-md-4" key={i}>
                <div className="form-check  mt-2 mb-2">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={item.value}
                        name={item.label}
                        id={item.label}
                        onChange={handleSafetyChange(i)}
                    />
                    <label
                        className="form-check-label" htmlFor={item.label}
                    >
                        {item.label}
                    </label>
                </div>
            </div>
        )
    }

    const createBlockedCategoryUI = () => {
        return blockedList.map((item, i) =>
            <div className="col-md-4" key={i}>
                <div className="form-check  mt-2 mb-2">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={item.value}
                        name={item.label}
                        id={item.label}
                        disabled
                    // onChange={changeSelection(index)}
                    />
                    <label
                        className="form-check-label" htmlFor={item.label}
                    >
                        {item.label}
                    </label>
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
                <div className="page-content">
                    <Helmet>
                        <title>Brand Safety | Bidsxchange</title>
                    </Helmet>
                    <Container fluid={true}>
                        <SupplyBreadCrumb title="Admin" breadcrumbItem="Brand Safety" />
                        <Row>
                            <div className="col-12">
                                <Card>
                                    <CardBody>
                                        <Form onSubmit={handleSubmit}>
                                            <Row >
                                                <div >
                                                    <div className="accordion" id="accordion">
                                                        <div className="accordion-item b-0" style={{ border: 'none' }}>
                                                            <h5 className="accordion-header" id="headingOne">
                                                                Block Advertiser Category
                                                            </h5>

                                                            <Collapse isOpen={true} className="accordion-collapse">
                                                                <div className="accordion-body pb-0" >
                                                                    <div className="row">
                                                                        {createUI()}
                                                                    </div>
                                                                    <div className="row mt-2">
                                                                        <h5 id="headingOne mt-3">
                                                                            <u> Default Blocked Advertiser Category</u>
                                                                        </h5>
                                                                        {createBlockedCategoryUI()}
                                                                    </div>
                                                                    <div className="row mt-2">
                                                                        <h5 id="headingOne mt-3">
                                                                            <u> Add Sub-Category to Block</u>
                                                                        </h5>
                                                                        <div >
                                                                            {
                                                                                subCategoryList.map((subItem, i) => {
                                                                                    return (
                                                                                        <div className="vast-settings" key={i}>
                                                                                            <div className="accordion-body">
                                                                                                    <div className="form-group">
                                                                                                        <div className="row ">
                                                                                                            <div className="col-6">
                                                                                                                <Input
                                                                                                                    name="categoryName"
                                                                                                                    type="text"
                                                                                                                    value={subItem.categoryName}
                                                                                                                    onChange={tagChange(i)}
                                                                                                                    placeholder='Enter Sub-Category'
                                                                                                                >
                                                                                                                </Input>
                                                                                                            </div>
                                                                                                            <div className="col-2">
                                                                                                                <div className='row form-group '>
                                                                                                                    <Button type="button" className='btn btn-danger ml-auto ' style={{ marginRight: '1pc', marginTop: '0pc', background: '#dc3545', float: 'right', width: '3pc', }} onClick={removeCategory(i)} disabled={subCategoryList.length === 1}
                                                                                                                    >X</Button>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                            <div className='row'>
                                                                                <Button
                                                                                    color="primary"
                                                                                    className="font-16 btn-block btn btn-primary"
                                                                                    style={{ margin: '1% 2rem 0% ', width: 'auto' }}
                                                                                    onClick={addNewCategory}
                                                                                >
                                                                                    <i className="mdi mdi-plus-circle-outline me-1" />
                                                                                    Add Sub-Category
                                                                                </Button>
                                                                            </div>
                                                                            <Row >
                                                                                <div className="accordion mt-4" id="accordion">
                                                                                    <div className="accordion-item b-0 mt-2" style={{ border: 'none' }}>
                                                                                        <h5 className="accordion-header" id="headingOne">
                                                                                            Block Advertiser Domain
                                                                                        </h5>
                                                                                        <Collapse isOpen={true} className="accordion-collapse">
                                                                                            <div className="accordion-body pb-0">
                                                                                                <div className="row ">
                                                                                                    {
                                                                                                        domainList && domainList.length > 0 && domainList.map((subItem, i) => {
                                                                                                            return (
                                                                                                                <div className="vast-settings mt-4" key={i}>
                                                                                                                        <div >
                                                                                                                            <div className="form-group">
                                                                                                                                <div className="row ">
                                                                                                                                    <div className="col-6">
                                                                                                                                        <Input
                                                                                                                                            name="domainName"
                                                                                                                                            type="text"
                                                                                                                                            value={subItem.domainName}
                                                                                                                                            onChange={domainChange(i)}
                                                                                                                                            placeholder='Enter Domain'
                                                                                                                                        >
                                                                                                                                        </Input>
                                                                                                                                    </div>
                                                                                                                                    <div className="col-2">
                                                                                                                                        <div className='row form-group '>
                                                                                                                                            <Button type="button" className='btn btn-danger ml-auto ' style={{ marginRight: '1pc', marginTop: '0pc', background: '#dc3545', float: 'right', width: '3pc', }} onClick={removeDomian(i)} disabled={domainList.length === 1}
                                                                                                                                            >X</Button>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                    <div className='row'>
                                                                                                        <Button
                                                                                                            color="primary"
                                                                                                            className="font-16 btn-block btn btn-primary"
                                                                                                            style={{ margin: '1.5pc 0.8pc 0% ', width: 'auto' }}
                                                                                                            onClick={addNewDomain}
                                                                                                        >
                                                                                                            <i className="mdi mdi-plus-circle-outline me-1" />
                                                                                                            Add Domain
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Collapse>
                                                                                    </div>
                                                                                </div>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col>
                                                                                    <div className=" text-sm-end">
                                                                                        {!isEdit &&
                                                                                            <button
                                                                                                type="submit"
                                                                                                className="btn btn-primary save-user"
                                                                                            >
                                                                                                Submit
                                                                                            </button>
                                                                                        }
                                                                                        {isEdit &&
                                                                                            <button
                                                                                                type="submit"
                                                                                                className="btn btn-primary save-user"
                                                                                            >
                                                                                                Update
                                                                                            </button>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </div>
                        </Row>

                    </Container>
                </div>
            {/* </div> */}
        </React.Fragment >
    )
}

Brand_Safety.propTypes = {}

export default Brand_Safety