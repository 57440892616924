import {
    GET_MARKET_CONTENTS_SUCCESS,
    GET_MARKET_CONTENTS_FAIL,
    LOAD_MARKET_CONTENTS,
    DELETE_MARKET_CONTENT_SUCCESS,
    DELETE_MARKET_CONTENT_ERROR,
    ADD_MARKET_CONTENTS_SUCCESS,
    ADD_MARKET_CONTENTS_FAIL
} from "./actionTypes"

const INIT_STATE = {
    vcons: [],
    error: {},
    playlistErr: null,
    queuedMsg: null,
    successMsg: null,
    errorMsg: null,
    marketContent: null,
    response: null,
    loading: false
}

const marketContents = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_MARKET_CONTENTS:
            return {
                ...state,
                loading: true,
            }
        case GET_MARKET_CONTENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                vcons: action.payload,
            }

        case GET_MARKET_CONTENTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case ADD_MARKET_CONTENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                successMsg: action.payload,
            }

        case ADD_MARKET_CONTENTS_FAIL:
            return {
                ...state,
                loading: false,
                errorMsg: action.payload,
            }
        case DELETE_MARKET_CONTENT_SUCCESS:

            return {
                ...state,
                loading: false,
                successMsg: action.payload,
            }
        case DELETE_MARKET_CONTENT_ERROR:
            return {
                ...state,
                loading: false,
                playlistErr: action.payload,
                errorMsg: action.type === 'GET_MARKET_CONTENTS_SUCCESS' ? null : action.payload,
            }

        default:
            return state
    }
}

export default marketContents