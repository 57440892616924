/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react"
import {
    Card, CardBody, Col, Form, Input, Label, Row, Spinner, Button, Progress, Nav,
    NavItem,
    NavLink, TabContent,
    TabPane
} from "reactstrap"
import axios from "axios"
import classnames from "classnames";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

var videoContentFileName = ""

const Add_Import = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [inputValue,setInputValue] = useState('')

    const DOWNLOAD_URL_PLAYLIST = 'http://127.0.0.1:2005/downloadplaylist';
    // const DOWNLOAD_URL_PLAYLIST = 'http://1.0.77.245:2005/downloadplaylist'
    
    const savePlaylistData = list => {
        props.getPlaylistInfo(list)
        props.toggleSetPlaylsit()
    }

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };




    const handleDownload = async () => {
        try {
            const response = await axios.get(inputValue, { responseType: 'blob' });
            const videoBlob = response.data;
            const videoName = inputValue.match(/(?<=\/)(\w|-)+(?:\.\w+)?(?=$|\?)/)[0];
            const videoType = videoBlob.type
            videoContentFileName = videoName

            const videoFile = new File([videoBlob], `video_${videoName}`, { type: 'video/mp4' });

            const videoUrl = URL.createObjectURL(videoFile);


            console.log(videoBlob.type)
            setLoader(true);
            props.onVideoUpload(videoUrl);
            props.onVideoName(videoName)

            const formData = new FormData()
            let newFile = videoFile
            formData.append("data", videoFile)


            const toBase64 = (videoFile) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(videoFile);
                    reader.onload = () => {
                        var media = new Audio(reader.result);
                        media.onloadedmetadata = function () {
                            media.duration; // this would give duration of the video/audio file
                            setVideoContent((videoContent) => ({
                                ...videoContent,
                                videoContentDuration: media.duration,
                            }));
                            props.saveData({ videoContentDuration: media.duration });
                        };
                        resolve(reader.result);
                    };
                    reader.onerror = (error) => reject(error);
                    console.log(reader.result);
                });

            var newBaseData = toBase64(newFile);
            const newBaseContent = {};

            (async () => {
                newBaseContent.data = await newBaseData;
                newBaseContent.fileName = videoName;
                newBaseContent.type = videoType;
                newBaseContent.userID = userInfo.userID;
                newBaseContent.userRole = userInfo.userRole;
                newBaseContent.companyID = userInfo.companyID;
                console.log(newBaseContent);
                axios.post("/v2.0/reportUpload/upload/uploadFile", newBaseContent, {
                        headers: headers,
                        onUploadProgress: (progressEvent) => {
                            const progress = Math.round(
                                (progressEvent.loaded / progressEvent.total) * 100
                            );
                            setUploadProgress(progress);
                        },
                    })
                    .then((res) => {
                        console.log("Video Upload ==>", res);
                        setLoader(false);
                        setVideoContent({
                            videoContentPath: `/home/ubuntu/videoContentData/${videoContentFileName}`,
                        });
                        props.saveData({
                            videoContentPath: `/home/ubuntu/videoContentData/${videoContentFileName}`,
                        });
                        props.changeActiveState(2);
                    })
                    .catch((err) => {
                        setLoader(false);
                        console.log("e", err);
                    });
            })();



            // const link = document.createElement('a');
            // link.href = videoUrl;
            // link.download = 'video.mp4';
            // link.click();
        } catch (error) {
            console.error(error);
        }
    };

    const get_info = async () => {
        try {
            const body = {
                url: 'http://127.0.0.1:2024/downloadplaylist',
                requestBody: {
                  url: inputValue,
                  userID: userInfo.userID,
                  userRole: userInfo.userRole,
                  companyID: userInfo.companyID,
                },
              }
          const playlistInfo = await axios.post("/v1.0/connectOP", body,{
            headers:headers,
          });
          console.log(playlistInfo);
          const playlist = playlistInfo.data;
          savePlaylistData(playlist)
          console.log(playlist);
          // for (const item of playlist) {
          //   console.log(item)
          //   const videoUrl = `${DOWNLOAD_URL_VIDEO}?url=${item.shortUrl}`;
          //   console.log(videoUrl)
          //   const videoInfo = await axios.get(videoUrl, { responseType: 'blob' });
          //   const blob = videoInfo.data;
          //   setVideoBlob(blob)
          // const filename = `${item.title}.mp4`;
          // const downloadLink = document.createElement('a');
          // downloadLink.href = URL.createObjectURL(blob);
          // downloadLink.download = filename;
          // downloadLink.click();
          // URL.revokeObjectURL(downloadLink.href);
          { }
        } catch (error) {
          console.error(error);
        }
      }
      


    return (
        <Col md="12">
            {/* <Form  method="post"> */}
            {/* <Form action="https://support-ticket.bidsxchange.com/create" encType="multipart/form-data" method="post"> */}
            <div style={{ padding: '1% 3%', height: '53vh', overflow: 'auto' }} className="d-flex justify-conent-center align-items-center">
                <Col sm="12">
                    <Row>
                        <Col sm="12">
                            <div className="d-flex flex-column">
                                <Label htmlFor="youtube-link">Enter Playlist link:</Label>
                                <Input type="text" id="youtube-link" name="youtube-link" placeholder="https://www.youtube.com/watch?v=..." value={inputValue}  onChange={(e) => setInputValue(e.target.value)} />
                                {/* {videoId && <iframe src={embedUrl} title="Embedded YouTube video" width="560" height="315" />} */}
                                <div className="d-flex justify-content-center align-items-center">
                                    <Button className="mt-5" onClick={get_info}>Get Info</Button>
                                </div>
                                {/* {loader &&
                                    <Progress
                                        className="mt-2"
                                        animated
                                        color="success"
                                        style={{
                                            height: '15px'
                                        }}
                                        striped
                                        value={uploadProgress}
                                    >{uploadProgress}%</Progress>
                                } */}

                            </div>
                        </Col>
                    </Row>
                </Col>
            </div>
            {/* </Form> */}
        </Col>
    )
}

Add_Import.propTypes = {}

export default Add_Import