/* eslint-disable react/prop-types */
import React, { useRef, useEffect, useState } from 'react';
import {
    Card, CardBody, Col, Form, Input, Label, Row, Spinner, Button, Progress, Nav,
    NavItem,
    NavLink, TabContent,
    TabPane
} from "reactstrap";
import axios from 'axios';
import classnames from "classnames";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";


var videoThumbnailFileName = ''
var thumbnailUrlCaptured = ""
 
const Select_Thumbnail = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [thumbnailUrl, setThumbnailUrl] = useState("");
    const [videoLoaded, setVideoLoaded] = useState(false);
    const [loader, setLoader] = useState(false);
    const [frameBlob, setFrameBlob] = useState(null);
    console.log(props)
    console.log(props.videoFile)
    console.log(props.videoFileName)
    const [videoContentThumbnailPath, setVideoContentThumbnailPath] = useState("");

    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [thumbnailState, setThumbnailState] = useState("selectUpload");

    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        progressBar: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 5000,
        extendedTimeOut: 1000
    };

    //old one
    // function handleVideoTimeUpdate() {
    //     console.log("called handle video time update")
    //     const video = videoRef.current;
    //     const canvas = canvasRef.current;
    //     canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
    //     const thumbnailUrl = canvas.toDataURL();
    //     canvas.toBlob((blob) => {
    //         // call another function and pass the blob object as an argument
    //         setFrameBlob(blob)
    //         uploadSelectedFrame(blob)

    //     }, 'image/png', 0.8);
    //     // console.log(canvas);
    //     console.log(thumbnailUrl);
    //     thumbnailUrlCaptured =  thumbnailUrl;

    // }

    //new
    function handleVideoTimeUpdate() {
        console.log("called handle video time update")
        const video = videoRef.current;
        const canvas = canvasRef.current;
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
        const thumbnailUrl = canvas.toDataURL();
        canvas.toBlob((blob) => {
            uploadSelectedFrame(blob);
        }, 'image/png', 0.2);
        console.log(thumbnailUrl);
        setThumbnailUrl(thumbnailUrl);
    }




    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }


    const fileChangeThumbnail = (file) => {
        setLoader(true)
        props.updateThumbnailData()

        console.log(file.target.files[0])
        const fileName = file.target.files[0].name
        const fileType = file.target.files[0].type
        console.log("fileName=====================", fileName, file)
        videoThumbnailFileName = file.target.files[0].name

        const formData = new FormData();
        let newFile = file.target.files[0];
        formData.append("file", newFile);



        (async () => {


            const body = formData

            axios.post("/uploadThumbnail", body, {
                headers: headers
            })
                .then((res) => {
                    console.log("Thumbnail Upload ==>", res)

                    setLoader(false)
                    setVideoContentThumbnailPath(`/app/videocontents/thumbnails/${fileName}`);
                    props.saveData({ videoContentThumbnailPath: `/app/videocontents/thumbnails/${fileName}` })
                    // toastr.success("Frame uploaded successfully")


                })
                .catch((err) => {
                    setLoader(false)
                    toastr.error(err)
                    console.log("e", err)
                });
        })();
    };


    const handleVideoLoadedData = () => {
        setVideoLoaded(true);
    }



    const uploadSelectedFrame = async (blob) => {
        setLoader(true)
        const rawfileName = props.videoFileName
        const altFileName = rawfileName.replace(".mp4", "")
        const fileName = `${altFileName}.png`

        const formData = new FormData();
        formData.append('file', blob, fileName);
        formData.append('type', 'image/png');


        const body = formData

        axios.post("/uploadThumbnail", body, {
            headers: headers
        })
            .then((res) => {
                console.log("Thumbnail Upload ==>", res)


                setLoader(false)
                setVideoContentThumbnailPath(`/app/videocontents/thumbnails/${fileName}`);
                props.saveData({ videoContentThumbnailPath: `/app/videocontents/thumbnails/${fileName}` })
                // toastr.success("Frame uploaded successfully")


            })
            .catch((err) => {
                setLoader(false)
                toastr.error(err)
                console.log("e", err)
            });
    }


    console.log(videoContentThumbnailPath)

    const handleDirectChange = e => {
        const { name } = e.target;
        console.log(name)
        setThumbnailState(name)
    }


    return (
        <Col sm="12">
            {/* <Nav tabs className="nav-tabs-custom nav-justified" style={{ width: '20pc' }}>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "1",
                                })}
                                onClick={() => {
                                    toggleCustom("1");
                                }}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="fas fa-home"></i>
                                </span>
                                <span className="d-none d-sm-block" style={{ fontSize: '13px' }}>Upload Thumbnail</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "2",
                                })}
                                onClick={() => {
                                    toggleCustom("2");
                                }}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="far fa-user"></i>
                                </span>
                                <span className="d-none d-sm-block" style={{ fontSize: '13px' }}>Select Manual</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent
                        activeTab={customActiveTab}
                        className="p-3 text-muted"
                    >
                        <TabPane tabId="1">
                            {loader &&
                                <Spinner className="ms-2 loader" color="primary" />
                            }

                        </TabPane>
                        <TabPane tabId="2">

                        </TabPane>
                    </TabContent> */}

            <div className="mb-2">
                <Label className="form-label">Select Thumbnail</Label>
                <div className='row'>
                    <Col md={3}>
                        <div className="d-flex justify-content-start align-items-center form-check form-switch form-check-success form-switch-md" style={{ paddingLeft: "1rem" }}>
                            <input
                                className="form-check-input m-1"
                                type="checkbox"
                                name={'selectUpload'}
                                id={'fileUpload'}
                                checked={thumbnailState === 'selectUpload'}
                                onChange={handleDirectChange}
                            />
                            <label className="form-check-label  m-2 mt-1 m-1" htmlFor={'fileUpload'}> File Upload</label>

                        </div>
                    </Col>
                    {props.linkType === "mp4" &&
                        <Col md={3}>
                            <div className='d-flex justify-content-start align-items-center form-check form-switch form-check-success form-switch-md' style={{ paddingLeft: "0rem" }}>
                                <input
                                    className="form-check-input m-1"
                                    type="checkbox"
                                    name={'selectManually'}
                                    id={'fileManually'}
                                    checked={thumbnailState === 'selectManually'}
                                    onChange={handleDirectChange}
                                />
                                <label className="form-check-label  m-2 mt-1 m-1" htmlFor={'fileManually'}> Select Manually  </label>
                            </div>
                        </Col>
                    }
                </div>

                {thumbnailState === 'selectManually' && props.linkType === "mp4" &&
                    <Row>
                        <Col md="12">
                            <p style={{ fontWeight: "500" }}>Choose Your Thumbnail: </p>
                            <div className='d-flex justify-content-around align-items-start'>
                                <div className='d-flex flex-column justify-content-between'>


                                    <video className='m-2' ref={videoRef} src={props.videoFile} controls width={426} height={240} crossOrigin="anonymous" onLoadedData={handleVideoLoadedData}>
                                        {/* <source  type="video/mp4" /> */}
                                    </video>
                                    <Button className=' text-right m-3' onClick={handleVideoTimeUpdate} disabled={!videoLoaded}>capture</Button>
                                </div>
                                <canvas ref={canvasRef} width={640} height={360} className="d-none" />
                                {thumbnailUrl ? <img className='single-frame m-1 ' src={thumbnailUrl} crossOrigin="anonymous" alt="Video Thumbnail" /> : <div className='single-frame d-flex justify-content-center align-items-center' style={{ backgroundColor: "gray" }}> <h6>Capture dynamically which frame you want</h6></div>}
                                {/* <Button className='m-3' onClick={uploadSelectedFrame} >Save Frame</Button> */}
                                {/* {thumbnailUrl && <img className='single-frame' src={thumbnailUrl} crossOrigin="anonymous" alt="Video Thumbnail" />} */}

                            </div>
                        </Col>
                    </Row>
                }
                {thumbnailState === 'selectUpload' &&
                    <Row>
                        <Col sm="9">
                            <div className="mb-3">
                                <Label className="form-label">Browse Thumbnail Image File</Label>
                                <div>
                                    <Input type="file" accept="image/x-png,image/jpg" onChange={fileChangeThumbnail} />
                                    <span className="notes">Note: Allowed file types: jpg, png. Max-size 5Mb</span>
                                </div>
                                {/* {submitted && !videoContent.videoContentThumbnailPath ? (
                                               <FormFeedback type="invalid">{'VideoContent Name is required'}</FormFeedback>
                                           ) : null} */}
                            </div>

                        </Col>
                    </Row>
                }
            </div>


        </Col>
    )
}

Select_Thumbnail.propTypes = {}

export default Select_Thumbnail




