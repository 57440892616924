/* PARTNER */
export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES"
export const NO_DATA_PARTNER = "NO_DATA_PARTNER"
export const GET_PARTNERS = "GET_PARTNERS"
export const GET_PARTNERS_SUCCESS = "GET_PARTNERS_SUCCESS"
export const GET_PARTNERS_FAIL = "GET_PARTNERS_FAIL"

export const ADD_PARTNER = "ADD_PARTNER"
export const ADD_PARTNER_SUCCESS = "ADD_PARTNER_SUCCESS"
export const ADD_PARTNER_ERROR = "ADD_PARTNER_ERROR"

export const UPDATE_PARTNER = "UPDATE_PARTNER"
export const UPDATE_PARTNER_SUCCESS = "UPDATE_PARTNER_SUCCESS"
export const UPDATE_PARTNER_ERROR = "UPDATE_PARTNER_ERROR"

export const READ_PARTNER = "READ_PARTNER"
export const READ_PARTNER_SUCCESS = "READ_PARTNER_SUCCESS"

export const STATUS_PARTNER = "STATUS_PARTNER"
export const STATUS_PARTNER_SUCCESS = "STATUS_PARTNER_SUCCESS"

export const ARCHIVE_PARTNER = "ARCHIVE_PARTNER"
export const ARCHIVE_PARTNER_SUCCESS = "ARCHIVE_PARTNER_SUCCESS"

export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"

export const ADD_USER = "ADD_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_ERROR = "ADD_USER_ERROR"

export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR"

export const GET_PARTNERS_LIST = "GET_PARTNERS_LIST"
export const GET_PARTNERS_SUCCESS_LIST = "GET_PARTNERS_SUCCESS_LIST"
export const GET_PARTNERS_FAIL_LIST = "GET_PARTNERS_FAIL_LIST"