import React, { useState, useEffect,useRef  } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col,  Modal,Button,  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,  Card,
  CardBody,
  CardTitle,
  CardText,
  Input, } from "reactstrap";
import SimpleBar from "simplebar-react";


//i18n
import { withTranslation } from "react-i18next";

  //redux
  import { useSelector, useDispatch } from "react-redux";

  //Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

  import {
    getNotRead_Notifications as onGetNotificationLists,
  } from "store/Notifications/Publisher_Notification/actions";

import axios from "axios";

var selectedItem = []
var selectedIndex = []

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [selectedNotification, setSelectedNotification] = useState({})
  const [jsTagModalState, setJsTagModalState] = useState(false);
  const [isRight, setIsRight] = useState(false);
  const [reason, setReason] = useState('');
  const [rejectedState, setRejectedState] = useState(false);

  const publisherApprovalPage = useRef();

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 1000,
    extendedTimeOut: 1000
  };

const { notificationLists } = useSelector(state => ({
  notificationLists: state.pub_notifications.notReadNotList,
}));

const toggleRightCanvas = () => {
  setIsRight(!isRight);
};

// useEffect(() => {
//   if (notificationLists !== null) {
//     dispatch(onGetNotificationLists({isRead:'notRead'}));
//   }
// }, []);

function add3Dots(string, limit)
{
  var dots = "...";
  if(string.length > limit)
  {
    string = string.substring(0,limit) + dots;
  }

    return string;
}

function tog_standard(notification) {
  console.log(notification)
  if (notification !== '') {
    setSelectedNotification(notification)
    setJsTagModalState(!jsTagModalState);
  } else {
    setJsTagModalState(!jsTagModalState);
  }
}

const readAllNotification = (event) => {
  if (notificationLists.length > 0 ){
      notificationLists.map((item,index) => {
        selectedItem.push(item.notificationID)
      })
      statusNotification('readNotification','all')
  }

}

const readNotification = (notificationID,status) => (event) => {
  selectedItem.push(notificationID)
  console.log(notificationID)
  statusNotification(status,'')
}

  const statusNotification = (status,isAll) =>{
    console.log('clicked',status)
    let body2 = {
        userID: userInfo.userID,
        notificationID: selectedItem,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name:userInfo.name,parentID:userInfo.parentID,isSupplyPartner:userInfo.isSupplyPartner,isForSupplyPartner:userInfo.isForSupplyPartner
      }
      axios.post(userInfo.isSupplyPartner ? "/v2.0/notifications/updateIsRead/SupplyPartner" : "/v2.0/notifications/updateIsRead", body2,
        {
          headers: headers
        })
        .then((res) => {
          console.log("result is notifications ==>", res)
          if (res.data.success) {
            if(status === 'takeAction'){
              selectedItem = []
              dispatch(onGetNotificationLists({isRead:'notRead', userRole: userInfo.userRole,isSupplyPartner:userInfo.isSupplyPartner}));
              publisherApprovalPage.current.click()
              setIsRight(!isRight);
            }
            else{
              toastr.success('Notification Marked as Read Successfully!...')
              selectedItem = []
              setJsTagModalState(false)
              dispatch(onGetNotificationLists({isRead:'notRead', userRole: userInfo.userRole,isSupplyPartner:userInfo.isSupplyPartner}));
              if(isAll === 'all'){
                setIsRight(!isRight);
              }
            }
          }
        })
        .catch((err) => {
          console.log("e", err)
        });
  }

  return (
    <React.Fragment>
      {/* <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        color="primary"
        onClick={function noRefCheck(){}}
        className="dropdown d-inline-block"
        tag="li"
        direction='end'
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className={notificationLists.length > 0 ? 'bx bx-bell bx-tada' : 'bx bx-bell'} />
          {notificationLists.length > 0 &&
              <span className="badge bg-danger rounded-pill">{notificationLists.length}</span>
          }
         
        </DropdownToggle>

        <DropdownMenu end className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h5 className="m-0"> {props.t("Notifications")} </h5>
              </Col>
            </Row>
          </div>
          {notificationLists.length > 0 &&
          <SimpleBar style={{ height: "430px" }}>
              { notificationLists.map((item,index) => {
                return (
                  <div className="d-flex p-2" key={index}>
                  <div className="avatar-xs me-3">
                    <span className="avatar-title bg-primary rounded-circle font-size-16">
                      <i className="mdi mdi-clock-outline" />
                    </span>
                  </div>
                  <div className="flex-grow-1">
                    <p className="mt-0 mb-1">
                      {add3Dots(item.notificationName,30)}
                    </p>
                    <div className="font-size-12 text-muted">
                      <h6 className="mb-1">
                        {add3Dots(item.message,40)}   &nbsp;     <Link  to={'#'} onClick={() => { tog_standard(item); }}>
                  <u>View</u> 
                </Link>
                      </h6>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />
                        {item.notificationCreateTime}
                      </p>
                    </div>
                  </div>
                </div>
                )
              }) 
              }
       
          </SimpleBar>
          }
          {notificationLists.length === 0 &&
               <SimpleBar style={{ height: "100px" }}>
                    <h5 className="text-center mt-4">No Notifications</h5>
               </SimpleBar>
          }
          {notificationLists.length > 0 &&
                   <div className="p-2 border-top d-grid">
                     {userInfo.userRole === 'Partner'&&
                           <Link className="btn btn-sm btn-link font-size-14 text-center" to="/User_Profile" onClick={() => localStorage.setItem("activeTab", 3)}>
                           <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{props.t("View More..")}</span>
                         </Link>
                      }
                      { userInfo.userRole === 'Root' &&
                            <Link className="btn btn-sm btn-link font-size-14 text-center" to="/profile">
                            <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{props.t("View More..")}</span>
                          </Link>
                      }
             
                 </div>
          }
     
        </DropdownMenu>
      </Dropdown> */}

      <Button
      onClick={toggleRightCanvas}
         className="btn  header-item noti-icon"
         tag="button"
         id="page-header-notifications-dropdown"
         style={{background:'none'}}
       >
         <i className={notificationLists.length > 0 ? 'bx bx-bell bx-tada' : 'bx bx-bell'} />
         {notificationLists.length > 0 &&
             <span className="badge bg-dark rounded-pill">{notificationLists.length}</span>
         }

      </Button>
      <Offcanvas isOpen={isRight} direction='end' className="NotificationEnd">
        <OffcanvasHeader toggle={toggleRightCanvas} style={{padding:'0.5rem 1rem'}}>
        <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h5 className="m-0"> {props.t("Notifications")} </h5>
              </Col>
            </Row>
          </div>
        </OffcanvasHeader>
        <OffcanvasBody style={{padding:'1rem 0.2rem'}}>
   
          {notificationLists.length > 0 &&
          <SimpleBar style={{ maxHeight: "80vh" ,overflow:'auto',padding:'0.3rem'}}>
              { notificationLists.map((item,index) => {
                return (
                  <Card key={index}  className="mb-2">
                  <CardBody>
                    <CardTitle className="mt-0"> 
                     <span className=" rounded-circle font-size-12">
                      <i className="mdi mdi-clock-outline" />  &nbsp;&nbsp;  {item.notificationCreateTime}
                    </span> 
                    <h5>{item.notificationName}</h5>
                    </CardTitle>
                    <CardText>
                    <span dangerouslySetInnerHTML={{ __html: item.message }}></span> 
                    </CardText>
                    <div className="col-12">
                      <div className="text-sm-end">
                              <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                {(item.notificationInfo === 'AdFormat' || item.notificationInfo === 'ReportAPIAccess' ) ?
                                     <button type="button" className="btn btn-primary"  onClick={readNotification(item.notificationID,'takeAction')} >
                                     <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">Take Action</span>
                                   </button> :  <button type="button" className="btn btn-primary" onClick={readNotification(item.notificationID,'readNotification')}
                                  disabled={item.isRead}>Mark as Read</button>
                                }
                                 </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                //   <div className="d-flex p-2" >
                //   <div className="avatar-xs me-3">
                  
                //   </div>
                //   <div className="flex-grow-1">
                //     <p className="mt-0 mb-1">
                //       {add3Dots(item.notificationName,30)}
                //     </p>
                //     <div className="font-size-12 text-muted">
                //       <h6 className="mb-1">
                //         {add3Dots(item.message,40)}   &nbsp;     <Link  to={'#'} onClick={() => { tog_standard(item); }}>
                //   <u>View</u> 
                // </Link>
                //       </h6>
                //       <p className="mb-0">
                //         <i className="mdi mdi-clock-outline" />
                     
                //       </p>
                //     </div>
                //   </div>
                //   </div>
                )
              }) 
              }
            {/*<Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <img
                  src={avatar3}
                  className="me-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1">James Lemire</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t("It will seem like simplified English") +
                        "."}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />
                      {props.t("1 hours ago")}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-success rounded-circle font-size-16">
                    <i className="bx bx-badge-check" />
                  </span>
                </div>
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1">
                    {props.t("Your item is shipped")}
                  </h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t(
                        "If several languages coalesce the grammar"
                      )}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />
                      {props.t("3 min ago")}
                    </p>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <img
                  src={avatar4}
                  className="me-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1">Salena Layfield</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t(
                        "As a skeptical Cambridge friend of mine occidental"
                      ) + "."}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />
                      {props.t("1 hours ago")}
                    </p>
                  </div>
                </div>
              </div>
            </Link>*/}
          </SimpleBar>
          }

          {notificationLists.length === 0 &&
               <SimpleBar style={{ height: "100px" }}>
                    <h5 className="text-center mt-4">No Notifications</h5>
               </SimpleBar>
          }
          {notificationLists.length > 1 &&
             <div className="btn-group btn-group-sm" role="group" aria-label="Basic example" style={{position:'absolute',bottom:'1%'}}>
                <button type="button" className="btn btn-primary"  onClick={readAllNotification}
                >Mark All are Read</button>
             </div>

          }
          {/* {notificationLists.length > 0 &&
                   <div className="p-2 border-top d-grid">
                     {userInfo.userRole === 'Partner'&&
                           <Link className="btn btn-sm btn-link font-size-14 text-center" to="/User_Profile" onClick={() => localStorage.setItem("activeTab", 3)}>
                           <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{props.t("View More..")}</span>
                         </Link>
                      }
                      { userInfo.userRole === 'Root' &&
                            <Link className="btn btn-sm btn-link font-size-14 text-center" to="/profile">
                            <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{props.t("View More..")}</span>
                          </Link>
                      }
             
                 </div>
          } */}
        </OffcanvasBody>
      </Offcanvas>
      <Link ref={publisherApprovalPage} to="/Publisher_Approval"></Link>
      {selectedNotification &&
            <Modal
            size="lg"
            isOpen={jsTagModalState}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                {selectedNotification.notificationName}
              </h5>
              <button
                type="button"
                onClick={() => {
                  setJsTagModalState(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            
            <div className="modal-body">
              <div className="row mb-2">
                <div className="col-md-11">
                  <h5>Message</h5>
                </div>
              </div>
              <p className=" mb-2">{selectedNotification.message}</p>
              <br />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setJsTagModalState(false);
                }}
                className="btn btn-secondary "
                data-dismiss="modal"
              >
                Close
              </button>
        
              <Button
                color="primary"
                className="font-16 btn-block btn btn-primary"
                onClick={readNotification(selectedNotification.notificationID)}
                disabled={selectedNotification.isRead}
              >
                Mark as Read
              </Button>
            </div>
          </Modal>
      }
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};