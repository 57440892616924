/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback, Spinner } from "reactstrap";
import Select from "react-select";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    addNewCategory as onADDCategory,
    updateCategory as onUpdateCategory,
    addCategorySuccess,
    addCategoryFail,
    updateCategorySuccess,
    updateCategoryFail,
} from "store/customTracking/Categories/actions";

import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";

const NewCategory = (props) => {
    const dispatch = useDispatch();
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [category, setCategory] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [loader, setLoader] = useState(false);

    console.log(props)

    useEffect(() => {
        if (props.selectedCategory !== '') {
            setIsEdit(true)
            setCategory(props.selectedCategory)
        }
    }, []);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            houseAdsCategoryName: (category && category.houseAdsCategoryName) || '',
        },
        validationSchema: Yup.object({
            houseAdsCategoryName: Yup.string().required("Category Name is required"),
        }),
        onSubmit: (values) => {
            setLoader(true)
            if (props.selectedCategory !== '') {
                dispatch(onUpdateCategory({ ...values, HouseAdsCategoryID: props.selectedCategory.houseAdsCategoryID,houseAdsCategoryName:values.houseAdsCategoryName.trim() }));
            } else {
                dispatch(onADDCategory({ ...values,houseAdsCategoryName:values.houseAdsCategoryName.trim() }));
            }
        }
    });

    const { errorMsg, successMsg } = useSelector(state => ({
        errorMsg: state.categories.errorMsg,
        successMsg: state.categories.successMsg,
    }));

    console.log(errorMsg, successMsg)

    useEffect(() => {
        if (successMsg !== null) {
            if (successMsg.statusCode === 200) {
                dispatch(addCategorySuccess(""));
                dispatch(addCategoryFail(""));
                dispatch(updateCategorySuccess(""));
                dispatch(updateCategoryFail(""));
                setLoader(false)
                    if (props.selectedCategory !== '') {
                        props.closeCanvas('Update')
                    } else {
                        props.closeCanvas('Create')
                    }
            }
        }
    }, [successMsg]);

    useEffect(() => {
        if (errorMsg !== null && errorMsg !== '') {
          setLoader(false)
          if(errorMsg.status && errorMsg.status === 500){
          }else{
            dispatch(addCategorySuccess(""));
                dispatch(addCategoryFail(""));
                dispatch(updateCategorySuccess(""));
                dispatch(updateCategoryFail(""));
          }
        }
      }, [errorMsg]);
    
      const toggleToast = (n) => (e) => {
        dispatch(addCategorySuccess(""));
        dispatch(addCategoryFail(""));
        dispatch(updateCategorySuccess(""));
        dispatch(updateCategoryFail(""));
            props.closeCanvas('')
      };

    function allowAlphaNumericSpace(e) {
        var code = ('charCode' in e) ? e.charCode : e.keyCode;
        if ( !(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault();}
      };


    return (
        <React.Fragment>
            {loader &&
                <Spinner className="ms-2 loader" color="primary" />
            }
            <div className="col-6" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
                <Form onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); return false; }}>
                    <Row style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
                        <Col xs={12}>
                            <div className="mb-3">
                                <Label className="form-label">Category Name</Label>
                                <Input
                                    name="houseAdsCategoryName"
                                    type="text"
                                    maxLength={65}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    onKeyPress={allowAlphaNumericSpace}
                                    value={validation.values.houseAdsCategoryName || ""}
                                    invalid={
                                        validation.touched.houseAdsCategoryName && validation.errors.houseAdsCategoryName ? true : false
                                    }
                                    readOnly={props.selectedArchived}
                                />
                                {validation.touched.houseAdsCategoryName && validation.errors.houseAdsCategoryName ? (
                                    <FormFeedback type="invalid">{validation.errors.houseAdsCategoryName}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row className="drawer-footer"  style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
                        <Col>
                            <div className="text-start">
                                &nbsp;&nbsp;
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    // eslint-disable-next-line react/prop-types
                                    onClick={props.closeCanvas}
                                >
                                    Back
                                </button>
                                &nbsp;&nbsp;
                                {!isEdit &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Save
                                    </button>
                                }
                                &nbsp;&nbsp;

                                {isEdit && !props.selectedArchived &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Update
                                    </button>
                                }
                            </div>
                        </Col>
                    </Row>
                </Form>
                {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
            <Toaster status="error" msg={errorMsg.message}/>
        }
      </div>
        {errorMsg !== null && errorMsg !== '' &&  errorMsg.status && errorMsg.status === 500 && 
            <div className="p-0 justify-content-center d-flex">
            <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')}/>
          </div>
        }
        </React.Fragment>
    )
}

export default NewCategory