import {
    LOAD_PUB_VIDEO_CONTENTS,
    GET_PUB_VIDEO_CONTENTS,
    GET_PUB_VIDEO_CONTENTS_SUCCESS,
    GET_PUB_VIDEO_CONTENTS_FAIL,
    ADD_PUB_VIDEO_CONTENT,
    ADD_PUB_VIDEO_CONTENT_SUCCESS,
    ADD_PUB_VIDEO_CONTENT_ERROR,
    DELETE_PUB_VIDEO_CONTENT,
    DELETE_PUB_VIDEO_CONTENT_SUCCESS,
    DELETE_PUB_VIDEO_CONTENT_ERROR
} from './actionTypes';

export const loadPubVideoContents = Params => ({
    type: LOAD_PUB_VIDEO_CONTENTS,
    payload: Params
})

export const getPubVideoContents = Params => ({
    type: GET_PUB_VIDEO_CONTENTS,
    payload: Params
})

export const getPubVideoContentsSuccess = VideoContents => ({
    type: GET_PUB_VIDEO_CONTENTS_SUCCESS,
    payload: VideoContents,
})

export const getPubVideoContentsFail = error => ({
    type: GET_PUB_VIDEO_CONTENTS_FAIL,
    payload: error,
})

export const addNewPubVideoContent = VideoContents => ({
    type: ADD_PUB_VIDEO_CONTENT,
    payload: VideoContents,
})

export const addPubVideoContentSuccess = VideoContents => ({
    type: ADD_PUB_VIDEO_CONTENT_SUCCESS,
    payload: VideoContents,
})

export const addPubVideoContentFail = error => ({
    type: ADD_PUB_VIDEO_CONTENT_ERROR,
    payload: error,
})

export const deletePubVideoContent = VideoContents => ({
    type: DELETE_PUB_VIDEO_CONTENT,
    payload: VideoContents,
})

export const deletePubVideoContentSuccess = VideoContents => ({
    type: DELETE_PUB_VIDEO_CONTENT_SUCCESS,
    payload: VideoContents,
})

export const deletePubVideoContentFail = error => ({
    type: DELETE_PUB_VIDEO_CONTENT_ERROR,
    payload: error,
})
