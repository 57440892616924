import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types'
import axios from "axios";
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row, Input, Label, Form,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown
} from "reactstrap"

import DateRangePickers from '../../../helpers/DateRangePicker'

//redux
import { useSelector, useDispatch } from "react-redux";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import {
  getCreative as onGetCreative,
  statusCreative as onStatusCreative,
  archiveCreative as onArchiveCreative,
  statusCreativeSuccess,
  archiveCreativeSuccess,
  addCreativeFail,
  updateCreativeFail,
} from "store/Demand/Creative/actions";

// //Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Create_Creatives from './Create_Creatives/Create_Creatives';

var total = 0
var totalPercentage = 0
var selectedItem = ''
var selectedData = ''
var selectedIndex = []
var creativeType = ''
var selectedDataByLineItem = ''

const CreativeWeightage = props => {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [creativeData, setCreativeData] = useState([]);
  const [totalWeight, setTotalWeight] = useState(0);
  const [finalData, setFinalData] = useState({
    request: []
  });
  const [btnprimary1, setBtnprimary1] = useState(false)
  const [isRight, setIsRight] = useState(false);
  const parsed = queryString.parse(window.location.search);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const [templatesData, setTemplatesData] = useState([]);
  const [statusSearch, setStatusSearch] = useState('Active')
  const [creativeTypeSearch, setCreativeTypeSearch] = useState('')
  const [creativeSizeSearch, setCreativeSizeSearch] = useState('')
  const [creativeTemplateSearch, setCreativeTemplateSearch] = useState('')
  const [isArchived, setIsArchived] = useState(false)

  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));

  const statusChange = (e) => {
    setStatusSearch(e.target.value)
    getCreatives(e.target.value, creativeTypeSearch, creativeSizeSearch, creativeTemplateSearch);
  }

  const creativeTypeChange = (e) => {
    setCreativeTypeSearch(e.target.value)
    getCreatives(statusSearch, e.target.value, creativeSizeSearch, creativeTemplateSearch);
  }

  const creativeSizeChange = (e) => {
    setCreativeSizeSearch(e.target.value)
    getCreatives(statusSearch, creativeTypeSearch, e.target.value, creativeTemplateSearch);
  }

  const creativeTemplateChange = (e) => {
    setCreativeTemplateSearch(e.target.value)
    getCreatives(statusSearch, creativeTypeSearch, e.target.value, e.target.value);
  }

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const bodyTemplate = {
    "url": '',
    "requestBody": {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name: userInfo.name,
    }
  }

  // useEffect(() => {
  //   bodyTemplate.url = "http://127.0.0.1:2002/v1.0/CreativeTemplates/list"
  //   axios.post("/v1.0/connectOP", bodyTemplate,
  //     {
  //       headers: headers
  //     })
  //     .then((res) => {
  //       console.log("result is ==>", res)
  //       if (res.data) {
  //         console.log(res.data.response)
  //         res.data.response.map((item, index) => {
  //           item.id = index + 1
  //         })
  //         setTemplatesData(objectModals(res.data.response))
  //         console.log(templatesData)
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("e", err)
  //     });
  // }, []);

  const objectModals = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.creativeTemplateName
      obj.label = item.creativeTemplateName
      dataList.push(obj)
    })
    return dataList
  }

  const changeDatePick = (event) => {
    console.log(event)
    console.log("start: ", event.startDate._d);
    console.log("end: ", event.endDate._d);
    setFromDate(event.startDate._d.toISOString());
    setToDate(event.endDate._d.toISOString());
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex, e) => {
      console.log(row, isSelect, rowIndex)
      if (isSelect) {
        setSelectedRowsStatus(true)
        selectedIndex.push(row.creativeID)
        selectedItem = row.creativeID
        console.log(selectedIndex)
      } else {
        let index = selectedIndex.indexOf(row.creativeID)
        selectedIndex.splice(index, 1)
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
        console.log(selectedIndex)
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      console.log(isSelect);
      console.log(rows);
      console.log(e);
      if (isSelect) {
        rows.map((item, index) => {
          selectedIndex.push(item.creativeID)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelectedRowsStatus(false)
        selectedIndex = []
      }
    }
  };

  const handleClose = () => {
    setSelectedRowsStatus(false)
  }

  const { creativesResponse } = useSelector(state => ({
    creativesResponse: state.creatives.response,
  }));

  const { archiveCreativeResponse } = useSelector(state => ({
    archiveCreativeResponse: state.creatives.archiveMsg,
  }));

  console.log(creativesResponse)
  useEffect(() => {
    if (creativesResponse !== null) {
      if (creativesResponse.statusCode === 200) {
        toastr.success('Creative Status Changed Successfully')
        getCreatives(statusSearch, creativeTypeSearch, creativeSizeSearch, creativeTemplateSearch);
        dispatch(statusCreativeSuccess(null));
      }
    }
  }, [dispatch, creativesResponse]);

  useEffect(() => {
    if (archiveCreativeResponse !== null) {
      if (archiveCreativeResponse.statusCode === 200) {
        toastr.success('Creative Archived Successfully')
        getCreatives(statusSearch, creativeTypeSearch, creativeSizeSearch, creativeTemplateSearch);
        dispatch(archiveCreativeSuccess(null));
      }
    }
  }, [dispatch, archiveCreativeResponse]);

  const contentChange = index => e => {
    console.log(creativeData, e.target.value)
    console.log(index)
    console.log(e.target)
    let newArr = [...creativeData];
    console.log(newArr)
    console.log(newArr[index].creativeWeight)
    if (e.target.value) {
      newArr[index].creativeWeight = parseInt(e.target.value);
    } else {
      newArr[index].creativeWeight = '';
    }
    console.log(total)
    console.log(newArr)
    console.log(newArr[index].creativeWeight)
    setCreativeData(newArr);
    changePercentage(newArr)
  }

  const blurChange = index => e => {
    console.log("input blurred")
    let newArr = [...creativeData];
    if (e.target.value) {
      if (parseInt(e.target.value) < 1) {
        newArr[index].creativeWeight = 1;
      } else {
        newArr[index].creativeWeight = parseInt(e.target.value);
      }
    } else {
      newArr[index].creativeWeight = '';
    }
    console.log(newArr)
    setCreativeData(newArr);
    changePercentage(newArr)
  }

  function allowOnlyNumbers(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if (!(code >= 48 && code < 58) && !(code >= 46 && code < 47)) { e.preventDefault(); }
  };

  useEffect(() => {
    getCreatives(statusSearch, creativeTypeSearch, creativeSizeSearch, creativeTemplateSearch);
    if (parsed.lineItemID) {
      selectedDataByLineItem = parsed
    }
    if (parsed.status === 'CreativeCreateByLineItem') {
      creativeType = parsed.creativeType
      setIsRight(true);
    }
  }, [])

  const getCreatives = (statusSearchs, creativeTypeSearchs, creativeSizeSearchs, creativeTemplateSearchs) => {
    total = 0;
    if (parsed.lineItemID) {
      const body = {
          filter: statusSearchs,
          creativeType: creativeTypeSearchs,
          creativeSize: creativeSizeSearchs,
          creativeTemplateName: creativeTemplateSearchs,
          userID: userInfo.userID,
          userRole: userInfo.userRole,
          companyID: userInfo.companyID,
          name: userInfo.name,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
      }
    axios.post(userInfo.userRole === 'SupplyPartner' ? "/v2.0/demandSupplyPartner/Creatives/lineitem/read/SupplyPartner/" + parsed.lineItemID :  "/v2.0/demand/Creatives/lineitem/read/" + parsed.lineItemID, body,
      {
          headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json',
            "Authorization": `Bearer ${userInfo.token}`
          }
        })
        .then((res) => {
          console.log("result is ==>", res)
          if (res.data) {
            console.log(res.data)
            res.data.response.creatives.map((item, index) => {
              item.id = index + 1
            })
            res.data.response.creatives.map((item, index) => {
              total = total + parseInt(item.creativeWeight)
              setTotalWeight(total)
              console.log(total)
            })
            res.data.response.creatives.map((item, index) => {
              console.log(total)
              item.creativeWeightPercentage = Math.round(((item.creativeWeight / total) * 100), 6);
            })
            setCreativeData([...res.data.response.creatives])
            if (res.data.response.creatives.length > 0) {
              totalPercentageChange(res.data.response.creatives)
            }
            let requestData = []
            res.data.response.creatives.map((item, index) => {
              let obj = {
                creativeID: item.creativeID,
                creativeWeight: parseInt(item.creativeWeight),
                creativeWeightPercentage: parseInt(item.creativeWeightPercentage)
              }
              requestData.push(obj)
              setFinalData({ ...finalData, request: requestData })
              console.log(finalData)
            })
          }
        })
        .catch((err) => {
          console.log("e", err)
          // setInValid(true)
        });
    }
  }

  const toggleDrawer = (close, creative, creativeTypes) => (event) => {
    console.log(event)
    if (event === 'Create') {
      toastr.success('Creative Saved Successfully!...')
    }
    if (event === 'Update') {
      toastr.success('Creative Updated Successfully!...')
    }
    if (close) {
      selectedData = creative
      creativeType = creativeTypes
    } else {
      selectedData = ''
      creativeType = ''
    }
    setIsArchived((creative.isCreativeArchived === true) ? true : false)
    console.log(close, creative, creativeTypes)
    getCreatives(statusSearch, creativeTypeSearch, creativeSizeSearch, creativeTemplateSearch);
    dispatch(addCreativeFail(null));
    dispatch(updateCreativeFail(null));
    setIsRight(close);
  };


  const changePercentage = (data) => {
    console.log(data)
    total = 0
    creativeData.map((item, index) => {
      if (Number.isNaN(parseInt(item.creativeWeight)) || parseInt(item.creativeWeight) < 1) {
        total = total + 1
      } else {
        total = total + parseInt(item.creativeWeight)
      }
      console.log(item)
      setTotalWeight(total)
      console.log(total)
    })
    totalPercentageChange(creativeData)
    let requestData = []
    creativeData.map((item, index) => {
      let obj = {}
      if (Number.isNaN(parseInt(item.creativeWeight)) || parseInt(item.creativeWeight) < 1) {
        obj.creativeWeight = 1
      } else {
        obj.creativeWeight = parseInt(item.creativeWeight)
      }
      obj.creativeID = item.creativeID
      obj.creativeWeightPercentage = parseInt(item.creativeWeightPercentage) || 1
      requestData.push(obj)
      setFinalData({ ...finalData, request: requestData })
    })
  }

  const creativeMaxChange = (data) => {
    console.log(data)
    var maxId = data.reduce(function (maxId, item) {
      return Math.max(maxId, item.creativeWeightPercentage)
    }, Number.MIN_VALUE);
    console.log("Max:", maxId)
    console.log("Max:", data)
    console.log("TotalPercentage:", totalPercentage)
    data.map((item, index) => {
      let newArr = [...data];
      if (totalPercentage >= 100) {
        if (item.creativeWeightPercentage === maxId) {
          console.log(item.creativeWeightPercentage)
          newArr[index].creativeWeightPercentage = maxId - (totalPercentage - 100);
          console.log(newArr)

          setCreativeData(newArr);
        }
      }
      else if (item.creativeWeightPercentage >= 99) {
        newArr[index].creativeWeightPercentage = item.creativeWeightPercentage - 2;
      }
      else if (item.creativeWeightPercentage === 0) {
        newArr[index].creativeWeightPercentage = 1;
        totalPercentage = totalPercentage + 1
      }
    })
  }

  const creativeMinChange = (data) => {
    console.log(data)

    let newArr = [...data];
    console.log(totalPercentage)
    if (totalPercentage === 99) {
      newArr[0].creativeWeightPercentage = newArr[0].creativeWeightPercentage + 1
      console.log(newArr)

      setCreativeData(newArr);
    }
    creativeMaxChange(newArr)
  }

  const totalPercentageChange = (data) => {
    console.log(data)
    totalPercentage = 0
    data.map((item, index) => {
      if (item.creativeWeight && item.creativeWeight > 1) {
        item.creativeWeightPercentage = Math.floor(((item.creativeWeight / total) * 100), 6);
      } else {
        item.creativeWeightPercentage = Math.floor(((1 / total) * 100), 6);
      }
      totalPercentage = totalPercentage + item.creativeWeightPercentage
    })
    console.log(data)
    let newArr = [...data];
    setCreativeData(newArr)
    creativeMinChange(newArr)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (finalData.request.length > 0) {
      const bodyData = {
          ...finalData, userID: userInfo.userID,
          userRole: userInfo.userRole,
          companyID: userInfo.companyID,
          name: userInfo.name,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner
      }
      axios.post(userInfo.userRole === 'SupplyPartner' ? "/v2.0/demandSupplyPartner/Creatives/lineitem/updateWeigth/SupplyPartner" : "/v2.0/demand/Creatives/lineitem/updateWeigth", bodyData,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": 'application/json',
            "Authorization": `Bearer ${userInfo.token}`
          }
        })
        .then((res) => {
          console.log("result is ==>", res)
          if (res.data.success) {
            toastr.success('Creative Weightage Updated Successfully')
          }
        })
        .catch((err) => {
          console.log("e", err)
        });
    }
  }
  console.log(creativeData)
  const creativeListColumns = [
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, creative) => <>{creative.id}</>,
    },
    {
      text: "ID",
 headerClasses:"ID",
      dataField: "creativeID",
      sort: true,
      formatter: (cellContent, creative) => (
        <>
          <span>{creative.creativeID}</span>
        </>
      ),
    },
    {
      dataField: "creativeCreateTime",
      text: "Creation Date",
      sort: true,
      order: "desc",
    },
    {
      text: "Creative Name",
      dataField: "creativeName",
      sort: true,
      // eslint-disable-next-line react/display-name
      // formatter: (cellContent, creative) => (
      //   <>
      //     <h5 className="font-size-14 mb-1">
      //       <Link to="#" className="text-dark">
      //         {creative.creativeName}
      //       </Link>
      //     </h5>
      //   </>
      // ),
    },
    // {
    //   text: "Creative Type",
    //   dataField: "creativeType",
    //   sort: false,
    //   formatter: (cellContent, creative) => (
    //     <div className=' ' >
    //       {creative.creativeType !== 'play' &&
    //         <i
    //           className="mdi mdi-television font-size-18"
    //           id="edittooltip" style={{ marginLeft: '0.5pc' }}
    //         ></i>
    //       }
    //       {creative.creativeType === 'play' &&
    //         <i
    //           className="mdi mdi-television-play font-size-18"
    //           id="edittooltip" style={{ marginLeft: '0.5pc' }}
    //         ></i>
    //       }
    //     </div>
    //   )
    // },
    {
      dataField: "creativeWeight",
      text: "Creative Weightage",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, creative, rowIndex) => (
        <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
          <span className='text-center'>
            <Input
              type="text" name="creativeWeight" style={{ width: '7vh' }} value={creative.creativeWeight} onBlur={blurChange(creative.id)} maxLength='4' placeholder='1' onChange={contentChange(creative.id)}
            />
          </span>
        </div>
      ),
    },
    {
      text: "Percentage",
      dataField: "creativeWeightPercentage",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, creative) => (
        <div >
          <span className='ml-4 mr-4 '>
            {creative.creativeWeightPercentage}%
          </span>
        </div>
      ),
    },
    {
      dataField: "creativeStatus",
      text: "Status",
      sort: true,
      formatter: (cellContent, creative) => (
        <>
          {creative.creativeStatus &&
            <span className="active-state">Active</span>
          }
          {!creative.creativeStatus &&
            <span className="inactive-state">Inactive</span>
          }
        </>
      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',
      hidden: (UserRolePrivileges.Demand && UserRolePrivileges.Demand.Creatives) && (!UserRolePrivileges.Demand.Creatives.update),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, creative) => (
        <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
          <span className="text-success " onClick={toggleDrawer(true, creative, '')} style={{ cursor: 'pointer' }}>
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
            ></i>
          </span>
        </div>
      ),
    },
  ];

  const { SearchBar } = Search;
  const sizePerPage = 10;
  const pageOptions = {
    sizePerPage: 10,
    totalSize: creativeData.length, // replace later with size(creatives),
    custom: true,
  };
  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const archiveCreative = (creativeId) => (event) => {
    let arr = []
    arr.push(creativeId)
    dispatch(onArchiveCreative({ idList: arr, creativeType: 'display',userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner  }));
  }

  const statusCreative = (creativeId, state) => (event) => {
    let arr = []
    arr.push(creativeId)
    dispatch(onStatusCreative({ idList: arr, creativeStatus: state, creativeType: 'display',userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner  }));
  }

  const createUI = () => {
    return creativeData.map((item, i) =>
      <div key={i}>
        <ul className="list-group">
          <li className="list-group-item  justify-content-between align-items-center creative-Data">
            <ul>
              <li>{item.creativeID}</li>
              <li>{item.creativeCreateTime}</li>
              <li>{item.creativeName}</li>
              <li>    <span className='text-center'>
                <Input
                  type="number" name="creativeWeight" style={{ width: '7vh' }} value={item.creativeWeight} onBlur={blurChange(i)} maxLength='4' placeholder='1' onChange={contentChange(i)}
                  onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4) }}
                />
              </span></li>
              <li>{item.creativeWeightPercentage}%</li>
              <li>
                {item.creativeStatus &&
                  <span className="active-state">Active</span>
                }
                {!item.creativeStatus &&
                  <span className="inactive-state">Inactive</span>
                }</li>
              <li>    <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
                <span className="color-dark-blue" style={{ cursor: 'pointer' }} onClick={toggleDrawer(true, item, '')}>
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                  ></i>
                </span>
              </div></li>
            </ul>
            {/* <div className='row'>
              <div className='col-6'>
                <span className="color-dark-blue" style={{ cursor: 'pointer' }} onClick={toggleDrawer(true, item, '')}>{item.creativeName}</span>
              </div>
              <div className='col-4'>
                <span className='text-center'>
                  <Input
                    type="text" name="creativeWeight" style={{ width: '7vh' }} value={item.creativeWeight} onBlur={blurChange(i)} maxLength='4' placeholder='1' onChange={contentChange(i)}
                  />
                </span>
              </div>
              <div className='col-2'>
                <span className='ml-4 mr-4 '>
                  {item.creativeWeightPercentage}%
                </span>
              </div>
            </div> */}
          </li>
        </ul>
      </div>
    )
  }

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";


  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      <div className="page-content">
        <Helmet>
          <title>Creative Weightage | Bidsxchange</title>
        </Helmet>
        <Container fluid={true}>
          {/* Render Breadcrumbs */}
          <div className='row'>
          <div className='col-9'>
            {parsed.lineItemID ? (
              <Breadcrumbs title="Demand" breadcrumbItem="Creative Weightage" lineItemID={parsed.lineItemID} />
            ) : (
              <Breadcrumbs title="Demand" breadcrumbItem="Creative Weightage" breadList="All Creatives" />
            )
            }
          </div>
          <div className='col-3'>
            <div className='row'>
              {UserRolePrivileges.Demand && UserRolePrivileges.Demand.Creatives && UserRolePrivileges.Demand.Creatives.create &&
                <div className="text-sm-end">
                  <Button
                    color="primary"
                    className="font-16 btn-block btn btn-primary"
                    onClick={toggleDrawer(true, '', parsed.creativeType)}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Add Creative
                  </Button>
                </div>
              }
            </div>
          </div>
          </div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className='row'>
                  <Row className="mb-2">
                    <Col xl="4">
                      <div className=" text-sm-end">
                        <DateRangePickers datePick={changeDatePick} />
                      </div>
                    </Col>
                    {/* <Col xl="2">
                                    <div className=" text-sm-end">
                                    <Input type="select" className="form-select" value={creativeTemplateSearch} onChange={creativeTemplateChange}>
                                        <option value=''> All</option>
                                        {templatesData.map((item) => (
                                          <option
                                            key={item.value}
                                            value={item.value}
                                          >
                                            {item.label}
                                          </option>
                                        ))}
                                      </Input>
                                    </div>
                                  </Col> */}
                    <Col xl="2">
                      <div className=" text-sm-end">
                        <Input type="select" className="form-select" value={creativeSizeSearch} onChange={creativeSizeChange}>
                          <option value=''> All</option>
                          <option value="300x250">300x250 </option>
                          <option value="300x600">300x600 </option>
                          <option value="728x90">728x90 </option>
                          <option value="120x600">120x600 </option>
                          <option value="160x600">160x600 </option>
                          <option value="970x250">970x250 </option>
                          <option value="970x90">970x90</option>
                          <option value="320x50">320x50</option>
                          <option value="320x100">320x100 </option>
                          <option value="300x50">300x50 </option>
                          <option value="300x100">300x100 </option>
                          <option value="468x60">468x60 </option>
                          <option value="468x90">468x90</option>
                          <option value="336x280">336x280 </option>
                        </Input>
                      </div>
                    </Col>
                    <Col xl="2">
                      <div className=" text-sm-end">
                        <Input type="select" className="form-select" value={creativeTypeSearch} onChange={creativeTypeChange}>
                          <option value=''> All</option>
                          <option value='image' >Image</option>
                          <option value='html'>HTML</option>
                          <option value='thirdParty'> Third Party</option>
                          <option value='stdb' >Standard Banner</option>
                          <option value='highImpact'> High Impact</option>
                          <option value='play'> Video</option>
                        </Input>
                      </div>
                    </Col>
                    <Col xl="2">
                      <div className=" text-sm-end">
                        <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                          <option value=''> All</option>
                          <option value='Active' > Active</option>
                          <option value='Inactive'> InActive</option>
                          <option value='Archived'> Archived</option>
                        </Input>
                      </div>
                    </Col>
                  </Row>
                  <form name="form" onSubmit={handleSubmit} className="creative-form" >
                    <div className=" mt-4">
                      <div className="form-group mt-4">
                        <div className="react-bootstrap-table">
                          <table className="table table-bordered">
                            <thead><tr>
                              <th >ID</th>
                              <th >Creation Date</th>
                              <th >Creative Name</th>
                              <th >Creative Weightage</th>
                              <th >%</th>
                              <th >Status</th>
                              <th >Action</th>
                            </tr>
                            </thead>
                            <tbody>
                              {creativeData.map((item, i) => (
                                <tr key={i}>
                                  <td>{item.creativeID}</td>
                                  <td>{item.creativeCreateTime}</td>
                                  <td>{item.creativeName}</td>
                                  <td style={{ wordBreak: 'break-word' }}>
                                    <span className='text-center'>
                                      <Input
                                        type="text" name="creativeWeight" style={{ width: '7vh' }} value={item.creativeWeight} onBlur={blurChange(i)} onKeyPress={allowOnlyNumbers} maxLength='4' placeholder='1' onChange={contentChange(i)}
                                        onInput={(e) => { e.target.value = Math.max(0, e.target.value ? parseInt(e.target.value) : '').toString().slice(0, 4) }}
                                      />
                                    </span>
                                  </td>
                                  <td>{item.creativeWeightPercentage}%</td>
                                  <td>
                                    {item.newStatus === 'Active' &&
                                      <span className="active-state">Active</span>
                                    }
                                    {item.newStatus === 'Inactive' &&
                                      <span className="inactive-state">Inactive</span>
                                    }
                                    {item.newStatus === 'Archived' &&
                                      <span className="archived-state">Archived</span>}
                                  </td>
                                  <td style={{ textAlign: 'center' }}>
                                    <div className="d-flex gap-3" style={{ marginLeft: '1pc' }}>
                                      <span className="text-success" style={{ cursor: 'pointer' }} onClick={toggleDrawer(true, item, '')}>
                                        {item.newStatus === 'Archived' &&
                                          <i
                                            className="mdi mdi-eye font-size-18"
                                            id="edittooltip"
                                          ></i>}
                                        {item.newStatus !== 'Archived' &&
                                          <i
                                            className="mdi mdi-pencil font-size-18"
                                            id="edittooltip"
                                          ></i>}
                                      </span>
                                      {item.newStatus === 'Inactive' &&
                                        <span className="active-state bg-primary text-white" onClick={statusCreative(item.creativeID, true)}>Activate</span>
                                      }
                                      {item.newStatus === 'Active' &&
                                        <span className="inactive-state bg-primary text-white" onClick={statusCreative(item.creativeID, false)}>DeActivate</span>
                                      }
                                      {item.newStatus !== 'Archived' &&
                                        <span className="archived-state bg-primary text-white" onClick={archiveCreative(item.creativeID)}>Archive</span>
                                      }
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className='col-12'>
                        {creativeData.length === 0 &&
                          <div className="form-group row justify-content-center mt-4">
                            <h5 className="mt-4 text-center">No Data</h5>
                          </div>
                        }
                        {creativeData.length > 0 &&
                          <Col>
                            <div className="text-end mt-4">
                              <Link className="btn btn-outline-primary" to="/Line_Items" > Cancel </Link>&nbsp;&nbsp;
                              <button type="submit" className="btn btn-primary save-user">
                                Save
                              </button>
                            </div>
                          </Col>
                        }
                      </div>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* </div> */}

      <Offcanvas isOpen={isRight} direction='end'  >
        <OffcanvasHeader toggle={toggleDrawer(false, '', '')}>
          {selectedData ? 'Edit Creative ' : 'Add ' + creativeType + ' Creative '}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Create_Creatives cancelCreative={toggleDrawer(false, '', '')} selectedArchived={isArchived} selectedCreative={selectedData} selectedCreativeType={creativeType} selectedLineItem={(parsed.status === 'CreativeCreateByLineItem' || parsed.status === 'CreativeCreateByParent') ? parsed : ''} />
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment >
  )
}

CreativeWeightage.propTypes = {}

export default CreativeWeightage