import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Input,
  Spinner,
  Label
} from "reactstrap"
import queryString from 'query-string';
import { MultiSelect } from "react-multi-select-component";

import DateRangePickers from '../../../helpers/DateRangePicker'

import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import {
  getHighImpactSetting as onGetHighImpactSetting,
  statusHighImpactSetting as onStatusHighImpactSetting,
  archiveHighImpactSetting as onArchiveHighImpactSetting,
  statusHighImpactSettingSuccess,
  archiveHighImpactSettingSuccess,
  addHighImpactSettingFail,
  updateHighImpactSettingFail,
} from "store/Supply/HighImpactSettings/actions";

import {
  getAdUnit as onGetAdUnits,
} from "store/Supply/AdUnit/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";
import NewHighImpact from "./NewHighImpact";
import CreateHI from "./CreateHighImpact/CreateHI";
import DetachHighImpact from "./DetachHighImpact";
import LoadingTable from "pages/LoaderPages/LoadingTable";
import LineItemDataTable from "Product_Helpers/ProductDataTable"
import ProductDataTableLI from "Product_Helpers/ProductDataTableLI";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var highImpacts = []

var filterCount = 0
let pageNumber = 1
let pageSize = 10
let totalRecords = 0
let searchText = ''

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import ErrorPage from "pages/errorFile";
var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 2 : 1
var fromDate = ''
var toDate = ''

const HighImpactSetting = props => {
  const dispatch = useDispatch();
  const [isRight, setIsRight] = useState(false);
  const [isRights, setIsRights] = useState(false);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const parsed = queryString.parse(window.location.search);
  const [statusSearch, setStatusSearch] = useState('Active')
  const [isArchived, setIsArchived] = useState(false)
  const [selected, setSelected] = useState([])
  const [createState, setCreateState] = useState(true)
  const [adUnitSearch, setAdUnitSearch] = useState([])
  const [adUnitData, setAdUnitData] = useState([])
  const [selectedAdUnit, setSelectedAdUnit] = useState([]);
  const [highImpactCreateStatus, setHighImpactCreateStatus] = useState('')
  const userInfo = JSON.parse(localStorage.getItem('authUser'));

  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));

  const [switchLoader, setSwitchLoader] = useState(true)

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }
  const [state, setState] = useState({
    start: moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
    end: moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
  });
  const { start, end } = state;
  const handleEvent = (startDate, endDate, label) => {
    setState({ start: startDate, end: endDate, });
    fromDate = startDate._d.toISOString();
    toDate = endDate._d.toISOString();
    filterCount = filterCount + 1
  }
  const labelDate = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');

  const [filterState, setFilterState] = useState(false)
  const statusChange = (e) => {
    if (e.target.value === 'Active') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (statusSearch === 'Active') {
      filterCount = filterCount + 1
    }
    setStatusSearch(e.target.value)
  }

  const handleSelectAdUnit = selectAdUnit => {
    if (selectAdUnit.length === 0) {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (adUnitSearch.length === 0) {
      filterCount = filterCount + 1
    }
    setSelectedAdUnit(selectAdUnit);
    let listData = []
    selectAdUnit.map((item, index) => {
      listData.push(item.value)
    })
    setAdUnitSearch(listData)
  }

  const applyFilter = (e) => {
    setisMenu(false)
    if (parsed.adUnitId) {
      dispatch(onGetHighImpactSetting({ adUnitID: parsed.adUnitId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', AdUnitID: adUnitSearch }));
    } else {
      dispatch(onGetHighImpactSetting({ adUnitID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', AdUnitID: adUnitSearch }));
    }
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  const clearAllFilter = (e) => {
    setisMenu(false)
    setSelectedAdUnit([])
    setAdUnitSearch([])
    setStatusSearch('Active')
    filterCount = 0
    setFilterState(false)
    fromDate = ''
    toDate = ''
    setState({
      start: moment().subtract(0, 'days'),
      end: moment().subtract(0, 'days'),
    })
    if (parsed.adUnitId) {
      dispatch(onGetHighImpactSetting({ adUnitID: parsed.adUnitId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: 'Active', userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": '', "endDate": '', AdUnitID: [] }));
    } else {
      dispatch(onGetHighImpactSetting({ adUnitID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: 'Active', userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": '', "endDate": '', AdUnitID: [] }));
    }
  }

  // const statusChange = (e) => {
  //   setStatusSearch(e.target.value)
  //   if (parsed.adUnitId) {
  //     dispatch(onGetHighImpactSetting({ adUnitID: parsed.adUnitId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: e.target.value,AdUnitID: adUnitSearch}));
  //   } else {
  //     dispatch(onGetHighImpactSetting({ adUnitID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: e.target.value,AdUnitID: adUnitSearch }));
  //   }
  // }

  // const handleSelectAdUnit = selectAdUnit => {
  //   setSelectedAdUnit(selectAdUnit);
  //   let listData = []
  //   selectAdUnit.map((item, index) => {
  //       listData.push(item.value)
  //   })
  //   setAdUnitSearch(listData)
  //   dispatch(onGetHighImpactSetting({ adUnitID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter:statusSearch,AdUnitID:listData }));
  // }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };

  useEffect(() => {
    dispatch(onGetHighImpactSetting(null));
    totalRecords = 0
    pageNumber = 1
    pageSize = 10
    searchText = ''
    if (parsed.status === 'HighImpactCreateByAdUnit') {
      setIsRight(true);
      setCreateState(!createState)
    }
  }, []);


  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      console.log(row, isSelect, rowIndex)
      if (isSelect) {
        setSelected([...selected, row.id])
        setSelectedRowsStatus(true)
        selectedIndex.push(row.highImpactSettingID)
        selectedItem.push(row.highImpactSettingID)
        console.log(selectedIndex)
      } else {
        setSelected(selected.filter(x => x !== row.id))
        let index = selectedIndex.indexOf(row.highImpactSettingID)
        selectedIndex.splice(index, 1)
        let index2 = selectedItem.indexOf(row.highImpactSettingID)
        selectedItem.splice(index2, 1)
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
        console.log(selectedIndex)
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      const ids = rows.map(r => r.id);
      if (isSelect) {
        setSelected(ids)
        rows.map((item, index) => {
          selectedIndex.push(item.highImpactSettingID)
          selectedItem.push(item.highImpactSettingID)
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
      }

    }
  };

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
    selectedIndex = []
  }

  const { highImpacts, paginationData } = useSelector(state => ({
    highImpacts: state.highImpactSettings.highImpactSettingList,
    paginationData: state.highImpactSettings.pagination
  }));

  totalRecords = paginationData ? paginationData.totalRecords ? paginationData.totalRecords : 0 : 0

  const { loading } = useSelector(state => ({
    loading: state.highImpactSettings.loading,
  }));

  const { noData } = useSelector(state => ({
    noData: state.highImpactSettings.noData,
  }));

  const { highImpactsResponse } = useSelector(state => ({
    highImpactsResponse: state.highImpactSettings.response,
  }));

  const { archiveHighImpactSettingResponse } = useSelector(state => ({
    archiveHighImpactSettingResponse: state.highImpactSettings.archiveMsg,
  }));

  const { adUnits } = useSelector(state => ({
    adUnits: state.adUnits.adUnitList
  }));

  // const { errorMsg, successMsg } = useSelector(state => ({
  //   errorMsg: state.highImpacts.errorMsg,
  //   successMsg: state.highImpacts.successMsg,
  // }));

  useEffect(() => {
    console.log(loading)
    if (loading) {
      setSwitchLoader(true)
    }
  }, [loading]);

  useEffect(() => {
    if (adUnits !== null) {
      dispatch(onGetAdUnits({ adUnitGroupID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: 'Active', adUnitType: "highImpact", adUnitSize: '', userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": '', "endDate": '', }));
    }
  }, []);

  useEffect(() => {
    if (adUnits && adUnits.length > 0) {
      setAdUnitData(objectAdUnitModal(adUnits))
      console.log(adUnits)
    }
  }, [adUnits]);

  const objectAdUnitModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.adUnitID
      obj.label = item.adUnitName
      dataList.push(obj)
    })
    return dataList
  }

  console.log(highImpactsResponse)
  useEffect(() => {
    if (highImpactsResponse !== null) {
      if (highImpactsResponse.statusCode === 200) {
        toastr.success('HighImpactSetting Status Changed Successfully')
        if (parsed.adUnitId) {
          dispatch(onGetHighImpactSetting({ adUnitID: parsed.adUnitId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', AdUnitID: adUnitSearch }));
        } else {
          dispatch(onGetHighImpactSetting({ adUnitID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', AdUnitID: adUnitSearch }));
        }
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        dispatch(statusHighImpactSettingSuccess(null));
      }
    }
  }, [dispatch, highImpactsResponse]);

  useEffect(() => {
    if (archiveHighImpactSettingResponse !== null) {
      if (archiveHighImpactSettingResponse.statusCode === 200) {
        toastr.success('HighImpactSetting Archived Successfully')
        if (parsed.adUnitId) {
          dispatch(onGetHighImpactSetting({ adUnitID: parsed.adUnitId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', AdUnitID: adUnitSearch }));
        } else {
          dispatch(onGetHighImpactSetting({ adUnitID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', AdUnitID: adUnitSearch }));
        }
        selectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        dispatch(archiveHighImpactSettingSuccess(null));
      }
    }
  }, [dispatch, archiveHighImpactSettingResponse]);

  const toggleRightCanvas = () => {
    selectedData = ''
    if (parsed.adUnitId) {
      dispatch(onGetHighImpactSetting({ adUnitID: parsed.adUnitId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', AdUnitID: adUnitSearch }));
    } else {
      dispatch(onGetHighImpactSetting({ adUnitID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', AdUnitID: adUnitSearch }));
    }
    dispatch(addHighImpactSettingFail(""));
    dispatch(updateHighImpactSettingFail(""));
    setIsRight(!isRight);
    setCreateState(!createState)
  };

  const toggleRightCanvass = () => {
    selectedData = ''
    if (parsed.adUnitId) {
      dispatch(onGetHighImpactSetting({ adUnitID: parsed.adUnitId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', AdUnitID: adUnitSearch }));
    } else {
      dispatch(onGetHighImpactSetting({ adUnitID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', AdUnitID: adUnitSearch }));
    }
    dispatch(addHighImpactSettingFail(""));
    dispatch(updateHighImpactSettingFail(""));
    setIsRights(!isRights);
  };

  const toggleEnableScroll = () => {
    setIsEnableScroll(!isEnableScroll);
  };

  const toggleDrawer = (close, highImpact, creationStatus) => (event) => {
    console.log(event)
    if (event === 'Create') {
      toastr.success('HighImpactSetting Saved Successfully!...')
    }
    if (event === 'Update') {
      toastr.success('HighImpactSetting Updated Successfully!...')
    }
    selectedData = highImpact
    setIsArchived(highImpact.isHighImpactSettingArchived)
    setHighImpactCreateStatus(creationStatus)
    if (parsed.adUnitId) {
      dispatch(onGetHighImpactSetting({ adUnitID: parsed.adUnitId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', AdUnitID: adUnitSearch }));
    } else {
      dispatch(onGetHighImpactSetting({ adUnitID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', AdUnitID: adUnitSearch }));
    }
    dispatch(addHighImpactSettingFail(""));
    dispatch(updateHighImpactSettingFail(""));
    setIsRight(close);
    setCreateState(!createState)
  };

  const toggleDrawers = (close, highImpact) => (event) => {
    console.log(event)
    if (parsed.adUnitId) {
      dispatch(onGetHighImpactSetting({ adUnitID: parsed.adUnitId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', AdUnitID: adUnitSearch }));
    } else {
      dispatch(onGetHighImpactSetting({ adUnitID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', AdUnitID: adUnitSearch }));
    }
    dispatch(addHighImpactSettingFail(""));
    dispatch(updateHighImpactSettingFail(""));
    setIsRights(close);
  };

  useEffect(() => {
    if (parsed.adUnitId) {
      dispatch(onGetHighImpactSetting({ adUnitID: parsed.adUnitId, pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": '', "endDate": '', AdUnitID: adUnitSearch }));
    }
  }, []);

  useEffect(() => {
    if (highImpacts !== null && !parsed.adUnitId) {
      dispatch(onGetHighImpactSetting({ adUnitID: '', pageSize: pageSize, pageNumber: pageNumber, searchText: searchText, filter: statusSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": '', "endDate": '', AdUnitID: adUnitSearch }));
    }
  }, []);

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const highImpactListColumns = [
    {
      text: "ID",
      headerClasses: "ID",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "ID",
      headerClasses: "ID",
      dataField: "highImpactSettingID",
      sort: true,
    },
    {
      dataField: "highImpactSettingCreateTime",
      text: "Creation Date",
      sort: true,
    },
    {
      text: "High Impact Name",
      dataField: "highImpactSettingName",
      sort: true,
    },

    {
      dataField: "highImpactSettingStatus",
      text: "Status",
      sort: true,
      formatter: (cellContent, highImpact) => (
        <>
          <span className={highImpact.newStatus === 'Active' ? "active-state" : highImpact.newStatus === 'Inactive' ? "inactive-state" : "archived-state"}>{highImpact.newStatus}</span>
        </>
      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',
      headerAlign: 'center',
      hidden: (UserRolePrivileges.Supply && UserRolePrivileges.Supply.HighImpactSettings) && (!UserRolePrivileges.Supply.HighImpactSettings.update),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, highImpact) => (
        <span >
          <span className="text-success " onClick={toggleDrawer(true, highImpact, "Edit")}>
            {highImpact.newStatus === 'Archived' &&
              <i
                className="mdi mdi-eye font-size-18"
                id="edittooltip"
              ></i>
            }
            {highImpact.newStatus !== 'Archived' &&
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
              ></i>
            }
          </span> &nbsp;&nbsp;
          <span className="text-success " onClick={toggleDrawer(true, highImpact, "Duplicate")}>
            <i
              className="bx bx-duplicate font-size-18"
              id="edittooltip"
            ></i>
          </span>
        </span>
      ),
    },
  ];

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const archiveHighImpactSetting = async () => {
    dispatch(onArchiveHighImpactSetting({ highImpactSettingIDList: selectedItem, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner }));
  }

  const statusHighImpactSetting = (state) => (event) => {
    console.log(state)
    dispatch(onStatusHighImpactSetting({ highImpactSettingIDList: selectedItem, highImpactSettingStatus: state, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner }));
  }

  console.log(highImpacts)

  if (highImpacts.length > 0) {
    if (UserRolePrivileges.Supply && UserRolePrivileges.Supply.HighImpactSettings) {
      if (!UserRolePrivileges.Supply.HighImpactSettings.archive && !UserRolePrivileges.Supply.HighImpactSettings.status) {
        highImpacts.map((item, index) => {
          selectRow.nonSelectable.push(index + 1)
        })
      } else {
        highImpacts.map((item, index) => {
          if (item.isHighImpactSettingArchived) {
            selectRow.nonSelectable.push(index + 1)
          }
        })
      }
    }
  }

  const paginationChange = async (selectedPages) => {
    console.log(selectedPages)
    pageSize = selectedPages.pageSize
    pageNumber = selectedPages.pageNumber
    if (parsed.adUnitId) {
      dispatch(onGetHighImpactSetting({ adUnitID: parsed.adUnitId, pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText, filter: statusSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', AdUnitID: adUnitSearch }));
    } else {
      dispatch(onGetHighImpactSetting({ adUnitID: '', pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText, filter: statusSearch, userRole: userInfo.userRole, userID: userInfo.userID, parentID:userInfo.parentID,isForSupplyPartner:userInfo.isForSupplyPartner, "startDate": fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate": toDate ? moment(toDate).format("YYYY-MM-DD") : '', AdUnitID: adUnitSearch }));
    }
  }

  const SupplyServiceStatus = JSON.parse(sessionStorage.getItem("SupplyServiceDown"));

  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      {SupplyServiceStatus ? <ErrorPage></ErrorPage> :
        <div className="page-content">
          <Helmet>
            <title>High Impact Settings | Bidsxchange</title>
          </Helmet>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <div className=" d-sm-flex  justify-content-between">
              {parsed.adUnitId ? (
                <SupplyBreadCrumb title="Supply" breadcrumbItem="High Impact Settings " />
              ) : (
                <SupplyBreadCrumb title="Supply" breadcrumbItem="High Impact Settings " breadList="All High Impact Settings" />
              )}
              <div className="text-sm-end">

                <div className="btn-group">
                  <Dropdown
                    direction="left"
                    isOpen={isMenu}
                    toggle={() => { }}
                  >
                    <DropdownToggle
                      tag="button"
                      onClick={toggleMenu}
                      className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                    >
                      <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                      {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                        Filter Applied
                      </span> : <span className="d-none font-16 d-sm-inline-block">
                        Filter
                      </span>
                      }
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                      <div className="dropdown-item-text">
                        <div className="row">
                          <h5 className="mb-0">Filters</h5>
                        </div>
                      </div>

                      <DropdownItem divider />

                      <Card>
                        <CardBody aria-hidden="true">
                          <Row >
                            <Col sm="12" className="mt-2">
                              <Label className="form-label">Ad Unit</Label>
                              <MultiSelect
                                options={adUnitData}
                                value={selectedAdUnit}
                                onChange={handleSelectAdUnit}
                                overrideStrings={{ "selectSomeItems": "Please Select Ad Unit " }}
                              />
                            </Col>
                            <Col sm="12" className="mt-2">
                              <Label className="form-label">Status</Label>
                              <div className=" text-sm-end">
                                <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                  <option value=''> Select Status</option>
                                  <option value=''> All</option>
                                  <option value='Active' > Active</option>
                                  <option value='Inactive'> InActive</option>
                                  <option value='Archived'> Archived</option>
                                </Input>
                              </div>
                            </Col>
                            <Col sm="12" className="date-range mt-2">
                              <Label className="form-label">Filter by Modified Dates</Label>
                              <div className="">
                                <DateRangePicker
                                  initialSettings={{
                                    opens: 'left',
                                    startDate: start.toDate(),
                                    endDate: end.toDate(),
                                  }}
                                  onCallback={handleEvent}
                                >
                                  <button className='datePicker w-100' type="button">
                                    <i className="bx bx-calendar font-size-18 " id="edittooltip" style={{ margin: '3% 3% 2%' }} ></i>{labelDate}
                                  </button>
                                </DateRangePicker>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      <DropdownItem divider />
                      <Row>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={clearAllFilter}
                          >
                            Clear ALL
                            {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px' }}>{filterCount}</span>
                              } */}
                          </DropdownItem>
                        </Col>
                        <Col sm="6">
                          <DropdownItem
                            className="text-primary text-center"
                            onClick={applyFilter}
                          >
                            Apply Filter
                            {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px' }}>{filterCount}</span>
                              } */}
                          </DropdownItem>
                        </Col>
                      </Row>

                    </DropdownMenu>
                  </Dropdown>
                </div> &nbsp;&nbsp;
                {UserRolePrivileges.Supply && UserRolePrivileges.Supply.HighImpactSettings && UserRolePrivileges.Supply.HighImpactSettings.create &&
                  <Button
                    color="primary"
                    id="add-his"
                    className="font-16 btn-block btn btn-primary"
                    onClick={toggleRightCanvas}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Add HIS
                  </Button>
                }
                &nbsp;&nbsp;
                <Button
                  color="primary"
                  id="detach-his"
                  className="btn btn-danger"
                  onClick={toggleRightCanvass}
                >
                  <i className="bx bx-unlink me-1" />
                  Detach HIS
                </Button>

                <ReactTooltip
                  anchorId="add-his"
                  place="top"
                  variant="dark"
                  content="Add High Impact Setting"
                />
                <ReactTooltip
                  anchorId="detach-his"
                  place="top"
                  variant="dark"
                  content="Detach High Impact Setting"
                />
                {/* <UncontrolledDropdown className="dropdown d-inline-block me-1">
                                                <DropdownToggle type="menu" className="btn btn-danger" id="dropdownMenuButton1">
                                                <i className="bx bx-unlink" /></DropdownToggle>
                                                <DropdownMenu>
                                                    <li><DropdownItem onClick={toggleRightCanvass}>Detach High Impact Settings</DropdownItem></li>
                                                </DropdownMenu>
                    </UncontrolledDropdown> */}
              </div>
            </div>
            <ProductDataTableLI statusAccess={(UserRolePrivileges.Supply && UserRolePrivileges.Supply.HighImpactSettings && UserRolePrivileges.Supply.HighImpactSettings.status) ? true : false}
              archiveAccess={(UserRolePrivileges.Supply && UserRolePrivileges.Supply.HighImpactSettings && UserRolePrivileges.Supply.HighImpactSettings.archive) ? true : false} loading={loading} data={highImpacts} columns={highImpactListColumns} statusChange={statusHighImpactSetting} archiveLineItem={archiveHighImpactSetting} rowSelection={selectRow} handleClose={handleClose} selectedIndex={selectedIndex} paginationSelection={paginationChange} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize} />


          </Container>
        </div>
      }
      {/* </div> */}

      <Offcanvas isOpen={isRight} direction='end' className="CanvasLarge">
        <OffcanvasHeader toggle={toggleRightCanvas}>
          {selectedData ? 'Edit High Impact Settings' : 'New High Impact Settings'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <CreateHI closeCanvas={toggleDrawer(false, '', '')} selectedHighImpactCreateStatus={highImpactCreateStatus} selectedHighImpact={selectedData} />
        </OffcanvasBody>
      </Offcanvas>

      <Offcanvas isOpen={isRights} direction='end' >
        <OffcanvasHeader toggle={toggleRightCanvass}>
          {'Detach High Impact Settings'}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <DetachHighImpact closeCanvas={toggleDrawers(false, '')} />
        </OffcanvasBody>
      </Offcanvas>


    </React.Fragment>
  )
}

HighImpactSetting.propTypes = {}

export default HighImpactSetting