/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Label,
    Input, Spinner
} from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';
import New_Role from "./New_Role";
import New_Role_Test from "./New_Role_Test"; //Anjali Changes

import {
    getRoles as onGetRoles,
    statusRole as onStatusRole,
    archiveRole as onArchiveRole,
    statusRoleSuccess,
    archiveRoleSuccess,
    addRoleFail,
    updateRoleFail,
} from "store/RoleManagement/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";
import LoadingTable from "pages/LoaderPages/LoadingTable";
import SupplyPartnerNewRole from "./SupplyPartnerNewRole";

var selectedItem = []
var selectedData = ''
var selectedIndex = []
var filterCount = 0

const Roles_Management = props => {
    const dispatch = useDispatch();
    const [isRight, setIsRight] = useState(false);
    const [isRightAnjali, setIsRightAnjali] = useState(false); //Added by Anjali
    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
    const [selected, setSelected] = useState([])
    const [statusSearch, setStatusSearch] = useState('Active')
    const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));
    const userInfo = JSON.parse(localStorage.getItem('authUser'));

    const [switchLoader, setSwitchLoader] = useState(true)

    const handleSwitch = () => {
        setSwitchLoader(!switchLoader)
    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            setIsRight(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    console.log(props)

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 5000,
        extendedTimeOut: 1000
    };

    const selectRow = {
        mode: "checkbox",
        nonSelectable: [],
        selected: selected,
        onSelect: (row, isSelect, rowIndex, e) => {
            console.log(row, isSelect, rowIndex)
            if (isSelect) {
                setSelected([...selected, row.id])
                setSelectedRowsStatus(true)
                selectedIndex.push(row.roleID)
                selectedItem.push(row.roleID)
            } else {
                setSelected(selected.filter(x => x !== row.id))
                let index = selectedIndex.indexOf(row.roleID)
                selectedIndex.splice(index, 1)
                let index2 = selectedItem.indexOf(row.roleID)
                selectedItem.splice(index2, 1)
                if (selectedIndex.length === 0) {
                    setSelectedRowsStatus(false)
                }
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            const ids = rows.map(r => r.id);
            if (isSelect) {
                setSelected(ids)
                rows.map((item, index) => {
                    selectedIndex.push(item.roleID)
                    selectedItem.push(item.roleID)
                })
                setSelectedRowsStatus(true)
            } else {
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
                selectedItem = []
            }
        }
    };

    const handleClose = () => {
        setSelectedRowsStatus(false)
        selectedItem = []
        setSelected([])
        selectedIndex = []
    }

    const { roles } = useSelector(state => ({
        roles: state.roles.roles,
    }));

    const { loadingAccess } = useSelector(state => ({
        loadingAccess: state.roles.loading,
    }));

    const { rolesResponse } = useSelector(state => ({
        rolesResponse: state.roles.response,
    }));

    const { archiveRolesResponse } = useSelector(state => ({
        archiveRolesResponse: state.roles.archiveMsg,
    }));

    useEffect(() => {
        console.log(loadingAccess)
        if (loadingAccess) {
            setSwitchLoader(true)
        }
        // if (roles && roles.length){
        //     setSwitchLoader(false)
        //   }
    }, [loadingAccess]);

    console.log(rolesResponse)
    useEffect(() => {
        if (rolesResponse !== null) {
            if (rolesResponse.success) {
                toastr.success('Role Status Changed Successfully')
                dispatch(onGetRoles({ userRole: userInfo.userRole,isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch }));
                dispatch(statusRoleSuccess(null));
                selectedItem = []
                selectedIndex = []
                setSelected([])
                setSelectedRowsStatus(false)
            } else {
                toastr.error(rolesResponse)
                dispatch(onGetRoles({ userRole: userInfo.userRole, isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch }));
                dispatch(statusRoleSuccess(null));
                selectedItem = []
                selectedIndex = []
                setSelected([])
                setSelectedRowsStatus(false)
            }
        }
    }, [dispatch, rolesResponse]);

    useEffect(() => {
        if (archiveRolesResponse !== null) {
            if (archiveRolesResponse.success) {
                toastr.success('Role Archived Successfully')
                dispatch(onGetRoles({ userRole: userInfo.userRole, isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch }));
                dispatch(archiveRoleSuccess(null));
                selectedItem = []
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
            }
        }
    }, [dispatch, archiveRolesResponse]);

    const toggleRightCanvas = () => {
        selectedData = ''
        // dispatch(onGetRoles({ userRole: userInfo.userRole,isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch }));
        dispatch(addRoleFail(null));
        dispatch(updateRoleFail(null));
        setIsRight(!isRight);
    };

    // Added by Anjali
    const toggleRightCanvasAnjali = () => {
        selectedData = ''
        // dispatch(onGetRoles({ userRole: userInfo.userRole,isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch }));
        dispatch(addRoleFail(null));
        dispatch(updateRoleFail(null));
        setIsRightAnjali(!isRightAnjali)
    };
    // Added by Anjali

    const toggleEnableScroll = () => {
        setIsEnableScroll(!isEnableScroll);
    };

    const toggleDrawer = (close, role) => (event) => {
        console.log(event)
        if (event === 'Create') {
            toastr.success('Role Saved Successfully!...')
        }
        if (event === 'Update') {
            toastr.success('Role Updated Successfully!...')
        }
        selectedData = role
        dispatch(onGetRoles({ userRole: userInfo.userRole, isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch }));
        dispatch(addRoleFail(null));
        dispatch(updateRoleFail(null));
        setIsRight(close);
    };

    const [filterState, setFilterState] = useState(false)

    const statusChange = (e) => {
        if (e.target.value === 'Active') {
            filterCount = filterCount > 0 ? filterCount - 1 : 0
        }
        if (statusSearch === 'Active') {
            filterCount = filterCount + 1
        }
        setStatusSearch(e.target.value)
    }

    const applyFilter = (e) => {
        dispatch(onGetRoles({ userRole: userInfo.userRole, isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch }));
        if (filterCount > 0) {
            setFilterState(true)
        } else {
            setFilterState(false)
        }
    }

    const [isMenu, setisMenu] = useState(false)
    const toggleMenu = () => {
        setisMenu(!isMenu)
    }

    const clearAllFilter = (e) => {
        setStatusSearch('Active')
        filterCount = 0
        setFilterState(false)
        dispatch(onGetRoles({ userRole: userInfo.userRole, isSupplyPartner:userInfo.isSupplyPartner,filter: 'Active' }));
    }

    useEffect(() => {
        if (roles !== null) {
            dispatch(onGetRoles({ userRole: userInfo.userRole, isSupplyPartner:userInfo.isSupplyPartner,filter: statusSearch }));
        }
    }, []);

    useEffect(() => {
        if (props.activeId === '2') {
            dispatch(onGetRoles({ userRole: userInfo.userRole, isSupplyPartner:userInfo.isSupplyPartner,filter: 'Active', role: '' }));
            setStatusSearch('Active')
        }
    }, [props.activeId]);

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const roleListColumns = [
        {
            text: "ID",
            headerClasses: "ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            text: "ID",
            headerClasses: "ID",
            dataField: "roleID",
            sort: true,
        },
        {
            dataField: "roleCreateTime",
            text: "Date",
            sort: true,
        },
        {
            text: "Role",
            dataField: "roleName",
            sort: true,
        },
        {
            dataField: "roleStatus",
            text: "Status",
            sort: true,
            formatter: (cellContent, role) => (
                <>
                    {role.roleStatus &&
                        <span className="active-state">Active</span>
                    }
                    {!role.roleStatus &&
                        <span className="inactive-state">Inactive</span>
                    }
                </>
            ),
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',
            hidden: (UserRolePrivileges.adminAccess && UserRolePrivileges.adminAccess.RoleManagement) && (!UserRolePrivileges.adminAccess.RoleManagement.update),
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, role) => (
                <div className="d-flex gap-3" >
                    <Link className="Email-state" to="#" onClick={toggleDrawer(true, role)}>
                    Edit
                    </Link>
                </div>
            ),
        },
    ];

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );


    const pageOptions = {
        paginationSize: 4,
        pageStartIndex: 1,
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };
    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const archiveRole = async () => {
        dispatch(onArchiveRole({ userRole: userInfo.userRole, idList: selectedItem,isSupplyPartner:userInfo.isSupplyPartner }));
    }

    const statusRole = (state) => (event) => {
        console.log(state)
        dispatch(onStatusRole({ userRole: userInfo.userRole, idList: selectedItem, roleStatus: state,isSupplyPartner:userInfo.isSupplyPartner }));
    }


    if (roles.length > 0) {
        if (UserRolePrivileges.adminAccess && UserRolePrivileges.adminAccess.RoleManagement) {
            if (!UserRolePrivileges.adminAccess.RoleManagement.archive && !UserRolePrivileges.adminAccess.RoleManagement.status) {
                roles.map((item, index) => {
                    selectRow.nonSelectable.push(index + 1)
                })
            } else {
                roles.map((item, index) => {
                    if (item.isRoleArchived) {
                        selectRow.nonSelectable.push(index + 1)
                    }
                })
            }
        }
    }

    return (
        <Row>
            <Col lg="12">
                {UserRolePrivileges.adminAccess && UserRolePrivileges.adminAccess.RoleManagement && UserRolePrivileges.adminAccess.RoleManagement.create &&
                    <div className=" text-sm-end mb-2">
                        <div className="btn-group">
                            <Dropdown
                                direction="left"
                                isOpen={isMenu}
                                toggle={toggleMenu}
                            >
                                <DropdownToggle
                                    tag="button"
                                    className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                                >
                                    <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                                    {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                                        Filter Applied
                                    </span> : <span className="d-none font-16 d-sm-inline-block">
                                        Filter
                                    </span>
                                    }
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                                    <div className="dropdown-item-text">
                                        <div className="row">
                                            <h5 className="mb-0">Filters</h5>
                                        </div>
                                    </div>

                                    <DropdownItem divider />
                                    <Card>
                                        <CardBody aria-hidden="true">
                                            <Row >
                                                <Col sm="12" className="mt-2">
                                                    <Label className="form-label">Status</Label>
                                                    <div className=" text-sm-end">
                                                        <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                                            <option value=''> All</option>
                                                            <option value='Active' > Active</option>
                                                            <option value='Inactive'> InActive</option>
                                                        </Input>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>

                                    <DropdownItem divider />
                                    <Row>
                                        <Col sm="6">
                                            <DropdownItem
                                                className="text-primary text-center"
                                                onClick={clearAllFilter}
                                            >
                                                Clear ALL
                                            </DropdownItem>
                                        </Col>
                                        <Col sm="6">
                                            <DropdownItem
                                                className="text-primary text-center"
                                                onClick={applyFilter}
                                            >
                                                Apply Filter
                                            </DropdownItem>
                                        </Col>
                                    </Row>

                                </DropdownMenu>
                            </Dropdown>
                        </div> &nbsp;&nbsp;
                        <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                            onClick={toggleRightCanvas}
                        >
                            <i className="mdi mdi-plus-circle-outline me-1" />
                            New Role
                        </Button>
                        &nbsp;&nbsp;
                        {/* Added by Anjali */}
                        {/* <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                            onClick={toggleRightCanvasAnjali}
                        >
                            <i className="mdi mdi-plus-circle-outline me-1" />
                            New Role Test Anjali
                        </Button> */}
                        {/* Added by Anjali */}
                    </div>
                }
            </Col>
            {switchLoader &&
                <LoadingTable loading={loadingAccess} targetLoading={handleSwitch} />
            }
            {!switchLoader &&
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <ToolkitProvider
                                keyField={keyField}
                                data={roles}
                                columns={roleListColumns}
                                // bootstrap4
                                search
                            >
                                {toolkitProps => (
                                    <React.Fragment>
                                        <Row className="mb-2">
                                            <Col sm="4">
                                                <div className="search-box ms-2 mb-2 d-inline-block">
                                                    <div className="position-relative">
                                                        <SearchBar {...toolkitProps.searchProps} />
                                                        <i className="bx bx-search-alt search-icon" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl="12">
                                                {selectedRowsStatus && selectedIndex.length > 0 &&
                                                    <div className="row m-0 React-Table-Selection">
                                                        {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                                        <div style={{ padding: '0.5% 2%' }}>

                                                            {/* <button color="primary" className="btn btn-primary " onClick={statusRole(true)}>Activate</button> */}
                                                            {UserRolePrivileges.adminAccess && UserRolePrivileges.adminAccess.RoleManagement && UserRolePrivileges.adminAccess.RoleManagement.status &&
                                                                <button color="primary" className="btn btn-primary " onClick={statusRole(true)}>
                                                                    Activate
                                                                </button>
                                                            }
                                                            &nbsp;  &nbsp;
                                                            {UserRolePrivileges.adminAccess && UserRolePrivileges.adminAccess.RoleManagement && UserRolePrivileges.adminAccess.RoleManagement.status &&
                                                                <button color="primary" className="btn btn-primary " onClick={statusRole(false)}>
                                                                    DeActivate
                                                                </button>
                                                            }
                                                            &nbsp;  &nbsp;
                                                            {/* <Button color="primary" className="btn btn-primary " onClick={archiveRole} >
                                                                        Archive
                                                                    </Button> */}
                                                            <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                                                className="mdi mdi-close-thick font-size-18"
                                                            ></i></Button>
                                                        </div>
                                                    </div>
                                                }
                                            </Col>
                                            <Col xl="12" className="table-responsive">
                                                <div >
                                                    <BootstrapTable
                                                        keyField={keyField}
                                                        responsive
                                                        bordered={false}
                                                        striped={false}
                                                        defaultSorted={defaultSorted}
                                                        selectRow={selectRow}
                                                        classes={
                                                            "table align-middle table-nowrap"
                                                        }
                                                        headerWrapperClasses={"thead-light"}
                                                        {...toolkitProps.baseProps}
                                                        pagination={paginationFactory(pageOptions)}
                                                        noDataIndication={<h5 className="text-center">No Data</h5>}
                                                    // ref={node}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                )}
                            </ToolkitProvider>
                        </CardBody>
                    </Card>
                </Col>
            }
            <Offcanvas isOpen={isRight} direction='end' className="CanvasLarge">
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {selectedData ? 'Edit Role' : 'Add New Role'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    {userInfo.isSupplyPartner ? <SupplyPartnerNewRole closeCanvas={toggleDrawer(false)} selectedRole={selectedData} /> : <New_Role closeCanvas={toggleDrawer(false)} selectedRole={selectedData} />}
                    {/* {userInfo.isSupplyPartner ? (
                        <SupplyPartnerNewRole closeCanvas={toggleDrawer(false)} selectedRole={selectedData} />
                    ) : [37, 38].includes(selectedData?.roleID) ? (
                        <New_Role_Test closeCanvas={toggleDrawer(false)} selectedRole={selectedData} />
                    ) : (
                        <New_Role closeCanvas={toggleDrawer(false)} selectedRole={selectedData} />
                    )} */}
                </OffcanvasBody>
            </Offcanvas>

            {/* Added by Anjali */}
            <Offcanvas isOpen={isRightAnjali} direction='end' className="CanvasLarge">
                <OffcanvasHeader toggle={toggleRightCanvasAnjali}>
                    {console.log('Anjali Select Role', selectedData)}
                    {selectedData ? 'Edit Role' : 'Add New Role'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    {userInfo.isSupplyPartner ? <SupplyPartnerNewRole closeCanvas={toggleDrawer(false)} selectedRole={selectedData} /> : <New_Role_Test closeCanvas={toggleDrawer(false)} selectedRole={selectedData} />}
                </OffcanvasBody>
            </Offcanvas>
            {/* Added by Anjali */}
        </Row>
    )
}

Roles_Management.propTypes = {}

export default Roles_Management