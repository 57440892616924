/* HIGH_IMPACT_SETTING */
export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES"
export const NO_DATA = "NO_DATA"
export const GET_HIGH_IMPACT_SETTINGS = "GET_HIGH_IMPACT_SETTINGS"
export const GET_HIGH_IMPACT_SETTINGS_SUCCESS = "GET_HIGH_IMPACT_SETTINGS_SUCCESS"
export const GET_HIGH_IMPACT_SETTINGS_FAIL = "GET_HIGH_IMPACT_SETTINGS_FAIL"

export const ADD_HIGH_IMPACT_SETTING = "ADD_HIGH_IMPACT_SETTING"
export const ADD_HIGH_IMPACT_SETTING_SUCCESS = "ADD_HIGH_IMPACT_SETTING_SUCCESS"
export const ADD_HIGH_IMPACT_SETTING_ERROR = "ADD_HIGH_IMPACT_SETTING_ERROR"

export const UPDATE_HIGH_IMPACT_SETTING = "UPDATE_HIGH_IMPACT_SETTING"
export const UPDATE_HIGH_IMPACT_SETTING_SUCCESS = "UPDATE_HIGH_IMPACT_SETTING_SUCCESS"
export const UPDATE_HIGH_IMPACT_SETTING_ERROR = "UPDATE_HIGH_IMPACT_SETTING_ERROR"

export const READ_HIGH_IMPACT_SETTING = "READ_HIGH_IMPACT_SETTING"
export const READ_HIGH_IMPACT_SETTING_SUCCESS = "READ_HIGH_IMPACT_SETTING_SUCCESS"

export const STATUS_HIGH_IMPACT_SETTING = "STATUS_HIGH_IMPACT_SETTING"
export const STATUS_HIGH_IMPACT_SETTING_SUCCESS = "STATUS_HIGH_IMPACT_SETTING_SUCCESS"

export const ARCHIVE_HIGH_IMPACT_SETTING = "ARCHIVE_HIGH_IMPACT_SETTING"
export const ARCHIVE_HIGH_IMPACT_SETTING_SUCCESS = "ARCHIVE_HIGH_IMPACT_SETTING_SUCCESS"