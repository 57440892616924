import {
    LOAD_WBCTG,
    NO_DATA_WBCTG,
    GET_WEBSITE_CATEGORIZATIONS_SUCCESS,
    GET_WEBSITE_CATEGORIZATIONS_FAIL,
    ADD_WEBSITE_CATEGORIZATION_SUCCESS,
    ADD_WEBSITE_CATEGORIZATION_ERROR,
    UPDATE_WEBSITE_CATEGORIZATION_SUCCESS,
    UPDATE_WEBSITE_CATEGORIZATION_ERROR,
    READ_WEBSITE_CATEGORIZATION_SUCCESS,
    STATUS_WEBSITE_CATEGORIZATION_SUCCESS,
    ARCHIVE_WEBSITE_CATEGORIZATION_SUCCESS,

    GET_LANGUAGE_SUCCESS,
    GET_LANGUAGE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
    websiteCategorizationList: [],
    languageList: [],
    error: {},
    loading:false,
    noData:false,
    successMsg: null,
    errorMsg: null,
    websiteCategorization: null,
    response:null,
    archiveMsg:null,
}

const websiteCategorizations = (state = INIT_STATE, action) => {
    
    switch (action.type) {
        case LOAD_WBCTG:
            return {
                ...state,
                loading: true,
            }
            case NO_DATA_WBCTG:
                return {
                    ...state,
                    noData: true,
                }
        case GET_WEBSITE_CATEGORIZATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                websiteCategorizationList: action.payload,
            }

        case GET_WEBSITE_CATEGORIZATIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case ADD_WEBSITE_CATEGORIZATION_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_WEBSITE_CATEGORIZATION_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_WEBSITE_CATEGORIZATIONS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_WEBSITE_CATEGORIZATION_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_WEBSITE_CATEGORIZATIONS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_WEBSITE_CATEGORIZATION_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_WEBSITE_CATEGORIZATIONS_SUCCESS' ? null : action.payload,
            }
        case READ_WEBSITE_CATEGORIZATION_SUCCESS:
            return {
                ...state,
                websiteCategorization: action.payload,
            }
        case STATUS_WEBSITE_CATEGORIZATION_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_WEBSITE_CATEGORIZATION_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
            case GET_LANGUAGE_SUCCESS:
                return {
                    ...state,
                    languageList: action.payload,
                }
    
            case GET_LANGUAGE_FAIL:
                return {
                    ...state,
                    error: action.payload,
                }
        default:
            return state
    }
}

export default websiteCategorizations