import {
    GET_PUB_PLAYLIST,
    GET_PUB_PLAYLIST_SUCCESS,
    GET_PUB_PLAYLIST_FAIL,
    GET_PUB_PLAYLISTBYID,
    GET_PUB_PLAYLISTBYID_SUCCESS,
    GET_PUB_PLAYLISTBYID_FAIL,
    CREATE_PUB_PLAYLIST,
    CREATE_PUB_PLAYLIST_SUCCESS,
    CREATE_PUB_PLAYLIST_FAIL,
    UPDATE_PUB_PLAYLIST,
    UPDATE_PUB_PLAYLIST_SUCCESS,
    UPDATE_PUB_PLAYLIST_FAIL,
    STATUS_PUB_PLAYLIST,
    STATUS_PUB_PLAYLIST_SUCCESS,
    ARCHIVE_PLAYLIST ,
    ARCHIVE_PUB_PLAYLIST_SUCCESS,
    ARCHIVE_PUB_PLAYLIST_FAIL
} from "./actionTypes"

export const getPubPlaylist = params => ({
    type: GET_PUB_PLAYLIST,
    payload: params
})

export const getPubPlaylistSuccess = playlist => ({
    type: GET_PUB_PLAYLIST_SUCCESS,
    payload: playlist
})

export const getPubPlaylistFail = error => ({
    type: GET_PUB_PLAYLIST_FAIL,
    payload: error
})
export const getPubPlaylistById = params => ({
    type: GET_PUB_PLAYLISTBYID,
    payload: params
})

export const getPubPlaylistSuccessById = playlist => ({
    type: GET_PUB_PLAYLISTBYID_SUCCESS,
    payload: playlist
})

export const getPubPlaylistFailById = error => ({
    type: GET_PUB_PLAYLISTBYID_FAIL,
    payload: error
})

export const createPubPlaylist = playlist => ({
    type: CREATE_PUB_PLAYLIST,
    payload: playlist
})

export const createPubPlaylistSuccess = playlist => ({
    type: CREATE_PUB_PLAYLIST_SUCCESS,
    payload: playlist
})

export const createPubPlaylistFail = error =>({
    type: CREATE_PUB_PLAYLIST_FAIL,
    payload: error
})

export const updatePubPlaylist = playlist =>({
    type: UPDATE_PUB_PLAYLIST,
    payload: playlist
})

export const updatePubPlaylistSuccess = playlist => ({
    type: UPDATE_PUB_PLAYLIST_SUCCESS,
    payload: playlist
})

export const updatePubPlaylistFail = error =>({
    type: UPDATE_PUB_PLAYLIST_FAIL,
    payload: error
})

export const statusPubPlaylist = playlist => ({
    type: STATUS_PUB_PLAYLIST,
    payload: playlist
  })

  export const statusPubPlaylistSuccess = playlist => ({
    type: STATUS_PUB_PLAYLIST_SUCCESS,
    payload: playlist
  })

  export const archivePubPlaylist = playlist => ({
    type: ARCHIVE_PLAYLIST,
    payload: playlist
  })

  export const archivePubPlaylistSuccess = playlist => ({
    type: ARCHIVE_PUB_PLAYLIST_SUCCESS,
    payload: playlist
  })

  export const archivePubPlaylistFail = error => ({
    type: ARCHIVE_PUB_PLAYLIST_FAIL,
    payload: error
  })