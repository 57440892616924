import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CONTEXTUAL_DATAS,ADD_CONTEXTUAL_DATA , UPDATE_CONTEXTUAL_DATA,READ_CONTEXTUAL_DATA,STATUS_CONTEXTUAL_DATA,ARCHIVE_CONTEXTUAL_DATA} from "./actionTypes"

import {
  loadNetBlockings,
  noDataNetBlockings,
  getContextualDatasSuccess,
  getContextualDatasFail,
  addNetBlockingSuccess,
  addNetBlockingFail,
  updateNetBlockingSuccess,
  updateNetBlockingFail,
  readNetBlockingSuccess,
  statusNetBlockingSuccess,
  archiveNetBlockingSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getContextualData,updateContextualData,statusChangeContextualData,archiveContextualData } from "../../../helpers/Backend_helper"

function* fetchContextualData({ payload: adv }) {
  try {
    yield put(loadNetBlockings(true))
    const response = yield call(getContextualData,adv)
    console.log(response)
    response.response.map((item, index) => {
      item.id = index + 1
  })
  console.log(response)
  if(response.response.length === 0){
    yield put(noDataNetBlockings(true))
  }
    yield put(getContextualDatasSuccess(response.response))
  } catch (error) {
    yield put(getContextualDatasFail(error))
  }
}


function* onUpdateNetBlocking({ payload: adv }) {
  try {
    const response = yield call(updateContextualData, adv)
    if(response.success){
      yield put(updateNetBlockingSuccess(response))
    }else{
      yield put(updateNetBlockingFail(response))
    }
  } catch (error) {
    console.log(error)
    yield put(updateNetBlockingFail(error))
  }
}

function* onStatusNetBlocking({ payload: adv }) {
  try {
    const response = yield call(statusChangeContextualData, adv)
    yield put(statusNetBlockingSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(statusNetBlockingSuccess(error))
  }
}

function* onArchiveNetBlocking({ payload: adv }) {
  try {
    const response = yield call(archiveContextualData, adv)
    yield put(archiveNetBlockingSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(archiveNetBlockingSuccess(error))
  }
}

function* contextualDataSaga() {
  yield takeEvery(GET_CONTEXTUAL_DATAS, fetchContextualData)
  yield takeEvery(UPDATE_CONTEXTUAL_DATA, onUpdateNetBlocking)
  yield takeEvery(STATUS_CONTEXTUAL_DATA, onStatusNetBlocking)
  yield takeEvery(ARCHIVE_CONTEXTUAL_DATA, onArchiveNetBlocking)
}

export default contextualDataSaga;
