/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react"
import {
    Card, CardBody, Col, Form, Row, Spinner, TabContent,
    TabPane, NavItem,
    NavLink, Button
} from "reactstrap"
 
import { Link } from "react-router-dom";

import classnames from "classnames"
import Video_Upload from "./Video_Upload";
import Video_General_Info from "./Video_General_Info";
import Select_Thumbnail from "./Select_Thumbnail";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// actions
import {
    addNewVideoContent as onADDVideoContent,
    addVideoContentSuccess,
    addVideoContentFail,
} from "store/Media/VideoContent/actions"; 

import {
    addNewPubVideoContent as onAddPubVideoContent,
    addPubVideoContentSuccess,
    addPubVideoContentFail
} from 'store/Media_Publisher/VideoContent/actions'


import { useSelector, useDispatch } from "react-redux";
import Link_Upload from "./Link_Upload";

var finalData = {
    videoContentOtherCategory: "",
    videoContentOtherDuration: "",
    videoContentOtherLanguage: "",
    videoContentOtherName: "",
    videoContentOtherMp4Path: "",
    videoContentOtherThumbnailPath: "",
    videoContentOtherCompressedMp4Path: '',
    videoContentOtherTsPath: [],
    videoContentOtherM3u8Path:'',
    isUploadedContent: true
}

const Create_Content = props => {
    const [activeTab, setactiveTab] = useState(1)
    const [passedSteps, setPassedSteps] = useState([1])
    const [loader, setLoader] = useState(false)
    const [videoFile, setVideoFile] = useState(null)
    const [videoFileName, setVideoFileName] = useState("")
    const userInfo = JSON.parse(localStorage.getItem('authUser'));

    const [creativeType, setCreativeType] = useState({
        creativeTypeName: 'selection',
    });

    const dispatch = useDispatch()

    console.log(props)



    const [data, setData] = useState({
        videoContentOtherName: '',
        videoContentOtherCategory: '',
        videoContentOtherLanguage: '',
        videoContentOtherMp4Path: "",
        videoContentOtherThumbnailPath: '',
        videoContentOtherDuration: '',
        videoContentOtherCompressedMp4Path: '',
        videoContentOtherTsPath: [],
        videoContentOtherM3u8Path:'',
        isUploadedContent: true
    })

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        progressBar: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 5000,
        extendedTimeOut: 1000
    };


    const handleVideoUpload = (file) => {
        setVideoFile(file)
    }

    const handleVideoName = name => {
        setVideoFileName(name)
    }

    const changeActiveState = (range) => {
        console.log(range)
        setactiveTab(range)
        setPassedSteps([...passedSteps, range])
    }

    function toggleTab(tab) {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab]
            if (tab >= 1 && tab <= 2) {
                setactiveTab(tab)
                setPassedSteps(modifiedSteps)
            }
        }
    }
    const handleSubmit = async (e) => {
        console.log(finalData)
        console.log("called submit")
        e.preventDefault();
        // setSubmitted(true);
        let partitionValidation = checkPartitionData()
        if (partitionValidation ) {
            setLoader(true)
            dispatch(onAddPubVideoContent({ ...finalData,isSupplyPartner:userInfo.isSupplyPartner }));
        }
    }

    const checkPartitionData = () => {
        if (finalData.videoContentOtherName === '') {
          toastr.error('Please Enter Video Content Name')
          return false
        }else if(finalData.videoContentOtherCategory === ''){
            toastr.error("Pleae Enter Video Content Category")
        } else{
          return true
        }
      }

    const { errorMsg, successMsg } = useSelector(state => ({
        errorMsg: state.pubVideoContents.errorMsg,
        successMsg: state.pubVideoContents.successMsg,
    }));


    useEffect(() => {
        console.log(successMsg)
        if (successMsg !== null) {
            if (successMsg.success) {
                dispatch(addPubVideoContentSuccess(""));
                setLoader(false)
                props.closeCanvas('Create')
                finalData = {
                    videoContentOtherCategory: "",
                    videoContentOtherDuration: "",
                    videoContentOtherLanguage: "",
                    videoContentOtherName: "",
                    videoContentOtherMp4Path: "",
                    videoContentOtherThumbnailPath: "",
                    videoContentOtherCompressedMp4Path: '',
                    videoContentOtherTsPath: [],
                    videoContentOtherM3u8Path:'',
                    isUploadedContent: true
                }
            }
        }
    }, [successMsg]);

    useEffect(() => {
        console.log(errorMsg)
        if (errorMsg !== null && errorMsg !== '') {
            setLoader(false)
            dispatch(addPubVideoContentFail(""));
            toastr.error(errorMsg)
            finalData = {
                videoContentOtherCategory: "",
                videoContentOtherDuration: "",
                videoContentOtherLanguage: "",
                videoContentOtherName: "",
                videoContentOtherMp4Path: "",
                videoContentOtherThumbnailPath: "",
                videoContentOtherCompressedMp4Path: '',
                videoContentOtherTsPath: [],
                videoContentOtherM3u8Path:'',
                isUploadedContent: true
            }
        }
    }, [errorMsg]);

    const getVideoData = async (savedData) => {
        console.log(savedData)
        setData({ ...data, ...savedData })
        finalData = { ...finalData, ...savedData }
        console.log(finalData)
    }

    return (
        <Col md="12">
            {loader &&
                <Spinner className="ms-2 loader" color="primary" />
            }
            <Form onSubmit={handleSubmit}>
                {/* <Form action="https://support-ticket.bidsxchange.com/create" encType="multipart/form-data" method="post"> */}
                <div style={{ padding: '1% 3%', height: '90vh', overflow: 'auto' }}>

                    <Row >
                        <Col lg="12" className="p-0">

                            <h4 className="card-title mb-4">Video Content Settings</h4>
                            <div className=" wizard clearfix vertical">
                                <div className="steps clearfix">
                                    <ul>
                                        <NavItem
                                            className={classnames({ current: activeTab === 1 })}
                                        >
                                            <NavLink
                                                className={classnames({ current: activeTab === 1 })}
                                                onClick={() => {
                                                    setactiveTab(1)
                                                }}
                                            // disabled={!(passedSteps || []).includes(1)}
                                            >
                                                <span className="number">1.</span>Upload Video
                                            </NavLink>
                                        </NavItem>
                                        <NavItem
                                            className={classnames({ current: activeTab === 2 })}
                                        >
                                            <NavLink
                                                className={classnames({ active: activeTab === 2 })}
                                                onClick={() => {
                                                    setactiveTab(2)
                                                }}
                                            // disabled={!(passedSteps || []).includes(2)}
                                            >
                                                <span className="number ">02</span> General Settings
                                            </NavLink>
                                        </NavItem>
                                        {/* <NavItem
                                            className={classnames({ current: activeTab === 3 })}
                                        >
                                            <NavLink
                                                className={classnames({ active: activeTab === 3 })}
                                                onClick={() => {
                                                    setactiveTab(3)
                                                }}
                                            >
                                                <span className="number">03</span> Select Thumbnail
                                            </NavLink>
                                        </NavItem> */}

                                    </ul>
                                </div>
                                <div className="content clearfix ">
                                    <TabContent activeTab={activeTab} className="body">
                                        <TabPane tabId={1}>
                                            <Video_Upload saveData={getVideoData} uploadType={props.uploadType} tabId={activeTab} changeActiveState={changeActiveState} onVideoUpload={handleVideoUpload} onVideoName={handleVideoName} type={props.type} videoContentPath={data.videoContentOtherMp4Path} videoFile={videoFile} videoFileName={finalData.videoContentOtherName} />
                                        </TabPane>
                                        <TabPane tabId={2}>
                                            <div>
                                                <Video_General_Info saveData={getVideoData} tabId={activeTab} changeActiveState={changeActiveState} videoFileName={videoFileName} />
                                            </div>
                                        </TabPane>
                                        {/* <TabPane tabId={3}>
                                            <div>
                                                <Select_Thumbnail saveData={getVideoData} tabId={activeTab} videoContentPath={data.videoContentPath} videoFile={videoFile} videoFileName={finalData.videoContentOtherName} />
                                            </div>
                                        </TabPane> */}

                                    </TabContent>
                                </div>
                                <div className="actions clearfix">
                                    <ul>
                                        <li
                                            className={
                                                activeTab === 1 ? "previous disabled" : "previous"
                                            }
                                        >
                                            <Link
                                                to="#"
                                                onClick={() => {
                                                    toggleTab(activeTab - 1)
                                                }}
                                            >
                                                Previous
                                            </Link>
                                        </li>
                                        <li
                                            className={activeTab === 3 ? "next disabled" : "next"}
                                        >
                                            <Link
                                                to="#"
                                                onClick={() => {
                                                    toggleTab(activeTab + 1)
                                                }}
                                            >
                                                Next
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </Col>
                    </Row>

                </div>
                <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
                    <Col>
                        <div className="text-start">
                            <Button type="submit"
                                color="primary"
                            >
                                Create Content
                            </Button>
                            &nbsp;&nbsp;
                            <button
                                type="button"
                                className="btn btn-outline-primary"
                                // eslint-disable-next-line react/prop-types
                                onClick={props.creativeTypeClose}
                            >
                                Back
                            </button>
                        </div>
                    </Col>
                </Row>
            </Form>

        </Col >


    )
}

Create_Content.propTypes = {}

export default Create_Content