import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
} from "reactstrap"

import DateRangePickers from 'helpers/DateRangePicker'
import SimpleBar from "simplebar-react";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';
import LoadingTable from "pages/LoaderPages/LoadingTable";

const lineItemDataTable = props => {
  console.log(props)
  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [switchLoader, setSwitchLoader] = useState(true)

  const changeDatePick = (event) => {
    setFromDate(event.startDate._d.toISOString());
    setToDate(event.endDate._d.toISOString());
  }

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  useEffect(() => {
    if (props.loading) {
      setSwitchLoader(true)
    }
  }, [props.loading]);

  const keyField = "id";

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );

  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  return (
    <React.Fragment>
      {switchLoader &&
        <LoadingTable loading={props.loading} targetLoading={handleSwitch} />
      }
      {!switchLoader &&
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <ToolkitProvider
                  keyField={keyField}
                  data={props.data}
                  columns={props.columns}
                  // bootstrap4
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col sm="4">
                          <div className="search-box ms-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <Col sm="4"></Col>
                        <Col sm="4" className="date-range ">
                          <div className="text-sm-end">
                            <DateRangePickers datePick={changeDatePick} />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          {props.selectedIndex.length > 0 &&
                            <div className="row m-0 React-Table-Selection">
                              {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                              <div style={{ padding: '0.5% 2%' }}>

                                {/* <button color="primary" className="btn btn-primary " onClick={statusLineItem(true)}>Activate</button> */}
                                {UserRolePrivileges.Demand && UserRolePrivileges.Demand.LineItems && UserRolePrivileges.Demand.LineItems.status &&
                                  <button color="primary" className="btn btn-primary " onClick={props.statusChange(true)}>
                                    Activate
                                  </button>
                                }
                                &nbsp;  &nbsp;
                                {UserRolePrivileges.Demand && UserRolePrivileges.Demand.LineItems && UserRolePrivileges.Demand.LineItems.status &&
                                  <button color="primary" className="btn btn-primary " onClick={props.statusChange(false)}>
                                    DeActivate
                                  </button>
                                }
                                &nbsp;  &nbsp;
                                {UserRolePrivileges.Demand && UserRolePrivileges.Demand.LineItems && UserRolePrivileges.Demand.LineItems.archive &&
                                  <Button color="primary" className="btn btn-primary " onClick={props.archiveLineItem} >
                                    Archive
                                  </Button>
                                }
                                <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={props.handleClose} >  <i
                                  className="mdi mdi-close-thick font-size-18"
                                ></i></Button>
                              </div>
                            </div>
                          }
                        </Col>
                        <Col xl="12 " className="table-responsive">
                               <SimpleBar className="h-100">
                               <BootstrapTable
                            keyField={keyField}
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            selectRow={props.rowSelection}
                            classes={
                              "table align-middle table-nowrap"
                            }
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            pagination={paginationFactory(pageOptions)}
                            noDataIndication={<h5 className="text-center">No Data</h5>}
                          // ref={node}
                          />
                               </SimpleBar>
                       
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      }
    </React.Fragment >
  )
}

lineItemDataTable.propTypes = {

}

export default lineItemDataTable
