/* SIZE */
export const GET_SIZES = "GET_SIZES"
export const GET_SIZES_SUCCESS = "GET_SIZES_SUCCESS"
export const GET_SIZES_FAIL = "GET_SIZES_FAIL"

/* Devices */
export const GET_DEVICES = "GET_DEVICES"
export const GET_DEVICE_SUCCESS = "GET_DEVICE_SUCCESS"
export const GET_DEVICE_FAIL = "GET_DEVICE_FAIL"

/* OS */
export const GET_OSS = "GET_OSS"
export const GET_OS_SUCCESS = "GET_OS_SUCCESS"
export const GET_OS_FAIL = "GET_OS_FAIL"

/* BROWSER */
export const GET_BROWSERS = "GET_BROWSERS"
export const GET_BROWSER_SUCCESS = "GET_BROWSER_SUCCESS"
export const GET_BROWSER_FAIL = "GET_BROWSER_FAIL"

/* Domains */
export const GET_DOMAINS = "GET_DOMAINS"
export const GET_DOMAIN_SUCCESS = "GET_DOMAIN_SUCCESS"
export const GET_DOMAIN_FAIL = "GET_DOMAIN_FAIL"

/* PageUrls */
export const GET_PAGE_URLS = "GET_PAGE_URLS"
export const GET_PAGE_URL_SUCCESS = "GET_PAGE_URL_SUCCESS"
export const GET_PAGE_URL_FAIL = "GET_PAGE_URL_FAIL"

/* PageUrls Key */
export const GET_INTERESTS = "GET_INTERESTS"
export const GET_INTEREST_SUCCESS = "GET_INTEREST_SUCCESS"
export const GET_INTEREST_FAIL = "GET_INTEREST_FAIL"

/* Keywords */
export const GET_KEYWORDS = "GET_KEYWORDS"
export const GET_KEYWORD_SUCCESS = "GET_KEYWORD_SUCCESS"
export const GET_KEYWORD_FAIL = "GET_KEYWORD_FAIL"

/* Languages */
export const GET_LANGUAGES = "GET_LANGUAGES"
export const GET_LANGUAGE_SUCCESS = "GET_LANGUAGE_SUCCESS"
export const GET_LANGUAGE_FAIL = "GET_LANGUAGE_FAIL"

/* Categories */
export const GET_CATEGORYS = "GET_CATEGORYS"
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS"
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL"

/* Brand Affinities */
export const GET_BRAND_AFFINITYS = "GET_BRAND_AFFINITYS"
export const GET_BRAND_AFFINITY_SUCCESS = "GET_BRAND_AFFINITY_SUCCESS"
export const GET_BRAND_AFFINITY_FAIL = "GET_BRAND_AFFINITY_FAIL"

/* Regions */
export const GET_REGIONS = "GET_REGIONS"
export const GET_REGION_SUCCESS = "GET_REGION_SUCCESS"
export const GET_REGION_FAIL = "GET_REGION_FAIL"

/* Cities */
export const GET_CITYS = "GET_CITYS"
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS"
export const GET_CITY_FAIL = "GET_CITY_FAIL"




