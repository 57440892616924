/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback, Spinner } from "reactstrap";
import ReactHlsPlayer from 'react-hls-player'

const VideoPlayer = props => {
    console.log(props)
    return (
        <React.Fragment>
            <Col xs={12} style={{ padding: '5%' }}>
                <div className="mb-3">
                    {props.isCDNLink ?



                        <video type="video/mp4" muted style={{ padding: '12px', borderRadius: '1.2rem', width: '100%' }}
                            src={props.selectedVideo && props.selectedVideo.videoURL} controls autoPlay >
                        </video>
                        :
                        <ReactHlsPlayer
                            src={props.selectedVideo && props.selectedVideo.videoContentM3u8URL
                            }
                            autoPlay={true}
                            controls={true}
                            muted={true}
                            width="100%"
                        />
                    }
                </div>
                <h5 className="text-left m-3" style={{ fontWeight: 600 }}> Name: <span style={{ fontWeight: 400 }}>{props.selectedVideo && props.selectedVideo.videoContentName} </span> </h5>

                <h6 style={{ fontWeight: 600 }} className="text-left m-3">Category :  <span style={{ fontWeight: 400 }}>{props.selectedVideo && props.selectedVideo.videoContentCategory} </span> &nbsp;&nbsp; |  &nbsp;&nbsp; Language :  <span style={{ fontWeight: 400 }}>{props.selectedVideo && props.selectedVideo.videoContentLanguage}</span> </h6>

            </Col>
        </React.Fragment>
    )
}

VideoPlayer.propTypes = {}

export default VideoPlayer