import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_BILL_HISTORY,GET_BILL_HISTORY_LIST} from "./actionTypes"

import {
  loadActivitiesBills,
  noDataBills,
  getBillHistorySuccess,
  getBillHistoryFail,
  getBillHistoryListSuccess,
  getBillHistoryListFail
} from "./actions"

//Include Both Helper File with needed methods
import { getBillHistory,getBillHistoryList} from "../../helpers/Backend_helper"

function* fetchBillHistory({ payload: bills }) {
  try {
    yield put(loadActivitiesBills(true))
    const response = yield call(getBillHistory,bills)
    console.log(response)
    if(response.response.length === 0){
      yield put(noDataBills(true))
    }
    yield put(getBillHistorySuccess(response.response))
  } catch (error) {
    yield put(getBillHistoryFail(error))
  }
}

function* fetchBillHistoryList({ payload: bills }) {
  try {
    yield put(loadActivitiesBills(true))
    const response = yield call(getBillHistoryList,bills)
    if(response.response.length === 0){
      yield put(noDataBills(true))
    }
    yield put(getBillHistoryListSuccess(response.response))
  } catch (error) {
    yield put(getBillHistoryListFail(error))
  }
}

function* billsSaga() {
  yield takeEvery(GET_BILL_HISTORY, fetchBillHistory)
  yield takeEvery(GET_BILL_HISTORY_LIST,fetchBillHistoryList)
}

export default billsSaga;
