/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from "axios";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Spinner,
    Label, Form
} from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';

import { MultiSelect } from "react-multi-select-component";

import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table-nextgen-toolkit';

import moment from "moment";

import {
    getRevenueDisplayReports as onGetRevenueDisplayReports,
} from "store/Proxy/ProxyAdUnits/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

//Import Breadcrumb
import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";

var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 2 : 1

var fromDate = moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days')
var toDate = moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')
var loader = false


const Revenue_Report = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const dispatch = useDispatch();
    const [reportOpen, setReportOpen] = useState(false);
    const [btnprimary1, setBtnprimary1] = useState(false)
    const [totalCodeServed, setTotalCodeServed] = useState(0)
    const [totalImpressions, setTotalImpressions] = useState(0)
    const [totalRevenue, setTotalRevenue] = useState(0)
    const [AvgRPM, setAvgRPM] = useState(0)
    const [AvgFillRate, setAvgFillRate] = useState(0)
    const [totalClicks, setTotalClicks] = useState(0)
    const [clicksPresent, setClicksPresent] = useState(false)

    var currentTimeStamp = Date.now()

    const [AccountManager, setAccountManager] = useState([]);
    const [PublisherManager, setPublisherManager] = useState([]);
    const [Partner, setPartner] = useState([]);
    const [Publisher, setPublisher] = useState([]);
    const [Domain, setDomian] = useState([]);
    const [AdUnitName, setAdUnitName] = useState([]);
    const [AdUnitSize, setAdUnitSize] = useState([]);
    const [InventoryType, setInventoryType] = useState([]);
    const [DealType, setDealType] = useState([]);
    const [Device, setDevice] = useState([]);

    const [selectedAccountManager, setSelectedAccountManager] = useState([]);
    const [selectedPublisherManager, setSelectedPublisherManager] = useState([]);
    const [selectedPartner, setSelectedPartner] = useState([]);
    const [selectedPublisher, setSelectedPublisher] = useState([]);
    const [selectedDomain, setSelectedDomain] = useState([]);
    const [selectedAdUnitName, setSelectedAdUnitName] = useState([]);
    const [selectedAdUnitSize, setSelectedAdUnitSize] = useState([]);
    const [selectedInventoryType, setSelectedInventoryType] = useState([]);
    const [selectedDealType, setSelectedDealType] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState([]);

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const { ExportCSVButton } = CSVExport;

    const [selectAll, setSelectAll] = useState(false)
    const [selectMetrice, setSelectMetrice] = useState(false)
    const [range, setRange] = useState('Day')
    // const [loader, setLoader] = useState(false)
    const [selectAllMTC, setSelectAllMTC] = useState(false)

    const [selectAllDM, setSelectAllDM] = useState({
        AccountManager: false,
        PublisherManager: false,
        Partner: false,
        Publisher: false,
        Domain: false,
        AdUnitName: false,
        AdUnitSize: false,
        InventoryType: false,
        DealType: false,
        Device: false,
    });

    const [filters, setFilters] = useState({
        AccountManager: [],
        PublisherManager: [],
        Partner: [],
        Publisher: [],
        Domain: [],
        AdUnitName: [],
        AdUnitSize: [],
        InventoryType: [],
        DealType: [],
        Device: [],
    });

    const [filterData, setFilterData] = useState({
        AccountManager: [],
        PublisherManager: [],
        Partner: [],
        Publisher: [],
        Domain: [],
        AdUnitName: [],
        AdUnitSize: [],
        InventoryType: [],
        DealType: [],
        Device: [],
    })

    const [selectedMTC, setSelectedMTC] = useState({
        TotalCodeServed: false,
        PaidImpressions: false,
        Revenue: false,
        RPM: false,
        FillRate: false,
    });

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            name: userInfo.name
        }
    }

    const handleSelectAccountManager = accountManager => {
        setSelectedAccountManager(accountManager)
        let listData = []
        accountManager.map((item, index) => {
            listData.push(item.value)
        })
        setFilters(filters => ({ ...filters, AccountManager: listData }));
        changeData('AccountManager', listData)
    }

    const handleSelectPublisherManager = publisherManager => {
        setSelectedPublisherManager(publisherManager)
        let listData = []
        publisherManager.map((item, index) => {
            listData.push(item.value)
        })
        setFilters(filters => ({ ...filters, PublisherManager: listData }));
        changeData('PublisherManager', listData)
    }

    const handleSelectPartner = partner => {
        setSelectedPartner(partner)
        let listData = []
        partner.map((item, index) => {
            listData.push(item.value)
        })
        setFilters(filters => ({ ...filters, Partner: listData }));
        checkPartnerClick(listData)
        changeData('Partner', listData)
    }

    const checkPartnerClick = (data) => {
        body.url = "http://127.0.0.1:2003/v1.0/Query/Revenue/DisplayMatrixFilter"
        body.requestBody = {
            ...body.requestBody,
            "Partner": data,
        }
        axios.post("/v1.0/connectOP", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data) {
                    if (res.data.dimensions.indexOf('Click') >= 0) {
                        setClicksPresent(true)
                        setSelectedMTC(selectedMTC => ({ ...selectedMTC, Click: false }))
                    }else{
                        setClicksPresent(false)
                        setSelectedMTC({
                            TotalCodeServed: false,
                            PaidImpressions: false,
                            Revenue: false,
                            RPM: false,
                            FillRate: false,
                        });
                    }
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    };

    const handleSelectPublisher = publisher => {
        setSelectedPublisher(publisher)
        let listData = []
        publisher.map((item, index) => {
            listData.push(item.value)
        })
        setFilters(filters => ({ ...filters, Publisher: listData }));
        changeData('Publisher', listData)
    }

    const handleSelectDomian = domian => {
        setSelectedDomain(domian)
        let listData = []
        domian.map((item, index) => {
            listData.push(item.value)
        })
        setFilters(filters => ({ ...filters, Domain: listData }));
        changeData('Domain', listData)
    }

    const handleSelectAdUnitName = adUnitName => {
        setSelectedAdUnitName(adUnitName)
        let listData = []
        adUnitName.map((item, index) => {
            listData.push(item.value)
        })
        setFilters(filters => ({ ...filters, AdUnitName: listData }));
        changeData('AdUnitName', listData)
    }

    const handleSelectAdUnitSize = adUnitSize => {
        setSelectedAdUnitSize(adUnitSize)
        let listData = []
        adUnitSize.map((item, index) => {
            listData.push(item.value)
        })
        setFilters(filters => ({ ...filters, AdUnitSize: listData }));
        changeData('AdUnitSize', listData)
    }

    const handleSelectInventoryType = inventoryType => {
        setSelectedInventoryType(inventoryType)
        let listData = []
        inventoryType.map((item, index) => {
            listData.push(item.value)
        })
        setFilters(filters => ({ ...filters, InventoryType: listData }));
        changeData('InventoryType', listData)
    }

    const handleSelectDealType = dealType => {
        setSelectedDealType(dealType)
        let listData = []
        dealType.map((item, index) => {
            listData.push(item.value)
        })
        setFilters(filters => ({ ...filters, DealType: listData }));
        changeData('DealType', listData)
    }

    const handleSelectDevice = device => {
        setSelectedDevice(device)
        let listData = []
        device.map((item, index) => {
            listData.push(item.value)
        })
        setFilters(filters => ({ ...filters, Device: listData }));
        changeData('Device', listData)
    }


    const changeFilter = (e) => {
        const { name, value } = e.target;
        setFilters(filters => ({ ...filters, [name]: value }));
        changeData(e)
    }

    const changeMTCSelect = (e) => {
        setSelectAllMTC(e.target.checked)
        setSelectedMTC({
            TotalCodeServed: e.target.checked,
            PaidImpressions: e.target.checked,
            Revenue: e.target.checked,
            RPM: e.target.checked,
            FillRate: e.target.checked,
        })
        clicksPresent ?  setSelectedMTC(selectedMTC => ({ ...selectedMTC, Click: e.target.checked })) : ''
    }

    const changeSelectionMTC = (e) => {
        const { name, value } = e.target;
        setSelectedMTC(selectedMTC => ({ ...selectedMTC, [name]: e.target.checked }));
        let newMTC = { ...selectedMTC, [name]: e.target.checked }
        let MtcArr = Object.values(newMTC)
        if (!MtcArr.includes(false)) {
            setSelectAllMTC(true)
        } else {
            setSelectAllMTC(false)
        }
    }



    useEffect(() => {
        fromDate = moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days')
        toDate = moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')

        body.url = "http://127.0.0.1:2003/v1.0/Query/Revenue/DisplayGetFilter"
        axios.post("/v1.0/connectOP", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data) {
                    // setFilterData(res.data)
                    objectModalData(res.data)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }, []);


    const changeData = (name, data) => {
        resetSelectedData(name)
        // const { name, value } = data.target;
        body.url = "http://127.0.0.1:2003/v1.0/Query/Revenue/DisplayGetSpecificFilter"
        body.requestBody = {
            ...body.requestBody,
            "AccountManager": name === 'AccountManager' ? data : [],
            "PublisherManager": name === 'PublisherManager' ? data : [],
            "Partner": name === 'Partner' ? data : [],
            "Publisher": name === 'Publisher' ? data : [],
            "Domain": name === 'Domain' ? data : [],
            AdUnitName: name === 'AdUnitName' ? data : [],
            AdUnitSize: name === 'AdUnitSize' ? data : [],
            InventoryType: name === 'InventoryType' ? data : [],
            DealType: name === 'DealType' ? data : [],
            Device: name === 'Device' ? data : [],
        }
        axios.post("/v1.0/connectOP", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data) {
                    // setFilterData({ ...filterData, ...res.data })
                    objectModalData(res.data)
                    console.log(filterData)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    };

    const resetSelectedData = (name) => {
        if (name === 'AccountManager') {
            setSelectedPublisherManager([])
            setSelectedPartner([])
            setSelectedPublisher([])
            setSelectedDomain([])
            setSelectedAdUnitName([])
            setSelectedAdUnitSize([])
            setSelectedInventoryType([])
            setSelectedDealType([])
            setSelectedDevice([])
            setFilters(filters => ({
                ...filters,
                PublisherManager: [],
                Partner: [],
                Publisher: [],
                Domain: [],
                AdUnitName: [],
                AdUnitSize: [],
                InventoryType: [],
                DealType: [],
                Device: [],
            }));
        }
        else if (name === 'PublisherManager') {
            setSelectedPartner([])
            setSelectedPublisher([])
            setSelectedDomain([])
            setSelectedAdUnitName([])
            setSelectedAdUnitSize([])
            setSelectedInventoryType([])
            setSelectedDealType([])
            setSelectedDevice([])
            setFilters(filters => ({
                ...filters,
                Partner: [],
                Publisher: [],
                Domain: [],
                AdUnitName: [],
                AdUnitSize: [],
                InventoryType: [],
                DealType: [],
                Device: [],
            }));
        }
        else if (name === 'Partner') {
            setSelectedPublisher([])
            setSelectedDomain([])
            setSelectedAdUnitName([])
            setSelectedAdUnitSize([])
            setSelectedInventoryType([])
            setSelectedDealType([])
            setSelectedDevice([])
            setFilters(filters => ({
                ...filters,
                Publisher: [],
                Domain: [],
                AdUnitName: [],
                AdUnitSize: [],
                InventoryType: [],
                DealType: [],
                Device: [],
            }));
        }
        else if (name === 'Publisher') {
            setSelectedDomain([])
            setSelectedAdUnitName([])
            setSelectedAdUnitSize([])
            setSelectedInventoryType([])
            setSelectedDealType([])
            setSelectedDevice([])
            setFilters(filters => ({
                ...filters,
                Domain: [],
                AdUnitName: [],
                AdUnitSize: [],
                InventoryType: [],
                DealType: [],
                Device: [],
            }));
        }
        else if (name === 'Domain') {
            setSelectedAdUnitName([])
            setSelectedAdUnitSize([])
            setSelectedInventoryType([])
            setSelectedDealType([])
            setSelectedDevice([])
            setFilters(filters => ({
                ...filters,
                AdUnitName: [],
                AdUnitSize: [],
                InventoryType: [],
                DealType: [],
                Device: [],
            }));
        }
        else if (name === 'AdUnitName') {
            setSelectedAdUnitSize([])
            setSelectedInventoryType([])
            setSelectedDealType([])
            setSelectedDevice([])
            setFilters(filters => ({
                ...filters,
                AdUnitSize: [],
                InventoryType: [],
                DealType: [],
                Device: [],
            }));
        }
        else if (name === 'AdUnitSize') {
            setSelectedInventoryType([])
            setSelectedDealType([])
            setSelectedDevice([])
            setFilters(filters => ({
                ...filters,
                InventoryType: [],
                DealType: [],
                Device: [],
            }));
        }
        else if (name === 'InventoryType') {
            setSelectedDealType([])
            setSelectedDevice([])
            setFilters(filters => ({
                ...filters,
                DealType: [],
                Device: [],
            }));
        }
        else if (name === 'DealType') {
            setSelectedDevice([])
            setFilters(filters => ({
                ...filters,
                Device: [],
            }));
        }
        else {
        }
    }

    const objectModalData = (response) => {
        if (response.AccountManager) {
            setAccountManager(objectModal(response.AccountManager))
        }
        if (response.PublisherManager) {
            setPublisherManager(objectModal(response.PublisherManager))
        }
        if (response.Partner) {
            setPartner(objectModal(response.Partner))
        }
        if (response.Publisher) {
            setPublisher(objectModal(response.Publisher))
        }
        if (response.Domain) {
            setDomian(objectModal(response.Domain))
        }
        if (response.AdUnitName) {
            setAdUnitName(objectModal(response.AdUnitName))
        }
        if (response.AdUnitSize) {
            setAdUnitSize(objectModal(response.AdUnitSize))
        }
        if (response.InventoryType) {
            setInventoryType(objectModal(response.InventoryType))
        }
        if (response.DealType) {
            setDealType(objectModal(response.DealType))
        }
        if (response.Device) {
            setDevice(objectModal(response.Device))
        }
    }

    const objectModal = (response) => {
        console.log
        var dataList = []
        response.map((item, index) => {
            let obj = {}
            obj.value = item
            obj.label = item
            dataList.push(obj)
        })
        return dataList
    }

    const changeDMSelect = (e) => {
        setSelectAll(e.target.checked)
        setSelectAllDM({
            AccountManager: e.target.checked,
            PublisherManager: e.target.checked,
            Partner: e.target.checked,
            Publisher: e.target.checked,
            Domain: e.target.checked,
            AdUnitName: e.target.checked,
            AdUnitSize: e.target.checked,
            InventoryType: e.target.checked,
            DealType: e.target.checked,
            Device: e.target.checked,
        })
    }

    const changeSelection = (e) => {
        setSelectAll(false)
        const { name, value } = e.target;
        setSelectAllDM(selectAllDM => ({ ...selectAllDM, [name]: e.target.checked }));
    }

    const changeRange = (e) => {
        setRange(e.target.value)
    }



    const { errorMsg, successMsg } = useSelector(state => ({
        errorMsg: state.proxyAdUnit.errorMsg,
        successMsg: state.proxyAdUnit.successMsg,
    }));

    console.log(errorMsg, successMsg)

    const { revenueDisplayReports } = useSelector(state => ({
        revenueDisplayReports: state.proxyAdUnit.revenueDisplayRpt,
    }));

    const { loading } = useSelector(state => ({
        loading: state.proxyAdUnit.loading,
    }));

    console.log(revenueDisplayReports)

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const revenueDisplayReportsColumns = [
        {
            text: "No",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
            footer: ''
        },
    ];

    const createColoumn = () => {

    }

    const { SearchBar } = Search;

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );


    const pageOptions = {
        paginationSize: 4,
        pageStartIndex: 1,
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };

    const defaultSorted = [
        {
            dataField: "requests", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        let DimArr = Object.values(selectAllDM)
        let MtcArr = Object.values(selectedMTC)
        if (!MtcArr.includes(true)) {
            toastr.warning('Please select atleast one Metrices')
        }
        else {
            // setLoader(true)
            loader = true

            let query = {
                "dimensions": selectAllDM,
                "startDate": moment(fromDate).format("YYYY-MM-DD"),
                "endDate": moment(toDate).format("YYYY-MM-DD"),
                "filters": filters,
                "metrices": selectedMTC,
                "range": range,
                name: userInfo.name
            }
            console.log(query)
            dispatch(onGetRevenueDisplayReports({ ...query }));
            setReportOpen(true)
        }
    }

    if (revenueDisplayReports.length > 0) {
        let keys = [];
        for (var k in revenueDisplayReports[0]) keys.push(k);
        if (keys.includes("hour")) {
            revenueDisplayReports.map((item, index) => {
                item.hour = parseInt(item.hour)
            })
        }
        console.log(keys)
        if (keys.includes("Date")) {
            revenueDisplayReportsColumns.push({
                dataField: "Date",
                text: "Date",
                sort: true,
                footer: 'Total'
            })
        }
        if (keys.includes("Month")) {
            revenueDisplayReportsColumns.push({
                dataField: "Month",
                text: "Month",
                sort: true,
                footer: 'Total'
            })
        }
        if (keys.includes("hour")) {
            revenueDisplayReportsColumns.push({
                dataField: "hour",
                text: "Hour",
                sort: true,
                footer: ''
            })
        }
        if (keys.includes("AccountManager")) {
            revenueDisplayReportsColumns.push({
                dataField: "AccountManager",
                text: "Account Manager",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("PublisherManager")) {
            revenueDisplayReportsColumns.push({
                dataField: "PublisherManager",
                text: "Publisher Manager",
                sort: true,
                footer: ''
            })
        }
        if (keys.includes("Partner")) {
            revenueDisplayReportsColumns.push({
                dataField: "Partner",
                text: "Partner",
                sort: true,
                style: { wordBreak: 'break-word' },
                footer: ''
            })
        }
        if (keys.includes("Publisher")) {
            revenueDisplayReportsColumns.push({
                dataField: "Publisher",
                text: "Publisher",
                sort: true,
                style: { wordBreak: 'break-word' },
                footer: ''
            })
        }
        if (keys.includes("Domain")) {
            revenueDisplayReportsColumns.push({
                dataField: "Domain",
                text: "Domain",
                sort: true,
                style: { wordBreak: 'break-word' },
                footer: ''
            })
        }
        if (keys.includes("AdUnitName")) {
            revenueDisplayReportsColumns.push({
                dataField: "AdUnitName",
                text: "Ad Unit",
                sort: true,
                style: { wordBreak: 'break-word' },
                footer: '',
                headerStyle: (colum, colIndex) => {
                    return { width: '80px', textAlign: 'center' };
                }
            })
        }
        if (keys.includes("AdUnitSize")) {
            revenueDisplayReportsColumns.push({
                dataField: "AdUnitSize",
                text: "Size",
                sort: true,
                style: { wordBreak: 'break-word' },
                footer: ''
            })
        }
        if (keys.includes("InventoryType")) {
            revenueDisplayReportsColumns.push({
                dataField: "InventoryType",
                text: "Inventory Type",
                sort: true,
                style: { wordBreak: 'break-word' },
                footer: ''
            })
        }
        if (keys.includes("DealType")) {
            revenueDisplayReportsColumns.push({
                dataField: "DealType",
                text: "Deal Type",
                sort: true,
                style: { wordBreak: 'break-word' },
                footer: ''
            })
        }
        if (keys.includes("Device")) {
            revenueDisplayReportsColumns.push({
                dataField: "Device",
                text: "Device",
                sort: true,
                style: { wordBreak: 'break-word' },
                footer: ''
            })
        }
        if (keys.includes("TotalCodeServed")) {
            revenueDisplayReportsColumns.push({
                dataField: "TotalCodeServed",
                text: "Total Code Served",
                sort: true,
                style: { wordBreak: 'break-word' },
                footer: `${totalCodeServed}`
            })
        }
        if (keys.includes("PaidImpressions")) {
            revenueDisplayReportsColumns.push({
                dataField: "PaidImpressions",
                text: "Paid Impressions",
                sort: true,
                footer: `${totalImpressions}`
            })
        }
        if (keys.includes("Click")) {
            revenueDisplayReportsColumns.push({
                dataField: "Click",
                text: "Clicks",
                sort: true,
                footer: `${totalClicks}`
            })
        }
        if (keys.includes("Revenue")) {
            revenueDisplayReportsColumns.push({
                dataField: "Revenue",
                text: "Revenue",
                sort: true,
                footer: `${totalRevenue}`,
                formatter: (cellContent, revenue) => (
                    <>
                        <span>{((parseFloat(revenue.Revenue)).toFixed(2))}</span>
                    </>
                ),
            })
        }
        if (keys.includes("RPM")) {
            revenueDisplayReportsColumns.push({
                dataField: "RPM",
                text: "RPM",
                sort: true,
                footer: `${AvgRPM}`
            })
        }
        if (keys.includes("FillRate")) {
            revenueDisplayReportsColumns.push({
                dataField: "FillRate",
                text: "Fill Rate",
                sort: true,
                footer: `${AvgFillRate}` + '%'
            })
        }

        loader = false
        console.log(revenueDisplayReportsColumns)
    } else {
        loader = false
    }

    useEffect(() => {
        console.log(revenueDisplayReports)

        if (revenueDisplayReports !== null && revenueDisplayReports.length > 0) {
            // setLoader(false)
            loader = false
            let totalCodeServed = 0
            let paidImpressions = 0
            let totalClicksCount = 0
            let revenue = 0
            let avgRPM = 0
            let avgFillRate = 0
            revenueDisplayReports.map((item, index) => {
                if (item.Date) {
                    item.Date = moment(item.Date).format("DD/MM/YYYY");
                }
                if (item.TotalCodeServed) {
                    item.TotalCodeServed = Number(item.TotalCodeServed)
                    totalCodeServed = totalCodeServed + parseInt(item.TotalCodeServed)
                }
                if (item.PaidImpressions) {
                    item.PaidImpressions = Number(item.PaidImpressions)
                    paidImpressions = paidImpressions + (parseInt(item.PaidImpressions) || 0)
                }
                if (item.Click) {
                    if(item.Click !== "NA"){
                        item.Click = Number(item.Click)
                        totalClicksCount = totalClicksCount + (parseInt(item.Click) || 0)
                    }
                }
                if (item.Revenue) {
                    item.Revenue = Number(item.Revenue)
                    revenue = revenue + (parseFloat(item.Revenue))
                }
                if (item.RPM) {
                    item.RPM = Number(item.RPM)
                    avgRPM = avgRPM + (parseFloat(item.RPM))
                }
                if (item.FillRate) {
                    item.FillRate = Number(item.FillRate)
                    avgFillRate = avgFillRate + (parseFloat(item.FillRate))
                }
            })
            if (avgRPM > 0) {
                let avg = (revenue > 0 && paidImpressions > 0) ? (revenue / paidImpressions) * 1000 : 0
                setAvgRPM(avg.toFixed(2))
            }
            if (avgFillRate > 0) {
                let avgFLR = (paidImpressions > 0 && totalCodeServed > 0) ? (paidImpressions / totalCodeServed) * 100 : 0
                setAvgFillRate(avgFLR.toFixed(2))
            }
            setTotalCodeServed(totalCodeServed)
            setTotalImpressions(paidImpressions)
            setTotalClicks(totalClicksCount)
            setTotalRevenue(revenue.toFixed(2))
        } else {
            loader = false
        }

    }, [revenueDisplayReports]);

    var CSVProp
    const CSVClick = () => {
        CSVProp.onExport();
    }

    const MyExportCSV = (csvprops) => {
        console.log(csvprops)
        CSVProp = csvprops
        return (<></>);
    };

    const [state, setState] = useState({
        start: moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days'),
        end: moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),
    });

    const { start, end } = state;

    const handleEvent = (startDate, endDate, label) => {
        console.log(startDate, endDate)
        setState({ start: startDate, end: endDate, });
        fromDate = startDate._d.toISOString();
        toDate = endDate._d.toISOString();
    };

    const labelDate = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');

    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
            <div className="page-content">
                <Helmet>
                    <title>Revenue Report | Bidsxchange</title>
                </Helmet>
                {!reportOpen &&
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <div className=" d-sm-flex  justify-content-between">
                            <SupplyBreadCrumb title="Proxy" breadcrumbItem="Revenue Report Query " />
                        </div>
                        <Row>
                            <div className="col-12">
                                <Card>
                                    <CardBody>
                                        <Form onSubmit={handleSubmit}>
                                            <Row style={{ padding: '1% 3%', }}>
                                                <Col xs={4} >
                                                    <div className="mb-3">
                                                        <h5>SELECT DATE</h5> <br />
                                                        {/* <DateRangePickerReport datePick={changeDatePick} startDate={dateFromDate} endDate={dateToDate} /> */}

                                                        <DateRangePicker
                                                            initialSettings={{
                                                                opens: 'left',
                                                                startDate: start.toDate(),
                                                                endDate: end.toDate(),
                                                                // minDate: moment().subtract(estCurrentDate > 1 ? 31 : 30, 'days'),
                                                                maxDate: moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),
                                                                ranges: {
                                                                    // Today: [ moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),
                                                                    // moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),],
                                                                    Yesterday: [
                                                                        moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
                                                                        moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
                                                                    ],
                                                                    'Last 7 Days': [moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days'), moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')],
                                                                    'Last 30 Days': [moment().subtract(estCurrentDate > 1 ? 31 : 30, 'days'), moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')],
                                                                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                                    'Last Month': [
                                                                        moment().subtract(1, 'month').startOf('month'),
                                                                        moment().subtract(1, 'month').endOf('month'),
                                                                    ],
                                                                },
                                                            }}
                                                            onCallback={handleEvent}
                                                        >
                                                            <button className='datePicker' type="button">
                                                                <i className="bx bx-calendar font-size-18 " id="edittooltip" style={{ margin: '3% 3% 2%' }} ></i>{labelDate}
                                                            </button>
                                                        </DateRangePicker>
                                                    </div>
                                                    <br />
                                                    <h5>FILTERS</h5>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Account Manager</Label>
                                                        {/* <Input
                                                                name="AccountManager"
                                                                type="select"
                                                                className="form-select"
                                                                value={filters.AccountManager}
                                                                onChange={changeFilter}
                                                            >
                                                                <option value=''>Select...</option>
                                                                {filterData.AccountManager.map((elem) => (
                                                                    <option key={elem} value={elem}>{elem}</option>
                                                                ))}
                                                            </Input> */}
                                                        <MultiSelect
                                                            options={AccountManager}
                                                            value={selectedAccountManager}
                                                            onChange={handleSelectAccountManager}
                                                            overrideStrings={{ "selectSomeItems": "Please Select Account Manager" }}
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label className="form-label">Publisher Manager</Label>
                                                        {/* <Input
                                                                name="PublisherManager"
                                                                type="select"
                                                                className="form-select"
                                                                value={filters.PublisherManager}
                                                                onChange={changeFilter}
                                                            >
                                                                <option value=''>Select...</option>
                                                                {filterData.PublisherManager.map((elem) => (
                                                                    <option key={elem} value={elem}>{elem}</option>
                                                                ))}
                                                            </Input> */}
                                                        <MultiSelect
                                                            options={PublisherManager}
                                                            value={selectedPublisherManager}
                                                            onChange={handleSelectPublisherManager}
                                                            overrideStrings={{ "selectSomeItems": "Please Select Publisher Manager" }}
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label className="form-label">Supply Partner </Label>
                                                        {/* <Input
                                                                name="Partner"
                                                                type="select"
                                                                className="form-select"
                                                                value={filters.Partner}
                                                                onChange={changeFilter}
                                                            >
                                                                <option value=''>Select...</option>
                                                                {filterData.Partner.map((elem) => (
                                                                    <option key={elem} value={elem}>{elem}</option>
                                                                ))}
                                                            </Input> */}
                                                        <MultiSelect
                                                            options={Partner}
                                                            value={selectedPartner}
                                                            onChange={handleSelectPartner}
                                                            overrideStrings={{ "selectSomeItems": "Please Select Supply Partner" }}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Publisher</Label>
                                                        {/* <Input
                                                                name="Publisher"
                                                                type="select"
                                                                className="form-select"
                                                                value={filters.Publisher}
                                                                onChange={changeFilter}
                                                            >
                                                                <option value=''>Select...</option>
                                                                {filterData.Publisher.map((elem) => (
                                                                    <option key={elem} value={elem}>{elem}</option>
                                                                ))}
                                                            </Input> */}
                                                        <MultiSelect
                                                            options={Publisher}
                                                            value={selectedPublisher}
                                                            onChange={handleSelectPublisher}
                                                            overrideStrings={{ "selectSomeItems": "Please Select Publisher" }}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Domain</Label>
                                                        {/* <Input
                                                                name="Domain"
                                                                type="select"
                                                                className="form-select"
                                                                value={filters.Domain}
                                                                onChange={changeFilter}
                                                            >
                                                                <option value=''>Select...</option>
                                                                {filterData.Domain.map((elem) => (
                                                                    <option key={elem} value={elem}>{elem}</option>
                                                                ))}
                                                            </Input> */}
                                                        <MultiSelect
                                                            options={Domain}
                                                            value={selectedDomain}
                                                            onChange={handleSelectDomian}
                                                            overrideStrings={{ "selectSomeItems": "Please Select Domain" }}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Ad Unit</Label>
                                                        {/* <Input
                                                                name="AdUnitName"
                                                                type="select"
                                                                className="form-select"
                                                                value={filters.AdUnitName}
                                                                onChange={changeFilter}
                                                            >
                                                                <option value=''>Select...</option>
                                                                {filterData.AdUnitName.map((elem) => (
                                                                    <option key={elem} value={elem}>{elem}</option>
                                                                ))}
                                                            </Input> */}
                                                        <MultiSelect
                                                            options={AdUnitName}
                                                            value={selectedAdUnitName}
                                                            onChange={handleSelectAdUnitName}
                                                            overrideStrings={{ "selectSomeItems": "Please Select Ad Unit " }}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label>AdUnit Size</Label>
                                                        {/* <Input
                                                                name="AdUnitSize"
                                                                type="select"
                                                                className="form-select"
                                                                value={filters.AdUnitSize}
                                                                onChange={changeFilter}
                                                            >
                                                                <option value=''>Select...</option>
                                                                {filterData.AdUnitSize.map((elem) => (
                                                                    <option key={elem} value={elem}>{elem}</option>
                                                                ))}
                                                            </Input> */}
                                                        <MultiSelect
                                                            options={AdUnitSize}
                                                            value={selectedAdUnitSize}
                                                            onChange={handleSelectAdUnitSize}
                                                            overrideStrings={{ "selectSomeItems": "Please Select AdUnit Size" }}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Inventory Type </Label>
                                                        {/* <Input
                                                                name="InventoryType"
                                                                type="select"
                                                                className="form-select"
                                                                value={filters.InventoryType}
                                                                onChange={changeFilter}
                                                            >
                                                                <option value=''>Select...</option>
                                                                {filterData.InventoryType.map((elem) => (
                                                                    <option key={elem} value={elem}>{elem}</option>
                                                                ))}
                                                            </Input> */}
                                                        <MultiSelect
                                                            options={InventoryType}
                                                            value={selectedInventoryType}
                                                            onChange={handleSelectInventoryType}
                                                            overrideStrings={{ "selectSomeItems": "Please Select Inventory Type" }}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Deal Type</Label>
                                                        {/* <Input
                                                                name="DealType"
                                                                type="select"
                                                                className="form-select"
                                                                value={filters.DealType}
                                                                onChange={changeFilter}
                                                            >
                                                                <option value=''>Select...</option>
                                                                {filterData.DealType.map((elem) => (
                                                                    <option key={elem} value={elem}>{elem}</option>
                                                                ))}
                                                            </Input> */}
                                                        <MultiSelect
                                                            options={DealType}
                                                            value={selectedDealType}
                                                            onChange={handleSelectDealType}
                                                            overrideStrings={{ "selectSomeItems": "Please Select Deal Type" }}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Device</Label>
                                                        {/* <Input
                                                                name="Device"
                                                                type="select"
                                                                className="form-select"
                                                                value={filters.Device}
                                                                onChange={changeFilter}
                                                            >
                                                                <option value=''>Select...</option>
                                                                {filterData.Device.map((elem) => (
                                                                    <option key={elem} value={elem}>{elem}</option>
                                                                ))}
                                                            </Input> */}
                                                        <MultiSelect
                                                            options={Device}
                                                            value={selectedDevice}
                                                            onChange={handleSelectDevice}
                                                            overrideStrings={{ "selectSomeItems": "Please Select Device" }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xs={4} style={{ padding: '0% 3%', }}>

                                                    <div className="mb-3">
                                                        <h5> SELECT RANGE</h5><br />
                                                        <Input
                                                            name="range"
                                                            type="select"
                                                            className="form-select"
                                                            onChange={changeRange}
                                                            value={range}
                                                        >
                                                            <option value='' disabled>Select</option>
                                                            <option value='Month'>Month</option>
                                                            <option value='Day'>Day</option>
                                                            <option value='Cumulative'>Cumulative</option>
                                                        </Input>
                                                    </div>
                                                    <br />
                                                    <h5 className="mb-3 ">DIMENSIONS</h5>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAll}
                                                            onChange={changeDMSelect}
                                                            id="revenueSelectAll"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="revenueSelectAll"
                                                        >
                                                            Select All
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.AccountManager}
                                                            name="AccountManager"
                                                            onChange={changeSelection}
                                                            id="accountManager"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="accountManager"
                                                        >
                                                            Account Manager
                                                        </label>
                                                    </div>

                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.PublisherManager}
                                                            name="PublisherManager"
                                                            onChange={changeSelection}
                                                            id="publisherManager"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="publisherManager"
                                                        >
                                                            Publisher Manager
                                                        </label>
                                                    </div>

                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.Partner}
                                                            name="Partner"
                                                            onChange={changeSelection}
                                                            id="supplyPartner"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="supplyPartner"
                                                        >
                                                            Supply Partner
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.Publisher}
                                                            name="Publisher"
                                                            onChange={changeSelection}
                                                            id="publisher"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="publisher"
                                                        >
                                                            Publisher
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.Domain}
                                                            name="Domain"
                                                            onChange={changeSelection}
                                                            id="domain"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="domain"
                                                        >
                                                            Domain
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.AdUnitName}
                                                            name="AdUnitName"
                                                            onChange={changeSelection}
                                                            id="adunit"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="adunit"
                                                        >
                                                            Ad Unit
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.AdUnitSize}
                                                            name="AdUnitSize"
                                                            onChange={changeSelection}
                                                            id="adUnitSize"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="adUnitSize"
                                                        >
                                                            AdUnit Size
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.InventoryType}
                                                            name="InventoryType"
                                                            onChange={changeSelection}
                                                            id="inventoryType"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="inventoryType"
                                                        >
                                                            Inventory Type
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.DealType}
                                                            name="DealType"
                                                            onChange={changeSelection}
                                                            id="dealType"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="dealType"
                                                        >
                                                            Deal Type
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllDM.Device}
                                                            name="Device"
                                                            onChange={changeSelection}
                                                            id="device"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="device"
                                                        >
                                                            Device
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col xs={4} style={{ padding: '0 3%', }}>
                                                    <div className="mb-3">
                                                        <h5> SELECT Time Zone</h5><br />
                                                        <Input
                                                            name="timeZone"
                                                            type="select"
                                                            className="form-select"
                                                            defaultValue={'EST'}
                                                        >
                                                            <option value='' disabled>Select</option>
                                                            <option value='EST'>EST</option>
                                                        </Input>
                                                    </div>
                                                    <br />
                                                    <h5 className="mb-3">METRICES</h5>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectAllMTC}
                                                            onChange={changeMTCSelect}
                                                            id="selectAllRevenue"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="selectAllRevenue"
                                                        >
                                                            Select All
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectedMTC.TotalCodeServed}
                                                            name="TotalCodeServed"
                                                            onChange={changeSelectionMTC}
                                                            id="totalCodeServed"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="totalCodeServed"
                                                        >
                                                            Total Code Served
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectedMTC.PaidImpressions}
                                                            name="PaidImpressions"
                                                            onChange={changeSelectionMTC}
                                                            id="paidImpressions"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="paidImpressions"
                                                        >
                                                            Paid Impressions
                                                        </label>
                                                    </div>

                                                    {clicksPresent &&
                                                        <div className="form-check mt-2 mb-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectedMTC.Click}
                                                                name="Click"
                                                                onChange={changeSelectionMTC}
                                                                id="clicks"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="clicks"
                                                            >
                                                                Clicks
                                                            </label>
                                                        </div>
                                                    }

                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectedMTC.Revenue}
                                                            name="Revenue"
                                                            onChange={changeSelectionMTC}
                                                            id="revenue"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="revenue"
                                                        >
                                                            Revenue
                                                        </label>
                                                    </div>
                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectedMTC.RPM}
                                                            name="RPM"
                                                            onChange={changeSelectionMTC}
                                                            id="rpm"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="rpm"
                                                        >
                                                            RPM
                                                        </label>
                                                    </div>

                                                    <div className="form-check mt-2 mb-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={selectedMTC.FillRate}
                                                            name="FillRate"
                                                            onChange={changeSelectionMTC}
                                                            id="fillRate"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="fillRate"
                                                        >
                                                            Fill Rate
                                                        </label>
                                                    </div>


                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className=" text-sm-end">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary save-user"
                                                        >
                                                            Run Report
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </div>
                        </Row>
                    </Container>
                }
                {reportOpen &&
                    <Container fluid={true}>
                        <div className=" d-sm-flex  justify-content-between">
                            <SupplyBreadCrumb title="Proxy" breadcrumbItem="Revenue Report " />
                            <div className=" text-sm-end">
                                <div className="btn-group">
                                    <Dropdown
                                        isOpen={btnprimary1}
                                        toggle={() => setBtnprimary1(!btnprimary1)}
                                    >
                                        <DropdownToggle tag="button" className="btn btn-primary">
                                            Export
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {/* <DropdownItem onClick={copyTable}>Copy</DropdownItem> */}
                                            <DropdownItem onClick={CSVClick}>CSV</DropdownItem>
                                            {/* <DropdownItem onClick={exportPDF}>PDF</DropdownItem> */}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                                &nbsp;&nbsp;&nbsp;
                                <Button
                                    color="primary"
                                    className="font-16 btn-block btn btn-primary"
                                    onClick={(e) => setReportOpen(false)}
                                >
                                    <i className="mdi mdi-plus-circle-outline me-1" />
                                    Modify Report
                                </Button>
                            </div>
                        </div>
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody aria-hidden="true">
                                        {loading &&
                                            <>
                                                <Spinner className="ms-2 loader" color="primary" />
                                                <br />
                                                <h5 className="text-center mt-5" style={{ padding: '15%', fontSize: '25px' }}></h5>
                                                <br />
                                            </>
                                        }
                                        {revenueDisplayReports.length === 0 && !loading &&
                                            <h5 className="text-center" style={{ padding: '15%', fontSize: '25px' }}>No Data</h5>
                                        }
                                        {revenueDisplayReports.length > 0 && !loading &&
                                            <ToolkitProvider
                                                keyField={keyField}
                                                data={revenueDisplayReports}
                                                columns={revenueDisplayReportsColumns}
                                                // bootstrap4
                                                search
                                                exportCSV={{
                                                    fileName: 'Revenue-Report-' + currentTimeStamp + '.csv',
                                                }}
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col sm="8">
                                                                <div className="search-box ms-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <SearchBar {...toolkitProps.searchProps} />
                                                                        <i className="bx bx-search-alt search-icon" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col sm="1">
                                                                {/* <ExportCSVButton { ...toolkitProps.csvProps }>Export CSV!!</ExportCSVButton> */}

                                                                <MyExportCSV {...toolkitProps.csvProps} />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12" className="table-responsive">
                                                                <div >
                                                                    <BootstrapTable
                                                                        keyField={keyField}
                                                                        responsive
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        classes={
                                                                            "table align-middle table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        pagination={paginationFactory(pageOptions)}
                                                                        noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }

            </div>
            {/* </div> */}
        </React.Fragment>
    )
}

Revenue_Report.propTypes = {}

export default Revenue_Report