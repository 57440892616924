
import React, { useEffect, useState, useRef, useCallback } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from "axios";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  ButtonDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Input,
  Spinner,
  Label
} from "reactstrap"
import queryString from 'query-string';

import DateRangePickers from '../../../helpers/DateRangePicker'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

import {
  getCreative as onGetCreative,
  statusCreative as onStatusCreative,
  archiveCreative as onArchiveCreative,
  statusCreativeSuccess,
  archiveCreativeSuccess,
  addCreativeFail,
  updateCreativeFail,
} from "store/Demand/Creative/actions";

import {
  getLineItem as onGetLineItems,
} from "store/Demand/LineItem/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

// //Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Create_Creative from "./Create_Creatives/Create_Creatives";
import LoadingTable from "pages/LoaderPages/LoadingTable";
import LineItemDataTable from "Product_Helpers/ProductDataTable"
import ProductDataTableLI from "Product_Helpers/ProductDataTableLI";

import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 2 : 1
var fromDate = ''
var toDate = ''

var selectedItem = []
var videoSelectedItem = []
var selectedData = ''
var selectedIndex = []
var creativeType = ''

var filterCount = 0

let pageNumber = 1
let pageSize = 10
let totalRecords = 0
let searchText = ''

const Creative = () => {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [isRight, setIsRight] = useState(false);
  const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
  const [drp_primary1, setDrp_primary1] = useState(false)
  const [btnprimary1, setBtnprimary1] = useState(false)
  const parsed = queryString.parse(window.location.search);
  const [statusSearch, setStatusSearch] = useState('Active')
  const [creativeTypeSearch, setCreativeTypeSearch] = useState('')
  const [creativeSizeSearch, setCreativeSizeSearch] = useState('')
  const [creativeTemplateSearch, setCreativeTemplateSearch] = useState('')
  const [isArchived, setIsArchived] = useState(false)
  const [selected, setSelected] = useState([])
  const [createState, setCreateState] = useState(true)

  const [switchLoader, setSwitchLoader] = useState(true)
  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));
  const UserInventoryType = localStorage.getItem('InventoryType');
  const [creativeCreateStatus, setCreativeCreateStatus] = useState('')

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  const [isMenu, setisMenu] = useState(false)
  const toggleMenu = () => {
    setisMenu(!isMenu)
  }
  const [state, setState] = useState({
    start: moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
    end: moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
  });
  const { start, end } = state;
  const handleEvent = (startDate, endDate, label) => {
    setState({ start: startDate, end: endDate, });
    fromDate = startDate._d.toISOString();
    toDate = endDate._d.toISOString();
    filterCount = filterCount + 1
  }
  const labelDate = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setIsRight(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const [filterState, setFilterState] = useState(false)

  const statusChange = (e) => {
    if (e.target.value === 'Active') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (statusSearch === 'Active') {
      filterCount = filterCount + 1
    }
    setStatusSearch(e.target.value)
  }

  const creativeTypeChange = (e) => {
    if (e.target.value === '') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (creativeSizeSearch === '') {
      filterCount = filterCount + 1
    }
    setCreativeTypeSearch(e.target.value)
  }

  const creativeSizeChange = (e) => {
    if (e.target.value === '') {
      filterCount = filterCount > 0 ? filterCount - 1 : 0
    }
    if (creativeSizeSearch === '') {
      filterCount = filterCount + 1
    }
    setCreativeSizeSearch(e.target.value)
  }

  const applyFilter = (e) => {
    if (parsed.lineItemID) {
      dispatch(onGetCreative({ lineItemID: parsed.lineItemID, userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '',pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, creativeType: creativeTypeSearch, creativeSize: creativeSizeSearch, creativeTemplateName: creativeTemplateSearch }));
    } else {
      dispatch(onGetCreative({ lineItemID: '', userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '',pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, creativeType: creativeTypeSearch, creativeSize: creativeSizeSearch, creativeTemplateName: creativeTemplateSearch }));
    }
    if (filterCount > 0) {
      setFilterState(true)
    } else {
      setFilterState(false)
    }
  }

  // const statusChange = (e) => {
  //   setStatusSearch(e.target.value)
  //   if (parsed.lineItemID) {
  //     dispatch(onGetCreative({ lineItemID: parsed.lineItemID, userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '',pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: e.target.value, creativeType: creativeTypeSearch, creativeSize: creativeSizeSearch, creativeTemplateName: creativeTemplateSearch }));
  //   } else {
  //     dispatch(onGetCreative({ lineItemID: '', userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '',pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: e.target.value, creativeType: creativeTypeSearch, creativeSize: creativeSizeSearch, creativeTemplateName: creativeTemplateSearch }));
  //   }
  // }

  // const creativeTypeChange = (e) => {
  //   setCreativeTypeSearch(e.target.value)
  //   if (parsed.lineItemID) {
  //     dispatch(onGetCreative({ lineItemID: parsed.lineItemID, userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '',pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, creativeType: e.target.value, creativeSize: creativeSizeSearch, creativeTemplateName: creativeTemplateSearch }));
  //   } else {
  //     dispatch(onGetCreative({ lineItemID: '', userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '',pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, creativeType: e.target.value, creativeSize: creativeSizeSearch, creativeTemplateName: creativeTemplateSearch }));
  //   }
  // }

  // const creativeSizeChange = (e) => {
  //   setCreativeSizeSearch(e.target.value)
  //   if (parsed.lineItemID) {
  //     dispatch(onGetCreative({ lineItemID: parsed.lineItemID, userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '',pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, creativeType: creativeTypeSearch, creativeSize: e.target.value, creativeTemplateName: creativeTemplateSearch }));
  //   } else {
  //     dispatch(onGetCreative({ lineItemID: '', userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '',pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, creativeType: creativeTypeSearch, creativeSize: e.target.value, creativeTemplateName: creativeTemplateSearch }));
  //   }
  // }

  const creativeTemplateChange = (e) => {
    setCreativeTemplateSearch(e.target.value)
    if (parsed.lineItemID) {
      dispatch(onGetCreative({ lineItemID: parsed.lineItemID, userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '',pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, creativeType: creativeTypeSearch, creativeSize: creativeSizeSearch, creativeTemplateName: e.target.value }));
    } else {
      dispatch(onGetCreative({ lineItemID: '', userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '',pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, creativeType: creativeTypeSearch, creativeSize: creativeSizeSearch, creativeTemplateName: e.target.value }));
    }
  }

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const body = {
    "url": '',
    "requestBody": {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
      name: userInfo.name,
    }
  }

  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 3000,
    extendedTimeOut: 1000
  };

  useEffect(() => {
    dispatch(onGetCreative(null));
    dispatch(onGetLineItems(null));
    totalRecords = 0
    if (parsed.status === 'CreativeCreateByLineItem') {
      creativeType = parsed.creativeType
      setIsRight(true);
      setCreateState(!createState)
    }
  }, []);

  const objectModals = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.creativeTemplateName
      obj.label = item.creativeTemplateName
      dataList.push(obj)
    })
    return dataList
  }

  const selectRow = {
    mode: "checkbox",
    nonSelectable: [],
    selected: selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      console.log(row, isSelect, rowIndex)
      if (isSelect) {
        setSelected([...selected, row.id])
        setSelectedRowsStatus(true)
        selectedIndex.push(row.creativeID)
        if (row.creativeType === 'video' || row.creativeType === 'play') {
          videoSelectedItem.push(row.creativeID)
        } else {
          selectedItem.push(row.creativeID)
        }
        console.log(selectedIndex)
      } else {
        setSelected(selected.filter(x => x !== row.id))
        let index = selectedIndex.indexOf(row.creativeID)
        selectedIndex.splice(index, 1)
        if (row.creativeType === 'video' || row.creativeType === 'play') {
          let index2 = videoSelectedItem.indexOf(row.creativeID)
          videoSelectedItem.splice(index2, 1)
        } else {
          let index3 = selectedItem.indexOf(row.creativeID)
          selectedItem.splice(index3, 1)
        }
        if (selectedIndex.length === 0) {
          setSelectedRowsStatus(false)
        }
        console.log(selectedIndex)
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      const ids = rows.map(r => r.id);
      if (isSelect) {
        setSelected(ids)
        rows.map((item, index) => {
          selectedIndex.push(item.creativeID)
          if (item.creativeType === 'video' || item.creativeType === 'play') {
            videoSelectedItem.push(item.creativeID)
          } else {
            selectedItem.push(item.creativeID)
          }
        })
        setSelectedRowsStatus(true)
      } else {
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        selectedItem = []
        videoSelectedItem = []
      }
    }
  };

  const handleClose = () => {
    setSelectedRowsStatus(false)
    selectedItem = []
    setSelected([])
    selectedIndex = []
    videoSelectedItem = []
  }

  const { creatives,paginationData } = useSelector(state => ({
    creatives: state.creatives.creatives,
    paginationData: state.creatives.pagination
  }));

  totalRecords = paginationData ? paginationData.totalRecords ? paginationData.totalRecords : 0 : 0

  const { loading } = useSelector(state => ({
    loading: state.creatives.loading,
  }));

  const { noData } = useSelector(state => ({
    noData: state.creatives.noData,
  }));

  const { creativesResponse } = useSelector(state => ({
    creativesResponse: state.creatives.response,
  }));

  const { archiveCreativeResponse } = useSelector(state => ({
    archiveCreativeResponse: state.creatives.archiveMsg,
  }));

  useEffect(() => {
    if (loading) {
      setSwitchLoader(true)
    }
  }, [loading]);

  console.log(creativesResponse)
  useEffect(() => {
    if (creativesResponse !== null) {
      if (creativesResponse.statusCode === 200) {
        toastr.success('Creative Status Changed Successfully')
        if (parsed.lineItemID) {
          dispatch(onGetCreative({ lineItemID: parsed.lineItemID, userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '',pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, creativeType: creativeTypeSearch, creativeSize: creativeSizeSearch, creativeTemplateName: creativeTemplateSearch }));
        } else {
          dispatch(onGetCreative({ lineItemID: '', userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '',pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, creativeType: creativeTypeSearch, creativeSize: creativeSizeSearch, creativeTemplateName: creativeTemplateSearch }));
        }
        selectedItem = []
        videoSelectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        dispatch(statusCreativeSuccess(null));
      }
    }
  }, [dispatch, creativesResponse]);

  useEffect(() => {
    if (archiveCreativeResponse !== null) {
      if (archiveCreativeResponse.statusCode === 200) {
        toastr.success('Creative Archived Successfully')
        if (parsed.lineItemID) {
          dispatch(onGetCreative({ lineItemID: parsed.lineItemID, userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '',pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, creativeType: creativeTypeSearch, creativeSize: creativeSizeSearch, creativeTemplateName: creativeTemplateSearch }));
        } else {
          dispatch(onGetCreative({ lineItemID: '', userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '',pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, creativeType: creativeTypeSearch, creativeSize: creativeSizeSearch, creativeTemplateName: creativeTemplateSearch }));
        }
        selectedItem = []
        videoSelectedItem = []
        setSelected([])
        setSelectedRowsStatus(false)
        selectedIndex = []
        dispatch(archiveCreativeSuccess(null));
      }
    }
  }, [dispatch, archiveCreativeResponse]);

  const toggleRightCanvas = () => {
    selectedData = ''
    if (parsed.lineItemID) {
      dispatch(onGetCreative({ lineItemID: parsed.lineItemID, userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '',pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, creativeType: creativeTypeSearch, creativeSize: creativeSizeSearch, creativeTemplateName: creativeTemplateSearch }));
    } else {
      dispatch(onGetCreative({ lineItemID: '', userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '',pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, creativeType: creativeTypeSearch, creativeSize: creativeSizeSearch, creativeTemplateName: creativeTemplateSearch }));
    }
    setIsRight(!isRight);
    setCreateState(!createState)
  };

  const toggleEnableScroll = () => {
    setIsEnableScroll(!isEnableScroll);
  };

  const toggleDrawer = (close, creative, creativeTypes, creationStatus) => (event) => {
    console.log(event)
    console.log(creativeTypes)
    if (event === 'Create') {
      toastr.success('Creative Saved Successfully!...')
    }
    if (event === 'Update') {
      toastr.success('Creative Updated Successfully!...')
    }
    if (close) {
      selectedData = creative
      creativeType = creativeTypes
    } else {
      selectedData = ''
      creativeType = ''
    }
    setIsArchived((creative.isCreativeArchived === true) ? true : false)
    setCreativeCreateStatus(creationStatus)
    console.log(close, creative, creativeTypes)
    if (parsed.lineItemID) {
      dispatch(onGetCreative({ lineItemID: parsed.lineItemID, userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '',pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, creativeType: creativeTypeSearch, creativeSize: creativeSizeSearch, creativeTemplateName: creativeTemplateSearch }));
    } else {
      dispatch(onGetCreative({ lineItemID: '', userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '',pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, creativeType: creativeTypeSearch, creativeSize: creativeSizeSearch, creativeTemplateName: creativeTemplateSearch }));
    }
    dispatch(addCreativeFail(null));
    dispatch(updateCreativeFail(null));
    setIsRight(close);
    setCreateState(!createState)
  };

  useEffect(() => {
    if (parsed.lineItemID) {
      dispatch(onGetCreative({ lineItemID: parsed.lineItemID, userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate": '', "endDate": '',pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, creativeType: creativeTypeSearch, creativeSize: creativeSizeSearch, creativeTemplateName: creativeTemplateSearch }));
    }
  }, []);

  useEffect(() => {
    if (creatives !== null && !parsed.lineItemID) {
      dispatch(onGetCreative({ lineItemID: '', userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate": '', "endDate": '',pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: statusSearch, creativeType: creativeTypeSearch, creativeSize: creativeSizeSearch, creativeTemplateName: creativeTemplateSearch }));
    }
  }, []);

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  console.log(creatives)

  const creativeListColumns = [
    {
      text: "ID",
      headerClasses: "ID",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "ID",
      headerClasses: "ID",
      dataField: "creativeID",
      sort: true,
      formatter: (cellContent, creative) => (
        <>
          <span>{creative.creativeID}</span>
        </>
      ),
    },
    {
      dataField: "creativeCreateTime",
      text: "Creation Date",
      sort: true,
      order: "desc",
    },
    {
      text: "Creative Name",
      dataField: "creativeName",
      sort: true,
      // eslint-disable-next-line react/display-name
      // formatter: (cellContent, creative) => (
      //   <>
      //     <h5 className="font-size-14 mb-1">
      //       <Link to="#" className="text-dark">
      //         {creative.creativeName}
      //       </Link>
      //     </h5>
      //   </>
      // ),
    },
    {
      text: "Creative Type",
      dataField: "creativeTypeShow",
      sort: false
    },
    {
      dataField: "creativeStatus",
      text: "Status",
      sort: false,
      formatter: (cellContent, creative) => (
        <>
          <span className={creative.newStatus === 'Active' ? "active-state" : creative.newStatus === 'Inactive' ? "inactive-state" : "archived-state"}>{creative.newStatus}</span>
        </>
      ),
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Action",
      align: 'center',
      headerAlign: 'center',
      hidden: (UserRolePrivileges.Demand && UserRolePrivileges.Demand.Creatives) && (!UserRolePrivileges.Demand.Creatives.update),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, creative) => (
        <span>
          <span className="text-success " onClick={toggleDrawer(true, creative, '', "Edit")}>
            {creative.newStatus === 'Archived' &&
              <i
                className="mdi mdi-eye font-size-18"
                id="edittooltip"
              ></i>}
            {creative.newStatus !== 'Archived' &&
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
              ></i>}
          </span> &nbsp;&nbsp;
          <span className="text-success " onClick={toggleDrawer(true, creative, '', "Duplicate")}>
            <i
              className="bx bx-duplicate font-size-18"
              id="edittooltip"
            ></i>
          </span>
        </span>

      ),
    },
  ];

  const { SearchBar } = Search;

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );


  const pageOptions = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
  };

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const archiveCreative = async () => {
    if (videoSelectedItem.length > 0) {
      dispatch(onArchiveCreative({ idList: videoSelectedItem, creativeType: 'video',userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID }));
    }
    if (selectedItem.length > 0) {
      dispatch(onArchiveCreative({ idList: selectedItem, creativeType: 'display' ,userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID}));
    }
  }

  const statusCreative = (state) => (event) => {
    console.log(state)
    console.log(videoSelectedItem, selectedItem)
    if (videoSelectedItem.length > 0) {
      dispatch(onStatusCreative({ idList: videoSelectedItem, videoCreativeStatus: state, creativeType: 'video',userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID }));
    }
    if (selectedItem.length > 0) {
      dispatch(onStatusCreative({ idList: selectedItem, creativeStatus: state, creativeType: 'display',userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID }));
    }

  }

  console.log(creatives)

  if (creatives.length > 0) {
    if (UserRolePrivileges.Demand && UserRolePrivileges.Demand.Creatives) {
      if (!UserRolePrivileges.Demand.Creatives.archive && !UserRolePrivileges.Demand.Creatives.status) {
        creatives.map((item, index) => {
          selectRow.nonSelectable.push(index + 1)
        })
      } else {
        creatives.map((item, index) => {
          if (item.isCreativeArchived) {
            selectRow.nonSelectable.push(index + 1)
          }
        })
      }
    }
  }


  const clearAllFilter = (e) => {
    setStatusSearch('Active')
    setCreativeTypeSearch('')
    setCreativeSizeSearch('')
    filterCount = 0
    setFilterState(false)
    fromDate = ''
    toDate = ''
    setState({
      start: moment().subtract(0, 'days'),
      end: moment().subtract(0, 'days'),
    })
    if (parsed.lineItemID) {
      dispatch(onGetCreative({ lineItemID: parsed.lineItemID, userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate": '', "endDate": '',pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: 'Active', creativeType: '', creativeSize: '', creativeTemplateName: '' }));
    } else {
      dispatch(onGetCreative({ lineItemID: '', userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate": '', "endDate": '',pageSize: pageSize, pageNumber: pageNumber, searchText: searchText,filter: 'Active', creativeType: '', creativeSize: '', creativeTemplateName: '' }));
    }
  }

  const paginationChange = async (selectedPages) => {
    console.log(selectedPages)
    pageSize = selectedPages.pageSize
    pageNumber = selectedPages.pageNumber
    if (parsed.lineItemID) {
      dispatch(onGetCreative({ lineItemID: parsed.lineItemID, userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '',pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText,filter: statusSearch, creativeType: creativeTypeSearch, creativeSize: creativeSizeSearch, creativeTemplateName: creativeTemplateSearch }));
    } else {
      dispatch(onGetCreative({ lineItemID: '', userRole:userInfo.userRole,userID:userInfo.userID,parentID:userInfo.parentID,"startDate":fromDate ? moment(fromDate).format("YYYY-MM-DD") : '', "endDate":toDate ? moment(toDate).format("YYYY-MM-DD") : '',pageSize: selectedPages.pageSize, pageNumber: selectedPages.pageNumber, searchText: selectedPages.searchText,filter: statusSearch, creativeType: creativeTypeSearch, creativeSize: creativeSizeSearch, creativeTemplateName: creativeTemplateSearch }));
    }
  }

  return (
    <React.Fragment>
      {/* <Sidebar />
      <Header />
      <div className="main-content"> */}
      <div className="page-content">
        <Helmet>
          <title>Creative | Bidsxchange</title>
        </Helmet>
        <Container fluid={true} style={{ pointerEvents: (loading) ? 'none' : 'unset' }}>
          {/* Render Breadcrumbs */}
          <div className='row'>
            <div className='col-8'>
              {parsed.lineItemID ? (
                <Breadcrumbs title="Demand" breadcrumbItem="Creative" lineItemID={parsed.lineItemID} />
              ) : (
                <Breadcrumbs title="Demand" breadcrumbItem="Creative" breadList="All Creatives" />
              )
              }
            </div>
            <div className='col-4'>
              <div className='row'>
                {UserRolePrivileges.Demand && UserRolePrivileges.Demand.Creatives && UserRolePrivileges.Demand.Creatives.create &&
                  <div className="text-sm-end">

                    <div className="btn-group">
                      <Dropdown
                        direction="left"
                        isOpen={isMenu}
                        toggle={() => { }}
                      >
                        <DropdownToggle
                          tag="button"
                          onClick={toggleMenu}
                          className={'btn font-16 btn-block' + (filterState ? ' btn-danger' : ' btn-light')}
                        >
                          <i className="bx bx-filter-alt align-middle"></i> &nbsp;
                          {filterState ? <span className="d-none font-16 d-sm-inline-block" >
                            Filter Applied
                          </span> : <span className="d-none font-16 d-sm-inline-block">
                            Filter
                          </span>
                          }
                          {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px',color:'red' }}>{filterCount}</span>
                              } */}
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-md dropdown-menu-end" style={{ width: '30em' }}>
                          <div className="dropdown-item-text">
                            <div className="row">
                              <h5 className="mb-0">Filters</h5>
                            </div>
                          </div>

                          <DropdownItem divider />
                          <Card>
                            <CardBody aria-hidden="true">
                              <Row >
                                <Col sm="12" className="mt-2">
                                  <Label className="form-label">Size</Label>
                                  <div className=" text-sm-end">
                                    <Input type="select" className="form-select" value={creativeSizeSearch} onChange={creativeSizeChange}>
                                      <option value=''> All</option>
                                      <option value="300x250">300x250 </option>
                                      <option value="300x600">300x600 </option>
                                      <option value="728x90">728x90 </option>
                                      <option value="120x600">120x600 </option>
                                      <option value="160x600">160x600 </option>
                                      <option value="970x250">970x250 </option>
                                      <option value="970x90">970x90</option>
                                      <option value="320x50">320x50</option>
                                      <option value="320x100">320x100 </option>
                                      <option value="300x50">300x50 </option>
                                      <option value="300x100">300x100 </option>
                                      <option value="468x60">468x60 </option>
                                      <option value="468x90">468x90</option>
                                      <option value="336x280">336x280 </option>
                                    </Input>
                                  </div>
                                </Col>
                                {UserInventoryType !== 'video' &&
                                  <Col sm="12" className="mt-2">
                                    <Label className="form-label">Creative Type</Label>
                                    <div className=" text-sm-end">
                                      <Input type="select" className="form-select" value={creativeTypeSearch} onChange={creativeTypeChange}>
                                        <option value=''> All</option>
                                        <option value='image' >Image</option>
                                        <option value='html'>HTML</option>
                                        <option value='thirdParty'> Third Party</option>
                                        <option value='stdb' >Standard Banner</option>
                                        <option value='highImpact'> High Impact</option>
                                        {UserInventoryType === 'both' &&
                                          <option value='video'> Video</option>
                                        }
                                      </Input>
                                    </div>
                                  </Col>}
                                <Col sm="12" className="mt-2">
                                  <Label className="form-label">Status</Label>
                                  <div className=" text-sm-end">
                                    <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                      <option value=''> All</option>
                                      <option value='Active' > Active</option>
                                      <option value='Inactive'> InActive</option>
                                      <option value='Archived'> Archived</option>
                                    </Input>
                                  </div>
                                </Col>
                                <Col sm="12" className="date-range mt-2">
                                  <Label className="form-label">Filter by Modified Dates</Label>
                                  <div className="">
                                    <DateRangePicker
                                      initialSettings={{
                                        opens: 'left',
                                        startDate: start.toDate(),
                                        endDate: end.toDate(),
                                      }}
                                      onCallback={handleEvent}
                                    >
                                      <button className='datePicker w-100' type="button">
                                        <i className="bx bx-calendar font-size-18 " id="edittooltip" style={{ margin: '3% 3% 2%' }} ></i>{labelDate}
                                      </button>
                                    </DateRangePicker>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>

                          <DropdownItem divider />
                          <Row>
                            <Col sm="6">
                              <DropdownItem
                                className="text-primary text-center"
                                onClick={clearAllFilter}
                              >
                                Clear ALL
                                {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px' }}>{filterCount}</span>
                              } */}
                              </DropdownItem>
                            </Col>
                            <Col sm="6">
                              <DropdownItem
                                className="text-primary text-center"
                                onClick={applyFilter}
                              >
                                Apply Filter
                                {/* {filterCount > 0 &&
                                <span className="badge bg-primary rounded-pill" style={{ position: 'absolute', top: '-1px',paddingTop:'3px' }}>{filterCount}</span>
                              } */}
                              </DropdownItem>
                            </Col>
                          </Row>

                        </DropdownMenu>
                      </Dropdown>
                    </div> &nbsp;&nbsp;

                    {(parsed.status === 'CreativeCreateByLineItem' || parsed.status === 'CreativeCreateByParent') &&
                      <Button
                        color="primary"
                        className="font-16 btn-block btn btn-primary"
                        onClick={toggleDrawer(true, '', parsed.creativeType, "Create")}
                      >
                        <i className="mdi mdi-plus-circle-outline me-1" />
                        Add Creative
                      </Button>
                    }
                    {(!parsed.status) &&
                      <div className="btn-group">
                        {UserInventoryType !== 'video' ?
                          <Dropdown
                            isOpen={btnprimary1}
                            toggle={() => setBtnprimary1(!btnprimary1)}
                          >
                            <DropdownToggle tag="button" className="btn btn-primary">
                              <i className="mdi mdi-plus-circle-outline me-1" />Add Creative&nbsp; <i className="mdi mdi-chevron-down" />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem onClick={toggleDrawer(true, '', 'stdb', "Create")}>Standard</DropdownItem>
                              <DropdownItem onClick={toggleDrawer(true, '', 'highImpact', "Create")}>High Impact</DropdownItem>
                              {UserInventoryType === 'both' &&
                                <DropdownItem onClick={toggleDrawer(true, '', 'video', "Create")}>Video</DropdownItem>
                              }
                            </DropdownMenu>
                          </Dropdown> : <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                            onClick={toggleDrawer(true, '', 'video', "Create")}
                          >
                            <i className="mdi mdi-plus-circle-outline me-1" />
                            Add Creative
                          </Button>}
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          </div>
          <ProductDataTableLI statusAccess={(UserRolePrivileges.Demand && UserRolePrivileges.Demand.Creatives && UserRolePrivileges.Demand.Creatives.status) ? true : false}
            archiveAccess={(UserRolePrivileges.Demand && UserRolePrivileges.Demand.Creatives && UserRolePrivileges.Demand.Creatives.archive) ? true : false} loading={loading} data={creatives} columns={creativeListColumns} statusChange={statusCreative} archiveLineItem={archiveCreative} rowSelection={selectRow} handleClose={handleClose} selectedIndex={selectedIndex} paginationSelection={paginationChange} totalRecords={totalRecords} pageNumber={pageNumber} pageSize={pageSize} />
        </Container>
      </div>
      {/* </div> */}

      <Offcanvas isOpen={isRight} direction='end'  >
        <OffcanvasHeader toggle={toggleDrawer(false, '', '', '')}>
          {(selectedData && creativeCreateStatus !== "Duplicate") ? 'Edit Creative ' : 'Add ' + creativeType + ' Creative '}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">
          <Create_Creative cancelCreative={toggleDrawer(false, '', '', '')} selectedArchived={isArchived} selectedcreativeCreateStatus={creativeCreateStatus} selectedCreative={selectedData} selectedCreativeType={creativeType} selectedLineItem={(parsed.status === 'CreativeCreateByLineItem' || parsed.status === 'CreativeCreateByParent') ? parsed : ''} />
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment >
  )
}

export default Creative
