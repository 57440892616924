import {
    LOAD_ACTIVITIES_LI,
    NO_DATA,
    GET_LINEITEMS_SUCCESS,
    GET_LINEITEMS_FAIL,
    ADD_LINEITEM_SUCCESS,
    ADD_LINEITEM_ERROR,
    UPDATE_LINEITEM_SUCCESS,
    UPDATE_LINEITEM_ERROR,
    READ_LINEITEM_SUCCESS,
    STATUS_LINEITEM_SUCCESS,
    ARCHIVE_LINEITEM_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    lineItems: [],
    lineItemsPagination:{},
    error: {},
    loading: false,
    noData: false,
    successMsg: null,
    errorMsg: null,
    lineItem: null,
    response: null,
    archiveMsg: null,
}

const lineItems = (state = INIT_STATE, action) => {
    console.log(action.payload)
    switch (action.type) {
        case LOAD_ACTIVITIES_LI:
            return {
                ...state,
                loading: true,
            }
        case NO_DATA:
            return {
                ...state,
                noData: true,
                loading: false,
            }
        case GET_LINEITEMS_SUCCESS:
            return {
                ...state,
                loading: false,
                lineItems: action.payload.response.lineItems ? action.payload.response.lineItems : [],
                lineItemsPagination:action.payload.response
            }

        case GET_LINEITEMS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case ADD_LINEITEM_SUCCESS:

            return {
                ...state,
                successMsg: action.payload,
            }
        case ADD_LINEITEM_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_LINEITEMS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_LINEITEM_SUCCESS:
            return {
                ...state,
                successMsg: action.type === 'GET_LINEITEMS_SUCCESS' ? null : action.payload,
            }
        case UPDATE_LINEITEM_ERROR:
            return {
                ...state,
                error: action.payload,
                errorMsg: action.type === 'GET_LINEITEMS_SUCCESS' ? null : action.payload,
            }
        case READ_LINEITEM_SUCCESS:
            return {
                ...state,
                lineItem: action.payload,
            }
        case STATUS_LINEITEM_SUCCESS:
            return {
                ...state,
                response: action.payload,
            }
        case ARCHIVE_LINEITEM_SUCCESS:
            return {
                ...state,
                archiveMsg: action.payload,
            }
        default:
            return state
    }
}

export default lineItems